import React, {Component, useEffect} from 'react';
import styles from './styles.module.scss';
import PopupContainer from '../../../components/PopupContainer';
import SimplePopup from '../../../components/SimplePopup';

import {adminImages, loginImages, commonImages} from '../../../assets/images';
import moment from 'moment';
import ViewRenderer from './views';
import DeptMaterialPopup from '../../../components/DeptMaterialPopup';
import AgencyRquestsPopup from '../../../components/AgencyRquestsPopup';
import AgencyPersonDetails from '../../../components/AgencyPersonDetails';
import AgencyMaterialDetails from '../../../components/AgencyMaterialDetails';

import {API} from '../../../requests';
import strings from '../../../globalization';
import Spinner from '../../../components/Spinner';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Flex from '../../../components/Flex';
import util from '../../../util';
import {AgGridReact} from 'ag-grid-react';
import dimensions from '../../../util/dimensions-util';
import './styles.css';
import {customStyles} from './customStyles';

import Select from 'react-select';
import {dailyMonitoringProgress, entrySummary, rejectedReports} from './data';

const {vw: widthConstant, vh: heightConstant} = dimensions;

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  ERROR: 'error',
  SPINNER_POPUP: 'spinner-popup',
  PERMIT_DETAILS: 'permitDetails',
};

const STATUSES = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  ALL: 'ALL',
};

class DailyProgressMonitoring extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dailyProgressList: [],
      filteredDailyProgressList: [],
      startDate: moment().subtract(9, 'days'),

      endDate: moment().add(1, 'day'),

      dates: {
        start: moment().subtract(9, 'days').format('MM-DD-YYYY'),
        end: moment().add(1, 'day').format('MM-DD-YYYY'),
      },
      filterAgencies: [],
      searchText: '',
      statuses: [
        {
          id: 1,
          name: STATUSES.APPROVED,
          label: STATUSES.APPROVED,
        },
        {
          id: 2,
          name: STATUSES.REJECTED,
          label: STATUSES.REJECTED,
        },
        {
          id: 0,
          name: STATUSES.ALL,
          label: STATUSES.ALL,
        },
      ],
      CHAList: [
        {
          id: 0,
          name: `CHA 1`,
          label: `CHA 1`,
        },
        {
          id: 1,
          name: `CHA 2`,
          label: `CHA 2`,
        },
        {
          id: 2,
          name: `CHA 3`,
          label: `CHA 3`,
        },
      ],
      selectedCHA: '',
    };
  }

  componentDidMount() {
    this.getData();
  }

  applyFilters = () => {
    const {searchText, dailyProgressList} = this.state;
    const finalFilterData =
      dailyProgressList.filter(element =>
        Object.values(element)
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()),
      ) || dailyProgressList;
    this.setState({
      filteredDailyProgressList: finalFilterData,
    });
  };

  getData = async () => {
    this.showSpinner();
    const {startDate, endDate} = this.state;

    if (!startDate || !endDate) {
      this.showErrorPopup(`Date parameters cannot be empty!`);
      return;
    }

    let params = {
      startDate: moment(startDate).format('DD-MM-YYYY'),
      endDate: moment(endDate).format('DD-MM-YYYY'),
    };
    console.log('params...', params);
    API.fetchDailyMonitoring(params)
      .then(res => {
        this.setState({
          dailyProgressList: res.data,
          filteredDailyProgressList: res.data,
          popup: undefined,
        });
      })
      .catch(err => {
        console.log('err', err);
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };
  showErrorPopup = errorMsg => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message:
          errorMsg || `Failed to fetch data. Please refresh and try again.`,
        messageStyle: {color: '#E67717'},

        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
          },
        ],
        onClose: this.closePopup,
      },
    });
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  onChangeHandler = (action, data) => {
    switch (action) {
      case 'status': {
        this.setState({
          selectedStatus: data,
        });
        break;
      }
      case 'CHA': {
        this.setState({
          selectedCHA: data,
        });
        console.log('cha...', data);
        break;
      }
      case 'startTime': {
        this.setState({
          startTime: data,
        });
        break;
      }
      case 'endTime': {
        this.setState({
          endTime: data,
        });
        break;
      }

      case 'date': {
        const {startDate, endDate, dates} = data;
        this.setState({
          startDate,
          endDate,
          dates,
        });
        break;
      }

      case 'search': {
        console.info('data..', data);
        this.setState({
          searchText: data,
        });

        break;
      }

      default: {
        console.info('case not handled', action);
      }
    }
  };

  onClickHandler = (action, data) => {
    const {history, location} = this.props;

    switch (action) {
      case 'apply': {
        this.getData();
        break;
      }
      case 'clearFilters': {
        this.resetData();
        break;
      }

      case 'rowClick': {
        console.info('rowClick called...', data);

        break;
      }
      case 'document-view': {
        console.log('in document view redirection case');
        history.push({
          pathname: location.pathname + '/document-view',
          //   pathname: location.pathname + '/add-new-person',
          state: {background: location, url: data},
        });

        break;
      }
      default: {
        console.info('case not handled', action);
      }
    }
  };

  resetData = () => {
    this.setState(
      {
        startDate: moment().subtract(1, 'month'),
        endDate: moment().add(1, 'day'),
        dates: {
          start: moment().subtract(1, 'month').format('MM-DD-YYYY'),
          end: moment().add(1, 'day').format('MM-DD-YYYY'),
        },
      },
      this.getData,
    );
  };

  getCSVDownloadHeaders = () => {
    return [
      {label: 'Date', key: 'date'},
      {label: 'permitRequests', key: 'permit'},
      {label: 'Agency Registration Counts', key: 'company'},
      {label: 'Driver Registration Counts', key: 'person'},
      {label: 'Vehicle Registration Counts', key: 'vehicle'},
    ];
  };

  getCSVDownloadData = () => {
    const {filteredDailyProgressList} = this.state;
    return filteredDailyProgressList.map(el => {
      return {
        date: moment(el.date).format('MMMM Do YYYY'),
        permit: el.permit,
        company: el.company,
        person: el.person,
        vehicle: el.vehicle,
      };
    });
  };

  getProps = () => {
    const {
      searchText,
      selectedAgency,
      selectedStatus,
      startDate,
      endDate,
      dates,
      startTime,
      endTime,
      dailyProgressList,
      statuses,
      CHAList,
      selectedCHA,
      filteredDailyProgressList,
    } = this.state;
    return {
      onClick: this.onClickHandler,
      onChange: this.onChangeHandler,
      searchText,
      selectedAgency,
      selectedStatus,
      startDate,
      endDate,
      dates,
      startTime,
      endTime,
      dailyProgressList: filteredDailyProgressList,
      statuses,
      CHAList,
      selectedCHA,
      downloadData: this.getCSVDownloadData(),
      downloadHeaders: this.getCSVDownloadHeaders(),
    };
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };

  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <SimplePopup {...popup} />;
      }
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SPINNER: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

// export default withRouter(PersonCards);
export default withRouter(DailyProgressMonitoring);
