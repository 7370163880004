import React, {useState} from 'react';
import styles from './styles.module.scss';

import dimensions from '../../../../../util/dimensions-util';
import Flex from '../../../../../components/Flex';
import {formLeftFields, formRightFields} from '../../formFields';
import FormElement from '../../../../../components/FormElement';
import {API} from '../../../../../requests';
import {operatorImages} from '../../../../../assets/images';

const {vw: widthConstant, vh: heightConstant} = dimensions;

export default function Desktop({
  buttons,
  header,
  buttonContainer,
  onChangeHandler,
  onBlur,
  stateData,
  fileURLCallback,
  readOnlyFields,
  photo,
}) {
  console.log('stateData#####', stateData);
  const [userPhoto, setPhoto] = useState();

  const handleUpload = (field, files) => {
    if (files === null) {
      throw new Error('File does not exist');
    }

    let filesArray = [];
    for (let i = 0, numFiles = files.length; i < numFiles; i++) {
      filesArray.push(files[i]);
    }
    let promises = filesArray.map(file => {
      const data = new FormData();
      data.append('s3FolderName', 'company');
      data.append('file', file);
      data.append('allowedExtensions', ['JakNVgcBVZUPG', 'JPw7fCgDTT6AEG', 'PCrAriI079CNG']);
      return API.uploadFilesToDpt(data).then(response => {
        const url = response.data?.fileUrl;
        return {fileUrl: url, fileName: file.name};
      });
    });

    return Promise.all(promises)
      .then(response => {
        fileURLCallback(response[0].fileUrl, null);
        setPhoto(response[0].fileUrl);
      })
      .catch(error => {       
        console.log('error', error);
        const errorMsg = error.response
        ? error.response.data.message
        : error.message;
        fileURLCallback(null, errorMsg);
        return null;
        throw new Error(errorMsg);
      });
  };
  // Just for photo
  const checkDisabled = stateField => {
    const {mode} = stateData;
    if (mode === 'edit') {
      return readOnlyFields.includes(stateField);
    }
  };

  const getUserImage = () => {
    const {photo, preSignedPhoto} = stateData;

    if (preSignedPhoto?.value) {
      return preSignedPhoto.value;
    } else {
      return operatorImages.userIcon;
    }
    // stateData.photo
    //                   ? stateData.photo.value
    //                   : operatorImages.userIcon
  };
  return (
    <Flex className={styles.mainContainer}>
      {/* Hi console.log('stateData - locations', stateData); return ( */}
      <div className={[styles.container]}>
        <div className={styles.heading}> {header}</div>
        <div className={styles.bodyContainer}>
          <div className={[styles.gridOfThree, styles.divider].join(' ')}>
            <div>
              {formLeftFields.map((elem, idx) => {
                console.log('stateData', stateData);
                return (
                  <div
                    key={`formLeftFields-${idx}`}
                    className={[styles.divider, styles.formFields].join(' ')}>
                    <FormElement
                      type={elem.elementType}
                      label={elem.label}
                      required={elem.required}
                      placeholderText={elem.placeholder}
                      onChange={text => onChangeHandler(elem.stateField, text)}
                      tabIndex={idx * 2 + 1}
                      error={stateData[elem.stateField].error}
                      options={stateData[elem.optionsField]}
                      value={stateData[elem.stateField].value}
                      key={idx}
                      maxLength={elem.maxLength}
                      keyboardType={elem.keyboardType}
                      containerStyle={{marginBotton: '40px'}}
                      maxFiles={elem.maxFiles}
                      onBlur={() => onBlur(elem.stateField, elem)}
                      allowedFormats={elem.allowedFormats}
                      minimumDate={elem.minimumDate}
                      isVerifyOtp={elem.verifyOtp}
                      fieldName={elem.stateField}
                      containerClassNames={styles.containerClassNames}
                      labelClassNames={styles.labelClassNames}
                      disabled={checkDisabled(elem.stateField)}
                    />
                  </div>
                );
              })}
            </div>
            <div>
              {formRightFields.map((elem, idx) => {
                return (
                  <div
                    key={`formLeftFields-${idx}`}
                    className={[styles.divider, styles.formFields].join(' ')}>
                    <FormElement
                      type={elem.elementType}
                      label={elem.label}
                      required={elem.required}
                      placeholderText={elem.placeholder}
                      onChange={text => onChangeHandler(elem.stateField, text)}
                      tabIndex={idx * 2 + 1}
                      error={stateData[elem.stateField].error}
                      options={stateData[elem.optionsField]}
                      value={stateData[elem.stateField].value}
                      key={idx}
                      maxLength={elem.maxLength}
                      keyboardType={elem.keyboardType}
                      // containerStyle={{marginBotton: 40 * vh}}
                      maxFiles={elem.maxFiles}
                      onBlur={() => onBlur(elem.stateField, elem)}
                      allowedFormats={elem.allowedFormats}
                      minimumDate={elem.minimumDate}
                      fieldName={elem.stateField}
                      containerClassNames={styles.containerClassNames}
                      labelClassNames={styles.labelClassNames}
                      disabled={checkDisabled(elem.stateField)}
                    />
                  </div>
                );
              })}
            </div>
            <div className={styles.imageWrapper}>
              <div className={styles.imageSquare}>
                <img
                  src={getUserImage()}
                  // src={operatorImages.userIcon}
                  alt="X"
                  className={styles.userIcon}
                />
              </div>
              <FormElement
                type={'file'}
                placeholderText={'upload photo'}
                uploadBoxClassNames={styles.uploadBoxClassNames}
                tabIndex={1 * 2 + 1}
                handleUpload={file => {
                  handleUpload('photo', file);
                }}
                value={1}
                key={1}
                allowedFormats={['images']}
              />
              <p className={styles.imageError}>
                {stateData.photo ? stateData.photo.error : null}
              </p>
            </div>
          </div>
        </div>

        {buttons && buttons.length > 0 ? (
          <div className={[styles.buttonContainer, buttonContainer].join(' ')}>
            {buttons.map(button => {
              const {text, buttonStyles, ...remainingProps} = button;
              return (
                <button
                  className={[styles.button, buttonStyles].join(' ')}
                  key={'popup-button-' + text}
                  {...remainingProps}>
                  {text}
                </button>
              );
            })}
          </div>
        ) : null}
      </div>
    </Flex>
  );
}
