import React from 'react';
import pendingIcon from '../../../assets/images/pending-icon/image.png';

import styles from './styles.module.scss';
import strings from '../../../globalization';
import Button from '../../Button';
import Flex from '../../Flex';
import bgIcon from '../../../assets/images/placeholder-person/image.png';
import truckIcon from '../../../assets/images/truck-icon-blue/image@3x.png';
import documentIcon from '../../../assets/images/document-icon/image.png';
import moment from 'moment';

const ViewVehicle = ({vehicle, onClose, viewDocument}) => {
  console.log('vehicle', vehicle);
  return (
    <Flex direction="column" className={styles.container}>
      <Header />
      <Flex direction="row" className={styles.detailsContainer}>
        <img src={truckIcon} alt="U" className={styles.personImage} />
        <Flex direction="column" className={styles.detailsColumn}>
          <LabelValue label="Owner Name" value={vehicle.name} />
          <LabelValue label="Owner Phone Number" value={vehicle.phoneNumber} />
          <LabelValue
            label="Owner Email Address"
            value={vehicle.emailAddress}
          />

          <LabelValue
            label="Vehicle Engine Number"
            value={vehicle.vehicleEngineNumber}
          />
          <LabelValue
            label="Vehicle Insurance Number"
            value={vehicle.vehicleInsuranceNumber}
          />
          <LabelValue
            label="Vehicle Pollution Number"
            value={vehicle.vehiclePollutionNumber}
          />
          <LabelValue label="Vehicle Type" value={vehicle.vehicleType.name} />
          <LabelValue
            label="Vehicle Make and Model"
            value={vehicle.vehicleMakeAndModel}
          />
          <LabelValue
            label="Vehicle Manufacturing Year"
            value={vehicle.vehicleManufacturingYear}
          />
        </Flex>
        <Flex direction="column" className={styles.detailsColumn}>
          <LabelValue label="RFID Card Number" value={vehicle.rfidnumber} />
          <LabelValue
            label="Vehicle Registration Number"
            value={vehicle.vehicleRegistrationNumber}
          />
          <LabelValue label="Reference ID" value={vehicle.referenceId} />
          <LabelValue
            label="Vehicle Chassis Number"
            value={vehicle.chassisNumber}
          />
          <DocumentView
            viewDocument={viewDocument}
            label={`RC Document`}
            documentUrl={vehicle.rcDocument}
          />
          <DocumentView
            viewDocument={viewDocument}
            label={`Pollution Certificate`}
            documentUrl={vehicle.puc}
          />
          <DocumentView
            viewDocument={viewDocument}
            label={`Fitness Certificate`}
            documentUrl={vehicle.fitnessCertificate}
          />
          <DocumentView
            viewDocument={viewDocument}
            label={`Vehicle Insurance Certificate`}
            documentUrl={vehicle.vehicleInsuranceCertificate}
          />
        </Flex>
      </Flex>
      <button onClick={onClose} className={styles.goBack}>
        Go Back
      </button>
    </Flex>
  );
};

const Header = () => {
  return (
    <Flex className={styles.header}>
      <p>{'Vehicle Details'}</p>
    </Flex>
  );
};

const LabelValue = ({label, value}) => {
  return (
    <Flex direction="row" className={styles.labelValueContainer}>
      <p className={styles.label}>{label}: &nbsp;</p>
      <p className={styles.value}> {value}</p>
    </Flex>
  );
};

const DocumentView = ({viewDocument, label, documentUrl}) => {
  return (
    <Flex
      direction="row"
      className={styles.labelValueContainer}
      style={{alignItems: 'center'}}>
      <p className={styles.label}>Document: &nbsp;</p>
      <p className={styles.value} style={{marginRight: '0.4vw'}}>
        {' '}
        {label}
      </p>
      <img
        onClick={() => viewDocument(documentUrl)}
        src={documentIcon}
        alt="U"
        className={styles.documentIcon}
      />
    </Flex>
  );
};

export default ViewVehicle;
