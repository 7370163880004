import moment from 'moment';
import strings from '../../../globalization';

const formLeftFields = [
  {
    elementType: 'text',
    inputType: 'text',
    label: `${strings.operatorName}`,
    placeholder: `${strings.operatorName}`,
    required: true,
    stateField: 'operatorName',
    stateDefault: null,
    maxLength: 20,
  },
  {
    feild: 'emailId',
    elementType: 'text',
    inputType: 'email',
    label: `${strings.emailId}`,
    placeholder: `${strings.emailAddressPlaceholder}`,
    required: true,
    stateField: 'emailAddress',
    stateDefault: '',
    maxLength: 128,
  },
  {
    elementType: 'phone',
    inputType: 'phone',
    label: strings.mobileNumber,
    placeholder: strings.phoneNumber,
    required: true,
    stateField: 'phoneNumber',
    stateDefault: '',
    maxLength: 10,
  },
  {
    field: 'address',
    label: `${strings.address}`,
    placeholder: `${strings.enterAddress}`,
    required: true,
    inputType: 'text',
    elementType: 'text',
    stateField: 'address',
    stateDefault: null,
    minLength: 5,
    maxLength: 200,
  },

  {
    elementType: 'text',
    type: 'text',
    label: `${strings.loginId}`,
    placeholder: `${strings.loginId}`,
    required: true,
    inputType: 'text',
    stateField: 'loginId',
    stateDefault: null,
    maxLength: 128,
  },
  {
    elementType: 'dropdown',
    inputType: 'text',
    label: strings.location,
    placeholder: strings.location,
    optionsField: 'locationsOptions',
    required: true,
    stateField: 'location',
    stateDefault: null,
    maxLength: 20,
  },
];
const formRightFields = [
  {
    elementType: 'text',
    inputType: 'text',
    label: `${strings.operatorId}`,
    placeholder: `${strings.operatorId}`,
    required: true,
    stateField: 'operatorId',
    stateDefault: null,
    minLength: 6,
    maxLength: 128,
  },
  {
    elementType: 'dropdown',
    inputType: 'text',
    label: 'Gender',
    placeholder: 'Select gender',
    optionsField: 'genderOptions',
    required: true,
    stateField: 'gender',
    stateDefault: null,
    maxLength: 20,
  },
  {
    label: `${strings.aadharNumber}`,
    placeholder: `${strings.aadharNumber}`,
    inputType: 'number',
    required: true,
    elementType: 'text',
    stateField: 'aadharNumber',
    stateDefault: null,
    maxLength: 12,
    minLength: 12,
  },
  {
    elementType: 'text',
    label: strings.pincode,
    placeholder: `${strings.pincode}`,
    required: true,
    inputType: 'pincode',
    stateField: 'pincode',
    stateDefault: null,
    maxLength: 20,
  },

  {
    elementType: 'dropdown',
    inputType: 'text',
    label: strings.role,
    placeholder: strings.role,
    optionsField: 'roleOptions',
    required: true,
    stateField: 'role',
    stateDefault: null,
    maxLength: 20,
  },

  {
    stateField: 'shift',
    elementType: 'dropdown',
    inputType: 'text',
    label: `${strings.shift}`,
    placeholder: `Select Shift`,
    required: true,
    optionsField: 'shiftOptions',
    stateDefault: null,
    maxLength: 20,
  },
];

export {formLeftFields, formRightFields};
