import React from 'react';
import styles from './styles.module.scss';
import {loginImages} from '../../assets/images';
import strings from '../../globalization';
import Flex from '../Flex';

function RegistrationFormCard(props) {
  const {
    header,
    showBack,
    showTerms,
    termsClassNames,
    buttonLabel = 'Submit Request',
    onClick,
    onChange,
    chekboxName,
    error,
    nextButton,
    backClick,
    submitButtonClass,
    termsClick,
    terms,
  } = props;
  return (
    <div className={styles.rightContainer}>
      {showBack ? (
        <Flex onClick={backClick} className={styles.backlabel}>
          <img
            className={styles.backButtonIcon}
            src={loginImages.backButtonIcon}
            alt="back"
          />
          <span>{strings.back}</span>
        </Flex>
      ) : // </button>
      null}
      <div className={styles.formContainer}>
        <div className={styles.registrationlabel}>{header}</div>
        {/* {!showTerms ? (
          <div className={[styles.topHeader]}>
            <span>{strings.agencyDetails}</span>
            <span>{strings.step1}</span>
          </div>
        ) : null} */}
        <div className={styles.form}>{props.children}</div>
        {showTerms ? (
          <div className={[styles.tcWrapper, termsClassNames].join(' ')}>
            {/* <img className={styles.checkBoxIcon} src={loginImages.checkBoxIcon} alt="" /> */}
            <input
              className={styles.checkBoxIcon}
              type="checkbox"
              name={chekboxName}
              onChange={e => {
                onChange(e);
              }}
              checked={terms}
            />
            <span className={styles.acceptLabel}>
              {strings.acceptAll}
              <span onClick={termsClick} className={styles.tcLabel}>
                {strings.termsAndConditions}
              </span>
            </span>
          </div>
        ) : null}

        {error ? (
          <div
            style={{color: 'red', textAlign: 'center', marginBottom: '1rem'}}>
            {error}
          </div>
        ) : null}

        {nextButton ? (
          <div style={{display: 'flex', justifyContent: 'center'}}>
            {nextButton()}
          </div>
        ) : (
          <button
            onClick={event => onClick('personRegistration', event)}
            type="button"
            buttonStyle="primary"
            disabled={!terms}
            className={[styles.submitButton, submitButtonClass].join(' ')}>
            {buttonLabel}
          </button>
        )}
      </div>
    </div>
  );
}

export default RegistrationFormCard;
