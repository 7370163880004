import React, {Component} from 'react';
import PopupContainer from '../../../components/PopupContainer';
import moment from 'moment';

import ViewRenderer from './views';
import AgencyRquestsPopup from '../../../components/AgencyRquestsPopup';
import {API} from '../../../requests';
import {rowData} from './formData';

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  SPINNER: 'spinner',
  ERROR: 'error',
};
export default class Attendence extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attendence: rowData,
      filterAttendence: rowData,
      status: undefined,
      startDate: moment(),
      endDate: moment(),
      selectedStatus: '',
      selectedShift: '',
      searchText: '',
      statuses: [
        {
          id: 'present',
          name: 'Present',
        },
        {
          id: 'onLeave',
          name: 'On Leave',
        },
      ],
    };
  }

  getData = async () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
    const {startDate, endDate} = this.state;
    let startDateStr = startDate.format('MM-DD-YYYY');
    let endDateStr = endDate.format('MM-DD-YYYY');
    await API.getAllAgencyDetails(startDateStr, endDateStr)
      .then(response => {
        this.setState({
          agencies: response.data,
          popup: undefined,
        });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        console.info('error....', errorMsg);
        this.setState({
          popup: undefined,
        });
        // this.setState({
        //   popup: {
        //     type: POPUP_CONSTANTS.SIMPLE_POPUP,
        //     message: errorMsg,
        //     messageStyle: { color: "#E67717" },
        //     buttons: [
        //       {
        //         title: "Okay!",
        //         onPress: this.closePopup,
        //       },
        //     ],
        //   },
        // });
      });
  };

  componentDidMount() {
    this.getData();
  }

  /**
   *
   * @param {*} action
   * @param {*} data
   */

  onClickHandler = (action, data) => {
    const {history} = this.props;
    switch (action) {
      case 'signin': {
        history.push('/sign-in');
        break;
      }
      case 'vehicle-registration': {
        history.push('/vehicle-registration');
        break;
      }
      case 'apply': {
        this.getData();
        break;
      }
      default: {
        console.info('case not handled', action);
      }
    }
  };

  filterChangeHandler = (action, data) => {
    switch (action) {
      case 'status': {
        const {attendence} = this.state;
        console.info('data..', data);
        const filterAttendence = attendence.filter(
          operator => operator.attendance === data,
        );
        this.setState({
          filterAttendence,
          selectedStatus: data,
        });
        break;
      }
      case 'shift': {
        const {operators} = this.state;
        const filterOperators = operators.filter(
          operator => operator.location.id === data,
        );
        this.setState({
          filterOperators,
          selectedLocation: data,
        });
        break;
      }

      case 'search': {
        console.info('data..', data);
        // const { locations } = this.state;
        // const filterLocations = locations.filter((location) => location.name.toLowerCase().includes(data.toLowerCase()));
        this.setState({
          searchText: data,
        });
        break;
      }
      case 'clearFilter': {
        const {attendence} = this.state;
        console.info('clearFilter...', attendence);
        this.setState({
          selectedShift: '',
          searchText: '',
          selectedStatus: '',
          filterAttendence: attendence,
        });
        break;
      }
      default: {
        console.info('filterChangeHandler case not handled', action);
      }
    }
  };
  getProps = () => {
    const {
      filterAttendence,
      statuses,
      selectedStatus,
      selectedShift,
      searchText,
    } = this.state;
    return {
      filterAttendence,
      onClick: this.onClickHandler,
      filterChangeHandler: this.filterChangeHandler,
      statuses,
      selectedStatus,
      selectedShift,
      searchText,
    };
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };

  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      // case "spinner": {
      //   return <Spinner />;
      // }
      case POPUP_CONSTANTS.STANDARD: {
        return <AgencyRquestsPopup {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}
