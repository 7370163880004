import React, {useState} from 'react';
import styles from './Mobile.module.scss';
import AuthLayout from '../../../../../components/AuthLayout';
import {
  formLeftFields,
  formRightFields,
  getSignatoryConfig,
} from '../../formFeilds';
import {withRouter} from 'react-router-dom';
import RegistrationFormCard from '../../../../../components/RegistrationFormCard';
import strings from '../../../../../globalization/index';
import FormElement from '../../../../../components/FormElement';
import Flex from '../../../../../components/Flex';

const registrationSteps = {
  STEP_1: '1',
  STEP_2: '2',
  TOTAL: '2',
};

const Mobile = props => {
  const {
    getInputForSignatory,
    history,
    stateData,
    onChangeHandler,
    onBlur,
    onClick,
    handleSubmit,
    onChange,
    currentStep,
    errorMessage,
    handelNextButton,
    handleUpload,
    handlePrevButton,
    termsClick,
    triggerOTP,
    otpVerified,
    otpVerifiedField,
  } = props;
  const [page2Config, setPage2Config] = useState(getSignatoryConfig());
  // console.info("page2Config", page2Config);

  return (
    <>
      {currentStep === registrationSteps.STEP_1 ? (
        <RegistrationFormCard
          showTerms={currentStep === registrationSteps.STEP_2}
          showBack={true}
          backClick={() => history.push('/signin')}
          header={strings.newAgencyRegistration}
          onClick={onClick}
          onChange={event => {
            onChange('terms', event.target.checked);
          }}
          error={errorMessage}
          nextButton={() => (
            <button
              className={styles.nextButtonStyle}
              onClick={() => handelNextButton()}>
              {strings.next}
            </button>
          )}>
          <Flex direction="column" className={styles.page1Container}>
            <div className={[styles.topHeader]}>
              <span>{strings.agencyDetails}</span>
              <span>{strings.step1}</span>
            </div>

            <div className={[styles.gridOfTwo, styles.divider].join(' ')}>
              <div>
                {formLeftFields.map((elem, idx) => {
                  return (
                    <div
                      key={`formLeftFields-${idx}`}
                      className={[styles.divider, styles.formFields].join(' ')}>
                      <FormElement
                        type={elem.elementType}
                        label={elem.label}
                        required={elem.required}
                        placeholderText={elem.placeholder}
                        onChange={text =>
                          onChangeHandler(elem.stateField, text)
                        }
                        tabIndex={idx * 2 + 1}
                        error={stateData[elem.stateField].error}
                        options={stateData[elem.stateField].optionsField}
                        value={stateData[elem.stateField].value}
                        key={idx}
                        maxLength={elem.maxLength}
                        keyboardType={elem.keyboardType}
                        containerStyle={{marginBotton: '40px'}}
                        handleUpload={(file, error) => {
                          handleUpload(elem.stateField, file, error);
                        }}
                        maxFiles={elem.maxFiles}
                        onBlur={() => onBlur(elem.stateField, elem)}
                        allowedFormats={elem.allowedFormats}
                        minimumDate={elem.minimumDate}
                        triggerOTP={() => onClick('otp-verify', elem)}
                        otpVerified={otpVerified}
                        otpVerifiedField={otpVerifiedField}
                        isVerifyOtp={elem.verifyOtp}
                        fieldName={elem.stateField}
                      />
                    </div>
                  );
                })}
              </div>
              <div>
                {formRightFields.map((elem, idx) => {
                  return (
                    <div
                      key={`formLeftFields-${idx}`}
                      className={[styles.divider, styles.formFields].join(' ')}>
                      <FormElement
                        type={elem.elementType}
                        label={elem.label}
                        required={elem.required}
                        placeholderText={elem.placeholder}
                        onChange={text =>
                          onChangeHandler(elem.stateField, text)
                        }
                        tabIndex={idx * 2 + 1}
                        error={stateData[elem.stateField].error}
                        options={stateData[elem.stateField].optionsField}
                        value={stateData[elem.stateField].value}
                        key={idx}
                        maxLength={elem.maxLength}
                        keyboardType={elem.keyboardType}
                        // containerStyle={{marginBotton: 40 * vh}}
                        handleUpload={(file, error) => {
                          handleUpload(elem.stateField, file, error);
                        }}
                        maxFiles={elem.maxFiles}
                        onBlur={() => onBlur(elem.stateField, elem)}
                        allowedFormats={elem.allowedFormats}
                        minimumDate={elem.minimumDate}
                        triggerOTP={() => onClick('otp-verify', elem)}
                        otpVerified={otpVerified}
                        otpVerifiedField={otpVerifiedField}
                        isVerifyOtp={elem.verifyOtp}
                        fieldName={elem.stateField}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </Flex>
        </RegistrationFormCard>
      ) : (
        <RegistrationFormCard
          showTerms={true}
          showBack={true}
          header={strings.newAgencyRegistration}
          onClick={handleSubmit}
          onChange={event => {
            onChange('terms', event.target.checked);
          }}
          backClick={() => handlePrevButton()}
          error={errorMessage}
          termsClick={termsClick}>
          <div className={styles.signatoryContainer}>
            <div className={styles.topHeader}>
              <span>{strings.authorizedUsers}</span>
              <span>{strings.step2}</span>
            </div>
            {page2Config.map((signatoryFields, idx) => (
              <Flex direction="column" key={`signatory-form-group-${idx}`}>
                <div className={styles.header}>
                  <span>{signatoryFields.title}</span>
                </div>
                <div
                  key={`formLeftFields-${idx}`}
                  // className={styles.formFields}
                  className={[styles.signatorySection, styles.formFields].join(
                    ' ',
                  )}>
                  {signatoryFields.formDetails.map((elem, idx) => {
                    return (
                      <FormElement
                        type={elem.elementType}
                        label={elem.label}
                        required={elem.required}
                        placeholderText={elem.placeholder}
                        onChange={text =>
                          onChangeHandler(elem.stateField, text)
                        }
                        tabIndex={idx * 2 + 1}
                        error={stateData[elem.stateField].error}
                        // options={stateData[elem.optionsField]}
                        value={stateData[elem.stateField].value}
                        key={idx}
                        maxLength={elem.maxLength}
                        keyboardType={elem.keyboardType}
                        // containerStyle={{marginBotton: 40 * vh}}
                        handleUpload={(file, error) => {
                          handleUpload(elem.stateField, file, error);
                        }}
                        maxFiles={elem.maxFiles}
                        onBlur={() => onBlur(elem.stateField, elem)}
                        allowedFormats={elem.allowedFormats}
                        triggerOTP={() => onClick('otp-verify', elem)}
                        otpVerified={otpVerified}
                        otpVerifiedField={otpVerifiedField}
                        isVerifyOtp={elem.verifyOtp}
                        fieldName={elem.stateField}
                      />
                    );
                  })}
                </div>
              </Flex>
            ))}
          </div>
        </RegistrationFormCard>
      )}
    </>
  );
};
export default withRouter(Mobile);
