import strings from '../../../globalization';

export const getPersonFormData = () => {
  return [
    {
      elementType: 'text',
      inputType: 'alphabets',
      label: strings.name,
      placeholder: strings.enterName,
      stateField: 'name',
      id: 'name',
      stateDefault: '',
      minLength: 5,
      maxLength: 128,
    },
    {
      inputType: 'phone',
      elementType: 'phone',
      keyboardType: 'number-pad',
      label: strings.phoneNumber,
      placeholder: strings.enterPhoneNumber,
      stateField: 'phoneNumber',
      id: 'phoneNumber',
      stateDefault: '',
      maxLength: 10,
      // verifyOtp: true,
    },
    {
      elementType: 'text',
      keyboardType: 'default',
      inputType: 'email',
      label: strings.emailId,
      placeholder: strings.enterEmailAddress,
      stateField: 'emailAddress',
      id: 'emailAddress',
      stateDefault: '',
      maxLength: 50,
    },
    {
      elementType: 'dropdown',
      label: strings.gender,
      placeholder: strings.selectGender,
      optionsField: 'genderOptions',
      stateField: 'genderId',
      id: 'genderId',
      stateDefault: null,
      maxLength: 20,
    },
    {
      elementType: 'date',
      keyboardType: 'default',
      inputType: 'date',
      label: strings.dateOfBirth,
      placeholder: strings.selectDate,
      stateField: 'dob',
      id: 'dob',
      stateDefault: null,
      maxLength: 20,
    },

    {
      elementType: 'dropdown',
      label: strings.personTypeField,
      placeholder: strings.personTypePlaceholder,
      optionsField: 'personTypeOptions',
      stateField: 'typeId',
      id: 'typeId',
      stateDefault: null,
      maxLength: 20,
    },
    {
      elementType: 'text',
      inputType: 'alphabets',
      label: strings.aadharNumber,
      placeholder: 'Aadhaar Number not found',
      stateField: 'aadhaarNumber',
      id: 'aadhaarNumber',
      stateDefault: '',
      minLength: 5,
      maxLength: 128,
    },
    {
      elementType: 'text',
      keyboardType: 'default',
      inputType: 'text',
      label: strings.aadharNumberDocumentField,
      placeholder: 'Aadhaar document not found',
      stateField: 'aadhaarDocument',
      id: 'aadhaarDocument',
      fileIcon: 'aadhaarDocument',
      stateDefault: '',
      maxLength: 50,
    },
    {
      elementType: 'text',
      inputType: 'alphabets',
      label: 'Driving Licence Number',
      placeholder: 'Driving Licence Not Found',
      stateField: 'drivingLicenceNumber',
      id: 'drivingLicenceNumber',
      stateDefault: '',
      minLength: 5,
      maxLength: 128,
    },
    {
      elementType: 'text',
      keyboardType: 'default',
      inputType: 'text',
      label: 'Driving Licence Document',
      placeholder: 'Driving Licence Not Found',
      stateField: '',
      id: 'drivingLicenceDocument',
      fileIcon: 'drivingLicenceDocument',
      stateDefault: '',
      maxLength: 50,
    },
    {
      elementType: 'text',
      inputType: 'alphabets',
      label: 'Company',
      placeholder: 'Company Name not entered',
      stateField: 'company',
      id: 'company',
      stateDefault: '',
      minLength: 5,
      maxLength: 128,
    },
  ];
};

export const getVehiclesFormData = () => {
  return [
    {
      elementType: 'text',
      inputType: 'alphabets',
      label: strings.vehicleOwnerName,
      placeholder: strings.vehicleOwnerName,
      stateField: 'name',
      id: 'name',
      stateDefault: '',
      minLength: 5,
      maxLength: 128,
      disabled: true,
    },
    {
      inputType: 'phone',
      elementType: 'phone',
      keyboardType: 'number-pad',
      label: strings.vehicleOwnerPhoneNumber,
      placeholder: 'Enter Owner Mobile number',
      stateField: 'phoneNumber',
      id: 'phoneNumber',
      stateDefault: '',
      maxLength: 10,
      disabled: true,
    },
    {
      elementType: 'text',
      keyboardType: 'default',
      inputType: 'email',
      label: strings.vehicleOwnerEmail,
      placeholder: 'Enter Owner person email',
      stateField: 'emailAddress',
      id: 'emailAddress',
      stateDefault: '',
      maxLength: 50,
      disabled: true,
    },
    {
      elementType: 'text',
      inputType: 'text',
      label: strings.vehicleNumber,
      placeholder: 'Vehicle Number',
      stateField: 'vehicleRegistrationNumber',
      id: 'vehicleRegistrationNumber',
      stateDefault: '',
      minLength: 5,
      maxLength: 128,
      disabled: true,
    },
    {
      elementType: 'text',
      inputType: 'text',
      label: 'Vehicle Type',
      placeholder: strings.vehicleType,
      stateField: 'vehicleType',
      id: 'vehicleType',
      stateDefault: '',
      minLength: 5,
      maxLength: 128,
      disabled: true,
    },
    {
      elementType: 'text',
      inputType: 'text',
      label: strings.vehicleChassisNumber,
      placeholder: 'Enter Chassis Number',
      stateField: 'chassisNumber',
      id: 'chassisNumber',
      stateDefault: '',
      minLength: 5,
      maxLength: 128,
      disabled: true,
    },
    {
      elementType: 'text',
      inputType: 'text',
      label: strings.vehicleEngineNumber,
      placeholder: 'Enter Vehicle Engine Number',
      stateField: 'vehicleEngineNumber',
      id: 'vehicleEngineNumber',
      stateDefault: '',
      minLength: 5,
      maxLength: 128,
      disabled: true,
    },
    {
      elementType: 'text',
      inputType: 'text',
      label: strings.vehicleRegtNumber,
      placeholder: 'Enter Vehicle RC Number',
      stateField: 'vehicleRegistrationNumber',
      id: 'vehicleRegistrationNumber',
      stateDefault: '',
      minLength: 5,
      maxLength: 128,
      fileIcon: 'rcDocument',
      disabled: true,
    },
    {
      elementType: 'text',
      inputType: 'text',
      label: strings.vehicleInsuranceCertificate,
      placeholder: 'Enter Vehicle Insurance Number',
      stateField: 'vehicleInsuranceNumber',
      id: 'vehicleInsuranceNumber',
      stateDefault: '',
      minLength: 5,
      maxLength: 128,
      fileIcon: 'vehicleInsuranceCertificate',
      disabled: true,
    },
    {
      elementType: 'text',
      inputType: 'text',
      label: strings.pUCCertificate,
      placeholder: 'Enter PUC Certificate',
      stateField: 'vehiclePollutionNumber',
      id: 'vehiclePollutionNumber',
      stateDefault: '',
      minLength: 5,
      maxLength: 128,
      fileIcon: 'puc',
      disabled: true,
    },
    {
      elementType: 'text',
      inputType: 'text',
      label: strings.fitnessCertificate,
      placeholder: 'Fitness Certificate Not Uploaded',
      stateField: 'fitnessCertificate',
      id: 'fitnessCertificate',
      stateDefault: '',
      minLength: 5,
      maxLength: 128,
      fileIcon: 'fitnessCertificate',
      disabled: true,
    },
  ];
};

export const dummyData = [
  {
    type: 'person',
    referenceId: 'REFTESTMANU',
  },
  {
    type: 'vehicle',
    referenceId: 'REFEVHICLETEST',
  },
  {
    type: 'vehicle',
    referenceId: 'REFTESTMANUTEST',
  },
];
