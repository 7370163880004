import React, {useEffect, useState} from 'react';
import Flex from '../../../../../../components/Flex';
import styles from './styles.module.scss';
import searchIcon from '../../../../../../assets/images/search-icon/image.png';
import truckIcon from '../../../../../../assets/images/truck-icon-blue/image@3x.png';
import materialIcon from '../../../../../../assets/images/material-icon/image@3x.png';

import {AgGridReact} from 'ag-grid-react';
import {operatorImages} from '../../../../../../assets/images';
import moment from 'moment';

import './styles.css';
import FormElement from '../../../../../../components/FormElement';
import {API} from '../../../../../../requests';
import util from '../../../../../../util';
import Select from 'react-select';
import {customStyles} from './customStyles';

export default function Desktop({
  vehicle,
  driver,
  goBack,
  onChange,
  searchText,
  onClick,
  material,
  currentDriver,
  vehiclesArray,
  materialsArray,
  persons,
  favoriteOptions,
  favorite,
  driverFormData,
  filter,
  filterOptions,
}) {
  const [selectedOption, setSelectedOption] = useState('database');

  console.log('MATERIAL-->', material);

  const deleteRow = (event, id) => {
    onClick('deleteDriver', id);
  };
  const rowStyle = {border: 'none', cursor: 'pointer'};
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return {background: '#f6f6f6', border: 'none'};
    }
  };

  const columnDefs = [
    {
      headerName: 'Name',
      field: 'name',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      cellStyle: {display: 'flex', alignItems: 'center'},
      cellRendererFramework: ({data: {name}}) => {
        return <div className={styles.sessionTime}>{name}</div>;
      },
    },
    {
      headerName: 'Request Type',
      field: 'requestType',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      cellStyle: {display: 'flex', alignItems: 'center'},
      cellRendererFramework: ({data: {requestType}}) => {
        return <div className={styles.sessionTime}>{requestType}</div>;
      },
    },
    {
      headerName: 'Aadhaar Number',
      field: 'aadhaarNumber',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      cellStyle: {display: 'flex', alignItems: 'center'},
      cellRendererFramework: ({data: {aadhaarNumber}}) => {
        return <div className={styles.sessionTime}>{aadhaarNumber}</div>;
      },
    },
    {
      headerName: 'RFID Number',
      field: 'RFIDNumber',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      cellStyle: {display: 'flex', alignItems: 'center'},
      cellRendererFramework: ({data: {RFIDNumber}}) => {
        return <div className={styles.sessionTime}>{RFIDNumber}</div>;
      },
    },

    {
      headerName: 'Actions',
      field: 'actions',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      cellStyle: {display: 'flex', alignItems: 'center'},
      cellRendererFramework: ({data: {id}}) => {
        return (
          <Flex className={styles.actions}>
            <img
              onClick={event => deleteRow(event, id)}
              src={operatorImages.deleteIcon}
              alt="delete"
              className={styles.image}
            />
          </Flex>
        );
      },
    },
  ];

  const getBottomDriverDetails = () => {
    if (currentDriver) {
      // Disable radio buttons + show table with mapped vehicle and driver.

      const preparedObject = {
        id: currentDriver.id,
        name: currentDriver.name,
        requestType: `Vehicle - Driver (${vehicle.vehicleRegistrationNumber})`,
        documentProofNumber: currentDriver.documentProofNumber,
        RFIDNumber: currentDriver.rfidnumber,
        aadhaarNumber: currentDriver.aadhaarNumber,
      };

      return (
        <div className={['ag-theme-balham', styles.tableWrapper].join(' ')}>
          <AgGridReact
            columnDefs={columnDefs}
            rowHeight={40}
            headerHeight={30}
            defaultColDef={{sortable: true}}
            rowClass={[styles.gridRowClass]}
            rowData={[preparedObject]}
            rowStyle={rowStyle}
            getRowStyle={getRowStyle}
            overlayNoRowsTemplate={
              '<span>Search persons with RFID No. or Reference Number to add</span>'
            }
            domLayout="autoHeight"></AgGridReact>
        </div>
      );
    } else {
      return (
        <>
          {selectedOption === 'database' ? (
            <Flex direction="column" className={styles.searchCardContainer}>
              <Flex className={styles.filtersWrapper}>
                <div className={styles.selectWrapper}>
                  <Select
                    options={filterOptions}
                    defaultValue={
                      filter
                        ? (filterOptions || [])
                            .map(option => ({
                              value: option.id,
                              label: option.name,
                            }))
                            .find(o => o.id === filter)
                        : null
                    }
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    placeholder={'Select Type'}
                    styles={customStyles}
                    onChange={({id}) => {
                      onChange('filter', id);
                    }}
                  />
                </div>
                <SearchBar
                  searchText={searchText}
                  onChange={onChange}
                  onClick={() => onClick('search')}
                />
              </Flex>
              {driver ? (
                <PersonsMetadata
                  onClick={onClick}
                  person={driver}
                  persons={persons}
                  driverFormData={driverFormData}
                />
              ) : null}
            </Flex>
          ) : (
            <Favorites
              onChange={onChange}
              favorite={favorite}
              favoriteOptions={favoriteOptions}
              onClick={onClick}
              driver={driver}
              persons={persons}
            />
          )}
        </>
      );
    }
  };

  return (
    <Flex className={styles.mainContainer}>
      <Flex className={styles.container}>
        <Header />
        <Flex direction="row" className={styles.cardButtonContainer}>
          <VehiclesDisplayCard vehicle={vehicle} />
          {material ? <MaterialsDisplayCard material={material} /> : null}
        </Flex>

        <Flex direction="column" className={styles.bottomContainer}>
          <Flex direction="row" className={styles.radioGroup}>
            <RadioGroup
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              disabled={currentDriver ? true : false}
            />
          </Flex>
          {getBottomDriverDetails()}
        </Flex>
        <Flex direction="row" className={styles.buttonContainer}>
          <button className={styles.cancel} onClick={() => onClick('cancel')}>
            Cancel
          </button>
          <button className={styles.finish} onClick={() => onClick('submit')}>
            Finish
          </button>
        </Flex>
      </Flex>
    </Flex>
  );
}

const Favorites = ({
  onChange,
  favorite,
  favoriteOptions,
  onClick,
  driver,
  persons,
}) => {
  return (
    <Flex direction="column" className={styles.dropdownContainer}>
      <FormElement
        type={'dropdown'}
        label={'Select Driver'}
        required={true}
        placeholderText={'Select available drivers'}
        onChange={text => {
          onChange('favorite', text);
        }}
        options={favoriteOptions}
        value={favorite.value}
        error={favorite.error}
        dropdownStyle={{height: '45px'}}
        containerClassNames={styles.containerClassNames}
      />
      {driver ? (
        <PersonsMetadata onClick={onClick} person={driver} persons={persons} />
      ) : null}
    </Flex>
  );
};

const Header = () => {
  return (
    <Flex className={styles.header}>
      <p>{'Add Driver'}</p>
    </Flex>
  );
};

const SearchBar = ({onChange, onClick, searchText}) => {
  return (
    <Flex direction="row" className={styles.searchContainer}>
      <input
        className={styles.searchInput}
        onChange={e => onChange('searchText', e.target.value)}
        placeholder={'Search by Reference Number / Aadhaar Number'}
        value={searchText}
      />
      <button className={styles.searchButton} onClick={onClick}>
        <img src={searchIcon} className={styles.searchIcon} alt="Avatar" />
      </button>
    </Flex>
  );
};

const VehiclesDisplayCard = ({vehicle}) => {
  console.log('vehicle', vehicle);
  return (
    <Flex direction="column" className={styles.personDetails}>
      {/* //image text container */}
      <Flex direction="row" className={styles.imageTextContainer}>
        <img src={truckIcon} alt="u" className={styles.personImage} />
        <Flex direction="column" className={styles.textArea}>
          <p>
            Vehicle Number: <span>{vehicle?.vehicleRegistrationNumber}</span>
          </p>

          <p>
            Vehicle Type: <span>{vehicle?.vehicleType.name}</span>
          </p>
        </Flex>
      </Flex>
    </Flex>
  );
};
const MaterialsDisplayCard = ({material}) => {
  console.log('material', material);
  return (
    <Flex direction="column" className={styles.personDetails}>
      <Flex direction="row" className={styles.imageTextContainer}>
        <img src={materialIcon} alt="u" className={styles.personImage} />
        <Flex direction="column" className={styles.textArea}>
          <p>
            Material Type: <span>{material?.materialType?.name}</span>
          </p>

          <p>
            IGM Number: <span>{material?.igmnumber}</span>
          </p>
          <p>
            Delivery Date:{' '}
            <span>
              {moment(material?.deliveryOrderDate).format('DD/MM/YYYY')}
            </span>
          </p>
          <p>
            Vessel VCN Number: <span>{material?.vcnnumber}</span>
          </p>
        </Flex>
      </Flex>
    </Flex>
  );
};

const RadioGroup = ({selectedOption, setSelectedOption, disabled}) => {
  return (
    <>
      <div className="radio">
        <label>
          <input
            disabled={disabled}
            type="radio"
            value="self"
            checked={selectedOption === 'self'}
            onChange={e => {
              setSelectedOption(e.target.value);
            }}
          />
          Add from favorites list
        </label>
      </div>
      <div className="radio">
        <label>
          <input
            disabled={disabled}
            type="radio"
            value="database"
            checked={selectedOption === 'database'}
            onChange={e => {
              setSelectedOption(e.target.value);
            }}
          />
          Add Driver from Driver Database
        </label>
      </div>
    </>
  );
};

const PersonsMetadata = ({onClick, person, persons, driverFormData}) => {
  const [drivingLicence, setDrivingLicence] = useState(
    person.drivingLicenceNumber,
  );
  const [drivingDetails, setDrivingDetails] = useState({
    drivingLicenceNumber: {value: '', error: null},
    drivingLicenceDocument: {value: [], error: null},
    drivingLicenceValidity: {value: null, error: null},
  });

  const handleUpload = (field, files) => {
    if (files === null) {
      throw new Error('File does not exist');
    }

    let filesArray = [];
    for (let i = 0, numFiles = files.length; i < numFiles; i++) {
      filesArray.push(files[i]);
    }

    console.log('filesArray', filesArray);

    let promises = filesArray.map(file => {
      const data = new FormData();
      data.append('s3FolderName', 'company');
      data.append('file', file);
      data.append('allowedExtensions', ['Pk8b8jAuPC1DF', 'JakNVgcBVZUPG', 'JPw7fCgDTT6AEG', 'PCrAriI079CNG']);
      return API.uploadFilesToDpt(data).then(response => {
        console.log('Response Here', response.data);
        const url = response.data?.fileUrl;
        return {fileUrl: url, fileName: file.name};
      });
    });

    return Promise.all(promises)
      .then(response => {
        console.log('response', response);
        setDrivingDetails(prevState => ({
          ...drivingDetails,
          [field]: {
            ...prevState[field],
            value: [...prevState[field].value, ...response],
            error: null,
          },
        }));
      })
      .catch(error => {
        console.log('error', error);
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        console.log(errorMsg);
        setDrivingDetails(prevState => ({
          [field]: {
            ...prevState[field],
            value: [],
            error: errorMsg,
          },
        }));
        return null;
      });
  };
  console.log('drivingDetails', drivingDetails);

  const onChange = (field, value) => {
    console.log('field', field, 'value', value);
    setDrivingDetails({...drivingDetails, [field]: {value, error: null}});
  };

  const [visible, setVisible] = useState(true);
  useEffect(() => {
    if (
      persons?.filter(function (pa) {
        return pa.id === person.id;
      }).length > 0
    ) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  }, [persons, person]);

  useEffect(() => {}, [drivingDetails]);

  const handleAdd = () => {
    if (!person.drivingLicenceNumber) {
      let error = handleErrors();

      if (!error) {
        const driverDetails = {
          personId: person.id,
          drivingLicenceNumber: drivingDetails.drivingLicenceNumber.value,
          drivingLicenceDocument:
            drivingDetails.drivingLicenceDocument.value[0].fileUrl,
          drivingLicenceValidity: moment(
            drivingDetails.drivingLicenceValidity.value,
          ).format('DD/MM/YYYY'),
        };
        const data = {
          ...person,
          driverDetails: driverDetails,
        };
        onClick('addDriverToTableAndUpdate', data);
      }
      console.log('error', error);
    } else {
      onClick('addDriverToTable', person);
    }
  };
  const handleErrors = () => {
    let err = false;
    const update = {};
    let formData = [...driverFormData];
    for (let i = 0; i < formData.length; i++) {
      let element = formData[i];
      const error = util.validate(
        drivingDetails[element.stateField].value,
        element,
      );
      if (error) {
        err = true;
        update[element.stateField] = {
          ...drivingDetails[element.stateField],
          error: error,
        };
      }
    }

    setDrivingDetails(prevState => ({
      ...prevState,
      ...update,
    }));
    return err;
  };
  return (
    <div style={{overflow: 'auto'}}>
      <Flex direction="column" className={styles.personDetails}>
        {/* //image text container */}
        <Flex direction="row" className={styles.imageTextContainer}>
          <img src={person.photo} alt="u" className={styles.personImage} />
          <Flex direction="column" className={styles.textArea}>
            <p>
              Name: <span>{person.name}</span>
            </p>
            <p>
              D.O.B.: <span>{moment(person.dob).format('DD/MM/YYYY')}</span>
            </p>
            <p>
              Aadhaar Number: <span>{person.aadhaarNumber}</span>
            </p>
          </Flex>
        </Flex>

        {visible ? (
          <Flex direction="row" className={styles.buttonContainer}>
            <button
              style={{width: '8vw'}}
              className={styles.cancel}
              onClick={() => onClick('view')}>
              View
            </button>
            <button
              style={{width: '8vw'}}
              className={styles.finish}
              onClick={handleAdd}>
              Add
            </button>
          </Flex>
        ) : (
          <p className={styles.personAlreadyAdded}>Person already added</p>
        )}
      </Flex>
      {!drivingLicence ? (
        <Flex direction="column">
          <p className={styles.notFound}>
            Driving Licence Not Found, Please fill the below fields to continue.
          </p>
          <Flex direction="column" className={styles.driverDetailsContainer}>
            {driverFormData.map((elem, idx) => {
              console.log(
                'drivingDetails[elem.stateField].value',
                drivingDetails[elem.stateField].value,
              );
              return (
                <FormElement
                  key={idx}
                  type={elem.elementType}
                  label={elem.label}
                  required={true}
                  placeholderText={elem.placeholder}
                  onChange={text => {
                    onChange(elem.stateField, text);
                  }}
                  value={drivingDetails[elem.stateField].value}
                  error={drivingDetails[elem.stateField].error}
                  dropdownStyle={{height: '45px'}}
                  containerClassNames={styles.containerClassNames}
                  minimumDate={elem.minimumDate}
                  handleUpload={(file, error) => {
                    handleUpload(elem.stateField, file, error);
                  }}
                  maxLength={20}
                />
              );
            })}
          </Flex>
        </Flex>
      ) : null}
    </div>
  );
};
