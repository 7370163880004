import React, {Component} from 'react';
import {Auth} from 'aws-amplify';
import {connect} from 'react-redux';
import {INPUT_CONSTANTS} from './formfeilds';
import TextInput from '../../../components/TextInput';
import PopupContainer from '../../../components/PopupContainer';
import Popup from '../../../components/SimplePopup';
import {API} from '../../../requests/index';
import {setLoginData} from '../../../store/actions/login';
import ViewRenderer from './views';
import strings from '../../../globalization/index';
import Spinner from '../../../components/Spinner';
import {commonImages} from '../../../assets/images';
import ROLES_CONSTANTS from '../../../constants/roles-constants';

const challenges = {
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
  SMS_MFA: 'SMS_MFA',
};

const POPUP_CONSTANTS = {
  STANDARD_POPUP: 'standard-popup',
  ERROR_POPUP: 'error-popup',
  SPINNER_POPUP: 'spinner-popup',
  USER_NOT_CONFIRM: 'user-not-confirm',
  SIMPLE_POPUP: 'simple-popup',
};

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRsetPassword: false,
      newPassword: '',
      confirmPassword: '',
    };
    //configureAuth();
  }
  onChange = (action, data) => {
    console.log(action, data);
    this.setState(prevState => {
      return {
        ...prevState,
        [action]: data,
      };
    });
  };

  getInputType = element => {
    switch (element.type) {
      case INPUT_CONSTANTS.PASSWORD:
      case INPUT_CONSTANTS.NUMBER:
      case INPUT_CONSTANTS.TEXT: {
        return (
          <TextInput
            label={element.label}
            lblDirection="row"
            onChange={update => {
              this.onChange(element.field, update);
            }}
            isEdit={true}
            type={element.type}
            placeholder={element.placeholder}
            //inputStyle={styles.inputTextAddress}
            required={element.required}
            name={element.field}
            error={this.state[element.field].error}
            value={this.state[element.field]}
          />
        );
      }
      default: {
        console.warn('case not handled', element.type);
        return null;
      }
    }
  };

  /**
   *
   * @param {*} action
   * @param {*} data
   */

  onClickHandler = (action, data) => {
    const error = this.matchPassword();
    const {history} = this.props;
    switch (action) {
      case 'signin': {
        history.push('/sign-in');
        break;
      }
      case 'reset': {
        this.signInWithCognito();
        break;
      }
      default: {
        console.info('case not handled', action);
      }
    }
  };

  signInWithCognito = async () => {
    if (this.matchPassword()) {
      return;
    }
    const {newPassword} = this.state;
    const {reduxProps} = this.props;
    try {
      this.showSpinner();
      const response = await Auth.completeNewPassword(
        reduxProps.login?.authenticatedUser,
        newPassword,
        {
          name: 'vijay',
        },
      );
      if (response.challengeParam && response.challengeParam.userAttributes) {
        const phone = response.challengeParam.userAttributes.phone_number;
        this.login(phone);
      } else {
        this.closePopup();
      }
    } catch (error) {
      console.log(error);
      const errorMsg = error.response
        ? error.response.data.message
        : error.message;
      this.closePopup();
      this.showErrorPopup(errorMsg);
    }
  };
  login = async userInfo => {
    const type = '';
    const user = await Auth.currentSession();
    const {idToken = {}} = user;
    const {jwtToken = ''} = idToken;

    API.login(jwtToken)
      .then(response => {
        const {token, user} = response.data;

        API.setBearerToken(token);
        this.closePopup();
        this.props.dispatchSetLoginData(response.data);
        // if (type === CHANGE_PASSWORD) {
        //   //navigation.goBack();
        //   return;
        // }
        this.closePopup();
        console.log('user::', user);
        if (ROLES_CONSTANTS.agencyUser.includes(user.roleId)) {
          this.props.history.push('./u/dashboard');
        } else if (ROLES_CONSTANTS.deptUser.includes(user.roleId)) {
          this.props.history.push('./u/dashboard');
        } else if (ROLES_CONSTANTS.operatorModuleUser.includes(user.roleId)) {
          this.props.history.push('./u/personCards');
        } else {
          this.props.history.push('./u');
        }
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        this.closePopup();
        this.showErrorPopup(errorMsg);
      });
    API.getPreLoginToken()
      .then(response => {
        console.log(response);
        API.setPreLoginToken(response);
      })
      .catch(error => {
        //this.showErrorPopup('');
        console.log(error);
      });
  };
  getProps = () => {
    return {
      onClick: this.onClickHandler,
      onChangeState: this.onChange,
      ...this.state,
    };
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };
  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  showSuccessPopup = successMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: successMessage,
        onClose: this.closePopup,
        headingImage: commonImages.successIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  showErrorRetryPopup = (errorMsg, retryCallback) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMsg,
        messageStyle: {color: '#E67717'},

        buttons: [
          {
            text: strings.cancelPopup,
            onClick: this.closePopup,
            outline: true,
          },
          {
            text: strings.retryApiPopup,
            onClick: () => {
              this.closePopup();
              retryCallback();
            },
          },
        ],
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
      },
    });
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="blue" />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      default: {
        console.warn('case not handled', popup.type);
        return null;
      }
    }
  };

  matchPassword = () => {
    let error = false;
    const password = this.state.newPassword;
    const confirm = this.state.confirmPassword;
    if (password !== confirm || password === '' || confirm === '') {
      error = true;
      this.setState({
        ['confirmPassword' + '-error']: {
          error: `${strings.passwordDoseNotMatch}`,
        },
      });
    }
    if (password === confirm) {
      error = false;
      this.setState({
        ['confirmPassword' + '-error']: {
          error: '',
        },
      });
    }
    return error;
  };
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

const mapStateToProps = reduxProps => ({
  reduxProps,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetLoginData: payload => {
    dispatch(setLoginData(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
