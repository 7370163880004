import React, {useState} from 'react';
import styles from './styles.module.scss';
import checkIcon from '../../assets/images/check-icon-black/image.png';
const CustomCheckbox = ({onClick, label = 'Checkbox label'}) => {
  const [checked, setChecked] = useState(false);
  const handleClick = () => {
    setChecked(!checked);
    onClick && onClick(!checked);
  };
  return (
    <div className={styles.container}>
      <div className={styles.checkbox} onClick={handleClick}>
        {checked && (
          <img src={checkIcon} className={styles.icon} alt={`check icon`} />
        )}
      </div>
      <p onClick={handleClick} className={styles.text}>
        {label}
      </p>
    </div>
  );
};

export default CustomCheckbox;
