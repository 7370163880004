import React, {useState} from 'react';
import {loginImages} from '../../../../assets/images';

import strings from '../../../../globalization';
import styles from './styles.module.scss';

import arrowIcon from '../../../../assets/images/stealth/arrow_right_icon.png';
import sannaLogoBlack from '../../../../assets/images/stealth/sanna_logo_black.png';
import Flex from '../../../../components/EDrishtiDesignSystem/Flex';

import eDrishtiLogo from '../../../../assets/images/resource-center/powered-by-sanna/image.png';
import homeIcon from '../../../../assets/images/resource-center/home/image@3x.png';
import search from '../../../../assets/images/resource-center/search/image@3x.png';

import personIcon from '../../../../assets/images/resource-center/sidebar/user/image@3x.png';
import personIconWhite from '../../../../assets/images/resource-center/sidebar/user-white/image@3x.png';

import vehicleIcon from '../../../../assets/images/resource-center/sidebar/vehicle/image@3x.png';
import vehicleIconWhite from '../../../../assets/images/resource-center/sidebar/vehicle-white/image@3x.png';

import manageRegIcon from '../../../../assets/images/resource-center/sidebar/manage-registration-info/image@3x.png';
import manageRegIconWhite from '../../../../assets/images/resource-center/sidebar/manage-registration-info-white/image@3x.png';

import paymentIcon from '../../../../assets/images/resource-center/sidebar/payment/image@3x.png';
import paymentIconWhite from '../../../../assets/images/resource-center/sidebar/payment-white/image@3x.png';

import userIcon from '../../../../assets/images/resource-center/sidebar/person/image@3x.png';
import userIconWhite from '../../../../assets/images/resource-center/sidebar/person-white/image@3x.png';

import videoGuidesIcon from '../../../../assets/images/resource-center/guides/video-guides/image.png';
import quickGuidesIcon from '../../../../assets/images/resource-center/guides/quick-guides/image.png';

import playIcon from '../../../../assets/images/resource-center/play/image@3x.png';

import messageIcon from '../../../../assets/images/resource-center/message/image@3x.png';
import phoneIcon from '../../../../assets/images/resource-center/phone/image@3x.png';

import {Link} from 'react-router-dom';
import {
  manageRegistrationInformation,
  payments,
  personRegistrationContent,
  user,
  vehicleRegistration,
} from './content';

import {Marker} from 'react-mark.js';
const Desktop = ({handleClick}) => {
  const [searchText, setSearchText] = useState('');
  const onClick = (action, data) => {
    switch (action) {
      case 'home':
        break;
      case 'set-active-card':
        setSearchText('');
        setActiveCard(data);
        break;
      default:
        break;
    }
  };

  const sidebarItems = [
    {
      id: 0,
      title: 'Person Registration',
      icon: personIcon,
      activeIcon: personIconWhite,
      content: personRegistrationContent,
    },
    {
      id: 1,
      title: 'Vehicle Registration',
      icon: vehicleIcon,
      activeIcon: vehicleIconWhite,
      content: vehicleRegistration,
    },
    {
      id: 2,
      title: 'Manage Registration Information',
      icon: manageRegIcon,
      activeIcon: manageRegIconWhite,
      content: manageRegistrationInformation,
    },
    {
      id: 3,
      title: 'Payment And Pricing',
      icon: paymentIcon,
      activeIcon: paymentIconWhite,
      content: payments,
    },
    {
      id: 4,
      title: 'User Account',
      icon: userIcon,
      activeIcon: userIconWhite,
      content: user,
    },
  ];

  const filteredPersonRegistrationContent = keywords => {
    setSearchText(keywords);
    const filteredPC =
      personRegistrationContent.filter(element =>
        Object.values(element)
          .toString()
          .toLowerCase()
          .includes(keywords.toLowerCase()),
      ) || {};

    const filteredVC =
      vehicleRegistration.filter(element =>
        Object.values(element)
          .toString()
          .toLowerCase()
          .includes(keywords.toLowerCase()),
      ) || {};

    const filterManageRegistrationInformation =
      manageRegistrationInformation.filter(element =>
        Object.values(element)
          .toString()
          .toLowerCase()
          .includes(keywords.toLowerCase()),
      ) || {};
    const filterPaymentsContent =
      payments.filter(element =>
        Object.values(element)
          .toString()
          .toLowerCase()
          .includes(keywords.toLowerCase()),
      ) || {};
    const filterUserContent =
      user.filter(element =>
        Object.values(element)
          .toString()
          .toLowerCase()
          .includes(keywords.toLowerCase()),
      ) || {};

    sidebarItems[0].content = filteredPC;
    sidebarItems[1].content = filteredVC;
    sidebarItems[2].content = filterManageRegistrationInformation;
    sidebarItems[3].content = filterPaymentsContent;
    sidebarItems[4].content = filterUserContent;

    console.log(filteredPC);
    if (filteredPC.length > 0) {
      setActiveCard(sidebarItems[0]);
    } else if (filteredVC.length > 0) {
      setActiveCard(sidebarItems[1]);
    } else if (filterManageRegistrationInformation.length > 0) {
      setActiveCard(sidebarItems[2]);
    } else if (filterPaymentsContent.length > 0) {
      setActiveCard(sidebarItems[3]);
    } else if (filterUserContent.length > 0) {
      setActiveCard(sidebarItems[4]);
    }
  };

  const [activeCard, setActiveCard] = useState(sidebarItems[0]);
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Header icon={eDrishtiLogo} onClick={() => onClick('home')} />
        <div className={styles.textHeading}>
          <h1 className={styles.resources}>Resources</h1>
          <p className={styles.para}>How can we help you?</p>

          <Flex
            align="center"
            justify="flex-start"
            className={styles.searchContainer}>
            <img src={search} alt="search" className={styles.searchButton} />
            <input
              type="text"
              value={searchText}
              className={styles.searchInput}
              placeholder="Search with keywords"
              onChange={e => filteredPersonRegistrationContent(e.target.value)}
            />
          </Flex>
          <p className={styles.example}>Example: Registration</p>
        </div>
      </div>

      <div className={styles.content}>
        <LinkTab />
        <h2 className={styles.contentFAQHeading}>FAQ</h2>

        <Flex className={styles.faqContainer}>
          <div className={styles.sidebar}>
            {sidebarItems.map(el => (
              <button
                onClick={() => onClick('set-active-card', el)}
                className={styles.sidebarCard}
                key={el.id}
                style={{
                  backgroundColor:
                    activeCard.id === el.id ? '#002C94' : '#ffffff',
                }}>
                <div className={styles.iconContainer}>
                  <img
                    src={activeCard.id === el.id ? el.activeIcon : el.icon}
                    className={styles.sidebarIcon}
                    alt="icon"
                  />
                </div>
                <p
                  style={{
                    color: activeCard.id === el.id ? '#ffffff' : '#000000',
                  }}>
                  {el.title}
                </p>
              </button>
            ))}
          </div>
          <div className={styles.faqContent}>
            <h1 className={styles.faqContentHeader}>{activeCard.title}</h1>

            {activeCard.content && (
              <>
                {activeCard.content.map((question, idx) => (
                  <div key={`question-${idx}`}>
                    <Marker mark={searchText}>
                      <h2 className={styles.contentHeading}>
                        {question.title}
                      </h2>

                      {question.answers.map((answer, index) => (
                        <div key={`question-${idx}-answer-${index}`}>
                          {answer.type === 'text' ? (
                            <p className={styles.answerText}>
                              {answer.content}
                            </p>
                          ) : answer.type === 'bullet' ? (
                            <ul
                              className={styles.bulletPointsUL}
                              style={{
                                listStyleType: answer.listStyleType
                                  ? answer.listStyleType
                                  : 'disc',
                              }}>
                              {answer.content.map((bullet, bulletIdx) => (
                                <li
                                  key={`question-${idx}-answer${index}-bullet-${bulletIdx}`}>
                                  {bullet}
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <a
                              href={answer.content}
                              className={styles.link}
                              target="__blank">
                              {answer.title}
                            </a>
                          )}
                        </div>
                      ))}
                    </Marker>
                  </div>
                ))}
              </>
            )}
          </div>
        </Flex>

        <Flex justify="space-between" className={styles.guides}>
          <Flex
            onClick={() => handleClick('video-popup')}
            align="center"
            className={styles.guideCard}>
            <img
              src={videoGuidesIcon}
              alt="video guide icon"
              className={styles.guidesIcon}
            />
            <p>Video Guides</p>
            <button className={styles.playButton}>
              <img src={playIcon} alt="play" className={styles.play} />
            </button>
          </Flex>
          <Flex
            onClick={() => handleClick('guide-popup')}
            align="center"
            className={styles.guideCard}>
            <img
              src={quickGuidesIcon}
              alt="quick guide icon"
              className={styles.guidesIcon}
            />
            <p>Quick Guides</p>
            <button className={styles.playButton}>
              <img src={playIcon} alt="play" className={styles.play} />
            </button>
          </Flex>
        </Flex>

        <div className={styles.bottomText}>
          <h2>Didn't find an answer?</h2>

          <p>Our team is just a phone call or email away to support you !</p>
        </div>

        <Flex justify="space-between" className={styles.contact}>
          <Flex className={styles.contactCard} align="center">
            <div className={styles.imageIllustrationContainer}>
              <div className={styles.filler} />
              <div className={styles.fillerSecond} />
              <img
                src={phoneIcon}
                alt="phone icon icon"
                className={styles.contactIcon}
              />
            </div>

            <Flex direction="column" className={styles.contactContent}>
              <div>
                <h2>Support Number</h2>
                <h4>+91 9316546987</h4>
              </div>
              <div>
                <h2>Timings </h2>
                <h4>10 AM to 5:30 PM</h4>
              </div>
            </Flex>
          </Flex>
          <Flex className={styles.contactCard} align="center">
            <div className={styles.imageIllustrationContainer}>
              <div className={styles.filler} />
              <div className={styles.fillerSecond} />
              <img
                src={messageIcon}
                alt="Message icon"
                className={styles.contactIcon}
              />
            </div>

            <Flex direction="column" className={styles.contactContent}>
              <div>
                <h2>Please write us for any support queries</h2>
              </div>
              <div>
                <h4>edrishti@sanya.ai</h4>
              </div>
            </Flex>
          </Flex>
        </Flex>
      </div>
    </div>
  );
};

const LinkTab = () => {
  return (
    <Flex align="center" className={styles.linkTab}>
      <Link className={styles.homeLink} to="/eDrishti">
        Home
      </Link>
      <span> / </span>
      <Link className={styles.resourceLink} to="/resource">
        Resource
      </Link>
    </Flex>
  );
};

const Header = ({onClick, icon}) => {
  return (
    <Flex
      direction="row"
      justify="space-between"
      align="center"
      className={styles.topBar}>
      <img src={eDrishtiLogo} className={styles.logo} alt="logo" />
      <Link to="/eDrishti" className={styles.homeButton}>
        <img src={homeIcon} className={styles.homeIcon} alt="home" />
      </Link>
    </Flex>
  );
};
export default Desktop;
