import React, {Component} from 'react';
import styles from './styles.module.scss';
import PopupContainer from '../../../components/PopupContainer';
import Popup from '../../../components/Popup';
import ViewRenderer from './views';
import {API} from '../../../requests';
import strings from '../../../globalization';
import Spinner from 'react-spinkit';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import moment from 'moment';

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  ERROR: 'error',
  SPINNER_POPUP: 'spinner',
};
class AgencyDashBoard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statusDetails: {
        totalPermitsCount: '00',
        pendingCount: '00',
        rejectedCount: '00',
        approvedCount: '00',
      },
    };
  }

  componentDidMount() {
    this.getPermitStatusDetails();
  }

  /**
   *
   * @param {*} action
   * @param {*} data
   */

  getProps = () => {
    const {statusDetails} = this.state;
    const {reduxProps} = this.props;
    const {
      login: {loginInfo},
    } = reduxProps;
    const {company, user} = loginInfo;
    return {
      statusDetails,
      onClick: this.onClickHandler,
      user,
      company,
    };
  };

  getPermitStatusDetails = () => {
    const currentHour = new Date().getHours();
    let startDate = moment().format('MM-DD-YYYY');
    let endDate = moment().format('MM-DD-YYYY');
    if (currentHour < 8) {
      startDate = moment().subtract(1, 'days').format('MM-DD-YYYY');
      endDate = moment().subtract(1, 'days').format('MM-DD-YYYY');
    }
    API.getPermitStatusDetails(startDate, endDate)
      .then(res => {
        console.info('statusDetails:', res);
        this.setState({
          statusDetails: res.data,
        });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        console.info('error....', errorMsg);
        this.setState({
          popup: undefined,
        });
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.SIMPLE_POPUP,
            message: errorMsg,
            messageStyle: {color: '#E67717'},
            buttons: [
              {
                title: 'Okay!',
                onPress: this.closePopup,
              },
            ],
          },
        });
      });
  };

  onClickHandler = (action, data) => {
    const {history} = this.props;
    switch (action) {
      case 'permits': {
        //FIXME
        console.log('from add permit');
        history.push('/u/permits');
        break;
      }
      case 'profile': {
        //FIXME
        console.log('from add permit');
        history.push('/u/profile');
        break;
      }
      default: {
        break;
      }
    }
  };

  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.SPINNER: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

const mapStateToProps = reduxProps => ({
  reduxProps,
});

const mapDispatchToProps = dispatch => ({
  // dispatchSetPersonsData: payload => {
  //   dispatch(setPersonsData(payload));
  // },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AgencyDashBoard),
);

//export default withRouter(AgencyDashBoard);
