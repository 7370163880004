import React, {useState} from 'react';
import styles from './Desktop.module.scss';
import './styles.css';
import strings from '../../../../../globalization';
import {withRouter} from 'react-router-dom';
import {AgGridReact} from 'ag-grid-react';
import Select from 'react-select';
import moment from 'moment';
import 'react-daterange-picker/dist/css/react-calendar.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import dimensions from '../../.../../../../../util/dimensions-util';
import {customStyles} from './customStyles';
import {adminImages} from '../../../../../assets/images';
import DateRangePicker from 'react-daterange-picker';
// import { rowData } from './data';
import TextInput from '../../../../../components/TextInput';
import Button from '../../../../../components/Button';

import calendarBlue from '../../../../../assets/images/calendar-blue-icon/image.png';

const {vw: widthConstant, vh: heightConstant} = dimensions;

const columnDefs = [
  {
    headerName: strings.ADMIN.referenceNumber,
    field: 'referenceId',
    headerClass: styles.rowHeader,
    width: 200 * widthConstant,
    resizable: true,
    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {referenceId}}) => {
      return <div className={styles.sessionTime}>{referenceId}</div>;
    },
  },
  {
    headerName: strings.ADMIN.agencyName,
    field: 'company',
    headerClass: styles.rowHeader,
    width: 230 * widthConstant,
    resizable: true,
    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {company}}) => {
      return (
        <div className={styles.sessionTime}>
          {company ? company.name : null}
        </div>
      );
    },
  },
  {
    headerName: strings.ADMIN.purposeOfWork,
    field: 'workPurposeName',
    headerClass: styles.rowHeader,
    width: 230 * widthConstant,
    cellStyle: {display: 'flex', alignItems: 'center'},
    resizable: true,
    cellRendererFramework: ({data: {workPurposeName}}) => {
      return <div className={styles.sessionTime}>{workPurposeName}</div>;
    },
  },
  {
    headerName: strings.ADMIN.areaOfWork,
    field: 'workAreaName',
    headerClass: styles.rowHeader,
    width: 230 * widthConstant,
    resizable: true,
    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {workAreaName}}) => {
      return <div className={styles.sessionTime}>{workAreaName}</div>;
    },
  },
  {
    headerName: strings.ADMIN.requestType,
    field: 'requestType',
    headerClass: styles.rowHeader,
    width: 220 * widthConstant,
    resizable: true,
    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {persons, vehicle}}) => {
      return (
        <div className={styles.sessionTime}>
          {persons.length > 0 ? 'person' : null}{' '}
          {persons.length > 0 && vehicle ? ',' : null}{' '}
          {vehicle ? 'vehicle' : null}
        </div>
      );
    },
  },
  {
    headerName: strings.ADMIN.visitingDate,
    field: 'visitingDate',
    headerClass: styles.rowHeader,
    width: 220 * widthConstant,
    resizable: true,
    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {visitingDate}}) => {
      return (
        <div className={styles.sessionTime}>
          {visitingDate ? moment(visitingDate).format('DD/MM/YYYY') : null}
        </div>
      );
    },
  },
  {
    headerName: strings.ADMIN.status,
    field: 'status',
    headerClass: styles.rowHeader,
    width: 180 * widthConstant,
    resizable: true,
    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {status}}) => {
      return (
        <div className={styles.status}>
          <div className={styles.statusLabel}>
            <div className={styles.roundIcon}></div>
            {status}
          </div>
        </div>
      );
    },
  },
];

const Desktop = props => {
  const {
    onClick,
    onChange,
    statuses,
    selectedStatus,
    selectedAgency,
    agencies,
    filterAgencies,
    searchText,
    dates,
  } = props;
  const [showDate, setShowDate] = useState(false);

  const agencyNames =
    agencies &&
    agencies
      .map(agency => {
        return (
          agency.company && {
            id: agency.referenceId,
            name: agency.company.name,
          }
        );
      })
      .filter(name => name !== undefined);
  const onCellClick = (event, index) => {
    onClick('rowClick', event.data);
  };
  const onSelect = dates => {
    onChange('date', {
      startDate: moment(dates.start).format('MM-DD-YYYY'),
      endDate: moment(dates.end).format('MM-DD-YYYY'),
      dates,
    });
    toggleDate();
  };
  const toggleDate = () => {
    setShowDate(!showDate);
  };

  const getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map(item => [item[key], item])).values()];
  };

  const formatDate = date => moment(date).format('MMM Do YY');
  const rowStyle = {border: 'none', cursor: 'pointer'};
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return {background: '#f6f6f6', border: 'none'};
    }
  };

  const handleClear = () => {
    setShowDate(false);
    onClick('clearFilters');
  };

  return (
    <div className={styles.agencyContainer}>
      <div className={styles.headerText}>{strings.ADMIN.agencyRequest}</div>
      <div className={styles.header}>
        <div className={styles.left}>
          <div className={styles.agencyNameDropdown}>
            <Select
              options={getUniqueListBy(agencyNames, 'name')}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              placeholder={strings.ADMIN.agencyName}
              styles={customStyles}
              value={getUniqueListBy(agencyNames, 'name').filter(
                option => option.name === selectedAgency,
              )}
              onChange={({name}) => {
                onChange('agencyName', name);
              }}
            />
          </div>
          <div className={styles.status}>
            <Select
              options={statuses}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              placeholder={strings.ADMIN.Status}
              styles={customStyles}
              value={statuses.filter(option => option.name === selectedStatus)}
              onChange={({name}) => {
                onChange('status', name);
              }}
            />
          </div>
          <div className={styles.dateWrapper}>
            <div onClick={toggleDate} className={styles.inputDate}>
              {' '}
              <div>
                {' '}
                {dates
                  ? formatDate(dates.start) + '-' + formatDate(dates.end)
                  : strings.ADMIN.dateRange}
              </div>{' '}
              <img src={calendarBlue} className={styles.calendar} alt="C" />
            </div>
            {showDate ? (
              <div className={styles.datePicker}>
                <DateRangePicker
                  numberOfCalendars={1}
                  selectionType="range"
                  onSelect={onSelect}
                  className={styles.dateRange}
                  value={dates}
                  singleDateRange={true}
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.inputSearch}>
            <TextInput
              onChange={text => {
                onChange('search', text);
              }}
              isEdit={true}
              type={'text'}
              value={searchText}
              inputStyle={styles.inputText}
              showSeacrh={true}
            />
          </div>
          <div onClick={handleClear} className={styles.clearLabel}>
            {' '}
            {strings.ADMIN.clear}
          </div>
          <Button
            onClick={() => onClick('apply')}
            buttonStyle="primary"
            label={strings.ADMIN.apply}
            className={styles.applyBtn}></Button>
        </div>
      </div>
      <div className={['ag-theme-balham', styles.tableWrapper].join(' ')}>
        <AgGridReact
          rowStyle={rowStyle}
          getRowStyle={getRowStyle}
          rowHoverHighlight={true}
          columnDefs={columnDefs}
          rowHeight={50 * heightConstant}
          headerHeight={50 * heightConstant}
          defaultColDef={{sortable: true}}
          rowClass={[styles.gridRowClass]}
          onCellClicked={(event, index) => onCellClick(event, index)}
          rowData={filterAgencies}></AgGridReact>
      </div>
      <div className={styles.bottomLabel}> Showing {filterAgencies.length}</div>
    </div>
  );
};
export default withRouter(Desktop);
