import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {API} from '../../../requests';
import util from '../../../util';
import {getOperators} from './data';
import {
  formLeftFields,
  formRightFields,
  shiftFormLeftFields,
  shiftFormMiddleFields,
  shiftFormRightFields,
} from './formFields';

import Spinner from '../../../components/Spinner';
import Popup from '../../../components/SimplePopup';

import styles from './styles.module.scss';

import ViewRenderer from './views';
import {commonImages, operatorImages} from '../../../assets/images';
import strings from '../../../globalization';
import PopupContainer from '../../../components/PopupContainer';
import moment from 'moment';

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  SPINNER: 'spinner',
  ERROR: 'error',
  SIMPLE_POPUP: 'simple-popup',
};

class AddNewLocation extends Component {
  constructor(props) {
    super(props);
    let preparedObject = {};
    [
      ...formLeftFields,
      ...formRightFields,
      ...shiftFormLeftFields,
      ...shiftFormMiddleFields,
      ...shiftFormRightFields,
    ].forEach(obj => {
      preparedObject[obj.stateField] = {value: obj.stateDefault, error: null};
      if (obj.optionsField) {
        preparedObject[obj.optionsField] = [];
      }
    });

    const id = this.props.match.params.id;

    this.state = {
      mode: id ? 'edit' : 'add',
      id: parseInt(id),

      ...preparedObject,
      numberOfShiftsOptions: [
        {
          id: 2,
          name: 2,
        },
        {
          id: 3,
          name: 3,
        },
        {
          id: 4,
          name: 4,
        },
        {
          id: 5,
          name: 5,
        },
      ],
      shiftRows: [],
    };
  }

  componentDidMount = () => {
    const {mode, id} = this.state;
    console.log('location ID', id);

    if (mode === 'edit') {
      this.fetchLocationById(id);
    }
  };
  fetchLocationById = id => {
    this.showSpinner();
    API.fetchLocationById(id)
      .then(res => {
        console.log('res---location by id', res.data);
        this.populateData(res.data);
      })
      .catch(error => {
        console.log('error', error);
        const errorMsg = util.getErrorMessage(error);
        this.showErrorPopup(errorMsg);
      });
  };
  populateData = location => {
    const {id, numberOfShiftsOptions} = this.state;
    const formData = {};

    console.log('location.noOfShifts', location.noOfShifts);

    Object.keys(location).forEach((key, index) => {
      const value = location[key];
      formData[key] = {value};
    });
    formData.numberOfShifts = {
      value: location.noOfShifts,
    };
    formData.locationName = {
      value: location.name,
    };
    formData.maxOperatorsLimit = {
      value: location.limitOfOperators,
    };
    formData.locationId = {
      value: location.id,
    };
    formData.locationObject = {
      value: location,
    };
    this.fetchShiftsByLocationId(id);

    this.setState(prevState => {
      return {
        ...prevState,
        ...formData,
        numberOfShiftsOptions,
        id,
      };
    });
  };
  fetchShiftsByLocationId = id => {
    API.fetchShift(id)
      .then(res => {
        let finalShiftsArray = [];

        for (let i = 0; i < res.data.length; i++) {
          let preparedObj = {};
          let element = res.data[i];
          preparedObj.id = i;
          preparedObj.name = {value: element.name, error: null};
          preparedObj.startTime = {
            value: moment(element.startTime, 'h:mm A'),
            error: null,
          };
          preparedObj.endTime = {
            value: moment(element.endTime, 'h:mm A'),
            error: null,
          };

          finalShiftsArray.push(preparedObj);
        }

        this.setState({
          shiftRows: finalShiftsArray,
          initialShiftRows: finalShiftsArray,
          popup: null,
        });
      })
      .catch(error => {
        console.log('error', error);
        const errorMsg = util.getErrorMessage(error);
        this.showErrorPopup(errorMsg);
      });
  };

  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      default: {
        console.warn('case not handled', popup.type);
        return null;
      }
    }
  };

  getOnBlurErrors = (currentObject, element) => {
    const {mode} = this.state;
    if (element) {
      let error = util.validate(currentObject.value, element);
      if (mode === 'edit' && element.stateField === 'maxOperatorsLimit') {
        let checkBool = this.handleMaxOperatorsLimit();
        if (checkBool) {
          return 'Max operators cannot be decreased';
        }
      }
      return error;
    } else {
      return null;
    }
  };

  onBlur = (field, element) => {
    console.info('onBlur..', field, element);
    this.setState(prevState => {
      const currentObj = prevState[field];
      return {
        ...prevState,
        [field]: {
          ...currentObj,
          // error: element ? util.validate(currentObj.value, element) : null,
          error: this.getOnBlurErrors(currentObj, element),
        },
      };
    });
  };

  onChangeHandler = (field, value) => {
    console.log('Number of shifts!!!!!!', field, value);
    if (field === 'numberOfShifts') {
      this.generateShiftRows(value);
    } else {
      this.setState(prevState => {
        const currentObj = prevState[field];
        return {
          ...prevState,
          [field]: {
            ...currentObj,
            value,
            error: null,
          },
        };
      });
    }
  };

  handleEditModeShifts = value => {
    const {locationObject, initialShiftRows} = this.state;
    console.log('#VALUE#', value);
    console.log('#locationObject#', locationObject.value.noOfShifts);
    if (value < locationObject.value.noOfShifts) {
      this.setState(prevState => ({
        numberOfShifts: {
          value: locationObject.value.noOfShifts,
          error: 'Number of Shifts cannot be decreased',
        },
      }));
    } else {
      let shiftsArray = [];
      for (let i = 0; i < value - locationObject.value.noOfShifts; i++) {
        let shiftObj = {};

        shiftObj.id = i;
        shiftObj.name = {value: '', error: null};
        shiftObj.startTime = {value: '', error: null};
        shiftObj.endTime = {value: '', error: null};

        shiftsArray.push(shiftObj);
      }
      this.setState({
        shiftRows: [...initialShiftRows, ...shiftsArray],
        numberOfShifts: {
          value,
        },
      });
    }
  };

  generateShifts = value => {
    let finalShiftsArray = [];

    for (let i = 0; i < value; i++) {
      let preparedObj = {};
      preparedObj.id = i;
      preparedObj.name = {value: '', error: null};
      preparedObj.startTime = {value: '', error: null};
      preparedObj.endTime = {value: '', error: null};

      finalShiftsArray.push(preparedObj);
    }

    this.setState({
      shiftRows: finalShiftsArray,
      numberOfShifts: {
        value,
      },
    });
  };

  generateShiftRows = value => {
    const {mode} = this.state;
    if (mode === 'edit') {
      this.handleEditModeShifts(value);
    } else {
      this.generateShifts(value);
    }
  };
  onShiftChangeHandler = (field, element, value) => {
    const {shiftRows: sr} = this.state;
    console.log('shift field', field);

    this.setState({
      shiftRows: sr.map((elem, idx) => {
        if (elem.id === element.id) {
          return {
            ...elem,
            [field]: {
              ...elem[field],
              value,
              error: '',
            },
          };
        }
        return elem;
      }),
    });
  };
  handleAdd = () => {
    console.log('add clicked');
    let formData = [...formLeftFields, ...formRightFields];
    const err = this.handleErrors(formData);
    const shiftError = this.handleShiftRowsErrors();
    if (!err && !shiftError) {
      this.submitFormData()
        .then(res => {
          console.log(res);
          // Call the API again to fetch latest list
          // this.getData();
          this.showSuccessPopup('Locations added successfully!', this.getData);
        })
        .catch(error => {
          const errorMsg = util.getErrorMessage(error);
          this.showErrorPopup(errorMsg);
          console.log(error);
        });
    }
  };
  handleUpdate = id => {
    console.log('update clicked');
    let formData = [...formLeftFields, ...formRightFields];
    const err = this.handleErrors(formData);
    const shiftError = this.handleShiftRowsErrors();
    if (!err && !shiftError) {
      this.updateFormData(id)
        .then(res => {
          console.log(res);
          // Call the API again to fetch latest list
          // this.getData();
          this.showSuccessPopup(
            'Locations Updated successfully!',
            this.getData,
          );
        })
        .catch(error => {
          const errorMsg = util.getErrorMessage(error);
          this.showErrorPopup(errorMsg);
          console.log(error);
        });
    }
  };
  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  showSuccessPopup = (message, onClick) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: message,
        headingImage: operatorImages.successIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: onClick ? onClick : this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  handleMaxOperatorsLimit = () => {
    const {maxOperatorsLimit, locationObject} = this.state;
    console.log('maxOperatorsLimit', maxOperatorsLimit);
    console.log('locationObject', locationObject);
    if (maxOperatorsLimit.value < locationObject.value.limitOfOperators) {
      return true;
    } else {
      return false;
    }
  };
  handleNumberOfShifts = () => {};
  handleErrors = formData => {
    const {mode} = this.state;
    let err = false;
    const update = {};
    console.log('formData', formData);

    for (let i = 0; i < formData.length; i++) {
      let element = formData[i];
      let error = util.validate(this.state[element.stateField].value, element);
      if (mode === 'edit' && element.stateField === 'maxOperatorsLimit') {
        let checkBool = this.handleMaxOperatorsLimit();
        console.log('checkBool', checkBool);
        if (checkBool) {
          error = 'Max operators cannot be decreased';
        }
      }
      // if (element.stateField === 'numberOfShifts') {
      //   error = this.handleNumberOfShifts();
      // }
      if (error) {
        err = true;
        update[element.stateField] = {
          ...this.state[element.stateField],
          error: error,
        };
      }
    }
    this.setState({
      ...update,
    });
    return err;
  };
  handleShiftRowsErrors = () => {
    const {shiftRows} = this.state;
    let tempShiftRows = [...shiftRows];
    let error = false;
    tempShiftRows.forEach(shiftRow => {
      if (!shiftRow.name.value) {
        shiftRow.name.error = 'Name cannot be empty';
        error = true;
      }
      if (!shiftRow.startTime.value) {
        shiftRow.startTime.error = 'startTime cannot be empty';
        error = true;
      }
      if (!shiftRow.endTime.value) {
        shiftRow.endTime.error = 'endTime cannot be empty';
        error = true;
      }
    });

    console.log('tempShiftRows', tempShiftRows);
    this.setState({
      shiftRows: tempShiftRows,
    });
    return error;
  };
  submitFormData = async () => {
    const {
      location,
      locationId,
      locationName,
      maxOperatorsLimit,
      numberOfShifts,
      shiftRows,
    } = this.state;
    console.log('shiftRows - submit form data', shiftRows);

    let preparedData = {
      name: locationName.value,
      locationId: locationId.value,
      noOfShifts: numberOfShifts.value,
      limitOfOperators: maxOperatorsLimit.value,
    };

    let preparedArray = [];
    shiftRows.forEach(shift => {
      let preparedObj = {};
      preparedObj.name = shift.name.value;
      preparedObj.startTime = shift.startTime.value.format('hh:mm a');
      preparedObj.endTime = shift.endTime.value.format('hh:mm a');

      preparedArray.push(preparedObj);
    });
    preparedData.shift = preparedArray;

    console.log('preparedData', preparedData);

    // FIXME: Uncomment
    this.showSpinner();

    let res = await API.saveLocation(preparedData);
    return res;
  };

  updateFormData = async id => {
    const {
      location,
      locationId,
      locationName,
      maxOperatorsLimit,
      numberOfShifts,
      shiftRows,
    } = this.state;
    console.log('shiftRows - submit form data', shiftRows);

    let preparedData = {
      id,
      name: locationName.value,
      locationId: locationId.value,
      noOfShifts: numberOfShifts.value,
      limitOfOperators: maxOperatorsLimit.value,
    };

    let preparedArray = [];
    shiftRows.forEach(shift => {
      let preparedObj = {};
      preparedObj.name = shift.name.value;
      preparedObj.startTime = shift.startTime.value.format('hh:mm a');
      preparedObj.endTime = shift.endTime.value.format('hh:mm a');

      preparedArray.push(preparedObj);
    });
    preparedData.shift = preparedArray;

    console.log('preparedData', preparedData);

    // FIXME: Uncomment
    this.showSpinner();

    let res = await API.updateLocation(preparedData);
    return res;
  };
  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  getButtons = () => {
    const {mode, id} = this.state;
    console.log('TYPE OF ID', id, mode);
    switch (mode) {
      case 'add': {
        return [
          {
            text: 'Cancel',
            buttonStyles: styles.buttonPrimary,
            onClick: () => {
              this.closePopup();
            },
          },
          {
            text: 'Add',
            buttonStyles: styles.buttonSecondary,
            onClick: () => {
              this.handleAdd();
            },
          },
        ];
      }
      case 'edit': {
        return [
          {
            text: 'Cancel',
            buttonStyles: styles.buttonPrimary,
            onClick: () => {
              this.closePopup();
            },
          },
          {
            text: 'Update',
            buttonStyles: styles.buttonSecondary,
            onClick: () => {
              this.handleUpdate(id);
            },
          },
        ];
      }
      default: {
        return;
      }
    }
  };

  closePopup = () => {
    const {history} = this.props;
    history.goBack();
  };
  getHeader = () => {
    const {mode} = this.state;
    if (mode === 'add') {
      return 'Add New Location';
    } else {
      return 'Edit Location';
    }
  };
  getReadOnlyFields = () => {
    return ['locationId'];
  };
  getProps = () => {
    const {shiftRows} = this.state;
    // console.log("filterLocations", filterLocations);
    return {
      stateData: this.state,
      onBlur: this.onBlur,
      shiftRows,
      onShiftChange: this.onShiftChangeHandler,
      onChangeHandler: this.onChangeHandler,
      buttons: this.getButtons(),
      header: this.getHeader(),
      readOnlyFields: this.getReadOnlyFields(),
    };
  };

  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer containerStyle={styles.popupContainerStyle}>
            {' '}
            {this.getPopupContent()}{' '}
          </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

// export default withRouter(PersonCards);
export default withRouter(AddNewLocation);
