import React from 'react';
import styles from './styles.module.scss';
import clsx from 'clsx';

import rightArrow from '../../../assets/images/right-arrow-white-long/image.png';
import rightArrowBlue from '../../../assets/images/common-images/right-arrow-long-blue/image@3x.png';

export interface IButton {
  label?: string;
  buttonStyle?:
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'tertiary'
    | 'primary-outline'
    | String;
  className?: string;
  icon?: 'rightArrow' | 'rightArrowBlue' | 'downArrow' | String;
  customIcon?: any;
  onClick?: Function;
  iconStyle?: React.CSSProperties;
  as?: 'a' | 'button' | T | string;
}

const Button = (props: IButton) => {
  const {
    label,
    buttonStyle,
    className,
    icon,
    iconStyle,
    as: Tag = 'button',
    ...remainingProps
  } = props;

  const getButtonStyles = () => {
    switch (buttonStyle) {
      case 'primary':
        return styles.PrimaryButton;
      case 'secondary':
        return styles.secondaryButton;
      case 'danger':
        return styles.dangerButton;
      case 'tertiary':
        return styles.tertiaryButton;
      case 'primary-outline':
        return styles.primaryOutline;
      default:
        return styles.PrimaryButton;
    }
  };
  const getIcon = () => {
    switch (icon) {
      case 'rightArrow': {
        return rightArrow;
      }
      case 'rightArrowBlue': {
        return rightArrowBlue;
      }
      default: {
        return rightArrow;
      }
    }
  };
  return (
    <Tag
      {...props}
      className={clsx(getButtonStyles(), className, styles.button)}
      {...remainingProps}>
      {label}
      {icon && (
        <img
          className={clsx(styles.icon, iconStyle)}
          src={getIcon()}
          alt="icon"
        />
      )}
    </Tag>
  );
};

export default Button;
