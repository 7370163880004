export const getOperators = () => {
  return [
    {
      pincode: 301010,
      shiftId: 3,
      lastName: 'JEST',
      updatedBy: 808,
      loginId: 'LOGINJESTID',
      address: 'UNITED STATES OF KUTCH',
      genderId: 1,
      photo:
        'https://dpt-mroads-stage.s3.ap-south-1.amazonaws.com/files/company/8520fd62-f922-4c07-bba6-05e77c0e7b06Screenshot20211109at12.54.46PM.png',
      userId: 884,
      firstName: 'JEST',
      emailAddress: 'JEST.OPERATOR@YOPMAIL.COM',
      createdDate: '2021-11-11T06:38Z',
      shiftDetails: {
        createdDate: 1636612424000,
        updatedBy: 808,
        createdBy: 808,
        locationId: 1,
        name: 'Morning',
        modifiedDate: 1636694772000,
        startTime: '09:08 am',
        id: 3,
        endTime: '12:02 pm',
        status: 'Inactive',
      },
      phoneNumber: '+910000776277',
      aadharNumber: 'ANSJDANJ12332112',
      createdBy: 808,
      locationId: 1,
      idCardNumber: 'JESTID',
      modifiedDate: '2021-11-11T06:38Z',
      location: {
        createdDate: 1636612409000,
        updatedBy: 808,
        createdBy: 808,
        name: 'West Gate 2',
        modifiedDate: 1636695473000,
        noOfShifts: 2,
        id: 1,
        limitOfOperators: 2,
        status: 'Active',
        locationShiftMapping: [
          {
            createdDate: 1636612409000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 1,
            name: 'Morning',
            modifiedDate: 1636612424000,
            startTime: '09:05 am',
            id: 1,
            endTime: '12:02 pm',
            status: 'Inactive',
          },
          {
            createdDate: 1636612409000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 1,
            name: 'Evening',
            modifiedDate: 1636612424000,
            startTime: '05:02 pm',
            id: 2,
            endTime: '09:02 pm',
            status: 'Inactive',
          },
          {
            createdDate: 1636612424000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 1,
            name: 'Morning',
            modifiedDate: 1636694772000,
            startTime: '09:08 am',
            id: 3,
            endTime: '12:02 pm',
            status: 'Inactive',
          },
          {
            createdDate: 1636612424000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 1,
            name: 'Evening',
            modifiedDate: 1636694772000,
            startTime: '05:02 pm',
            id: 4,
            endTime: '09:02 pm',
            status: 'Inactive',
          },
          {
            createdDate: 1636694772000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 1,
            name: 'Morning',
            modifiedDate: 1636695473000,
            startTime: '09:08 am',
            id: 7,
            endTime: '12:02 pm',
            status: 'Inactive',
          },
          {
            createdDate: 1636694772000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 1,
            name: 'Evening',
            modifiedDate: 1636695473000,
            startTime: '05:02 pm',
            id: 8,
            endTime: '09:02 pm',
            status: 'Inactive',
          },
          {
            createdDate: 1636695473000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 1,
            name: 'Morning',
            modifiedDate: 1636695473000,
            startTime: '09:08 am',
            id: 9,
            endTime: '12:02 pm',
            status: 'Active',
          },
          {
            createdDate: 1636695473000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 1,
            name: 'Evening',
            modifiedDate: 1636695473000,
            startTime: '05:02 pm',
            id: 10,
            endTime: '09:02 pm',
            status: 'Active',
          },
        ],
      },
      id: 1,
      user: {
        lastName: 'JEST',
        address: 'UNITED STATES OF KUTCH',
        role: {
          roleId: 6,
          name_hindi: 'ऑपरेटर_व्यवस्थापक',
          name: 'OPERATOR_ADMIN',
          status: 'Active',
        },
        roleId: 6,
        departmentId: 0,
        photo:
          'https://dpt-mroads-stage.s3.ap-south-1.amazonaws.com/files/company/8520fd62-f922-4c07-bba6-05e77c0e7b06Screenshot20211109at12.54.46PM.png',
        title: '',
        userId: 884,
        firstName: 'JEST',
        emailAddress: 'JEST.OPERATOR@YOPMAIL.COM',
        companyId: 195,
        cognitoUsername: 'f75a69b2-ef86-43dd-bd2b-86c17860a8bd',
        createdDate: 1636612682000,
        phoneNumber: '+910000776277',
        modifiedDate: 1636612682000,
        middleName: '',
        designation: '',
        status: 'Active',
      },
      status: 'Active',
    },
    {
      pincode: 300012,
      shiftId: 4,
      lastName: 'ashishhh',
      updatedBy: 808,
      loginId: 'LOGINIDGANDHIDHAM',
      address: 'United states of gandhidham',
      genderId: 1,
      photo:
        'https://dpt-mroads-stage.s3.ap-south-1.amazonaws.com/files/company/b61da0e2-1512-4718-9442-b421db23e7f9image.png',
      userId: 885,
      firstName: 'ashishhh',
      emailAddress: 'op.employeeashish@yopmail.com',
      createdDate: '2021-11-11T06:43Z',
      shiftDetails: {
        createdDate: 1636612424000,
        updatedBy: 808,
        createdBy: 808,
        locationId: 1,
        name: 'Evening',
        modifiedDate: 1636694772000,
        startTime: '05:02 pm',
        id: 4,
        endTime: '09:02 pm',
        status: 'Inactive',
      },
      phoneNumber: '+910000118882',
      aadharNumber: 'aasjdnajshdajsd',
      createdBy: 884,
      locationId: 1,
      idCardNumber: 'opemployeeashish2',
      modifiedDate: '2021-11-12T06:35Z',
      location: {
        createdDate: 1636612409000,
        updatedBy: 808,
        createdBy: 808,
        name: 'West Gate 2',
        modifiedDate: 1636695473000,
        noOfShifts: 2,
        id: 1,
        limitOfOperators: 2,
        status: 'Active',
        locationShiftMapping: [
          {
            createdDate: 1636612409000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 1,
            name: 'Morning',
            modifiedDate: 1636612424000,
            startTime: '09:05 am',
            id: 1,
            endTime: '12:02 pm',
            status: 'Inactive',
          },
          {
            createdDate: 1636612409000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 1,
            name: 'Evening',
            modifiedDate: 1636612424000,
            startTime: '05:02 pm',
            id: 2,
            endTime: '09:02 pm',
            status: 'Inactive',
          },
          {
            createdDate: 1636612424000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 1,
            name: 'Morning',
            modifiedDate: 1636694772000,
            startTime: '09:08 am',
            id: 3,
            endTime: '12:02 pm',
            status: 'Inactive',
          },
          {
            createdDate: 1636612424000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 1,
            name: 'Evening',
            modifiedDate: 1636694772000,
            startTime: '05:02 pm',
            id: 4,
            endTime: '09:02 pm',
            status: 'Inactive',
          },
          {
            createdDate: 1636694772000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 1,
            name: 'Morning',
            modifiedDate: 1636695473000,
            startTime: '09:08 am',
            id: 7,
            endTime: '12:02 pm',
            status: 'Inactive',
          },
          {
            createdDate: 1636694772000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 1,
            name: 'Evening',
            modifiedDate: 1636695473000,
            startTime: '05:02 pm',
            id: 8,
            endTime: '09:02 pm',
            status: 'Inactive',
          },
          {
            createdDate: 1636695473000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 1,
            name: 'Morning',
            modifiedDate: 1636695473000,
            startTime: '09:08 am',
            id: 9,
            endTime: '12:02 pm',
            status: 'Active',
          },
          {
            createdDate: 1636695473000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 1,
            name: 'Evening',
            modifiedDate: 1636695473000,
            startTime: '05:02 pm',
            id: 10,
            endTime: '09:02 pm',
            status: 'Active',
          },
        ],
      },
      id: 2,
      user: {
        lastName: 'ashishhh',
        address: 'United states of gandhidham',
        role: {
          roleId: 7,
          name_hindi: 'ऑपरेटर_कर्मचारी',
          name: 'OPERATOR_EMPLOYEE',
          status: 'Active',
        },
        roleId: 7,
        departmentId: 0,
        photo:
          'https://dpt-mroads-stage.s3.ap-south-1.amazonaws.com/files/company/b61da0e2-1512-4718-9442-b421db23e7f9image.png',
        title: '',
        userId: 885,
        firstName: 'ashishhh',
        emailAddress: 'op.employeeashish@yopmail.com',
        companyId: 195,
        cognitoUsername: '3e837ff1-4d9e-414b-a594-294588466f2f',
        createdDate: 1636613037000,
        phoneNumber: '+910000118882',
        modifiedDate: 1636613037000,
        middleName: '',
        designation: '',
        status: 'Inactive',
      },
      status: 'Inactive',
    },
    {
      pincode: 300002,
      shiftId: 18,
      lastName: 'test1',
      updatedBy: 808,
      loginId: 'lginajsdnjas',
      address: 'awjdnajwdn',
      genderId: 1,
      photo:
        'https://dpt-mroads-stage.s3.ap-south-1.amazonaws.com/files/company/22b9f58e-1765-44bd-8e94-633ee709ef20Screenshot20211111at10.58.18AM.png',
      userId: 899,
      firstName: 'test1',
      emailAddress: 'emailtest@yopmail.com',
      createdDate: '2021-11-12T06:02Z',
      shiftDetails: {
        createdDate: 1636696873000,
        updatedBy: 808,
        createdBy: 808,
        locationId: 3,
        name: 'Shift Beta',
        modifiedDate: 1636696873000,
        startTime: '11:22 am',
        id: 18,
        endTime: '09:22 pm',
        status: 'Active',
      },
      phoneNumber: '+910000922212',
      aadharNumber: 'aksdaksndja',
      createdBy: 808,
      locationId: 3,
      idCardNumber: 'test123',
      modifiedDate: '2021-11-12T06:02Z',
      location: {
        createdDate: 1636696410000,
        updatedBy: 808,
        createdBy: 808,
        name: 'North Gate',
        modifiedDate: 1636696873000,
        noOfShifts: 2,
        id: 3,
        limitOfOperators: 2,
        status: 'Active',
        locationShiftMapping: [
          {
            createdDate: 1636696410000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 3,
            name: 'Shift Alpha',
            modifiedDate: 1636696873000,
            startTime: '09:22 am',
            id: 15,
            endTime: '08:22 am',
            status: 'Inactive',
          },
          {
            createdDate: 1636696410000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 3,
            name: 'Shift Beta',
            modifiedDate: 1636696873000,
            startTime: '11:22 am',
            id: 16,
            endTime: '09:22 pm',
            status: 'Inactive',
          },
          {
            createdDate: 1636696873000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 3,
            name: 'Shift Alpha',
            modifiedDate: 1636696873000,
            startTime: '09:22 am',
            id: 17,
            endTime: '08:22 am',
            status: 'Active',
          },
          {
            createdDate: 1636696873000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 3,
            name: 'Shift Beta',
            modifiedDate: 1636696873000,
            startTime: '11:22 am',
            id: 18,
            endTime: '09:22 pm',
            status: 'Active',
          },
        ],
      },
      id: 3,
      user: {
        lastName: 'test1',
        address: 'awjdnajwdn',
        role: {
          roleId: 6,
          name_hindi: 'ऑपरेटर_व्यवस्थापक',
          name: 'OPERATOR_ADMIN',
          status: 'Active',
        },
        roleId: 6,
        departmentId: 0,
        photo:
          'https://dpt-mroads-stage.s3.ap-south-1.amazonaws.com/files/company/22b9f58e-1765-44bd-8e94-633ee709ef20Screenshot20211111at10.58.18AM.png',
        title: '',
        userId: 899,
        firstName: 'test1',
        emailAddress: 'emailtest@yopmail.com',
        companyId: 195,
        cognitoUsername: '2bfc2d2c-901a-41bc-a41d-41eabbd0f733',
        createdDate: 1636696932000,
        phoneNumber: '+910000922212',
        modifiedDate: 1636696932000,
        middleName: '',
        designation: '',
        status: 'Active',
      },
      status: 'Active',
    },
    {
      pincode: 300102,
      shiftId: 18,
      lastName: 'test2',
      updatedBy: 808,
      loginId: 'akndkawnID',
      address: 'kanwkdnkawkdn',
      genderId: 1,
      photo:
        'https://dpt-mroads-stage.s3.ap-south-1.amazonaws.com/files/company/b789c051-08f4-48c2-83bf-e1df5c9a5ff7Screenshot20211109at12.08.08PM.png',
      userId: 900,
      firstName: 'test2',
      emailAddress: 'test2.mroads@yopmail.com',
      createdDate: '2021-11-12T06:03Z',
      shiftDetails: {
        createdDate: 1636696873000,
        updatedBy: 808,
        createdBy: 808,
        locationId: 3,
        name: 'Shift Beta',
        modifiedDate: 1636696873000,
        startTime: '11:22 am',
        id: 18,
        endTime: '09:22 pm',
        status: 'Active',
      },
      phoneNumber: '+910000188822',
      aadharNumber: 'askdnakndka',
      createdBy: 808,
      locationId: 3,
      idCardNumber: 'test2ssss',
      modifiedDate: '2021-11-12T06:03Z',
      location: {
        createdDate: 1636696410000,
        updatedBy: 808,
        createdBy: 808,
        name: 'North Gate',
        modifiedDate: 1636696873000,
        noOfShifts: 2,
        id: 3,
        limitOfOperators: 2,
        status: 'Active',
        locationShiftMapping: [
          {
            createdDate: 1636696410000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 3,
            name: 'Shift Alpha',
            modifiedDate: 1636696873000,
            startTime: '09:22 am',
            id: 15,
            endTime: '08:22 am',
            status: 'Inactive',
          },
          {
            createdDate: 1636696410000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 3,
            name: 'Shift Beta',
            modifiedDate: 1636696873000,
            startTime: '11:22 am',
            id: 16,
            endTime: '09:22 pm',
            status: 'Inactive',
          },
          {
            createdDate: 1636696873000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 3,
            name: 'Shift Alpha',
            modifiedDate: 1636696873000,
            startTime: '09:22 am',
            id: 17,
            endTime: '08:22 am',
            status: 'Active',
          },
          {
            createdDate: 1636696873000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 3,
            name: 'Shift Beta',
            modifiedDate: 1636696873000,
            startTime: '11:22 am',
            id: 18,
            endTime: '09:22 pm',
            status: 'Active',
          },
        ],
      },
      id: 4,
      user: {
        lastName: 'test2',
        address: 'kanwkdnkawkdn',
        role: {
          roleId: 6,
          name_hindi: 'ऑपरेटर_व्यवस्थापक',
          name: 'OPERATOR_ADMIN',
          status: 'Active',
        },
        roleId: 6,
        departmentId: 0,
        photo:
          'https://dpt-mroads-stage.s3.ap-south-1.amazonaws.com/files/company/b789c051-08f4-48c2-83bf-e1df5c9a5ff7Screenshot20211109at12.08.08PM.png',
        title: '',
        userId: 900,
        firstName: 'test2',
        emailAddress: 'test2.mroads@yopmail.com',
        companyId: 195,
        cognitoUsername: '5b13b5f3-02ff-437a-aec9-3d59c2ab90f1',
        createdDate: 1636696993000,
        phoneNumber: '+910000188822',
        modifiedDate: 1636696993000,
        middleName: '',
        designation: '',
        status: 'Active',
      },
      status: 'Active',
    },
    {
      pincode: 300091,
      shiftId: 18,
      lastName: 'test3',
      updatedBy: 808,
      loginId: 'lkaoidnwianw',
      address: 'akmdwkmkawd',
      genderId: 1,
      photo:
        'https://dpt-mroads-stage.s3.ap-south-1.amazonaws.com/files/company/033f982f-08e1-4722-a3e7-79f7d758383bScreenshot20211106at1.05.23PM.png',
      userId: 901,
      firstName: 'test3',
      emailAddress: 'email.test3@yopmail.com',
      createdDate: '2021-11-12T06:03Z',
      shiftDetails: {
        createdDate: 1636696873000,
        updatedBy: 808,
        createdBy: 808,
        locationId: 3,
        name: 'Shift Beta',
        modifiedDate: 1636696873000,
        startTime: '11:22 am',
        id: 18,
        endTime: '09:22 pm',
        status: 'Active',
      },
      phoneNumber: '+910002929292',
      aadharNumber: 'asdasdas',
      createdBy: 808,
      locationId: 3,
      idCardNumber: 'tst21323',
      modifiedDate: '2021-11-12T06:03Z',
      location: {
        createdDate: 1636696410000,
        updatedBy: 808,
        createdBy: 808,
        name: 'North Gate',
        modifiedDate: 1636696873000,
        noOfShifts: 2,
        id: 3,
        limitOfOperators: 2,
        status: 'Active',
        locationShiftMapping: [
          {
            createdDate: 1636696410000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 3,
            name: 'Shift Alpha',
            modifiedDate: 1636696873000,
            startTime: '09:22 am',
            id: 15,
            endTime: '08:22 am',
            status: 'Inactive',
          },
          {
            createdDate: 1636696410000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 3,
            name: 'Shift Beta',
            modifiedDate: 1636696873000,
            startTime: '11:22 am',
            id: 16,
            endTime: '09:22 pm',
            status: 'Inactive',
          },
          {
            createdDate: 1636696873000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 3,
            name: 'Shift Alpha',
            modifiedDate: 1636696873000,
            startTime: '09:22 am',
            id: 17,
            endTime: '08:22 am',
            status: 'Active',
          },
          {
            createdDate: 1636696873000,
            updatedBy: 808,
            createdBy: 808,
            locationId: 3,
            name: 'Shift Beta',
            modifiedDate: 1636696873000,
            startTime: '11:22 am',
            id: 18,
            endTime: '09:22 pm',
            status: 'Active',
          },
        ],
      },
      id: 5,
      user: {
        lastName: 'test3',
        address: 'akmdwkmkawd',
        role: {
          roleId: 6,
          name_hindi: 'ऑपरेटर_व्यवस्थापक',
          name: 'OPERATOR_ADMIN',
          status: 'Active',
        },
        roleId: 6,
        departmentId: 0,
        photo:
          'https://dpt-mroads-stage.s3.ap-south-1.amazonaws.com/files/company/033f982f-08e1-4722-a3e7-79f7d758383bScreenshot20211106at1.05.23PM.png',
        title: '',
        userId: 901,
        firstName: 'test3',
        emailAddress: 'email.test3@yopmail.com',
        companyId: 195,
        cognitoUsername: '5e077172-1636-4857-beaa-76e9ed738ab3',
        createdDate: 1636697039000,
        phoneNumber: '+910002929292',
        modifiedDate: 1636697039000,
        middleName: '',
        designation: '',
        status: 'Active',
      },
      status: 'Active',
    },
  ];
};
