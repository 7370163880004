import React, {useState, useEffect} from 'react';
import styles from './Desktop.module.scss';
import './styles.css';
import strings from '../../../../../../globalization';
import {withRouter} from 'react-router-dom';
import {AgGridReact} from 'ag-grid-react';
import Select from 'react-select';
import moment from 'moment';
import PermitsHeader from '../../../../../../components/PermitsHeader';
import 'react-daterange-picker/dist/css/react-calendar.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import dimensions from '../../.../../../../../../util/dimensions-util';
import {customStyles} from './customStyles';
import DateRangePicker from 'react-daterange-picker';
import TextInput from '../../../../../../components/TextInput';
import Button from '../../../../../../components/Button';

import calendarBlue from '../../../../../../assets/images/calendar-blue-icon/image.png';
import downloadIcon from '../../../../../../assets/images/download-icon/download-icon.png';

import Flex from '../../../../../../components/Flex';
import {CSVLink} from 'react-csv';
import TimePicker from 'rc-time-picker';
import SannaLogo from '../../../../../../components/SannaLogo';

const {vw: widthConstant, vh: heightConstant} = dimensions;
const tabs = [
  {
    label: strings.AGENCY.PERMITS.requestNemPermit,
    id: 1,
    route: 'request-new-permits',
  },
  {
    label: strings.AGENCY.PERMITS.pending,
    id: 2,
    route: 'pending-permits',
  },
  {
    label: strings.AGENCY.PERMITS.approvedRejected,
    id: 3,
    route: 'approved-rejected-permits',
  },
];

const STATUS_MAPPER = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  TRIP_COMPLETED: 'TRIP COMPLETED',
  VEHICLE_DELINKED: 'VEHICLE DELINKED',
  PERSON_DELINKED: 'PERSON DELINKED',
};

const requestTypeList = [
  {
    id: 1,
    name: 'Person',
  },
  {
    id: 2,
    name: 'Vehicle',
  },
];

const Desktop = props => {
  const {
    onClick,
    onChange,
    statuses,
    selectedStatus,
    selectedDropDownStatus,
    searchText,
    dates,
    startDate,
    endDate,
    perPage,
    hideStatusFilter,
    permitList,
    startTime,
    endTime,
    totalCount,
  } = props;
  console.log('permitList', permitList);

  const isPermitExpired = d => {
    // Adding 32 hours to visiting date (12hours+8hours)
    // Because it will expired on next day at 8:00AM.
    const dateInMilliseconds = new Date(d).getTime() + 115200000;
    const currentTimeMilliseconds = new Date().getTime();
    if (currentTimeMilliseconds > dateInMilliseconds) {
      return true;
    } else {
      return false;
    }
  };

  const columnDefs = [
    {
      headerName: strings.AGENCY.PERMITS.referenceNumber,
      field: 'referenceId',
      headerClass: styles.rowHeader,
      width: 200 * widthConstant,
      autoHeight: true,
      resizable: true,
      wrapText: true,
    },
    {
      headerName: strings.AGENCY.PERMITS.requestType,
      field: 'requestType',
      headerClass: styles.rowHeader,
      width: 160 * widthConstant,
      autoHeight: true,
      resizable: true,
      wrapText: true,
      cellRenderer: params => {
        if (params?.data !== undefined) {
          const {data} = params;
          const {persons, vehicles} = data;
          const {requestType} = data;
          return `<div className=${styles.sessionTime}>
                ${requestType}
              </div>`;
        }
      },
    },
    {
      headerName: 'Vehicle Number',
      field: 'vehicleRegistrationNumber',
      headerClass: styles.rowHeader,
      width: 400 * widthConstant,
      // flex: 1,
      autoHeight: true,
      resizable: true,
      wrapText: true,
      cellStyle: {display: 'flex', alignItems: 'center'},
      cellRenderer: params => {
        if (params?.data !== undefined) {
          const {data} = params;
          const {
            persons,
            vehicles,
            vehicleOwnerName,
            vehicleRegistrationNumber,
          } = data;
          return `<div className=${styles.sessionTime}>
                ${vehicleRegistrationNumber ? vehicleRegistrationNumber : '-'}
                ${vehicleOwnerName ? '/' + vehicleOwnerName : '-'}
              </div>`;
        }
      },
    },

    {
      headerName: strings.AGENCY.PERMITS.requestDate,
      field: 'createdDate',
      headerClass: styles.rowHeader,
      width: 220 * widthConstant,
      // flex: 1,
      resizable: true,
      autoHeight: true,
      wrapText: true,
      cellStyle: {display: 'flex', alignItems: 'center', lineHeight: 1.5},
      cellRenderer: params => {
        if (params.value !== undefined) {
          const {data} = params;
          const {createdDate} = data;
          return `<div className=${styles.sessionTime}>
                ${
                  createdDate
                    ? moment(createdDate).format('MMMM Do YY HH:mm')
                    : null
                }
              </div>`;
        }
      },
    },
    {
      headerName: strings.AGENCY.PERMITS.visitDate,
      field: 'visitingDate',
      headerClass: styles.rowHeader,
      width: 140 * widthConstant,
      resizable: true,
      autoHeight: true,
      wrapText: true,
      cellRenderer: params => {
        if (params.value !== undefined) {
          const {data} = params;
          const {visitingDate} = data;
          return `<div className=${styles.sessionTime}>
                ${
                  visitingDate
                    ? moment(visitingDate).format('DD/MM/YYYY')
                    : null
                }
              </div>`;
        }
      },
    },
    {
      headerName: strings.AGENCY.PERMITS.purposeOfWork,
      field: 'workAreaName',
      headerClass: styles.rowHeader,
      width: 450 * widthConstant,
      resizable: true,
      autoHeight: true,
      wrapText: true,
      cellStyle: {
        lineHeight: 1.5,
        wordBreak: 'initial',
      },
      cellRenderer: params => {
        if (params?.data !== undefined) {
          const {data} = params;
          const {workPurpose} = data;
          return `<div className=${styles.sessionTime}>${
            workPurpose?.length > 90
              ? workPurpose.substring(0, 90) + '...'
              : workPurpose
          }</div>`;
        }
      },
    },
    {
      headerName: strings.AGENCY.PERMITS.status,
      field: 'status',
      headerClass: styles.rowHeader,
      width: 180 * widthConstant,
      resizable: true,
      cellStyle: {display: 'flex', alignItems: 'center'},
      cellRendererFramework: params => {
        const {data} = params;
        return (
          <div
            className={[
              styles.status,
              data?.status === 'APPROVED' ? styles.statusApproved : '',
              data?.status === 'REJECTED' ? styles.statusRejected : '',
              data?.status === 'TRIP_COMPLETED' ? styles.statusApproved : '',
            ].join(' ')}>
            <div className={styles.statusLabel}>
              <div className={styles.roundIcon}></div>
              {STATUS_MAPPER[data?.status]}
            </div>
          </div>
        );
      },
    },
    {
      headerName: 'Action',
      field: 'actions',
      headerClass: styles.rowHeader,
      width: 200 * widthConstant,
      resizable: true,
      cellStyle: {display: 'flex', alignItems: 'center'},
      cellRendererFramework: params => {
        const {data} = params;
        const isNotExpired =
          data !== undefined ? !isPermitExpired(data.visitingDate) : false;
        return (
          <Flex direction="row">
            <Flex direction="row">
              <button
                disabled={
                  isNotExpired &&
                  (data?.status === 'APPROVED' ||
                    data?.status === 'TRIP_COMPLETED')
                    ? false
                    : true
                }
                onClick={event => {
                  event.preventDefault();
                  onClick('delink', data);
                }}
                className={styles.driverBtn}>
                Delink
              </button>
            </Flex>
            <button
              disabled={
                data?.status === 'APPROVED' ||
                data?.status === 'TRIP_COMPLETED' ||
                data?.status === 'VEHICLE_DELINKED' ||
                data?.status === 'PERSON_DELINKED'
                  ? false
                  : true
              }
              onClick={event => {
                event.preventDefault();
                onClick('print-permit', data);
              }}
              className={styles.driverBtn}>
              Print
            </button>
          </Flex>
        );
      },
    },

    // {
    //   headerName: 'Print',
    //   field: 'actions',
    //   headerClass: styles.rowHeader,
    //   width: 200 * widthConstant,
    //   resizable: true,
    //   cellStyle: {display: 'flex', alignItems: 'center'},
    //   cellRendererFramework: ({data}) => {
    //     return (
    //       <button
    //         disabled={data.status === 'APPROVED' ? false : true}
    //         onClick={event => {
    //           event.preventDefault();
    //           onClick('print-permit', data);
    //         }}
    //         className={styles.driverBtn}>
    //         Print
    //       </button>
    //     );
    //   },
    // },
  ];
  const [showDate, setShowDate] = useState(false);
  const [startTimePanel, setStartTimePanel] = useState(null);
  const [endTimePanel, setEndTimePanel] = useState(null);

  const [focusedInput, setFocusedInput] = useState(null);

  const onCellClick = (event, index) => {
    if (event.column.colId === 'actions') {
      // onClick('change-driver', event.data);
    } else {
      onClick('rowClick', event.data);
    }
  };
  const onSelect = dates => {
    onChange('date', {
      startDate: moment(dates.start).format('MM-DD-YYYY'),
      endDate: moment(dates.end).format('MM-DD-YYYY'),
      dates,
    });
    toggleDate();
  };
  const toggleDate = () => {
    setShowDate(!showDate);
  };

  const [gridApi, setGridApi] = useState(null);
  const onGridReady = params => {
    setGridApi(params.api);
    onChange('gridAPI', params.api);
  };
  const updateTable = () => {
    onChange('updateTable', null);
  };

  useEffect(() => {
    console.log(gridApi);
    if (gridApi && totalCount > 10) {
      console.log('changing to normal');
      gridApi.setDomLayout('normal');
    } else if (gridApi) {
      console.log('changing to autoHeight');
      gridApi.setDomLayout('autoHeight');
    }
  }, [totalCount]);

  useEffect(() => {
    console.log(gridApi);
    updateTable();
  }, [gridApi, startDate, endDate, selectedStatus]);

  const onTabClick = id => {
    switch (id) {
      case 1:
        onClick('statusRoute', {route: 'request-permits', id: id});
        break;
      case 2:
        onClick('statusRoute', {route: 'pending-permits', id: id});
        break;
      case 3:
        onClick('statusRoute', {route: 'approved-rejected-permits', id: id});
        break;
      default:
        break;
    }
  };

  const formatDate = date => moment(date, 'MM-DD-YYYY').format('MMM Do YY');
  const rowStyle = {border: 'none', cursor: 'pointer'};
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return {background: '#f6f6f6', border: 'none'};
    }
  };

  const handleClear = () => {
    setShowDate(false);
    onClick('clearFilters');
  };
  const handleInput = text => {
    setShowDate(false);
    onChange('search', text);
  };
  const handleSelect = name => {
    setShowDate(false);
    onChange('statusChange', name);
  };
  const handleButtonClick = () => {
    setShowDate(false);
    onClick('apply');
  };
  const handleCellClick = (event, index) => {
    setShowDate(false);
    onCellClick(event, index);
  };

  const getColumns = () => {
    if (selectedStatus === 'PENDING') {
      return columnDefs.filter(el => el.field !== 'status');
    } else {
      return columnDefs;
    }
  };
  return (
    <div className={styles.agencyContainer}>
      <PermitsHeader
        title="Permits"
        onTabClick={onTabClick}
        tabs={tabs}
        activeTab={selectedStatus === 'PENDING' ? 2 : 3}
      />
      <div className={styles.bodyContainer}>
        <div className={styles.header}>
          <div className={styles.left}>
            {!hideStatusFilter ? (
              <div className={styles.agencyNameDropdown}>
                <Select
                  options={statuses}
                  getOptionLabel={option => option.displayName}
                  getOptionValue={option => option.id}
                  placeholder={'Select status'}
                  styles={customStyles}
                  value={statuses.filter(
                    option => option?.id === selectedDropDownStatus?.id,
                  )}
                  onChange={handleSelect}
                />
              </div>
            ) : null}
            <div className={styles.dateWrapper}>
              <div onClick={toggleDate} className={styles.inputDate}>
                <div>
                  {dates
                    ? formatDate(dates.start) + '-' + formatDate(dates.end)
                    : strings.ADMIN.dateRange}
                </div>
                <img src={calendarBlue} className={styles.calendar} alt="C" />
              </div>
              {showDate ? (
                <div className={styles.datePicker}>
                  <DateRangePicker
                    numberOfCalendars={1}
                    selectionType="range"
                    onSelect={onSelect}
                    className={styles.dateRange}
                    value={dates}
                    singleDateRange={true}
                    focusedInput={focusedInput}
                    onFocusChange={e => setFocusedInput(e)}
                  />
                </div>
              ) : null}
            </div>
            <div className={styles.timeWrapper}>
              <div>
                <TimePicker
                  // use12Hours
                  addon={panel => setStartTimePanel(panel)}
                  showSecond={false}
                  // FIXME: Change these
                  value={startTime}
                  onChange={time => {
                    onChange('startTime', time);
                    if (
                      startTimePanel?.state?.currentSelectPanel === 'minute'
                    ) {
                      startTimePanel.close();
                    }
                  }}
                  className={styles.timepicker}
                  placeholder={`Start Time`}
                  format={'HH:mm'}
                />
                <TimePicker
                  // use12Hours
                  showSecond={false}
                  // FIXME: Change these
                  addon={panel => setEndTimePanel(panel)}
                  value={endTime}
                  onChange={time => {
                    onChange('endTime', time);
                    if (endTimePanel?.state?.currentSelectPanel === 'minute') {
                      endTimePanel.close();
                    }
                  }}
                  className={styles.timepicker}
                  placeholder={`End Time`}
                  format={'HH:mm'}
                />
              </div>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.inputSearch}>
              <TextInput
                onChange={handleInput}
                isEdit={true}
                type={'text'}
                value={searchText}
                inputStyle={styles.inputText}
                showSeacrh={true}
                placeholder="Search"
              />
            </div>
            <div onClick={handleClear} className={styles.clearLabel}>
              {strings.ADMIN.clear}
            </div>
            <Button
              onClick={handleButtonClick}
              buttonStyle="primary"
              label={strings.ADMIN.apply}
              className={styles.applyBtn}></Button>
            <div>
              {permitList && permitList.length > 0 ? (
                <img
                  className={styles.downloadIcon}
                  onClick={() => onClick('loadCSV')}
                  src={downloadIcon}
                  alt="icon"
                />
              ) : null}
            </div>
          </div>
        </div>
        <div
          className={[
            'ag-theme-balham agencyPermitsTable',
            styles.tableWrapper,
            totalCount > 10
              ? styles.tableWrapperNormal
              : styles.tableWrapperAutoHeight,
          ].join(' ')}>
          <AgGridReact
            rowStyle={rowStyle}
            getRowStyle={getRowStyle}
            rowHoverHighlight={true}
            columnDefs={getColumns()}
            rowHeight={60 * heightConstant}
            headerHeight={60 * heightConstant}
            defaultColDef={{sortable: true}}
            rowClass={[styles.gridRowClass]}
            onCellClicked={handleCellClick}
            pagination={true}
            rowModelType={'infinite'}
            paginationPageSize={perPage}
            onGridReady={onGridReady}
            cacheBlockSize={perPage}
            domLayout="autoHeight"></AgGridReact>
        </div>
        {/* <div className={styles.bottomLabel}>
          Showing {filterAgencies.length}
        </div> */}
      </div>
      <div className={styles.SannaLogo}>
        <SannaLogo />
      </div>
    </div>
  );
};
export default withRouter(Desktop);
