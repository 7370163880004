import Auth from '@aws-amplify/auth';
import React, {useState, useEffect} from 'react';
import {Route, Redirect} from 'react-router-dom';
import Spinner from '../Spinner';
import {API} from '../../requests';
import {dispatchSetLoginData, store} from '../../store';
// import UTIL from "../../util";
// import { auth } from "../../config/firebase";
import PopupContainer from '../PopupContainer';
// import Spinner from "../Spinner";
// import store from "../../store";

const PublicRoute = ({component: Component, tabs, restricted, ...rest}) => {
  // console.info(UTIL.isLogin());
  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route
      {...rest}
      render={props => {
        if (restricted) {
          return <CheckLoggedIn Component={Component} {...props} />;
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

/**
 *
 * @param {Props} props
 * @return {JSX.Element}
 */
function CheckLoggedIn({Component, ...props}: Props) {
  const [state, setState] = useState('spinner');
  const {login} = store.getState();

  useEffect(() => {
    if (!login.loginInfo.user) {
      Auth.currentSession()
        .then(session => {
          const {idToken = {}} = session;
          const {jwtToken = ''} = idToken;
          return API.login(jwtToken).then(response => {
            const {token, user} = response.data;
            console.info('User', user);
            API.setBearerToken(token);
            dispatchSetLoginData(response.data);
            setState('redirect');
          });
        })
        .catch(() => {
          setState('component');
        });
    } else {
      setState('redirect');
    }
    API.getPreLoginToken()
    .then(response => {
      console.log(response);
      API.setPreLoginToken(response);
    })
    .catch(error => {
      //this.showErrorPopup('');
      console.log(error);
    });
    return () => {
      // unsubscriber && unsubscriber();
    };
  }, [login.isLoggedIn, login.loginInfo.user]);

  if (state === 'spinner') {
    return (
      <PopupContainer>
        <Spinner></Spinner>
      </PopupContainer>
    );
  } else if (state === 'redirect') {
    return <Redirect to="/u" />;
  } else {
    return <Component {...props} />;
  }
}

export default PublicRoute;
