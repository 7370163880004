import React, {Component} from 'react';

import strings from '../../globalization';
import PopupContainer from '../../components/PopupContainer';
import Spinner from '../../components/Spinner';
import styles from './styles.module.scss';
import Popup from '../../components/SimplePopup';
import SimplePopupWithComponent from '../../components/SimplePopupWithComponent';

import {withRouter} from 'react-router-dom';
import ViewRenderer from './views';
import REQUEST_TYPE_OPTIONS from '../../constants/request-type-options';
import Approved from '../../components/Approved';
import Pending from '../../components/Pending';
import STATUSES from '../../constants/statuses-constants';
import Rejected from '../../components/Rejected';
import PaymentFailed from '../../components/Credits/PaymentFailed';
import PaymentPending from '../../components/PaymentPending';
import PaymentComplete from '../../components/PaymentComplete';
import {commonImages, operatorImages, adminImages} from '../../assets/images';
import OtpPopup from '../../components/OtpPopup';
import RegistrationStatus from '../../components/RegistrationStatus';

import {API} from '../../requests/index';
import util from '../../util';
import PAYMENT_CONSTANTS from '../../constants/payment-constants';
import Invoice from './Invoice';
import moment from 'moment';
import SurrenderComponentWithSteps from '../../components/ManageRegistrationInformationComponents/SurrenderComponentWithSteps';
import LostComponentWithSteps from '../../components/ManageRegistrationInformationComponents/LostComponentWithSteps';
const POPUP_CONSTANTS = {
  STANDARD_POPUP: 'standard-popup',
  ERROR_POPUP: 'error-popup',
  SPINNER_POPUP: 'spinner-popup',
  USER_NOT_CONFIRM: 'user-not-confirm',
  SIMPLE_POPUP: 'simple-popup',
  PRIVACY_POPUP: 'privacy-popup',
  OTP_POPUP: 'otp-popup',
  STATUS_POPUP: 'status-popup',
  PERSON_INVOICE_POPUP: 'person-invoice-popup',
  VEHICLE_INVOICE_POPUP: 'vehicle-invoice-popup',
  SURRENDER_POPUP: 'surrender-popup',
  LOST_POPUP: 'lost-popup',
};

const STATUS_CONSTANTS = {
  PAYMENT_PENDING: 'Payment Pending',
  PAYMENT_COMPLETED: 'Registration Submitted',
  PAYMENT_FAILED: 'Payment Failed ',
  OPERATOR_ADMIN_APPROVED: 'Registration Pending',
  OPERATOR_ADMIN_REJECTED: 'Registration Unsuccessful',
  DEPARTMENT_ADMIN_APPROVED: 'Registration Approved',
  DEPARTMENT_ADMIN_REJECTED: 'Registration Unsuccessful',
  ISSUED: 'RFID Issued',
  HOLD: 'RFID Hold',
  SURRENDER: 'RFID Surrender',
  UPDATED: 'Details Updated',
  UPDATE_REJECTED: 'Update Rejected',
  REISSUE_PAYMENT_PENDING: 'Reissue Payment Pending',
  REISSUE_PAYMENT_COMPLETED: 'Reissue Payment Completed',
  REISSUE_PAYMENT_FAILED: 'Reissue Payment Failed',
};

const DISABLE_EDIT_STATUSES = [
  'OPERATOR_ADMIN_APPROVED',
  'DEPARTMENT_ADMIN_APPROVED',
  'HOLD',
  'SURRENDER',
  'REISSUE_PAYMENT_PENDING',
  'REISSUE_PAYMENT_COMPLETED',
  'REISSUE_PAYMENT_FAILED',
];

class ManageRegistrationInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popup: null,
      referenceId: '',
      status: null,
      returnedObject: null,
      requestType: null,
      otpVerified: false,
      requestTypeOptions: [
        {
          name: REQUEST_TYPE_OPTIONS.PERSON,
          id: 1,
        },
        {
          name: REQUEST_TYPE_OPTIONS.VEHICLE,
          id: 2,
        },
        {
          name: REQUEST_TYPE_OPTIONS.COMPANY,
          id: 3,
        },
      ],
    };
  }

  componentDidMount() {
    this.getPreLoginToken();
  }
  getPreLoginToken = () => {
    API.getPreLoginToken()
      .then(response => {
        console.log(response);
        API.setPreLoginToken(response);
      })
      .catch(error => {
        //this.showErrorPopup('');
        console.log(error);
      });
  };

  getPopupContent = () => {
    const {
      popup,
      phoneNumber,
      currentInvoiceData,
      personDetails,
      vehicleDetails,
      requestType,
    } = this.state;

    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP_WITH_COMPONENT: {
        return <SimplePopupWithComponent {...popup} />;
      }
      case POPUP_CONSTANTS.PERSON_INVOICE_POPUP: {
        return (
          <Invoice
            type="person"
            invoiceData={currentInvoiceData}
            data={personDetails}
            onClose={this.getPerson}
          />
        );
      }
      case POPUP_CONSTANTS.VEHICLE_INVOICE_POPUP: {
        return (
          <Invoice
            type="vehicle"
            invoiceData={currentInvoiceData}
            data={vehicleDetails}
            onClose={this.getVehicle}
          />
        );
      }
      case POPUP_CONSTANTS.OTP_POPUP: {
        return (
          <OtpPopup
            onClose={this.closePopup}
            showSuccessPopup={() =>
              this.showSuccessPopup('Phone number verified successfully!')
            }
            showErrorPopup={errorMessage => {
              this.showErrorPopup(errorMessage);
            }}
            phoneNumber={this.state?.currentOtpVerifiactionObject.phoneNumber}
            onOtpVerifySuccess={this.onOtpVerifySuccess}
            message={
              strings.message + phoneNumber.substr(phoneNumber.length - 4)
            }
            resendOTP={this.generateOtp}
          />
        );
      }
      case POPUP_CONSTANTS.STATUS_POPUP: {
        return <RegistrationStatus {...popup} />;
      }
      case POPUP_CONSTANTS.SURRENDER_POPUP: {
        return (
          <SurrenderComponentWithSteps
            showSuccessPopup={() =>
              this.showSuccessPopup('Phone number verified successfully!')
            }
            phoneNumber={
              requestType === 1
                ? personDetails?.phoneNumber
                : vehicleDetails?.phoneNumber
            }
            {...popup}
          />
        );
      }
      case POPUP_CONSTANTS.LOST_POPUP: {
        return (
          <LostComponentWithSteps
            showSuccessPopup={() =>
              this.showSuccessPopup('Phone number verified successfully!')
            }
            phoneNumber={
              requestType === 1
                ? personDetails?.phoneNumber
                : vehicleDetails?.phoneNumber
            }
            {...popup}
          />
        );
      }
      default: {
        console.warn('case not handled', popup.type);
        return null;
      }
    }
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };
  showSuccessPopup = successMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: successMessage,
        onClose: this.closePopup,
        headingImage: operatorImages.successIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  showSuccessPopupComponent = messageComponent => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP_WITH_COMPONENT,
        children: messageComponent,
        onClose: this.closePopup,
        headingImage: operatorImages.successIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  surrenderStepOne = (entityType, data) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SURRENDER_POPUP,
        entity: entityType === 'person' ? 'card' : 'tag',
        entityType: entityType,

        onClose: this.closePopup,
        onOutsideClick: this.closePopup,
        onSuccess:
          entityType === 'person'
            ? () => this.handlePersonSurrender(data)
            : () => this.handleVehicleSurrender(data),
      },
    });
  };

  handlePersonSurrender = data => {
    this.showSpinner();

    let formData = {
      id: data.id,
      remarks:
        'Person card surrendered by the user from Manage Registration Information.',
    };

    API.surrenderPersonPassSelf(formData)
      .then(res => {
        console.log('res', res);
        this.showSuccessPopupComponent(
          <div className={styles.surrenderPopupComponent}>
            <p className={styles.surrenderPopupComponentHeading}>
              Your card has been successfully surrendered
            </p>
            <p>RFID Card Number: {data?.rfidnumber}</p>
            <p>Person Name: {data?.name}</p>
          </div>,
        );
      })
      .catch(error => {
        console.log(error);
        const errorMsg = util.getErrorMessage(error);
        this.showErrorPopup(errorMsg);
      });
  };
  handleVehicleSurrender = data => {
    this.showSpinner();

    let formData = {
      id: data.id,
      remarks:
        'Vehicle Tag surrendered by the user from Manage Registration Information.',
    };

    API.surrenderVehiclePassSelf(formData)
      .then(res => {
        console.log('res', res);
        this.showSuccessPopupComponent(
          <div className={styles.surrenderPopupComponent}>
            <p className={styles.surrenderPopupComponentHeading}>
              Your tag has been successfully surrendered
            </p>
            <p>RFID Tag Number: {data?.rfidnumber}</p>
            <p>
              Vehicle Registration Number: {data?.vehicleRegistrationNumber}
            </p>
          </div>,
        );
      })
      .catch(error => {
        console.log(error);
        const errorMsg = util.getErrorMessage(error);
        this.showErrorPopup(errorMsg);
      });
  };

  handlePersonLost = data => {
    this.showSpinner();

    let formData = {
      id: data.id,
      remarks:
        'Person card Marked as Lost / Damaged by the user from Manage Registration Information.',
    };

    API.lostPersonCard(formData)
      .then(res => {
        console.log('res', res);
        this.showSuccessPopupComponent(
          <div className={styles.surrenderPopupComponent}>
            <p className={styles.surrenderPopupComponentHeading}>
              Your Card Details have been registered as lost/damaged
            </p>
            {/* <p>RFID Card Number: {data?.rfidnumber}</p> */}
            {/* <p>Person Name: {data?.name}</p> */}
          </div>,
        );
      })
      .catch(error => {
        console.log(error);
        const errorMsg = util.getErrorMessage(error);
        this.showErrorPopup(errorMsg);
      });
  };
  handleVehicleLost = data => {
    this.showSpinner();

    let formData = {
      id: data.id,
      remarks:
        'Vehicle Tag marked as Lost / Damaged by the user from Manage Registration Information.',
    };

    API.lostVehicleTag(formData)
      .then(res => {
        console.log('res', res);
        this.showSuccessPopupComponent(
          <div className={styles.surrenderPopupComponent}>
            <p className={styles.surrenderPopupComponentHeading}>
              Your Tag Details have been registered as lost/damaged
            </p>
            {/* <p>RFID Tag Number: {data?.rfidnumber}</p>
            <p>
              Vehicle Registration Number: {data?.vehicleRegistrationNumber}
            </p> */}
          </div>,
        );
      })
      .catch(error => {
        console.log(error);
        const errorMsg = util.getErrorMessage(error);
        this.showErrorPopup(errorMsg);
      });
  };

  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  showOtpPopup = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.OTP_POPUP,
      },
    });
  };

  closePopup = () => {
    this.setState({
      popup: null,
    });
  };
  onClickHandler = action => {
    const {history} = this.props;
    switch (action) {
      case 'privacy': {
        this.showPrivacyPopup(POPUP_CONSTANTS.PRIVACY_POPUP);
        break;
      }
      case 'terms': {
        this.showPrivacyPopup(POPUP_CONSTANTS.TERMS_POPUP);
        break;
      }
      case 'checkApplicationStatus': {
        history.push('/check-application-status');
        break;
      }
      case 'goBackToLogin': {
        history.push('/signin');
        break;
      }
      case 'payment': {
        this.displayRazorpay();
        break;
      }
      default: {
        break;
      }
    }
  };
  getTextContent = (entity, returnedObject) => {
    console.log(entity);
    switch (entity) {
      case REQUEST_TYPE_OPTIONS.PERSON:
        return returnedObject.name;
      case REQUEST_TYPE_OPTIONS.VEHICLE:
        return returnedObject.name;
      case REQUEST_TYPE_OPTIONS.COMPANY:
        return returnedObject.name;
      default:
        return null;
    }
  };
  getStatusContent = () => {
    if (!this.state.returnedObject) return;
    const {returnedObject} = this.state;
    let status = returnedObject.status;
    console.log('STATUS', status);
    let entity;
    if (this.state.requestType === 1) {
      entity = REQUEST_TYPE_OPTIONS.PERSON;
    } else if (this.state.requestType === 2) {
      entity = REQUEST_TYPE_OPTIONS.VEHICLE;
    } else {
      entity = REQUEST_TYPE_OPTIONS.COMPANY;
    }
    switch (status) {
      case STATUSES.APPROVED:
        return (
          <Approved
            returnedObject={returnedObject}
            entity={entity}
            onClick={this.handleClickHerePress}
            handleLoginClick={this.handleLoginClick}
            getTextContent={this.getTextContent}
            containerClassNames={styles.containerClassNames}
          />
        );

      case STATUSES.PENDING:
        return (
          <Pending
            returnedObject={returnedObject}
            entity={entity}
            onPress={this.handleClickHerePress}
            getTextContent={this.getTextContent}
          />
        );

      case STATUSES.REJECTED:
        return (
          <Rejected
            returnedObject={returnedObject}
            entity={entity}
            onClick={this.handleClickHerePress}
            getTextContent={this.getTextContent}
            containerClassNames={styles.containerClassNames}
          />
        );
      case STATUSES.PAYMENT_FAILED:
        return (
          <PaymentFailed
            returnedObject={returnedObject}
            entity={entity}
            onClick={this.handleClickHerePress}
            getTextContent={this.getTextContent}
            containerClassNames={styles.containerClassNames}
          />
        );
      case STATUSES.PAYMENT_PENDING:
        return (
          <PaymentPending
            returnedObject={returnedObject}
            entity={entity}
            onPress={this.handleClickHerePress}
            getTextContent={this.getTextContent}
          />
        );
      case STATUSES.PAYMENT_COMPLETED:
        return (
          <PaymentComplete
            returnedObject={returnedObject}
            entity={entity}
            onClick={this.handleClickHerePress}
            handleLoginClick={this.handleLoginClick}
            getTextContent={this.getTextContent}
            containerClassNames={styles.containerClassNames}
          />
        );
      default:
        return null;
    }
  };
  handleBackClick = () => {
    const {history} = this.props;
    history.push('/signin');
  };

  handleLoginClick = () => {
    const {history} = this.props;
    history.push('/signin');
  };
  onForgotClick = () => {
    const {history} = this.props;
    history.push('/get-reference-number');
  };

  handleClickHerePress = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: strings.loginDetailsSent,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
          },
        ],
        onClose: this.closePopup,
        messageStyle: {
          color: '#202428',
          fontWeight: 'normal',
        },
      },
    });
  };

  showErrorPopup = errorMsg => {
    // crashlytics().recordError(new Error(errorMsg));

    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMsg,
        messageStyle: {color: '#E67717'},

        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
          },
        ],
        onClose: this.closePopup,
      },
    });
  };
  onChangeHandler = (field, value) => {
    console.log('field', field, 'value', value);
    this.reset();
    if (field === 'requestType') {
      this.setState({
        returnedObject: null,
        otpVerified: false,
      });
    }
    let preparedStateObject = {};
    preparedStateObject[field] = value;
    this.setState(preparedStateObject);
  };

  reset = () => {
    this.setState({
      otpVerified: false,
    });
  };

  onSubmit = () => {
    const {requestType, referenceId} = this.state;
    console.log(this.state);
    this.showSpinner();

    if (!requestType) {
      this.showErrorPopup(strings.pleaseSelectRequestType);
    } else if (!referenceId) {
      this.showErrorPopup(strings.pleaseEnterReferenceNumber);
    } else {
      if (requestType === 1) {
        this.verifyPerson();
      } else if (requestType === 2) {
        this.verifyVehicle();
      } else {
        this.verifyCompany();
      }
    }
  };

  onUploadNOC = () => {
    const {history} = this.props;
    history.push('/upload-police-noc');
  };

  openOTPPopUp = () => {
    this.setState(
      {
        currentOtpVerifiactionObject: {
          field: 'phoneNumber',
          phoneNumber: this.state['phoneNumber'],
          verifiedNumberFields: {
            ...this.state?.currentOtpVerifiactionObject?.verifiedNumberFields,
          },
        },
      },
      () => {
        this.generateOtp(this.state.currentOtpVerifiactionObject.phoneNumber);
      },
    );
  };

  generateOtp = async phoneNumber => {
    console.log(phoneNumber);
    this.showSpinner();
    if (!phoneNumber || phoneNumber.toString().length !== 10) {
      this.showErrorPopup('Please enter a valid phone number', phoneNumber);
    } else {
      const result = util.validatePhoneNumber(phoneNumber);
      if (!result) {
        this.showErrorPopup('Please enter a valid phone number');
        return;
      }
      await API.generateOtp({phoneNumber: `+91${phoneNumber}`})
        .then(res => {
          this.showOtpPopup();
        })
        .catch(error => {
          console.log(error);
          // const errorMsg = error.response
          const errorMsg = util.getErrorMessage(error);
          // const errorMsg =
          //   'Invalid Phone Number. Please enter a valid phone number.';
          this.showErrorPopup(errorMsg);
        });
    }
  };

  onOtpVerifySuccess = isSuccess => {
    const {currentOtpVerifiactionObject} = this.state;
    if (true) {
      this.setState({
        otpVerified: isSuccess,
        currentOtpVerifiactionObject: {
          ...this.state.currentOtpVerifiactionObject,
          otpVerified: isSuccess,
          verifiedNumberFields: {
            ...this.state.currentOtpVerifiactionObject.verifiedNumberFields,
            [currentOtpVerifiactionObject.field]: isSuccess,
          },
        },
      });
    }
    console.log(this.state);
  };

  statusCard = data => {
    const {
      heading,
      icon,
      buttons,
      content,
      referenceId,
      status,
      remarks,
      reason,
      invoiceData,
      type,
    } = data;

    return (
      <>
        <div className={styles.blueText}>Reference Number - {referenceId}</div>

        <div className={styles.statusCardContainer}>
          <div className={styles.heading}>{heading}</div>
          <div className={styles.cardDetails}>
            <img className={styles.icon} alt="U" src={icon} />
            <div>{content}</div>
          </div>
          {status === 'OPERATOR_ADMIN_REJECTED' ||
          status === 'DEPARTMENT_ADMIN_REJECTED' ||
          status === 'UPDATE_REJECTED' ? (
            <div>
              <div className={styles.rejectedMessage}>
                <div>Remarks</div>
                <div className={styles.message}>{remarks}</div>
              </div>
              <div className={styles.rejectedMessage}>
                <div>Reason</div>
                <div className={styles.message}>{reason}</div>
              </div>
            </div>
          ) : null}
          {buttons && buttons.length > 0 ? (
            <div className={[styles.buttonContainer].join(' ')}>
              {buttons.map(button => {
                const {text, buttonStyles, ...remainingProps} = button;
                return (
                  <button
                    className={[styles.button, buttonStyles].join(' ')}
                    key={'popup-button-' + text}
                    {...remainingProps}>
                    {text}
                  </button>
                );
              })}
            </div>
          ) : null}
        </div>
        {invoiceData && invoiceData?.length > 0 ? (
          <div>{this.showInvoiceList(invoiceData, type)}</div>
        ) : null}
      </>
    );
  };

  getVehicleContent = () => {
    const {vehicleDetails} = this.state;
    const {
      vehicleRegistrationNumber,
      vehicleInsuranceNumber,
      vehicleCategory,
      status,
      vehicleType,
    } = vehicleDetails;
    return (
      <>
        <div className={styles.contentContainer}>
          <div>
            <b>Vehicle Number:</b> {vehicleRegistrationNumber}
          </div>
          <div>
            <b>Vehicle Insurance Number:</b> {vehicleInsuranceNumber}
          </div>
          <div>
            <b>Vehicle Category: </b>
            {vehicleCategory?.name}
          </div>
          <div>
            <b>Vehicle Type: </b>
            {vehicleType?.name}
          </div>
          <div>
            <b>Status:</b>{' '}
            <span className={styles.bolder}>{STATUS_CONSTANTS[status]}</span>
          </div>
        </div>
      </>
    );
  };

  getPersonContent = () => {
    const {personDetails} = this.state;
    console.log(personDetails);
    return (
      <>
        <div className={styles.contentContainer}>
          <div>
            <b>Name:</b> {personDetails?.name}
          </div>
          <div>
            {' '}
            <b>Mobile Number:</b> {personDetails?.phoneNumber.slice(3)}
          </div>
          <div>
            <b>Aadhaar Number:</b> {personDetails?.aadhaarNumber}
          </div>
          <div>
            <b>Status:</b>{' '}
            <span className={styles.bolder}>
              {STATUS_CONSTANTS[personDetails?.status]}{' '}
            </span>
          </div>
        </div>
      </>
    );
  };

  getAgencyContent = () => {
    const {agencyDetails} = this.state;
    const {name, departmentName, status, emailAddress} = agencyDetails;
    return (
      <>
        <div className={styles.contentContainer}>
          <div>
            <b>Agency Name:</b> {name}
          </div>
          <div>
            <b>Email Address:</b> {emailAddress}
          </div>

          <div>
            <b>Department:</b> {departmentName}
          </div>
          <div>
            <b>Status:</b> <span className={styles.bolder}>{status}</span>
          </div>
        </div>
      </>
    );
  };

  showInvoiceList = (invoiceData, type) => {
    const {items} = invoiceData;
    const lastIndex = invoiceData?.length - 1;
    return (
      <div className={styles.invoiceList}>
        <div className={styles.invoiceHeading}>Invoices</div>
        {invoiceData.map((item, index) => {
          return (
            <div
              className={[
                styles.invoiceItem,
                index === lastIndex ? styles.lastItem : '',
              ].join(' ')}>
              <div>{'Invoice ' + (index + 1)}</div>
              <div>{moment(item?.date).format('MMM Do YYYY')}</div>
              <div
                className={styles.invoiceDownloadBtn}
                onClick={() => this.showInvoicePopup(type, item)}>
                Download
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  showInvoicePopup = (type, data) => {
    this.setState({
      popup: {
        type:
          type === 'person'
            ? POPUP_CONSTANTS.PERSON_INVOICE_POPUP
            : POPUP_CONSTANTS.VEHICLE_INVOICE_POPUP,
      },
      currentInvoiceData: data,
    });
  };

  showStatusPopup = data => {
    const {heading} = data;
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.STATUS_POPUP,
        heading: heading,
        message: this.statusCard(data),
        data: {},
        onClose: this.closePopup,
        buttonContainer: styles.buttonContainer,
        buttons: [
          {
            text: 'Close',
            buttonStyles: styles.backButton,
            onClick: () => {
              this.closePopup();
            },
          },
        ],
      },
    });
  };

  verifyPerson = () => {
    const {referenceId, otpVerified} = this.state;

    if (otpVerified) {
      this.getPerson();
    } else {
      API.verifyPersonByReferenceId(referenceId)
        .then(personRes => {
          console.log('personRes.data', personRes.data);
          this.setState({
            phoneNumber: personRes.data?.phoneNumber.slice(3),
            popup: null,
          });
          this.openOTPPopUp();
        })
        .catch(error => {
          const errorMsg = util.getErrorMessage(error);
          console.log(errorMsg);
          this.showErrorPopup(errorMsg);
        });
    }
  };

  preSignUrl = async url => {
    const {personDetails} = this.state;
    const {history} = this.props;
    const data = {
      url: url,
      expiryTime: 30,
    };
    await API.preSignedURL(data)
      .then(res => {
        console.log('res.data', res.data?.preSignedUrl);
        // setUrl(res.data?.preSignedUrl);
        const {
          status,
          referenceId,
          paymentDetails,
          remarks,
          reason,
          rfidnumber,
        } = personDetails;
        const data = {
          heading: 'Person Details',
          icon: res.data?.preSignedUrl,
          referenceId: referenceId,
          content: this.getPersonContent(),
          status: status,
          remarks: remarks,
          reason: reason,
          invoiceData: personDetails?.invoiceData,
          type: 'person',
          buttons:
            status === 'PAYMENT_PENDING' || status === 'PAYMENT_FAILED'
              ? [
                  {
                    text: 'Edit',
                    buttonStyles: styles.backButton,
                    onClick: () => {
                      history.push({
                        pathname: '/edit-person-registration',
                        referenceId: referenceId,
                      });
                    },
                  },
                  {
                    text: 'Pay',
                    buttonStyles: styles.backButton,
                    onClick: () => {
                      this.closePopup();
                      this.props.history.push('/payments', {
                        id: referenceId,
                        title: strings.personRegistration,
                        paymentDetails: paymentDetails,
                        type: PAYMENT_CONSTANTS.PERSON,
                      });
                    },
                  },
                ]
              : !DISABLE_EDIT_STATUSES.includes(status)
              ? [
                  {
                    text: 'Edit',
                    buttonStyles: styles.backButton,
                    onClick: () => {
                      history.push({
                        pathname: '/edit-person-registration',
                        referenceId: referenceId,
                      });
                    },
                  },

                  // {
                  //   text: 'Download Invoice',
                  //   buttonStyles: styles.buttonDownload,
                  //   onClick: () => {
                  //     this.showInvoicePopup('person');
                  //   },
                  // },
                ]
              : [],
        };
        if (
          status === 'REISSUE_PAYMENT_PENDING' ||
          status === 'REISSUE_PAYMENT_FAILED'
        ) {
          data.buttons.push({
            text: 'Pay',
            buttonStyles: styles.backButton,
            onClick: () => {
              this.closePopup();
              this.props.history.push('/payments', {
                id: referenceId,
                title: strings.personRegistration,
                paymentDetails: paymentDetails,
                type: PAYMENT_CONSTANTS.PERSON,
              });
            },
          });
        }
        if (rfidnumber) {
          data.buttons.push({
            text: 'Surrender Card',
            buttonStyles: styles.surrenderStyles,
            onClick: () => this.handleSurrender('person', personDetails),
          });
          data.buttons.push({
            text: 'Lost / Damaged',
            buttonStyles: styles.surrenderStyles,
            onClick: () => this.handleLostDamaged('person', personDetails),
          });
        }
        this.showStatusPopup(data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleSurrender = (entityType, data) => {
    this.surrenderStepOne(entityType, data);
  };
  handleLostDamaged = (entityType, data) => {
    this.lostDamagedStepOne(entityType, data);
  };
  lostDamagedStepOne = (entityType, data) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.LOST_POPUP,
        entity: entityType === 'person' ? 'card' : 'tag',
        entityType: entityType,

        onClose: this.closePopup,
        onOutsideClick: this.closePopup,
        onSuccess:
          entityType === 'person'
            ? () => this.handlePersonLost(data)
            : () => this.handleVehicleLost(data),
      },
    });
  };

  getPerson = () => {
    const {referenceId} = this.state;
    console.log('person reference id:', referenceId);
    API.getPersonByReferenceId(referenceId)
      .then(personRes => {
        console.log('personRes.data', personRes.data);
        this.setState(
          {
            personDetails: personRes.data,
            popup: null,
          },
          () => this.preSignUrl(personRes?.data?.photo),
        );
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        console.log(errorMsg);
        this.showErrorPopup(errorMsg);
      });
  };

  verifyVehicle = () => {
    const {referenceId, otpVerified} = this.state;
    if (otpVerified) {
      this.getVehicle();
    } else {
      API.verifyVehicleByReferenceId(referenceId)
        .then(vehicleRes => {
          console.log('vehicleRes.data', vehicleRes.data);
          this.setState({
            phoneNumber: vehicleRes.data?.phoneNumber.slice(3),
            popup: null,
          });
          this.openOTPPopUp();
        })
        .catch(error => {
          const errorMsg = util.getErrorMessage(error);
          console.log(errorMsg);
          this.showErrorPopup(errorMsg);
        });
    }
  };

  getVehicle = () => {
    const {referenceId} = this.state;
    const {history} = this.props;

    console.log('vehicle reference id:', referenceId);
    API.getVehicleDetailsByReferenceId(referenceId)
      .then(vehicleRes => {
        console.log('vehicleRes.data', vehicleRes.data);
        this.setState({
          vehicleDetails: vehicleRes.data,
          popup: null,
        });
        const {
          status,
          referenceId,
          paymentDetails,
          remarks,
          reason,
          invoiceData,
          rfidnumber,
        } = vehicleRes?.data;
        const data = {
          heading: 'Vehicle Details',
          icon: adminImages.truckIconBlue,
          referenceId: referenceId,
          remarks: remarks,
          reason: reason,
          status: status,
          invoiceData: invoiceData,
          type: 'vehicle',
          content: this.getVehicleContent(),
          buttons:
            status === 'PAYMENT_PENDING' || status === 'PAYMENT_FAILED'
              ? [
                  {
                    text: 'Edit',
                    buttonStyles: styles.backButton,
                    onClick: () => {
                      history.push({
                        pathname: '/edit-vehicle-registration',
                        referenceId: referenceId,
                      });
                    },
                  },
                  {
                    text: 'Pay',
                    buttonStyles: styles.backButton,
                    onClick: () => {
                      this.closePopup();
                      this.props.history.push('/payments', {
                        id: referenceId,
                        // title: strings.personRegistration,
                        title: 'Vehicle Registration',
                        paymentDetails: paymentDetails,
                        type: PAYMENT_CONSTANTS.VEHICLE,
                      });
                    },
                  },
                ]
              : !DISABLE_EDIT_STATUSES.includes(status)
              ? [
                  {
                    text: 'Edit',
                    buttonStyles: styles.backButton,
                    onClick: () => {
                      history.push({
                        pathname: '/edit-vehicle-registration',
                        referenceId: referenceId,
                      });
                    },
                  },

                  // {
                  //   text: 'Download Invoice',
                  //   buttonStyles: styles.buttonDownload,
                  //   onClick: () => {
                  //     this.showInvoicePopup('vehicle');
                  //   },
                  // },
                ]
              : [],
        };

        if (
          status === 'REISSUE_PAYMENT_PENDING' ||
          status === 'REISSUE_PAYMENT_FAILED'
        ) {
          data.buttons.push({
            text: 'Pay',
            buttonStyles: styles.backButton,
            onClick: () => {
              this.closePopup();
              this.props.history.push('/payments', {
                id: referenceId,
                title: strings.vehicleRegistration,
                paymentDetails: paymentDetails,
                type: PAYMENT_CONSTANTS.VEHICLE,
              });
            },
          });
        }

        if (rfidnumber) {
          data.buttons.push({
            text: 'Surrender Tag',
            buttonStyles: styles.surrenderStyles,
            onClick: () => this.handleSurrender('vehicle', vehicleRes.data),
          });
          data.buttons.push({
            text: 'Lost / Damaged',
            buttonStyles: styles.surrenderStyles,
            onClick: () => this.handleLostDamaged('vehicle', vehicleRes.data),
          });
        }
        this.showStatusPopup(data);
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        console.log(errorMsg);
        this.showErrorPopup(errorMsg);
      });
  };

  verifyCompany = () => {
    const {referenceId, otpVerified} = this.state;
    const {history} = this.props;
    if (otpVerified) {
      this.getCompany();
      // history.push({
      //   pathname: '/edit-agency-registration',
      //   referenceId: referenceId,
      // });
    } else {
      API.verifyCompanyByReferenceId(referenceId)
        .then(companyDetails => {
          console.log('companyDetails.data:', companyDetails.data);
          this.setState({
            phoneNumber: companyDetails.data?.phoneNumber.slice(3),
            popup: null,
          });
          this.openOTPPopUp();
        })
        .catch(error => {
          const errorMsg = util.getErrorMessage(error);
          console.log(errorMsg);
          this.showErrorPopup(errorMsg);
        });
    }
  };

  getCompany = () => {
    const {referenceId} = this.state;
    const {history} = this.props;

    console.log('agency reference id:', referenceId);
    API.getAgencyDetailsByReferenceId(referenceId)
      .then(agencyRes => {
        console.log('agencyRes.data', agencyRes.data);
        this.setState({
          agencyDetails: agencyRes.data,
          popup: null,
        });
        const {status, referenceId} = agencyRes?.data;
        const data = {
          heading: 'Agency Details',
          icon: adminImages.agencyIcon,
          referenceId: referenceId,
          content: this.getAgencyContent(),
          buttons:
            status === 'PENDING' || status === 'REJECTED' || status === 'HOLD'
              ? [
                  {
                    text: 'Edit',
                    buttonStyles: styles.backButton,
                    onClick: () => {
                      history.push({
                        pathname: '/edit-agency-registration',
                        referenceId: referenceId,
                      });
                    },
                  },
                ]
              : [],
        };
        this.showStatusPopup(data);
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        console.log(errorMsg);
        this.showErrorPopup(errorMsg);
      });
  };

  getProps = () => {
    return {
      onSubmit: this.onSubmit,
      backClick: this.handleBackClick,
      onChangeHandler: this.onChangeHandler,
      getStatusContent: this.getStatusContent,
      stateData: this.state,
      onForgotClick: this.onForgotClick,
      onUploadNOC: this.onUploadNOC,
    };
  };

  render() {
    const {popup} = this.state;
    const props = this.getProps();

    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...props} />
      </>
    );
  }
}

// export default withRouter(PersonCards);
export default withRouter(ManageRegistrationInformation);
