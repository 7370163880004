import React from 'react';
import Flex from '../../Flex';
import styles from './styles.module.scss';

import successIcon from '../../../assets/images/success/image.png';
import strings from '../../../globalization';

function Success({
  containerClass,
  title,
  RFIDNumber,
  remark,
  children,
  data,
  onOkay,
  person,
  vehicle,
  ...remainingProps
}) {
  return (
    <div className={[styles.container, containerClass].join(' ')}>
      <Flex className={styles.header}>
        <h1>{title}</h1>
      </Flex>
      <Flex direction="column" className={styles.childrenContainer}>
        <img src={successIcon} className={styles.image} alt="U" />
        <p className={styles.subheading}>
          {strings.newCardsWithRFID}: {RFIDNumber} {strings.hasBeenApprovedFor}{' '}
          {person ? 'person' : 'vehicle'}{' '}
          {person ? data.name : data.vehicleNumber}
        </p>
        <p>
          {strings.remarks}: {remark}
        </p>
      </Flex>

      <Flex className={styles.buttonsContainer} {...remainingProps}>
        <button className={styles.okay} onClick={onOkay}>
          {strings.okayPopup}
        </button>
      </Flex>
    </div>
  );
}

export default Success;
