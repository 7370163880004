import React from 'react';
import styles from './PopupContainer.module.css';

type Props = {
  children: JSX.Element,
};

/**
 * @param {Props} props
 * @return {JSX.Element}
 */
function PopupContainer(props: Props) {
  const {children, containerStyle = null} = props;
  return (
    <div className={[styles.container, containerStyle].join(' ')}>
      {children}
    </div>
  );
}

export default PopupContainer;
