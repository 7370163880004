import strings from '../../../globalization';
export const editFormData = () => {
  return [
    {
      elementType: 'text',
      // keyboardType: 'default',
      inputType: 'name',
      label: strings.masterAdminName,
      placeholder: strings.masterAdminNamePlaceholder,
      required: true,
      //name: 'name',
      stateField: 'name',
      error: ' At Least Three Characters Required',
      stateDefault: '',
      minLength: 3,
      maxLength: 120,
      dependentOnOtherFields: false,
    },
    {
      elementType: 'text',
      //keyboardType: 'default',
      inputType: 'name_hindi',
      // name: 'name_hindi',
      label: strings.masterAdminNameHindi,
      placeholder: strings.masterAdminNameHindiPlaceholder,
      error: ' At Least Three Characters Required',
      required: true,
      stateField: 'name_hindi',
      stateDefault: '',
      minLength: 3,
      maxLength: 120,
      dependentOnOtherFields: false,
    },
    {
      elementType: 'dropdown',
      inputType: 'text',
      label: 'Status',
      placeholder: 'Select Status',
      optionsField: 'statuses',
      required: true,
      stateField: 'status',
      stateDefault: null,
      maxLength: 20,
      error: 'Field is required',
      dependentOnOtherFields: false,
    },
  ];
};
