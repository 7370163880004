import React, {useState} from 'react';
import Flex from '../../Flex';
import FormElement from '../../FormElement';
import styles from './styles.module.scss';

import bgIcon from '../../../assets/images/background/image.png';
import closeIcon from '../../../assets/images/close-icon/image.png';
import moment from 'moment';
import strings from '../../../globalization';

const STATUSES = {
  ISSUED: 'ISSUED',
  HOLD: 'HOLD',
  PENDING: 'PENDING',
  SURRENDERED: 'SURRENDER',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAYMENT_COMPLETED: 'PAYMENT_COMPLETED',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  REISSUE_PAYMENT_COMPLETED: 'REISSUE_PAYMENT_COMPLETED',
  UPDATED: 'UPDATED',
};

function VerifyPersonPopup({
  containerClass,
  formData,
  stateData,
  onClose,
  viewHistory,
  person,
  onClick,
  pageStatus,
  personRejectReasons,
  formDataState,
  errorScreen = '',
}) {
  console.log('errorScreen', errorScreen);
  const [verifiedDetails, setVerifiedDetails] = useState(null);
  const [error, setError] = useState(null);

  const getStatus = () => {
    let status = person.status;

    switch (status) {
      case STATUSES.ISSUED: {
        return 'Issued';
      }
      case STATUSES.HOLD: {
        return 'On Hold';
      }
      case STATUSES.SURRENDERED: {
        return 'Surrendered';
      }
      case STATUSES.PAYMENT_COMPLETED: {
        return 'Payment Completed';
      }
      case STATUSES.PAYMENT_PENDING: {
        return 'Payment Pending';
      }
      case STATUSES.PAYMENT_FAILED: {
        return 'Payment Failed';
      }
      case STATUSES.UPDATED: {
        return 'Details Updated';
      }
      case STATUSES.REISSUE_PAYMENT_COMPLETED: {
        return 'Reissue';
      }
      default: {
        return status;
      }
    }
  };

  const getButtons = () => {
    switch (pageStatus) {
      case 'reject': {
        return (
          <div className={styles.finalButtons}>
            <button className={styles.back} onClick={() => onClick('back')}>
              {`Back`}
            </button>

            <button className={styles.reject} onClick={handleReject}>
              {`Reject`}
            </button>
          </div>
        );
      }
      default: {
        return (
          <div className={styles.finalButtons}>
            <button className={styles.back} onClick={() => onClick('back')}>
              {`Back`}
            </button>

            <button className={styles.reject} onClick={handleReject}>
              {`Reject`}
            </button>
            <button className={styles.approve} onClick={handleApprove}>
              {strings.approve}
            </button>
          </div>
        );
      }
    }
  };

  const getStyles = () => {
    let status = person.status;

    switch (status) {
      case STATUSES.ISSUED: {
        return {
          padding: '2px 10px',
          borderRadius: '5px',
          backgroundColor: '#0AC15770',
        };
      }
      case STATUSES.HOLD: {
        return {
          padding: '2px 10px',
          borderRadius: '5px',
          backgroundColor: '#E6771770',
        };
      }
      case STATUSES.SURRENDERED: {
        return {
          padding: '2px 10px',
          borderRadius: '5px',
          backgroundColor: '#E6771770',
        };
      }
      default: {
        return {
          padding: '2px 10px',
          borderRadius: '5px',
          backgroundColor: '#0045E670',
        };
      }
    }
  };

  const getFormData = () => {
    return (
      <Flex direction="row" className={styles.columnLayout}>
        {formData.map((elem, idx) => {
          return (
            <FormElement
              type={elem.elementType}
              label={elem.label}
              required={elem.required}
              placeholderText={elem.placeholder}
              tabIndex={idx}
              options={stateData[elem.optionsField]}
              // value={stateData[elem.stateField].value}
              fileIcon={person[elem.fileIcon]}
              value={person[elem.id]}
              key={`firstColumn-${idx}`}
              maxLength={elem.maxLength}
              disabled={true}
              defaultValue={person[elem.id]}
              containerClassNames={styles.containerClassNames}
              labelClassNames={styles.labelClassNames}
              onRightIconClick={() =>
                onClick('document-view', {
                  type: 'person',
                  stateField: elem.stateField,
                  url: person[elem.fileIcon],
                })
              }
            />
          );
        })}
      </Flex>
    );
  };

  const getImageColumn = () => {
    return (
      <Flex direction="column" className={styles.imageTextContainer}>
        <img
          src={person.photo ? person.photo : bgIcon}
          className={styles.image}
          alt="AVATAR"
        />
        {/* <img src={bgIcon} className={styles.image} alt="AVATAR" /> */}
        <p className={styles.text}>
          {strings.referenceNumberLabel}: {person.referenceId}
        </p>
        <p className={styles.text}>
          {strings.requestDate}:{' '}
          {moment(person.createdDate).format('DD/MM/YYYY')}
        </p>
        <p className={styles.text}>
          {strings.status}: <span style={getStyles()}>{getStatus()}</span>
        </p>
        <button
          className={styles.history}
          onClick={() => viewHistory(person.referenceId)}>
          {strings.viewHistory}
        </button>
      </Flex>
    );
  };

  const getPageContent = () => {
    console.log('pageStatus...', pageStatus);

    switch (pageStatus) {
      case 'reject': {
        return (
          <Reject
            person={person}
            personRejectReasons={personRejectReasons}
            onClick={onClick}
          />
        );
      }
      default: {
        return (
          <Flex direction="column">
            <Flex direction="row">
              {getFormData()}
              {getImageColumn()}{' '}
            </Flex>
            <Flex direction="row" style={{alignItems: 'center'}}>
              <input
                id="verifiedDetails"
                type="radio"
                name="verifiedDetails"
                value={1}
                onChange={e => setVerifiedDetails(e.target.value)}
              />
              <label
                htmlFor="verifiedDetails"
                style={{paddingLeft: '10px'}}
                className={styles.radioLabelUniversal}
                onChange={e => setVerifiedDetails(e.target.value)}>
                Verified Details
              </label>
            </Flex>
            <Flex className={styles.errorContainer}>
              {error ? (
                <p className={styles.rejectText}>{error}</p>
              ) : errorScreen ? (
                <p className={styles.rejectText}>{errorScreen}</p>
              ) : null}
            </Flex>

            <Flex className={styles.buttonsContainer}>
              <div className={styles.finalButtons}>
                <button className={styles.back} onClick={() => onClick('back')}>
                  {`Back`}
                </button>
                {person.status === STATUSES.PAYMENT_COMPLETED && (
                  <>
                    {' '}
                    <button
                      className={styles.reject}
                      onClick={() => onClick('handle-reject-page-status')}>
                      {`Resubmit`}
                    </button>
                    <button className={styles.approve} onClick={handleApprove}>
                      {`Submit`}
                    </button>{' '}
                  </>
                )}
                {person.status === STATUSES.UPDATED && (
                  <>
                    {' '}
                    <button
                      className={styles.reject}
                      onClick={() => onClick('handle-reject-page-status')}>
                      {`Reject`}
                    </button>
                    <button className={styles.approve} onClick={handleApprove}>
                      {`Reverify`}
                    </button>{' '}
                  </>
                )}
              </div>
            </Flex>
          </Flex>
        );
      }
    }
  };

  const handleApprove = () => {
    setError('');
    if (!verifiedDetails) {
      setError(`Please verify all the details`);
      return;
    }

    //
    onClick('handle-approve-person', {
      id: person.id,
      ...person,
    });
  };

  const handleReject = () => {};
  return (
    <div className={[styles.container, containerClass].join(' ')}>
      <Flex className={styles.mainContainer}>{getPageContent()}</Flex>
    </div>
  );
}

const Reject = ({person, personRejectReasons, onClick}) => {
  const [rejectReasonId, setRejectReasonId] = useState(null);
  const [remark, setRemark] = useState('');
  const [error, setError] = useState('');
  const onChangeValue = e => {
    console.log(e.target.value);
    setRejectReasonId(e.target.value);
  };

  const onReject = () => {
    if (!rejectReasonId) {
      setError('Please select reject reason');
      return;
    }
    if (!remark) {
      setError('Please enter a remark');
      return;
    }
    onClick('handle-reject-person', {
      id: person.id,
      rejectReasonId: +rejectReasonId,
      remark,
      type: 'person',
      status: person.status,
    });
  };
  return (
    <div style={{width: '100%'}}>
      <p>
        Reference Number:{' '}
        <span className={styles.referenceId}>{person?.referenceId}</span>
      </p>

      <Flex
        direction="column"
        onChange={e => onChangeValue(e)}
        className={styles.radioContainer}>
        <p className={styles.radioHeader}>Reason for rejection</p>
        {personRejectReasons.map((reason, idx) => (
          <Flex direction="row" style={{alignItems: 'center'}}>
            <input
              type="radio"
              name="reason"
              id={`reject-reason-${idx}`}
              value={reason.id}
            />
            <label
              htmlFor={`reject-reason-${idx}`}
              style={{paddingLeft: '10px'}}
              key={`${idx}-${reason.id}-${reason.reason}`}
              className={styles.radioLabel}>
              {reason.reason}
            </label>
          </Flex>
        ))}
      </Flex>
      <div>
        <textarea
          onChange={e => setRemark(e.target.value)}
          rows="5"
          placeholder="Enter remarks..."
          className={styles.rejectTextarea}
          style={{width: '100%'}}></textarea>
      </div>
      <p className={styles.rejectText}>{error}</p>
      <Flex className={styles.buttonsContainer}>
        <div className={styles.finalButtons}>
          <button
            className={styles.back}
            onClick={() => onClick('reset-page-status')}>
            {`Back`}
          </button>

          <button className={styles.reject} onClick={onReject}>
            {`Resubmit`}
          </button>
        </div>
      </Flex>
    </div>
  );
};

export default VerifyPersonPopup;
