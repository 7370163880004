import React, {Component} from 'react';
import PopupContainer from '../../../components/PopupContainer';
import moment from 'moment';
import styles from './styles.module.scss';

import ViewRenderer from './views';
import {API} from '../../../requests';
import {commonImages, operatorImages} from '../../../assets/images';
import strings from '../../../globalization';

import Popup from '../../../components/SimplePopup';

import POPUP_CONSTANTS from '../../../constants/popup-constants';
import REQUEST_TYPE_OPTIONS from '../../../constants/request-type-options';

import Spinner from '../../../components/Spinner';

import {connect} from 'react-redux';
import util from '../../../util';

// import Popup from "../../../components/SimplePopup";

const DROPDOWN_KEY_VALUE = {
  1: REQUEST_TYPE_OPTIONS.PERSON,
  2: REQUEST_TYPE_OPTIONS.VEHICLE,
  3: REQUEST_TYPE_OPTIONS.AGENCY,
};

const STATUS = {
  ISSUED: 'Issued',
  HOLD: 'Hold',
};

class Messages extends Component {
  constructor(props) {
    super(props);

    const {
      login: {loginInfo},
    } = props.reduxProps;

    const {user} = loginInfo;
    console.log('user - messages', user);
    this.state = {
      activeTab: 'received',
      activeMessage: undefined,
      pageStatus: 'landing',
      user: user,
      messages: [],
      locations: [],
    };
  }
  componentDidMount = () => {
    this.fetchAllMessages();
    this.fetchAllLocations();
  };

  fetchAllLocations = () => {
    API.fetchActiveLocation()
      .then(response => {
        console.info('response...', response);
        this.setState(
          {
            locations: response.data,
          },
          function () {
            console.log('locations - ', this.state.locations);
          },
        );
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        console.info('error....', errorMsg);
        this.showErrorPopup(errorMsg);
      });
  };

  fetchAllMessages = () => {
    const {
      user: {roleId},
    } = this.state;
    // Check role - Then call receive or sent APIs | roleId (5) === Super Admin
    if (roleId === 5) {
      API.fetchAllSentMessages()
        .then(response => {
          console.info('response... sent messages', response);
          this.setState({
            messages: response.data,
          });
        })
        .catch(error => {
          const errorMsg = util.getErrorMessage(error);
          console.info('error....', errorMsg);
          this.showErrorPopup(errorMsg);
        });
    } else {
      API.fetchAllReceivedMessages()
        .then(response => {
          console.info('response... received messages', response);
          console.info('Role Id', roleId);
          this.setState({
            messages: response.data,
          });
        })
        .catch(error => {
          const errorMsg = util.getErrorMessage(error);
          console.info('error....', errorMsg);
          this.showErrorPopup(errorMsg);
        });
    }
  };

  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  onClickHandler = (action, data) => {
    const {history} = this.props;
    console.info('action....', action);
    switch (action) {
      case 'activeTab': {
        this.setState({
          activeTab: data,
        });
        break;
      }
      case 'activeMessage': {
        this.setState({
          activeMessage: data,
          pageStatus: 'activeMessage',
        });
        break;
      }
      case 'addNewMessage': {
        this.setState({
          pageStatus: 'addNewMessage',
        });
        break;
      }
      case 'submitMessage': {
        this.submitMessage();
        this.setState({
          pageStatus: 'addNewMessage',
        });
        break;
      }
      case 'discardMessage': {
        this.setState({
          pageStatus: 'landing',
        });
        break;
      }
      case 'signin': {
        history.push('/sign-in');
        break;
      }
      default: {
        console.info('case not handled', action);
      }
    }
  };

  submitMessage = () => {
    const {location, message} = this.state;
    this.showSpinner();
    let formData = {
      locationIds: [location.id],
      message: message,
    };
    API.sendMessage(formData)
      .then(res => {
        console.log('response...', res.data);
        this.setState({
          pageStatus: 'landing',
        });
        this.showSuccessPopup('Message sent successfully!');

        // Call the fetch API again to fetch all recent messages
        this.fetchAllMessages();
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        this.showErrorPopup(errorMsg);
      });
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };
  onChangeHandler = (action, data) => {
    switch (action) {
      case 'activeTab': {
        this.setState({
          activeTab: data,
        });
        break;
      }
      case 'searchText': {
        this.setState({
          searchText: data,
        });
        break;
      }
      case 'selectedLocation': {
        this.setState({
          location: data,
        });
        break;
      }
      case 'message': {
        this.setState({
          message: data,
        });
        break;
      }

      default: {
        console.info('case not handled', action);
      }
    }
  };

  getProps = () => {
    const {activeTab, messages, activeMessage, pageStatus, user, locations} =
      this.state;
    return {
      onClick: this.onClickHandler,
      onChange: this.onChangeHandler,
      activeTab,
      messages,
      activeMessage,
      pageStatus,
      user,
      locations,
    };
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };

  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };
  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };
  showSuccessPopup = successMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: successMessage,
        onClose: this.closePopup,
        headingImage: operatorImages.successIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

const mapStateToProps = reduxProps => ({
  reduxProps,
});

export default connect(mapStateToProps, null)(Messages);
