import React from 'react';
import AuthLayout from '../../../../../components/AuthLayout';
import RegistrationFormCard from '../../../../../components/RegistrationFormCard';
import styles from './Mobile.module.scss';
import FormInput from '../../../../../components/FormInput';
import CustomInput from '../../../../../components/CustomInput';
import FormElement from '../../../../../components/FormElement';
import {loginImages} from '../../../../../assets/images';
import AcceptTerms from '../../../../../components/AcceptTermsBox';
import Button from '../../../../../components/Button';
import Flex from '../../../../../components/Flex';
import strings from '../../../../../globalization';

function Mobile({
  formLeftFields,
  formRightFields,
  onClick,
  onChange,
  onBack,
  onChangeHandler,
  handleUpload,
  onBlur,
  backClick,
  stateData,
  onSubmit,
  checked,
  toggleChecked,
  triggerOTP,
  otpVerified,
  otpVerifiedField,
  ...stateValues
}) {
  const formData = [...formLeftFields, ...formRightFields];
  return (
    <div>
      <div className={styles.formContainer}>
        <div>
          <Flex onClick={backClick} className={styles.backlabel}>
            <img
              className={styles.backButtonIcon}
              src={loginImages.backButtonIcon}
              alt="back"
            />
            <span>{strings.back}</span>
          </Flex>
          <header>
            <h1 className={styles.header}>Vehicle Registration</h1>
          </header>
        </div>

        <div>
          {formData.map((elem, idx) => {
            return (
              <div
                key={`formElement-${idx}`}
                className={(styles.divider, styles.formFields)}>
                <FormElement
                  type={elem.elementType}
                  label={elem.label}
                  required={elem.required}
                  placeholderText={elem.placeholder}
                  onChange={text => {
                    onChangeHandler(elem.stateField, text);
                  }}
                  error={stateData[elem.stateField].error}
                  options={stateData[elem.optionsField]}
                  value={stateData[elem.stateField].value}
                  key={idx}
                  maxLength={elem.maxLength}
                  keyboardType={elem.keyboardType}
                  // containerStyle={{marginBotton: 40 * vh}}
                  handleUpload={(file, error) => {
                    handleUpload(elem.stateField, file, error);
                  }}
                  maxFiles={elem.maxFiles}
                  onBlur={() => onBlur(elem.stateField, elem)}
                  allowedFormats={elem.allowedFormats}
                  triggerOTP={() => onClick('otp-verify', elem)}
                  otpVerified={otpVerified}
                  otpVerifiedField={otpVerifiedField}
                  isVerifyOtp={elem.verifyOtp}
                  fieldName={elem.stateField}
                />
              </div>
            );
          })}
        </div>
        <div className={styles.divider}>
          <AcceptTerms toggleChecked={toggleChecked} checked={checked} />
        </div>
        <Flex style={{justifyContent: 'center'}}>
          <Button
            buttonStyle="primary"
            // label="Submit Request"
            label="Proceed To Payment"
            className={styles.submitButton}
            onClick={onSubmit}
            disabled={!checked}
          />
        </Flex>
      </div>
    </div>
  );
}

export default Mobile;
