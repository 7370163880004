import React from 'react';
import RegistrationLeftContainer from '../RegistrationLeftContainer';

import styles from './AuthLayout.module.scss';

function AuthLayout(props) {
  return (
    <div className={styles.backgroundImage}>
      <div className={styles.authLayout}>
        <RegistrationLeftContainer />
        <div className={styles.rightSection}>{props.children}</div>
      </div>
    </div>
  );
}

export default AuthLayout;
