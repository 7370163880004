import React from 'react';
import Card from '../../../../../../components/PermitDetails';
import styles from './styles.module.scss';
import permit from '../../../../../../assets/images/operator-icon/image.png';
import moment from 'moment';

const Desktop = props => {
  console.log('props', props);
  const {
    permits,
    visitingDate,
    numberOfDays,
    purposeOfWork,
    login,
    purposeOfWorkOptions,
    passValidityPeriod,
  } = props;
  const vehicles = permits.vehicles;
  const persons = permits.persons;
  const loginInfo = login;
  console.log('passValidityPeriod', passValidityPeriod);
  const header = {
    startDate: moment(visitingDate?.value).format('MMM Do YYYY'),
    endDate: moment(visitingDate?.value)
      .add(numberOfDays, 'days')
      .format('MMM Do YYYY'),
    validity:
      passValidityPeriod?.value === 2
        ? '8 HRS from Time of Entry'
        : numberOfDays,
    purpose: purposeOfWorkOptions.filter(
      elem => elem.id === purposeOfWork?.value,
    )[0]?.name,
  };

  const wallet = [
    {
      entryCategory:
        persons.length > 0 && vehicles.length > 0
          ? 'Man, Material && Vehicle permit'
          : vehicles.length > 0
          ? 'Man, Vehicle and Material Permit'
          : '-',
      walletSummary: '',
      numberOfTrips: numberOfDays,
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p>Permit Summary</p>
      </div>
      <div className={styles.body}>
        <div className={styles.permitcard}>
          {persons?.map((elem, idx) => (
            <>
              <div className={styles.permit}>
                <img src={permit} alt="permit" />
                <p>Permit {idx + 1}</p>
              </div>
              <Card
                idx={idx}
                company={loginInfo?.company?.name}
                header={header}
                type="person"
                elem={elem}
                numberOfDays={numberOfDays}
              />
            </>
          ))}
        </div>
        <div className={styles.permitcard}>
          {vehicles?.map((elem, idx) => (
            <>
              <div className={styles.permit}>
                <img src={permit} alt="permit" />
                <p>Permit {idx + 1}</p>
              </div>
              <Card
                idx={idx}
                company={loginInfo?.company?.name}
                header={header}
                type="vehicle"
                elem={elem}
                numberOfDays={numberOfDays}
                isTrip={passValidityPeriod?.value === 2}
              />
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Desktop;
