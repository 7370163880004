import React from 'react';
import styles from './styles.module.scss';
import AuthLayout from '../../../../../components/AuthLayout';
import {withRouter} from 'react-router-dom';
import strings from '../../../../../globalization/index';
import FormElement from '../../../../../components/FormElement';
import Flex from '../../../../../components/Flex';
import {loginImages} from '../../../../../assets/images';
import Button from '../../../../../components/Button';

const Mobile = props => {
  const {
    stateData,
    onChangeHandler,
    onBlur,
    formData,
    backClick,
    handleButtonPress,
  } = props;

  return (
    <Flex direction="column" className={styles.container}>
      <Flex onClick={backClick} className={styles.backlabel}>
        <img
          className={styles.backButtonIcon}
          src={loginImages.backButtonIcon}
          alt="back"
        />
        <span>{strings.back}</span>
      </Flex>
      <header>
        <h1 className={styles.header}>{strings.getReferenceNumber}</h1>
      </header>
      <Flex direction="column" className={styles.formContainer}>
        {formData.map((elem, idx) => {
          return (
            <Flex direction="column" style={{marginBottom: '1rem'}}>
              <FormElement
                type={elem.elementType}
                label={elem.label}
                required={elem.required}
                placeholderText={elem.placeholder}
                onChange={text => onChangeHandler(elem.stateField, text)}
                tabIndex={idx}
                error={stateData[elem.stateField].error}
                options={stateData[elem.optionsField]}
                value={stateData[elem.stateField].value}
                key={idx}
                maxLength={elem.maxLength}
                maxFiles={elem.maxFiles}
                onBlur={() => onBlur(elem.stateField, elem)}
                allowedFormats={elem.allowedFormats}
              />
            </Flex>
          );
        })}

        <Button
          buttonStyle="primary"
          label={strings.getReferenceNumber}
          className={styles.submitButton}
          onClick={handleButtonPress}
        />
      </Flex>
    </Flex>
  );
};
export default withRouter(Mobile);
