import strings from '../../../globalization';

export const userImage = './static/images/Icons/user.png';
export const lockImage = './static/images/Icons/lock.png';
export const formFields = [
  {
    field: 'newPassword',
    type: 'password',
    label: `${strings.newPassword}`,
    placeholder: `${strings.enterNewPassword}`,
    required: true,
    inputType: 'text',
  },
  {
    field: 'confirmPassword',
    type: 'password',
    label: `${strings.confirm}`,
    placeholder: `${strings.confirmPassword}`,
    required: true,
    inputType: 'text',
  },
];

export const INPUT_CONSTANTS = {
  TEXT: 'text',
  DROPDOWN: 'dropdown',
  DATE: 'date',
  FILE: 'file',
  NUMBER: 'number',
  PASSWORD: 'password',
};

export const keys = {
  title: `${strings.title}`,
  subTitle: `${strings.subTitle}`,
};
