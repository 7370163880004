import React, { useState } from 'react';
import { useRouteMatch, Switch, Route, useLocation } from 'react-router-dom';
import SideBar from '../components/SideBar';
import { useMediaQuery } from 'react-responsive';
import styles from './styles.module.css';
// import AgencyPermits from '../pages/Agency/AgencyPermits';
import AgencyDashBoard from '../pages/Agency/Dashboard';
import { agencyTabs } from './tabs';
import RequestNewPermit from '../pages/Agency/AgencyPermits/RequestNewPermit';
import AddPerson from '../pages/Agency/AgencyPermits/AddPerson1';
import AddVehicle from '../pages/Agency/AgencyPermits/AddVehicle';
import AddMaterial from '../pages/Agency/AgencyPermits/AddMaterial';
import AddDriver from '../pages/Agency/AgencyPermits/AddDriver';
import ChangeDriver from '../pages/Agency/AgencyPermits/ChangeDriver';
import PendingPermits from '../pages/Agency/AgencyPermits/PendingPermits';
import VehiclePersonMovement from '../pages/Agency/Reports/VehiclePersonMovement';
import VehicleEntryExitMovement from '../pages/Agency/Reports/VehicleEntryExitMovement';
import { useReducer } from 'react';
import SelectVehicle from '../pages/Agency/AgencyPermits/SelectVehicle';
import AvailableCredits from '../pages/Agency/Credits/AvailableCredits';
import AvailableCreditsWallet from '../pages/Agency/Wallet/AvailableCredits1';
import CreditHistory from '../pages/Agency/Credits/CreditHistory';
import TransactionHistory from '../pages/Agency/Wallet/TransactionHistory';
import BuyCredits from '../pages/Agency/Wallet/BuyCredits';
import Cart from '../pages/Agency/Wallet/Cart';
import UsersList from '../pages/Agency/UserManagement/UsersList';
import AddUser from '../pages/Agency/UserManagement/AddUser';
import Profile from '../pages/Agency/Profile1';
import GenericPopup from '../components/GenericPopup';
import PopupContainer from '../components/PopupContainer';
import ViewDocument from '../components/ViewDocumentView';
import PermitPass from '../pages/Agency/AgencyPermits/PermitPass';
import UsageHistory from '../pages/Agency/Wallet/UsageHistory';
import Invoice from '../pages/Agency/Wallet/Invoice';

const initialState = {
  vehicles: [],
  currentVehicle: {
    // material: {},
    // vehicle: {},
    // driver: {},
  },
};

function reducer(state, action) {
  console.log('action', action);
  console.log('state', state);
  switch (action.type) {
    case 'add-vehicle': {
      return {
        ...state,
        currentVehicle: {
          ...state.currentVehicle,
          vehicle: action.payload,
        },
      };
    }
    case 'add-material': {
      return {
        ...state,
        currentVehicle: {
          ...state.currentVehicle,
          material: action.payload,
        },
      };
    }
    case 'add-driver': {
      console.log('deleted:');
      return {
        ...state,
        currentVehicle: {
          ...state.currentVehicle,
          driver: action.payload,
        },
      };
    }
    case 'submit-vehicle': {
      return {
        ...state,
        vehicles: state.vehicles.concat(action.payload),
        currentVehicle: {},
      };
    }
    case 'update-material': {
      let requiredVehicleObject = state.vehicles.find(
        vArr => action.payload.vehicleId === vArr.vehicle.id,
      );

      let filteredVehicles = state.vehicles.filter(
        vArr => action.payload.vehicleId !== vArr.vehicle.id,
      );

      const rvObj = {
        ...requiredVehicleObject,
        material: action.payload,
      };

      filteredVehicles.push(rvObj);
      return {
        ...state,
        vehicles: [...filteredVehicles],
      };
    }
    case 'delete-vehicle': {
      const updatedVehicles = state.vehicles.filter(
        elem => elem.vehicle.id !== action.payload,
      );
      console.log('updated vehicle...', updatedVehicles);
      return {
        ...state,
        vehicles: updatedVehicles,
      };
    }
    case 'delete-material': {
      let updatedVehicles = state.vehicles.map(elem => {
        if (elem.material && elem.material.id === action.payload) {
          //material found, make it undefined
          elem.material = undefined;
        }
        return elem;
      });
      console.log('updated vehicle...after material updation', updatedVehicles);
      return {
        ...state,
        vehicles: updatedVehicles,
      };
    }
    default:
      throw new Error();
  }
}

const initialWalletState = {
  cart: [],
};

function walletReducer(state, action) {
  console.log('action', action);
  console.log('state', state);
  switch (action.type) {
    case 'add-cart-item': {
      return {
        ...state,
        cart: state.cart.concat(action.payload),
      };
    }
    case 'delete-cart-item': {
      const updatedCart = state.cart.filter(elem => elem.id !== action.payload);
      console.log('updated cart value...', updatedCart);
      return {
        ...state,
        cart: updatedCart,
      };
    }
    default:
      throw new Error();
  }
}

const WalletRouter = ({ url }) => {
  const [state, dispatch] = useReducer(walletReducer, initialWalletState);

  return (
    <>
      <Route
        path={`${url}/wallet/buy-credits`}
        children={<BuyCredits dispatch={dispatch} reducerState={state} />}
      />
      <Route
        path={`${url}/wallet/cart`}
        children={<Cart dispatch={dispatch} reducerState={state} />}
      />
      <Route
        path={`${url}/wallet/transaction-history/invoice`}
        children={<Invoice />}
      />
    </>
  );
};

const AddVehicleDriverMaterialRouter = ({ url }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <>
      <Route
        path={`${url}/permits/add-new-vehicle`}
        children={<AddVehicle dispatch={dispatch} reducerState={state} />}
      />
      <Route
        key="viewDocument0"
        exact
        path={`${url}/permits/add-new-vehicle/document-view`}
        children={
          <PopupContainer containerStyle={styles.popupContainerStyle}>
            <ViewDocument />
          </PopupContainer>
        }
      />
      <Route
        path={`${url}/permits/add-new-material`}
        children={<AddMaterial dispatch={dispatch} reducerState={state} />}
      />
      <Route
        path={`${url}/permits/add-new-driver`}
        children={<AddDriver dispatch={dispatch} reducerState={state} />}
      />
      <Route
        key="viewDocument0122"
        exact
        path={`${url}/permits/add-new-driver/document-view`}
        children={
          <PopupContainer containerStyle={styles.popupContainerStyle}>
            <ViewDocument />
          </PopupContainer>
        }
      />
      <Route
        path={`${url}/permits/select-vehicle`}
        children={<SelectVehicle dispatch={dispatch} reducerState={state} />}
      />
    </>
  );
};

/**
 * @return {JSX.Element}
 */
function AgencyRouter(props) {
  const { url } = useRouteMatch();
  console.info('url', url);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const onProfileClick = () => {
    const { history } = props;
    history.push(`${url}/profile`);
  };
  let location = useLocation();
  let background = location.state && location.state.background;
  console.info('background', background);

  let details = {
    title: 'Attention!',

    onClick: () => setShowGenericPopup(false),
    children: (
      <div className={styles.helpCenter}>
        <p className={styles.emailPrompt}>
          Only permits involving entry from West-Gate 1 are operational.
        </p>
        <p className={styles.emailPrompt}>
          We will start with other gates soon!
        </p>
      </div>
    ),
  };
  const [showGenericPopup, setShowGenericPopup] = useState(true);

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles.sideBar}>
          <SideBar module={'admin'} header={'Permits'} tabs={agencyTabs} />
        </div>

        <div className={styles.relative}>
          <div className={styles.bodyWrapper}>
            {/* {showGenericPopup && (
              <PopupContainer>
                <GenericPopup {...details} />
              </PopupContainer>
            )} */}
            <Switch>
              <Route path={`${url}/dashboard`}>
                <AgencyDashBoard />
              </Route>

              <Route key="person" path={`${url}/reports/person`}>
                <VehiclePersonMovement type={'person'} />
              </Route>
              <Route key="vehicle" path={`${url}/reports/vehicle`}>
                <VehiclePersonMovement type={'vehicle'} />
              </Route>
              <Route key="vehicle-entry-exit" path={`${url}/reports/vehicle-entry-exit`}>
                <VehicleEntryExitMovement type={'vehicle-entry-exit'} />
              </Route>

              <Route key="vehicle" path={`${url}/reports`}>
                <VehiclePersonMovement type={'vehicle'} />
              </Route>

              {/* <Route path={`${url}/permits/change-driver`}>
                <ChangeDriver />
              </Route> */}
              <Route key="pending" path={`${url}/permits/pending-permits`}>
                <PendingPermits status={'PENDING'} />
              </Route>
              <Route
                key="approved"
                path={`${url}/permits/approved-rejected-permits`}>
                <PendingPermits status={'APPROVED'} />
              </Route>
              <Route path={`${url}/permits`}>
                <RequestNewPermit />
              </Route>
              <Route
                key="availableCredits"
                exact
                path={`${url}/credits/available-credits`}>
                <AvailableCredits status={'AVAILABLE_CREDITS'} />
              </Route>
              <Route
                key="creditHistory"
                exact
                path={`${url}/credits/credit-history`}>
                <CreditHistory status={'CREDIT_HISTORY'} />
              </Route>
              <Route path={`${url}/profile`}>
                <Profile />
              </Route>
              <Route path={`${url}/credits`}>
                <AvailableCredits status={'AVAILABLE_CREDITS'} />
              </Route>
              <Route path={`${url}/wallet/available-credits`}>
                <AvailableCreditsWallet status={'AVAILABLE_CREDITS'} />
              </Route>
              <Route path={`${url}/wallet/transaction-history`}>
                <TransactionHistory status={'TRANSACTION_HISTORY'} />
              </Route>
              <Route path={`${url}/wallet/usage-history`}>
                <UsageHistory status={'USAGE_HISTORY'} />
              </Route>
              <Route path={`${url}/wallet`}>
                <AvailableCreditsWallet status={'AVAILABLE_CREDITS'} />
              </Route>
              <Route path={`${url}/user-management`}>
                <UsersList status={'PENDING'} />
              </Route>
            </Switch>
            {background && (
              <>
                <Route
                  path={`${url}/permits/add-new-person`}
                  children={<AddPerson />}
                />
                <Route
                  key="viewDocument11"
                  exact
                  path={`${url}/permits/add-new-person/document-view`}
                  children={
                    <PopupContainer containerStyle={styles.popupContainerStyle}>
                      <ViewDocument />
                    </PopupContainer>
                  }
                />
                <WalletRouter url={url} />
                <AddVehicleDriverMaterialRouter url={url} />
                <Route
                  key="addUser"
                  exact
                  path={`${url}/user-management/add-new-user`}
                  children={<AddUser status={'ADD'} />}
                />
                <Route
                  key="editUser"
                  exact
                  path={`${url}/user-management/edit-user`}
                  children={<AddUser status={'EDIT'} />}
                />
                <Route
                  key="deleteUser"
                  exact
                  path={`${url}/user-management/delete-user`}
                  children={<AddUser status={'DELETE'} />}
                />
                <Route
                  key="viewDocument01"
                  exact
                  path={`${url}/permits/approved-rejected-permits/document-view`}
                  children={
                    <PopupContainer containerStyle={styles.popupContainerStyle}>
                      <ViewDocument />
                    </PopupContainer>
                  }
                />
                <Route
                  key="viewDocument1"
                  exact
                  path={`${url}/permits/approved-rejected-permits/change-driver/document-view`}
                  children={
                    <PopupContainer containerStyle={styles.popupContainerStyle}>
                      <ViewDocument />
                    </PopupContainer>
                  }
                />
                <Route
                  key="change-driver"
                  exact
                  path={`${url}/permits/approved-rejected-permits/change-driver`}
                  children={<ChangeDriver />}
                />
                <Route
                  key="print-permit-agency"
                  exact
                  path={`${url}/permits/approved-rejected-permits/print-permit/:referenceId`}
                  children={<PermitPass />}
                />
                <Route
                  key="viewDocument2"
                  exact
                  path={`${url}/permits/pending-permits/document-view`}
                  children={
                    <PopupContainer containerStyle={styles.popupContainerStyle}>
                      <ViewDocument />
                    </PopupContainer>
                  }
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgencyRouter;
