export const customStyles = {
  placeholder: (provided, state) => {
    return {
      ...provided,
      fontFamily: 'Poppins-Regular',
      fontSize: '14px',
      color: '#002C94',
      letterSpacing: '0.56px',
      fontWeight: 400,
      display: 'flex',
      alignItems: 'center',
    };
  },
  control: (provided, state) => {
    return {
      ...provided,
      height: '2.2rem',
      marginTop: '0.2rem',
      width: '8rem',
      background: '#E6EDFF',
      borderRadius: 0,
      borderWidth: 0,
      fontSize: '0.8rem',
    };
  },
  indicatorSeparator: provided => {
    return {
      ...provided,
      backgroundColor: '#E6EDFF',
    };
  },
};
