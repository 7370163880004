import strings from '../../../../globalization';
import moment from 'moment';
export const getUserForm = () => {
  return [
    {
      elementType: 'text',
      inputType: 'alphabets',
      label: 'Employee name',
      placeholder: 'Enter name',
      required: true,
      stateField: 'firstName',
      stateDefault: '',
      maxLength: 20,
      minLength: 3,
    },
    {
      elementType: 'text',
      inputType: 'text',
      label: 'Employee ID',
      placeholder: 'Enter Employee ID',
      required: true,
      stateField: 'employeeId',
      stateDefault: '',
      maxLength: 20,
    },

    {
      inputType: 'phone',
      elementType: 'phone',
      keyboardType: 'number-pad',
      label: 'Employee Mobile Number',
      placeholder: 'Enter Mobile Number',
      required: true,
      stateField: 'phoneNumber',
      stateDefault: '',
      maxLength: 10,
    },
    {
      elementType: 'text',
      inputType: 'email',
      label: 'Employee Email Address',
      placeholder: 'Enter email Address',
      required: true,
      stateField: 'emailAddress',
      stateDefault: '',
      maxLength: 45,
    },

    {
      elementType: 'text',
      inputType: 'alphabets',
      label: 'Employee Designation',
      placeholder: 'Enter Designation',
      required: true,
      stateField: 'designation',
      stateDefault: '',
      maxLength: 25,
      minLength: 3,
    },
    // {
    //   elementType: 'file',
    //   inputType: 'upload',
    //   label: 'Signatory Document',
    //   placeholder: strings.selectDocument,
    //   required: true,
    //   stateField: 'authorizedDocument',
    //   // FIXME
    //   stateDefault: [],
    //   allowedFormats: ['pdf', 'images'],
    //   maxFiles: 1,
    //   dependentOnOtherFields: false,
    // },
    // {
    //   elementType: 'date',
    //   inputType: 'text',
    //   label: 'Document Validity(if any)',
    //   placeholder: 'Select Date',
    //   required: true,
    //   stateField: 'documentValidity',
    //   stateDefault: null,
    //   maxLength: 20,
    //   dependentOnOtherFields: true,
    // },
    // {
    //   elementType: 'file',
    //   inputType: 'upload',
    //   label: 'Identity Proof Document',
    //   placeholder: strings.selectDocument,
    //   required: true,
    //   stateField: 'idProofDocument',
    //   // FIXME
    //   stateDefault: [],
    //   allowedFormats: ['pdf', 'images'],
    //   maxFiles: 1,
    //   dependentOnOtherFields: false,
    // },

    {
      elementType: 'dropdown',
      inputType: 'text',
      label: 'Select Privilege (Role)',
      placeholder: 'Select role',
      optionsField: 'agencyRoleOptions',
      required: true,
      stateField: 'role',
      stateDefault: null,
      maxLength: 20,
      dependentOnOtherFields: false,
    },
  ];
};
