import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss';

const CSVReader = ({onProcessed}) => {
  const [csvFile, setCsvFile] = useState();
  const [csvArray, setCsvArray] = useState([]);

  const submit = () => {
    const file = csvFile;
    const reader = new FileReader();

    reader.onload = function (e) {
      const text = e.target.result;
      console.log(text);
      processCSV(text); // plugged in here
    };

    reader.readAsText(file);
  };
  const processCSV = (str, delim = ',') => {
    const headers = str.slice(0, str.indexOf('\n')).split(delim);

    const rows = str.slice(str.indexOf('\n') + 1).split('\n');

    const newArray = rows.map(row => {
      const values = row.split(delim);
      const eachObject = headers.reduce((obj, header, i) => {
        header = header.trim();
        obj[header] = values[i];
        return obj;
      }, {});
      return eachObject;
    });
    setCsvArray(newArray);
    onProcessed && onProcessed({csvArray: newArray, headers});
  };

  useEffect(() => {
    if (csvFile) {
      submit();
    }
  }, [csvFile]);

  const handleChange = e => {
    const files = e.target.files;
    setCsvFile(files[0]);
    // reset file to let us upload same file
    e.target.value = '';
  };
  return (
    <div>
      <form id="csv-form">
        <label htmlFor="csvFile" className={styles.button}>
          Upload CSV
        </label>
        <input
          type="file"
          accept=".csv"
          id="csvFile"
          style={{display: 'none'}}
          onChange={handleChange}
        />

        {/* <button
          onClick={e => {
            e.preventDefault();
            if (csvFile) submit();
          }}>
          Upload CSV
        </button> */}
      </form>
    </div>
  );
};

export default CSVReader;
