import React, { useState } from 'react';
import styles from './Desktop.module.scss';
import { withRouter } from 'react-router-dom';

import 'react-daterange-picker/dist/css/react-calendar.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import dimensions from '../../.../../../../../util/dimensions-util';
import Flex from '../../../../../components/Flex';

import bellIcon from '../../../../../assets/images/bell-icon/image.png';
import bgIcon from '../../../../../assets/images/background/image.png';
import truckIcon from '../../../../../assets/images/truck-icon-blue/image@3x.png';
import {
  adminImages,
  operatorImages,
  loginImages,
} from '../../../../../assets/images';
import TextInput from '../../../../../components/TextInput';
import Button from '../../../../../components/Button';

import Select from 'react-select';
import { customStyles } from './customStyles';
import FormElement from '../../../../../components/FormElement';
import moment from 'moment';
import strings from '../../../../../globalization';
import SannaLogo from '../../../../../components/SannaLogo';

const { vw: widthConstant, vh: heightConstant } = dimensions;

const STATUSES = {
  ISSUED: 'ISSUED',
  HOLD: 'HOLD',
  PENDING: 'PENDING',
  SURRENDERED: 'SURRENDER',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAYMENT_COMPLETED: 'PAYMENT_COMPLETED',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  OPERATOR_ADMIN_APPROVED: 'OPERATOR_ADMIN_APPROVED',
  OPERATOR_ADMIN_REJECTED: 'OPERATOR_ADMIN_REJECTED',
  DEPARTMENT_ADMIN_APPROVED: 'DEPARTMENT_ADMIN_APPROVED',
  DEPARTMENT_ADMIN_REJECTED: 'DEPARTMENT_ADMIN_REJECTED',
  REISSUE_PAYMENT_COMPLETED: 'REISSUE_PAYMENT_COMPLETED',
  REISSUE_PAYMENT_PENDING: 'REISSUE_PAYMENT_PENDING',
  REISSUE_PAYMENT_FAILED: 'REISSUE_PAYMENT_FAILED',
  UPDATED: 'UPDATED',
  UPDATE_REJECTED: 'UPDATE_REJECTED',
};

const RFID_ASSIGN_ALLOW_LIST = {
  REISSUE_PAYMENT_COMPLETED: 'REISSUE_PAYMENT_COMPLETED',
  DEPARTMENT_ADMIN_APPROVED: 'DEPARTMENT_ADMIN_APPROVED',
};

const Desktop = props => {
  const {
    onClick,
    onChange,
    searchText,
    filterOptions,
    filter,
    formElements,
    vehicleInfo,
    buttons,
    pageStatus,
    selectedType,
    successDetails,
    viewDocument,
    viewHistory,
    edit,
    stateData,
  } = props;
  const handleReject = () => { };

  const [error, setError] = useState('');
  console.log(buttons, edit);
  const validate = formData => {
    const { verifiedPersonalDetails, verifiedProofDocuments, RFID, confirmRFID } =
      formData;
    setError('');

    if (!verifiedPersonalDetails || !verifiedProofDocuments) {
      setError('Please verify all the documents');
      return false;
    }
    if (!RFID) {
      setError('Please assign an RFID number');
      return false;
    }
    if (
      (vehicleInfo.status === STATUSES.DEPARTMENT_ADMIN_APPROVED ||
        vehicleInfo.status === STATUSES.REISSUE_PAYMENT_COMPLETED) &&
      RFID !== confirmRFID
    ) {
      setError(`RFID Numbers do not match.`);
      return false;
    }
    return true;
  };

  const handleButtonClick = (action, dataObject) => {
    switch (action) {
      case 'approve': {
        handleApprove(action, dataObject);
        break;
      }
      case 'keepOnHold': {
        onClick(action, {
          vehicleInfo: vehicleInfo,
        });
        break;
      }
      case 'surrenderCard': {
        onClick(action, {
          vehicleInfo: vehicleInfo,
        });
        break;
      }
      case 'lost': {
        onClick(action, {
          vehicleInfo: vehicleInfo,
        });
        break;
      }
      case 'changeRFID': {
        onClick(action, {
          vehicleInfo: vehicleInfo,
        });
        break;
      }
      case 'edit': {
        onClick(action, {
          vehicleInfo: vehicleInfo,
        });
        break;
      }
      // case 'editCancel': {
      //   onClick(action, {
      //     vehicleInfo: vehicleInfo,
      //   });
      //   break;
      // }
      // case 'editSubmit': {
      //   onClick(action, {
      //     vehicleInfo: vehicleInfo,
      //   });
      //   break;
      // }
      default: {
        console.warn('case not handled');
        break;
      }
    }
  };
  const handleApprove = (action, dataObject) => {
    let isValid = validate(dataObject);
    console.log('isValid', isValid);
    if (!isValid) {
      return;
    } else {
      onClick(action, { ...dataObject, vehicleInfo: vehicleInfo });
    }
  };

  const handleEditCancel = dataObject => {
    onClick('editCancel', { ...dataObject, vehicleInfo: vehicleInfo });
  };

  const handleEditSubmit = dataObject => {
    onClick('editSubmit', { ...dataObject, vehicleInfo: vehicleInfo });
  };

  const getText = (type, data, RFIDNumber) => {
    switch (type) {
      case 'issued': {
        return (
          <span className={styles.message}>
            New cards with RFID No.: {RFIDNumber} has been approved for Vehicle:
            {data.vehicleRegistrationNumber}
          </span>
        );
      }
      case 'hold': {
        return (
          <span className={styles.message}>
            RFID Card number: {data.RFIDNumber} for Vehicle Number:{' '}
            {data.vehicleRegistrationNumber} has been kept on hold.
          </span>
        );
      }
      case 'surrendered': {
        return (
          <span className={styles.message}>
            RFID Card has been surrendered.
          </span>
        );
      }
      default: {
        break;
      }
    }
  };

  const getPageInfo = (status, viewHistory) => {
    switch (status) {
      case 'landing': {
        return (
          <div className={styles.defaultContainer}>
            <img
              src={operatorImages.noDataIcon}
              className={styles.noDataimage}
              alt="AVATAR"
            />
            <p>
              {' '}
              {`Search with Reference Number or Vehicle Registration Number to get details`}{' '}
            </p>
          </div>
        );
      }
      case 'showVehicleInfo': {
        return (
          <div className={styles.bodyContainer}>
            <TopSection
              formElements={formElements}
              vehicleInfo={vehicleInfo}
              viewDocument={viewDocument}
              viewHistory={viewHistory}
              edit={edit}
              stateData={stateData}
              onChange={onChange}
            />
            <BottomSection
              handleApprove={handleApprove}
              handleReject={handleReject}
              buttons={buttons}
              onClick={onClick}
              onChange={onChange}
              onButtonClick={handleButtonClick}
              error={error}
              vehicleInfo={vehicleInfo}
              key={vehicleInfo.id}
            />
          </div>
        );
      }
      case 'showEditVehicleInfo': {
        return (
          <div className={styles.bodyContainer}>
            <TopSection
              formElements={formElements}
              vehicleInfo={vehicleInfo}
              viewDocument={viewDocument}
              viewHistory={viewHistory}
              edit={edit}
              stateData={stateData}
              onChange={onChange}
            />
            <Flex
              className={[
                styles.buttonsContainer,
                styles.editButtonsContainer,
              ].join(' ')}>
              <>
                <button
                  className={styles.editCancelBtn}
                  onClick={handleEditCancel}>
                  Cancel
                </button>
                <button
                  className={styles.editSubmit}
                  onClick={handleEditSubmit}>
                  Submit
                </button>
              </>
            </Flex>
          </div>
        );
      }
      case 'success': {
        console.log('success details', successDetails);

        const { vehicleInfo, remark, type } = successDetails;
        return (
          <div className={styles.successContainer}>
            <img
              src={adminImages.truckIconBlue}
              className={styles.imageBlue}
              alt="AVATAR"
            />
            <div className={styles.referenceNumber}>
              Reference Number: {vehicleInfo.referenceId}
            </div>
            <div className={styles.messageWrapper}>
              <img
                className={styles.successIcon}
                src={loginImages.successIcon}
                alt="Logo"
              />

              {getText(type, vehicleInfo, successDetails?.RFIDNumber)}
              <span className={styles.remarks}>Remarks: {remark}</span>
              <button
                className={styles.button}
                onClick={() => onClick('landing')}>
                Okay!
              </button>
            </div>
          </div>
        );
      }
      case 'updated': {
        console.log('updated details', successDetails);

        const { vehicleInfo, type } = successDetails;
        return (
          <div className={styles.successContainer}>
            <img
              src={adminImages.truckIconBlue}
              className={styles.imageBlue}
              alt="AVATAR"
            />
            <div className={styles.referenceNumber}>
              Reference Number: {vehicleInfo.referenceId}
            </div>
            <div className={styles.messageWrapper}>
              <img
                className={styles.successIcon}
                src={loginImages.successIcon}
                alt="Logo"
              />
              <span className={styles.message}>
                Details have been updated successfully.
              </span>
              <button
                className={styles.button}
                onClick={() => onClick('landing')}>
                Okay!
              </button>
            </div>
          </div>
        );
      }

      default:
        break;
    }
  };

  return (
    <div className={styles.personCardsContainer}>
      <Header />
      {pageStatus !== 'success' ? (
        <FiltersRow
          searchText={searchText}
          onChange={onChange}
          filterOptions={filterOptions}
          filter={filter}
          onClick={onClick}
          selectedType={selectedType}
        />
      ) : null}
      {getPageInfo(pageStatus, viewHistory)}
      <SannaLogo className={styles.SannaLogo} />
    </div>
  );
};
export default withRouter(Desktop);

const Header = () => {
  return (
    <Flex className={styles.headerContainer}>
      <h1 className={styles.headerText}>Vehicle Cards</h1>
      <Flex style={{ alignItems: 'center' }}>
        <div className={styles.imageContainer}>
          <img src={bellIcon} className={styles.image} />
        </div>
        <img src={bgIcon} className={styles.image} />
      </Flex>
    </Flex>
  );
};
const getImageColumn = (vehicleInfo, viewHistory) => {
  return (
    <Flex direction="column" className={styles.imageTextContainer}>
      <img
        src={adminImages.truckIconBlue}
        className={styles.avatarImage}
        alt="AVATAR"
      />
      <p className={styles.text}>Reference Number: {vehicleInfo.referenceId}</p>
      <p className={styles.text}>
        Request Date: {moment(vehicleInfo.createdDate).format('DD/MM/YYYY')}
      </p>
      <p className={styles.text}>
        Status:{' '}
        <span style={getStyles(vehicleInfo)}>{getStatus(vehicleInfo)}</span>
      </p>
      {vehicleInfo.status === STATUSES.ISSUED ? (
        <p className={styles.text}>
          {/* FIXME: Remove modified date and add approved Date */}
          Approved date: {moment(vehicleInfo.modifiedDate).format('DD/MM/YYYY')}
        </p>
      ) : null}

      <button className={styles.history} onClick={viewHistory}>
        View History
      </button>
    </Flex>
  );
};
const getStatus = vehicleInfo => {
  let status = vehicleInfo.status;

  switch (status) {
    case STATUSES.ISSUED: {
      return 'Issued';
    }
    case STATUSES.HOLD: {
      return 'On Hold';
    }
    case STATUSES.SURRENDERED: {
      return 'Surrendered';
    }
    case STATUSES.PAYMENT_COMPLETED: {
      return 'Payment Completed';
    }
    case STATUSES.PAYMENT_PENDING: {
      return 'Payment Pending';
    }
    case STATUSES.PAYMENT_FAILED: {
      return 'Payment Failed';
    }
    case STATUSES.OPERATOR_ADMIN_APPROVED: {
      return 'Operator Admin Approved';
    }
    case STATUSES.OPERATOR_ADMIN_REJECTED: {
      return 'Operator Admin Rejected';
    }
    case STATUSES.DEPARTMENT_ADMIN_APPROVED: {
      return 'Department Admin Approved';
    }
    case STATUSES.DEPARTMENT_ADMIN_REJECTED: {
      return 'Department Admin Rejected';
    }
    case STATUSES.REISSUE_PAYMENT_PENDING: {
      return 'Reissue Payment Pending';
    }
    case STATUSES.REISSUE_PAYMENT_COMPLETED: {
      return 'Reissue Payment Completed';
    }
    case STATUSES: {
      return 'Reissue Payment Failed';
    }
    case STATUSES.REISSUE_PAYMENT_FAILED: {
      return 'Reissue Payment Failed';
    }
    case STATUSES.UPDATED: {
      return 'Updated';
    }
    case STATUSES.UPDATE_REJECTED: {
      return 'Update Rejected';
    }

    default: {
      return status;
    }
  }
};
const getStyles = vehicleInfo => {
  let status = vehicleInfo.status;

  switch (status) {
    case STATUSES.ISSUED: {
      return {
        padding: '2px 10px',
        borderRadius: '5px',
        backgroundColor: '#0AC15770',
      };
    }
    case STATUSES.HOLD: {
      return {
        padding: '2px 10px',
        borderRadius: '5px',
        backgroundColor: '#E6771770',
      };
    }
    case STATUSES.SURRENDERED: {
      return {
        padding: '2px 10px',
        borderRadius: '5px',
        backgroundColor: '#E6771770',
      };
    }
    default: {
      return {
        padding: '2px 10px',
        borderRadius: '5px',
        backgroundColor: '#0045E670',
      };
    }
  }
};

const getFormElements = (
  stateData,
  formElements,
  vehicleInfo,
  viewDocument,
  edit,
  onChange,
) => {
  console.log(edit, stateData);
  return (
    <Flex className={[styles.divider, styles.formFields, edit ? styles.h165 : ''].join(' ')}>
      {formElements.map((elem, idx) => (
        <FormElement
          key={`formLeftFields-${idx}`}
          type={elem.elementType}
          label={elem.label}
          required={elem.required}
          placeholderText={elem.placeholder}
          tabIndex={idx * 2 + 1}
          disabled={edit ? false : elem.disabled}
          value={
            edit ? stateData[elem.stateField] : vehicleInfo[elem.stateField]
          }
          onChange={text => onChange(elem.stateField, text)}
          fileIcon={vehicleInfo[elem.fileIcon]}
          maxLength={elem.maxLength}
          keyboardType={elem.keyboardType}
          containerStyle={{ marginBotton: '40px' }}
          maxFiles={elem.maxFiles}
          allowedFormats={elem.allowedFormats}
          minimumDate={elem.minimumDate}
          isVerifyOtp={elem.verifyOtp}
          fieldName={elem.stateField}
          options={stateData[elem.optionsField]}
          containerClassNames={styles.containerClassNames}
          labelClassNames={styles.labelClassNames}
          uppercase={elem.uppercase}
          onRightIconClick={() => viewDocument(vehicleInfo[elem.fileIcon])}
        />
      ))}
    </Flex>
  );
};

const TopSection = ({
  formElements,
  vehicleInfo,
  viewDocument,
  viewHistory,
  edit,
  stateData,
  onChange,
}) => {
  console.log(edit);
  const className =
    vehicleInfo.status === 'Approved'
      ? styles.approvedStatus
      : styles.holdStatus;
  return (
    <div className={styles.topSection}>
      <Flex className={styles.formContainer}>
        {getFormElements(
          stateData,
          formElements,
          vehicleInfo,
          viewDocument,
          edit,
          onChange,
        )}
        {getImageColumn(vehicleInfo, viewHistory)}
      </Flex>
    </div>
  );
};

const BottomSection = ({ buttons, onButtonClick, vehicleInfo, error }) => {
  const [RFID, setRFID] = useState(
    vehicleInfo.RFIDNumber ? vehicleInfo.RFIDNumber : '',
  );
  const [confirmRFID, setConfirmRFID] = useState('');
  // const [remark, setRemark] = useState(
  //   vehicleInfo.remarks ? vehicleInfo.remarks : '',
  // );
  console.log('buttons:', buttons);
  const [verifiedPersonalDetails, setVerifiedPersonalDetails] = useState(false);
  const [verifiedProofDocuments, setVerifiedProofDocuments] = useState(false);
  return (
    <div>
      <div className={styles.checkboxes}>
        <div>
          <input
            id="verifiedPersonalDetails"
            type="checkbox"
            className={styles.checkbox}
            checked={verifiedPersonalDetails}
            onChange={() =>
              setVerifiedPersonalDetails(!verifiedPersonalDetails)
            }
          />
          <label className={styles.label} htmlFor="verifiedPersonalDetails">
            Verified Personal Details
          </label>
        </div>
        <div>
          <input
            id="verifiedProofDocuments"
            type="checkbox"
            className={styles.checkbox}
            checked={verifiedProofDocuments}
            onChange={() => setVerifiedProofDocuments(!verifiedProofDocuments)}
          />
          <label className={styles.label} htmlFor="verifiedProofDocuments">
            Verified Proof Documents
          </label>
        </div>
      </div>
      <Flex className={styles.bottomFields}>
        <div className={styles.formInput}>
          {RFID_ASSIGN_ALLOW_LIST[vehicleInfo.status] && (
            <FormElement
              type="text"
              label={`Enter RFID Tag Number`}
              disableCopyPaste
              placeholderText={strings.pleaseEnterRFID}
              value={confirmRFID}
              maxLength={30}
              onChange={value => setConfirmRFID(value)}
              required
              disabled={
                RFID_ASSIGN_ALLOW_LIST[vehicleInfo.status] ? false : true
              }
              containerClassNames={styles.containerClassNames}
              labelClassNames={styles.labelClassNames}
              error={error}
              viewType={`password`}
            />
          )}
          <FormElement
            type="text"
            label="Confirm RFID Tag Number"
            placeholderText="Please Enter RFID Tag Number"
            value={RFID}
            disableCopyPaste
            onChange={text => setRFID(text)}
            maxLength={30}
            containerClassNames={styles.containerClassNames}
            labelClassNames={styles.labelClassNames}
            required
            error={error}
            disabled={RFID_ASSIGN_ALLOW_LIST[vehicleInfo.status] ? false : true}
          />
        </div>
      </Flex>
      <Flex className={styles.buttonsContainer}>
        {buttons.map(button => {
          return (
            <button
              className={button.style}
              onClick={() =>
                onButtonClick(button.action, {
                  RFID,
                  verifiedPersonalDetails,
                  verifiedProofDocuments,
                  confirmRFID,
                })
              }>
              {button.text}
            </button>
          );
        })}
      </Flex>
    </div>
  );
};

const FiltersRow = ({
  onClick,
  onChange,
  searchText,
  filterOptions,
  filter,
  selectedType,
}) => {
  return (
    <Flex className={styles.filterBarContainer}>
      <Flex className={styles.selectWrapper}>
        <Select
          options={filterOptions}
          value={filterOptions.filter(option => option.id === selectedType)}
          defaultValue={
            filter
              ? (filterOptions || [])
                .map(option => ({
                  value: option.id,
                  label: option.name,
                }))
                .find(o => o.id === filter)
              : null
          }
          // value={filter}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          placeholder={'Select Type'}
          styles={customStyles}
          onChange={({ id }) => {
            onChange('filter', id);
          }}
        />
      </Flex>

      <Flex className={styles.searchBox}>
        <TextInput
          onChange={update => {
            onChange('searchText', update);
          }}
          value={searchText}
          isEdit={true}
          type={'text'}
          placeholder={'Enter ref. number or reg. number or RFID number'}
          inputStyle={styles.inputText}
          showSeacrh={true}
        />
      </Flex>

      <Button
        onClick={() => onClick('apply')}
        buttonStyle="primary"
        label={'Go'}
        disabled={!(selectedType && searchText)}
        className={styles.button}></Button>
    </Flex>
  );
};
