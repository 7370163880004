import React from 'react';
import successIcon from '../../assets/images/success/image.png';

import styles from './styles.module.scss';
import strings from '../../globalization';
import Button from '../Button';
import Flex from '../Flex';

export const PaymentComplete = ({
  entity,
  returnedObject,
  onClick,
  handleLoginClick,
  getTextContent,
  containerClassNames,
}) => {
  console.log('entity', entity);
  console.log('returnedObject', returnedObject);

  return (
    <Flex
      direction="column"
      className={[styles.container, containerClassNames].join(' ')}>
      <img src={successIcon} alt="X" className={styles.image} />
      <p className={styles.result}>
        {entity} {'Payment Completed'} -{' '}
        {getTextContent(entity, returnedObject)}
      </p>
      <p>
        {returnedObject.note && `${strings.reason}: ${returnedObject.note}`}
      </p>
      <p>
        {/* {strings.youShouldHaveAlreadyReceived}{' '}
        <button onClick={onClick} className={styles.clickHere}>
          {strings.clickHere}
        </button> */}
      </p>
      <Button
        buttonStyle="primary"
        label="Go to login"
        className={styles.submitButton}
        onClick={handleLoginClick}
      />
    </Flex>
  );
};

export default PaymentComplete;
