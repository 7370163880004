import React from 'react';
import pendingIcon from '../../assets/images/pending-icon/image.png';

import styles from './styles.module.scss';
import strings from '../../globalization';
import Button from '../Button';
import Flex from '../Flex';

export const PaymentPending = ({
  entity,
  returnedObject,
  onClick,
  handleLoginClick,
  getTextContent,
  containerClassNames,
}) => {
  console.log('entity', entity);
  console.log('returnedObject', returnedObject);

  return (
    <Flex
      direction="column"
      className={[styles.container, containerClassNames].join(' ')}>
      <img src={pendingIcon} alt="X" className={styles.image} />
      <p className={styles.result}>
        {entity} {'Payment Pending'} - {getTextContent(entity, returnedObject)}
      </p>
      <p>{strings.yourRequestIsStillPending}</p>
    </Flex>
  );
};

export default PaymentPending;
