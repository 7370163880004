export const dailyMonitoringProgress = [
  {
    date: new Date(),
    permitRequests: 40,
    agency: 20,
    driver: 20,
    vehicle: 10,
  },
  {
    date: new Date(),
    permitRequests: 40,
    agency: 20,
    driver: 20,
    vehicle: 10,
  },
  {
    date: new Date(),
    permitRequests: 40,
    agency: 20,
    driver: 20,
    vehicle: 10,
  },
  {
    date: new Date(),
    permitRequests: 40,
    agency: 20,
    driver: 20,
    vehicle: 10,
  },
  {
    date: new Date(),
    permitRequests: 40,
    agency: 20,
    driver: 20,
    vehicle: 10,
  },
  {
    date: new Date(),
    permitRequests: 40,
    agency: 20,
    driver: 20,
    vehicle: 10,
  },
  {
    date: new Date(),
    permitRequests: 40,
    agency: 20,
    driver: 20,
    vehicle: 10,
  },
];
