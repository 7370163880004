import React from 'react';
import Flex from '../../../../../../components/Flex';
import FormElement from '../../../../../../components/FormElement';
import styles from './styles.module.scss';

export default function Desktop({
  onClick,
  checkDisabled,
  onChange,
  selectedVehicle,
  selectedVehicleOptions,
}) {
  return (
    <Flex className={styles.mainContainer}>
      <Flex className={styles.container}>
        <Header />
        <Flex direction="row" className={styles.cardButtonContainer}>
          <FormElement
            type={'dropdown'}
            label={'Select Vehicle'}
            required={true}
            placeholderText={'Select available vehicle'}
            onChange={text => {
              onChange('selectedVehicle', text);
            }}
            options={selectedVehicleOptions}
            value={selectedVehicle.value}
            error={selectedVehicle.error}
            dropdownStyle={{height: '45px'}}
            containerClassNames={styles.containerClassNames}
          />
        </Flex>

        <Flex direction="row" className={styles.buttonContainer}>
          <button className={styles.cancel} onClick={() => onClick('cancel')}>
            Cancel
          </button>
          <button
            disabled={checkDisabled()}
            className={styles.finish}
            style={
              checkDisabled()
                ? {backgroundColor: '#0045e650', cursor: 'not-allowed'}
                : {backgroundColor: '#0045e6'}
            }
            onClick={() => onClick('proceed')}>
            Proceed
          </button>
        </Flex>
      </Flex>
    </Flex>
  );
}

const Header = () => {
  return (
    <Flex className={styles.header}>
      <p>{'Select Vehicle'}</p>
    </Flex>
  );
};
