import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss';
import util from '../../util';
import strings from '../../globalization';
import Flex from '../Flex';

// eslint-disable-next-line max-lines-per-function
const CheckText = ({
  inputType,
  inputLable,
  onTextChange,
  onKeyDown,
  placeholder,
  onLabelPress,
  style,
  value,
  keyboardType = 'default',
  textStyle,
  leftIcon,
  rightIcon,
  fileIcon,
  showBottomRightLabel,
  rightLabel,
  onIconClick,
  toggleEyeIcon,
  showIcon = false,
  iconName = '',
  inputLabelStyle,
  isNumber = false,
  containerStyle,
  required = false,
  placeholderColor = '#D3D3D4',
  error,
  onBlur,
  maxLength,
  tabIndex,
  labelStyles,
  containerStyles,
  iconStyles,
  type,
  onRightIconClick,
  inputBoxClassName,
  containerClassNames,
  labelClassNames,
  isEdit,
  disabled,
  uppercase = false,
  ...inputProps
}: Props) => {
  const [viewed, setViewed] = useState(false);

  const textChangeHandler = text => {
    if (isNumber && +text < 0) {
      util.throwError(`${inputLable} ${strings.cannotBeNegative}`);
      return;
    }
    if (uppercase) {
      text = text.toUpperCase();
    }
    text = text.replace(/\s+/g, ' ');
    // let trimmed = text.trim();
    onTextChange(text);
  };

  const handleRightIconClick = () => {
    setViewed(true);
    onRightIconClick && onRightIconClick();
  };

  return (
    <div style={containerStyles} className={styles.container}>
      <Flex className={styles.labelContainer}>
        <p className={[styles.label, labelStyles, labelClassNames].join(' ')}>
          {inputLable}
        </p>
        {required ? <span style={{color: 'red'}}>*</span> : null}
        {viewed && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            className={styles.viewed}
            fill="#16a34a">
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </Flex>

      <Flex
        style={style}
        className={[
          styles.inputBox,
          inputBoxClassName,
          containerClassNames,
        ].join(' ')}>
        {leftIcon && (
          <Flex direction="column" className={styles.imageContainer}>
            <img
              alt="U"
              src={leftIcon}
              className={styles.leftIconStyles}
              style={iconStyles}
            />
          </Flex>
        )}
        <input
          // {...inputProps}
          tabIndex={tabIndex}
          className={styles.input}
          value={value}
          placeholder={placeholder}
          // secureTextEntry={inputType}
          onChange={e => textChangeHandler(e.target.value)}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          maxLength={maxLength}
          spellCheck="false"
          type={type}
          disabled={disabled}
          style={
            disabled ? {backgroundColor: '#FAFAFA', color: '#c4c6ca'} : null
          }
          {...inputProps}
        />
        {rightIcon && (
          <Flex
            direction="column"
            onClick={onRightIconClick}
            className={styles.rightImageContainer}>
            <img
              alt="U"
              src={rightIcon}
              className={styles.rightIconStyles}
              style={iconStyles}
            />
          </Flex>
        )}
        {fileIcon && (
          <Flex
            direction="column"
            onClick={handleRightIconClick}
            className={styles.fileContainer}>
            View Document
          </Flex>
        )}
      </Flex>

      <Flex style={{width: '100%', height: '1rem'}}>
        <p className={styles.error}>{error}</p>
      </Flex>
    </div>
  );
};

export default CheckText;
