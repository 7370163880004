export const customStyles = {
  placeholder: (provided, state) => {
    return {
      ...provided,
      fontFamily: 'Poppins-Regular',
      fontSize: '14px',
      color: '#002C94',
      letterSpacing: '0.56px',
      fontWeight: 400,
      display: 'flex',
      alignItems: 'center',
    };
  },
  control: (provided, state) => {
    return {
      ...provided,
      boxShadow: 'none',
    };
  },
  indicatorSeparator: provided => {
    return {
      ...provided,
      backgroundColor: '#E6EDFF',
    };
  },
  singleValue: provided => {
    return {
      ...provided,
      fontWeight: 'bold',
      fontFamily: 'Poppins-SemiBold',
    };
  },
};
