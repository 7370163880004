import React, {useState, useEffect} from 'react';
import pendingIcon from '../../../assets/images/pending-icon/image.png';

import styles from './styles.module.scss';
import strings from '../../../globalization';
import Button from '../../Button';
import Flex from '../../Flex';
import bgIcon from '../../../assets/images/placeholder-person/image.png';
import documentIcon from '../../../assets/images/document-icon/image.png';
import moment from 'moment';
import {API} from '../../../requests';

const ViewPerson = ({person, onClose, viewDocument}) => {
  console.log('person', person);
  const [photoURL, setPhotoURL] = useState(person?.photo);

  useEffect(() => {
    preSignUrl(person?.photo);
  }, []);
  const preSignUrl = async url => {
    const data = {
      url: url,
      expiryTime: 30,
    };
    await API.preSignedURL(data)
      .then(res => {
        console.log('res.data', res.data?.preSignedUrl);
        setPhotoURL(res.data?.preSignedUrl);
      })
      .catch(error => {
        console.log(error);
      });
  };
  return (
    <Flex direction="column" className={styles.container}>
      <Header />
      <Flex direction="row" className={styles.detailsContainer}>
        <img src={photoURL} alt="U" className={styles.personImage} />
        <Flex direction="column" className={styles.detailsColumn}>
          <LabelValue label="Name" value={person.name} />
          <LabelValue label="Gender" value={person.gender} />
          <LabelValue label="Document Type" value={person.documentTypeName} />
          <LabelValue label="Aadhaar Number" value={person.aadhaarNumber} />
          <LabelValue
            label="Date of Birth"
            value={moment(person.dob).format('DD/MM/YYYY')}
          />
        </Flex>
        <Flex direction="column" className={styles.detailsColumn}>
          <LabelValue label="Phone Number" value={person.phoneNumber} />
          <LabelValue label="Email Address" value={person.emailAddress} />
          <LabelValue label="Person Type" value={person.personType} />

          <LabelValue label="RFID Card Number" value={person.rfidnumber} />
          <Flex
            direction="row"
            className={styles.labelValueContainer}
            style={{alignItems: 'center'}}>
            <p className={styles.label}>Document: &nbsp;</p>
            <p className={styles.value} style={{marginRight: '0.4vw'}}>
              {' '}
              {`Aadhaar Card`}
            </p>
            <img
              onClick={() => viewDocument(person.aadhaarDocument)}
              src={documentIcon}
              alt="U"
              className={styles.documentIcon}
            />
          </Flex>
        </Flex>
      </Flex>
      <button onClick={onClose} className={styles.goBack}>
        Go Back
      </button>
    </Flex>
  );
};

const Header = () => {
  return (
    <Flex className={styles.header}>
      <p>{'Person Details'}</p>
    </Flex>
  );
};

const LabelValue = ({label, value}) => {
  return (
    <Flex direction="row" className={styles.labelValueContainer}>
      <p className={styles.label}>{label}: &nbsp;</p>
      <p className={styles.value}> {value}</p>
    </Flex>
  );
};

export default ViewPerson;
