import React, {useRef, useState} from 'react';
import styles from './styles.module.scss';
import {deptDashBoard} from '../../../../../assets/images';

import dimensions from '../../../../../util/dimensions-util';
import Flex from '../../../../../components/Flex';

import 'react-datasheet/lib/react-datasheet.css';
import moment from 'moment';

import operatorMan from '../../../../../assets/images/operator-dashboard-images/man/image@3x.png';
import personFileIcon from '../../../../../assets/images/operator-dashboard-images/person-file-icon/image@3x.png';
import vehicleFileIcon from '../../../../../assets/images/operator-dashboard-images/vehicle-file-icon/image@3x.png';
import personIcon from '../../../../../assets/images/operator-dashboard-images/person-icon/image@3x.png';
import vehicleIcon from '../../../../../assets/images/operator-dashboard-images/vehicle-icon/image@3x.png';
import cardsIcon from '../../../../../assets/images/operator-dashboard-images/cards/image@3x.png';
import tagsIcon from '../../../../../assets/images/operator-dashboard-images/tags/image@3x.png';
import SannaLogo from '../../../../../components/SannaLogo';

const ROLE_MAPPER = {
  COMPANY_ADMIN: 'Company Admin',
  COMPANY_SUPER_ADMIN: 'Company Super Admin',
  DEPARTMENT_VIEWER: 'Department Viewer',
  DEPARTMENT_ADMIN: 'Department Admin',
  DEPARTMENT_SUPER_ADMIN: 'Department Super Admin',
  DEPUTY_CHAIRMAN: 'Deputy Chairman',
  MASTER: 'Master',
  OPERATOR_ADMIN: 'Operator Admin',
  OPERATOR_EMPLOYEE: 'Operator Employee',
  OPERATOR_SUPER_ADMIN: 'Operator Super Admin',
  COMPANY_VIEWER: 'Company Viewer',
  SIGNATORY: 'Signatory',
};

export default function Desktop({onClick, user, dashboardDetails}) {
  console.log(
    'dashboard details',
    dashboardDetails,
    dashboardDetails?.toDay?.dailyTotalPersons,
  );
  return (
    <>
      <Flex direction="column" className={styles.mimicContainer}>
        <Header onClick={onClick} user={user} />
        <div className={styles.mainContainer}>
          <p className={styles.date}>
            <span>Today,</span> {moment().format('Do MMM YYYY')}
          </p>
          <div className={styles.topContainer}>
            <div className={styles.registrationsContainer}>
              <div className={styles.paddingNormal}>
                <h1 className={styles.boxHeading}>Registrations</h1>
              </div>
              <DivideBottom />
              <div className={styles.registrationsMainContent}>
                <img src={operatorMan} className={styles.man} alt="man icon" />
                <div className={styles.registrationsContent}>
                  <RegistrationsBox
                    title={`Person`}
                    number={
                      dashboardDetails?.toDay?.dailyPersonRegistrations
                        ?.dailyTotalPersons
                    }
                    pending={
                      dashboardDetails?.toDay?.dailyPersonRegistrations
                        ?.daiylyPersonsPending
                    }
                    submitted={
                      dashboardDetails?.toDay?.dailyPersonRegistrations
                        ?.dailyPersonsSubmitted
                    }
                    resubmitted={
                      dashboardDetails?.toDay?.dailyPersonRegistrations
                        ?.dailyPersonsReSubmitted
                    }
                    updated={
                      dashboardDetails?.toDay?.dailyPersonRegistrations
                        ?.dailyPersonsUpdated
                    }
                  />
                  <DashedBorder />
                  <RegistrationsBox
                    title={`Vehicle`}
                    number={
                      dashboardDetails?.toDay?.dailyVehicleRegistrations
                        ?.dailyTotalVehicle
                    }
                    pending={
                      dashboardDetails?.toDay?.dailyVehicleRegistrations
                        ?.dailyVehiclesPending
                    }
                    submitted={
                      dashboardDetails?.toDay?.dailyVehicleRegistrations
                        ?.dailyVehiclesSubmitted
                    }
                    resubmitted={
                      dashboardDetails?.toDay?.dailyVehicleRegistrations
                        ?.dailyVehiclesReSubmitted
                    }
                    updated={
                      dashboardDetails?.toDay?.dailyVehicleRegistrations
                        ?.dailyVehiclesUpdated
                    }
                  />
                </div>
              </div>
            </div>
            <div className={styles.passesIssuedContainer}>
              <div className={styles.paddingNormal}>
                <h1 className={styles.boxHeading}>Passes Issued</h1>
              </div>
              <DivideBottom />
              <Flex
                direction="column"
                style={{
                  padding: '1rem',
                  justifyContent: 'space-between',
                  height: '80%',
                }}>
                <PassesIssuedBox
                  icon={personFileIcon}
                  number={
                    dashboardDetails?.toDay?.dailyPersonRegistrations
                      ?.dailyTotalPersonsIssued
                  }
                  title={`Person`}
                />
                <DashedBorder />
                <PassesIssuedBox
                  icon={vehicleFileIcon}
                  number={
                    dashboardDetails?.toDay?.dailyVehicleRegistrations
                      ?.dailyTotalVehicleIssued
                  }
                  title={`Vehicle`}
                />
              </Flex>
            </div>
          </div>

          <p className={styles.date}>
            <span>Till Date</span>
          </p>

          <div className={styles.bottomContainer}>
            <Flex className={styles.leftBoxes}>
              <BottomBox
                title={`Person`}
                registered={
                  dashboardDetails?.tillDate?.totalPersonRegistrations
                    ?.totalPersonRegistraions
                }
                issued={
                  dashboardDetails?.tillDate?.totalPersonRegistrations
                    ?.totalPersonCardIssues
                }
                icon={personIcon}
              />
              <BottomBox
                title={`Vehicle`}
                registered={
                  dashboardDetails?.tillDate?.totalVehicleRegistrations
                    ?.totalVehicleRegistrations
                }
                issued={
                  dashboardDetails?.tillDate?.totalVehicleRegistrations
                    ?.totalVehicleCardIssues
                }
                icon={vehicleIcon}
                type={`vehicle`}
              />
            </Flex>
            <Flex className={styles.rightBoxes}>
              <SmallBox
                title={`Lost / Damaged`}
                cards={
                  dashboardDetails?.tillDate?.totalPersonRegistrations
                    ?.totalLostPersonCards
                }
                tags={
                  dashboardDetails?.tillDate?.totalVehicleRegistrations
                    ?.totalLostVehicleCards
                }
              />
              <SmallBox
                title={`Surrendered`}
                cards={
                  dashboardDetails?.tillDate?.totalPersonRegistrations
                    ?.totalSurrenderedPersonCards
                }
                tags={
                  dashboardDetails?.tillDate?.totalVehicleRegistrations
                    ?.totalSurrenderedVehicleCards
                }
              />
            </Flex>
          </div>
        </div>
        <SannaLogo />
      </Flex>
    </>
  );
}
const SmallBox = ({title, cards, tags}) => {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return (
    <div className={styles.barBox}>
      <Flex className={styles.paddingNormal} style={{alignItems: 'center'}}>
        <h1 className={styles.boxHeading} style={{fontSize: '1rem'}}>
          {title}
        </h1>
      </Flex>
      <Flex className={styles.smallBoxContainer}>
        <Flex
          className={styles.smallBoxContent}
          style={{backgroundColor: '#eff2f8'}}>
          <Flex className={styles.smallBoxContentItem}>
            <Flex style={{alignItems: 'center'}}>
              <img src={cardsIcon} alt="cards" />
              <p style={{fontFamily: 'Poppins-Light'}}>Cards</p>
            </Flex>
            <p style={{fontFamily: 'Poppins-Bold'}}>{cards}</p>
          </Flex>
        </Flex>
        <Flex
          className={styles.smallBoxContent}
          style={{backgroundColor: '#e5e9ef'}}>
          <Flex className={styles.smallBoxContentItem}>
            <Flex style={{alignItems: 'center'}}>
              <img src={tagsIcon} alt="cards" />
              <p style={{fontFamily: 'Poppins-Light'}}>Tags</p>
            </Flex>
            <p style={{fontFamily: 'Poppins-Bold'}}>{tags}</p>
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};

const BottomBox = ({title, icon, registered, issued, type = 'person'}) => {
  const calculatePercentage = () => {
    let percentage = (Number(issued) / Number(registered)) * 100;

    console.log('percentage', Math.round(percentage) + '%');
    return Math.round(percentage) + '%';
  };
  function numberWithCommas(x) {
    if (!x) return;
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return (
    <div className={styles.barBox}>
      <Flex className={styles.paddingNormal} style={{alignItems: 'center'}}>
        <img className={styles.bottomBoxImage} src={icon} alt="icon" />
        <h1 className={styles.boxHeading} style={{fontSize: '1rem'}}>
          {title}
        </h1>
      </Flex>
      <DivideBottom style={{marginBottom: '1.4rem'}} />
      <div className={styles.metrics}>
        <Flex className={styles.metricContainer}>
          <p>Registered</p>
          <span>{numberWithCommas(registered)}</span>
        </Flex>
        <div className={styles.bar}></div>
      </div>

      <div className={styles.metrics}>
        <Flex className={styles.metricContainer}>
          <p>{type === 'person' ? 'Cards' : 'Tags'} Issued</p>
          <span>{numberWithCommas(issued)}</span>
        </Flex>
        <div className={styles.barContainer}>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: '#CBE8C770',
              borderRadius: '15px',
              width: `${calculatePercentage()}`,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const PassesIssuedBox = ({icon, title, number}) => {
  return (
    <Flex direction="row" className={styles.passesIssuedBox}>
      <img
        src={icon}
        className={styles.passesIssuedImage}
        style={{marginRight: '0.5rem'}}
        alt="icon"
      />
      <Flex direction="column">
        <p className={styles.boxHeading} style={{fontSize: '1.8rem'}}>
          {number}
        </p>
        <p className={styles.subHeadingBox}>{title}</p>
      </Flex>
    </Flex>
  );
};

const RegistrationsBox = ({
  title,
  number,
  pending,
  submitted,
  resubmitted,
  updated,
}) => {
  return (
    <div className={styles.regTop}>
      <div style={{marginRight: '1rem'}}>
        <p className={styles.boxHeading} style={{fontSize: '1.8rem'}}>
          {number}
        </p>
        <p className={styles.subHeadingBox}>{title}</p>
      </div>
      <NumberBox number={pending} title={`Pending`} />
      <NumberBox number={submitted} title={`Submitted`} />
      <NumberBox number={resubmitted} title={`Resubmitted`} />
      <NumberBox number={updated} title={`Updated`} />
    </div>
  );
};

const NumberBox = ({title, number}) => {
  return (
    <div className={styles.numberBox}>
      <p>{number}</p>
      <span>{title}</span>
    </div>
  );
};

const Header = ({onClick, user}) => {
  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerText}>{`Operator Module Dashboard`}</div>
      <div
        className={styles.profileContainer}
        onClick={() => onClick('profile')}>
        <img
          className={styles.pic}
          alt="icon"
          src={deptDashBoard.deptDashBoardUserIcon}></img>
        <div>
          <div className={styles.name}>{user?.firstName}</div>
          <div className={styles.role}>{ROLE_MAPPER[user?.roles?.name]}</div>
        </div>
        <img
          alt="settings"
          className={styles.settingBtn}
          src={deptDashBoard.deptDashBoardSettingIcon}
        />
      </div>
    </div>
  );
};

const DivideBottom = props => {
  return <div {...props} className={styles.divideBottom}></div>;
};

const DashedBorder = () => {
  return <div className={styles.dashedBorder}></div>;
};
