import strings from '../../../globalization';

export const getFormData = () => {
  return [
    {
      elementType: 'text',
      inputType: 'alphabets',
      label: strings.name,
      placeholder: strings.enterName,
      stateField: 'name',
      id: 'name',
      stateDefault: '',
      minLength: 5,
      maxLength: 128,
    },
    {
      inputType: 'phone',
      elementType: 'phone',
      keyboardType: 'number-pad',
      label: strings.phoneNumberLabel,
      placeholder: strings.enterPhoneNumber,
      stateField: 'phoneNumber',
      id: 'phoneNumber',
      stateDefault: '',
      maxLength: 10,
      // verifyOtp: true,
    },
    {
      elementType: 'text',
      keyboardType: 'default',
      inputType: 'email',
      label: strings.emailId,
      placeholder: strings.enterEmailAddress,
      stateField: 'emailAddress',
      id: 'emailAddress',
      stateDefault: '',
      maxLength: 50,
    },
    {
      elementType: 'dropdown',
      label: strings.gender,
      placeholder: strings.selectGender,
      optionsField: 'genderOptions',
      stateField: 'genderId',
      id: 'genderId',
      stateDefault: null,
      maxLength: 20,
    },
    {
      elementType: 'date',
      keyboardType: 'default',
      inputType: 'date',
      label: strings.dateOfBirth,
      placeholder: strings.selectDate,
      stateField: 'dob',
      id: 'dob',
      stateDefault: null,
      maxLength: 20,
    },

    {
      elementType: 'dropdown',
      label: strings.countryField,
      placeholder: strings.selectCountry,
      optionsField: 'countryOptions',
      stateField: 'countryId',
      id: 'countryId',
      stateDefault: 1,
      maxLength: 20,
    },
    {
      elementType: 'text',
      inputType: 'alphabets',
      label: strings.nationality,
      placeholder: strings.enterNationality,
      stateField: 'nationality',
      id: 'nationality',
      stateDefault: 'Indian',
      minLength: 5,
      maxLength: 128,
    },

    {
      elementType: 'dropdown',
      label: strings.personTypeField,
      placeholder: strings.personTypePlaceholder,
      optionsField: 'personTypeOptions',
      stateField: 'typeId',
      id: 'typeId',
      stateDefault: null,
      maxLength: 20,
    },
    {
      elementType: 'text',
      inputType: 'alphabets',
      label: strings.aadharNumber,
      placeholder: strings.aadharNumber,
      stateField: 'aadhaarNumber',
      id: 'aadhaarNumber',
      stateDefault: '',
      minLength: 5,
      maxLength: 128,
    },
    {
      elementType: 'text',
      inputType: 'alphabets',
      label: `Driving Licence Number`,
      placeholder: `Driving Licence Number`,
      stateField: 'drivingLicenceNumber',
      id: 'drivingLicenceNumber',
      stateDefault: '',
      minLength: 5,
      maxLength: 128,
    },
    {
      elementType: 'text',
      keyboardType: 'default',
      inputType: 'text',
      label: strings.aadharNumberDocumentField,
      placeholder: strings.aadharNumberDocumentField,
      stateField: 'aadhaarDocument',
      id: 'aadhaarDocument',
      fileIcon: 'aadhaarDocument',
      stateDefault: '',
      maxLength: 50,
    },
    {
      elementType: 'text',
      keyboardType: 'default',
      inputType: 'text',
      label: `Driving Licence`,
      placeholder: `Driving Licence Document`,
      stateField: 'drivingLicenceDocument',
      id: 'drivingLicenceDocument',
      fileIcon: 'drivingLicenceDocument',
      stateDefault: '',
      maxLength: 50,
    },
    {
      elementType: 'text',
      keyboardType: 'default',
      inputType: 'text',
      label: `Company Name`,
      placeholder: `Company Name`,
      stateField: 'company',
      id: 'company',
      stateDefault: '',
      maxLength: 50,
    },
    {
      elementType: 'text',
      keyboardType: 'default',
      inputType: 'text',
      label: `Department Name`,
      placeholder: `Department Name`,
      stateField: 'departmentName',
      id: 'departmentName',
      stateDefault: '',
      maxLength: 120,
    },

    // {
    //   elementType: 'dropdown',
    //   label: strings.documentTypeField,
    //   placeholder: strings.documentTypePlaceholder,
    //   optionsField: 'documentTypeOptions',
    //   stateField: 'documentTypeId',
    //   id: 'documentTypeId',
    //   stateDefault: null,
    //   maxLength: 20,
    // },
    // {
    //   elementType: 'text',
    //   keyboardType: 'default',
    //   inputType: 'text',
    //   label: strings.documentProofNumber,
    //   placeholder: strings.documentProofNumberPlaceHoler,
    //   stateField: 'documentProofNumber',
    //   id: 'documentProofNumber',
    //   fileIcon: 'drivingLicenceUrl',
    //   stateDefault: '',
    //   maxLength: 50,
    // },
  ];
};

export const getEditFormData = () => {
  return [
    {
      elementType: 'text',
      inputType: 'alphabets',
      label: strings.name,
      placeholder: strings.enterName,
      stateField: 'name',
      id: 'name',
      stateDefault: '',
      minLength: 5,
      maxLength: 128,
    },

    {
      elementType: 'date',
      keyboardType: 'default',
      inputType: 'date',
      label: strings.dateOfBirth,
      placeholder: strings.selectDate,
      stateField: 'dob',
      id: 'dob',
      stateDefault: null,
      maxLength: 20,
    },

    {
      inputType: 'phone',
      elementType: 'phone',
      keyboardType: 'number-pad',
      label: strings.phoneNumberLabel,
      placeholder: strings.enterPhoneNumber,
      stateField: 'phoneNumber',
      id: 'phoneNumber',
      stateDefault: '',
      maxLength: 10,
      // verifyOtp: true,
    },

    {
      elementType: 'text',
      keyboardType: 'default',
      inputType: 'number',
      label: strings.aadharNumberField,
      placeholder: strings.aadharNumberPlaceholder,
      stateField: 'aadhaarNumber',
      stateDefault: '',
      id: 'aadhaarNumber',
      minLength: 12,
      maxLength: 12,
      dependentOnOtherFields: false,
    },

    {
      elementType: 'text',
      inputType: 'alphanumeric',
      keyboardType: 'default',
      label: `Driving Licence Number`,
      placeholder: `Driving Licence Number`,
      stateField: 'drivingLicenceNumber',
      id: 'drivingLicenceNumber',
      stateDefault: '',
      minLength: 10,
      maxLength: 16,
      uppercase: true,
    },
  ];
};
