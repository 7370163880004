import React, {useState} from 'react';
import strings from '../../../globalization';
import Flex from '../../Flex';
import FormElement from '../../FormElement';
import styles from './styles.module.scss';

const ChangeRFID = ({header, onSubmit, onCancel}) => {
  const [RFID, setRFID] = useState('');
  const [confirmRFID, setConfirmRFID] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = () => {
    let isValid = validate();
    console.log('isValid', isValid);

    console.log('err', isValid);
    if (!isValid) {
      return;
    } else {
      onSubmit(RFID);
    }
  };
  const handleCancel = () => {
    onCancel();
  };

  const validate = () => {
    setError('');
    if (!RFID || !confirmRFID) {
      setError(`Fields cannot be empty.`);
      return false;
    } else if (RFID !== confirmRFID) {
      setError(`RFID Numbers do not match.`);
      return false;
    }
    return true;
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>{header || `Change RFID Card Number`}</h1>
      <Flex className={styles.bottomFields}>
        <div className={styles.formInput}>
          <FormElement
            type="text"
            label={`Enter RFID Card Number`}
            placeholderText={strings.pleaseEnterRFID}
            value={confirmRFID}
            maxLength={30}
            onChange={value => setConfirmRFID(value)}
            required
            //   disabled={person.status !== STATUSES.DEPARTMENT_ADMIN_APPROVED}
            containerClassNames={styles.containerClassNames}
            labelClassNames={styles.labelClassNames}
            error={error}
            viewType={`password`}
            disableCopyPaste
          />

          <FormElement
            type="text"
            label={`Confirm RFID Card Number`}
            placeholderText={strings.pleaseEnterRFID}
            value={RFID}
            maxLength={30}
            onChange={value => setRFID(value)}
            required
            // disabled={person.status !== STATUSES.DEPARTMENT_ADMIN_APPROVED}
            containerClassNames={styles.containerClassNames}
            labelClassNames={styles.labelClassNames}
            error={error}
            disableCopyPaste
          />
        </div>
      </Flex>
      <Flex className={styles.buttonsContainer}>
        <button className={styles.surrender} onClick={handleCancel}>
          {`Cancel`}
        </button>
        <button className={styles.approve} onClick={handleSubmit}>
          {`Submit`}
        </button>
      </Flex>
    </div>
  );
};

export default ChangeRFID;
