import React, {useState} from 'react';
import styles from './Desktop.module.scss';
import AuthLayout from '../../../../../components/AuthLayout';
import {keys, INPUT_CONSTANTS, formFields} from '../../formfeilds';
import TextInput from '../../../../../components/TextInput';
import SeparatorComponent from '../../../../../components/Sparator';
import {withRouter} from 'react-router-dom';
import strings from '../../../../../globalization';
import CustomInput from '../../../../../components/CustomInput';
import eyeIcon from '../../../../../assets/images/eye/image@3x.png';
import eyeIconClosed from '../../../../../assets/images/eye-hide/image@3x.png';

const Desktop = props => {
  const {onClick, onChangeState} = props;
  const [visible, setVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);

  const handleClick = action => {
    console.info('handleClick...', action, props);
    onClick(action);
  };
  const onChange = (action, data) => {
    // console.info('onChange...', action);
    onChangeState(action, data);
  };

  const onRightIconClick = element => {
    console.log(props);
    if (element.field === 'newPassword') {
      setVisible(!visible);
    } else if (element.field === 'confirmPassword') {
      setConfirmVisible(!confirmVisible);
    }
  };

  const getInputType = element => {
    switch (element.type) {
      case INPUT_CONSTANTS.PASSWORD:
      case INPUT_CONSTANTS.NUMBER:
      case INPUT_CONSTANTS.TEXT: {
        return (
          <TextInput
            label={element.label}
            lblDirection="row"
            onChange={update => onChange(element.field, update)}
            isEdit={true}
            type={element.type}
            placeholder={element.placeholder}
            //inputStyle={styles.inputTextAddress}
            required={element.required}
            name={element.field}
            error={props[element.field + '-error']?.error || ''}
            value={props[element.field]}
            type={
              element.field === 'newPassword'
                ? visible
                  ? 'text'
                  : 'password'
                : confirmVisible
                ? 'text'
                : 'password'
            }
            rightIcon={
              element.field === 'newPassword'
                ? visible
                  ? eyeIcon
                  : eyeIconClosed
                : confirmVisible
                ? eyeIcon
                : eyeIconClosed
            }
            onRightIconClick={() => onRightIconClick(element)}
          />
        );
      }
      default: {
        console.warn('case not handled', element.type);
        return null;
      }
    }
  };
  return (
    // <AuthLayout>
    <div className={styles.loginContainer}>
      <div className={styles.logoContainer}>
        <img src="./static/images/Logo/logo.png" alt="Logo" />
        <h2>{keys.title}</h2>
        <h4>{keys.subTitle}</h4>
      </div>
      <div className={styles.formContainer}>
        <div className={styles.h2}>{strings.resetPassword}</div>
        {formFields.map(field =>
          getInputType(field, onRightIconClick, visible, confirmVisible),
        )}
      </div>
      <button
        onClick={() => handleClick('reset')}
        className={styles.loginButton}>
        {strings.confirm}
      </button>
    </div>
    // </AuthLayout>
  );
};
export default withRouter(Desktop);
