import React, {useState, useEffect} from 'react';
import styles from './styles.module.scss';
import './styles.css';
import strings from '../../../../../../globalization';
import {withRouter} from 'react-router-dom';
import {AgGridReact} from 'ag-grid-react';
import Select from 'react-select';
import moment from 'moment';
import PermitsHeader from '../../../../../../components/PermitsHeader';

import 'react-daterange-picker/dist/css/react-calendar.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import dimensions from '../../../../../../util/dimensions-util';
// import {customStyles} from './customStyles';
import {adminImages, agencyImages} from '../../../../../../assets/images';
import DateRangePicker from 'react-daterange-picker';
// import { rowData } from './data';
import TextInput from '../../../../../../components/TextInput';
import Button from '../../../../../../components/Button';

import calendarBlue from '../../../../../../assets/images/calendar-blue-icon/image.png';
import {dummyData} from './dummyData';
import SannaLogo from '../../../../../../components/SannaLogo';

const {vw: widthConstant, vh: heightConstant} = dimensions;

const Desktop = props => {
  const {
    onClick,
    onChange,
    selectedStatus,
    agencies,
    filterAgencies,
    searchText,
    dates,
    roleId,
  } = props;

  const tabs = [
    {
      label: 'Users',
      id: 1,
      route: 'usage-management',
    },
  ];

  const columnDefs = [
    {
      headerName: 'Name',
      field: 'name',
      headerClass: styles.rowHeader,
      width: 250 * widthConstant,
      resizable: true,
      autoHeight: true,
      sortable: false,
      wrapText: true,
      cellStyle: {lineHeight: 1.5},
      cellRendererFramework: ({data: {firstName}}) => {
        return <div className={styles.sessionTime}>{firstName}</div>;
      },
    },
    {
      headerName: 'Mobile Number',
      field: 'mobileNumber',
      headerClass: styles.rowHeader,
      width: 210 * widthConstant,
      resizable: true,
      sortable: false,
      autoHeight: true,

      cellRendererFramework: ({data: {phoneNumber}}) => {
        return <div className={styles.sessionTime}>{phoneNumber}</div>;
      },
    },
    {
      headerName: 'Email Address',
      field: 'emailAddress',
      headerClass: styles.rowHeader,
      width: 350 * widthConstant,
      resizable: true,
      wrapText: true,
      autoHeight: true,

      cellStyle: {display: 'flex', alignItems: 'center', lineHeight: '1.5'},
      cellRendererFramework: ({data: {emailAddress}}) => {
        return <div className={styles.sessionTime}>{emailAddress}</div>;
      },
    },
    {
      headerName: 'Designation',
      field: 'designation',
      headerClass: styles.rowHeader,
      width: 250 * widthConstant,
      resizable: true,
      flex: 1,
      autoHeight: true,
      wrapText: true,
      cellRendererFramework: ({data: {designation}}) => {
        return <div className={styles.sessionTime}>{designation}</div>;
      },
    },
    {
      headerName: 'Role',
      field: 'role',
      headerClass: styles.rowHeader,
      width: 250 * widthConstant,
      resizable: true,
      sortable: false,
      cellRendererFramework: ({data: {roles}}) => {
        return (
          <div className={styles.sessionTime}>
            {roles === 'COMPANY_ADMIN'
              ? 'Company Admin'
              : roles === 'SIGNATORY'
              ? 'Company Viewer'
              : roles}
          </div>
        );
      },
    },
    {
      headerName: 'Action',
      field: 'action',
      headerClass: styles.rowHeader,
      width: 150 * widthConstant,
      resizable: true,
      sortable: false,
      cellStyle: {display: 'flex', alignItems: 'center'},
      cellRendererFramework: ({data: {id, status}}) => {
        return (
          <div>
            {status === 'PENDING' && roleId === 8 ? (
              <div className={styles.actionContainer}>
                <button
                  className={styles.addRoleBtn}
                  onClick={() => onClick('editUser', id)}>
                  + Add role
                </button>
              </div>
            ) : roleId === 8 ? (
              <div className={styles.actionContainer}>
                <img
                  onClick={() => onClick('editUser', id)}
                  className={styles.editIcon}
                  src={agencyImages.editIcon}
                  alt="edit"
                />
                <img
                  onClick={() => onClick('deleteUser', id)}
                  className={styles.editIcon}
                  src={agencyImages.deleteIcon}
                  alt="delete"
                />
              </div>
            ) : (
              <div>--</div>
            )}
          </div>
        );
      },
    },
  ];
  const [showDate, setShowDate] = useState(false);
  const [gridApi, setGridApi] = useState(null);

  useEffect(() => {
    console.log(gridApi);
    if (gridApi) {
      // gridApi.setRowHeight(50);
    }
    if (gridApi && filterAgencies.length > 10) {
      console.log('changing to normal');
      gridApi.setDomLayout('normal');
    } else if (gridApi) {
      console.log('changing to autoHeight');
      gridApi.setDomLayout('autoHeight');
    }
  }, [gridApi, filterAgencies]);

  const onGridReady = params => {
    setGridApi(params.api);
  };

  const onCellClick = (event, index) => {
    onClick('rowClick', event.data);
  };
  const onSelect = dates => {
    onChange('date', {
      startDate: moment(dates.start).format('MM-DD-YYYY'),
      endDate: moment(dates.end).format('MM-DD-YYYY'),
      dates,
    });
    toggleDate();
  };
  const toggleDate = () => {
    setShowDate(!showDate);
  };

  const onTabClick = id => {
    switch (id) {
      case 1:
        onClick('statusRoute', {route: 'users-list', id: id});
        break;

      default:
        break;
    }
  };

  const formatDate = date => moment(date).format('MMM Do YY');
  const rowStyle = {border: 'none', cursor: 'pointer'};
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return {background: '#f6f6f6', border: 'none'};
    }
  };
  const handleClear = () => {
    setShowDate(false);
    onClick('clearFilters');
  };
  return (
    <div>
      <PermitsHeader
        title="Users Management"
        onTabClick={onTabClick}
        tabs={tabs}
        // availableCredits={availableCredits}
        activeTab={1}
      />
      <div className={styles.bodyContainer}>
        <div className={styles.header}>
          <div className={styles.left}>
            <div className={styles.dateWrapper}>
              <a
                href="https://edrishti.sanya.ai/user-manual/User-Management.pdf"
                target="__blank"
                className={styles.learnMore}>
                Learn more about how user management works
              </a>

              {showDate ? (
                <div className={styles.datePicker}>
                  <DateRangePicker
                    numberOfCalendars={1}
                    selectionType="range"
                    onSelect={onSelect}
                    className={styles.dateRange}
                    value={dates}
                    singleDateRange={true}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.inputSearch}>
              <TextInput
                onChange={text => {
                  onChange('search', text);
                }}
                isEdit={true}
                type={'text'}
                value={searchText}
                inputStyle={styles.inputText}
                showSeacrh={true}
                placeholder={`Search`}
              />
            </div>
            <div onClick={handleClear} className={styles.clearLabel}>
              {' '}
              {strings.ADMIN.clear}
            </div>
            {roleId === 8 ? (
              <Button
                onClick={() => onClick('addNewUser')}
                buttonStyle="primary"
                label={'Add New User'}
                className={styles.applyBtn}></Button>
            ) : null}
          </div>
        </div>
        <div
          className={[
            'ag-theme-balham',
            styles.tableWrapper,
            filterAgencies?.length > 10
              ? styles.tableWrapperNormal
              : styles.tableWrapperAutoHeight,
          ].join(' ')}>
          {' '}
          <AgGridReact
            onGridReady={onGridReady}
            rowStyle={rowStyle}
            getRowStyle={getRowStyle}
            rowHoverHighlight={true}
            columnDefs={columnDefs}
            rowHeight={50 * heightConstant}
            headerHeight={50 * heightConstant}
            defaultColDef={{sortable: true}}
            rowClass={[styles.gridRowClass]}
            pagination={true}
            paginationPageSize={100}
            onCellClicked={(event, index) => onCellClick(event, index)}
            rowData={filterAgencies}
            domLayout="normal"></AgGridReact>
        </div>
        <div className={styles.bottomLabel}>
          {' '}
          Showing {filterAgencies.length}
        </div>
      </div>
      <div className={styles.SannaLogo}>
        <SannaLogo />
      </div>
    </div>
  );
};

export default Desktop;
