import React from 'react';
import CustomSelect from '../Select/SelectComponentStyle';
import TextInput from '../TextInput';
import styles from './styles.module.scss';

const INPUT_CONSTANTS = {
  TEXT: 'text',
  DROPDOWN: 'dropdown',
  DATE: 'date',
  FILE: 'file',
};

/*
  
   elementType: 'input',
        keyboardType: 'default',
        inputType: 'alphabets',
        label: "Person Name",
        placeholder: "Enter name",
        required: true,
        stateField: 'individualName',
        stateDefault: null,
        minLength: 5,
        maxLength: 128,
  
  */

function FormElement({field: element, meta: stateValues, onChange}) {
  switch (element.elementType) {
    case INPUT_CONSTANTS.TEXT: {
      return (
        <TextInput
          label={element.label}
          lblDirection="row"
          onChange={update => {
            console.info('onchange', onChange);
            onChange(element.stateField, update);
          }}
          isEdit={true}
          type={element.elementType}
          placeholder={element.placeholder}
          //inputStyle={styles.inputTextAddress}
          required={element.required}
          name={element.stateField}
          error={stateValues[element.stateField]?.error}
          value={stateValues[element.stateField]?.value}
        />
      );
    }
    case INPUT_CONSTANTS.DATE: {
      return (
        <TextInput
          label={element.label}
          lblDirection="row"
          required={element.required}
          onChange={update => {
            onChange(element.stateField, new Date(update));
          }}
          isEdit={true}
          type={element.elementType}
          placeholder={element.placeholder}
          inputStyle={styles.inputTextAddress}
          name={element.stateField}
          error={stateValues[element.stateField]?.error}
          value={stateValues[element.stateField]?.value}
        />
      );
    }
    case INPUT_CONSTANTS.FILE: {
      return (
        <TextInput
          label={element.label}
          lblDirection="row"
          onChange={update => {
            this.handleUpload(element.field, update);
          }}
          isEdit={true}
          type={element.elementType}
          placeholder={element.placeholder}
          inputStyle={styles.inputTextAddress}
          name={element.stateField}
          error={stateValues[element.stateField]?.error}
          value={stateValues[element.stateField]?.value}
        />
      );
    }
    case INPUT_CONSTANTS.DROPDOWN: {
      return (
        <div className={styles.dropdown}>
          <span className={styles.selectLabelClass}>{element.label}</span>
          <CustomSelect
            options={stateValues[element.field + '-options']}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            placeholder={element.placeholder}
            required={element.required}
            name={element.field}
            defaultValue={stateValues[element.field]}
            onChange={({id}) => {
              onChange(element.field, id);
            }}
          />
          <span style={{color: 'red'}}>
            {stateValues[element.field + '-error']?.error || null}
          </span>
        </div>
      );
    }
    default: {
      console.warn('case not handled', element.type);
      return null;
    }
  }
}

export default FormElement;
