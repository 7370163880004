import React, {useState} from 'react';
import styles from './Popup.module.scss';
import creditCoins from '../../assets/images/credits/image.png';

// import {
//   close,
//   success as successImg,
//   error as errorImg,
// } from "../../assets/images";

import {adminImages} from '../../assets/images';
import strings from '../../globalization';
import moment from 'moment';

type Button = {
  text: String,
  onClick: () => void,
  className?: String,
};

type Props = {
  message: Object,
  onClose?: () => void,
  error?: Boolean,
  success?: Boolean,
  buttons?: Button[],
  heading?: String,
};

/**
 *
 * @param {Boolean} error
 * @param {Boolean} success
 * @return {JSX.Element}
 */

/**
 *
 * @param {Props} props
 * @return {JSX.Element}
 */
function AddCreditsPopup(props: Props) {
  const {
    error,
    success,
    onClose,
    onClick,
    buttons,
    heading,
    availableCredits,
    buttonContainer,
    message,
    data,
  } = props;
  // const {referenceId, status} = data;
  const [credits, setCredits] = useState(0);
  const [amount, setAmount] = useState(0);
  const [gst, setGst] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [showError, setShowError] = useState(false);

  let containerClass = '';
  if (error) {
    containerClass = styles.error;
  } else if (success) {
    containerClass = styles.success;
  }

  const proceedPay = () => {
    if (credits < 1) {
      console.log('please add credits');
      setShowError(true);
    } else {
      console.log('else block...');
      onClick(credits, amount, gst, totalAmount);
    }
  };
  const updateInputValue = evt => {
    console.log(evt.target.value);
    if (!isNaN(evt.target.value)) {
      const value = Number(evt.target.value);
      setCredits(value);
      setAmount(value);
      //      setGst((value * 18) / 100);
      setTotalAmount(value);
      console.log(totalAmount, amount, gst);
      setShowError(false);
    } else {
      setShowError(true);
    }
  };

  const getAddCreditsPopup = () => {
    //    const {amount, gst, totalAmount} = this.state;
    return (
      <div className={styles.requestInfo}>
        <div className={styles.creditsContainer}>
          <div className={styles.flexBox}>
            <img
              src={creditCoins}
              className={[styles.image, styles.bellIcon].join(' ')}
            />
            <div className={styles.creditsLabel}>
              <span className={styles.label}>Available Credits:</span>{' '}
              <span className={styles.credits}>{availableCredits}</span>
            </div>
          </div>
          <div className={styles.addCreditsBlock}>
            <div className={styles.headingLabel}>
              Add Credits(<i>1 credit = 1.00 INR</i>)
            </div>

            <div className={styles.inputContainer}>
              <span className={styles.label}>Enter credits to be Added*</span>
              <input
                onChange={evt => updateInputValue(evt)}
                type="text"
                placeholder="Enter credits"
                onkeyup="this.value=this.value.replace(/[^\d]/,'')"
              />
              {showError ? (
                <span className={styles.error}>
                  Please add credits(Ex: 100,200,...)
                </span>
              ) : null}
            </div>
            <div className={styles.inputContainer}>
              <div className={styles.amountContainer}>
                <div className={styles.dottedLine}></div>
                <div className={styles.amountBlock}>
                  <span>Amount:</span>
                  <span>{amount}</span>
                </div>
                {/* <div className={styles.amountBlock}>
                  <span>GST(18%):</span>
                  <span>{gst}</span>
                </div> */}
                <div className={styles.dottedLine}></div>

                <div className={styles.amountBlock}>
                  <span>Total:</span>
                  <span>{totalAmount}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={[styles.container, containerClass].join(' ')}>
      <div className={styles.secondContainer}>
        {onClose ? (
          //  <span className={styles.closeIcon}>X </span>
          <img
            alt="close"
            src={adminImages.closeIcon}
            className={styles.closeIcon}
            onClick={onClose}
          />
        ) : null}

        <div className={styles.heading}> {heading}</div>
        <div className={styles.bodyContainer}>
          <div className={styles.infoContainer}>{getAddCreditsPopup()}</div>
        </div>

        {buttons && buttons.length > 0 ? (
          <div className={[styles.buttonContainer, buttonContainer].join(' ')}>
            {buttons.map(button => {
              const {text, buttonStyles, ...remainingProps} = button;
              return (
                <button
                  className={[styles.button, buttonStyles].join(' ')}
                  key={'popup-button-' + text}
                  onClick={e => proceedPay()}
                  {...remainingProps}>
                  {text}
                </button>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default AddCreditsPopup;
