import React from 'react';
import AuthLayout from '../../../../../components/AuthLayout';
import RegistrationFormCard from '../../../../../components/RegistrationFormCard';
import styles from './Desktop.module.scss';
import FormInput from '../../../../../components/FormInput';
import CustomInput from '../../../../../components/CustomInput';
import FormElement from '../../../../../components/FormElement';
import {loginImages} from '../../../../../assets/images';
import AcceptTerms from '../../../../../components/AcceptTermsBox';
import Button from '../../../../../components/Button';
import Flex from '../../../../../components/Flex';
import strings from '../../../../../globalization';
import {Metadata} from '../../../../../components/Metadata';
import PAGE_TITLES from '../../../../../constants/page-titles';

function Desktop({
  formLeftFields,
  formRightFields,
  onClick,
  onChange,
  onBack,
  onChangeHandler,
  handleUpload,
  onBlur,
  backClick,
  stateData,
  onSubmit,
  checked,
  toggleChecked,
  triggerOTP,
  otpVerified,
  otpVerifiedField,
  ...stateValues
}) {
  console.log('formLeftFields', formLeftFields, stateData);
  const disableFieldsForIssuedCards = [
    'name',
    'vehicleRC',
    'vehicleCategory',
    'vehicleEngineNumber',
    'vehicleManufacturingYear',
    'vehicleRegistrationNumber',
    'vehicleRCValidity',
    'vehicleType',
    'vehicleChassisNumber',
    'vehicleMakeAndModel',
    'departmentId',
  ];
  return (
    <div className={styles.formContainer}>
      <Flex direction="column">
        <Flex onClick={backClick} className={styles.backlabel}>
          <img
            className={styles.backButtonIcon}
            src={loginImages.backButtonIcon}
            alt="back"
          />
          <span>{strings.back}</span>
        </Flex>
        <header>
          <h1 className={styles.header}>Vehicle Registration</h1>
        </header>
      </Flex>
      <div className={[styles.gridOfTwo, styles.divider].join(' ')}>
        <div>
          {formLeftFields.map((elem, idx) => {
            return (
              <div
                key={`formLeftFields-${idx}`}
                className={(styles.divider, styles.formFields)}>
                <FormElement
                  type={elem.elementType}
                  label={elem.label}
                  required={elem.required}
                  placeholderText={elem.placeholder}
                  onChange={text => {
                    onChangeHandler(elem.stateField, text);
                  }}
                  tabIndex={idx * 2 + 1}
                  error={stateData[elem.stateField].error}
                  options={stateData[elem.optionsField]}
                  value={stateData[elem.stateField].value}
                  key={idx}
                  disabled={
                    (stateData?.vehicleDetails?.status === 'ISSUED' ||
                      stateData?.vehicleDetails?.status === 'UPDATED' ||
                      stateData?.vehicleDetails?.status ===
                        'UPDATE_REJECTED') &&
                    disableFieldsForIssuedCards.includes(elem.stateField)
                      ? true
                      : false
                  }
                  maxLength={elem.maxLength}
                  keyboardType={elem.keyboardType}
                  // containerStyle={{marginBotton: 40 * vh}}
                  handleUpload={(file, error, inputRef) => {
                    handleUpload(elem.stateField, file, error, inputRef);
                  }}
                  maxFiles={elem.maxFiles}
                  onBlur={() => onBlur(elem.stateField, elem)}
                  allowedFormats={elem.allowedFormats}
                  triggerOTP={() => onClick('otp-verify', elem)}
                  otpVerified={otpVerified}
                  otpVerifiedField={otpVerifiedField}
                  isVerifyOtp={elem.verifyOtp}
                  fieldName={elem.stateField}
                  minimumDate={elem.minimumDate}
                  uppercase={elem.uppercase}
                />
              </div>
            );
          })}
        </div>

        <div>
          {formRightFields.map((elem, idx) => (
            <div
              key={`formRightFields-${idx}`}
              className={(styles.divider, styles.formFields)}>
              <FormElement
                type={elem.elementType}
                label={elem.label}
                required={elem.required}
                placeholderText={elem.placeholder}
                onChange={text => {
                  onChangeHandler(elem.stateField, text);
                }}
                disabled={
                  (stateData?.vehicleDetails?.status === 'ISSUED' ||
                    stateData?.vehicleDetails?.status === 'UPDATED' ||
                    stateData?.vehicleDetails?.status === 'UPDATE_REJECTED') &&
                  disableFieldsForIssuedCards.includes(elem.stateField)
                    ? true
                    : false
                }
                tabIndex={idx * 2 + 2}
                error={stateData[elem.stateField].error}
                options={stateData[elem.optionsField]}
                value={stateData[elem.stateField].value}
                key={idx}
                maxLength={elem.maxLength}
                keyboardType={elem.keyboardType}
                // containerStyle={{marginBotton: 40 * vh}}
                handleUpload={(file, error, inputRef) => {
                  handleUpload(elem.stateField, file, error, inputRef);
                }}
                maxFiles={elem.maxFiles}
                onBlur={() => onBlur(elem.stateField, elem)}
                allowedFormats={elem.allowedFormats}
                triggerOTP={() => onClick('otp-verify', elem)}
                otpVerified={otpVerified}
                otpVerifiedField={otpVerifiedField}
                isVerifyOtp={elem.verifyOtp}
                fieldName={elem.stateField}
                minimumDate={elem.minimumDate}
                noOptionsText={elem.noOptionsText}
                uppercase={elem.uppercase}
              />
            </div>
          ))}
        </div>
      </div>
      <div className={styles.divider}>
        {console.log('CHECKED', checked)}
        <AcceptTerms
          toggleChecked={toggleChecked}
          checked={checked}
          onClick={() => onClick('terms')}
        />
      </div>
      <Flex style={{justifyContent: 'center'}}>
        {stateData?.status === 'EDIT' ? (
          <Button
            buttonStyle="primary"
            label={
              stateData?.vehicleDetails?.status === 'PAYMENT_PENDING' ||
              stateData?.vehicleDetails?.status === 'PAYMENT_FAILED'
                ? 'Proceed To Payment'
                : 'Update'
            }
            className={styles.submitButton}
            onClick={onSubmit}
            disabled={!checked}
          />
        ) : (
          <Button
            buttonStyle="primary"
            label={'Proceed To Payment'}
            className={styles.submitButton}
            onClick={onSubmit}
            disabled={!checked}
          />
        )}
        {/* <Button
          buttonStyle="primary"
          label="Proceed to Payment"
          className={styles.submitButton}
          onClick={onSubmit}
          disabled={!checked}
        /> */}
      </Flex>
    </div>
  );
}

export default Desktop;
