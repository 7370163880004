import React from 'react';
import styles from './styles.module.scss';
import strings from '../../globalization';
function SeparatorComponent() {
  return (
    <div className={styles.divider}>
      <div /> <span>{strings.or}</span>
      <div />
    </div>
  );
}

export default SeparatorComponent;
