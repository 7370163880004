import React, {useEffect, useState} from 'react';
import styles from './Person.module.scss';
import moment from 'moment';
import {adminImages, loginImages} from '../../../src/assets/images';
import documentIcon from '../../../src/assets/images/document-icon/image.png';
import strings from '../../globalization';
import {API} from '../../requests';

/**
 *
 * @param {Boolean} error
 * @param {Boolean} success
 * @return {JSX.Element}
 */

/**
 *
 * @param {Props} props
 * @return {JSX.Element}
 */
function AgencyPersonDetails(props) {
  console.log(props);
  const {
    name,
    emailAddress,
    phoneNumber,
    gender,
    dob,
    nationality,
    documentType,
    aadhaarDocument,
    aadhaarNumber,
    country,
    rfidnumber,
    aadhaarUrl,
    onClick,
    photo,
    personType
  } = props || {};
  const formatDate = date => moment(date).format('MMM Do YYYY');
  const [url, setUrl] = useState(photo);
  useEffect(() => {
    getDocumentTempURL(photo);
  }, []);
  const getDocumentTempURL = async url => {
    const data = {
      url: url,
      expiryTime: 30,
    };
    await API.preSignedURL(data)
      .then(res => {
        console.log('res.data', res.data?.preSignedUrl);
        setUrl(res.data?.preSignedUrl);
      })
      .catch(error => {
        console.log(error);
      });
  };
  return (
    <div className={styles.cardWrapper}>
      <div>
        {photo ? (
          <img src={url} className={styles.cardImage} alt="photo" />
        ) : (
          '-'
        )}
      </div>
      <div className={styles.info}>
        <div>
          <div className={styles.requestKey}>
            {strings.ADMIN.POP_UP.name} :
            <span className={styles.requestLabel}> {name} </span>
          </div>
          <div className={styles.requestKey}>
            {strings.ADMIN.POP_UP.email} :{emailAddress}
            <span className={styles.requestLabel}></span>
          </div>
          <div className={styles.requestKey}>
            {strings.ADMIN.POP_UP.phoneNo} :
            <span className={styles.requestLabel}> {phoneNumber} </span>
          </div>
        </div>
        <div>
          <div className={styles.requestKey}>
            {strings.ADMIN.POP_UP.gender} :{' '}
            <span className={styles.requestLabel}>
              {gender ? gender.name : null}
            </span>
          </div>
          <div className={styles.requestKey}>
            {strings.ADMIN.POP_UP.dateOfBirth}:
            <span className={styles.requestLabel}>
              {dob ? formatDate(dob) : null}{' '}
            </span>
          </div>
          <div className={styles.requestKey}>
            <span className={styles.requestLabel}>
              {'-'}
              {country ? country.name : null}{' '}
            </span>
          </div>
        </div>
        <div>
          <div className={styles.requestKey}>{'-'}</div>
          <div className={styles.requestKey}>
            {strings.ADMIN.POP_UP.personType} :
            <span className={styles.requestLabel}>{personType?.name ? personType?.name : ' others'}</span>
          </div>
          <div className={styles.requestKey}>
            {strings.ADMIN.POP_UP.documnetType} :
            <span className={styles.requestLabel}> {documentType} </span>
          </div>
        </div>
        <div>
          <div className={styles.requestKey}>
            {strings.ADMIN.POP_UP.documentProofNumber}:
            <span className={styles.requestLabel}> {aadhaarNumber} </span>
          </div>
          <div className={styles.requestKey}>
            {strings.ADMIN.POP_UP.rFIDcardNumber}:
            <span className={styles.requestLabel}>{rfidnumber} </span>
          </div>
          <div className={styles.requestKey}>
            {strings.ADMIN.POP_UP.document} :
            <span className={styles.requestLabel}>
              {' '}
              {'document'}
              {aadhaarDocument ? (
                <a onClick={() => onClick('document-view', aadhaarDocument)}>
                  <img className={styles.documentIcon} src={documentIcon} />
                </a>
              ) : null}{' '}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgencyPersonDetails;
