import React, {useState} from 'react';
import Flex from '../Flex';

import styles from './styles.module.scss';
const AddValidityPeriod = ({days: propsDays, onClick}) => {
  const [days, setDays] = useState(propsDays);

  const handleClick = () => {
    console.log('clicked-->');
    onClick && onClick(days);
  };
  const textChangeHandler = text => {
    console.log('textChangehandler...', text);
    let regex = /^[0-9]*$/;

    console.log('regex text...', regex.test(text));

    if (regex.test(text) && +text >= 0 && +text <= 365) {
      if (!isNaN(text.substr(-1))) {
        text = text.replace(/^\s+/g, '');
        let trimmed = text.trim();
        setDays(trimmed);
      }
    }
  };
  return (
    <Flex className={styles.container}>
      <div className={styles.inputBlock}>
        <p className={styles.heading}>{'Enter Days'}</p>
        <input
          placeholder={'Enter Number of Days'}
          value={days}
          onChange={e => textChangeHandler(e.target.value)}
          className={styles.input}
          type="number"
        />
        <button
          onClick={handleClick}
          className={styles.addButtonStyles}
          disabled={!days}>
          {'Okay'}
        </button>
      </div>
    </Flex>
  );
};

export default AddValidityPeriod;
