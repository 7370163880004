import React, {memo} from 'react';

const TableTimestamp = props => {
  const {timestamp, children} = props;
  console.log('timestamp...', timestamp);
  return <>{children}</>;
};

function areEqual(prevProps, nextProps) {
  return prevProps.timestamp === nextProps.timestamp;
}

export default memo(TableTimestamp, areEqual);
