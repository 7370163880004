import React, {useEffect, useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import Flex from '../Flex';
import styles from './styles.module.scss';
import {useHistory, useLocation} from 'react-router-dom';

import closeIcon from '../../assets/images/close-icon/image.png';
import rotateIcon from '../../assets/images/rotate/image.png';

import zoominIcon from '../../assets/images/zoomin/image.png';
import zoomoutIcon from '../../assets/images/zoomout/image.png';
import {API} from '../../requests';

const ViewDocument = () => {
  const history = useHistory();
  const location = useLocation();

  const [numPages, setNumPages] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [url, setUrl] = useState(location.state.url);

  const [rotate, setRotate] = useState(0);
  const [zoomValue, setZoomValue] = useState(1);

  console.log(location.state.url);
  useEffect(() => {
    getDocumentTempURL(url);
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  const TYPES = {
    JPEG: 'jpeg',
    JPG: 'jpg',
    PNG: 'png',
    PDF: 'pdf',
  };

  const onDocumentLoadSuccess = ({numPages}) => {
    setNumPages(numPages);
    setLoaded(true);
    console.log(numPages);
  };
  function get_url_extension(url) {
    return url && url.split(/[#?]/)[0].split('.').pop().trim();
  }
  const onClose = () => {
    history.goBack();
  };

  const getDocumentTempURL = async url => {
    const data = {
      url: url,
      expiryTime: 30,
    };
    await API.preSignedURL(data)
      .then(res => {
        console.log('res.data', res.data?.preSignedUrl);
        setUrl(res.data?.preSignedUrl);
      })
      .catch(error => {
        console.log(error);
      });
  };
  const renderDocument = () => {
    let extension = get_url_extension(url);
    console.log('URL ----', extension);
    extension = extension.toLowerCase();
    console.log('EXTENSION lowercase----', extension);

    switch (extension) {
      case TYPES.PDF: {
        return (
          <>
            <Document
              // options={{workerSrc: 'pdf.worker.js'}}
              className={styles.pdfClassName}
              file={url}
              onLoadError={console.error}
              onLoadSuccess={onDocumentLoadSuccess}
              onRenderSuccess={onDocumentLoadSuccess}>
              {Array.apply(null, Array(numPages))
                .map((x, i) => i + 1)
                .map(page => (
                  <Page pageNumber={page} />
                ))}
            </Document>
            <p>
              Page {pageNumber} of {numPages}
            </p>
          </>
        );
      }

      case TYPES.JPEG: {
        return (
          <div className={styles.documentViewContainer}>
            <img className={styles.imageViewer} src={url} alt="U" />
          </div>
        );
      }
      case TYPES.JPG: {
        return (
          <div className={styles.documentViewContainer}>
            <img className={styles.imageViewer} src={url} alt="U" />
          </div>
        );
      }
      case TYPES.PNG: {
        return (
          <div className={styles.documentViewContainer}>
            <img className={styles.imageViewer} src={url} alt="U" />
          </div>
        );
      }
      default: {
        return (
          <div className={styles.documentViewContainer}>
            <img className={styles.imageViewer} src={url} alt="U" />
          </div>
        );
      }
    }
  };

  const rotateView = () => {
    console.log('rotate called');
    setRotate(rotate + (90 % 360));
  };

  const handleZoom = action => {
    switch (action) {
      case 'zoomin': {
        if (zoomValue < 3) {
          setZoomValue(zoomValue + 0.5);
        }
        break;
      }

      case 'zoomout': {
        if (zoomValue > 1) {
          setZoomValue(zoomValue - 0.5);
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  console.log('url', url);
  return (
    <Flex direction="column" className={styles.container}>
      <div
        style={{
          position: 'relative',
        }}>
        <button onClick={onClose} className={styles.button}>
          <img src={closeIcon} className={styles.crossIcon} alt="U" />
        </button>

        <div className={styles.viewContainer}>
          <div
            style={{
              transform: `rotate(${rotate}deg) scale(${zoomValue})`,
              // transform: `scale(${zoomValue})`,
              transformOrigin: rotate % 360 === 0 ? `top left` : `center`,
            }}>
            {renderDocument()}
          </div>
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <button
          onClick={() => handleZoom('zoomin')}
          className={styles.actionButton}>
          <img src={zoominIcon} className={styles.rotateIcon} alt="U" />
        </button>
        <button onClick={rotateView} className={styles.actionButton}>
          <img src={rotateIcon} className={styles.rotateIcon} alt="U" />
        </button>
        <button
          onClick={() => handleZoom('zoomout')}
          className={styles.actionButton}>
          <img src={zoomoutIcon} className={styles.rotateIcon} alt="U" />
        </button>
      </div>
    </Flex>
  );
};

export default ViewDocument;
