import React from 'react';
import strings from '../../globalization';
import Flex from '../Flex';
import styles from './styles.module.scss';

function AcceptTerms({checked, toggleChecked, onClick}) {
  return (
    <Flex className={styles.container}>
      <input
        type="checkbox"
        checked={checked}
        className={styles.checkbox}
        onChange={e => toggleChecked()}
      />
      <p>
        {strings.acceptAll}
        <span className={styles.terms} onClick={() => onClick()}>
          {' '}
          {strings.termsAndConditions}
        </span>
      </p>
    </Flex>
  );
}

export default AcceptTerms;
