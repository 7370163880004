import React, { useState } from 'react';
import styles from './Desktop.module.scss';
import { withRouter } from 'react-router-dom';

import 'react-daterange-picker/dist/css/react-calendar.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import dimensions from '../../.../../../../../util/dimensions-util';
import Flex from '../../../../../components/Flex';

import bellIcon from '../../../../../assets/images/bell-icon/image.png';
import bgIcon from '../../../../../assets/images/background/image.png';
import TextInput from '../../../../../components/TextInput';
import Button from '../../../../../components/Button';
import DateRangePicker from 'react-daterange-picker';

import calendarBlue from '../../../../../assets/images/calendar-blue-icon/image.png';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import Select from 'react-select';
import { customStyles } from './customStyles';
import FormElement from '../../../../../components/FormElement';
import PersonCardsPopup from '../../../../../components/operator/PersonCardsPopup';
import PersonCardsEditPopup from '../../../../../components/operator/PersonCardsEditPopup';

import SuccessPage from '../../../../../components/operator/SuccessPage';
import { operatorImages } from '../../../../../assets/images';
import strings from '../../../../../globalization';
import SannaLogo from '../../../../../components/SannaLogo';

const { vw: widthConstant, vh: heightConstant } = dimensions;

const Desktop = props => {
  const {
    onClick,
    onChange,
    searchText,
    filterOptions,
    filter,
    viewDocument,
    successData,
    pageStatus,
    formMetadata,
  } = props;

  const checkDisabled = () => {
    console.log('Check disabled called!');
    console.log('searchText, filter', searchText, filter);
    if (!searchText || !filter) {
      // setDisabled(true);
      return true;
    } else {
      // setDisabled(false);
      return false;
    }
  };

  const getPageInfo = pageStatus => {
    switch (pageStatus) {
      case 'landing': {
        return (
          <Flex className={styles.defaultContainer}>
            <img
              src={operatorImages.noDataIcon}
              className={styles.noDataimage}
              alt="AVATAR"
            />
            <p>
              {' '}
              {`Search with Reference Number or Aadhaar Number to get details`}
            </p>
          </Flex>
        );
      }
      case 'showPersonDetails': {
        return (
          <div className={styles.tableWrapper}>
            <PersonCardsPopup
              viewDocument={viewDocument}
              {...formMetadata}
              key={formMetadata?.person.id}
            />
          </div>
        );
      }

      case 'showEditPersonDetails': {
        return (
          <div className={styles.tableWrapper}>
            <PersonCardsEditPopup
              viewDocument={viewDocument}
              {...formMetadata}
              key={formMetadata?.person.id}
            />
          </div>
        );
      }

      case 'success': {
        return <SuccessPage {...successData} />;
      }
      default: {
        console.warn('Case not handled', pageStatus);
        break;
      }
    }
  };

  return (
    <div className={styles.personCardsContainer}>
      <Header />
      {pageStatus !== 'success' ? (
        <FiltersRow
          searchText={searchText}
          onChange={onChange}
          filterOptions={filterOptions}
          filter={filter}
          onClick={onClick}
          disabled={checkDisabled()}
        />
      ) : null}
      {getPageInfo(pageStatus)}
      <SannaLogo />
    </div>
  );
};
export default withRouter(Desktop);

const Header = () => {
  return (
    <Flex className={styles.headerContainer}>
      <h1 className={styles.headerText}>Person Cards</h1>
      <Flex style={{ alignItems: 'center' }}>
        <div className={styles.imageContainer}>
          <img src={bellIcon} className={styles.image} />
        </div>
        <img src={bgIcon} className={styles.image} />
      </Flex>
    </Flex>
  );
};

const FiltersRow = ({
  onClick,
  onChange,
  searchText,
  filterOptions,
  filter,
  disabled,
}) => {
  console.log('filter', filter);
  return (
    <Flex className={styles.filterBarContainer}>
      <Flex className={styles.selectWrapper}>
        <Select
          options={filterOptions}
          // value={statuses.filter(
          //   (option) => option.attendance === selectedStatus
          // )}
          defaultValue={
            filter
              ? (filterOptions || [])
                .map(option => ({
                  value: option.id,
                  label: option.name,
                }))
                .find(o => o.id === filter)
              : null
          }
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          placeholder={'Select Type'}
          styles={customStyles}
          onChange={({ id }) => {
            onChange('filter', id);
          }}
        />
      </Flex>

      <Flex className={styles.searchBox}>
        <TextInput
          onChange={update => {
            onChange('searchText', update);
          }}
          value={searchText}
          isEdit={true}
          type={'text'}
          inputStyle={styles.inputText}
          showSeacrh={true}
          placeholder={'Enter ref. number or Aadhaar Number or RFID'}
        />
      </Flex>

      <Button
        onClick={() => onClick('apply')}
        buttonStyle="primary"
        label={'Go'}
        className={styles.button}
        disabled={disabled}></Button>
    </Flex>
  );
};
