import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
//import App from "../App";
import '../App.scss';
import AdminRouter from '../Routes/admin-router';
import OperatorRouter from '../Routes/operator-router';
import AgencyRouter from '../Routes/agency-router';
import DeptRouter from './dept-router';

import {connect} from 'react-redux';
import PrivateRoute from '../components/PrivateRoute';
import PublicRouter from './public-router';
import Loading from '../components/Loading';
import Playground from '../pages/Playground';
// import PermitPass from '../pages/PermitPass/Views/Desktop/index';
import ROLES_CONSTANTS from '../constants/roles-constants';
import AdminPortalRouter from './admin-portal-router';
import MasterAdminRouter from './master-admin-router';
import InvoicesRouter from './invoices-router';
const operatorRoleIds = [5, 6, 7];
const agencyRoleIds = [2, 3, 8];
const deptRoleIds = [4];

function Routes(props) {
  const {reduxProps} = props;

  const getLoggedInRouter = () => {
    const {
      login: {loginInfo: {user} = {}},
    } = reduxProps;
    console.info('user', user);
    if (ROLES_CONSTANTS.operatorModuleUser.includes(user?.roleId)) {
      return OperatorRouter;
    } else if (ROLES_CONSTANTS.agencyUser.includes(user?.roleId)) {
      return AgencyRouter;
    } else if (ROLES_CONSTANTS.deptUser.includes(user?.roleId)) {
      return DeptRouter;
    } else if (ROLES_CONSTANTS.adminPortalUser.includes(user?.roleId)) {
      return AdminPortalRouter;
    } else if (ROLES_CONSTANTS.invoiceAdmin.includes(user?.roleId)) {
      return InvoicesRouter;
    } else if (ROLES_CONSTANTS.masterAdmin.includes(user?.roleId)) {
      return MasterAdminRouter;
    } else {
      return AdminRouter;
    }
  };

  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute path="/u" component={getLoggedInRouter()} />
        <Route exact path="/test" component={Loading} />
        <Route exact path="/playground" component={Playground} />
        {/* <Route exact path="/permitpass" component={PermitPass} /> */}
        <Route component={PublicRouter} />
      </Switch>
    </BrowserRouter>
  );
}

const mapStateToProps = reduxProps => ({
  reduxProps,
});

export default connect(mapStateToProps, null)(Routes);
