export const customStyles = {
  placeholder: (provided, state) => {
    return {
      ...provided,
      fontFamily: 'Poppins-Regular',
      fontSize: '14px',
      letterSpacing: '0.56px',
      fontWeight: 400,
      display: 'flex',
      alignItems: 'center',
      color: 'white',
    };
  },
  control: (provided, state) => {
    return {
      ...provided,
      height: '2.2rem',
      marginTop: '0.2rem',
      width: '13rem',
      background: '#082F8E',
      borderRadius: 5,
      borderWidth: 0,
      fontSize: '0.8rem',
      color: 'white',
    };
  },
  indicatorSeparator: provided => {
    return {
      ...provided,
      backgroundColor: '#E6EDFF',
    };
  },
  singleValue: provided => {
    return {
      ...provided,
      color: '#FFFFFF',
    };
  },
};
