import clsx from 'clsx';
import React from 'react';
import Button from '../../EDrishtiDesignSystem/Button';
import Flex from '../../EDrishtiDesignSystem/Flex';
import IconButton from '../../EDrishtiDesignSystem/IconButton';
import styles from './styles.module.scss';

import BackgroundImage from '../../../assets/images/resource-center/video-guide/background/image.png';
import PDFIcon from '../../../assets/images/resource-center/quick-guides/pdf/image.png';

const QuickGuide = ({onClose, onClick}) => {
  return (
    <div className={styles.container}>
      <IconButton
        onClick={onClose}
        variant="cross"
        className={styles.crossIcon}
      />

      <div className={clsx(styles.content)}>
        <h2 className={styles.heading}>Please find the quick guides below</h2>
        <Flex align="center" style={{flexWrap: 'wrap', gap: '1rem'}}>
          <GuideBox
            title={`Person Registration`}
            description={`Any person with the intent of entering the port for any purpose of work must register themselves. (One- time)`}
            href={`https://edrishti.sanya.ai/user-manual/a1d7cbbb-3f1a-4362-97b8-d4a872fd7129PersonRegistration.pdf`}
          />
          <GuideBox
            title={`Vehicle Registration`}
            description={`Any vehicle owner with the intent of entering their vehicle into the port for any purpose of work must register themselves. (One- time)`}
            href={`https://edrishti.sanya.ai/user-manual/48a58c43-aee3-4aaf-a264-d50ff1749810VehicleRegistration.pdf`}
          />
          <GuideBox
            title={`Wallet Usage`}
            description={`e-Drishti Credits Wallet System gives access to your funds to make hassle-free payments and acts as a prepaid recharge service to your access into the Port.`}
            href={`https://edrishti.sanya.ai/user-manual/wallet-usage.pdf`}
          />
          {/* <GuideBox
            title={`Raising a Permit`}
            description={` Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum
          dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet
          Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum
          dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet`}
            href={`#`}
          /> */}
        </Flex>
      </div>
    </div>
  );
};

const GuideBox = ({title, description, href}) => {
  return (
    <div className={styles.guide}>
      <div className={styles.iconContainer}>
        <img src={PDFIcon} alt="icon" className={styles.icon} />
      </div>
      <div className={styles.guideContent}>
        <h1>{title}</h1>
        <p>{description}</p>
        <div style={{marginTop: '1rem'}}>
          <Button
            label="View"
            buttonStyle="primary-outline"
            className={styles.CTAButton}
            as="a"
            target="__blank"
            href={href}
          />
          <Button
            label="Download"
            buttonStyle="primary-outline"
            className={styles.CTAButton}
            as="a"
            target="__blank"
            href={href}
          />
        </div>
      </div>
    </div>
  );
};

export default QuickGuide;
