import React, { useEffect, useState } from 'react';
import Flex from '../../../../../../components/Flex';
import TextInput from '../../../../../../components/TextInput';
import styles from './styles.module.scss';
import searchIcon from '../../../../../../assets/images/search-icon/image.png';
import { agencyImages, commonImages } from '../../../../../../assets/images';
import moment from 'moment';
import Select from 'react-select';
import { customStyles } from './customStyles';
import { API } from '../../../../../../requests';

export default function Desktop({
  person,
  vehicle,
  onChange,
  searchText,
  onClick,
  personsArray,
  persons,
  filter,
  filterOptions,
  status,
}) {
  const [personAdded, setPersonAdded] = useState(
    status === 'view' ? true : false,
  );
  const [personDuplicate, setPersonDuplicate] = useState(false);

  const [vehicleAdded, setVehicleAdded] = useState(false);

  const [currentStep, setCurrentStep] = useState(status === 'view' ? 5 : 1);
  const [showVehicleSearchBar, setShowVehicleSearchBar] = useState(
    status === 'view' ? true : false,
  );

  console.log('status:', status);
  console.log('person', person);
  const [twoWheelVehicleNumber, setTwoWheelVehicleNumber] = useState('');

  const [showAddTwoWheelerInputBox, setShowAddTwoWheelerInputBox] = useState(false);

  const { twoWheelerVehicleRegistrationNumber } = person || {};
  const permitSubmitSteps = {
    STEP_1: 1, // display popup (only cancel button required)
    STEP_2: 2, // display person data (cancel and confirm buttons required)
    STEP_3: 3, // confirm person data (cancel and submit buttons required)
    STEP_4: 4, // display check box with final display of person data(cancel and submit buttons required)
    STEP_5: 5, // display person and vehicle data (confirm vehicle button and cancel required)
    STEP_6: 6, // final display person and vehicle data (cancel and submit buttons required)
  };
  const changeStep = step => {
    setCurrentStep(step);
  };
  const isPersonDuplicate = value => {
    setPersonDuplicate(value);
  };
  const getButtons = step => {
    console.log(step, showVehicleSearchBar);
    switch (step) {
      case permitSubmitSteps.STEP_1:
        return (
          <>
            {' '}
            <button className={styles.cancel} onClick={() => onClick('cancel')}>
              Cancel
            </button>
          </>
        );
      case permitSubmitSteps.STEP_2:
        return (
          <>
            <button className={styles.cancel} onClick={() => onClick('cancel')}>
              Cancel
            </button>
            <button
              className={styles.finish}
              style={
                (showVehicleSearchBar && !vehicle) ||
                  (!personDuplicate && person && Object.keys(person).length) > 0
                  ? null
                  : { backgroundColor: '#0045e670' }
              }
              disabled={
                (showVehicleSearchBar && !vehicle) ||
                  (!personDuplicate && person && Object.keys(person).length > 0)
                  ? false
                  : true
              }
              onClick={() => {
                onClick('addPersonToTable', person);
                if (person?.RFIDNumber) {
                  setPersonAdded(true);
                  setCurrentStep(3);
                }
              }}>
              Confirm
            </button>
          </>
        );
      case permitSubmitSteps.STEP_3:
        return (
          <>
            <button className={styles.cancel} onClick={() => onClick('cancel')}>
              Cancel
            </button>
            <button
              className={styles.finish}
              disabled={person && Object.keys(person).length > 0 ? false : true}
              onClick={() => {
                onClick('submit', personsArray);
                // setCurrentStep(4);
              }}>
              Confirm
            </button>
          </>
        );
      case permitSubmitSteps.STEP_4:
        return (
          <>
            <button className={styles.cancel} onClick={() => onClick('cancel')}>
              Cancel
            </button>
            <button
              className={styles.finish}
              disabled={true}
              style={{ backgroundColor: '#0045e670' }}
              onClick={() => {
                onClick('submit', personsArray);
              }}>
              Confirm
            </button>
          </>
        );
      case permitSubmitSteps.STEP_5:
        return (
          <>
            <button className={styles.cancel} onClick={() => onClick('cancel')}>
              {status === 'view' ? 'Close' : 'Cancel'}
            </button>
            {status !== 'view' ? (
              <button
                className={styles.finish}
                onClick={() => {
                  onClick('submit', personsArray);
                }}>
                Submit
              </button>
            ) : null}
          </>
        );
      default:
        break;
    }
  };

  return (
    <Flex className={styles.mainContainer}>
      <Flex className={styles.container}>
        <Header />
        <div className={styles.bodyContainer}>
          {!personAdded ? (
            <>
              <div className={styles.filterLabel}>Select search type</div>
              <Flex className={styles.filtersWrapper}>
                <div className={styles.selectWrapper}>
                  <Select
                    options={filterOptions}
                    // value={statuses.filter(
                    //   (option) => option.attendance === selectedStatus
                    // )}
                    defaultValue={
                      filter
                        ? (filterOptions || [])
                          .map(option => ({
                            value: option.id,
                            label: option.name,
                          }))
                          .find(o => o.id === filter)
                        : null
                    }
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    placeholder={'Select Type'}
                    styles={customStyles}
                    onChange={({ id }) => {
                      onChange('filter', id);
                    }}
                  />
                </div>
                <SearchBar
                  searchText={searchText}
                  onChange={onChange}
                  placeholder={'Search By Ref. Number / Aadhaar Number'}
                  onClick={() => {
                    onClick('personSearchText');
                    setCurrentStep(2);
                  }}
                />
              </Flex>
            </>
          ) : null}
          <Flex className={styles.personCardsContainer}>
            {person ? (
              <PersonsMetadata
                onClick={onClick}
                person={person}
                personsArray={personsArray}
                persons={persons}
                status={status}
                isPersonDuplicate={isPersonDuplicate}
              />
            ) : null}
            {personAdded && !showVehicleSearchBar ? (
              <>
                {' '}
                <SuccessText
                  text="Person added successfully"
                  icon={agencyImages.successIcon}
                />
              </>
            ) : null}
          </Flex>
          {!personDuplicate && currentStep === 2 ?
            <Flex direction="row" className={styles.addTwoWheelContainer}>
              <div className={styles.twoWheelerInputBoxLabel} onClick={() => { setShowAddTwoWheelerInputBox(true) }}>Add two wheeler</div>
              {showAddTwoWheelerInputBox ? <AddTwoWheeler onClick={() => onClick('cancel')} onAddTwoWheelerVehicle={(number) => {
                console.log(number);
                setTwoWheelVehicleNumber(number);
                setShowAddTwoWheelerInputBox(false);
                onClick('addTwoWheeler', number);
              }} /> : null}
            </Flex> : null}
          {personAdded && !twoWheelerVehicleRegistrationNumber ? (
            <>
              {' '}
              <Flex className={styles.checkBoxContainer} direction="column">
                {currentStep < 5 ? (
                  <Flex>
                    <input
                      id="verifiedPersonalDetails"
                      type="checkbox"
                      className={styles.checkbox}
                      checked={showVehicleSearchBar}
                      onChange={() => {
                        setShowVehicleSearchBar(!showVehicleSearchBar);
                        if (showVehicleSearchBar) {
                          delete person?.vehicleId;
                          onClick('addPersonToTable', person);
                          setCurrentStep(3);
                        } else {
                          setCurrentStep(4);
                        }
                      }}
                    />
                    <div className={styles.addLabel}>
                      Add Associate Vehicle ?
                    </div>
                  </Flex>
                ) : (
                  <div className={styles.addLabel}>
                    Associated Permit Vehicle
                  </div>
                )}
                {showVehicleSearchBar ? (
                  <>
                    {currentStep < 5 ? (
                      <SearchBar
                        searchText={searchText}
                        onChange={onChange}
                        placeholder={'Search By Vehicle number/RFID/Ref. No'}
                        onClick={() => {
                          onClick('vehicleSearchText');
                          if (vehicle) {
                            setVehicleAdded(true);
                            setCurrentStep(2);
                          } else {
                          }
                        }}
                      />
                    ) : null}
                    {vehicle ? (
                      <Flex
                        className={[
                          styles.personCardsContainer,
                          styles.vehicleContainer,
                        ].join(' ')}>
                        <VehicleMetadata
                          personsArray={personsArray}
                          person={person}
                          onClick={onClick}
                          changeStep={changeStep}
                          vehicle={vehicle}
                          currentStep={currentStep}
                          status={status}
                        />
                      </Flex>
                    ) : null}
                  </>
                ) : null}
              </Flex>
            </>
          ) : null}
        </div>
        <Flex direction="row" className={styles.buttonContainer}>
          {getButtons(currentStep)}
          {/* <button className={styles.cancel} onClick={() => onClick('cancel')}>
            Cancel
          </button>
          <button
            className={styles.finish}
            disabled={person && Object.keys(person).length > 0 ? false : true}
            onClick={() => {
              onClick('addPersonToTable', person);
              setPersonAdded(true);
            }}>
            Confirm
          </button> */}
        </Flex>
      </Flex>
    </Flex>
  );
}

const Header = () => {
  return (
    <Flex className={styles.header}>
      <p>{'Add Person'}</p>
    </Flex>
  );
};

const DataItem = ({ label, value }) => {
  return (
    <Flex className={styles.lineContainer}>
      <div className={styles.dataLabel}>{label}</div>
      <div className={styles.dataValue}>{value}</div>
    </Flex>
  );
};

const SuccessText = ({ icon, text }) => {
  return (
    <Flex className={styles.successDiv}>
      <img src={icon} className={styles.successIcon} alt={'u'} />
      <div className={styles.dataValue}>{text}</div>
    </Flex>
  );
};

const SearchBar = ({ onChange, onClick, searchText, placeholder }) => {
  return (
    <Flex direction="row" className={styles.searchContainer}>
      <input
        className={styles.searchInput}
        onChange={e => onChange('searchText', e.target.value)}
        placeholder={placeholder}
        value={searchText}
      />
      <button className={styles.searchButton} onClick={onClick}>
        <img src={searchIcon} className={styles.searchIcon} alt="Avatar" />
      </button>
    </Flex>
  );
};



const AddTwoWheeler = ({ onAddTwoWheelerVehicle, onClick }) => {
  const [twoWheelerVehicleRFIDNumber, setTwoWheelerVehicleRFIDNumber] = useState('');
  return (
    <Flex className={styles.mainContainer}>
      <Flex className={styles.container}>
        <Flex className={styles.header}>
          <p>{'Add Vehicle Number'}</p>
        </Flex>
        <div className={styles.bodyContainer}>
          <div className={styles.twoWheelerInputBoxContainer}>
            <Flex
              direction="row"
              className={styles.buttonContainer}
            >
              <input className={styles.twoWheelerInput} placeholder={'Enter two wheeler vehicle number'} value={twoWheelerVehicleRFIDNumber} onChange={e => setTwoWheelerVehicleRFIDNumber(e.target.value)} />

            </Flex>
            <Flex
              direction="row"
              className={styles.buttonContainer}
            >
              <button className={styles.cancel} onClick={() => onClick('cancel')}>
                Cancel
              </button>
              <button style={{ fontFamily: 'Poppins-light' }} className={styles.finish} onClick={() => onAddTwoWheelerVehicle(twoWheelerVehicleRFIDNumber)}>
                Add
              </button>
            </Flex>
          </div>
        </div>
      </Flex>
    </Flex>)
}

const PersonsMetadata = ({
  onClick,
  person,
  persons,
  personsArray = [],
  isPersonDuplicate,
  status,
}) => {
  const [visible, setVisible] = useState(true);
  const [photoURL, setPhotoURL] = useState(person?.photo);
  console.log('personsArray', personsArray);
  console.log('persons', persons);

  const preSignUrl = async url => {
    const data = {
      url: url,
      expiryTime: 30,
    };
    await API.preSignedURL(data)
      .then(res => {
        console.log('res.data', res.data?.preSignedUrl);
        setPhotoURL(res.data?.preSignedUrl);
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log('person details:', person);
    if (status !== 'view') {
      if (
        persons?.filter(function (pa) {
          return pa.id === person.id;
        }).length > 0
      ) {
        /* personsArray contains the element we're looking for */
        setVisible(false);
        isPersonDuplicate(true);
      } else {
        setVisible(true);
        isPersonDuplicate(false);
      }
    }

    preSignUrl(person?.photo);
  }, [persons, person, personsArray]);
  return (
    <>
      <Flex direction="column" className={styles.personDetails}>
        {/* //image text container */}
        <Flex direction="row" className={styles.imageTextContainer}>
          <img
            onClick={visible ? () => onClick('view') : () => { }}
            src={photoURL}
            alt="u"
            className={styles.personImage}
          />
          <Flex direction="column" className={styles.textArea}>
            <DataItem value={person?.name} label="Name:" />
            <DataItem value={person?.RFIDNumber} label="RFID Number:" />
            <DataItem value={person?.aadhaarNumber} label="Aadhaar Number: " />
            <DataItem
              value={person?.phoneNumber}
              label="Registered Phone No.: "
            />
            {person?.twoWheelerVehicleRegistrationNumber ?
              <DataItem
                value={person?.twoWheelerVehicleRegistrationNumber}
                label="Two Wheeler Vehicle Reg. Number: "
              /> : null}

          </Flex>
        </Flex>

        {!visible ? (
          <p className={styles.personAlreadyAdded}>Person already added</p>
        ) : null}
      </Flex>

    </>
  );
};

const addVehicle = (vehicle, person, personsArray, onClick) => {
  const updatedPerson = { ...person, ...{ vehicle: vehicle } };
  console.log(vehicle, updatedPerson);
  onClick('addPersonToTable', updatedPerson);
};

const VehicleMetadata = ({
  onClick,
  vehicle,
  person,
  personsArray,
  changeStep,
  status,
}) => {
  useEffect(() => {
    console.log('vehicle details:', vehicle);
  }, [vehicle]);
  const [confirmVehicle, setConfirmVehicle] = useState(false);
  return (
    <Flex direction="column" className={styles.personDetails}>
      {/* //image text container */}
      <Flex direction="row" className={styles.imageTextContainer}>
        <div className={styles.vehicleImage}>
          <img src={commonImages.vehicleIconBlue} alt="u" />
        </div>
        <Flex direction="column" className={styles.textArea}>
          <DataItem
            value={vehicle?.vehicleRegistrationNumber}
            label="vehicle Registration Number:"
          />
          <DataItem value={vehicle?.rfidnumber} label="RFID Number:" />
          <DataItem
            value={vehicle?.vehicleType?.name}
            label="Vehicle Category: "
          />
          <DataItem
            value={vehicle?.phoneNumber}
            label="Registered Phone No.: "
          />
        </Flex>
      </Flex>
      {!confirmVehicle && status !== 'view' ? (
        <Flex
          direction="row"
          className={styles.buttonContainer}
          style={{ justifyContent: 'end' }}>
          <button
            className={styles.finish}
            style={{ fontFamily: 'Poppins-light' }}
            onClick={() => {
              setConfirmVehicle(true);
              addVehicle(vehicle, person, personsArray, onClick);
              changeStep(5);
            }}>
            Confirm Vehicle
          </button>
        </Flex>
      ) : null}
    </Flex>
  );
};
