import React, { Component } from 'react';
import PopupContainer from '../../../components/PopupContainer';
import moment from 'moment';
import styles from './styles.module.scss';

import ViewRenderer from './views';
import AddNewOperator from '../../../components/operator/AddNewOperator';
import { API } from '../../../requests';
import { formRightFields, formLeftFields } from './formFeilds';
import util from '../../../util';
// import {locations} from './formData';
import { commonImages, operatorImages } from '../../../assets/images';
import Popup from '../../../components/SimplePopup';
import strings from '../../../globalization';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  SPINNER: 'spinner',
  ERROR: 'error',
  SIMPLE_POPUP: 'simple-popup',
};
class ManageOperators extends Component {
  operatorPhotoURL = '';
  constructor(props) {
    super(props);
    let preparedObject = {};
    [...formLeftFields, ...formRightFields].forEach(obj => {
      preparedObject[obj.stateField] = { value: obj.stateDefault, error: null };
      if (obj.optionsField) {
        preparedObject[obj.optionsField] = [];
      }
    });

    const {
      login: { loginInfo },
    } = props.reduxProps;

    const { user } = loginInfo;
    const { location } = loginInfo;
    console.log('loginInfo - Manage Operators', loginInfo);
    this.state = {
      operators: [],
      filterOperators: [],
      status: undefined,
      startDate: moment(),
      endDate: moment(),
      selectedStatus: '',
      selectedLocation: '',
      searchText: '',
      statuses: [
        {
          id: 'ACTIVE',
          name: 'Active',
        },
        {
          id: 'INACTIVE',
          name: 'Inactive',
        },
        {
          id: 'PENDING',
          name: 'Pending',
        },
      ],
      user: user,
      locations: [],
      activeLocation: location,
      ...preparedObject,
      photo: { value: operatorImages.userIcon },
      roleOptions: [],
    };
  }

  getData = async () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });

    API.fetchActiveOperator()
      .then(response => {
        console.info('response... active operators', response.data);
        this.setState({
          operators: response.data,
          filterOperators: response.data,
          popup: undefined,
        });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        console.info('error....', errorMsg);
        this.setState({
          popup: undefined,
        });
      });
    // Also doing the shift setting here
    API.fetchActiveLocation()
      .then(response => {
        this.setState({
          locations: response.data,
          locationsOptions: response.data,
          popup: undefined,
        });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        console.info('error....', errorMsg);
        this.setState({
          popup: undefined,
        });
      });
    API.getAllGender()
      .then(genderRes => {
        this.setState({
          genderOptions: genderRes.data,
        });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        this.showErrorRetryPopup(errorMsg, this.getData);
      });
    API.fetchAllRoles()
      .then(rolesRes => {
        let requiredRoles = rolesRes.data.filter(
          elem => elem.roleId === 6 || elem.roleId === 7,
        );
        // FIXME: Added ID field manually to roles array
        requiredRoles = requiredRoles.map(elem => {
          return { ...elem, id: elem.roleId };
        });
        this.setState({
          roleOptions: requiredRoles,
        });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        this.showErrorPopup(errorMsg);
      });
  };


  showErrorRetryPopup = (errorMsg, retryCallback) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMsg,
        messageStyle: { color: '#E67717' },

        buttons: [
          {
            text: strings.cancelPopup,
            onClick: this.closePopup,
            outline: true,
          },
          {
            text: strings.retryApiPopup,
            onClick: () => {
              this.closePopup();
              retryCallback();
            },
          },
        ],
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
      },
    });
  };

  componentDidMount() {
    this.getData();
  }

  /**
   *
   * @param {*} action
   * @param {*} data
   */

  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: { color: '#E67717' },
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  showSuccessPopup = (message, onClick) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: message,
        headingImage: operatorImages.successIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: onClick ? onClick : this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  onClickHandler = (action, data) => {
    // const { history } = this.props;
    switch (action) {
      // case "clearFilter": {
      //   console.info('action....', action, data);
      //   break;
      // }

      default: {
        console.info('case not handled', action);
      }
    }
  };
  editOperator = id => {
    const { history, location } = this.props;
    history.push({
      pathname: location.pathname + '/add-new-operator/' + id,
      state: { background: location },
    });
  };

  onDelete = id => {
    console.log('onDelete called ONLY');
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: 'Are you sure you want to delete this operator?',
        onClose: this.closePopup,
        buttons: [
          {
            text: 'Yes',
            onClick: () => this.handleDelete(id),
            outline: true,
          },
          {
            text: 'No',
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  handleDelete = id => {
    API.deleteOperator(id)
      .then(res => {
        this.showSuccessPopup('Operator deleted Successfully', this.getData);
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        this.showErrorPopup(errorMsg);
      });
  };
  getRoleOptions = () => {
    const {
      roleOptions,
      user: { roleId },
    } = this.state;
    if (roleId === 6) {
      let roles = roleOptions.filter(elem => elem.roleId === 7);
      return roles;
    } else {
      return roleOptions;
    }
  };
  getLocationsOptions = () => {
    const {
      locationsOptions,
      activeLocation,
      user: { roleId },
    } = this.state;
    if (roleId === 6) {
      let locationOption = locationsOptions.filter(
        elem => elem.id === activeLocation.id,
      );
      return locationOption;
    } else {
      return locationsOptions;
    }
  };
  filterChangeHandler = (action, data) => {
    const { history, location } = this.props;
    console.log('action', action, data);
    switch (action) {
      case 'status': {
        const { operators } = this.state;

        const filterOperators = operators.filter(
          operator => operator.status === data,
        );
        this.setState({
          filterOperators,
          selectedStatus: data,
        });
        break;
      }
      case 'location': {
        const { operators } = this.state;
        const filterOperators = operators.filter(
          operator => operator.location.id === data,
        );
        this.setState({
          filterOperators,
          selectedLocation: data,
        });
        break;
      }

      case 'search': {
        console.info('data..', data);
        const { operators } = this.state;
        console.log('operators', operators);
        const filterOperators = operators.filter(operator =>
          operator.firstName?.toLowerCase().includes(data.toLowerCase()),
        );
        this.setState({
          filterOperators,
        });
        break;
      }
      case 'createNew': {
        history.push({
          pathname: location.pathname + '/add-new-operator',
          state: { background: location },
        });
        break;
      }
      case 'clearFilter': {
        const { operators } = this.state;
        this.setState({
          selectedLocation: null,
          searchText: '',
          selectedStatus: null,
          filterOperators: operators,
        });
        break;
      }
      default: {
        console.info('filterChangeHandler case not handled', action);
      }
    }
  };
  onChange = (action, data) => {
    this.setState(prevState => {
      return {
        ...prevState,
        [action]: data,
      };
    });
  };
  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };
  fetchShift = locationId => {
    API.fetchShift(locationId)
      .then(response => {
        console.info('response...', response);
        this.setState({
          shiftOptions: response.data,
        });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        console.info('error....', errorMsg);
        this.showErrorPopup(errorMsg);
      });
  };

  fuleURLCallback = url => {
    // this.operatorPhotoURL = url;
    this.setState({
      photo: { value: url },
    });
  };
  onChangeHandler = (field, value) => {
    if (field === 'searchText') {
      this.filterChangeHandler('search', value);
    }
    if (field === 'location') {
      this.fetchShift(value);
    }

    this.setState(prevState => {
      const currentObj = prevState[field];
      return {
        ...prevState,
        [field]: {
          ...currentObj,
          value,
          error: null,
        },
      };
    });
  };

  getProps = () => {
    const {
      statuses,
      filterOperators,
      locations,
      selectedStatus,
      selectedLocation,
      searchText,
    } = this.state;
    return {
      onClick: this.onClickHandler,
      filterChangeHandler: this.filterChangeHandler,
      handleUpload: this.handleUpload,
      editOperator: this.editOperator,
      onDelete: this.onDelete,
      statuses,
      operators: filterOperators,
      locations,
      selectedStatus,
      selectedLocation,
      // searchText,
      user: this.state.user,
      onChange: this.onChangeHandler,
      searchText: this.state.searchText.value,
    };
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };

  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const { popup, photo } = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const { popup } = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

const mapStateToProps = reduxProps => ({
  reduxProps,
});
export default withRouter(connect(mapStateToProps, null)(ManageOperators));
