import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import POPUP_CONSTANTS from '../../../../constants/popup-constants';
import {API} from '../../../../requests';
import util from '../../../../util';

import styles from './styles.module.scss';

import Spinner from '../../../../components/Spinner';
import Popup from '../../../../components/SimplePopup';

import ViewRenderer from './views';
import PopupContainer from '../../../../components/PopupContainer';
import {commonImages} from '../../../../assets/images';
import strings from '../../../../globalization';
import {connect} from 'react-redux';
import {setPersonsData} from '../../../../store/actions/permits';
import {getUserForm} from './data';

class AddUser extends Component {
  constructor(props) {
    super(props);
    const {rowData} = this.props.location.state;
    const {status} = this.props;
    this.formData = getUserForm();
    let preparedObject = {};
    this.formData.map(obj => {
      if (
        (status === 'EDIT' || status === 'DELETE') &&
        rowData[obj.stateField]
      ) {
        preparedObject[obj.stateField] = {
          value: rowData[obj.stateField],
          error: null,
        };
      } else {
        preparedObject[obj.stateField] = {value: obj.stateDefault, error: null};
      }
      if (obj.optionsField) {
        preparedObject[obj.optionsField] = [];
      }
    });
    this.state = {
      selectedStatus: this.props.status,
      userId: rowData && rowData.userId ? rowData.userId : undefined,
      ...preparedObject,
    };

    console.log(rowData, this.state);
  }

  componentDidMount = () => {
    this.getData();
  };
  goBack = () => {
    console.log('goBack Called!!-->');
    const {history} = this.props;
    history.goBack();
  };

  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }

      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };

  onClick = (action, data) => {
    const {history} = this.props;
    // const {dispatchSetPersonsData} = this.props;

    switch (action) {
      case 'cancel': {
        this.goBack();
        break;
      }
      case 'delete': {
        console.log(data);
        this.deleteConfirmPopup(data);
        break;
      }
      case 'save': {
        // console.log('calling dispatchSetPersonsData--->');
        //  dispatchSetPersonsData(data);

        console.log('this.state', this.state);
        this.submitUserRequest();
        break;
      }
      case 'view': {
        this.onView();
        break;
      }
      default: {
        console.warn('No action found...', action);
        break;
      }
    }
  };

  submitUserRequest = () => {
    let error = this.handleErrors();
    if (error) {
      this.closePopup();
    } else {
      this.showSpinner();
      this.submitUser()
        .then(res => {
          this.closePopup();
          this.setState({
            success: true,
          });
          console.log('User added/updated successfully', res);
          this.goBack();
        })
        .catch(errors => {
          const errorMsg = errors.response
            ? errors.response.data.message
            : errors.message;
          this.closePopup();
          this.showErrorPopup(
            errorMsg
              ? errorMsg
              : 'There was an error while adding/updating the user',
          );
          console.log(errors);
        });
    }
  };

  submitUser = async () => {
    console.log('in submitRequest');
    const {
      firstName,
      phoneNumber,
      emailAddress,
      role,
      designation,
      userId,
      selectedStatus,
      employeeId,
    } = this.state;
    console.log(this.state);

    let preparedData = {
      userId: userId ? userId : undefined,
      name: firstName.value,
      phoneNumber: '+91' + phoneNumber.value,
      emailAddress: emailAddress.value,
      designation: designation.value,
      roleId: role.value,
      employeeId: employeeId.value,
    };

    console.log('preparedData', preparedData);
    let res;
    if (selectedStatus === 'ADD') {
      res = await API.addDepartmentUser(preparedData);
    } else if (selectedStatus === 'EDIT') {
      res = await API.editDepartmentUser(preparedData);
    }
    if (res?.status === 200) {
      console.log('response after adding/updating user', res.data);
    }
    return res.data;
  };

  deleteUser = async btn => {
    console.log('in deleteUser');
    const {userId} = this.state;
    let res;
    res = await API.deleteDepartmentUser({
      id: userId,
    });
    if (res?.status === 200) {
      console.log('response after deleting user', res.data);
    }
    return res.data;
  };

  deleteConfirmPopup = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: 'Are you sure want to delete the user?',
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: 'No',
            onClick: this.closePopup,
          },
          {
            text: 'Yes',
            onClick: () => {
              this.deleteUserRequest();
            },
            outline: true,
          },
        ],
      },
    });
  };

  deleteUserRequest = () => {
    this.showSpinner();
    this.deleteUser()
      .then(res => {
        this.closePopup();
        this.setState({
          success: true,
        });
        console.log('User deleted successfully', res);
        this.goBack();
      })
      .catch(errors => {
        const errorMsg = errors.response
          ? errors.response.data.message
          : errors.message;
        this.closePopup();
        this.showErrorPopup(
          errorMsg ? errorMsg : 'There was an error while deleting the user',
        );
        console.log(errors);
      });
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  checkDisabled = () => {
    // const {personsArray} = this.state;

    // if (personsArray.length > 0) {
    //   return false;
    // } else {
    //   return true;
    // }
    return false;
  };
  onChangeHandler = (field, value) => {
    console.log('field', field, 'value', value);

    this.setState(prevState => ({
      ...prevState,
      [field]: {
        value,
      },
    }));
    console.log('this.state', this.state);
  };
  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  closePopup = () => {
    this.setState({
      popup: null,
    });
  };

  onView = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.VIEW_PERSON_POPUP,
        onClose: this.closePopup,
        viewDocument: this.viewDocument,
      },
    });
  };
  viewDocument = url => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.VIEW_DOCUMENT,
        url: url,
        onClick: this.closePopup,
      },
    });
  };

  getData = async () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
    let promises = [];
    promises.push(this.getAgencyRoles());

    Promise.all(promises)
      .then(() => {
        this.closePopup();
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);

        // crashlytics().recordError(new Error(errorMsg));
        console.error('getData error', errorMsg, error);
        this.showErrorPopup(errorMsg);
      });
  };

  getAgencyRoles = () => {
    return API.getDepartmentRolesList().then(response => {
      console.info('getDepartmentRolesList response', response);
      const options = response.data.map(element => {
        const new_obj = {
          ...element,
          id: element.roleId,
          name: element.displayName,
        };
        return new_obj;
      });

      this.setState({
        agencyRoleOptions: options,
      });
    });
  };

  checkDisabledForm = elem => {
    const {selectedStatus} = this.state;
    if (
      (selectedStatus === 'EDIT' || selectedStatus === 'DELETE') &&
      (elem.stateField === 'phoneNumber' || elem.stateField === 'emailAddress')
    ) {
      return true;
    } else {
      return false;
    }
  };
  handleUpload = (field, files) => {
    this.showSpinner();
    if (files === null) {
      throw new Error('File does not exist');
    }

    let filesArray = [];
    for (let i = 0, numFiles = files.length; i < numFiles; i++) {
      filesArray.push(files[i]);
    }

    console.log('filesArray', filesArray);

    // FIXME: Fix real uploading thing.

    let promises = filesArray.map(file => {
      const data = new FormData();
      data.append('s3FolderName', 'company');
      data.append('file', file);
      data.append('allowedExtensions', ['Pk8b8jAuPC1DF', 'JakNVgcBVZUPG', 'JPw7fCgDTT6AEG', 'PCrAriI079CNG']);
      return API.uploadFilesToDpt(data).then(response => {
        console.log('Response Here', response.data);
        const url = response.data?.fileUrl;
        return {fileUrl: url, fileName: file.name};
      });
    });

    return Promise.all(promises)
      .then(response => {
        console.log('[field] - this.state[field]', this.state[field]);
        console.log('response', response);
        this.setState(prevState => ({
          popup: null,
          [field]: {
            ...prevState[field],
            value: [...prevState[field].value, ...response],
            error: null,
          },
        }));
      })
      .catch(error => {
        console.log('error', error);
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;

        this.setState({
          popup: null,
        });
        this.showErrorPopup(errorMsg);
        //throw new Error(errorMsg);
        console.log(errorMsg);
        return null;
      });
  };

  handleErrors = () => {
    let err = false;
    const update = {};
    let formData = [...this.formData];
    console.log('formData', formData);
    for (let i = 0; i < formData.length; i++) {
      let element = formData[i];
      console.log('element', i, element);
      const error = util.validate(
        this.state[element.stateField].value,
        element,
      );
      if (error) {
        err = true;
        console.log('error', error);
        console.log('element.stateField', element.stateField);
        update[element.stateField] = {
          ...this.state[element.stateField],
          error: error,
        };
      }
    }
    this.setState({
      ...update,
    });
    return err;
  };

  getProps = () => {
    const {person, personsArray, searchText} = this.state;
    const persons = this.props.location.state.persons;
    return {
      goBack: this.goBack,
      onClick: this.onClick,
      onChangeHandler: this.onChangeHandler,
      checkDisabled: this.checkDisabled,
      checkDisabledForm: this.checkDisabledForm,
      handleUpload: this.handleUpload,
      stateData: this.state,
      formData: this.formData,
      selectedStatus: this.state.selectedStatus,
    };
  };
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer containerStyle={styles.popupContainerStyle}>
            {' '}
            {this.getPopupContent()}{' '}
          </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

// const mapStateToProps = reduxProps => ({
//   reduxProps,
// });

const mapDispatchToProps = dispatch => ({
  dispatchSetPersonsData: payload => {
    dispatch(setPersonsData(payload));
  },
});

// export default withRouter(PersonCards);
export default withRouter(connect(null, mapDispatchToProps)(AddUser));
