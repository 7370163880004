import React from 'react';
import styles from './Mobile.module.scss';
import AuthLayout from '../../../../../components/AuthLayout';
import {keys, INPUT_CONSTANTS, formFields, buttonGroup} from '../../formfeilds';
import TextInput from '../../../../../components/TextInput';
import SeparatorComponent from '../../../../../components/Sparator';
import {withRouter} from 'react-router-dom';
import strings from '../../../../../globalization';

const Mobile = props => {
  const {onClick, onChangeState} = props;
  const handleClick = action => {
    console.info('handleClick...', action);
    onClick(action);
    // const { history } = this.props;
    // history.push(action ? action : "signin");
  };
  const onChange = (action, data) => {
    // console.info('onChange...', action);
    onChangeState(action, data);
    // this.setState((prevState) => {
    //   return {
    //     ...prevState,
    //     [action]: data,
    //   };
    // });
  };

  const getInputType = element => {
    switch (element.type) {
      case INPUT_CONSTANTS.PASSWORD:
      case INPUT_CONSTANTS.NUMBER:
      case INPUT_CONSTANTS.TEXT: {
        return (
          <TextInput
            label={element.label}
            lblDirection="row"
            onChange={update => onChange(element.field, update)}
            isEdit={true}
            type={element.type}
            placeholder={element.placeholder}
            //inputStyle={styles.inputTextAddress}
            required={element.required}
            name={element.field}
            // error={this.state[element.field].error}
            // value={this.state[element.field]}
          />
        );
      }
      default: {
        console.warn('case not handled', element.type);
        return null;
      }
    }
  };
  return (
    <div className={styles.loginContainer}>
      <div className={styles.logoContainer}>
        <img src="./static/images/Logo/logo.png" alt="Logo" />
        <h2>{keys.title}</h2>
        <h4>{keys.subTitle}</h4>
      </div>
      <div className={styles.formContainer}>
        <p>{strings.resetPassword}</p>
        {formFields.map(field => getInputType(field))}
        {/* <div className={styles.forgotPasswordRow}>
          <span>
            <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
            <label for="vehicle1"> {strings.rememberMe}</label>
          </span>
          <a href="">{strings.forgotPassword}</a>
        </div> */}
      </div>
      <button
        onClick={() => handleClick('reset')}
        className={styles.loginButton}>
        {strings.submit}
      </button>
    </div>
  );
};
export default withRouter(Mobile);
