import styles from './styles.module.scss';
import {AgGridReact} from 'ag-grid-react';
import calendarBlue from '../../../../../assets/images/calendar-blue-icon/image.png';
import dimensions from '../../../../../util/dimensions-util';
import moment from 'moment';
import 'react-datasheet/lib/react-datasheet.css';
import PermitsHeader from '../../../../../components/PermitsHeader';
import 'react-daterange-picker/dist/css/react-calendar.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import './styles.css';
import React, {useState} from 'react';
import TextInput from '../../../../../components/TextInput';
import Button from '../../../../../components/Button';
import {withRouter} from 'react-router-dom';
import strings from '../../../../../globalization';
import DateRangePicker from 'react-daterange-picker';
import TimePicker from 'rc-time-picker';
import Flex from '../../../../../components/Flex';

const {vw: widthConstant, vh: heightConstant} = dimensions;

function Desktop({onClick, onChange, passAuditReport, dates, searchText}) {
  const [showDate, setShowDate] = useState(false);
  const rowStyle = {border: 'none', cursor: 'pointer'};
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return {background: '#f6f6f6', border: 'none'};
    }
  };
  // const formatDate = date => moment(date).format('MMM Do YY');
  const onSelect = dates => {
    console.log('dates', dates);
    onChange('date', {
      startDate: moment(dates.start).format('MM-DD-YYYY'),
      endDate: moment(dates.end).format('MM-DD-YYYY'),
      dates,
    });
    toggleDate();
  };
  const toggleDate = () => {
    setShowDate(!showDate);
  };
  const formatDate = date => moment(date).format('MMM Do YY');
  const onTabClick = () => {};
  const handleClear = () => {
    setShowDate(false);
    onClick('clearFilters');
  };
  const columnDefs = [
    {
      headerName: 'Agency Name',
      field: 'agencyName',
      headerClass: styles.rowHeader,
      flex: 0.4,
      resizable: true,
    },

    {
      headerName: 'Pass Category',
      field: 'passCategory',
      headerClass: styles.rowHeader,
      flex: 0.8,
      resizable: true,
    },
    {
      headerName: 'Action',
      field: 'action',
      headerClass: styles.rowHeader,
      flex: 0.7,
      resizable: true,
      cellStyle: {'border-right': '1px solid #979797'},
    },

    {
      headerName: 'Number Of Passes',
      field: 'numberOfPasses',
      headerClass: styles.rowHeader,
      flex: 0.4,
      resizable: true,
    },
    {
      headerName: 'Date',
      field: 'date',
      headerClass: styles.rowHeader,
      flex: 0.6,
      resizable: true,
    },
  ];

  return (
    <div>
      <PermitsHeader
        title="Pass Audit Report"
        onTabClick={onTabClick}
        showTabs={false}
      />
      <div className={styles.agencyContainer}>
        <div className={styles.header}>
          <div className={styles.left}>
            <Flex direction="row">
              <div className={styles.dateWrapper}>
                <div onClick={toggleDate} className={styles.inputDate}>
                  {' '}
                  <div>
                    {' '}
                    {dates
                      ? formatDate(dates.start) + '-' + formatDate(dates.end)
                      : strings.ADMIN.dateRange}
                  </div>{' '}
                  <img src={calendarBlue} className={styles.calendar} alt="C" />
                </div>
                {showDate ? (
                  <div className={styles.datePicker}>
                    <DateRangePicker
                      numberOfCalendars={1}
                      selectionType="range"
                      onSelect={onSelect}
                      className={styles.dateRange}
                      value={dates}
                      singleDateRange={true}
                    />
                  </div>
                ) : null}
              </div>
            </Flex>
          </div>
          <div className={styles.right}>
            <div className={styles.inputSearch}>
              <TextInput
                onChange={text => {
                  onChange('search', text);
                }}
                isEdit={true}
                type={'text'}
                value={searchText}
                inputStyle={styles.inputText}
                showSeacrh={true}
                placeholder="Search"
              />
            </div>

            <div onClick={handleClear} className={styles.clearLabel}>
              Clear
            </div>
            <Button
              onClick={() => onClick('apply')}
              buttonStyle="primary"
              label={strings.ADMIN.apply}
              className={styles.applyBtn}></Button>
          </div>
        </div>

        <div className={['ag-theme-balham', styles.tableWrapper].join(' ')}>
          <AgGridReact
            columnDefs={columnDefs}
            defaultColDef={{sortable: true}}
            rowClass={[styles.gridRowClass]}
            rowData={passAuditReport}
            rowStyle={rowStyle}
            getRowStyle={getRowStyle}
            rowHoverHighlight={true}
            pagination={true}
            paginationPageSize={10}
            rowHeight={50 * heightConstant}
            headerHeight={50 * heightConstant}
            domLayout="autoHeight"></AgGridReact>
        </div>
        <div className={styles.bottomLabel}>
          {' '}
          Showing {passAuditReport?.length}
        </div>
      </div>
    </div>
  );
}
export default withRouter(Desktop);
