import React from 'react';
import styles from './styles.module.scss';
import {operatorImages} from '../../assets/images';
import Flex from '../Flex';
import {CSVLink} from 'react-csv';
import moment from 'moment';
import strings from '../../globalization';
const CSVPopup = ({onClose, data, headers}) => {
  return (
    <Flex className={styles.container} direction="column">
      <img src={operatorImages.successIcon} alt="U" />
      <p className={styles.message}>{strings.CSVpopup.message}</p>
      <Flex className={styles.button}>
        <button className={styles.cancelBtn} onClick={onClose}>
          {strings.CSVpopup.cancel}
        </button>

        <CSVLink
          className={styles.okayBtn}
          data={data}
          filename={`permits-${moment().format(`DD-MM-YYYY`)}.csv`}
          headers={headers}
          onClick={onClose}>
          {strings.CSVpopup.okay}
        </CSVLink>
      </Flex>
    </Flex>
  );
};
export default CSVPopup;
