import React, { useState, useEffect } from 'react';
import styles from './Desktop.module.scss';
import './styles.css';
import strings from '../../../../../../globalization';
import { withRouter } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import Select from 'react-select';
import moment from 'moment';
import PermitsHeader from '../../../../../../components/PermitsHeader';
import 'react-daterange-picker/dist/css/react-calendar.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import dimensions from '../../.../../../../../../util/dimensions-util';
import { customStyles } from './customStyles';
import DateRangePicker from 'react-daterange-picker';
import TextInput from '../../../../../../components/TextInput';
import Button from '../../../../../../components/Button';

import calendarBlue from '../../../../../../assets/images/calendar-blue-icon/image.png';
import downloadIcon from '../../../../../../assets/images/download-icon/download-icon.png';

import TimePicker from 'rc-time-picker';
import SannaLogo from '../../../../../../components/SannaLogo';

const { vw: widthConstant, vh: heightConstant } = dimensions;
const tabs = [
  {
    label: 'Vehicle Movement',
    id: 1,
    route: 'vehicle',
  },
  // {
  //   label: 'Person Movement',
  //   id: 2,
  //   route: 'person',
  // },
  {
    label: 'Vehicle Entry & Exit',
    id: 3,
    route: 'vehicle-entry-exit',
  },
];

const Desktop = props => {
  const {
    onClick,
    onChange,
    statuses,
    selectedStatus,
    selectedDropDownStatus,
    searchText,
    dates,
    startDate,
    endDate,
    perPage,
    permitList,
    startTime,
    endTime,
    totalCount,
    selectedType,
  } = props;
  console.log('vehicle list', permitList);
  console.log('selected type:', selectedStatus);

  const vehicleColumnDefs = [
    {
      headerName:
        permitList && permitList[0]?.requestType === 1
          ? 'Exit timestamp'
          : permitList && permitList[0]?.requestType === 0
            ? 'Entry timestamp'
            : selectedType + ' timestamp',
      field: 'requestType',
      headerClass: styles.rowHeader,
      width: 210 * widthConstant,
      autoHeight: true,
      resizable: true,
      wrapText: true,
      cellStyle: { display: 'flex', alignItems: 'center', lineHeight: 1.5 },
      cellRenderer: params => {
        if (params.value !== undefined) {
          const { data } = params;
          const { createdDate } = data;
          return `<div className=${styles.sessionTime}>
                ${createdDate
              ? moment(createdDate).format('DD/MM/YYYY HH:mm')
              : null
            }
              </div>`;
        }
      },
    },
    {
      headerName: 'Permit reference ID',
      field: 'permitReferenceId',
      headerClass: styles.rowHeader,
      width: 250 * widthConstant,
      autoHeight: true,
      resizable: true,
      wrapText: true,
    },
    {
      headerName: 'Vehicle Registration Number',
      field: 'vehicleRegistrationNumber',
      headerClass: styles.rowHeader,
      width: 300 * widthConstant,
      // flex: 1,
      autoHeight: true,
      resizable: true,
      wrapText: true,
      cellStyle: { display: 'flex', alignItems: 'center' },
      cellRenderer: params => {
        if (params?.data !== undefined) {
          const { data } = params;
          const { vehicleRegistrationNumber } = data;
          return `<div className=${styles.sessionTime}>
                ${vehicleRegistrationNumber}
              </div>`;
        }
      },
    },
    {
      headerName: 'Vehicle Owner Name',
      field: 'vehicleOwnerName',
      headerClass: styles.rowHeader,
      width: 300 * widthConstant,
      // flex: 1,
      resizable: true,
      autoHeight: true,
      wrapText: true,
      cellStyle: { display: 'flex', alignItems: 'center', lineHeight: 1.5 },
      cellRenderer: params => {
        if (params.value !== undefined) {
          const { data } = params;
          const { vehicleOwnerName } = data;
          return `<div className=${styles.sessionTime}>
                ${vehicleOwnerName ? vehicleOwnerName : null}
              </div>`;
        }
      },
    },
    {
      headerName: 'Gate',
      field: 'gateName',
      headerClass: styles.rowHeader,
      width: 230 * widthConstant,
      // flex: 1,
      resizable: true,
      autoHeight: true,
      wrapText: true,
      cellStyle: { display: 'flex', alignItems: 'center', lineHeight: 1.5 },
      cellRenderer: params => {
        if (params.value !== undefined) {
          const { data } = params || {};
          const { gateName } = data || {};
          return `<div className=${styles.sessionTime}>
                ${gateName ? gateName : '-'}
              </div>`;
        }
      },
    },
    {
      headerName: 'Credits',
      field: 'numberOfPasses',
      headerClass: styles.rowHeader,
      width: 130 * widthConstant,
      // flex: 1,
      resizable: true,
      autoHeight: true,
      wrapText: true,
      cellStyle: { display: 'flex', alignItems: 'center', lineHeight: 1.5 },
      cellRenderer: params => {
        if (params.data !== undefined) {
          const { data } = params || {};
          const { numberOfPasses } = data || {};
          return `<div className=${styles.sessionTime}>
                ${numberOfPasses ? numberOfPasses : '-'}
              </div>`;
        }
      },
    },
    {
      headerName: 'Reconciliation Status',
      field: 'reconciliationStatus',
      headerClass: styles.rowHeader,
      width: 220 * widthConstant,
      // flex: 1,
      resizable: true,
      autoHeight: true,
      wrapText: true,
      cellStyle: { display: 'flex', alignItems: 'center', lineHeight: 1.5 },
      cellRenderer: params => {
        if (params.value !== undefined) {
          const { data } = params;
          const { reconciliationStatus } = data;
          return `<div className=${styles.sessionTime}>
                ${reconciliationStatus
              ? reconciliationStatus.replace(/_/g, ' ')
              : null
            }
              </div>`;
        }
      },
    },
  ];

  const personColumnDefs = [
    {
      headerName:
        permitList && permitList[0]?.requestType === 1
          ? 'Exit timestamp'
          : permitList && permitList[0]?.requestType === 0
            ? 'Entry timestamp'
            : selectedType + ' timestamp',
      field: 'requestType',
      headerClass: styles.rowHeader,
      width: 210 * widthConstant,
      autoHeight: true,
      resizable: true,
      wrapText: true,
      cellStyle: { display: 'flex', alignItems: 'center', lineHeight: 1.5 },
      cellRenderer: params => {
        if (params.value !== undefined) {
          const { data } = params;
          const { createdDate } = data;
          return `<div className=${styles.sessionTime}>
                ${createdDate
              ? moment(createdDate).format('DD/MM/YYYY HH:mm')
              : null
            }
              </div>`;
        }
      },
    },
    {
      headerName: 'Permit reference ID',
      field: 'permitReferenceId',
      headerClass: styles.rowHeader,
      width: 250 * widthConstant,
      autoHeight: true,
      resizable: true,
      wrapText: true,
    },
    {
      headerName: 'Person Name',
      field: 'name',
      headerClass: styles.rowHeader,
      width: 300 * widthConstant,
      // flex: 1,
      autoHeight: true,
      resizable: true,
      wrapText: true,
      cellStyle: { display: 'flex', alignItems: 'center' },
      cellRenderer: params => {
        if (params?.data !== undefined) {
          const { data } = params;
          const { name } = data;
          return `<div className=${styles.sessionTime}>
                ${name}
              </div>`;
        }
      },
    },
    {
      headerName: 'Person RFID',
      field: 'personRFID',
      headerClass: styles.rowHeader,
      width: 320 * widthConstant,
      // flex: 1,
      resizable: true,
      autoHeight: true,
      wrapText: true,
      cellStyle: { display: 'flex', alignItems: 'center', lineHeight: 1.5 },
      cellRenderer: params => {
        if (params.value !== undefined) {
          const { data } = params;
          const { personRFID } = data;
          return `<div className=${styles.sessionTime}>
                ${personRFID ? personRFID : null}
              </div>`;
        }
      },
    },

    {
      headerName: 'Credits',
      field: 'createdDate',
      headerClass: styles.rowHeader,
      width: 130 * widthConstant,
      // flex: 1,
      resizable: true,
      autoHeight: true,
      wrapText: true,
      cellStyle: { display: 'flex', alignItems: 'center', lineHeight: 1.5 },
      cellRenderer: params => {
        if (params.value !== undefined) {
          const { data } = params;
          const { numberOfPasses } = data;
          return `<div className=${styles.sessionTime}>
                ${numberOfPasses ? numberOfPasses : '-'}
              </div>`;
        }
      },
    },
    {
      headerName: 'Reconciliation Status',
      field: 'reconciliationStatus',
      headerClass: styles.rowHeader,
      width: 240 * widthConstant,
      flex: 1,
      resizable: true,
      cellStyle: { display: 'flex', alignItems: 'center', lineHeight: 1.5 },
      cellRenderer: params => {
        if (params.value !== undefined) {
          const { data } = params;
          const { reconciliationStatus } = data;
          return `<div className=${styles.sessionTime}>
                ${reconciliationStatus
              ? reconciliationStatus.replace(/_/g, ' ')
              : null
            }
              </div>`;
        }
      },
    },
  ];

  const [showDate, setShowDate] = useState(false);
  const [startTimePanel, setStartTimePanel] = useState(null);
  const [endTimePanel, setEndTimePanel] = useState(null);

  const [focusedInput, setFocusedInput] = useState(null);

  const onSelect = dates => {
    onChange('date', {
      startDate: moment(dates.start).format('MM-DD-YYYY'),
      endDate: moment(dates.end).format('MM-DD-YYYY'),
      dates,
    });
    toggleDate();
  };
  const toggleDate = () => {
    setShowDate(!showDate);
  };

  const [gridApi, setGridApi] = useState(null);
  const onGridReady = params => {
    setGridApi(params.api);
    onChange('gridAPI', params.api);
  };
  const updateTable = () => {
    onChange('updateTable', null);
  };

  useEffect(() => {
    console.log(gridApi);
    if (gridApi && totalCount > 10) {
      console.log('changing to normal');
      gridApi.setDomLayout('normal');
    } else if (gridApi) {
      console.log('changing to autoHeight');
      gridApi.setDomLayout('autoHeight');
    }
  }, [totalCount]);

  useEffect(() => {
    console.log(gridApi);
    updateTable();
  }, [gridApi, startDate, endDate, selectedStatus]);

  const onTabClick = id => {
    switch (id) {
      case 1:
        onClick('statusRoute', { route: 'vehicle', id: id });
        break;
      case 2:
        onClick('statusRoute', { route: 'person', id: id });
        break;
      case 3:
        onClick('statusRoute', { route: 'vehicle-entry-exit', id: id });
        break;

      default:
        break;
    }
  };

  const formatDate = date => moment(date, 'MM-DD-YYYY').format('MMM Do YY');
  const rowStyle = { border: 'none', cursor: 'pointer' };
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: '#f6f6f6', border: 'none' };
    }
  };

  const handleClear = () => {
    setShowDate(false);
    onClick('clearFilters');
  };
  const handleInput = text => {
    setShowDate(false);
    onChange('search', text);
  };
  const handleSelect = name => {
    setShowDate(false);
    onChange('statusChange', name);
  };
  const handleButtonClick = () => {
    setShowDate(false);
    onClick('apply');
  };

  const getColumns = () => {
    if (selectedStatus === 'vehicle') {
      return vehicleColumnDefs;
    } else {
      return personColumnDefs;
    }
  };
  return (
    <div className={styles.agencyContainer}>
      <PermitsHeader
        title={
          selectedStatus === 'vehicle' ? 'Vehicle Movement' : 'Person Movement'
        }
        onTabClick={onTabClick}
        tabs={tabs}
        activeTab={selectedStatus === 'vehicle' ? 1 : 2}
      />
      <div className={styles.bodyContainer}>
        <div className={styles.header}>
          <div className={styles.left}>
            <div className={styles.agencyNameDropdown}>
              <Select
                options={statuses}
                getOptionLabel={option => option.displayName}
                getOptionValue={option => option.id}
                placeholder={'Select type'}
                styles={customStyles}
                value={statuses.filter(
                  option => option?.id === selectedDropDownStatus?.id,
                )}
                onChange={handleSelect}
              />
            </div>
            <div className={styles.dateWrapper}>
              <div onClick={toggleDate} className={styles.inputDate}>
                <div>
                  {dates
                    ? formatDate(dates.start) + '-' + formatDate(dates.end)
                    : strings.ADMIN.dateRange}
                </div>
                <img src={calendarBlue} className={styles.calendar} alt="C" />
              </div>
              {showDate ? (
                <div className={styles.datePicker}>
                  <DateRangePicker
                    numberOfCalendars={1}
                    selectionType="range"
                    onSelect={onSelect}
                    className={styles.dateRange}
                    value={dates}
                    singleDateRange={true}
                    focusedInput={focusedInput}
                    onFocusChange={e => setFocusedInput(e)}
                  />
                </div>
              ) : null}
            </div>
            <div className={styles.timeWrapper}>
              <div>
                <TimePicker
                  // use12Hours
                  addon={panel => setStartTimePanel(panel)}
                  showSecond={false}
                  // FIXME: Change these
                  value={startTime}
                  onChange={time => {
                    onChange('startTime', time);
                    if (
                      startTimePanel?.state?.currentSelectPanel === 'minute'
                    ) {
                      startTimePanel.close();
                    }
                  }}
                  className={styles.timepicker}
                  placeholder={`Start Time`}
                  format={'HH:mm'}
                />
                <TimePicker
                  // use12Hours
                  showSecond={false}
                  // FIXME: Change these
                  addon={panel => setEndTimePanel(panel)}
                  value={endTime}
                  onChange={time => {
                    onChange('endTime', time);
                    if (endTimePanel?.state?.currentSelectPanel === 'minute') {
                      endTimePanel.close();
                    }
                  }}
                  className={styles.timepicker}
                  placeholder={`End Time`}
                  format={'HH:mm'}
                />
              </div>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.inputSearch}>
              <TextInput
                onChange={handleInput}
                isEdit={true}
                type={'text'}
                value={searchText}
                inputStyle={styles.inputText}
                showSeacrh={true}
                placeholder="Search"
              />
            </div>
            <div onClick={handleClear} className={styles.clearLabel}>
              {strings.ADMIN.clear}
            </div>
            <Button
              onClick={handleButtonClick}
              label={strings.ADMIN.apply}
              className={styles.applyBtn}></Button>
            <div>
              {permitList && permitList.length > 0 ? (
                <img
                  className={styles.downloadIcon}
                  onClick={() => onClick('loadCSV')}
                  src={downloadIcon}
                  alt="icon"
                />
              ) : null}
            </div>
          </div>
        </div>
        <div
          className={[
            'ag-theme-balham vehicleMovementTable',
            styles.tableWrapper,
            totalCount > 10
              ? styles.tableWrapperNormal
              : styles.tableWrapperAutoHeight,
          ].join(' ')}>
          <AgGridReact
            rowStyle={rowStyle}
            getRowStyle={getRowStyle}
            rowHoverHighlight={true}
            columnDefs={getColumns()}
            rowHeight={60 * heightConstant}
            headerHeight={60 * heightConstant}
            defaultColDef={{ sortable: true }}
            rowClass={[styles.gridRowClass]}
            pagination={true}
            rowModelType={'infinite'}
            paginationPageSize={perPage}
            onGridReady={onGridReady}
            cacheBlockSize={perPage}
            domLayout="autoHeight"></AgGridReact>
        </div>
        {/* <div className={styles.bottomLabel}>
          Showing {filterAgencies.length}
        </div> */}
      </div>
      <div className={styles.SannaLogo}>
        <SannaLogo />
      </div>
    </div>
  );
};
export default withRouter(Desktop);
