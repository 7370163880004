import React, {useState} from 'react';

import File from '../../assets/images/file-icon/image.png';
import styles from './styles.module.scss';

import util from '../../util';
import Flex from '../Flex';
import ALLOWED_FORMATS from '../../constants/allowed-formats';

export const CustomUpload = ({
  labelText,
  placeholderText,
  required = false,
  handleUpload,
  value,
  maxFiles = 3,
  maxSize = 2,
  onChange,
  allowedFormats,
  onBlur,
  tabIndex,
  multiple = false,
  uploadBoxClassNames,
  error: err,
  placeholderStyle,
  containerClassNames,
  isPhoto,
  disabled,
  name,
}) => {
  const [id, setId] = useState(util.generateUniqueId());
  const [error, setError] = useState(null);
  const inputRef = React.useRef();
  const handleCancel = (e, index) => {
    e.preventDefault();
    let localState = value.filter((elem, idx) => idx !== index);
    if (inputRef) {
      inputRef.current.value = '';
    }
    
    onChange(localState);
  };
  const getSubstring = text => {
    return `${text.substr(0, 8)}...`;
  };
  const upload = event => {
    console.log(
      'fileSize, maxSize(MB), maxFiles',
      event.target.files[0].size / 1024 / 1024,
      maxSize,
      maxFiles,
    );
    console.log('fileType', event.target.files[0].type);

    let isFileValid = checkFileTypeAndAllowedFormats(event);

    setError('');
    if (value.length >= maxFiles) {
      setError(`Cannot upload more than ${maxFiles} files`);
      handleUpload('', `Cannot upload more than ${maxFiles} file(s)`, inputRef);
    } else if (event.target.files[0].size / 1024 / 1024 > maxSize) {
      setError(`Cannot upload more than ${maxSize} MB`);
      handleUpload('', `Cannot upload more than ${maxSize} MB`, inputRef);
    } else if (!isFileValid) {
      console.log('file invalid');
      setError(`File type is not allowed`);
      handleUpload('', `File type is not allowed`, inputRef);
    } else {
      handleUpload(event.target.files, null, inputRef);
    }
  };
  const checkFileTypeAndAllowedFormats = event => {
    let fileType = event.target.files[0].type;
    let formats = getAllowedFormats();

    if (formats.length === 0) {
      return true;
    }
    if (formats.includes(fileType)) {
      return true;
    } else {
      return false;
    }
  };

  const getAllowedFormats = () => {
    let formats = [];
    allowedFormats?.map(type => {
      if (type === ALLOWED_FORMATS.PDF) {
        formats.push('application/pdf');
      }
      if (type === ALLOWED_FORMATS.IMAGES) {
        formats.push('image/png');
        formats.push('image/jpeg');
        formats.push('image/jpg');
      }
    });
    return formats.join(', ');
  };

  const getAllowedFormatsText = () => {
    let formats = [];
    allowedFormats?.map(type => {
      if (type === ALLOWED_FORMATS.PDF) {
        formats.push('.pdf');
      }
      if (type === ALLOWED_FORMATS.IMAGES) {
        formats.push('.png');
        formats.push('.jpg');
        formats.push('.jpeg');
      }
    });
    return formats.join(', ');
  };

  return (
    <div style={styles.containerStyles}>
      <Flex className={styles.textboxContainer}>
        <p className={styles.label}>{labelText}</p>
        {required ? <p style={{color: 'red'}}>*</p> : null}
      </Flex>

      <div className={containerClassNames} tabIndex={tabIndex}>
        <label
          bordered
          htmlFor={`uploadHTMLElement-${name}-${id}`}
          className={[styles.uploadBox, uploadBoxClassNames].join(' ')}
          style={
            disabled ? {backgroundColor: '#FAFAFA', color: '#c4c6ca'} : null
          }
          //   onClick={selectFile}
        >
          {value && value.length ? (
            <Flex direction="row" className={[styles.container].join(' ')}>
              {value.map((elem, index) => (
                <Flex
                  direction="row"
                  key={index}
                  className={styles.fileContainer}>
                  <p className={styles.fileName}>
                    {elem.fileName ? getSubstring(elem.fileName) : ''}
                  </p>

                  {!disabled && (
                    <button
                      className={styles.button}
                      onClick={e => {
                        handleCancel(e, index);
                      }}>
                      <p>x</p>
                    </button>
                  )}
                </Flex>
              ))}
            </Flex>
          ) : (
            <p className={styles.placeholderStyles} style={placeholderStyle}>
              {/* {placeholderText} ({getAllowedFormats()}) */}
              {placeholderText}
            </p>
          )}
          <div>
            <img src={File} className={styles.icon} />
          </div>
        </label>
        {allowedFormats && isPhoto ? (
          <p className={(styles.allowedFormats, styles.photoAllowedFormats)}>
            (Allowed Formats: {getAllowedFormatsText()})
          </p>
        ) : null}
        <input
          tabIndex={tabIndex}
          data-date-inline-picker="true"
          type="file"
          // className={styles.input}
          // defaultValue={value}
          id={`uploadHTMLElement-${name}-${id}`}
          required={required}
          multiple={multiple}
          onBlur={onBlur}
          name={name}
          accept={getAllowedFormats()}
          onChange={event => upload(event)}
          ref={inputRef}
          disabled={disabled}
        />
      </div>
      {/* <p className={styles.error}>{error}</p> */}
      <Flex direction="column" style={{width: '100%', height: '1rem'}}>
        {allowedFormats && !isPhoto ? (
          <p className={styles.allowedFormats}>
            Allowed Formats: {getAllowedFormatsText()}
          </p>
        ) : null}

        <p className={styles.error}>{err}</p>
      </Flex>
    </div>
  );
};

export default CustomUpload;
