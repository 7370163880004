import React, {useEffect, useRef, useState} from 'react';
import Flex from '../../Flex';
import SannaLogo from '../../SannaLogo';
import styles from './styles.module.scss';

import first from '../../../assets/images/Landing/solutions/1.png';
import second from '../../../assets/images/Landing/solutions/2.png';
import third from '../../../assets/images/Landing/solutions/3.png';
import fourth from '../../../assets/images/Landing/solutions/4.png';
import fifth from '../../../assets/images/Landing/solutions/5.png';

import {motion, useViewportScroll} from 'framer-motion';
import clsx from 'clsx';
import {style} from 'react-dom-factories';

let interval;
export const Solutions = () => {
  const cards = [
    {
      id: 1,
      src: first,
      text: 'Access Control Systems',
      subText:
        'Vehicle congestion is a major hurdle at every port’s entry gates. Vehicles wait in queues for hours to get the permits approved.',
      bullets: [
        'Integrating hardware technologies like RFID, ANPR, and CCTV cameras with software application modules.',
        'Presenting the security personnel with our technological tools to assist them to maintain security.',
        'Simplified process for the customers to raise and track the entry requests using a web/mobile application.',
      ],
    },
    {
      id: 2,
      src: second,
      text: 'Perimeter security',
      subText:
        'Due to the long perimeters of the ports, it becomes a challenge to coordinate and maintain security as per the mandates of the port.',
      bullets: [
        'Reducing manual interference to ensure no trespassing by using PIDS (Perimeter Intrusion Detection Systems)',
        'Easily integrating the modules with the existing security systems and command centres.',
        'Easy-to-deploy & highly accurate solutions; as PIDS is highly effective and works on simple optical fibres.',
      ],
    },
    {
      id: 3,
      src: third,
      text: 'Geo-tracking',
      subText:
        'Keeping track of all the vehicles in the port at a given time can be hectic but it is an important task to maintain security. ',
      bullets: [
        'Real-time position and movement tracking of each vehicle entering the port.',
        'Meticulous mapping of the port area via geo-fencing and categorizing it into different zones with different levels of clearances.',
        'Notifying the command centre whenever the vehicle enters the restricted zone.',
      ],
    },
    {
      id: 4,
      src: fourth,
      text: 'Weighbridge Automation',
      subText:
        'Tracking the material moving out of the port can also be integrating Smart Weighbridges with the existing security systems.',
      bullets: [
        'Setting up weighbridges at each entry and exit gate of the port & integrating it with the existing surveillance system.',
        'Electronic record keeping of the weight of the truck while entering and exiting the port.',
        'Notification to the authority when the weight of the truck exceeds the permissible value.',
      ],
    },
    {
      id: 5,
      src: fifth,
      text: 'Container Tracking',
      subText:
        'Tracking the containers in advance using GPS tools gives the advantage of planning the subsequent processes accordingly.',
      bullets: [
        'Real-time shipment tracking with industry-standard accuracy in ETAs.',
        'Berth status tracking and auto-allocation of the berths according to the ETA of the shipment.',
        'Detailed lists of port timelines and schedules of each ship. ',
      ],
    },
  ];
  const variants = {
    hidden: {opacity: 0, y: '1vh'},
    show: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.1,
      },
    },
    // transition: { staggerChildren: 0.5 },
    exit: {opacity: 0, y: '1vh'},
  };
  const itemA = {
    hidden: {opacity: 0, y: '2vh'},
    show: {opacity: 1, y: 0},
  };

  const [currentCard, setCurrentCard] = useState(cards[0]);

  useEffect(() => {
    if (currentCard) {
      startFlipping();
    }

    return () => clearInterval(interval);
  }, []);

  const startFlipping = () => {
    interval = setInterval(() => {
      setCurrentCard(currentIcon => cards[currentIcon.id % cards.length]);
    }, 5000);
  };

  const graph = useRef(null);

  useEffect(() => {
    rotateCircle();
  }, []);
  useEffect(() => {
    // rotateGraph();
  }, [currentCard]);

  // const {scrollY} = window;
  //   return scrollY;

  const rotateGraph = () => {
    console.log('rotate.current.graph');
    console.log('currentCard', currentCard?.id);
    const circleGraph = graph.current;
    console.log('curcleGraph', circleGraph);

    circleGraph.style.transform = `rotate(-${(360 / 5) * currentCard?.id}deg)`;
  };

  const rotateCircle = ang => {
    const ciclegraph = graph.current;
    const circleElements = ciclegraph?.childNodes;
    // remove svg from here

    let angle = ang || 360 - 90;
    let dangle = 360 / circleElements.length;

    for (let i = 0; i < circleElements.length; i++) {
      let circle = circleElements[i];
      angle += dangle;
      circle.style.transform = `rotate(${angle}deg) translate(${
        ciclegraph.clientWidth / 2
      }px) rotate(-${angle}deg)`;
    }
  };

  return (
    <div className={styles.solutionsContainer}>
      <Overlay />
      <div className={styles.content}>
        {/* TODO */}
        <div className={styles.leftContainer}>
          <svg
            id="circle-svg"
            viewBox="0 0 1026 1026"
            fill="none"
            aria-hidden="true"
            className={styles.animateSpinSlow}>
            <path
              d="M1025 513c0 282.77-229.23 512-512 512S1 795.77 1 513 230.23 1 513 1s512 229.23 512 512Z"
              stroke="#D4D4D4"
              strokeOpacity="0.7"></path>
            <path
              d="M513 1025C230.23 1025 1 795.77 1 513"
              stroke="url(#DBZ2x_crX9o0sotz6Y9G--gradient-1)"
              strokeLinecap="round"></path>
            <defs>
              <linearGradient
                id="DBZ2x_crX9o0sotz6Y9G--gradient-1"
                x1="1"
                y1="513"
                x2="1"
                y2="1025"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#06b6d4"></stop>
                <stop offset="1" stopColor="#06b6d4" stopOpacity="0"></stop>
              </linearGradient>
            </defs>
          </svg>
          <div className={styles.ciclegraph} ref={graph}>
            {cards.map((el, idx) => (
              <div
                onClick={() => {
                  clearInterval(interval);
                  setCurrentCard({...el});
                  // startFlipping();
                }}
                className={clsx(styles.circle)}>
                <div
                  className={clsx(
                    styles.innerCircle,
                    currentCard?.id === el?.id ? styles.animate : null,
                  )}
                  style={{
                    backgroundColor:
                      currentCard?.id === el?.id ? '#000000' : '#00000030',
                  }}>
                  <img
                    src={el?.src}
                    alt={el?.alt || 'icon'}
                    style={{
                      height: '2rem',
                      width: '2rem',
                      objectFit: 'contain',
                      opacity: currentCard?.id === el?.id ? 1 : 0.6,
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.rightContainer}>
          <h2 className={styles.header}>Solution Overview</h2>
          <motion.div
            key={currentCard.id}
            variants={variants}
            initial="hidden"
            animate="show"
            exit="exit"
            transition={{duration: 0.5}}
            className={styles.loopContainer}>
            <p className={styles.subHeader}>{currentCard.text}</p>
            <p className={styles.subHeaderText}>{currentCard.subText}</p>
            {currentCard.bullets.map((el, idx) => (
              <motion.div
                variants={itemA}
                className={styles.bullets}
                transition={{duration: 0.5}}
                key={`bullet-${idx}`}>
                <CheckIcon
                  style={{
                    marginRight: '0.4rem',
                    marginTop: '0.2rem',
                    flexShrink: 0,
                  }}
                />
                <p>{el}</p>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

const CheckIcon = props => {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" fill="white" fill-opacity="0.3" />
      <path
        d="M11.3371 5.8183C11.2616 5.82049 11.19 5.85118 11.1373 5.90387L6.69284 10.2268L4.86395 8.44791C4.83717 8.42078 4.8051 8.39913 4.7696 8.3842C4.73411 8.36928 4.69591 8.3614 4.65725 8.36102C4.61858 8.36064 4.58023 8.36776 4.54443 8.38198C4.50863 8.39619 4.47611 8.41721 4.44877 8.4438C4.42142 8.4704 4.39981 8.50203 4.3852 8.53685C4.37058 8.57167 4.36326 8.60897 4.36365 8.64658C4.36404 8.68419 4.37215 8.72134 4.38749 8.75586C4.40283 8.79038 4.4251 8.82158 4.45299 8.84763L6.48736 10.8263C6.54186 10.8793 6.61577 10.9091 6.69284 10.9091C6.7699 10.9091 6.84381 10.8793 6.89832 10.8263L11.5483 6.30359C11.5903 6.26388 11.619 6.21276 11.6306 6.15693C11.6422 6.1011 11.6362 6.04316 11.6134 5.9907C11.5906 5.93824 11.552 5.89371 11.5027 5.86294C11.4534 5.83218 11.3957 5.81662 11.3371 5.8183Z"
        fill="white"
      />
    </svg>
  );
};

const Overlay = () => {
  return <div className={styles.overlay} />;
};

{
}
