import React from 'react';
import styles from './styles.module.css';
import RegistrationLeftContainer from '../../../components/RegistrationLeftContainer';
import RegistrationFormCard from '../../../components/RegistrationFormCard';
import {loginImages} from '../../../assets/images';
import PopupContainer from '../../../components/PopupContainer';
import ViewRenderer from './views/index';

// function RegistrationSuccess(props) {
class RegistrationSuccess extends React.Component<> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClickHandler = () => {
    const {history} = this.props;
    history.push('/signin');
  };

  getProps = () => {
    return {
      onClick: this.onClickHandler,
    };
  };
  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }

  // render(){
  //   const { location } = this.props;
  //   return (
  //     <div className={styles.container}>
  //       <RegistrationLeftContainer />
  //       <RegistrationFormCard
  //         onClick={this.onClick}
  //         buttonLabel="Go back to Login"
  //         header={location.state?.message}
  //       >
  //         <div className={styles.body}>
  //           <img className={styles.successIcon} src={loginImages.successIcon} alt="Logo" />
  //           <div className={styles.message1}>Successfully Submitted</div>

  //           <div className={styles.message2}>
  //             {" "}
  //             Your reference number is <br />
  //             <span className={styles.id}>{location.state?.id} </span>, please keep a note of it.
  //           </div>
  //           <div className={styles.message3}>
  //             You will receive a message with reference details to your mobile <br />
  //             number. You can check the status of the application{" "}
  //             <span className={styles.here}>here</span>
  //           </div>
  //         </div>
  //       </RegistrationFormCard>
  //     </div>
  //   );
  // }
}

export default RegistrationSuccess;
