import React, {Component, useEffect} from 'react';
import styles from './styles.module.scss';
import PopupContainer from '../../../components/PopupContainer';
import SimplePopup from '../../../components/SimplePopup';

import {adminImages, loginImages, commonImages} from '../../../assets/images';
import moment from 'moment';
import ViewRenderer from './views';
import DeptMaterialPopup from '../../../components/DeptMaterialPopup';
import AgencyRquestsPopup from '../../../components/AgencyRquestsPopup';
import AgencyPersonDetails from '../../../components/AgencyPersonDetails';
import AgencyMaterialDetails from '../../../components/AgencyMaterialDetails';

import {API} from '../../../requests';
import strings from '../../../globalization';
import Spinner from '../../../components/Spinner';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Flex from '../../../components/Flex';
import util from '../../../util';
import {AgGridReact} from 'ag-grid-react';
import dimensions from '../../../util/dimensions-util';
import './styles.css';
import {customStyles} from './customStyles';

import Select from 'react-select';
import {entrySummary, rejectedReports} from './data';
import VehicleMovementPersonDetails from '../../../components/VehicleMovementPersonDetails';
import VehicleMovementPermitsDetailsPopup from '../../../components/VehicleMovementPermitsDetailsPopup';

const {vw: widthConstant, vh: heightConstant} = dimensions;

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  ERROR: 'error',
  SPINNER_POPUP: 'spinner-popup',
  PERMIT_DETAILS: 'permitDetails',
};

const STATUSES = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  ALL: 'ALL',
};

class PersonMovementReports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rejectionList: [],
      filteredRejectionList: [],
      startDate: moment().subtract(2, 'days'),
      startTime: moment().startOf('day'),
      endDate: moment(),
      endTime: moment().endOf('day'),
      dates: {
        start: moment().subtract(2, 'days').format('MM-DD-YYYY'),
        end: moment().format('MM-DD-YYYY'),
      },
      filterAgencies: [],
      searchText: '',
      statuses: [
        {
          id: 1,
          name: STATUSES.APPROVED,
          label: STATUSES.APPROVED,
        },
        {
          id: 2,
          name: STATUSES.REJECTED,
          label: STATUSES.REJECTED,
        },
        {
          id: 0,
          name: STATUSES.ALL,
          label: STATUSES.ALL,
        },
      ],
      CHAList: [
        {
          id: 0,
          name: `CHA 1`,
          label: `CHA 1`,
        },
        {
          id: 1,
          name: `CHA 2`,
          label: `CHA 2`,
        },
        {
          id: 2,
          name: `CHA 3`,
          label: `CHA 3`,
        },
      ],
      selectedCHA: '',
      gates: [],
      selectedGate: '',
      gatesTypeList: [
        {name: 'All', id: -1, status: 'ACTIVE'},
        {name: 'Entry', id: 0, status: 'ACTIVE'},
        {name: 'Exit', id: 1, status: 'ACTIVE'},
      ],
      selectedGateType: {name: 'Entry', id: 0, status: 'ACTIVE'},
    };
  }

  componentDidMount() {
    this.getData();
    this.fetchCompanyDetails();
    this.fetchGatesList();
  }

  applyFilters = () => {
    const {searchText, rejectionList} = this.state;
    const finalFilterData =
      rejectionList.filter(element =>
        Object.values(element)
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()),
      ) || rejectionList;
    this.setState({
      filteredRejectionList: finalFilterData,
    });
  };

  fetchGatesList = async () => {
    API.fetchGatesList()
      .then(res => {
        this.setState({
          gates: res.data,
        });
      })
      .catch(err => {
        console.log('err', err);
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };
  getData = async () => {
    this.showSpinner();
    const {
      startDate,
      endDate,
      startTime,
      endTime,
      selectedStatus,
      selectedCHA,
      selectedGate,
      selectedGateType,
    } = this.state;

    if (!startTime || !endTime || !startDate || !endDate) {
      this.showErrorPopup(`Date and Time parameters cannot be empty!`);
      return;
    }
    let params = {
      startDate: `${moment(startDate).format('DD-MM-YYYY')} ${moment(
        startTime,
      ).format('HH:mm')}`,
      endDate: `${moment(endDate).format('DD-MM-YYYY')} ${moment(
        endTime,
      ).format('HH:mm')}`,
      status: selectedStatus?.id || 0, // 0 if all
      agencyId: selectedCHA?.id || 0, // 0 if all
      gateId: selectedGate?.id || 0,
      requestType: selectedGateType?.id, // -1 if all
      pageSize: 10000,
      pageNumber: 0,
    };

    console.log('params...', params, selectedGateType);
    API.fetchPersonMovementSummary(params)
      .then(res => {
        this.setState(
          {
            rejectionList: res.data,
            filteredRejectionList: res.data,
            popup: undefined,
          },
          this.applyFilters,
        );
      })
      .catch(err => {
        console.log('err', err);
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };
  showErrorPopup = errorMsg => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message:
          errorMsg || `Failed to fetch data. Please refresh and try again.`,
        messageStyle: {color: '#E67717'},

        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
          },
        ],
        onClose: this.closePopup,
      },
    });
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  fetchCompanyDetails = () => {
    API.fetchActiveCHADetails()
      .then(res => {
        let modifiedCHAList = res.data.map(el => {
          return {
            id: el.id,
            name: el.name,
            label: el.name,
          };
        });
        modifiedCHAList.push({
          id: 0,
          name: 'Select All',
          label: 'Select All',
        });
        this.setState({
          CHAList: modifiedCHAList,
        });
      })
      .catch(err => {
        console.log('err', err);
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };

  onChangeHandler = (action, data) => {
    switch (action) {
      case 'status': {
        this.setState({
          selectedStatus: data,
        });
        break;
      }
      case 'CHA': {
        this.setState({
          selectedCHA: data,
        });
        console.log('cha...', data);
        break;
      }
      case 'gate': {
        this.setState({
          selectedGate: data,
        });
        console.log('gate...', data);
        break;
      }
      case 'gateType': {
        this.setState({
          selectedGateType: data,
        });
        console.log('gate...', data);
        break;
      }

      case 'startTime': {
        this.setState({
          startTime: data,
        });
        break;
      }
      case 'endTime': {
        this.setState({
          endTime: data,
        });
        break;
      }

      case 'date': {
        const {startDate, endDate, dates} = data;
        this.setState({
          startDate,
          endDate,
          dates,
        });
        break;
      }

      case 'search': {
        console.info('data..', data);
        this.setState({
          searchText: data,
        });

        break;
      }

      default: {
        console.info('case not handled', action);
      }
    }
  };

  onClickHandler = (action, data) => {
    const {history, location} = this.props;

    switch (action) {
      case 'apply': {
        this.getData();
        break;
      }
      case 'clearFilters': {
        this.resetData();
        break;
      }

      case 'rowClick': {
        console.info('rowClick called...', data);
        this.permitDetailsRowClick(data);

        break;
      }
      case 'document-view': {
        console.log('in document view redirection case');
        history.push({
          pathname: location.pathname + '/document-view',
          //   pathname: location.pathname + '/add-new-person',
          state: {background: location, url: data},
        });

        break;
      }
      default: {
        console.info('case not handled', action);
      }
    }
  };

  getPermitDetailsInfo = data => {
    let permitDetails = data?.permitDetails || null;
    // let vehicle = data?.vehicleDetails || null;
    let vehicle = data?.vehicleDetails || null;

    let person = data?.personDetails || null;
//    let material = data?.permitDetails?.material || [];
    return (
      <div className={styles.requestInfo}>
      
        {/* VEHICLE */}
        {vehicle?.id && (
          <RequestCard
            key={'vehicle'}
            header={strings.ADMIN.POP_UP.vehicleDetails}>
            {this.getVehicleDetails(vehicle)}
          </RequestCard>
        )}

        {/* DRIVER */}
        {/* {driver?.id && (
          <RequestCard header={strings.ADMIN.POP_UP.driverDetails}>
            <VehicleMovementPersonDetails
              onClick={this.onClickHandler}
              {...driver}
            />
          </RequestCard>
        )} */}

        {/* PERSON */}
        {person && (
          <RequestCard
            key={'person'}
            header={strings.ADMIN.POP_UP.personDetails}>
            <AgencyPersonDetails onClick={this.onClickHandler} {...person} />
          </RequestCard>
        )}
      </div>
    );
  };
  
  permitDetailsRowClick = data => {
    let params = {
      gateLevelPersonAuditId: data?.id,
    };
    API.fetchPersonMovementDetails(params)
      .then(res => {
        console.log('res.data fetch person movement details', res.data);
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.PERMIT_DETAILS,
            // FIXME: COmmented code?
            // heading: `Permit Details - ${data?.referenceId}`,
            heading: `Permit Details`,
            message: this.getPermitDetailsInfo(res?.data),
            onClose: () => this.closePopup(),
            data: res?.data?.permitDetails,
            showPermitMetadata: res?.data?.permitDetails?.id ? true : false,
            buttonContainer: styles.buttonContainer,
            buttons: [],
          },
        });
      })
      .catch(err => {
        console.log('err', err);
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };  

  resetData = () => {
    this.setState(
      {
        searchText: '',
        selectedStatus: '',
        selectedCHA: '',
        selectedGate: '',
        startDate: moment().subtract(2, 'days'),
        startTime: moment().startOf('day'),
        endDate: moment(),
        endTime: moment().endOf('day'),
        dates: {
          start: moment().subtract(2, 'days').format('MM-DD-YYYY'),
          end: moment().format('MM-DD-YYYY'),
        },
        selectedGateType: {name: 'Entry', id: 0, status: 'ACTIVE'},
      },

      this.getData,
    );
  };

  getCSVDownloadHeaders = () => {
    return [
      {label: 'Gate ID', key: 'readerName'},
      {label: 'vehicleNumber', key: 'vehicleRegistrationNumber'},
      {label: 'Status at the Gate', key: 'status'},
      {label: 'CHA Name', key: 'companyName'},
      {label: 'Driver Name', key: 'driverName'},
      {label: 'Driver Company Name', key: 'driverCompanyName'},
      {label: 'Reason (if-rejected)', key: 'error'},
      {label: 'Timestamp at Gate', key: 'createdDate'},
    ];
  };

  getCSVDownloadData = () => {
    const {filteredRejectionList} = this.state;
    return filteredRejectionList.map(el => {
      return {
        readerName: el.readerName,
        vehicleRegistrationNumber: el.vehicleRegistrationNumber,
        status: el.status,
        companyName: el.companyName,
        error: el.error,
        createdDate: moment(el.createdDate).format('MMMM Do YY HH:mm'),
        driverName: el?.driverName || '-',
        driverCompanyName: el?.driverCompanyname || '-',
      };
    });
  };

  getProps = () => {
    const {
      searchText,
      selectedAgency,
      selectedStatus,
      startDate,
      endDate,
      dates,
      startTime,
      endTime,
      rejectionList,
      statuses,
      CHAList,
      selectedCHA,
      gates,
      selectedGate,
      filteredRejectionList,
      selectedGateType,
      gatesTypeList,
    } = this.state;
    return {
      onClick: this.onClickHandler,
      onChange: this.onChangeHandler,
      searchText,
      selectedAgency,
      selectedStatus,
      startDate,
      endDate,
      dates,
      startTime,
      endTime,
      rejectionList: filteredRejectionList,
      statuses,
      CHAList,
      selectedCHA,
      gates,
      selectedGate,
      selectedGateType,
      gatesTypeList,
      downloadData: this.getCSVDownloadData(),
      downloadHeaders: this.getCSVDownloadHeaders(),
    };
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };

  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <SimplePopup {...popup} />;
      }
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      
      case POPUP_CONSTANTS.SPINNER: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.PERMIT_DETAILS: {
        return <VehicleMovementPermitsDetailsPopup {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}



const RequestCard = props => {
  const {header} = props;

  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardheader}>{header}</div>
      {props.children}
    </div>
  );
};

export default withRouter(PersonMovementReports);
