import styles from './styles.module.scss';
import {AgGridReact} from 'ag-grid-react';
import {operatorImages} from '../../../../../assets/images';
import dimensions from '../../../../../util/dimensions-util';
import Flex from '../../../../../components/Flex';
import 'react-datasheet/lib/react-datasheet.css';
import PermitsHeader from '../../../../../components/PermitsHeader';
import 'react-daterange-picker/dist/css/react-calendar.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {customStyles} from './customStyles';
import React, {useState} from 'react';
import TextInput from '../../../../../components/TextInput';
import Button from '../../../../../components/Button';
import {withRouter} from 'react-router-dom';
import Select from 'react-select';

const {vw: widthConstant, vh: heightConstant} = dimensions;

function Desktop({
  onClick,
  businessDetail,
  tableActionHandler,
  selectedStatus,
  searchText,
  statuses,
}) {
  const rowStyle = {border: 'none', cursor: 'pointer'};
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return {background: '#f6f6f6', border: 'none'};
    }
  };
  //console.log('myRole', businessDetail);
  const onTabClick = () => {};
  const editRow = (event, data) => {
    onClick('edit', data);
  };
  const deleteRow = (event, data) => {
    onClick('delete', data);
  };
  const columnDefs = [
    {
      headerName: 'ID',
      field: 'id',
      headerClass: styles.rowHeader,
      flex: 0.5,
      resizable: true,
    },

    {
      headerName: 'English Name',
      field: 'name',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
    },
    {
      headerName: 'Hindi Name',
      field: 'name_hindi',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
    },

    {
      headerName: 'Status',
      field: 'status',
      headerClass: styles.rowHeader,
      flex: 0.7,
      resizable: true,
      cellStyle: {'border-right': '1px solid #979797'},
    },
    {
      headerName: 'Actions',
      field: 'actions',
      headerClass: styles.rowHeader,
      flex: 0.7,
      resizable: true,
      cellRendererFramework: ({data}) => {
        return (
          <Flex className={styles.actionImg}>
            <img
              onClick={e => {
                editRow(e, data);
              }}
              src={operatorImages.editIcon}
              alt="edit"
              className={styles.edit}
            />
            <img
              onClick={e => {
                deleteRow(e, data);
              }}
              src={operatorImages.deleteIcon}
              alt="delete"
              className={styles.delete}
            />
          </Flex>
        );
      },
    },
  ];

  return (
    <div>
      <PermitsHeader
        title="Business Details"
        onTabClick={onTabClick}
        showTabs={false}
      />
      <div className={styles.agencyContainer}>
        <div className={styles.filterWrapper}>
          <div className={styles.left}>
            <div className={styles.dropdown}>
              <Select
                options={statuses}
                value={statuses?.filter(
                  option => option?.id === selectedStatus,
                )}
                getOptionLabel={option => option?.name}
                getOptionValue={option => option?.id}
                placeholder={'Status'}
                styles={customStyles}
                onChange={({id}) => {
                  tableActionHandler('status', id);
                }}
              />
            </div>
          </div>

          <div className={styles.left}>
            <div className={styles.inputSearch}>
              <TextInput
                onChange={update => {
                  tableActionHandler('search', update);
                }}
                isEdit={true}
                type={'text'}
                value={searchText}
                inputStyle={styles.inputText}
                showSeacrh={true}
                placeholder="Search by Name"
              />
            </div>
            <div
              onClick={() => tableActionHandler('clearFilter')}
              className={styles.clearLabel}>
              Clear
            </div>
            <Button
              onClick={() => tableActionHandler('addNewBusinessDetail')}
              buttonStyle="primary"
              label={'Add'}
              className={styles.button}></Button>
          </div>
        </div>
        <div className={['ag-theme-balham', styles.tableWrapper].join(' ')}>
          <AgGridReact
            columnDefs={columnDefs}
            defaultColDef={{sortable: true}}
            rowClass={[styles.gridRowClass]}
            rowData={businessDetail}
            rowStyle={rowStyle}
            getRowStyle={getRowStyle}
            pagination={true}
            paginationPageSize={10}
            rowHoverHighlight={true}
            rowHeight={50 * heightConstant}
            headerHeight={50 * heightConstant}
            domLayout="autoHeight"></AgGridReact>
        </div>
        <div className={styles.bottomLabel}>
          {' '}
          Showing {businessDetail?.length}
        </div>
      </div>
    </div>
  );
}
export default withRouter(Desktop);
