import React, {Component} from 'react';

import strings from '../../globalization';
import PopupContainer from '../../components/PopupContainer';
import Spinner from '../../components/Spinner';
import styles from './styles.module.scss';
import Popup from '../../components/SimplePopup';

import {withRouter} from 'react-router-dom';
import ViewRenderer from './views';
import {commonImages, operatorImages, adminImages} from '../../assets/images';
import OtpPopup from '../../components/OtpPopup';
import RegistrationStatus from '../../components/RegistrationStatus';

import {API} from '../../requests/index';
import util from '../../util';
import moment from 'moment';

import {getFormData} from './data';

const POPUP_CONSTANTS = {
  STANDARD_POPUP: 'standard-popup',
  ERROR_POPUP: 'error-popup',
  SPINNER_POPUP: 'spinner-popup',
  USER_NOT_CONFIRM: 'user-not-confirm',
  SIMPLE_POPUP: 'simple-popup',
  PRIVACY_POPUP: 'privacy-popup',
  OTP_POPUP: 'otp-popup',
  STATUS_POPUP: 'status-popup',
  PERSON_INVOICE_POPUP: 'person-invoice-popup',
  VEHICLE_INVOICE_POPUP: 'vehicle-invoice-popup',
};

const STATUS_CONSTANTS = {
  PAYMENT_PENDING: 'Payment Pending',
  PAYMENT_COMPLETED: 'Registration Submitted',
  PAYMENT_FAILED: 'Payment Failed ',
  OPERATOR_ADMIN_APPROVED: 'Registration Pending',
  OPERATOR_ADMIN_REJECTED: 'Registration Unsuccessful',
  DEPARTMENT_ADMIN_APPROVED: 'Registration Approved',
  DEPARTMENT_ADMIN_REJECTED: 'Registration Unsuccessful',
  ISSUED: 'RFID Issued',
  HOLD: 'RFID Hold',
  SURRENDER: 'RFID Surrender',
  UPDATED: 'Details Updated',
  UPDATE_REJECTED: 'Update Rejected',
};

const DISABLE_EDIT_STATUSES = [
  'OPERATOR_ADMIN_APPROVED',
  'DEPARTMENT_ADMIN_APPROVED',
  'HOLD',
  'SURRENDER',
];

class UploadPoliceNOC extends Component {
  constructor(props) {
    super(props);

    let preparedObject = {};
    this.formData = getFormData();
    this.formData.data.forEach(obj => {
      preparedObject[obj.stateField] = {value: obj.stateDefault, error: null};
      if (obj.optionsField) {
        preparedObject[obj.optionsField] = [];
      }
    });

    this.state = {
      popup: null,
      referenceId: '',
      RFIDNumber: null,
      personPhoto: null,
      status: null,
      returnedObject: null,
      requestType: null,
      otpVerified: false,
      ...preparedObject,
    };
  }

  componentDidMount() {
    this.getPreLoginToken();
  }

  getPreLoginToken = () => {
    API.getPreLoginToken()
      .then(response => {
        console.log(response);
        API.setPreLoginToken(response);
      })
      .catch(error => {
        //this.showErrorPopup('');
        console.log(error);
      });
  };
  handleErrors = () => {
    console.log('Handling errors');
    let err = false;
    const update = {};
    const {personType} = this.state;
    let requiredBool = false;

    let formData = this.formData.data;
    for (let i = 0; i < formData.length; i++) {
      let element = formData[i];
      requiredBool = false;

      let error = util.validate(this.state[element.stateField].value, {
        ...element,
        required: requiredBool || element.required,
      });

      if (error) {
        err = true;
        update[element.stateField] = {
          ...this.state[element.stateField],
          error: error,
        };
      } else {
        update[element.stateField] = {
          ...this.state[element.stateField],
          error: null,
        };
      }
    }
    this.setState({
      ...update,
    });
    return err;
  };

  getPopupContent = () => {
    const {
      popup,
      phoneNumber,
      currentInvoiceData,
      personDetails,
      vehicleDetails,
    } = this.state;

    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.OTP_POPUP: {
        return (
          <OtpPopup
            onClose={this.closePopup}
            showSuccessPopup={() =>
              this.showMobileVerifySuccessPopup(
                'Your mobile number was successfully verified',
              )
            }
            showErrorPopup={errorMessage => {
              this.showErrorPopup(errorMessage);
            }}
            phoneNumber={this.state?.currentOtpVerifiactionObject.phoneNumber}
            onOtpVerifySuccess={this.onOtpVerifySuccess}
            message={
              strings.message + phoneNumber.substr(phoneNumber.length - 4)
            }
            resendOTP={this.generateOtp}
          />
        );
      }
      case POPUP_CONSTANTS.STATUS_POPUP: {
        return <RegistrationStatus {...popup} />;
      }
      default: {
        console.warn('case not handled', popup.type);
        return null;
      }
    }
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };
  showSuccessPopup = successMessage => {
    const {history} = this.props;
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: successMessage,
        onClose: this.closePopup,
        headingImage: operatorImages.successIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: () => {
              history.push('/signin');
              this.closePopup();
            },
            outline: true,
          },
        ],
      },
    });
  };
  showMobileVerifySuccessPopup = successMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: successMessage,
        onClose: this.closePopup,
        headingImage: operatorImages.successIcon,
        buttons: [
          {
            text: 'Continue',
            onClick: () => {
              this.closePopup();
              this.getPerson();
            },
            outline: true,
          },
        ],
      },
    });
  };

  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  showOtpPopup = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.OTP_POPUP,
      },
    });
  };

  closePopup = () => {
    this.setState({
      popup: null,
    });
  };
  onClickHandler = action => {
    const {history} = this.props;
    switch (action) {
      case 'goBackToLogin': {
        history.push('/signin');
        break;
      }
      default: {
        break;
      }
    }
  };

  handleBackClick = () => {
    const {history} = this.props;
    history.push('/signin');
  };

  handleClickHerePress = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: strings.loginDetailsSent,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
          },
        ],
        onClose: this.closePopup,
        messageStyle: {
          color: '#202428',
          fontWeight: 'normal',
        },
      },
    });
  };
  onChangeHandler = (field, value) => {
    console.log('field', field, 'value', value);
    this.reset();
    let preparedStateObject = {};
    if (field === 'RFIDNumber') {
      preparedStateObject[field] = value;
    } else {
      preparedStateObject[field] = {
        value,
      };
    }
    this.setState(preparedStateObject, function () {
      console.log('this.state', this.state);
    });
  };

  reset = () => {
    this.setState({
      otpVerified: false,
    });
  };

  finalSubmit = () => {
    let error = this.handleErrors();
    this.showSpinner();
    if (error) {
      this.closePopup();
      return;
    }
    this.submitFormData()
      .then(res => {
        console.log('Res', res);
        // success and failure popups needs to show here
        //  this.showUpdateStatusPopup();
        this.showSuccessPopup(
          'Your Police NOC document and validity details have been updated!',
        );
      })
      .catch(errors => {
        const errorMsg = errors.response
          ? errors.response.data.message
          : errors.message;
        this.showErrorPopup(
          'There was some issue with the upload of your documents. Please Try again',
        );
        console.log(errors);
        console.log(errorMsg);
      });
  };

  submitFormData = async () => {
    const {personDetails} = this.state;
    this.showSpinner();
    const policeNOCValidity = this.state?.policeNOCValidity.value
      ? util.getDateTimeString(this.state.policeNOCValidity.value, 'mmddyyyy')
      : undefined;
    const preparedData = {
      id: personDetails?.id,
      policeNOCValidity: policeNOCValidity,
      policeNOCCertificate:
        this.state.policeNOCCertificate.value.length > 0
          ? this.state.policeNOCCertificate.value[0].fileUrl
          : '',
    };
    let res;
    res = await API.savePoliceDoc(preparedData);
    console.log('preparedData', preparedData);
    return res.data;
  };

  onSubmit = () => {
    const {RFIDNumber} = this.state;
    console.log(this.state);
    this.showSpinner();

    if (!RFIDNumber) {
      this.showErrorPopup('Please enter RFID Number');
    } else {
      console.log('this.state.RFIDNumber', RFIDNumber);
      this.verifyPerson();
    }
  };

  openOTPPopUp = () => {
    this.setState(
      {
        currentOtpVerifiactionObject: {
          field: 'phoneNumber',
          phoneNumber: this.state['phoneNumber'],
          verifiedNumberFields: {
            ...this.state?.currentOtpVerifiactionObject?.verifiedNumberFields,
          },
        },
      },
      () => {
        this.generateOtp(this.state.currentOtpVerifiactionObject.phoneNumber);
      },
    );
  };

  generateOtp = async phoneNumber => {
    console.log(phoneNumber);
    this.showSpinner();
    if (!phoneNumber || phoneNumber.toString().length !== 10) {
      this.showErrorPopup('Please enter a valid phone number', phoneNumber);
    } else {
      const result = util.validatePhoneNumber(phoneNumber);
      if (!result) {
        this.showErrorPopup('Please enter a valid phone number');
        return;
      }
      await API.generateOtp({phoneNumber: `+91${phoneNumber}`})
        .then(res => {
          this.showOtpPopup();
        })
        .catch(error => {
          console.log(error);
          // const errorMsg = error.response
          const errorMsg = util.getErrorMessage(error);
          // const errorMsg =
          //   'Invalid Phone Number. Please enter a valid phone number.';
          this.showErrorPopup(errorMsg);
        });
    }
  };

  onOtpVerifySuccess = isSuccess => {
    const {currentOtpVerifiactionObject} = this.state;
    if (true) {
      this.setState({
        otpVerified: isSuccess,
        currentOtpVerifiactionObject: {
          ...this.state.currentOtpVerifiactionObject,
          otpVerified: isSuccess,
          verifiedNumberFields: {
            ...this.state.currentOtpVerifiactionObject.verifiedNumberFields,
            [currentOtpVerifiactionObject.field]: isSuccess,
          },
        },
      });
    }
    console.log(this.state);
  };

  statusCard = data => {
    const {
      heading,
      icon,
      buttons,
      content,
      referenceId,
      status,
      remarks,
      reason,
      invoiceData,
      type,
    } = data;

    return (
      <>
        <div className={styles.blueText}>Reference Number - {referenceId}</div>

        <div className={styles.statusCardContainer}>
          <div className={styles.heading}>{heading}</div>
          <div className={styles.cardDetails}>
            <img className={styles.icon} alt="U" src={icon} />
            <div>{content}</div>
          </div>
          {status === 'OPERATOR_ADMIN_REJECTED' ||
          status === 'DEPARTMENT_ADMIN_REJECTED' ||
          status === 'UPDATE_REJECTED' ? (
            <div>
              <div className={styles.rejectedMessage}>
                <div>Remarks</div>
                <div className={styles.message}>{remarks}</div>
              </div>
              <div className={styles.rejectedMessage}>
                <div>Reason</div>
                <div className={styles.message}>{reason}</div>
              </div>
            </div>
          ) : null}
          {buttons && buttons.length > 0 ? (
            <div className={[styles.buttonContainer].join(' ')}>
              {buttons.map(button => {
                const {text, buttonStyles, ...remainingProps} = button;
                return (
                  <button
                    className={[styles.button, buttonStyles].join(' ')}
                    key={'popup-button-' + text}
                    {...remainingProps}>
                    {text}
                  </button>
                );
              })}
            </div>
          ) : null}
        </div>
        {invoiceData && invoiceData?.length > 0 ? (
          <div>{this.showInvoiceList(invoiceData, type)}</div>
        ) : null}
      </>
    );
  };

  getVehicleContent = () => {
    const {vehicleDetails} = this.state;
    const {
      vehicleRegistrationNumber,
      vehicleInsuranceNumber,
      vehicleCategory,
      status,
      vehicleType,
    } = vehicleDetails;
    return (
      <>
        <div className={styles.contentContainer}>
          <div>
            <b>Vehicle Number:</b> {vehicleRegistrationNumber}
          </div>
          <div>
            <b>Vehicle Insurance Number:</b> {vehicleInsuranceNumber}
          </div>
          <div>
            <b>Vehicle Category: </b>
            {vehicleCategory?.name}
          </div>
          <div>
            <b>Vehicle Type: </b>
            {vehicleType?.name}
          </div>
          <div>
            <b>Status:</b>{' '}
            <span className={styles.bolder}>{STATUS_CONSTANTS[status]}</span>
          </div>
        </div>
      </>
    );
  };

  showStatusPopup = data => {
    const {heading} = data;
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.STATUS_POPUP,
        heading: heading,
        message: this.statusCard(data),
        data: {},
        onClose: this.closePopup,
        buttonContainer: styles.buttonContainer,
        buttons: [
          {
            text: 'Close',
            buttonStyles: styles.backButton,
            onClick: () => {
              this.closePopup();
            },
          },
        ],
      },
    });
  };

  verifyPerson = () => {
    const {referenceId, otpVerified, RFIDNumber} = this.state;

    if (otpVerified) {
      this.getPerson();
    } else {
      API.verifyPersonByRFIDNumber(RFIDNumber)
        .then(personRes => {
          console.log('personRes.data', personRes.data);
          this.setState({
            phoneNumber: personRes.data?.phoneNumber.slice(3),
            referenceId: personRes.data?.referenceId,
            popup: null,
          });
          this.openOTPPopUp();
        })
        .catch(error => {
          const errorMsg = util.getErrorMessage(error);
          console.log(errorMsg);
          this.showErrorPopup(errorMsg);
        });
    }
  };

  preSignUrl = async url => {
    const data = {
      url: url,
      expiryTime: 30,
    };
    await API.preSignedURL(data)
      .then(res => {
        console.log('res.data', res.data?.preSignedUrl);
        this.setState({
          personPhoto: res.data?.preSignedUrl ? res.data?.preSignedUrl : true,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  getPerson = () => {
    const {RFIDNumber, referenceId} = this.state;
    console.log('person RFIDNumber:', RFIDNumber);
    this.showSpinner();
    API.getPersonByReferenceId(referenceId)
      .then(personRes => {
        console.log('personRes.data', personRes.data);
        this.setState(
          {
            personDetails: personRes.data,
            popup: null,
          },
          () => this.preSignUrl(personRes?.data?.photo),
        );
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        console.log(errorMsg);
        this.showErrorPopup(errorMsg);
      });
  };

  handleUpload = (field, files, error, inputRef) => {
    if (error) {
      this.showErrorPopup(error);
      return;
    }
    this.showSpinner();
    if (files === null) {
      throw new Error('File does not exist');
    }

    let filesArray = [];
    for (let i = 0, numFiles = files.length; i < numFiles; i++) {
      filesArray.push(files[i]);
    }

    console.log('filesArray', filesArray);

    // FIXME: Fix real uploading thing.

    let promises = filesArray.map(file => {
      const data = new FormData();
      data.append('s3FolderName', 'company');
      data.append('file', file);
      data.append('allowedExtensions', ['Pk8b8jAuPC1DF', 'JakNVgcBVZUPG', 'JPw7fCgDTT6AEG', 'PCrAriI079CNG']);
      return API.uploadFilesToDpt(data).then(response => {
        console.log('Response Here', response.data);
        const url = response.data?.fileUrl;
        return {fileUrl: url, fileName: file.name};
      });
    });

    return Promise.all(promises)
      .then(response => {
        console.log('[field] - this.state[field]', this.state[field]);
        console.log('response', response);
        this.setState(prevState => ({
          popup: null,
          [field]: {
            ...prevState[field],
            value: [...prevState[field].value, ...response],
            error: null,
          },
        }));
      })
      .catch(error => {
        console.log('error', error);
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;

        this.setState({
          popup: null,
        });
        this.showErrorPopup(errorMsg);
        //throw new Error(errorMsg);
        console.log(errorMsg);
        if (inputRef) {
          inputRef.current.value = '';
        }
        return null;
      });
  };
  getProps = () => {
    return {
      onSubmit: this.onSubmit,
      finalSubmit: this.finalSubmit,
      backClick: this.handleBackClick,
      onChangeHandler: this.onChangeHandler,
      stateData: this.state,
      handleUpload: this.handleUpload,
      formFields: this.formData.data,
    };
  };

  render() {
    const {popup} = this.state;
    const props = this.getProps();

    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...props} />
      </>
    );
  }
}

// export default withRouter(PersonCards);
export default withRouter(UploadPoliceNOC);
