import React, {useState, useEffect} from 'react';
import strings from '../../globalization';
import Flex from '../Flex';
import styles from './styles.module.scss';
const CountDownTimer = ({seconds, onTimerComplete, onShowResendButton}) => {
  const [secs, setSecs] = useState(seconds);
  useEffect(() => {
    console.log(secs);
    if (secs > 0) {
      if (secs === 100) {
        onShowResendButton(true);
      }
      const interval = setInterval(() => {
        setSecs(prevSeconds => prevSeconds - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
    if (secs === 0) {
      onTimerComplete(true);
    }
  }, [secs]);

  // time format
  const formatTime = time => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return <>{`${Math.floor(seconds / 60)}`}</>;
};

export default CountDownTimer;
