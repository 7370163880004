import util from '../../../../../../util';
// import strings from '../../../../globalization';

export const dummyData = [
  {
    id: util.generateUniqueId(),
    date: new Date(),
    referenceNumber: 'ABCDREFERENCE',
    permitType: 'Man Vehicle',
    department: 'Engineering',
    status: 'Approved',
  },
  {
    id: util.generateUniqueId(),
    date: new Date(),
    referenceNumber: 'ABCDREFERENCE',
    permitType: 'Man Vehicle',
    department: 'Engineering',
    status: 'Approved',
  },
  {
    id: util.generateUniqueId(),
    date: new Date(),
    referenceNumber: 'ABCDREFERENCE',
    permitType: 'Man Vehicle',
    department: 'Engineering',
    status: 'Approved',
  },
  {
    id: util.generateUniqueId(),
    date: new Date(),
    referenceNumber: 'ABCDREFERENCE',
    permitType: 'Man Vehicle',
    department: 'Engineering',
    status: 'Pending',
  },
  {
    id: util.generateUniqueId(),
    date: new Date(),
    referenceNumber: 'ABCDREFERENCE',
    permitType: 'Man Vehicle',
    department: 'Engineering',
    status: 'Approved',
  },
  {
    id: util.generateUniqueId(),
    date: new Date(),
    referenceNumber: 'ABCDREFERENCE',
    permitType: 'Man Vehicle',
    department: 'Engineering',
    status: 'Approved',
  },
  {
    id: util.generateUniqueId(),
    date: new Date(),
    referenceNumber: 'ABCDREFERENCE',
    permitType: 'Man Vehicle',
    department: 'Engineering',
    status: 'Approved',
  },
];
