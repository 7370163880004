import React, {useState} from 'react';
import Flex from '../../../../../components/Flex';
import PermitsHeader from '../../../../../components/PermitsHeader';
import styles from './styles.module.scss';

import userIcon from '../../../../../assets/images/user-icon/image@3x.png';
import home from '../../../../../assets/images/agency-blue/image@3x.png';
import bgIcon from '../../../../../assets/images/background/image.png';
import FormElement from '../../../../../components/FormElement';
import editIconGray from '../../../../../assets/images/profile/edit-icon-gray/image@2x.png';
import editIconActive from '../../../../../assets/images/profile/edit-icon-active/image@2x.png';
import rectangleIcon from '../../../../../assets/images/profile/rectangle-icon/image1@2x.png';
import SannaLogo from '../../../../../components/SannaLogo';
import eyeIcon from '../../../../../assets/images/eye/image@3x.png';
import eyeIconClosed from '../../../../../assets/images/eye-hide/image@3x.png';
import TextInput from '../../../../../components/TextInput';

const TAB_CONSTANTS = {
  PROFILE: 'profile',
  AGENCY: 'agency',
  CHANGE_PASSWORD: 'change-password',
};

const ROLE_MAPPER = {
  COMPANY_ADMIN: 'Company Admin',
  SIGNATORY: 'Signatory',
  COMPANY_SUPER_ADMIN: 'Company Super Admin',
};

export default function Desktop({
  activePage,
  onClick,
  user,
  company,
  role,
  onChange,
  profileFormData,
  agencyFormData,
  resetPasswordFormData,
  profileState,
  agencyState,
  resetPasswordState,
}) {
  console.log('company...', company, resetPasswordState);

  const handleClick = data => {
    console.log('data', data);
    onClick('button-click', data);
  };
  const getPageContent = () => {
    switch (activePage) {
      case TAB_CONSTANTS.PROFILE: {
        return (
          <div>
            <ProfileCard
              user={user}
              role={role}
              profileFormData={profileFormData}
              stateData={profileState}
              company={company}
              onChange={(action, data) => onChange('profile', action, data)}
              onClick={onClick}
            />
          </div>
        );
      }
      case TAB_CONSTANTS.AGENCY: {
        return (
          <div>
            <AgencyCard
              company={company}
              role={role}
              agencyFormData={agencyFormData}
              stateData={agencyState}
              onChange={(action, data) => onChange('agency', action, data)}
              onClick={onClick}
            />
          </div>
        );
      }
      case TAB_CONSTANTS.CHANGE_PASSWORD: {
        return (
          <div>
            <ChangePassword
              formData={resetPasswordFormData}
              company={company}
              stateData={resetPasswordState}
              onChange={(action, data) =>
                onChange('change-password', action, data)
              }
              onClick={onClick}></ChangePassword>
          </div>
        );
      }
      default: {
        console.warn('case not handled...', activePage);
      }
    }
  };
  return (
    <div>
      <div className={styles.headerContainer}>
        <PermitsHeader title="Profile" activeTab={1} showTabs={false} />
      </div>
      <Flex direction="row" className={styles.mainContainer}>
        <Flex direction="column" className={styles.tabsContainer}>
          <div className={styles.imgContainer}>
            <div className={styles.imgIcon}>
              <img src={rectangleIcon} className={styles.centerIcon} alt="s" />
            </div>
            <div className={styles.label}>{company?.name}</div>
          </div>

          <div
            onClick={() => handleClick(TAB_CONSTANTS.PROFILE)}
            className={styles.button}
            style={
              activePage === TAB_CONSTANTS.PROFILE ? {color: '#002C94'} : null
            }>
            <div
              className={[
                styles.editIconWrapper,
                activePage === TAB_CONSTANTS.PROFILE ? styles.active : '',
              ].join(' ')}>
              {activePage === TAB_CONSTANTS.PROFILE ? (
                <img src={editIconActive} alt="U" />
              ) : (
                <img src={editIconGray} alt="U" />
              )}
            </div>
            Profile Details
          </div>
          {Object.keys(company).length > 0 && (
            <div
              onClick={() => handleClick(TAB_CONSTANTS.AGENCY)}
              className={[styles.button, styles.lastButton].join(' ')}
              style={
                activePage === TAB_CONSTANTS.AGENCY ? {color: '#002C94'} : null
              }>
              <div
                className={[
                  styles.editIconWrapper,
                  activePage === TAB_CONSTANTS.AGENCY ? styles.active : '',
                ].join(' ')}>
                {activePage === TAB_CONSTANTS.AGENCY ? (
                  <img src={editIconActive} alt="U" />
                ) : (
                  <img src={editIconGray} alt="U" />
                )}
              </div>
              Agency Details
            </div>
          )}
          <div
            onClick={() => handleClick(TAB_CONSTANTS.CHANGE_PASSWORD)}
            className={[styles.button, styles.lastButton].join(' ')}
            style={
              activePage === TAB_CONSTANTS.CHANGE_PASSWORD
                ? {color: '#002C94'}
                : null
            }>
            <div
              className={[
                styles.editIconWrapper,
                activePage === TAB_CONSTANTS.CHANGE_PASSWORD
                  ? styles.active
                  : '',
              ].join(' ')}>
              {activePage === TAB_CONSTANTS.CHANGE_PASSWORD ? (
                <img src={editIconActive} alt="U" />
              ) : (
                <img src={editIconGray} alt="U" />
              )}
            </div>
            Change password
          </div>
        </Flex>
        <Flex direction="column" className={styles.dataContainer}>
          {getPageContent()}
        </Flex>
      </Flex>
      <div className={styles.SannaLogo}>
        <SannaLogo />
      </div>
    </div>
  );
}

const ProfileCard = ({
  user,
  onChange,
  role,
  profileFormData,
  onClick,
  stateData,
  company,
}) => {
  return (
    <Flex direction="column">
      <div className={styles.headLabel}>Edit Profile Details</div>
      <Flex direction="row" className={styles.topContainer}>
        <Flex>
          <div>
            <DataItem
              label={'Agency Reference Number '}
              value={company?.referenceId}
              labelStyle={styles.profileLabelWidth}
            />
            <DataItem
              label={'Login/User ID'}
              value={user?.phoneNumber?.slice(3)}
              labelStyle={styles.profileLabelWidth}
            />
            <DataItem
              label={'Role'}
              value={role?.displayName}
              labelStyle={styles.profileLabelWidth}
            />
            <DataItem
              label={'Designation'}
              value={user?.designation ? user?.designation : role?.displayName}
              labelStyle={styles.profileLabelWidth}
            />
          </div>
        </Flex>
      </Flex>
      <Flex direction="row" className={styles.formContainer}>
        {profileFormData.map((elem, idx) => {
          return (
            <div key={`formFields-${idx}`} className={styles.formFields}>
              <FormElement
                label={elem.label}
                value={
                  elem.stateField === 'phoneNumber'
                    ? stateData[elem.stateField]?.value.slice(3)
                    : stateData[elem.stateField].value
                }
                type={elem.elementType}
                // disabled={elem.disabled}
                minLength={elem.minLength}
                maxLength={elem.maxLength}
                required={elem.required}
                disabled={elem.disabled}
                error={stateData[elem.stateField].error}
                // labelClassNames={
                //   elem.disabled ? styles.disableLabel : styles.labelClass
                // }
                // containerClassNames={
                //   elem.disabled ? styles.disabledContainer : styles.containerClass
                // }
                onChange={text => onChange(elem.stateField, text)}
              />
            </div>
          );
        })}
      </Flex>
      <Flex direction="row" style={{justifyContent: 'center'}}>
        <button
          className={styles.btnPrimary}
          onClick={() => onClick('update-profile')}>
          Save Changes
        </button>
      </Flex>
    </Flex>
  );
};
const DataItem = ({value, label, labelStyle}) => {
  return (
    <Flex className={styles.dataItemWrapper}>
      <div className={[styles.label, labelStyle].join(' ')}>{label} :</div>
      <div>{value}</div>
    </Flex>
  );
};
const AgencyCard = ({
  company,
  onChange,
  role,
  agencyFormData,
  onClick,
  stateData,
}) => {
  console.log('stateData company...', stateData);
  return (
    <Flex direction="column">
      <div className={styles.headLabel}>Edit Agency Details</div>

      <Flex>
        <div>
          <DataItem label={'Department'} value={company?.department?.name} />
          <DataItem label={'Agency Name'} value={company?.name} />
          <DataItem
            label={'Nature of Business'}
            value={company?.businessDetails?.name}
          />
        </div>
        <div className={styles.marginLeft30}>
          <DataItem label={'GST Number'} value={company?.gstnumber} />
          <DataItem label={'PAN Number'} value={company?.pannumber} />
          <DataItem label={'PAN Document'} value="-" />
        </div>
      </Flex>
      <Flex direction="row" className={styles.formContainer}>
        {agencyFormData.map((elem, idx) => {
          return (
            <div key={`formFields-${idx}`} className={styles.formFields}>
              <FormElement
                label={elem.label}
                value={
                  elem.stateField === 'phoneNumber'
                    ? stateData[elem.stateField]?.value.slice(3)
                    : stateData[elem.stateField].value
                }
                type={elem.elementType}
                minLength={elem.minLength}
                maxLength={elem.maxLength}
                required={elem.required}
                disabled={elem.disabled}
                onChange={text => onChange(elem.stateField, text)}
                options={stateData[elem.optionsField]}
                dropdownContainerStyle={[
                  styles.dropdownContainerStyle,
                  elem.disabled ? styles.disableDropDown : ' ',
                ].join(' ')}
                error={stateData[elem.stateField].error}
              />
            </div>
          );
        })}
      </Flex>
      <Flex direction="row" style={{justifyContent: 'center'}}>
        {role.roleId === 8 ? (
          <button
            className={styles.btnPrimary}
            onClick={() => onClick('update-agency')}>
            Save Changes
          </button>
        ) : null}
      </Flex>
    </Flex>
  );
};

const ChangePassword = ({formData, stateData, onChange, onClick}) => {
  const [visible, setVisible] = useState(false);

  const [confirmVisible, setConfirmVisible] = useState(false);
  const [oldPasswordVisible, setOldPasswordVisible] = useState(false);

  const getType = element => {
    switch (element.field) {
      case 'newPassword': {
        return visible
          ? {type: 'text', icon: eyeIcon}
          : {type: 'password', icon: eyeIconClosed};
      }
      case 'oldPassword': {
        return oldPasswordVisible
          ? {type: 'text', icon: eyeIcon}
          : {type: 'password', icon: eyeIconClosed};
      }
      case 'confirmPassword': {
        return confirmVisible
          ? {type: 'text', icon: eyeIcon}
          : {type: 'password', icon: eyeIconClosed};
      }
      default: {
        return null;
      }
    }
  };

  const onRightIconClick = element => {
    if (element.field === 'newPassword') {
      setVisible(!visible);
    } else if (element.field === 'confirmPassword') {
      setConfirmVisible(!confirmVisible);
    } else if (element.field === 'oldPassword') {
      setOldPasswordVisible(!oldPasswordVisible);
    }
  };

  const getInputType = (element, stateData, onChange) => {
    switch (element.type) {
      case 'password': {
        return (
          <TextInput
            label={element.label}
            lblDirection="row"
            onChange={update => onChange(element.field, update)}
            isEdit={true}
            placeholder={element.placeholder}
            //inputStyle={styles.inputTextAddress}
            required={element.required}
            name={element.field}
            error={stateData[element.field]?.error}
            value={stateData[element.field]?.value}
            type={getType(element)?.type}
            rightIcon={getType(element)?.icon}
            onRightIconClick={() => onRightIconClick(element)}
          />
        );
      }
      default: {
        console.warn('case not handled', element.type);
        return null;
      }
    }
  };

  return (
    <Flex direction="column">
      <div className={styles.headLabel}>Change Password</div>
      <div className={styles.passwordFieldsContainer}>
        {formData.map((elem, idx) => {
          return (
            <div
              key={`formFields-${idx}`}
              className={[styles.formFields, styles.passwordFields].join(' ')}>
              {getInputType(elem, stateData, onChange)}
              {/* <FormElement
                label={elem.label}
                value={stateData[elem.stateField]?.value}
                type={elem.elementType}
                minLength={elem.minLength}
                maxLength={elem.maxLength}
                required={elem.required}
                disabled={elem.disabled}
                onChange={text => onChange(elem.stateField, text)}
                dropdownContainerStyle={[
                  styles.dropdownContainerStyle,
                  elem.disabled ? styles.disableDropDown : ' ',
                ].join(' ')}
                error={stateData[elem.stateField]?.error}
              /> */}
            </div>
          );
        })}
        <Flex direction="row" style={{justifyContent: 'center'}}>
          <button
            className={styles.btnPrimary}
            onClick={() => onClick('update-password')}>
            Change Password
          </button>
        </Flex>
      </div>
    </Flex>
  );
};
