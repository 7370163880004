import React, {Component} from 'react';
import ViewRenderer from './views';
import {API} from '../../../requests';
import 'react-datasheet/lib/react-datasheet.css';
import './styles.css';
import PopupContainer from '../../../components/PopupContainer';
import POPUP_CONSTANTS from '../../../constants/popup-constants';
import Spinner from '../../../components/Spinner';
import Popup from '../../../components/SimplePopup';
import Popupcan from '../../../components/PopupPerson/popup';
import {editFormData} from './editData';
import strings from '../../../globalization';
import util from '../../../util';
import {getFormData} from './data';
import DeletePopup from '../../../components/DeletePopup';
import {commonImages, operatorImages} from '../../../assets/images';
class WorkArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: undefined,
      searchText: '',
      workArea: [],
      singleWorkArea: [],
      filterWorkArea: [],

      selectedStatus: '',
    };
  }
  statuses = [
    {
      id: '',
      name: 'ALL',
    },
    {
      id: 'inactive',
      name: 'INACTIVE',
    },
    {
      id: 'active',
      name: 'ACTIVE',
    },
  ];
  editStatusDropDown = this.statuses.filter(num => num.id !== '');
  onSubmit = workArea => {
    const workAreaData = {
      name: workArea.name.value,
      name_hindi: workArea.name_hindi.value,
    };
    this.setState({
      popup: null,
    });
    // console.log('my workArea save data', workAreaData);
    this.saveWorkArea(workAreaData);
  };
  showErrorPopup = errorMsg => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMsg,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.onClose,
          },
        ],
        onClose: this.closePopup,
      },
    });
  };
  showSuccessPopup = (successMessage, onClick) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: successMessage,
        onClose: this.onClose,
        headingImage: operatorImages.successIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: onClick ? onClick : this.onClose,
            outline: true,
          },
        ],
      },
    });
  };

  onClickHandler = (action, data) => {
    switch (action) {
      case 'edit': {
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.EDIT_POPUP,
          },
          singleWorkArea: data,
        });

        break;
      }
      case 'delete': {
        // console.log('my delete id', data);
        data.status === 'ACTIVE' &&
          this.setState({
            workArea: data,
            popup: {type: POPUP_CONSTANTS.DELETE_POPUP},
          });

        break;
      }
      default: {
        break;
      }
    }
  };
  onClose = () => {
    this.setState({
      popup: null,
    });
  };
  tableActionHandler = (action, data) => {
    switch (action) {
      case 'status': {
        this.setState(
          {
            selectedStatus: data,
          },
          this.applyFilters,
        );
        break;
      }

      case 'addNewWorkArea': {
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.ADD_DATA_POPUP,
          },
        });
        break;
      }

      case 'search': {
        this.setState(
          {
            searchText: data,
          },
          this.applyFilters,
        );
        break;
      }
      case 'clearFilter': {
        this.setState({
          searchText: '',
          selectedStatus: '',
        });
        this.fetchWorkArea();

        break;
      }
      default: {
        console.info('tableActionHandler case not handled', action);
      }
    }
  };
  onEditHandler = data => {
    //console.log('my id', this.state.workArea.workAreaId);
    let myData = {
      id: this.state.singleWorkArea.id,
      name: data.name.value,
      name_hindi: data.name_hindi.value,

      status: data.status.value.toUpperCase(),
    };
    this.setState({popup: null});
    this.updateWorkArea(myData);
  };

  fetchWorkArea = () => {
    this.setState({popup: {type: POPUP_CONSTANTS.SPINNER_POPUP}});
    API.fetchWorkAreaTypeData()
      .then(res => {
        this.setState(
          {
            // ...this.state,
            workArea: res.data,
            filterWorkArea: res.data,
            popup: null,
          },
          this.applyFilters,
        );
      })
      .catch(err => {
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };
  saveWorkArea = workAreaName => {
    let formData = {
      name: workAreaName.name,
      name_hindi: workAreaName.name_hindi,

      status: workAreaName.status,
    };
    API.saveWorkAreaData(formData)
      .then(res => {
        this.showSuccessPopup(
          'Work Area Added Successfully',
          this.fetchWorkArea,
        );
      })
      .catch(error => {
        const err = util.getErrorMessage(error);
        this.showErrorPopup(err);
      });
  };
  deleteWorkAreaData = workAreaId => {
    API.deleteWorkArea(workAreaId)
      .then(res => {
        this.showSuccessPopup(
          'Work Area Deleted Successfully',
          this.fetchWorkArea,
        );
      })
      .catch(error => {
        const err = util.getErrorMessage(error);
        this.showErrorPopup(err);
      });
  };
  updateWorkArea = data => {
    API.updatesWorkAreaData(data)
      .then(res => {
        this.showSuccessPopup(
          'Work Area Edited Successfully',
          this.fetchWorkArea,
        );
      })
      .catch(error => {
        const err = util.getErrorMessage(error);
        this.showErrorPopup(err);
      });
  };

  getProps = () => {
    const {searchText, selectedStatus, filterWorkArea} = this.state;
    return {
      onClick: this.onClickHandler,
      tableActionHandler: this.tableActionHandler,
      workArea: filterWorkArea,
      statuses: this.statuses,
      searchText,
      selectedStatus,
    };
  };

  applyFilters = () => {
    const {searchText, selectedStatus, workArea} = this.state;
    const filteredData =
      workArea.length > 0 &&
      workArea.filter(row => {
        const name = {name: row.name, name_hindi: row.name_hindi};
        if (
          searchText &&
          !Object.values(name)
            .toString()
            .toLowerCase()
            .includes(searchText.toLowerCase())
        ) {
          return false;
        }

        if (
          selectedStatus &&
          row.status.toLowerCase() !== selectedStatus.toLowerCase()
        ) {
          return false;
        }
        return true;
      });

    console.info('filteredData', filteredData, selectedStatus);

    this.setState({
      filterWorkArea: filteredData,
    });
  };
  getPopupContent = () => {
    const {popup} = this.state;
    switch (popup.type) {
      case POPUP_CONSTANTS.ADD_DATA_POPUP: {
        return (
          <Popupcan
            header="Add"
            onSubmit={this.onSubmit}
            onClose={this.onClose}
            getFormData={getFormData}
            // statuses={this.statuses}
          />
        );
      }
      case POPUP_CONSTANTS.EDIT_POPUP: {
        return (
          <Popupcan
            header="Edit"
            categoryTypeData={this.state.singleWorkArea}
            onSubmit={this.onEditHandler}
            onClose={this.onClose}
            getFormData={editFormData}
            statuses={this.editStatusDropDown}
          />
        );
      }
      case POPUP_CONSTANTS.DELETE_POPUP: {
        return (
          <DeletePopup onClick={this.onDeleteHandler} onClose={this.onClose} />
        );
      }
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };
  onDeleteHandler = () => {
    let workAreaId = this.state.workArea.id;
    //console.log('my delete id', id);
    this.setState({popup: null});
    this.deleteWorkAreaData(workAreaId);
  };
  componentDidMount() {
    this.fetchWorkArea();
  }

  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}
export default WorkArea;
