import React, { Component } from 'react';
import PopupContainer from '../../../components/PopupContainer';
import moment from 'moment';

import ViewRenderer from './views';
import AgencyRquestsPopup from '../../../components/AgencyRquestsPopup';
import { API } from '../../../requests';
import PersonCardsPopup from '../../../components/operator/PersonCardsPopup';
import { getFormData, getEditFormData } from './data';
import { commonImages, operatorImages } from '../../../assets/images';
import strings from '../../../globalization';

import Popup from '../../../components/SimplePopup';

import POPUP_CONSTANTS from '../../../constants/popup-constants';
import REQUEST_TYPE_OPTIONS from '../../../constants/request-type-options';
import util from '../../../util';
import Spinner from '../../../components/Spinner';
import Success from '../../../components/operator/Success';
import Reject from '../../../components/operator/Reject';
import { persons } from './tableData';

import { getPopupFormData } from './popupData';

// import DocViewer from 'react-doc-viewer';
import { pdfjs } from 'react-pdf';
import CardsActionsPopup from '../../../components/operator/CardsActionsPopup';
import History from '../../../components/operator/History';
import { getHistoryData } from './history';
import ViewDocument from '../../../components/ViewDocument';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ChangeRFID from '../../../components/operator/ChangeRFID';

// import { withRouter } from "react-router-dom";

// import Popup from "../../../components/SimplePopup";

const DROPDOWN_KEY_VALUE = {
  1: REQUEST_TYPE_OPTIONS.PERSON,
  2: REQUEST_TYPE_OPTIONS.VEHICLE,
  3: REQUEST_TYPE_OPTIONS.AGENCY,
};

const FILTER_OPTIONS = {
  REFERENCE_NUMBER: 'REFERENCE_NUMBER',
  AADHAAR_NUMBER: 'AADHAAR_NUMBER',
  RFID_NUMBER: 'RFID_NUMBER',
};

const STATUS = {
  ISSUED: 'Issued',
  HOLD: 'Hold',
};

class PersonCards extends Component {
  constructor(props) {
    super(props);

    let preparedObject = {};
    this.popupFormData = getPopupFormData();
    this.history = getHistoryData();
    this.persons = persons;
    this.filteredPersons = persons;
    this.formData = getFormData();
    this.editEditFormData = getEditFormData();
    this.formData.forEach(obj => {
      preparedObject[obj.stateField] = {
        value: obj.stateDefault,
        // error: null,
      };
      if (obj.optionsField) {
        preparedObject[obj.optionsField] = [];
      }
    });

    const reduxProps = this.props.reduxProps;
    console.log('reduxProps-->Person Cards', reduxProps);
    const roleId = reduxProps?.login?.loginInfo?.user?.roleId;
    console.log('ROLE ID---', roleId);

    this.state = {
      startDate: moment(),
      endDate: moment(),
      searchText: '',
      filter: null,
      filterOptions: [
        {
          id: FILTER_OPTIONS.REFERENCE_NUMBER,
          name: 'Reference Number',
        },
        {
          id: FILTER_OPTIONS.AADHAAR_NUMBER,
          name: 'Aadhaar Number',
        },
        {
          id: FILTER_OPTIONS.RFID_NUMBER,
          name: 'RFID Number',
        },
      ],
      // filter: 1,
      showPerson: false,
      showSuccess: false,
      successData: null,
      person: null,
      pageStatus: 'landing',
      roleId: reduxProps?.login?.loginInfo?.user?.roleId,

      ...preparedObject,

      documentTypeOptions: [
        { id: 1, name: 'Aadhaar' },
        { id: 2, name: 'Driving License' },
        { id: 3, name: 'PAN' },
      ],
      edit: false,
    };
  }

  getAllGenders = () => {
    API.getAllGender()
      .then(genderRes => {
        this.setState({
          genderOptions: genderRes.data,
        });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        this.showErrorPopup(errorMsg);
      });
  };

  getAllPersonOptions = () => {
    API.getAllPersonOptions()
      .then(personOptionsRes => {
        this.setState({
          personTypeOptions: personOptionsRes.data,
        });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        this.showErrorPopup(errorMsg);
      });
  };

  getAllCountries = () => {
    API.getAllCountries()
      .then(countriesRes => {
        this.setState({
          countryOptions: countriesRes.data,
        });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        this.showErrorPopup(errorMsg);
      });
  };

  fetchPersonDocumentTypes = () => {
    API.fetchPersonDocumentTypes()
      .then(optionsRes => {
        console.log('res...documentTypes', optionsRes.data);
        this.setState({
          documentTypeOptions: optionsRes.data,
        });
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        console.log(errorMsg);
        this.showErrorPopup(errorMsg);
      });
  };

  fetchOptions = async () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
    let promises = [];
    promises.push(this.getAllGenders());
    promises.push(this.getAllPersonOptions());
    promises.push(this.getAllCountries());
    promises.push(this.fetchPersonDocumentTypes());

    Promise.all(promises)
      .then(res => {
        this.setState({
          popup: null,
        });
      })
      .catch(error => console.log(error));
  };
  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: { color: '#E67717' },
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  showSuccessPopup = (message, onClick) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: message || 'Action handled successfully!',
        messageStyle: {},
        headingImage: operatorImages.successIcon,
        onClose: onClick ? onClick : this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: onClick ? onClick : this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  showConfirmationPopup = (message, onOkay, onCancel) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: message || `Are you sure you want to perform this operation?`,
        messageStyle: { color: '#E67717' },
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: onOkay ? onOkay : this.closePopup,
            outline: true,
            style: { backgroundColor: '#0045e6', color: '#fff' },
          },
          {
            text: 'Cancel',
            onClick: onCancel ? onCancel : this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  componentDidMount() {
    this.fetchOptions();
    this.applyFilters();
  }
  getDocumentProofNumber = documentId => {
    switch (documentId) {
      case 1: {
        return 'aadhaarNumber';
      }
      case 2: {
        return 'drivingLicenceNumber';
      }
      case 3: {
        return 'panNumber';
      }
      case 4: {
        return 'passportNumber';
      }
      case 5: {
        return 'voterIdNumber';
      }
      default: {
        return '';
      }
    }
  };
  getDocumentProofUrl = documentId => {
    switch (documentId) {
      case 1: {
        return 'aadhaarUrl';
      }
      case 2: {
        return 'drivingLicenceUrl';
      }
      case 3: {
        return 'panUrl';
      }
      case 4: {
        return 'passportUrl';
      }
      case 5: {
        return 'voterIdUrl';
      }
      default: {
        return '';
      }
    }
  };

  handleFilterBarClick = () => {
    // API to fetch details and populate state.
    const { filter } = this.state;
    this.showSpinner();
    // Reset person
    if (filter === FILTER_OPTIONS.REFERENCE_NUMBER) {
      this.fetchByReferenceId();
    } else if (filter === FILTER_OPTIONS.AADHAAR_NUMBER) {
      this.fetchByAadhaarNumber();
    } else {
      this.fetchByRFID();
    }
  };

  fetchByAadhaarNumber = () => {
    const aadhaarNumber = this.state.searchText.trim();
    API.fetchPersonByAadhaarNumber(aadhaarNumber)
      .then(res => {
        console.log('res.data....', res.data);

        let person = res.data;

        let documentId = person.documentTypeId;
        let documentProofField = this.getDocumentProofNumber(documentId);
        let documentProofNumber = person[documentProofField];

        let documentUrlStateField = this.getDocumentProofUrl(documentId);
        let documentUrl = person[documentUrlStateField];
        let phoneNumber = person.phoneNumber.split('+91')[1];

        this.setState(
          {
            person: {
              ...res.data,
              documentProofNumber: documentProofNumber,
              documentUrl: documentUrl,
              // FIXME: Hardcoded fields
              nationality: 'India',
              countryId: 1,
              phoneNumber,
            },
            // showPerson: true,
            personPhoto: res.data.photo,
            photoUrl: res.data.photo,
            pageStatus: 'showPersonDetails',
            documentProofNumber: { value: documentProofNumber },
            popup: null,
            name: person?.name,
            aadhaarNumber: person?.aadhaarNumber,
            drivingLicenceNumber: person?.drivingLicenceNumber,
            phoneNumber: phoneNumber,
            dob: person?.dob,
            edit: false,
          },
          () => this.preSignUrl(this.state?.person?.photo),
        );
        if (person?.typeId !== 1) {
          this.formData = [...this.formData]
            .filter(obj => obj.id !== 'drivingLicenceNumber')
            .filter(el => el.id !== 'drivingLicenceDocument');
        }
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        this.setState({
          person: null,
          showPerson: false,
          pageStatus: '',
        });
        this.showErrorPopup(errorMsg);
      });
  };

  fetchByRFID = () => {
    const RFID = this.state.searchText.trim();
    API.fetchPersonByRFID(RFID)
      .then(res => {
        console.log('res.data....', res.data);

        let person = res.data;

        let documentId = person.documentTypeId;
        let documentProofField = this.getDocumentProofNumber(documentId);
        let documentProofNumber = person[documentProofField];

        let documentUrlStateField = this.getDocumentProofUrl(documentId);
        let documentUrl = person[documentUrlStateField];
        let phoneNumber = person.phoneNumber.split('+91')[1];

        this.setState(
          {
            person: {
              ...res.data,
              documentProofNumber: documentProofNumber,
              documentUrl: documentUrl,
              // FIXME: Hardcoded fields
              nationality: 'India',
              countryId: 1,
              phoneNumber,
            },
            // showPerson: true,
            personPhoto: res.data.photo,
            photoUrl: res.data.photo,
            pageStatus: 'showPersonDetails',
            documentProofNumber: { value: documentProofNumber },
            popup: null,
            name: person?.name,
            aadhaarNumber: person?.aadhaarNumber,
            drivingLicenceNumber: person?.drivingLicenceNumber,
            phoneNumber: phoneNumber,
            dob: person?.dob,
            edit: false,
          },
          () => this.preSignUrl(this.state?.person?.photo),
        );
        if (person?.typeId !== 1) {
          this.formData = [...this.formData]
            .filter(obj => obj.id !== 'drivingLicenceNumber')
            .filter(el => el.id !== 'drivingLicenceDocument');
        }
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        this.setState({
          person: null,
          showPerson: false,
          pageStatus: '',
        });
        this.showErrorPopup(errorMsg);
      });
  };

  preSignUrl = async url => {
    const data = {
      url: url,
      expiryTime: 30,
    };
    await API.preSignedURL(data)
      .then(res => {
        console.log('res.data', res.data?.preSignedUrl);
        // setUrl(res.data?.preSignedUrl);
        this.setState({
          person: {
            ...this.state.person,
            photo: res.data?.preSignedUrl,
          },
          personPhoto: res.data?.preSignedUrl,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  fetchByReferenceId = () => {
    const referenceId = this.state.searchText.trim();
    API.getPersonByReferenceId(referenceId)
      .then(res => {
        console.log('res.data....', res.data);

        let person = res.data;

        let documentId = person.documentTypeId;
        let documentProofField = this.getDocumentProofNumber(documentId);
        let documentProofNumber = person[documentProofField];

        let documentUrlStateField = this.getDocumentProofUrl(documentId);
        let documentUrl = person[documentUrlStateField];
        let phoneNumber = person.phoneNumber.split('+91')[1];

        this.setState(
          {
            person: {
              ...res.data,
              documentProofNumber: documentProofNumber,
              documentUrl: documentUrl,
              // FIXME: Hardcoded fields
              nationality: 'India',
              countryId: 1,
              phoneNumber,
            },
            // showPerson: true,
            personPhoto: res.data.photo,
            photoUrl: res.data.photo,
            pageStatus: 'showPersonDetails',
            documentProofNumber: { value: documentProofNumber },
            popup: null,
            name: person?.name,
            aadhaarNumber: person?.aadhaarNumber,
            drivingLicenceNumber: person?.drivingLicenceNumber,
            phoneNumber: phoneNumber,
            dob: person?.dob,
            edit: false,
          },
          () => this.preSignUrl(this.state?.person?.photo),
        );
        if (person?.typeId !== 1) {
          this.formData = [...this.formData]
            .filter(obj => obj.id !== 'drivingLicenceNumber')
            .filter(el => el.id !== 'drivingLicenceDocument');
        }
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        this.setState({
          person: null,
          showPerson: false,
          pageStatus: '',
        });
        this.showErrorPopup(errorMsg);
      });
  };

  onClickHandler = (action, data) => {
    const { history } = this.props;

    console.log('action', action);
    switch (action) {
      case 'apply': {
        this.handleFilterBarClick();
        break;
      }
      default: {
        console.info('case not handled', action);
      }
    }
  };

  handleUpdate = person => {
    console.log('RFIDNumber, remark');
    const { name, dob, aadhaarNumber, drivingLicenceNumber, phoneNumber } = this.state;
    this.showSpinner();
    console.log(name, dob, aadhaarNumber, drivingLicenceNumber);
    if (!name || name?.trim().length === 0) {
      this.showErrorPopup(` Pleas enter name`);
      return;
    }
    if (!aadhaarNumber || aadhaarNumber?.trim().length < 12) {
      this.showErrorPopup(` Pleas enter 12 digit aadhaar number`);
      return;
    }
    if (!drivingLicenceNumber || drivingLicenceNumber?.trim().length === 0) {
      this.showErrorPopup(` Pleas enter driving license number`);
      return;
    }
    if (!dob) {
      this.showErrorPopup(` Pleas enter date of birth`);
      return;
    }
    if (!phoneNumber) {
      this.showErrorPopup(` Pleas enter phone Number`);
      return;
    }
    // const dateOfBirth = util.getDateTimeString(dob, 'mmddyyyy');

    let formData = {
      id: person.id,
      name: name === person?.name ? '' : name,
      aadharNumber:
        aadhaarNumber === person?.aadhaarNumber ? '' : aadhaarNumber,
      drivingLicenceNumber:
        drivingLicenceNumber === person?.drivingLicenceNumber
          ? ''
          : drivingLicenceNumber,
      phoneNumber: phoneNumber === person?.phoneNumber ? '' : `+91${phoneNumber}`,

      DOB:
        moment(dob).format('MM/DD/YYYY').toString() ===
          moment(person?.dob).format('MM/DD/YYYY').toString()
          ? ''
          : moment(dob).format('MM/DD/YYYY'),
    };

    API.updatePersonByOperator(formData)
      .then(res => {
        console.log('res', res);

        this.setState({
          popup: null,
          pageStatus: 'success',
          successData: {
            data: person,
            type: 'updated',
            onOkay: () => {
              this.setState({ pageStatus: 'landing' });
            },
          },
        });
      })
      .catch(error => {
        console.log(error);
        const errorMsg = util.getErrorMessage(error);
        this.showErrorPopup(errorMsg);
      });
  };

  handleApprove = (RFIDNumber, remark, verifiedList, data) => {
    console.log('RFIDNumber, remark', RFIDNumber, verifiedList);
    const { personPhoto, photoUrl } = this.state;

    this.showSpinner();
    if (RFIDNumber.length !== 7) {
      this.showErrorPopup(`RFID number should be 7 digits`);
      return;
    }
    let formData = {
      id: data.id,
      checkPersonalDetails: verifiedList.verifiedPersonalDetails,
      checkProofDocuments: verifiedList.verifiedProofDocument,
      RFIDNumber: RFIDNumber,
      photo: photoUrl ? photoUrl : 'dummy.jpg',
      // photo: personPhoto,
      remarks: remark,
    };

    API.approvePerson(formData)
      .then(res => {
        console.log('res', res);

        this.setState({
          popup: null,
          pageStatus: 'success',
          successData: {
            data,
            RFIDNumber,
            remark,
            type: 'issued',
            onOkay: () => {
              this.setState({ pageStatus: 'landing' });
            },
          },
        });
      })
      .catch(error => {
        console.log(error);
        const errorMsg = util.getErrorMessage(error);
        this.showErrorPopup(errorMsg);
      });
  };
  handleReject = remark => {
    console.log('remark', remark);
    const { person, personPhoto, photoUrl } = this.state;

    this.showSpinner();

    let formData = {
      id: person.id,
      remarks: remark,
      photo: personPhoto,
      photoUrl: photoUrl ? photoUrl : 'dummy.jpg',
    };

    API.rejectPerson(formData)
      .then(res => {
        console.log(res);
        this.setState({
          popup: null,
          pageStatus: 'success',
          successData: {
            data: person,
            remark,
            type: 'hold',
            onOkay: () => {
              this.setState({ pageStatus: 'landing' });
            },
          },
        });
      })
      .catch(error => {
        console.log(error);
        const errorMsg = util.getErrorMessage(error);
        this.showErrorPopup(errorMsg);
      });
  };
  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  onChangeHandler = (field, value) => {
    console.log('field', field, 'value', value);
    if (field === 'searchText') {
      this.applyFilters(value);
    }
    this.setState(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };

  handleSurrender = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.CARDS_ACTIONS_POPUP,
        title: strings.titlePersonsCard,
        subTitle: strings.areYouSureSurrender,
        onBack: () => {
          this.setState({
            popup: null,
          });
        },
        onConfirm: remark => this.surrender(remark),
        handlePictureUpload: this.handlePictureUpload,
      },
    });
  };
  handlePictureUpload = blob => {
    const formData = new FormData();
    formData.append('s3FolderName', 'company');
    formData.append('file', blob);

    API.uploadToS3(formData)
      .then(response => {
        console.log('File uploaded to S3...', response.data);
        this.setState({
          photoUrl: response.data,
        });
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        this.showErrorPopup(errorMsg);
      });
  };
  surrender = remark => {
    // console.log("RFIDNumber, remark", RFIDNumber, remark, verifiedList);
    const { person, personPhoto } = this.state;
    this.showSpinner();

    let formData = {
      id: person.id,
      remarks: remark,
      photo: personPhoto,

      // photo: photoUrl ? photoUrl : 'dummy.jpg',
    };

    API.surrenderPerson(formData)
      .then(res => {
        console.log('res', res);

        this.setState({
          popup: null,
          pageStatus: 'success',
          successData: {
            remark: remark,
            type: 'surrendered',
            data: person,
            onOkay: () => {
              this.setState({ pageStatus: 'landing' });
            },
          },
        });
      })
      .catch(error => {
        console.log(error);
        const errorMsg = util.getErrorMessage(error);
        this.showErrorPopup(errorMsg);
      });
  };

  viewDocument = url => {
    const { history, location } = this.props;

    history.push({
      pathname: location.pathname + '/document-view',
      state: { background: location, url: url },
    });
  };

  getDocumentTempURL = url => {
    const { history, location } = this.props;
    const data = {
      url: url,
      expiryTime: 30,
    };
    API.preSignedURL(data)
      .then(res => {
        console.log('res.data', res.data?.preSignedUrl);
        history.push({
          pathname: location.pathname + '/document-view',
          state: { background: location, url: res.data?.preSignedUrl },
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  onEditState = RFIDNumber => {
    console.log('On edit state method:', RFIDNumber);
    this.setState({
      edit: true,
      pageStatus: 'showEditPersonDetails',
    });
  };

  onApprovePopup = (RFIDNumber, verifiedList, person) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.CARDS_ACTIONS_POPUP,
        title: strings.titlePersonsCard,
        subTitle: strings.areYouSureIssue,
        onBack: () => {
          this.setState({
            popup: null,
          });
        },
        onConfirm: remark =>
          this.handleApprove(RFIDNumber, remark, verifiedList, person),
        handlePictureUpload: this.handlePictureUpload,
      },
    });
    this.setState({
      person: {
        ...person,
        RFIDNumber: RFIDNumber,
      },
    });
  };
  onRejectPopup = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.CARDS_ACTIONS_POPUP,
        title: strings.titlePersonsCard,
        subTitle: strings.areYouSureReject,
        onBack: () => {
          this.setState({
            popup: null,
          });
        },
        onConfirm: remark => this.handleReject(remark),
        handlePictureUpload: this.handlePictureUpload,
      },
    });
  };
  viewHistory = () => {
    this.getPersonHistory();
  };

  getPersonHistory = () => {
    const { history, location } = this.props;
    const { person } = this.state;
    this.showSpinner();

    API.viewPersonHistory(person.referenceId)
      .then(res => {
        history.push({
          pathname: location.pathname + '/view-history',
          state: {
            background: location,
            history: res.data,
            rejectReasons: this.state.personRejectReasons,
          },
        });
        this.setState({
          popup: null,
        });
      })
      .catch(err => {
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };

  handleLost = () => {
    this.showConfirmationPopup(
      `Are you sure you want to proceed with this operation?`,
      this.lost,
    );
  };

  lost = () => {
    console.log('clicked lost...', this.state.person);
    this.showSpinner();
    API.personCardLost({ id: this.state.person.id })
      .then(res => {
        this.showSuccessPopup(
          `Card successfully marked as lost / damaged!`,
          this.handleFilterBarClick,
        );
      })
      .catch(err => {
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };

  handleChangeRFID = () => {
    console.log('handle change RFID...');
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.CHANGE_RFID_POPUP,
        onSubmit: RFID => this.changeRFID(RFID),
        onCancel: this.closePopup,
      },
    });
  };

  changeRFID = RFID => {
    this.showSpinner();

    if (RFID.length !== 7) {
      this.showErrorPopup(`RFID number should be 7 digits`);
      return;
    }
    const { person } = this.state;
    let formData = {
      id: person?.id,
      RFIDNumber: RFID,
    };

    API.changeRFIDPerson(formData)
      .then(res => {
        this.showSuccessPopup(
          `RFID number successfully changed!`,
          this.handleFilterBarClick,
        );
      })
      .catch(err => {
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };

  getProps = () => {
    const { searchText, person, pageStatus } = this.state;
    return {
      onClick: this.onClickHandler,
      persons: this.filteredPersons,
      searchText: searchText,
      onChange: this.onChangeHandler,
      filterOptions: this.state.filterOptions,
      filter: this.state.filter,

      showPerson: this.state.showPerson,
      showSuccess: this.state.showSuccess,
      successData: this.state.successData,
      viewDocument: this.viewDocument,

      pageStatus,

      formMetadata: {
        formData: this.state.edit ? this.editEditFormData : this.formData,
        stateData: this.state,

        person: this.state.person,
        onApprove: (RFIDNumber, verifiedList) =>
          this.onApprovePopup(RFIDNumber, verifiedList, person),
        onSurrender: () => {
          this.handleSurrender();
        },
        onReject: verifiedList => this.onRejectPopup(),
        onClose: () => this.setState({ popup: null }),
        onEdit: RFIDNumber => this.onEditState(RFIDNumber),
        onChangeHandler: (elem, text) => this.onChangeHandler(elem, text),
        onEditSubmit: () => this.handleUpdate(person),
        onEditCancel: () => {
          this.setState({
            edit: false,
            pageStatus: 'showPersonDetails',
          });
        },
        onLost: this.handleLost,
        onChangeRFID: this.handleChangeRFID,
        viewHistory: this.viewHistory,
        roleId: this.state.roleId,
      },
    };
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };

  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const { popup } = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.STANDARD: {
        return <AgencyRquestsPopup {...popup} />;
      }
      case POPUP_CONSTANTS.SUCCESS_POPUP: {
        return <Success person {...popup} />;
      }
      case POPUP_CONSTANTS.REJECT_POPUP: {
        return <Reject person {...popup} />;
      }
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.PERSONS_CARD_POPUP: {
        return <PersonCardsPopup {...popup} />;
      }
      case POPUP_CONSTANTS.CHANGE_RFID_POPUP: {
        return <ChangeRFID {...popup} />;
      }
      case POPUP_CONSTANTS.CARDS_ACTIONS_POPUP: {
        return (
          <CardsActionsPopup
            formData={this.popupFormData}
            stateData={this.state.person}
            {...popup}
          />
        );
      }
      case POPUP_CONSTANTS.HISTORY_POPUP: {
        return (
          <History
            formData={this.popupFormData}
            stateData={this.state.person}
            {...popup}
          />
        );
      }
      case POPUP_CONSTANTS.VIEW_DOCUMENT: {
        return <ViewDocument {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };

  applyFilters = (search = '') => {
    // const { searchText } = this.state;

    this.filteredPersons = this.persons.filter(elem =>
      elem.referenceNumber.toLowerCase().includes(search.toLowerCase()),
    );
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const { popup } = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

const mapStateToProps = reduxProps => ({
  reduxProps,
});

const mapDispatchToProps = dispatch => ({
  // dispatchSetPersonsData: payload => {
  //   dispatch(setPersonsData(payload));
  // },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PersonCards),
);
