const ROLES_CONSTANTS = {
  admin: 0,
  agencyUser: [2, 3, 8],
  deptUser: [4, 9, 10, 13],
  operatorModuleUser: [5, 6, 7],
  adminPortalUser: [11, 18], // role id 18 - We want to show inventory
  masterAdmin: [1],
  invoiceAdmin: [17],
};

export default ROLES_CONSTANTS;
