import React from 'react';
import styles from './styles.module.scss';
import {useState} from 'react';
import Button from '../Button';
import FormElement from '../FormElement';
import util from '../../util';

const PopupData = props => {
  const {
    onSubmit,
    onClose,
    requiredName,
    header,
    getFormData,
    categoryTypeData,
  } = props;
  let preparedObject = {};
  const formData = getFormData();
  console.log('categoryTypeData', categoryTypeData);
  formData.forEach(obj => {
    preparedObject[obj.stateField] = {value: obj.stateDefault, error: null};
  });

  const [state, setState] = useState({
    ...preparedObject,
    name: {
      value:
        categoryTypeData && categoryTypeData.name ? categoryTypeData.name : '',
    },
    name_hindi: {
      value:
        categoryTypeData && categoryTypeData.name_hindi
          ? categoryTypeData.name_hindi
          : '',
    },
    fullName: {
      value:
        categoryTypeData && categoryTypeData.fullName
          ? categoryTypeData.fullName
          : '',
    },
    displayName: {
      value:
        categoryTypeData && categoryTypeData.displayName
          ? categoryTypeData.displayName
          : '',
    },
    errorCode: {
      value:
        categoryTypeData && categoryTypeData.errorCode
          ? categoryTypeData.errorCode
          : '',
    },
    status: {
      value:
        categoryTypeData && categoryTypeData.status
          ? categoryTypeData.status.toLowerCase()
          : '',
    },
    vehicleCategoriesId: {
      value:
        categoryTypeData && categoryTypeData.vehicleCategoriesId
          ? categoryTypeData.vehicleCategoriesId
          : '',
    },
    id: {
      value: categoryTypeData && categoryTypeData.id ? categoryTypeData.id : '',
    },
    packType: {
      value:
        categoryTypeData && categoryTypeData.packType
          ? categoryTypeData.packType
          : '',
    },
    designation: {
      value:
        categoryTypeData && categoryTypeData.designation
          ? categoryTypeData.designation
          : '',
    },
    emailAddress: {
      value:
        categoryTypeData && categoryTypeData.emailAddress
          ? categoryTypeData.emailAddress
          : '',
    },
    phoneNumber: {
      value:
        categoryTypeData && categoryTypeData.phoneNumber
          ? categoryTypeData.phoneNumber
          : '',
    },
  });
  const handleChange = (stateField, value) => {
    setState({
      ...state,
      [stateField]: {value: value},
    });
  };

  const onBlur = (field, element) => {
    const currentObj = state[field];
    let error = util.validate(currentObj.value, element);

    setState({
      ...state,
      [field]: {
        ...currentObj,
        error: element ? util.validate(currentObj.value, element) : null,
      },
    });
  };

  const onSubmitHandler = () => {
    let error = handleErrors();
    if (!error) {
      onSubmit(state);
    }
  };
  const handleErrors = () => {
    let err = false;
    const update = {};
    let requireBool = false;
    let myFormData = [...formData];

    for (let i = 0; i < myFormData.length; i++) {
      let element = myFormData[i];
      requireBool = false;
      let error = util.validate(state[element.stateField].value, {
        ...element,
        required: requireBool || element.required,
      });

      if (error) {
        err = true;
        update[element.stateField] = {
          ...state[element.stateField],
          error: error,
        };
      } else {
        update[element.stateField] = {
          ...state[element.stateField],
          error: null,
        };
      }
    }
    setState({...update});
    return err;
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>{header}</div>
      <div>
        <div className={styles.formData}>
          {getFormData().map((elem, idx) => {
            return (
              <div key={`formLeftFields-${idx}`} className={styles.formInput}>
                <FormElement
                  onChange={change => handleChange(elem.stateField, change)}
                  state={state}
                  requiredName={requiredName}
                  placeholderText={elem.placeholder}
                  type={elem.elementType}
                  label={elem.label}
                  required={elem.required}
                  minLenght={elem.maxLength}
                  maxLength={elem.maxLength}
                  inputType={elem.inputType}
                  stateField={elem.stateField}
                  error={state[elem.stateField].error}
                  value={state[elem.stateField].value}
                  stateDefault={elem.stateDefault}
                  disabled={elem.disable}
                  options={props[elem.optionsField]}
                  onBlur={() => onBlur(elem.stateField, elem)}
                />
              </div>
            );
          })}
        </div>
        <div className={styles.btnContainer}>
          <Button
            buttonStyle="primary"
            label={'Cancel'}
            className={styles.backBtn}
            onClick={onClose}
          />
          <Button
            buttonStyle="primary"
            label={'Save'}
            className={styles.addBtn}
            onClick={onSubmitHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default PopupData;
