import moment from 'moment';
import React, { PureComponent } from 'react';
import LineGraph from '../../../components/LineGraph';
import PopupContainer from '../../../components/PopupContainer';
import Spinner from '../../../components/Spinner';
import Popup from '../../../components/SimplePopup';

import './styles.css';
import styles from './styles.module.scss';
import ViewRenderer from './views';
import { API } from '../../../requests';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { commonImages } from '../../../assets/images';
import strings from '../../../globalization';
import util from '../../../util';

import closeIcon from '../../../assets/images/close-icon/image.png';

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  SPINNER_POPUP: 'spinner',
  ERROR: 'error',
  SIMPLE_POPUP: 'simple-popup',
  GRAPH_POPUP: 'graph-popup',
};

class CISFDepartmentDashboard extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      statuses: [
        {
          id: 2,
          name: 'week',
          displayName: 'Last 7 days',
        },
        {
          id: 3,
          name: 'month',
          displayName: 'Month to Date',
        },
        {
          id: 4,
          name: 'custom',
          displayName: 'Custom...',
        },
      ],
      selectedDropDownStatus: {
        id: 2,
        name: 'week',
        displayName: 'Last 7 days',
      },
      trafficDetails: [],
      filteredTrafficDetails: [],
      startDate: moment().subtract(8, 'days').format('MM-DD-YYYY'),
      endDate: moment().subtract(1, 'days').format('MM-DD-YYYY'),
      dates: {
        start: moment().subtract(8, 'days').format('MM-DD-YYYY'),
        end: moment().subtract(1, 'days').format('MM-DD-YYYY'),
      },
      gateDetails: [],
      selectedGates: new Set(),
      selectedParameters: new Set(),
      parametersDetails: [
        {
          id: 0,
          name: 'Permits Raised',
          label: 'Permits Raised',
          key: 'permitsRaised',
          value: 'permitsRaised',
        },
        {
          id: 1,
          name: 'Total Vehicles',
          label: 'Total Vehicles',
          key: 'totalVehicles',
          value: 'totalVehicles',
        },
        {
          id: 2,
          name: 'Vehicles With Permits',
          label: 'Vehicles With Permits',
          key: 'vehiclesWithPermits',
          value: 'vehiclesWithPermits',
        },
        {
          id: 3,
          name: 'Invalid Entries',
          label: 'Invalid Entries',
          key: 'invalidEntries',
          value: 'invalidEntries',
        },
      ],
      dashboardDetails: null,
    };
  }

  componentDidMount() {
    this.getData();
    this.fetchAllGateDetails();
    // this.fetchDashboard();
  }
  fetchDashboard = () => {
    this.showSpinner();
    API.fetchDeputyDepartmentDashboard()
      .then(res => {
        this.setState({ dashboardDetails: res?.data, popup: null });
      })
      .catch(err => {
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };

  fetchAllGateDetails = () => {
    API.fetchGatesList()
      .then(res => {
        this.setState({
          gateDetails: res.data,
        });
      })
      .catch(err => {
        const error = util.getErrorMessage(err);
        this.showErrorPopup(err);
      });
  };
  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: { color: '#E67717' },
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  getData = () => {
    const { startDate, endDate, selectedGates } = this.state;
    console.log('selectedGates,', selectedGates);
    let params = {
      startDate,
      endDate,
      gateId:
        Array.from(selectedGates).length > 0
          ? Array.from(selectedGates)
            .map(el => el.id)
            .join(',')
          : 0,
    };

    this.showSpinner();
    // API.fetchTrafficDepartmentDashboardV2(params)
    //   .then(res => {
    //     console.log('res.data', res.data);
    //     this.setState({
    //       popup: null,
    //       trafficDetails: res.data,
    //       filteredTrafficDetails: res.data,
    //       dashboardDetails: res.data,
    //     });
    //   })
    //   .catch(err => {
    //     console.log(err);
    //     const error = util.getErrorMessage(err);
    //     this.showErrorPopup(error);
    //   });

    API.fetchTrafficDepartmentDashBoardForTodayData(params)
      .then(res => {
        console.log('DepartmentDashBoardForTodayData:', res.data);

        const updatedDetails = {
          ...this.state.trafficDetails,
          ...res.data
        };

        this.setState({
          popup: null,
          trafficDetails: updatedDetails,
          filteredTrafficDetails: updatedDetails,
          dashboardDetails: updatedDetails,
        });
      })
      .catch(err => {
        console.log(err);
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });

    API.fetchTrafficDepartmentDashBoardForGraphs(params)
      .then(res => {
        console.log('DepartmentDashBoardForGraphs:', res.data);

        const updatedDetails = {
          ...this.state.trafficDetails,
          ...res.data
        };

        this.setState({
          popup: null,
          trafficDetails: updatedDetails,
          filteredTrafficDetails: updatedDetails,
          dashboardDetails: updatedDetails,
        });
        console.log('updated details:', this.state.dashboardDetails);

      })
      .catch(err => {
        console.log(err);
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });

  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  onClickHandler = (action, data) => {
    const { history, location, status } = this.props;
    switch (action) {
      case 'permits': {
        //FIXME
        history.push('/u/permits');
        break;
      }
      case 'profile': {
        //FIXME
        history.push('/u/profile');
        break;
      }
      case 'date': {
        const { startDate, endDate, dates } = data;
        console.log(data);
        // calculate difference:
        let diff = moment(startDate).diff(moment(endDate), 'days'); // 1
        if (Math.abs(diff) > 31) {
          this.showErrorPopup(`Only a maximum of 1 month is allowed.`);
          return;
        }
        console.log('diff...', diff);

        this.setState(
          {
            startDate,
            endDate,
            dates,
          },
          this.getData,
        );

        break;
      }
      case 'dateChange': {
        if (data) {
          this.setState({
            selectedDropDownStatus: data,
          });
        }
        this.setDate(data?.name);
        break;
      }

      case 'graph-popup': {
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.GRAPH_POPUP,
          },
        });
        break;
      }
      case 'handle-metrics-filters': {
        this.handleMetricsFilters(data);
        break;
      }
      case 'handle-gate-filters': {
        this.handleGateFilters(data);
        break;
      }
      case 'print-graph': {
        console.log('print graph', 'data:', data);
        history.push({
          pathname: '/u/dashboard/download-graph',
          state: {
            background: location,
            gateDetails: this.state.gateDetails,
            filteredTrafficDetails: this.state.filteredTrafficDetails,
            selectedGates: this.state.selectedGates,
            selectedParameters: this.state.selectedParameters,
          },
        });
        break;
      }
      case 'apply-filters': {
        this.getData();
        break;
      }

      default: {
        break;
      }
    }
  };

  handleMetricsFilters = data => {
    const { selectedParameters } = this.state;
    let finalSelectedParameters = new Set(selectedParameters);
    let parameter = data?.metric;
    console.log('parameter', parameter);

    let { checked } = data;

    if (checked) {
      finalSelectedParameters.add(parameter);
    } else {
      finalSelectedParameters.delete(parameter);
    }

    this.setState({
      selectedParameters: finalSelectedParameters,
    });
  };
  handleGateFilters = data => {
    const { selectedGates } = this.state;
    let finalSelectedGates = new Set(selectedGates);
    let gate = data.singleGateDetails;
    console.log('data...', data.singleGateDetails);
    let { checked } = data;

    if (checked) {
      finalSelectedGates.add(gate);
    } else {
      finalSelectedGates.delete(gate);
    }

    this.setState(
      {
        selectedGates: finalSelectedGates,
      },
      // this.getData,
    );

    // if checked --- we need to add it to the array
  };

  setDate = timePeriod => {
    switch (timePeriod) {
      case 'today': {
        this.setState(
          {
            startDate: moment().format('MM-DD-YYYY'),
            endDate: moment().format('MM-DD-YYYY'),
            dates: {
              start: moment().format('MM-DD-YYYY'),
              end: moment().format('MM-DD-YYYY'),
            },
          },
          this.getData,
        );
        break;
      }
      case 'week': {
        this.setState(
          {
            startDate: moment().subtract(8, 'days').format('MM-DD-YYYY'),
            endDate: moment().subtract(1, 'days').format('MM-DD-YYYY'),
            dates: {
              start: moment().subtract(8, 'days').format('MM-DD-YYYY'),
              end: moment().subtract(1, 'days').format('MM-DD-YYYY'),
            },
          },
          this.getData,
        );
        break;
      }
      case 'month': {
        this.setState(
          {
            startDate: moment().startOf('month').format('MM-DD-YYYY'),
            endDate: moment().subtract(1, 'days').format('MM-DD-YYYY'),
            dates: {
              start: moment().startOf('month').format('MM-DD-YYYY'),
              end: moment().subtract(1, 'days').format('MM-DD-YYYY'),
            },
          },
          this.getData,
        );
        break;
      }
      default: {
        console.log('case not handled in setDate method');
      }
    }
  };
  getPopupContent = () => {
    const { popup, filteredTrafficDetails, selectedParameters, selectedGates } =
      this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.GRAPH_POPUP: {
        return (
          <div className={styles.graphPopup} onClick={this.closePopup}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button
                style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                onClick={this.closePopup}>
                <img src={closeIcon} style={{ width: '2rem' }} />
              </button>
            </div>
            <LineGraph
              trafficDetails={filteredTrafficDetails}
              selectedGates={selectedGates}
              selectedParameters={selectedParameters}
              responsive
            />
          </div>
        );
      }
      default: {
        console.warn('case not handled', popup.type);
        return null;
      }
    }
  };

  closePopup = () => {
    this.setState({
      popup: null,
    });
  };

  getProps = () => {
    const {
      statuses,
      selectedDropDownStatus,
      startDate,
      endDate,
      dates,
      gateDetails,
      parametersDetails,
      selectedGates,
      selectedParameters,
      filteredTrafficDetails,
      dashboardDetails,
    } = this.state;
    const { reduxProps } = this.props;
    const {
      login: { loginInfo },
    } = reduxProps;
    const { user, department } = loginInfo;
    return {
      statuses,
      selectedDropDownStatus,
      onClick: this.onClickHandler,
      startDate,
      endDate,
      dates,
      user,
      departmentName: department?.name,
      gateDetails: gateDetails,
      parametersDetails: parametersDetails,
      selectedGates,
      selectedParameters,
      filteredTrafficDetails,
      dashboardDetails,
    };
  };

  render() {
    const viewProps = this.getProps();
    const { popup } = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer containerStyle={styles.popupContainerStyle}>
            {this.getPopupContent()}
          </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}
const mapStateToProps = reduxProps => ({
  reduxProps,
});

const mapDispatchToProps = dispatch => ({
  // dispatchSetPersonsData: payload => {
  //   dispatch(setPersonsData(payload));
  // },
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CISFDepartmentDashboard),
);
