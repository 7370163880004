import React from 'react';
import styles from './Loader.module.scss';
import {commonImages} from '../../assets/images';
import strings from '../../globalization';
/**
 *
 * @param {Boolean} error
 * @param {Boolean} success
 * @return {JSX.Element}
 */

/**
 *
 * @param {Props} props
 * @return {JSX.Element}
 */
function Spinner(props) {
  return (
    <div className={styles.loaderWrapper}>
      <img src={commonImages.loadingIcon} alt="" />
    </div>
  );
}

export default Spinner;
