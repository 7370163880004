import React from 'react';
import styles from './Material.module.scss';
import moment from 'moment';
import {adminImages, loginImages} from '../../assets/images';
import documentIcon from '../../../src/assets/images/document-icon/image.png';
import strings from '../../globalization';
import {AgGridReact} from 'ag-grid-react';
import dimensions from '../../util/dimensions-util';
import './styles.css';
import ReactTooltip from 'react-tooltip';

const {vw: widthConstant, vh: heightConstant} = dimensions;

/**
 *
 * @param {Boolean} error
 * @param {Boolean} success
 * @return {JSX.Element}
 */

/**
 *
 * @param {Props} props
 * @return {JSX.Element}
 */
function AgencyMaterialDetails(props) {
  console.log(props);
  const {
    // IMPORT DATA.
    materialTypeId,
    igmnumber,
    imonumber,
    igmdate,
    vcnnumber,
    outOfChargeNo,
    cha,
    chacode,
    billOfEntryNo,
    vesselName,
    vesselType,
    lineInfo,
    deliveryOrder,
    deliveryOrderDate,

    // EXPORT DATA
    shippingBillNumber,
    natureOfCargo,
    hscode,
    fobvalue,
    cartingPermissionDate,
    cartingPermissionDocument,
    cartingPermissionNumber,
    cargoDescription,
    totalWeightNumberOfUnits,
    natureOfCargoDetails,
    unitsOfWeight,
    onClick,
    photo,
    unitOfWeightDetails,
  } = props || {};

  const unitsList = [
    {name_hindi: '', name: 'KGS', id: 1, status: 'ACTIVE'},
    {name_hindi: '', name: 'MTS', id: 2, status: 'ACTIVE'},
  ];
  const natureOfCargoList = [
    {name_hindi: '', name: 'Break Bulk', id: 2, status: 'ACTIVE'},
    {name_hindi: '', name: 'Dry Bulk', id: 1, status: 'ACTIVE'},
    {name_hindi: '', name: 'Liquid', id: 3, status: 'ACTIVE'},
  ];

  const columnDefs = [
    {
      headerName: 'Line Number',
      field: 'lineNumber',
      headerClass: styles.rowHeader,
      width: 180 * widthConstant,
      flex: 1,
      resizable: true,
      cellRendererFramework: ({data: {lineNumber}}) => {
        return <div className={styles.sessionTime}>{lineNumber}</div>;
      },
    },
    {
      headerName: 'BL No',
      field: 'blno',
      headerClass: styles.rowHeader,
      width: 180 * widthConstant,
      flex: 1,
      resizable: true,
      cellRendererFramework: ({data: {blno}}) => {
        return <div className={styles.sessionTime}>{blno}</div>;
      },
    },
    {
      headerName: 'Applied Quantity',
      field: 'appliedGrossQuantity',
      headerClass: styles.rowHeader,
      width: 180 * widthConstant,
      flex: 1,
      editable: true,
      resizable: true,
      cellRendererFramework: ({data: {appliedGrossQuantity}}) => {
        return <div className={styles.sessionTime}>{appliedGrossQuantity}</div>;
      },
    },
    {
      headerName: 'Units',
      field: 'units',
      headerClass: styles.rowHeader,
      width: 100 * widthConstant,
      flex: 1,
      resizable: true,
      cellRendererFramework: ({data: {units}}) => {
        return <div className={styles.sessionTime}>{units}</div>;
      },
    },
    {
      headerName: 'Cargo Description',
      field: 'cargoDescription',
      headerClass: styles.rowHeader,
      width: 250 * widthConstant,
      flex: 1,
      resizable: true,
      cellRendererFramework: ({data: {cargoDescription}}) => {
        return <div className={styles.sessionTime}>{cargoDescription}</div>;
      },
    },
    {
      headerName: 'Nature Of Cargo',
      field: 'natureOfCargo',
      headerClass: styles.rowHeader,
      width: 200 * widthConstant,
      flex: 1,
      resizable: true,
      cellRendererFramework: ({data: {natureOfCargo}}) => {
        return <div className={styles.sessionTime}>{natureOfCargo}</div>;
      },
    },
  ];

  const formatDate = date => moment(date).format('MMM Do YYYY');
  const rowStyle = {border: 'none', cursor: 'pointer'};
  const getRowStyle = params => {
    // if (params.node.rowIndex % 2 === 0) {
    //   return {background: '#f6f6f6', border: 'none'};
    // }
  };

  return (
    <>
      <ReactTooltip
        html={true}
        className={styles.toolTip}
        place="top"
        type="info"
        effect="solid"
        multiline={true}
      />
      {materialTypeId === 2 ? (
        <div className={styles.cardWrapper}>
          <div>
            <img
              src={adminImages.truckIconBlue}
              className={styles.truckIcon}
              alt="icon"
            />
          </div>
          <div className={styles.info}>
            <div>
              <div className={styles.requestKey}>
                {'shipping Bill Number'} :
                <span className={styles.requestLabel}>
                  {' '}
                  {shippingBillNumber}{' '}
                </span>
              </div>
              <div className={styles.requestKey}>
                {'FOB Value'} :{fobvalue}
                <span className={styles.requestLabel}></span>
              </div>
              <div className={styles.requestKey}>
                {'HS Code'} :
                <span className={styles.requestLabel}> {hscode} </span>
              </div>
            </div>
            <div>
              <div className={styles.requestKey}>
                {'Nature Of Cargo'} :{' '}
                <span className={styles.requestLabel}>
                  {natureOfCargoDetails?.name}
                </span>
              </div>
              <div className={styles.requestKey}>
                {'Total Weight/Number Of Units'}:
                <span className={styles.requestLabel}>
                  {totalWeightNumberOfUnits}{' '}
                </span>
              </div>
              <div className={styles.requestKey}>
                {'Units Of Weight'}:
                <span className={styles.requestLabel}>
                  {unitOfWeightDetails ? unitOfWeightDetails?.name : ''}{' '}
                </span>
              </div>
            </div>
            <div>
              {/* <div className={styles.requestKey}>
                {' '}
                {'CHA Code'} :
                <span className={styles.requestLabel}>{chacode}</span>
              </div> */}
              <div className={styles.requestKey}>
                {'Vessel Name'} :
                <span className={styles.requestLabel}>{vesselName}</span>
              </div>
              <div className={styles.requestKey}>
                {'Cargo Description'} :
                <span
                  data-place="top"
                  data-tip={cargoDescription}
                  className={styles.requestLabel}>
                  {' '}
                  {cargoDescription?.substring(0, 17) + '...'}{' '}
                </span>
              </div>
            </div>
            <div>
              <div className={styles.requestKey}>
                {'Carting Permission Date'}:
                <span className={styles.requestLabel}>
                  {' '}
                  {formatDate(cartingPermissionDate)}{' '}
                </span>
              </div>
              <div className={styles.requestKey}>
                {'Carting Permission Number'}:{' '}
                <span className={styles.requestLabel}>
                  {cartingPermissionNumber}{' '}
                </span>
              </div>
              <div className={styles.requestKey}>
                {'Carting Permission Document'} :
                <span className={styles.requestLabel}>
                  {' '}
                  {'document'}
                  {cartingPermissionDocument ? (
                    <a
                      onClick={() =>
                        onClick('document-view', cartingPermissionDocument)
                      }>
                      <img className={styles.documentIcon} src={documentIcon} />
                    </a>
                  ) : null}{' '}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {materialTypeId === 1 ? (
        <div className={styles.cardWrapper}>
          <div>
            <img
              src={adminImages.truckIconBlue}
              className={styles.truckIcon}
              alt="icon"
            />
          </div>
          <div className={styles.info}>
            <div>
              <div className={styles.requestKey}>
                {'IGM Number'} :
                <span className={styles.requestLabel}> {igmnumber} </span>
              </div>
              <div className={styles.requestKey}>
                {'IGM Date'} :{formatDate(igmdate)}
                <span className={styles.requestLabel}></span>
              </div>
              <div className={styles.requestKey}>
                {'IMO Number'} :
                <span className={styles.requestLabel}> {imonumber} </span>
              </div>
            </div>
            <div>
              <div className={styles.requestKey}>
                {'VCN Number'} :{' '}
                <span className={styles.requestLabel}>{vcnnumber}</span>
              </div>
              <div className={styles.requestKey}>
                {'Out of Charge Number'}:
                <span className={styles.requestLabel}>{outOfChargeNo} </span>
              </div>
              {/* <div className={styles.requestKey}>
                <span className={styles.requestLabel}>
                  {'CHA'}
                  {cha}{' '}
                </span>
              </div> */}
            </div>
            <div>
              {/* <div className={styles.requestKey}>
                {' '}
                {'CHA Code'} :
                <span className={styles.requestLabel}>{chacode}</span>
              </div> */}
              <div className={styles.requestKey}>
                {'Vessel Name'} :
                <span className={styles.requestLabel}>{vesselName}</span>
              </div>
              <div className={styles.requestKey}>
                {'Bill Of Entry No'} :
                <span className={styles.requestLabel}> {billOfEntryNo} </span>
              </div>
            </div>
            <div>
              {/* <div className={styles.requestKey}>
                {'Vessel Type'}:
                <span className={styles.requestLabel}> {vesselType} </span>
              </div> */}
              <div className={styles.requestKey}>
                {'Delivery Order'}:
                <span className={styles.requestLabel}>{deliveryOrder} </span>
              </div>
              <div className={styles.requestKey}>
                {'Delivery Order Date'} :
                <span className={styles.requestLabel}>
                  {' '}
                  {formatDate(deliveryOrderDate)}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {materialTypeId === 1 ? (
        <div className={styles.cardWrapper}>
          <div className={['ag-theme-balham', styles.tableWrapper].join(' ')}>
            <AgGridReact
              checkboxSelection={true}
              rowStyle={rowStyle}
              getRowStyle={getRowStyle}
              rowHoverHighlight={true}
              columnDefs={columnDefs}
              rowHeight={50 * heightConstant}
              headerHeight={50 * heightConstant}
              defaultColDef={{sortable: true}}
              rowClass={[styles.gridRowClass, styles.importDataTable]}
              rowData={lineInfo}></AgGridReact>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default AgencyMaterialDetails;
