import strings from '../../../globalization';
export const getFormData = () => {
  return [
    {
      elementType: 'text',
      // keyboardType: 'default',
      inputType: 'name',
      label: strings.masterAdminName,
      placeholder: strings.masterAdminNamePlaceholder,
      required: true,
      //name: 'name',
      stateField: 'fullName',
      error: ' At Least Four Characters Required',
      stateDefault: '',
      minLength: 4,
      maxLength: 120,
      dependentOnOtherFields: false,
    },
    {
      inputType: 'phone',
      elementType: 'phone',
      keyboardType: 'number-pad',
      label: strings.personPhoneNumber,
      placeholder: strings.personPhoneNumber,
      required: true,
      stateField: 'phoneNumber',
      stateDefault: '',
      maxLength: 10,
      dependentOnOtherFields: false,
    },
    {
      elementType: 'text',
      keyboardType: 'default',
      inputType: 'email',
      label: strings.emailId,
      placeholder: strings.enterEmailAddress,
      stateField: 'emailAddress',
      id: 'emailAddress',
      required: true,
      stateDefault: '',
      maxLength: 50,
    },
    {
      elementType: 'text',
      inputType: 'alphabets',
      label: 'Designation',
      placeholder: 'Enter Designation',
      required: true,
      stateField: 'designation',
      stateDefault: '',
      maxLength: 20,
      minLength: 3,
    },
    {
      elementType: 'dropdown',
      inputType: 'text',
      label: 'Select Role',
      placeholder: 'Select Role',
      optionsField: 'activeRoles',
      required: true,
      stateField: 'roleId',
      stateDefault: null,
      maxLength: 20,
      error: 'Field is required',
      dependentOnOtherFields: false,
    },
  ];
};
