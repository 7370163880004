import React from 'react';

interface IFlex {
  direction?: 'row' | 'column' | undefined;
  align?: 'center' | 'flex-start' | 'flex-end' | undefined;
  justify?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-evenly'
    | undefined;
  children?: any;
  style?: any;
}
const Flex = ({direction, align, justify, children, style, ...rest}: IFlex) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: direction ? direction : 'row',
        justifyContent: justify ? justify : 'flex-start',
        alignItems: align ? align : 'flex-start',
        ...style,
      }}
      {...rest}>
      {children}
    </div>
  );
};

export default Flex;
