import React from 'react';
import {useRouteMatch, Switch, Route} from 'react-router-dom';
import SideBar from '../components/SideBar';
import {useMediaQuery} from 'react-responsive';
import styles from './styles.module.css';
import AgencyRquests from '../pages/Admin/AgencyRquests';
import PermitRquests from '../pages/Admin/PermitRquests';
import {masterAdminTabs} from './tabs';

import Profile from '../pages/Agency/Profile';

import Gender from '../pages/MasterAdmin/Gender';
import BloodGroup from '../pages/MasterAdmin/BloodGroup';
import VehicleType from '../pages/MasterAdmin/VehicleType';
import VehicleCategory from '../pages/MasterAdmin/VehicleCategory';
import PersonType from '../pages/MasterAdmin/PersonType';
import Department from '../pages/MasterAdmin/Department';
import MaterialType from '../pages/MasterAdmin/MaterialType';
import CargoNature from '../pages/MasterAdmin/CargoNature';
import Roles from '../pages/MasterAdmin/Role';
import WorkPurpose from '../pages/MasterAdmin/WorkPurpose';
import WorkArea from '../pages/MasterAdmin/WorkArea';
import BussinessDetails from '../pages/MasterAdmin/BussinessDetails';
import GateLevelRejectReason from '../pages/MasterAdmin/GateLevelRejectReason';
import UnitOfWeight from '../pages/MasterAdmin/UnitOfWeight';
import WalletBalancesReport from '../pages/MasterAdmin/WalletBalancesReport';

import PassAuditReport from '../pages/MasterAdmin/PassAuditReport';
import RejectionReports from '../pages/Department/RejectionReports';

import PassCategory from '../pages/MasterAdmin/PassCategory';
import PacksByPass from '../pages/MasterAdmin/PacksByPassCategory';
import VehicleMovements from '../pages/MasterAdmin/VehicleMovements';
import DailyProgressMonitoring from '../pages/MasterAdmin/DailyProgressMonitoring';

import PermitsRaisedReport from '../pages/MasterAdmin/PermitsRaisedReport';
import Users from '../pages/MasterAdmin/Users';
import AgencyManagement from '../pages/MasterAdmin/AgencyManagement';

/**
 * @return {JSX.Element}
 */
function MasterAdminRouter(props) {
  const {url} = useRouteMatch();
  console.info('url', url);
  const isMobile = useMediaQuery({maxWidth: 767});

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        {!isMobile ? (
          <div className={styles.sideBar}>
            <SideBar
              module={'admin'}
              header={'Permit Requests'}
              tabs={masterAdminTabs}
            />
          </div>
        ) : null}
        <div className={styles.relative}>
          <div className={styles.bodyWrapper}>
            <Switch>
              <Route path={`${url}/person-type`}>
                <PersonType />
              </Route>
              <Route path={`${url}/gender`}>
                <Gender />
              </Route>
              <Route path={`${url}/blood-group`}>
                <BloodGroup />
              </Route>
              <Route path={`${url}/vehicle-type`}>
                <VehicleType />
              </Route>
              <Route path={`${url}/vehicle-category`}>
                <VehicleCategory />
              </Route>
              <Route path={`${url}/roles`}>
                <Roles />
              </Route>
              <Route path={`${url}/department`}>
                <Department />
              </Route>
              <Route path={`${url}/work-purpose`}>
                <WorkPurpose />
              </Route>
              <Route path={`${url}/work-area`}>
                <WorkArea />
              </Route>
              <Route path={`${url}/business-details`}>
                <BussinessDetails />
              </Route>
              <Route path={`${url}/material-types`}>
                <MaterialType />
              </Route>
              <Route path={`${url}/natureOf-cargo`}>
                <CargoNature />
              </Route>
              <Route path={`${url}/Pass-Category`}>
                <PassCategory />
              </Route>
              <Route path={`${url}/Packs-By-Pass-Category`}>
                <PacksByPass />
              </Route>
              <Route path={`${url}/unit-of-weight`}>
                <div>
                  <UnitOfWeight />
                </div>
              </Route>

              <Route path={`${url}/gate-level-reject-reason`}>
                <GateLevelRejectReason />
              </Route>
              <Route path={`${url}/wallet-balances-report`}>
                <WalletBalancesReport />
              </Route>
              <Route path={`${url}/vehicle-movement-report`}>
                <VehicleMovements />
              </Route>
              <Route path={`${url}/pass-audit-report`}>
                <PassAuditReport />
              </Route>
              <Route path={`${url}/permits-raised-report`}>
                <PermitsRaisedReport />
              </Route>
              <Route path={`${url}/daily-progress-monitoring`}>
                <DailyProgressMonitoring />
              </Route>
              <Route path={`${url}/users`}>
                <Users />
              </Route>
              <Route path={`${url}/agency-management`}>
                <AgencyManagement />
              </Route>
              <Route path={`${url}/profile`}>
                <Profile />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MasterAdminRouter;
