import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import gateIcon from '../../../assets/images/deputy-chairman-icons/gate/image@3x.png';
import personIcon from '../../../assets/images/deputy-chairman-icons/person/image@3x.png';
import vehicleIcon from '../../../assets/images/deputy-chairman-icons/vehicle/image@3x.png';
import personRegistered from '../../../assets/images/deputy-chairman-icons/person-registered/image@3x.png';
import vehicleRegistered from '../../../assets/images/deputy-chairman-icons/vehicle-registered/image@3x.png';
import invalidPermit from '../../../assets/images/deputy-chairman-icons/invalid-permit/image@3x.png';
import eDrishtiLogo from '../../../assets/images/edrishti-logo-white/image@3x.png';
import logoutIcon from '../../../assets/images/logout-icon-admin-portal/image@3x.png';
import deputyChairmanUser from '../../../assets/images/deputy-chairman-user-icon/image@3x.png';
import dateIcon from '../../../assets/images/deputy-chairman-icons/date/image@3x.png';
import permitsRaised from '../../../assets/images/deputy-chairman-icons/permits-raised/image@3x.png';
import van from '../../../assets/images/deputy-chairman-icons/van/image@3x.png';


import moment from 'moment';
import Flex from '../../Flex';
import withPermit from '../../../assets/images/deputy-chairman-icons/with-permit/image@3x.png';

const MetricsLandingPerson = ({ dashboardDetails }) => {
  const [dateString, setDateString] = useState('');
  const [showTime, setShowTime] = useState(false);

  useEffect(() => {
    let hour = moment().hour();
    if (hour >= 0 && hour < 8) {
      let ds = `${moment()
        .subtract(1, 'days')
        .format('Do MMMM YYYY')} - ${moment().format('Do MMMM YYYY')}`;
      setDateString(ds);
      setShowTime(true);
    } else {
      setDateString(moment().format('Do MMM YYYY'));
      setShowTime(false);
    }
  }, []);
  return (
    <div className={styles.topContainer}>
      <div className={styles.containerIcon}>
        <Icon src={dateIcon} />
      </div>
      <p>
        {dateString}
        {showTime && (
          <span style={{ display: 'block', fontSize: '0.8rem' }}>
            8:00 AM to 7:59 AM
          </span>
        )}
      </p>
      <TopMetrics metrics={dashboardDetails} />
      <Flex direction="row" className={styles.topMiddleContainer}>
        <TopMiddleBox
          gateName={dashboardDetails?.today?.['northGatePerson']?.name}
          metrics={dashboardDetails?.today?.['northGatePerson']}
        />

        <TopMiddleBox
          gateName={dashboardDetails?.today?.['northGateBike']?.name}
          metrics={dashboardDetails?.today?.['northGateBike']}
        />
      </Flex>

    </div>
  );
};

export default MetricsLandingPerson;

const MetricsCardTop = ({ icon, number, title, metrics }) => {
  return (
    <Flex style={{ alignItems: 'center' }}>
      <Icon
        src={icon ? icon : van}
        containerClassName={styles.topContainerMetricsIconsContainer}
        imageClassName={styles.topContainerMetricsIcons}
        alt="permits raised"
      />
      <div className={styles.content}>
        <h2 className={styles.number}>{number}</h2>
        <p style={{ marginBottom: '0rem' }}>{title ? title : 'Vehicle '}</p>
      </div>
    </Flex>
  );
};

const TopBottomBox = ({ boxTitle, boxIcon, registered, issued }) => {
  return (
    <div className={styles.topBottomContainerBox}>
      <Flex
        style={{
          minHeight: '2rem',
          backgroundColor: '#ECF0F9',
          alignItems: 'center',
        }}>
        <Icon
          src={boxIcon ? boxIcon : personIcon}
          containerClassName={styles.topBottomIconContainer}
        />
        <p className={styles.bigCardHeaderText}>{boxTitle ? boxTitle : '-'}</p>
      </Flex>
      <Flex className={styles.topBottomContainerBoxBottom}>
        <Icon
          src={personRegistered}
          imageStyle={{ height: '2rem' }}
          containerClassName={styles.topBottomIconContainer}
        />
        <p>Registered</p>
        <p className={styles.number}>{registered}</p>
        <DivideY color={`#ECF0F9`} />
        <Icon
          src={withPermit}
          imageStyle={{ height: '2rem' }}
          containerClassName={styles.topBottomIconContainer}
        />
        <p>Passes Issued</p>
        <p className={styles.number}>{issued}</p>
      </Flex>
    </div>
  );
};

const Icon = ({ src, containerClassName, imageClassName, imageStyle, alt }) => {
  return (
    <div className={[styles.iconContainer, containerClassName].join(' ')}>
      <img
        src={src ? src : dateIcon}
        alt={alt ? alt : 'date'}
        style={imageStyle}
        className={[styles.genericIcon, imageClassName].join(' ')}
      />
    </div>
  );
};

const TopMetrics = ({ metrics }) => {
  return (
    <div className={styles.topContainerMetricsContainer}>
      <div
        className={styles.topContainerMetricsContainerItem}
        style={{ width: '25%', marginRight: '20px' }}>
        <Icon
          src={permitsRaised}
          containerClassName={styles.topContainerMetricsIconsContainer}
          imageClassName={styles.topContainerMetricsIcons}
          alt="permits raised"
        />
        <div className={styles.content}>
          <h2 className={styles.number}>
            {metrics?.today?.totalPermitsRaisedToday}
          </h2>
          <p>Permits Raised</p>
        </div>
      </div>
      <div
        className={styles.topContainerMetricsContainerItem}
        style={{ width: '75%', justifyContent: 'space-evenly' }}>
        <MetricsCardTop
          icon={personIcon}
          title={`Persons `}
          number={metrics?.today?.totalPedestrianEntries}
        />
        <DivideY />
        <MetricsCardTop
          icon={withPermit}
          title={`With Permit`}
          number={metrics?.today?.pedestrianEntriesWithPermit}
        />
        <DivideY />
        <MetricsCardTop
          icon={invalidPermit}
          title={`Invalid `}
          number={metrics?.today?.pedestrianEntriesWithOutPermit}
        />
      </div>
    </div>
  );
};

const DivideY = ({ color }) => {
  return (
    <div
      style={{
        height: '60px',
        width: '1px',
        backgroundColor: color ? color : '#4168C6',
      }}
    />
  );
};



const TopMiddleBox = ({ gateName, metrics }) => {

  return (
    <div className={styles.topMiddleBox}>
      <Flex style={{ alignItems: 'center', marginBottom: '0.4rem' }}>
        <img
          src={gateIcon}
          className={styles.gateIcon}
          style={{ marginRight: '0.5rem' }}
          alt={`gate`}
        />
        <p className={styles.topMiddleContainerHeader}>
          {gateName ? gateName : '-'}
        </p>
      </Flex>
      <Flex className={styles.topMiddleContainerBox}>
        <Flex className={styles.topMiddleContainerBoxInner}>
          <TopMiddleInnerBox
            title={`Person `}
            number={metrics?.permits}
            icon={personIcon}
            className={styles.borderRight}
          />
          <TopMiddleInnerBox
            title={`With Permits`}
            number={metrics?.withPermits}
            icon={withPermit}
            bgColor="#A8DCBF"
            className={styles.borderRight}
          />
          <TopMiddleInnerBox
            title={`Invalid `}
            number={
              metrics?.invalidPermits?.toString() ||
              metrics?.invalidEntries?.toString()
            }
            icon={invalidPermit}
            bgColor="#EBB78C"
            className={styles.borderRight}
          />
        </Flex>
      </Flex>
    </div>
  );
};

const TopMiddleInnerBox = ({
  icon,
  iconAlt,
  number,
  title,
  className,
  bgColor,
  metrics,
}) => {
  return (
    <div
      className={className}
      style={{ minHeight: '3rem', width: '5rem', flex: 1 }}>
      <div
        style={{
          height: '1.8rem',
          width: '100%',
          backgroundColor: bgColor ? bgColor : '#C7D7FE',
          position: 'relative',
        }}>
        <Icon
          src={icon ? icon : van}
          containerClassName={styles.topMiddleMetricsIconContainer}
          style={{ position: 'absolute' }}
          imageClassName={styles.topContainerMetricsIcons}
          alt={iconAlt ? iconAlt : '-'}
        />
      </div>

      <div className={styles.topMiddleContainerBoxBody}>
        <p className={styles.topMiddleContainerBoxNumber}>{number}</p>
        <p className={styles.topMiddleContainerBoxTag}>{title ? title : '-'}</p>
      </div>
    </div>
  );
};
