import React from 'react';
import styles from './Person.module.scss';
import moment from 'moment';
import {adminImages, loginImages} from '../../assets/images';
import documentIcon from '../../../src/assets/images/document-icon/image.png';
import strings from '../../globalization';
/**
 *
 * @param {Boolean} error
 * @param {Boolean} success
 * @return {JSX.Element}
 */

/**
 *
 * @param {Props} props
 * @return {JSX.Element}
 */
function SignatoryDetails(props) {
  console.log('signatory details', props);
  const {
    rfidnumber,
    GSTNumber,
    businessName,
    address1,
    address2,
    name,
    companyDocuments,
    departmentName,
    emailAddress,
    phoneNumber,
    designation,
    firstName,
    authorizedDocument,
    idProofDocument,
    onClick,
  } = props || {};
  const formatDate = date => moment(date).format('MMM Do YYYY');
  return (
    <div className={styles.cardWrapper}>
      <div className={styles.info}>
        <div>
          <div className={styles.requestKey}>
            Name :<span className={styles.requestLabel}> {firstName} </span>
          </div>
          <div className={styles.requestKey}>
            {'Designation'} :{' '}
            <span className={styles.requestLabel}>{designation}</span>
          </div>
          <div className={styles.requestKey}>
            {'Authorized Document '} :{' '}
            {authorizedDocument ? (
              <span className={styles.requestLabel}>
                Document
                <a onClick={() => onClick('document-view', authorizedDocument)}>
                  <img
                    className={styles.documentIcon}
                    alt="."
                    src={documentIcon}
                  />
                </a>
              </span>
            ) : (
              '-'
            )}
          </div>
        </div>
        <div>
          <div className={styles.requestKey}>
            {'Mobile Number'} :{' '}
            <span className={styles.requestLabel}>
              {phoneNumber ? phoneNumber : null}
            </span>
          </div>
          <div className={styles.requestKey}>
            {'-'}
            <span>{''}</span>
          </div>
          <div className={styles.requestKey}>
            {'Identity Proof Document'}:{' '}
            {idProofDocument ? (
              <span className={styles.requestLabel}>
                {'Document'}
                <a onClick={() => onClick('document-view', idProofDocument)}>
                  <img
                    className={styles.documentIcon}
                    alt="."
                    src={documentIcon}
                  />
                </a>
              </span>
            ) : (
              '-'
            )}
          </div>
        </div>
        <div>
          <div className={styles.requestKey}>
            {'Email'} :
            <span className={styles.requestLabel}> {emailAddress} </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignatoryDetails;
