const english = {
  // LOGIN
  title: 'Deendayal Port Trust',
  subTitle: 'e-Drishti',
  login: ' Login',
  invalidUsernameOrPassword: 'Invalid username or password',
  createNew: ' Create New',
  rememberMe: 'Remember me',
  forgotPassword: 'Forgot password',
  dontHaveAgentAccount: 'Don’t have an Agency Account?',
  vehicleRegistration: 'Vehicle Registration',
  personRegistration: 'Person Registration',
  requestFreePermit: 'Request Free Permit',
  requestForVisitorPermit: 'Request For Visitor Permit',
  checkApplicationStatus: 'Check Application Status',
  manageRegistrationInfo: 'Manage Registration Information',
  checkPermitStatus: 'Check Permit Status',

  personName: 'Person Name',
  password: 'Password',
  enterName: 'Enter name',
  enterFullName: 'Enter Full Name',
  enterPassword: 'Enter password',
  userId: 'User ID',
  enterUserId: 'Enter User ID',
  sendOtp: 'Send OTP',
  resendOtp: 'Resend OTP',
  privacyPolicy: 'Privacy Policy',
  termsOfUse: 'Terms of Use',
  helpCenter: 'Help Center',
  useFulResource: 'Useful Resources',
  copyRight: '© Copyright 2024. sanna. All rights reserved.',
  or: 'or',
  verifyOTP: 'Verify OTP',

  //FORGOT PASSWORD
  enterOTP: 'Enter OTP',

  // NEW AGENCY REGISTRATION
  newAgencyRegistration: 'New Agency Registration',
  authorizedUsers: 'Authorized Users',
  agencyDetails: 'Agency Details',
  step2: 'Step 2/2',
  step1: 'Step 1/2',
  signatory1: 'Signatory 1',
  signatory2: 'Signatory 2',
  signatory3: 'Signatory 3',
  next: 'Next',
  selectDepartment: 'Select department',
  department: 'Department',
  agencyPhoneNumber: 'Agency Mobile Number',
  enterPhoneNumber: 'Enter Mobile Number',
  licenseNo: 'License No.',
  selectLicenseNumber: 'Select license number',
  natureOfBusiness: 'Nature of Business',
  selectNatureOfBussiness: 'Select nature of business',
  address2: 'Address 2',
  enterAddress: 'Enter address',
  companyRegistrationProof: 'Company Registration Proof',
  chooseFile: 'Choose file (Max: 2 MB)',
  agencyName: 'Agency Name',
  enterAgencyName: 'Enter agency name',
  agencyEmailAddress: 'Agency Email Address',
  enterEmailAddress: 'Enter email address',
  licenseValidUpto: 'License Valid Upto',
  selectDate: 'Select date',
  address1: 'Address 1',
  gstNumber: 'GST Number',
  enterGSTNumber: 'Enter GST number',

  // VEHICLE REGISTRATION
  vehicleOwnerName: 'Vehicle Owner Name',
  enterVehicleOwnerName: 'Enter vehicle owner name',
  ownerPhoneNumber: 'Owner Mobile Number',
  enterVehicleOwnerPhoneNumber: 'Enter vehicle owner Mobile Number',
  vehicleRCNumber: 'Vehicle RC Number',
  enterRCNumber: 'Enter RC number',
  vehicleInsuranceNumber: 'Vehicle Insurance Number',
  enterInsuranceNumber: 'Enter insurance number',
  vehiclePollutionNumber: 'Vehicle Pollution Number',
  enterPollutionNumber: 'Enter Pollution Number',
  vehicleCategory: 'Vehicle Category',
  enterVehicleCategory: 'Enter vehicle category',
  vehicleType: 'Vehicle Type',
  enterVehicleType: 'Enter vehicle type',
  vehicleEngineNumber: 'Vehicle Engine Number',
  enterEngineNumber: 'Enter engine number',
  vehicleRegistrationNumberField: 'Vehicle Registration Number',
  vehicleRegistrationPlaceholder: 'Enter vehicle registration number',
  vehicleManufacturingYearField: 'Vehicle Manufacturing Year',
  vehicleManufacturingYearPlaceholder: 'Enter Vehicle Manufacturing Year',
  vehicleMakeAndModelField: 'Vehicle Make and Model',
  vehicleMakeAndModelPlaceholder: 'Enter Vehicle Make and Model',
  vehicleRCValidity: 'Vehicle RC Validity',
  vehicleInsuranceValidity: 'Vehicle Insurance Validity',
  pucValidity: 'PUC Validity',
  fitnessCertificateValidity: 'Fitness Certificate Validity',

  ownerEmail: 'Owner Email',
  enterVehicleOwnerEmail: 'Enter vehicle owner email',
  vehicleNumber: 'Vehicle Number',
  enterVehicleNumber: 'Enter vehicle number',
  vehicleRC: 'Vehicle RC',
  vehicleInsuranceCertificate: 'Vehicle Insurance Certificate',
  pUCCertificate: 'PUC Certificate',
  vehicleChassisNumber: 'Vehicle Chassis Number',
  enterChassisNumber: 'Enter chassis number',
  fitnessCertificate: 'Fitness Certificate',

  verifyMobNUmber: 'Verify Your Mobile Number',
  message: 'Enter 6-digit code sent to your mobile number ending with ***',
  // REGISTRATION SUCCESS
  successfullySubmitted: 'Successfully Submitted',
  yourReferenceNumberIs: 'Your reference number is',
  pleaseKeepNoteOfIt: ' please keep a note of it',
  youWillReceiveMessage:
    ' You will receive a message with reference details to your mobile',
  youCanCheckTheStatus: ' number.',
  here: 'here',
  back: 'Back',
  termsAndConditions: 'terms and conditions',
  acceptAll: ' Accept all',
  goBackToLogin: 'Go back to Login',

  // RESET PASSWORD
  resetPassword: 'Reset Password',
  passwordDoseNotMatch: 'Password does not match',
  passwordStartsWithSpace: 'Password should not start with space',

  newPassword: 'New Password',
  enterNewPassword: 'Enter New password',
  confirm: 'Confirm',
  confirmPassword: 'Confirm password',

  // PERSON REGISTRATION
  personTypeField: 'Person Type',
  personTypePlaceholder: 'Select Person Type',
  countryField: 'Country',
  countryPlaceholder: 'Select Country',
  documentTypeField: 'Document Type',
  documentTypePlaceholder: 'Select Document Type',
  uploadDocumentField: 'Upload Proof Document',
  uploadDocumentPlaceholder: 'Choose File (Max: 2 MB)',
  bloodGroup: 'Blood Group',
  bloodGroupPlaceholder: 'Select Blood Group',
  safetyCertificateValidity: 'Safety Certificate Validity',
  medicalCertificateValidity: 'Medical Certificate Validity',
  policeNOCValidity: 'Police NOC Validity',
  dob: 'Date of Birth',
  personName: 'Person Name',
  enterName: 'Enter name',
  personPhoneNumber: 'Person Mobile Number',
  selectGender: 'Select gender',
  documentProofNumber: 'Document Proof Number',
  documentProofNumberPlaceHoler: 'Enter Aadhaar/PAN/Driving License/ID',
  documentValidity: 'Document Validity',
  uploadSafetyCertificate: 'Upload Safety Certificate',

  officialContractDocument: 'Upload contract document',
  officialContractDocumentValidity: 'Contract document validity',

  selectDocument: 'Select document (Max: 2 MB)',
  uploadMedicalCertificate: 'Upload Medical Certificate',
  selectMedicalCertificate: 'Choose File (Max: 2 MB)',
  uploadPoliceNOC: 'Upload Police NOC',
  selectPoliceNOC: 'Choose File (Max: 2 MB)',
  personEmail: 'Person Email',
  enterEmail: 'Enter person email',
  individualPhoto: 'Individual Photo',
  chooseFile: 'Choose file (Max: 2 MB)',

  // Vehicle Registration
  ownerNameField: 'Vehicle Owner Name',
  ownerNamePlaceholder: 'Enter vehicle owner name',

  ownerEmailField: 'Owner Email',
  ownerEmailPlacholder: 'Enter vehicle owner email',

  ownerPhoneNumberField: 'Owner Mobile Number',
  ownerPhoneNumberPlaceholder: 'Enter owner mobile number',

  vehicleNumberField: 'Vehicle Number',
  vehicleNumberPlaceholder: 'Enter Vehicle Number',

  vehicleRCNumberField: 'Vehicle RC Number',
  vehiicleRCNumberPlaceholder: 'Enter Vehicle RC Number',

  vehicleRCField: 'Vehicle RC',
  vehiceRCPlaceholder: 'Choose file (Max: 2 MB)',

  vehicleInsuranceNumberField: 'Vehicle Insurance Number',
  vehicleInsuranceNumberPlaceholder: 'Enter Insurance Number',

  vehicleInsuranceCertificateField: 'Vehicle Insurance Certificate',
  vehicleInsuranceCertificatePlaceholder: 'Choose file (Max: 2 MB)',

  vehiclePollutionNumberField: 'Vehicle Pollution Number',
  vehiclePollutionNumberPlaceholder: 'Enter Pollution Number',

  PUCCertificateField: 'PUC Certificate',
  PUCCertificatePlaceholder: 'Choose file (Max: 2 MB)',

  vehicleTypeField: 'Vehicle Type',
  vehicleTypePlaceholder: 'Enter Vehicle Type',

  vehicleChassisNumberField: 'Vehicle Chassis Number',
  vehicleChassisNumberPlaceholder: 'Enter Vehicle Chassis Number',

  vehicleEngineNumberField: 'Vehicle Engine Number',
  vehicleEngineNumberPlaceholder: 'Enter Engine Vehicle Number',

  fitnessCertificateField: 'Fitness Certificate',
  fitnessCertificatePlaceholder: 'Choose file (Max: 2 MB)',

  // CHECK APPLICATION STATUS
  requestTypeLabel: 'Request Type',
  requestTypePlaceholder: 'Enter Request Type',
  referenceNumberLabel: 'Reference Number',
  referenceNumberPlaceholder: 'Enter Reference Number',
  forgotReferenceNumber: ' Forgot reference number?',
  checkStatusButton: 'Check Status',

  registrationRequestApproved: 'Registration Request Approved',
  reason: 'Reason',
  youShouldHaveAlreadyReceived: `You should have already received reference number to your registered
  mobile number, if not`,
  clickHere: 'click here',
  registrationRequestPending: 'Registration Request Pending',
  yourRequestIsStillPending: `Your request is still pending, you will receive a message to your
  registered mobile number.`,
  registrationRequestRejected: 'Registration Request Rejected',
  loginDetailsSent:
    'Login credentials have been successfully sent to your registered mobile number',

  // Popup
  pleaseSelectRequestType: 'Please select request type',
  pleaseEnterReferenceNumber: 'Please Enter a Reference Number',

  getReferenceNumber: 'Get Reference Number',
  proofDocumentTypeSubmitted: 'Proof Document Type Submitted',
  proofDocumentTypeSubmittedLabel: 'Enter Proof Document type',
  documentNumberLabel: 'Document Number',
  documentNumberPlaceholder: 'Enter Document Number',
  referenceNumberSent:
    'Reference Number have been successfully sent to your registered mobile number',

  okayPopup: 'Okay!',
  submitRequest: 'Submit Request',
  agencyLandlineNumber: 'Agency Landline Number',
  agencyLandlineNumberPlaceholder: 'Enter Landline Number',
  agencyMobileNumber: 'Agency Mobile Number',
  agencyMobileNumberPlaceholder: 'Enter Mobile Number',
  designation: 'Designation',
  designationPlaceholder: 'Enter Designation',
  gstDocument: 'GST Document',

  name: 'Name',
  namePlaceholder: 'Enter Name',
  mobileNumber: 'Mobile Number',
  mobileNumberPlaceholder: 'Enter Mobile Number',
  emailAddress: 'Email Address',
  emailAddressPlaceholder: 'Enter Email Address',
  cancelPopup: 'Cancel',
  retryApiPopup: 'Retry',

  // OPERATOR
  operatorName: 'Operator Name',
  emailId: 'Email ID',

  address: 'Address',
  loginId: 'Login ID',
  shift: 'Shift',
  shift1: 'Shift 1',
  shift2: 'Shift 2',
  shift3: 'Shift 3',

  operatorId: 'Operator ID',
  gender: 'Gender',
  aadharNumber: 'Aadhaar Number',
  pincode: 'Pincode',
  role: 'Role',
  location: 'Location',
  startTime: 'Start Time',
  endTime: 'End Time',
  maxOperatorsLimit: 'Max Operators Limit',
  enterMaxOperatorsLimit: 'Enter Max Operators Limit',
  locationId: 'Location ID',
  enterLocationId: 'Enter Location ID',
  locationName: 'Location name',
  enterLocationName: 'Enter Location Name',
  numberOfShifts: 'No. of Shifts',
  selectShift: 'Select Shift',

  languagePreference: 'Language Preference',
  english: 'English',
  hindi: 'हिंदी',
  approve: 'Approve',
  keepOnHold: 'Keep on hold',
  surrenderCard: 'Surrender Card',
  edit: 'Edit',

  searchBar: 'Search By Name',

  DEPT: {
    agencyRegistrationRequest: 'Agency Registration Requests',
  },

  //ADMIN
  //Admin agency requests
  ADMIN: {
    agencyRequest: 'Agency Permit Requests',
    agencyName: 'Agency Name',
    Status: 'Status',
    dateRange: 'Date Range',
    clear: 'clear',
    apply: 'Apply',
    reconcileAll: 'Reconcile All',
    referenceNumber: 'Reference Number',
    agencyName: 'Agency Name',
    purposeOfWork: 'Purpose of Work',
    areaOfWork: 'Area of Work',
    requestType: 'Request Type',
    visitingDate: 'Visiting Date',
    refresh: 'Refresh',
    POP_UP: {
      RFIDTagNumber: 'RFID Tag Number',
      agencyRequest: 'Agency Request',
      visitingDate: 'Visiting Date',
      purposeofWork: 'Purpose of Work',
      areofWork: 'Area of Work',
      department: 'Department',
      passValidityPeriod: 'Pass Validity Period',
      personDetails: 'Person Details',
      name: 'Name',
      email: 'Email',
      phoneNo: 'Phone No',
      gender: 'Gender',
      dateOfBirth: 'Date of Birth ',
      country: 'Country',
      nationality: 'Nationality',
      personType: 'Person Type ',
      documnetType: 'Document Type',
      documentProofNumber: 'Document Proof Number',
      rFIDcardNumber: 'RFID card Number',
      document: 'Document',
      vehicleDetails: 'Vehicle Details',
      vehicleOwnerName: 'Vehicle Owner Name',
      ownerEmail: 'Owner Email',
      ownerPhoneNumber: 'Owner Mobile Number',
      vehicleType: 'Vehicle Type',
      vehicleNumber: 'Vehicle Number',
      vehicleRCNumber: 'Vehicle RC Number',
      vehicleRC: 'Vehicle RC',
      vehicleInsuranceNumber: 'Vehicle Insurance Number',
      vehiclePollutionNumber: 'Vehicle Pollution Number',
      vehicleEngineNumber: 'Vehicle Engine Number',
      vehicleChassisNumber: 'Vehicle Chassis Number',
      vehicleInsuranceCertificate: 'Vehicle Insurance Certificate',
      pUCCertificate: 'PUC Certificate',
      fitnessCertificate: 'Fitness Certificate',
      driverDetails: 'Driver Details',
      toReject: 'To reject please enter the reason for rejection',
      rejectCnf1: 'Permit Request  with reference number',
      rejectRegistrationCnf1: 'Registration request  with reference number',

      rejectCnf2: 'has been Successfully Rejected',
      approveCnf1: 'Permit Request  with reference number',
      approveRegistrationCnf1: 'Registration request with reference number',
      approveCnf2: 'has been Successfully Approved',
      errorMessage:
        'There was an error processing your request. Please try again later.',
      back: 'Back',
      okay: 'Okay',
      reject: 'Reject',
      approve: 'Approve',

      // Operator module
      referenceNumber: 'Reference Number',
    },
  },

  AGENCY: {
    DASH_BOARD: {
      dashBoard: 'Dashboard',
      permitsApplied: 'Permits Applied',
      permitsPending: 'Permits Pending',
      permitsApproved: 'Permits Approved',
      permitsRejected: 'Permits Rejected',
      addPermit: '+ Add Permit',
    },
    PERMITS: {
      vehicleRegistrationNumber: 'Vehicle Registration Number',
      permits: 'Permits',
      requestNemPermit: 'Request New Permit',
      pending: 'Pending',
      approvedRejected: 'Approved/Rejected',
      // Request New Permit
      visitingDate: 'Visiting Date',
      purposeOfWork: 'Purpose of Work',
      department: 'Department',
      areaOfWork: 'Area of Work',
      passValidity: 'Pass Validity',
      policeVerificationDocument: 'Police Verification Document',
      addPerson: 'Add Person',
      addVehicle: 'Add Vehicle',
      clear: 'Clear',
      submitRequest: 'Submit Request',
      // pending/Approved/rejected tabs
      requestType: 'Request Type',
      dateRange: 'Date Range',
      apply: 'Apply',
      referenceNumber: 'Reference Number',
      requestDate: 'Request Date',
      visitDate: 'Visit Date',
      noOfRequests: 'No. Of Requests',
      status: 'Status',
    },
    REPORTS: {},
  },

  // Operator
  areYouSureSurrender: 'Are you sure you want to surrender this card?',
  titlePersonsCard: 'Persons Card',
  areYouSureIssue: 'Are you sure you want to issue this card?',
  areYouSureReject: 'Are you sure you want to reject this card?',
  history: 'History',
  pleaseTakeThePicture: 'Please take the picture.',
  remarksCantBeEmpty: 'Remark field cannot be empty.',
  typeYourRemarks: 'Type your remarks here...',
  currentStatus: 'Current Status',
  phoneNumber: 'Phone Number',
  phoneNumberLabel: 'Phone Number',

  rfidCardNumber: 'RFID Card Number',
  enterRfidNumber: 'Enter RFID Number',
  dateOfBirth: 'Date of Birth',
  selectCountry: 'Select Country',
  nationality: 'Nationality',
  enterNationality: 'Enter Nationality',

  vehiclesCard: 'Vehicles Card',

  // Success
  newCardsWithRFID: 'New cards with RFID No.',
  hasBeenApprovedFor: 'has been approved for',
  remarks: 'Remark',
  searchWithRfid: 'Search with reference number or RFID number to get details ',
  person: 'Person',
  hasBeenPutOnHoldForPerson: 'has been put on hold for Person',
  RFIDSurrendered: 'RFID Card has been surrendered',

  vehicleOwnerEmail: 'Vehicle Owner Email',
  vehicleOwnerPhoneNumber: 'Owner Mobile Number',
  vehicleRegtNumber: 'Vehicle Regt. Number',
  keepOnHold: 'Keep on hold',
  surrenderCard: 'Surrender Card',
  requestDate: 'Request Date',
  status: 'Status',
  viewHistory: 'View History',
  pleaseVerify: 'Please verify all the documents',
  pleaseAssignRFID: 'Please assign an RFID number',
  verifiedPerson: 'Verified Person Details',
  verifiedProof: 'Verified Proof Document',
  pleaseEnterRFID: 'Please Enter RFID Card Number',

  paymentDetails: 'Payment Details',
  registrationAmount: 'Registration Amount',
  gst: 'GST',
  pay: 'Pay',
  total: 'Total',
  tryAgain: 'Try Again',
  payLater: 'Go Back to Home',

  companyName: 'Company name',
  enterCompanyName: 'Enter Company Name',

  aadharNumberField: 'Aadhaar Number',
  aadharNumberPlaceholder: 'Enter Aadhaar Number',

  aadharNumberDocumentField: 'Aadhaar Document',
  aadharNumberDocumentPlaceholder: 'Select File (Max: 2 MB)',

  drivingLicenseField: 'Driving Licence Number',
  drivingLicensePlaceholder: 'Enter Driving Licence Number',

  drivingLicenseDocumentField: 'Upload Driving Licence',
  drivingLicenseDocumentPlaceholder: 'Select File (Max: 2 MB)',

  drivingLicenseValidity: 'Driving Licence Validity',
  drivingLicenseValidityPlaceholder: 'Select File (Max: 2 MB)',

  PANNumber: 'Pan Number',
  enterPANNumber: 'Enter PAN Number',

  PANDocument: 'Pan Document',
  enterPANDocument: 'Select File (Max: 2 MB)',
  authorizedDocument: 'Authorized Document',
  documentValiditySignatory: 'Document Validity (if any)',
  identityProofDocument: 'Identity Proof Document',

  masterAdminName: 'Name in English',
  masterAdminNamePlaceholder: 'Enter Name in English',
  masterAdminNameHindi: 'Name in Hindi',
  masterAdminNameHindiPlaceholder: 'Enter Name in Hindi',

  adminStatus: 'Status',
  adminStatusPlaceholder: 'Select Status',
  //CSVpopup
  CSVpopup: {
    okay: 'Okay',
    cancel: 'Cancel',
    message: 'Click on okay to finish download',
  },
  //Traffic department
  permitRequest:
    'This table represents all the permit requests raised for the person or vehicle types. The department users have to approve/reject the permits from this table. When clicked on the individual row, the entire permit details are popped-up.',
  vehicleMovement:
    'This downloadable report is used to track every vehicle entry into the gate. Filters- CHA, status, date, and timestamp wise.',
  dayWiseReports:
    'A downloadable high-level overview report of the number of permits raised for the day, registrations (agency, vehicle, and person)',
  permitsRaised:
    'A downloadable report with the number of permits raised in the day with any visiting date-CHA wise. Filters- CHA, date, and timestamp.',
  AgencyRegsReq:
    'This table represents the registration requests raised by the Agencies for their account activation. The department users have to approve/reject the request based on their own department criteria. When clicked on the individual row, the entire registration details of the agency are popped-up.',
  groundStock: 'description of GroundStock',
  regRequest:
    'This table lists all the registration requests (person and vehicle) to be approved/rejected by the account user. Filters- Type, Status, Search bar, Date Range',
  cargoRequest: 'description of cargo requests',
};

export default english;
