import React from 'react';
import styles from './styles.module.scss';

import Flex from '../../../../../../components/Flex';
import PermitsHeader from '../../../../../../components/PermitsHeader';

import FormElement from '../../../../../../components/FormElement';
// import Button from '../../../../../../components/Button';
import strings from '../../../../../../globalization';
import { AgGridReact } from 'ag-grid-react';
import {
  operatorImages,
  loginImages,
  commonImages,
} from '../../../../../../assets/images';

import dimensions from '../../.../../../../../../util/dimensions-util';
import './styles.css';
import SannaLogo from '../../../../../../components/SannaLogo';
const { vw: widthConstant, vh: heightConstant } = dimensions;

const tabs = [
  {
    label: strings.AGENCY.PERMITS.requestNemPermit,
    id: 1,
    route: 'request-new-permits',
  },
  {
    label: strings.AGENCY.PERMITS.pending,
    id: 2,
    route: 'pending-permits',
  },
  {
    label: strings.AGENCY.PERMITS.approvedRejected,
    id: 3,
    route: 'approved-rejected-permits',
  },
];

export default function Desktop({
  containerClassNames,
  onChangeHandler,
  stateData,
  formData,
  handleUpload,
  checkDisabledForm,
  onClick,
  persons,
  vehicles,
  materials,
  passDate,
  checkMaterialDisabledButton,
  availableCredits,
  serverTime,
}) {
  console.log('vehicles:', vehicles);
  const { success, referenceIds } = stateData;
  const rowStyle = { border: 'none', cursor: 'pointer' };
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: '#f6f6f6', border: 'none' };
    }
  };

  const onTabClick = id => {
    switch (id) {
      case 1:
        onClick('statusRoute', { route: 'request-permits', id: id });
        break;
      case 2:
        onClick('statusRoute', { route: 'pending-permits', id: id });
        break;
      case 3:
        onClick('statusRoute', { route: 'approved-rejected-permits', id: id });
        break;
      default:
        break;
    }
  };
  const deletePerson = id => {
    onClick('delete-person', id);
  };
  const viewPerson = id => {
    onClick('viewPerson', id);
  };

  const deleteVehicle = id => {
    onClick('delete-vehicle', id);
  };
  const deleteMaterial = id => {
    onClick('delete-material', id);
  };
  const referenceIdsDefs = [
    {
      headerName: 'Name',
      field: 'name',
      headerClass: styles.referenceIdsRowHeader,
      flex: 1,
      resizable: true,
      cellStyle: { display: 'flex', alignItems: 'center' },
      cellRendererFramework: ({ data: { name } }) => {
        return <div className={styles.referenceIdsRowValues}>{name}</div>;
      },
    },
    {
      headerName: 'Type',
      field: 'type',
      headerClass: styles.referenceIdsRowHeader,
      flex: 1,
      resizable: true,
      cellStyle: { display: 'flex', alignItems: 'center' },
      cellRendererFramework: ({ data: { type } }) => {
        return <div className={styles.referenceIdsRowValues}>{type}</div>;
      },
    },
    {
      headerName: 'Reference ID',
      field: 'referenceId',
      headerClass: styles.referenceIdsRowHeader,
      flex: 1,
      resizable: true,
      cellStyle: { display: 'flex', alignItems: 'center' },
      cellRendererFramework: ({ data: { referenceId } }) => {
        return (
          <div className={styles.referenceIdsRowValues}>{referenceId}</div>
        );
      },
    },
  ];

  const personColumnDefs1 = [
    {
      headerName: 'Name',
      field: 'name',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      cellStyle: { display: 'flex', alignItems: 'center' },
      cellRendererFramework: ({ data: { name } }) => {
        return <div className={styles.sessionTime}>{name}</div>;
      },
    },
    {
      headerName: 'Request Type',
      field: 'requestType',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      cellStyle: { display: 'flex', alignItems: 'center' },
      cellRendererFramework: ({ data: { requestType } }) => {
        return <div className={styles.sessionTime}>{'Person'}</div>;
      },
    },
    {
      headerName: 'Aadhaar Number',
      field: 'aadhaarNumber',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      cellStyle: { display: 'flex', alignItems: 'center' },
      cellRendererFramework: ({ data: { aadhaarNumber } }) => {
        return <div className={styles.sessionTime}>{aadhaarNumber}</div>;
      },
    },
    {
      headerName: 'RFID Number',
      field: 'RFIDNumber',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      cellStyle: { display: 'flex', alignItems: 'center' },
      cellRendererFramework: ({ data: { RFIDNumber } }) => {
        return <div className={styles.sessionTime}>{RFIDNumber}</div>;
      },
    },
    {
      headerName: 'Vehicle Reg. Number',
      field: 'twoWheelerVehicleRegistrationNumber',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      cellStyle: { display: 'flex', alignItems: 'center' },
      cellRendererFramework: ({ data: { twoWheelerVehicleRegistrationNumber } }) => {
        return <div className={styles.sessionTime}>{twoWheelerVehicleRegistrationNumber}</div>;
      },
    },
    {
      headerName: 'Actions',
      field: 'actions',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      cellStyle: { display: 'flex', alignItems: 'center' },
      cellRendererFramework: ({ data: { id } }) => {
        return (
          <Flex className={styles.actions}>
            <img
              onClick={event => viewPerson(id)}
              src={commonImages.viewIcon}
              alt="delete"
              className={styles.image}
            />

            <img
              onClick={event => deletePerson(id)}
              src={operatorImages.deleteIcon}
              alt="delete"
              className={styles.image}
            />
          </Flex>
        );
      },
    },
  ];


  const personColumnDefs = [
    {
      headerName: 'Name',
      field: 'name',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      cellStyle: { display: 'flex', alignItems: 'center' },
      cellRendererFramework: ({ data: { name } }) => {
        return <div className={styles.sessionTime}>{name}</div>;
      },
    },
    {
      headerName: 'Request Type',
      field: 'requestType',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      cellStyle: { display: 'flex', alignItems: 'center' },
      cellRendererFramework: ({ data: { requestType } }) => {
        return <div className={styles.sessionTime}>{'Person'}</div>;
      },
    },
    {
      headerName: 'Aadhaar Number',
      field: 'aadhaarNumber',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      cellStyle: { display: 'flex', alignItems: 'center' },
      cellRendererFramework: ({ data: { aadhaarNumber } }) => {
        return <div className={styles.sessionTime}>{aadhaarNumber}</div>;
      },
    },
    {
      headerName: 'RFID Number',
      field: 'RFIDNumber',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      cellStyle: { display: 'flex', alignItems: 'center' },
      cellRendererFramework: ({ data: { RFIDNumber } }) => {
        return <div className={styles.sessionTime}>{RFIDNumber}</div>;
      },
    },

    {
      headerName: 'Actions',
      field: 'actions',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      cellStyle: { display: 'flex', alignItems: 'center' },
      cellRendererFramework: ({ data: { id } }) => {
        return (
          <Flex className={styles.actions}>
            <img
              onClick={event => viewPerson(id)}
              src={commonImages.viewIcon}
              alt="delete"
              className={styles.image}
            />

            <img
              onClick={event => deletePerson(id)}
              src={operatorImages.deleteIcon}
              alt="delete"
              className={styles.image}
            />
          </Flex>
        );
      },
    },
  ];

  const vehicleAndDriverColumnDefs = [
    {
      headerName: 'Vehicle Number',
      field: 'nameVehicleNumber',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      cellStyle: { display: 'flex', alignItems: 'center' },
      cellRendererFramework: ({ data: { entityName } }) => {
        return <div className={styles.sessionTime}>{entityName}</div>;
      },
    },
    {
      headerName: 'Request Type',
      field: 'requestType',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      cellStyle: { display: 'flex', alignItems: 'center' },
      cellRendererFramework: ({ data: { entityRequestType } }) => {
        return <div className={styles.sessionTime}>{entityRequestType}</div>;
      },
    },
    // {
    //   headerName: 'Document Proof Number',
    //   field: 'documentProofNumber',
    //   headerClass: styles.rowHeader,
    //   flex: 1,
    //   resizable: true,
    //   cellStyle: {display: 'flex', alignItems: 'center'},
    //   cellRendererFramework: ({data: {entityDocumentProofNumber}}) => {
    //     return (
    //       <div className={styles.sessionTime}>{entityDocumentProofNumber}</div>
    //     );
    //   },
    // },
    {
      headerName: 'RFID Number',
      field: 'RFIDNumber',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      cellStyle: { display: 'flex', alignItems: 'center' },
      cellRendererFramework: ({ data: { entityRFIDNumber } }) => {
        return <div className={styles.sessionTime}>{entityRFIDNumber}</div>;
      },
    },

    {
      headerName: 'Trip Type',
      field: 'tripType',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      cellStyle: { display: 'flex', alignItems: 'center' },
      cellRendererFramework: ({ data: { tripType } }) => {
        return <div className={styles.sessionTime}>{tripType}</div>;
      },
    },

    {
      headerName: 'Actions',
      field: 'actions',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      cellStyle: { display: 'flex', alignItems: 'center' },
      cellRendererFramework: ({ data: { vehicleId } }) => {
        return (
          <Flex className={styles.actions}>
            {/* <img
              // onClick={event => editRow(event, id)}
              src={operatorImages.editIcon}
              alt="edit"
              className={styles.image}
            /> */}
            <img
              onClick={event => deleteVehicle(vehicleId)}
              src={operatorImages.deleteIcon}
              alt="delete"
              className={styles.image}
            />
          </Flex>
        );
      },
    },
  ];
  const materialColumnDef = [
    {
      headerName: 'Material Type',
      field: 'materialType',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      cellStyle: { display: 'flex', alignItems: 'center' },
      cellRendererFramework: ({ data: { entityMaterialType } }) => {
        return <div className={styles.sessionTime}>{entityMaterialType}</div>;
      },
    },
    {
      headerName: 'IGM/CHA/Permit',
      field: 'IGMCHAPermit',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      cellStyle: { display: 'flex', alignItems: 'center' },
      cellRendererFramework: ({ data: { entityNumber } }) => {
        return <div className={styles.sessionTime}>{entityNumber}</div>;
      },
    },
    {
      headerName: 'Validity Date',
      field: 'validityDate',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      cellStyle: { display: 'flex', alignItems: 'center' },
      cellRendererFramework: ({ data: { entityValidityDate } }) => {
        return <div className={styles.sessionTime}>{entityValidityDate}</div>;
      },
    },
    {
      headerName: 'Vehicle Linked / Vehicle Number',
      field: 'vehicleLinkedVehicleNumber',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      cellStyle: { display: 'flex', alignItems: 'center' },
      cellRendererFramework: ({ data: { entityLinkedVehicle } }) => {
        return <div className={styles.sessionTime}>{entityLinkedVehicle}</div>;
      },
    },

    {
      headerName: 'Actions',
      field: 'actions',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      cellStyle: { display: 'flex', alignItems: 'center' },
      cellRendererFramework: ({ data: { id } }) => {
        return (
          <Flex className={styles.actions}>
            {/* <img
              // onClick={event => editRow(event, id)}
              src={operatorImages.editIcon}
              alt="edit"
              className={styles.image}
            /> */}
            <img
              onClick={event => deleteMaterial(id)}
              src={operatorImages.deleteIcon}
              alt="delete"
              className={styles.image}
            />
          </Flex>
        );
      },
    },
  ];

  const successRender = () => {
    return (
      <div className={styles.successContainer}>
        <div>
          <img
            className={styles.successIcon}
            src={loginImages.successIcon}
            alt="Logo"
          />
          <div className={styles.content}>
            Your Permit request for{' '}
            {persons.length > 0 ? persons.length + ' person(s) ' : ''}{' '}
            {persons.length > 0 && vehicles.length > 0 ? 'and' : ''}{' '}
            {vehicles.length > 0 ? vehicles.length + ' vehicle(s) ' : ''}
            has been submitted successfully.
            <br />
            {/* {referenceId && (
              <>
                the Reference Numbers are{' '}
                <span className={styles.referenceId}>{referenceId}</span>
              </>
            )} */}
            {referenceIds && referenceIds.length > 0 ? (
              <>
                <p style={{ marginBottom: '2vh' }}>The reference number(s): </p>
                <div style={{ width: '60%', margin: '0 auto' }}>
                  <AgGridReact
                    rowStyle={rowStyle}
                    getRowStyle={getRowStyle}
                    rowHoverHighlight={true}
                    rowHeight={45 * heightConstant}
                    headerHeight={45 * heightConstant}
                    columnDefs={referenceIdsDefs}
                    defaultColDef={{ sortable: true }}
                    rowClass={[styles.gridRowClass]}
                    // onCellClicked={(event, index) => onCellClick(event, index)}
                    rowData={referenceIds}
                    // overlayNoRowsTemplate={overlayNoRowsTemplate}
                    domLayout="autoHeight"></AgGridReact>
                </div>
              </>
            ) : null}
          </div>
          <button onClick={() => onClick('successOkay')}>Okay</button>
        </div>
      </div>
    );
  };

  return (
    <div className={[styles.container, containerClassNames].join(' ')}>
      <PermitsHeader
        onTabClick={onTabClick}
        title="Permits"
        tabs={tabs}
        activeTab={1}
        availableCredits={availableCredits}
      />
      {!success ? (
        <Flex direction="column" className={styles.body}>
          <Flex className={styles.formContainer}>
            {formData.map((elem, idx) => {
              return (
                <Flex
                  className={[
                    elem.stateField === 'purposeOfWork'
                      ? styles.purposeWorkDiv
                      : styles.firstDiv,
                  ].join(' ')}
                  direction="column">
                  <FormElement
                    type={elem.elementType}
                    label={elem.label}
                    required={elem.required}
                    placeholderText={elem.placeholder}
                    onChange={text => {
                      onChangeHandler(elem.stateField, text);
                    }}
                    options={stateData[elem.optionsField]}
                    value={stateData[elem.stateField].value}
                    key={idx}
                    maxLength={elem.maxLength}
                    maxFiles={elem.maxFiles}
                    // disabled={checkDisabledForm(elem)}
                    error={stateData[elem.stateField].error}
                    allowedFormats={elem.allowedFormats}
                    handleUpload={(file, error) => {
                      handleUpload(elem.stateField, file, error);
                    }}
                    disabled={checkDisabledForm(elem)}
                    dropdownStyle={{ height: '45px' }}
                    containerClassNames={
                      elem.stateField === 'purposeOfWork'
                        ? styles.dropdownContainerStyle
                        : styles.containerClassNames
                    }
                    minimumDate={serverTime || elem.minimumDate}
                  />
                  {elem.stateField === 'passValidityPeriod' && passDate ? (
                    <p className={styles.passDate}>
                      Validity: {passDate.value}
                    </p>
                  ) : null}
                </Flex>
              );
            })}
          </Flex>
          {/* <Flex className={styles.personPermitHeaderContainer}>
            <div className={styles.personPermitHeader}>
              <div className={styles.humanImgBg}>
                <img src={commonImages.humanIconBlue} alt="u" />
              </div>
              <div>Person Permit</div>
            </div>

            <button
              onClick={() => onClick('addPerson')}
              className={styles.primaryButton}>
              + Add Person
            </button>
          </Flex> */}
          {persons.length > 0 ? (
            <div className={['ag-theme-balham', styles.tableWrapper].join(' ')}>
              <Flex direction="row" className={styles.personTableHeader}>
                <p>Persons Details</p>
                <p>
                  <i>{persons.length} person permit(s)</i>
                </p>
              </Flex>
              <AgGridReact
                rowStyle={rowStyle}
                getRowStyle={getRowStyle}
                rowHoverHighlight={true}
                columnDefs={persons[0]?.twoWheelerVehicleRegistrationNumber ? personColumnDefs1 : personColumnDefs}
                rowHeight={78 * heightConstant}
                headerHeight={60 * heightConstant}
                defaultColDef={{ sortable: true }}
                rowClass={[styles.gridRowClass]}
                // onCellClicked={(event, index) => onCellClick(event, index)}
                rowData={persons}
                domLayout="autoHeight"></AgGridReact>
            </div>
          ) : null}
          <Flex
            className={[
              styles.personPermitHeaderContainer,
              styles.vehiclePermitHeaderContainer,
            ].join(' ')}>
            <div className={styles.personPermitHeader}>
              <div className={styles.humanImgBg}>
                <img src={commonImages.vehicleIconBlue} alt="u" />
              </div>
              <div>Vehicle Permit</div>
            </div>
            <button
              onClick={() => onClick('addVehicle')}
              className={styles.primaryButton}>
              + Add Vehicle
            </button>
          </Flex>
          <Flex direction="row" className={styles.buttonsContainer}>
            {/* <button onClick={() => onClick('addPerson')}>+ Add Person</button>
            <button onClick={() => onClick('addVehicle')}>+ Add Vehicle</button> */}
            {/* <button onClick={() => onClick('addMaterial')}>Add Material</button> */}
            {/* <button onClick={() => onClick('addDriver')}>Add Driver</button> */}
          </Flex>

          {vehicles.length > 0 ? (
            <>
              <div
                className={['ag-theme-balham', styles.tableWrapper].join(' ')}>
                <Flex direction="row" className={styles.personTableHeader}>
                  <p>Vehicle Details</p>
                  <p>
                    <i>{vehicles.length} vehicle permits</i>
                  </p>
                </Flex>
                <AgGridReact
                  rowStyle={rowStyle}
                  getRowStyle={getRowStyle}
                  rowHoverHighlight={true}
                  rowHeight={78 * heightConstant}
                  headerHeight={60 * heightConstant}
                  columnDefs={vehicleAndDriverColumnDefs}
                  defaultColDef={{ sortable: true }}
                  rowClass={[styles.gridRowClass]}
                  // onCellClicked={(event, index) => onCellClick(event, index)}
                  rowData={vehicles}
                  // overlayNoRowsTemplate={overlayNoRowsTemplate}
                  domLayout="autoHeight"></AgGridReact>
              </div>
            </>
          ) : null}
          {materials.length > 0 ? (
            <div className={['ag-theme-balham', styles.tableWrapper].join(' ')}>
              <Flex direction="row" className={styles.personTableHeader}>
                <p>Material Details</p>
                <button
                  disabled={checkMaterialDisabledButton()}
                  className={styles.addMaterialButton}
                  onClick={() => {
                    onClick('addMaterial');
                  }}
                  style={
                    checkMaterialDisabledButton()
                      ? { color: '#0045e650', cursor: 'not-allowed' }
                      : { color: '#0045e6' }
                  }>
                  +Add Material
                </button>
              </Flex>
              <AgGridReact
                rowStyle={rowStyle}
                getRowStyle={getRowStyle}
                rowHoverHighlight={true}
                rowHeight={45 * heightConstant}
                headerHeight={45 * heightConstant}
                columnDefs={materialColumnDef}
                defaultColDef={{ sortable: true }}
                rowClass={[styles.gridRowClass]}
                // onCellClicked={(event, index) => onCellClick(event, index)}
                rowData={materials}
                // overlayNoRowsTemplate={overlayNoRowsTemplate}
                domLayout="autoHeight"></AgGridReact>
            </div>
          ) : null}
          <Flex direction="row" className={styles.buttonsBottomContainer}>
            <button onClick={() => onClick('clear')}>Clear</button>
            <button
              onClick={() => onClick('submitRequest')}
              className={styles.applyBtn}>
              Submit Request
            </button>
          </Flex>
        </Flex>
      ) : (
        successRender()
      )}
      <div className={styles.SannaLogo}>
        <SannaLogo />
      </div>
    </div>
  );
}
