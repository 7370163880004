import React, {Component} from 'react';
import styles from './styles.module.scss';
import PopupContainer from '../../../../components/PopupContainer';
import Popup from '../../../../components/Popup';
import {withRouter} from 'react-router-dom';
import FormElement from '../../../../components/FormElement';

import {loginImages, commonImages} from '../../../../assets/images';
import moment from 'moment';
import ViewRenderer from './views';
import AgencyRquestsPopup from '../../../../components/AgencyRquestsPopup';
import AddCreditsPopup from '../../../../components/AddCreditsPopup';
import {API} from '../../../../requests';
import strings from '../../../../globalization';
import Spinner from '../../../../components/Spinner';
import {tempData, getFormData} from './data';
import TermsCredits from '../../../../components/Credits/Terms';

import logo from '../../../../assets/images/Logo/logo.png';
import PaymentSuccess from '../../../../components/Credits/PaymentSuccess';
import PaymentFailed from '../../../../components/Credits/PaymentFailed';

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  ERROR: 'error',
  SPINNER_POPUP: 'spinner',
  ADD_CREDITS: 'addCredits',
  TERMS_CREDITS_POPUP: 'terms-credits-popup',
  PAYMENT_SUCCESS_CREDITS: 'payment-success-credits',
  PAYMENT_FAILURE_CREDITS: 'payment-failure-credits',
};
class AvailableCredits extends Component {
  constructor(props) {
    super(props);
    console.log(props);

    this.state = {
      transactions: [],
      selectedStatus: this.props.status,
      selectedRequestType: '',
      //      selectedAgency: '',

      startDate: new moment().startOf('year').format('MM-DD-YYYY'),
      endDate: new moment().add(1, 'years').format('MM-DD-YYYY'),
      dates: null,
      filterTransactions: [],
      searchText: '',
      credits: 0,
      availableCredits: 0,
      holdCredits: 0,
      gst: 0,
      amount: 0,
      totalAmount: 0,
      statuses: [
        {
          id: '1',
          name: 'AVAILABLE_CREDITS',
        },
        {
          id: '2',
          name: 'HISTORY',
        },
      ],
    };
  }

  componentDidMount = () => {
    this.getCreditsData();
  };

  getCreditsData = () => {
    this.showSpinner();
    API.getCreditWalletDetails()
      .then(res => {
        console.log('res.data', res.data);
        this.setState({
          availableCredits: res.data.availableCredits,
          holdCredits: res.data.holdCredits,
          transactions: res.data.creditHistory,
          filterTransactions: res.data.creditHistory,
          popup: null,
        });
      })
      .catch(error => console.log(error));
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  applyFilters = () => {
    const {searchText, transactions} = this.state;

    const filterBySearch =
      transactions.filter(element =>
        element.transactionId
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()),
      ) || transactions;

    this.setState({
      filterTransactions: filterBySearch,
    });
  };

  getData = async (startDate, endDate, isFilter, status) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
    await API.getAllCompanyPermitDetails(startDate, endDate, status)
      .then(response => {
        this.setState({
          transactions: response.data,
          filterTransactions: response.data,
          popup: undefined,
        });
        if (isFilter) {
          this.applyFilters();
        }
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        console.info('error....', errorMsg);
        this.setState({
          popup: undefined,
        });
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.SIMPLE_POPUP,
            message: errorMsg,
            messageStyle: {color: '#E67717'},
            buttons: [
              {
                title: 'Okay!',
                onPress: this.closePopup,
              },
            ],
          },
        });
      });
  };

  getErrorMessage = referenceId => {
    return (
      <div className={styles.requestSuccess}>
        <div>
          <img
            src={commonImages.errorIcon}
            className={styles.successIcon}
            alt="logo"
          />
        </div>
        <div className={styles.errorMsg}>
          {strings.ADMIN.POP_UP.errorMessage}
        </div>
      </div>
    );
  };

  getSuccessMessage = referenceId => {
    return (
      <div className={styles.requestSuccess}>
        <div>
          <img
            src={loginImages.successIcon}
            className={styles.successIcon}
            alt="logo"
          />
        </div>
        <div className={styles.successMessage}>
          {strings.ADMIN.POP_UP.approveCnf1} {referenceId}{' '}
          {strings.ADMIN.POP_UP.approveCnf2}
        </div>
      </div>
    );
  };

  getRejectMessage = () => {
    return (
      <div className={styles.requestReject}>
        <div className={styles.rejectMessage}>
          {strings.ADMIN.POP_UP.toReject}
        </div>
        <textarea
          placeholder={'Type here...'}
          className={styles.popupTextarea}
          required
        />
      </div>
    );
  };
  getRejectConformation = referenceId => {
    return (
      <div className={styles.requestReject}>
        <div className={styles.rejectMessage}>
          {strings.ADMIN.POP_UP.rejectCnf1} {referenceId}{' '}
          {strings.ADMIN.POP_UP.rejectCnf2}
        </div>
        {/* <textarea
          placeholder={"Type here"}
          className={styles.popupTextarea}
          required
        /> */}
      </div>
    );
  };

  onChangeHandler = (action, data) => {
    switch (action) {
      case 'requestType': {
        const {transactions} = this.state;
        // const filterTransactions = transactions.filter(row =>
        //   (
        //     (row.persons.length > 0 ? 'person' : ' ') +
        //     (row.vehicle ? 'vehicle' : '').toLowerCase()
        //   )
        //     .split(' ')
        //     .includes(data.toLowerCase()),
        // );
        this.setState({
          selectedRequestType: data,
        });
        break;
      }

      case 'date': {
        const {startDate, endDate, dates} = data;
        this.setState({
          startDate,
          endDate,
          dates,
        });
        break;
      }

      case 'search': {
        console.info('data..', data);
        this.setState(
          {
            searchText: data,
          },
          function () {
            this.applyFilters();
          },
        );
        // const { transactions } = this.state;
        // const filterTransactions = transactions.filter((element) =>
        //     Object.values(element).toString().toLowerCase().includes(data.toLowerCase()));
        // this.setState({
        //   filterTransactions,
        // });
        break;
      }

      default: {
        console.info('case not handled', action);
      }
    }
  };

  /**
   *
   * @param {*} action
   * @param {*} data
   */

  onClickHandler = (action, data) => {
    const {history, location, status} = this.props;
    switch (action) {
      case 'signin': {
        history.push('/sign-in');
        break;
      }
      case 'vehicle-registration': {
        history.push('/vehicle-registration');
        break;
      }
      case 'apply': {
        const {startDate, endDate} = this.state;
        let startDateStr = moment(startDate).format('MM-DD-YYYY');
        let endDateStr = moment(endDate).format('MM-DD-YYYY');
        this.getData(
          startDateStr,
          endDateStr,
          true,
          status === 'PENDING' ? status : null,
        );
        break;
      }
      case 'clearFilters': {
        this.resetData();
        break;
      }
      case 'addCredits': {
        console.info('data', data);
        // this.showTermsPopup();
        const {availableCredits} = this.state;
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.ADD_CREDITS,
            heading: `Add Credits`,
            // message: this.getAddCreditsPopup(data),
            onClose: this.closePopup,
            // onClick: (credits, amount, gst, totalAmount) => {
            //   console.log(credits, amount, gst, totalAmount);
            //   this.setState({
            //     credits: credits,
            //     gst: gst,
            //     amount: amount,
            //     totalAmount: totalAmount,
            //   });
            //   console.log(this.state);
            //   // this.approveAgency(data);
            // },
            onClick: (credits, amount, gst, totalAmount) => {
              this.showTermsPopup(credits, amount, gst, totalAmount);
            },
            data: data,
            availableCredits: availableCredits,
            buttonContainer: styles.buttonContainer,
            buttons: [
              {
                text: 'Cancel',
                buttonStyles: styles.buttonPrimary,
                onClick: () => {
                  this.closePopup();
                },
              },
              {
                text: 'Proceed',
                buttonStyles: styles.buttonSecondary,
              },
            ],
          },
        });
        break;
      }
      case 'statusRoute': {
        //FIXME
        history.push({
          pathname: '/u/credits/' + data.route,
        });

        break;
      }
      default: {
        console.info('case not handled', action);
      }
    }
  };

  showTermsPopup = (credits, amount, gst, totalAmount) => {
    console.log(
      'credits, amount, gst, totalAmount',
      credits,
      amount,
      gst,
      totalAmount,
    );
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.TERMS_CREDITS_POPUP,
        onClose: this.closePopup,
        buttons: [
          {
            text: 'Cancel',
            buttonStyles: styles.buttonPrimary,
            onClick: () => {
              this.closePopup();
            },
          },
          {
            text: 'Agree and Proceed',
            buttonStyles: styles.buttonSecondary,
            onClick: () => {
              console.log(credits, amount, gst, totalAmount);
              this.setState({
                credits: credits,
                gst: gst,
                amount: amount,
                totalAmount: totalAmount,
              });
              console.log('this.state...', this.state);
              // create order
              this.displayRazorpay(amount);
              // call razorpay
              // initialize payment
              // this.approveAgency(data);
            },
          },
        ],
        // data: data,
        buttonContainer: styles.buttonContainer,
      },
    });
  };
  displayRazorpay = async amount => {
    console.info('display razorpay -->');

    const res = await this.loadRazorpay();
    // this.showSpinner();
    const orderRes = await API.createOrder(amount)
      .then(res => {
        console.log('createOrderIdRes...', res);
        this.setState({
          orderId: res.data.orderId,
        });
        return res.data;
      })
      .catch(err => console.log('err...', err));

    if (!res) {
      alert('Razorpay SDK Failed to load');
      return;
    }

    this.setState({
      popup: null,
    });

    // console.log(data);
    var options = {
      key: process.env.REACT_APP_RAZORPAY_API_KEY_ID, // Enter the Key ID generated from the Dashboard
      name: 'e-Drishti Access Control System',
      currency: 'INR',
      amount: amount,
      order_id: orderRes.orderId,
      description: 'Thankyou!',
      image: {logo},
      handler: response => {
        console.log('RESPONSE...', response);
        let successDetails = {
          orderId: response.razorpay_order_id,
          paymentId: response.razorpay_payment_id,
          signature: response.razorpay_signature,
          amount: amount,
          availableCredits: this.state.availableCredits,
        };
        // Call Add credits API here.
        console.log('successDetails...', {successDetails});
        this.addCredits(successDetails);
      },
      modal: {
        ondismiss: data => {
          console.log('this.state..error', this.state);
          const errorDetails = {
            orderId: this.state.orderId,
            paymentId: 'HARDCODED',
            code: 'FAILED-HARDCODED',
          };
          console.log('errorDetails...', errorDetails);
          console.log('errorDetails...', data);
          // this.callFailureAPI(errorDetails);
          this.showPaymentFailurePopup(errorDetails);
        },
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };
  showPaymentFailurePopup = errorDetails => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.PAYMENT_FAILURE_CREDITS,
        onClose: this.closePopup,
        buttons: [
          {
            text: 'Pay Later',
            buttonStyles: styles.buttonPrimary,
            onClick: () => {
              this.closePopup();
            },
          },
          {
            text: 'Try Again',
            buttonStyles: styles.buttonSecondary,
            onClick: () => {
              this.displayRazorpay(this.state.amount);
            },
          },
        ],
        // data: data,
        data: {amount: this.state.amount, orderId: errorDetails.orderId},
        // data: data,
        buttonContainer: styles.buttonContainer,
      },
    });
  };

  addCredits = data => {
    const formData = {
      orderId: data.orderId,
      paymentId: data.paymentId,
      signature: data.signature,
      paymentMode: 'UPI/DUMMY',
    };
    API.addCredits(formData)
      .then(res => {
        this.showPaymentSuccessPopup(data);
      })
      .catch(err => console.log('error..', err));
  };
  loadRazorpay = () => {
    return new Promise(resolve => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      // document.body.appendChild(script);

      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };

  showPaymentSuccessPopup = data => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.PAYMENT_SUCCESS_CREDITS,
        onClose: this.closePopup,
        buttons: [
          {
            text: 'Okay',
            buttonStyles: styles.buttonPrimary,
            onClick: () => {
              this.getCreditsData();
              this.closePopup();
            },
          },
        ],
        // data: data,
        // data: {amount, availableBalance, referenceId, orderId},
        data: {
          ...data,
          availableCredits: +data.availableCredits + +data.amount,
        },
        buttonContainer: styles.buttonContainer,
      },
    });
  };
  showPaymentFailurePopup = data => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.PAYMENT_FAILURE_CREDITS,
        onClose: this.closePopup,
        buttons: [
          {
            text: 'Pay Later',
            buttonStyles: styles.buttonPrimary,
            onClick: () => {
              this.closePopup();
            },
          },
          {
            text: 'Try Again',
            buttonStyles: styles.buttonSecondary,
            onClick: () => {
              this.displayRazorpay(this.state.amount);
            },
          },
        ],
        // data: data,
        // data: {amount, availableBalance, referenceId, orderId},
        data: data,
        buttonContainer: styles.buttonContainer,
      },
    });
  };

  getProps = () => {
    const {
      statuses,
      transactions,
      filterTransactions,
      searchText,
      //  selectedAgency,
      selectedRequestType,
      selectedStatus,
      startDate,
      endDate,
      amount,
      dates,
      availableCredits,
      holdCredits,
    } = this.state;
    return {
      onClick: this.onClickHandler,
      onChange: this.onChangeHandler,
      statuses,
      transactions,
      filterTransactions,
      searchText,
      selectedRequestType,
      selectedStatus,
      startDate,
      endDate,
      dates,
      amount,
      availableCredits,
      holdCredits,
    };
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };

  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      // case "spinner": {
      //   console.log('showing spinner');
      //   return <Spinner />;
      // }
      case POPUP_CONSTANTS.STANDARD: {
        return <AgencyRquestsPopup {...popup} />;
      }
      case POPUP_CONSTANTS.TERMS_CREDITS_POPUP: {
        return <TermsCredits {...popup} />;
      }
      case POPUP_CONSTANTS.PAYMENT_SUCCESS_CREDITS: {
        return <PaymentSuccess {...popup} />;
      }
      case POPUP_CONSTANTS.PAYMENT_FAILURE_CREDITS: {
        return <PaymentFailed {...popup} />;
      }
      case POPUP_CONSTANTS.ADD_CREDITS: {
        return <AddCreditsPopup {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.SPINNER: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

const RequestCard = props => {
  const {header} = props;

  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardheader}>{header}</div>
      {props.children}
    </div>
  );
};

export default withRouter(AvailableCredits);
