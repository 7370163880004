import React, {useEffect} from 'react';
import PopupContainer from '../../../../components/PopupContainer';
// import {withRouter} from 'react-router-dom';

import styles from './styles.module.scss';
import ViewRenderer from './Views';
import ReactToPrint, {PrintContextConsumer} from 'react-to-print';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// import useLocation from ''
class Invoice extends React.Component {
  constructor(props) {
    super(props);
    const {reduxProps} = this.props;

    const {
      login: {loginInfo},
    } = reduxProps;
    console.log('loginInfo...', loginInfo);
    this.state = {
      popup: null,
      company: loginInfo,
      details: this.props.location.state.data,
    };
  }
  getProps = () => {
    return {
      print: false,
      company: this.state.company,
      details: this.state.details,
      onClick: this.onClickHandler,
    };
  };
  goBack = () => {
    const {history} = this.props;
    history.goBack();
  };
  onClickHandler = (action, data) => {
    switch (action) {
      case 'print': {
        this.setState({
          print: true,
        });
        break;
      }
      case 'close': {
        this.goBack();
        break;
      }
      default: {
        console.warn('case not handled', action, data);
      }
    }
  };
  render() {
    const {print} = this.state;
    const {popup} = this.state;
    const viewProps = this.getProps();
    const pageStyle = `
  @page {
    size: 290mm 290mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;
    return (
      <>
        {popup ? (
          <PopupContainer containerStyle={styles.popupContainerStyle}>
            {this.getPopupContent()}
          </PopupContainer>
        ) : (
          <ViewRenderer
            ref={el => {
              this.componentRef = el;
              console.info('reference', el);
            }}
            id="pdf"
            {...viewProps}
          />
        )}

        {print ? (
          <ReactToPrint
            content={() => {
              console.info(this.componentRef);
              return this.componentRef;
            }}
            pageStyle={pageStyle}>
            <PrintContextConsumer>
              {({handlePrint}) => (
                <AutomaticPrint
                  goBack={this.goBack}
                  handlePrint={handlePrint}
                />
              )}
            </PrintContextConsumer>
          </ReactToPrint>
        ) : null}
      </>
    );
  }
}
const mapStateToProps = reduxProps => ({
  reduxProps,
});

export default withRouter(connect(mapStateToProps, null)(Invoice));

function AutomaticPrint({handlePrint, goBack}) {
  useEffect(() => {
    setTimeout(() => {
      // FIXME: Uncomment before committing.
      handlePrint();
      goBack();
    }, 1000);
  }, []);
  return null;
}
