import React from 'react';
import styles from './styles.module.scss';
import credit from '../../assets/images/Icon/credits/credits.png';
const Card = ({header, elem, idx, type, numberOfDays, isTrip}) => {
  return (
    <div key={idx} className={styles.card}>
      <div className={styles.header}>
        <Data name="Permit Start Date" value={header.startDate} />
        <Data name="Permit Validity(days)" value={header.validity} />
        <Data name="Purpose of Work" value={header.purpose} />
        <Data
          value={
            <p className={styles.value}>
              Your permit start date is{' '}
              <span
                style={{fontWeight: 'bold', fontFamily: 'Poppins-SemiBold'}}>
                {header.startDate} 08:00 AM
              </span>{' '}
              and will expire on{' '}
              <span
                style={{fontWeight: 'bold', fontFamily: 'Poppins-SemiBold'}}>
                {header.endDate} 07:59 AM
              </span>{' '}
            </p>
          }
        />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.vehicleDetails}>
            <p className={styles.rowhead}>
              {' '}
              {type === 'person'
                ? 'Person Details'
                : 'Vehicle-Driver Details'}{' '}
            </p>

            <Data name="Name" value={elem?.name} />
            <Data name="Email" value={elem?.emailAddress} />
            <Data name="Mobile Number" value={elem?.phoneNumber} />
            <Data name="Aadhaar Number" value={elem?.aadhaarNumber} />
            <Data name="Company" value={elem?.company} />

            <Data
              name="Vehicle Type"
              value={elem?.vehicle?.vehicleType?.name}
            />

            <Data
              name="Vehicle Number"
              value={elem?.vehicle?.vehicleRegistrationNumber}
            />

            <Data name="Vehicle Company" value={elem?.company?.name} />
            <Data name="Driver Details" value={elem?.driver?.name} />
            <Data name="Driver Company" value={elem?.company?.name} />
          </div>

          <div className={styles.materialDetails}>
            <p className={styles.rowhead}> Material Details</p>
            {elem?.material ? (
              <>
                <Data
                  name="Material Name"
                  value={elem?.material?.materialType?.name}
                />
                <Data name="Type of Vessel" value={elem?.material?.type} />
                <Data
                  name="Bill of Entry Number"
                  value={elem?.material?.BillOfEntryNo}
                />
                <Data
                  name="Shipping Bill Number"
                  value={elem?.material?.ShippingBillNumber}
                />

                <Data name="VCN Number" value={elem?.material?.VCNNumber} />
                <Data name="Vessel Name" value={elem?.material?.vesselName} />
                {elem?.material?.materialTypeId === 1 ? (
                  <>
                    {/* <Data
                      name="Gross Quantity"
                      value={elem?.material?.lineInfo[0].availableGrossQuantity}
                    />
                    <Data name="Cargo Type" value={elem?.material?.cargoType} />
                    <Data
                      name="Cargo Description"
                      value={elem?.material?.lineInfo[0]?.CargoDescription}
                    /> */}
                  </>
                ) : null}
                {elem?.material?.materialTypeId === 2 ? (
                  <>
                    <Data
                      name="Gross Quantity"
                      value={elem?.material?.totalWeightNumberOfUnits}
                    />
                    <Data
                      name="Units"
                      value={
                        elem?.material?.unitsOfWeightOptions.filter(
                          elem => elem.id === elem?.material?.unitsOfWeight,
                        )[0]?.name
                      }
                    />
                    <Data
                      name="Nature Of Cargo"
                      value={
                        elem?.material?.natureOfCargoOptions.filter(
                          elem => elem.id === elem?.material?.natureOfCargo,
                        )[0]?.name
                      }
                    />
                    <Data name="Cargo Type" value={elem?.material?.cargoType} />
                    <Data
                      name="Cargo Description"
                      value={elem?.material?.cargoDescription}
                    />
                  </>
                ) : null}
                <Data
                  name="Cargo Description"
                  value={elem?.material?.description}
                />
              </>
            ) : null}
          </div>
        </div>
        {/* <div key={idx} className={styles.bottom}>
          <Data
            name="Entry Category"
            value={
              isTrip
                ? 'Man, Vehicle & Material Permit (Per trip)'
                : type === 'person'
                ? 'Man Permit single or with bi-cycle'
                : type === 'vehicle' && elem?.material
                ? 'Man, Vehicle and Material Permit'
                : type === 'vehicle'
                ? 'Man, Vehicle and Material Permit'
                : '-'
            }
          />
          <div className={[styles.data, styles.img].join(' ')}>
            <img
              src={credit}
              className={styles.credit}
              alt="logo"
              style={{color: 'blue'}}
            />
            <p className={styles.key}>Wallet Summary</p>
          </div>
          <Data name="Number of Trips" value={numberOfDays} />
        </div> */}
      </div>
    </div>
  );
};

export default Card;

const Data = ({name, value}) => {
  console.log('key', name);
  return (
    <div>
      {value ? (
        <div className={styles.data}>
          {name ? <p className={styles.key}>{name} : </p> : null}
          <p className={styles.value}>{value}</p>
        </div>
      ) : null}
    </div>
  );
};
