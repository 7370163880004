import strings from '../../../globalization';

export const getFormData = () => {
  return [
    {
      elementType: 'dropdown',
      inputType: 'dropdown',
      label: strings.requestTypeLabel,
      placeholder: strings.requestTypePlaceholder,
      optionsField: 'requestTypeOptions',
      required: true,
      stateField: 'requestType',
      stateDefault: null,
    },
    {
      elementType: 'dropdown',
      inputType: 'dropdown',
      label: strings.proofDocumentTypeSubmitted,
      placeholder: strings.proofDocumentTypeSubmittedLabel,
      optionsField: 'proofDocumentTypeOptions',
      required: true,
      stateField: 'proofDocumentType',
      stateDefault: null,
    },
    {
      elementType: 'text',
      keyboardType: 'default',
      inputType: 'text',
      label: strings.documentNumberLabel,
      placeholder: strings.documentNumberPlaceholder,
      required: true,
      stateField: 'documentNumber',
      stateDefault: null,
      maxLength: 50,
    },
  ];
};
