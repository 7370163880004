import React, {useState, useEffect} from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import PopupContainer from '../PopupContainer';
import Spinner from '../Spinner';
import {store, dispatchSetLoginData} from '../../store';
import Auth from '@aws-amplify/auth';
import {API} from '../../requests';

/**
 *
 * @param {Props} props
 * @return {JSX.Element}
 */
function CheckLogin({Component, ...props}) {
  const [state, setState] = useState('spinner');
  const {login} = store.getState();
  const location = useLocation();

  useEffect(() => {
    Auth.currentSession()
      .then(session => {
        const {idToken = {}} = session;
        const {jwtToken = ''} = idToken;
        return API.login(jwtToken).then(response => {
          const {token, user} = response.data;

          console.info('User', user);
          API.setBearerToken(token);
          dispatchSetLoginData(response.data);
          setState('component');
        });
      })
      .catch(() => {
        console.log('Auth.current session CATCH...');
        setState('redirect');
      });

    API.getPreLoginToken()
      .then(response => {
        console.log(response);
        API.setPreLoginToken(response);
      })
      .catch(error => {
        //this.showErrorPopup('');
        console.log(error);
      });

    return () => {
      // unsubscriber && unsubscriber();
    };

   

  }, [login.isLoggedIn]);

  if (state === 'spinner') {
    return (
      <PopupContainer>
        <Spinner></Spinner>
      </PopupContainer>
    );
  } else if (state === 'redirect') {
    return (
      <Redirect
        to={{
          pathname: '/edrishti',
          state: {redirectPath: location.pathname},
        }}
      />
    );
  } else {
    return <Component {...props} />;
  }
}

export default CheckLogin;
