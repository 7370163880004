import strings from '../../../globalization/index';
import UserIcon from '../../../assets/images/user-icon/image.png';
import lockIcon from '../../../assets/images/lock-icon/image.png';

export const userImage = './static/images/Icons/user.png';
export const lockImage = './static/images/Icons/lock.png';

export const formFields = [
  {
    field: 'userId',
    type: 'text',
    label: () => `${strings.userId}`,
    placeholder: () => `${strings.enterUserId}`,
    required: true,
    inputType: 'phone',
    leftIcon: UserIcon,
  },
  {
    field: 'password',
    type: 'password',
    label: () => `${strings.password}`,
    placeholder: () => `${strings.enterPassword}`,
    required: true,
    inputType: 'text',
    leftIcon: lockIcon,
  },
];

export const INPUT_CONSTANTS = {
  TEXT: 'text',
  DROPDOWN: 'dropdown',
  DATE: 'date',
  FILE: 'file',
  NUMBER: 'number',
  PASSWORD: 'password',
};

export const keys = {
  title: `${strings.title}`,
  subTitle: `${strings.subTitle}`,
};

export const buttonGroup = [
  {
    label: () => `${strings.vehicleRegistration}`,
    navigate: 'vehicle-registration',
    toolTip:
      'Any vehicle owner with the intent of entering the port with their vehicle for any purpose of work must register their vehicle through this form. (One-time)',
    disabled: false,
  },
  {
    label: () => `${strings.personRegistration}`,
    navigate: 'person-registration',
    toolTip:
      'Any person with the intent of entering the port for any purpose of work must register themselves through this form. (One-time)',
    disabled: false,
  },
  // {
  //   label: `${strings.requestFreePermit}`,
  //   navigate: "signin",
  // },
  // {
  //   label: () => `${strings.requestForVisitorPermit}`,
  //   navigate: 'signin',
  //   toolTip:
  //     'Any person with the intent of visiting the port on a temporary basis must register and raise a request through this form.',
  //   disabled: true,
  // },
  // {
  //   label: () => `${strings.checkApplicationStatus}`,
  //   navigate: 'check-application-status',
  //   toolTip:
  //     'This module is used to update/view Reference Number related information.',
  //   disabled: false,
  // },
  {
    label: () => `${strings.manageRegistrationInfo}`,
    navigate: 'manage-registration-information',
    toolTip: 'This module is used to update/view registration information.',
    disabled: false,
  },
  {
    label: () => `${strings.checkPermitStatus}`,
    navigate: 'check-permit-status',
    toolTip: 'You can check your permit here.',
    disabled: false,
  },
];

//FIXME need to add proper content
export const privacyData = [
  {
    title: 'Purpose',
    content:
      "e-Drishti Access Control System is committed to protecting any personal information that we may receive while you access our website and mobile application. We believe it is important for you to know how we treat information about you that we may receive when you engage with us. This Privacy Policy is devised to help you feel more confident about the privacy and security of your personal details. 'You' shall mean You, the User of the Website/ Application and ‘Yourself’ interpreted accordingly. 'Users' means the Users of the Website/ application collectively and/or individually as the context allows.",
  },
  {
    title: 'The Information We Collect',
    content:
      'We receive both information that is directly provided to Us, such as personal information You provide when You visit the Website/ mobile application, and information that is passively or automatically collected from You, such as information collected from the browser or device You used to access Our Website or Services. In this Privacy Policy, we refer to all this as the ‘User Information’.',
  },
  {
    title: '',
    content:
      'Information You Provide To Us-- There are portions of this Website / Application where We may need to collect personal information from You for a specific purpose., such as: name, address, e-mail address, telephone number, etc. ',
  },
  {
    title: '',
    content:
      'Information That is Automatically Collected-- In general, You can visit this Website without telling who You are or revealing any information about Yourself. We use tracking tools like Google Analytics about your usage of our website. ',
  },
  {
    title: 'Information You Provide To Us',
    content:
      'There are portions of this Website / Application where We may need to collect personal information from You for a specific purpose., such as: name, address, e-mail address, telephone number, etc.',
  },
  {
    title: 'Information That is Automatically Collected',
    content:
      'In general, You can visit this Website without telling who You are or revealing any information about Yourself. We use tracking tools like Google Analytics about your usage of our website.',
  },
  {
    title: 'Use of Personal Information',
    content:
      'We use information to contact you: We might use the information you provide to contact You for confirmation of a purchase on our website or for pass/permit request purposes.',
  },

  {
    title: '',
    content:
      'We use information to respond to your requests or questions: We might use Your information to provide customer service to Your queries. ',
  },
  {
    title: '',
    content:
      'We use information to improve our services: We might use Your information to customize Your experience with us. This could include displaying content based upon Your preferences.',
  },
  {
    title: '',
    content:
      'We use information to send you transactional communications:  We might send You emails or SMS about Your account or a ticket purchase.',
  },
  {
    title: 'Security Data and Storing',
    content:
      'Security is very important to us. All security procedures are in place to protect the confidentiality, integrity and availability of Your User Information. We maintain strict physical, electronic, and administrative safeguards to protect Your User Information including your personal information from unauthorized or inappropriate access.',
  },
  {
    title: '',
    content:
      'We follow generally accepted standards to collect, store and protect personal data, including the use of encryption. We retain personal data for as long as it is needed to provide the services you have requested, and thereafter for legal and service purposes. These may include retention periods mandated by legal, contractual, or similar obligations; for resolving, preserving, enforcing or defending our legal and contractual rights; needed to maintain adequate and accurate business and financial records or how you access, update, or delete your data etc.',
  },
  {
    title: 'Updates',
    content:
      'We may change this privacy policy from time to time and You should check these regularly. Your use of the Website will be deemed an acceptance of the privacy policy existing at that time.',
  },
];

export const termsData = [
  {
    title: 'Acknowledgement',
    content:
      "Visitors to this website- www.sanya.ai/edrishti are bound by the following terms and conditions ('terms'). So, please read the terms carefully before continuing to use this site.",
  },
  {
    title: '',
    content:
      'If you do not agree with any of these terms, please do not proceed further with using the site',
  },
  {
    title: '',
    content:
      'Your access to and use of the services in this website is also conditioned on your acceptance of and compliance with the privacy policy of the website.',
  },
  {
    title: '',
    content:
      'Our privacy policy describes our policies and procedures on the collection, use and disclosure of your personal information when you use the application or the website. Please read our privacy policy carefully before using our service.',
  },
  {
    title: '',
    content:
      'We reserve the right to change/update these Terms from time to time without notice. You acknowledge and agree that it is your responsibility to review this periodically to familiarize yourself with any modifications.',
  },
  {
    title: '',
    content:
      'Your continued use of this site after such modifications will constitute acknowledgment and agreement of the modified terms and conditions.',
  },
  {
    title: 'Website Content',
    content:
      'The content of the pages of this website is for your general information and use only. It is subject to change without notice.',
  },
  {
    title: '',
    content:
      'All content and materials available on www.sanya.ai/edrishti  , including but not limited to text, graphics, website name, code, images and logos are the intellectual property of KR mroads India Pvt. Ltd., and are protected by applicable copyright and trademark law. Any inappropriate use, including but not limited to the reproduction, distribution, display or transmission of any content on this site is strictly prohibited, unless specifically authorized by- “KR mroads India Pvt. Ltd.”',
  },
  {
    title: 'User Accounts',
    content:
      'When You create an account with Us, You must provide Us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of Your account on Our Service.',
  },
  {
    title: '',
    content:
      'You are responsible for safeguarding the password that You use to access the Service and for any activities or actions under Your password, whether Your password is with Our Service or a Third-Party Social Media Service.',
  },
  {
    title: '',
    content:
      'You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware of any breach of security or unauthorized use of Your account.',
  },
  {
    title: '',
    content:
      'You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than You without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.',
  },
  {
    title: '',
    content:
      'You are responsible for maintaining the confidentiality of any login information associated with any account you use to access our Resources. Accordingly, you are responsible for all activities that occur under your account/s.',
  },
  {
    title: 'Data',
    content:
      'Although regular backups of Data are performed, sanya does not guarantee there will be no loss or corruption of data.',
  },
  {
    title: '',
    content:
      'Corrupt or invalid backup points may be caused by, without limitation, Data that is corrupted prior to being backed up or that changes during the time a backup is performed.',
  },
  {
    title: '',
    content:
      'sanya will provide support and attempt to troubleshoot any known or discovered issues that may affect the backups of Content. But You acknowledge that KR mroads India Pvt. Ltd. has no liability related to the integrity of Content or the failure to successfully restore Content to a usable state.',
  },
  {
    title: '',
    content:
      'In order to access our Resources, you may be required to provide certain information or data about yourself (such as identification, email, phone number, contact details, etc.) as part of the registration process, or as part of your ability to use the Resources. You agree that any information you provide will always be accurate, correct, and up to date.',
  },
  {
    title: 'Availability, Errors and Inaccuracies',
    content:
      'We are constantly updating Our offerings on the Services. The Services available may be mispriced, described inaccurately, or unavailable, and We may experience delays in updating information regarding our Service.',
  },
  {
    title: '',
    content:
      'We cannot and do not guarantee the accuracy or completeness of any information, including prices, specifications, availability, and services.',
  },
  {
    title: '',
    content:
      'We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.',
  },
  {
    title: 'Termination of use',
    content:
      'You agree that we may, at our sole discretion, suspend or terminate Your access to all or part of our website and Resources with or without notice and for any reason, including, without limitation, breach of this Agreement.',
  },
  {
    title: '',
    content:
      'Any suspected illegal, fraudulent or abusive activity may be grounds for terminating your relationship and may be referred to appropriate law enforcement authorities- if required.',
  },
  {
    title: '',
    content:
      'Upon suspension or termination, your right to use the Resources we provide will immediately cease, and we reserve the right to remove or delete any information that you may have on file with us, including any account or login information.',
  },
  {
    title: 'Refund Policy',
    content:
      'No refunds will be given except in the event of non-performance or inexplicable errors by/from/of any service by www.sanya.ai/edrishti',
  },
  {
    title: 'SMS/Email Policy',
    content:
      'By consenting to these terms, you agree to receive recurring SMS/Email messages with service-related messages, including updates, alerts, and information. ',
  },
  {
    title: '',
    content:
      'We do not charge you for the SMS Service, but you are responsible for all charges and fees associated with text messaging imposed by your wireless provider. Message frequency varies. Standard message and data rates may apply from your wireless provider. Check your mobile plan and contact your wireless provider for details.',
  },
];
