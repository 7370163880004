import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import POPUP_CONSTANTS from '../../../../constants/popup-constants';
import {API} from '../../../../requests';
import util from '../../../../util';

import styles from './styles.module.scss';

import Spinner from 'react-spinkit';
import Popup from '../../../../components/SimplePopup';

import ViewRenderer from './views';
import PopupContainer from '../../../../components/PopupContainer';
import {commonImages} from '../../../../assets/images';
import strings from '../../../../globalization';
import {data} from 'react-dom-factories';
import {connect} from 'react-redux';
import {setVehicleData} from '../../../../store/actions/permits';
import moment from 'moment';
import ViewVehicle from '../../../../components/Permits/ViewVehicle';
import ViewDocument from '../../../../components/ViewDocument';

const FILTER_OPTIONS = {
  REFERENCE_NUMBER: 'REFERENCE_NUMBER',
  REGISTRATION_NUMBER: 'REGISTRATION_NUMBER',
  RFID_TAG: 'RFID_TAG',
};

class AddVehicle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: {value: ''},

      filterOptions: [
        {
          id: FILTER_OPTIONS.REFERENCE_NUMBER,
          name: 'Reference Number',
        },
        {
          id: FILTER_OPTIONS.REGISTRATION_NUMBER,
          name: 'Vehicle Registration Number',
        },
        {
          id: FILTER_OPTIONS.RFID_TAG,
          name: 'RFID Tag number',
        },
      ],
      filter: null,
    };
  }
  goBack = () => {
    console.log('goBack Called!!-->');
    const {history} = this.props;
    history.goBack();
  };
  componentDidMount = () => {
    const {reducerState} = this.props;

    console.log('Reducer State-->Component did mount', reducerState);
  };
  getDocumentProofNumber = documentId => {
    switch (documentId) {
      case 1: {
        return 'aadhaarNumber';
      }
      case 2: {
        return 'drivingLicenceNumber';
      }
      case 3: {
        return 'panNumber';
      }
      case 4: {
        return 'passportNumber';
      }
      case 5: {
        return 'voterIdNumber';
      }
      default: {
        return '';
      }
    }
  };
  getDocumentProofUrl = documentId => {
    switch (documentId) {
      case 1: {
        return 'aadhaarUrl';
      }
      case 2: {
        return 'drivingLicenceUrl';
      }
      case 3: {
        return 'panUrl';
      }
      case 4: {
        return 'passportUrl';
      }
      case 5: {
        return 'voterIdUrl';
      }
      default: {
        return '';
      }
    }
  };

  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.VIEW_VEHICLE_POPUP: {
        return <ViewVehicle vehicle={this.state.vehicle} {...popup} />;
      }
      case POPUP_CONSTANTS.VIEW_DOCUMENT: {
        return <ViewDocument {...popup} />;
      }

      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };
  handleSearch = () => {
    // API to fetch details and populate state.
    const {searchText, filter} = this.state;
    if (!filter) {
      this.showErrorPopup(`Please select a filter`);
      return;
    }
    const {reducerState} = this.state;
    this.showSpinner();
    if (filter.value === FILTER_OPTIONS.REFERENCE_NUMBER) {
      API.getVehicleByReferenceId(searchText.value)
        .then(res => {
          console.log('res.data.. reference number..', res.data);

          let vehicle = res.data;

          let documentId = vehicle.documentTypeId;
          let documentProofField = this.getDocumentProofNumber(documentId);
          let documentProofNumber = vehicle[documentProofField];

          let documentUrlStateField = this.getDocumentProofUrl(documentId);
          let documentUrl = vehicle[documentUrlStateField];

          this.setState({
            vehicle: {
              ...res.data,
              tripType: 2,
              documentProofNumber: documentProofNumber,
              documentUrl: documentUrl,
            },
            popup: null,
          });
        })
        .catch(error => {
          const errorMsg = util.getErrorMessage(error);
          this.setState({
            vehicle: null,
          });
          this.showErrorPopup(errorMsg);
        });
    } else if (filter.value === FILTER_OPTIONS.RFID_TAG) {
      API.fetchVehicleByRFID(searchText.value)
        .then(res => {
          console.log('res.data.. reference number..', res.data);

          let vehicle = res.data;

          let documentId = vehicle.documentTypeId;
          let documentProofField = this.getDocumentProofNumber(documentId);
          let documentProofNumber = vehicle[documentProofField];

          let documentUrlStateField = this.getDocumentProofUrl(documentId);
          let documentUrl = vehicle[documentUrlStateField];

          this.setState({
            vehicle: {
              ...res.data,
              tripType: 2,
              documentProofNumber: documentProofNumber,
              documentUrl: documentUrl,
            },
            popup: null,
          });
        })
        .catch(error => {
          const errorMsg = util.getErrorMessage(error);
          this.setState({
            vehicle: null,
          });
          this.showErrorPopup(errorMsg);
        });
    } else {
      API.fetchVehicleByVehicleRegistrationNumber(searchText.value)
        .then(res => {
          console.log('res.data registration number....', res.data);

          let vehicle = res.data;

          let documentId = vehicle.documentTypeId;
          let documentProofField = this.getDocumentProofNumber(documentId);
          let documentProofNumber = vehicle[documentProofField];

          let documentUrlStateField = this.getDocumentProofUrl(documentId);
          let documentUrl = vehicle[documentUrlStateField];

          this.setState({
            vehicle: {
              ...res.data,
              tripType: 2,
              documentProofNumber: documentProofNumber,
              documentUrl: documentUrl,
            },
            popup: null,
          });
        })
        .catch(error => {
          const errorMsg = util.getErrorMessage(error);
          this.setState({
            vehicle: null,
          });
          this.showErrorPopup(errorMsg);
        });
    }
  };
  checkDisabled = () => {
    const {reducerState} = this.props;
    //vehicles contain driver, material and vehicle details
    const vehicles = reducerState.vehicles;
    // const driver = reducerState.vehicles.driver;

    if (vehicles.length > 0) {
      return false;
    } else {
      return true;
    }
  };
  onDriverAddCallback = driver => {
    this.setState({
      driver: driver,
    });
  };
  onAddVehicleClick = data => {
    const {history, location} = this.props;
    const {vehicle} = this.state;
    const {dispatch, reducerState} = this.props;
    console.log('vehicle::', vehicle, reducerState);

    if (vehicle.status === 'BLACKLISTED') {
      this.showErrorPopup('Vehicle blacklisted. Please add another vehicle.');
    } else if (!vehicle.RFIDNumber) {
      this.showErrorPopup(
        'RFID Number of the vehicle is missing. Please add another vehicle.',
      );
    } else {
      dispatch({
        type: 'add-vehicle',
        payload: vehicle,
      });
      const drivers = reducerState.vehicles.map(vObj => vObj.driver);
      //   callback for adding driver and vehicle
      history.push({
        pathname: '/u/permits/add-new-driver',
        state: {
          ...location.state,
          persons: [...location.state.persons, ...drivers],
          // vehicle: vehicle,
        },
      });
    }
  };
  deleteVehicleFromTable = data => {
    const {dispatch} = this.props;
    dispatch({
      type: 'delete-vehicle',
      payload: data,
    });
    console.log('delete called');
  };
  onAddMaterialClick = () => {
    const {history, location, reducerState} = this.props;

    history.push({
      pathname: '/u/permits/select-vehicle',
      state: {
        ...location.state,
        comingFrom: 'addVehiclesPage',
        vehicles: reducerState.vehicles,
      },
    });
  };

  deleteMaterialFromTable = data => {
    const {dispatch} = this.props;
    dispatch({
      type: 'delete-material',
      payload: data,
    });
  };
  onClick = (action, data) => {
    const {history, location, dispatchSetVehiclesData} = this.props;
    const {reducerState, dispatch} = this.props;
    switch (action) {
      case 'emptySearch': {
        this.setState({
          searchText: {value: ''},
        });
        break;
      }
      case 'search': {
        this.handleSearch();
        break;
      }
      case 'cancel': {
        history.push('/u/permits/request-permits');
        // this.goBack();
        break;
      }
      case 'addVehicle': {
        this.onAddVehicleClick(data);
        break;
      }
      case 'addMaterial': {
        // this.onAddVehicleClick(data);
        this.onAddMaterialClick(data);

        break;
      }
      case 'delete': {
        break;
      }
      case 'delete-vehicle': {
        console.log('delete vehicle called...', data);
        this.showConfirmationPopup(
          'Are you sure you want to delete the vehicle from the permit?',
          this.deleteVehicleFromTable,
          data,
        );
        break;
      }
      case 'delete-material': {
        this.showConfirmationPopup(
          'Are you sure you want to delete this material?',
          this.deleteMaterialFromTable,
          data,
        );

        break;
      }
      case 'submit': {
        dispatchSetVehiclesData(reducerState.vehicles);
        history.push('/u/permits/request-permits');
        break;
      }
      case 'viewVehicle': {
        this.setState({
          searchText: {value: ''},
        });
        this.onView(data);
        break;
      }

      case 'onTripChange': {
        console.log(data);
        const {vehicle} = this.state;
        this.setState({
          vehicle: {...vehicle, tripType: data},
        });
        break;
      }

      default: {
        console.warn('No action found...', action);
        break;
      }
    }
  };
  onView = url => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.VIEW_VEHICLE_POPUP,
        onClose: this.closePopup,
        viewDocument: this.viewDocument,
      },
    });
  };
  viewDocument = url => {
    // this.setState({
    //   popup: {
    //     type: POPUP_CONSTANTS.VIEW_DOCUMENT,
    //     url: url,
    //     onClick: this.closePopup,
    //   },
    // });
    const {history, location} = this.props;
    history.push({
      pathname: location.pathname + '/document-view',
      state: {background: location, url: url},
    });
  };
  getDocumentTempURL = url => {
    const {history, location} = this.props;
    const data = {
      url: url,
      expiryTime: 30,
    };
    API.preSignedURL(data)
      .then(res => {
        console.log('res.data', res.data?.preSignedUrl);
        history.push({
          pathname: location.pathname + '/document-view',
          state: {background: location, url: res.data?.preSignedUrl},
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };
  onChangeHandler = (field, value) => {
    console.log('field', field, 'value', value);

    this.setState(prevState => ({
      ...prevState,
      [field]: {
        value,
      },
    }));
    console.log('this.state', this.state);
    if (field === 'filter') {
      this.setState({
        searchText: {value: ''},
      });
    }
  };
  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  showConfirmationPopup = (message, onConfirm, data) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: message,
        messageStyle: {color: '#E67717'},
        // headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.cancelPopup,
            onClick: this.closePopup,
            outline: true,
          },
          {
            text: strings.confirm,
            onClick: onConfirm
              ? () => {
                  onConfirm(data);
                  this.setState({
                    popup: null,
                  });
                }
              : this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  closePopup = () => {
    this.setState({
      popup: null,
    });
  };

  computeVehiclesTableData = () => {
    const {reducerState} = this.props;
    const vehicles = reducerState.vehicles;
    let finalArray = [];

    vehicles.map(vObj => {
      console.log('vObj', vObj);
      let vehicleObj = {
        vehicleId: vObj.vehicle.id,
        // driverId: vObj.driver.id,
        //FIXME  replace vehicleRegistrationNumber with vechile name
        entityName: vObj.vehicle.vehicleRegistrationNumber,
        entityRFIDNumber: vObj.vehicle.rfidnumber,
        entityRequestType: 'Vehicle',
        entityDocumentProofNumber: '-',
      };

      // let driverObj = {
      //   vehicleId: vObj.vehicle.id,
      //   driverId: vObj.driver.id,
      //   entityName: vObj.driver.name,
      //   entityRFIDNumber: vObj.driver.rfidnumber,
      //   entityRequestType: `Vehicle - Driver (${vObj.vehicle.vehicleRegistrationNumber})`,
      //   entityDocumentProofNumber: vObj.driver.aadhaarNumber,
      // };
      finalArray.push(vehicleObj);
      //  finalArray.push(driverObj);
    });

    return finalArray;
  };
  computeMaterialsTableData = () => {
    const {reducerState} = this.props;
    const vehicles = reducerState.vehicles;
    let finalArray = [];

    vehicles.map(vObj => {
      if (vObj.material) {
        console.log('vObj.material', vObj.material);
        let materialObj = {
          id: vObj.material.id,
          vehicleId: vObj.vehicle.id,
          //   driverId: vObj.driver.id,
          entityMaterialType: vObj.material.materialType.name,
          entityNumber:
            vObj.material.IGNNumber ||
            vObj.material.CHACode ||
            vObj.material.workOrderPermitNumber,
          entityValidityDate: vObj.material.validDate
            ? moment(vObj.material.validDate).format('DD/MM/YYYY')
            : moment(vObj.material.requestValidUpto).format('DD/MM/YYYY'),
          entityLinkedVehicle: vObj.vehicle.vehicleRegistrationNumber,
        };
        // finalArray.push(vehicleObj);
        console.log('materialObj---compute function', materialObj);
        finalArray.push(materialObj);
      }
    });

    return finalArray;
  };

  checkMaterialDisabledButton = () => {
    const {reducerState} = this.props;

    // check if any vehicle has material = undefined. If Yes, allow add material button
    let requiredData = reducerState.vehicles.filter(elem =>
      elem.material ? false : true,
    );

    // const vehicles = reducerState.vehicles;
    if (requiredData.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  getProps = () => {
    const {vehicle, searchText, filterOptions, filter} = this.state;
    const {reducerState} = this.props;
    const globalVehicles = this.props.location.state.vehicles;
    return {
      goBack: this.goBack,
      onClick: this.onClick,
      onChange: this.onChangeHandler,
      checkDisabled: this.checkDisabled,
      vehicle,
      vehicles: reducerState.vehicles,
      globalVehicles: globalVehicles,
      searchText: searchText?.value,
      vehiclesTableData: this.computeVehiclesTableData(),
      materialsTableData: this.computeMaterialsTableData(),
      checkMaterialDisabledButton: this.checkMaterialDisabledButton,
      filterOptions,
      filter,
    };
  };
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer containerStyle={styles.popupContainerStyle}>
            {' '}
            {this.getPopupContent()}{' '}
          </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

const mapStateToProps = reduxProps => ({
  reduxProps,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetVehiclesData: payload => {
    dispatch(setVehicleData(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddVehicle),
);
