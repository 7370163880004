import React, {useEffect, useRef, useState} from 'react';
import Flex from '../../../Flex';
import SannaLogo from '../../../SannaLogo';
import styles from './styles.module.scss';

import first from '../../../../assets/images/Landing/technology-overview/1.png';
import second from '../../../../assets/images/Landing/technology-overview/2.png';
import third from '../../../../assets/images/Landing/technology-overview/3.png';
import fourth from '../../../../assets/images/Landing/technology-overview/4.png';
import illustration from '../../../../assets/images/Landing/technology-overview/image.png';

import iot from '../../../../assets/images/Landing/technology-overview/iot.png';
import cctv from '../../../../assets/images/Landing/technology-overview/cctv.png';
import smart from '../../../../assets/images/Landing/technology-overview/smart.png';

import helmet from '../../../../assets/images/Landing/technology-overview/cctv/helmet.png';
import smoke from '../../../../assets/images/Landing/technology-overview/cctv/smoke.png';
import container from '../../../../assets/images/Landing/technology-overview/cctv/container.png';
import night from '../../../../assets/images/Landing/technology-overview/cctv/night.png';
import subtract from '../../../../assets/images/Landing/technology-overview/cctv/subtract2.png';
import cctvIllustration from '../../../../assets/images/Landing/technology-overview/cctv/illustration.png';

import {AnimatePresence, motion} from 'framer-motion';
import clsx from 'clsx';

export const TechnologyOverview = () => {
  const CCTVComponent = () => {
    const graph = useRef(null);
    useEffect(() => {
      rotateCircle();
    }, []);

    const rotateCircle = ang => {
      const ciclegraph = graph.current;
      const circleElements = ciclegraph?.childNodes;
      // remove svg from here

      let angle = ang || 360 - 90;
      let dangle = 360 / circleElements.length;

      for (let i = 0; i < circleElements.length; i++) {
        let circle = circleElements[i];
        angle += dangle;
        circle.style.transform = `rotate(${angle}deg) translate(${
          ciclegraph.clientWidth / 2
        }px) rotate(-${angle}deg)`;
      }
    };

    return (
      <div style={{position: 'relative', marginTop: '4rem'}}>
        <div
          className={clsx(styles.ciclegraph, styles.cctvCircleAnimation)}
          ref={graph}>
          {CCTVCards.map((el, idx) => (
            <div key={`cctv-images-${idx}`} className={clsx(styles.circleCCTV)}>
              <div
                className={clsx(
                  styles.innerCircleCCTV,
                  styles.cctvCircleAnimationReverse,
                )}
                style={{
                  backgroundColor: '#FFFFFF',
                }}>
                <img
                  src={el?.src}
                  alt={el?.alt || 'icon'}
                  style={{
                    height: '1.3rem',
                    width: '1.3rem',
                    objectFit: 'contain',
                  }}
                />
                <p
                  // className={styles.cctvCircleAnimation}
                  style={{
                    fontSize: '0.6rem',
                    position: 'absolute',
                    bottom: '-3rem',

                    width: '6rem',
                    margin: '0 auto',
                    textAlign: 'center',
                  }}>
                  {el?.text}
                </p>
              </div>
            </div>
          ))}
        </div>
        <img
          className={styles.cctvIllustration}
          src={cctvIllustration}
          alt="cctv"
        />
      </div>
    );
  };
  const CCTVCards = [
    {
      id: 0,
      src: helmet,
      text: 'Absense of Safety Equipment',
    },
    {
      id: 1,
      src: container,
      text: 'Container Damage Detection',
    },
    {
      id: 2,
      src: subtract,
      text: 'Intrusion Detection',
    },
    {
      id: 3,
      src: night,
      text: 'Night Vision',
    },
    {
      id: 4,
      src: smoke,
      text: 'Fire and Smoke Detector',
    },
  ];
  const cards = [
    {
      id: 0,
      name: 'AI_POWERED',
      src: first,
      text: 'AI-Powered Applications',
      subText:
        'Legacy systems fall short when it comes to authentication. saññã uses AI to implement FRT, predictive analysis, etc. and to automate tasks related to surveillance, access control, workforce allocation, permit handling, berth allocation, storage space allocation, and intrusion detection without any manual interference as per a set of pre-defined conditions.',
      content: <MovingContainer />,
    },
    {
      id: 1,
      src: second,
      name: 'SMART_ANALYTICS',
      text: 'Smart Analytics ',
      subText: (
        <>
          Using Smart analytics in your ports helps to draw meaningful insights
          from the cluttered bulk data making it understandable and usable. It
          can provide real-time insights to assist you in taking critical
          decisions at the right time.
          <br /> Our expertise in big data tools, predictive analysis, and deep
          understanding of port operations help us to draw tailor-made
          strategies and place you one step ahead
        </>
      ),
      content: (
        <>
          <svg
            id="circle-svg"
            viewBox="0 0 1026 1026"
            fill="none"
            aria-hidden="true"
            className={styles.animateSpinSlow}>
            <path
              d="M1025 513c0 282.77-229.23 512-512 512S1 795.77 1 513 230.23 1 513 1s512 229.23 512 512Z"
              stroke="#D4D4D4"
              strokeOpacity="0.7"></path>
            <path
              d="M513 1025C230.23 1025 1 795.77 1 513"
              stroke="url(#DBZ2x_crX9o0sotz6Y9G--gradient-1)"
              strokeLinecap="round"></path>
            <defs>
              <linearGradient
                id="DBZ2x_crX9o0sotz6Y9G--gradient-1"
                x1="1"
                y1="513"
                x2="1"
                y2="1025"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#06b6d4"></stop>
                <stop offset="1" stopColor="#06b6d4" stopOpacity="0"></stop>
              </linearGradient>
            </defs>
          </svg>
          <img
            className={styles.illustrationSmartAnalytics}
            src={smart}
            alt="smart analytics"
          />
        </>
      ),
    },
    {
      id: 2,
      name: 'IOT',
      src: third,
      text: 'IOT',
      subText: (
        <>
          Port equipment when operated in silos results in delayed information
          flow, manual interference, and process sluggishness.
          <br /> Sanna enhances user experience by creating a network of
          interlinked devices using standard connectors which establish an
          efficient information flow between them. These interlinked devices
          facilitate smoother workflows, real-time information sharing, and
          effective communication.
        </>
      ),
      content: <img className={styles.illustration} src={iot} alt="iot" />,
    },
    {
      id: 3,
      src: third,
      name: 'CCTV',
      text: 'CCTV Surveillance',
      subText: (
        <>
          We use advanced CCTV cameras that are equipped with industry-standard
          features like night vision, and AI-powered functionalities like
          intrusion detection, detection of the absence of safety equipment,
          container damage detection, etc.
          <br /> These CCTV systems can be integrated with the existing
          surveillance system and operated from the command center.
        </>
      ),
      content: <CCTVComponent />,
    },
  ];

  const variants = {
    hidden: {opacity: 0, y: '-1vh'},
    show: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.1,
      },
    },
    // transition: { staggerChildren: 0.5 },
    exit: {opacity: 0, y: '1vh'},
  };
  const itemA = {
    hidden: {opacity: 0, height: 0},
    show: {opacity: 1, height: 'auto'},
    exit: {opacity: 0, height: 0},
  };

  return (
    <div className={styles.technologyOverviewContainer}>
      <div className={styles.leftContainer}>
        <h2 className={styles.header}>Technology Overview</h2>
        <div className={styles.leftContainerContent}>
          <AnimatePresence>
            {cards.map((el, idx) => (
              <div
                key={`current-technology-${idx}`}
                className={clsx(styles.featuresContainer)}>
                <h3
                  style={{
                    color: '#ec8940',
                    fontSize: '1.6rem',
                  }}>
                  {el?.text}
                </h3>
                <p
                  style={{
                    fontSize: '1rem',
                  }}>
                  {el.subText}
                </p>
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    height: '32rem',
                  }}>
                  {el.content}
                </div>
              </div>
            ))}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

const MovingContainer = () => {
  useEffect(() => {
    setPropertiesRandomly();
  }, []);

  const setPropertiesRandomly = () => {
    // use CSS setProperty to set --firstCircleAnimation and --secondCircleAnimation
    const firstCircleInitial = Math.floor(Math.random() * 360);
    const firstCircleFinal = 360 + firstCircleInitial;

    const secondCircleInitial = Math.floor(Math.random() * 360);
    const secondCircleFinal = 360 + secondCircleInitial;

    const thirdCircleInitial = Math.floor(Math.random() * 360);
    const thirdCircleFinal = 360 + thirdCircleInitial;

    const fourthCircleInitial = Math.floor(Math.random() * 360);
    const fourthCircleFinal = 360 + fourthCircleInitial;

    document.documentElement.style.setProperty(
      '--first-circle-initial',
      `${firstCircleInitial}deg`,
    );
    document.documentElement.style.setProperty(
      '--first-circle-final',
      `${firstCircleFinal}deg`,
    );

    document.documentElement.style.setProperty(
      '--second-circle-initial',
      `${secondCircleInitial}deg`,
    );
    document.documentElement.style.setProperty(
      '--second-circle-final',
      `${secondCircleFinal}deg`,
    );

    document.documentElement.style.setProperty(
      '--third-circle-initial',
      `${thirdCircleInitial}deg`,
    );
    document.documentElement.style.setProperty(
      '--third-circle-final',
      `${thirdCircleFinal}deg`,
    );

    document.documentElement.style.setProperty(
      '--fourth-circle-initial',
      `${fourthCircleInitial}deg`,
    );
    document.documentElement.style.setProperty(
      '--fourth-circle-final',
      `${fourthCircleFinal}deg`,
    );
  };
  return (
    <>
      <img
        src={illustration}
        alt="illustration"
        className={styles.illustrationAI}
      />
      <div className={styles.circleBoundary}>
        <div className={clsx(styles.circle, styles.firstCircleAnimation)}>
          <img
            src={first}
            alt="icon"
            style={{height: '1rem', width: '1rem', objectFit: 'contain'}}
          />
        </div>
      </div>
      <div
        className={styles.circleBoundary}
        style={{
          height: '20rem',
          width: '20rem',
          border: '1px dashed #82828240',
        }}>
        <div className={clsx(styles.circle, styles.secondCircleAnimation)}>
          <img
            src={second}
            alt="icon"
            style={{height: '1rem', width: '1rem', objectFit: 'contain'}}
          />
        </div>
      </div>
      <div
        className={styles.circleBoundary}
        style={{
          height: '25rem',
          width: '25rem',
          border: '1px dashed #82828230',
        }}>
        <div className={clsx(styles.circle, styles.thirdCircleAnimation)}>
          <img
            src={third}
            alt="icon"
            style={{height: '1rem', width: '1rem', objectFit: 'contain'}}
          />
        </div>
      </div>
      <div
        className={styles.circleBoundary}
        style={{
          height: '30rem',
          width: '30rem',
          border: '1px dashed #82828220',
        }}>
        <div className={clsx(styles.circle, styles.fourthCircleAnimation)}>
          <img
            src={fourth}
            alt="icon"
            style={{height: '1rem', width: '1rem', objectFit: 'contain'}}
          />
        </div>
      </div>
    </>
  );
};
