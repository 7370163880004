import Axios from 'axios';
import URLs from './configuration';

Axios.defaults.timeout = 60000;
Axios.defaults.headers.common['Content-Type'] = 'application/json';

const Config = {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
};

class Requests {
  constructor() {
    this.axios = Axios.create({
      baseURL: Config.API_BASE_URL,
    });
    this.token =
      'eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiJEUFRfSldUIiwic3ViIjoie1wibGFzdE5hbWVcIjpcIlwiLFwiYWRkcmVzc1wiOlwiXCIsXCJyb2xlSWRcIjo4LFwiZGVwYXJ0bWVudElkXCI6MSxcInJvbGVzXCI6e1wicm9sZUlkXCI6OCxcIm5hbWVfaGluZGlcIjpcIuCkleCkguCkquCkqOClgF_gpLjgpYHgpKrgpLBf4KSP4KSh4KSu4KS_4KSoXCIsXCJuYW1lXCI6XCJDT01QQU5ZX1NVUEVSX0FETUlOXCIsXCJzdGF0dXNcIjpcIkFDVElWRVwifSxcImlkUHJvb2ZEb2N1bWVudFwiOlwiXCIsXCJwaG90b1wiOlwiXCIsXCJ0aXRsZVwiOlwiXCIsXCJ1c2VySWRcIjoxMDczLFwiZmlyc3ROYW1lXCI6XCJzYW4gYWplbmN5MlwiLFwiZW1haWxBZGRyZXNzXCI6XCJzYW4uYm9sbGEzQHlvcG1haWwuY29tXCIsXCJjb21wYW55SWRcIjoyNjQsXCJjb2duaXRvVXNlcm5hbWVcIjpcIjI1YmI4ZjVlLWZkNzYtNDBmMi1hZDhiLTE2MDA5MTE0MzcxNFwiLFwiY3JlYXRlZERhdGVcIjpcIjIwMjEtMTItMjNUMDc6MTVaXCIsXCJwaG9uZU51bWJlclwiOlwiKzkxNTU1NTU1NTU1NVwiLFwibW9kaWZpZWREYXRlXCI6XCIyMDIyLTAxLTMxVDExOjQ3WlwiLFwiYXV0aG9yaXplZERvY3VtZW50XCI6XCJcIixcIm1pZGRsZU5hbWVcIjpcIlwiLFwiZGVzaWduYXRpb25cIjpcIlwiLFwic3RhdHVzXCI6XCJBQ1RJVkVcIn0iLCJhdXRob3JpdGllcyI6WyJDT01QQU5ZX1NVUEVSX0FETUlOIl0sImlhdCI6MTY0MzY0MzA4NSwiZXhwIjoxNjQzNzI5NDg1fQ.xSsL1gRC0VrNVH-Zu515p7zHnOxkYHLwLP60QUdmf9B7f6N60AgRz2sXGEnlOCzqcCdiGwjWmy5kYPjrJY7OiQ';

    this.preLoginToken = null;
  }

  setBuildURL = url => {
    this.axios.baseURL = url;
  };
  setBearerToken = token => {
    this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    this.token = token;
  };

  setPreLoginToken = token => {
    console.log(token?.data);
    this.preLoginToken = token?.data;
    this.axios.defaults.headers.common.sessionToken = `${token?.data}`;
  };

  getPreLoginToken = () => {
    return this.axios.get(URLs.getPreLoginToken);
  };

  login = cognitoToken => {
    return this.axios.get(URLs.login, {
      headers: {
        Authorization: `Bearer ${cognitoToken}`,
      },
    });
  };

  logout = () => {
    return this.axios.get(URLs.logout);
  };

  getAllDepartments = () => {
    return this.axios.get(URLs.department);
  };
  getAllBusinesses = () => this.axios.get(URLs.businesses);

  registerCompany = formData => {
    console.log('AXIOS FORM DATA', formData);
    return this.axios.post(URLs.registerCompany, formData, {
      headers: {
        sessionToken: `${this.preLoginToken}`,
      },
    });
  };
  getAllWorkPurpose = () => {
    return this.axios.get(URLs.workPurpose, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  getAlldeleteWorkPurpose = () => {
    return this.axios.get(URLs.deleteWorkPurpose, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  getAllWorkArea = () => {
    // console.log('Work Purpose', formData);
    return this.axios.get(URLs.workArea, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  getAllGender = () => {
    // console.log('Work Purpose', formData);
    return this.axios.get(URLs.gender);
  };

  getStatusesByDate = (startingDate, endingDate) => {
    return this.axios.get(URLs.statuses, {
      params: {
        visitingDateStart: startingDate,
        visitingDateEnd: endingDate,
      },
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  submitIndividualRequestPermit = formData => {
    console.log('AXIOS INDIVIDUAL PERMIT REQUEST FORMDATA', formData);
    return this.axios.post(URLs.registerPermit, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  getStatusByReferenceId = referenceId => {
    return this.axios.get(`${URLs.companyStatus}/${referenceId}`);
  };

  uploadToS3 = formData => {
    return this.axios.post(URLs.fileUpload, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };
  uploadFilesToDpt = formData => {
    return this.axios.post(URLs.uploadFilesToDpt, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  approveIndividualPermit = id => {
    return this.axios.post(
      URLs.approveIndividualPermit,
      { id: id },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };
  rejectIndividualPermit = id => {
    return this.axios.post(
      URLs.rejectIndividualPermit,
      { id: id },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };
  fetchAllIndividualRequests = (startingDate, endingDate) => {
    return this.axios.get(URLs.allIndividualPermit, {
      params: {
        visitingDateStart: startingDate,
        visitingDateEnd: endingDate,
      },
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  // FIXME: Fetch by startDate and endDate - API change
  fetchAllCompanies = (startDate, endDate) => {
    return this.axios.get(URLs.allCompanies, {
      params: {
        startDate: startDate,
        endDate: endDate,
      },
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  approveAgencyRequest = id => {
    return this.axios.post(
      URLs.approveRequest,
      { id: id },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };
  rejectAgencyRequest = (id, message) => {
    return this.axios.post(
      URLs.rejectRequest,
      { id: id, notes: message },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };
  approveVehicleRequest = id => {
    return this.axios.post(
      URLs.approveVehicle,
      { id: id },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };
  rejectVehicleRequest = id => {
    return this.axios.post(
      URLs.rejectVehicle,
      { id: id },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };
  submitVehiclePermitRequest = formData => {
    console.log('AXIOS INDIVIDUAL PERMIT REQUEST FORMDATA', formData);
    return this.axios.post(URLs.registerVehicle, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  fetchAllVehiclesByDate = (startDate, endDate) => {
    console.log('startDate', startDate, 'endDate', endDate);
    return this.axios.get(URLs.vehicleRequests, {
      params: {
        visitingDateStart: startDate,
        visitingDateEnd: endDate,
      },
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  fetchMasterVehicleDetails = (startDate, endDate) => {
    console.log('startDate', startDate, 'endDate');
    return this.axios.get(URLs.allVehicleRequests, {
      params: {
        visitingDateStart: startDate,
        visitingDateEnd: endDate,
      },
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  registerPerson = formData => {
    // console.log('AXIOS FORM DATA', formData);
    return this.axios.post(URLs.personRegistration, formData, {
      headers: {
        sessionToken: `${this.preLoginToken}`,
      },
    });
  };
  registerVehicle = formData => {
    // console.log('AXIOS FORM DATA', formData);
    return this.axios.post(URLs.vehicleRegister, formData, {
      headers: {
        sessionToken: `${this.preLoginToken}`,
      },
    });
  };
  getPersonByReferenceId = referenceId => {
    // console.log('AXIOS FORM DATA', formData);
    console.log(`${URLs.getOnePerson}/${referenceId}`);
    return this.axios.get(`${URLs.getOnePerson}/${referenceId}`);
  };
  getVehicleByReferenceId = referenceId => {
    // console.log('AXIOS FORM DATA', formData);
    console.log(`${URLs.getOneVehicle}/${referenceId}`);
    return this.axios.get(`${URLs.getOneVehicle}/${referenceId}`);
  };

  getVehicleByRegRefRFID = regRefRFID => {
    // console.log('AXIOS FORM DATA', formData);
    return this.axios.get(`${URLs.vehicleByRegRefRFID}/${regRefRFID}`);
  };

  getPermitStatusForPedestrian = id => {
    // console.log('AXIOS FORM DATA', formData);
    return this.axios.get(`${URLs.checkPermitStatusForPedestrian}/${id}`);
  };


  getVehicleByIdNumber = referenceId =>
    this.axios.get(`${URLs.getVehicleByIdNumber}/${referenceId}`);

  fetchOptions = entity => {
    // console.log('AXIOS FORM DATA', formData);
    console.log(`${URLs.fetchOptions}/${entity}`);
    return this.axios.get(`${URLs.fetchOptions}/${entity}`);
  };

  fetchPersonDocumentTypes = () => {
    // console.log('AXIOS FORM DATA', formData);

    return this.axios.get(URLs.fetchPersonDocumentTypes);
  };

  getPersonReferenceNumber = (idType, idNumber) => {
    console.log('idType, idNumber', idType, idNumber);
    return this.axios.get(URLs.getPersonReferenceNumber, {
      params: {
        idType: idType,
        idNumber: idNumber,
      },
    });
  };
  getVehicleReferenceNumber = (idType, idNumber) => {
    return this.axios.get(URLs.getVehicleReferenceNumber, {
      params: {
        idType: idType,
        idNumber: idNumber,
      },
    });
  };
  getCompanyReferenceNumber = (idType, idNumber) => {
    return this.axios.get(URLs.getCompanyReferenceNumber, {
      params: {
        idType: idType,
        idNumber: idNumber,
      },
    });
  };
  getCompanyByReferenceId = referenceId => {
    return this.axios.get(`${URLs.companyStatus}/${referenceId}`);
  };

  getAllPersonOptions = () => {
    return this.axios.get(URLs.fetchAllPersonOptions);
  };
  getAllCountries = () => {
    return this.axios.get(URLs.fetchAllCountries);
  };

  getAllBloodGroups = () => {
    return this.axios.get(URLs.fetchAllBloodGroups);
  };

  getAllVehicleCategories = () => {
    // return this.axios.get(URLs.fetchActiveVehicleTypes);
    return this.axios.get(URLs.getAllVehicleCategories);
  };

  getAllVehicleTypes = () => {
    return this.axios.get(URLs.fetchActiveVehicleTypes);
  };

  generateOtp = formData => {
    console.log('AXIOS FORM DATA', formData);
    return this.axios.post(URLs.generateOtp, formData, {
      headers: {
        sessionToken: `${this.preLoginToken}`,
      },
    });
  };

  verifyOtp = formData => {
    console.log('AXIOS FORM DATA', formData);
    return this.axios.post(URLs.verifyOtp, formData, {
      headers: {
        sessionToken: `${this.preLoginToken}`,
      },
    });
  };

  // permits
  getPermitStatusDetails = (startDate, endDate) => {
    return this.axios.get(URLs.fetchPermitsDashboard, {
      params: {
        startDate: startDate || '09-01-2021',
        endDate: endDate || '12-30-2023',
      },

      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  fetchPermitByReferenceId = referenceId => {
    console.log('fetchPermitByReferenceId', referenceId);
    return this.axios.get(URLs.fetchPermitByReferenceId + referenceId, {
      headers: {
        // Authorization: `Bearer ${this.token}`,
      },
    });
  };

  fetchImportMaterialData = formData => {
    console.log('FETCH IMPORT MATERIAL DATA METHOD INPUT:', formData);
    return this.axios.post(URLs.fetchMaterialData, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  fetchExportMaterialData = formData => {
    console.log('FETCH IMPORT MATERIAL DATA METHOD INPUT:', formData);
    return this.axios.post(URLs.fetchExportMaterialData, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  // ADMIN
  getAllAgencyDetails = (
    startDate,
    endDate,
    status,
    pageNumber,
    pageSize,
    searchString,
  ) =>
    this.axios.get(URLs.fetchAllPermitDetails, {
      params: {
        startDate: startDate || '09-01-2019',
        endDate: endDate || '12-30-2100',
        status: status || undefined,
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchString: searchString || undefined,
      },
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });

  // OPERATORS
  fetchActiveOperator = () => {
    return this.axios.get(URLs.fetchAllOperators, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  fetchActiveLocation = () => {
    return this.axios.get(URLs.fetchActiveLocation, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  saveOperator = formData => {
    console.log('AXIOS - SAVEOPERATOR', formData);
    return this.axios.post(URLs.saveOperatore, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  deleteOperator = id => {
    console.log('AXIOS - DELETEOPERATOR', id);
    return this.axios.post(
      URLs.deleteOperator,
      { id: id },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };

  updateOperator = formData => {
    console.log('AXIOS - UPDATEOPERATOR', formData);
    return this.axios.post(URLs.updateOperator, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  fetchOperatorById = operatorId => {
    console.log(
      'fetch location by ID AXIOS - ',
      `${URLs.fetchOperatorById}/${operatorId}`,
    );
    return this.axios.get(`${URLs.fetchOperatorById}/${operatorId}`, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  // Location

  saveLocation = formData => {
    return this.axios.post(URLs.saveLocation, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  deleteLocation = id => {
    console.log('delete location AXIOS - ', id);
    return this.axios.post(
      URLs.deleteLocation,
      { id: id },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };
  updateLocation = formData => {
    return this.axios.post(URLs.updateLocation, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  // fetchShift
  fetchShift = locationId => {
    console.log(
      'fetch location by ID AXIOS - ',
      `${URLs.fetchShift}/${locationId}`,
    );
    return this.axios.get(`${URLs.fetchShift}/${locationId}`, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  fetchLocationById = locationId => {
    console.log(
      'fetch location by ID AXIOS - ',
      `${URLs.fetchLocationById}/${locationId}`,
    );
    return this.axios.get(`${URLs.fetchLocationById}/${locationId}`, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  // Person
  approvePerson = formData => {
    return this.axios.post(URLs.operatorApprovePerson, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  rejectPerson = formData => {
    console.log('AXIOS - reject person', formData);
    return this.axios.post(URLs.operatorRejectPerson, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  surrenderPerson = formData => {
    return this.axios.post(URLs.surrenderPerson, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  updatePersonByOperator = formData => {
    return this.axios.post(URLs.updatePersonByOperator, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  // Vehicle
  approveVehicle = formData => {
    return this.axios.post(URLs.operatorApproveVehicle, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  rejectVehicle = formData => {
    console.log('AXIOS - reject vehicle', formData);
    return this.axios.post(URLs.operatorRejectVehicle, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  surrenderVehicle = formData => {
    return this.axios.post(URLs.surrenderVehicle, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  updateVehicleByOperator = formData => {
    return this.axios.post(URLs.updateVehicleByOperator, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  // Messages
  fetchAllSentMessages = () => {
    return this.axios.get(URLs.fetchAllSentMessages, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  fetchAllReceivedMessages = () => {
    return this.axios.get(URLs.fetchAllReceivedMessages, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  sendMessage = formData => {
    return this.axios.post(URLs.sendMessage, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  fetchAllRoles = () => {
    return this.axios.get(URLs.fetchAllRoles, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  // Payments
  createPaymentOrderId = () => {
    return this.axios.get(URLs.createPaymentOrderId);
  };
  personPaymentFailure = formData => {
    return this.axios.post(URLs.personPaymentFailure, formData);
  };
  vehiclePaymentFailure = formData => {
    return this.axios.post(URLs.vehiclePaymentFailure, formData);
  };
  personPaymentSuccess = formData => {
    return this.axios.post(URLs.personPaymentSuccess, formData);
  };
  vehiclePaymentSuccess = formData => {
    return this.axios.post(URLs.vehiclePaymentSuccess, formData);
  };

  // Agency Requests
  getVehicleValidity = () => {
    return this.axios.get(URLs.fetchVehicleValidity, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  // Materials
  fetchAllMaterialTypes = () => {
    return this.axios.get(URLs.fetchAllMaterialTypes);
  };
  fetchAllNatureOfCargo = () => {
    return this.axios.get(URLs.fetchAllNatureOfCargo);
  };

  fetchAllUnitOfWeight = () => {
    return this.axios.get(URLs.fetchAllUnitOfWeight);
  };

  fetchAllPermitTypes = () => {
    return this.axios.get(URLs.fetchAllPermitTypes);
  };
  fetchAllVehicleTypes = () => {
    return this.axios.get(URLs.fetchAllVehicleTypes);
  };
  fetchDriversForCompany = () => {
    console.log('fetch drivers for company...');
    return this.axios.get(URLs.fetchDriversForCompany, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  // Check person
  checkPerson = phoneNumber => {
    return this.axios.post(URLs.checkPerson, { phoneNumber: phoneNumber });
  };
  checkVehicle = vehicleRegistrationNumber => {
    return this.axios.post(URLs.checkVehicle, {
      vehicleRegistrationNumber: vehicleRegistrationNumber,
    });
  };
  createOrder = amount => {
    return this.axios.post(
      URLs.createOrder,
      { amount: amount },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };
  addCredits = formData => {
    return this.axios.post(URLs.addCredits, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  getCreditWalletDetails = () => {
    console.log('getCreditWalledDetails...');
    return this.axios.get(URLs.getCreditWalletDetails, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  getCreditsUsageHistory = (fromDate, toDate) => {
    console.log('getCreditsUsageHistory...');
    return this.axios.get(URLs.getCreditsUsageHistory, {
      params: {
        fromDate: fromDate || '09-01-2020',
        toDate: toDate || '12-30-2023',
      },
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  updatePersonDrivingLicence = formData => {
    return this.axios.post(URLs.updatePersonDrivingLicence, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  //AGENCY USER MANAGEMENT
  fetchCompanyUsers = status =>
    this.axios.get(URLs.fetchCompanyUsers, {
      params: {
        status: status,
      },
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  getActiveVesselTypes = () =>
    this.axios.get(URLs.fetchActiveVesselTypes, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  getAgencyRoles = () =>
    this.axios.get(URLs.getAgencyRoles, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });

  addAgencyUser = formData => {
    console.log('AXIOS ADD USER FORM DATA', formData);
    return this.axios.post(URLs.addAgencyUser, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  deleteAgencyUser = data => {
    console.log('AXIOS ADD USER FORM DATA', data);
    return this.axios.post(URLs.deleteAgencyUser, data, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  editAgencyUser = formData => {
    console.log('AXIOS ADD USER FORM DATA', formData);
    return this.axios.post(URLs.editAgencyUser, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  updateCompanyPendingUser = formData => {
    console.log('AXIOS ADD USER FORM DATA', formData);
    return this.axios.post(URLs.updateCompanyPendingUser, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  // Agency APIs.
  getAllCompanyPermitDetails = params => {
    console.log(params);

    return this.axios.get(URLs.fetchAllCompanyPermitDetails, {
      params: params,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  getAllCompanyPermitDetailsFromView = params => {
    console.log(params);

    return this.axios.get(URLs.fetchAllCompanyPermitsInViews, {
      params: params,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  getAllCompanyPermitDetailsForCSV = params => {
    return this.axios.get(URLs.fetchAllCompanyPermitsInViews, {
      params: params,
      timeout: 60000,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  fetchPermitDetailsForViews = id => {
    return this.axios.get(URLs.fetchPermitDetailsForViews, {
      params: {
        permitId: id,
      },
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  registerPermit = formData => {
    console.log('AXIOS INDIVIDUAL REGISTER PERMIT FORM DATA', formData);
    return this.axios.post(URLs.registerPermit, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  getAllPasses = () => {
    return this.axios.get(URLs.getAllPasses, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  getPacksByPassCategory = id => {
    return this.axios.get(`${URLs.getPacksByPassCategory}/${id}`, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  paymentSuccess = formData => {
    return this.axios.post(URLs.paymentSuccess, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  paymentFailure = formData => {
    return this.axios.post(URLs.paymentFailure, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  calculatePayment = formData => {
    return this.axios.post(URLs.calculatePayment, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  createOrderWallet = formData => {
    return this.axios.post(URLs.createOrderWallet, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  editUserProfile = formData => {
    return this.axios.post(URLs.editUserProfile, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  updateCompanyDetails = formData => {
    return this.axios.post(URLs.updateCompanyDetails, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  // agency vehicle/person movement report
  fetchAgencyReportsForVehicles = params => {
    return this.axios.get(URLs.agencyReportsForVehicles, {
      params: params,
      timeout: 60000,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  fetchAgencyReportsForPersons = params => {
    return this.axios.get(URLs.agencyReportsForPersons, {
      params: params,
      timeout: 60000,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  fetchAgencyReportsForVehicleEntryAndExit = params => {
    return this.axios.get(URLs.agencyReportsForVehicleEntryAndExit, {
      params: params,
      timeout: 60000,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };


  // Traffic department APIs.
  fetchAllMaterialPermitDetailsForTraffic = (startDate, endDate, status) => {
    return this.axios.get(URLs.fetchAllMaterialPermitDetailsForTraffic, {
      params: {
        startDate: startDate || '09-01-2020',
        endDate: endDate || '12-30-2090',
        status: status ? status : null,
      },
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  fetchAllMaterialPermitDetailsForTrafficByVCN = (VCNNumber, visitingDate) => {
    return this.axios.get(
      `${URLs.fetchAllMaterialPermitDetailsForTrafficByVCN}`,
      {
        params: {
          vcn: VCNNumber,
          visitingDate: visitingDate,
        },
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };

  // fetchAllMaterialPermitDetailsForTrafficByVCN = VCNNumber =>
  //   this.axios.get(
  //     `${URLs.fetchAllMaterialPermitDetailsForTrafficByVCN}/${VCNNumber}`,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${this.token}`,
  //       },
  //     },
  //   );

  approveBulkPermits = data => {
    console.log('AXIOS POST:', data);

    return this.axios.post(URLs.approveBulk, data, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  rejectBulkPermits = data => {
    console.log('AXIOS POST:', data);
    return this.axios.post(URLs.rejectBulk, data, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  fetchDepartmentVCNNumbers = (startDate, endDate) => {
    return this.axios.get(URLs.fetchDepartmentVCNNumbers, {
      params: {
        startDate: startDate || '01-01-2021',
        endDate: endDate || '12-30-2090',
      },
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  //DEPARTMENT APIs
  getAllDepartmentUsers = () => {
    return this.axios.get(URLs.fetchAllDepartmentUsers, {
      params: {
        pageNumber: 0,
        pageSize: 5000,
      },
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  getDepartmentRolesList = () => {
    return this.axios.get(URLs.fetchDepartmentRolesList, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  addDepartmentUser = formData => {
    console.log('AXIOS ADD USER FORM DATA', formData);
    return this.axios.post(URLs.addDepartmentUser, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  deleteDepartmentUser = data => {
    console.log('AXIOS ADD USER FORM DATA', data);
    return this.axios.post(URLs.deleteDepartmentUser, data, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  editDepartmentUser = formData => {
    console.log('AXIOS ADD USER FORM DATA', formData);
    return this.axios.post(URLs.editDepartmentUser, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  fetchAllCompaniesByDepartment = (startDate, endDate, status) =>
    this.axios.get(URLs.fetchAllCompaniesByDepartment, {
      params: {
        startDate: startDate || '01-01-2021',
        endDate: endDate || '12-30-2050',
        status: status,
      },
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  approveAgencyRegistraion = (id, notes) => {
    return this.axios.post(
      URLs.approveCompany,
      {
        id: id,
        notes: notes,
      },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };
  rejectAgencyRegistraion = (id, notes) => {
    return this.axios.post(
      URLs.rejectCompany,
      {
        id: id,
        notes: notes,
      },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };

  getAllAdminPortalMetadata = () => {
    return this.axios.get(URLs.getAllAdminPortalMetadata, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  // Manage registration information
  getPersonDetailsByReferenceId = referenceId => {
    return this.axios.get(
      `${URLs.getPersonDetailsByReferenceId}/${referenceId}`,
    );
  };
  getVehicleDetailsByReferenceId = referenceId => {
    return this.axios.get(
      `${URLs.getVehicleDetailsByReferenceId}/${referenceId}`,
    );
  };
  getAgencyDetailsByReferenceId = referenceId => {
    return this.axios.get(`${URLs.companyStatus}/${referenceId}`);
  };

  verifyPersonByReferenceId = referenceId => {
    return this.axios.get(`${URLs.verifyPersonByReferenceId}/${referenceId}`);
  };
  verifyVehicleByReferenceId = referenceId => {
    return this.axios.get(`${URLs.verifyVehicleByReferenceId}/${referenceId}`);
  };
  verifyCompanyByReferenceId = referenceId => {
    return this.axios.get(`${URLs.verifyCompanyByReferenceId}/${referenceId}`);
  };
  updatePersonRegistration = formData => {
    // console.log('AXIOS FORM DATA', formData);
    return this.axios.post(URLs.updatePersonRegistration, formData, {
      headers: {
        sessionToken: `${this.preLoginToken}`,
      },
    });
  };
  updateVehicleRegistration = formData => {
    // console.log('AXIOS FORM DATA', formData);
    return this.axios.post(URLs.updateVehicleRegistration, formData, {
      headers: {
        sessionToken: `${this.preLoginToken}`,
      },
    });
  };
  updateAgencyRegistration = formData => {
    // console.log('AXIOS FORM DATA', formData);
    return this.axios.post(URLs.updateAgencyRegistration, formData, {
      headers: {
        sessionToken: `${this.preLoginToken}`,
      },
    });
  };

  updateIssuedPersonRegistration = formData => {
    // console.log('AXIOS FORM DATA', formData);
    return this.axios.post(URLs.updateIssuedPersonRegistration, formData, {
      headers: {
        sessionToken: `${this.preLoginToken}`,
      },
    });
  };
  updateIssuedVehicleRegistration = formData => {
    // console.log('AXIOS FORM DATA', formData);
    return this.axios.post(URLs.updateIssuedVehicleRegistration, formData, {
      headers: {
        sessionToken: `${this.preLoginToken}`,
      },
    });
  };

  // Operator verify registrations

  verifyRegistrationsOperators = (startDate, endDate) =>
    this.axios.get(URLs.verifyRegistrationsOperators, {
      params: {
        startDate: startDate || '01-01-2021',
        endDate: endDate || '12-30-2050',
      },
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });

  fetchPersonRejectReasons = () => {
    return this.axios.get(URLs.fetchPersonRejectReasons, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  fetchVehicleRejectReasons = () => {
    return this.axios.get(URLs.fetchVehicleRejectReasons, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  operatorReVerifyApprovePersonRegistration = formData => {
    return this.axios.post(
      URLs.operatorReVerifyApprovePersonRegistration,
      formData,
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };

  operatorApprovePersonRegistration = formData => {
    return this.axios.post(URLs.operatorApprovePersonRegistration, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  operatorReVerifyRejectPersonRegistration = formData => {
    return this.axios.post(
      URLs.operatorReVerifyRejectPersonRegistration,
      formData,
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };

  operatorRejectPersonRegistration = formData => {
    return this.axios.post(URLs.operatorRejectPersonRegistration, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  operatorApproveVehicleRegistration = formData => {
    return this.axios.post(URLs.operatorApproveVehicleRegistration, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  operatorReVerifyApproveVehicleRegistration = formData => {
    return this.axios.post(
      URLs.operatorReVerifyApproveVehicleRegistration,
      formData,
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };

  operatorReVerifyRejectVehicleRegistration = formData => {
    return this.axios.post(
      URLs.operatorReVerifyRejectVehicleRegistration,
      formData,
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };

  operatorRejectVehicleRegistration = formData => {
    return this.axios.post(URLs.operatorRejectVehicleRegistration, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  // Department validate registrations
  departmentValidateRegistrations = (
    startDate,
    endDate,
    status,
    pageNumber,
    pageSize,
    searchString,
  ) =>
    this.axios.get(URLs.departmentValidateRegistrations, {
      params: {
        startDate: startDate || '01-01-2022',
        endDate: endDate || '12-30-2100',
        status: status || undefined,
        pageNumber: pageNumber || 0,
        pageSize: pageSize || 5000,
        searchString: searchString || undefined,
      },
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });

  departmentAdminApprovePersonRegistration = formData => {
    return this.axios.post(
      URLs.departmentAdminApprovePersonRegistration,
      formData,
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };
  departmentAdminRejectPersonRegistration = formData => {
    return this.axios.post(
      URLs.departmentAdminRejectPersonRegistration,
      formData,
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };
  departmentAdminApproveVehicleRegistration = formData => {
    return this.axios.post(
      URLs.departmentAdminApproveVehicleRegistration,
      formData,
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };
  departmentAdminRejectVehicleRegistration = formData => {
    return this.axios.post(
      URLs.departmentAdminRejectVehicleRegistration,
      formData,
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };

  viewPersonHistory = personID => {
    return this.axios.get(`${URLs.viewPersonHistory}/${personID}`);
  };
  viewVehicleHistory = vehicleID => {
    return this.axios.get(`${URLs.viewVehicleHistory}/${vehicleID}`);
  };
  fetchPersonByAadhaarNumber = aadhaarNumber => {
    return this.axios.get(
      `${URLs.fetchPersonByAadhaarNumber}/${aadhaarNumber}`,
    );
  };
  fetchVehicleByVehicleRegistrationNumber = vehicleRegistrationNumber => {
    return this.axios.get(
      `${URLs.fetchVehicleByVehicleRegistrationNumber}/${vehicleRegistrationNumber}`,
    );
  };
  fetchByVehicleRegistrationNumberOrRefNoOrRfidNumberAndVisitDate = params => {
    return this.axios.get(
      URLs.fetchByVehicleRegistrationNumberOrRefNoOrRfidNumberAndVisitDate,
      {
        params: params,
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };

  fetchGroundStockData = () => {
    return this.axios.get(URLs.fetchGroundStockData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  saveGroundStock = (formData, type) => {
    return this.axios.post(`${URLs.saveGroundStock}/${type}`, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  fetchTrafficDepartmentDashboard = () => {
    return this.axios.get(URLs.trafficDepartmentDashboard, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  fetchDepartmentDashBoardWithTrends = params => {
    return this.axios.get(URLs.fetchDepartmentDashBoardWithTrends, {
      params: params,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  fetchPersonTypeData = () => {
    //console.log('fetchData', URLs);
    return this.axios.get(URLs.fetchPersonTypeTableData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  fetchGenderTypeData = () => {
    //console.log('fetchData', URLs);
    return this.axios.get(URLs.fetchGenderTypeTableData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  fetchBloodGroupTypeData = () => {
    //console.log('fetchData', URLs);
    return this.axios.get(URLs.fetchBloodGroupTableData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  fetchVehicleTypeData = () => {
    //console.log('fetchData', URLs);
    return this.axios.get(URLs.fetchVehicleTableData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  fetchVehicleCategoryTableData = () => {
    //console.log('fetchData', URLs);
    return this.axios.get(URLs.fetchVehicleCategoryTableData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  fetchRolesTypeData = () => {
    return this.axios.get(URLs.fetchRolesTableData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  fetchBusinessDetailTypeData = () => {
    return this.axios.get(URLs.fetchBusinessDetailTableData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  fetchWorkAreaTypeData = () => {
    return this.axios.get(URLs.fetchWorkAreaTableData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  fetchWorkPurposeTypeData = () => {
    return this.axios.get(URLs.fetchWorkPurposeTableData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  fetchUnitOfWeightTypeData = () => {
    return this.axios.get(URLs.fetchUnitOfWeightTableData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  fetchGateLevelRejectReasonTypeData = () => {
    return this.axios.get(URLs.fetchGateLevelRejectReasonTableData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  fetchWalletBalancesReportData = () => {
    return this.axios.get(URLs.fetchWalletBalancesReportTableData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  fetchPassAuditReportData = params => {
    return this.axios.get(URLs.fetchPassAuditReportDataTableData, {
      params: params,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  savePersonTypeData = formData => {
    return this.axios.post(URLs.savePersonType, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  saveGenderTypeData = formData => {
    return this.axios.post(URLs.saveGenderType, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  saveBloodGroupTypeData = formData => {
    return this.axios.post(URLs.saveBloodGroupType, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  saveVehicleTypeData = formData => {
    return this.axios.post(URLs.saveVehicleType, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  saveVehicleCategoryTypeData = formData => {
    return this.axios.post(URLs.saveVehicleCategoryType, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  saveRolesData = formData => {
    return this.axios.post(URLs.saveRolesType, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  saveWorkAreaData = formData => {
    return this.axios.post(URLs.saveWorkAreaType, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  saveWorkPurposeData = formData => {
    return this.axios.post(URLs.saveWorkPurposeType, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  saveBusinessDetailData = formData => {
    return this.axios.post(URLs.saveBusinessDetailType, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  saveUnitOfWeightData = formData => {
    return this.axios.post(URLs.saveUnitOfWeightType, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  saveGateLevelRejectReasonData = formData => {
    return this.axios.post(URLs.saveGateLevelRejectReasonType, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  updatesPersonTypeData = formData => {
    console.log('AXIOS - UPDATEOPERATOR', formData);
    return this.axios.post(URLs.updatePersonRowData, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  updatesGenderTypeData = formData => {
    console.log('AXIOS - UPDATEOPERATOR', formData);
    return this.axios.post(URLs.updateGenderRowData, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  updatesBloodGroupTypeData = formData => {
    console.log('AXIOS - UPDATEOPERATOR', formData);
    return this.axios.post(URLs.updateBloodGroupRowData, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  updatesVehicleTypeData = formData => {
    console.log('AXIOS - UPDATEOPERATOR', formData);
    return this.axios.post(URLs.updateVehicleRowData, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  updatesVehicleCategoryTypeData = formData => {
    console.log('AXIOS - UPDATEOPERATOR', formData);
    return this.axios.post(URLs.updateVehicleCategoryRowData, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  updatesRolesData = formData => {
    console.log('AXIOS - UPDATEOPERATOR', formData);
    return this.axios.post(URLs.updateRolesRowData, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  updatesBusinessDetailData = formData => {
    console.log('AXIOS - UPDATEOPERATOR', formData);
    return this.axios.post(URLs.updateBusinessDetailRowData, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  updatesWorkAreaData = formData => {
    console.log('AXIOS - UPDATEOPERATOR', formData);
    return this.axios.post(URLs.updateWorkAreaRowData, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  updatesWorkPurposeData = formData => {
    console.log('AXIOS - UPDATEOPERATOR', formData);
    return this.axios.post(URLs.updateWorkPurposeRowData, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  updatesGateLevelRejectReasonData = formData => {
    console.log('AXIOS - UPDATEOPERATOR', formData);
    return this.axios.post(URLs.updateGateLevelRejectReasonRowData, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  updatesGateLevelRejectReasonData = formData => {
    console.log('AXIOS - UPDATEOPERATOR', formData);
    return this.axios.post(URLs.updateGateLevelRejectReasonRowData, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  updatesUnitOfWeightData = formData => {
    console.log('AXIOS - UPDATEOPERATOR', formData);
    return this.axios.post(URLs.updateUnitOfWeightRowData, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  deletePersonType = id => {
    console.log('AXIOS - DELETEOPERATOR', id);
    return this.axios.post(
      URLs.deletePersonType,
      { id: id },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };
  deleteGateLevelRejectReason = id => {
    console.log('AXIOS - DELETEOPERATOR', id);
    return this.axios.post(
      URLs.deleteGateLevelRejectReasonType,
      { errorCode: id },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };
  deleteGenderType = id => {
    console.log('AXIOS - DELETEOPERATOR', id);
    return this.axios.post(
      URLs.deleteGenderType,
      { id: id },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };
  deleteUnitOfWeight = id => {
    console.log('AXIOS - DELETEOPERATOR', id);
    return this.axios.post(
      URLs.deleteUnitOfWeightType,
      { id: id },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };
  deleteRoles = roleId => {
    console.log('AXIOS - DELETEOPERATOR', roleId);
    return this.axios.post(
      URLs.deleteRolesType,
      { roleId: roleId.toString() },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };
  deleteWorkArea = id => {
    console.log('AXIOS - DELETEOPERATOR', id);
    return this.axios.post(
      URLs.deleteWorkAreaType,
      { id: id },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };
  deleteWorkPurpose = id => {
    console.log('AXIOS - DELETEOPERATOR', id);
    return this.axios.post(
      URLs.deleteWorkPurposeType,
      { id: id },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };
  deleteBusinessDetail = id => {
    console.log('AXIOS - DELETEOPERATOR', id);
    return this.axios.post(
      URLs.deleteBusinessDetailType,
      { id: id },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };
  deleteBloodGroupType = id => {
    console.log('AXIOS - DELETEOPERATOR', id);
    return this.axios.post(
      URLs.deleteBloodGroupType,
      { id: id },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };
  deleteVehicleType = id => {
    console.log('AXIOS - DELETEOPERATOR', id);
    return this.axios.post(
      URLs.deleteVehicleType,
      { id: id },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };
  deleteVehicleCategoryType = id => {
    console.log('AXIOS - DELETEOPERATOR', id);
    return this.axios.post(
      URLs.deleteVehicleCategoryType,
      { id: id },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };
  fetchDepartmentTypeData = () => {
    //console.log('fetchData', URLs);
    return this.axios.get(URLs.fetchAllDepartments, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  saveDepartmentTypeData = formData => {
    return this.axios.post(URLs.saveDepartment, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  deleteDepartmentType = id => {
    console.log('AXIOS - DELETEOPERATOR', id);
    return this.axios.post(
      URLs.deleteDepartment,
      { id: id },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };

  updateDepartmentTypeData = formData => {
    console.log('AXIOS - UPDATEOPERATOR', formData);
    return this.axios.post(URLs.updateDepartment, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  fetchAllCargoNatureTypeData = () => {
    //console.log('fetchData', URLs);
    return this.axios.get(URLs.fetchAllCargoNature, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  saveCargoNatureTypeData = formData => {
    return this.axios.post(URLs.saveCargoNature, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  updateCargoNatureTypeData = formData => {
    console.log('AXIOS - UPDATEOPERATOR', formData);
    return this.axios.post(URLs.updateCargoNature, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  deleteCargoNatureTypeData = id => {
    console.log('AXIOS - DELETEOPERATOR', id);
    return this.axios.post(
      URLs.deleteCargoNature,
      { id: id },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };
  fetchAllMaterialTypesData = () => {
    //console.log('fetchData', URLs);
    return this.axios.get(URLs.fetchAllMaterialType, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  saveMaterialTypeData = formData => {
    return this.axios.post(URLs.saveMaterialType, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  updateMaterialTypeData = formData => {
    console.log('AXIOS - UPDATEOPERATOR', formData);
    return this.axios.post(URLs.updateMaterialType, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  deleteMaterialTypeData = id => {
    console.log('AXIOS - DELETEOPERATOR', id);
    return this.axios.post(
      URLs.deleteMaterialType,
      { id: id },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };
  fetchPassCategoriesData = () => {
    //console.log('fetchData', URLs);
    return this.axios.get(URLs.fetchAllPassCategories, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  savePassCategoriesData = formData => {
    return this.axios.post(URLs.savePassCategories, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  updatePassCategoriesTypeData = formData => {
    console.log('AXIOS - UPDATEOPERATOR', formData);
    return this.axios.post(URLs.updatePassCategories, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  deletePassCategoriesTypeData = id => {
    console.log('AXIOS - DELETEOPERATOR', id);
    return this.axios.post(
      URLs.deletePassCategories,
      { id: id },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };
  fetchAllPacksByPassIdData = () => {
    //console.log('fetchData', URLs);
    return this.axios.get(URLs.fetchAllPacksByPassCategory, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  savePacksByPassCategoryData = formData => {
    return this.axios.post(URLs.savePacksByPassCategory, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  updatePacksByPassCategoryTypeData = formData => {
    console.log('AXIOS - UPDATEOPERATOR', formData);
    return this.axios.post(URLs.updatePacksByPassCategory, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  deletePacksByPassCategoryData = id => {
    console.log('AXIOS - DELETEOPERATOR', id);
    return this.axios.post(
      URLs.deletePacksByPassCategory,
      { id: id },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };

  fetchAllUsersData = () => {
    //console.log('fetchData', URLs);
    return this.axios.get(URLs.fetchAllUsers, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  updateUserInformationData = formData => {
    console.log('AXIOS - UPDATEOPERATOR', formData);
    return this.axios.post(URLs.updateUserInformationData, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  deleteUserByIdData = id => {
    console.log('AXIOS - DELETEOPERATOR', id);
    return this.axios.post(
      URLs.deleteUserByIdData,
      { id: id },
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };

  saveUserForMasterAdminData = formData => {
    return this.axios.post(URLs.saveUserForMasterAdmin, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  changeDriver = formData => {
    return this.axios.post(URLs.changeDriver, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  fetchTransactionDetails = formData => {
    return this.axios.get(URLs.fetchTransactionDetails, {
      params: {
        pageNumber: 0,
        pageSize: 5000,
        ...formData,
      },
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  fetchUsageDetails = formData => {
    return this.axios.get(URLs.fetchUsageDetails, {
      params: {
        ...formData,
      },
      timeout: 50000,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  fetchPersonByRFID = RFIDNumber => {
    return this.axios.get(`${URLs.fetchPersonByRFID}/${RFIDNumber}`);
  };
  fetchVehicleByRFID = RFIDNumber => {
    return this.axios.get(`${URLs.fetchVehicleByRFID}/${RFIDNumber}`);
  };

  fetchRejectionReportEntrySummary = params => {
    return this.axios.get(URLs.fetchRejectionReportEntrySummary, {
      params: params,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  fetchPersonMovementSummary = params => {
    return this.axios.get(URLs.fetchPersonMovementSummary, {
      params: params,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  fetchDailyMonitoring = params => {
    return this.axios.get(URLs.fetchDailyMonitoring, {
      params: params,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  fetchTotalCompanyPermitsVehicleRegistrationNumbersForCSV = params => {
    return this.axios.get(
      URLs.fetchAllCompanyPermitsVehicleRegistrationNumbers,
      {
        params: params,
        timeout: 50000,
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };

  fetchAllCompanyPermitsVehicleRegistrationNumbers = params => {
    return this.axios.get(
      URLs.fetchAllCompanyPermitsVehicleRegistrationNumbers,
      {
        params: params,
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      },
    );
  };

  fetchActiveCHADetails = () => {
    return this.axios.get(URLs.fetchActiveCHADetails, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  fetchActiveCompanyDetailsByDepartment = () => {
    return this.axios.get(URLs.fetchActiveCompanyDetailsByDepartment, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  personCardLost = formData => {
    return this.axios.post(URLs.personCardLost, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  vehicleCardLost = formData => {
    return this.axios.post(URLs.vehicleCardLost, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  preSignedURL = data => {
    return this.axios.post(URLs.preSignedURL, data, {});
  };

  delink = formData => {
    return this.axios.post(URLs.delink, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  changeRFIDPerson = formData => {
    return this.axios.post(URLs.changeRFIDPerson, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  changeRFIDVehicle = formData => {
    return this.axios.post(URLs.changeRFIDVehicle, formData, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  fetchAgencyManagementDetailsForAdmin = () => {
    return this.axios.get(URLs.fetchAgencyManagementDetailsForAdmin, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  reconciliationById = params => {
    return this.axios.get(URLs.reconciliationById, {
      params: params,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  reconciliationAll = () => {
    return this.axios.get(URLs.reconciliationAll, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  getServerTime = () => {
    return this.axios.get(URLs.serverTime);
  };

  getAllInvoices = formData => {
    return this.axios.get(URLs.fetchAllInvoices, {
      params: {
        ...formData,
      },
      timeout: 50000,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  fetchGatesList = () => {
    return this.axios.get(URLs.fetchActiveGates);
  };

  fetchDeputyDepartmentDashboard = () => {
    return this.axios.get(URLs.fetchDeputyDepartmentDashboard);
  };

  verifyOTPForPasswordChange = formData => {
    return this.axios.post(URLs.verifyOTPForPasswordChange, formData);
  };

  changeUserPassword = formData => {
    return this.axios.post(URLs.changeUserPassword, formData);
  };


  fetchTrafficDepartmentDashboardV2 = params => {
    return this.axios.get(URLs.fetchTrafficDepartmentDashboardV2, {
      params: params,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  // DEPT DASHBOARD API'S.

  fetchTrafficDepartmentDashBoardForGraphs = params => {
    return this.axios.get(URLs.fetchTrafficDepartmentDashBoardForGraphs, {
      params: params,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  fetchTrafficDepartmentDashBoardForTodayData = params => {
    return this.axios.get(URLs.fetchTrafficDepartmentDashBoardForTodayData, {
      params: params,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  // Person dashboard API.

  trafficDepartmentDashBoardForPedestrian = params => {
    return this.axios.get(URLs.trafficDepartmentDashBoardForPedestrian, {
      params: params,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };


  savePoliceDoc = formData => {
    // console.log('AXIOS FORM DATA', formData);
    return this.axios.post(URLs.savePoliceDoc, formData);
  };

  verifyPersonByRFIDNumber = RFIDNumber => {
    // console.log('AXIOS FORM DATA', formData);
    return this.axios.get(`${URLs.verifyPersonByRFIDNumber}/${RFIDNumber}`);
  };
  fetchOperatorDashboard = () => {
    // console.log('AXIOS FORM DATA', formData);
    return this.axios.get(URLs.fetchOperatorDashboard);
  };

  fetchPersonActivityDetails = params => {
    return this.axios.get(URLs.personGateLevelHistory, {
      params: params,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  fetchPersonLatestActivity = params => {
    return this.axios.get(URLs.personGateLevelLatestActivity, {
      params: params,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  fetchVehicleActivityDetailsList = params => {
    return this.axios.get(URLs.vehicleGateLevelHistory, {
      params: params,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };
  fetchVehicleLatestActivity = params => {
    return this.axios.get(URLs.vehicleGateLevelLatestActivity, {
      params: params,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  surrenderPersonPassSelf = formData => {
    return this.axios.post(URLs.surrenderPersonPassSelf, formData);
  };
  surrenderVehiclePassSelf = formData => {
    return this.axios.post(URLs.surrenderVehiclePassSelf, formData);
  };

  lostPersonCard = formData => {
    return this.axios.post(URLs.lostPersonCard, formData);
  };
  lostVehicleTag = formData => {
    return this.axios.post(URLs.lostVehicleTag, formData);
  };

  blackListPersonCard = formData => {
    return this.axios.post(URLs.blackListPersonCard, formData);
  };
  blackListVehicleTag = formData => {
    return this.axios.post(URLs.blackListVehicleTag, formData);
  };

  unBlackListPersonCard = formData => {
    return this.axios.post(URLs.unBlackListPersonCard, formData);
  };
  unBlackListVehicleTag = formData => {
    return this.axios.post(URLs.unBlackListVehicleTag, formData);
  };

  fetchVehicleMovementDetails = params => {
    return this.axios.get(URLs.fetchVehicleMovementDetails, {
      params: params,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  fetchPersonMovementDetails = params => {
    return this.axios.get(URLs.fetchPersonMovementDetails, {
      params: params,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  // FETCH AGENCY DETAILS/BLACKLIST/UNBLACKLIST
  fetchCompanyDetailsForDepartmentByAgencyId = referenceId => {
    return this.axios.get(URLs.fetchCompanyDetailsForDepartment, {
      params: {
        referenceId: referenceId,
      },
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  fetchAllCompaniesForDepartment = () => {
    return this.axios.get(`${URLs.fetchAllCompaniesForDepartment}`);
  };

  blackListAgency = formData => {
    return this.axios.post(URLs.blackListAgency, formData);
  };

  unBlackListAgency = formData => {
    return this.axios.post(URLs.unBlackListAgency, formData);
  };
  contact = formData => {
    // console.log('AXIOS FORM DATA', formData);
    return this.axios.post(URLs.contact, formData);
  };

  getPaymentProperties = () => {
    return this.axios.get(`${URLs.getPaymentProperties}`);
  }

}

export const API = new Requests();
