import styles from './styles.module.scss';
import {operatorImages} from '../../assets/images';
const DeletePopup = ({onClick, onClose}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p>Delete Users</p>
      </div>
      <div className={styles.body}>
        <img src={operatorImages.deleteIcon} alt="u" />
        <p>Are you sure want to delete</p>
      </div>
      <div className={styles.footer}>
        <button className={styles.cancel} onClick={onClose}>
          Cancel
        </button>
        <button className={styles.delete} onClick={onClick}>
          Delete
        </button>
      </div>
    </div>
  );
};
export default DeletePopup;

// className={styles.container}
{
  /* <div style={{height: '300px', width: '400px', backgroundColor: 'red'}}>
      Hello world
    </div> */
}
