import React, {Component} from 'react';
import ViewRenderer from './views';
import {API} from '../../../requests';
import './styles.css';
import PopupContainer from '../../../components/PopupContainer';
import POPUP_CONSTANTS from '../../../constants/popup-constants';
import Spinner from '../../../components/Spinner';
import Popup from '../../../components/SimplePopup';
import strings from '../../../globalization';
import util from '../../../util';
import {getFormData} from './data';
import PopupData from '../../../components/PopupPerson/popup';
import {operatorImages} from '../../../assets/images';
import {editFormData} from './editData';
import DeletePopup from '../../../components/DeletePopup';

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: undefined,

      searchText: '',
      userList: [],
      filterUserList: [],
      selectedStatus: '',
      singleUser: {},
      header: '',
    };
  }
  statuses = [
    {
      id: '',
      name: 'ALL',
    },
    {
      id: 'inactive',
      name: 'INACTIVE',
    },
    {
      id: 'active',
      name: 'ACTIVE',
    },
    {
      id: 'pending',
      name: 'PENDING',
    },
  ];
  updatedStatus = this.statuses.filter(elem => elem.id !== '');
  onAddHandler = data => {
    console.log('add-data', data);
    const userListData = {
      fullName: data.fullName.value,
      roleId: data.roleId.value,
      designation: data.designation.value,
      emailAddress: data.emailAddress.value,
      phoneNumber: data.phoneNumber.value,
    };
    console.log('new-user', userListData);
    this.saveUserData(userListData);
    this.setState({
      popup: null,
    });
  };
  showErrorPopup = errorMsg => {
    // crashlytics().recordError(new Error(errorMsg));

    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMsg,
        messageStyle: {color: '#E67717'},

        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.onClose,
          },
        ],
        onClose: this.closePopup,
      },
    });
  };
  fetchAllRolesData = (action, data) => {
    API.fetchAllRoles()
      .then(res => {
        console.log('resdata', res.data);
        const newData = res.data.map(elem => {
          return {id: elem.roleId, name: elem.name};
        });
        let newResponse = newData.filter(elem => elem.status !== 'INACTIVE');
        if (action === 'add') {
          this.setState({
            popup: {
              type: POPUP_CONSTANTS.ADD_DATA_POPUP,
              activeRoles: newResponse,
            },
          });
        } else if (action === 'edit') {
          this.setState({
            popup: {
              type: POPUP_CONSTANTS.EDIT_POPUP,
              statuses: this.updatedStatus,
              activeRoles: newResponse,
              categoryTypeData: data,
            },
          });
        }
      })
      .catch(err => {
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };
  showSuccessPopup = (successMessage, onClick) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: successMessage,
        onClose: this.onClose,
        headingImage: operatorImages.successIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: onClick ? onClick : this.onClose,
            outline: true,
          },
        ],
      },
    });
  };

  onClickHandler = (action, data) => {
    console.log('row-data', data);
    switch (action) {
      case 'edit': {
        this.fetchAllRolesData(action, data);

        break;
      }
      case 'delete': {
        data.status === 'ACTIVE' &&
          this.setState({
            singleUser: data,
            popup: {type: POPUP_CONSTANTS.DELETE_POPUP},
          });

        break;
      }
      default: {
        console.log('nahi match huya');
        break;
      }
    }
  };
  onClose = () => {
    this.setState({
      popup: null,
    });
  };
  tableActionHandler = (action, data) => {
    switch (action) {
      case 'status': {
        this.setState(
          {
            selectedStatus: data,
          },
          this.applyFilters,
        );
        break;
      }
      case 'shift': {
        break;
      }
      case 'add': {
        this.fetchAllRolesData(action, data);
        break;
      }
      case 'search': {
        this.setState(
          {
            searchText: data,
          },
          this.applyFilters,
        );
        break;
      }
      case 'clearFilter': {
        this.setState({
          searchText: '',
          selectedStatus: '',
        });
        this.fetchAllUsersData();
        break;
      }
      default: {
        console.info('filterChangeHandler case not handled', action);
      }
    }
  };
  applyFilters = () => {
    const {searchText, selectedStatus, userList} = this.state;
    console.log('selected-status', selectedStatus);
    const filteredData =
      userList.length > 0 &&
      userList.filter(row => {
        if (
          searchText &&
          !Object.values(row)
            .toString()
            .toLowerCase()
            .includes(searchText.toLowerCase())
        ) {
          return false;
        }

        if (
          selectedStatus &&
          row.status?.toLowerCase() !== selectedStatus.toLowerCase()
        ) {
          return false;
        }
        return true;
      });
    this.setState({
      filterUserList: filteredData,
    });
  };
  fetchAllUsersData = () => {
    this.setState({popup: {type: POPUP_CONSTANTS.SPINNER_POPUP}});
    API.fetchAllUsersData()
      .then(res => {
        console.log('res', res.data);
        this.setState(
          {
            ...this.state,
            userList: res.data,
            filterUserList: res.data,
            popup: null,
          },
          this.applyFilters,
        );
      })
      .catch(err => {
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };
  saveUserData = userInfo => {
    let formData = {
      ...userInfo,
      status: userInfo.status,
    };
    this.setState({popup: {type: POPUP_CONSTANTS.SPINNER_POPUP}});
    API.saveUserForMasterAdminData(formData)
      .then(res => {
        this.showSuccessPopup(
          'New User Added Successfully',
          this.fetchAllUsersData,
        );
      })
      .catch(error => {
        const err = util.getErrorMessage(error);
        this.showErrorPopup(err);
      });
  };
  onDeleteHandler = () => {
    console.log('single-user', this.state.singleUser);
    let id = this.state.singleUser.id;
    this.deleteUserByIdDataType(id);
    this.setState({popup: null});
  };

  deleteUserByIdDataType = id => {
    this.setState({popup: {type: POPUP_CONSTANTS.SPINNER_POPUP}});
    API.deleteUserByIdData(id)
      .then(res => {
        this.showSuccessPopup(
          'User Deleted Successfully',
          this.fetchAllUsersData,
        );
      })
      .catch(error => {
        const err = util.getErrorMessage(error);
        this.showErrorPopup(err);
      });
  };
  updateUserInformationDataType = data => {
    this.setState({popup: {type: POPUP_CONSTANTS.SPINNER_POPUP}});
    API.updateUserInformationData(data)
      .then(res => {
        this.showSuccessPopup(
          'User Information Updated Successfully',
          this.fetchAllUsersData,
        );
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        this.showErrorPopup(errorMsg);
      });
  };

  onEditHandler = data => {
    console.log('edit-data', data);
    let myData = {
      userId: data.id.value,
      fullName: data.fullName.value,
      status: data.status.value.toUpperCase(),
      roleId: data.roleId.value,
      designation: data.designation.value,
    };
    console.log('myData', myData);
    this.updateUserInformationDataType(myData);
    //FIXME
    this.setState({popup: null});
  };

  getProps = () => {
    const {searchText, filterUserList, selectedStatus} = this.state;
    return {
      onClick: this.onClickHandler,
      onChange: this.onChangeHandler,
      filterChangeHandler: this.tableActionHandler,
      userList: filterUserList,
      statuses: this.statuses,
      searchText,
      selectedStatus,
    };
  };
  //FIXME  add logic here
  // checkDisabled = elem => {
  //   const {header} = this.state.popup;
  //   console.log('header', header);
  //   if (
  //     header === 'EDIt' &&
  //     (elem.stateField === 'phoneNumber' || elem.stateField === 'emailAddress')
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  getPopupContent = () => {
    const {popup} = this.state;
    switch (popup.type) {
      case POPUP_CONSTANTS.ADD_DATA_POPUP: {
        return (
          <PopupData
            header="ADD"
            onSubmit={this.onAddHandler}
            onClose={this.onClose}
            getFormData={getFormData}
            {...popup}
          />
        );
      }
      case POPUP_CONSTANTS.EDIT_POPUP: {
        return (
          <PopupData
            header="EDIT"
            onSubmit={this.onEditHandler}
            onClose={this.onClose}
            checkDisabled={this.checkDisabled}
            getFormData={editFormData}
            {...popup}
          />
        );
      }
      case POPUP_CONSTANTS.DELETE_POPUP: {
        return (
          <DeletePopup onClick={this.onDeleteHandler} onClose={this.onClose} />
        );
      }
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };
  componentDidMount() {
    this.fetchAllUsersData();
  }

  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

export default Users;
