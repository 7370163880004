import React, {useState, useEffect} from 'react';
import styles from './Desktop.module.scss';
import './styles.css';
import strings from '../../../../../globalization';
import {withRouter} from 'react-router-dom';
import {AgGridReact} from 'ag-grid-react';
import Select from 'react-select';
import moment from 'moment';
import 'react-daterange-picker/dist/css/react-calendar.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import dimensions from '../../../../../util/dimensions-util';
import {customStyles} from './customStyles';
import {adminImages} from '../../../../../assets/images';
import DateRangePicker from 'react-daterange-picker';
import TextInput from '../../../../../components/TextInput';
import Button from '../../../../../components/Button';

import calendarBlue from '../../../../../assets/images/calendar-blue-icon/image.png';
import CustomTimePicker from '../../../../../components/CustomTimePicker';
import TimePicker from 'rc-time-picker';
import Flex from '../../../../../components/Flex';
import {CSVLink} from 'react-csv';

const {vw: widthConstant, vh: heightConstant} = dimensions;

const entrySummaryColumnDef = [
  {
    headerName: 'Vehicle Number',
    field: 'vehicleNumber',
    headerClass: styles.rowHeader,
    width: 140 * widthConstant,
    resizable: true,
    flex: 1,
    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: params => {
      const {data} = params;
      if (params?.data !== undefined) {
        console.log('params:', params);
        const {vehicleRegistrationNumber} = params?.data;
        return (
          <div className={styles.sessionTime}>{vehicleRegistrationNumber}</div>
        );
      } else {
        return null;
      }
    },
  },
  {
    headerName: 'CHA Name',
    field: 'CHAName',
    headerClass: styles.rowHeader,
    width: 200 * widthConstant,
    resizable: true,
    flex: 1,
    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: params => {
      if (params?.data !== undefined) {
        const {companyName} = params?.data;
        return <div className={styles.sessionTime}>{companyName}</div>;
      } else {
        return null;
      }
    },
  },
  {
    headerName: 'Timestamp',
    field: 'timestamp',
    headerClass: styles.rowHeader,
    width: 390 * widthConstant,
    flex: 1,
    resizable: true,
    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: params => {
      if (params?.data !== undefined) {
        const {createdDate} = params?.data;
        return <div>{moment(createdDate).format('MMMM Do YY HH:mm')}</div>;
      } else {
        return null;
      }
    },
  },
];

const Desktop = props => {
  const {
    onClick,
    onChange,
    searchText,
    dates,
    rejectionList,
    startTime,
    endTime,
    selectedStatus,
    statuses,
    CHAList,
    selectedCHA,
    downloadData,
    downloadHeaders,
    totalCount,
  } = props;
  const [showDate, setShowDate] = useState(false);

  const [gridApi, setGridApi] = useState(null);
  const onGridReady = params => {
    setGridApi(params.api);
    onChange('gridAPI', params.api);
  };
  const updateTable = () => {
    onChange('updateTable', null);
  };
  useEffect(() => {
    console.log(gridApi);
    updateTable();
  }, [gridApi, selectedStatus]);

  const onCellClick = (event, index) => {
    onClick('rowClick', event.data);
  };
  const onSelect = dates => {
    onChange('date', {
      startDate: moment(dates.start).format('MM-DD-YYYY'),
      endDate: moment(dates.end).format('MM-DD-YYYY'),
      dates,
    });
    toggleDate();
  };
  const toggleDate = () => {
    setShowDate(!showDate);
  };

  const formatDate = date => moment(date).format('MMM Do YY');
  const rowStyle = {border: 'none', cursor: 'pointer'};
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return {background: '#f6f6f6', border: 'none'};
    }
  };
  const handleClear = () => {
    setShowDate(false);
    onClick('clearFilters');
  };

  return (
    <div className={styles.agencyContainer}>
      <div className={styles.headerText}>{`Permits Raised Report`}</div>
      <div className={styles.header}>
        <div className={styles.left}>
          <Flex direction="column" className={styles.leftTop}>
            <Flex direction="row">
              <div className={styles.dateWrapper}>
                <div onClick={toggleDate} className={styles.inputDate}>
                  {' '}
                  <div>
                    {' '}
                    {dates
                      ? formatDate(dates.start) + '-' + formatDate(dates.end)
                      : strings.ADMIN.dateRange}
                  </div>{' '}
                  <img src={calendarBlue} className={styles.calendar} alt="C" />
                </div>
                {showDate ? (
                  <div className={styles.datePicker}>
                    <DateRangePicker
                      numberOfCalendars={1}
                      selectionType="range"
                      onSelect={onSelect}
                      className={styles.dateRange}
                      value={dates}
                      singleDateRange={true}
                    />
                  </div>
                ) : null}
              </div>
              <div>
                <TimePicker
                  // use12Hours
                  showSecond={false}
                  // FIXME: Change these
                  value={startTime}
                  onChange={time => onChange('startTime', time)}
                  className={styles.timepicker}
                  placeholder={`Start Time`}
                  format={'HH:mm'}
                />
                <TimePicker
                  // use12Hours
                  showSecond={false}
                  // FIXME: Change these
                  value={endTime}
                  onChange={time => onChange('endTime', time)}
                  className={styles.timepicker}
                  placeholder={`End Time`}
                  format={'HH:mm'}
                />
              </div>
            </Flex>
          </Flex>
        </div>

        <div className={styles.right}>
          {/* <div className={styles.inputSearch}>
            <TextInput
              onChange={text => {
                onChange('search', text);
              }}
              isEdit={true}
              type={'text'}
              value={searchText}
              inputStyle={styles.inputText}
              showSeacrh={true}
              placeholder="Search"
            />
          </div> */}
          <Select
            options={CHAList}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            placeholder={`Select Agency`}
            styles={customStyles}
            value={CHAList.filter(option => option?.id === selectedCHA?.id)}
            onChange={data => {
              onChange('CHA', data);
            }}
          />
          <div onClick={handleClear} className={styles.clearLabel}>
            {' '}
            {strings.ADMIN.clear}
          </div>
          {rejectionList && rejectionList.length > 0 ? (
            <CSVLink
              className={styles.downloadBtn}
              data={downloadData}
              filename={`person-movement-edrishti-${moment().format(
                `DD-MM-YYYY`,
              )}.csv`}
              headers={downloadHeaders}>
              Download
            </CSVLink>
          ) : null}
          <Button
            onClick={() => onClick('apply')}
            buttonStyle="primary"
            label={strings.ADMIN.apply}
            className={styles.applyBtn}></Button>
        </div>
      </div>
      <div className={['ag-theme-balham', styles.tableWrapper].join(' ')}>
        <AgGridReact
          rowStyle={rowStyle}
          getRowStyle={getRowStyle}
          rowHoverHighlight={true}
          columnDefs={entrySummaryColumnDef}
          rowHeight={50 * heightConstant}
          headerHeight={50 * heightConstant}
          defaultColDef={{sortable: true}}
          rowClass={[styles.gridRowClass]}
          pagination={true}
          paginationPageSize={10}
          onGridReady={onGridReady}
          rowModelType={'infinite'}
          domLayout="autoHeight"
          animateRows={true}
          onCellClicked={(event, index) => onCellClick(event, index)}
          rowData={rejectionList ? rejectionList : []}></AgGridReact>
      </div>
      <div className={styles.bottomLabel}> Total Count: {totalCount}</div>
    </div>
  );
};
export default withRouter(Desktop);
