import React from 'react';
import styles from './Desktop.module.scss';
import {withRouter} from 'react-router-dom';
import RegistrationFormCard from '../../../../../components/RegistrationFormCard';
import {loginImages} from '../../../../../assets/images';
import RegistrationLeftContainer from '../../../../../components/RegistrationLeftContainer';
import strings from '../../../../../globalization';

const Desktop = props => {
  const {onClick, location} = props;
  return (
    <div className={styles.container}>
      {/* <RegistrationLeftContainer /> */}
      <RegistrationFormCard
        onClick={onClick}
        terms={true}
        buttonLabel="Go back to Login"
        terms={true}
        header={location.state?.message}>
        <div className={styles.body}>
          <div className={styles.title}>{location?.state?.title || ''}</div>
          <img
            className={styles.successIcon}
            src={loginImages.successIcon}
            alt="Logo"
          />
          <div className={styles.message1}>{strings.successfullySubmitted}</div>

          <div className={styles.message2}>
            {' '}
            {strings.yourReferenceNumberIs} <br />
            <span className={styles.id}>{location.state?.id} </span>,{' '}
            {strings.pleaseKeepNoteOfIt}.
          </div>
          {location?.state?.status === 'UPDATED' ? null : (
            <div className={styles.message3}>
              {strings.youWillReceiveMessage} <br />
              {strings.youCanCheckTheStatus}{' '}
              {/* <span
              className={styles.here}
              onClick={() => {
                props.history.push('/check-application-status');
              }}>
              {' '}
              {strings.here}
            </span> */}
            </div>
          )}
        </div>
      </RegistrationFormCard>
    </div>
  );
};
export default withRouter(Desktop);
