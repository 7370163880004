import moment from 'moment';
import React, {PureComponent} from 'react';
import LineGraph from '../../components/LineGraph';
import PopupContainer from '../../components/PopupContainer';
import Spinner from '../../components/Spinner';
import Popup from '../../components/SimplePopup';

import './styles.css';
import styles from './styles.module.scss';
import ViewRenderer from './views';
import {API} from '../../requests';

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  SPINNER_POPUP: 'spinner',
  ERROR: 'error',
  SIMPLE_POPUP: 'simple-popup',
  GRAPH_POPUP: 'graph-popup',
};

class Playground extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      statuses: [
        {
          id: '1',
          name: 'today',
          displayName: 'Today',
        },
        {
          id: '2',
          name: 'week',
          displayName: 'Last 7 days',
        },
        {
          id: '3',
          name: 'month',
          displayName: 'Month to Date',
        },
        {
          id: '4',
          name: 'custom',
          displayName: 'Custom...',
        },
      ],
      selectedDropDownStatus: {
        id: '2',
        name: 'week',
        displayName: 'Last 7 days',
      },
      startDate: moment().subtract(1, 'week').format('MM-DD-YYYY'),
      endDate: moment().format('MM-DD-YYYY'),
      dates: {
        start: moment().subtract(1, 'week').format('MM-DD-YYYY'),
        end: moment().format('MM-DD-YYYY'),
      },
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const {startDate, endDate} = this.state;
    let params = {
      startDate,
      endDate,
    };

    this.showSpinner();
    API.fetchTrafficDepartmentDashboard(params)
      .then(res => {
        console.log('res.data', res.data);
        this.setState({popup: null});
      })
      .catch(err => {
        console.log(err);
      });
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  onClickHandler = (action, data) => {
    const {history} = this.props;
    switch (action) {
      case 'permits': {
        //FIXME
        history.push('/u/permits');
        break;
      }
      case 'profile': {
        //FIXME
        history.push('/u/profile');
        break;
      }
      case 'date': {
        const {startDate, endDate, dates} = data;
        console.log(data);
        this.setState({
          startDate,
          endDate,
          dates,
        });
        // FIXME: Call API here
        // setTimeout(() => {
        //   this.fetchData();
        // }, 0);
        break;
      }
      case 'dateChange': {
        if (data) {
          this.setState({
            selectedDropDownStatus: data,
          });
        }
        this.setDate(data?.name);
        // FIXME: Call API here

        // setTimeout(() => {
        //   this.fetchData();
        // }, 0);
        break;
      }

      case 'graph-popup': {
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.GRAPH_POPUP,
          },
        });
        break;
      }
      case 'handle-metrics-filters': {
        this.handleMetricsFilters(data);
        break;
      }
      case 'handle-gate-filters': {
        this.handleGateFilters(data);
        break;
      }

      default: {
        break;
      }
    }
  };

  handleMetricsFilters = data => {};
  handleGateFilters = data => {};

  setDate = timePeriod => {
    switch (timePeriod) {
      case 'today': {
        this.setState({
          startDate: moment().format('MM-DD-YYYY'),
          endDate: moment().format('MM-DD-YYYY'),
          dates: {
            start: moment().format('MM-DD-YYYY'),
            end: moment().format('MM-DD-YYYY'),
          },
        });
        break;
      }
      case 'week': {
        this.setState({
          startDate: moment().subtract(7, 'days').format('MM-DD-YYYY'),
          endDate: moment().format('MM-DD-YYYY'),
          dates: {
            start: moment().subtract(7, 'days').format('MM-DD-YYYY'),
            end: moment().format('MM-DD-YYYY'),
          },
        });
        break;
      }
      case 'month': {
        this.setState({
          startDate: moment().startOf('month').format('MM-DD-YYYY'),
          endDate: moment().format('MM-DD-YYYY'),
          dates: {
            start: moment().startOf('month').format('MM-DD-YYYY'),
            end: moment().format('MM-DD-YYYY'),
          },
        });
        break;
      }
      default: {
        console.log('case not handled in setDate method');
      }
    }
  };
  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.GRAPH_POPUP: {
        return (
          <div className={styles.graphPopup} onClick={this.closePopup}>
            <LineGraph responsive {...popup} />
          </div>
        );
      }
      default: {
        console.warn('case not handled', popup.type);
        return null;
      }
    }
  };

  closePopup = () => {
    this.setState({
      popup: null,
    });
  };

  getProps = () => {
    const {statuses, selectedDropDownStatus, startDate, endDate, dates} =
      this.state;

    return {
      statuses,
      selectedDropDownStatus,
      onClick: this.onClickHandler,
      startDate,
      endDate,
      dates,
    };
  };

  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer containerStyle={styles.popupContainerStyle}>
            {this.getPopupContent()}
          </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

export default Playground;
