import React, {useState, useEffect} from 'react';
import styles from './styles.module.scss';
import './styles.css';
import strings from '../../../../globalization';
import {withRouter} from 'react-router-dom';
import {AgGridReact} from 'ag-grid-react';
import Select from 'react-select';
import moment from 'moment';

import 'react-daterange-picker/dist/css/react-calendar.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import dimensions from '../../../../util/dimensions-util';
import {customStyles} from './customStyles';
import DateRangePicker from 'react-daterange-picker';

import TextInput from '../../../../components/TextInput';
import Button from '../../../../components/Button';
import calendarBlue from '../../../../assets/images/calendar-blue-icon/image.png';

import TableTimestamp from '../../../../components/TableTimestamp';
import SannaLogo from '../../../../components/SannaLogo';
const {vw: widthConstant, vh: heightConstant} = dimensions;

const Desktop = props => {
  const {
    onClick,
    onChange,
    selectedStatus,
    searchText,
    dates,
    timestamp,
    statuses,
    selectedRequestType,
    totalCount,
  } = props;

  const columnDefs = [
    {
      headerName: 'Date',
      field: 'date',
      headerClass: styles.rowHeader,
      minWidth: 150,
      flex: 1,
      resizable: true,
      cellStyle: {display: 'flex', alignItems: 'center'},
      cellRendererFramework: params => {
        console.log('params:', params);
        if (params?.data !== undefined) {
          const {date} = params?.data;
          return (
            <div className={styles.sessionTime}>
              {moment(date).format('DD/MM/YYYY HH:mm')}
            </div>
          );
        } else {
          return null;
        }
      },
    },
    {
      headerName: 'Transaction ID',
      field: 'transactionId',
      headerClass: styles.rowHeader,
      // width: 200 * widthConstant,
      flex: 1,
      resizable: true,
      wrapText: true,
      autoHeight: true,
      minWidth: 150,
      cellStyle: {display: 'flex', alignItems: 'center'},
      cellRendererFramework: params => {
        if (params?.data !== undefined) {
          const transactionId = params?.data?.transactionId;
          return (
            <div className={styles.sessionTime}>
              {transactionId ? transactionId : '-'}
            </div>
          );
        } else {
          return null;
        }
      },
    },
    {
      headerName: 'Registration Type',
      field: 'registrationType',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      minWidth: 200,
      wrapText: true,
      autoHeight: true,
      cellStyle: {display: 'flex', alignItems: 'center'},
      cellRendererFramework: params => {
        if (params?.data !== undefined) {
          const registrationType = params?.data?.registrationType;
          return (
            <div className={styles.sessionTime}>
              {registrationType ? registrationType : '-'}
            </div>
          );
        } else {
          return null;
        }
      },
    },
    {
      headerName: 'Download',
      field: 'download',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      wrapText: true,
      autoHeight: true,
      minWidth: 200,
      cellStyle: {lineHeight: 1.5},
      cellRendererFramework: params => {
        if (params?.data !== undefined) {
          const invoiceData = params?.data;
          return (
            <div className={styles.sessionTime}>
              <button
                onClick={() => onClick('downLoadInvoice', invoiceData)}
                className={styles.invoiceButton}>
                Download
              </button>
            </div>
          );
        } else {
          return null;
        }
      },
    },
  ];
  const [showDate, setShowDate] = useState(false);
  const [gridApi, setGridApi] = useState(null);

  console.log('total Count', totalCount);
  const updateTable = () => {
    onChange('updateTable', null);
  };
  useEffect(() => {
    console.log(gridApi);
    updateTable();
  }, [gridApi, selectedStatus]);

  useEffect(() => {
    console.log(gridApi);
    if (gridApi && totalCount > 10) {
      console.log('changing to normal');
      gridApi.setDomLayout('normal');
    } else if (gridApi) {
      console.log('changing to autoHeight');
      gridApi.setDomLayout('autoHeight');
    }
  }, [totalCount]);

  const onGridReady = params => {
    setGridApi(params.api);
    onChange('gridAPI', params.api);
  };
  const onSelect = dates => {
    onChange('date', {
      startDate: moment(dates.start).format('MM-DD-YYYY'),
      endDate: moment(dates.end).format('MM-DD-YYYY'),
      dates,
    });
    toggleDate();
  };
  const toggleDate = () => {
    setShowDate(!showDate);
  };

  const formatDate = date => moment(date).format('MMM Do YY');
  const rowStyle = {border: 'none', cursor: 'pointer'};
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return {background: '#f6f6f6', border: 'none'};
    }
  };

  const handleClear = () => {
    setShowDate(false);
    onClick('clearFilters');
  };
  const handleSelect = name => {
    setShowDate(false);
    onChange('statusChange', name);
  };

  return (
    <div>
      <div className={styles.bodyContainer}>
        <div className={styles.headerText}>Download Invoices</div>

        <div className={styles.header}>
          <div className={styles.left}>
            <div className={styles.dateWrapper}>
              <div onClick={toggleDate} className={styles.inputDate}>
                {' '}
                <div>
                  {' '}
                  {dates
                    ? formatDate(dates.start) + '-' + formatDate(dates.end)
                    : strings.ADMIN.dateRange}
                </div>{' '}
                <img src={calendarBlue} className={styles.calendar} alt="C" />
              </div>
              {showDate ? (
                <div className={styles.datePicker}>
                  <DateRangePicker
                    numberOfCalendars={1}
                    selectionType="range"
                    onSelect={onSelect}
                    className={styles.dateRange}
                    value={dates}
                    singleDateRange={true}
                  />
                </div>
              ) : null}
            </div>
            <div className={styles.agencyNameDropdown}>
              <Select
                options={statuses}
                getOptionLabel={option => option.displayName}
                getOptionValue={option => option.id}
                placeholder={'Select status'}
                styles={customStyles}
                value={statuses.filter(
                  option => option?.id === selectedRequestType?.id,
                )}
                onChange={handleSelect}
              />
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.inputSearch}>
              <TextInput
                onChange={text => {
                  onChange('search', text);
                }}
                isEdit={true}
                type={'text'}
                value={searchText}
                inputStyle={styles.inputText}
                showSeacrh={true}
                placeholder={`Search Transaction ID`}
              />
            </div>
            <div onClick={handleClear} className={styles.clearLabel}>
              {' '}
              {strings.ADMIN.clear}
            </div>
            {/* {invoices && invoices?.length > 0 ? (
              <Button
                onClick={() => onClick('loadCSV')}
                buttonStyle="primary"
                label={'Download'}
                className={styles.downloadBtn}></Button>
            ) : null} */}
            <Button
              onClick={() => {
                setShowDate(false);
                onClick('apply');
              }}
              buttonStyle="primary"
              label={strings.ADMIN.apply}
              className={styles.applyBtn}></Button>
          </div>
        </div>
        <TableTimestamp timestamp={timestamp}>
          <div
            className={[
              'ag-theme-balham',
              styles.tableWrapper,
              totalCount > 10
                ? styles.tableWrapperNormal
                : styles.tableWrapperAutoHeight,
            ].join(' ')}>
            <AgGridReact
              onGridReady={onGridReady}
              rowStyle={rowStyle}
              getRowStyle={getRowStyle}
              rowHoverHighlight={true}
              columnDefs={columnDefs}
              rowHeight={55 * heightConstant}
              headerHeight={40}
              defaultColDef={{sortable: true}}
              rowClass={[styles.gridRowClass]}
              pagination={true}
              paginationPageSize={100}
              rowModelType={'infinite'}
              domLayout="autoHeight"></AgGridReact>
          </div>
        </TableTimestamp>
        {/* <div className={styles.bottomLabel}>
          {' '}
          Showing {filterAgencies.length}
        </div> */}
      </div>
      <div className={styles.SannaLogo}>
        <SannaLogo />
      </div>
    </div>
  );
};

export default Desktop;
