import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import POPUP_CONSTANTS from '../../../../constants/popup-constants';
import { API } from '../../../../requests';
import util from '../../../../util';

import styles from './styles.module.scss';

import Spinner from 'react-spinkit';
import Popup from '../../../../components/SimplePopup';

import ViewRenderer from './views';
import PopupContainer from '../../../../components/PopupContainer';
import { commonImages } from '../../../../assets/images';
import strings from '../../../../globalization';
import { connect } from 'react-redux';
import { setPersonsData } from '../../../../store/actions/permits';
import ViewPerson from '../../../../components/Permits/ViewPerson';
import ViewDocument from '../../../../components/ViewDocument';

const FILTER_OPTIONS = {
  REFERENCE_NUMBER: 'REFERENCE_NUMBER',
  AADHAAR_NUMBER: 'AADHAAR_NUMBER',
  RFID_CARD_NUMBER: 'RFID_CARD_NUMBER',
};

class AddPerson extends Component {
  constructor(props) {
    super(props);
    console.log(this.props.location);
    const persons = this.props.location.state?.persons;
    const personId =
      this.props.location.state.status === 'view'
        ? this.props.location.state?.id
        : 0;
    this.state = {
      searchText: '',
      person:
        this.props.location.state.status === 'view'
          ? persons?.filter(function (pa) {
            return pa.id === personId;
          })[0]
          : null,
      vehicle: null,
      personsArray: [],
      filter: null,
      filterOptions: [
        {
          id: FILTER_OPTIONS.REFERENCE_NUMBER,
          name: 'Reference Number',
        },
        {
          id: FILTER_OPTIONS.AADHAAR_NUMBER,
          name: 'Aadhaar Number',
        },
        {
          id: FILTER_OPTIONS.RFID_CARD_NUMBER,
          name: 'RFID Card Number',
        },
      ],
      noOfDays: this.props.location.state?.noOfDays,
      visitingDate: this.props.location.state?.visitingDate,
      companyId: this.props.location.state?.companyId,
    };
  }
  goBack = () => {
    console.log('goBack Called!!-->');
    const { history } = this.props;
    history.goBack();
  };
  getDocumentTypeName = documentId => {
    switch (documentId) {
      case 1: {
        return 'Aadhaar';
      }
      case 2: {
        return 'Driving License';
      }
      case 3: {
        return 'PAN';
      }
      case 4: {
        return 'Passport';
      }
      case 5: {
        return 'Voter ID';
      }
      default: {
        return '';
      }
    }
  };
  getDocumentProofNumber = documentId => {
    switch (documentId) {
      case 1: {
        return 'aadhaarNumber';
      }
      case 2: {
        return 'drivingLicenceNumber';
      }
      case 3: {
        return 'panNumber';
      }
      case 4: {
        return 'passportNumber';
      }
      case 5: {
        return 'voterIdNumber';
      }
      default: {
        return '';
      }
    }
  };
  getDocumentProofUrl = documentId => {
    switch (documentId) {
      case 1: {
        return 'aadhaarUrl';
      }
      case 2: {
        return 'drivingLicenceUrl';
      }
      case 3: {
        return 'panUrl';
      }
      case 4: {
        return 'passportUrl';
      }
      case 5: {
        return 'voterIdUrl';
      }
      default: {
        return '';
      }
    }
  };

  getPopupContent = () => {
    const { popup } = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.VIEW_PERSON_POPUP: {
        return <ViewPerson person={this.state.person} {...popup} />;
      }
      case POPUP_CONSTANTS.VIEW_DOCUMENT: {
        return <ViewDocument {...popup} />;
      }

      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };
  handleVehicleSearch = () => {
    // API to fetch details and populate state.
    const { searchText, filter, visitingDate, noOfDays, companyId } = this.state;
    this.showSpinner();
    // Reset person

    if (!filter) {
      this.showErrorPopup(`Please select a filter`);
      return;
    }
    const data = {
      id: searchText.value,
      visitingDate: visitingDate,
      noOfDays: noOfDays,
      companyId: companyId,
    };
    API.fetchByVehicleRegistrationNumberOrRefNoOrRfidNumberAndVisitDate(data)
      .then(res => {
        console.log('res.data registration number....', res.data);

        let vehicle = res.data[0];

        let documentId = vehicle.documentTypeId;
        let documentProofField = this.getDocumentProofNumber(documentId);
        let documentProofNumber = vehicle[documentProofField];

        let documentUrlStateField = this.getDocumentProofUrl(documentId);
        let documentUrl = vehicle[documentUrlStateField];

        this.setState({
          vehicle: {
            ...res.data[0],
            documentProofNumber: documentProofNumber,
            documentUrl: documentUrl,
          },
          popup: null,
        });
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        this.setState({
          vehicle: null,
        });
        this.showErrorPopup(errorMsg);
      });
  };
  handleSearch = () => {
    // API to fetch details and populate state.
    const { searchText, filter } = this.state;
    this.showSpinner();
    // Reset person

    if (!filter) {
      this.showErrorPopup(`Please select a filter`);
      return;
    }

    if (filter.value === FILTER_OPTIONS.REFERENCE_NUMBER) {
      API.getPersonByReferenceId(searchText.value)
        .then(res => {
          console.log('res.data....', res.data);

          let person = res.data;
          let documentId = person.documentTypeId;
          let documentTypeName = this.getDocumentTypeName(
            person.documentTypeId,
          );
          let documentProofField = this.getDocumentProofNumber(documentId);
          let documentProofNumber = person[documentProofField];

          let documentUrlStateField = this.getDocumentProofUrl(documentId);
          let documentUrl = person[documentUrlStateField];

          this.setState({
            person: {
              ...res.data,
              documentProofNumber: documentProofNumber,
              documentUrl: documentUrl,
              documentTypeName: documentTypeName,
            },
            popup: null,
          });
        })
        .catch(error => {
          const errorMsg = util.getErrorMessage(error);
          this.setState({
            person: null,
          });
          this.showErrorPopup(errorMsg);
        });
    } else if (filter.value === FILTER_OPTIONS.RFID_CARD_NUMBER) {
      API.fetchPersonByRFID(searchText.value)
        .then(res => {
          console.log('res.data....', res.data);

          let person = res.data;
          let documentId = person.documentTypeId;
          let documentTypeName = this.getDocumentTypeName(
            person.documentTypeId,
          );
          let documentProofField = this.getDocumentProofNumber(documentId);
          let documentProofNumber = person[documentProofField];

          let documentUrlStateField = this.getDocumentProofUrl(documentId);
          let documentUrl = person[documentUrlStateField];

          this.setState({
            person: {
              ...res.data,
              documentProofNumber: documentProofNumber,
              documentUrl: documentUrl,
              documentTypeName: documentTypeName,
            },
            popup: null,
          });
        })
        .catch(error => {
          const errorMsg = util.getErrorMessage(error);
          this.setState({
            person: null,
          });
          this.showErrorPopup(errorMsg);
        });
    } else {
      API.fetchPersonByAadhaarNumber(searchText.value)
        .then(res => {
          console.log('res.data....', res.data);

          let person = res.data;
          let documentId = person.documentTypeId;
          let documentTypeName = this.getDocumentTypeName(
            person.documentTypeId,
          );
          let documentProofField = this.getDocumentProofNumber(documentId);
          let documentProofNumber = person[documentProofField];

          let documentUrlStateField = this.getDocumentProofUrl(documentId);
          let documentUrl = person[documentUrlStateField];

          this.setState({
            person: {
              ...res.data,
              documentProofNumber: documentProofNumber,
              documentUrl: documentUrl,
              documentTypeName: documentTypeName,
            },
            popup: null,
          });
        })
        .catch(error => {
          const errorMsg = util.getErrorMessage(error);
          this.setState({
            person: null,
          });
          this.showErrorPopup(errorMsg);
        });
    }
  };
  addPersonToTable = data => {
    if (!data.RFIDNumber) {
      this.showErrorPopup(
        'RFID Number of the person is missing. Please add another person.',
      );
    } else {
      const personsArrayTemp = this.state.personsArray.filter(
        e => e.id !== data?.id,
      );

      this.setState({
        personsArray: personsArrayTemp,
      });

      this.setState(prevState => ({
        ...prevState,
        personsArray: [...personsArrayTemp, data],
        //        person: null,
        searchText: { value: '' },
      }));
    }
  };
  deletePersonFromTable = id => {
    const personsArrayTemp = this.state.personsArray.filter(e => e.id !== id);

    this.setState({
      personsArray: personsArrayTemp,
    });
  };
  onClick = (action, data) => {
    const { history } = this.props;
    const { dispatchSetPersonsData } = this.props;

    switch (action) {
      case 'personSearchText': {
        this.handleSearch();
        break;
      }
      case 'vehicleSearchText': {
        this.handleVehicleSearch();
        break;
      }
      case 'removeVehicleFromPersonDetails': {
        this.handleVehicleSearch();
        break;
      }

      case 'cancel': {
        this.goBack();
        break;
      }
      case 'addPersonToTable': {
        this.addPersonToTable(data);
        break;
      }
      case 'delete': {
        this.deletePersonFromTable(data);
        break;
      }
      case 'submit': {
        console.log('calling dispatchSetPersonsData--->', data);
        dispatchSetPersonsData(data);
        history.goBack();
        break;
      }
      case 'view': {
        this.onView();
        break;
      }
      case 'addTwoWheeler': {
        console.log(data);
        this.setState(prevState => ({
          ...prevState,
          person: {
            ...prevState.person,
            twoWheelerVehicleRegistrationNumber: data ? data : null,
          },
        }));
        break;
      }
      default: {
        console.warn('No action found...', action);
        break;
      }
    }
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  checkDisabled = () => {
    const { personsArray } = this.state;

    if (personsArray.length > 0) {
      return false;
    } else {
      return true;
    }
  };
  onChangeHandler = (field, value) => {
    console.log('field', field, 'value', value);

    this.setState(prevState => ({
      ...prevState,
      [field]: {
        value,
      },
    }));
    console.log('this.state', this.state);
  };
  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: { color: '#E67717' },
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  closePopup = () => {
    this.setState({
      popup: null,
    });
  };

  onView = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.VIEW_PERSON_POPUP,
        onClose: this.closePopup,
        viewDocument: this.viewDocument,
      },
    });
  };
  viewDocument = url => {
    // this.setState({
    //   popup: {
    //     type: POPUP_CONSTANTS.VIEW_DOCUMENT,
    //     url: url,
    //     onClick: this.closePopup,
    //   },
    // });
    const { history, location } = this.props;
    history.push({
      pathname: location.pathname + '/document-view',
      state: { background: location, url: url },
    });
  };

  getDocumentTempURL = url => {
    const { history, location } = this.props;
    const data = {
      url: url,
      expiryTime: 30,
    };
    API.preSignedURL(data)
      .then(res => {
        console.log('res.data', res.data?.preSignedUrl);
        history.push({
          pathname: location.pathname + '/document-view',
          state: { background: location, url: res.data?.preSignedUrl },
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
  getProps = () => {
    const { person, personsArray, searchText, filter, filterOptions, vehicle } =
      this.state;
    const persons = this.props.location.state.persons;
    const personId =
      this.props.location.state.status === 'view'
        ? this.props.location.state?.id
        : 0;

    return {
      goBack: this.goBack,
      onClick: this.onClick,
      onChange: this.onChangeHandler,
      checkDisabled: this.checkDisabled,
      person,
      persons,
      personsArray,
      searchText: searchText?.value,
      filter,
      filterOptions,
      vehicle:
        this.props.location.state.status === 'view'
          ? persons?.filter(function (pa) {
            return pa.id === personId;
          })[0]?.vehicle
          : vehicle,
      personId,
      status: this.props.location.state?.status,
    };
  };
  render() {
    const viewProps = this.getProps();
    const { popup } = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer containerStyle={styles.popupContainerStyle}>
            {' '}
            {this.getPopupContent()}{' '}
          </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

// const mapStateToProps = reduxProps => ({
//   reduxProps,
// });

const mapDispatchToProps = dispatch => ({
  dispatchSetPersonsData: payload => {
    dispatch(setPersonsData(payload));
  },
});

// export default withRouter(PersonCards);
export default withRouter(connect(null, mapDispatchToProps)(AddPerson));
