import React, {useState} from 'react';
import styles from './styles.module.scss';
import {AgGridReact} from 'ag-grid-react';
import {operatorImages} from '../../../../assets/images';

import dimensions from '../../../../util/dimensions-util';
import Flex from '../../../../components/Flex';

import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import moment from 'moment';
import LineGraph from '../../../../components/LineGraph';

import Select from 'react-select';
import {customStyles} from './customStyles';

import DateRangePicker from 'react-daterange-picker';

import truckIcon from '../../../../assets/images/traffic-department-icons/truck/image.png';
import gateIcon from '../../../../assets/images/traffic-department-icons/gate/image.png';

import downloadIcon from '../../../../assets/images/traffic-department-icons/download/image.png';
import expandIcon from '../../../../assets/images/traffic-department-icons/expand/image.png';
import filterIcon from '../../../../assets/images/traffic-department-icons/filter/image.png';
import shareIcon from '../../../../assets/images/traffic-department-icons/share/image.png';
import ReactToPrint from 'react-to-print';
import CustomCheckbox from '../../../../components/CustomCheckbox';

const {vw: widthConstant, vh: heightConstant} = dimensions;

export default function Desktop({
  statuses,
  selectedDropDownStatus,
  onClick,
  startDate,
  endDate,
  dates,
}) {
  const columns = [
    {
      name: 'First Header',
    },
    {
      name: 'Second Header',
    },
    {
      name: 'Third Header',
    },
    {
      name: 'Fourth Header',
    },
    {
      name: 'Fifth Header',
    },
  ];

  const [showDate, setShowDate] = useState(false);
  const [showFiltersBar, setShowFiltersBar] = useState(false);
  const formatDate = date => moment(date).format('MMM Do YY');

  const onSelect = dates => {
    onClick('date', {
      startDate: moment(dates.start).format('MM-DD-YYYY'),
      endDate: moment(dates.end).format('MM-DD-YYYY'),
      dates,
    });
    toggleDate();
  };
  const toggleDate = () => {
    setShowDate(!showDate);
  };

  const handleSelect = name => {
    setShowDate(false);
    if (name?.name === 'custom') {
      setShowDate(true);
    }
    onClick('dateChange', name);
  };
  const componentRef = React.useRef(null);

  const onBeforeGetContentResolve = React.useRef(null);

  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState('old boring text');

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handleAfterPrint = React.useCallback(() => {
    console.log('`onAfterPrint` called');
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log('`onBeforePrint` called');
  }, []);
  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log('`onBeforeGetContent` called');
    setLoading(true);
    setText('Loading new text...');

    return new Promise(resolve => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText('New, Updated Text!');
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);
  const reactToPrintTrigger = React.useCallback(() => {
    return (
      <button className={styles.metricButton}>
        <img
          src={downloadIcon}
          className={styles.metricButtonIcon}
          alt="icon"
        />
      </button>
    );
  }, []);

  return (
    <>
      <div className={styles.mimicContainer}>
        <div className={styles.sidebar} />

        <div className={styles.mainContainer}>
          <div className={styles.topContainer}>
            <p>Vehicle Traffic</p>
            <div className={styles.rightContainer}>
              <p className={styles.date}>{moment().format('Do MMM YYYY')}</p>
              <div className={styles.liveContainer}>
                <div className={styles.redCircle} />
                <p>Live</p>
              </div>
            </div>
          </div>
          <Flex direction="row" className={styles.metrics}>
            <MetricCard
              title={`Total Vehicle Entries`}
              metric={`622`}
              icon={truckIcon}
            />
            <MetricCard
              title={`West Gate 1`}
              metric={`721`}
              icon={gateIcon}
              number={1}
            />
            <MetricCard
              title={`West Gate 2`}
              metric={`312`}
              icon={gateIcon}
              number={2}
            />
            <MetricCard
              title={`West Gate 3`}
              metric={`222`}
              icon={gateIcon}
              number={3}
            />
          </Flex>
          <div className={styles.graphContainer}>
            <Flex direction="row" style={{justifyContent: 'space-between'}}>
              <p>Traffic Department</p>
              <Flex direction="row" className={styles.rightFiltersContainer}>
                <div className={styles.agencyNameDropdown}>
                  <Select
                    options={statuses}
                    getOptionLabel={option => option.displayName}
                    getOptionValue={option => option.id}
                    placeholder={'Select date'}
                    styles={customStyles}
                    className={styles.dropDown}
                    value={statuses.filter(
                      option => option?.id === selectedDropDownStatus?.id,
                    )}
                    onChange={handleSelect}
                  />
                  <div onClick={toggleDate} className={styles.inputDate}>
                    {' '}
                    {dates
                      ? '(' +
                        formatDate(dates.start) +
                        '-' +
                        formatDate(dates.end) +
                        ')'
                      : null}{' '}
                  </div>
                </div>
                <div className={styles.dateWrapper}>
                  {showDate ? (
                    <div className={styles.datePicker}>
                      <DateRangePicker
                        numberOfCalendars={1}
                        selectionType="range"
                        onSelect={onSelect}
                        className={styles.dateRange}
                        value={dates}
                        singleDateRange={true}
                      />
                    </div>
                  ) : null}
                </div>
                {/* <MetricButton icon={downloadIcon} onClick={onClick} /> */}
                <ReactToPrint
                  content={reactToPrintContent}
                  documentTitle="AwesomeFileName"
                  onAfterPrint={handleAfterPrint}
                  onBeforeGetContent={handleOnBeforeGetContent}
                  onBeforePrint={handleBeforePrint}
                  removeAfterPrint
                  trigger={reactToPrintTrigger}
                />
                <MetricButton icon={shareIcon} onClick={onClick} />
                <MetricButton
                  icon={filterIcon}
                  onClick={() => setShowFiltersBar(!showFiltersBar)}
                />
                <MetricButton
                  icon={expandIcon}
                  onClick={() => onClick('graph-popup')}
                />
              </Flex>
            </Flex>
            {showFiltersBar && (
              <div className={styles.filtersContainer}>
                <MetricsFilters onClick={onClick} />
                <GateFilters onClick={onClick} />
              </div>
            )}

            <div ref={componentRef}>
              <LineGraph responsive />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const GateFilters = ({onClick, gateDetails}) => {
  return (
    <Flex direction="column">
      <h2 className={styles.metricsFiltersHeader}>Select Gate</h2>
      <Flex direction="row">
        <CustomCheckbox
          label={`Permits Raised`}
          onClick={data =>
            onClick('handle-metrics-filters', {
              metric: 'permitsRaised',
              value: data,
            })
          }
        />
        <CustomCheckbox
          label={`Total Vehicles`}
          onClick={data =>
            onClick('handle-metrics-filters', {
              metric: 'totalVehicles',
              value: data,
            })
          }
        />
        <CustomCheckbox
          label={`Vehicles With Permits`}
          onClick={data =>
            onClick('handle-metrics-filters', {
              metric: 'vehiclesWithPermits',
              value: data,
            })
          }
        />
        <CustomCheckbox
          label={`Invalid Entries`}
          onClick={data =>
            onClick('handle-metrics-filters', {
              metric: 'invalidEntries',
              value: data,
            })
          }
        />
      </Flex>
    </Flex>
  );
};

const MetricsFilters = ({onClick}) => {
  return (
    <Flex direction="column">
      <h2 className={styles.metricsFiltersHeader}>Select Parameter</h2>
      <Flex direction="row">
        <CustomCheckbox
          label={`Permits Raised`}
          onClick={data =>
            onClick('handle-metrics-filters', {
              metric: 'permitsRaised',
              value: data,
            })
          }
        />
        <CustomCheckbox
          label={`Total Vehicles`}
          onClick={data =>
            onClick('handle-metrics-filters', {
              metric: 'totalVehicles',
              value: data,
            })
          }
        />
        <CustomCheckbox
          label={`Vehicles With Permits`}
          onClick={data =>
            onClick('handle-metrics-filters', {
              metric: 'vehiclesWithPermits',
              value: data,
            })
          }
        />
        <CustomCheckbox
          label={`Invalid Entries`}
          onClick={data =>
            onClick('handle-metrics-filters', {
              metric: 'invalidEntries',
              value: data,
            })
          }
        />
      </Flex>
    </Flex>
  );
};

const MetricButton = ({icon, onClick}) => {
  return (
    <button onClick={onClick} className={styles.metricButton}>
      <img src={icon} className={styles.metricButtonIcon} alt="icon" />
    </button>
  );
};

const MetricCard = ({icon, number, metric, title}) => {
  return (
    <div className={styles.metricCardContainer}>
      <Flex direction="row" style={{height: '100%', alignItems: 'center'}}>
        <div className={styles.imageContainer}>
          <img src={icon} alt="icon" />
          {number && (
            <div className={styles.numberContainer}>
              <p className={styles.number}>{number}</p>
            </div>
          )}
        </div>
        <Flex direction="column">
          <p className={styles.metric}>{metric}</p>
          <p className={styles.title}>{title}</p>
        </Flex>
      </Flex>
    </div>
  );
};
