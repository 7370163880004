import strings from '../../../globalization';
export const editFormData = () => {
  return [
    {
      elementType: 'text',
      keyboardType: 'default',
      inputType: 'specialName',
      label: strings.masterAdminName,
      placeholder: strings.masterAdminNamePlaceholder,
      required: true,

      stateField: 'name',
      error: ' At Least Four Characters Required',
      stateDefault: '',
      minLength: 4,
      maxLength: 120,
      dependentOnOtherFields: false,
    },
    {
      elementType: 'text',
      keyboardType: 'default',
      inputType: 'hindiName',

      label: strings.masterAdminNameHindi,
      placeholder: strings.masterAdminNameHindiPlaceholder,
      error: ' At Least Four Characters Required',
      required: true,
      stateField: 'name_hindi',
      stateDefault: '',
      minLength: 4,
      maxLength: 120,
      dependentOnOtherFields: false,
    },
    {
      elementType: 'text',
      keyboardType: 'default',
      inputType: 'alphabets',

      label: 'Display Name',
      placeholder: 'Enter Display Name',
      error: ' At Least Four Characters Required',
      required: true,
      stateField: 'displayName',
      stateDefault: '',
      minLength: 4,
      maxLength: 120,
      dependentOnOtherFields: false,
    },
    {
      elementType: 'dropdown',
      inputType: 'text',
      label: strings.adminStatus,
      placeholder: strings.adminStatusPlaceholder,
      optionsField: 'statuses',
      required: true,
      stateField: 'status',
      stateDefault: null,
      maxLength: 20,
      error: 'Field is required',
      dependentOnOtherFields: false,
    },
  ];
};
