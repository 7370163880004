import moment from 'moment';
import util from '../../../util';

export const vehicles = [
  {
    id: 107,
    ownerEmailAddress: 'manuarorawork@yopmail.com',
    referenceNumber: 'OMB1338808543',
    vehicleOwnerName: 'Manu',
    ownerPhoneNumber: '9587738861',
    vehicleNumber: 'Rj14cx8936',
    payment: 'Pending',
    requestDate: '9/10/2021',
    status: 'Pending',

    vehicleType: 'Lorry',
    vehicleEngineNumber: 'ENGPAAJI123',
    PUCCertificate: 'PUCXYZMN',
    vehicleChassisNumber: 'CHASSIS51',
    vehicleInsuranceCertificate: 'CERTINSPXY',
    fitnessCertificate: 'FITNESSS4312',
    vehicleRCNumber: 'RCPAAJI12332',
  },
];
