import React, {useEffect, useState} from 'react';
import Flex from '../../../../../../components/Flex';
import TextInput from '../../../../../../components/TextInput';
import styles from './styles.module.scss';
import searchIcon from '../../../../../../assets/images/search-icon/image.png';
import bgIcon from '../../../../../../assets/images/background/image.png';
import truckIcon from '../../../../../../assets/images/truck-icon-blue/image@3x.png';

import {AgGridReact} from 'ag-grid-react';
import {adminImages, operatorImages} from '../../../../../../assets/images';
import moment from 'moment';
import Select from 'react-select';
import {customStyles} from './customStyles';
import ReactTooltip from 'react-tooltip';

import './styles.css';

export default function Desktop({
  vehicle,
  goBack,
  onChange,
  searchText,
  onClick,
  checkDisabled,
  vehiclesTableData,
  materialsTableData,
  vehicles,
  globalVehicles,
  checkMaterialDisabledButton,
  filterOptions,
  filter,
  // vehiclesArray,
  // materialsArray,
}) {
  const [showVehicleSummary, setShowVehicleSummary] = useState(false);
  const [title, setTitle] = useState('Add Vehicle');
  const [showFilters, setShowFilters] = useState(true);
  const [showSuccessBlock, setShowSuccessBlock] = useState(false);
  const [isAlreadyAdded, setIsAlreadyAdded] = useState(false);

  console.log('VehiclesMetadata vehicles', vehicles);
  console.log('VehiclesMetadata globalVehicles', globalVehicles);

  console.log('vehicle', vehicle);
  useEffect(() => {
    if (
      vehicles?.filter(function (va) {
        return va?.vehicle?.id === vehicle?.id;
      }).length > 0 ||
      globalVehicles?.filter(function (va) {
        return va?.vehicle?.id === vehicle?.id;
      }).length > 0
    ) {
      /* personsArray contains the element we're looking for */
      setIsAlreadyAdded(true);
    } else {
      setIsAlreadyAdded(false);
    }
  }, [vehicles, globalVehicles, vehicle, isAlreadyAdded]);
  console.log('filter vehicles...', filter);
  const deleteRow = (event, id) => {
    // event.stopPropagation();
    onClick('delete-vehicle', id);
  };

  const deleteMaterialRow = (event, id) => {
    onClick('delete-material', id);
  };
  const confirm = () => {
    setShowSuccessBlock(true);
    onClick('addVehicle', vehicle);
  };

  const showSummary = () => {
    console.log('show summary');
    setShowVehicleSummary(true);
    setTitle('View Summary');
    setShowFilters(false);
  };
  const goBackTo = () => {
    console.log('show summary');
    setShowVehicleSummary(false);
    setTitle('Add Vehicle');
    setShowFilters(true);
    onClick('emptySearch');
  };
  const ViewSummary = ({vehicle}) => {
    console.log(vehicle);
    return (
      <Flex className={styles.summaryBlock}>
        <div>
          <DataCard label={'Owner Name:'} value={vehicle?.name} />
          <DataCard
            label={'Owner Phone Number: '}
            value={vehicle?.phoneNumber}
          />
          <DataCard
            label={'Owner Email Address:  '}
            value={vehicle?.emailAddress}
          />
          <DataCard
            label={'Vehicle Engine Number: '}
            value={vehicle?.vehicleEngineNumber}
          />
          <DataCard
            label={'Vehicle Insurance Number: '}
            value={vehicle?.vehicleInsuranceNumber}
          />
          <DataCard
            label={'Vehicle Pollution Number: '}
            value={vehicle?.vehiclePollutionNumber}
          />
        </div>
        <div>
          <DataCard label={'RFID Card Number:'} value={vehicle?.RFIDNumber} />
          <DataCard
            label={'Vehicle Registration Number: '}
            value={vehicle?.vehicleRegistrationNumber}
          />
          <DataCard label={'Reference ID:  '} value={vehicle?.referenceId} />
          <DataCard
            label={'Vehicle Chassis Number:  '}
            value={vehicle?.chassisNumber}
          />
          <DataCard
            label={'Vehicle Make and Model: '}
            value={vehicle?.vehicleMakeAndModel}
          />
          <DataCard
            label={'Vehicle Manufacturing Year: '}
            value={vehicle?.vehicleManufacturingYear}
          />
          {/* <DataCard
            type="doc"
            label={'Document: '}
            value={vehicle?.RFIDNumber}
          />
          <DataCard
            type="doc"
            label={'Document:  '}
            value={vehicle?.RFIDNumber}
          />
          <DataCard
            type="doc"
            label={'Document:  '}
            value={vehicle?.RFIDNumber}
          /> */}
        </div>
      </Flex>
    );
  };
  const DataCard = ({label, value}) => {
    return (
      <Flex>
        <div className={styles.dataLabel}>{label}</div>
        <div className={styles.dataValue}>{value}</div>
      </Flex>
    );
  };

  return (
    <Flex className={styles.mainContainer}>
      <Flex className={styles.container}>
        <Header title={title} />
        {showFilters ? (
          <Flex className={styles.filtersWrapper}>
            <div className={styles.selectWrapper}>
              <Select
                options={filterOptions}
                // value={statuses.filter(
                //   (option) => option.attendance === selectedStatus
                // )}
                defaultValue={
                  filter
                    ? (filterOptions || [])
                        .map(option => ({
                          value: option.id,
                          label: option.name,
                        }))
                        .find(o => o.id === filter)
                    : null
                }
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                placeholder={'Select Type'}
                onChange={({id}) => {
                  onChange('filter', id);
                }}
                styles={customStyles}
              />
            </div>
            <SearchBar
              searchText={searchText}
              onChange={onChange}
              onClick={() => onClick('search')}
            />
          </Flex>
        ) : null}

        <Flex
          className={[
            styles.personCardsContainer,
            showVehicleSummary ? styles.vehicleSummaryTop : '',
          ].join(' ')}>
          {vehicle ? (
            <VehiclesMetadata
              onClick={onClick}
              vehicle={vehicle}
              vehicles={vehicles}
              globalVehicles={globalVehicles}
              // vehiclesArray={vehiclesArray}
            />
          ) : null}
        </Flex>
        {showSuccessBlock ? (
          <div>
            <VehicleSuccessBlock onClick={onClick} />
          </div>
        ) : null}

        {/* <div className={['ag-theme-balham', styles.tableWrapper].join(' ')}>
          <Flex direction="row" className={styles.tableMetadata}>
            <p>Vehicle & Driver Details</p>
          </Flex>
          <AgGridReact
            columnDefs={vehicleAndDriverColumnDefs}
            rowHeight={40}
            headerHeight={30}
            defaultColDef={{sortable: true}}
            rowClass={[styles.gridRowClass]}
            // onCellClicked={(event, index) => onCellClick(event, index)}
            rowData={vehiclesTableData}
            overlayNoRowsTemplate={
              '<span>Search Vehicle with Reference Number to add</span>'
            }></AgGridReact>
        </div> */}
        {/* <div className={['ag-theme-balham', styles.tableWrapper].join(' ')}> */}
        {/* <Flex direction="row" className={styles.tableMetadata}>
            <p>Material Details</p>
            <button
              className={styles.addMaterialButton}
              onClick={() => {
                onClick('addMaterial');
              }}
              style={
                checkMaterialDisabledButton()
                  ? {color: '#0045e650', cursor: 'not-allowed'}
                  : {color: '#0045e6'}
              }>
              +Add Material
            </button>
          </Flex> */}
        {/* <AgGridReact
            columnDefs={materialColumnDef}
            rowHeight={40}
            headerHeight={30}
            defaultColDef={{sortable: true}}
            rowClass={[styles.gridRowClass]}
            // onCellClicked={(event, index) => onCellClick(event, index)}
            rowData={materialsTableData}></AgGridReact> */}
        {/* </div> */}

        {vehicle && showVehicleSummary && !showSuccessBlock ? (
          <ViewSummary vehicle={vehicle} />
        ) : null}
        <Flex
          direction="row"
          className={[
            styles.buttonContainer,
            !showVehicleSummary ? styles.marginTop80 : '',
          ].join(' ')}>
          {!showVehicleSummary ? (
            <button className={styles.cancel} onClick={() => onClick('cancel')}>
              Cancel
            </button>
          ) : (
            <button className={styles.cancel} onClick={() => goBackTo()}>
              Go Back
            </button>
          )}
          {vehicle && !showVehicleSummary ? (
            <button className={styles.cancel} onClick={() => showSummary()}>
              View
            </button>
          ) : null}

          {vehicle && !isAlreadyAdded ? (
            <div>
              <button
                style={{width: '8vw'}}
                className={styles.finish}
                onClick={() => confirm(vehicle)}>
                Confirm
              </button>
            </div>
          ) : null}
        </Flex>
      </Flex>
    </Flex>
  );
}

const VehicleSuccessBlock = ({onClick}) => {
  return (
    <div className={styles.vehicleSuccessBlock}>
      <img
        src={adminImages.confirmImg}
        alt="u"
        className={styles.successIcon}
      />
      <div className={styles.successContent}>
        Vehicle is added successfully !
      </div>
      <div className={styles.successContent}>
        Would you like to add material to your vehicle?
      </div>
      <Flex direction="row" className={styles.buttonContainer}>
        <button
          className={styles.finish}
          onClick={() => {
            onClick('addMaterial');
          }}>
          Add Material
        </button>
      </Flex>
    </div>
  );
};
const Header = ({title}) => {
  return (
    <Flex className={styles.header}>
      <p>{title}</p>
    </Flex>
  );
};

const SearchBar = ({onChange, onClick, searchText}) => {
  return (
    <Flex direction="row" className={styles.searchContainer}>
      <input
        className={styles.searchInput}
        onChange={e => onChange('searchText', e.target.value)}
        placeholder={'Search by Ref. Number / Registration Number'}
        value={searchText}
      />
      <button className={styles.searchButton} onClick={onClick}>
        <img src={searchIcon} className={styles.searchIcon} alt="Avatar" />
      </button>
    </Flex>
  );
};

const VehiclesMetadata = ({onClick, vehicle, vehicles, globalVehicles}) => {
  const [visible, setVisible] = useState(true);
  const [selectedTrip, setSelectedTrip] = useState(2);

  console.log('VehiclesMetadata vehicles', vehicles);
  console.log('VehiclesMetadata globalVehicles', globalVehicles);

  console.log('vehicle', vehicle);
  useEffect(() => {
    if (
      vehicles?.filter(function (va) {
        return va.vehicle.id === vehicle.id;
      }).length > 0 ||
      globalVehicles?.filter(function (va) {
        return va.vehicle.id === vehicle.id;
      }).length > 0
    ) {
      /* personsArray contains the element we're looking for */
      setVisible(false);
    } else {
      setVisible(true);
    }
  }, [vehicles, globalVehicles, vehicle, visible]);
  return (
    <Flex direction="column" className={styles.personDetails}>
      {/* //image text container */}
      <Flex direction="row" className={styles.imageTextContainer}>
        <img
          src={adminImages.vehicleIcon}
          alt="u"
          className={styles.personImage}
        />
        <Flex direction="column" className={styles.textArea}>
          <p>
            Vehicle Number: <span>{vehicle.vehicleRegistrationNumber}</span>
          </p>

          <p>
            Vehicle Type: <span>{vehicle.vehicleType.name}</span>
          </p>
          {/* <p>
            RFID Card Number: <span>{vehicle.rfidnumber}</span>
          </p> */}
        </Flex>
      </Flex>
      <Flex className={styles.tripTypeDetails}>
        <p>Select trip type:</p>
        <div className={styles.radioButtons}>
          <div
            data-place="top"
            data-tip={'One entry & One exit within permit validity only'}>
            <input
              checked={selectedTrip === 1}
              onChange={() => {
                setSelectedTrip(1);
                setTimeout(() => {
                  onClick('onTripChange', 1);
                }, 100);
              }}
              name="tripType"
              type="radio"
            />{' '}
            <span>Single trip</span>
          </div>
          <div
            data-place="top"
            data-tip={'Multiple entry & exit within permit validity'}>
            {' '}
            <input
              name="tripType"
              type="radio"
              checked={selectedTrip === 2}
              onChange={() => {
                setSelectedTrip(2);
                setTimeout(() => {
                  onClick('onTripChange', 2);
                }, 100);
              }}
            />{' '}
            <span>Multiple trips</span>
          </div>
        </div>
        <ReactTooltip
          html={true}
          className={styles.toolTip}
          place="top"
          type="info"
          effect="solid"
          multiline={true}
        />
      </Flex>
      {!visible ? (
        <p className={styles.personAlreadyAdded}>Vehicle already added</p>
      ) : null}
      {/* //buttons container
      {visible ? (
        <Flex direction="row" className={styles.buttonContainer}>
          <button
            style={{width: '8vw'}}
            className={styles.cancel}
            onClick={() => onClick('viewVehicle')}>
            View
          </button>
          <button
            style={{width: '8vw'}}
            className={styles.finish}
            onClick={() => onClick('addVehicle', vehicle)}>
            Add
          </button>
        </Flex>
      ) : (
        <p className={styles.personAlreadyAdded}>Vehicle already added</p>
      )} */}
    </Flex>
  );
};
