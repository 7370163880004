import React from 'react';
import {keys, INPUT_CONSTANTS, formFields, buttonGroup} from '../../data';
import {withRouter} from 'react-router-dom';
import strings from '../../../../../globalization';
import CustomInput from '../../../../../components/CustomInput';
import Button from '../../../../../components/Button';
import styles from './Mobile.module.scss';
import OtpInput from 'react-otp-input';

const Mobile = props => {
  const {onClick, onChangeState, otp, isShowOtp} = props;
  const formFieldsData = isShowOtp ? formFields : [formFields[0]];

  const handleClick = action => {
    console.info('handleClick...', action);
    onClick(action);
  };
  const onChange = (action, data) => {
    // console.info('onChange...', action);
    onChangeState(action, data.target.value);
  };

  const getInputType = element => {
    if (element.field === 'otp' && isShowOtp) {
      return (
        <div className={styles.otpContainer}>
          <label>Enter OTP</label>
          <OtpInput
            value={otp}
            numInputs={6}
            inputStyle={{
              width: '2.5rem',
              height: '2.5rem',
            }}
            onChange={update => onChangeState(element.field, update)}
            separator={<span>&nbsp; &nbsp;</span>}
          />
        </div>
      );
    }

    if (element.field !== 'otp') {
      return (
        <CustomInput
          labelStyles={styles.customLabel}
          inputLable={element.label}
          onChange={update => onChange(element.field, update)}
          type={element.type}
          placeholder={element.placeholder}
          // required={element.required}
          name={element.field}
          leftIcon={element.leftIcon}
          style={{width: '100%', height: '50px'}}
          containerStyles={{marginBottom: '20px'}}
        />
      );
    }
  };
  return (
    <div className={styles.loginContainer}>
      <div className={styles.logoContainer}>
        <img src="./static/images/Logo/logo.png" alt="Logo" />
        <h2>{keys.title}</h2>
        <h4>{keys.subTitle}</h4>
      </div>
      <div className={styles.formContainer}>
        <p className={styles.loginText}>{strings.forgotPassword}</p>
        {formFieldsData.map(field => getInputType(field))}
        <div className={styles.divider} />
        <div className={styles.forgotPasswordRow}>
          <a
            href=""
            onClick={e => {
              e.preventDefault();
              handleClick('send-otp');
            }}>
            {strings.sendOtp}
          </a>
        </div>

        {isShowOtp && (
          <Button
            buttonStyle="primary"
            label={strings.confirm}
            className={styles.submitButton}
            onClick={() => handleClick('change-password')}
          />
        )}
      </div>
    </div>
  );
};
export default withRouter(Mobile);
