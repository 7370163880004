/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {withRouter} from 'react-router-dom';

import {getFormData, termsData} from './formFeilds';
import Select from 'react-select';
import util from '../../../util';
import {API} from '../../../requests/index';
import AuthLayout from '../../../components/AuthLayout';
import PopupContainer from '../../../components/PopupContainer';
import ViewRenderer from './views';
import Spinner from '../../../components/Spinner';
import strings from '../../../globalization';
import Popup from '../../../components/SimplePopup';
import {commonImages, operatorImages} from '../../../assets/images';
import OtpPopup from '../../../components/OtpPopup';
import POPUP_CONSTANTS from '../../../constants/popup-constants';
import {showOtpPopup} from '../../../util/popup-util';
import {Metadata} from '../../../components/Metadata';
import PAGE_TITLES from '../../../constants/page-titles';
import PAYMENT_CONSTANTS from '../../../constants/payment-constants';
import Privacy from '../../../components/Privacy';

const INPUT_CONSTANTS = {
  TEXT: 'text',
  DROPDOWN: 'dropdown',
  DATE: 'date',
  FILE: 'file',
};

const DROPDOWN_KEY_VALUE = {
  1: 'Person',
  2: 'Vehicle',
  3: 'Agency',
};

class VehicleRegistration extends React.Component {
  constructor(props) {
    super(props);
    const {status} = props;
    console.log('status', status, props);

    let preparedObject = {};
    this.formData = getFormData();
    [...this.formData.left, ...this.formData.right].forEach(obj => {
      preparedObject[obj.stateField] = {value: obj.stateDefault, error: null};
      if (obj.optionsField) {
        preparedObject[obj.optionsField] = [];
      }
    });
    this.state = {
      expiryMinutes: 1,
      checked: false,
      otpVerified: false,
      status: status,
      currentOtpVerifiactionObject: {
        verifiedNumberFields: {},
      },
      ...preparedObject,
    };
    this.showOtpPopup = showOtpPopup.bind(this);
    console.log('this.state', this.state);
  }

  onChange = (action, data) => {
    this.setState(prevState => {
      return {
        ...prevState,
        [action]: data,
      };
    });
  };
  toggleChecked = () => {
    // Open terms popup
    const {checked} = this.state;
    if (!checked) {
      this.showTermsPopup();
    } else {
      this.setState({
        checked: !this.state.checked,
      });
    }
  };

  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  showSuccessPopup = successMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: successMessage,
        onClose: this.closePopup,
        headingImage: operatorImages.successIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  showErrorRetryPopup = (errorMsg, retryCallback) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMsg,
        messageStyle: {color: '#E67717'},

        buttons: [
          {
            text: strings.cancelPopup,
            onClick: this.closePopup,
            outline: true,
          },
          {
            text: strings.retryApiPopup,
            onClick: () => {
              this.closePopup();
              retryCallback();
            },
          },
        ],
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
      },
    });
  };

  openOTPPopUp = () => {
    this.setState(
      {
        currentOtpVerifiactionObject: {
          field: 'phoneNumber',
          phoneNumber: this.state['phoneNumber'].value,
          verifiedNumberFields: {
            ...this.state?.currentOtpVerifiactionObject?.verifiedNumberFields,
          },
        },
      },
      () => {
        this.generateOtp(this.state.currentOtpVerifiactionObject.phoneNumber);
      },
    );
  };
  checkVehicle = async vehicleRegistrationNumber => {
    const data = await API.checkVehicle(vehicleRegistrationNumber)
      .then(res => {
        console.log('checkVehicleRes...', res.data);
        const {isVehicleExists} = res.data;
        let result = false;
        if (isVehicleExists) {
          result = true;
        }
        console.log('result...', result);
        return result;
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error.message);
        this.showErrorPopup(errorMsg);
      });
    return data;
  };

  handleSubmit = async () => {
    const {vehicleRegistrationNumber} = this.state;
    let error = this.handleErrors();
    const {otpVerified, vehicleDetails} = this.state;
    const {history, status} = this.props;

    const vehicleRegistrationNumberExists = await this.checkVehicle(
      vehicleRegistrationNumber.value,
    );

    console.log(
      'vehicleRegistrationNumberExists...',
      vehicleRegistrationNumberExists,
    );

    if (error) {
      this.closePopup();
    } else if (vehicleRegistrationNumberExists && status !== 'EDIT') {
      this.showErrorPopup(
        'Vehicle Already Exists with this Registration Number',
      );
    } else if (!otpVerified) {
      this.openOTPPopUp();
    } else {
      console.log('FIND ME!');
      this.showSpinner();
      this.submitFormData()
        .then(res => {
          let referenceId = res.referenceId;
          let paymentDetails = res.paymentDetails;
          console.log('paymentDetails', res.paymentDetails);

          if (status === 'EDIT') {
            if (
              (referenceId && vehicleDetails?.status === 'PAYMENT_PENDING') ||
              vehicleDetails?.status === 'PAYMENT_FAILED'
            ) {
              this.closePopup();
              this.props.history.push('/payments', {
                id: referenceId,
                // title: strings.personRegistration,
                title: 'Vehicle Registration',
                paymentDetails: paymentDetails,
                type: PAYMENT_CONSTANTS.VEHICLE,
              });
            } else if (referenceId) {
              this.closePopup();
              this.props.history.push('/registration-success', {
                id: referenceId,
                status: vehicleDetails?.status,
                title: 'Update Vehicle Registration',
              });
              //  alert('details updated successfully');
              // this.showUpdateStatusPopup(referenceId);
            } else {
              this.showErrorPopup(
                'There Was An Error While Updating The Vehicle Details. Please Try Again.',
              );
            }
          } else {
            if (referenceId) {
              this.closePopup();
              this.props.history.push('/payments', {
                id: referenceId,
                // title: strings.personRegistration,
                title: 'Vehicle Registration',
                paymentDetails: paymentDetails,
                type: PAYMENT_CONSTANTS.VEHICLE,
              });

              // this.props.history.push('/registration-success', {
              //   id: referenceId,
              //   title: strings.vehicleRegistration,
              // });
              // Remove popup

              //  this.createOrderIdAndNavigate(referenceId);

              // this.setState({
              //   referenceId: referenceId,
              // });
            } else {
              this.showErrorPopup(
                'There Was An Error While Saving The Details. Please Try Again.',
              );
            }
          }
        })
        .catch(errors => {
          const errorMsg = errors.response
            ? errors.response.data.message
            : errors.message;
          this.closePopup();
          this.showErrorPopup(errorMsg);
          console.log(errors);
        });
    }
  };
  createOrderIdAndNavigate = referenceId => {
    API.createPaymentOrderId()
      .then(res => {
        const paymentDetails = {
          orderId: res.data.orderId,
          subTotal: res.data.subTotal,
          tax: res.data.tax,
        };

        console.log('Payment Details -->', paymentDetails);

        this.props.history.push('/payments', {
          id: referenceId,
          // title: strings.personRegistration,
          title: 'Person Registration',
          paymentDetails: paymentDetails,
          type: PAYMENT_CONSTANTS.VEHICLE,
        });
      })
      .catch(errors => {
        const errorMsg = errors.response
          ? errors.response.data.message
          : errors.message;
        this.closePopup();
        this.showErrorPopup(errorMsg);
        console.log(errors);
      });
  };

  submitFormData = async () => {
    const {status} = this.props;
    const {vehicleDetails} = this.state;

    console.log(
      new Date().getFullYear() - this.state.vehicleManufacturingYear.value <= 1,
    );
    let vehicleInsuranceValidity = util.getDateTimeString(
      this.state.vehicleInsuranceValidity.value,
      'mmddyyyy',
    );

    let vehicleRCValidity = util.getDateTimeString(
      this.state.vehicleRCValidity.value,
      'mmddyyyy',
    );

    let pucValidity = util.getDateTimeString(
      this.state.pucValidity.value,
      'mmddyyyy',
    );

    let insuranceCertificateValidity = util.getDateTimeString(
      this.state.vehicleInsuranceValidity.value,
      'mmddyyyy',
    );

    let fitnessCertificateValidity = () => {
      if (
        new Date().getFullYear() - this.state.vehicleManufacturingYear.value <=
        1
      ) {
        return undefined;
      } else {
        return util.getDateTimeString(
          this.state.fitnessCertificateValidity.value,
          'mmddyyyy',
        );
      }
    };

    let preparedData = {
      //vehicleNumber: this.state.vehicleNumber.value,
      // rcNumber: this.state.vehicleRCNumber.value,
      vehicleRegistrationNumber: this.state.vehicleRegistrationNumber.value,
      vehicleInsuranceNumber: this.state.vehicleInsuranceNumber.value,
      vehiclePollutionNumber: this.state.vehiclePollutionNumber.value,
      vehicleCategoryId: this.state.vehicleCategory.value,
      vehicleTypeId: this.state.vehicleType.value,
      chassisNumber: this.state.vehicleChassisNumber.value,
      vehicleEngineNumber: this.state.vehicleEngineNumber.value,
      rcDocument: this.state.vehicleRC.value[0].fileUrl,
      vehicleInsuranceCertificate:
        this.state.vehicleInsuranceCertificate.value[0].fileUrl,
      puc: this.state.PUCCertificate.value[0].fileUrl,
      emailAddress: this.state.emailAddress.value || '',
      phoneNumber: `+91${this.state.phoneNumber.value}`,
      name: this.state.name.value,
      vehicleManufacturingYear: this.state.vehicleManufacturingYear.value,
      vehicleMakeAndModel: this.state.vehicleMakeAndModel.value,
      fitnessCertificateValidity: fitnessCertificateValidity(),
      fitnessCertificate:
        new Date().getFullYear() - this.state.vehicleManufacturingYear.value <=
        1
          ? undefined
          : this.state.fitnessCertificate.value[0].fileUrl,
      insuranceCertificateValidity: insuranceCertificateValidity,
      pucValidity: pucValidity,
      rcValidity: vehicleRCValidity,
      vehicleInsuranceValidity: vehicleInsuranceValidity,
      departmentId: this.state?.departmentId?.value,
    };
    if (this.state.GSTNumber.value) {
      preparedData.GSTNumber = this.state.GSTNumber.value;
    }

    if (this.state.officialContractDocument?.value?.length > 0) {
      preparedData.officialContractDocument =
        this.state.officialContractDocument.value[0].fileUrl;
    }

    if (this.state.officialContractDocumentValidity.value) {
      preparedData.officialContractDocumentValidity = util.getDateTimeString(
        this.state.officialContractDocumentValidity.value,
        'mmddyyyy',
      );
    }

    console.log('preparedData:', preparedData, this.state, vehicleDetails);
    // let res = await API.registerVehicle(preparedData);
    let res;
    if (status === 'EDIT') {
      if (
        vehicleDetails?.status === 'ISSUED' ||
        vehicleDetails?.status === 'UPDATED' ||
        vehicleDetails?.status === 'UPDATE_REJECTED'
      ) {
        res = await API.updateIssuedVehicleRegistration({
          ...preparedData,
          id: vehicleDetails?.id,
        });
      } else {
        res = await API.updateVehicleRegistration({
          ...preparedData,
          id: vehicleDetails?.id,
        });
      }
    } else {
      res = await API.registerVehicle(preparedData);
    }
    console.log('preparedData', preparedData);

    // let res = await API.registerVehicle(preparedData);
    // if (res?.status === 200 && res?.data?.referenceId) {
    //   console.log('res', res.data);
    //   // this.props?.history?.push({
    //   //   pathname: "registration-success",
    //   //   state: {
    //   //     id: res?.data?.referenceId,
    //   //   },
    //   // });
    // }
    return res.data;
  };

  getData = () => {
    this.showSpinner();

    const promises = [];

    promises.push(
      API.getAllDepartments().then(departmentsRes => {
        //       const {departmentId: departmentElement} = this.state;
        this.setState({
          deptOptions: util.addDataToStrings(departmentsRes.data),
        });
        console.log('state object:', this.state);
      }),
    );

    promises.push(
      API.getAllVehicleCategories().then(res => {
        this.setState({
          vehicleCategories: util.addDataToStrings(res.data),
        });
      }),
    );

    Promise.all(promises)
      .then(() => {
        this.setState({
          popup: null,
        });
      })
      .catch(error => {
        console.log(error);
        const errorMsg = error.response
          ? error.response.data?.message || error.response.data
          : error.message;
        this.showErrorPopup(errorMsg);
      });
  };

  handleErrors = () => {
    let err = false;
    const update = {};
    let formData = [...this.formData.left, ...this.formData.right];
    console.log('formData', formData);
    let requiredBool = false;
    for (let i = 0; i < formData.length; i++) {
      let element = formData[i];
      requiredBool = false;

      if (element.stateField === 'GSTNumber') {
        if (this.state[element.stateField].value) {
          requiredBool = true;
        }
      }

      console.log('element', i, element);
      let error = util.validate(this.state[element.stateField].value, {
        ...element,
        required: requiredBool || element.required,
      });
      if (error) {
        err = true;
        console.log('error', error);
        console.log('element.stateField', element.stateField);
        update[element.stateField] = {
          ...this.state[element.stateField],
          error: error,
        };
      }
    }
    this.setState({
      ...update,
    });
    return err;
  };

  getOptions = element => {
    const {countries, personType, documentType, genderTypes} = this.state;
    switch (element) {
      case 'countryId':
        return countries;
      case 'typeId':
        return personType;
      case 'genderId':
        return genderTypes;
      case 'documentTypeId':
        return documentType;
      case 'nationality':
        return countries;
      default:
        return [];
    }
  };

  componentDidMount() {
    this.getPreLoginToken();
    const {status} = this.props;
   // this.getData();
    if (status === 'EDIT') {
      this.getExistingVehicleDetails();
    }
  }

  getPreLoginToken = () => {
    API.getPreLoginToken()
      .then(response => {
        console.log(response);
        API.setPreLoginToken(response);
        this.getData();
      })
      .catch(error => {
        //this.showErrorPopup('');
        console.log(error);
      });
  };

  getExistingVehicleDetails = () => {
    const {status, location} = this.props;
    const {referenceId} = location;
    console.log(status, this.props, location?.referenceId);

    API.getVehicleDetailsByReferenceId(referenceId)
      .then(response => {
        console.log('vehicle details', response.data);
        this.setState({
          vehicleDetails: response.data,
          popup: null,
        });
        this.onChangeHandler(
          'vehicleManufacturingYear',
          response.data?.vehicleManufacturingYear,
        );
        this.onChangeHandler(
          'vehicleCategory',
          response.data?.vehicleCategoryId,
        );

        const vehicleDetails = {
          ...response.data,
          vehicleRC: response.data?.rcDocument
            ? [
                {
                  fileUrl: response.data?.rcDocument,
                  fileName: response.data?.rcDocument.substr(
                    response.data?.rcDocument.length - 10,
                  ),
                },
              ]
            : null,
          vehicleInsuranceCertificate: response.data
            ?.vehicleInsuranceCertificate
            ? [
                {
                  fileUrl: response.data?.vehicleInsuranceCertificate,
                  fileName: response.data?.vehicleInsuranceCertificate.substr(
                    response.data?.vehicleInsuranceCertificate.length - 10,
                  ),
                },
              ]
            : null,

          PUCCertificate: response.data?.puc
            ? [
                {
                  fileUrl: response.data?.puc,
                  fileName: response.data?.puc.substr(
                    response.data?.puc.length - 10,
                  ),
                },
              ]
            : null,
          fitnessCertificate: response.data?.fitnessCertificate
            ? [
                {
                  fileUrl: response.data?.fitnessCertificate,
                  fileName: response.data?.fitnessCertificate.substr(
                    response.data?.fitnessCertificate.length - 10,
                  ),
                },
              ]
            : null,

          officialContractDocument: response.data?.officialContractDocument
            ? [
                {
                  fileUrl: response.data?.officialContractDocument,
                  fileName: response.data?.officialContractDocument.substr(
                    response.data?.officialContractDocument.length - 10,
                  ),
                },
              ]
            : null,
          officialContractDocumentValidity: new Date(
            response.data?.officialContractDocumentValidity,
          ).getTime(),

          vehicleRCValidity: new Date(response.data?.rcValidity).getTime(),
          vehicleCategory: response.data?.vehicleCategoryId,
          vehicleType: response.data?.vehicleTypeId,
          vehicleChassisNumber: response.data?.chassisNumber,
          phoneNumber: response.data?.phoneNumber.slice(3),
          fitnessCertificateValidity: new Date(
            response.data?.fitnessCertificateValidity,
          ).getTime(),
          GSTNumber: response.data?.gstnumber,
        };

        [...this.formData.left, ...this.formData.right].forEach(obj => {
          console.log(obj?.stateField, vehicleDetails['' + obj.stateField]);
          if (obj.inputType === 'upload') {
            this.setState({
              [obj.stateField]: {
                value: vehicleDetails['' + obj.stateField]
                  ? vehicleDetails['' + obj.stateField]
                  : [],
                error: null,
              },
            });
          } else {
            this.setState({
              [obj.stateField]: {
                value: vehicleDetails['' + obj.stateField],
                error: null,
              },
            });
          }
        });
        console.log(this.state, this.formData.right);
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        console.log(errorMsg);
        this.showErrorPopup(errorMsg);
      });
  };

  onChangeHandler = (field, value) => {
    console.log('field:', field, 'value:', value);
    let preparedStateObject = {};
    if (field === 'phoneNumber') {
      this.onOtpVerifySuccess(false);
    }
    if (field === 'vehicleManufacturingYear') {
      // Check if  vehicle age is < 1
      if (new Date().getFullYear() - value <= 1) {
        this.formData.left = [...this.formData.left].filter(
          element => element.stateField !== 'fitnessCertificate',
        );
        this.formData.right = [...this.formData.right].filter(
          element => element.stateField !== 'fitnessCertificateValidity',
        );
      } else {
        this.formData = getFormData();
      }
      let preparedStateObject = {};
      preparedStateObject[field] = {
        value,
        error: null,
      };
      this.setState(preparedStateObject);
    } else if (field === 'vehicleCategory') {
      const {vehicleCategories} = this.state;
      preparedStateObject['vehicleTypeOptions'] = vehicleCategories.find(
        category => category.id === value,
      )?.vehicleTypes;
      preparedStateObject[field] = {
        value,
      };
      preparedStateObject['vehicleType'] = {
        value: null,
      };
      console.info('preparedStateObject', preparedStateObject);
      this.setState(preparedStateObject, function () {
        console.log('this.state', this.state);
      });
    } else {
      preparedStateObject[field] = {
        value,
      };
      this.setState(preparedStateObject, function () {
        console.log('this.state', this.state);
      });
    }
    console.log(this.state);
  };
  onBlur = (field, element) => {
    const currentObj = this.state[field];

    console.log('formField', element);
    this.setState({
      [field]: {
        ...currentObj,
        error: element ? util.validate(currentObj.value, element) : null,
      },
    });
  };
  handleUpload = (field, files, error, inputRef) => {
    console.info('field:', field, 'files', files);
    if (error) {
      this.showErrorPopup(error);
      return;
    }
    this.showSpinner();

    if (files === null) {
      throw new Error('File does not exist');
    }

    let filesArray = [];
    for (let i = 0, numFiles = files.length; i < numFiles; i++) {
      filesArray.push(files[i]);
    }

    console.log('filesArray', filesArray);

    // FIXME: Fix real uploading thing.

    let promises = filesArray.map(file => {
      const data = new FormData();
      data.append('s3FolderName', 'company');
      data.append('file', file);
      data.append('allowedExtensions', ['Pk8b8jAuPC1DF', 'JakNVgcBVZUPG', 'JPw7fCgDTT6AEG', 'PCrAriI079CNG']);
      return API.uploadFilesToDpt(data).then(response => {
        console.log('Response Here', response.data);
        const url = response.data?.fileUrl;
        return {fileUrl: url, fileName: file.name};
      });
    });

    return Promise.all(promises)
      .then(response => {
        console.log('[field] - this.state[field]', this.state[field]);
        console.log('response', response);
        this.setState(prevState => ({
          popup: null,
          [field]: {
            ...prevState[field],
            value: [...prevState[field].value, ...response],
            error: null,
          },
        }));
      })
      .catch(error => {
        console.log('error', error);
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;

        this.setState({
          popup: null,
        });
     
        this.showErrorPopup(errorMsg);
        //throw new Error(errorMsg);
        if (inputRef) {
          inputRef.current.value = '';
        }
        console.log(errorMsg);
      });
  };

  getProps = () => {
    const {history} = this.props;
    const {errorMessage} = this.state;
    console.info('vehicleCategories', this.state.vehicleCategories);
    return {
      // formLeftFields: this.formData.left,
      // formRightFields: this.formData.right,
      // getInputType: this.getInputType,
      // history,
      // errorMessage,
      // onChange: this.onChange,
      onClick: this.onClickHandler,
      onChangeHandler: this.onChangeHandler,
      formLeftFields: this.formData.left,
      formRightFields: this.formData.right,
      onBlur: this.onBlur,
      handleUpload: this.handleUpload,
      backClick: this.handleBackClick,
      onSubmit: this.handleSubmit,
      toggleChecked: this.toggleChecked,
      stateData: this.state,
      otpVerifiedField: this.state.currentOtpVerifiactionObject,
      ...this.state,
    };
  };

  handleBackClick = () => {
    const {history} = this.props;
    history.push('/signin');
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };
  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };
  acceptTerms = () => {
    this.setState({
      checked: true,
      popup: null,
    });
  };

  showTermsPopup = () => {
    console.log('showTermsPopup');
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.TERMS_POPUP,
        onClose: this.closePopup,
        onButtonClick: this.acceptTerms,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  onClickHandler = (action, data) => {
    switch (action) {
      case 'otp-verify': {
        this.setState(
          {
            currentOtpVerifiactionObject: {
              field: data.stateField,
              phoneNumber: this.state[data.stateField].value,
              verifiedNumberFields: {
                ...this.state?.currentOtpVerifiactionObject
                  ?.verifiedNumberFields,
              },
            },
          },
          () => {
            this.generateOtp(
              this.state.currentOtpVerifiactionObject.phoneNumber,
            );
          },
        );

        //this.generateOtp(this.state.phoneNumber?.value);
        break;
      }
      case 'terms': {
        this.showTermsPopup();
        break;
      }
      default: {
        console.info('case not handled', action);
      }
    }
  };
  /**
   * @return {JSX.Element}
   */

  generateOtp = async phoneNumber => {
    this.showSpinner();
    if (!phoneNumber || phoneNumber.toString().length !== 10) {
      this.showErrorPopup('Please enter a valid phone number');
    } else {
      const result = util.validatePhoneNumber(phoneNumber);
      if (!result) {
        this.showErrorPopup('Please enter a valid phone number');
        return;
      }
      await API.generateOtp({phoneNumber: `+91${phoneNumber}`})
        .then(res => {
          if (res?.data?.phoneNumber === `+91${phoneNumber}`) {
            this.setState({
              expiryMinutes: res?.data?.expiryMinutes,
            });
            this.showOtpPopup();
          } else {
            this.showErrorPopup('Invalid phone number');
          }
        })
        .catch(error => {
          console.log(error);
          // const errorMsg = error.response
          const errorMsg = util.getErrorMessage(error);
          // const errorMsg =
          //   'Invalid Phone Number. Please enter a valid phone number.';
          this.showErrorPopup(errorMsg);
        });
    }
  };

  onOtpVerifySuccess = isSucess => {
    const {currentOtpVerifiactionObject} = this.state;
    if (true) {
      this.setState({
        otpVerified: isSucess,
        currentOtpVerifiactionObject: {
          ...this.state.currentOtpVerifiactionObject,
          otpVerified: isSucess,
          verifiedNumberFields: {
            ...this.state.currentOtpVerifiactionObject.verifiedNumberFields,
            [currentOtpVerifiactionObject.field]: isSucess,
          },
        },
      });
    }
  };

  getPopupContent = () => {
    const {popup, phoneNumber} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="blue" />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.OTP_POPUP: {
        return (
          <OtpPopup
            onClose={this.closePopup}
            showSuccessPopup={() =>
              this.showSuccessPopup('Phone number verified successfully!')
            }
            showErrorPopup={errorMessage => {
              this.showErrorPopup(errorMessage);
            }}
            phoneNumber={this.state?.phoneNumber?.value}
            onOtpVerifySuccess={this.onOtpVerifySuccess}
            message={
              strings.message +
              phoneNumber.value.substr(phoneNumber.value.length - 4)
            }
            resendOTP={this.generateOtp}
            expiryMinutes={this.state?.expiryMinutes}
          />
        );
      }
      case POPUP_CONSTANTS.TERMS_POPUP: {
        return (
          <Privacy
            onClose={this.closePopup}
            messageObject={termsData}
            heading="Terms and Conditions"
            {...popup}
          />
        );
      }
      default: {
        console.warn('case not handled', popup.type);
        return null;
      }
    }
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <Metadata title={PAGE_TITLES.vehicleRegistration} />
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}
export default withRouter(VehicleRegistration);

//export default VehicleRegistration;
