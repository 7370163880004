export const personRegistrationContent = [
  {
    title: 'What is person registration and how to do it?',
    tags: 'Person, people, enrollment, driver, cleaner',
    answers: [
      {
        type: 'text',
        content:
          '1. Any person with the intent of entering the port for any purpose of work must register themselves',
      },
      {
        type: 'text',
        content:
          '2. Enrolling users details into the system by filling person registration form. (One- time)',
      },
      {
        type: 'text',
        content: 'For more details please click on attached link-',
      },
      {
        type: 'link',
        title: 'E-Drishti Person Registration Manual',
        content:
          'https://edrishti.sanya.ai/user-manual/a1d7cbbb-3f1a-4362-97b8-d4a872fd7129PersonRegistration.pdf',
      },
    ],
  },
  {
    title: `Didn't receive OTP?`,
    tags: 'Person, people, enrollment, driver, cleaner',
    answers: [
      {
        type: 'text',
        content:
          'Check your network connectivity and Mobile Number should have incoming service.',
      },
      {
        type: 'text',
        content:
          'If you fail to receive the OTP even with good network connectivity, please click on "Resend OTP"',
      },
      {
        type: 'text',
        content:
          'In any case if the OTP is not being received, please call our help center or write to us at - edrishti@sanya.ai',
      },
    ],
  },
  {
    title: `Is there any usage validity for RFID person card? `,
    tags: 'Person, people, enrollment, driver, cleaner',
    answers: [
      {
        type: 'text',
        content: 'There is no usage validity for RFID person card.',
      },
    ],
  },
  {
    title: `How to recharge the Card and Tag?`,
    tags: 'Person, people, enrollment, driver, cleaner',
    answers: [
      {
        type: 'text',
        content:
          'The Card or Tag does not require any recharge, however to enter inside the port, a valid  permit should be raised against the card or tag by an active agency for the respective Person/Vehicle.',
      },
    ],
  },
  {
    title: `What documents are required for person registration?`,
    tags: 'Person, people, enrollment, driver, cleaner',

    answers: [
      {
        type: 'text',
        content: 'Documents required for Person registration are- ',
      },
      {
        type: 'bullet',
        content: [
          'Aadhar Card.(Mandatory)',
          'If person type is Driver, then Driving License (Mandatory)',
          'Safety Certificate (Optional)',
          'Medical Certificate (Optional)',
          'Police NOC (Optional)',
          'Individual Photo (Mandatory)',
        ],
      },
      {
        type: 'text',
        content: '**Max Document size is 2MB**',
      },
    ],
  },
];

export const vehicleRegistration = [
  {
    title: 'What is vehicle registration and how to do it?',
    tags: 'Vehicle, truck, dumper',
    answers: [
      {
        type: 'text',
        content:
          '1. Any vehicle with intent of entering the port for any purpose of work must register themselves.',
      },
      {
        type: 'text',
        content:
          '2. Enrolling users details into the system by filling Vehicle registration form. (One- time)',
      },
      {
        type: 'text',
        content: 'For more details please click on attached link-',
      },
      {
        type: 'link',
        title: 'E-Drishti Vehicle Registration Manual',
        content:
          'https://edrishti.sanya.ai/user-manual/48a58c43-aee3-4aaf-a264-d50ff1749810VehicleRegistration.pdf',
      },
    ],
  },
  {
    title: `Didn't receive OTP?`,
    tags: 'Vehicle, truck, dumper',

    answers: [
      {
        type: 'text',
        content:
          'Check your network connectivity and Mobile Number should have incoming service.',
      },
      {
        type: 'text',
        content:
          'If you fail to receive the OTP even with good network connectivity, please click on "Resend OTP"',
      },
      {
        type: 'text',
        content:
          'In any case if the OTP is not being received, please call our help center or write to us at - edrishti@sanya.ai',
      },
    ],
  },
  {
    title: `Is there any usage validity for RFID vehicle tag? `,
    tags: 'Vehicle, truck, dumper',

    answers: [
      {
        type: 'text',
        content: 'There is no usage validity for RFID vehicle tag.',
      },
    ],
  },
  {
    title: `What documents are required for vehicle registration?`,
    tags: 'Vehicle, truck, dumper',

    answers: [
      {
        type: 'text',
        content: 'Documents required for Vehicle registration are- ',
      },
      {
        type: 'bullet',
        listStyleType: 'decimal',
        content: [
          'Vehicle RC.(Mandatory)',
          'Vehicle Insurance Certificate (Mandatory)',
          'PUC Certificate (Optional)',
          'Fitness Certificate (Mandatory)',
        ],
      },
      {
        type: 'text',
        content: '**Max Document size is 2MB**',
      },
    ],
  },
];

export const manageRegistrationInformation = [
  {
    title:
      'If the card is in "Issued" status, how to update the existing documents which are about to get expired?',
    tags: 'Lost, damaged, accident, invoice, break',
    answers: [
      {
        type: 'text',
        content:
          '"Manage Registration Information" module is used to Edit/Update/View/Manage registration information.',
      },
      {
        type: 'text',
        content:
          '1. Person registration:  If the status of registration is "Issued" or "Details Updated" or "Update Rejected" then partial-edit option should be used by the user to update their Driving license, Safety Certificate and Police NOC with their validity.',
      },
      {
        type: 'text',
        content:
          '2. Vehicle registration:  If the status of registration is "Issued" or "Details Updated" or "Update Rejected" then partial-edit option should be used by the user to update Vehicle Insurance, PUC  and fitness certificate with their validity.',
      },
    ],
  },
  {
    title: 'How to download invoice for person/vehicle registration?',
    tags: 'Lost, damaged, accident, invoice, break',

    answers: [
      {
        type: 'text',
        content: 'To download invoice:',
      },
      {
        type: 'bullet',
        listStyleType: 'decimal',
        content: [
          'Click on "Manage Registration Information"',
          'Select person/vehicle from dropdown and click on "Get OTP"',
          'After entering OTP, click on submit details.',
          'Details of person/vehicle will display on the screen, beneath these details there will be an Invoices section',
          'Click on "Download Invoice"',
        ],
      },
    ],
  },
  {
    title: 'How to surrender RFID Card/Tag?',
    tags: 'Lost, damaged, accident, invoice, break',

    answers: [
      {
        type: 'text',
        content: 'To surrender RFID card/tag:',
      },
      {
        type: 'bullet',
        listStyleType: 'decimal',
        content: [
          'Click on "Manage Registraion Information"',
          'Select person/vehicle from dropdown and click on "Get OTP"',
          'After entering OTP, click on submit details.',
          'Details of person/vehicle will display on the screen, beneath these details there will be "Surrender Card" button.',
          'Read the next steps and details in the next screen about surrendering a card/tag.',
          'You will be prompted to again enter OTP to confirm your action.',
          'Post entering OTP and confirming, your card/tag will be marked as surrendered and this action cannot be revoked',
        ],
      },
    ],
  },
  {
    title:
      'If the RFID card/tag is lost or damaged, what is the process to obtain a new RFID card/tag?',
    tags: 'Lost, damaged, accident, invoice, break',

    answers: [
      {
        type: 'text',
        content:
          'The process to obtain a new RFID card/tag if lost or damaged:',
      },
      {
        type: 'bullet',
        listStyleType: 'decimal',
        content: [
          'Click on "Manage Registraion Information"',
          'Select person/vehicle from dropdown and click on "Get OTP"',
          'After entering OTP, click on submit details.',
          'Details of person/vehicle will display on the screen, beneath these details there will be lost or damaged button.',
          'Click on "Lost/Damaged" button.',
          'Read the next steps and details in the next screen about marking a card/tag as lost/damaged.',
          'Once your card/tag is marked as lost or damaged, please refresh the page and complete the payment for respective card/tag.',
          'After the payment, if status of your card/tag is in "Reissue payment completed", please visit nearest e-Drishti help center and collect your card/tag.',
        ],
      },
    ],
  },
  {
    title: 'How to update details of existing agency?',
    tags: 'Lost, damaged, accident, invoice, break',

    answers: [
      {
        type: 'text',
        content: 'To update details of existing agency-',
      },
      {
        type: 'bullet',
        listStyleType: 'decimal',
        content: [
          'Click on "Manage Registration Information" . Select Agency and  from dropdown, enter you unique reference number and click on "Get OTP"',
          'After entering OTP, click on submit details.',
          'Details of Agency will display on the screen click on edit and fill the missing details for your agency.',
          'After providing details Click on submit.',
          'Your agency is successfully registered please make a note of unique refrence number.',
        ],
      },
    ],
  },
];

export const payments = [
  {
    title: 'What is the cost for RFID person card and RFID vehicle tag?',
    tags: 'amount, rate, wallet, gst',

    answers: [
      {
        type: 'text',
        content: 'The cost for RFID Card/Tag is a one-time payment.',
      },
      {
        type: 'text',
        content: '1. RFID Person Card : INR 200 + GST',
      },
      {
        type: 'text',
        content: '2. RFID Vehicle Tag : INR 100 + GST',
      },
    ],
  },
  {
    title:
      'How to do repayment for person and vehicle registration if payment is failed?',
    tags: 'amount, rate, wallet, gst',
    answers: [
      {
        type: 'text',
        content: 'To do repayment:',
      },
      {
        type: 'text',
        content:
          '1. Person Payment Failed:  If the registration payment is failed, user can do re-payment from "Manage Registration Information" section using a unique reference number/Aadhar Number.',
      },
      {
        type: 'text',
        content:
          '2. Vehicle Payment Failed: If the registration payment is failed, user can do re-payment from "Manage Registration Information" section using a unique reference number/Vehicle Number.',
      },
    ],
  },
  {
    title: 'How much will be charged for a vehicle to enter the port?',
    tags: 'amount, rate, wallet, gst',
    answers: [
      {
        type: 'text',
        content:
          'Currently as per DPA approved rates, 35 credits will be deducted from the user account at the gates for each entry.',
      },
      {
        type: 'text',
        content: '**[1 credit = INR 1]**',
      },
      {
        type: 'text',
        content:
          'Note: This price is subject to amendment based on the progress of the project. ',
      },
    ],
  },
  {
    title:
      'Why even after recharging my user account wallet credits are not reflected?',
    tags: 'amount, rate, wallet, gst',
    answers: [
      {
        type: 'text',
        content:
          'Due to bank server or payment gateway issues, payments in some cases does not get received at our end. However, we do reconciliation every 24 hours and if payment is received it will be reflected in your user account wallet else it will be refunded from your bank within 5-7 working days. ',
      },
    ],
  },
  {
    title: 'How to recharge user account wallet ?',
    tags: 'amount, rate, wallet, gst',
    answers: [
      {
        type: 'text',
        content: 'To recharge wallet-',
      },
      {
        type: 'bullet',
        listStyleType: 'disc',
        content: [
          'Step-1: Login into the agency click on "Wallet".',
          'Step-2: In available credits click on "Buy credits".',
          'Step-3: Choose the mode of payment and complete the payment.',
          'After providing details Click on submit.',
          'Step-4: After successful payment, credits will be reflected in "Your credits".',
        ],
      },
      {
        type: 'text',
        content: '**[1 credit = INR 1]**',
      },
    ],
  },
  {
    title: 'Will I get invoice for every vehicle entering the port?',
    tags: 'amount, rate, wallet, gst',
    answers: [
      {
        type: 'text',
        content:
          'Invoice will be generated for adding credits into the wallet.',
      },
    ],
  },
  {
    title: 'Is there any payment to be done while registering an agency?',
    tags: 'amount, rate, wallet, gst',
    answers: [
      {
        type: 'text',
        content: 'No, there is no payment for agency registration.',
      },
    ],
  },
];

export const user = [
  {
    title: 'How to raise permit?',
    tags: 'permit, delink, bulk permit, user, account',
    answers: [
      {
        type: 'text',
        content: 'To raise a permit-',
      },
      {
        type: 'bullet',
        listStyleType: 'disc',
        content: [
          'Step-1: Click on "Add Permit" request to raise a new permit. In the Permits screen, add the following- "Permit Start Date", "Purpose of Work", "Pass Validity" and then Click on "+Add Vehicle"',
          'Step-2: Enter "Vehicle Reference Number" or "Vehicle Registration Number" or "RFID Tag Number" in the search bar to get the vehicle data and then click on "Confirm".',
          'Step-3: Click on "Submit Request".',
          'Step-4: Review the permit details in the next screem and comfirm the permit. ',
          'Step-5: A new permit request is raised for approval and the corresponding details are forwarded via an email and SMS.  ',
        ],
      },
    ],
  },
  {
    title: 'Can we raise permit for more then one vehicle at once?',
    tags: 'permit, delink, bulk permit, user, account',

    answers: [
      {
        type: 'text',
        content: 'To raise Bulk Permit-',
      },
      {
        type: 'bullet',
        listStyleType: 'disc',
        content: [
          'Step-1: Click on "Add Permit" request to raise a new permit. In the Permits screen, add the following- Permit Start Date, Purpose of Work, Pass Validity and then Click on +Add Vehicle',
          'Step-2: Enter Vehicle Reference Number/Vehicle Registration Number/RFID Tag Number in the search bar to get the vehicle data and then click on confirm.',
          'Step-3: For bulk permits again click on add vehicle and follow the step No. 1, 2, 3 and then click on submit a request.',
        ],
      },
    ],
  },
  {
    title: 'What does delink means?',
    tags: 'permit, delink, bulk permit, user, account',

    answers: [
      {
        type: 'text',
        content: `If the vehicle is not required by the agency, it can be released from the vehicle's active permit by clicking on "Delink" option. So the vehicle can be used by other agencies to raise a permit. `,
      },
    ],
  },
  {
    title: 'Why credits got deducted twice for same vehicle with in 2 minutes?',
    tags: 'permit, delink, bulk permit, user, account',

    answers: [
      {
        type: 'text',
        content: `For any such double deductions, we will revert the credits into the user account wallet under our reconcillation policy.`,
      },
    ],
  },
];
