import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import POPUP_CONSTANTS from '../../../../constants/popup-constants';
import {API} from '../../../../requests';
import util from '../../../../util';

import Spinner from 'react-spinkit';
import Popup from '../../../../components/SimplePopup';

import styles from './styles.module.scss';
import ViewRenderer from './views';
import PopupContainer from '../../../../components/PopupContainer';
import {commonImages} from '../../../../assets/images';
import strings from '../../../../globalization';

class SelectVehicle extends Component {
  constructor(props) {
    super(props);

    // const {reducerState} = this.props;
    // let data = reducerState.vehicles.map(vObj => {
    //   return {...vObj.vehicle, name: vObj.vehicle.vehicleRegistrationNumber};
    // });

    // let requiredData = reducerState.vehicles.filter(elem =>
    //   elem.material ? false : true,
    // );

    // let rdArr = requiredData.map(vObj => {
    //   return {...vObj.vehicle, name: vObj.vehicle.vehicleRegistrationNumber};
    // });

    const {vehicles} = this.props.location.state;

    let requiredData = vehicles.filter(elem => (elem.material ? false : true));
    let rdArr = requiredData.map(vObj => {
      return {...vObj.vehicle, name: vObj.vehicle.vehicleRegistrationNumber};
    });

    // console.log('data', requiredData);

    this.state = {
      searchText: null,
      driver: null,
      selectedVehicle: '',
      selectedVehicleOptions: rdArr,

      // FIXME: Add vehicle from location object
    };
  }

  goBack = () => {
    console.log('goBack Called!!-->');
    const {history} = this.props;
    history.goBack();
  };

  getDocumentProofNumber = documentId => {
    switch (documentId) {
      case 1: {
        return 'aadhaarNumber';
      }
      case 2: {
        return 'drivingLicenceNumber';
      }
      case 3: {
        return 'panNumber';
      }
      case 4: {
        return 'passportNumber';
      }
      case 5: {
        return 'voterIdNumber';
      }
      default: {
        return '';
      }
    }
  };
  getDocumentProofUrl = documentId => {
    switch (documentId) {
      case 1: {
        return 'aadhaarUrl';
      }
      case 2: {
        return 'drivingLicenceUrl';
      }
      case 3: {
        return 'panUrl';
      }
      case 4: {
        return 'passportUrl';
      }
      case 5: {
        return 'voterIdUrl';
      }
      default: {
        return '';
      }
    }
  };
  handleSearch = () => {
    // API to fetch details and populate state.
    const {searchText} = this.state;
    this.showSpinner();
    API.getPersonByReferenceId(searchText.value)
      .then(res => {
        console.log('res.data....', res.data);

        let driver = res.data;

        let documentId = driver.documentTypeId;
        let documentProofField = this.getDocumentProofNumber(documentId);
        let documentProofNumber = driver[documentProofField];

        let documentUrlStateField = this.getDocumentProofUrl(documentId);
        let documentUrl = driver[documentUrlStateField];

        this.setState({
          driver: {
            ...res.data,
            documentProofNumber: documentProofNumber,
            documentUrl: documentUrl,
          },
          popup: null,
        });
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        this.setState({
          driver: null,
        });
        this.showErrorPopup(errorMsg);
      });
  };
  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };
  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }

      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };

  onChangeHandler = (field, value) => {
    console.log('field', field, 'value', value);

    this.setState(prevState => ({
      ...prevState,
      [field]: {
        value,
      },
    }));
    console.log('this.state', this.state);
  };

  onSelectVehicle = () => {
    const {history, location, dispatch, reducerState} = this.props;
    const {selectedVehicle, selectedVehicleOptions} = this.state;
    console.log('selectedVehicle', selectedVehicle);
    console.log('selectedVehicleOptions', selectedVehicleOptions);
    let vehicle = selectedVehicleOptions.find(
      elem => elem.id === selectedVehicle.value,
    );
    console.log('found vehicle...', vehicle);
    dispatch({
      type: 'add-vehicle',
      payload: vehicle,
    });
    history.push({
      pathname: '/u/permits/add-new-material',
      state: {
        ...location.state,
      },
    });
  };
  onClick = (action, data) => {
    const {dispatch, reducerState} = this.props;
    const {history, location} = this.props;
    console.log('location', location);
    switch (action) {
      case 'search': {
        this.handleSearch();
        break;
      }
      case 'cancel': {
        this.goBack();
        break;
      }

      case 'proceed': {
        this.onSelectVehicle();
        break;
      }
      default: {
        console.warn('No action found...', action);
        break;
      }
    }
  };

  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  closePopup = () => {
    this.setState({
      popup: null,
    });
  };

  checkDisabled = () => {
    const {selectedVehicle} = this.state;

    if (selectedVehicle) {
      return false;
    } else {
      return true;
    }
  };

  getProps = () => {
    const {location} = this.props;
    const {reducerState} = this.props;
    console.log('reducerState', reducerState);
    console.log('LOCATION', location);
    // const {vehicle, driver} = this.state;
    return {
      onClick: this.onClick,
      onChange: this.onChangeHandler,
      checkDisabled: this.checkDisabled,
      selectedVehicleOptions: this.state.selectedVehicleOptions,
      selectedVehicle: this.state.selectedVehicle,
    };
  };

  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer containerStyle={styles.popupContainerStyle}>
            {' '}
            {this.getPopupContent()}{' '}
          </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

// export default withRouter(PersonCards);
export default withRouter(SelectVehicle);
