import React from 'react';
import styles from './styles.module.scss';
import util from '../../util';
import strings from '../../globalization';
import Flex from '../Flex';

// eslint-disable-next-line max-lines-per-function
const PhoneInput = ({
  inputType,
  inputLable,
  onTextChange,
  placeholder,
  onLabelPress,
  style,
  value,
  keyboardType = 'default',
  textStyle,
  leftIcon,
  rightIcon,
  showBottomRightLabel,
  rightLabel,
  onIconClick,
  toggleEyeIcon,
  showIcon = false,
  iconName = '',
  inputLabelStyle,
  isNumber = false,
  containerStyle,
  required = false,
  placeholderColor = '#D3D3D4',
  error,
  onBlur,
  maxLength,
  tabIndex,
  containerStyles,
  labelStyles,
  iconStyles,
  inputBoxClassName,
  triggerOTP,
  otpVerified,
  isVerifyOtp,
  otpVerifiedField,
  containerClassNames,
  labelClassNames,
  name,
  disabled,
  ...inputProps
}: Props) => {
  const textChangeHandler = text => {
    // console.log('text phone', text.substr(-1), isNaN(text.substr(-1)));
    if (!isNaN(text.substr(-1))) {
      text = text.replace(/^\s+/g, '');
      let trimmed = text.trim();
      onTextChange(trimmed);
    }
  };

  const getPhoneVerifyStatus = () => {
    console.log(otpVerifiedField);
    if (
      (otpVerifiedField &&
        otpVerifiedField.field === name &&
        otpVerifiedField.otpVerified) ||
      otpVerifiedField?.verifiedNumberFields[name]
    ) {
      return (
        <span className={styles.mobileVerified}>Mobile Number Verified</span>
      );
    } else {
      if (isVerifyOtp) {
        return (
          <a
            className={styles.verifyOtpText}
            href=""
            onClick={e => {
              e.preventDefault();
              if (triggerOTP) {
                triggerOTP();
              }
            }}>
            {strings.verifyOTP}
          </a>
        );
      }
    }
  };
  return (
    <div style={containerStyles} className={styles.container}>
      <Flex direction="row" className={styles.labelContainer}>
        <p className={[styles.label, labelStyles, labelClassNames].join(' ')}>
          {inputLable}
        </p>
        {required ? <p style={{color: 'red'}}>*</p> : null}
      </Flex>

      <Flex
        style={style}
        className={[
          styles.inputBox,
          inputBoxClassName,
          containerClassNames,
        ].join(' ')}>
        {
          <Flex direction="column" className={styles.imageContainer}>
            <span className={styles.mobCode}>+91 </span>
            {/* <img
              alt="U"
              src={leftIcon}
              className={styles.leftIconStyles}
              style={iconStyles}
            /> */}
          </Flex>
        }
        <input
          // {...inputProps}
          tabIndex={tabIndex}
          className={styles.input}
          value={value}
          keyboardType={keyboardType}
          placeholder={placeholder}
          secureTextEntry={inputType}
          onChange={e => textChangeHandler(e.target.value)}
          onBlur={onBlur}
          maxLength={maxLength}
          disabled={disabled}
          style={
            disabled ? {backgroundColor: '#FAFAFA', color: '#000000'} : null
          }
          {...inputProps}
        />

        {getPhoneVerifyStatus()}
      </Flex>

      <Flex style={{width: '100%', height: '1rem'}}>
        <p className={styles.error}>{error}</p>
      </Flex>
    </div>
  );
};

export default PhoneInput;
