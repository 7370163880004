import React, {useState, useEffect} from 'react';
import styles from './styles.module.scss';

import Flex from '../../../../../../components/Flex';
import PermitsHeader from '../../../../../../components/PermitsHeader';

import FormElement from '../../../../../../components/FormElement';
import Button from '../../../../../../components/Button';
import ViewAgencyDetails from '../../../../../../components/ViewAgencyDetails';

import strings from '../../../../../../globalization';

import dimensions from '../../../../../../util/dimensions-util';
import './styles.css';
import SannaLogo from '../../../../../../components/SannaLogo';
import {customStyles} from './customStyles';

import dropDown from '../../../../../../assets/images/drop-down/dropdown@3x.png';
import calendarBlue from '../../../../../../assets/images/calendar-blue-icon/image.png';
import moment from 'moment';
import DateRangePicker from 'react-daterange-picker';
import TimePicker from 'rc-time-picker';
import {AgGridReact} from 'ag-grid-react';
import PersonVehicleDetails from '../../../../../../components/PersonVehicleLatestActivityDetails';
import {adminImages, commonImages} from '../../../../../../assets/images';

const {vw: widthConstant, vh: heightConstant} = dimensions;

const tabs = [
  {
    label: 'Person Details',
    id: 1,
    route: 'person-details',
  },
  {
    label: 'Vehicle Details',
    id: 2,
    route: 'vehicle-details',
  },
  {
    label: 'Agency Details',
    id: 3,
    route: 'agency-details',
  },
];

const columnDefs = [
  {
    headerName: 'Name',
    field: 'name',
    headerClass: styles.rowHeader,
    minWidth: 250 * widthConstant,
    resizable: true,
    flex: 1,
    wrapText: true,
    autoHeight: true,
    cellStyle: {display: 'flex', alignItems: 'center', lineHeight: 1.5},
    cellRendererFramework: params => {
      if (params?.data !== undefined) {
        const {firstName, lastName, middleName} = params?.data;
        return (
          <div className={styles.sessionTime}>
            {(firstName + ' ' + lastName)?.length > 45
              ? (firstName + ' ' + lastName).slice(0, 45) + '...'
              : firstName + ' ' + lastName}
          </div>
        );
      } else {
        return null;
      }
    },
  },
  {
    headerName: 'Email Address',
    field: 'emailAddress',
    headerClass: styles.rowHeader,
    minWidth: 350 * widthConstant,
    resizable: true,
    wrapText: true,
    autoHeight: true,
    cellStyle: {display: 'flex', alignItems: 'center', lineHeight: 1.5},
    cellRendererFramework: params => {
      if (params?.data !== undefined) {
        const {emailAddress} = params?.data;
        return (
          <div className={styles.sessionTime}>
            {emailAddress?.length > 35
              ? emailAddress?.slice(0, 35) + '...'
              : emailAddress}
          </div>
        );
      } else {
        return null;
      }
    },
  },
  {
    headerName: 'PhoneNumber',
    field: 'phoneNumber',
    headerClass: styles.rowHeader,
    width: 200 * widthConstant,
    resizable: true,
    wrapText: true,
    autoHeight: true,
    cellStyle: {display: 'flex', alignItems: 'center', lineHeight: 1.5},
    cellRendererFramework: params => {
      if (params?.data !== undefined) {
        const {phoneNumber} = params?.data;
        return (
          <div className={styles.sessionTime}>{phoneNumber?.slice(3)}</div>
        );
      } else {
        return null;
      }
    },
  },
  {
    headerName: 'Role',
    field: 'roleDisplayName',
    headerClass: styles.rowHeader,
    width: 240 * widthConstant,
    resizable: true,
    wrapText: true,
    autoHeight: true,
    cellStyle: {display: 'flex', alignItems: 'center', lineHeight: 1.5},
    cellRendererFramework: params => {
      if (params?.data !== undefined) {
        const {roleDisplayName} = params?.data;
        return <div className={styles.sessionTime}>{roleDisplayName}</div>;
      } else {
        return null;
      }
    },
  },
  {
    headerName: 'Status',
    field: 'status',
    headerClass: styles.rowHeader,
    width: 200 * widthConstant,
    resizable: true,
    wrapText: true,
    autoHeight: true,
    cellStyle: {display: 'flex', alignItems: 'center', lineHeight: 3},
    cellRendererFramework: params => {
      if (params?.data !== undefined) {
        const {status} = params?.data;
        return <div className={styles.sessionTime}>{status}</div>;
      } else {
        return null;
      }
    },
  },
];

export default function Desktop({
  containerClassNames,
  onChangeHandler,
  stateData,
  formData,
  onClick,
  serverTime,
  onSubmit,
}) {
  console.log(stateData);

  const {agencyData} = stateData;
  const {companyDetails, users} = agencyData || {};

  const [requestType, setRequestType] = useState(null);

  const placeholderTextConstant = {
    RFID: {
      label: 'RFID Number',
      placeholder: 'Enter RFID Number',
    },
    vehicleNumber: {
      label: 'Vehicle Number',
      placeholder: 'Enter Vehicle Number',
    },
  };

  const {
    vehicleLatestActivity,
    dates,
    vehicleActivityList,
    startTime,
    endTime,
    totalCount,
  } = stateData;

  const [showDate, setShowDate] = useState(false);
  const [showVehicleActivityTable, setShowVehicleActivityTable] =
    useState(false);
  const [showLatestActivity, setShowLatestActivity] = useState(false);

  const [startTimePanel, setStartTimePanel] = useState(null);
  const [endTimePanel, setEndTimePanel] = useState(null);

  const [closePopup, setClosePopup] = useState(false);
  const [remarks, setRemarks] = useState(null);

  console.log(totalCount, stateData);
  const [gridApi, setGridApi] = useState(null);
  const onGridReady = params => {
    setGridApi(params.api);
    onChangeHandler('gridAPI', params.api);
  };
  const updateTable = () => {
    onChangeHandler('updateTable', null);
  };

  useEffect(() => {
    console.log(gridApi);
    if (gridApi && totalCount > 10) {
      console.log('changing to normal');
      gridApi.setDomLayout('normal');
    } else if (gridApi) {
      console.log('changing to autoHeight');
      gridApi.setDomLayout('autoHeight');
    }
  }, [totalCount, gridApi]);

  useEffect(() => {
    console.log(gridApi);
    updateTable();
  }, [gridApi]);

  const onCellClick = (event, index) => {
    onClick('rowClick', event.data);
  };
  const onSelect = dates => {
    onChangeHandler('date', {
      startDate: moment(dates.start).format('MM-DD-YYYY'),
      endDate: moment(dates.end).format('MM-DD-YYYY'),
      dates,
    });
    toggleDate();
  };
  const toggleDate = () => {
    setShowDate(!showDate);
  };

  const formatDate = date => moment(date).format('MMM Do YY');
  const rowStyle = {border: 'none', cursor: 'pointer'};
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return {background: '#f6f6f6', border: 'none'};
    }
  };
  const showVehicleTable = () => {
    setShowVehicleActivityTable(!showVehicleActivityTable);
  };
  const latestActivity = () => {
    setShowLatestActivity(!showLatestActivity);
  };
  const handleClear = () => {
    setShowDate(false);
    onClick('clearFilters');
  };

  const onTabClick = id => {
    switch (id) {
      case 1:
        onClick('statusRoute', {route: 'person-details', id: id});
        break;
      case 2:
        onClick('statusRoute', {route: 'vehicle-details', id: id});
        break;
      case 3:
        onClick('statusRoute', {route: 'agency-details', id: id});
        break;
      default:
        break;
    }
  };
  const blackListVehicle = data => {
    // onClick(data);
    setClosePopup(true);
  };

  return (
    <div className={[styles.container, containerClassNames].join(' ')}>
      <PermitsHeader
        onTabClick={onTabClick}
        title="View Agency Details"
        tabs={tabs}
        activeTab={3}
      />
      <Flex className={styles.formContainer}>
        <FormElement
          type="dropdown"
          label={'Search with agency name/reference Id'}
          required={true}
          placeholderText={'Enter agency name or reference Id'}
          onChange={text => {
            onChangeHandler('agencySearch', text);
          }}
          value={stateData.referenceId}
          maxLength={30}
          options={stateData.requestTypeOptions}
          dropdownContainerStyle={styles.dropdownStyle}
          styles={customStyles}
        />

        <Button
          buttonStyle="primary"
          label={`Submit`}
          className={styles.submitButton}
          onClick={onSubmit}
        />
      </Flex>
      <div>
        {' '}
        {companyDetails ? (
          <ViewAgencyDetails
            onBlackList={blackListVehicle}
            onUnBlackList={id => {
              onClick('unBlackList', id);
            }}
            onClick={onClick}
            companyDetails={companyDetails}
          />
        ) : null}
      </div>

      {companyDetails ? (
        <Slider
          title={'Agency Users'}
          max={showVehicleActivityTable}
          onClick={showVehicleTable}
        />
      ) : null}
      {companyDetails && showVehicleActivityTable ? (
        <div className={styles.tableContainer}>
          <div
            className={[
              'ag-theme-balham',
              totalCount > 10
                ? styles.tableWrapperNormal
                : styles.tableWrapperAutoHeight,
            ].join(' ')}>
            <AgGridReact
              onGridReady={onGridReady}
              rowStyle={rowStyle}
              getRowStyle={getRowStyle}
              rowHoverHighlight={true}
              columnDefs={columnDefs}
              rowHeight={55 * heightConstant}
              headerHeight={60 * heightConstant}
              defaultColDef={{sortable: true}}
              rowClass={[styles.gridRowClass]}
              pagination={true}
              paginationPageSize={100}
              rowModelType={'infinite'}
              animateRows={true}
              onCellClicked={(event, index) => onCellClick(event, index)}
              domLayout="autoHeight"></AgGridReact>
          </div>
          <div className={styles.bottomLabel}> Total Count: {totalCount}</div>
        </div>
      ) : null}
      {closePopup ? (
        <BlackListPopUp
          onClose={() => {
            setClosePopup(false);
          }}
          setRemarks={text => {
            setRemarks(text);
          }}
          agency={companyDetails}
          remarks={remarks}
          onSubmit={() => {
            onClick('blackList', {id: companyDetails?.id, remarks: remarks});
            setRemarks(null);
            setClosePopup(false);
          }}
        />
      ) : null}
      <div className={styles.SannaLogo}>
        <SannaLogo />
      </div>
    </div>
  );
}

const Slider = ({onClick, max, title}) => {
  return (
    <Flex
      className={[styles.slider, max ? styles.sliderOpen : ''].join(' ')}
      onClick={onClick}>
      <div>{title}</div>
      <img
        src={dropDown}
        alt={'u'}
        className={max ? styles.dropDownRotate : styles.dropDownIcon}
      />
    </Flex>
  );
};

const BlackListPopUp = ({onSubmit, remarks, agency, onClose, setRemarks}) => {
  return (
    <div className={styles.popUpContainer}>
      <div className={[styles.container].join(' ')}>
        <div className={styles.secondContainer}>
          {onClose ? (
            //  <span className={styles.closeIcon}>X </span>
            <img
              alt="close"
              src={adminImages.closeIcon}
              className={styles.closeIcon}
              onClick={onClose}
            />
          ) : null}

          <div className={styles.heading}>
            {' '}
            {'Blacklist Agency -' + agency?.name}
          </div>
          <div className={styles.bodyContainer}></div>

          <Flex direction="column" className={styles.textareaContainer}>
            <img
              alt="e"
              src={commonImages.errorIcon}
              className={styles.errorIcon}
            />
            <div style={{textAlign: 'center', marginBottom: 20}}>
              Are you sure want to blacklist the agency? <br />
              This will temporarily disable the agency account, <br />
              If yes please add the reason in the below box and submit
            </div>
            <textarea
              rows="5"
              maxlength="500"
              className={styles.remark}
              value={remarks}
              onChange={e => setRemarks(e.target.value)}
              placeholder={strings.typeYourRemarks}></textarea>

            <Button
              buttonStyle="primary"
              label={`Submit`}
              className={styles.finalButton}
              onClick={onSubmit}
            />
          </Flex>
        </div>
      </div>
    </div>
  );
};
