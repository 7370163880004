import React from 'react';
import styles from './styles.module.scss';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import Flex from '../Flex';
import moment from 'moment';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const LineGraph = props => {
  const { selectedGates, selectedParameters, trafficDetails, type } = props;
  let selectedGatesArray = Array.from(selectedGates);

  const labels = trafficDetails?.dayWisePermitsCount?.map(el => {
    return moment(el?.date).format('Do MMM');
  });
  console.log('this rerenders...', selectedParameters);
  let permitsRaised = {
    label: 'Permits Raised',
    data: trafficDetails?.dayWisePermitsCount?.map(el => el.count),

    borderColor: '#15803d',
    backgroundColor: '#15803d70',
    borderWidth: 2,
    bezierCurve: true,
    pointRadius: 5,
  };

  let totalVehicles = {
    label: type === 'person' ? 'Total Persons' : 'Total Vehicles',

    data: type === 'person' ? trafficDetails?.dayWisePersonsCount?.map(el => el.count) : trafficDetails?.dayWiseVehiclesCount?.map(el => el.count),
    borderColor: '#0e7490',
    backgroundColor: '#0e749070',
    borderWidth: 2,
    pointRadius: 5,
  };
  let vehiclesWithPermits = {
    label: type === 'person' ? 'Persons with permits' : 'Vehicles With Permits',
    data: trafficDetails?.dayWiseValidPermitsCount?.map(el => el.count),
    borderColor: '#4338ca',
    backgroundColor: '#4338ca70',
    borderWidth: 2,
    pointRadius: 5,
  };

  let invalidEntries = {
    label: 'Invalid Entries',
    data: trafficDetails?.dayWiseInvalidPermitsCount?.map(el => el.count),
    borderColor: '#dc2626',
    backgroundColor: '#dc262670',
    borderWidth: 2,
    pointRadius: 5,
  };
  let datasets = [];
  if (selectedParameters.size !== 0) {
    if (selectedParameters.has('permitsRaised')) datasets.push(permitsRaised);
    if (selectedParameters.has('invalidEntries')) datasets.push(invalidEntries);
    if (type === 'person') {
      if (selectedParameters.has('totalPersons')) datasets.push(totalVehicles);
      if (selectedParameters.has('personsWithPermits'))
        datasets.push(vehiclesWithPermits);
    } else {
      if (selectedParameters.has('totalVehicles')) datasets.push(totalVehicles);
      if (selectedParameters.has('vehiclesWithPermits'))
        datasets.push(vehiclesWithPermits);
    }
  } else {
    datasets.push(
      permitsRaised,
      totalVehicles,
      vehiclesWithPermits,
      invalidEntries,
    );
  }

  const data = {
    labels,
    datasets: datasets,
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
  };

  return (
    <>
      <Line {...props} options={options} data={data} />
      <Flex direction="row" className={styles.gates}>
        {selectedGatesArray?.map(el => (
          <p key={`${el?.name} - ${el?.id}`} className={styles.gate}>
            {el?.name}
          </p>
        ))}
      </Flex>
    </>
  );
};

export default LineGraph;
