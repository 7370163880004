import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ViewRenderer from './views';
import styles from './styles.module.scss';
import POPUP_CONSTANTS from '../../../constants/popup-constants';

import Spinner from '../../../components/Spinner';

import Popup from '../../../components/SimplePopup';
import PopupContainer from '../../../components/PopupContainer';
import { API } from '../../../requests';
import {
  getSignatoryAgency,
  getSignatoryProfile,
  getSuperAdminAgency,
  getSuperAdminProfile,
} from './data';
import util from '../../../util';
import { commonImages, operatorImages } from '../../../assets/images';
import strings from '../../../globalization';
import moment from 'moment';
import { updateCompanyData, updateUserData } from '../../../store/actions/login';

const TAB_CONSTANTS = {
  PROFILE: 'profile',
  AGENCY: 'agency',
};
class Profile extends Component {
  constructor(props) {
    super(props);
    const { reduxProps } = this.props;
    const {
      login: { loginInfo },
    } = reduxProps;
    const { company } = loginInfo;
    const { user } = loginInfo;
    console.log('user...', user);
    console.log('company...', company);
    console.log(reduxProps);

    this.profileFormData = this.getProfileData();

    this.agencyFormData = this.getAgencyData();

    let preparedProfileObject = {};
    this.profileFormData.forEach(obj => {
      if (obj.inputType === 'upload') {
        preparedProfileObject[obj.stateField] = {
          value: [
            { fileName: user[obj.stateField], fileUrl: user[obj.stateField] },
          ],
          error: null,
        };
      } else {
        preparedProfileObject[obj.stateField] = {
          value: user[obj.stateField],
          error: null,
        };
      }

      if (obj.optionsField) {
        preparedProfileObject[obj.optionsField] = [];
      }
    });

    let preparedAgencyObject = {};
    this.agencyFormData.forEach(obj => {
      if (obj.inputType === 'upload') {
        preparedAgencyObject[obj.stateField] = {
          value: [
            {
              fileName: company.companyDocuments.filter(
                elem => elem.fileType === obj.stateField,
              )[0]?.fileName,
              fileUrl: company.companyDocuments.filter(
                elem => elem.fileType === obj.stateField,
              )[0]?.fileUrl,
            },
          ],
          error: null,
        };
      } else {
        preparedAgencyObject[obj.stateField] = {
          value: company[obj.stateField],
          error: null,
        };
      }
      if (obj.optionsField) {
        preparedAgencyObject[obj.optionsField] = [];
      }
    });

    this.state = {
      popup: null,
      activePage: TAB_CONSTANTS.PROFILE,
      user: user,
      company: company,
      role: null,
      profileState: preparedProfileObject,
      agencyState: preparedAgencyObject,
    };
  }

  getProfileData = () => {
    const { reduxProps } = this.props;
    const {
      login: { loginInfo },
    } = reduxProps;
    const { user } = loginInfo;

    switch (user.roleId) {
      case 2: {
        return getSignatoryProfile();
      }
      case 3: {
        return getSignatoryProfile();
      }
      case 8: {
        return getSuperAdminProfile();
      }
      default: {
        return getSignatoryProfile();
      }
    }
  };
  getAgencyData = () => {
    const { reduxProps } = this.props;
    const {
      login: { loginInfo },
    } = reduxProps;
    const { user } = loginInfo;

    switch (user.roleId) {
      case 2: {
        return getSignatoryAgency();
      }
      case 3: {
        return getSignatoryAgency();
      }
      case 8: {
        return getSuperAdminAgency();
      }
      default: {
        return getSignatoryAgency();
      }
    }
  };

  componentDidMount = () => {
    this.showSpinner();
    this.getData();
  };
  showSuccessPopup(successMessage) {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: successMessage,
        onClose: this.closePopup,
        headingImage: operatorImages.successIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  }
  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: { color: '#E67717' },
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  closePopup = () => {
    this.setState({
      popup: null,
    });
  };

  getData = async () => {
    this.showSpinner();
    const roleId = this.state.user.roleId;
    const { departmentId } = this.state.agencyState;

    const promises = [];

    promises.push(
      API.fetchAllRoles()
        .then(res => {
          const { data } = res;
          const role = data.find(role => role.roleId === roleId);
          console.log('ran successfully,,,,', role);

          this.setState({ role: role, popup: null });
        })
        .catch(err => {
          console.log(err);
        }),
    );

    promises.push(
      API.getAllDepartments().then(departmentsRes => {
        this.setState(prevState => ({
          agencyState: {
            ...prevState.agencyState,
            departmentOptions: departmentsRes.data,
          },
        }));
      }),
    );

    promises.push(
      API.getAllBusinesses().then(businessesRes => {
        this.setState(prevState => ({
          agencyState: {
            ...prevState.agencyState,
            businessOptions: businessesRes.data,
          },
        }));
      }),
    );

    Promise.all(promises)
      .then(() => {
        this.setState({
          popup: null,
        });
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        this.showErrorRetryPopup(errorMsg, this.getData);
      });
  };

  showErrorRetryPopup = (errorMsg, retryCallback) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMsg,
        messageStyle: { color: '#E67717' },

        buttons: [
          {
            text: strings.cancelPopup,
            onClick: this.closePopup,
            outline: true,
          },
          {
            text: strings.retryApiPopup,
            onClick: () => {
              this.closePopup();
              retryCallback();
            },
          },
        ],
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
      },
    });
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  getPopupContent = () => {
    const { popup } = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }

      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };

  onClickHandler = (action, data) => {
    switch (action) {
      case 'button-click': {
        this.setState({
          activePage: data,
        });
        break;
      }
      case 'update-profile': {
        this.updateUserProfile();
        break;
      }
      case 'update-agency': {
        this.updateAgency();
        break;
      }
      default: {
        console.warn('case not handled...', action);
      }
    }
  };

  updateUserProfile = () => {
    this.showSpinner();
    const { profileState, user } = this.state;
    console.log('PROFILE STATE...', profileState, user);
    const { dispatchUpdateUserData } = this.props;

    let error = this.handleProfileErrors();
    if (!error) {
      let formData = {
        name: profileState.firstName.value,
        phoneNumber: profileState.phoneNumber.value,
        emailAddress: profileState.emailAddress.value,
        roleId: user.roleId,
        designation: user.designation,
        authorizedDocument: user.authorizedDocument,
        //     documentValidity: profileState.documentValidity.value,
        // idProofDocument: profileState.idProofDocument.value[0].fileUrl,
      };

      API.editUserProfile(formData)
        .then(res => {
          dispatchUpdateUserData({ firstName: profileState.firstName.value });
          this.showSuccessPopup('Details updated successfully!');
        })
        .catch(err => {
          const errorMsg = util.getErrorMessage(err);
          this.showErrorPopup(errorMsg);
        });
    } else {
      this.setState({
        popup: null,
      });
    }
  };
  updateAgency = () => {
    this.showSpinner();
    const { agencyState } = this.state;
    console.log('agencyState STATE...', agencyState);
    const { dispatchUpdateCompanyData } = this.props;

    let error = this.handleAgencyErrors();

    if (!error) {
      let formData = {
        name: agencyState.name.value,
        departmentId: agencyState.departmentId.value,
        phoneNumber: agencyState.phoneNumber.value,
        emailAddress: agencyState.emailAddress.value,
        businessId: agencyState.businessId.value,
        address1: agencyState.address1.value,
        address2: agencyState.address2.value,
      };

      API.updateCompanyDetails(formData)
        .then(res => {
          dispatchUpdateCompanyData({ name: agencyState.name.value });
          this.showSuccessPopup('Details updated successfully!');
        })
        .catch(err => {
          const errorMsg = util.getErrorMessage(err);
          this.showErrorPopup(errorMsg);
        });
    } else {
      this.setState({
        popup: null,
      });
    }
  };
  handleProfileErrors = () => {
    let err = false;
    const update = {};

    for (let i = 0; i < this.profileFormData.length; i++) {
      let element = this.profileFormData[i];

      let error = util.validate(
        this.state.profileState[element.stateField].value,
        element,
      );

      if (error) {
        err = true;
        update[element.stateField] = {
          ...this.state.profileState[element.stateField],
          error: error,
        };
      } else {
        update[element.stateField] = {
          ...this.state.profileState[element.stateField],
          error: null,
        };
      }
    }
    console.log('update...', update);
    this.setState({
      profileState: {
        ...update,
      },
    });

    return err;
  };
  handleAgencyErrors = () => {
    let err = false;
    const update = {};

    for (let i = 0; i < this.agencyFormData.length; i++) {
      let element = this.agencyFormData[i];

      let error = util.validate(
        this.state.agencyState[element.stateField].value,
        element,
      );

      if (error) {
        err = true;
        update[element.stateField] = {
          ...this.state.agencyState[element.stateField],
          error: error,
        };
      } else {
        update[element.stateField] = {
          ...this.state.agencyState[element.stateField],
          error: null,
        };
      }
    }
    this.setState({
      agencyState: {
        ...update,
      },
    });
    return err;
  };

  onChangeHandler = (stateName, field, value) => {
    const { profileState, agencyState } = this.state;

    if (stateName === 'profile') {
      this.setState({
        profileState: {
          ...profileState,
          [field]: {
            value,
            error: null,
          },
        },
      });
    } else {
      this.setState({
        agencyState: {
          ...agencyState,
          [field]: {
            value,
            error: null,
          },
        },
      });
    }
    console.log('field', field);
    console.log('value', value);
    this.setState(
      {
        [field]: {
          value,
          error: null,
        },
      },
      function () {
        console.log('this.state...', this.state);
      },
    );
  };

  getProps = () => {
    const { activePage, user, company, role, profileState, agencyState } =
      this.state;
    return {
      activePage,
      onClick: this.onClickHandler,
      onChange: this.onChangeHandler,
      profileFormData: this.profileFormData,
      agencyFormData: this.agencyFormData,
      profileState,
      agencyState,
      user,
      company,
      role,
    };
  };
  render() {
    const viewProps = this.getProps();
    const { popup } = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer containerStyle={styles.popupContainerStyle}>
            {' '}
            {this.getPopupContent()}{' '}
          </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

const mapStateToProps = reduxProps => ({
  reduxProps,
});

const mapDispatchToProps = dispatch => ({
  dispatchUpdateUserData: payload => {
    dispatch(updateUserData(payload));
  },
  dispatchUpdateCompanyData: payload => {
    dispatch(updateCompanyData(payload));
  },
});

// export default withRouter(PersonCards);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Profile),
);
