import React from 'react';
import Mobile from './Mobile';
import Desktop from './Desktop';
import {useMediaQuery} from 'react-responsive';

/**
 * This component is to render precall screen in different platforms
 * @param {viewRenderProps} props
 * @return {JSX.Element}
 */
function ViewRenderer(props) {
  const isMobile = useMediaQuery({maxWidth: 1080});
  if (isMobile) {
    return <Mobile {...props} />;
  } else {
    return <Desktop {...props} />;
  }
}

export default ViewRenderer;
