import React, { useEffect, useState } from 'react';
import { withRouter, useRouteMatch, Link } from 'react-router-dom';
import styles from './SideBar.module.scss';
import { loginImages, adminImages } from '../../assets/images';
import Flex from '../Flex';

import rightIcon from '../../assets/images/right-arrow-white/image.png';
import rightIconBlue from '../../assets/images/right-arrow-blue/image.png';
import operatorLogo from '../../assets/images/operator/logo/image.png';
import eDrishtiLogo from '../../assets/images/edrishti-logo/image@3x.png';
import { dispatchSetLogoutData } from '../../store';
import { Auth } from 'aws-amplify';
import InactivityDetector from '../InactivityDetector';
import { API } from '../../requests';
// import API from '../../network';
import PopUp from '../SimplePopup';
import PopupContainer from '../PopupContainer';

/**
 * Used to render side bar
 * @param {*} props
 * @return {JSX.Element}
 */
function SideBar(props) {
  const { tabs, header, history } = props;
  const [isPermtTab, setIsPermtTab] = useState(true);
  let updatedTabs = tabs;
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [isErrorOccurred, setIsErrorOccurred] = useState(false);
  const closeErrorPopup = () => {
    handleLogout();
  }

  const popup = {
    type: 'popup',
    message: 'Your session has expired. Please log in again to continue.',
    buttons: [
      {
        text: 'Okay',
        onClick: closeErrorPopup,
      },
    ],
    onClose: closeErrorPopup,
    messageStyle: {
      color: '#202428',
      fontWeight: 'normal',
    },
  };


  useEffect(() => {
    console.log('in use Effect');
    const handleApiError = error => {
      const { history } = {};
      // Extract status code from the error object
      const statusCode = error.response ? error.response.status : null;
      console.log(error);
      console.log('statusCode:...', statusCode);
      // Handle different types of API errors
      if (statusCode === 403) {
        // Forbidden error - redirect to forbidden page

        setIsSessionExpired(true);
        return;
        // history.push('/forbidden');
      } else if (statusCode === 404) {
        // Not found error - redirect to 404 page
        // history.push('/404');
      } else {
        // Redirect to a generic error page for other errors
        // history.push('/error');
      }
    };

    // Add interceptors for global error handling
    const responseInterceptor = API.axios.interceptors.response.use(
      response => response,
      error => {
        // Handle API errors
        handleApiError(error);

        return Promise.reject(error);
      }
    );



    // Remove interceptors to prevent memory leaks
    return () => {
      API.axios.interceptors.response.eject(responseInterceptor);
    };
  }, [isSessionExpired]);




  const handleLogout = async () => {
    await Auth.signOut()
      .then(() => {
        dispatchSetLogoutData();
      })
      .catch(error => {
        console.log('error Auth.signout()', error);
      });
    API.logout()
      .then(response => {
        console.info('logout success', response);
        history.push('/u');
      })
      .catch(error => {
        console.error('logout error', error, error.request);
      });
  };
  const openTab = index => {
    console.log(updatedTabs[index], index);
    updatedTabs[index].isOpen = !updatedTabs[index]?.isOpen;
    setIsPermtTab(!isPermtTab);
  };
  return (<>
    {isSessionExpired ? <div style={{ zIndex: 99999 }}> <PopupContainer containerStyle={styles.zIndex9999}><PopUp containerClassName={styles.popUpStyle} {...popup} /></PopupContainer></div> : null}
    <Flex className={styles.col1DisplayItems}>
      <Flex className={styles.topContainer}>
        <Flex direction="row" className={styles.logoInfo}>
          <Flex direction="column" style={{ justifyContent: 'center' }}>
            <img
              src={eDrishtiLogo}
              className={styles.edrishtiLogo}
              alt="logo"
            />
            {/* <span className={styles.rowLabel}>e-Drishti</span> */}
          </Flex>
          <img
            src={loginImages.newDptLogo}
            className={styles.logoIcon}
            alt="logo"
          />
        </Flex>
        {/* <Flex className={styles.dashboard}>
          <img
            src={adminImages.dashboardIcon}
            className={styles.dashboardIcon}
            alt="logo"
          />
          <span className={styles.dashboardLabel}>Dashboard</span>
        </Flex> */}
        {updatedTabs.map((item, index) => {
          return (
            <div style={{ width: '100%' }} key={'tab-' + item.keyName}>
              {item.children ? (
                <div>
                  <Flex
                    style={
                      item?.isOpen
                        ? {
                          backgroundColor: '#002c94',
                          borderRadius: '5px',
                          color: 'white',
                        }
                        : null
                    }
                    className={styles.currentModule}
                    onClick={() => openTab(index)}>
                    <img
                      src={item?.isOpen ? item.whiteIcon : item.icon}
                      className={styles.logo}
                      alt="U"
                    />
                    <div
                      style={
                        item?.isOpen
                          ? {
                            color: 'white',
                          }
                          : null
                      }
                      className={styles.currentModuleText}>
                      {item.keyName}
                    </div>
                    <img
                      src={item?.isOpen ? rightIcon : rightIconBlue}
                      className={[styles.rightIcon, styles.rotateIcon].join(
                        ' ',
                      )}
                      alt="U"
                    />
                  </Flex>
                  {item?.isOpen ? (
                    <div className={styles.centerAlign}>
                      {item.children &&
                        item.children.map(item => {
                          return (
                            <CustomLink
                              isChild={true}
                              item={item}
                              key={item.keyName}
                            />
                          );
                        })}
                    </div>
                  ) : null}
                </div>
              ) : (
                <div style={{ width: '100%' }}>
                  <CustomLink isChild={false} item={item} key={item.keyName} />
                </div>
              )}
            </div>
          );
        })}
      </Flex>
      <button className={styles.bottomButton} onClick={handleLogout}>
        <div className={styles.renderCol1Text}>Logout</div>
      </button>
      <InactivityDetector onInactive={handleLogout} />
    </Flex>
  </>
  );
}

/**
 *
 * @param {*} param0
 * @return {JSX.Element}
 */
function CustomLink({ item, isChild }) {
  const { url } = useRouteMatch();
  // FIXME: Improve the logic.
  let path = url.replace('/u/', '/u');
  if (item?.name === 'gps') {
    path = url.replace('/u', '');
  }
  const URI = item ? `${path}/${item.route}` : `${path}`;
  console.log(URI, path);
  const match = useRouteMatch({
    path: URI,
    exact: false,
  });


  return (
    <Link to={URI} target={item?.name === 'gps' ? '_blank' : ''} className={styles.link}>
      {isChild ? (
        <Flex
          className={styles.imageParent}
          style={
            match
              ? {
                backgroundColor: '#5F8FFF',
                borderRadius: '5px',
                color: 'white',
              }
              : null
          }>
          <div
            className={styles.renderCol1Text}
            style={match ? { color: '#FFFF' } : null}>
            {item.keyName}
          </div>
          <img
            src={match ? rightIcon : rightIconBlue}
            className={styles.rightIcon}
            alt="U"
          />
        </Flex>
      ) : (
        <Flex
          className={styles.currentParent}
          style={
            match ? { backgroundColor: '#002c94', borderRadius: '5px' } : null
          }>
          {item.whiteIcon && (
            <img
              src={match ? item.whiteIcon : item.icon}
              className={styles.logo}
              alt="U"
            />
          )}
          <div
            className={styles.currentModuleText}
            style={match ? { color: '#FFFF' } : null}>
            {item.keyName}
          </div>
        </Flex>
      )}
    </Link>
  );
}

export default withRouter(SideBar);
