import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import Spinner from 'react-spinkit';
import Popup from '../../../../components/SimplePopup';

import styles from './styles.module.scss';

import ViewRenderer from './views';
import {commonImages, operatorImages} from '../../../../assets/images';
import strings from '../../../../globalization';
import PopupContainer from '../../../../components/PopupContainer';
import {API} from '../../../../requests';
import moment from 'moment';
import {connect} from 'react-redux';

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  SPINNER: 'spinner',
  ERROR: 'error',
  SIMPLE_POPUP: 'simple-popup',
  ADD_DAYS_POPUP: 'add-days-popup',
  PERMITS_POPUP: 'permits-popup',
};

class AgencyView extends Component {
  constructor(props) {
    super(props);

    const reduxProps = this.props.reduxProps;
    console.log('reduxProps--> Request New Permit', reduxProps);
    const {
      login: {loginInfo},
    } = reduxProps;
    const {company, user} = loginInfo;

    this.state = {
      popup: null,
      success: false,
      serverTime: null,
      companyId: company?.id,
      requestTypeOptions: [],
      totalCount: 0,
    };
  }

  componentDidMount = () => {
    this.reset();

    this.fetchAllCompaniesForDepartment();
  };

  fetchAllCompaniesForDepartment = () => {
    this.showSpinner();
    API.fetchAllCompaniesForDepartment()
      .then(res => {
        console.log('fetchAllCompaniesForDepartment', res.data);
        const companyList = res.data.map(el => {
          const temp = {
            name: el.companyName,
            id: el.referenceId,
            referenceId: el.id,
          };
          return temp;
        });
        this.setState({
          requestTypeOptions: companyList,
          popup: null,
        });
      })
      .catch(error => console.log(error));
  };

  onSubmit = () => {
    const {referenceId} = this.state;
    console.log(this.state);
    this.showSpinner();

    if (!referenceId) {
      this.showErrorPopup('Please enter agency name or reference Id');
      return;
    }
    this.fetchCompanyDetailsForDepartmentByAgencyId(referenceId);
  };

  fetchCompanyDetailsForDepartmentByAgencyId = referenceId => {
    if (!referenceId) {
      this.showErrorPopup('Please enter reference number or agency name');
      return;
    }
    this.showSpinner();
    this.setState({
      agencyData: null,
    });
    API.fetchCompanyDetailsForDepartmentByAgencyId(referenceId)
      .then(res => {
        console.log('res.data', res.data);
        this.setState({
          agencyData: res.data,
          popup: null,
        });
        // this.getVehicleLatestData();
      })
      .catch(error => {
        console.log('error', error);
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;

        this.setState({
          popup: null,
          agencyData: null,
        });
        this.showErrorPopup(
          errorMsg ||
            'There was an error while fetching the agency details, please try again after some time.',
        );

        console.log(errorMsg);
      });
  };

  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      default: {
        console.warn('case not handled', popup.type);
        return null;
      }
    }
  };

  showConfirmationPopup = (message, onConfirm, data) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: message,
        messageStyle: {color: '#E67717'},
        // headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.cancelPopup,
            onClick: this.closePopup,
            outline: true,
          },
          {
            text: strings.confirm,
            onClick: onConfirm
              ? () => {
                  onConfirm(data);
                  this.setState({
                    popup: null,
                  });
                }
              : this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  onChangeHandler = (field, value, obj) => {
    console.log(field, value, obj);
    switch (field) {
      case 'agencySearch':
        return this.setState({
          agencyData: null,
          referenceId: value,
        });

      case 'gridAPI': {
        console.log('grid API', value);
        this.setState({gridApi: value});
        this.onChangeHandler('updateTable');
        break;
      }
      case 'updateTable': {
        const {gridApi, selectedStatus, agencyData} = this.state;
        const {users} = agencyData || [];
        const perPage = 100;
        if (gridApi) {
          const dataSource = {
            getRows: params => {
              const page = params.endRow / perPage;
              console.log(page, params, selectedStatus);
              // this.getData(params, page - 1, perPage);
              params.successCallback(users, users?.length);
              this.setState({
                popup: undefined,
                totalCount: users?.length,
              });
            },
          };
          gridApi.setDatasource(dataSource);
        }
        break;
      }
      default:
        return this.setState({
          [field]: value,
        });
    }
  };

  showTwoButtonsPopup = (errorMessage, onFirstClick, onSecondClick) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: `Cancel`,
            onClick: onFirstClick ? onFirstClick : this.closePopup,
            outline: true,
            style: {fontSize: '0.8rem'},
          },
          {
            text: `Recharge`,
            onClick: onSecondClick ? onSecondClick : this.closePopup,
            outline: true,
            style: {
              backgroundColor: '#0045e6',
              color: '#ffffff',
              fontSize: '0.8rem',
            },
          },
        ],
      },
    });
  };

  blackListAgency = data => {
    console.log(data);
    this.showSpinner();
    if (!data?.remarks) {
      this.showErrorPopup('Please add the reason before blacklist the agency');
      return;
    }
    if (!data?.id) {
      this.showErrorPopup(
        'There is an error while blacklisting the agency, pleas try again after some time',
      );
      return;
    }

    API.blackListAgency(data)
      .then(res => {
        console.log('res.data', res.data);
        this.setState({
          popup: null,
        });
        this.showSuccessPopup(
          'You have blacklisted the agency successfully.',
          () => {
            this.onSubmit();
          },
        );
      })
      .catch(error => {
        console.log('error', error);
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;

        this.setState({
          popup: null,
          personLatestActivity: null,
        });
        this.showErrorPopup(
          errorMsg ||
            'There was an error while blacklisting the agency, please try again after some time.',
        );

        console.log(errorMsg);
      });
  };

  unBlackListAgency = data => {
    console.log(data);
    // if (!data?.remarks) {
    //   this.showErrorPopup('Please add the reason before blacklist the vehicle');
    //   return;
    // }
    this.showSpinner();
    if (!data) {
      this.showErrorPopup(
        'There is an error while removing blacklist for the agency, pleas try again after some time',
      );
      return;
    }
    const inputObject = {id: data, remarks: ''};

    API.unBlackListAgency(inputObject)
      .then(res => {
        console.log('res.data', res.data);
        this.setState({
          popup: null,
        });
        this.showSuccessPopup(
          'You have removed the agency from blacklisting successfully.',
          () => {
            this.onSubmit();
          },
        );
      })
      .catch(error => {
        console.log('error', error);
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;

        this.setState({
          popup: null,
          personLatestActivity: null,
        });
        this.showErrorPopup(
          errorMsg ||
            'There was an error while removing from the blacklist, please try again after some time.',
        );

        console.log(errorMsg);
      });
  };

  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  showSuccessPopup = (message, onClick) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: message,
        headingImage: operatorImages.successIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: onClick ? onClick : this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  resetState = () => {
    this.setState({
      popup: null,
    });
  };
  onClickHandler = (action, data) => {
    const {history, location} = this.props;
    switch (action) {
      case 'clear': {
        console.log('clicked on clear');
        this.reset();
        break;
      }

      case 'blackList': {
        this.blackListAgency(data);
        break;
      }
      case 'unBlackList': {
        this.unBlackListAgency(data);
        break;
      }

      case 'statusRoute': {
        //FIXME path
        history.push({
          pathname: '/u/view/' + data.route,
        });
        break;
      }

      case 'document-view': {
        console.log('in document view redirection case:', data);
        history.push({
          pathname: location.pathname + '/document-view',
          state: {background: location, url: data},
        });
        // this.getDocumentTempURL(data);

        break;
      }
      default: {
        console.warn('no action taken');
      }
    }
  };

  reset = () => {
    this.resetState();
  };
  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  closePopup = () => {
    this.setState({
      popup: null,
    });
  };

  getProps = () => {
    const {reduxProps} = this.props;
    const {permits} = reduxProps;
    const {serverTime, requestTypeOptions} = this.state;
    return {
      stateData: this.state,
      onChangeHandler: this.onChangeHandler,
      onClick: this.onClickHandler,
      serverTime,
      onSubmit: this.onSubmit,
      requestTypeOptions: requestTypeOptions,
    };
  };

  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer containerStyle={styles.popupContainerStyle}>
            {' '}
            {this.getPopupContent()}{' '}
          </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}
// export default withRouter(PersonView);
const mapStateToProps = reduxProps => ({
  reduxProps,
});

const mapDispatchToProps = dispatch => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AgencyView),
);
