import React from 'react';
import rejectIcon from '../../assets/images/reject-icon-cross/image.png';

import styles from './styles.module.scss';
import strings from '../../globalization';
import Button from '../Button';
import Flex from '../Flex';

export const PaymentFailed = ({
  entity,
  returnedObject,
  onClick,
  handleLoginClick,
  getTextContent,
  containerClassNames,
}) => {
  console.log('entity', entity);
  console.log('returnedObject', returnedObject);

  return (
    <Flex
      direction="column"
      className={[styles.container, containerClassNames].join(' ')}>
      <img src={rejectIcon} alt="X" className={styles.image} />
      <p className={styles.result}>
        {entity} {'Payment Failed'} - {getTextContent(entity, returnedObject)}
      </p>
      <p>
        {returnedObject.note && `${strings.reason}: ${returnedObject.note}`}
      </p>
    </Flex>
  );
};

export default PaymentFailed;
