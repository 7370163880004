import strings from '../../../globalization';

export const getPopupFormData = () => {
  return [
    {
      elementType: 'text',
      inputType: 'alphabets',
      label: strings.vehicleOwnerName,
      placeholder: strings.enterVehicleOwnerName,
      stateField: 'name',
      id: 'name',
      stateDefault: '',
      minLength: 5,
      maxLength: 128,
      disabled: true,
    },

    {
      elementType: 'text',
      inputType: 'text',
      label: strings.vehicleNumber,
      placeholder: strings.vehicleNumber,
      stateField: 'vehicleRegistrationNumber',
      id: 'vehicleRegistrationNumber',
      stateDefault: '',
      minLength: 5,
      maxLength: 128,
      disabled: true,
    },
    {
      elementType: 'text',
      inputType: 'text',
      label: strings.rfidCardNumber,
      placeholder: strings.rfidCardNumber,
      stateField: 'RFIDNumber',
      id: 'RFIDNumber',
      stateDefault: '',
      minLength: 5,
      maxLength: 128,
      disabled: true,
    },
  ];
};
