import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import util from '../../../../util';

import Spinner from 'react-spinkit';
import Popup from '../../../../components/SimplePopup';

import styles from './styles.module.scss';

import ViewRenderer from './views';
import {commonImages, operatorImages} from '../../../../assets/images';
import strings from '../../../../globalization';
import PopupContainer from '../../../../components/PopupContainer';
import {API} from '../../../../requests';
import moment from 'moment';
import {connect} from 'react-redux';

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  SPINNER: 'spinner',
  ERROR: 'error',
  SIMPLE_POPUP: 'simple-popup',
  ADD_DAYS_POPUP: 'add-days-popup',
  PERMITS_POPUP: 'permits-popup',
};

class VehicleView extends Component {
  constructor(props) {
    super(props);

    const reduxProps = this.props.reduxProps;
    console.log('reduxProps--> Request New Permit', reduxProps);
    const {
      login: {loginInfo},
    } = reduxProps;
    const {company, user} = loginInfo;

    this.state = {
      popup: null,
      passDate: '',
      success: false,
      serverTime: null,
      companyId: company?.id,
      vehicleNumber: null,
      RFIDNumber: null,
      requestTypeOptions: [
        {
          name: 'Vehicle RFID',
          id: 1,
        },
        {
          name: 'Vehicle Number',
          id: 2,
        },
      ],
      vehicleActivityList: [],
      startDate: moment().subtract(1, 'year'),
      startTime: moment().startOf('day'),
      endDate: moment().add(1, 'day'),
      endTime: moment().endOf('day'),
      dates: {
        start: moment().subtract(1, 'year').format('MM-DD-YYYY'),
        end: moment().add(1, 'day').format('MM-DD-YYYY'),
      },
      totalCount: 0,
    };
  }

  componentDidMount = () => {
    this.reset();
    this.getServerTime();
  };

  getServerTime = () => {
    API.getServerTime()
      .then(res => {
        console.log('getServerTime res.data', res.data);
        const date = new Date(res.data.timestamp);
        const showPerviousDate = date.getHours() < 8;
        this.setState({
          serverTime: showPerviousDate
            ? moment(date).subtract(1, 'day').toDate()
            : date,
        });
      })
      .catch(error => console.log(error));
  };

  onSubmit = () => {
    const {requestType, RFIDNumber, vehicleNumber} = this.state;
    console.log(this.state);
    this.showSpinner();

    if (!requestType) {
      this.showErrorPopup(strings.pleaseSelectRequestType);
      return;
    } else if (requestType === 1 && !RFIDNumber) {
      this.showErrorPopup('Please enter RFID Number');
      return;
    } else if (requestType === 2 && !vehicleNumber) {
      this.showErrorPopup('Please enter vehicle number');
      return;
    }
    if (requestType === 1) {
      this.getVehicleDataByRFID(RFIDNumber);
    } else if (requestType === 2) {
      this.getVehicleDataByVehicleNumber(vehicleNumber);
    }
  };

  getVehicleDataByVehicleNumber = vehicleNumber => {
    if (!vehicleNumber) {
      this.showErrorPopup('Please enter Vehicle Number');
      return;
    }
    this.showSpinner();
    this.setState({
      vehicleData: null,
    });
    API.fetchVehicleByVehicleRegistrationNumber(vehicleNumber)
      .then(res => {
        console.log('res.data', res.data);
        this.setState({
          vehicleData: res.data,
          popup: null,
        });
        this.getVehicleLatestData();
      })
      .catch(error => {
        console.log('error', error);
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;

        this.setState({
          popup: null,
          vehicleData: null,
        });
        this.showErrorPopup(
          errorMsg ||
            'There was an error while fetching the vehicle Details, please try again after some time.',
        );

        console.log(errorMsg);
      });
  };

  getVehicleDataByRFID = RFIDNumber => {
    if (!RFIDNumber) {
      this.showErrorPopup('Please enter RFID Number');
      return;
    }
    this.showSpinner();
    this.setState({
      vehicleData: null,
    });
    API.fetchVehicleByRFID(RFIDNumber)
      .then(res => {
        console.log('res.data', res.data);
        this.setState({
          vehicleData: res.data,
          popup: null,
        });
        this.getVehicleLatestData();
      })
      .catch(error => {
        console.log('error', error);
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;

        this.setState({
          popup: null,
          vehicleData: null,
        });
        this.showErrorPopup(
          errorMsg ||
            'There was an error while fetching the vehicle Details, please try again after some time.',
        );

        console.log(errorMsg);
      });
  };

  getVehicleLatestData = () => {
    const {vehicleData} = this.state;
    const {status} = vehicleData;
    const {RFIDNumber} = vehicleData;
    console.log(vehicleData);
    if (!RFIDNumber) {
      console.log('RFID Number not available for this vehicle', vehicleData);
      // this.showErrorPopup('RFID Number not available for this vehicle');
      return;
    }
    this.showSpinner();
    this.setState({
      vehicleLatestActivity: null,
    });
    API.fetchVehicleLatestActivity({truckRFID: RFIDNumber})
      .then(res => {
        console.log('res.data', res.data);
        this.setState({
          vehicleLatestActivity: res.data,
          popup: null,
        });
      })
      .catch(error => {
        console.log('error', error);
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;

        this.setState({
          popup: null,
          vehicleLatestActivity: null,
        });
        this.showErrorPopup(
          errorMsg ||
            'There was an error while fetching the vehicle latest activity details, please try again after some time.',
        );

        console.log(errorMsg);
      });
  };

  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      default: {
        console.warn('case not handled', popup.type);
        return null;
      }
    }
  };

  showConfirmationPopup = (message, onConfirm, data) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: message,
        messageStyle: {color: '#E67717'},
        // headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.cancelPopup,
            onClick: this.closePopup,
            outline: true,
          },
          {
            text: strings.confirm,
            onClick: onConfirm
              ? () => {
                  onConfirm(data);
                  this.setState({
                    popup: null,
                  });
                }
              : this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  getData = async (paramsAPI, page, perPage) => {
    this.showSpinner();
    const {startDate, endDate, startTime, endTime, vehicleData} = this.state;
    const {RFIDNumber} = vehicleData;
    if (!startTime || !endTime || !startDate || !endDate) {
      this.showErrorPopup(`Date and Time parameters cannot be empty!`);
      return;
    }

    if (!RFIDNumber) {
      this.showErrorPopup(`RFID Not available for this vehicle!`);
      return;
    }

    let params = {
      startDate: `${moment(startDate).format('DD-MM-YYYY')} ${moment(
        startTime,
      ).format('HH:mm')}`,
      endDate: `${moment(endDate).format('DD-MM-YYYY')} ${moment(
        endTime,
      ).format('HH:mm')}`,
      pageNumber: page,
      pageSize: perPage,
      truckRFID: RFIDNumber,
    };
    console.log('params...', params);
    API.fetchVehicleActivityDetailsList(params)
      .then(res => {
        console.log('response:', res, perPage);
        paramsAPI.successCallback(
          res.data?.vehicleHistory,
          res.data?.totalCount,
        );

        this.setState({
          vehicleActivityList: res.data?.vehicleHistory,
          popup: undefined,
          totalCount: res.data?.totalCount,
        });
      })
      .catch(err => {
        console.log('err', err);
        console.log(err);
        paramsAPI.successCallback([], 0);
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };

  onChangeHandler = (field, value) => {
    console.log(field, value);
    switch (field) {
      case 'requestType':
        return this.setState({
          vehicleData: null,
          RFIDNumber: '',
          vehicleNumber: '',
          [field]: value,
        });

      case 'startTime': {
        this.setState({
          startTime: value,
        });
        break;
      }
      case 'endTime': {
        this.setState({
          endTime: value,
        });
        break;
      }

      case 'date': {
        const {startDate, endDate, dates} = value;
        this.setState({
          startDate,
          endDate,
          dates,
        });
        break;
      }

      case 'gridAPI': {
        console.log('grid API', value);
        this.setState({gridApi: value});
        this.onChangeHandler('updateTable');
        break;
      }
      case 'updateTable': {
        const {gridApi, selectedStatus} = this.state;
        const perPage = 100;
        if (gridApi) {
          const dataSource = {
            getRows: params => {
              const page = params.endRow / perPage;
              console.log(page, params, selectedStatus);
              this.getData(params, page - 1, perPage);
            },
          };
          gridApi.setDatasource(dataSource);
        }
        break;
      }
      default:
        return this.setState({
          [field]: value,
        });
    }
  };

  showTwoButtonsPopup = (errorMessage, onFirstClick, onSecondClick) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: `Cancel`,
            onClick: onFirstClick ? onFirstClick : this.closePopup,
            outline: true,
            style: {fontSize: '0.8rem'},
          },
          {
            text: `Recharge`,
            onClick: onSecondClick ? onSecondClick : this.closePopup,
            outline: true,
            style: {
              backgroundColor: '#0045e6',
              color: '#ffffff',
              fontSize: '0.8rem',
            },
          },
        ],
      },
    });
  };

  blackListVehicle = data => {
    console.log(data);
    this.showSpinner();
    const {RFIDNumber} = this.state;
    if (!data?.remarks) {
      this.showErrorPopup('Please add the reason before blacklist the vehicle');
      return;
    }
    if (!data?.id) {
      this.showErrorPopup(
        'There is an error while blacklisting the vehicle tag, pleas try again after some time',
      );
      return;
    }

    API.blackListVehicleTag(data)
      .then(res => {
        console.log('res.data', res.data, RFIDNumber);
        this.setState({
          popup: null,
        });
        this.showSuccessPopup(
          'You have blacklisted the vehicle tag successfully.',
          () => {
            this.onSubmit();
          },
        );
      })
      .catch(error => {
        console.log('error', error);
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;

        this.setState({
          popup: null,
          personLatestActivity: null,
        });
        this.showErrorPopup(
          errorMsg ||
            'There was an error while blacklisting the vehicle tag, please try again after some time.',
        );

        console.log(errorMsg);
      });
  };

  unBlackListVehicle = data => {
    console.log(data);
    // if (!data?.remarks) {
    //   this.showErrorPopup('Please add the reason before blacklist the vehicle');
    //   return;
    // }
    this.showSpinner();
    if (!data) {
      this.showErrorPopup(
        'There is an error while removing blacklist for the vehicle tag, pleas try again after some time',
      );
      return;
    }
    const inputObject = {id: data, remarks: ''};

    API.unBlackListVehicleTag(inputObject)
      .then(res => {
        console.log('res.data', res.data);
        this.setState({
          popup: null,
        });
        this.showSuccessPopup(
          'You have removed the vehicle tag from blacklisting successfully.',
          () => {
            this.onSubmit();
          },
        );
      })
      .catch(error => {
        console.log('error', error);
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;

        this.setState({
          popup: null,
          personLatestActivity: null,
        });
        this.showErrorPopup(
          errorMsg ||
            'There was an error while removing from the blacklist, please try again after some time.',
        );

        console.log(errorMsg);
      });
  };

  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  showSuccessPopup = (message, onClick) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: message,
        headingImage: operatorImages.successIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: onClick ? onClick : this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  resetState = () => {
    this.setState({
      popup: null,
    });
  };
  onClickHandler = (action, data) => {
    const {history, location} = this.props;
    switch (action) {
      case 'clear': {
        console.log('clicked on clear');
        this.reset();
        break;
      }

      case 'apply': {
        this.onChangeHandler('updateTable');
        break;
      }
      case 'blackList': {
        this.blackListVehicle(data);
        break;
      }
      case 'unBlackList': {
        this.unBlackListVehicle(data);
        break;
      }
      case 'clearFilters': {
        this.resetData();
        break;
      }

      case 'statusRoute': {
        //FIXME path
        history.push({
          pathname: '/u/view/' + data.route,
        });
        break;
      }

      case 'document-view': {
        console.log('in document view redirection case:', data);
        history.push({
          pathname: location.pathname + '/document-view',
          state: {background: location, url: data},
        });
        // this.getDocumentTempURL(data);

        break;
      }
      default: {
        console.warn('no action taken');
      }
    }
  };

  resetData = () => {
    this.setState(
      {
        startDate: moment().subtract(1, 'year'),
        startTime: moment().startOf('day'),
        endDate: moment().add(1, 'day'),
        endTime: moment().endOf('day'),
        dates: {
          start: moment().subtract(1, 'year').format('MM-DD-YYYY'),
          end: moment().add(1, 'day').format('MM-DD-YYYY'),
        },
      },
      this.onChangeHandler('updateTable'),
    );
  };

  reset = () => {
    this.resetState();
  };
  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  closePopup = () => {
    this.setState({
      popup: null,
    });
  };

  getProps = () => {
    const {reduxProps} = this.props;
    const {permits} = reduxProps;
    const {serverTime, requestTypeOptions} = this.state;
    return {
      stateData: this.state,
      onChangeHandler: this.onChangeHandler,
      onClick: this.onClickHandler,
      serverTime,
      onSubmit: this.onSubmit,
      requestTypeOptions: requestTypeOptions,
    };
  };

  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer containerStyle={styles.popupContainerStyle}>
            {' '}
            {this.getPopupContent()}{' '}
          </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}
// export default withRouter(PersonView);
const mapStateToProps = reduxProps => ({
  reduxProps,
});

const mapDispatchToProps = dispatch => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VehicleView),
);
