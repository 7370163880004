import React from 'react';
import {useRouteMatch, Switch, Route} from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';
import styles from './styles.module.css';
import PublicRoute from '../components/PublicRoute';
import SignIn from '../pages/Auth/Login';
//import App from "../App";
import NewAgencyRegistration from '../pages/AccountCreationFlows/NewAgencyRegistration';
import '../App.scss';
import PersonRegistration from '../pages/AccountCreationFlows/PersonRegistration';
import VehicleRegistration from '../pages/AccountCreationFlows/VehicleRegistration';
import RegistrationSuccess from '../pages/AccountCreationFlows/RegistrationSuccess';
import Download from '../pages/Download';
import ResetPassword from '../pages/Auth/ResetPassword';
import ChangePassword from '../pages/Auth/ChangePassword';
import CheckApplicationStatus from '../pages/AccountCreationFlows/CheckApplicationStatus';
import GetReferenceNumber from '../pages/AccountCreationFlows/GetReferenceNumber';
import TableTesting from '../pages/TableTesting';
import AuthLayout from '../components/AuthLayout';
import Payments from '../pages/Payments';
import Sanna from '../pages/Stealth';
import ManageRegistrationInformation from '../pages/ManageRegistrationInformation';
import CheckPermitStatus from '../pages/CheckPermitStatus';
import UploadPoliceNOC from '../pages/PoliceNOCUpload';

import PermitPass from '../pages/Agency/AgencyPermits/PermitPass';
import BarChart from '../pages/BarChart/BarChart';
import ResourceCenter from '../pages/ResourceCenter';
// import VerifyMobileNumber from '../pages/AccountCreationFlows/VerifyMobileNumber';
/**
 * @return {JSX.Element}
 */
function PublicRouter(props) {
  const {url} = useRouteMatch();
  console.info('url', url);
  const isMobile = useMediaQuery({maxWidth: 767});

  const onProfileClick = () => {
    const {history} = props;
    history.push(`${url}/profile`);
  };

  return (
    <div className={styles.container}>
      <Switch>
        <PublicRoute restricted exact path="/" component={Sanna} />

        <PublicRoute
          restricted
          exact
          path="/resource"
          component={ResourceCenter}
        />
        <PublicRoute restricted exact path="/barchart" component={BarChart} />
        <PublicRoute
          exact
          path="/verify-permit/:referenceId"
          component={props => <PermitPass {...props} print={false} />}
        />

        <AuthLayout>
          <PublicRoute restricted exact path="/signin" component={SignIn} />
          <PublicRoute restricted exact path="/edrishti" component={SignIn} />

          <PublicRoute
            restricted
            exact
            path="/reset-password"
            component={ResetPassword}
          />
          <PublicRoute
            restricted
            exact
            path="/change-password"
            component={ChangePassword}
          />
          <PublicRoute
            key="person"
            exact
            path="/person-registration"
            component={PersonRegistration}
          />
          <PublicRoute
            key="person-edit"
            exact
            path="/edit-person-registration"
            children={<PersonRegistration status={'EDIT'} />}
          />
          <PublicRoute
            exact
            path="/vehicle-registration"
            component={VehicleRegistration}
          />
          <PublicRoute
            key="vehicle-edit"
            exact
            path="/edit-vehicle-registration"
            children={<VehicleRegistration status={'EDIT'} />}
          />

          <PublicRoute
            exact
            path="/registration-success"
            component={RegistrationSuccess}
          />
          <PublicRoute
            key="agency"
            exact
            path="/newAgencyRegistration"
            component={NewAgencyRegistration}
          />
          <PublicRoute
            exact
            key="agency-edit"
            path="/edit-agency-registration"
            children={<NewAgencyRegistration status={'EDIT'} />}
          />

          <PublicRoute
            exact
            path="/check-application-status"
            component={CheckApplicationStatus}
          />
          <PublicRoute
            exact
            path="/check-permit-status"
            component={CheckPermitStatus}
          />

          {/* <PublicRoute
            exact
            path="/verify-mobile-number"
            component={VerifyMobileNumber}
          /> */}
          <PublicRoute
            exact
            path="/get-reference-number"
            component={GetReferenceNumber}
          />

          <PublicRoute exact path="/payments" component={Payments} />
          <PublicRoute exact path="/download" component={Download} />
          <PublicRoute
            exact
            path="/manage-registration-information"
            component={ManageRegistrationInformation}
          />
          <PublicRoute
            exact
            path="/upload-police-noc"
            component={UploadPoliceNOC}
          />
        </AuthLayout>
      </Switch>
    </div>
  );
}

export default PublicRouter;
