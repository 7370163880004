import React from 'react';
import {useRouteMatch, Switch, Route, useLocation} from 'react-router-dom';
import SideBar from '../components/SideBar';
import {useMediaQuery} from 'react-responsive';
import styles from './styles.module.css';
import AgencyRquests from '../pages/Admin/AgencyRquests';
import PermitRquests from '../pages/Admin/PermitRquests';
import Attendence from '../pages/Operators/Attendence';
import Locations from '../pages/Operators/Locations';
import PersonCards from '../pages/Operators/PersonCards';
import VehicleCards from '../pages/Operators/VehicleCards';
import ManageOperators from '../pages/Operators/ManageOperators';
import Messages from '../pages/Operators/Messages';

import {operatorTabs} from './tabs';
import {connect} from 'react-redux';
import AddNewLocation from '../pages/Operators/AddNewLocation';
import AddNewOperator from '../pages/Operators/AddNewOperator';
import VerifyRegistrations from '../pages/Operators/VerifyRegistrations';
import PopupContainer from '../components/PopupContainer';
import ViewDocument from '../components/ViewDocumentView';
import History from '../components/operator/History';
import OperatorDashboard from '../pages/Operators/OperatorDashboard';

/**
 * @return {JSX.Element}
 */
function AdminRouter(props) {
  const {url} = useRouteMatch();
  console.info('url', url);
  const isMobile = useMediaQuery({maxWidth: 767});

  const onProfileClick = () => {
    const {history} = props;
    history.push(`${url}/profile`);
  };
  let location = useLocation();
  let background = location.state && location.state.background;
  console.info('background', background);

  const {
    login: {
      loginInfo: {user},
    },
  } = props.reduxProps;

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        {!isMobile ? (
          <div className={styles.sideBar}>
            <SideBar
              module={'admin'}
              header={'Operator'}
              // tabs={operatorTabs.children.filter(o =>
              //   o.roleIds.includes(user ? user.roleId : 5),
              // )}
              tabs={operatorTabs}
              roleId={user ? user.roleId : 0}
            />
          </div>
        ) : null}
        <div className={styles.relative}>
          <div className={styles.bodyWrapper}>
            <Switch location={background || location}>
              <Route path={`${url}/dashboard`}>
                <OperatorDashboard />
              </Route>
              <Route path={`${url}/attendance`}>
                <Attendence />
              </Route>
              <Route exact path={`${url}/locations`}>
                <Locations />
              </Route>
              {/* <Route path={`${url}/locations/add-new-location`}>
                <AddNewLocation />
              </Route>
              <Route path={`${url}/locations/add-new-location/:id`}>
                <AddNewLocation />
              </Route> */}
              <Route path={`${url}/personCards`}>
                <PersonCards />
              </Route>
              <Route path={`${url}/vehicleCards`}>
                <VehicleCards />
              </Route>
              <Route path={`${url}/manageOperators`}>
                <ManageOperators />
              </Route>
              <Route path={`${url}/messages`}>
                <Messages />
              </Route>
              <Route path={`${url}/verify-registrations`}>
                <VerifyRegistrations />
              </Route>
            </Switch>
            {background && (
              <>
                <Route
                  path={`${url}/locations/add-new-location`}
                  children={<AddNewLocation />}
                />
                <Route
                  path={`${url}/locations/add-new-location/:id`}
                  children={<AddNewLocation />}
                />
                <Route
                  path={`${url}/manageOperators/add-new-operator`}
                  children={<AddNewOperator />}
                />
                <Route
                  path={`${url}/manageOperators/add-new-operator/:id`}
                  children={<AddNewOperator />}
                />
                <Route
                  key="viewDocumentPerson"
                  exact
                  path={`${url}/personCards/document-view`}
                  children={
                    <PopupContainer containerStyle={styles.popupContainerStyle}>
                      <ViewDocument />
                    </PopupContainer>
                  }
                />
                <Route
                  key="viewPersonHistory"
                  exact
                  path={`${url}/personCards/view-history`}
                  children={
                    <PopupContainer containerStyle={styles.popupContainerStyle}>
                      <History />
                    </PopupContainer>
                  }
                />
                <Route
                  key="viewDocumentVehicle"
                  exact
                  path={`${url}/vehicleCards/document-view`}
                  children={
                    <PopupContainer containerStyle={styles.popupContainerStyle}>
                      <ViewDocument />
                    </PopupContainer>
                  }
                />
                <Route
                  key="viewVehicleHistory"
                  exact
                  path={`${url}/vehicleCards/view-history`}
                  children={
                    <PopupContainer containerStyle={styles.popupContainerStyle}>
                      <History />
                    </PopupContainer>
                  }
                />
                <Route
                  key="viewDocument"
                  exact
                  path={`${url}/verify-registrations/document-view`}
                  children={
                    <PopupContainer containerStyle={styles.popupContainerStyle}>
                      <ViewDocument />
                    </PopupContainer>
                  }
                />
                <Route
                  key="viewHistory"
                  exact
                  path={`${url}/verify-registrations/view-history`}
                  children={
                    <PopupContainer containerStyle={styles.popupContainerStyle}>
                      <History />
                    </PopupContainer>
                  }
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = reduxProps => ({
  reduxProps,
});

export default connect(mapStateToProps, null)(AdminRouter);
