import {ActionTypes} from '../constants';

export function setVehicleData(payload) {
  return {
    type: ActionTypes.SET_VEHICLE_DATA,
    payload,
  };
}

export function setPersonsData(payload) {
  return {
    type: ActionTypes.SET_PERSONS_DATA,
    payload,
  };
}
export function clearPersonsVehiclesData(payload) {
  return {
    type: ActionTypes.CLEAR_PERSONS_VEHICLES_DATA,
    payload,
  };
}

export function deleteMaterial(payload) {
  return {
    type: ActionTypes.DELETE_MATERIAL,
    payload,
  };
}

export function deleteVehicle(payload) {
  return {
    type: ActionTypes.DELETE_VEHICLE,
    payload,
  };
}

export function deletePerson(payload) {
  return {
    type: ActionTypes.DELETE_PERSON,
    payload,
  };
}

export function updateMaterial(payload) {
  return {
    type: ActionTypes.UPDATE_MATERIAL,
    payload,
  };
}
