import React, {useState} from 'react';
import styles from './styles.module.scss';
import AuthLayout from '../../../../components/AuthLayout';
import REQUEST_TYPE_OPTIONS from '../../../../constants/request-type-options';

import {withRouter} from 'react-router-dom';
import strings from '../../../../globalization/index';
import FormElement from '../../../../components/FormElement';
import Flex from '../../../../components/Flex';
import Button from '../../../../components/Button';
import {loginImages} from '../../../../assets/images';
import moment from 'moment';
const Desktop = ({
  backClick,
  onSubmit,
  stateData,
  onChangeHandler,
  handleUpload,
  formFields,
  finalSubmit,
}) => {
  const [requestType, setRequestType] = useState(null);
  const { personDetails, personPhoto} = stateData;
  return (
    // <AuthLayout>
    <Flex direction="column" className={styles.container}>
      <Flex onClick={backClick} className={styles.backlabel}>
        <img
          className={styles.backButtonIcon}
          src={loginImages.backButtonIcon}
          alt="back"
        />
        <span>{strings.back}</span>
      </Flex>
     { !personPhoto ? <Flex direction="column" className={styles.wrapper}>
        <div>
          <header>
            <h1
              className={styles.header}>{`Enter your RFID number`}</h1>
          </header>
          <Flex direction="column" className={styles.formContainer}>

            <FormElement
              type="text"
              label={
                "RFID Number"
              }
              required={true}
              placeholderText={'Enter RFID Number'
              }
              onChange={text => {
                onChangeHandler('RFIDNumber', text);
              }}
              value={stateData.RFIDNumber}
              maxLength={30}
            />           
            <Button
              buttonStyle="primary"
              label={`Submit`}
              className={styles.submitButton}
              onClick={onSubmit}
            />          
          </Flex>
        </div>
      </Flex> : null }
      { personPhoto ? <Flex direction="column" className={styles.wrapper}>
        <div>
          <header>
            <h1
              className={styles.header}>{`Upload Police NOC`}</h1>
          </header>
          <StatusCard data={personDetails} personPhoto={personPhoto}/>
          <Flex direction="column" className={styles.formContainer}>

      {formFields.map((elem, idx) => {
            return (
              <div
                key={`formFields-${idx}`}
                className={(styles.divider, styles.formFields)}>
                <FormElement
                  type={elem.elementType}
                  label={elem.label}
                  required={elem.required}
                  placeholderText={elem.placeholder}
                  onChange={text => {
                    onChangeHandler(elem.stateField, text);
                  }}
                  tabIndex={idx * 2 + 1}
                  error={stateData[elem.stateField].error}
                  options={stateData[elem.optionsField]}
                  value={stateData[elem.stateField].value}
                  key={idx}
                  maxLength={elem.maxLength}
                  keyboardType={elem.keyboardType}
                  // containerStyle={{marginBotton: 40 * vh}}
                  handleUpload={(file, error, inputRef) => {
                    handleUpload(elem.stateField, file, error, inputRef);
                  }}
                  maxFiles={elem.maxFiles}
                  allowedFormats={elem.allowedFormats}
                  fieldName={elem.stateField}
                  minimumDate={elem.minimumDate}
                  dropdownContainerStyle={styles.dropdownContainerStyle}
                  uppercase={elem.uppercase}
                />
              </div>
            );
          })} 

            <Button
              buttonStyle="primary"
              label={`Submit`}
              className={styles.submitButton}
              onClick={finalSubmit}
            />          
          </Flex>
      </div>
      </Flex> : null }
    </Flex>
    // </AuthLayout>
  );
};
export default withRouter(Desktop);



const StatusCard = ({data, personPhoto}) => {
  const { name, dob, phoneNumber } = data || {};
  console.log(data);
  return (
    <Flex direction='column' className={styles.personCardWrapper}>
      <div className={styles.personHeader}>
        Person Details
      </div> 
      <div className={styles.personBody}>
      <img className={styles.photo} src={personPhoto}/>
      <div>
        <div className={styles.personLabel}>Name: {name}</div>  
        <div className={styles.personLabel}>D.O.B: {dob ? moment(dob).format('DD/MM/YYYY'): '-'}</div>  

        <div className={styles.personLabel}>Mobile Number: {phoneNumber ? phoneNumber.slice(3): ''}</div>  

      </div>  
      </div>     

    </Flex>
  );
};