import React, {Component} from 'react';
import PopupContainer from '../../../../components/PopupContainer';
import Popup from '../../../../components/SimplePopup';
import {withRouter} from 'react-router-dom';
import {API} from '../../../../requests';

import ViewRenderer from './views';
import Spinner from '../../../../components/Spinner';
import {commonImages} from '../../../../assets/images';
import strings from '../../../../globalization';
import {connect} from 'react-redux';

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  SIMPLE_POPUP: 'simple',
  ERROR: 'error',
  SPINNER_POPUP: 'spinner',
};
class UsersList extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    const {reduxProps} = this.props;
    const {
      login: {loginInfo},
    } = reduxProps;
    const {company, user, department} = loginInfo;
    console.log('logged in user:', user);

    this.state = {
      selectedStatus: this.props.status,
      searchText: '',
      company,
      department,
      user,
      agencies: [],
      filterAgencies: [],
    };
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState({
        agencies: [],
        filterAgencies: [],
        popup: undefined,
      });

      console.log('on route change');
      this.getData();
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }

  componentDidMount() {
    this.setState({
      agencies: [],
      filterAgencies: [],
      popup: undefined,
    });

    this.getData();
  }

  getData = async status => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
    await API.getAllDepartmentUsers(status)
      .then(response => {
        console.log(response);
        //  const {agencies, filterAgencies} = this.state;
        this.setState(prevState => ({
          agencies: [...prevState.agencies, ...response.data],
          filterAgencies: [...prevState.filterAgencies, ...response.data],
          popup: undefined,
        }));

        // this.setState({
        //   ...prevState
        //   agencies: response.data,
        //   filterAgencies: response.data,
        //   popup: undefined,
        // });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        console.info('error....', errorMsg);
        this.setState({
          popup: undefined,
        });
        this.showErrorPopup(
          'There was an error while fetching the users list, please try again after some time.',
        );
        // this.setState({
        //   popup: {
        //     type: POPUP_CONSTANTS.SIMPLE_POPUP,
        //     message:
        //       'There was an error while fetching the user details, please try again after some time.',
        //     messageStyle: {color: '#E67717'},
        //     buttons: [
        //       {
        //         text: 'Okay!',
        //         onClick: this.closePopup,
        //       },
        //     ],
        //   },
        // });
      });
  };

  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  onChangeHandler = (action, data) => {
    switch (action) {
      case 'search': {
        console.info('data..', data);
        this.setState({
          searchText: data,
        });
        const {agencies} = this.state;
        const filterAgencies = agencies.filter(element =>
          Object.values(element)
            .toString()
            .toLowerCase()
            .includes(data.toLowerCase()),
        );
        console.log('filterAgencies', filterAgencies);
        this.setState({
          filterAgencies,
        });

        break;
      }

      default: {
        console.info('case not handled', action);
      }
    }
  };

  /**
   *
   * @param {*} action
   * @param {*} data
   */

  onClickHandler = (action, data) => {
    const {history, location, status} = this.props;
    const {agencies, filterAgencies} = this.state;
    switch (action) {
      // case 'addRole': {
      //   //FIXME
      //   history.push({
      //     pathname: '/u/user-management/add-new-user',
      //     state: {background: location},
      //   });
      //   break;
      // }
      case 'addNewUser': {
        //FIXME
        history.push({
          pathname: '/u/user-management/add-new-user',
          state: {background: location},
        });
        break;
      }

      case 'deleteUser': {
        const rowData = agencies.filter(element => element?.userId === data)[0];
        console.log('data:', data, rowData);
        const new_RowData = {
          ...rowData,
          role: rowData.roleId,
          idProofDocument: rowData.idProofDocument
            ? [
                {
                  fileUrl: rowData.idProofDocument,
                  fileName: rowData.idProofDocument.substr(
                    rowData.idProofDocument.length - 10,
                  ),
                },
              ]
            : null,
          authorizedDocument: rowData.authorizedDocument
            ? [
                {
                  fileUrl: rowData.authorizedDocument,
                  fileName: rowData.authorizedDocument.substr(
                    rowData.authorizedDocument.length - 10,
                  ),
                },
              ]
            : null,

          phoneNumber: rowData.phoneNumber.slice(3),
        };
        console.log(new_RowData);
        //FIXME
        history.push({
          pathname: '/u/user-management/delete-user',
          state: {background: location, rowData: new_RowData},
        });
        break;
      }
      case 'editUser': {
        //FIXME
        const rowData = agencies.filter(element => element?.userId === data)[0];
        console.log(data);
        const new_RowData = {
          ...rowData,
          role: rowData.roleId,
          idProofDocument: rowData.idProofDocument
            ? [
                {
                  fileUrl: rowData.idProofDocument,
                  fileName: rowData.idProofDocument.substr(
                    rowData.idProofDocument.length - 10,
                  ),
                },
              ]
            : null,
          authorizedDocument: rowData.authorizedDocument
            ? [
                {
                  fileUrl: rowData.authorizedDocument,
                  fileName: rowData.authorizedDocument.substr(
                    rowData.authorizedDocument.length - 10,
                  ),
                },
              ]
            : null,

          phoneNumber: rowData.phoneNumber.slice(3),
        };
        console.log(new_RowData);
        history.push({
          pathname: '/u/user-management/edit-user',
          state: {background: location, rowData: new_RowData},
        });
        break;
      }
      case 'learnMore': {
        //FIXME
        history.push({
          pathname: '/u/user-management/learn-more',
        });
        break;
      }
      case 'clearFilters': {
        this.resetData();
        break;
      }
      default: {
        console.info('case not handled', action);
      }
    }
  };

  resetData = () => {
    this.setState({
      searchText: '',
      agencies: [],
      filterAgencies: [],
      popup: undefined,
    });
    this.getData();
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };

  getProps = () => {
    const {user, department} = this.state;
    return {
      onClick: this.onClickHandler,
      selectedStatus: this.state.selectedStatus,
      onChange: this.onChangeHandler,
      searchText: this.state.searchText,
      agencies: this.state.agencies,
      filterAgencies: this.state.filterAgencies,
      roleId: user.roleId,
      departmentName: department?.name,
    };
  };

  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      // case "spinner": {
      //   console.log('showing spinner');
      //   return <Spinner />;
      // }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}
const mapStateToProps = reduxProps => ({
  reduxProps,
});

const mapDispatchToProps = dispatch => ({
  // dispatchSetPersonsData: payload => {
  //   dispatch(setPersonsData(payload));
  // },
});

// export default withRouter(PersonCards);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UsersList),
);

// export default withRouter(UsersList);
