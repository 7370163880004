import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
//import reportWebVitals from "./reportWebVitals";
import './App.scss';
import {configureAuth} from './services/aws_service';
import {store, persistor} from './store';
import {PersistGate} from 'redux-persist/integration/react';
import Routes from './Routes';
import Flex from './components/Flex';
import Loading from './components/Loading';

const Application = props => {
  configureAuth();
  const [pageLoaded, setPageLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setPageLoaded(true);
    }, 4000);
  }, []);
  // const store = configureStore();
  const routeStyle = {width: '100vw', height: '100vh'};
  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Flex style={routeStyle}>
            {/* {pageLoaded ? <Routes /> : <Loading />} */}
            <Routes />
          </Flex>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
};

ReactDOM.render(<Application />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
