import React from 'react';
import Flex from '../../Flex';
import styles from './styles.module.scss';

import successIcon from '../../../assets/images/success/image.png';
import placeholderPerson from '../../../assets/images/placeholder-person/image.png';
import strings from '../../../globalization';

function SuccessPage({
  containerClass,
  title,
  RFIDNumber,
  referenceNumber,
  remark,
  data,
  onOkay,
  type,
  ...remainingProps
}) {
  console.log('data success ####', data);
  const getText = () => {
    switch (type) {
      case 'issued': {
        return (
          <p className={styles.mainText}>
            {strings.newCardsWithRFID}: {RFIDNumber}{' '}
            {strings.hasBeenApprovedFor} {strings.person}: {data.name}
          </p>
        );
      }
      case 'hold': {
        return (
          <p className={styles.mainText}>
            {strings.rfidCardNumber}: {data.RFIDNumber}{' '}
            {strings.hasBeenPutOnHoldForPerson}: {data.name}
          </p>
        );
      }
      case 'surrendered': {
        return <p className={styles.mainText}>{strings.RFIDSurrendered}.</p>;
      }
      case 'updated': {
        return <p className={styles.mainText}>Data updated successfully.</p>;
      }
      default: {
        break;
      }
    }
  };
  return (
    <div className={[styles.container, containerClass].join(' ')}>
      <Flex direction="column" className={styles.successContainer}>
        {/* FIXME: ADD PHOTO OF THE USER - TEMPORARILY DEMO IS TEHRE. */}
        <img
          src={data.photo ? data.photo : placeholderPerson}
          alt="U"
          className={styles.avatar}
        />
        <p className={styles.refNumber}>
          {strings.referenceNumberLabel}: {data.referenceId}
        </p>

        <Flex direction="column" className={styles.successBox}>
          <img src={successIcon} alt="U" className={styles.successIcon} />
          <Flex direction="column" className={styles.mainTextContainer}>
            {getText()}
            {remark ? (
              <p className={styles.remark}>
                {strings.remarks}: {remark}
              </p>
            ) : null}
          </Flex>

          <button className={styles.okay} onClick={onOkay}>
            {strings.okayPopup}
          </button>
        </Flex>
      </Flex>
    </div>
  );
}

export default SuccessPage;
