import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import PopupContainer from '../../../components/PopupContainer';
import styles from './styles.module.scss';
import ViewRenderer from './Views';
import ReactToPrint, {PrintContextConsumer} from 'react-to-print';
import Spinner from '../../../components/Spinner';
import Popup from '../../../components/SimplePopup';
import {API} from '../../../requests';
import util from '../../../util';
import strings from '../../../globalization';

const POPUP_CONSTANTS = {
  STANDARD_POPUP: 'standard-popup',
  ERROR_POPUP: 'error-popup',
  SPINNER_POPUP: 'spinner-popup',
  USER_NOT_CONFIRM: 'user-not-confirm',
  SIMPLE_POPUP: 'simple-popup',
};

// import useLocation from ''
class DownloadPermit extends React.Component {
  constructor(props) {
    super(props);
    console.info('state', props.location, props.history, props.match);

    this.state = {
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
      gateDetails: props.location.state.gateDetails || [],
      filteredTrafficDetails: props.location.state.filteredTrafficDetails || [],
      selectedGates: props.location.state.selectedGates || [],
      selectedParameters: props.location.state.selectedParameters || [],
    };
    // const {location} = props;
    // this.driver = location.state.driver;
    // this.vehicle = location.state.vehicle;
    // this.permit = location.state.permit;
    // this.material = location.state.material;
  }

  componentDidMount() {
    console.log('state deatils', this.state.gateDetails);
    this.setState({
      popup: null,
    });
  }
  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  showErrorPopup = errorMsg => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMsg,
        messageStyle: {color: '#E67717'},

        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
          },
        ],
        onClose: this.closePopup,
      },
    });
  };

  goBack = () => {
    const {history} = this.props;
    history.goBack();
  };

  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      default: {
        console.warn('case not handled', popup.type);
        return null;
      }
    }
  };
  onClickHandler = (action, data) => {
    console.log('onClickHandler...', action);
    const {history} = this.props;
    switch (action) {
      case 'closePopup': {
        history.goBack();
        break;
      }
      default: {
        console.warn('case not handled', action);
      }
    }
  };
  closePopup = () => {
    const {history} = this.props;
    history.goBack();
  };

  getProps = () => {
    // const {location} = this.props;
    const {
      gateDetails,
      filteredTrafficDetails,
      selectedGates,
      selectedParameters,
    } = this.state;

    return {
      gateDetails,
      onClick: this.onClickHandler,
      filteredTrafficDetails,
      selectedGates,
      selectedParameters,
    };
  };
  render() {
    const {print = true} = this.props;
    const {popup} = this.state;
    const viewProps = this.getProps();
    const pageStyle = `
  @page {
    size: 290mm 290mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;
    return (
      <>
        {popup ? (
          <PopupContainer containerStyle={styles.popupContainerStyle}>
            {this.getPopupContent()}
          </PopupContainer>
        ) : (
          <ViewRenderer
            ref={el => {
              this.componentRef = el;
              console.info('reference', el);
            }}
            id="pdf"
            {...viewProps}
          />
        )}

        {print ? (
          <ReactToPrint
            content={() => {
              console.info('this.componentRef', this.componentRef);
              return this.componentRef;
            }}
            pageStyle={pageStyle}>
            <PrintContextConsumer>
              {({handlePrint}) => (
                <AutomaticPrint
                  goBack={this.goBack}
                  handlePrint={handlePrint}
                />
              )}
            </PrintContextConsumer>
          </ReactToPrint>
        ) : null}
      </>
    );
  }
}

export default withRouter(DownloadPermit);

function AutomaticPrint({handlePrint, goBack}) {
  useEffect(() => {
    setTimeout(() => {
      // FIXME: Uncomment before committing.
      handlePrint();
      // goBack();
    }, 1000);
    setTimeout(() => {
      // FIXME: Uncomment before committing.
      goBack();
      // goBack();
    }, 2000);
  }, []);
  return null;
}
