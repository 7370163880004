import React, {Component} from 'react';

import ViewRenderer from './views';
import {API} from '../../../requests';
import 'react-datasheet/lib/react-datasheet.css';
import './styles.css';
import PopupContainer from '../../../components/PopupContainer';
import POPUP_CONSTANTS from '../../../constants/popup-constants';
import Spinner from '../../../components/Spinner';
import Popup from '../../../components/SimplePopup';
import moment from 'moment';
import Popupcan from '../../../components/PopupPerson/popup';
import strings from '../../../globalization';
import util from '../../../util';
import {getFormData} from './data';
import {editFormData} from './editData';
import DeletePopup from '../../../components/DeletePopup';
import {commonImages, operatorImages} from '../../../assets/images';

class BloodGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: undefined,

      searchText: '',
      bloodGroupType: [],
      singleBloodGroupType: [],
      filterBloodGroupType: [],

      selectedStatus: '',
    };
  }
  statuses = [
    {
      id: '',
      name: 'ALL',
    },
    {
      id: 'inactive',
      name: 'INACTIVE',
    },
    {
      id: 'active',
      name: 'ACTIVE',
    },
  ];
  editStatusDropDown = this.statuses.filter(num => num.id !== '');

  onSubmit = bloodGroupType => {
    const bloodGroupTypeData = {
      name: bloodGroupType.name.value,
      name_hindi: bloodGroupType.name_hindi.value,
    };
    this.setState({
      popup: null,
    });
    this.saveBloodGroupType(bloodGroupTypeData);
  };
  showErrorPopup = errorMsg => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMsg,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.onClose,
          },
        ],
        onClose: this.closePopup,
      },
    });
  };
  showSuccessPopup = (successMessage, onClick) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: successMessage,
        onClose: this.onClose,
        headingImage: operatorImages.successIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: onClick ? onClick : this.onClose,
            outline: true,
          },
        ],
      },
    });
  };
  onClickHandler = (action, data) => {
    switch (action) {
      case 'edit': {
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.EDIT_POPUP,
            statuses: this.statuses,
          },
          singleBloodGroupType: data,
        });

        break;
      }
      case 'delete': {
        data.status === 'ACTIVE' &&
          this.setState({
            bloodGroupType: data,
            popup: {type: POPUP_CONSTANTS.DELETE_POPUP},
          });

        break;
      }

      default: {
        break;
      }
    }
  };
  onClose = () => {
    this.setState({
      popup: null,
    });
  };
  tableActionHandler = (action, data) => {
    switch (action) {
      case 'status': {
        console.info('data..', data);
        this.setState(
          {
            selectedStatus: data,
          },
          this.applyFilters,
        );
        break;
      }

      case 'addNewBloodGroup': {
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.ADD_DATA_POPUP,
            statuses: this.statuses,
          },
        });
        break;
      }

      case 'search': {
        this.setState(
          {
            searchText: data,
          },
          this.applyFilters,
        );
        break;
      }
      case 'clearFilter': {
        this.setState({
          searchText: '',
          selectedStatus: '',
        });
        this.fetchBloodGroupType();
        break;
      }
      default: {
        console.info('filterChangeHandler case not handled', action);
      }
    }
  };
  deletebloodGroupTypeData = id => {
    API.deleteBloodGroupType(id)
      .then(res => {
        this.showSuccessPopup(
          'Blood Group Deleted Successfully',
          this.fetchBloodGroupType,
        );
      })
      .catch(error => {
        const err = util.getErrorMessage(error);
        this.showErrorPopup(err);
      });
  };

  onDeleteHandler = () => {
    let id = this.state.bloodGroupType.id;
    this.setState({popup: null});
    this.deletebloodGroupTypeData(id);
  };

  onEditHandler = data => {
    let myData = {
      bloodGroupId: this.state.singleBloodGroupType.id,
      name: data.name.value,
      name_hindi: data.name_hindi.value,
      status: data.status.value.toUpperCase(),
    };
    this.setState({popup: null});
    this.updateBloodGroupType(myData);
  };
  updateBloodGroupType = data => {
    API.updatesBloodGroupTypeData(data)
      .then(res => {
        this.showSuccessPopup(
          'Blood Group Updated Successfully',
          this.fetchBloodGroupType,
        );
      })
      .catch(error => {
        const err = util.getErrorMessage(error);
        this.showErrorPopup(err);
      });
  };
  applyFilters = () => {
    const {searchText, selectedStatus, bloodGroupType} = this.state;
    const filteredData =
      bloodGroupType.length > 0 &&
      bloodGroupType.filter(row => {
        const name = {name: row.name, name_hindi: row.name_hindi};
        if (
          searchText &&
          !Object.values(name)
            .toString()
            .toLowerCase()
            .includes(searchText.toLowerCase())
        ) {
          return false;
        }

        if (
          selectedStatus &&
          row.status.toLowerCase() !== selectedStatus.toLowerCase()
        ) {
          return false;
        }
        return true;
      });

    console.info('filteredData', filteredData, selectedStatus);

    this.setState({
      filterBloodGroupType: filteredData,
    });
  };

  fetchBloodGroupType = () => {
    this.setState({popup: {type: POPUP_CONSTANTS.SPINNER_POPUP}});
    API.fetchBloodGroupTypeData()
      .then(res => {
        this.setState(
          {
            // ...this.state,
            bloodGroupType: res.data,
            filterBloodGroupType: res.data,
            popup: null,
          },
          this.applyFilters,
        );
      })
      .catch(error => {
        const err = util.getErrorMessage(error);
        this.showErrorPopup(err);
      });
  };
  saveBloodGroupType = bloodGroupTypeName => {
    let formData = {
      name: bloodGroupTypeName.name,
      name_hindi: bloodGroupTypeName.name_hindi,
      status: bloodGroupTypeName.status,
    };

    API.saveBloodGroupTypeData(formData)
      .then(res => {
        this.showSuccessPopup(
          'Blood Group Added Successfully',
          this.fetchBloodGroupType,
        );
      })
      .catch(error => {
        const err = util.getErrorMessage(error);
        this.showErrorPopup(err);
      });
  };
  // deleteBloodGroupTypeData = id => {
  //   API.deleteBloodGroupType(id)
  //     .then(res => {
  //       this.showSuccessPopup('Delete Successfully', this.fetchBloodGroupType);
  //     })
  //     .catch(error => {
  //       const err = util.getErrorMessage(error);
  //       this.showErrorPopup(err);
  //     });
  // };

  getProps = () => {
    const {searchText, selectedStatus, filterBloodGroupType} = this.state;
    return {
      onClick: this.onClickHandler,

      tableActionHandler: this.tableActionHandler,
      bloodGroupType: filterBloodGroupType,
      statuses: this.statuses,
      searchText,
      selectedStatus,
    };
  };

  getPopupContent = () => {
    const {popup} = this.state;
    switch (popup.type) {
      case POPUP_CONSTANTS.ADD_DATA_POPUP: {
        return (
          <Popupcan
            header="Add"
            onSubmit={this.onSubmit}
            onClose={this.onClose}
            getFormData={getFormData}
            statuses={this.statuses}
          />
        );
      }
      case POPUP_CONSTANTS.EDIT_POPUP: {
        return (
          <Popupcan
            header="Edit"
            categoryTypeData={this.state.singleBloodGroupType}
            onSubmit={this.onEditHandler}
            onClose={this.onClose}
            getFormData={editFormData}
            statuses={this.editStatusDropDown}
          />
        );
      }
      case POPUP_CONSTANTS.DELETE_POPUP: {
        return (
          <DeletePopup onClick={this.onDeleteHandler} onClose={this.onClose} />
        );
      }
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };
  componentDidMount() {
    this.fetchBloodGroupType();
  }

  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

export default BloodGroup;
