import React, {useEffect, useRef, useState} from 'react';
import Flex from '../../Flex';
import styles from './styles.module.scss';

import successIcon from '../../../assets/images/success/image.png';
import FormElement from '../../FormElement';
import CameraCapture from '../CameraCapture';
import {AgGridReact} from 'ag-grid-react';

import './styles.css';

import dimensions from '../../../util/dimensions-util';
import {operatorImages} from '../../../assets/images';
import moment from 'moment';
import strings from '../../../globalization';
import {useHistory, useLocation} from 'react-router-dom';
const {vw: widthConstant, vh: heightConstant} = dimensions;

const STATUSES = {
  ISSUED: 'Issued',
  HOLD: 'Hold',
  PENDING: 'Pending',
  SURRENDERED: 'Surrendered',
  SURRENDER: 'Surrendered',
  PAYMENT_PENDING: 'Payment Pending',
  PAYMENT_COMPLETED: 'Payment Completed',
  PAYMENT_FAILED: 'Payment Failed',
  OPERATOR_ADMIN_APPROVED: 'Operator Admin Approved',
  OPERATOR_ADMIN_REJECTED: 'Operator Admin Rejected',
  DEPARTMENT_ADMIN_APPROVED: 'Department Admin Approved',
  DEPARTMENT_ADMIN_REJECTED: 'Department Admin Rejected',
  LOST: 'Lost / Damaged',
  WAITING_FOR_APPROVAL: 'Waiting For Approval',
  WAITING_FOR_REVERIFY: 'Waiting For Reverify',
  OPERATOR_ADMIN_REVERIFIED: 'Operator Admin Reverified',
  REISSUE: 'Reissue Payment Complete',
  REISSUE_PAYMENT_FAILED: 'Reissue Payment Failed',
};

const UPDATED_BY_USER_STATUSES = {
  REISSUE_PAYMENT_FAILED: 'REISSUE_PAYMENT_FAILED',
  SURRENDER: 'SURRENDER',
  LOST: 'LOST',
};

function History(props) {
  const history = useHistory();
  const location = useLocation();

  const historyData = location?.state?.history || [];
  const rejectReasons = location?.state?.rejectReasons || [];

  console.log('rejectReasons in history', rejectReasons);

  console.log('historyData...', historyData);
  const columnDefs = [
    {
      headerName: 'Date',
      field: 'modifiedDate',
      headerClass: styles.rowHeader,
      width: 180 * widthConstant,
      flex: 1,
      resizable: true,
      wrapText: true,
      autoHeight: true,
      cellRendererFramework: ({data: {modifiedDate}}) => {
        return (
          <div className={styles.sessionTime}>
            {moment(modifiedDate).format('DD/MM/YYYY')}
          </div>
        );
      },
    },
    {
      headerName: 'Resubmitted Reason',
      field: 'rejectReason',
      headerClass: styles.rowHeader,
      width: 180 * widthConstant,
      flex: 1,
      resizable: true,
      wrapText: true,
      autoHeight: true,
      cellRendererFramework: ({data}) => {
        return (
          <div className={styles.sessionTime}>
            {/* {data.rejectReasonId
              ? rejectReasons.find(r => r.id === data.rejectReasonId)?.reason
              : '-'} */}
            {data['Reject Reason']}
          </div>
        );
      },
    },
    {
      headerName: 'Comments',
      field: 'comments',
      headerClass: styles.rowHeader,
      width: 180 * widthConstant,
      flex: 1,
      resizable: true,
      wrapText: true,
      autoHeight: true,
      cellRendererFramework: ({data}) => {
        return (
          <div className={styles.sessionTime} style={{lineHeight: 1.8}}>
            {data?.notes ? data?.notes : data?.remarks ? data?.remarks : '-'}
          </div>
        );
      },
    },
    {
      headerName: 'Activity By',
      field: 'activityBy',
      headerClass: styles.rowHeader,
      width: 180 * widthConstant,
      flex: 1,
      resizable: true,
      wrapText: true,
      autoHeight: true,
      cellRendererFramework: ({data}) => {
        return (
          <div className={styles.sessionTime}>
            {data.updatedBy
              ? data.updatedBy
              : UPDATED_BY_USER_STATUSES[data.status] && !data.updatedBy
              ? 'User'
              : '-'}
          </div>
        );
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      headerClass: styles.rowHeader,
      width: 180 * widthConstant,
      flex: 1,
      resizable: true,
      cellRendererFramework: ({data: {status}}) => {
        return (
          <div className={styles.sessionTime}>
            <p>{STATUSES[status] ? STATUSES[status] : status}</p>
          </div>
        );
      },
    },
  ];
  const getStyles = () => {
    let status = '';

    switch (status) {
      case STATUSES.ISSUED: {
        return {
          padding: '2px 10px',
          borderRadius: '5px',
          backgroundColor: '#0AC15770',
        };
      }
      case STATUSES.HOLD: {
        return {
          padding: '2px 10px',
          borderRadius: '5px',
          backgroundColor: '#E6771770',
        };
      }
      case STATUSES.SURRENDERED: {
        return {
          padding: '2px 10px',
          borderRadius: '5px',
          backgroundColor: '#E6771770',
        };
      }
      default: {
        return {
          padding: '2px 10px',
          borderRadius: '5px',
          backgroundColor: '#0045E670',
        };
      }
    }
  };
  const onBack = () => {
    history.goBack();
  };
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return {background: '#f6f6f6', border: 'none'};
    }
  };
  const rowStyle = {border: 'none', cursor: 'pointer'};

  // console.log('FormData - history', stateData);

  return (
    <div className={[styles.container].join(' ')}>
      <Flex className={styles.header}>
        <h1>{`History`}</h1>
      </Flex>

      <div className={['ag-theme-balham', styles.tableWrapper].join(' ')}>
        <AgGridReact
          columnDefs={columnDefs}
          rowStyle={rowStyle}
          getRowStyle={getRowStyle}
          rowHoverHighlight={true}
          rowHeight={60 * heightConstant}
          headerHeight={60 * heightConstant}
          rowClass={[styles.gridRowClass]}
          defaultColDef={{sortable: true}}
          rowData={historyData}
          domLayout="autoHeight"></AgGridReact>
      </div>
      <Flex className={styles.buttonsContainer}>
        <button className={styles.okay} onClick={onBack}>
          Back
        </button>
      </Flex>
    </div>
  );
}

export default History;
