import React, {Component, useEffect} from 'react';
import styles from './styles.module.scss';
import PopupContainer from '../../../components/PopupContainer';
import Popup from '../../../components/SimplePopup';

import {adminImages, loginImages, commonImages} from '../../../assets/images';
import moment from 'moment';
import ViewRenderer from './views';
import DeptMaterialPopup from '../../../components/DeptMaterialPopup';
import AgencyRquestsPopup from '../../../components/AgencyRquestsPopup';
import AgencyPersonDetails from '../../../components/AgencyPersonDetails';
import AgencyMaterialDetails from '../../../components/AgencyMaterialDetails';

import {API} from '../../../requests';
import strings from '../../../globalization';
import Spinner from '../../../components/Spinner';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Flex from '../../../components/Flex';
import util from '../../../util';
import {AgGridReact} from 'ag-grid-react';
import dimensions from '../../../util/dimensions-util';
import './styles.css';
import {customStyles} from './customStyles';

import Select from 'react-select';

const {vw: widthConstant, vh: heightConstant} = dimensions;

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  ERROR: 'error',
  SPINNER_POPUP: 'spinner',
  PERMIT_DETAILS: 'permitDetails',
};
const rowStyle = {border: 'none', cursor: 'pointer'};
const getRowStyle = params => {};

const getNatureOfCargo = item => {
  let data = item;
  switch (item) {
    case 'DB':
      data = 'Dry Bulk';
      break;
    case 'BB':
      data = 'Bread Bulk';
      break;
    default:
      return;
  }
  console.log(data);

  return data;
};
const exportColumnDefs = [
  {
    headerName: '',
    field: 'checkBox',
    headerClass: styles.rowHeader,
    width: 50 * widthConstant,
    resizable: true,
    checkboxSelection: function (params) {
      const displayedColumns = params.columnApi.getAllDisplayedColumns();
      return displayedColumns[0] === params.column;
    },
    headerCheckboxSelection: true,
  },

  {
    headerName: 'Shipping Bill Number',
    field: 'shippingBillNumber',
    headerClass: styles.rowHeader,
    width: 230 * widthConstant,
    resizable: true,
    cellRendererFramework: ({data: {shippingBillNumber}}) => {
      return <div className={styles.sessionTime}>{shippingBillNumber}</div>;
    },
  },
  {
    headerName: 'HS Code',
    field: 'HSCode',
    headerClass: styles.rowHeader,
    width: 180 * widthConstant,
    resizable: true,
    cellRendererFramework: ({data: {hscode}}) => {
      return <div className={styles.sessionTime}>{hscode}</div>;
    },
  },
  {
    headerName: 'FOB Value',
    field: 'FOB',
    headerClass: styles.rowHeader,
    width: 180 * widthConstant,
    resizable: true,
    cellRendererFramework: ({data: {fobvalue}}) => {
      return <div className={styles.sessionTime}>{fobvalue}</div>;
    },
  },
  {
    headerName: 'Nature of Cargo',
    field: 'natureOfCargoDetails',
    headerClass: styles.rowHeader,
    width: 180 * widthConstant,
    resizable: true,
    cellRendererFramework: ({data: {natureOfCargoDetails}}) => {
      return (
        <div className={styles.sessionTime}>{natureOfCargoDetails?.name}</div>
      );
    },
  },
  {
    headerName: 'Total Weight/Number of Units',
    field: 'totalWeightNumberOfUnits',
    headerClass: styles.rowHeader,
    width: 180 * widthConstant,
    resizable: true,
    cellRendererFramework: ({data: {totalWeightNumberOfUnits}}) => {
      return (
        <div className={styles.sessionTime}>{totalWeightNumberOfUnits}</div>
      );
    },
  },
  {
    headerName: 'Cargo Description',
    field: 'cargoDescription',
    headerClass: styles.rowHeader,
    width: 180 * widthConstant,
    resizable: true,
    cellRendererFramework: ({data: {cargoDescription}}) => {
      return <div className={styles.sessionTime}>{cargoDescription}</div>;
    },
  },
];

const importColumnDefs = [
  {
    headerName: '',
    field: 'checkBox',
    headerClass: styles.rowHeader,
    width: 50 * widthConstant,
    resizable: true,
    checkboxSelection: function (params) {
      const displayedColumns = params.columnApi.getAllDisplayedColumns();
      return displayedColumns[0] === params.column;
    },
    headerCheckboxSelection: true,
  },
  {
    headerName: 'Type',
    field: 'workPurposeName',
    headerClass: styles.rowHeader,
    width: 150 * widthConstant,
    resizable: true,
    flex: 1,
    cellRendererFramework: ({data: {materialTypeId, workPurposeName}}) => {
      return (
        <div className={styles.sessionTime}>
          {' '}
          {materialTypeId === 1
            ? 'Import'
            : materialTypeId === 2
            ? 'Export'
            : null}
        </div>
      );
    },
  },

  {
    headerName: 'IGM/SB Number',
    field: 'igmnumber',
    headerClass: styles.rowHeader,
    width: 230 * widthConstant,
    resizable: true,
    flex: 1,
    cellRendererFramework: ({
      data: {igmnumber, shippingBillNumber, materialTypeId},
    }) => {
      return (
        <div className={styles.sessionTime}>
          {materialTypeId === 1 ? igmnumber : shippingBillNumber}
        </div>
      );
    },
  },
  {
    headerName: 'CHA Name',
    field: 'agencyName',
    headerClass: styles.rowHeader,
    width: 230 * widthConstant,
    resizable: true,
    flex: 1,
    cellRendererFramework: ({data: {agencyName}}) => {
      return <div className={styles.sessionTime}>{agencyName}</div>;
    },
  },
  {
    headerName: 'Cargo Type',
    field: 'cargoType',
    headerClass: styles.rowHeader,
    width: 180 * widthConstant,
    resizable: true,
    flex: 1,
    cellRendererFramework: ({
      data: {natureOfCargoDetails, lineInfo, materialTypeId},
    }) => {
      return (
        <div className={styles.sessionTime}>
          {materialTypeId === 1
            ? getNatureOfCargo(lineInfo[0]?.natureOfCargo)
            : natureOfCargoDetails?.name}
        </div>
      );
    },
  },
  {
    headerName: ' Cargo Name',
    field: 'cargoName',
    headerClass: styles.rowHeader,
    width: 180 * widthConstant,
    editable: true,
    resizable: true,
    wrapText: true,
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 1.5,
      wordBreak: 'initial',
    },
    cellRendererFramework: ({
      data: {cargoDescription, materialTypeId, lineInfo},
    }) => {
      return (
        <div className={styles.sessionTime}>
          {materialTypeId === 1
            ? lineInfo[0]?.cargoDescription
            : cargoDescription}
        </div>
      );
    },
  },
  {
    headerName: 'Gross Quantity',
    field: 'grossQuantity',
    headerClass: styles.rowHeader,
    width: 230 * widthConstant,
    resizable: true,
    flex: 1,
    cellRendererFramework: ({data}) => {
      return <div className={styles.sessionTime}>{data?.grossQuantity}</div>;
    },
  },
  {
    headerName: 'Applied Quantity',
    field: 'appliedGrossQuantity',
    headerClass: styles.rowHeader,
    width: 230 * widthConstant,
    resizable: true,
    flex: 1,
    cellRendererFramework: ({data}) => {
      return (
        <div className={styles.sessionTime}>{data?.appliedGrossQuantity}</div>
      );
    },
  },
  {
    headerName: 'Cargo Description',
    field: 'cargoDescription',
    headerClass: styles.rowHeader,
    width: 230 * widthConstant,
    resizable: true,
    wrapText: true,
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 1.5,
      wordBreak: 'initial',
    },

    cellRendererFramework: ({data}) => {
      return <div className={styles.sessionTime}>{data?.cargoDescription}</div>;
    },
  },
];

class TrafficDeptPermitRequests extends Component {
  constructor(props) {
    super(props);
    const {reduxProps} = this.props;
    const {
      login: {loginInfo},
    } = reduxProps;
    const {department, user} = loginInfo;
    console.log(user, department);

    this.state = {
      agencies: [],
      selectedStatus: '',
      selectedMaterialType: 1,
      showRejectValidation: false,
      materialType: '',
      selectedAgency: '',
      startDate: new moment().add(-2, 'years').format('MM-DD-YYYY'),
      endDate: new moment().add(1, 'years').format('MM-DD-YYYY'),
      dates: null,
      filterAgencies: [],
      searchText: '',
      rejectMessage: '',
      departmentName: department?.name,
      exportData: [],
      importData: [],
      selectedNodes: [],
      statuses: [
        {
          id: '1',
          name: 'PENDING',
        },
        {
          id: '2',
          name: 'APPROVED',
        },
        {
          id: '3',
          name: 'REJECTED',
        },
      ],
      materialTypeOptions: [
        {
          name_hindi: '',
          name: 'Export Cargo',
          id: 2,
          status: 'ACTIVE',
        },
        {
          name_hindi: '',
          name: 'Import Cargo',
          id: 1,
          status: 'ACTIVE',
        },
      ],
    };
  }

  applyFilters = () => {
    const {searchText, agencies} = this.state;
    const finalFilterData =
      agencies.filter(element =>
        Object.values(element)
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()),
      ) || agencies;
    this.setState({
      filterAgencies: finalFilterData,
    });
  };

  getData = async (startDate, endDate, isFilter, selectedStatus) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
    await API.fetchDepartmentVCNNumbers(startDate, endDate, selectedStatus)
      .then(response => {
        console.log(response?.data);
        // const material = response?.data
        //   .filter(item => {
        //     return item?.material.length > 0;
        //   })
        //   .map(element => {
        //     return {
        //       ...element?.material[0],
        //       agencyName: element?.company?.name,
        //       status: element?.status,
        //     };
        //   });
        // const ids = material.map(o => o.vcnnumber);
        // const filteredMaterial = material.filter(
        //   ({vcnnumber}, index) => !ids.includes(vcnnumber, index + 1),
        // );

        console.log('vcn number list', response?.data);

        this.setState({
          agencies: response?.data,
          filterAgencies: response?.data,
          permits: response?.data,
          popup: undefined,
        });
        if (isFilter) {
          this.applyFilters();
        }
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        console.info('error....', errorMsg);
        this.setState({
          popup: undefined,
        });
        // this.setState({
        //   popup: {
        //     type: POPUP_CONSTANTS.SIMPLE_POPUP,
        //     message: errorMsg,
        //     messageStyle: { color: "#E67717" },
        //     buttons: [
        //       {
        //         title: "Okay!",
        //         onPress: this.closePopup,
        //       },
        //     ],
        //   },
        // });
      });
  };

  onGridReady = params => {
    console.log(params.api);
    this.tableAPI = params.api;
    this.setState({tableAPI: params.api});
  };

  getDetailsByVCN = async (data, visitingDate) => {
    const {vcnnumber} = data;
    const {vesselVCNNumber} = this.state;
    console.log('data:', data, visitingDate);

    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
    await API.fetchAllMaterialPermitDetailsForTrafficByVCN(
      vesselVCNNumber || vcnnumber,
      moment(visitingDate).format('MM-DD-YYYY'),
    )
      .then(response => {
        console.log('response', response?.data, 'visitingDate:', visitingDate);
        let exportData = [];
        let importData = [];
        // if (selectedMaterialType === 1) {
        //   importData = response?.data;
        // } else if (selectedMaterialType === 2) {
        //   exportData = response?.data;
        // }
        importData = response?.data;
        this.setState({
          exportData: [],
          importData: response?.data,
          popup: {
            type: POPUP_CONSTANTS.STANDARD,
            heading: `VCN - ${vesselVCNNumber || vcnnumber} - ${moment(
              visitingDate,
            ).format('DD/MM/YYYY')}`,
            message: this.getRequestInfo(data, response?.data || [], []),
            onClose: this.closePopup,
            data: data,
            buttonContainer: styles.buttonContainer,
            buttons:
              exportData.length > 0 || importData.length > 0
                ? [
                    {
                      text: strings.ADMIN.POP_UP.reject,
                      buttonStyles: styles.buttonPrimary,
                      onClick: () => {
                        const {referenceId} = data;
                        const {tableAPI} = this.state;

                        const permitIDList = tableAPI
                          .getSelectedNodes()
                          .map(node => node.data)
                          .map(e => e.permitId);

                        const permitIDs = permitIDList
                          .map((c, index) => {
                            if (permitIDList.indexOf(c) === index) {
                              return {
                                id: c,
                              };
                            }
                          })
                          .filter(item => item !== undefined);

                        this.rejectReason(vesselVCNNumber, data, permitIDs);
                      },
                    },
                    {
                      text: strings.ADMIN.POP_UP.approve,
                      buttonStyles: styles.buttonSecondary,
                      onClick: () => {
                        this.approvePermit(data);
                      },
                    },
                  ]
                : [],
          },
        });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        console.info('error....', errorMsg);
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.STANDARD,
            heading: `VCN - ${vesselVCNNumber}`,
            message: `There was an error while fetching permit details using VCN Number - ${vesselVCNNumber}`,
            data: data,
            onClose: this.closePopup,
            buttonContainer: styles.buttonContainer,
            buttons: [
              {
                text: 'Okay',
                buttonStyles: styles.backButton,
                onClick: this.closePopup,
              },
            ],
          },
        });
      });
  };

  approvePermit = async data => {
    console.log('data...approve', data);
    const {id, vesselVCNNumber} = data;
    const {tableAPI} = this.state;

    const permitIDList = tableAPI
      .getSelectedNodes()
      .map(node => node.data)
      .map(e => e.permitId);

    if (permitIDList.length === 0) {
      this.setState({
        popup: {
          type: POPUP_CONSTANTS.SIMPLE_POPUP,
          message: 'Please select at least one permit to approve/reject',
          buttons: [
            {
              text: strings.okayPopup,
              onClick: this.closePopup,
            },
          ],
          onClose: this.closePopup,
          messageStyle: {
            color: '#202428',
            fontWeight: 'normal',
          },
        },
      });
      return;
    }

    const permitIDs = permitIDList
      .map((c, index) => {
        if (permitIDList.indexOf(c) === index) {
          return {
            id: c,
          };
        }
      })
      .filter(item => item !== undefined);

    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
    await API.approveBulkPermits(permitIDs)
      .then(response => {
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.STANDARD,
            heading: `VCN - ${vesselVCNNumber}`,
            message: this.getSuccessMessage(vesselVCNNumber),
            data: data,
            onClose: this.closePopup,
            buttonContainer: styles.buttonContainer,
            buttons: [
              {
                text: 'Okay',
                buttonStyles: styles.backButton,
                onClick: () => {
                  const {startDate, endDate, selectedStatus} = this.state;
                  let startDateStr = moment(startDate).format('MM-DD-YYYY');
                  let endDateStr = moment(endDate).format('MM-DD-YYYY');
                  this.getData(startDateStr, endDateStr, true, selectedStatus);
                  this.closePopup();
                },
              },
            ],
          },
        });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        console.info('error....', errorMsg);

        this.setState({
          popup: {
            type: POPUP_CONSTANTS.STANDARD,
            heading: `VCN - ${vesselVCNNumber}`,
            message: this.getErrorMessage(vesselVCNNumber),
            data: data,
            onClose: this.closePopup,
            buttonContainer: styles.buttonContainer,
            buttons: [
              {
                text: 'Okay',
                buttonStyles: styles.backButton,
                onClick: this.closePopup,
              },
            ],
          },
        });
      });
  };

  rejectReason = (vesselVCNNumber, data, permitIDs) => {
    if (permitIDs.length === 0) {
      this.setState({
        popup: {
          type: POPUP_CONSTANTS.SIMPLE_POPUP,
          message: 'Please select at least one permit to reject.',
          buttons: [
            {
              text: strings.okayPopup,
              onClick: this.closePopup,
            },
          ],
          onClose: this.closePopup,
          messageStyle: {
            color: '#202428',
            fontWeight: 'normal',
          },
        },
      });
      return;
    }

    this.setState({
      popup: {
        type: POPUP_CONSTANTS.STANDARD,
        heading: `VCN - ${vesselVCNNumber}`,
        message: this.getRejectMessage(),
        data: data,
        onClose: this.closePopup,
        buttonContainer: styles.buttonContainer,
        buttons: [
          {
            text: 'Back',
            buttonStyles: styles.backButton,
            onClick: this.closePopup,
          },
          {
            text: 'Reject',
            buttonStyles: styles.buttonPrimary,
            onClick: () => {
              this.rejectAgency(data, permitIDs);
            },
          },
        ],
      },
    });
  };

  rejectAgency = async (data, permitIDs) => {
    const {vesselVCNNumber} = this.state;
    const {rejectMessage} = this.state;
    console.log('reject message...', rejectMessage);

    if (rejectMessage.length < 2) {
      this.setState({
        showRejectValidation: true,
      });
      alert('Please add reject message');
      return;
    } else {
      this.setState({
        showRejectValidation: false,
      });
    }

    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
    let permitIdsWithReasons = permitIDs.map(el => {
      return {
        id: el.id,
        notes: rejectMessage,
      };
    });
    console.log('permitIdsWithReasons', permitIdsWithReasons);

    await API.rejectBulkPermits(permitIdsWithReasons)
      .then(response => {
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.STANDARD,
            heading: `VCN - ${vesselVCNNumber}`,
            message: this.getRejectConformation(vesselVCNNumber),
            data: data,
            onClose: this.closePopup,
            buttonContainer: styles.buttonContainer,
            buttons: [
              {
                text: 'Okay',
                buttonStyles: styles.backButton,
                onClick: () => {
                  const {startDate, endDate, selectedStatus} = this.state;
                  let startDateStr = moment(startDate).format('MM-DD-YYYY');
                  let endDateStr = moment(endDate).format('MM-DD-YYYY');
                  this.getData(startDateStr, endDateStr, true, selectedStatus);
                  this.closePopup();
                },
              },
            ],
          },
        });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        console.info('error....', errorMsg);
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.STANDARD,
            heading: `VCN - ${vesselVCNNumber}`,
            message: this.getErrorMessage(vesselVCNNumber),
            data: data,
            onClose: this.closePopup,
            buttonContainer: styles.buttonContainer,
            buttons: [
              {
                text: 'Okay',
                buttonStyles: styles.backButton,
                onClick: this.closePopup,
              },
            ],
          },
        });
      });
  };
  componentDidMount() {
    this.getData(null, null);
  }

  getVehicleDetails = props => {
    const {vehicle} = props;
    const {
      name,
      emailAddress,
      phoneNumber,
      vehicleType,
      vehicleNumber,
      vehicleInsuranceNumber,
      vehicleEngineNumber,
      vehiclePollutionNumber,
      chassisNumber,
      vehicleInsuranceCertificate,
      fitnessCertificate,
      vehicleRegistrationNumber,
      puc,
      rcDocument,
    } = props || {};
    console.log('props:', props, vehicle);
    return (
      <div className={styles.cardWrapper}>
        <div>
          <img
            src={adminImages.truckIconBlue}
            className={styles.truckIcon}
            alt="logo"
          />
        </div>
        <div className={styles.info}>
          <div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehicleOwnerName} :
              <span className={styles.requestLabel}> {name} </span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.ownerEmail} :
              <span className={styles.requestLabel}>{emailAddress}</span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.ownerPhoneNumber} :
              <span className={styles.requestLabel}> {phoneNumber} </span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehicleType} :
              <span className={styles.requestLabel}>{vehicleType.name}</span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.document} :
              <span className={styles.requestLabel}>
                {' '}
                {rcDocument ? (
                  <a
                    onClick={() =>
                      this.onClickHandler('document-view', rcDocument)
                    }>
                    {' '}
                    {strings.ADMIN.POP_UP.vehicleRC}{' '}
                    <img
                      className={styles.documentIcon}
                      src={adminImages.documentIcon}
                    />
                  </a>
                ) : null}
              </span>
            </div>
          </div>
          <div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehicleNumber} :
              <span className={styles.requestLabel}>
                {' '}
                {vehicleRegistrationNumber}{' '}
              </span>
            </div>
            {/* <div className={styles.requestKey}>
            {strings.ADMIN.POP_UP.vehicleRCNumber} :
              <span className={styles.requestLabel}>{"-"} </span>
            </div> */}
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehicleInsuranceNumber} :
              <span className={styles.requestLabel}>
                {' '}
                {vehicleInsuranceNumber}{' '}
              </span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.document} :
              <span className={styles.requestLabel}>
                {vehicleInsuranceCertificate ? (
                  <a
                    onClick={() =>
                      this.onClickHandler(
                        'document-view',
                        vehicleInsuranceCertificate,
                      )
                    }>
                    {strings.ADMIN.POP_UP.vehicleInsuranceCertificate}{' '}
                    <img
                      className={styles.documentIcon}
                      src={adminImages.documentIcon}
                    />
                  </a>
                ) : null}
              </span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.document} :
              <span className={styles.requestLabel}>
                {' '}
                {puc ? (
                  <a onClick={() => this.onClickHandler('document-view', puc)}>
                    {' '}
                    {strings.ADMIN.POP_UP.pUCCertificate}{' '}
                    <img
                      className={styles.documentIcon}
                      src={adminImages.documentIcon}
                    />
                  </a>
                ) : null}
              </span>
            </div>
          </div>
          <div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehiclePollutionNumber} :
              <span className={styles.requestLabel}>
                {' '}
                {vehiclePollutionNumber}{' '}
              </span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehicleEngineNumber} :
              <span className={styles.requestLabel}>{vehicleEngineNumber}</span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehicleChassisNumber} :
              <span className={styles.requestLabel}> {chassisNumber} </span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.document} :
              <span className={styles.requestLabel}>
                {' '}
                {fitnessCertificate ? (
                  <a
                    onClick={() =>
                      this.onClickHandler('document-view', fitnessCertificate)
                    }>
                    Fitness Certificate{' '}
                    <img
                      className={styles.documentIcon}
                      src={adminImages.documentIcon}
                    />
                  </a>
                ) : null}{' '}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  getPermitDetailsInfo = data => {
    const {driver} = data;
    const {vehicles, persons, material} = data;
    console.log('vehicle:', data);

    return (
      <div className={styles.requestInfo}>
        {material.map((mat, index) => {
          return (
            <>
              {' '}
              {mat?.materialTypeId === 1 || mat?.materialTypeId === 2 ? (
                <RequestCard
                  key={'person' + index}
                  header={'Material Details' + '-' + (index + 1)}>
                  <AgencyMaterialDetails
                    onClick={this.onClickHandler}
                    {...mat}
                  />
                </RequestCard>
              ) : null}
            </>
          );
        })}

        {persons.map((person, index) => {
          return (
            <RequestCard
              key={'person' + index}
              header={strings.ADMIN.POP_UP.personDetails + '-' + (index + 1)}>
              <AgencyPersonDetails onClick={this.onClickHandler} {...person} />
            </RequestCard>
          );
        })}

        {vehicles.map((vehicle, index) => {
          return (
            <>
              <RequestCard
                key={'vehicle' + index}
                header={
                  strings.ADMIN.POP_UP.vehicleDetails + '-' + (index + 1)
                }>
                {this.getVehicleDetails(vehicle?.vehicle)}
              </RequestCard>
              {vehicle?.driver ? (
                <RequestCard
                  header={
                    strings.ADMIN.POP_UP.driverDetails + '-' + (index + 1)
                  }>
                  <AgencyPersonDetails
                    onClick={this.onClickHandler}
                    {...vehicle?.driver}
                  />
                </RequestCard>
              ) : null}
            </>
          );
        })}
      </div>
    );
  };

  getRequestInfo = (data, importData, exportData) => {
    console.log('material:', data);
    console.log('import and export:', importData, exportData);
    const {materialTypeOptions, selectedMaterialType} = this.state;
    const {vesselName} = data;
    let importMaterialData = [];
    let exportMaterialData = [];
    if (importData.length > 0) {
      importMaterialData = importData.map(element => {
        const temp = {
          permitId: element?.id,
          vehicles: element?.vehicles,
          persons: element?.persons,
          referenceId: element?.referenceId,
          agencyName: element?.company?.name,
          workPurposeName: element?.workPurposeName,
          grossQuantity: element?.material[0]?.lineInfo[0]?.grossQuantity,
          appliedGrossQuantity:
            element?.material[0]?.lineInfo[0]?.appliedGrossQuantity,
          cargoDescription: element?.material[0]?.lineInfo[0]?.cargoDescription,

          ...element?.material[0],
        };
        return temp;
      });

      // tempImportMaterialData.forEach(element => {
      //   element?.lineInfo.map(item => {
      //     importMaterialData.push({
      //       ...element,
      //       ...item,
      //     });
      //   });
      // });
    }
    if (exportData.length > 0) {
      exportMaterialData = exportData.map(element => {
        const temp = {
          permitId: element?.id,
          vehicles: element?.vehicles,
          persons: element?.persons,
          referenceId: element?.referenceId,
          grossQuantity: element?.material[0]?.lineInfo[0]?.grossQuantity,
          appliedGrossQuantity:
            element?.material[0]?.lineInfo[0]?.appliedGrossQuantity,
          cargoDescription: element?.material[0]?.lineInfo[0]?.cargoDescription,
          ...element?.material[0],
        };
        return temp;
      });
    }

    return (
      <Flex direction="column">
        {vesselName ? (
          <div className={styles.vesselName}> Vessel Name: {vesselName}</div>
        ) : null}
        {exportData.length === 0 && importData.length === 0 ? (
          <div className={styles.noPermits}>
            There are no permits available for approval.
          </div>
        ) : null}
        {importMaterialData.length > 0 ? (
          <div>
            {/* <div className={styles.importLabel}>Import Permit Requests</div> */}
            <div className={['ag-theme-balham', styles.tableWrapper].join(' ')}>
              <AgGridReact
                checkboxSelection={true}
                rowStyle={rowStyle}
                getRowStyle={getRowStyle}
                rowHoverHighlight={true}
                columnDefs={importColumnDefs}
                rowHeight={50 * heightConstant}
                headerHeight={50 * heightConstant}
                defaultColDef={{sortable: true}}
                rowClass={[styles.gridRowClass, styles.importDataTable]}
                rowSelection={'multiple'}
                suppressRowClickSelection={true}
                rowMultiSelectWithClick={true}
                onGridReady={this.onGridReady}
                onCellClicked={(event, index) => this.onCellClick(event, index)}
                rowData={importMaterialData}></AgGridReact>
            </div>
          </div>
        ) : null}
        {exportData.length > 0 ? (
          <div>
            <div className={styles.importLabel}>Export Permit Requests</div>
            <div className={['ag-theme-balham', styles.tableWrapper].join(' ')}>
              <AgGridReact
                checkboxSelection={true}
                rowStyle={rowStyle}
                getRowStyle={getRowStyle}
                rowHoverHighlight={true}
                columnDefs={exportColumnDefs}
                rowHeight={50 * heightConstant}
                headerHeight={50 * heightConstant}
                defaultColDef={{sortable: true}}
                suppressRowClickSelection={true}
                rowClass={[styles.gridRowClass, styles.importDataTable]}
                rowSelection={'multiple'}
                rowMultiSelectWithClick={true}
                onGridReady={this.onGridReady}
                onCellClicked={(event, index) =>
                  this.onExportCellClick(event, index)
                }
                rowData={exportMaterialData}></AgGridReact>
            </div>
          </div>
        ) : null}
      </Flex>
    );
  };
  onCellClick = (event, index) => {
    console.log('rowClick', event.data);
    const {importData} = this.state;
    const {vcnnumber} = event.data;

    console.log(importData);
    const tempImportData = importData.filter(
      element => element.id === event.data.permitId,
    );
    this.onClickHandler('permitRowClick', {
      ...tempImportData[0],
      vcnnumber,
    });
  };
  onExportCellClick = (event, index) => {
    const {exportData} = this.state;
    const {vcnnumber} = event.data;
    const tempExportData = exportData.filter(
      element => element.id === event.data.permitId,
    );
    console.log(tempExportData, exportData, event.data);
    this.onClickHandler('permitRowClick', {
      ...tempExportData[0],
      vcnnumber,
    });
  };

  getErrorMessage = referenceId => {
    return (
      <div className={styles.requestSuccess}>
        <div>
          <img
            src={commonImages.errorIcon}
            className={styles.successIcon}
            alt="logo"
          />
        </div>
        <div className={styles.errorMsg}>
          {strings.ADMIN.POP_UP.errorMessage}
        </div>
      </div>
    );
  };

  getSuccessMessage = referenceId => {
    return (
      <div className={styles.requestSuccess}>
        <div>
          <img
            src={loginImages.successIcon}
            className={styles.successIcon}
            alt="logo"
          />
        </div>
        <div className={styles.successMessage}>
          Permit Request with VCN Number {referenceId}{' '}
          {strings.ADMIN.POP_UP.approveCnf2}
        </div>
      </div>
    );
  };
  handleChange = (event, field) => {
    console.log(event.target.value, field);
    this.setState({[field]: event.target.value});
    console.log(this.state);
  };

  getRejectMessage = () => {
    const {showRejectValidation} = this.state;
    return (
      <div className={styles.requestReject}>
        <div className={styles.rejectMessage}>
          {strings.ADMIN.POP_UP.toReject}
        </div>
        <textarea
          placeholder={'Type here...'}
          className={styles.popupTextarea}
          required
          onChange={event => this.handleChange(event, 'rejectMessage')}
        />
        {showRejectValidation ? (
          <div className={styles.error}>Please add reject message.</div>
        ) : null}
      </div>
    );
  };
  getRejectConformation = VCNNumber => {
    return (
      <div className={styles.requestReject}>
        <div className={styles.rejectMessage}>
          Permit Request with VCN number {VCNNumber} has been rejected.
        </div>
        {/* <textarea
          placeholder={"Type here"}
          className={styles.popupTextarea}
          required
        /> */}
      </div>
    );
  };

  onChangeHandler = (action, data) => {
    switch (action) {
      case 'status': {
        this.setState({
          selectedStatus: data,
        });

        // const { agencies } = this.state;
        // const filterAgencies = agencies.filter(
        //   (row) => row.status.toLowerCase() === data.toLowerCase()
        // );
        // this.setState({
        //   filterAgencies,
        //   selectedStatus: data,
        // });

        break;
      }
      case 'selectedMaterialType': {
        this.setState({
          selectedMaterialType: data,
        });
        console.log(data);
        this.setState({
          exportData: [],
          importData: [],
        });
        //  this.getDetailsByVCN(data);
        break;
      }

      case 'date': {
        const {startDate, endDate, dates} = data;
        this.setState({
          startDate,
          endDate,
          dates,
        });
        break;
      }

      case 'search': {
        console.info('data..', data);
        this.setState({
          searchText: data,
        });
        const {agencies} = this.state;
        const filterAgencies = agencies.filter(element =>
          Object.values(element)
            .toString()
            .toLowerCase()
            .includes(data.toLowerCase()),
        );
        this.setState({
          filterAgencies,
        });
        break;
      }

      default: {
        console.info('case not handled', action);
      }
    }
  };

  /**
   *
   * @param {*} action
   * @param {*} data
   */

  onClickHandler = (action, data) => {
    const {history, location} = this.props;

    switch (action) {
      case 'signin': {
        history.push('/sign-in');
        break;
      }
      case 'vehicle-registration': {
        history.push('/vehicle-registration');
        break;
      }
      case 'apply': {
        const {startDate, endDate, selectedStatus} = this.state;
        let startDateStr = moment(startDate).format('MM-DD-YYYY');
        let endDateStr = moment(endDate).format('MM-DD-YYYY');
        this.getData(startDateStr, endDateStr, true, selectedStatus);
        break;
      }
      case 'clearFilters': {
        this.resetData();
        break;
      }
      case 'permitRowClick': {
        console.info('permitRowClick called...', data);
        const {referenceId, vcnnumber} = data;
        const {visitingDate} = this.state;
        this.setState({
          vesselVCNNumber: vcnnumber,
          popup: {
            type: POPUP_CONSTANTS.PERMIT_DETAILS,
            heading: `Agency Permit Requests - ${referenceId}`,
            message: this.getPermitDetailsInfo(data),
            onClose: () => {
              this.getDetailsByVCN(data, visitingDate);
            },
            data: data,
            buttonContainer: styles.buttonContainer,
            buttons: [],
          },
        });
        break;
      }

      case 'rowClick': {
        console.info('rowClick called...', data);
        const {vesselVCNNumber, visitingDate} = data;
        this.setState({
          vesselVCNNumber: vesselVCNNumber,
          visitingDate: visitingDate,
          selectedMaterialType: 1,
          exportData: [],
          importData: [],
        });
        this.getDetailsByVCN(data, visitingDate);
        break;
      }
      case 'document-view': {
        console.log('in document view redirection case');
        history.push({
          pathname: location.pathname + '/document-view',
          state: {background: location, url: data},
        });
        // this.getDocumentTempURL(data);

        break;
      }
      default: {
        console.info('case not handled', action);
      }
    }
  };

  getDocumentTempURL = url => {
    const {history, location} = this.props;
    const data = {
      url: url,
      expiryTime: 30,
    };
    API.preSignedURL(data)
      .then(res => {
        console.log('res.data', res.data?.preSignedUrl);
        history.push({
          pathname: location.pathname + '/document-view',
          state: {background: location, url: res.data?.preSignedUrl},
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
  resetData = () => {
    this.setState({
      searchText: '',
      selectedStatus: '',
      selectedAgency: '',
      selectedMaterialType: 1,
      startDate: new moment().add(-2, 'years').format('MM-DD-YYYY'),
      endDate: new moment().add(1, 'years').format('MM-DD-YYYY'),
      dates: null,
    });
    let yearEndDate = new moment().add(1, 'years').format('MM-DD-YYYY');
    let yearStartDate = new moment().add(-2, 'years').format('MM-DD-YYYY');
    this.getData(yearStartDate, yearEndDate, false);
  };

  getProps = () => {
    const {
      statuses,
      agencies,
      filterAgencies,
      searchText,
      selectedAgency,
      selectedStatus,
      selectedMaterialType,
      startDate,
      endDate,
      dates,
      departmentName,
      materialTypeOptions,
    } = this.state;
    return {
      onClick: this.onClickHandler,
      onChange: this.onChangeHandler,
      statuses,
      materialTypeOptions,
      agencies,
      filterAgencies,
      searchText,
      selectedAgency,
      selectedStatus,
      selectedMaterialType,
      startDate,
      endDate,
      dates,
      departmentName,
    };
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };

  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      // case "spinner": {
      //   console.log('showing spinner');
      //   return <Spinner />;
      // }
      case POPUP_CONSTANTS.STANDARD: {
        return <DeptMaterialPopup {...popup} />;
      }
      case POPUP_CONSTANTS.PERMIT_DETAILS: {
        return <AgencyRquestsPopup {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SPINNER: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

const RequestCard = props => {
  const {header} = props;

  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardheader}>{header}</div>
      {props.children}
    </div>
  );
};

const mapStateToProps = reduxProps => ({
  reduxProps,
});

const mapDispatchToProps = dispatch => ({
  // dispatchSetPersonsData: payload => {
  //   dispatch(setPersonsData(payload));
  // },
});

// export default withRouter(PersonCards);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TrafficDeptPermitRequests),
);
