import React, {Component} from 'react';
import ViewRenderer from './views';
import {API} from '../../../requests';
import './styles.css';
import PopupContainer from '../../../components/PopupContainer';
import POPUP_CONSTANTS from '../../../constants/popup-constants';
import Spinner from '../../../components/Spinner';
import Popup from '../../../components/SimplePopup';
import strings from '../../../globalization';
import util from '../../../util';
import {getFormData} from './data';
import {editFormData} from './editData';
import DeletePopup from '../../../components/DeletePopup';
import PopupData from '../../../components/PopupPerson/popup';
import {operatorImages} from '../../../assets/images';

class Department extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: undefined,

      searchText: '',
      Departments: [],
      filterDepartments: [],
      selectedStatus: '',
      // categoryTypeData: {},
    };
  }
  statuses = [
    {
      id: '',
      name: 'ALL',
    },
    {
      id: 'inactive',
      name: 'INACTIVE',
    },
    {
      id: 'active',
      name: 'ACTIVE',
    },
  ];
  updateStatus = this.statuses.filter(elem => elem.id !== '');
  onSubmit = Department => {
    const DepartmentData = {
      name: Department.name.value,
      name_hindi: Department.name_hindi.value,
    };

    this.saveDepartmentTypeData({
      ...DepartmentData,
    });
    this.setState({
      popup: null,
    });
  };
  showErrorPopup = errorMsg => {
    // crashlytics().recordError(new Error(errorMsg));

    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMsg,
        messageStyle: {color: '#E67717'},

        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.onClose,
          },
        ],
        onClose: this.closePopup,
      },
    });
  };

  showSuccessPopup = (successMessage, onClick) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: successMessage,
        onClose: this.onClose,
        headingImage: operatorImages.successIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: onClick ? onClick : this.onClose,
            outline: true,
          },
        ],
      },
    });
  };

  onClickHandler = (action, data) => {
    switch (action) {
      case 'edit': {
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.EDIT_POPUP,
            statuses: this.updateStatus,
            categoryTypeData: data,
          },
        });

        break;
      }
      case 'delete': {
        data.status === 'ACTIVE' &&
          this.setState({
            Departments: data,
            popup: {type: POPUP_CONSTANTS.DELETE_POPUP},
          });

        break;
      }
      default: {
        console.log('nahi match huya');
        break;
      }
    }
  };
  onClose = () => {
    this.setState({
      popup: null,
    });
  };
  tableActionHandler = (action, data) => {
    switch (action) {
      case 'status': {
        this.setState(
          {
            selectedStatus: data,
          },
          this.applyFilters,
        );
        break;
      }
      case 'add': {
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.ADD_DATA_POPUP,
          },
        });
        break;
      }
      // case 'apply': {
      //   if (this.selectedStatus && this.searchText) {
      //     // this.setState({});
      //     this.applyFilters();
      //   }

      //   this.setState({});
      // }
      case 'search': {
        this.setState(
          {
            searchText: data,
          },
          this.applyFilters,
        );
        break;
      }
      case 'clearFilter': {
        this.setState({
          searchText: '',
          selectedStatus: '',
        });
        // this.fetchAllDepartments();
        break;
      }
      default: {
        console.info('filterChangeHandler case not handled', action);
      }
    }
  };
  applyFilters = () => {
    const {searchText, selectedStatus, Departments} = this.state;
    const filteredData =
      // Departments.length > 0 && //FIXME    applyFilters getting call fetch(API)
      Departments.filter(row => {
        console.log('row', row);
        if (
          searchText &&
          !Object.values({name: row.name, name_hindi: row.name_hindi}) //FIXME filter basis of only name
            .toString()
            .toLowerCase()
            .includes(searchText.toLowerCase())
        ) {
          return false;
        }

        if (
          selectedStatus &&
          row.status.toLowerCase() !== selectedStatus.toLowerCase()
        ) {
          return false;
        }
        return true;
      });
    this.setState({
      filterDepartments: filteredData,
    });
  };
  fetchAllDepartments = () => {
    this.setState({popup: {type: POPUP_CONSTANTS.SPINNER_POPUP}});
    API.fetchDepartmentTypeData()
      .then(res => {
        this.setState(
          {
            Departments: res.data,
            filterDepartments: res.data,
            popup: null,
          },
          // this.applyFilters,
        );
      })
      .catch(err => {
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };
  saveDepartmentTypeData = DepartmentData => {
    let formData = {
      name: DepartmentData.name,
      name_hindi: DepartmentData.name_hindi,
      status: DepartmentData.status,
    };

    this.setState({popup: {type: POPUP_CONSTANTS.SPINNER_POPUP}});
    API.saveDepartmentTypeData(formData)
      .then(res => {
        this.showSuccessPopup(
          'Department Added Successfully',
          this.fetchAllDepartments,
        );
      })
      .catch(error => {
        const err = util.getErrorMessage(error);
        this.showErrorPopup(err);
      });
  };
  onDeleteHandler = () => {
    let id = this.state.Departments.id;
    this.deleteDepartmentTypeData(id);
    this.setState({popup: null});
  };

  deleteDepartmentTypeData = id => {
    this.setState({popup: {type: POPUP_CONSTANTS.SPINNER_POPUP}});
    API.deleteDepartmentType(id)
      .then(res => {
        this.showSuccessPopup(
          'Department Deleted Successfully',
          this.fetchAllDepartments,
        );
      })
      .catch(error => {
        const err = util.getErrorMessage(error);
        this.showErrorPopup(err);
      });
  };
  updateDepartmentTypeData = data => {
    this.setState({popup: {type: POPUP_CONSTANTS.SPINNER_POPUP}});
    API.updateDepartmentTypeData(data)
      .then(res => {
        this.showSuccessPopup(
          'Department Updated Successfully',
          this.fetchAllDepartments,
        );
      })
      .catch(error => {
        const err = util.getErrorMessage(error);
        this.showErrorPopup(err);
      });
  };
  onEditHandler = data => {
    console.log('data,', data);
    let myData = {
      // id: this.state.categoryTypeData.id,
      id: data.id.value,
      name: data.name.value,
      name_hindi: data.name_hindi.value,
      status: data.status.value.toUpperCase(),
    };
    console.log('mydata,', myData);

    this.updateDepartmentTypeData(myData);
    this.setState({popup: null});
  };
  getProps = () => {
    const {searchText, filterDepartments, selectedStatus} = this.state;
    return {
      onClick: this.onClickHandler,
      onChange: this.onChangeHandler,
      filterChangeHandler: this.tableActionHandler,
      Departments: filterDepartments,
      statuses: this.statuses,
      searchText,
      selectedStatus,
    };
  };

  getPopupContent = () => {
    const {popup} = this.state;
    switch (popup.type) {
      case POPUP_CONSTANTS.ADD_DATA_POPUP: {
        return (
          <PopupData
            header="Add"
            onSubmit={this.onSubmit}
            onClose={this.onClose}
            getFormData={getFormData}
            {...popup}
          />
        );
      }
      case POPUP_CONSTANTS.EDIT_POPUP: {
        return (
          <PopupData
            header="Edit"
            onSubmit={this.onEditHandler}
            onClose={this.onClose}
            getFormData={editFormData}
            {...popup}
          />
        );
      }
      case POPUP_CONSTANTS.DELETE_POPUP: {
        return (
          <DeletePopup onClick={this.onDeleteHandler} onClose={this.onClose} />
        );
      }
      // case POPUP_CONSTANTS.SUCCESS_POPUP: {
      //   return (
      //     <div style={{color: 'red', height: '100px', width: '300px'}}>
      //       success pop
      //     </div>
      //   );
      // }
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };
  componentDidMount() {
    this.fetchAllDepartments();
  }

  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

export default Department;
