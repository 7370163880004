import React from 'react';
import styles from './styles.module.scss';
import {AgGridReact} from 'ag-grid-react';
import {operatorImages} from '../../../../assets/images';

import dimensions from '../../../../util/dimensions-util';
import Flex from '../../../../components/Flex';

const {vw: widthConstant, vh: heightConstant} = dimensions;

export default function Desktop({operators}) {
  const columnDefs = [
    {
      headerName: 'Operator ID No.',
      field: 'idCardNumber',
      headerClass: styles.rowHeader,
      width: 180 * widthConstant,
      resizable: true,
      cellRendererFramework: ({data: {idCardNumber}}) => {
        return <div className={styles.sessionTime}>{idCardNumber}</div>;
      },
    },
    {
      headerName: 'Operator Name',
      field: 'opertatorName',
      headerClass: styles.rowHeader,
      width: 220 * widthConstant,
      resizable: true,
      cellRendererFramework: ({data: {user}}) => {
        return <div className={styles.sessionTime}>{user?.firstName}</div>;
      },
    },
    {
      headerName: 'Mobile Number',
      field: 'phoneNumber',
      headerClass: styles.rowHeader,
      width: 220 * widthConstant,
      resizable: true,
      cellRendererFramework: ({data: {user}}) => {
        return <div className={styles.sessionTime}>{user?.phoneNumber}</div>;
      },
    },
    {
      headerName: 'Location',
      field: 'location',
      headerClass: styles.rowHeader,
      width: 200 * widthConstant,
      resizable: true,
      cellRendererFramework: ({data: {location}}) => {
        return <div className={styles.sessionTime}>{location.name}</div>;
      },
    },
    {
      headerName: 'Role',
      field: 'role',
      headerClass: styles.rowHeader,
      width: 200 * widthConstant,
      resizable: true,
      cellRendererFramework: ({data: {user}}) => {
        return <div className={styles.sessionTime}>{user?.role?.name}</div>;
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      headerClass: styles.rowHeader,
      width: 170 * widthConstant,
      resizable: true,
      cellRendererFramework: ({data: {status}}) => {
        const styleClass =
          status === 'Active' ? styles.activeStatus : styles.inActiveStatus;
        const dotClass = status === 'Active' ? styles.dot : styles.inActiveDot;
        return (
          <div className={styleClass}>
            <div className={styles.statusLabel}>
              <span className={dotClass}></span>
              {status}
            </div>
          </div>
        );
      },
    },
    {
      headerName: 'Actions',
      field: 'visitingDate',
      headerClass: styles.rowHeader,
      width: 195 * widthConstant,
      resizable: true,
      cellRendererFramework: ({data: {idCardNumber, id}}) => {
        return (
          <div className={styles.actions}>
            <img
              //   onClick={event => editRow(event, id)}
              src={operatorImages.editIcon}
              alt="edit"
              className={styles.image}
            />
            <img
              //   onClick={event => deleteRow(event, id)}
              src={operatorImages.deleteIcon}
              alt="delete"
              className={styles.image}
            />
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <p>Playground</p>
      <div className={['ag-theme-balham', styles.tableWrapper].join(' ')}>
        <AgGridReact
          columnDefs={columnDefs}
          rowHeight={44}
          headerHeight={60}
          defaultColDef={{sortable: true}}
          rowClass={[styles.gridRowClass]}
          //   onCellClicked={(event, index) => onCellClick(event, index)}
          rowData={operators}
          domLayout="autoHeight"></AgGridReact>
      </div>
    </div>
  );
}
