export const rowData = [
  {
    operatorId: '8648758HHG76',
    opertatorName: 'Vinay Patel',
    shift: 'shift 1',
    role: 'General Employee',
    clockIn: '10:00 AM',
    clockOut: '10:00 AM',
    totalHours: '12 hrs',
    attendance: 'present',
  },
  {
    operatorId: '8648758HHG76',
    opertatorName: 'Vinay Patel',
    shift: 'shift 1',
    role: 'General Employee',
    clockIn: '10:00 AM',
    clockOut: '10:00 AM',
    totalHours: '12 hrs',
    attendance: 'onLeave',
  },
  {
    operatorId: '8648758HHG76',
    opertatorName: 'Vinay Patel',
    shift: 'shift 1',
    role: 'General Employee',
    clockIn: '10:00 AM',
    clockOut: '10:00 AM',
    attendance: 'present',
    totalHours: '12 hrs',
  },
  {
    operatorId: '8648758HHG76',
    opertatorName: 'Vinay Patel',
    shift: 'shift 1',
    role: 'General Employee',
    clockIn: '10:00 AM',
    clockOut: '10:00 AM',
    attendance: 'On Leave',
    totalHours: '12 hrs',
  },
  {
    operatorId: '8648758HHG76',
    opertatorName: 'Vinay Patel',
    shift: 'shift 1',
    role: 'General Employee',
    clockIn: '10:00 AM',
    clockOut: '10:00 AM',
    attendance: 'present',
    totalHours: '12 hrs',
  },
  {
    operatorId: '8648758HHG76',
    opertatorName: 'Vinay Patel',
    shift: 'shift 1',
    role: 'General Employee',
    clockIn: '10:00 AM',
    clockOut: '10:00 AM',
    attendance: 'On Leave',
    totalHours: '12 hrs',
  },
  {
    operatorId: '8648758HHG76',
    opertatorName: 'Vinay Patel',
    shift: 'shift 1',
    role: 'General Employee',
    clockIn: '10:00 AM',
    clockOut: '10:00 AM',
    attendance: 'present',
    totalHours: '12 hrs',
  },
  {
    operatorId: '8648758HHG76',
    opertatorName: 'Vinay Patel',
    shift: 'shift 1',
    role: 'General Employee',
    clockIn: '10:00 AM',
    clockOut: '10:00 AM',
    attendance: 'On Leave',
    totalHours: '12 hrs',
  },
  {
    operatorId: '8648758HHG76',
    opertatorName: 'Vinay Patel',
    shift: 'shift 1',
    role: 'General Employee',
    clockIn: '10:00 AM',
    clockOut: '10:00 AM',
    attendance: 'onLeave',
    totalHours: '12 hrs',
  },
  {
    operatorId: '8648758HHG76',
    opertatorName: 'Vinay Patel',
    shift: 'shift 1',
    role: 'General Employee',
    clockIn: '10:00 AM',
    clockOut: '10:00 AM',
    attendance: 'On Leave',
    totalHours: '12 hrs',
  },
  {
    operatorId: '8648758HHG76',
    opertatorName: 'Vinay Patel',
    shift: 'shift 1',
    role: 'General Employee',
    clockIn: '10:00 AM',
    clockOut: '10:00 AM',
    attendance: 'On Leave',
    totalHours: '12 hrs',
  },
  {
    operatorId: '8648758HHG76',
    opertatorName: 'Vinay Patel',
    shift: 'shift 1',
    role: 'General Employee',
    clockIn: '10:00 AM',
    clockOut: '10:00 AM',
    attendance: 'present',
    totalHours: '12 hrs',
  },
  {
    operatorId: '8648758HHG76',
    opertatorName: 'Vinay Patel',
    shift: 'shift 1',
    role: 'General Employee',
    clockIn: '10:00 AM',
    clockOut: '10:00 AM',
    attendance: 'On Leave',
    totalHours: '12 hrs',
  },
  {
    operatorId: '8648758HHG76',
    opertatorName: 'Vinay Patel',
    shift: 'shift 1',
    role: 'General Employee',
    clockIn: '10:00 AM',
    clockOut: '10:00 AM',
    attendance: 'present',
    totalHours: '12 hrs',
  },
  {
    operatorId: '8648758HHG76',
    opertatorName: 'Vinay Patel',
    shift: 'shift 1',
    role: 'General Employee',
    clockIn: '10:00 AM',
    clockOut: '10:00 AM',
    attendance: 'On Leave',
    totalHours: '12 hrs',
  },
  {
    operatorId: '8648758HHG76',
    opertatorName: 'Vinay Patel',
    shift: 'shift 1',
    role: 'General Employee',
    clockIn: '10:00 AM',
    clockOut: '10:00 AM',
    attendance: 'present',
    totalHours: '12 hrs',
  },
  {
    operatorId: '8648758HHG76',
    opertatorName: 'Vinay Patel',
    shift: 'shift 1',
    role: 'General Employee',
    clockIn: '10:00 AM',
    clockOut: '10:00 AM',
    attendance: 'On Leave',
    totalHours: '12 hrs',
  },
  {
    operatorId: '8648758HHG76',
    opertatorName: 'Vinay Patel',
    shift: 'shift 1',
    role: 'General Employee',
    clockIn: '10:00 AM',
    clockOut: '10:00 AM',
    attendance: 'present',
    totalHours: '12 hrs',
  },
  {
    operatorId: '8648758HHG76',
    opertatorName: 'Vinay Patel',
    shift: 'shift 1',
    role: 'General Employee',
    clockIn: '10:00 AM',
    clockOut: '10:00 AM',
    attendance: 'present',
    totalHours: '12 hrs',
  },
  {
    operatorId: '8648758HHG76',
    opertatorName: 'Vinay Patel',
    shift: 'shift 1',
    role: 'General Employee',
    clockIn: '10:00 AM',
    clockOut: '10:00 AM',
    attendance: 'On Leave',
    totalHours: '12 hrs',
  },
];
