import React, { useState } from 'react';
import styles from './styles.module.scss';
import AuthLayout from '../../../../components/AuthLayout';
import REQUEST_TYPE_OPTIONS from '../../../../constants/request-type-options';

import { withRouter } from 'react-router-dom';
import strings from '../../../../globalization/index';
import FormElement from '../../../../components/FormElement';
import Flex from '../../../../components/Flex';
import Button from '../../../../components/Button';
import { loginImages } from '../../../../assets/images';
import SeparatorComponent from '../../../../components/Sparator';

const Desktop = ({ backClick, onSubmit, stateData, onChangeHandler }) => {
  const [requestType, setRequestType] = useState(2);
  const placeholderTextConstant = {
    person: {
      label: 'Reference/Aadhaar Number',
      placeholder: 'Enter Reference or Aadhaar Number',
    },
    vehicle: {
      label: 'Reference/Vehicle Number/RFID',
      placeholder: 'Enter Reference or Vehicle Number or RFID',
    },
    agency: {
      label: 'Reference Number',
      placeholder: 'Enter Reference Number',
    },
  };
  return (
    // <AuthLayout>
    <Flex direction="column" className={styles.container}>
      <Flex onClick={backClick} className={styles.backlabel}>
        <img
          className={styles.backButtonIcon}
          src={loginImages.backButtonIcon}
          alt="back"
        />
        <span>{strings.back}</span>
      </Flex>
      <Flex direction="column" className={styles.wrapper}>
        <div>
          <header>
            <h1 className={styles.header}>{`Check Permit Status`}</h1>
          </header>
          <Flex direction="column" className={styles.formContainer}>
            <FormElement
              type="dropdown"
              label={strings.requestTypeLabel}
              required={true}
              placeholderText={strings.requestTypePlaceholder}
              onChange={text => {
                setRequestType(text);
                console.log(text);
                onChangeHandler('requestType', text);
              }}
              value={stateData.requestType}
              maxLength={30}
              options={stateData.requestTypeOptions}
            />
            <FormElement
              type="text"
              label={
                requestType === 1
                  ? placeholderTextConstant.person.label
                  : requestType === 2
                    ? placeholderTextConstant.vehicle.label
                    : strings.referenceNumberLabel
              }
              required={true}
              placeholderText={
                requestType === 1
                  ? placeholderTextConstant.person.placeholder
                  : requestType === 2
                    ? placeholderTextConstant.vehicle.placeholder
                    : strings.referenceNumberPlaceholder
              }
              onChange={text => {
                onChangeHandler('referenceId', text);
              }}
              value={stateData.referenceId}
              maxLength={30}
            />
            <Button
              buttonStyle="primary"
              label={stateData.otpVerified ? `Check Status` : `Get OTP`}
              className={styles.submitButton}
              onClick={onSubmit}
            />
          </Flex>
        </div>
      </Flex>
    </Flex>
    // </AuthLayout>
  );
};
export default withRouter(Desktop);
