import React from 'react';
import styles from './styles.module.scss';

import illustration from '../../assets/images/help-center/call-center/image.png';
import phone from '../../assets/images/help-center/telephone/image@2x.png';
import email from '../../assets/images/help-center/message/image@2x.png';
import Button from '../EDrishtiDesignSystem/Button';
import clsx from 'clsx';
import IconButton from '../EDrishtiDesignSystem/IconButton';
const HelpCenterPopupContent = ({onClose}) => {
  return (
    <div className={styles.helpCenter}>
      <IconButton
        onClick={onClose}
        variant="cross"
        className={styles.crossIcon}
      />
      <img
        src={illustration}
        className={styles.illustration}
        alt="illustration"
      />
      <div className={clsx(styles.content, styles.customPadding)}>
        <div className={styles.cardBody}>
          <img src={phone} className={styles.icon} alt="telephone" />
          <div className={styles.cardTextContent}>
            <div>
              <p>+91 9316546987</p>
              <span>(Support Number 1)</span>
            </div>
            <div>
              <p>+91 6354448297</p>
              <span>(Support Number 2)</span>
            </div>
            <div>
              <p>10 AM to 5:30 PM</p>
              <span>(Timings)</span>
            </div>
          </div>
        </div>
        <div className={styles.cardBody}>
          <img src={email} className={styles.icon} alt="email" />
          <div className={styles.cardTextContent}>
            <div>
              <p>edrishti@sanya.ai</p>
            </div>
            <div>
              <span>(Please write us for any support queries)</span>
            </div>
          </div>
        </div>
        <div className={styles.divide}></div>
        <p className={styles.instructionsHeader}>
          Quick reference guides for registration
        </p>
        <div className={styles.buttonsContainer}>
          <Button
            label="Person Registration"
            buttonStyle="primary-outline"
            icon="rightArrowBlue"
            className={styles.CTAButton}
            as="a"
            href="https://edrishti.sanya.ai/user-manual/a1d7cbbb-3f1a-4362-97b8-d4a872fd7129PersonRegistration.pdf"
            target="__blank"
          />
          <Button
            label="Vehicle Registration"
            buttonStyle="primary-outline"
            icon="rightArrowBlue"
            className={styles.CTAButton}
            as="a"
            href="https://edrishti.sanya.ai/user-manual/48a58c43-aee3-4aaf-a264-d50ff1749810VehicleRegistration.pdf"
            target="__blank"
          />
        </div>
      </div>
    </div>
  );
};

export default HelpCenterPopupContent;
