import React, {useEffect, useState} from 'react';
import Flex from '../../Flex';
import SannaLogo from '../../SannaLogo';
import styles from './styles.module.scss';

import first from '../../../assets/images/Landing/why-us/1.png';
import second from '../../../assets/images/Landing/why-us/2.png';
import third from '../../../assets/images/Landing/why-us/3.png';
import fourth from '../../../assets/images/Landing/why-us/4.png';
import fifth from '../../../assets/images/Landing/why-us/5.png';
import sixth from '../../../assets/images/Landing/why-us/6.png';

import {motion} from 'framer-motion';
import clsx from 'clsx';
let interval;
export const WhyUs = () => {
  const icons = [
    {id: 0, src: first, text: 'Cloud Based Architecture'},
    {id: 1, src: second, text: 'Reduced OPEX'},
    {id: 2, src: third, text: 'Intuitive'},
    {id: 3, src: fourth, text: 'Customizable'},
    {id: 4, src: fifth, text: 'Dynamic'},
    {id: 5, src: sixth, text: '3rd Party Integration'},
  ];

  return (
    <div className={styles.whyUsContainer}>
      <h2 className={styles.header}>Why Us</h2>
      <p className={styles.subHeader}>
        Sanna’s functionality can be customized according to the requirements of
        each client. Our blend of hardware, software, and automation
        technologies will boost productivity and ease operations.
      </p>
      <div className={styles.grid}>
        {icons.map((el, idx) => (
          <div
            key={`grid-why-us-${idx}`}
            className={clsx(
              styles.gridItem,
              idx !== icons.length - 1 ? styles.divider : null,
            )}>
            <img
              src={el.src}
              alt="icon"
              style={{height: '2rem', width: '2rem', objectFit: 'contain'}}
            />
            <div
              style={{height: '4rem', display: 'flex', alignItems: 'center'}}>
              <p>{el.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
