import React from 'react';
import Flex from '../../Flex';
import FormElement from '../../FormElement';

import styles from './Styles.module.css';

const ShiftRows = ({elem, idx, onShiftChange}) => {
  console.log('SHIFTROWS elem', elem);
  return (
    <Flex key={idx} className={styles.shiftRows}>
      <div style={{width: '40%'}}>
        <FormElement
          type="text"
          label="Shift"
          required
          value={elem.name.value}
          error={elem.name.error}
          placeholderText={`Shift ${idx + 1}`}
          tabIndex={idx}
          containerClassNames={styles.containerClassNames}
          labelClassNames={styles.labelClassNames}
          onChange={text => onShiftChange('name', elem, text)}
          maxLength={128}
        />
      </div>
      <Flex
        direction="row"
        style={{
          width: '60%',
          justifyContent: 'space-between',
          marginLeft: '20px',
          marginRight: '20px',
        }}>
        <FormElement
          type="timepicker"
          label="Start Time"
          required
          value={elem.startTime.value}
          error={elem.startTime.error}
          placeholderText="Enter Start Time"
          tabIndex={idx + 1}
          containerClassNames={styles.timeContainerClassNames}
          labelClassNames={styles.labelClassNames}
          onChange={updatedStartDate =>
            onShiftChange('startTime', elem, updatedStartDate)
          }
        />

        <FormElement
          type="timepicker"
          label="End Time"
          required
          value={elem.endTime.value}
          error={elem.endTime.error}
          placeholderText="Enter End Time"
          containerClassNames={styles.timeContainerClassNames}
          labelClassNames={styles.labelClassNames}
          tabIndex={idx + 1}
          onChange={updatedEndDate =>
            onShiftChange('endTime', elem, updatedEndDate)
          }
        />
      </Flex>
    </Flex>
  );
};

export default ShiftRows;
