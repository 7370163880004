import strings from '../../../globalization';

const formLeftFields = [
  {
    elementType: 'text',
    inputType: 'text',
    label: `${strings.locationId}`,
    placeholder: `${strings.enterLocationId}`,
    required: true,
    stateField: 'locationId',
    stateDefault: null,
    maxLength: 20,
  },
  {
    elementType: 'text',
    inputType: 'number',
    label: `${strings.maxOperatorsLimit}`,
    placeholder: `${strings.enterMaxOperatorsLimit}`,
    required: true,
    stateField: 'maxOperatorsLimit',
    stateDefault: '',
    maxLength: 10,
  },
];
const formRightFields = [
  {
    elementType: 'text',
    inputType: 'text',
    label: `${strings.locationName}`,
    placeholder: `${strings.enterLocationName}`,
    required: true,
    stateField: 'locationName',
    stateDefault: null,
    minLength: 6,
    maxLength: 128,
  },
  {
    elementType: 'dropdown',
    inputType: 'text',
    label: strings.numberOfShifts,
    placeholder: strings.selectShift,
    optionsField: 'numberOfShiftsOptions',
    required: true,
    stateField: 'numberOfShifts',
    stateDefault: null,
    maxLength: 20,
  },
];

const shiftFormLeftFields = [
  {
    stateField: 'shift1',
    elementType: 'dropdown',
    inputType: 'text',
    label: `${strings.shift}`,
    placeholder: `${strings.shift1}`,
    required: true,
    optionsField: 'optionsField',
    stateDefault: null,
    maxLength: 20,
  },
  {
    stateField: 'shift2',
    elementType: 'dropdown',
    inputType: 'text',
    label: `${strings.shift}`,
    placeholder: `${strings.shift2}`,
    required: true,
    optionsField: 'optionsField',
    stateDefault: null,
    maxLength: 20,
  },
  {
    stateField: 'shift3',
    elementType: 'dropdown',
    inputType: 'text',
    label: `${strings.shift}`,
    placeholder: `${strings.shift3}`,
    required: true,
    optionsField: 'optionsField',
    stateDefault: null,
    maxLength: 20,
  },
];

const shiftFormMiddleFields = [
  {
    elementType: 'timepicker',
    inputType: 'text',
    label: `${strings.startTime}`,
    placeholder: `${strings.startTime}`,
    required: true,
    stateField: 'shift1StartTime',
    stateDefault: null,
    minLength: 6,
    maxLength: 128,
  },
  {
    elementType: 'timepicker',
    inputType: 'text',
    label: `${strings.startTime}`,
    placeholder: `${strings.startTime}`,
    required: true,
    stateField: 'shift2StartTime',
    stateDefault: null,
    minLength: 6,
    maxLength: 128,
  },
  {
    elementType: 'timepicker',
    inputType: 'text',
    label: `${strings.startTime}`,
    placeholder: `${strings.startTime}`,
    required: true,
    stateField: 'shift3StartTime',
    stateDefault: null,
    minLength: 6,
    maxLength: 128,
  },
];

const shiftFormRightFields = [
  {
    elementType: 'timepicker',
    inputType: 'time',
    label: `${strings.endTime}`,
    placeholder: `${strings.endTime}`,
    required: true,
    stateField: 'shift1EndTime',
    stateDefault: null,
    minLength: 6,
    maxLength: 128,
  },
  {
    elementType: 'timepicker',
    inputType: 'text',
    label: `${strings.endTime}`,
    placeholder: `${strings.endTime}`,
    required: true,
    stateField: 'shift2EndTime',
    stateDefault: null,
    minLength: 6,
    maxLength: 128,
  },
  {
    elementType: 'timepicker',
    inputType: 'text',
    label: `${strings.endTime}`,
    placeholder: `${strings.endTime}`,
    required: true,
    stateField: 'shift3EndTime',
    stateDefault: null,
    minLength: 6,
    maxLength: 128,
  },
];

export {
  formLeftFields,
  formRightFields,
  shiftFormLeftFields,
  shiftFormMiddleFields,
  shiftFormRightFields,
};
