import strings from '../../../globalization';
export const editFormData = () => {
  return [
    {
      elementType: 'text',
      // keyboardType: 'default',
      inputType: 'name',
      label: 'Name',
      placeholder: 'Name',
      required: true,
      //name: 'name',
      stateField: 'name',
      error: ' At Least Four Characters Required',
      stateDefault: '',
      minLength: 4,
      maxLength: 120,
      dependentOnOtherFields: false,
    },
    {
      elementType: 'text',
      //keyboardType: 'default',
      inputType: 'name_hindi',
      // name: 'name_hindi',
      label: 'Name Hindi',
      placeholder: 'Name Hindi',
      error: ' At Least Four Characters Required',
      required: true,
      stateField: 'name_hindi',
      stateDefault: '',
      minLength: 4,
      maxLength: 120,
      dependentOnOtherFields: false,
    },
    {
      elementType: 'dropdown',
      inputType: 'text',
      label: strings.adminStatus,
      placeholder: strings.adminStatusPlaceholder,
      optionsField: 'statuses',
      required: true,
      stateField: 'status',
      stateDefault: null,
      maxLength: 20,
      error: 'Field is required',
      dependentOnOtherFields: false,
    },
  ];
};
