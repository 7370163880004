import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss';

import mroadsLogo from '../../../assets/images/Landing/footer/mroads/image.png';
export const Footer = () => {
  return (
    <div className={styles.footerContainer}>
      <p>Copyright © {new Date().getFullYear()} saññã All rights reserved.</p>
      {/* <img src={mroadsLogo} alt="mroads logo" className={styles.logo} /> */}
      <svg
        style={{width: '6rem', objectFit: 'contain'}}
        width="305"
        height="85"
        viewBox="0 0 305 85"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.1427 81.3018C9.66922 79.889 6.60922 77.6452 4.29355 74.7365C2.14328 72.0356 0.861389 68.7945 0.613281 65.9689H17.3192C17.4846 67.2155 18.4771 68.836 20.0071 69.9164C21.7025 71.0799 23.7287 71.7031 25.8376 71.6616C27.9465 71.6616 29.5592 71.2461 30.7171 70.4566C31.7922 69.7917 32.4538 68.5867 32.4538 67.3401C32.4538 65.8027 31.6268 64.6808 29.9727 63.9328C27.3676 62.894 24.7211 62.1045 21.9506 61.4812C18.6011 60.7748 15.293 59.8191 12.0676 58.6557C9.42111 57.6584 7.0641 56.0378 5.24464 53.9602C3.34248 51.841 2.39139 48.9324 2.39139 45.2757C2.39139 42.284 3.30112 39.3753 5.03787 36.8821C6.77463 34.3474 9.37978 32.3945 12.7706 30.8986C16.1614 29.4442 20.2138 28.6963 24.8865 28.6963C31.7922 28.6963 37.2506 30.3584 41.2203 33.6826C45.19 37.0068 47.5057 41.4114 48.0433 49.3479H32.4538C32.2471 45.1926 31.3373 43.5721 29.9314 42.4917C28.5254 41.4114 26.6646 40.8712 24.3489 40.8712C22.3641 40.8712 20.8754 41.2451 19.8003 41.9931C18.7665 42.6579 18.1876 43.8214 18.229 45.0264C18.229 46.5639 19.056 47.6858 20.7514 48.4753C22.4468 49.2232 25.0519 50.0127 28.6081 50.7607C31.9989 51.5502 35.3484 52.589 38.6152 53.794C41.2617 54.8328 43.5773 56.4534 45.3968 58.5725C47.3403 60.7748 48.3327 63.7251 48.4154 67.4232C48.4568 70.415 47.5057 73.3652 45.6863 75.8168C43.8668 78.2684 41.2617 80.2214 37.8709 81.6342C34.1079 83.0885 30.0968 83.8365 26.0857 83.7534C21.6611 83.8365 17.2779 83.0054 13.2254 81.3433L13.1427 81.3018Z"
          fill="black"
          stroke="black"
          stroke-width="0.64"
        />
        <path
          d="M98.5737 35.8849C98.5737 34.1812 98.5737 32.6853 98.5737 31.1479C98.5737 30.5246 98.7391 30.4415 99.318 30.4415C104.611 30.4415 109.904 30.4415 115.197 30.4415C115.817 30.4415 115.941 30.6077 115.941 31.1894C115.941 42.4086 115.941 53.5862 115.941 64.7639V81.2186C115.941 81.6342 115.61 81.925 115.238 81.925C109.945 81.925 104.652 81.925 99.318 81.925C98.6978 81.925 98.5737 81.8004 98.5737 81.2186C98.6151 79.6812 98.5737 78.1853 98.5737 76.5232C98.0361 77.1465 97.6226 77.6867 97.1677 78.1853C94.6867 80.6785 91.4613 82.3406 87.9464 82.9223C78.601 84.7506 69.8759 83.3378 62.2259 77.3958C56.7675 73.1575 53.7075 67.4232 52.6737 60.6917C51.764 54.8744 52.4256 49.1817 55.1134 43.8629C59.0832 36.0926 65.658 31.5218 74.135 29.5689C79.304 28.2807 84.7623 28.4054 89.8899 30.0259C93.3221 31.1063 96.3407 33.1424 98.5323 35.9264L98.5737 35.8849ZM82.7361 69.2931C84.0594 69.2931 85.424 69.1684 86.7059 68.8776C92.0402 67.631 95.4723 64.3068 96.8369 59.1543C98.1602 54.0018 96.5061 49.5556 92.3296 46.2314C87.9464 42.741 82.8188 42.4086 77.6086 43.9045C70.5375 45.9406 66.6091 53.8355 69.1729 60.5255C71.2818 66.0104 76.3267 69.2931 82.7361 69.2931Z"
          fill="black"
          stroke="black"
          stroke-width="0.64"
        />
        <path
          d="M289.618 35.8848V31.0647C289.618 30.5661 289.742 30.483 290.197 30.483H305.579C306.076 30.483 306.158 30.6077 306.158 31.1063V81.3433C306.158 81.8419 306.034 81.9666 305.579 81.9666C300.452 81.9666 295.324 81.9666 290.197 81.9666C289.742 81.9666 289.576 81.8835 289.618 81.3848C289.618 79.8474 289.618 78.2684 289.618 76.4816C288.046 78.8086 285.896 80.5953 283.374 81.6757C279.652 83.2962 275.6 83.9611 271.589 83.6702C266.296 83.4209 261.375 82.0081 256.95 78.9332C250.913 74.7364 247.357 68.836 246.034 61.4812C244.958 55.3729 245.537 49.4725 248.266 43.9045C252.029 36.1757 258.274 31.6049 266.337 29.6519C271.258 28.3223 276.468 28.4885 281.348 30.0675C284.614 31.1063 287.509 33.1839 289.618 35.9679V35.8848ZM276.385 71.2045C277.709 71.2045 279.073 71.0798 280.355 70.7889C285.689 69.5424 289.122 66.2182 290.486 61.0656C291.809 55.9131 290.155 51.467 285.979 48.1844C281.596 44.7355 276.468 44.3615 271.258 45.8574C264.187 47.8935 260.258 55.7885 262.822 62.4369C264.931 67.9218 269.976 71.2045 276.385 71.2045Z"
          fill="black"
          stroke="black"
          stroke-width="0.64"
        />
        <path
          d="M140.547 37.5469C141.457 36.3419 142.532 35.22 143.69 34.2227C146.295 32.1867 149.438 30.9401 152.746 30.6492C156.715 30.1506 160.809 30.3999 164.655 31.4803C171.685 33.4748 175.737 38.1287 177.267 45.0264C177.722 47.0625 177.929 49.1401 177.929 51.2593V82.9223C177.929 83.3793 177.556 83.7533 177.102 83.7533C171.685 83.7533 166.309 83.7533 160.892 83.7533C160.23 83.7533 160.106 83.5871 160.106 82.9638C160.106 73.7807 160.106 64.5561 160.106 55.373C160.106 53.6693 159.982 52.0072 159.61 50.3451C158.742 46.8547 156.467 44.8186 152.787 44.3615C151.174 44.1538 149.52 44.1953 147.908 44.5278C143.979 45.2757 141.746 47.5611 141.043 51.4255C140.795 52.7551 140.712 54.1264 140.712 55.4976V81.9666C140.712 82.4236 140.34 82.7561 139.927 82.7561C134.923 82.7561 129.92 82.7561 124.875 82.7561C124.461 82.7561 124.172 82.4236 124.172 82.0497V32.893C124.172 32.3113 124.296 32.1451 124.916 32.1451C129.837 32.1451 134.758 32.1451 139.679 32.1451C140.258 32.1451 140.464 32.2698 140.423 32.8515C140.464 34.3474 140.506 35.8433 140.506 37.5469H140.547Z"
          fill="black"
          stroke="black"
          stroke-width="0.64"
        />
        <path
          d="M202.533 37.5885C204.807 34.389 208.198 32.062 212.043 31.1063C214.855 30.4415 217.709 30.2337 220.562 30.4415C224.077 30.6077 227.509 31.3141 230.652 32.9762C235.531 35.594 238.136 39.7492 239.335 44.9017C239.79 46.9378 239.997 49.0154 239.997 51.1346V82.8807C239.997 83.3378 239.625 83.6702 239.211 83.6702C233.794 83.6702 228.377 83.6702 222.919 83.6702C222.547 83.6702 222.216 83.3378 222.216 82.9638C222.216 73.7392 222.216 64.5561 222.216 55.3314C222.216 53.6278 222.092 51.9657 221.72 50.3036C220.851 46.7301 218.577 44.7355 214.855 44.2785C213.077 44.0291 211.258 44.1122 209.48 44.5278C205.965 45.3173 203.773 47.6858 203.112 51.3424C202.905 52.6721 202.781 54.0433 202.781 55.4145V81.8835C202.781 82.299 202.45 82.6314 202.036 82.6314C196.992 82.6314 191.947 82.6314 186.902 82.6314C186.53 82.6314 186.24 82.3406 186.24 81.9666V32.81C186.24 32.3113 186.323 32.1036 186.943 32.1036C191.905 32.1036 196.868 32.1036 201.83 32.1036C202.367 32.1036 202.574 32.1867 202.533 32.7684C202.533 34.3059 202.533 35.8017 202.533 37.5469V37.5885Z"
          fill="black"
          stroke="black"
          stroke-width="0.64"
        />
        <path
          d="M235.24 0.68988C235.943 2.93372 236.604 5.136 237.307 7.37984C237.928 9.45746 238.548 11.5351 239.21 13.6127C239.334 13.862 239.292 14.1529 239.127 14.3607C237.266 16.189 235.447 18.0173 233.048 19.4301C229.12 21.757 224.778 22.0479 220.188 21.0922C217.707 20.5104 215.267 19.7209 212.993 18.7237C209.685 17.3109 206.087 16.3552 202.407 15.9397C198.148 15.5241 194.798 16.6045 192.234 19.4301C191.573 20.1365 190.994 20.8844 190.291 21.6739C189.795 19.9702 189.299 18.3913 188.802 16.8123C187.975 14.1944 187.19 11.5766 186.363 8.91728C186.239 8.66796 186.239 8.41865 186.363 8.16933C188.347 5.55153 190.704 3.26614 194.178 1.97801C198.106 0.482118 202.159 0.648328 206.211 1.52093C210.264 2.43509 214.151 3.88942 218.079 5.136C219.733 5.71773 221.429 6.13326 223.165 6.46568C226.515 7.04742 229.203 5.9255 231.56 4.09719C232.593 3.26614 233.544 2.35198 234.454 1.39627C234.661 1.10541 234.95 0.897643 235.281 0.731433L235.24 0.68988Z"
          fill="#01F9F4"
          stroke="#01F9F4"
          stroke-width="0.64"
        />
        <path
          d="M296.44 0.689868C297.681 4.63736 298.756 8.50174 300.079 12.283C300.575 13.6958 300.327 14.6515 299.087 15.6488C297.722 16.7707 296.523 18.0173 294.993 18.973C290.899 21.6739 286.268 22.131 281.306 21.0506C278.618 20.4273 276.054 19.5547 273.614 18.4743C270.472 17.1447 267.039 16.2721 263.524 15.8981C259.224 15.441 255.792 16.5629 253.228 19.3885C252.608 20.0949 252.029 20.8013 251.326 21.5908C251.078 20.8428 250.912 20.178 250.706 19.5547C249.589 15.9812 248.473 12.4077 247.356 8.87571C247.232 8.6264 247.273 8.37708 247.397 8.12777C249.63 5.17754 252.401 2.68439 256.577 1.56247C260.134 0.56521 263.731 0.772973 267.329 1.56247C271.464 2.47663 275.31 3.93096 279.279 5.13599C280.809 5.63462 282.339 6.05014 283.952 6.38256C286.971 7.00585 289.576 6.38256 291.892 4.80357C293.297 3.80631 294.538 2.72594 295.655 1.52092L296.482 0.648315L296.44 0.689868Z"
          fill="#F58E1F"
          stroke="#F58E1F"
          stroke-width="0.64"
        />
        <path
          d="M171.56 0.68988C172.924 5.136 174.248 9.41591 175.571 13.7374C175.654 13.9451 175.612 14.1529 175.447 14.3191C173.462 16.2721 171.518 18.225 168.913 19.6378C164.902 21.7986 160.56 21.9232 156.053 20.926C153.531 20.3027 151.132 19.4716 148.816 18.4328C144.805 16.8123 140.67 15.6903 136.163 15.7734C133.31 15.815 131.077 16.9369 129.216 18.6821C128.265 19.5963 127.438 20.5935 126.652 21.6323C125.866 19.0976 125.081 16.6876 124.378 14.236C123.799 12.4492 123.261 10.6209 122.682 8.83417C122.558 8.62641 122.6 8.3771 122.724 8.16933C125.205 4.88668 128.348 2.22732 133.144 1.27162C136.783 0.565223 140.339 1.0223 143.813 1.8949C147.907 2.93372 151.752 4.38805 155.722 5.59308C157.045 6.0086 158.41 6.34102 159.816 6.54878C163.124 7.00586 165.647 5.88394 167.921 4.09719C169.203 3.09993 170.278 1.93646 171.518 0.731433L171.56 0.68988Z"
          fill="#EE5123"
          stroke="#EE5123"
          stroke-width="0.64"
        />
      </svg>
    </div>
  );
};
