import React from 'react';

function Flex(
  {
    direction,
    className,
    children,
    style,
    ...props
  }: {
    direction: 'row' | 'column',
    style: React.CSSProperties,
  },
  ref,
) {
  return (
    <div
      ref={ref}
      style={{display: 'flex', flexDirection: direction, ...style}}
      className={className}
      {...props}>
      {children}
    </div>
  );
}

export default React.forwardRef(Flex);
