import React, {Component} from 'react';
import styles from './styles.module.scss';
import PopupContainer from '../../../../components/PopupContainer';
import Popup from '../../../../components/SimplePopup';
import {withRouter} from 'react-router-dom';

import {
  loginImages,
  commonImages,
  operatorImages,
} from '../../../../assets/images';
import moment from 'moment';
import ViewRenderer from './views';
import {API} from '../../../../requests';
import strings from '../../../../globalization';
import Spinner from '../../../../components/Spinner';
import util from '../../../../util';
import * as XLSX from 'xlsx';

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  ERROR: 'error',
  SPINNER_POPUP: 'spinner-popup',
};
class VehiclePersonMovement extends Component {
  constructor(props) {
    super(props);
    console.log('this.props.type:', this.props.type);
    this.state = {
      permits: [],
      downloadedList: [],
      CSVLoading: false,
      totalCount: 0,
      selectedType: 'entry',
      selectedStatus: this.props.type,
      selectedDropDownStatus: {
        id: '1',
        name: 'entry',
        displayName: 'ENTRY',
      },
      startDate: moment().subtract(1, 'week').format('MM-DD-YYYY'),
      startTime: moment().startOf('day'),
      endDate: moment().add(1, 'day').format('MM-DD-YYYY'),
      endTime: moment().endOf('day'),
      // startDate: new moment().startOf('year').format('MM-DD-YYYY'),
      // endDate: new moment().add(1, 'years').format('MM-DD-YYYY'),
      dates: {
        start: moment().subtract(1, 'week').format('MM-DD-YYYY'),
        end: moment().add(1, 'day').format('MM-DD-YYYY'),
      },

      searchText: '',
      perPage: 100,
      statuses: [
        {
          id: '1',
          name: 'entry',
          displayName: 'ENTRY',
        },
        {
          id: '2',
          name: 'exit',
          displayName: 'EXIT',
        },
      ],
    };

    // setTimeout(() => {
    //   const {statuses, selectedType} = this.state;
    //   this.onChangeHandler(
    //     'statusChange',
    //     statuses.filter(option => option?.name === selectedType)[0],
    //   );
    // }, 0);
  }

  getData = async (
    params,
    startDate,
    endDate,
    status,
    page,
    perPage,
    searchString,
    startTime,
    endTime,
    selectedType,
  ) => {
    console.log('status:', status, searchString);
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
    console.log(startDate, endDate);
    let paramsList = {
      startDate: `${moment(startDate, 'MM-DD-YYYY').format(
        'DD-MM-YYYY',
      )} ${moment(startTime).format('HH:mm')}`,
      endDate: `${moment(endDate, 'MM-DD-YYYY').format('DD-MM-YYYY')} ${moment(
        endTime,
      ).format('HH:mm')}`,
      status: status,
      accessType: selectedType === 'entry' ? 0 : 1,
      searchString,
      pageNumber: page,
      pageSize: perPage,
    };

    if(status === 'vehicle'){
      await API.fetchAgencyReportsForVehicles(paramsList)
      .then(resp => resp)
      .then(res => {
        console.log('response:', res.data, perPage);
        params.successCallback(
          res?.data.vehicleMovementArray,
          res?.data?.totalCount ? res?.data?.totalCount : 0,
        );
        this.setState({
          popup: undefined,
          permitList: res?.data?.vehicleMovementArray,
          totalCount: res?.data?.totalCount,
        });
      })
      .catch(err => {
        console.log(err);
        params.successCallback([], 0);
        this.setState({
          popup: undefined,
        });
      });

    } else {
      await API.fetchAgencyReportsForPersons(paramsList)
      .then(resp => resp)
      .then(res => {
        console.log('response:', res.data, perPage);
        params.successCallback(
          res?.data.personMovementArray,
          res?.data?.totalCount ? res?.data?.totalCount : 0,
        );
        this.setState({
          popup: undefined,
          permitList: res?.data?.personMovementArray,
          totalCount: res?.data?.totalCount,
        });
      })
      .catch(err => {
        console.log(err);
        params.successCallback([], 0);
        this.setState({
          popup: undefined,
        });
      });

    }

  };

  componentDidMount() {
    this.onChangeHandler('updateTable');
  }
  getErrorMessage = referenceId => {
    return (
      <div className={styles.requestSuccess}>
        <div>
          <img
            src={commonImages.errorIcon}
            className={styles.successIcon}
            alt="logo"
          />
        </div>
        <div className={styles.errorMsg}>
          {strings.ADMIN.POP_UP.errorMessage}
        </div>
      </div>
    );
  };

  getSuccessMessage = referenceId => {
    return (
      <div className={styles.requestSuccess}>
        <div>
          <img
            src={loginImages.successIcon}
            className={styles.successIcon}
            alt="logo"
          />
        </div>
        <div className={styles.successMessage}>
          {strings.ADMIN.POP_UP.approveCnf1} {referenceId}
          {strings.ADMIN.POP_UP.approveCnf2}
        </div>
      </div>
    );
  };

  onChangeHandler = (action, data) => {
    console.log(data, action);
    switch (action) {
      case 'statusChange': {
        console.log(data);
        if (data) {
          this.setState({
            selectedType: data?.name,
            selectedDropDownStatus: data,
          });
        }
        break;
      }

      case 'date': {
        const {startDate, endDate, dates} = data;
        this.setState({
          startDate,
          endDate,
          dates,
        });
        break;
      }

      case 'search': {
        console.info('data..', data);
        this.setState({
          searchText: data,
        });
        break;
      }
      case 'gridAPI': {
        console.log('grid API', data);
        this.setState({gridApi: data});
        this.onChangeHandler('updateTable');
        break;
      }
      case 'updateTable': {
        const {
          gridApi,
          selectedStatus,
          startDate,
          endDate,
          searchText,
          perPage,
          startTime,
          endTime,
          selectedType,
        } = this.state;
        this.setState({downloadedList: []});
        if (gridApi) {
          const dataSource = {
            getRows: params => {
              const page = params.endRow / perPage;
              console.log(page, params, selectedStatus);
              this.getData(
                params,
                startDate,
                endDate,
                selectedStatus,
                page - 1,
                perPage,
                searchText,
                startTime,
                endTime,
                selectedType,
              );
            },
          };
          gridApi.setDatasource(dataSource);
        }
        break;
      }
      case 'startTime': {
        this.setState({
          startTime: data,
        });
        break;
      }
      case 'endTime': {
        this.setState({
          endTime: data,
        });
        break;
      }
      default: {
        console.info('case not handled', action);
      }
    }
  };

  /**
   *
   * @param {*} action
   * @param {*} data
   */

  onClickHandler = (action, data) => {
    const {history, location, status} = this.props;
    console.log('action...', action, 'data...', data);
    switch (action) {
      case 'signin': {
        history.push('/sign-in');
        break;
      }
      case 'vehicle-registration': {
        history.push('/vehicle-registration');
        break;
      }
      case 'apply': {
        this.onChangeHandler('updateTable');
        break;
      }
      case 'clearFilters': {
        this.resetData();
        break;
      }

      case 'statusRoute': {
        //FIXME
        history.push({
          pathname: '/u/reports/' + data.route,
        });

        break;
      }
      case 'document-view': {
        history.push({
          pathname: location.pathname + '/document-view',
          state: {background: location, url: data},
        });
        break;
      }

      case 'loadCSV': {
        this.getCSVData();
        break;
      }

      default: {
        console.info('case not handled', action);
      }
    }
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  showSuccessPopup = successMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: successMessage,
        onClose: this.closePopup,
        headingImage: operatorImages.successIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  resetData = () => {
    const {status} = this.props;
    this.setState({
      searchText: '',
      startDate: moment().subtract(1, 'week').format('MM-DD-YYYY'),
      startTime: moment().startOf('day'),
      endDate: moment().add(1, 'day').format('MM-DD-YYYY'),
      endTime: moment().endOf('day'),
      dates: {
        start: moment().subtract(1, 'week').format('MM-DD-YYYY'),
        end: moment().add(1, 'day').format('MM-DD-YYYY'),
      },
      selectedType: 'entry',
      selectedStatus: this.props.type,
      selectedDropDownStatus: {
        id: '1',
        name: 'entry',
        displayName: 'ENTRY',
      },
    });

    this.onChangeHandler('updateTable');
  };

  downloadExcel = data => {
    const updatedData = data.map(item => {
      console.log(item);
      return {
        Timestamp: moment(item.createdDate).format('DD/MM/YYYY HH:mm'),
        'Permit reference ID': item?.permitReferenceId,
        'Vehicle Registration Number': item?.vehicleRegistrationNumber,
        'Vehicle Owner Name': item?.vehicleOwnerName,
        Gate: item?.gateName,
        Credits: item?.credits,
        'Reconciliation Status': item?.reconciliationStatus,
      };
    });
    console.log(updatedData);
    const sheetNamePrefix = data[0]?.requestType;
    const worksheet = XLSX.utils.json_to_sheet(updatedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(
      workbook,
      `${sheetNamePrefix} vehicle movement report-${moment().format(
        `DD-MM-YYYY`,
      )}.xlsx`,
    );
  };
  downloadPersonExcel = data => {
    const updatedData = data.map(item => {
      console.log(item);

      return {
        Timestamp: moment(item.createdDate).format('DD/MM/YYYY HH:mm'),
        'Permit reference ID': item?.permitReferenceId,
        'Person Name': item?.personName,
        'Person RFID': item?.personRFID,
        Credits: item?.credits,
        'Reconciliation Status': item?.reconciliationStatus,
      };
    });
    console.log(updatedData);
    const sheetNamePrefix = data[0]?.requestType;
    const worksheet = XLSX.utils.json_to_sheet(updatedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(
      workbook,
      `${sheetNamePrefix} person movement report-${moment().format(
        `DD-MM-YYYY`,
      )}.xlsx`,
    );
  };

  getCSVData = async () => {
    this.showSpinner();
    const {
      startDate,
      endDate,
      startTime,
      endTime,
      selectedStatus,
      searchText,
      totalCount,
      selectedType,
    } = this.state;
    this.setState({CSVLoading: false, downloadedList: []});
    if (!startTime || !endTime || !startDate || !endDate) {
      this.showErrorPopup(`Date and Time parameters cannot be empty!`);
      return;
    }
    console.log(startDate, endDate);
    let params = {
      startDate: `${moment(startDate, 'MM-DD-YYYY').format(
        'DD-MM-YYYY',
      )} ${moment(startTime).format('HH:mm')}`,
      endDate: `${moment(endDate, 'MM-DD-YYYY').format('DD-MM-YYYY')} ${moment(
        endTime,
      ).format('HH:mm')}`,
      status: selectedStatus,
      accessType: selectedType === 'entry' ? 0 : 1,
      searchString: searchText,
      pageNumber: 0,
      pageSize: totalCount + 100,
    };
    console.log('params...', params, selectedStatus);
    if(selectedStatus === 'vehicle') {
      API.fetchAgencyReportsForVehicles(params)
      .then(res => {
        console.log('response:', res);

        this.setState({
          downloadedList: res?.data?.vehicleMovementArray,
          popup: undefined,
          totalCount: res?.data?.totalCount,
          CSVLoading: true,
        });

        const data = this.getCSVDownloadData();
        console.log('about to download:', data);
        this.downloadExcel(data);
      })
      .catch(err => {
        console.log('err', err);
        console.log(err);
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
        this.setState({CSVLoading: false});
      });
    } else {
      API.fetchAgencyReportsForPersons(params)
      .then(res => {
        console.log('response:', res);

        this.setState({
          downloadedList: res?.data?.personMovementArray,
          popup: undefined,
          totalCount: res?.data?.totalCount,
          CSVLoading: true,
        });

        const data = this.getPersonCSVDownloadData();
        console.log('about to download:', data);
        this.downloadPersonExcel(data);
      })
      .catch(err => {
        console.log('err', err);
        console.log(err);
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
        this.setState({CSVLoading: false});
      });
    }
    
  };

  getCSVDownloadData = () => {
    const {downloadedList} = this.state;

    console.log('downloadedList:', downloadedList);
    return downloadedList.map(el => {
      const vehicleRegistrationNumber = el?.vehicleRegistrationNumber
        ? el?.vehicleRegistrationNumber
        : '-';
      const vehicleOwnerName = el?.vehicleOwnerName
        ? el?.vehicleOwnerName
        : '-';
      const numberOfPasses = el?.numberOfPasses ? el?.numberOfPasses : '-';
      return {
        requestType: el.requestType === 0 ? 'Entry' : 'Exit',
        createdDate: el.createdDate,
        permitReferenceId: el.permitReferenceId,
        vehicleRegistrationNumber: vehicleRegistrationNumber,
        vehicleOwnerName: vehicleOwnerName,
        gateName: el.gateName,
        credits: numberOfPasses,
        reconciliationStatus: el?.reconciliationStatus?.replace(/_/g, ' '),
      };
    });
  };

  getPersonCSVDownloadData = () => {
    const {downloadedList} = this.state;

    console.log('downloadedList:', downloadedList);
    return downloadedList.map(el => {
      const personRFID = el?.personRFID
        ? el?.personRFID
        : '-';
      const personName = el?.name
        ? el?.name
        : '-';
      const numberOfPasses = el?.numberOfPasses ? el?.numberOfPasses : '-';
      return {
        requestType: el.requestType === 0 ? 'Entry' : 'Exit',
        createdDate: el.createdDate,
        permitReferenceId: el.permitReferenceId,
        personName: personName,
        personRFID: personRFID,
        credits: numberOfPasses,
        reconciliationStatus: el?.reconciliationStatus?.replace(/_/g, ' '),
      };
    });
  };

  getProps = () => {
    const {
      statuses,
      permitList,
      searchText,
      selectedStatus,
      selectedType,
      startDate,
      endDate,
      dates,
      perPage,
      selectedDropDownStatus,
      CSVLoading,
      downloadedList,
      startTime,
      endTime,
      totalCount,
    } = this.state;
    return {
      onClick: this.onClickHandler,
      onChange: this.onChangeHandler,
      statuses,
      searchText,
      selectedDropDownStatus,
      selectedStatus,
      selectedType,
      startDate,
      endDate,
      dates,
      perPage,
      permitList: permitList,
      downloadedList,
      downloadData: this.getCSVDownloadData(),
      CSVLoading,
      startTime,
      endTime,
      totalCount,
    };
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };
  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }

      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

export default withRouter(VehiclePersonMovement);
