// import keyMirror from "fbjs/lib/keyMirror";

export const ActionTypes = {
  SET_LOGIN_DATA: 'SET_LOGIN_DATA',
  LOGOUT: 'LOGOUT',
  UPDATE_USER_DATA: 'UPDATE_USER_DATA',
  UPDATE_LANGUAGE: 'UPDATE_LANGUAGE',
  UPDATE_COMPANY_DATA: 'UPDATE_COMPANY_DATA',
  SET_AUTHENTICATED_USER: 'SET_AUTHENTICATED_USER',
  SET_VEHICLE_DATA: 'SET_VEHICLE_DATA',
  SET_PERSONS_DATA: 'SET_PERSONS_DATA',
  CLEAR_PERSONS_VEHICLES_DATA: 'CLEAR_PERSONS_VEHICLES_DATA',
  DELETE_MATERIAL: 'DELETE_MATERIAL',
  DELETE_VEHICLE: 'DELETE_VEHICLE',
  DELETE_PERSON: 'DELETE_PERSON',
  UPDATE_MATERIAL: 'UPDATE_MATERIAL',
  SET_CART_ITEM: 'SET_CART_ITEM',
  DELETE_CART_ITEM: 'DELETE_CART_ITEM',
  CLEAR_CART: 'CLEAR_CART',
  UPDATE_QUANTITY: 'UPDATE_QUANTITY',
};
