import React from 'react';
import styles from './styles.module.scss';
import AuthLayout from '../../../../../components/AuthLayout';

import {withRouter} from 'react-router-dom';
import strings from '../../../../../globalization/index';
import FormElement from '../../../../../components/FormElement';
import Flex from '../../../../../components/Flex';
import Button from '../../../../../components/Button';
import {loginImages} from '../../../../../assets/images';

const Desktop = ({
  backClick,
  onSubmit,
  stateData,
  getStatusContent,
  onChangeHandler,
  onForgotClick,
}) => {
  return (
    // <AuthLayout>
    <Flex direction="column" className={styles.container}>
      <Flex onClick={backClick} className={styles.backlabel}>
        <img
          className={styles.backButtonIcon}
          src={loginImages.backButtonIcon}
          alt="back"
        />
        <span>{strings.back}</span>
      </Flex>
      <Flex direction="column" className={styles.wrapper}>
        <div>
          <header>
            <h1 className={styles.header}>{strings.checkApplicationStatus}</h1>
          </header>
          <Flex direction="column" className={styles.formContainer}>
            <FormElement
              type="dropdown"
              label={strings.requestTypeLabel}
              required={true}
              placeholderText={strings.requestTypePlaceholder}
              onChange={text => {
                onChangeHandler('requestType', text);
              }}
              value={stateData.requestType}
              maxLength={30}
              options={stateData.requestTypeOptions}
            />
            <FormElement
              type="text"
              label={strings.referenceNumberLabel}
              required={true}
              placeholderText={strings.referenceNumberPlaceholder}
              onChange={text => {
                onChangeHandler('referenceNumber', text);
              }}
              value={stateData.referenceNumber}
              maxLength={30}
            />
            <Flex className={styles.forgotPasswordContainer}>
              <button className={styles.forgotPassword} onClick={onForgotClick}>
                {strings.forgotReferenceNumber}
              </button>
            </Flex>

            <Button
              buttonStyle="primary"
              label={strings.submitRequest}
              className={styles.submitButton}
              onClick={onSubmit}
            />
          </Flex>
        </div>
        {getStatusContent()}
      </Flex>
    </Flex>
    // </AuthLayout>
  );
};
export default withRouter(Desktop);
