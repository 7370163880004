import React, {Component} from 'react';

import {API} from '../../../requests';

import Popup from '../../../components/SimplePopup';
import strings from '../../../globalization';

// import crashlytics from '@react-native-firebase/crashlytics';
import {getFormData} from './data';
import util from '../../../util';
import PopupContainer from '../../../components/PopupContainer';
import ViewRenderer from './views';
import REQUEST_TYPE_OPTIONS from '../../../constants/request-type-options';

import Spinner from '../../../components/Spinner';
import {Metadata} from '../../../components/Metadata';
import PAGE_TITLES from '../../../constants/page-titles';
import {commonImages} from '../../../assets/images';

const POPUP_CONSTANTS = {
  SPINNER_POPUP: 'spinner-popup',
  SIMPLE_POPUP: 'simple-popup',
};

const DROPDOWN_KEY_VALUE = {
  1: REQUEST_TYPE_OPTIONS.PERSON,
  2: REQUEST_TYPE_OPTIONS.VEHICLE,
  3: REQUEST_TYPE_OPTIONS.AGENCY,
};

export default class GetReferenceNumber extends Component {
  constructor(props) {
    super(props);
    this.formData = getFormData();
    let preparedObject = {};

    this.formData.map(obj => {
      preparedObject[obj.stateField] = {value: obj.stateDefault, error: null};
      if (obj.optionsField) {
        preparedObject[obj.optionsField] = [];
      }
    });

    this.state = {
      popup: null,
      referenceNumber: '',
      status: null,
      returnedObject: null,

      ...preparedObject,
      requestTypeOptions: [
        {
          name: REQUEST_TYPE_OPTIONS.PERSON,
          id: 1,
        },
        {
          name: REQUEST_TYPE_OPTIONS.VEHICLE,
          id: 2,
        },
        {
          name: REQUEST_TYPE_OPTIONS.COMPANY,
          id: 3,
        },
      ],
    };
  }

  fetchAllOptions = selectedValue => {
    console.log(
      'this.state.requestType fetch: ',
      DROPDOWN_KEY_VALUE[selectedValue],
    );
    API.fetchOptions(DROPDOWN_KEY_VALUE[selectedValue])
      .then(optionsRes => {
        this.setState({
          proofDocumentTypeOptions: optionsRes.data,
          popup: null,
        });
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        console.log(errorMsg);
        this.showErrorPopup(errorMsg);
      });
  };

  onChangeHandler = (field, value) => {
    console.log(field, value);
    if (field === 'requestType') {
      this.setState({
        proofDocumentType: {
          value: null,
          error: null,
        },
      });
      this.fetchAllOptions(value);
    }
    this.setState({
      [field]: {
        value,
        error: null,
      },
    });
  };

  closePopup = () => {
    this.setState({
      popup: null,
    });
  };

  getPopupContent = () => {
    const {popup} = this.state;

    if (!popup) {
      return null;
    }
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP:
        return <Popup {...popup} />;
      default:
        return null;
    }
  };

  onSubmit = () => {
    this.showSpinner();

    API.getStatusByReferenceId(this.state.referenceNumber)
      .then(statusRes => {
        this.setState({
          status: statusRes.data,
          popup: null,
        });
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        console.log(errorMsg);
        this.showErrorPopup(errorMsg);
      });
  };

  handleErrors = () => {
    let err = false;
    const update = {};

    for (let i = 0; i < this.formData.length; i++) {
      let element = this.formData[i];
      const error = util.validate(
        this.state[element.stateField].value,
        element,
      );
      if (error) {
        err = true;
        update[element.stateField] = {
          ...this.state[element.stateField],
          error: error,
        };
      }
    }
    this.setState({
      ...update,
    });
    return err;
  };

  onSubmitClick = async () => {
    // Person
    this.showSpinner();
    const error = this.handleErrors();
    if (error) {
      this.setState({
        popup: null,
      });
    } else {
      this.handleSubmit();
    }
  };
  handleSubmit = async () => {
    console.log('this.state', this.state);
    if (this.state.requestType?.value === 1) {
      this.getPerson();
    } else if (this.state.requestType?.value === 2) {
      this.getVehicle();
    } else {
      this.getCompany();
    }
  };

  getPerson = () => {
    const {proofDocumentType, documentNumber} = this.state;
    API.getPersonReferenceNumber(proofDocumentType.value, documentNumber.value)
      .then(personRes => {
        this.showPopup(strings.referenceNumberSent);
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        console.log(errorMsg);
        this.showErrorPopup(errorMsg);
      });
  };
  getVehicle = () => {
    const {proofDocumentType, documentNumber} = this.state;
    API.getVehicleReferenceNumber(proofDocumentType.value, documentNumber.value)
      .then(vehicleRes => {
        this.showPopup(strings.referenceNumberSent);
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        console.log(errorMsg);
        this.showErrorPopup(errorMsg);
      });
  };

  getCompany = () => {
    const {proofDocumentType, documentNumber} = this.state;
    // Phone number check
    let type = proofDocumentType.value;
    let number = documentNumber.value;

    if (type === 2) {
      number = `+91${number}`;
    }
    API.getCompanyReferenceNumber(type, number)
      .then(vehicleRes => {
        this.showPopup(strings.referenceNumberSent);
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        console.log(errorMsg);
        this.showErrorPopup(errorMsg);
      });
  };
  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  showPopup = message => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: message,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
          },
        ],
        onClose: this.closePopup,
      },
    });
  };
  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };
  onBlur = field => {
    const currentObj = this.state[field];

    const formField = this.formData.find(f => f.stateField === field);
    this.setState({
      [field]: {
        ...currentObj,
        error: formField ? util.validate(currentObj.value, formField) : null,
      },
    });
  };
  handleBackClick = () => {
    const {history} = this.props;
    history.push('manage-registration-information');
  };
  getProps = () => {
    return {
      onBlur: this.onBlur,
      backClick: this.handleBackClick,
      onChangeHandler: this.onChangeHandler,
      onSubmitClick: this.onSubmitClick,
      formData: this.formData,
      stateData: this.state,
    };
  };

  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <Metadata title={PAGE_TITLES.getReferenceNumber} />
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}
