import React from 'react';
import styles from './Desktop.module.scss';
import {withRouter} from 'react-router-dom';

const Desktop = props => {
  const {onClick} = props;
  console.info('Permit Rquests Desktop.....');

  return <div className={styles.permitContainer}>Permit Rquests</div>;
};
export default withRouter(Desktop);
