import React from 'react';
import styles from './Person.module.scss';
import moment from 'moment';
import {adminImages, loginImages} from '../../assets/images';
import documentIcon from '../../../src/assets/images/document-icon/image.png';
import strings from '../../globalization';
import Flex from '../Flex';
import FormElement from '../FormElement';
import rectangleIcon from '../../assets/images/profile/rectangle-icon/image1@2x.png';
import Button from '../Button';

/**
 *
 * @param {Boolean} error
 * @param {Boolean} success
 * @return {JSX.Element}
 */

/**
 *
 * @param {Props} props
 * @return {JSX.Element}
 */
function VehicleDetails(props) {
  console.log('details', props);
  const {vehicleDetails, onClick, onBlackList, onUnBlackList} = props || {};
  const {name, vehicleType, status, id} = vehicleDetails;
  const formatDate = date => moment(date).format('MMM Do YYYY');
  return (
    <Flex direction="row" className={styles.mainContainer}>
      <Flex direction="column" className={styles.tabsContainer}>
        <div className={styles.imgContainer}>
          <div className={styles.imgIcon}>
            <img src={rectangleIcon} className={styles.centerIcon} alt="s" />
          </div>
          <div className={styles.nameBlock}>
            <div className={styles.name}>Owner: {name}</div>
            <div className={styles.nameLabel}>
              Vehicle Type : {vehicleType?.name}
            </div>
            <div className={styles.nameLabel}>Status : {status}</div>
          </div>
          {status === 'ISSUED' ? (
            <div className={styles.blackListBtnContainer}>
              <Button
                onClick={() => {
                  onBlackList(id);
                }}
                buttonStyle="orangeBtn"
                label={'Blacklist'}
                className={styles.blackListBtn}></Button>
            </div>
          ) : null}
          {status === 'BLACKLISTED' ? (
            <div className={styles.blackListBtnContainer}>
              <Button
                onClick={() => {
                  onUnBlackList(id);
                }}
                buttonStyle="orangeBtn"
                label={'Remove Blacklist'}
                className={styles.unBlackListBtn}></Button>
            </div>
          ) : null}
        </div>
      </Flex>
      <Flex direction="column" className={styles.dataContainer}>
        <VehicleCard onClick={onClick} vehicleDetails={vehicleDetails} />
      </Flex>
    </Flex>
  );
}

export default VehicleDetails;

const DataItem = ({onClick, value, label, labelStyle, document}) => {
  return (
    <Flex className={styles.dataItemWrapper}>
      <div className={[styles.label, labelStyle].join(' ')}>{label} :</div>
      {document && value ? (
        <div className={styles.documentViewLink}>
          {/* <div>{value?.slice(-10)}</div> */}
          <img
            onClick={() => onClick('document-view', value)}
            className={styles.documentIcon}
            alt="."
            src={documentIcon}
          />
        </div>
      ) : (
        <div>
          {value ? (
            value
          ) : (
            <span className={styles.greyColor}>DATA NOT UPLOADED</span>
          )}
        </div>
      )}
    </Flex>
  );
};

const VehicleCard = ({onClick, vehicleDetails}) => {
  console.log('stateData...', vehicleDetails);
  return (
    <Flex direction="column">
      <div className={styles.headLabel}>Vehicle Details</div>

      <Flex>
        <div>
          <DataItem
            label={'Owner Mobile Number. '}
            value={vehicleDetails?.phoneNumber?.slice(3)}
          />
          <DataItem
            label={'Vehicle Registration Number'}
            value={vehicleDetails?.vehicleRegistrationNumber}
          />
          <DataItem
            label={'Vehicle RC'}
            document={true}
            onClick={onClick}
            value={vehicleDetails?.rcDocument}
          />
          <DataItem
            label={'Vehicle Insurance Number'}
            value={vehicleDetails?.vehicleInsuranceNumber}
          />
          <DataItem
            label={'Vehicle Insurance Certificate'}
            document={true}
            onClick={onClick}
            value={vehicleDetails?.vehicleInsuranceCertificate}
          />

          <DataItem
            label={'Vehicle Pollution Number '}
            value={vehicleDetails?.vehiclePollutionNumber}
          />
          <DataItem
            label={'PUC Certificate'}
            document={true}
            onClick={onClick}
            value={vehicleDetails?.puc}
          />
        </div>
        <div className={styles.marginLeft30}>
          <DataItem
            label={'Vehicle Category'}
            value={vehicleDetails?.vehicleCategory?.name}
          />
          <DataItem
            label={'Vehicle Type'}
            value={vehicleDetails?.vehicleType?.name}
          />
          <DataItem
            label={'Vehicle Engine Number'}
            value={vehicleDetails?.vehicleEngineNumber}
          />
          <DataItem
            label={'Vehicle Chassis Number '}
            value={vehicleDetails?.chassisNumber}
          />{' '}
          <DataItem
            label={'Vehicle Manufacturing Year '}
            value={vehicleDetails?.vehicleManufacturingYear}
          />
          <DataItem
            label={'Vehicle Make and Model'}
            value={vehicleDetails?.vehicleMakeAndModel}
          />
          <DataItem
            label={'Fitness Certificate'}
            document={true}
            onClick={onClick}
            value={vehicleDetails?.fitnessCertificate}
          />
        </div>
      </Flex>
    </Flex>
  );
};
