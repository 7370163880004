import {REHYDRATE} from 'redux-persist';
import {ActionTypes} from '../constants';

const defaults = {
  isLoading: true,
  loginInfo: {},
  isLoggedIn: false,
  authenticatedUser: undefined,
};

export interface CacheState {}

export interface ActionType {
  type: string;
  payload: any;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaults, action: ActionType) => {
  switch (action.type) {
    case ActionTypes.SET_LOGIN_DATA: {
      return {
        ...state,
        loginInfo: {...action.payload},
        isLoading: false,
        isLoggedIn: true,
      };
    }
    case ActionTypes.LOGOUT: {
      return {
        ...state,
        ...defaults,
        // loginInfo: null,
        // isLoggedIn: false,
        // isLoading: false,
      };
    }
    case ActionTypes.UPDATE_USER_DATA: {
      return {
        ...state,
        loginInfo: {
          ...state.loginInfo,
          user: {...state.loginInfo.user, ...action.payload},
        },
      };
    }
    case ActionTypes.UPDATE_COMPANY_DATA: {
      return {
        ...state,
        loginInfo: {
          ...state.loginInfo,
          company: {...state.loginInfo.company, ...action.payload},
        },
      };
    }
    case ActionTypes.SET_AUTHENTICATED_USER: {
      return {
        ...state,
        authenticatedUser: action.payload.user,
      };
    }
    default:
      return {...state};
  }
};
