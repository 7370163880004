import leftIcon from '../images/left-section.png';
import logoIcon from '../images/Logo/logo.png';
import whiteLogoIcon from '../images/Logo/logo-white.png';
import backButtonIcon from '../images/back-button-icon/image@3x.png';
import checkBoxIcon from '../images/checkbox/image@3x.png';
import successIcon from '../images/success/image.png';
import dashboardIcon from '../images/dashboard/image@3x.png';
import dashboardIconWhite from '../images/dashboard/image-white@3x.png';
import agencyWhite from '../images/Icon/agency/image-white@3x.png';
import agency from '../images/Icon/agency/image@3x.png';
import searchIcon from '../images/search-icon/image@3x.png';
import closeIcon from '../images/close-icon/image.png';
import truckIcon from '../images/truck-icon/image@3x.png';
import truckIconBlue from '../images/truck-icon-blue/image@3x.png';
import noDataIcon from '../images/no-data/image@3x.png';
import documentIcon from '../images/document-icon/image.png';
import backgroundIcon from '../images/login-background/image@3x.png';
import eyeIcon from '../images/e-drishti-logo/image@3x.png';
import eyeIconBlue from '../images/eye-icon/image@3x.png';
import emblem from '../images/emblem/image.png';
import logo from '../images/Logo/image.png';
import sannaLogo from '../images/sanna-logo/image@3x.png';
import carouselImg1 from '../images/carousel/1.png';
import carouselImg2 from '../images/carousel/2.png';
import carouselImg3 from '../images/carousel/3.png';
import errorIcon from '../images/error-icon/image.png';
import agencyIcon from '../images/agency-icon/Agency@3x.png';

import deleteIcon from '../images/operator/delete/image@3x.png';
import editIcon from '../images/operator/edit/image@3x.png';
import userIcon from '../images/operator/user/image@3x.png';

import attachIcon from '../images/attchment-icon/image.png';
import cameraIcon from '../images/camera-icon/image.png';
import tickIcon from '../images/tick-icon/image@3x.png';
import captureIcon from '../images/capture-icon/image@3x.png';
import retakeIcon from '../images/retake-icon/image@3x.png';
import playStoreIcon from '../images/mobile-app-icons/google-play.png';
import appStoreIcon from '../images/mobile-app-icons/app-store.png';
import creditsIcon from '../images/Icon/credits/credits.png';
import userMngIcon from '../images/Icon/user/user.png';
import dbImg1 from '../images/dashboard/topblock/image.png';

import pendingImg1 from '../images/dashboard/pending/1.png';
import pendingImg2 from '../images/dashboard/pending/2.png';

import approvedImg1 from '../images/dashboard/approved/1.png';
import approvedImg2 from '../images/dashboard/approved/2.png';
import rejectedImg1 from '../images/dashboard/rejected/1.png';
import rejectedImg2 from '../images/dashboard/rejected/2.png';

import appliedImg1 from '../images/dashboard/applied/1.png';
import appliedImg2 from '../images/dashboard/applied/2.png';
import settingsIcon from '../images/dashboard/settings/image@3x.png';

import vehicleIcon from '../images/vehicle/vehicle@2x.png';
import confirmImg from '../images/vehicle/confirmImg.png';
import vehicleIconBlue from '../images/vehicle/truckIcon.png';

import metricsVehicleIcon from '../images/dashboard-metrics/vehicle.png';
import metricsPersonIcon from '../images/dashboard-metrics/person.png';
import metricsAgencyIcon from '../images/dashboard-metrics/agency.png';
import card1BK from '../images/dashboard-metrics/card1BK.png';
import metricsPermitsRaisedIcon from '../images/dashboard-metrics/permitsRaised@2x.png';
import metricsWithoutPermitIcon from '../images/dashboard-metrics/withoutPermit@2x.png';
import metricsVanIcon from '../images/dashboard-metrics/van@2x.png';
import metricsWithPermitIcon from '../images/dashboard-metrics/withPermit@2x.png';

// side bar icons
import permitsWhite from '../images/side-menu-icons/white/permits@3x.png';
import permitsBlue from '../images/side-menu-icons/blue/permits@3x.png';
import walletBlue from '../images/side-menu-icons/blue/wallet@3x.png';
// import menuIconWalletWhite from '../images/side-menu-icons/white/wallet@3x.png';
import usersBlue from '../images/side-menu-icons/blue/userManagement@3x.png';
import usersWhite from '../images/side-menu-icons/white/userManagement@3x.png';
import reportsBlue from '../images/side-menu-icons/blue/reports@3x.png';
import reportsWhite from '../images/side-menu-icons/white/reports@3x.png';

import agencyRequestsBlue from '../images/side-menu-icons/blue/agency-request@2x.png';
import agencyRequestsWhite from '../images/side-menu-icons/white/agency-request@2x.png';
import registrationRequestsBlue from '../images/side-menu-icons/blue/registration-requests@3x.png';
import registrationRequestsWhite from '../images/side-menu-icons/white/registration-requests@3x.png';

import infoIcon from '..//images/Icon/info-icon/info@1x.png';
import newDptLogo from '../images/new-dpt-logo/image.png';
import loadingIcon from '../images/spinner/spinner.gif';

import deptDashBoardAgencyIcon from '../images/dept-dashboard/agency.png';
import deptDashBoardPersonRegIcon from '../images/dept-dashboard/contacts@2x.png';
import deptDashBoardTruckIcon from '../images/dept-dashboard/truck@2x.png';
import deptDashBoardFileIcon from '../images/dept-dashboard/file@2x.png';
import deptDashBoardUserIcon from '../images/dept-dashboard/user@2x.png';
import deptDashBoardSettingIcon from '../images/dept-dashboard/setting@2x.png';

import humanIconBlue from '../images/person-permits/human.png';
import viewIcon from '../images/person-permits/eye.png';

import checkPermitStatusArrowIcon from '../images/check-permit-status/arrow.png';
import locationIcon from '../images/location-icon/image.png';

const loginImages = {
  leftIcon,
  logoIcon,
  whiteLogoIcon,
  backButtonIcon,
  checkBoxIcon,
  successIcon,
  backgroundIcon,
  eyeIcon,
  emblem,
  logo,
  sannaLogo,
  carouselImg1,
  carouselImg2,
  carouselImg3,
  playStoreIcon,
  appStoreIcon,
  newDptLogo,
};

const commonImages = {
  errorIcon,
  successIcon: errorIcon,
  infoIcon,
  loadingIcon,
  vehicleIconBlue,
  viewIcon,
  humanIconBlue,
  checkPermitStatusArrowIcon,
};

const adminImages = {
  dashboardIcon,
  searchIcon,
  closeIcon,
  truckIcon,
  truckIconBlue,
  documentIcon,
  attachIcon,
  cameraIcon,
  captureIcon,
  tickIcon,
  retakeIcon,
  dashboardIconWhite,
  agencyWhite,
  agency,
  eyeIconBlue,
  userMngIcon,
  creditsIcon,
  agencyIcon,
  vehicleIcon,
  confirmImg,
  metricsVehicleIcon,
  metricsPersonIcon,
  metricsAgencyIcon,
  metricsPermitsRaisedIcon,
  metricsVanIcon,
  metricsWithoutPermitIcon,
  metricsWithPermitIcon,
};

const operatorImages = {
  deleteIcon,
  editIcon,
  userIcon,
  noDataIcon,
  successIcon,
};

const agencyImages = {
  deleteIcon,
  editIcon,
  userIcon,
  noDataIcon,
  successIcon,
  dbImg1,
  appliedImg1,
  appliedImg2,
  pendingImg1,
  pendingImg2,
  approvedImg1,
  approvedImg2,
  rejectedImg1,
  rejectedImg2,
  settingsIcon,
  agencyIcon,
};

const menuIcons = {
  permitsWhite,
  permitsBlue,
  walletBlue,
  usersBlue,
  reportsBlue,
  agencyRequestsBlue,
  registrationRequestsBlue,
  reportsWhite,
  agencyRequestsWhite,
  registrationRequestsWhite,
  usersWhite,
  locationIcon,
};

const deptDashBoard = {
  deptDashBoardAgencyIcon,
  deptDashBoardFileIcon,
  deptDashBoardPersonRegIcon,
  deptDashBoardSettingIcon,
  deptDashBoardTruckIcon,
  deptDashBoardUserIcon,
};

export {
  loginImages,
  adminImages,
  commonImages,
  operatorImages,
  agencyImages,
  menuIcons,
  deptDashBoard,
};
