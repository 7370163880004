export const termsData = [
  {
    title: 'Acknowledgement',
    content:
      "Visitors to this website- www.sanya.ai/edrishti are bound by the following terms and conditions ('terms'). So, please read the terms carefully before continuing to use this site.",
  },
  {
    title: '',
    content:
      'If you do not agree with any of these terms, please do not proceed further with using the site',
  },
  {
    title: '',
    content:
      'Your access to and use of the services in this website is also conditioned on your acceptance of and compliance with the privacy policy of the website.',
  },
  {
    title: '',
    content:
      'Our privacy policy describes our policies and procedures on the collection, use and disclosure of your personal information when you use the application or the website. Please read our privacy policy carefully before using our service.',
  },
  {
    title: '',
    content:
      'We reserve the right to change/update these Terms from time to time without notice. You acknowledge and agree that it is your responsibility to review this periodically to familiarize yourself with any modifications.',
  },
  {
    title: '',
    content:
      'Your continued use of this site after such modifications will constitute acknowledgment and agreement of the modified terms and conditions.',
  },
  {
    title: 'Website Content',
    content:
      'The content of the pages of this website is for your general information and use only. It is subject to change without notice.',
  },
  {
    title: '',
    content:
      'All content and materials available on www.sanya.ai/edrishti  , including but not limited to text, graphics, website name, code, images and logos are the intellectual property of KR mroads India Pvt. Ltd., and are protected by applicable copyright and trademark law. Any inappropriate use, including but not limited to the reproduction, distribution, display or transmission of any content on this site is strictly prohibited, unless specifically authorized by- “KR mroads India Pvt. Ltd.”',
  },
  {
    title: 'User Accounts',
    content:
      'When You create an account with Us, You must provide Us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of Your account on Our Service.',
  },
  {
    title: '',
    content:
      'You are responsible for safeguarding the password that You use to access the Service and for any activities or actions under Your password, whether Your password is with Our Service or a Third-Party Social Media Service.',
  },
  {
    title: '',
    content:
      'You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware of any breach of security or unauthorized use of Your account.',
  },
  {
    title: '',
    content:
      'You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than You without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.',
  },
  {
    title: '',
    content:
      'You are responsible for maintaining the confidentiality of any login information associated with any account you use to access our Resources. Accordingly, you are responsible for all activities that occur under your account/s.',
  },
  {
    title: 'Data',
    content:
      'Although regular backups of Data are performed, sanya does not guarantee there will be no loss or corruption of data.',
  },
  {
    title: '',
    content:
      'Corrupt or invalid backup points may be caused by, without limitation, Data that is corrupted prior to being backed up or that changes during the time a backup is performed.',
  },
  {
    title: '',
    content:
      'sanya will provide support and attempt to troubleshoot any known or discovered issues that may affect the backups of Content. But You acknowledge that KR mroads India Pvt. Ltd. has no liability related to the integrity of Content or the failure to successfully restore Content to a usable state.',
  },
  {
    title: '',
    content:
      'In order to access our Resources, you may be required to provide certain information or data about yourself (such as identification, email, phone number, contact details, etc.) as part of the registration process, or as part of your ability to use the Resources. You agree that any information you provide will always be accurate, correct, and up to date.',
  },
  {
    title: 'Availability, Errors and Inaccuracies',
    content:
      'We are constantly updating Our offerings on the Services. The Services available may be mispriced, described inaccurately, or unavailable, and We may experience delays in updating information regarding our Service.',
  },
  {
    title: '',
    content:
      'We cannot and do not guarantee the accuracy or completeness of any information, including prices, specifications, availability, and services.',
  },
  {
    title: '',
    content:
      'We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.',
  },
  {
    title: 'Termination of use',
    content:
      'You agree that we may, at our sole discretion, suspend or terminate Your access to all or part of our website and Resources with or without notice and for any reason, including, without limitation, breach of this Agreement.',
  },
  {
    title: '',
    content:
      'Any suspected illegal, fraudulent or abusive activity may be grounds for terminating your relationship and may be referred to appropriate law enforcement authorities- if required.',
  },
  {
    title: '',
    content:
      'Upon suspension or termination, your right to use the Resources we provide will immediately cease, and we reserve the right to remove or delete any information that you may have on file with us, including any account or login information.',
  },
  {
    title: 'Refund Policy',
    content:
      'No refunds will be given except in the event of non-performance or inexplicable errors by/from/of any service by www.sanya.ai/edrishti',
  },
  {
    title: 'SMS/Email Policy',
    content:
      'By consenting to these terms, you agree to receive recurring SMS/Email messages with service-related messages, including updates, alerts, and information. ',
  },
  {
    title: '',
    content:
      'We do not charge you for the SMS Service, but you are responsible for all charges and fees associated with text messaging imposed by your wireless provider. Message frequency varies. Standard message and data rates may apply from your wireless provider. Check your mobile plan and contact your wireless provider for details.',
  },
];
