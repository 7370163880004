import React from 'react';
import Flex from '../../Flex';
import styles from './styles.module.scss';

import rejectIcon from '../../../assets/images/reject-icon/image.png';

function Reject({
  containerClass,
  title,
  remark,
  children,
  data,
  onOkay,
  person,
  vehicle,
  ...remainingProps
}) {
  return (
    <div className={[styles.container, containerClass].join(' ')}>
      <Flex className={styles.header}>
        <h1>{title}</h1>
      </Flex>
      <Flex direction="column" className={styles.childrenContainer}>
        <img src={rejectIcon} className={styles.image} alt="I" />
        <p className={styles.subheading}>
          {person ? 'Person' : 'Vehicle'} {data.name} has been rejected.
        </p>
        <p>Remark: {remark}</p>
      </Flex>

      <Flex className={styles.buttonsContainer} {...remainingProps}>
        <button className={styles.okay} onClick={onOkay}>
          Okay!
        </button>
      </Flex>
    </div>
  );
}

export default Reject;
