import React, {useState, useEffect} from 'react';
import styles from './Person.module.scss';
import moment from 'moment';
import {adminImages, loginImages} from '../../assets/images';
import documentIcon from '../../../src/assets/images/document-icon/image.png';
import strings from '../../globalization';
import Flex from '../Flex';
import FormElement from '../FormElement';
import Button from '../Button';
import rectangleIcon from '../../assets/images/profile/rectangle-icon/image1@2x.png';
import {API} from '../../requests';
/**
 *
 * @param {Boolean} error
 * @param {Boolean} success
 * @return {JSX.Element}
 */

/**
 *
 * @param {Props} props
 * @return {JSX.Element}
 */
function PersonDetails(props) {
  console.log('details', props);
  const {personDetails, onClick, onBlackList, onUnBlackList} = props || {};
  const {name, photo, personType, status, id} = personDetails || {};
  const [photoUrl, setPhotoUrl] = useState(photo);

  const formatDate = date => moment(date).format('MMM Do YYYY');

  const preSignUrl = async url => {
    const data = {
      url: url,
      expiryTime: 30,
    };
    await API.preSignedURL(data)
      .then(res => {
        console.log('res.data', res.data?.preSignedUrl);
        setPhotoUrl(res.data?.preSignedUrl);
      })
      .catch(error => {
        console.log(error);
      });
  };
  useEffect(() => {
    preSignUrl(photo);
  }, [photo]);
  return (
    <Flex direction="row" className={styles.mainContainer}>
      <Flex direction="column" className={styles.tabsContainer}>
        <div className={styles.imgContainer}>
          <div className={styles.imgIcon}>
            <img src={photoUrl} className={styles.centerIcon} alt="s" />
          </div>
          <div className={styles.nameBlock}>
            <div className={styles.name}>{name}</div>{' '}
            <div className={styles.nameLabel}>Person Type : {personType}</div>
            <div className={styles.nameLabel}>Status : {status}</div>
          </div>
          {status === 'ISSUED' ||
          status === 'UPDATED' ||
          status === 'UPDATE_REJECTED' ? (
            <div className={styles.blackListBtnContainer}>
              <Button
                onClick={() => {
                  onBlackList(id);
                }}
                buttonStyle="orangeBtn"
                label={'Blacklist'}
                className={styles.blackListBtn}></Button>
            </div>
          ) : null}

          {status === 'BLACKLISTED' ? (
            <div className={styles.blackListBtnContainer}>
              <Button
                onClick={() => {
                  onUnBlackList(id);
                }}
                buttonStyle="orangeBtn"
                label={'Remove Blacklist'}
                className={styles.unBlackListBtn}></Button>
            </div>
          ) : null}
        </div>
      </Flex>
      <Flex direction="column" className={styles.dataContainer}>
        <PersonCard onClick={onClick} personDetails={personDetails} />
      </Flex>
    </Flex>
  );
}

export default PersonDetails;

const DataItem = ({onClick, value, label, labelStyle, document}) => {
  return (
    <Flex className={styles.dataItemWrapper}>
      <div className={[styles.label, labelStyle].join(' ')}>{label} :</div>
      {document && value ? (
        <div className={styles.documentViewLink}>
          {/* <div>{value?.slice(-10)}</div> */}
          <img
            onClick={() => onClick('document-view', value)}
            className={styles.documentIcon}
            alt="."
            src={documentIcon}
          />
        </div>
      ) : (
        <div>
          {value ? (
            value
          ) : (
            <span className={styles.greyColor}>DATA NOT UPLOADED</span>
          )}
        </div>
      )}
    </Flex>
  );
};

const PersonCard = ({company, onClick, personDetails}) => {
  console.log('personDetails...', personDetails);
  const formatDate = date => moment(date).format('DD/MM/YYYY');

  return (
    <Flex direction="column">
      <div className={styles.headLabel}>Person Details</div>

      <Flex>
        <div>
          <DataItem label={'Phone No.'} value={personDetails?.phoneNumber} />
          <DataItem label={'Gender'} value={personDetails?.gender} />
          <DataItem
            label={'Date of Birth'}
            value={formatDate(personDetails?.dob)}
          />

          <DataItem
            label={'Aadhaar Document'}
            document={true}
            onClick={onClick}
            value={personDetails?.aadhaarDocument}
          />
          <DataItem label={'Person Type'} value={personDetails?.personType} />
          <DataItem
            label={'Blood Group'}
            value={personDetails?.bloodGroupName}
          />
        </div>
        <div className={styles.marginLeft30}>
          <DataItem
            label={'Safety Certificate'}
            document={true}
            onClick={onClick}
            value={personDetails?.safetyCertificateUrl}
          />
          <DataItem
            label={'Medical Certificate'}
            document={true}
            onClick={onClick}
            value={personDetails?.medicalCertificateUrl}
          />
          <DataItem label={'Company Name'} value={personDetails?.company} />
          <DataItem
            label={'Document of Police NOC'}
            document={true}
            onClick={onClick}
            value={personDetails?.policeNOCCertificate}
          />
          {personDetails?.drivingLicenceNumber ? (
            <DataItem
              label={'Driving License Number'}
              value={personDetails?.drivingLicenceNumber}
            />
          ) : null}
          {personDetails?.drivingLicenceDocument ? (
            <DataItem
              label={'Driving License Document'}
              document={true}
              onClick={onClick}
              value={personDetails?.drivingLicenceDocument}
            />
          ) : null}
        </div>
      </Flex>
    </Flex>
  );
};
