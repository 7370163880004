export const userImage = './static/images/Icons/user.png';
export const lockImage = './static/images/Icons/lock.png';
export const formFields = [
  {
    field: 'userId',
    type: 'text',
    label: 'Person Name',
    placeholder: 'Enter name',
    required: true,
    inputType: 'phone',
  },
  {
    field: 'password',
    type: 'password',
    label: 'Password',
    placeholder: 'Enter password',
    required: true,
    inputType: 'text',
  },
];

export const INPUT_CONSTANTS = {
  TEXT: 'text',
  DROPDOWN: 'dropdown',
  DATE: 'date',
  FILE: 'file',
  NUMBER: 'number',
  PASSWORD: 'password',
};

export const keys = {
  title: 'Deendayal Port Trust',
  subTitle: 'e-Drishti',
};

export const buttonGroup = [
  {
    label: 'Vehicle Registration',
    navigate: 'vehicle-registration',
  },
  {
    label: 'Person Registration',
    navigate: 'person-registration',
  },
  {
    label: 'Request Free Permit',
    navigate: 'signin',
  },
  {
    label: 'Request For Visitor Permit',
    navigate: 'signin',
  },
  {
    label: 'Check Application Status',
    navigate: 'signin',
  },
];
