import React from 'react';
import {style} from 'react-dom-factories';
import {
  formRightFields,
  formLeftFields,
  shiftFormLeftFields,
  shiftFormMiddleFields,
  shiftFormRightFields,
} from '../../../pages/Operators/Locations/formFeilds';
import Flex from '../../Flex';
import FormElement from '../../FormElement';
import ShiftRows from '../ShiftRows';
import styles from './Styles.module.css';

export interface Props {
  heading: String;
}

const AddNewLocation = (props: Props) => {
  const {
    buttons,
    heading,
    buttonContainer,
    onChangeHandler,
    onBlur,
    stateData,
    shiftRows,
    onShiftChange,
    containerClassNames,
  } = props;
  console.log('stateData - locations', stateData);

  return (
    <div className={[styles.container, containerClassNames].join(' ')}>
      <div className={styles.heading}> {heading}</div>
      <div className={styles.bodyContainer}>
        <div className={[styles.gridOfTwo, styles.divider].join(' ')}>
          <div>
            {formLeftFields.map((elem, idx) => {
              return (
                <div
                  key={`formLeftFields-${idx}`}
                  className={[styles.divider, styles.formFields].join(' ')}>
                  <FormElement
                    type={elem.elementType}
                    label={elem.label}
                    required={elem.required}
                    placeholderText={elem.placeholder}
                    onChange={text => onChangeHandler(elem.stateField, text)}
                    tabIndex={idx * 2 + 1}
                    error={stateData[elem.stateField].error}
                    // options={stateData[elem.stateField].optionsField}
                    value={stateData[elem.stateField].value}
                    key={idx}
                    maxLength={elem.maxLength}
                    keyboardType={elem.keyboardType}
                    containerStyle={{marginBotton: '40px'}}
                    // handleUpload={(file) => handleUpload(elem.stateField, file)}
                    maxFiles={elem.maxFiles}
                    onBlur={() => onBlur(elem.stateField, elem)}
                    allowedFormats={elem.allowedFormats}
                    minimumDate={elem.minimumDate}
                    isVerifyOtp={elem.verifyOtp}
                    fieldName={elem.stateField}
                    containerClassNames={styles.containerClassNames}
                    labelClassNames={styles.labelClassNames}
                  />
                </div>
              );
            })}
          </div>
          <div>
            {formRightFields.map((elem, idx) => {
              return (
                <div
                  key={`formLeftFields-${idx}`}
                  className={[styles.divider, styles.formFields].join(' ')}>
                  <FormElement
                    type={elem.elementType}
                    label={elem.label}
                    required={elem.required}
                    placeholderText={elem.placeholder}
                    onChange={text => onChangeHandler(elem.stateField, text)}
                    tabIndex={idx * 2 + 1}
                    error={stateData[elem.stateField].error}
                    // options={stateData[elem.stateField].optionsField}
                    options={stateData[elem.optionsField]}
                    value={stateData[elem.stateField].value}
                    key={idx}
                    maxLength={elem.maxLength}
                    keyboardType={elem.keyboardType}
                    // containerStyle={{marginBotton: 40 * vh}}
                    // handleUpload={(file) => handleUpload(elem.stateField, file)}
                    maxFiles={elem.maxFiles}
                    onBlur={() => onBlur(elem.stateField, elem)}
                    allowedFormats={elem.allowedFormats}
                    minimumDate={elem.minimumDate}
                    fieldName={elem.stateField}
                    containerClassNames={styles.containerClassNames}
                    labelClassNames={styles.labelClassNames}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div>
          {shiftRows?.map((elem, idx) => {
            console.log('shiftRows!', elem);

            return (
              <ShiftRows onShiftChange={onShiftChange} elem={elem} idx={idx} />
            );
          })}
        </div>
      </div>

      {buttons && buttons.length > 0 ? (
        <div className={[styles.buttonContainer, buttonContainer].join(' ')}>
          {buttons.map(button => {
            const {text, buttonStyles, ...remainingProps} = button;
            return (
              <button
                className={[styles.button, buttonStyles].join(' ')}
                key={'popup-button-' + text}
                {...remainingProps}>
                {text}
              </button>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default AddNewLocation;
