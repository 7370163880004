/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {withRouter} from 'react-router-dom';

import {getFormData, termsData} from './data';
import util from '../../../util';
import {API} from '../../../requests/index';
import ViewRenderer from './views';
import PopupContainer from '../../../components/PopupContainer';
import Popup from '../../../components/SimplePopup';
import Spinner from '../../../components/Spinner';
import strings from '../../../globalization';
import {commonImages, operatorImages} from '../../../assets/images';
import OtpPopup from '../../../components/OtpPopup';
import TakePicture from '../../../components/TakePicture';

import POPUP_CONSTANTS from '../../../constants/popup-constants';
import {showOtpPopup, closePopup, showSpinner} from '../../../util/popup-util';
import {Metadata} from '../../../components/Metadata';
import PAGE_TITLES from '../../../constants/page-titles';
import PAYMENT_CONSTANTS from '../../../constants/payment-constants';
import Privacy from '../../../components/Privacy';
import RegistrationUpdateStatusPopup from '../../../components/RegistrationUpdateStatusPopup';
import styles from './styles.module.scss';

const DROPDOWN_KEY_VALUE = {
  1: 'Person',
  2: 'Vehicle',
  3: 'Agency',
};

class PersonRegistration extends React.Component {
  constructor(props) {
    super(props);
    const {status} = props;
    console.log('status', status);
    let preparedObject = {};
    this.formData = getFormData();
    [...this.formData.left, ...this.formData.right].forEach(obj => {
      preparedObject[obj.stateField] = {value: obj.stateDefault, error: null};
      if (obj.optionsField) {
        preparedObject[obj.optionsField] = [];
      }
    });

    // Remove dl and upload DL from formData initially - Remove after populating state

    this.formData.left = this.formData.left.filter(
      el => el.dependentOnOtherFields === false,
    );
    this.formData.right = this.formData.right.filter(
      el => el.dependentOnOtherFields === false,
    );

    this.state = {
      expiryMinutes: 1,
      checked: false,
      otpVerified: false,
      status,
      currentOtpVerifiactionObject: {
        verifiedNumberFields: {},
      },
      ...preparedObject,
    };

    this.closePopup = closePopup.bind(this);
    this.showOtpPopup = showOtpPopup.bind(this);
    this.showSpinner = showSpinner.bind(this);
  }

  onChange = (action, data) => {
    this.setState(prevState => {
      return {
        ...prevState,
        [action]: data,
      };
    });
  };
  validateForm = () => {
    return true;
  };

  onChangeHandler = (field, value) => {
    console.info('field:', field, 'value:', value);
    let preparedStateObject = {};
    if (field === 'phoneNumber') {
      this.onOtpVerifySuccess(false);
    }
    // if (field === 'personType') {
    //   // reset documentType value  on personType change
    //   this.setState({
    //     documentType: {
    //       value: null,
    //       error: null,
    //     },
    //   });
    // }
    if (field === 'documentType') {
      // reset documentNumber
      this.setState({
        aadhaarPanDrivingLicenseNumber: {
          value: '',
          error: null,
        },
      });
    }
    if (field === 'personType' && value === 1) {
      this.formData = getFormData();
    } else if (field === 'personType' && value !== 1) {
      this.formData.left = this.formData.left.filter(
        el => el.dependentOnOtherFields === false,
      );
      this.formData.right = this.formData.right.filter(
        el => el.dependentOnOtherFields === false,
      );
    }

    if (
      field === 'documentType' &&
      (value === 1 || value === 3 || value === 5)
    ) {
      this.formData.left.forEach(el => {
        if (el.stateField === 'documentValidity') {
          el.disable = true;
          el.required = false;
        }
      });
      this.setState({
        documentValidity: {
          value: null,
          error: null,
        },
      });
    } else if (field === 'documentType') {
      this.formData.left.forEach(el => {
        if (el.stateField === 'documentValidity') {
          el.disable = false;
          el.required = true;
        }
      });
    }
    preparedStateObject[field] = {
      value,
    };
    this.setState(preparedStateObject, function () {
      console.log('this.state', this.state);
    });
  };

  handleUpload = (field, files, error, inputRef) => {
    if (error) {
      this.showErrorPopup(error);
      return;
    }
    this.showSpinner();
    if (files === null) {
      throw new Error('File does not exist');
    }

    let filesArray = [];
    for (let i = 0, numFiles = files.length; i < numFiles; i++) {
      filesArray.push(files[i]);
    }

    console.log('filesArray', filesArray);

    // FIXME: Fix real uploading thing.

    let promises = filesArray.map(file => {
      const data = new FormData();
      data.append('s3FolderName', 'company');
      data.append('file', file);
      data.append('allowedExtensions', ['Pk8b8jAuPC1DF', 'JakNVgcBVZUPG', 'JPw7fCgDTT6AEG', 'PCrAriI079CNG']);
      return API.uploadFilesToDpt(data).then(response => {
        console.log('Response Here', response.data);
        const url = response.data?.fileUrl;
        return {fileUrl: url, fileName: file.name};
      });
    });

    return Promise.all(promises)
      .then(response => {
        console.log('[field] - this.state[field]', this.state[field]);
        console.log('response', response);
        this.setState(prevState => ({
          popup: null,
          [field]: {
            ...prevState[field],
            value: [...prevState[field].value, ...response],
            error: null,
          },
        }));
      })
      .catch(error => {
        console.log('error', error);
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;

        this.setState({
          popup: null,
        });
        this.showErrorPopup(errorMsg);
        if (inputRef) {
          inputRef.current.value = '';
        }
    
        //throw new Error(errorMsg);
        console.log(errorMsg, inputRef);
        
      });
  };

  generateOtp = async phoneNumber => {
    this.showSpinner();
    if (!phoneNumber || phoneNumber.toString().length !== 10) {
      this.showErrorPopup('Please enter a valid phone number');
    } else {
      const result = util.validatePhoneNumber(phoneNumber);
      if (!result) {
        this.showErrorPopup('Please enter a valid phone number');
        return;
      }
      await API.generateOtp({phoneNumber: `+91${phoneNumber}`})
        .then(res => {
          if (res?.data?.phoneNumber === `+91${phoneNumber}`) {
            this.setState({
              expiryMinutes: res?.data?.expiryMinutes,
            });
            this.showOtpPopup();
          } else {
            this.showErrorPopup('Invalid phone number');
          }
        })
        .catch(error => {
          console.log(error);
          // const errorMsg = error.response
          const errorMsg = util.getErrorMessage(error);
          // const errorMsg =
          //   'Invalid Phone Number. Please enter a valid phone number.';
          this.showErrorPopup(errorMsg);
        });
    }
  };

  onSaveCameraPicture = file => {
    this.setState({
      individualPhoto: {
        value: [],
        error: null,
      },
    });
    this.handleUpload('individualPhoto', [file]);
  };

  onOtpVerifySuccess = isSucess => {
    const {currentOtpVerifiactionObject} = this.state;
    if (true) {
      this.setState({
        otpVerified: isSucess,
        currentOtpVerifiactionObject: {
          ...this.state.currentOtpVerifiactionObject,
          otpVerified: isSucess,
          verifiedNumberFields: {
            ...this.state.currentOtpVerifiactionObject.verifiedNumberFields,
            [currentOtpVerifiactionObject.field]: isSucess,
          },
        },
      });
    }
  };

  showTermsPopup = () => {
    console.log('showTermsPopup');
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.TERMS_POPUP,
        onClose: this.closePopup,
        onButtonClick: this.acceptTerms,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.acceptTerms,
            outline: true,
          },
        ],
      },
    });
  };
  acceptTerms = () => {
    this.setState({
      checked: true,
      popup: null,
    });
  };
  showSuccessPopup(successMessage) {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: successMessage,
        onClose: this.closePopup,
        headingImage: operatorImages.successIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  }

  getSuccessMessage = referenceId => {
    const {personDetails} = this.state;

    return (
      <div className={styles.requestSuccess}>
        <div className={styles.blueText}>Reference Number - {referenceId}</div>
        <div className={styles.successIconContainer}>
          <img
            src={operatorImages.successIcon}
            className={styles.successIcon}
            alt="logo"
          />
          <div className={styles.blueText}>
            {personDetails?.status === 'UPDATED' ||
            personDetails?.status === 'ISSUED' ||
            personDetails?.status === 'UPDATED_REJECTED'
              ? 'Your details are submitted for reverification'
              : 'Your details have been successfully updated'}
          </div>
        </div>
      </div>
    );
  };

  showUpdateStatusPopup = referenceId => {
    const {history} = this.props;
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.STANDARD,
        heading: `${'Person Request'}`,
        message: this.getSuccessMessage(referenceId),
        data: {},
        onClose: this.closePopup,
        buttonContainer: styles.buttonContainer,
        buttons: [
          {
            text: 'Close',
            buttonStyles: styles.backButton,
            onClick: () => {
              history.push('/manage-registration-information');
            },
          },
        ],
      },
    });
  };

  getPopupContent = () => {
    const {popup, phoneNumber} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="blue" />;
      }
      case POPUP_CONSTANTS.STANDARD: {
        return <RegistrationUpdateStatusPopup {...popup} />;
      }

      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }

      case POPUP_CONSTANTS.OTP_POPUP: {
        return (
          <OtpPopup
            onClose={this.closePopup}
            showSuccessPopup={() =>
              this.showSuccessPopup('Phone number verified successfully!')
            }
            showErrorPopup={errorMessage => {
              this.showErrorPopup(errorMessage);
            }}
            phoneNumber={this.state?.currentOtpVerifiactionObject.phoneNumber}
            onOtpVerifySuccess={this.onOtpVerifySuccess}
            message={
              strings.message +
              phoneNumber.value.substr(phoneNumber.value.length - 4)
            }
            resendOTP={this.generateOtp}
            expiryMinutes={this.state?.expiryMinutes}
          />
        );
      }
      case POPUP_CONSTANTS.PHOTO_CAPTURE_POPUP: {
        return (
          <TakePicture
            onClose={this.closePopup}
            showError={() =>
              this.showErrorPopup(
                'Something went wrong! Please try enabling the camera.',
              )
            }
            onSaveCameraPicture={this.onSaveCameraPicture}
            handleUpload={(file, error) => {
              this.setState({
                individualPhoto: {
                  value: [],
                  error: null,
                },
              });
              this.handleUpload('individualPhoto', file, error);
            }}
          />
        );
      }
      case POPUP_CONSTANTS.TERMS_POPUP: {
        return (
          <Privacy
            onClose={this.closePopup}
            messageObject={termsData}
            heading="Terms and Conditions"
            {...popup}
          />
        );
      }
      default: {
        console.warn('case not handled', popup.type);
        return null;
      }
    }
  };

  getData = async () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });

    const promises = [];

    promises.push(
      API.getAllDepartments().then(departmentsRes => {
        //       const {departmentId: departmentElement} = this.state;
        this.setState({
          deptOptions: util.addDataToStrings(departmentsRes.data),
        });
        console.log('state object:', this.state);
      }),
    );

    promises.push(
      API.getAllGender().then(genderRes => {
        this.setState({
          genderOptions: util.addDataToStrings(genderRes.data),
        });
      }),
    );

    promises.push(
      API.getAllPersonOptions().then(personOptionsRes => {
        this.setState({
          personTypeOptions: util.addDataToStrings(personOptionsRes.data),
        });
      }),
    );

    promises.push(
      API.getAllCountries().then(countriesRes => {
        this.setState({
          countryOptions: util.addDataToStrings(countriesRes.data),
        });
      }),
    );

    promises.push(
      API.getAllBloodGroups().then(bloodGroupRes => {
        this.setState({
          bloodGroupOptions: util.addDataToStrings(bloodGroupRes.data),
        });
      }),
    );

    promises.push(
      API.fetchOptions(DROPDOWN_KEY_VALUE[1]).then(optionsRes => {
        this.setState({
          documentTypeOptions: util.addDataToStrings(optionsRes.data),
          documentTypesAllOptions: util.addDataToStrings(optionsRes.data),
        });
      }),
    );

    Promise.all(promises)
      .then(() => {
        this.setState({
          popup: null,
        });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        this.showErrorRetryPopup(errorMsg, this.getData);
      });
  };

  handleErrors = () => {
    console.log('Handling errors');
    let err = false;
    const update = {};
    const {personType} = this.state;
    let requiredBool = false;

    let formData = [...this.formData.left, ...this.formData.right];
    for (let i = 0; i < formData.length; i++) {
      let element = formData[i];
      requiredBool = false;

      if (
        element.stateField === 'emailAddress' ||
        element.stateField === 'GSTNumber'
      ) {
        if (this.state[element.stateField].value) {
          requiredBool = true;
        }
      }

      let error = util.validate(this.state[element.stateField].value, {
        ...element,
        required: requiredBool || element.required,
      });

      if (personType.value === 1 && element.stateField === 'dateOfBirth') {
        if (!error) {
          // isOver18
          error = util.validateAge(this.state[element.stateField].value);
        }
      }

      if (error) {
        err = true;
        update[element.stateField] = {
          ...this.state[element.stateField],
          error: error,
        };
      } else {
        update[element.stateField] = {
          ...this.state[element.stateField],
          error: null,
        };
      }
    }
    this.setState({
      ...update,
    });
    return err;
  };

  getOptions = element => {
    const {countries, personType, documentType, genderTypes} = this.state;
    switch (element) {
      case 'countryId':
        return countries;
      case 'typeId':
        return personType;
      case 'genderId':
        return genderTypes;
      case 'documentTypeId':
        return documentType;
      case 'nationality':
        return countries;
      default:
        return [];
    }
  };

  componentDidMount() {
    this.getPreLoginToken();
   // this.getData();
    const {status, location} = this.props;
    const {referenceId} = location;
    console.log(status, this.props, location?.referenceId);
    if (status === 'EDIT') {
      API.getPersonDetailsByReferenceId(referenceId)
        .then(response => {
          console.log('personDetails', response.data);
          this.setState({
            personDetails: response.data,
            popup: null,
          });
          this.onChangeHandler('personType', response.data?.typeId);
          const personDetails = {
            ...response.data,
            individualName: response.data?.name,
            aadhaarDocument: response.data?.aadhaarDocument
              ? [
                  {
                    fileUrl: response.data?.aadhaarDocument,
                    fileName: response.data?.aadhaarDocument.substr(
                      response.data?.aadhaarDocument.length - 10,
                    ),
                  },
                ]
              : null,
            policeNOCCertificate: response.data?.policeNOCCertificate
              ? [
                  {
                    fileUrl: response.data?.policeNOCCertificate,
                    fileName: response.data?.policeNOCCertificate.substr(
                      response.data?.policeNOCCertificate.length - 10,
                    ),
                  },
                ]
              : null,
            drivingLicenceDocument: response.data?.drivingLicenceDocument
              ? [
                  {
                    fileUrl: response.data?.drivingLicenceDocument,
                    fileName: response.data?.drivingLicenceDocument.substr(
                      response.data?.drivingLicenceDocument.length - 10,
                    ),
                  },
                ]
              : null,

            individualPhoto: response.data?.photo
              ? [
                  {
                    fileUrl: response.data?.photo,
                    fileName: response.data?.photo.substr(
                      response.data?.photo.length - 10,
                    ),
                  },
                ]
              : null,
            medicalCertificate: response.data?.medicalCertificateUrl
              ? [
                  {
                    fileUrl: response.data?.medicalCertificateUrl,
                    fileName: response.data?.medicalCertificateUrl.substr(
                      response.data?.medicalCertificateUrl.length - 10,
                    ),
                  },
                ]
              : null,
            safetyDocument: response.data?.safetyCertificateUrl
              ? [
                  {
                    fileUrl: response.data?.safetyCertificateUrl,
                    fileName: response.data?.safetyCertificateUrl.substr(
                      response.data?.safetyCertificateUrl.length - 10,
                    ),
                  },
                ]
              : null,
            officialContractDocument: response.data?.officialContractDocument
              ? [
                  {
                    fileUrl: response.data?.officialContractDocument,
                    fileName: response.data?.officialContractDocument.substr(
                      response.data?.officialContractDocument.length - 10,
                    ),
                  },
                ]
              : null,

            phoneNumber: response.data?.phoneNumber.slice(3),
            companyName: response.data?.company,
            bloodGroup: response.data?.bloodGroupId,
            gender: response.data?.genderId,
            personType: response.data?.typeId,
            departmentId: response.data?.departmentId,
            dateOfBirth: new Date(response.data?.dob).getTime(),
            GSTNumber: response.data?.gstnumber,
          };

          if (status === 'EDIT') {
            [...this.formData.left, ...this.formData.right].forEach(obj => {
              console.log(obj?.stateField, personDetails['' + obj.stateField]);
              if (obj.inputType === 'upload') {
                this.setState({
                  [obj.stateField]: {
                    value: personDetails['' + obj.stateField]
                      ? personDetails['' + obj.stateField]
                      : [],
                    error: null,
                  },
                });
              } else {
                this.setState({
                  [obj.stateField]: {
                    value: personDetails['' + obj.stateField],
                    error: null,
                  },
                });
              }
            });
          }
          console.log(this.state, this.formData.right);
        })
        .catch(error => {
          const errorMsg = util.getErrorMessage(error);
          console.log(errorMsg);
          this.showErrorPopup(errorMsg);
        });
    }
  }

  getPreLoginToken = () => {
    API.getPreLoginToken()
      .then(response => {
        console.log(response);
        API.setPreLoginToken(response);
        this.getData();
      })
      .catch(error => {
        //this.showErrorPopup('');
        console.log(error);
      });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', null);
  }
  handleBackClick = () => {
    const {history} = this.props;
    history.push('/signin');
  };
  openOTPPopUp = () => {
    this.setState(
      {
        currentOtpVerifiactionObject: {
          field: 'phoneNumber',
          phoneNumber: this.state['phoneNumber'].value,
          verifiedNumberFields: {
            ...this.state?.currentOtpVerifiactionObject?.verifiedNumberFields,
          },
        },
      },
      () => {
        this.generateOtp(this.state.currentOtpVerifiactionObject.phoneNumber);
      },
    );
  };

  handleSubmit = async () => {
    console.log('this.state', this.state);
    const {history, status} = this.props;
    const {otpVerified, personDetails} = this.state;
    let error = this.handleErrors();

    this.showSpinner();
    if (error) {
      this.closePopup();
    } else if (!otpVerified) {
      this.openOTPPopUp();
    } else {
      this.submitFormData()
        .then(res => {
          console.log('Res', res);
          let referenceId = res.referenceId;
          console.log('REFERENCE ID: ', referenceId);
          let paymentDetails = res.paymentDetails;
          console.log('paymentDetails', res.paymentDetails);

          if (status === 'EDIT') {
            if (
              (referenceId && personDetails?.status === 'PAYMENT_PENDING') ||
              personDetails?.status === 'PAYMENT_FAILED'
            ) {
              this.closePopup();
              history.push('/payments', {
                id: referenceId,
                title: 'Person Registration',
                paymentDetails: paymentDetails,
                type: PAYMENT_CONSTANTS.PERSON,
              });
            } else {
              this.showUpdateStatusPopup(referenceId);
            }
          } else {
            if (referenceId) {
              this.closePopup();
              history.push('/payments', {
                id: referenceId,
                title: 'Person Registration',
                paymentDetails: paymentDetails,
                type: PAYMENT_CONSTANTS.PERSON,
              });
            } else {
              console.log('Something went wrong');
            }
          }
        })
        .catch(errors => {
          const errorMsg = errors.response
            ? errors.response.data.message
            : errors.message;
          this.showErrorPopup(errorMsg);
          console.log(errors);
        });
    }
  };
  submitFormData = async () => {
    const {status} = this.props;
    const {personDetails} = this.state;
    let dateOfBirth = util.getDateTimeString(
      this.state.dateOfBirth.value,
      'mmddyyyy',
    );
    let validity = this.state.drivingLicenceValidity.value
      ? util.getDateTimeString(
          this.state.drivingLicenceValidity.value,
          'mmddyyyy',
        )
      : undefined;

    const policeNOCValidity = this.state?.policeNOCValidity.value
      ? util.getDateTimeString(this.state.policeNOCValidity.value, 'mmddyyyy')
      : undefined;

    let preparedData = {
      name: this.state.individualName.value,

      phoneNumber: `+91${this.state.phoneNumber.value}`,
      genderId: this.state.gender.value,
      DOB: dateOfBirth,
      photo: this.state.individualPhoto.value[0].fileUrl,
      typeId: this.state.personType.value,
      departmentId: this.state.departmentId?.value,
      bloodGroupId: this.state.bloodGroup.value,
      medicalCertificateUrl:
        this.state.medicalCertificate.value.length > 0
          ? this.state.medicalCertificate.value[0].fileUrl
          : '',

      safetyCertificateUrl:
        this.state.safetyDocument.value.length > 0
          ? this.state.safetyDocument.value[0].fileUrl
          : '',

      company: this.state.companyName.value,

      aadharNumber: this.state.aadhaarNumber.value,
      aadhaarDocument: this.state.aadhaarDocument.value[0].fileUrl,

      policeNOCValidity: policeNOCValidity,
      policeNOCCertificate:
        this.state.policeNOCCertificate.value.length > 0
          ? this.state.policeNOCCertificate.value[0].fileUrl
          : '',
      drivingLicenceNumber: this.state.drivingLicenceNumber.value,
      drivingLicenceDocument:
        this.state.drivingLicenceDocument.value.length > 0
          ? this.state.drivingLicenceDocument.value[0].fileUrl
          : '',
      officialContractDocument:
        this.state.officialContractDocument.value.length > 0
          ? this.state.officialContractDocument.value[0].fileUrl
          : '',
    };
    if (this.state.drivingLicenceValidity.value) {
      preparedData.drivingLicenceValidity = util.getDateTimeString(
        this.state.drivingLicenceValidity.value,
        'mmddyyyy',
      );
    }

    if (this.state.medicalCertificateValidity.value) {
      preparedData.medicalCertificateValidity = util.getDateTimeString(
        this.state.medicalCertificateValidity.value,
        'mmddyyyy',
      );
    }
    if (this.state.safetyCertificateValidity.value) {
      preparedData.safetyCertificateValidity = util.getDateTimeString(
        this.state.safetyCertificateValidity.value,
        'mmddyyyy',
      );
    }
    if (this.state.officialContractDocumentValidity.value) {
      preparedData.officialContractDocumentValidity = util.getDateTimeString(
        this.state.officialContractDocumentValidity.value,
        'mmddyyyy',
      );
    }
    if (this.state.emailAddress.value) {
      preparedData.emailAddress = this.state.emailAddress.value;
    }
    if (this.state.GSTNumber.value) {
      preparedData.GSTNumber = this.state.GSTNumber.value;
    }

    console.log('preparedData:', preparedData);
    console.log('state value:', this.state);

    let res;
    if (status === 'EDIT') {
      if (
        personDetails?.status === 'ISSUED' ||
        personDetails?.status === 'UPDATED' ||
        personDetails?.status === 'UPDATE_REJECTED'
      ) {
        res = await API.updateIssuedPersonRegistration({
          ...preparedData,
          id: personDetails?.id,
        });
      } else {
        res = await API.updatePersonRegistration({
          ...preparedData,
          id: personDetails?.id,
        });
      }
    } else {
      res = await API.registerPerson(preparedData);
    }
    console.log('preparedData', preparedData);
    return res.data;
  };

  onBack = () => {
    const {history} = this.props;
    history.push('/signin');
  };
  onBlur = (field, element) => {
    const currentObj = this.state[field];

    console.log('formField', element);
    this.setState({
      [field]: {
        ...currentObj,
        error: element ? util.validate(currentObj.value, element) : null,
      },
    });
  };
  toggleChecked = () => {
    // Open terms popup
    const {checked} = this.state;
    if (!checked) {
      this.showTermsPopup();
    } else {
      this.setState({
        checked: !this.state.checked,
      });
    }
  };

  onClickHandler = (action, data) => {
    switch (action) {
      case 'otp-verify': {
        this.setState(
          {
            currentOtpVerifiactionObject: {
              field: data.stateField,
              phoneNumber: this.state[data.stateField].value,
              verifiedNumberFields: {
                ...this.state?.currentOtpVerifiactionObject
                  ?.verifiedNumberFields,
              },
            },
          },
          () => {
            this.generateOtp(
              this.state.currentOtpVerifiactionObject.phoneNumber,
            );
          },
        );

        // this.generateOtp(this.state.phoneNumber?.value);
        break;
      }
      case 'take-picture': {
        console.log('take-picture');
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.PHOTO_CAPTURE_POPUP,
          },
        });
        break;
      }
      case 'terms': {
        this.showTermsPopup();
        break;
      }
      default: {
        console.info('case not handled', action);
        break;
      }
    }
  };

  prepareProps = () => {
    return {
      onClick: this.onClickHandler,
      onChangeHandler: this.onChangeHandler,
      formLeftFields: this.formData.left,
      formRightFields: this.formData.right,
      onBlur: this.onBlur,
      handleUpload: this.handleUpload,
      backClick: this.handleBackClick,
      onSubmit: this.handleSubmit,
      toggleChecked: this.toggleChecked,
      stateData: this.state,
      otpVerifiedField: this.state.currentOtpVerifiactionObject,
      ...this.state,
    };
  };

  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  showErrorRetryPopup = (errorMsg, retryCallback) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMsg,
        messageStyle: {color: '#E67717'},

        buttons: [
          {
            text: strings.cancelPopup,
            onClick: this.closePopup,
            outline: true,
          },
          {
            text: strings.retryApiPopup,
            onClick: () => {
              closePopup.call(this);
              retryCallback();
            },
          },
        ],
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
      },
    });
  };

  render() {
    const {popup} = this.state;
    const props = this.prepareProps();
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <Metadata title={PAGE_TITLES.personRegistration} />

        <ViewRenderer {...props} />
      </>
    );
  }
}

export default withRouter(PersonRegistration);
// export default PersonRegistration;
