export const customStyles = {
  placeholder: (provided, state) => {
    return {
      ...provided,
      fontFamily: 'Poppins-Regular',
      fontSize: '14px',
      color: '#fff',
      letterSpacing: '0.56px',
      fontWeight: 400,
      display: 'flex',
      alignItems: 'center',
    };
  },
  control: (provided, state) => {
    return {
      ...provided,
      height: '40px',
      marginTop: '0.2rem',
      width: 320,
      background: '#002C94',
      borderWidth: 0,
      fontSize: '0.8rem',
      borderRadius: '5px',
    };
  },
  singleValue: provided => ({
    ...provided,
    color: 'white',
  }),
  ValueContainer: provided => {
    return {
      ...provided,
      color: '#FFFFFF',
    };
  },
  indicatorSeparator: provided => {
    return {
      ...provided,
      backgroundColor: '#E6EDFF',
    };
  },
};
