// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login: '/dpt/user/login',
  logout: '/dpt/user/logout',
  department: '/dpt/dept/fetchActiveDepartments',
  businesses: '/dpt/business/fetchActiveBusinessDetails',
  registerCompany: '/dpt/company/registerCompanyDetails',
  workPurpose: '/dpt/workPurpose/fetchActiveWorkPurpose',
  workArea: '/dpt/workArea/fetchActiveWorkArea',
  gender: '/dpt/gender/fetchAllGender',
  getPreLoginToken: '/dpt/user/generateToken',
  // registerPermit: '/dpt/individualPermit/registerPermitRequest',
  statuses: '/dpt/individualPermit/fetchByVisitingDate',
  companyStatus: '/dpt/company/fetchByReferenceId',
  fileUpload: '/dpt/file/uploadFilesToS3',
  uploadFilesToDpt: '/dpt/file/uploadFilesToDpt',
  approveIndividualPermit: '/dpt/individualPermit/approve',
  rejectIndividualPermit: '/dpt/individualPermit/reject',
  allIndividualPermit: '/dpt/individualPermit/fetchAllIndividualPermitRequests',
  allCompanies: '/dpt/company/fetchAllCompanies',
  personRegistration: '/dpt/individualPermit/personRegistration',
  getOnePerson: '/dpt/individualPermit/getByPersonReferenceId',
  fetchOptions: '/dpt/company/fetchDocumentTypes',
  getPersonReferenceNumber: '/dpt/individualPermit/getPersonReferenceNumber',
  getCompanyReferenceNumber: '/dpt/company/getCompanyReferenceNumber',
  fetchAllPersonOptions: '/dpt/personType/fetchActivePersonType',
  fetchAllCountries: '/dpt/country/fetchActiveCountries',
  fetchAllBloodGroups: '/dpt/bloodGroup/fetchAllBloodGroup',
  fetchActiveVehicleTypes: '/dpt/vehicleType/fetchActiveVehicleType',

  //PERMITS
  fetchPermitsDashboard: '/dpt/permitDetails/fetchPermitsDashboard',
  fetchMaterialData: '/dpt/pcsIntegration/fetchMaterialData',
  fetchAllCompanyPermitDetails:
    '/dpt/permitDetails/fetchAllCompanyPermitDetails',
  fetchAllCompanyPermitsInViews:
    '/dpt/permitDetails/fetchAllCompanyPermitsInViews',
  fetchPermitDetailsForViews: '/dpt/permitDetails/fetchPermitDetailsForViews',

  fetchExportMaterialData: '/dpt/pcsIntegration/fetchExportMaterialData',
  fetchActiveVesselTypes: '/dpt/permitDetails/fetchActiveVesselTypes',

  //AGENCY USER MANAGEMENT
  fetchCompanyUsers: '/dpt/user/fetchCompanyUsers',
  getAgencyRoles: '/dpt/company/fetchCompanyRolesList',
  addAgencyUser: '/dpt/user/addCompanyUser',
  editAgencyUser: '/dpt/user/editCompanyUser',
  deleteAgencyUser: '/dpt/user/deleteCompanyUser',
  updateCompanyPendingUser: '/dpt/user/updateCompanyPendingUser',
  updateCompanyDetails: '/dpt/company/updateCompanyDetails',
  editUserProfile: '/dpt/user/editUserProfile',

  // AGENCY VEHICLE/PERSON REPORTS
  agencyReportsForVehicles: '/dpt/reports/agencyReportsForVehicles',
  agencyReportsForPersons: '/dpt/reports/agencyReportsForPersons',
  agencyReportsForVehicleEntryAndExit: 'dpt/reports/agencyReportsOfVehiclesEntryAndExit',



  // TRAFFIC DEPARTMENT SERVICES
  fetchAllMaterialPermitDetailsForTraffic:
    '/dpt/permitDetails/fetchAllMaterialPermitDetailsForTraffic',
  fetchAllMaterialPermitDetailsForTrafficByVCN:
    '/dpt/permitDetails/fetchAllMaterialPermitDetailsForTrafficByVCN',

  approveBulk: '/dpt/permitDetails/approveBulk',
  rejectBulk: '/dpt/permitDetails/rejectBulk',
  fetchDepartmentVCNNumbers: '/dpt/permitDetails/fetchAllDepartmentVCNNumers',

  //DEPARTMENT SERVICES
  fetchDepartmentRolesList: '/dpt/dept/fetchDepartmentRolesList',
  fetchAllDepartmentUsers: '/dpt/dept/fetchAllDepartmentUsers',
  addDepartmentUser: '/dpt/dept/addDepartmentUser',
  editDepartmentUser: '/dpt/dept/editDepartmentUser',
  deleteDepartmentUser: '/dpt/dept/deleteDepartmentUser',
  fetchAllCompaniesByDepartment: '/dpt/company/fetchAllCompaniesByDepartment',
  approveCompany: '/dpt/company/approveCompany',
  rejectCompany: '/dpt/company/rejectCompany',

  // ADMIN
  fetchActiveCompanyDetails: '/dpt/company/fetchActiveCompanyDetails',
  fetchAllCompanies: '/dpt/company/fetchAllCompanies',
  generateOtp: '/dpt/api/phone-verification/generateOtp',
  verifyOtp: '/dpt/api/phone-verification/verifyOtp',
  fetchAllPermitDetails: '/dpt/permitDetails/fetchAllPermitDetails',
  approveRequest: '/dpt/permitDetails/approve',
  rejectRequest: '/dpt/permitDetails/reject',

  // OPERATORS
  fetchAllOperators: '/dpt/operator/fetchAllOperators',
  fetchActiveLocation: '/dpt/location/fetchActiveLocation',
  saveOperatore: '/dpt/operator/saveOperator',
  deleteOperator: '/dpt/operator/deleteOperator',

  // Locations
  saveLocation: '/dpt/location/saveLocation',
  deleteLocation: '/dpt/location/deleteLocation',
  fetchShift: '/dpt/location/fetchShiftByLocation',
  updateLocation: '/dpt/location/updateLocation',

  fetchLocationById: '/dpt/location/fetchLocationDetails',

  // Operators

  operatorApprovePerson: '/dpt/personPass/issue',
  operatorRejectPerson: '/dpt/personPass/hold',

  operatorApproveVehicle: '/dpt/vehiclePass/issue',
  operatorRejectVehicle: '/dpt/vehiclePass/hold',

  updateOperator: '/dpt/operator/editOperator',
  fetchOperatorById: '/dpt/operator/fetchOperatorDetails',
  updatePersonByOperator: '/dpt/operator/updatePersonByOperator',

  // VEHICLE
  registerVehicle: '/dpt/vehiclePermit/registerVehiclePermitRequest',
  vehicleRequests: '/dpt/vehiclePermit/fetchByVisitingDate',
  allVehicleRequests: '/dpt/vehiclePermit/fetchAllVehiclePermitRequests',
  approveVehicle: '/dpt/vehiclePermit/approve',
  rejectVehicle: '/dpt/vehiclePermit/reject',
  vehicleRegister: '/dpt/vehiclePermit/vehicleRegistration',
  updateVehicleByOperator: '/dpt/operator/updateVehicleByOperator',
  getVehicleReferenceNumber: '/dpt/vehiclePermit/getVehicleReferenceNumber',
  getOneVehicle: '/dpt/vehiclePermit/getByVehicleReferenceId',
  vehicleByRegRefRFID: '/dpt/permitDetails/checkPermitStatus',
  getVehicleByIdNumber: '/dpt/vehiclePermit/getVehicleByIdNumber',
  checkPermitStatusForPedestrian: 'dpt/permitDetails/checkPermitStatusForPedestrian',

  surrenderPerson: '/dpt/surrenderPass/surrenderPersonPass',
  surrenderVehicle: '/dpt/surrenderPass/surrenderVehiclePass',

  fetchAllRoles: '/dpt/role/fetchActiveRoles',

  // Messages
  fetchAllSentMessages: '/dpt/operatorMessages/fetchAllSentMessages',
  fetchAllReceivedMessages: '/dpt/operatorMessages/fetchAllRecievedMessages',
  sendMessage: '/dpt/operatorMessages/sendMessage',

  // Fetching documents
  fetchPersonDocumentTypes: '/dpt/company/fetchDocumentTypes/Person',

  createPaymentOrderId:
    'https://sf0jj1jsz9.execute-api.ap-south-1.amazonaws.com/dev/create-order',
  personPaymentFailure: '/dpt/individualPermit/personPaymentFailure',
  personPaymentSuccess: '/dpt/individualPermit/personPaymentSucess',

  // FIXME: Add correct APIs
  vehiclePaymentFailure: '/dpt/vehiclePermit/vehiclePaymentFailure',
  vehiclePaymentSuccess: '/dpt/vehiclePermit/vehiclePaymentSucess',

  // Agency Requests
  fetchVehicleValidity: '/dpt/vehicleValidity/fetchActiveVehicleValidity',
  registerPermit: '/dpt/permitDetails/registerPermit',
  // Materials
  fetchAllMaterialTypes: '/dpt/material/fetchActiveMaterialTypes',
  fetchAllNatureOfCargo: '/dpt/nature/fetchActiveCargoNature',
  fetchAllUnitOfWeight: '/dpt/unitWeight/fetchActiveUnitWeights',
  fetchAllPermitTypes: '/dpt/permitType/fetchActivePermitTypes',
  fetchAllVehicleTypes: '/dpt/vehicleCategory/fetchAllVehicleCategory',

  fetchDriversForCompany: '/dpt/individualPermit/fetchActiveDriversForCompany',
  checkPerson: '/dpt/individualPermit/checkPerson',
  checkVehicle: '/dpt/vehiclePermit/checkVehicle',
  createOrder: '/dpt/credits/createOrder',
  addCredits: '/dpt/credits/addCredits',
  getCreditWalletDetails: '/dpt/credits/getCreditWalletDetails',
  getCreditsUsageHistory: '/dpt/credits/getCreditsUsageHistory',
  updatePersonDrivingLicence:
    '/dpt/individualPermit/updatePersonDrivingLicence',

  getAllPasses: '/dpt/wallet/passCategories',
  getPacksByPassCategory: '/dpt/wallet/packsByPassId',
  calculatePayment: '/dpt/wallet/payment/calculate',
  paymentFailure: '/dpt/wallet/payment/failure',
  paymentSuccess: '/dpt/wallet/payment/success',
  createOrderWallet: '/dpt/wallet/payment/create-order',
  getAllVehicleCategories: '/dpt/vehicleCategory/fetchAllVehicleCategory',
  getAllAdminPortalMetadata: '/dpt/dashboard/fetchDashBoard',

  // MANAGE REFERENCE -- EDIT/UPDATE PERSON, VEHICLE AND AGENCY
  verifyPersonByReferenceId: '/dpt/individualPermit/verifyPersonByReferenceId',
  verifyVehicleByReferenceId: '/dpt/vehiclePermit/verifyVehicleByReferenceId',
  verifyCompanyByReferenceId: '/dpt/company/verifyCompanyByReferenceId',

  getPersonDetailsByReferenceId: '/dpt/individualPermit/getByPersonReferenceId',
  getVehicleDetailsByReferenceId: '/dpt/vehiclePermit/getByVehicleReferenceId',
  getAgencyRegistrationDetails: '/dpt/company/fetchPendingCompanyByReferenceId',

  updatePersonRegistration: '/dpt/individualPermit/updatePersonRegistration',
  updateVehicleRegistration: '/dpt/vehiclePermit/updateVehicleRegistration',
  updateAgencyRegistration: '/dpt/company/updatePendingCompanyDetails',

  updateIssuedPersonRegistration:
    '/dpt/individualPermit/updateIssuedPersonRegistration',

  operatorReVerifyApprovePersonRegistration:
    '/dpt/operator/operatorReVerifyApprovePersonRegistration',

  operatorReVerifyRejectPersonRegistration:
    '/dpt/operator/operatorReVerifyRejectPersonRegistration',

  updateIssuedVehicleRegistration:
    '/dpt/vehiclePermit/updateIssuedVehicleRegistration',

  operatorReVerifyApproveVehicleRegistration:
    '/dpt/operator/operatorReVerifyApproveVehicleRegistration',

  operatorReVerifyRejectVehicleRegistration:
    '/dpt/operator/operatorReVerifyRejectVehicleRegistration',

  // Operators verify registrations
  verifyRegistrationsOperators: '/dpt/operator/verifyRegistrations',
  fetchPersonRejectReasons: '/dpt/operator/fetchPersonRejectReason',
  fetchVehicleRejectReasons: '/dpt/operator/fetchVehicleRejectReason',

  operatorApprovePersonRegistration:
    '/dpt/operator/operatorApprovePersonRegistration',
  operatorRejectPersonRegistration:
    '/dpt/operator/operatorRejectPersonRegistration',
  operatorApproveVehicleRegistration:
    '/dpt/operator/operatorApproveVehicleRegistration',
  operatorRejectVehicleRegistration:
    '/dpt/operator/operatorRejectVehicleRegistration',

  // Department validate registrations
  departmentValidateRegistrations: '/dpt/dept/validateRegistrations',
  departmentAdminApprovePersonRegistration:
    '/dpt/dept/departmentAdminApprovePersonRegistration',
  departmentAdminRejectPersonRegistration:
    '/dpt/dept/departmentAdminRejectPersonRegistration',
  departmentAdminApproveVehicleRegistration:
    '/dpt/dept/departmentAdminApproveVehicleRegistration',
  departmentAdminRejectVehicleRegistration:
    '/dpt/dept/departmentAdminRejectVehicleRegistration',

  //  viewPersonHistory: '/dpt/individualPermit/fetchPersonHistoryByPersonID',
  //  viewVehicleHistory: '/dpt/vehiclePermit/fetchVehicleHistoryByVehicleId',
  viewPersonHistory: '/dpt/individualPermit/fetchPersonHistoryByReferenceId',
  viewVehicleHistory:
    '/dpt/vehiclePermit/fetchVehicleHistoryByVehicleReferenceId',

  fetchPersonByAadhaarNumber:
    '/dpt/individualPermit/fetchPersonByAadhaarNumber',
  fetchVehicleByVehicleRegistrationNumber:
    '/dpt/vehiclePermit/fetchVehicleByVehicleRegistrationNumber',
  fetchByVehicleRegistrationNumberOrRefNoOrRfidNumberAndVisitDate:
    '/dpt/vehiclePermit/fetchByVehicleRegistrationNumberOrRefNoOrRfidNumberAndVisitDate',

  fetchGroundStockData: '/dpt/permitDetails/fetchGroundStock',
  saveGroundStock: '/dpt/permitDetails/saveGroundStock',

  trafficDepartmentDashboard: '/dpt/dashboard/fetchDepartmentDashBoard',
  fetchDepartmentDashBoardWithTrends:
    '/dpt/dashboard/fetchDepartmentDashBoardWithTrends',

  changeDriver: '/dpt/permitDetails/editDriver',
  //master admin
  fetchPermitByReferenceId: '/dpt/permitDetails/fetchPermitByReferenceId/',
  fetchPersonTypeTableData: '/dpt/personType/fetchPersonTypeForMaster',
  fetchGenderTypeTableData: '/dpt/gender/fetchAllGenderForMaster',
  fetchBloodGroupTableData: '/dpt/bloodGroup/fetchAllBloodGroupForMaster',
  fetchVehicleTableData: '/dpt/vehicleType/fetchAllVehicleTypeForMaster',
  fetchVehicleCategoryTableData:
    '/dpt/vehicleCategory/fetchAllVehicleCategoryForMaster',
  fetchRolesTableData: '/dpt/role/fetchAllRoles',
  fetchBusinessDetailTableData: '/dpt/business/fetchAllBusinessDetails',
  fetchWorkPurposeTableData: '/dpt/workPurpose/fetchAllWorkPurpose',
  fetchWorkAreaTableData: '/dpt/workArea/fetchAllWorkArea',
  fetchUnitOfWeightTableData: '/dpt/unitWeight/fetchAllUnitWeights',
  fetchGateLevelRejectReasonTableData:
    '/dpt/gateErrorMessage/fetchAllErrorMessages',
  fetchWalletBalancesReportTableData: '/dpt/wallet/walletBalanceReport',
  fetchPassAuditReportDataTableData: '/dpt/wallet/passAuditReport',
  savePersonType: '/dpt/personType/savePersonType',
  saveGenderType: '/dpt/gender/saveGender',
  saveBloodGroupType: '/dpt/bloodGroup/saveBloodGroup',
  saveVehicleType: '/dpt/vehicleType/saveVehicleType',
  saveVehicleCategoryType: '/dpt/vehicleCategory/saveVehicleCategory',
  saveRolesType: '/dpt/role/saveRole',
  saveWorkAreaType: '/dpt/workArea/saveWorkArea',
  saveWorkPurposeType: '/dpt/workPurpose/saveWorkPurpose',
  saveBusinessDetailType: '/dpt/business/saveBusinessDetails',
  saveUnitOfWeightType: '/dpt/unitWeight/saveUnitWeight',
  saveGateLevelRejectReasonType: '/dpt/gateErrorMessage/saveErrorMessage',

  updatePersonRowData: '/dpt/personType/updatePersonTypeForMaster',
  updateBloodGroupRowData: '/dpt/bloodGroup/updateBloodGroupForMaster',
  updateVehicleRowData: '/dpt/vehicleType/updateVehicleTypeForMaster',
  updateVehicleCategoryRowData:
    '/dpt/vehicleCategory/updateVehicleCategoryForMaster',
  updateGenderRowData: '/dpt/gender/updateGenderForMaster',
  updateRolesRowData: '/dpt/role/updateRole',
  updateWorkAreaRowData: '/dpt/workArea/updateWorkArea',
  updateWorkPurposeRowData: '/dpt/workPurpose/updateWorkPurpose',
  updateBusinessDetailRowData: '/dpt/business/updateBusinessDetails',
  updateUnitOfWeightRowData: '/dpt/unitWeight/updateUnitWeight',
  updateGateLevelRejectReasonRowData:
    '/dpt/gateErrorMessage/updateErrorMessage',

  deleteRolesType: '/dpt/role/deleteRole',
  deleteWorkAreaType: '/dpt/workArea/deleteWorkArea',
  deleteWorkPurposeType: '/dpt/workPurpose/deleteWorkPurpose',
  deleteBusinessDetailType: '/dpt/business/deleteBusinessDetails',
  deletePersonType: '/dpt/personType/deletePersonType',
  deleteGenderType: '/dpt/gender/deleteGender',
  deleteBloodGroupType: '/dpt/bloodGroup/deleteBloodGroup',

  deleteVehicleType: '/dpt/vehicleType/deleteVehicleType',
  deleteVehicleCategoryType: '/dpt/vehicleCategory/deleteVehicleCategory',
  deleteUnitOfWeightType: '/dpt/unitWeight/deleteUnitWeight',
  deleteGateLevelRejectReasonType: '/dpt/gateErrorMessage/deleteErrorMessage',

  //user-master-admin
  fetchAllUsers: '/dpt/user/fetchUsers',
  updateUserInformationData: '/dpt/user/updateUserInformation',
  deleteUserByIdData: '/dpt/user/deleteUserById',
  saveUserForMasterAdmin: '/dpt/user/saveUserForMasterAdmin',

  fetchTransactionDetails: '/dpt/wallet/transactions',
  fetchUsageDetails: '/dpt/wallet/usage',

  fetchPersonByRFID: '/dpt/individualPermit/fetchPersonByRFID',
  fetchVehicleByRFID: '/dpt/vehiclePermit/fetchVehicleByRFID',

  fetchPersonMovementSummary: '/dpt/reports/person-movement',
  fetchRejectionReportEntrySummary: '/dpt/reports/vehicle-movement',
  fetchActiveCHADetails: '/dpt/company/fetchActiveCHADetails',
  fetchActiveCompanyDetailsByDepartment:
    '/dpt/company/fetchActiveCompanyDetailsByDepartment',

  fetchAllCompanyPermitsVehicleRegistrationNumbers:
    '/dpt/permitDetails/fetchAllCompanyPermitsVehicleRegistrationNumbers',

  personCardLost: '/dpt/personPass/lost',
  vehicleCardLost: '/dpt/vehiclePass/lost',

  fetchDailyMonitoring: '/dpt/reports/daily-monitoring',
  //pre sign url
  preSignedURL: '/dpt/user/preSignedUrlTest',

  changeRFIDPerson: '/dpt/personPass/replace',

  changeRFIDVehicle: '/dpt/vehiclePass/replace',
  delink: '/dpt/permitDetails/delink',

  saveDepartment: '/dpt/dept/saveDepartment',
  deleteDepartment: '/dpt/dept/deleteDepartment',
  updateDepartment: '/dpt/dept/updateDepartment',
  fetchAllDepartments: '/dpt/dept/fetchAllDepartments',

  saveCargoNature: '/dpt/nature/saveCargoNature',
  updateCargoNature: '/dpt/nature/updateCargoNature',
  deleteCargoNature: '/dpt/nature/deleteCargoNature',
  fetchAllCargoNature: '/dpt/nature/fetchAllCargoNature',

  saveMaterialType: '/dpt/material/saveMaterialType',
  updateMaterialType: '/dpt/material/updateMaterialType',
  deleteMaterialType: '/dpt/material/deleteMaterialType',
  fetchAllMaterialType: '/dpt/material/fetchAllMaterialTypes',

  savePassCategories: '/dpt/passCategories/save',
  updatePassCategories: '/dpt/passCategories/updatePassCategories',
  deletePassCategories: '/dpt/passCategories/deletePassCategories',
  fetchAllPassCategories: '/dpt/passCategories/fetchAllPassCategories',

  savePacksByPassCategory: '/dpt/wallet/savePacksByPassCategory',
  updatePacksByPassCategory: '/dpt/wallet/updatePacksByPassCategory',
  deletePacksByPassCategory: '/dpt/wallet/deletePacksByPassCategory',
  fetchAllPacksByPassCategory: '/dpt/wallet/fetchAllPacksByPassCategory',

  fetchAgencyManagementDetailsForAdmin:
    '/dpt/company/fetchAgencyManagementDetailsForAdmin',
  reconciliationById: '/dpt/wallet/reconciliation',
  reconciliationAll: '/dpt/wallet/reconciliation/all',

  // get all invoices data
  fetchAllInvoices: '/dpt/wallet/fetchAllInvoices',
  fetchActiveGates: '/dpt/gate/fetchAllGateDetails',

  // Server APIs
  serverTime: '/dpt/server/server-time',

  fetchDeputyDepartmentDashboard: '/dpt/dashboard/fetchNewDepartmentDashBoard',

  verifyOTPForPasswordChange: '/dpt/user/verifyOTPForPasswordChange',
  changeUserPassword: '/dpt/user/changeUserPassword',
  fetchTrafficDepartmentDashboardV2:
    '/dpt/dashboard/fetchNewTrafficDepartmentDashBoard',


  // Person dashboard API
  trafficDepartmentDashBoardForPedestrian:
    '/dpt/dashboard/TrafficDepatmentDashBoardForPedestrian',
  fetchTrafficDepartmentDashBoardForGraphs:
    '/dpt/dashboard/fetchTrafficDepartmentDashBoardForGraphs',

  fetchTrafficDepartmentDashBoardForTodayData:
    '/dpt/dashboard/fetchTrafficDepartmentDashBoardForTodayData',

  //Police NOC API
  savePoliceDoc: '/dpt/individualPermit/savePoliceNoc',
  verifyPersonByRFIDNumber: '/dpt/individualPermit/verifyPersonByRFIDNumber',
  fetchOperatorDashboard: '/dpt/dashboard/fetchOperatorDashBoard',

  // Person gate level activity
  personGateLevelHistory: '/dpt/reports/personGateLevelHistory',
  personGateLevelLatestActivity:
    '/dpt/reports/fetchPersonGateLevelLatestActivity',

  // Vehicle gate level activity
  vehicleGateLevelHistory: '/dpt/reports/vehicleGateLevelHistory',
  vehicleGateLevelLatestActivity:
    '/dpt/reports/fetchVehicleGateLevelLatestActivity',

  surrenderPersonPassSelf: '/dpt/surrenderPass/surrenderPersonPassSelf',
  surrenderVehiclePassSelf: '/dpt/surrenderPass/surrenderVehiclePassSelf',

  lostPersonCard: '/dpt/personPass/lostPersonCard',
  lostVehicleTag: '/dpt/vehiclePass/lostVehicleTag',

  blackListPersonCard: '/dpt/personPass/block',
  blackListVehicleTag: '/dpt/vehiclePass/block',

  unBlackListPersonCard: '/dpt/personPass/unBlackList',
  unBlackListVehicleTag: '/dpt/vehiclePass/unBlackList',

  fetchVehicleMovementDetails: '/dpt/reports/fetchVehicleMovementDetails',

  fetchPersonMovementDetails: '/dpt/reports/fetchPersonMovementDetails',


  // FETCH AGENCY DETAILS/BLACKLIST/UNBLACKLIST
  fetchAllCompaniesForDepartment: '/dpt/company/fetchAllCompaniesForDeparment',
  fetchCompanyDetailsForDepartment:
    '/dpt/company/fetchCompanyDetailsForDeparment',
  blackListAgency: '/dpt/company/addAgencyToBlackListed',
  unBlackListAgency: '/dpt/company/removeAgencyFromBlackListed',
  contact: '/dpt/sanya/ws/contactUs',

  getPaymentProperties: '/dpt/razorpay/getPaymentProperties'
};
