import React, {Component} from 'react';
import PopupContainer from '../../../../components/PopupContainer';
import Popup from '../../../../components/SimplePopup';
import {withRouter} from 'react-router-dom';

import ViewRenderer from './views';
import Spinner from '../../../../components/Spinner';
import moment from 'moment';
import {commonImages, operatorImages} from '../../../../assets/images';
import strings from '../../../../globalization';
import {API} from '../../../../requests';
import util from '../../../../util';

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  ERROR: 'error',
  SPINNER_POPUP: 'spinner',
};
const STATUS_MAPPER = {
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  PAYMENT_COMPLETED: 'PAYMENT_COMPLETED',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
};
class TransactionHistory extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      selectedStatus: this.props.status,
      searchText: '',
      startDate: new moment().startOf('year').format('MM-DD-YYYY'),
      endDate: new moment().add(1, 'years').format('MM-DD-YYYY'),
      dates: null,
      transactions: [],
      filteredTransactions: [],
      timestamp: new Date().getTime(),
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    // API Call here..
    const {startDate, endDate} = this.state;

    this.showSpinner();
    API.fetchTransactionDetails({startDate, endDate})
      .then(res => {
        let requiredData = res.data?.transactions.filter(
          item => item.status !== 'PAYMENT_PENDING',
        );
        console.log('requiredDta...', requiredData);
        this.setState(
          {
            transactions: requiredData,
            popup: null,
            timestamp: new Date().getTime(),
          },
          () => {
            this.applyFilters();
          },
        );
      })
      .catch(err => {
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };

  applyFilters = () => {
    const {searchText, transactions} = this.state;
    let filteredTransactions = transactions.filter(el =>
      el?.referenceId?.toLowerCase().includes(searchText.toLowerCase()),
    );

    console.log('filteredTransactions...', filteredTransactions);

    this.setState({
      transactions: filteredTransactions,
      timestamp: new Date().getTime(),
    });
  };

  onChangeHandler = (action, data) => {
    switch (action) {
      case 'search': {
        console.info('data..', data);
        this.setState({
          searchText: data,
        });

        break;
      }
      case 'date': {
        const {startDate, endDate, dates} = data;
        this.setState({
          startDate,
          endDate,
          dates,
        });
        break;
      }

      default: {
        console.info('case not handled', action);
      }
    }
  };

  /**
   *
   * @param {*} action
   * @param {*} data
   */

  onClickHandler = (action, data) => {
    const {history, location, status} = this.props;
    switch (action) {
      case 'statusRoute': {
        //FIXME
        history.push({
          pathname: '/u/wallet/' + data.route,
        });

        break;
      }
      case 'apply': {
        this.getData();
        break;
      }
      case 'clearFilters': {
        this.resetData();
        break;
      }
      case 'invoice': {
        console.log('invoice', 'data:', data);
        history.push({
          // pathname: '/u/wallet/transaction-history/invoice/' + data,
          pathname: '/u/wallet/transaction-history/invoice',
          state: {
            background: location,
            data: data,
          },
        });
        break;
      }
      default: {
        console.info('case not handled', action);
      }
    }
  };

  resetData = () => {
    const {status} = this.props;
    this.setState(
      {
        searchText: '',
        startDate: new moment().startOf('year').format('MM-DD-YYYY'),
        endDate: new moment().add(1, 'years').format('MM-DD-YYYY'),
        dates: null,
      },
      () => {
        this.getData();
      },
    );
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };

  getProps = () => {
    const {startDate, endDate, dates, searchText, transactions, timestamp} =
      this.state;
    return {
      onClick: this.onClickHandler,
      selectedStatus: this.state.selectedStatus,
      onChange: this.onChangeHandler,
      searchText,
      startDate,
      endDate,
      dates,
      transactions,
      timestamp,
    };
  };

  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      // case "spinner": {
      //   console.log('showing spinner');
      //   return <Spinner />;
      // }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.SPINNER: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };
  showSuccessPopup = (message, onClick) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: message,
        headingImage: operatorImages.successIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: onClick ? onClick : this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  closePopup = () => {
    this.setState({
      popup: null,
    });
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}
export default withRouter(TransactionHistory);
