/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import AuthLayout from '../../components/AuthLayout';
import styles from './styles.module.scss';

class Download extends React.Component<> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  resizeWindow = () => {
    if ((window.innerWidth < 700) & !this.state?.isMobile) {
      this.setState({isMobile: true});
    }
    window.addEventListener('resize', () => {
      const width = window.innerWidth;
      if (width < 700) {
        this.setState({isMobile: true});
      }

      if (width > 700) {
        this.setState({isMobile: false});
      }
    });
  };
  componentDidMount() {
    // this.getData();
    this.resizeWindow();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', null);
  }
  handleBackClick = () => {
    console.log('Find me');
    this.props.history.push('/signin');
  };

  render() {
    return (
      <div>
        {this.state?.isMobile ? (
          <div className={styles.formContainer}>
            <div className={styles.registrationlabel}>Downloads</div>
            <div className={styles.downloadContainer}>
              <p className={styles.rowLabel}>e-Drishti Demo App</p>
              <div className={styles.downloadButtonWrapper}>
                <a
                  type="button"
                  className={styles.downloadButton}
                  href="https://bit.ly/2YEqqj7"
                  target="__blank">
                  Download
                </a>
              </div>
            </div>
          </div>
        ) : (
          <AuthLayout>
            <div className={styles.formContainer}>
              <div className={styles.registrationlabel}>Downloads</div>
              <div className={styles.downloadContainer}>
                <p className={styles.rowLabel}>e-Drishti Demo App</p>
                <div className={styles.downloadButtonWrapper}>
                  <a
                    type="button"
                    className={styles.downloadButton}
                    href="https://bit.ly/2YEqqj7"
                    target="__blank">
                    Download
                  </a>
                </div>
              </div>
            </div>
          </AuthLayout>
        )}
      </div>
    );
  }
}

export default Download;
