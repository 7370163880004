import React, {Component} from 'react';
import PopupContainer from '../../../../components/PopupContainer';
import Popup from '../../../../components/SimplePopup';
import {withRouter} from 'react-router-dom';

import ViewRenderer from './views';
import Spinner from '../../../../components/Spinner';
import {connect} from 'react-redux';
import {setCartItem} from '../../../../store/actions/cart';
import util from '../../../../util';
import {commonImages} from '../../../../assets/images';
import strings from '../../../../globalization';
import {API} from '../../../../requests';
import styles from './styles.module.scss';

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  ERROR: 'error',
  SPINNER_POPUP: 'spinner',
};
class BuyCredits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStatus: this.props.status,
      searchText: '',
      name: this.props.location.state.data.name,
      id: this.props.location.state.data.id,
      packs: [],
      showAllPacks: false,
      showAllPacksText: 'View More Packs',
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.showSpinner();
    const {id} = this.state;
    API.getPacksByPassCategory(id).then(res => {
      console.log('res...getAllPasses', res.data);
      const modifiedPacks = res.data.map(elem => {
        let obj = {};
        obj.packId = elem.id;
        obj.title = elem.name;
        obj.price = elem.price;
        obj.validity = elem.packType;
        obj.status = elem.status;
        return obj;
      });
      this.setState({
        packs: modifiedPacks,
        popup: null,
      });
    });
  };

  showSuccessPopup = successMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: successMessage,
        onClose: this.closePopup,
        headingImage: commonImages.successIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  showErrorPopup = errorMsg => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMsg,
        messageStyle: {color: '#E67717'},

        buttons: [
          {
            text: strings.cancelPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
      },
    });
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  onChangeHandler = (action, data) => {
    switch (action) {
      case 'search': {
        console.info('data..', data);
        this.setState({
          searchText: data,
        });

        break;
      }

      default: {
        console.info('case not handled', action);
      }
    }
  };

  /**
   *
   * @param {*} action
   * @param {*} data
   */

  onClickHandler = (action, data) => {
    const {history, location, status} = this.props;
    const {dispatchSetCartItem} = this.props;

    switch (action) {
      case 'statusRoute': {
        //FIXME
        history.push({
          pathname: '/u/wallet/' + data.route,
        });

        break;
      }
      case 'cancel': {
        //FIXME
        history.goBack();

        break;
      }
      case 'show-all-packs': {
        this.setState({
          showAllPacks: !this.state.showAllPacks,
          showAllPacksText: this.state.showAllPacks
            ? 'View More Packs'
            : 'Show Less',
        });
        break;
      }
      case 'proceed': {
        const combinedCategoryAndPass = {
          ...data,
          categoryId: this.state.id,
        };

        if (!Number(combinedCategoryAndPass.quantity)) {
          this.showErrorPopup(`Quantity cannot be empty.`);
          return;
        }

        history.push({
          pathname: '/u/wallet/cart',
          state: {background: location, data: combinedCategoryAndPass},
        });

        break;
      }
      default: {
        console.info('case not handled', action);
      }
    }
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };

  getProps = () => {
    console.log('packs...', this.state.packs);
    const {
      showAllPacks,
      selectedStatus,
      searchText,
      name,
      packs,
      showAllPacksText,
    } = this.state;

    return {
      onClick: this.onClickHandler,
      selectedStatus: selectedStatus,
      onChange: this.onChangeHandler,
      searchText: searchText,
      name: name,
      packs: showAllPacks ? packs : packs.slice(0, 1),
      showAllPacksText: showAllPacksText,
    };
  };

  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      // case "spinner": {
      //   console.log('showing spinner');
      //   return <Spinner />;
      // }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.SPINNER: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer containerStyle={styles.popupContainerStyle}>
            {' '}
            {this.getPopupContent()}{' '}
          </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

const mapStateToProps = reduxProps => ({
  reduxProps,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetCartItem: payload => {
    dispatch(setCartItem(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BuyCredits),
);
