import React, {useEffect, useRef, useState} from 'react';
import Flex from '../../Flex';
import styles from './styles.module.scss';

import successIcon from '../../../assets/images/success/image.png';
import FormElement from '../../FormElement';
import CameraCapture from '../CameraCapture';
import strings from '../../../globalization';

function CardsActionsPopup({
  containerClass,
  title,
  subTitle,
  onBack,
  onConfirm,
  formData,
  stateData,
  handlePictureUpload,
  ...remainingProps
}) {
  console.log('formData', formData);
  console.log('stateData', stateData);

  const [remark, setRemark] = useState('');
  const [photoUrl, setPhotoUrl] = useState('');
  const [err, setErr] = useState('');

  const validate = () => {
    let error = '';
    // if (!photoUrl) {
    //   error = strings.pleaseTakeThePicture;
    // }
    if (!remark) {
      error = strings.remarksCantBeEmpty;
    }
    return error;
  };
  const handleConfirm = () => {
    let error = validate();
    if (error) {
      setErr(error);
    } else {
      onConfirm(remark);
    }
    // onConfirm(remark);
  };

  return (
    <div className={[styles.container, containerClass].join(' ')}>
      <Flex className={styles.header}>
        <h1>{title}</h1>
      </Flex>
      <Flex className={styles.formsContainer}>
        {formData.map((elem, idx) => (
          <FormElement
            type={elem.elementType}
            label={elem.label}
            required={elem.required}
            placeholderText={elem.placeholder}
            tabIndex={idx}
            options={stateData[elem.optionsField]}
            // value={stateData[elem.stateField].value}
            value={stateData[elem.id]}
            key={`firstColumn-${idx}`}
            maxLength={elem.maxLength}
            disabled={true}
            defaultValue={stateData[elem.id]}
            containerClassNames={styles.containerClassNames}
            labelClassNames={styles.labelClassNames}
          />
        ))}
      </Flex>

      <Flex direction="column" className={styles.childrenContainer}>
        <p className={styles.subheading}>
          {/* Are you sure you want to mark this card as surrendered? */}
          {subTitle}
        </p>
        <Flex direction="row" className={styles.imageTextareaContainer}>
          <textarea
            rows="5"
            className={styles.remark}
            value={remark}
            onChange={e => setRemark(e.target.value)}
            placeholder={strings.typeYourRemarks}></textarea>

          {/* <CameraCapture
            setPhotoUrl={setPhotoUrl}
            handlePictureUpload={handlePictureUpload}
          /> */}
        </Flex>
      </Flex>
      {/* <TemporaryComponent /> */}
      <Flex className={styles.errorContainer}>
        <p>{err}</p>
      </Flex>

      <Flex className={styles.buttonsContainer} {...remainingProps}>
        <button className={styles.okay} onClick={onBack}>
          {strings.back}
        </button>
        <button className={styles.okay} onClick={handleConfirm}>
          {strings.confirm}
        </button>
      </Flex>
    </div>
  );
}

export default CardsActionsPopup;
