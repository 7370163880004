import React from 'react';
import Flex from '../../../../../../components/Flex';
import PermitsHeader from '../../../../../../components/PermitsHeader';
import TextInput from '../../../../../../components/TextInput';
import styles from './styles.module.scss';
import exclamation from '../../../../../../assets/images/exclamation-icon/image.png';

import {AgGridReact} from 'ag-grid-react';
import dimensions from '../../.../../../../../../util/dimensions-util';

import './style.css';

import strings from '../../../../../../globalization';
import SannaLogo from '../../../../../../components/SannaLogo';
const {vw: widthConstant, vh: heightConstant} = dimensions;

const dummyData = [
  {
    id: 1,
    category: 'Man Permit single or with bi-cycle',
    availablePasses: 69,
    onHold: 0,
  },
  {
    id: 2,
    category: 'Man & Vehicle with - Two-wheeler (Motorized) ',
    availablePasses: 69,
    onHold: 0,
  },
  {
    id: 3,
    category: 'Man & Vehicle Personal Four-wheeler',
    availablePasses: 69,
    onHold: 0,
  },
  {id: 4, category: 'Man & Vehicle Permit', availablePasses: 69, onHold: 0},
];

const Desktop = ({
  onClick,
  selectedStatus,
  onChange,
  searchText,
  cart,
  passes,
  filteredPasses,
}) => {
  const rowStyle = {border: 'none', cursor: 'pointer'};
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return {background: '#f6f6f6', border: 'none'};
    }
  };

  const onTabClick = id => {
    switch (id) {
      case 1:
        onClick('statusRoute', {route: 'available-credits', id: id});
        break;
      case 2:
        onClick('statusRoute', {route: 'transaction-history', id: id});
        break;
      case 3:
        onClick('statusRoute', {route: 'usage-history', id: id});
        break;
      default:
        break;
    }
  };
  const tabs = [
    {
      label: 'Available Credits',
      id: 1,
      route: 'available-credits',
    },
    {
      label: 'Transaction History',
      id: 2,
      route: 'transaction-history',
    },
    {
      label: 'Usage History',
      id: 3,
      route: 'usage-history',
    },
  ];

  const columnDefs = [
    {
      headerName: 'Entry Category',
      field: 'entryCategory',
      headerClass: styles.rowHeader,
      width: 500 * widthConstant,
      resizable: true,
      cellStyle: {display: 'flex', alignItems: 'center'},
      cellRendererFramework: ({data: {name}}) => {
        return <div className={styles.sessionTime}>{name}</div>;
      },
    },
    {
      headerName: 'Available Trips',
      field: 'availablePasses',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      cellStyle: {display: 'flex', alignItems: 'center'},
      cellRendererFramework: ({data: {availablePasses}}) => {
        return <div className={styles.sessionTime}>{availablePasses}</div>;
      },
    },

    // {
    //   headerName: 'On Hold Trips',
    //   field: 'onHold',
    //   headerClass: styles.rowHeader,
    //   width: 180 * widthConstant,
    //   resizable: true,
    //   cellStyle: {display: 'flex', alignItems: 'center'},
    //   cellRendererFramework: ({data: {onHoldPasses}}) => {
    //     return <div className={styles.sessionTime}>{onHoldPasses}</div>;
    //   },
    // },
    // {
    //   headerName: 'Permit Approved Trips',
    //   field: 'vestedPasses',
    //   headerClass: styles.rowHeader,
    //   width: 180 * widthConstant,
    //   resizable: true,
    //   cellStyle: {display: 'flex', alignItems: 'center'},
    //   cellRendererFramework: ({data: {vestedPasses}}) => {
    //     return <div className={styles.sessionTime}>{vestedPasses}</div>;
    //   },
    // },

    {
      headerName: 'Action',
      field: 'action',
      headerClass: styles.rowHeader,
      width: 240 * widthConstant,
      styles: {flex: 1},
      resizable: true,
      cellStyle: {display: 'flex', alignItems: 'center'},
      cellRendererFramework: ({data}) => {
        return (
          <button onClick={() => handleClick(data)} className={styles.buy}>
            +Buy
          </button>
        );
      },
    },
  ];

  const handleClick = rowData => {
    console.log(rowData.id);
    onClick('buy-credits', rowData);
  };
  return (
    <div>
      <PermitsHeader
        title="Wallet"
        onTabClick={onTabClick}
        tabs={tabs}
        activeTab={selectedStatus === 'AVAILABLE_CREDITS' ? 1 : null}
      />
      <div className={styles.container}>
        <Flex direction="row" className={styles.header}>
          <Flex direction="row" className={styles.leftText}>
            <h1>Your Credits</h1>
            <Flex direction="row" className={styles.imagePara}>
              <img src={exclamation} className={styles.image} alt="U" />{' '}
              <a
                href="https://edrishti.sanya.ai/user-manual/wallet-usage.pdf"
                target="__blank">
                {' '}
                Learn more about how wallet works
              </a>
            </Flex>
          </Flex>
        </Flex>
        <Flex className={styles.box}>
          <span className={styles.totalCredits}>
            {filteredPasses[0]?.availablePasses}
          </span>{' '}
          credits available
        </Flex>
        <Flex>
          <button
            onClick={() => handleClick(filteredPasses[0])}
            className={styles.buyCredits}>
            Buy Credits
          </button>
        </Flex>
      </div>
      <div className={styles.SannaLogo}>
        <SannaLogo />
      </div>
    </div>
  );
};

export default Desktop;
