import React, {useState, useEffect} from 'react';
import styles from './Person.module.scss';
import moment from 'moment';
import {adminImages, loginImages} from '../../assets/images';
import documentIcon from '../../../src/assets/images/document-icon/image.png';
import strings from '../../globalization';
import Flex from '../Flex';
import FormElement from '../FormElement';
import rectangleIcon from '../../assets/images/profile/rectangle-icon/image1@2x.png';
import {API} from '../../requests';
/**
 *
 * @param {Boolean} error
 * @param {Boolean} success
 * @return {JSX.Element}
 */

/**
 *
 * @param {Props} props
 * @return {JSX.Element}
 */
function PersonVehicleDetails(props) {
  console.log('details', props);
  const {details, onClick} = props || {};
  const {name, photo, personType, status} = details || {};
  const formatDate = date => moment(date).format('MMM Do YYYY');

  return (
    <Flex direction="row" className={styles.mainContainer}>
      <Flex direction="column" className={styles.dataContainer}>
        <PersonCard onClick={onClick} details={details} />
      </Flex>
    </Flex>
  );
}

export default PersonVehicleDetails;

const DataItem = ({onClick, value, label, labelStyle, document}) => {
  return (
    <Flex className={styles.dataItemWrapper}>
      <div className={[styles.label, labelStyle].join(' ')}>{label} :</div>
      {document && value ? (
        <div className={styles.documentViewLink}>
          {/* <div>{value?.slice(-10)}</div> */}
          <img
            onClick={() => onClick('document-view', value)}
            className={styles.documentIcon}
            alt="."
            src={documentIcon}
          />
        </div>
      ) : (
        <div>
          {value ? value : <span className={styles.greyColor}> - </span>}
        </div>
      )}
    </Flex>
  );
};

const PersonCard = ({company, onClick, details}) => {
  console.log('details...', details);
  const formatDate = date => moment(date).format('MMMM Do YYYY, HH:mm');

  return (
    <Flex direction="column">
      <Flex>
        <div>
          <DataItem label={'Gate movement'} value={details?.vehicleMovement} />
          <DataItem label={'Gate with lane Id'} value={details?.readerName} />
          <DataItem label={'Purpose of work'} value={details?.puposeOfWork} />

          <DataItem
            label={'CHA name(If approved)'}
            value={details?.companyName}
          />
        </div>
        <div className={styles.marginLeft30}>
          <DataItem
            label={'Timestamp at Gate'}
            value={
              details?.createdDate ? formatDate(details?.createdDate) : '-'
            }
          />
          <DataItem label={'Reason for rejection'} value={details?.error} />
          <DataItem
            label={'Future permit raised'}
            value={details?.futurePermit ? 'Yes' : 'None'}
          />
        </div>
      </Flex>
    </Flex>
  );
};
