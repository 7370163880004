import React, {Component} from 'react';
import PopupContainer from '../../../components/PopupContainer';
import moment from 'moment';
import styles from './styles.module.scss';

import ViewRenderer from './views';
import AddNewLocation from '../../../components/operator/AddNewLocation';
import {API} from '../../../requests';
import util from '../../../util';

import Popup from '../../../components/SimplePopup';

// import { locations } from "./formData";
import {
  formLeftFields,
  formRightFields,
  shiftFormLeftFields,
  shiftFormMiddleFields,
  shiftFormRightFields,
} from './formFeilds';
import {commonImages, operatorImages} from '../../../assets/images';
import strings from '../../../globalization';
import {withRouter} from 'react-router-dom';

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  SPINNER: 'spinner',
  ERROR: 'error',
  SIMPLE_POPUP: 'simple-popup',
};
class Locations extends Component {
  constructor(props) {
    super(props);
    let preparedObject = {};
    [
      ...formLeftFields,
      ...formRightFields,
      ...shiftFormLeftFields,
      ...shiftFormMiddleFields,
      ...shiftFormRightFields,
    ].forEach(obj => {
      preparedObject[obj.stateField] = {value: obj.stateDefault, error: null};
      if (obj.optionsField) {
        preparedObject[obj.optionsField] = [];
      }
    });
    this.state = {
      locations: [],
      filterLocations: [],
      shiftRows: [],
      searchText: '',
      selectedLocation: null,
      ...preparedObject,
      statuses: [
        {
          id: 'Active',
          name: 'Active',
        },
        {
          id: 'Inactive',
          name: 'Inactive',
        },
      ],
      numberOfShiftsOptions: [
        {
          id: 2,
          name: 2,
        },
        {
          id: 3,
          name: 3,
        },
        {
          id: 4,
          name: 4,
        },
        {
          id: 5,
          name: 5,
        },
      ],
    };
  }

  getData = async () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });

    await API.fetchActiveLocation()
      .then(response => {
        // console.log("locations", response.data);
        this.setState({
          locations: response.data,
          filterLocations: response.data,
          popup: undefined,
        });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        console.info('error....', errorMsg);
        this.setState({
          popup: undefined,
        });
        // this.setState({
        //   popup: {
        //     type: POPUP_CONSTANTS.SIMPLE_POPUP,
        //     message: errorMsg,
        //     messageStyle: { color: "#E67717" },
        //     buttons: [
        //       {
        //         title: "Okay!",
        //         onPress: this.closePopup,
        //       },
        //     ],
        //   },
        // });
      });
  };

  componentDidMount() {
    this.getData();
  }
  componentDidUpdate() {
    console.log('updating component....');
  }

  onClickHandler = (action, data) => {
    // const { history } = this.props;
    console.info('filterChangeHandler...', action);
    switch (action) {
      case 'clearFilter': {
        console.info('action....', action, data);
        break;
      }
      case 'refresh': {
        console.info('action....', action, data);
        this.getData();
        break;
      }

      default: {
        console.info('case not handled', action);
      }
    }
  };
  submitFormData = async () => {
    const {
      location,
      locationId,
      locationName,
      maxOperatorsLimit,
      numberOfShifts,
      shiftRows,
    } = this.state;
    console.log('shiftRows - submit form data', shiftRows);

    let preparedData = {
      name: locationName.value,
      locationId: locationId.value,
      noOfShifts: numberOfShifts.value,
      limitOfOperators: maxOperatorsLimit.value,
    };

    let preparedArray = [];
    shiftRows.forEach(shift => {
      let preparedObj = {};
      preparedObj.name = shift.name.value;
      preparedObj.startTime = shift.startTime.value.format('hh:mm a');
      preparedObj.endTime = shift.endTime.value.format('hh:mm a');

      preparedArray.push(preparedObj);
    });
    preparedData.shift = preparedArray;

    console.log('preparedData', preparedData);

    // FIXME: Uncomment
    this.showSpinner();

    let res = await API.saveLocation(preparedData);
    return res;
  };
  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  updateFormData = async id => {
    const {
      location,
      locationId,
      locationName,
      maxOperatorsLimit,
      numberOfShifts,
      shiftRows,
    } = this.state;
    console.log('shiftRows - submit form data', shiftRows);

    let preparedData = {
      id,
      name: locationName.value,
      locationId: locationId.value,
      noOfShifts: numberOfShifts.value,
      limitOfOperators: maxOperatorsLimit.value,
    };

    let preparedArray = [];
    shiftRows.forEach(shift => {
      let preparedObj = {};
      preparedObj.name = shift.name.value;
      preparedObj.startTime = shift.startTime.value.format('hh:mm a');
      preparedObj.endTime = shift.endTime.value.format('hh:mm a');

      preparedArray.push(preparedObj);
    });
    preparedData.shift = preparedArray;

    console.log('preparedData', preparedData);

    // FIXME: Uncomment
    this.showSpinner();

    let res = await API.updateLocation(preparedData);
    return res;
  };
  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  showSuccessPopup = (message, onClick) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: message,
        headingImage: operatorImages.successIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: onClick ? onClick : this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  handleErrors = formData => {
    let err = false;
    const update = {};
    console.log('formData', formData);

    for (let i = 0; i < formData.length; i++) {
      let element = formData[i];
      const error = util.validate(
        this.state[element.stateField].value,
        element,
      );
      if (error) {
        err = true;
        update[element.stateField] = {
          ...this.state[element.stateField],
          error: error,
        };
      }
    }
    this.setState({
      ...update,
    });
    return err;
  };
  onClick = (action, event) => {
    let formData = [...formLeftFields, ...formRightFields];
    const err = this.handleErrors(formData);
    if (!err) {
      this.submitFormData();
    }
  };
  filterChangeHandler = (action, data) => {
    const {history, location} = this.props;
    switch (action) {
      case 'status': {
        console.log('action', action, 'data', data);
        const {locations} = this.state;
        const filterLocations = locations.filter(
          location => location.status === data.toUpperCase(),
        );
        this.setState({
          filterLocations,
          selectedLocation: data,
        });
        break;
      }
      case 'search': {
        console.log('data', data);
        const {locations} = this.state;
        const filterLocations = locations.filter(location =>
          location.name.toLowerCase().includes(data.toLowerCase()),
        );
        this.setState({
          filterLocations,
        });
        break;
      }
      case 'createNew': {
        console.log('history...', history, location);
        history.push({
          pathname: location.pathname + '/add-new-location',
          state: {background: location},
        });
        break;
      }
      case 'clearFilter': {
        const {locations} = this.state;
        this.setState({
          searchText: '',
          // selectedStatus: "",
          filterLocations: locations,
          selectedLocation: null,
        });
        break;
      }

      default: {
        console.info('case not handled', action);
      }
    }
  };

  handleShiftRowsErrors = () => {
    const {shiftRows} = this.state;
    let tempShiftRows = [...shiftRows];
    let error = false;
    tempShiftRows.forEach(shiftRow => {
      if (!shiftRow.name.value) {
        shiftRow.name.error = 'Name cannot be empty';
        error = true;
      }
      if (!shiftRow.startTime.value) {
        shiftRow.startTime.error = 'startTime cannot be empty';
        error = true;
      }
      if (!shiftRow.endTime.value) {
        shiftRow.endTime.error = 'endTime cannot be empty';
        error = true;
      }
    });

    console.log('tempShiftRows', tempShiftRows);
    this.setState({
      shiftRows: tempShiftRows,
    });
    return error;
  };

  handleAdd = () => {
    console.log('add clicked');
    let formData = [...formLeftFields, ...formRightFields];
    const err = this.handleErrors(formData);
    const shiftError = this.handleShiftRowsErrors();
    if (!err && !shiftError) {
      this.submitFormData()
        .then(res => {
          console.log(res);
          // Call the API again to fetch latest list
          // this.getData();
          this.showSuccessPopup('Locations added successfully!', this.getData);
        })
        .catch(error => {
          const errorMsg = util.getErrorMessage(error);
          this.showErrorPopup(errorMsg);
          console.log(error);
        });
    }
  };

  fetchShiftsByLocationId = id => {
    API.fetchShift(id)
      .then(res => {
        let finalShiftsArray = [];

        for (let i = 0; i < res.data.length; i++) {
          let preparedObj = {};
          let element = res.data[i];
          preparedObj.id = i;
          preparedObj.name = {value: element.name, error: null};
          preparedObj.startTime = {
            value: moment(element.startTime, 'h:mm A'),
            error: null,
          };
          preparedObj.endTime = {
            value: moment(element.endTime, 'h:mm A'),
            error: null,
          };

          finalShiftsArray.push(preparedObj);
        }

        this.setState({
          shiftRows: finalShiftsArray,
        });
        // this.setState({
        //   shiftRows: res.data,
        // });
      })
      .catch(error => {
        console.log('error', error);
        const errorMsg = util.getErrorMessage(error);
        this.showErrorPopup(errorMsg);
      });
  };

  handleUpdate = id => {
    console.log('update clicked');
    let formData = [...formLeftFields, ...formRightFields];
    const err = this.handleErrors(formData);
    const shiftError = this.handleShiftRowsErrors();
    if (!err && !shiftError) {
      this.updateFormData(id)
        .then(res => {
          console.log(res);
          // Call the API again to fetch latest list
          // this.getData();
          this.showSuccessPopup(
            'Locations Updated successfully!',
            this.getData,
          );
        })
        .catch(error => {
          const errorMsg = util.getErrorMessage(error);
          this.showErrorPopup(errorMsg);
          console.log(error);
        });
    }
  };

  onEdit = id => {
    const {history, location} = this.props;
    console.log('history...', history, location);
    history.push({
      pathname: location.pathname + '/add-new-location/' + id,
      state: {background: location},
    });
  };

  onDelete = id => {
    console.log('onDelete called ONLY');
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: 'Are you sure you want to delete this location?',
        onClose: this.closePopup,
        buttons: [
          {
            text: 'Yes',
            onClick: () => this.handleDelete(id),
            outline: true,
          },
          {
            text: 'No',
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  handleDelete = id => {
    API.deleteLocation(id)
      .then(res => {
        this.showSuccessPopup('Location deleted Successfully', this.getData);
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        this.showErrorPopup(errorMsg);
      });
  };

  getProps = () => {
    const {statuses, filterLocations, selectedLocation} = this.state;
    // console.log("filterLocations", filterLocations);
    return {
      onClick: this.onClickHandler,
      onChange: this.onChangeHandler,
      searchText: this.state.searchText.value,
      filterChangeHandler: this.filterChangeHandler,
      statuses,
      selectedLocation,
      locations: filterLocations,
      onEdit: this.onEdit,
      onDelete: this.onDelete,
    };
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };

  onChange = (action, data) => {
    this.setState(prevState => {
      return {
        ...prevState,
        [action]: data,
      };
    });
  };
  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  generateShiftRows = value => {
    let finalShiftsArray = [];

    for (let i = 0; i < value; i++) {
      let preparedObj = {};
      preparedObj.id = i;
      preparedObj.name = {value: '', error: null};
      preparedObj.startTime = {value: '', error: null};
      preparedObj.endTime = {value: '', error: null};

      finalShiftsArray.push(preparedObj);
    }

    this.setState({
      shiftRows: finalShiftsArray,
    });
  };

  onShiftChangeHandler = (field, element, value) => {
    const {shiftRows: sr} = this.state;
    console.log('shift field', field);

    this.setState({
      shiftRows: sr.map((elem, idx) => {
        if (elem.id === element.id) {
          return {
            ...elem,
            [field]: {
              ...elem[field],
              value,
              error: '',
            },
          };
        }
        return elem;
      }),
    });
  };
  onChangeHandler = (field, value) => {
    if (field === 'searchText') {
      this.filterChangeHandler('search', value);
    }

    if (field === 'numberOfShifts') {
      this.generateShiftRows(value);
    }
    this.setState(prevState => {
      const currentObj = prevState[field];
      return {
        ...prevState,
        [field]: {
          ...currentObj,
          value,
          error: null,
        },
      };
    });
  };
  onBlur = (field, element) => {
    console.info('onBlur..', field, element);
    this.setState(prevState => {
      const currentObj = prevState[field];
      return {
        ...prevState,
        [field]: {
          ...currentObj,
          error: element ? util.validate(currentObj.value, element) : null,
        },
      };
    });
  };
  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.STANDARD: {
        return (
          <AddNewLocation
            onChangeHandler={this.onChangeHandler}
            onBlur={this.onBlur}
            stateData={this.state}
            containerClassNames={styles.containerClassNames}
            shiftRows={this.state.shiftRows}
            onShiftChange={this.onShiftChangeHandler}
            {...popup}
          />
        );
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

export default withRouter(Locations);
