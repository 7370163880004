import styles from './styles.module.scss';
import {AgGridReact} from 'ag-grid-react';

import dimensions from '../../../../../util/dimensions-util';

import 'react-datasheet/lib/react-datasheet.css';
import PermitsHeader from '../../../../../components/PermitsHeader';
import 'react-daterange-picker/dist/css/react-calendar.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import React, {useState} from 'react';
import TextInput from '../../../../../components/TextInput';

import {withRouter} from 'react-router-dom';

const {vw: widthConstant, vh: heightConstant} = dimensions;

function Desktop({
  onClick,
  walletBalancesReport,
  tableActionHandler,
  selectedStatus,
  searchText,
  statuses,
}) {
  const rowStyle = {border: 'none', cursor: 'pointer'};
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return {background: '#f6f6f6', border: 'none'};
    }
  };

  const onTabClick = () => {};

  const columnDefs = [
    {
      headerName: 'Agency Name',
      field: 'agencyName',
      headerClass: styles.rowHeader,
      flex: 0.7,
      resizable: true,
    },

    {
      headerName: 'Pass Category',
      field: 'passCategory',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
    },
    {
      headerName: 'Available Balance',
      field: 'availablePass',
      headerClass: styles.rowHeader,
      flex: 0.5,
      resizable: true,
    },

    {
      headerName: 'On Hold Balance',
      field: 'onHoldPass',
      headerClass: styles.rowHeader,
      flex: 0.5,
      resizable: true,
    },
  ];

  return (
    <div>
      <PermitsHeader
        title="Wallet Balances Report"
        onTabClick={onTabClick}
        showTabs={false}
      />
      <div className={styles.agencyContainer}>
        <div className={styles.filterWrapper}>
          <div className={styles.left}>
            <div className={styles.inputSearch}>
              <TextInput
                onChange={update => {
                  tableActionHandler('search', update);
                }}
                isEdit={true}
                type={'text'}
                value={searchText}
                inputStyle={styles.inputText}
                showSeacrh={true}
                placeholder="Search"
              />
            </div>
            <div
              onClick={() => tableActionHandler('clearFilter')}
              className={styles.clearLabel}>
              Clear
            </div>
          </div>
        </div>
        <div className={['ag-theme-balham', styles.tableWrapper].join(' ')}>
          <AgGridReact
            columnDefs={columnDefs}
            defaultColDef={{sortable: true}}
            rowClass={[styles.gridRowClass]}
            rowData={walletBalancesReport}
            rowStyle={rowStyle}
            getRowStyle={getRowStyle}
            rowHoverHighlight={true}
            pagination={true}
            paginationPageSize={10}
            rowHeight={50 * heightConstant}
            headerHeight={50 * heightConstant}
            domLayout="autoHeight"></AgGridReact>
        </div>
        <div className={styles.bottomLabel}>
          {' '}
          Showing {walletBalancesReport?.length}
        </div>
      </div>
    </div>
  );
}
export default withRouter(Desktop);
