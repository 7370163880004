import React from 'react';
import styles from './styles.module.scss';
import Flex from '../Flex';
import {adminImages} from '../../assets/images';

type Props = {
  messageObject: Array<Object>,
  onClose?: () => void,
  buttons?: Button[],
  heading?: String,
};

function Privacy(props: Props) {
  const {
    messageObject,
    heading,
    onClose,
    onButtonClick,
    text,
    buttonText = 'I Agree',
  } = props;

  console.log('buttonText...', buttonText);

  return (
    <div className={[styles.container].join(' ')}>
      {onClose ? (
        <img
          alt="close"
          src={adminImages.closeIcon}
          className={styles.close}
          onClick={onClose}
        />
      ) : null}
      <div className={styles.heading}> {heading}</div>

      <div className={styles.bodyContainer}>
        <div>
          {messageObject.map((message, idx) => {
            const {title, content} = message;
            return (
              <div key={`privacy-content-${idx}`}>
                <div className={styles.title}>{title}</div>
                <div className={styles.content}>{content}</div>
              </div>
            );
          })}
        </div>
        <Flex className={styles.buttonContainer}>
          <button
            className={styles.button}
            onClick={() => {
              onButtonClick ? onButtonClick() : onClose();
            }}
            key={'popup-button-okay'}>
            {buttonText}
          </button>
        </Flex>
      </div>
    </div>
  );
}

export default Privacy;
