import React, {useState} from 'react';
import Select from 'react-select';
import Flex from '../Flex';
import styles from './styles.module.scss';

export const CustomDropdown = ({
  required,
  label,
  options,
  labelStyles,
  containerStyles,
  placeholderText,
  onChange,
  value,
  disabled,
  error,
  tabIndex,
  selectBoxClassName,
  dropdownStyle,
  boxStyles,
  containerClassNames,
  height,
  labelClassNames,
  noOptionsText,
  ...remainingProps
}) => {
  const [selected, setSelected] = useState('');

  const onValueChange = valueTemp => {
    setSelected(valueTemp.value);
    onChange(valueTemp.value);
  };
  let getVal = value
    ? (options || [])
        .map(option => ({
          value: option.id,
          label: option.name,
        }))
        .find(o => o.value === value)
    : null;

  return (
    <Flex direction="column" className={containerStyles}>
      <Flex>
        <p className={[styles.label, labelClassNames].join(' ')}>{label}</p>
        {required ? <p style={{color: 'red'}}>*</p> : null}
      </Flex>
      <div>
        <Select
          isDisabled={disabled}
          defaultValue={
            value
              ? (options || [])
                  .map(option => ({
                    value: option.id,
                    label: option.name,
                  }))
                  .find(o => o.value === value)
              : null
          }
          noOptionsMessage={({inputValue}) =>
            !inputValue ? noOptionsText : 'No results found'
          }
          value={getVal}
          className={[selectBoxClassName, containerClassNames].join(' ')}
          tabIndex={tabIndex}
          styles={{
            singleValue: (style, {isDisabled}) => {
              return {
                ...style,
                color: isDisabled ? '#00000' : '#000000',
              };
            },
            control: (style, {isDisabled}) => {
              return {
                ...style,
                height: !containerClassNames ? '50px' : '',
                fontFamily: 'Poppins-Regular',
                backgroundColor: isDisabled ? '#FAFAFA' : '#FFFFFF',
                borderColor: '#b3bac2',
                fontSize: '0.8rem',
                color: isDisabled ? '#000000' : '#000000',
                ...dropdownStyle,
              };
            },
            placeholder: defaultStyles => {
              return {
                ...defaultStyles,
                color: '#D3D3D4',
              };
            },
          }}
          placeholder={placeholderText}
          options={(options || []).map(option => ({
            value: option.id,
            label: option.name,
          }))}
          onChange={value => onValueChange(value)}
        />
      </div>
      <Flex style={{width: '100%', height: '1rem'}}>
        <p className={styles.error}>{error}</p>
      </Flex>
    </Flex>
  );
};

export default CustomDropdown;
