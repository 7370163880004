export const getFormData = () => {
  return {
    data: [
      {
        elementType: 'file',
        inputType: 'upload',
        label: 'Police NOC Certificate',
        placeholder: 'Upload Police NOC Certificate',
        required: true,
        stateField: 'policeNOCCertificate',
        // FIXME
        stateDefault: [],
        allowedFormats: ['pdf', 'images'],
        maxFiles: 1,
        dependentOnOtherFields: false,
      },
      {
        elementType: 'date',
        keyboardType: 'default',
        inputType: 'date',
        label: 'Police NOC Validity',
        placeholder: 'Police NOC Validity',
        required: true,
        disable: false,
        stateField: 'policeNOCValidity',
        stateDefault: null,
        maxLength: 20,
        minimumDate: new Date(),
        dependentOnOtherFields: false,
      },
    ],
  };
};
