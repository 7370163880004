/* eslint-disable require-jsdoc */
/**
 * @flow
 */

import React from 'react';
import styles from './TextInput.module.scss';
import {adminImages} from '../../assets/images';
import Flex from '../Flex';
function TextInput(props) {
  const {
    label,
    value = '',
    onChange,
    lblDirection,
    isPassword = false,
    isEdit,
    inputStyle,
    error,
    placeholder,
    type,
    name,
    required,
    onBlurr,
    icon,
    showSeacrh,
    fileNames,
    rightIcon,
    onRightIconClick,
    iconStyles,
  } = props;
  if (type === 'file') {
    return (
      <div
        className={
          lblDirection === 'row'
            ? styles.textInputWrapperRow
            : styles.textInputWrapperColumn
        }>
        <h4
          className={[
            styles.inputTextLabel,
            lblDirection === 'row' ? {} : styles.inputTextLabelColumn,
          ].join(' ')}>
          <span>
            {label} {required && <span style={{color: 'orange'}}>*</span>}
          </span>
        </h4>
        <label htmlFor="file" style={{display: 'block'}}>
          <div className={styles.customInput} htmlFor="file">
            {fileNames === '' && (
              <span className={styles.placeholder}> {placeholder}</span>
            )}
            <input
              readOnly={!isEdit || label === 'Email'}
              data-date-inline-picker="true"
              type={type}
              defaultValue={value}
              placeholder={placeholder}
              id="file"
              //className={[error ? styles.error : {}, styles.inputTextFile, inputStyle].join(" ")}
              name={name}
              required={required}
              multiple="multiple"
              onBlur={onBlurr}
              onChange={event => {
                if (type === 'file') {
                  onChange(event.target.files);
                }
                if (type !== 'file') {
                  onChange(event.target.value);
                }
              }}
            />
            {/* // FIXME: */}
            <div className={styles.fileName}>
              {fileNames && fileNames.length > 0 && fileNames[0]?.name}
            </div>
            <img src="./static/images/Icons/Attachment.png" />
          </div>
        </label>
        {error ? <span style={{color: 'red'}}>{error}</span> : null}
      </div>
    );
  }

  return (
    <div
      className={
        lblDirection === 'row'
          ? styles.textInputWrapperRow
          : styles.textInputWrapperColumn
      }>
      <h4
        className={[
          styles.inputTextLabel,
          lblDirection === 'row' ? {} : styles.inputTextLabelColumn,
        ].join(' ')}>
        <span>
          {label} {required && <span style={{color: 'orange'}}>*</span>}
        </span>
      </h4>
      <input
        readOnly={!isEdit || label === 'Email'}
        data-date-inline-picker="true"
        type={type}
        defaultValue={value}
        value={value}
        placeholder={placeholder}
        className={[
          error ? styles.error : {},
          styles.inputText,
          inputStyle,
        ].join(' ')}
        name={name}
        required={required}
        multiple="multiple"
        onBlur={onBlurr}
        onChange={event => {
          if (type === 'file') {
            onChange(event.target.files);
          }
          if (type !== 'file') {
            onChange(event.target.value);
          }
        }}
      />
      {showSeacrh ? (
        <img
          src={adminImages.searchIcon}
          className={styles.dashboardIcon}
          alt="logo"
        />
      ) : null}
      {rightIcon && (
        <img
          alt="U"
          src={rightIcon}
          className={styles.rightIcon}
          style={iconStyles}
          onClick={onRightIconClick}
        />
      )}
      {error ? <div style={{color: 'red'}}>{error}</div> : null}
    </div>
  );
}

export default TextInput;
