export const GSTStateCodes = [
  {
    name: 'Andaman and Nicobar Islands',
    code: '35',
    AlphaCode: 'AN',
  },
  {
    name: 'Andhra Pradesh',
    code: '28',
    AlphaCode: 'AP',
  },
  {
    name: 'Andhra Pradesh (new)',
    code: '37',
    AlphaCode: 'AD',
  },
  {
    name: 'Arunachal Pradesh ',
    code: '12',
    AlphaCode: 'AR',
  },
  {
    name: 'Assam',
    code: '18',
    AlphaCode: 'AS',
  },
  {
    name: 'Bihar',
    code: '10',
    AlphaCode: 'BH',
  },
  {
    name: 'Chandigarh',
    code: '04',
    AlphaCode: 'CH',
  },
  {
    name: 'Chattisgarh',
    code: '22',
    AlphaCode: 'CT',
  },
  {
    name: 'Dadra and Nagar Haveli',
    code: '26',
    AlphaCode: 'DN',
  },
  {
    name: 'Daman and Diu ',
    code: '25',
    AlphaCode: 'DD',
  },
  {
    name: 'Delhi',
    code: '07',
    AlphaCode: 'DL',
  },
  {
    name: 'Goa',
    code: '30',
    AlphaCode: 'GA',
  },
  {
    name: 'Gujarat',
    code: '24',
    AlphaCode: 'GJ',
  },
  {
    name: 'Haryana',
    code: '06',
    AlphaCode: 'HR',
  },
  {
    name: 'Himachal Pradesh',
    code: '02',
    AlphaCode: 'HP',
  },
  {
    name: 'Jammu and Kashmir',
    code: '01',
    AlphaCode: 'JK',
  },
  {
    name: 'Jharkhand',
    code: '20',
    AlphaCode: 'JH',
  },
  {
    name: 'Karnataka',
    code: '29',
    AlphaCode: 'KA',
  },
  {
    name: 'Kerala',
    code: '32',
    AlphaCode: 'KL',
  },
  {
    name: 'Ladakh',
    code: '38',
    AlphaCode: 'LA',
  },
  {
    name: 'Lakshadweep Islands',
    code: '31',
    AlphaCode: 'LD',
  },
  {
    name: 'Madhya Pradesh',
    code: '23',
    AlphaCode: 'MP',
  },
  {
    name: 'Maharashtra',
    code: '27',
    AlphaCode: 'MH',
  },
  {
    name: 'Manipur',
    code: '14',
    AlphaCode: 'MN',
  },
  {
    name: 'Meghalaya',
    code: '17',
    AlphaCode: 'ME',
  },
  {
    name: 'Mizoram',
    code: '15',
    AlphaCode: 'MI',
  },
  {
    name: 'Nagaland',
    code: '13',
    AlphaCode: 'NL',
  },
  {
    name: 'Odisha',
    code: '21',
    AlphaCode: 'OR',
  },
  {
    name: 'Pondicherry',
    code: '34',
    AlphaCode: 'PY',
  },
  {
    name: 'Punjab',
    code: '03',
    AlphaCode: 'PB',
  },
  {
    name: 'Rajasthan',
    code: '08',
    AlphaCode: 'RJ',
  },
  {
    name: 'Sikkim',
    code: '11',
    AlphaCode: 'SK',
  },
  {
    name: 'Tamil Nadu',
    code: '33',
    AlphaCode: 'TN',
  },
  {
    name: 'Telangana',
    code: '36',
    AlphaCode: 'TL',
  },
  {
    name: 'Tripura',
    code: '16',
    AlphaCode: 'TR',
  },
  {
    name: 'Uttar Pradesh',
    code: '09',
    AlphaCode: 'UP',
  },
  {
    name: 'Uttarakhand',
    code: '05',
    AlphaCode: 'UT',
  },
  {
    name: 'West Bengal',
    code: '19',
    AlphaCode: 'WB',
  },
];
