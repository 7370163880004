import React from 'react';
import styles from './styles.module.scss';

import contactBgImage from '../../../assets/images/Landing/contact/image@3x.png';
import likeImage from '../../../assets/images/contact/image.png';

const PAGE_STATUS = {
  FORM: 'form',
  SUCCESS: 'success',
  ERROR: 'error',
};
export const Contact = ({formData, onChange, onSubmit, pageStatus}) => {
  const getPageContent = () => {
    switch (pageStatus) {
      case PAGE_STATUS.FORM: {
        return (
          <>
            <div className={styles.formRow}>
              <div className={styles.formContainer}>
                <input
                  type="text"
                  value={formData.fullname.value}
                  onChange={e => onChange('fullname', e.target.value)}
                  className={styles.formInput}
                />
                {!formData.fullname.value && (
                  <div className={styles.customPlaceholder}>
                    <span>{`Full name`}</span>
                    <span
                      className="asterisk"
                      style={{
                        color: '#DF8E4F',
                      }}>
                      {' '}
                      *
                    </span>
                  </div>
                )}
                <p className={styles.formError}>{formData.fullname.error}</p>
              </div>
              <div className={styles.formContainer}>
                <input
                  type="email"
                  value={formData.email.value}
                  onChange={e => onChange('email', e.target.value)}
                  className={styles.formInput}
                />
                {!formData.email.value && (
                  <div className={styles.customPlaceholder}>
                    <span>{`Email`}</span>
                    <span
                      className="asterisk"
                      style={{
                        color: '#DF8E4F',
                      }}>
                      {' '}
                      *
                    </span>
                  </div>
                )}
                <p className={styles.formError}>{formData.email.error}</p>
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.formContainer}>
                <input
                  type="text"
                  value={formData.company.value}
                  onChange={e => onChange('company', e.target.value)}
                  className={styles.formInput}
                />
                {!formData.company.value && (
                  <div className={styles.customPlaceholder}>
                    <span>{`Company`}</span>
                    <span
                      className="asterisk"
                      style={{
                        color: '#DF8E4F',
                      }}>
                      {' '}
                      *
                    </span>
                  </div>
                )}
                <p className={styles.formError}>{formData.company.error}</p>
              </div>
              <div className={styles.formContainer}>
                <input
                  type="text"
                  placeholder="Contact No. (Optional)"
                  value={formData.phone.value}
                  onChange={e => onChange('phone', e.target.value)}
                  className={styles.formInput}
                />
                <p className={styles.formError}>{formData.phone.error}</p>
              </div>
            </div>
            <div className={styles.formContainer}>
              <textarea
                rows={5}
                value={formData.comments.value}
                onChange={e => onChange('comments', e.target.value)}
                className={styles.formInput}
              />
              {!formData.comments.value && (
                <div className={styles.customPlaceholder}>
                  <span>{`Comments`}</span>
                  <span
                    className="asterisk"
                    style={{
                      color: '#DF8E4F',
                    }}>
                    {' '}
                    *
                  </span>
                </div>
              )}
            </div>
            <p className={styles.formError}>{formData.comments.error}</p>
            <button className={styles.heroCTA} onClick={onSubmit}>
              Send
            </button>
          </>
        );
      }
      case PAGE_STATUS.SUCCESS: {
        return (
          <div className={styles.success}>
            <img src={likeImage} alt="like" className={styles.successIcon} />
            <p className={styles.headerContact}>Thank You</p>
            <p className={styles.subheadingContact}>
              We will get back to you soon
            </p>
          </div>
        );
      }
      default: {
        return null;
      }
    }
  };
  return (
    <div id="contact" className={styles.contactContainer}>
      <img
        src={contactBgImage}
        className={styles.contactImage}
        alt="contact form"
      />
      <div className={styles.overlay} />
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <h2 className={styles.header}>Contact Us</h2>
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.contactFormContainer}>{getPageContent()}</div>
        </div>
      </div>
    </div>
  );
};
