const hindi = {
  // LOGIN
  title: 'Deendayal Port Trust',
  subTitle: 'e-Drishti',
  login: 'लॉग इन',
  createNew: 'नया बनाओ',
  rememberMe: 'याद रखें',
  forgotPassword: 'पासवर्ड भूल गए',
  dontHaveAgentAccount: 'एजेंट खाता नहीं है?',
  vehicleRegistration: 'वाहन पंजीकरण',
  personRegistration: 'व्यक्ति पंजीकरण',
  requestFreePermit: 'निःशुल्क परमिट के लिए अनुरोध करें',
  requestForVisitorPermit: 'आगंतुक परमिट के लिए अनुरोध करें',
  checkApplicationStatus: 'आवेदन की स्थिति की जांच करें',
  manageRegistrationInfo: 'पंजीकरण जानकारी प्रबंधित करें',
  checkPermitStatus: 'परमिट की स्थिति जांचें',
  verifyMobNUmber: 'अपना मोबाइल नंबर सत्यापित करें',
  personName: 'नाम',
  password: 'पासवर्ड',
  enterName: 'नाम दर्ज करें',
  enterPassword: 'पासवर्ड दर्ज करें',
  userId: 'यूजर आईडी',
  enterUserId: 'यूजर आईडी दर्ज करें',
  sendOtp: 'ओटीपी  भेजें',
  privacyPolicy: 'गोपनीयता नीति',
  termsOfUse: 'उपयोग की शर्तें',
  helpCenter: 'सहायता केंद्र',
  useFulResource: 'उपयोगी संसाधन',

  copyRight: '© कॉपीराइट 2024. सना। सर्वाधिकार सुरक्षित।',
  or: 'या',
  verifyOTP: 'OTP Verify',

  //FORGOT PASSWORD
  enterOTP: 'Enter OTP',

  // NEW AGENCY REGISTRATION
  newAgencyRegistration: 'नया एजेंसी पंजीकरण',
  authorizedUsers: 'अधिकृत उपयोगकर्ता',
  agencyDetails: 'एजेंसी का विवरण',
  step2: 'चरण 2/2',
  step1: 'चरण 1/2',
  signatory1: 'हस्ताक्षरकर्ता 1',
  signatory2: 'हस्ताक्षरकर्ता 2',
  signatory3: 'हस्ताक्षरकर्ता 3',
  next: 'अगला',
  selectDepartment: 'विभाग का चयन करें',
  department: 'विभाग',
  agencyPhoneNumber: 'एजेंसी का फोन नंबर',
  enterPhoneNumber: 'फोन नंबर दर्ज करें',
  licenseNo: 'अनुज्ञापत्र क्रमांक',
  selectLicenseNumber: 'लाइसेंस नंबर चुनें',
  natureOfBusiness: 'व्यवसाय की प्रकृति',
  selectNatureOfBussiness: 'व्यवसाय की प्रकृति',
  address2: 'पता 2',
  enterAddress: 'पता',
  companyRegistrationProof: 'कंपनी पंजीकरण प्रमाण',
  chooseFile: 'फाइल चुनें',
  agencyName: 'एजेंसी का नाम',
  enterAgencyName: 'एजेंसी का नाम',
  agencyEmailAddress: 'ईमेल का पता',
  enterEmailAddress: 'ईमेल का पता दर्ज करें',
  licenseValidUpto: 'लाइसेंस वैधता',
  selectDate: 'तारीख़ चुनें',
  address1: 'पता 1',
  gstNumber: 'जीएसटी संख्या',
  enterGSTNumber: 'जीएसटी नंबर दर्ज करें',

  // VEHICLE REGISTRATION
  vehicleOwnerName: 'मालिक का नाम',
  enterVehicleOwnerName: 'वाहन के मालिक का नाम दर्ज करें',
  ownerPhoneNumber: 'मालिक का फोन नंबर',
  enterVehicleOwnerPhoneNumber: 'मालिक का फोन नंबर',
  vehicleRCNumber: 'वाहन पंजीकरण संख्या',
  enterRCNumber: 'वाहन का पंजीकरण संख्या दर्ज करें',
  vehicleInsuranceNumber: 'वाहन की बीमा संख्या',
  enterInsuranceNumber: 'बीमा की संख्या दर्ज करें',
  vehiclePollutionNumber: 'वाहन की प्रदूषण संख्या',
  enterPollutionNumber: 'प्रदूषण संख्या दर्ज करें',
  vehicleType: 'वाहन का प्रकार',
  enterVehicleType: 'वाहन का प्रकार दर्ज करें',
  vehicleEngineNumber: 'वाहन का इंजन नंबर',
  enterEngineNumber: 'इंजन नंबर दर्ज करें',
  vehicleRegistrationNumberField: 'वाहन पंजीकरण संख्या',
  vehicleRegistrationPlaceholder: 'वाहन पंजीकरण संख्या दर्ज करें',
  vehicleManufacturingYearField: 'वाहन निर्माण वर्ष',
  vehicleManufacturingYearPlaceholder: 'वाहन निर्माण वर्ष दर्ज करें',
  vehicleMakeAndModelField: 'वाहन मेक और मॉडल',
  vehicleMakeAndModelPlaceholder: 'वाहन मेक और मॉडल दर्ज करें',
  vehicleRCValidity: 'वाहन RC वैधता',
  vehicleInsuranceValidity: 'वाहन बीमा वैधता',
  pucValidity: 'PUC वैधता',
  fitnessCertificateValidity: 'फिटनेस प्रमाणपत्र की वैधता',
  vehicleCategory: 'वाहन श्रेणी',
  enterVehicleCategory: 'वाहन श्रेणी दर्ज करें',

  ownerEmail: 'मालिक का ईमेल',
  enterVehicleOwnerEmail: 'वाहन मालिक का ईमेल दर्ज करें',
  vehicleNumber: 'वाहन संख्या',
  enterVehicleNumber: 'वाहन संख्या दर्ज करें',
  vehicleRC: 'वाहन RC',
  vehicleInsuranceCertificate: 'वाहन बीमा प्रमाणपत्र',
  pUCCertificate: 'PUC प्रमाणपत्र',
  vehicleChassisNumber: 'वाहन चेसिस नंबर',
  enterChassisNumber: 'चेसिस नंबर दर्ज करें',
  fitnessCertificate: 'फिटनेस सर्टिफिकेट',

  // REGISTRATION SUCCESS
  successfullySubmitted: 'सफलतापूर्वक दर्ज हुआ',
  yourReferenceNumberIs: 'आपका संदर्भ संख्या है',
  pleaseKeepNoteOfIt: 'कृपया इसका ध्यान रखें',
  youWillReceiveMessage:
    ' आपको लॉगिन विवरण का एक संदेश आपके पंजीकर्ण मोबाइल नंबर पर प्राप्त होगा',
  youCanCheckTheStatus: ' आप आवेदन की स्थिति की जांच कर सकते हैं',
  here: 'यहां',
  back: 'वापस',
  termsAndConditions: 'नियम और शर्तें स्वीकार करें',
  acceptAll: 'सभी',
  goBackToLogin: 'लॉग इन पर वापस जाएं',

  // RESET PASSWORD
  resetPassword: 'Reset Password',
  passwordDoseNotMatch: 'Password does not match',
  passwordStartsWithSpace: 'Password should not start with space',

  newPassword: 'नया पासवर्ड',
  enterNewPassword: 'Enter New password',
  confirm: 'पुष्टि करें',
  confirmPassword: 'पासवर्ड की पुष्टि करें',

  // PERSON REGISTRATION
  personTypeField: 'व्यक्ति का प्रकार',
  personTypePlaceholder: 'व्यक्ति प्रकार चुनें',
  countryField: 'देश',
  countryPlaceholder: 'देश चुनें',
  documentTypeField: 'दस्तावेज़ का प्रकार',
  documentTypePlaceholder: 'दस्तावेज़ प्रकार चुनें',
  uploadDocumentField: 'दस्तावेज अपलोड करें',
  uploadDocumentPlaceholder: 'फाइल चुनें',
  bloodGroup: 'ब्लड ग्रुप',
  bloodGroupPlaceholder: 'ब्लड ग्रुप चुनें',
  safetyCertificateValidity: 'सुरक्षा प्रमाणपत्र वैधता',
  medicalCertificateValidity: 'मेडिकल प्रमाणपत्र अपलोड करें',
  policeNOCValidity: 'पुलिस NOC की वैधता',

  dob: 'जन्म की तारीख',
  personName: 'व्यक्ति का नाम',
  enterName: 'व्यक्ति का नाम दर्ज करें',
  personPhoneNumber: 'व्यक्ति फोन नंबर',
  selectGender: 'लिंग',
  documentProofNumber: 'दस्तावेज़ प्रमाण संख्या',
  documentProofNumberPlaceHoler:
    'आधार/ड्राइविंग लाइसेंस/पैन नंबर/आईडी दर्ज करें',
  documentValidity: 'दस्तावेज की वैधता',
  uploadSafetyCertificate: 'सुरक्षा प्रमाणपत्र अपलोड करें',
  officialContractDocument: 'अनुबंध दस्तावेज़ अपलोड करें',
  officialContractDocumentValidity: 'अनुबंध दस्तावेज़ वैधता',

  selectDocument: 'फाइल चुनें',
  uploadMedicalCertificate: 'मेडिकल प्रमाणपत्र अपलोड करें',
  selectMedicalCertificate: 'फाइल चुनें',
  uploadPoliceNOC: 'पुलिस NOC अपलोड करें',
  selectPoliceNOC: 'फाइल चुनें',
  personEmail: 'व्यक्ति ईमेल',
  enterEmail: 'व्यक्ति ईमेल दर्ज करें',
  individualPhoto: 'व्यक्ति का फोटो',
  chooseFile: 'फाइल चुनें',

  // Vehicle Registration
  ownerNameField: 'वाहन के मालिक का नाम',
  ownerNamePlaceholder: 'वाहन के मालिक का नाम दर्ज करें',

  ownerEmailField: 'मालिक का ईमेल',
  ownerEmailPlacholder: 'वाहन के मालिक का ईमेल दर्ज करें',

  ownerPhoneNumberField: 'मालिक का फोन नंबर',
  ownerPhoneNumberPlaceholder: 'मालिक का फोन नंबर दर्ज करें!',

  vehicleNumberField: 'वाहन पंजीकरण संख्या',
  vehicleNumberPlaceholder: 'वाहन संख्या दर्ज करें',

  vehicleRCNumberField: 'वाहन पंजीकरण संख्या',
  vehiicleRCNumberPlaceholder: 'वाहन का पंजीकरण संख्या दर्ज करें',

  vehicleRCField: 'वाहन की पंजीकरण',
  vehiceRCPlaceholder: 'फाइल चुनें',

  vehicleInsuranceNumberField: 'वाहन की बीमा संख्या',
  vehicleInsuranceNumberPlaceholder: 'बीमा की संख्या दर्ज करें',

  vehicleInsuranceCertificateField: 'वाहन का बीमा प्रमाणपत्र',
  vehicleInsuranceCertificatePlaceholder: 'फाइल चुनें',

  vehiclePollutionNumberField: 'वाहन की प्रदूषण संख्याr',
  vehiclePollutionNumberPlaceholder: 'प्रदूषण संख्या दर्ज करें',

  PUCCertificateField: 'PUC प्रमाणपत्र',
  PUCCertificatePlaceholder: 'फाइल चुनें',

  vehicleTypeField: 'वाहन का प्रकार',
  vehicleTypePlaceholder: 'वाहन का प्रकार दर्ज करें',

  vehicleChassisNumberField: 'वाहन चेसिस नंबर',
  vehicleChassisNumberPlaceholder: 'वाहन चेसिस नंबर दर्ज करें',

  vehicleEngineNumberField: 'वाहन का इंजन नंबर',
  vehicleEngineNumberPlaceholder: 'इंजन वाहन संख्या दर्ज करें',

  fitnessCertificateField: 'फिटनेस सर्टिफिकेट',
  fitnessCertificatePlaceholder: 'फाइल चुनें',

  // CHECK APPLICATION STATUS
  requestTypeLabel: 'अनुरोध का प्रकार',
  requestTypePlaceholder: 'अनुरोध का प्रकार दर्ज करें',
  referenceNumberLabel: 'संदर्भ संख्या',
  referenceNumberPlaceholder: 'संदर्भ संख्या दर्ज करें',
  forgotReferenceNumber: ' क्या संदर्भ संख्या भूल गए?',
  checkStatusButton: 'स्थिति की जांच करें',

  registrationRequestApproved: 'Registration Request Approved',
  reason: 'Reason',
  youShouldHaveAlreadyReceived: `You should have already received reference number to your registered
    mobile number, if not`,
  clickHere: 'click here',
  registrationRequestPending: 'Registration Request Pending',
  yourRequestIsStillPending: `Your request is still pending, you will receive a message to your
    registered mobile number.`,
  registrationRequestRejected: 'Registration Request Rejected',
  loginDetailsSent:
    'लॉग इन क्रेडिएंशियल्स आपके पंजीकृत मोबाइल नंबर पर सफलतापूर्वक भेज दिए गए हैं',

  // Popup
  pleaseSelectRequestType: 'कृपया अनुरोध का प्रकार चुनें',
  pleaseEnterReferenceNumber: 'Please Enter a Reference Number',

  getReferenceNumber: 'संदर्भ संख्या प्राप्त करें',
  proofDocumentTypeSubmitted: 'प्रमाण दस्तावेज का प्रकार दर्ज किया गया',
  proofDocumentTypeSubmittedLabel: 'प्रमाण दस्तावेज का प्रकार दर्ज करें',
  documentNumberLabel: 'दस्तावेज की संख्या',
  documentNumberPlaceholder: 'दस्तावेज की संख्या दर्ज करें',
  referenceNumberSent:
    'संदर्भ संख्या आपके पंजीकृत मोबाइल नंबर पर सफलतापूर्वक भेज दी गई है',

  okayPopup: 'ठीक!',
  submitRequest: 'अनुरोध दर्ज करें',
  agencyLandlineNumber: 'एजेंसी लैंडलाइन नंबर',
  agencyLandlineNumberPlaceholder: 'एजेंसी लैंडलाइन नंबर',
  agencyMobileNumber: 'एजेंसी का फोन नंबर',
  agencyMobileNumberPlaceholder: 'एजेंसी का फोन नंबर',
  designation: 'पद',
  designationPlaceholder: 'पदनाम दर्ज करें',
  gstDocument: 'जीएसटी दस्तावेज़',

  name: 'नाम',
  namePlaceholder: 'नाम दर्ज करें',
  mobileNumber: 'मोबाइल नंबर(+91)',
  mobileNumberPlaceholder: 'मोबाइल नंबर दर्ज करें',
  emailAddress: 'ईमेल पता',
  emailAddressPlaceholder: 'ईमेल पता दर्ज करें',
  cancelPopup: 'रद्द करें',
  retryApiPopup: 'पुन: प्रयास करें',
  searchBar: 'नाम से खोजें',
  // OPERATOR
  operatorName: 'Operator Name',
  emailId: 'Email ID',

  address: 'Address',
  loginId: 'Login ID',
  shift: 'Shift',
  shift1: 'Shift 1',
  shift2: 'Shift 2',
  shift3: 'Shift 3',

  operatorId: 'Operator ID',
  gender: 'लिंग',
  aadharNumber: 'Aadhar Number',
  pincode: 'Pincode',
  role: 'Role',
  location: 'Location',
  startTime: 'Start Time',
  endTime: 'End Time',
  maxOperatorsLimit: 'Max Operators Limit',
  enterMaxOperatorsLimit: 'Enter Max Operators Limit',
  locationId: 'Location ID',
  enterLocationId: 'Enter Location ID',
  locationName: 'Location name',
  enterLocationName: 'Enter Location Name',
  numberOfShifts: 'No. of Shifts',
  selectShift: 'Select Shift',

  languagePreference: 'Language Preference',
  english: 'English',
  hindi: 'हिंदी',
  approve: 'Approve',
  keepOnHold: 'Keep on hold',
  surrenderCard: 'Surrender Card',
  edit: 'Edit',

  DEPT: {
    agencyRegistrationRequest: 'Agency Registration Requests',
  },

  //ADMIN
  //Admin agency requests
  ADMIN: {
    agencyRequest: 'Agency Permit Requests',
    agencyName: 'एजेंसी का नाम',
    Status: 'Status',
    dateRange: 'Date Range',
    clear: 'clear',
    apply: 'Apply',
    reconcileAll: 'Reconcile All',
    referenceNumber: 'Reference Number',
    agencyName: 'Agency Name',
    purposeOfWork: 'Purpose of Work',
    areaOfWork: 'Area of Work',
    requestType: 'Request Type',
    visitingDate: 'Visiting Date',
    refresh: 'Refresh',
    POP_UP: {
      agencyRequest: 'Agency Request',
      visitingDate: 'Visiting Date',
      purposeofWork: 'Purpose of Work',
      areofWork: 'Are of Work',
      department: 'विभाग',
      passValidityPeriod: 'Pass Validity Period',
      personDetails: 'व्यक्ति विवरण',
      name: 'नाम',
      email: 'ईमेल',
      phoneNo: 'फोन नंबर',
      gender: 'लिंग',
      dateOfBirth: 'Date of Birth ',
      country: 'Country',
      nationality: 'Nationality',
      personType: 'Person Type ',
      documnetType: 'Documnet Type',
      documentProofNumber: 'Document Proof Number',
      rFIDcardNumber: 'RFID card Number',
      document: 'Document',
      vehicleDetails: 'वाहन का विवरण',
      vehicleOwnerName: 'मालिक का नाम',
      ownerEmail: 'मालिक का ईमेल',
      ownerPhoneNumber: 'मालिक का फोन नंबर',
      vehicleType: 'वाहन का प्रकार',
      vehicleNumber: 'वाहन संख्या',
      vehicleRCNumber: 'वाहन पंजीकरण संख्या',
      vehicleRC: 'वाहन की पंजीकरण',
      vehicleInsuranceNumber: 'वाहन की बीमा संख्या',
      vehiclePollutionNumber: 'वाहन की प्रदूषण संख्या',
      vehicleEngineNumber: 'Vehicle Engine Number',
      vehicleChassisNumber: 'Vehicle Chassis Number',
      vehicleInsuranceCertificate: 'Vehicle Insurance Certificate',
      pUCCertificate: 'PUC Certificate',
      fitnessCertificate: 'Fitness Certificate',
      driverDetails: 'ड्राइवर का विवरण',
      toReject: 'To reject please enter the reason for rejection',
      rejectCnf1: 'Permit Request  with reference number',
      rejectCnf2: 'has been Successfully Rejected',
      approveCnf1: 'Permit Request  with reference number',
      approveCnf2: 'has been Successfully Approved',
      driverDetails: 'Driver Details',
      errorMessage:
        'There was an error processing your request. Please try again later.',
      back: 'Back',
      okay: 'ठीक है',
      reject: 'अस्वीकार करें',
      approve: 'मंजूर करें',

      // Operator module
      referenceNumber: 'Reference Number',
    },
  },

  AGENCY: {
    DASH_BOARD: {
      dashBoard: 'डैशबोर्ड',
      permitsApplied: 'परमिट लागू',
      permitsPending: 'परमिट लंबित',
      permitsApproved: 'परमिट स्वीकृत',
      permitsRejected: 'परमिट अस्वीकृत',
      addPermit: '+ परमिट जोड़ें',
    },
    PERMITS: {},
    REPORTS: {},
  },

  // Operator
  areYouSureSurrender: 'Are you sure you want to surrender this card?',
  titlePersonsCard: 'Persons Card',
  areYouSureIssue: 'Are you sure you want to issue this card?',
  areYouSureReject: 'Are you sure you want to reject this card?',
  history: 'History',
  pleaseTakeThePicture: 'Please take the picture.',
  remarksCantBeEmpty: 'Remark field cannot be empty.',
  typeYourRemarks: 'Type your remarks here...',
  currentStatus: 'Current Status',
  phoneNumber: 'फ़ोन नंबर',

  rfidCardNumber: 'RFID Card Number',
  enterRfidNumber: 'Enter RFID Number',
  dateOfBirth: 'Date of Birth',
  selectCountry: 'Select Country',
  nationality: 'Nationality',
  enterNationality: 'Enter Nationality',

  vehiclesCard: 'Vehicles Card',

  // Success
  newCardsWithRFID: 'New cards with RFID No.',
  hasBeenApprovedFor: 'has been approved for',
  remarks: 'Remark',
  searchWithRfid: 'Search with reference number or RFID number to get details ',
  person: 'Person',
  hasBeenPutOnHoldForPerson: 'has been put on hold for Person',
  RFIDSurrendered: 'RFID Card has been surrendered',

  vehicleOwnerEmail: 'Vehicle Owner Email',
  vehicleOwnerPhoneNumber: 'Owner Mobile Number',
  vehicleRegtNumber: 'Vehicle Regt. Number',
  keepOnHold: 'Keep on hold',
  surrenderCard: 'Surrender Card',
  requestDate: 'Request Date',
  status: 'Status',
  viewHistory: 'View History',
  pleaseVerify: 'Please verify all the documents',
  pleaseAssignRFID: 'Please assign an RFID number',
  verifiedPerson: 'Verified Person Details',
  verifiedProof: 'Verified Proof Document',
  pleaseEnterRFID: 'Please Enter RFID Card Number',

  //CSVpopup
  CSVpopup: {
    okay: 'ठीक',
    cancel: 'रद्द करना',
    message: 'डाउनलोड खत्म करने के लिए ओके पर क्लिक करें',
  },
};

export default hindi;
