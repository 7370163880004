const PAGE_TITLES = {
  landing: 'e-Drishti Access Control System',
  vehicleRegistration: 'Vehicle Registration | e-Drishti Access Control System',
  personRegistration: 'Person Registration | e-Drishti Access Control System',
  checkApplicationStatus:
    'Check Application Status | e-Drishti Access Control System',
  newAgencyRegistration:
    'New Agency Registration | e-Drishti Access Control System',
  changePassword: 'Forgot Password | e-Drishti Access Control System',
  getReferenceNumber: 'Get Reference Number | e-Drishti Access Control System',
};

export default PAGE_TITLES;
