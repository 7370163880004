import React, {useState} from 'react';
import Flex from '../../../../../../components/Flex';
import PermitsHeader from '../../../../../../components/PermitsHeader';
import TextInput from '../../../../../../components/TextInput';
import styles from './styles.module.scss';
import exclamation from '../../../../../../assets/images/exclamation-icon/image.png';
import success from '../../../../../../assets/images/success/image.png';
import cross from '../../../../../../assets/images/reject-icon-cross/image.png';
import util from '../../../../../../util';

const Desktop = ({
  onClick,
  pageStatus,
  cartItems,
  amount,
  gst,
  total,
  calculatedGST,
  calculatedTotal,
  referenceId,
}) => {
  const onButtonClick = () => {
    console.log('on button click');
    onClick('success-cta');
  };
  const onFailure = action => {
    console.log(action);
    onClick(action);
  };
  const getPageContent = () => {
    switch (pageStatus) {
      case 'success':
        return <Success referenceId={referenceId} onClick={onButtonClick} />;
      case 'failure':
        return <Failure onClick={action => onFailure(action)} />;
      default:
        return (
          <CartContainer
            cartItems={cartItems}
            onClick={onClick}
            amount={amount}
            gst={gst}
            total={total}
            calculatedGST={calculatedGST}
            calculatedTotal={calculatedTotal}
          />
        );
    }
  };
  return (
    <div className={styles.mainContainer}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1>Review Order</h1>
        </div>
        {getPageContent()}
      </div>
    </div>
  );
};

const Success = ({onClick, referenceId}) => {
  return (
    <div className={styles.successContainer}>
      <h1>Payment Successful</h1>
      <img src={success} alt="U" />
      <h2>Check wallet for Available Trips</h2>
      <div>
        <p>
          Here is your reference ID: <span>{referenceId}</span>
        </p>
        <p>Please note this reference ID for future references.</p>
      </div>

      <button onClick={onClick}>Okay!</button>
    </div>
  );
};
const Failure = ({onClick}) => {
  return (
    <div className={styles.failureContainer}>
      <h1>Payment Failed</h1>
      <img src={cross} alt="U" />
      <h2>Please Try Again or Pay Later</h2>

      <div className={styles.failureCTA}>
        <button
          className={styles.payLater}
          onClick={() => onClick('pay-later')}>
          Pay Later
        </button>
        <button
          className={styles.tryAgain}
          onClick={() => onClick('try-again')}>
          Try Again!
        </button>
      </div>
    </div>
  );
};

const CartContainer = ({
  onClick,
  cartItems,
  amount,
  gst,
  total,
  calculatedGST,
  calculatedTotal,
}) => {
  const onDelete = item => {
    onClick('delete-cart-item', item);
  };
  return (
    <>
      <div className={styles.cartContainer}>
        <div className={styles.tableWrapper}>
          {/* <Table cartItems={cartItems} onDelete={onDelete} onClick={onClick} /> */}
        </div>
        <Flex direction="row" className={styles.details}>
          <p>Amount: </p>
          <p>₹ {calculatedTotal} </p>
        </Flex>
        <Flex direction="row" className={styles.details}>
          <p>GST(18%): </p>
          <p>₹ {calculatedGST.toFixed(2)} </p>
        </Flex>
        <div className={styles.divider}></div>

        <Flex direction="row" className={styles.details}>
          <p>Total: </p>
          <p>₹ {calculatedTotal + calculatedGST} </p>
        </Flex>
      </div>

      <Flex direction="row" className={styles.buttonsContainer}>
        <button
          className={styles.cancel}
          onClick={() => {
            onClick('cancel');
          }}>
          Cancel
        </button>
        <button
          className={styles.positive}
          onClick={() => {
            onClick('proceed', 2000);
          }}>
          Proceed To Payment
        </button>
      </Flex>
    </>
  );
};

const Table = ({cartItems, onDelete, onClick}) => {
  return (
    <>
      <Flex direction="row" className={styles.tableHeader}>
        <p style={{width: '40%'}}>Items</p>
        <p style={{width: '20%'}}>Type</p>
        <p style={{width: '20%'}}>Quantity</p>
        <p style={{width: '10%'}}>Cost</p>
        <p style={{width: '10%'}}>Total</p>
      </Flex>
      {cartItems.map((item, index) => {
        return (
          <TableRow
            key={index}
            cartItem={item}
            onDelete={() => onDelete(item)}
            onClick={onClick}
          />
        );
      })}
    </>
  );
};

const TableRow = ({cartItem, onDelete, onClick}) => {
  const [quantity, setQuantity] = useState(cartItem.quantity);

  const handleIncrease = () => {
    setQuantity(quantity + 1);
    onClick('update-quantity', {
      // id: cartItem.id,
      quantity: quantity + 1,
    });
  };
  const handleDecrease = () => {
    setQuantity(quantity - 1);
    onClick('update-quantity', {
      // id: cartItem.id,
      quantity: quantity - 1,
    });
  };

  const checkDisabled = () => {
    if (quantity > 1) {
      return false;
    }
    return true;
  };
  return (
    <Flex direction="row" style={{alignItems: 'center'}}>
      <Flex direction="row" className={styles.tableBody}>
        <p style={{width: '40%'}}>{cartItem.name}</p>
        <p style={{width: '20%'}}>{cartItem.type}</p>
        <div style={{width: '20%'}}>
          <Flex direction="row">
            <button
              disabled={checkDisabled()}
              onClick={handleDecrease}
              className={styles.quantityButtons}>
              -
            </button>
            <p className={styles.quantity}>{quantity}</p>
            <button onClick={handleIncrease} className={styles.quantityButtons}>
              +
            </button>
          </Flex>
        </div>
        <p style={{width: '10%'}}>₹ {cartItem.cost}</p>
        <p style={{width: '10%'}}>₹ {cartItem.total}</p>
      </Flex>
      <button onClick={onDelete} className={styles.cross}>
        X
      </button>
    </Flex>
  );
};

export default Desktop;
