import React, {useRef, useState} from 'react';
import styles from './styles.module.scss';
import {AgGridReact} from 'ag-grid-react';
import {deptDashBoard, operatorImages} from '../../../../../assets/images';

import dimensions from '../../../../../util/dimensions-util';
import Flex from '../../../../../components/Flex';

import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import moment from 'moment';
import LineGraph from '../../../../../components/LineGraph';

import Select from 'react-select';
import {customStyles} from './customStyles';

import DateRangePicker from 'react-daterange-picker';

import truckIcon from '../../../../../assets/images/traffic-department-icons/truck/image.png';
import gateIcon from '../../../../../assets/images/traffic-department-icons/gate/image.png';

import downloadIcon from '../../../../../assets/images/traffic-department-icons/download/image.png';
import expandIcon from '../../../../../assets/images/traffic-department-icons/expand/image.png';
import filterIcon from '../../../../../assets/images/traffic-department-icons/filter/image.png';
import shareIcon from '../../../../../assets/images/traffic-department-icons/share/image.png';
import ReactToPrint from 'react-to-print';
import CustomCheckbox from '../../../../../components/CustomCheckbox';
import strings from '../../../../../globalization';
import MetricsLanding from '../../../../../components/TrafficDashboardComponents/MetricsLanding';
import SannaLogo from '../../../../../components/SannaLogo';
import ReactTooltip from 'react-tooltip';
import useOnClickOutside from '../../../../../hooks/useOnClickOutside';

const {vw: widthConstant, vh: heightConstant} = dimensions;
const ROLE_MAPPER = {
  COMPANY_ADMIN: 'Company Admin',
  COMPANY_SUPER_ADMIN: 'Company Super Admin',
  DEPARTMENT_VIEWER: 'Department Viewer',
  DEPARTMENT_ADMIN: 'Department Admin',
  DEPARTMENT_SUPER_ADMIN: 'Department Super Admin',
  DEPUTY_CHAIRMAN: 'Deputy Chairman',
  MASTER: 'Master',
  OPERATOR_ADMIN: 'Operator Admin',
  OPERATOR_EMPLOYEE: 'Operator Employee',
  OPERATOR_SUPER_ADMIN: 'Operator Super Admin',
  COMPANY_VIEWER: 'Company Viewer',
  SIGNATORY: 'Signatory',
};

export default function Desktop({
  statuses,
  selectedDropDownStatus,
  onClick,
  startDate,
  endDate,
  dates,
  user,
  departmentName,
  gateDetails,
  parametersDetails,
  selectedGates,
  selectedParameters,
  filteredTrafficDetails,
  dashboardDetails,
}) {
  const columns = [
    {
      name: 'First Header',
    },
    {
      name: 'Second Header',
    },
    {
      name: 'Third Header',
    },
    {
      name: 'Fourth Header',
    },
    {
      name: 'Fifth Header',
    },
  ];

  const [showDate, setShowDate] = useState(false);
  const [showFiltersBar, setShowFiltersBar] = useState(false);
  const formatDate = date => moment(date).format('MMM Do YY');

  const dateRef = useRef();
  useOnClickOutside(dateRef, () => setShowDate(false));

  const onSelect = dates => {
    onClick('date', {
      startDate: moment(dates.start).format('MM-DD-YYYY'),
      endDate: moment(dates.end).format('MM-DD-YYYY'),
      dates,
    });
    toggleDate();
  };
  const toggleDate = () => {
    setShowDate(!showDate);
  };

  const handleSelect = name => {
    setShowDate(false);
    if (name?.name === 'custom') {
      setShowDate(true);
    }
    onClick('dateChange', name);
  };
  const componentRef = React.useRef(null);

  const onBeforeGetContentResolve = React.useRef(null);

  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState('old boring text');

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handleAfterPrint = React.useCallback(() => {
    console.log('`onAfterPrint` called');
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log('`onBeforePrint` called');
  }, []);
  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log('`onBeforeGetContent` called');
    setLoading(true);
    setText('Loading new text...');

    return new Promise(resolve => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText('New, Updated Text!');
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);
  const reactToPrintTrigger = React.useCallback(() => {
    return (
      <button className={styles.metricButton}>
        <img
          src={downloadIcon}
          className={styles.metricButtonIcon}
          alt="icon"
        />
      </button>
    );
  }, []);

  return (
    <>
      <ReactTooltip
        html={true}
        className={styles.toolTip}
        place="top"
        type="info"
        effect="solid"
        multiline={true}
      />
      <Flex direction="column" className={styles.mimicContainer}>
        <div className={styles.headerContainer}>
          <div className={styles.headerText}>
            {strings.AGENCY.DASH_BOARD.dashBoard + ' - ' + departmentName}
          </div>
          <div
            className={styles.profileContainer}
            onClick={() => onClick('profile')}>
            <img
              className={styles.pic}
              alt="icon"
              src={deptDashBoard.deptDashBoardUserIcon}></img>
            <div>
              <div className={styles.name}>{user?.firstName}</div>
              <div className={styles.role}>
                {ROLE_MAPPER[user?.roles?.name]}
              </div>
            </div>
            <img
              alt="settings"
              className={styles.settingBtn}
              src={deptDashBoard.deptDashBoardSettingIcon}
            />
          </div>
        </div>
        <div className={styles.mainContainer}>
          <div className={styles.topContainer}>
            <div className={styles.rightContainer}>
              {/* <p className={styles.date}>{moment().format('Do MMM YYYY')}</p> */}
              <div className={styles.liveContainer}>
                <div className={styles.greenCircle} />
                <p>Live</p>
              </div>
            </div>
          </div>
          <Flex direction="row" className={styles.metrics}>
            <MetricsLanding dashboardDetails={dashboardDetails} />
          </Flex>

          <div className={styles.graphContainer}>
            <Flex direction="row" style={{justifyContent: 'space-between'}}>
              <p>Trends</p>
              <Flex direction="row" className={styles.rightFiltersContainer}>
                <div className={styles.agencyNameDropdown}>
                  <Select
                    options={statuses}
                    getOptionLabel={option => option.displayName}
                    getOptionValue={option => option.id}
                    placeholder={'Select date'}
                    styles={customStyles}
                    className={styles.dropDown}
                    value={statuses.filter(
                      option => option?.id === selectedDropDownStatus?.id,
                    )}
                    onChange={handleSelect}
                  />
                  <div onClick={toggleDate} className={styles.inputDate}>
                    {' '}
                    {dates
                      ? '(' +
                        formatDate(dates.start) +
                        '-' +
                        formatDate(dates.end) +
                        ')'
                      : null}{' '}
                  </div>
                </div>
                <div className={styles.dateWrapper}>
                  {showDate ? (
                    <div className={styles.datePicker} ref={dateRef}>
                      <DateRangePicker
                        numberOfCalendars={1}
                        selectionType="range"
                        onSelect={onSelect}
                        className={styles.dateRange}
                        value={dates}
                        singleDateRange={true}
                        maximumDate={moment().subtract(1, 'days')}
                      />
                    </div>
                  ) : null}
                </div>

                <MetricButton
                  dataTip="Download"
                  icon={downloadIcon}
                  onClick={() => onClick('print-graph')}
                />
                {/* <MetricButton icon={shareIcon} onClick={onClick} /> */}
                <MetricButton
                  dataTip="Filter"
                  icon={filterIcon}
                  onClick={() => setShowFiltersBar(!showFiltersBar)}
                />
                <MetricButton
                  dataTip="Expand"
                  icon={expandIcon}
                  onClick={() => onClick('graph-popup')}
                />
                {showFiltersBar && (
                  <button
                    onClick={() => onClick('apply-filters')}
                    className={styles.apply}>
                    Apply Filters
                  </button>
                )}
              </Flex>
            </Flex>
            {showFiltersBar && (
              <div className={styles.filtersContainer}>
                <MetricsFilters
                  parametersDetails={parametersDetails}
                  onClick={onClick}
                />
                <GateFilters onClick={onClick} gateDetails={gateDetails} />
              </div>
            )}

            <div ref={componentRef}>
              <LineGraph
                trafficDetails={filteredTrafficDetails}
                selectedGates={selectedGates}
                selectedParameters={selectedParameters}
                responsive
              />
            </div>
          </div>
          <SannaLogo />
        </div>
      </Flex>
    </>
  );
}

const GateFilters = ({onClick, gateDetails}) => {
  return (
    <Flex direction="column" className={styles.gateFiltersContainer}>
      <h2 className={styles.metricsFiltersHeader}>Select Gate</h2>
      <Flex direction="row" style={{flexWrap: 'wrap'}}>
        {gateDetails?.map(el => (
          <CustomCheckbox
            label={el?.name}
            onClick={data =>
              onClick('handle-gate-filters', {
                singleGateDetails: el,
                checked: data,
              })
            }
          />
        ))}
      </Flex>
    </Flex>
  );
};

const MetricsFilters = ({onClick, parametersDetails}) => {
  return (
    <Flex
      direction="column"
      style={{
        flexShrink: 0,
        alignItems: 'flex-start',

        minHeight: '100%',
      }}>
      <h2 className={styles.metricsFiltersHeader}>Select Parameter</h2>
      <Flex direction="row">
        {parametersDetails?.map(el => (
          <CustomCheckbox
            key={el?.key}
            label={el.label}
            onClick={data =>
              onClick('handle-metrics-filters', {
                metric: el.key,
                checked: data,
              })
            }
          />
        ))}
      </Flex>
    </Flex>
  );
};

const MetricButton = ({icon, onClick, dataTip}) => {
  return (
    <button onClick={onClick} className={styles.metricButton}>
      <span data-tip={dataTip} data-place="top">
        <img src={icon} className={styles.metricButtonIcon} alt="icon" />
      </span>
    </button>
  );
};

const MetricCard = ({icon, number, metric, title}) => {
  return (
    <div className={styles.metricCardContainer}>
      <Flex direction="row" style={{height: '100%', alignItems: 'center'}}>
        <div className={styles.imageContainer}>
          <img src={icon} alt="icon" />
          {number && (
            <div className={styles.numberContainer}>
              <p className={styles.number}>{number}</p>
            </div>
          )}
        </div>
        <Flex direction="column">
          <p className={styles.metric}>{metric}</p>
          <p className={styles.title}>{title}</p>
        </Flex>
      </Flex>
    </div>
  );
};
