import styles from './styles.module.scss';
import dptlogo from '../../../../../assets/images/Logo/logo.png';
import drishtilog from '../../../../../assets/images/edrishti-group/Group.png';
import Flex from '../../../../../components/Flex';
import sannaLogo from '../../../../../assets/images/sanna-logo/image.png';
import celLogo from '../../../../../assets/images/cel-logo-red/image.png';
import mroadsLogo from '../../../../..//assets/images/mroads-logo/image.png';
import scannerLogo from '../../../../../assets/images/scanner-icon/image.png';
import newDptLogo from '../../../../../assets/images/new-dpt-logo/image.png';

import Card from '../../../../../components/PermitPassDetails';
import React from 'react';
import QRCode from 'react-qr-code';
import LineGraph from '../../../../../components/LineGraph';
import moment, { invalid } from 'moment';

const Desktop = (
  {
    gateDetails,
    onClick,
    filteredTrafficDetails,
    selectedGates,
    selectedParameters,
  },
  ref,
) => {
  console.log('Filtered traffic details', filteredTrafficDetails);

  const dates = filteredTrafficDetails?.dayWisePermitsCount?.map(el => {
    return moment(el?.date).format('Do MMM');
  });
  console.log('dates...', dates);
  let permitsRaised = {
    label: 'Permits Raised',
    data: filteredTrafficDetails?.dayWisePermitsCount?.map(el => el.count),
  };

  let totalPersons = {
    label: 'Total Persons',
    data: filteredTrafficDetails?.dayWisePersonsCount?.map(el => el.count),
  };
  let personsWithPermits = {
    label: 'Persons With Permits',
    data: filteredTrafficDetails?.dayWiseValidPermitsCount?.map(el => el.count),
  };

  let invalidEntries = {
    label: 'Invalid Entries',
    data: filteredTrafficDetails?.dayWiseInvalidPermitsCount?.map(
      el => el.count,
    ),
  };

  console.log(
    'all filters',
    permitsRaised,
    totalPersons,
    personsWithPermits,
    invalidEntries,
  );
  const getHeaders = () => {
    let selectedGatesArray = Array.from(selectedGates);
    let datasets = [];

    if (selectedParameters.size !== 0) {
      if (selectedParameters.has('permitsRaised'))
        datasets.push('Permits Raised');
      if (selectedParameters.has('totalPersons'))
        datasets.push('Total Vehicles');
      if (selectedParameters.has('personsWithPermits'))
        datasets.push('Vehicles With Permits');
      if (selectedParameters.has('invalidEntries'))
        datasets.push('Invalid Entries');
    } else {
      datasets.push(
        'Permits Raised',
        'Total Persons',
        'Persons With Permits',
        'Invalid Entries',
      );
    }

    return (
      <>
        {datasets.map(el => (
          <td key={el}>{el}</td>
        ))}
      </>
    );
  };

  const generateRows = () => { };

  const checkCondition = parameter => {
    if (selectedParameters.size !== 0) {
      if (selectedParameters.has(parameter)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <Flex
      className={styles.mainContainer}
      onClick={() => onClick('closePopup')}>
      <Flex className={styles.container} ref={ref}>
        <div className={styles.subcontainer}>
          <div className={styles.header}>
            <div className={styles.left}>
              <img src={drishtilog} alt="drishtilog" />
              <div className={styles.sanna}>
                <p>powered by</p> <img src={sannaLogo} alt="sanna" />
              </div>
            </div>
            <h1>Traffic Movement</h1>
            <img src={newDptLogo} alt="dptlogo" />
          </div>
          <Flex
            direction="column"
            style={{
              marginBottom: '5rem',
              height: '100%',
              width: '100%',
              alignItems: 'center',
            }}>
            <LineGraph
              trafficDetails={filteredTrafficDetails}
              selectedGates={selectedGates}
              selectedParameters={selectedParameters}
              responsive
            />
          </Flex>
          <div className={styles.table} style={{ marginBottom: '15rem' }}>
            <table>
              <thead>
                <tr className={styles.tableHeader}>
                  <th>Date</th>
                  {getHeaders()}
                </tr>
              </thead>
              <tbody>
                {generateRows()}
                {dates.map((el, idx) => (
                  <tr className={styles.rowBgcolor} key={`${el} - ${idx}`}>
                    <td>{el}</td>

                    {checkCondition('permitsRaised') && (
                      <td>{permitsRaised?.data[idx]}</td>
                    )}

                    {checkCondition('totalVehicles') && (
                      <td>{totalPersons?.data[idx]}</td>
                    )}

                    {checkCondition('vehiclesWithPermits') && (
                      <td>{personsWithPermits?.data[idx]}</td>
                    )}

                    {checkCondition('invalidEntries') && (
                      <td>{invalidEntries?.data[idx]}</td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.footer}>
            <img src={celLogo} alt="celLogo" />
            <img
              src={mroadsLogo}
              alt="mroadsLogo"
              style={{ height: '3.5vh', aspectRatio: 1 }}
            />
          </div>
        </div>
      </Flex>
    </Flex>
  );
};
export default React.forwardRef(Desktop);
