import React, {Component, useEffect} from 'react';
import styles from './styles.module.scss';
import PopupContainer from '../../../components/PopupContainer';
import SimplePopup from '../../../components/SimplePopup';

import {adminImages, loginImages, commonImages} from '../../../assets/images';
import moment from 'moment';
import ViewRenderer from './views';
import DeptMaterialPopup from '../../../components/DeptMaterialPopup';
import AgencyRquestsPopup from '../../../components/AgencyRquestsPopup';
import AgencyPersonDetails from '../../../components/AgencyPersonDetails';
import AgencyMaterialDetails from '../../../components/AgencyMaterialDetails';

import {API} from '../../../requests';
import strings from '../../../globalization';
import Spinner from '../../../components/Spinner';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Flex from '../../../components/Flex';
import util from '../../../util';
import {AgGridReact} from 'ag-grid-react';
import dimensions from '../../../util/dimensions-util';
import './styles.css';
import {customStyles} from './customStyles';

import Select from 'react-select';
import {entrySummary, rejectedReports} from './data';

const {vw: widthConstant, vh: heightConstant} = dimensions;

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  ERROR: 'error',
  SPINNER_POPUP: 'spinner-popup',
  PERMIT_DETAILS: 'permitDetails',
};

const STATUSES = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  ALL: 'ALL',
};

class PermitsRaisedReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rejectionList: [],
      filteredRejectionList: [],
      startDate: moment().subtract(1, 'week'),
      startTime: moment().startOf('day'),
      endDate: moment().add(1, 'day'),
      endTime: moment().endOf('day'),
      dates: {
        start: moment().subtract(1, 'week').format('MM-DD-YYYY'),
        end: moment().add(1, 'day').format('MM-DD-YYYY'),
      },
      totalCount: 0,
      filterAgencies: [],
      searchText: '',
      statuses: [
        {
          id: 1,
          name: STATUSES.APPROVED,
          label: STATUSES.APPROVED,
        },
        {
          id: 2,
          name: STATUSES.REJECTED,
          label: STATUSES.REJECTED,
        },
        {
          id: 0,
          name: STATUSES.ALL,
          label: STATUSES.ALL,
        },
      ],
      CHAList: [
        {
          id: 0,
          name: `CHA 1`,
          label: `CHA 1`,
        },
        {
          id: 1,
          name: `CHA 2`,
          label: `CHA 2`,
        },
        {
          id: 2,
          name: `CHA 3`,
          label: `CHA 3`,
        },
      ],
      selectedCHA: '',
    };
  }

  componentDidMount() {
    //  this.getData();
    this.fetchCompanyDetails();
  }

  applyFilters = () => {
    const {searchText, rejectionList} = this.state;
    const finalFilterData =
      rejectionList.filter(element =>
        Object.values(element)
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()),
      ) || rejectionList;
    this.setState({
      filteredRejectionList: finalFilterData,
    });
  };

  getData = async (paramsAPI, page, perPage) => {
    this.showSpinner();
    const {startDate, endDate, startTime, endTime, selectedCHA} = this.state;

    if (!startTime || !endTime || !startDate || !endDate) {
      this.showErrorPopup(`Date and Time parameters cannot be empty!`);
      return;
    }

    let params = {
      startDate: `${moment(startDate).format('DD-MM-YYYY')} ${moment(
        startTime,
      ).format('HH:mm')}`,
      endDate: `${moment(endDate).format('DD-MM-YYYY')} ${moment(
        endTime,
      ).format('HH:mm')}`,
      companyId: selectedCHA?.id || 0, // 0 if all
      pageNumber: page,
      pageSize: perPage,
    };
    console.log('params...', params);
    API.fetchAllCompanyPermitsVehicleRegistrationNumbers(params)
      .then(res => {
        console.log('response:', res, perPage);
        paramsAPI.successCallback(res.data?.permitDetails, res.data?.count);

        this.setState({
          rejectionList: res.data?.permitDetails,
          filteredRejectionList: res.data?.permitDetails,
          popup: undefined,
          totalCount: res.data?.count,
        });
      })
      .catch(err => {
        console.log('err', err);
        console.log(err);
        paramsAPI.successCallback([], 0);
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };
  showErrorPopup = errorMsg => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message:
          errorMsg || `Failed to fetch data. Please refresh and try again.`,
        messageStyle: {color: '#E67717'},

        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
          },
        ],
        onClose: this.closePopup,
      },
    });
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  fetchCompanyDetails = () => {
    API.fetchActiveCHADetails()
      .then(res => {
        let modifiedCHAList = res.data.map(el => {
          return {
            id: el.id,
            name: el.name,
            label: el.name,
          };
        });
        modifiedCHAList.push({
          id: 0,
          name: 'Select All',
          label: 'Select All',
        });
        this.setState({
          CHAList: modifiedCHAList,
        });
      })
      .catch(err => {
        console.log('err', err);
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };

  onChangeHandler = (action, data) => {
    switch (action) {
      case 'status': {
        this.setState({
          selectedStatus: data,
        });
        break;
      }
      case 'CHA': {
        this.setState({
          selectedCHA: data,
        });
        console.log('cha...', data);
        break;
      }
      case 'startTime': {
        this.setState({
          startTime: data,
        });
        break;
      }
      case 'endTime': {
        this.setState({
          endTime: data,
        });
        break;
      }

      case 'date': {
        const {startDate, endDate, dates} = data;
        this.setState({
          startDate,
          endDate,
          dates,
        });
        break;
      }

      case 'search': {
        console.info('data..', data);
        this.setState({
          searchText: data,
        });

        break;
      }

      case 'gridAPI': {
        console.log('grid API', data);
        this.setState({gridApi: data});
        this.onChangeHandler('updateTable');
        break;
      }
      case 'updateTable': {
        const {gridApi, selectedStatus, startDate, endDate, searchText} =
          this.state;
        const perPage = 100;
        if (gridApi) {
          const dataSource = {
            getRows: params => {
              const page = params.endRow / perPage;
              console.log(page, params, selectedStatus);
              this.getData(params, page - 1, perPage);
            },
          };
          gridApi.setDatasource(dataSource);
        }
        break;
      }

      default: {
        console.info('case not handled', action);
      }
    }
  };

  onClickHandler = (action, data) => {
    const {history, location} = this.props;

    switch (action) {
      case 'apply': {
        this.onChangeHandler('updateTable');
        break;
      }
      case 'clearFilters': {
        this.resetData();
        break;
      }

      case 'rowClick': {
        console.info('rowClick called...', data);

        break;
      }
      case 'document-view': {
        console.log('in document view redirection case');
        history.push({
          pathname: location.pathname + '/document-view',
          //   pathname: location.pathname + '/add-new-person',
          state: {background: location, url: data},
        });

        break;
      }
      default: {
        console.info('case not handled', action);
      }
    }
  };

  resetData = () => {
    this.setState(
      {
        searchText: '',
        selectedStatus: '',
        selectedCHA: '',
        startDate: moment().subtract(1, 'week'),
        startTime: moment().startOf('day'),
        endDate: moment().add(1, 'day'),
        endTime: moment().endOf('day'),
        dates: {
          start: moment().subtract(1, 'week').format('MM-DD-YYYY'),
          end: moment().add(1, 'day').format('MM-DD-YYYY'),
        },
      },
      this.onChangeHandler('updateTable'),
    );
  };

  getCSVDownloadHeaders = () => {
    return [
      {label: 'Vehicle Number', key: 'vehicleRegistrationNumber'},
      {label: 'CHA Name', key: 'companyName'},
      {label: 'Timestamp', key: 'createdDate'},
    ];
  };

  getCSVDownloadData = () => {
    const {filteredRejectionList} = this.state;
    return filteredRejectionList.map(el => {
      return {
        readerName: el.readerName,
        vehicleRegistrationNumber: el.vehicleRegistrationNumber,
        status: el.status,
        companyName: el.companyName,
        error: el.error,
        createdDate: moment(el.createdDate).format('MMMM Do YY HH:mm'),
        driverName: el?.driverName || '-',
        driverCompanyName: el?.driverCompanyname || '-',
      };
    });
  };

  getProps = () => {
    const {
      searchText,
      selectedAgency,
      selectedStatus,
      startDate,
      endDate,
      dates,
      startTime,
      endTime,
      rejectionList,
      statuses,
      CHAList,
      selectedCHA,
      filteredRejectionList,
      totalCount,
    } = this.state;
    return {
      onClick: this.onClickHandler,
      onChange: this.onChangeHandler,
      searchText,
      selectedAgency,
      selectedStatus,
      startDate,
      endDate,
      dates,
      startTime,
      endTime,
      rejectionList: filteredRejectionList,
      statuses,
      CHAList,
      selectedCHA,
      totalCount,
      downloadData: this.getCSVDownloadData(),
      downloadHeaders: this.getCSVDownloadHeaders(),
    };
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };

  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <SimplePopup {...popup} />;
      }
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SPINNER: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

// export default withRouter(PersonCards);
export default withRouter(PermitsRaisedReport);
