import React from 'react';
import styles from './styles.module.scss';
import {AgGridReact} from 'ag-grid-react';
import {operatorImages} from '../../../../../assets/images';

import dimensions from '../../../../../util/dimensions-util';
import Flex from '../../../../../components/Flex';
import AddNewLocation from '../../../../../components/operator/AddNewLocation';
import {formLeftFields, formRightFields} from '../../formFields';
import FormElement from '../../../../../components/FormElement';
import ShiftRows from '../../../../../components/operator/ShiftRows';

const {vw: widthConstant, vh: heightConstant} = dimensions;

export default function Desktop({
  buttons,
  header,
  buttonContainer,
  onChangeHandler,
  onBlur,
  stateData,
  shiftRows,
  onShiftChange,
  containerClassNames,
  readOnlyFields,
}) {
  const checkDisabled = stateField => {
    const {mode} = stateData;
    if (mode === 'edit') {
      return readOnlyFields.includes(stateField);
    }
  };
  return (
    <Flex className={styles.mainContainer}>
      {/* Hi console.log('stateData - locations', stateData); return ( */}
      <div className={[styles.container, containerClassNames].join(' ')}>
        <div className={styles.heading}> {header}</div>
        <div className={styles.bodyContainer}>
          <div className={[styles.gridOfTwo, styles.divider].join(' ')}>
            <div>
              {formLeftFields.map((elem, idx) => {
                return (
                  <div
                    key={`formLeftFields-${idx}`}
                    className={[styles.divider, styles.formFields].join(' ')}>
                    <FormElement
                      type={elem.elementType}
                      label={elem.label}
                      required={elem.required}
                      placeholderText={elem.placeholder}
                      onChange={text => onChangeHandler(elem.stateField, text)}
                      tabIndex={idx * 2 + 1}
                      error={stateData[elem.stateField].error}
                      // options={stateData[elem.stateField].optionsField}
                      value={stateData[elem.stateField].value}
                      key={idx}
                      maxLength={elem.maxLength}
                      keyboardType={elem.keyboardType}
                      containerStyle={{marginBotton: '40px'}}
                      // handleUpload={(file) => handleUpload(elem.stateField, file)}
                      maxFiles={elem.maxFiles}
                      onBlur={() => onBlur(elem.stateField, elem)}
                      allowedFormats={elem.allowedFormats}
                      minimumDate={elem.minimumDate}
                      isVerifyOtp={elem.verifyOtp}
                      fieldName={elem.stateField}
                      containerClassNames={styles.containerClassNames}
                      labelClassNames={styles.labelClassNames}
                      disabled={checkDisabled(elem.stateField)}
                    />
                  </div>
                );
              })}
            </div>
            <div>
              {formRightFields.map((elem, idx) => {
                return (
                  <div
                    key={`formLeftFields-${idx}`}
                    className={[styles.divider, styles.formFields].join(' ')}>
                    <FormElement
                      type={elem.elementType}
                      label={elem.label}
                      required={elem.required}
                      placeholderText={elem.placeholder}
                      onChange={text => onChangeHandler(elem.stateField, text)}
                      tabIndex={idx * 2 + 1}
                      error={stateData[elem.stateField].error}
                      // options={stateData[elem.stateField].optionsField}
                      options={stateData[elem.optionsField]}
                      value={stateData[elem.stateField].value}
                      key={idx}
                      maxLength={elem.maxLength}
                      keyboardType={elem.keyboardType}
                      // containerStyle={{marginBotton: 40 * vh}}
                      // handleUpload={(file) => handleUpload(elem.stateField, file)}
                      maxFiles={elem.maxFiles}
                      onBlur={() => onBlur(elem.stateField, elem)}
                      allowedFormats={elem.allowedFormats}
                      minimumDate={elem.minimumDate}
                      fieldName={elem.stateField}
                      containerClassNames={styles.containerClassNames}
                      labelClassNames={styles.labelClassNames}
                      disabled={checkDisabled(elem.stateField)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <Flex direction="column" className={styles.shifts}>
            {shiftRows?.map((elem, idx) => {
              console.log('shiftRows!', elem);

              return (
                <ShiftRows
                  onShiftChange={onShiftChange}
                  elem={elem}
                  idx={idx}
                />
              );
            })}
          </Flex>
        </div>

        {buttons && buttons.length > 0 ? (
          <div className={[styles.buttonContainer, buttonContainer].join(' ')}>
            {buttons.map(button => {
              const {text, buttonStyles, ...remainingProps} = button;
              return (
                <button
                  className={[styles.button, buttonStyles].join(' ')}
                  key={'popup-button-' + text}
                  {...remainingProps}>
                  {text}
                </button>
              );
            })}
          </div>
        ) : null}
      </div>
    </Flex>
  );
}
