import React, {Component} from 'react';
import PopupContainer from '../../../../components/PopupContainer';
import Popup from '../../../../components/Popup';
import {withRouter} from 'react-router-dom';

import ViewRenderer from './views';
import Spinner from '../../../../components/Spinner';
import {connect} from 'react-redux';
import {API} from '../../../../requests';
import {commonImages} from '../../../../assets/images';
import strings from '../../../../globalization';

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  ERROR: 'error',
  SPINNER_POPUP: 'spinner',
};
class AvailableCreditsWallet extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      selectedStatus: this.props.status,
      searchText: '',
      passes: [],
      filteredPasses: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.showSpinner();
    API.getAllPasses().then(res => {
      console.log('res...getAllPasses', res.data);
      this.setState({
        passes: res.data,
        popup: null,
        filteredPasses: res.data,
      });
    });
  };

  showSuccessPopup = successMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: successMessage,
        onClose: this.closePopup,
        headingImage: commonImages.successIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  showErrorPopup = errorMsg => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMsg,
        messageStyle: {color: '#E67717'},

        buttons: [
          {
            text: strings.cancelPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
      },
    });
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  onChangeHandler = (action, data) => {
    switch (action) {
      case 'search': {
        console.info('data..', data);
        this.setState({
          searchText: data,
        });
        this.applyFilter(data);

        break;
      }

      default: {
        console.info('case not handled', action);
      }
    }
  };

  applyFilter = searchText => {
    const {passes} = this.state;
    console.log('passes', passes);

    let filtered = passes.filter(elem =>
      elem.name.toLowerCase().includes(searchText.toLowerCase()),
    );
    this.setState({
      filteredPasses: filtered,
    });
  };

  onClear = () => {
    const {passes} = this.state;
    this.setState({
      filteredPasses: passes,
    });
  };

  /**
   *
   * @param {*} action
   * @param {*} data
   */

  onClickHandler = (action, data) => {
    const {history, location, status} = this.props;
    switch (action) {
      case 'statusRoute': {
        //FIXME
        history.push({
          pathname: '/u/wallet/' + data.route,
        });

        break;
      }
      case 'buy-credits': {
        //FIXME
        history.push({
          pathname: '/u/wallet/buy-credits',
          state: {background: location, data: data},
        });

        break;
      }
      case 'cart-button': {
        //FIXME
        history.push({
          pathname: '/u/wallet/cart',
          state: {background: location, data: data},
        });

        break;
      }
      case 'clear-filters': {
        this.onClear();

        break;
      }
      case 'refresh': {
        this.getData();
        break;
      }
      default: {
        console.info('case not handled', action);
      }
    }
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };

  getProps = () => {
    const {
      cart: {cart},
    } = this.props.reduxProps;
    return {
      onClick: this.onClickHandler,
      selectedStatus: this.state.selectedStatus,
      onChange: this.onChangeHandler,
      searchText: this.state.searchText,
      cart: cart,
      passes: this.state.passes,
      filteredPasses: this.state.filteredPasses,
    };
  };

  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      // case "spinner": {
      //   console.log('showing spinner');
      //   return <Spinner />;
      // }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.SPINNER: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

const mapStateToProps = reduxProps => ({
  reduxProps,
});

export default withRouter(
  connect(mapStateToProps, null)(AvailableCreditsWallet),
);
