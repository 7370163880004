import strings from '../../../../globalization';
import moment from 'moment';

const showPerviousDate = new Date().getHours() < 8;
export const getFormData = () => {
  return [
    {
      elementType: 'date',
      keyboardType: 'default',
      inputType: 'text',
      label: 'Permit Start Date',
      placeholder: 'Select Permit Start Date',
      required: true,
      stateField: 'visitingDate',
      stateDefault: null,
      maxLength: 20,
      minimumDate: showPerviousDate
        ? moment(new Date()).subtract(1, 'day').toDate()
        : new Date(),
    },
    {
      elementType: 'dropdown',
      keyboardType: 'default',
      inputType: 'text',
      label: 'Purpose of Work',
      placeholder: 'Select Purpose of Work',
      required: true,
      stateField: 'purposeOfWork',
      optionsField: 'purposeOfWorkOptions',
      stateDefault: null,
    },
    // {
    //   elementType: 'dropdown',
    //   keyboardType: 'default',
    //   inputType: 'text',
    //   label: 'Department',
    //   placeholder: 'Select Department',
    //   required: true,
    //   stateField: 'department',
    //   optionsField: 'departmentOptions',
    //   stateDefault: null,
    // },
    // {
    //   elementType: 'dropdown',
    //   keyboardType: 'default',
    //   inputType: 'text',
    //   label: 'Areas of Work',
    //   placeholder: 'Select Areas of Work',
    //   required: true,
    //   stateField: 'areasOfWork',
    //   optionsField: 'areasOfWorkOptions',
    //   stateDefault: null,
    // },
    {
      elementType: 'dropdown',
      keyboardType: 'default',
      inputType: 'text',
      label: 'Pass Validity',
      placeholder: 'Select pass validity',
      required: true,
      stateField: 'passValidityPeriod',
      optionsField: 'passValidityPeriodOptions',
      stateDefault: null,
    },
    // {
    //   elementType: 'file',
    //   inputType: 'upload',
    //   label: 'Police Verification Document',
    //   placeholder: 'choose file (Max: 2 MB)',
    //   required: true,
    //   stateField: 'policeVerificationDocument',
    //   stateDefault: [],
    //   maxFiles: 1,
    //   allowedFormats: ['pdf', 'images'],
    // },
  ];
};
