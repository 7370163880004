export const videos = [
  {
    id: 0,
    title: 'Person Registration',
    date: '21 Nov, 2022',
    videoLength: '5:04',
    source: 'https://edrishti.sanya.ai/user-manual/PersonRegistrationWeb.mov',
  },
  {
    id: 1,
    title: 'Vehicle Registration',
    date: '21 Nov, 2022',

    videoLength: '5:07',

    source: 'https://edrishti.sanya.ai/user-manual/VehicleRegistrationWeb.mov',
  },
  {
    id: 2,
    title: 'Wallet Usage',
    date: '21 Nov, 2022',

    videoLength: '1:43',

    source: 'https://edrishti.sanya.ai/user-manual/WalletRechargeWeb.mov',
  },
  {
    id: 3,
    title: 'Raising A Permit',
    date: '21 Nov, 2022',

    videoLength: '1:20',

    source:
      'https://edrishti.sanya.ai/user-manual/RaisePermitWebUpdateflow.mov',
  },
  {
    id: 4,
    title: 'Check Permit Status With Permit',
    date: '28 Feb, 2022',

    videoLength: '0:33',

    source:
      'https://edrishti.sanya.ai/user-manual/MobileVideoUserGuide/CheckPermitStatusWithPermit.mov',
  },
  {
    id: 5,
    title: 'Check Permit Status Without Permit',
    date: '28 Feb, 2022',

    videoLength: '0:23',

    source:
      'https://edrishti.sanya.ai/user-manual/MobileVideoUserGuide/CheckPermitStatusWithoutPermit.mov',
  },
];

export const mobileVideos = [
  {
    id: 0,
    title: 'Person Registration',
    date: '21 Nov, 2022',
    videoLength: '2:43',
    source:
      'https://edrishti.sanya.ai/user-manual/MobileVideoUserGuide/Person-Registration.mp4',
  },
  {
    id: 1,
    title: 'Vehicle Registration',
    date: '21 Nov, 2022',

    videoLength: '3:28',

    source:
      'https://edrishti.sanya.ai/user-manual/MobileVideoUserGuide/Vehicle-Registration.mp4',
  },
  {
    id: 2,
    title: 'Wallet Usage',
    date: '21 Nov, 2022',

    videoLength: '1:35',

    source:
      'https://edrishti.sanya.ai/user-manual/MobileVideoUserGuide/Wallet-Reacharge.mp4',
  },
  {
    id: 3,
    title: 'Raising A Permit',
    date: '21 Nov, 2022',

    videoLength: '1:58',

    source:
      'https://edrishti.sanya.ai/user-manual/RaisePermitAndroidUpdateflow.mov',
  },
  {
    id: 4,
    title: 'Check Permit Status With Permit',
    date: '28 Feb, 2022',

    videoLength: '0:26',

    source:
      'https://edrishti.sanya.ai/user-manual/MobileVideoUserGuide/CheckPermitStatusWithPermit-Android.mov',
  },
  {
    id: 5,
    title: 'Check Permit Status Without Permit',
    date: '28 Feb, 2022',

    videoLength: '0:26',

    source:
      'https://edrishti.sanya.ai/user-manual/MobileVideoUserGuide/CheckPermitStatus-WithoutPermit-Android.mov',
  },
];
