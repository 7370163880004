import {ActionTypes} from '../constants';

export function setLoginData(payload) {
  return {
    type: ActionTypes.SET_LOGIN_DATA,
    payload,
  };
}

export function logoutAction(payload) {
  return {
    type: ActionTypes.LOGOUT,
    payload,
  };
}

export function updateLanguage(payload) {
  return {
    type: ActionTypes.UPDATE_LANGUAGE,
    payload,
  };
}

export function updateUserData(payload) {
  return {
    type: ActionTypes.UPDATE_USER_DATA,
    payload,
  };
}
export function updateCompanyData(payload) {
  return {
    type: ActionTypes.UPDATE_COMPANY_DATA,
    payload,
  };
}

export function setAuthenticatedUser(payload) {
  return {
    type: ActionTypes.SET_AUTHENTICATED_USER,
    payload,
  };
}
