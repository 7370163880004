import React from 'react';
import { useRouteMatch, Switch, Route, useLocation } from 'react-router-dom';
import SideBar from '../components/SideBar';
import { useMediaQuery } from 'react-responsive';
import styles from './styles.module.css';
import AgencyRquests from '../pages/Admin/AgencyRquests';
import AgencyPermitRequests from '../pages/Department/AgencyPermitRequests';
import TrafficDeptPermitRequests from '../pages/Department/TrafficDeptPermitRequests';

import AgencyRegistrationRequests from '../pages/Department/AgencyRegistrationRequests';

import PermitRquests from '../pages/Admin/PermitRquests';
import { cisfTabs, deptTabs, trafficDeptTabs } from './tabs';
import UsersList from '../pages/Department/UserManagement/UsersList';
import AddUser from '../pages/Department/UserManagement/AddUser';
import ViewDocument from '../components/ViewDocumentView';
import PopupContainer from '../components/PopupContainer';
import ValidateRegistrations from '../pages/Department/ValidateRegistrations';
import History from '../components/operator/History';
// import Profile from '../pages/Department/Profile';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Datasheet from '../pages/Department/Datasheets';
import TrafficDepartmentDashboard from '../pages/Department/TrafficDepartmentDashboard1';
import Profile from '../pages/Department/Profile';
import RejectionReports from '../pages/Department/RejectionReports';
import PersonMovementReports from '../pages/Department/PersonMovementReports';
import PersonView from '../pages/Department/ViewPersonVehicleDetails/PersonView';
import VehicleView from '../pages/Department/ViewPersonVehicleDetails/VehicleView';
import AgencyView from '../pages/Department/ViewPersonVehicleDetails/AgencyView';

import PermitsRaised from '../pages/Department/PermitsRaised';

import DailyProgressMonitoring from '../pages/Department/DailyProgressMonitoring';
import TrafficDepartmentDashboardV2 from '../pages/Department/TrafficDepartmentDashboard2';
import DownloadPermit from '../pages/Department/DownloadGraph';
import DownloadPermitPerson from '../pages/Department/DownloadGraphPerson';

import CISFDepartmentDashboard from '../pages/Department/CISFDepartmentDashboard';
import TrafficDepartmentPersonDashboard from '../pages/Department/TrafficDepartmentPersonDashboard';

/**
 * @return {JSX.Element}
 */
function DeptRouter(props) {
  const { url } = useRouteMatch();
  console.info('url', url);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  let location = useLocation();
  let background = location.state && location.state.background;
  const { reduxProps } = props;
  const {
    login: { loginInfo },
  } = reduxProps;
  const { company, user } = loginInfo;
  console.log(user);
  const onProfileClick = () => {
    const { history } = props;
    history.push(`${url}/profile`);
  };
  let tabs = deptTabs;
  if (user?.departmentId === 3) {
    tabs = trafficDeptTabs;
  } else if (user?.departmentId === 10) {
    tabs = cisfTabs;
  } else {
    tabs = deptTabs;
  }
  return (
    <div className={styles.container}>
      <div className={styles.body}>
        {!isMobile ? (
          <div className={styles.sideBar}>
            <SideBar
              module={'department'}
              header={'Permit Requests'}
              tabs={tabs}
            />
          </div>
        ) : null}
        <div className={styles.relative}>
          <div className={styles.bodyWrapper}>
            <Switch>
              {user?.departmentId === 3 ? (
                <Route path={`${url}/dashboard`}>
                  <TrafficDepartmentDashboardV2 />
                </Route>
              ) : (
                <Route path={`${url}/dashboard`}>
                  <CISFDepartmentDashboard />
                </Route>
              )}
              <Route path={`${url}/person-dashboard`}>
                <TrafficDepartmentPersonDashboard />
              </Route>
              {/* {user?.departmentId === 10 ? (
                <Route path={`${url}/dashboard`}>
                  <CISFDepartmentDashboard />
                </Route>
              ) : null} */}
              {/* <Route
                key="print-graph-department"
                exact
                path={`${url}/dashboard/download-graph`}
                children={<DownloadPermit />}
              /> */}
              {user?.departmentId === 3 ? (
                <Route path={`${url}/agency-permit-requests-traffic`}>
                  <TrafficDeptPermitRequests />
                </Route>
              ) : null}
              <Route path={`${url}/agency-permit-requests`}>
                <AgencyPermitRequests />
              </Route>
              <Route path={`${url}/profile`}>
                <Profile />
              </Route>

              <Route
                key="pending"
                exact
                path={`${url}/agency-registrations/pending-permits`}>
                <AgencyRegistrationRequests status={'PENDING'} />
              </Route>
              <Route
                key="approved"
                exact
                path={`${url}/agency-registrations/approved-rejected-permits`}>
                <AgencyRegistrationRequests status={'APPROVED'} />
              </Route>

              <Route path={`${url}/agency-registrations`}>
                <AgencyRegistrationRequests />
              </Route>
              <Route path={`${url}/user-management`}>
                <UsersList status={'PENDING'} />
              </Route>
              <Route path={`${url}/datasheet`}>
                <Datasheet />
              </Route>
              <Route path={`${url}/daily-progress-monitoring`}>
                <DailyProgressMonitoring />
              </Route>
              <Route path={`${url}/permits-raised`}>
                <PermitsRaised />
              </Route>
              {user?.departmentId === 3 ? (
                <Route path={`${url}/validate-registrations`}>
                  <ValidateRegistrations />
                </Route>
              ) : (
                <Route path={`${url}/validate-registrations`}>
                  <ValidateRegistrations />
                </Route>
              )}
              {/* {user?.departmentId === 10 ? (
                <Route path={`${url}/validate-registrations`}>
                  <ValidateRegistrations />
                </Route>
              ) : null} */}

              <>
                <Route path={`${url}/vehicle-movement`}>
                  <RejectionReports />
                </Route>
                <Route
                  key="viewDocument8"
                  exact
                  path={`${url}/vehicle-movement/document-view`}
                  children={
                    <PopupContainer containerStyle={styles.popupContainerStyle}>
                      <ViewDocument />
                    </PopupContainer>
                  }
                />
                <Route path={`${url}/person-movement`}>
                  <PersonMovementReports />
                </Route>
                <Route
                  key="viewDocument5"
                  exact
                  path={`${url}/view/document-view`}
                  children={
                    <PopupContainer containerStyle={styles.popupContainerStyle}>
                      <ViewDocument />
                    </PopupContainer>
                  }
                />

                <Route
                  key="viewDocument6"
                  exact
                  path={`${url}/view/person-details/document-view`}
                  children={
                    <PopupContainer containerStyle={styles.popupContainerStyle}>
                      <ViewDocument />
                    </PopupContainer>
                  }
                />
                <Route
                  key="viewDocument7"
                  exact
                  path={`${url}/view/vehicle-details/document-view`}
                  children={
                    <PopupContainer containerStyle={styles.popupContainerStyle}>
                      <ViewDocument />
                    </PopupContainer>
                  }
                />
                <Route
                  key="viewDocument8"
                  exact
                  path={`${url}/view/agency-details/document-view`}
                  children={
                    <PopupContainer containerStyle={styles.popupContainerStyle}>
                      <ViewDocument />
                    </PopupContainer>
                  }
                />
                <Switch>
                  <Route key="person" path={`${url}/view/person-details`}>
                    <PersonView />
                  </Route>
                  <Route key="vehicle" path={`${url}/view/vehicle-details`}>
                    <VehicleView />
                  </Route>
                  <Route key="agency" path={`${url}/view/agency-details`}>
                    <AgencyView />
                  </Route>
                  <Route key="person-1" path={`${url}/view`}>
                    <PersonView />
                  </Route>
                </Switch>
              </>
            </Switch>
            {background && (
              <>
                <Route
                  key="addUser"
                  exact
                  path={`${url}/user-management/add-new-user`}
                  children={<AddUser status={'ADD'} />}
                />
                <Route
                  key="editUser"
                  exact
                  path={`${url}/user-management/edit-user`}
                  children={<AddUser status={'EDIT'} />}
                />
                <Route
                  key="deleteUser"
                  exact
                  path={`${url}/user-management/delete-user`}
                  children={<AddUser status={'DELETE'} />}
                />
                <Route
                  key="viewDocument"
                  exact
                  path={`${url}/agency-registrations/document-view`}
                  children={
                    <PopupContainer containerStyle={styles.popupContainerStyle}>
                      <ViewDocument />
                    </PopupContainer>
                  }
                />
                <Route
                  key="viewDocument2"
                  exact
                  path={`${url}/agency-permit-requests/document-view`}
                  children={
                    <PopupContainer containerStyle={styles.popupContainerStyle}>
                      <ViewDocument />
                    </PopupContainer>
                  }
                />
                <Route
                  key="viewDocument3"
                  exact
                  path={`${url}/agency-permit-requests-traffic/document-view`}
                  children={
                    <PopupContainer containerStyle={styles.popupContainerStyle}>
                      <ViewDocument />
                    </PopupContainer>
                  }
                />
                <Route
                  key="viewDocument4"
                  exact
                  path={`${url}/validate-registrations/document-view`}
                  children={
                    <PopupContainer containerStyle={styles.popupContainerStyle}>
                      <ViewDocument />
                    </PopupContainer>
                  }
                />
                <Route
                  key="viewHistory"
                  exact
                  path={`${url}/validate-registrations/view-history`}
                  children={
                    <PopupContainer containerStyle={styles.popupContainerStyle}>
                      <History />
                    </PopupContainer>
                  }
                />
                {user?.departmentId === 3 || user?.departmentId === 10 ? (
                  <Route
                    key="print-graph-department"
                    exact
                    path={`${url}/dashboard/download-graph`}
                    children={<DownloadPermit />}
                  />
                ) : null}
                <Route
                  key="print-graph-department"
                  exact
                  path={`${url}/dashboard/download-person-graph`}
                  children={<DownloadPermitPerson />}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = reduxProps => ({
  reduxProps,
});

export default withRouter(connect(mapStateToProps, null)(DeptRouter));
// export default DeptRouter;
