import React, {PureComponent} from 'react';

import Select from 'react-select';

import 'react-datasheet/lib/react-datasheet.css';
import './styles.css';

import Popup from '../../../components/SimplePopup';
import Spinner from '../../../components/Spinner';
import moment from 'moment';
import {importDropdownOptions, gridConfig, exportDropdownOptions} from './data';
import PopupContainer from '../../../components/PopupContainer';
import ViewRenderer from './views';
import {commonImages, operatorImages} from '../../../assets/images';
import strings from '../../../globalization';
import {API} from '../../../requests';
import util from '../../../util';

const ENTER_KEY = 13;
const TAB_KEY = 9;

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  ERROR: 'error',
  SPINNER_POPUP: 'spinner',
  PERMIT_DETAILS: 'permitDetails',
};
const TYPES = {
  IMPORT: 'import',
  EXPORT: 'export',
};

const Header = props => {
  const {col, isOver} = props;
  const className = 'cell read-only';
  return (
    <th
      className={className}
      style={{
        width: col.width,
        fontSize: '0.8rem',
        paddingRight: '10px',
        backgroundColor: '#e6edff',
        color: '#000',
      }}>
      {col.label}
    </th>
  );
};

class SheetRenderer extends PureComponent {
  render() {
    const {className, columns} = this.props;
    return (
      <table className={className}>
        <thead>
          <tr style={{background: '#E8EAED', textAlign: 'left'}}>
            <th className="cell read-only row-handle" key="$$actionCell" />
            {columns.map((col, index) => (
              <Header key={col.label} col={col} columnIndex={index} />
            ))}
          </tr>
        </thead>
        <tbody>{this.props.children}</tbody>
      </table>
    );
  }
}

const RowRenderer = props => {
  const {children, rowIndex} = props;
  const className = '';
  return (
    <tr className={className}>
      <td
        className="cell read-only row-handle"
        key="$$actionCell"
        style={{fontSize: '0.6rem', paddingRight: '4px', paddingLeft: '4px'}}>
        {rowIndex + 1}
      </td>
      {children}
    </tr>
  );
};

class SelectEditor extends PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.state = {};
  }
  componentDidMount() {}

  handleChange(opt) {
    const {onCommit, onRevert} = this.props;

    if (!opt) {
      return onRevert();
    }
    const {e} = this.state;
    onCommit(opt.label, e); // FIXME: Take value here
    console.log('COMMITTED', opt.label);
  }

  handleKeyDown(e) {
    // record last key pressed so we can handle enter
    if (e.which === ENTER_KEY || e.which === TAB_KEY) {
      e.persist();
      this.setState({e});
    } else {
      this.setState({e: null});
    }
  }

  render() {
    return (
      <Select
        autoFocus
        openOnFocus
        closeOnSelect
        value={this.props.value}
        onChange={this.handleChange}
        onInputKeyDown={this.handleKeyDown}
        options={this.props.cell.options}
      />
    );
  }
}

const SelectViewer = props => {
  const {value} = props;
  return (
    <div>
      {value}{' '}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="2"
        style={{height: '10px', width: '10px', color: 'gray'}}>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17 13l-5 5m0 0l-5-5m5 5V6"
        />
      </svg>
    </div>
  );
};
const DateViewer = props => {
  const {value} = props;
  return <div>{value ? moment(value).format('DD/MM/YYYY') : ''} </div>;
};

class DateEditor extends PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }
  componentDidMount() {
    this._input.focus();
  }

  handleChange(e) {
    this.props.onChange(e.target.value);
  }
  handleKeyDown(e) {
    const {onCommit} = this.props;
    // record last key pressed so we can handle enter
    if (e.which === ENTER_KEY || e.which === TAB_KEY) {
      onCommit(e.target.value, e);
    }
  }

  render() {
    return (
      <input
        type="date"
        ref={input => {
          this._input = input;
        }}
        className="data-editor"
        value={this.props.value}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
      />
    );
  }
}
const InputViewer = props => {
  const {value} = props;
  return <div style={{}}>{value}</div>;
};
class InputEditor extends PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }
  componentDidMount() {
    this._input.focus();
  }

  handleChange(e) {
    this.props.onChange(e.target.value);
  }
  handleKeyDown(e) {
    const {onCommit} = this.props;
    // record last key pressed so we can handle enter
    if (e.which === ENTER_KEY || e.which === TAB_KEY) {
      onCommit(e.target.value, e);
    }
  }

  render() {
    return (
      <input
        type="text"
        ref={input => {
          this._input = input;
        }}
        className="data-editor"
        value={this.props.value}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
      />
    );
  }
}
class InputEditorNumber extends PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }
  componentDidMount() {
    this._input.focus();
  }

  handleChange(e) {
    console.log('input editor change...', e.target.value);
    if (+e.target.value >= 0) {
      this.props.onChange(+e.target.value);
    } else {
      this.props.onChange('');
    }
  }
  handleKeyDown(e) {
    const {onCommit} = this.props;
    // record last key pressed so we can handle enter
    if (e.which === ENTER_KEY || e.which === TAB_KEY) {
      onCommit(e.target.value, e);
    }
  }

  render() {
    return (
      <input
        type="number"
        ref={input => {
          this._input = input;
        }}
        className="data-editor"
        value={this.props.value}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
      />
    );
  }
}
class Datasheet extends PureComponent {
  constructor(props) {
    super(props);
    this.COMPONENTS_MAPPER = {
      SelectEditor: SelectEditor,
      SelectViewer: SelectViewer,
      DateEditor: DateEditor,
      DateViewer: DateViewer,
      InputEditor: InputEditor,
      InputViewer: InputViewer,
      InputEditorNumber: InputEditorNumber,
    };
    // this.defaultRow = this.generateDefaultRow();

    this.state = {
      columns: gridConfig.importColumns,
      cargoTypeOptions: importDropdownOptions.cargoTypeOptions,
      cargoNameOptions: importDropdownOptions.cargoNameOptions,
      UOMOptions: importDropdownOptions.UOMOptions,
      typeOptions: [
        {
          label: TYPES.IMPORT,
          name: TYPES.IMPORT,
          value: TYPES.IMPORT,
          id: TYPES.IMPORT,
        },
        {
          label: TYPES.EXPORT,
          name: TYPES.EXPORT,
          value: TYPES.EXPORT,
          id: TYPES.EXPORT,
        },
      ],
      selectedType: TYPES.IMPORT,
      grid: [],
    };

    this.handleChanges = this.handleChanges.bind(this);
    this.renderSheet = this.renderSheet.bind(this);
    this.renderRow = this.renderRow.bind(this);
  }

  componentDidMount() {
    this.populateTable();
  }

  showConfirmationPopup = (message, onClick) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: message,
        onClose: this.closePopup,
        buttons: [
          {
            text: `Close`,
            onClick: this.closePopup,
            outline: true,
          },
          {
            text: strings.okayPopup,
            onClick: onClick,
            outline: true,
          },
        ],
      },
      pageStatus: '',
    });
  };
  closePopup = () => {
    this.setState({
      popup: null,
    });
  };

  populateTable = () => {
    const {selectedType} = this.state;

    this.showSpinner();

    API.fetchGroundStockData()
      .then(res => {
        console.log('res...', res);
        const data =
          selectedType === TYPES.IMPORT ? res.data.import : res.data.export;

        if (data.length > 0) {
          let assignedGrid = this.prepareGrid([...data, {}], 'bodyKey');

          this.setState(
            {
              grid: assignedGrid,
              popup: null,
            },
            function () {
              // console.log('grid...component did mount', this.state.grid);
            },
          );
        } else {
          let grid = this.prepareGrid(new Array(5).fill({}), 'bodyKey');
          this.setState({
            grid: grid,
            popup: null,
          });
        }
      })
      .catch(err => {
        console.log(err);
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };

  prepareGrid = (data, valueKey) => {
    const {selectedType} = this.state;
    let grid = [];
    let columns =
      selectedType === TYPES.IMPORT
        ? gridConfig.importColumns
        : gridConfig.exportColumns;
    let options =
      selectedType === TYPES.IMPORT
        ? importDropdownOptions
        : exportDropdownOptions;

    data.forEach((row, index) => {
      let rowArr = this.createRow(row, index, {options, columns, valueKey});

      grid.push(rowArr);
    });

    return grid;
  };

  createRow = (row, index, rowOptions) => {
    const {valueKey, options, columns} = rowOptions || {};

    let rowArr = [];

    // construct row from data
    columns.forEach((col, colIndex) => {
      let cell = {};
      cell.key = index + '-' + colIndex;
      cell.value = row[col[valueKey]];
      cell.bodyKey = col.bodyKey;
      if (col.bodyKey === 'id') {
        cell.backendId = cell.value;
        cell.value = index + 1;
      }
      if (col.type === 'select') {
        cell.dataEditor = SelectEditor;
        cell.valueViewer = SelectViewer;
      } else if (col.type === 'date') {
        cell.dataEditor = DateEditor;
        cell.valueViewer = DateViewer;
        // if value is a valid moment date then put the else condition
      } else if (col.type === 'input') {
        cell.dataEditor = InputEditor;
        cell.valueViewer = InputViewer;
      } else if (col.type === 'inputNumber') {
        cell.dataEditor = InputEditorNumber;
        cell.valueViewer = InputViewer;
        cell.value = isNaN(Number(cell.value)) ? '' : Number(cell.value);
      }

      if (col.options) {
        cell.options = options[col.options];
      }

      cell.hidden = col.hidden;

      rowArr.push(cell);
    });
    return rowArr;
  };
  beforeUnloadFunction = e => {
    e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    // Chrome requires returnValue to be set
    e.returnValue = '';
  };

  handleChanges(changes) {
    console.log('changes', changes);
    // if window.onbeforeunload is not defined then add handler
    if (!window.onbeforeunload) {
      window.onbeforeunload = e => this.beforeUnloadFunction(e);
    }

    const grid = this.state.grid.map(row => [...row]);
    changes.forEach(({cell, row, col, value}) => {
      let tempColIdx = col + 1;
      if (grid[row] && grid[row][tempColIdx]) {
        grid[row][tempColIdx] = {...grid[row][tempColIdx], value};
      }
    });
    this.setState({grid});
  }

  renderSheet(props) {
    return <SheetRenderer columns={this.getColumns()} {...props} />;
  }

  getColumns() {
    const {selectedType} = this.state;
    return selectedType === TYPES.IMPORT
      ? gridConfig.importColumns.filter(el => !el.hidden)
      : gridConfig.exportColumns.filter(el => !el.hidden);
  }

  renderRow(props) {
    const {row, cells, ...rest} = props;
    return <RowRenderer rowIndex={row} {...rest} />;
  }
  addRow = () => {
    const {selectedType} = this.state;
    let columns =
      selectedType === TYPES.IMPORT
        ? gridConfig.importColumns
        : gridConfig.exportColumns;

    let options =
      selectedType === TYPES.IMPORT
        ? importDropdownOptions
        : exportDropdownOptions;

    let defaultRow = this.createRow({}, this.state.grid.length, {
      options,
      columns,
      valueKey: 'bodyKey',
    });

    const grid = [...this.state.grid, defaultRow];
    console.log('defaultRow...', defaultRow);
    console.log('grid...', grid);

    this.setState({
      grid: grid,
    });
  };

  // FIXME: Add a validation for empty string case (should not take spaces)
  isEmpty = cell => {
    // return cell.value === undefined || cell.value === null || cell.value === '';
    // !cell.value.replace(/\r?\n|\r/g, '')
    // return (!cell.value && typeof cell.value === 'string');
    if (!cell.value) {
      return true;
    }
    if (typeof cell.value === 'string') {
      return !cell.value.replace(/\r?\n|\r/g, '').trim();
    }

    return false;
  };

  sanitizedGridRows = grid => {
    return grid.filter(row => {
      let isEmpty = true;

      for (let i = 0; i < row.length; i++) {
        let cell = row[i];
        if (!cell.hidden && !this.isEmpty(cell)) {
          isEmpty = false;
          break;
        }
      }

      return !isEmpty;
    });
  };

  save = () => {
    const {selectedType, grid} = this.state;

    let sanitizedGrid = this.sanitizedGridRows(grid);

    const requestBody = sanitizedGrid.map((row, rowIndex) => {
      let rowObj = {};
      row.forEach((cell, cellIndex) => {
        if (cell.bodyKey === 'id') {
          if (cell.backendId) {
            rowObj[cell.bodyKey] = cell.backendId;
          } else {
            rowObj[cell.bodyKey] = rowIndex + 1;
          }
        } else {
          rowObj[cell.bodyKey] = cell.value;
        }
      });
      return rowObj;
    });

    this.showSpinner();
    console.log('requestBody...', requestBody);
    API.saveGroundStock(requestBody, selectedType)
      .then(res => {
        this.showSuccessPopup(`Data saved successfully!`);
      })
      .catch(err => {
        console.log(err);
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });

    window.onbeforeunload = undefined;
  };
  handleProfileClick = () => {};
  onProfileClick = () => {};

  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SPINNER: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };
  showSuccessPopup = successMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: successMessage,
        onClose: this.closePopup,
        headingImage: operatorImages.successIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  // give me a tweet that can engage my audience

  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };
  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };
  onChangeHandler = (field, value) => {
    console.log('this.state.selectedType...', this.state.selectedType);
    if (field === 'selectedType') {
      this.showConfirmationPopup(
        'Are you sure you want to switch tabs? Unsaved data will be lost.',
        () => {
          this.setState(
            {
              [field]: value,
            },
            this.populateTable,
          );
        },
      );
    } else {
      this.setState({
        [field]: value,
      });
    }
  };
  onCsvUpload = dataWithHeaders => {
    const {selectedType, grid} = this.state;
    const receivedHeaders = dataWithHeaders.headers;
    const requiredHeaders = this.getColumns().map(col => col.label);
    const data = dataWithHeaders.csvArray;

    let columns =
      selectedType === TYPES.IMPORT
        ? gridConfig.importColumns
        : gridConfig.exportColumns;

    let options =
      selectedType === TYPES.IMPORT
        ? importDropdownOptions
        : exportDropdownOptions;

    let defaultRow = this.createRow({}, this.state.grid.length, {
      options,
      columns,
      valueKey: 'bodyKey',
    });

    // sanitize data
    // remove carriage returns or line breaks in receivedHeaders
    let sanitizedHeaders = receivedHeaders
      .map(header => {
        return header.replace(/\r?\n|\r/g, '');
      })
      .filter(el => !!el);

    console.log('receivedHeaders', sanitizedHeaders);
    console.log('requiredHeaders', requiredHeaders);
    console.log('data received from CSV file', data);
    // compare both the above arrays, return false if both dont have same length or dont have all the values same
    if (
      sanitizedHeaders.length !== requiredHeaders.length ||
      !sanitizedHeaders.every(header => requiredHeaders.includes(header))
    ) {
      console.log('error...', {sanitizedHeaders, requiredHeaders});
      this.showErrorPopup(
        `Headers do not match. Please try again with proper table headers.`,
      );
      return;
    }

    let assignedGrid = this.prepareGrid(data, 'label');
    // console.log('assignedGrid in onCsvUpload...', assignedGrid);

    let newGrid = this.sanitizedGridRows([...grid, ...assignedGrid]);
    console.log('assignedGrid in onCsvUpload...', assignedGrid);

    this.setState({
      grid: [...newGrid, defaultRow],
      // popup: null,
    });
    if (!window.onbeforeunload) {
      window.onbeforeunload = e => this.beforeUnloadFunction(e);
    }
  };

  getProps = () => {
    const {grid, typeOptions, selectedType} = this.state;
    return {
      data: grid.map(row => row.slice(1)),
      handleProfileClick: this.handleProfileClick,
      onProfileClick: this.onProfileClick,
      renderSheet: this.renderSheet,
      renderRow: this.renderRow,
      handleChanges: this.handleChanges,
      addRow: this.addRow,
      save: this.save,
      typeOptions,
      selectedType,
      onChange: this.onChangeHandler,
      onCsvUpload: this.onCsvUpload,
    };
  };
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

export default Datasheet;
