import React from 'react';

import styles from './styles.module.scss';
import CustomInput from '../CustomInput';
import CustomDropdown from '../CustomDropdown';
import PhoneInput from '../PhoneInput';
import CustomUpload from '../CustomUpload';
import CustomDate from '../CustomDate';
import CustomTimePicker from '../CustomTimePicker';
import UploadPhotoInput from '../UploadPhotoInput';
import CustomDatePackage from '../CustomDatePackage';
import CheckText from '../CheckText';

const FormElement = ({
  type,
  label,
  placeholderText,
  options,
  dropdownContainerStyle,
  onChange,
  required = false,
  value,
  keyboardType,
  maxLength = 10,
  maxSize = 2,
  containerStyle,
  handleUpload,
  maxFiles,
  minimumDate,
  maximumDate,
  disabled,
  onPress,
  error,
  onBlur,
  tabIndex,
  allowedFormats,
  triggerOTP,
  otpVerified,
  isVerifyOtp,
  fieldName,
  otpVerifiedField,
  defaultValue,
  uploadBoxClassNames,
  containerClassNames,
  labelClassNames,
  onRightIconClick,
  fileIcon,
  triggerPicturePopup,
  dropdownStyle,
  noOptionsText,
  uppercase,
  stateField,
  viewType,
  disableCopyPaste,
}) => {
  const getElements = () => {
    if (type === 'text') {
      return (
        <CustomInput
          inputLable={label}
          placeholder={placeholderText}
          required={required}
          value={value}
          // style={styles.input}
          onTextChange={onChange}
          maxLength={maxLength}
          fileIcon={fileIcon}
          disableCopyPaste={disableCopyPaste}
          // inputLabelStyle={styles.lables}
          // containerStyle={[styles.containerStyle, containerStyle]}
          error={error}
          onBlur={onBlur}
          tabIndex={tabIndex}
          inputBoxClassName={styles.inputBoxClassName}
          disabled={disabled}
          defaultValue={defaultValue}
          containerClassNames={containerClassNames}
          labelClassNames={labelClassNames}
          onRightIconClick={onRightIconClick}
          uppercase={uppercase}
          stateField={stateField}
          type={viewType}
        />
      );
    }
    if (type === 'checkText') {
      return (
        <CheckText
          inputLable={label}
          placeholder={placeholderText}
          required={required}
          value={value}
          // style={styles.input}
          onTextChange={onChange}
          maxLength={maxLength}
          fileIcon={fileIcon}
          // inputLabelStyle={styles.lables}
          // containerStyle={[styles.containerStyle, containerStyle]}
          error={error}
          onBlur={onBlur}
          tabIndex={tabIndex}
          inputBoxClassName={styles.inputBoxClassName}
          disabled={disabled}
          defaultValue={defaultValue}
          containerClassNames={containerClassNames}
          labelClassNames={labelClassNames}
          onRightIconClick={onRightIconClick}
          uppercase={uppercase}
        />
      );
    }
    if (type === 'phone') {
      return (
        <PhoneInput
          inputLable={label}
          placeholder={placeholderText}
          required={required}
          value={value}
          // style={styles.input}
          onTextChange={onChange}
          maxLength={maxLength}
          // inputLabelStyle={styles.lables}
          // containerStyle={[styles.containerStyle, containerStyle]}
          error={error}
          onBlur={onBlur}
          tabIndex={tabIndex}
          inputBoxClassName={styles.mobileInputBoxClassName}
          triggerOTP={triggerOTP}
          otpVerified={otpVerified}
          isVerifyOtp={isVerifyOtp}
          otpVerifiedField={otpVerifiedField}
          name={fieldName}
          disabled={disabled}
          defaultValue={defaultValue}
          containerClassNames={containerClassNames}
          labelClassNames={labelClassNames}
        />
      );
    }
    if (type === 'dropdown') {
      return (
        <CustomDropdown
          containerStyles={dropdownContainerStyle}
          required={required}
          label={label}
          placeholderText={placeholderText}
          options={options}
          onChange={onChange}
          value={value}
          error={error}
          tabIndex={tabIndex}
          // selectBoxClassName={styles.selectBoxClassName}
          dropdownStyle={dropdownStyle}
          disabled={disabled}
          defaultValue={defaultValue}
          containerClassNames={containerClassNames}
          labelClassNames={labelClassNames}
          noOptionsText={noOptionsText}
        />
      );
    }
    if (type === 'date') {
      return (
        <CustomDatePackage
          datepickerLabel={label}
          datepickerPlaceholder={placeholderText}
          required={required}
          onChange={onChange}
          value={value}
          minimumDate={minimumDate}
          maximumDate={maximumDate}
          error={error}
          tabIndex={tabIndex}
          dateClassNames={styles.dateClassNames}
          disabled={disabled}
          defaultValue={defaultValue}
          containerClassNames={containerClassNames}
          labelClassNames={labelClassNames}
        />
      );
    }
    if (type === 'file') {
      return (
        <CustomUpload
          labelText={label}
          placeholderText={placeholderText}
          required={required}
          onChange={onChange}
          handleUpload={handleUpload}
          value={value}
          maxFiles={maxFiles}
          maxSize={maxSize}
          error={error}
          allowedFormats={allowedFormats}
          tabIndex={tabIndex}
          uploadBoxClassNames={[
            uploadBoxClassNames,
            styles.uploadBoxClassNames,
          ].join(' ')}
          disabled={disabled}
          defaultValue={defaultValue}
          containerClassNames={containerClassNames}
          name={fieldName}
        />
      );
    }
    if (type === 'timepicker') {
      return (
        <CustomTimePicker
          inputLable={label}
          placeholder={placeholderText}
          required={required}
          value={value}
          onChange={onChange}
          maxLength={maxLength}
          error={error}
          onBlur={onBlur}
          tabIndex={tabIndex}
          inputBoxClassName={styles.inputBoxClassName}
          disabled={disabled}
          defaultValue={defaultValue}
          labelClassNames={labelClassNames}
          containerClassNames={containerClassNames}
        />
      );
    }

    if (type === 'uploadphoto') {
      return (
        <UploadPhotoInput
          labelText={label}
          placeholderText={placeholderText}
          required={required}
          onChange={onChange}
          handleUpload={handleUpload}
          value={value}
          maxFiles={maxFiles}
          error={error}
          allowedFormats={allowedFormats}
          tabIndex={tabIndex}
          uploadBoxClassNames={[
            uploadBoxClassNames,
            styles.uploadBoxClassNames,
          ].join(' ')}
          disable={disabled}
          defaultValue={defaultValue}
          triggerPicturePopup={triggerPicturePopup}
        />
      );
    }
  };

  return <div>{getElements()}</div>;
};

export default FormElement;
