import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { INPUT_CONSTANTS } from './data';
import TextInput from '../../../components/TextInput';
import PopupContainer from '../../../components/PopupContainer';
import Popup from '../../../components/SimplePopup';
import { configureAuth } from '../../../services/aws_service';
import Spinner from '../../../components/Spinner';

import strings from '../../../globalization';

import ViewRenderer from './views';

import { commonImages } from '../../../assets/images';
import { Metadata } from '../../../components/Metadata';
import PAGE_TITLES from '../../../constants/page-titles';
import { API } from '../../../requests';

const POPUP_CONSTANTS = {
  STANDARD_POPUP: 'standard-popup',
  ERROR_POPUP: 'error-popup',
  SPINNER_POPUP: 'spinner-popup',
  USER_NOT_CONFIRM: 'user-not-confirm',
  SIMPLE_POPUP: 'simple-popup',
};

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      userId: '',
      otp: '',
      isShowOtp: false,
    };
    configureAuth();
  }
  onChange = (action, data) => {
    console.log(action, data);
    this.setState(prevState => {
      return {
        ...prevState,
        [action]: data,
      };
    });
  };

  getInputType = element => {
    switch (element.type) {
      case INPUT_CONSTANTS.PASSWORD:
      case INPUT_CONSTANTS.NUMBER:
      case INPUT_CONSTANTS.TEXT: {
        return (
          <TextInput
            label={element.label}
            lblDirection="row"
            onChange={update => {
              this.onChange(element.field, update);
            }}
            isEdit={true}
            type={element.type}
            placeholder={element.placeholder}
            //inputStyle={styles.inputTextAddress}
            required={element.required}
            name={element.field}
            error={this.state[element.field].error}
            value={this.state[element.field]}
          />
        );
      }
      default: {
        console.warn('case not handled', element.type);
        return null;
      }
    }
  };

  /**
   *
   * @param {*} action
   * @param {*} data
   */

  onClickHandler = (action, data) => {
    switch (action) {
      case 'send-otp': {
        this.forgotPasswordHandler();
        break;
      }
      case 'change-password': {
        this.handleConfirm();
        break;
      }
      default: {
        console.info('case not handled', action);
      }
    }
  };

  forgotPasswordHandler = async () => {
    const { userId } = this.state;

    let appendedPhoneNumber = `+91${userId}`;
    const data = {
      phoneNumber: appendedPhoneNumber,
    };
    this.showSpinner();
    await API.verifyOTPForPasswordChange(data)
      .then(res => {
        this.showSuccessPopup(strings.otpSentSuccessfully);
        this.closePopup();
        this.showSuccessPopup('An OTP will be sent to the provided number!');
        this.setState({
          userId: userId,
          isShowOtp: true,
        });
      })
      .catch(error => {
        console.error('error', error, error.request);
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        this.closePopup();
        this.showErrorPopup(errorMsg);
      });
    // this.showErrorPopup(message);
  };

  handleConfirm = async () => {
    const { userId, otp, password } = this.state;
    if (!otp) {
      this.showErrorPopup('Invalid OTP');
      this.setState({
        otp: '',
      });
    } else if (!password) {
      this.showErrorPopup('Enter Valid Password');
    } else {
      try {
        this.showSpinner();
        const data = {
          phoneNumber: `+91${userId}`,
          password: password,
          token: otp,
        };
        await API.changeUserPassword(data);
        this.props.history.push('./signin');
      } catch (error) {
        console.error('error', error, error.request);
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        this.closePopup();
        this.showErrorPopup(errorMsg);
        this.setState({
          otp: '',
        });
      }
    }
  };

  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: { color: '#E67717' },
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  showSuccessPopup = successMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: successMessage,
        onClose: this.closePopup,
        headingImage: commonImages.successIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };
  backClick = () => {
    this.props.history.goBack();
  };
  getProps = () => {
    return {
      onClick: this.onClickHandler,
      onChangeState: this.onChange,
      otp: this.state.otp,
      isShowOtp: this.state.isShowOtp,
      backClick: this.backClick,
    };
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };

  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const { popup } = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="blue" />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      default: {
        console.warn('case not handled', popup.type);
        return null;
      }
    }
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const { popup } = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <Metadata title={PAGE_TITLES.changePassword} />
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

export default ForgotPassword;
