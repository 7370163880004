import React, {useState} from 'react';
import styles from './styles.module.scss';
import creditCoins from '../../../assets/images/credits/image.png';
import errorIcon from '../../../assets/images/reject-icon-cross/image.png';

import {adminImages} from '../../../assets/images';
import strings from '../../../globalization';
import moment from 'moment';

type Button = {
  text: String,
  onClick: () => void,
  className?: String,
};

type Props = {
  message: Object,
  onClose?: () => void,
  error?: Boolean,
  success?: Boolean,
  buttons?: Button[],
  heading?: String,
};

/**
 *
 * @param {Boolean} error
 * @param {Boolean} success
 * @return {JSX.Element}
 */

/**
 *
 * @param {Props} props
 * @return {JSX.Element}
 */
function PaymentFailed(props: Props) {
  const {
    error,
    success,
    onClose,
    onClick,
    buttons,
    heading,
    buttonContainer,
    message,
    data,
  } = props;

  let containerClass = '';
  if (error) {
    containerClass = styles.error;
  } else if (success) {
    containerClass = styles.success;
  }

  const getSuccessData = () => {
    //    const {amount, gst, totalAmount} = this.state;

    return (
      <div className={styles.requestInfo}>
        <h1 className={styles.successText}>Payment Failed</h1>
        <img src={errorIcon} className={styles.successIcon} alt="U" />
        <p className={styles.subheadingText}>
          Failed to add credits worth {data.amount} for {data.amount} INR
        </p>

        <p style={{color: '#000', marginBottom: '40px'}}>
          Here is your Transaction ID:{' '}
          <span className={styles.orderIdText}>{data.orderId}</span>
          {/* and Reference ID: <span className={styles.orderIdText}>REF123009123</span> */}
        </p>
        <p style={{color: '#000', marginBottom: '40px'}}>Please try again!</p>
      </div>
    );
  };

  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <div className={[styles.container, containerClass].join(' ')}>
      <div className={styles.secondContainer}>
        {onClose ? (
          //  <span className={styles.closeIcon}>X </span>
          <img
            alt="close"
            src={adminImages.closeIcon}
            className={styles.closeIcon}
            onClick={onClose}
          />
        ) : null}

        <div className={styles.heading}> {`Add Credits`}</div>
        <div className={styles.bodyContainer}>
          <div className={styles.infoContainer}>{getSuccessData()}</div>
        </div>

        {buttons && buttons.length > 0 ? (
          <div className={[styles.buttonContainer, buttonContainer].join(' ')}>
            {buttons.map(button => {
              const {text, buttonStyles, ...remainingProps} = button;
              return (
                <button
                  className={[styles.button, buttonStyles].join(' ')}
                  key={'popup-button-' + text}
                  onClick={e => handleClick()}
                  {...remainingProps}>
                  {text}
                </button>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default PaymentFailed;
