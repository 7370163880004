import strings from '../../../globalization';

export const getFormData = () => {
  return {
    firstColumn: [
      {
        elementType: 'text',
        inputType: 'alphabets',
        label: strings.vehicleOwnerName,
        placeholder: strings.vehicleOwnerName,
        stateField: 'name',
        id: 'name',
        stateDefault: '',
        minLength: 5,
        maxLength: 128,
        disabled: true,
      },
      {
        inputType: 'phone',
        elementType: 'phone',
        keyboardType: 'number-pad',
        label: strings.vehicleOwnerPhoneNumber,
        placeholder: 'Enter Owner Mobile number',
        stateField: 'phoneNumber',
        id: 'phoneNumber',
        stateDefault: '',
        maxLength: 10,
        disabled: true,
      },
      {
        elementType: 'text',
        keyboardType: 'default',
        inputType: 'email',
        label: strings.vehicleOwnerEmail,
        placeholder: 'Enter Owner person email',
        stateField: 'emailAddress',
        id: 'emailAddress',
        stateDefault: '',
        maxLength: 50,
        disabled: true,
      },
      {
        elementType: 'text',
        inputType: 'text',
        label: strings.vehicleNumber,
        placeholder: 'Vehicle Number',
        stateField: 'vehicleRegistrationNumber',
        id: 'vehicleRegistrationNumber',
        stateDefault: '',
        minLength: 5,
        maxLength: 128,
        disabled: true,
      },
      {
        elementType: 'text',
        inputType: 'text',
        label: 'Vehicle Type',
        placeholder: strings.vehicleType,
        stateField: 'vehicleType',
        id: 'vehicleType',
        stateDefault: '',
        minLength: 5,
        maxLength: 128,
        disabled: true,
      },
      {
        elementType: 'text',
        inputType: 'text',
        label: strings.vehicleChassisNumber,
        placeholder: 'Enter Chassis Number',
        stateField: 'chassisNumber',
        id: 'chassisNumber',
        stateDefault: '',
        minLength: 5,
        maxLength: 128,
        disabled: true,
      },
      {
        elementType: 'text',
        inputType: 'text',
        label: strings.vehicleEngineNumber,
        placeholder: 'Enter Vehicle Engine Number',
        stateField: 'vehicleEngineNumber',
        id: 'vehicleEngineNumber',
        stateDefault: '',
        minLength: 5,
        maxLength: 128,
        disabled: true,
      },
      {
        elementType: 'text',
        inputType: 'text',
        label: strings.vehicleRegtNumber,
        placeholder: 'Enter Vehicle RC Number',
        stateField: 'vehicleRegistrationNumber',
        id: 'vehicleRegistrationNumber',
        stateDefault: '',
        minLength: 5,
        maxLength: 128,
        fileIcon: 'rcDocument',
        disabled: true,
      },
      {
        elementType: 'text',
        inputType: 'text',
        label: strings.vehicleInsuranceCertificate,
        placeholder: 'Enter Vehicle Insurance Number',
        stateField: 'vehicleInsuranceNumber',
        id: 'vehicleInsuranceNumber',
        stateDefault: '',
        minLength: 5,
        maxLength: 128,
        fileIcon: 'vehicleInsuranceCertificate',
        disabled: true,
      },
      {
        elementType: 'text',
        inputType: 'text',
        label: strings.pUCCertificate,
        placeholder: 'Enter PUC Certificate',
        stateField: 'vehiclePollutionNumber',
        id: 'vehiclePollutionNumber',
        stateDefault: '',
        minLength: 5,
        maxLength: 128,
        fileIcon: 'puc',
        disabled: true,
      },
      {
        elementType: 'text',
        inputType: 'text',
        label: strings.fitnessCertificate,
        placeholder: 'Enter Fitness Certificate',
        stateField: 'fitnessCertificate',
        id: 'fitnessCertificate',
        stateDefault: '',
        minLength: 5,
        maxLength: 128,
        fileIcon: 'fitnessCertificate',
        disabled: true,
      },
      {
        elementType: 'text',
        inputType: 'alphabets',
        label: 'Department Name',
        placeholder: 'Department Name',
        stateField: 'departmentName',
        id: 'departmentName',
        stateDefault: '',
        minLength: 5,
        maxLength: 128,
        disabled: true,
      },
    ],
  };
};

export const getEditFormData = () => {
  return [
    {
      elementType: 'text',
      inputType: 'alphabets',
      label: strings.vehicleOwnerName,
      placeholder: strings.vehicleOwnerName,
      stateField: 'name',
      id: 'name',
      stateDefault: '',
      minLength: 5,
      maxLength: 128,
      disabled: true,
    },

    {
      elementType: 'text',
      keyboardType: 'default',
      inputType: 'alphanumeric',
      label: strings.vehicleNumber,
      placeholder: 'Vehicle Number',
      stateField: 'vehicleRegistrationNumber',
      id: 'vehicleRegistrationNumber',
      stateDefault: '',
      maxLength: 10,
      minLength: 9,
      uppercase: true,
    },
    {
      elementType: 'dropdown',
      inputType: 'text',
      label: strings.vehicleType,
      placeholder: strings.vehicleTypePlaceholder,
      optionsField: 'vehicleTypeOptions',
      required: true,
      stateField: 'vehicleTypeId',
      stateDefault: null,
      maxLength: 128,
      noOptionsText: 'Please select vehicle category',
    },
    {
      inputType: 'phone',
      elementType: 'phone',
      keyboardType: 'number-pad',
      label: strings.vehicleOwnerPhoneNumber,
      placeholder: 'Enter Owner Mobile number',
      stateField: 'phoneNumber',
      id: 'phoneNumber',
      stateDefault: '',
      maxLength: 10,
    },
    // {
    //   elementType: 'text',
    //   inputType: 'text',
    //   label: 'Vehicle Type',
    //   placeholder: strings.vehicleType,
    //   stateField: 'vehicleType',
    //   id: 'vehicleType',
    //   stateDefault: '',
    //   minLength: 5,
    //   maxLength: 128,
    //   disabled: true,
    // },
  ];
};
