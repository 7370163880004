import React, {useState} from 'react';
import styles from './Desktop.module.scss';
import './styles.css';
import strings from '../../../../../../globalization';
import {withRouter} from 'react-router-dom';
import {AgGridReact} from 'ag-grid-react';
import Select from 'react-select';
import moment from 'moment';
import PermitsHeader from '../../../../../../components/PermitsHeader';

import 'react-daterange-picker/dist/css/react-calendar.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import dimensions from '../../../../../../util/dimensions-util';
import DateRangePicker from 'react-daterange-picker';
// import { rowData } from './data';
import TextInput from '../../../../../../components/TextInput';
import Button from '../../../../../../components/Button';

import calendarBlue from '../../../../../../assets/images/calendar-blue-icon/image.png';

const {vw: widthConstant, vh: heightConstant} = dimensions;
const tabs = [
  {
    label: 'Available Credits',
    id: 1,
    route: 'available-credits',
  },
  {
    label: 'Usage History',
    id: 2,
    route: 'history',
  },
];

const columnDefs = [
  {
    headerName: 'Date',
    field: 'updatedDate',
    headerClass: styles.rowHeader,
    width: 130 * widthConstant,
    resizable: true,
    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {updatedDate}}) => {
      return (
        <div className={styles.sessionTime}>
          {updatedDate ? moment(updatedDate).format('DD/MM/YYYY') : null}
        </div>
      );
    },
  },
  {
    headerName: 'Reference ID',
    field: 'referenceId',
    headerClass: styles.rowHeader,
    width: 250 * widthConstant,
    resizable: true,
    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {referenceId}}) => {
      return <div className={styles.sessionTime}>{referenceId}</div>;
    },
  },

  {
    headerName: 'Transaction ID',
    field: 'transactionId',
    headerClass: styles.rowHeader,
    width: 250 * widthConstant,
    resizable: true,
    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {transactionId}}) => {
      return <div className={styles.sessionTime}>{transactionId}</div>;
    },
  },
  {
    headerName: 'Mode of Payment',
    field: 'type',
    headerClass: styles.rowHeader,
    width: 200 * widthConstant,
    resizable: true,
    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {type}}) => {
      return <div className={styles.sessionTime}>{type}</div>;
    },
  },
  {
    headerName: 'Time',
    field: 'updatedDate',
    headerClass: styles.rowHeader,
    width: 120 * widthConstant,
    resizable: true,
    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {updatedDate}}) => {
      return (
        <div className={styles.sessionTime}>
          {updatedDate ? moment(updatedDate).format('hh:mm A') : null}
        </div>
      );
    },
  },
  {
    headerName: 'Credits Used',
    field: 'creditsUsed',
    headerClass: styles.rowHeader,
    width: 180 * widthConstant,
    resizable: true,
    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {creditsUsed}}) => {
      return <div className={styles.sessionTime}>{creditsUsed}</div>;
    },
  },
  {
    headerName: 'Balance',
    field: 'balance',
    headerClass: styles.rowHeader,
    width: 150 * widthConstant,
    resizable: true,
    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {credits}}) => {
      return <div className={styles.sessionTime}>{credits}</div>;
    },
  },
  {
    headerName: strings.AGENCY.PERMITS.status,
    field: 'status',
    headerClass: styles.rowHeader,
    width: 180 * widthConstant,
    resizable: true,
    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {status}}) => {
      return (
        <div
          className={[
            styles.status,
            status === 'APPROVED' ? styles.statusApproved : '',
            status === 'PENDING' ? styles.statusRejected : '',
          ].join(' ')}>
          <div className={styles.statusLabel}>
            {status === 'APPROVED' ? 'Approved' : 'Pending'}
          </div>
        </div>
      );
    },
  },
];

const Desktop = props => {
  const {
    onClick,
    onChange,
    statuses,
    selectedStatus,
    agencies,
    filterAgencies,
    searchText,
    availableCredits,
    dates,
  } = props;
  const [showDate, setShowDate] = useState(false);

  const onSelect = dates => {
    onChange('date', {
      startDate: moment(dates.start).format('MM-DD-YYYY'),
      endDate: moment(dates.end).format('MM-DD-YYYY'),
      dates,
    });
    toggleDate();
  };
  const toggleDate = () => {
    setShowDate(!showDate);
  };

  const onTabClick = id => {
    switch (id) {
      case 1:
        onClick('statusRoute', {route: 'available-credits', id: id});
        break;
      case 2:
        onClick('statusRoute', {route: 'credit-history', id: id});
        break;
      default:
        break;
    }
  };

  const formatDate = date => moment(date).format('MMM Do YY');
  const rowStyle = {border: 'none', cursor: 'pointer'};
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return {background: '#f6f6f6', border: 'none'};
    }
  };
  const handleClear = () => {
    setShowDate(false);
    onClick('clearFilters');
  };

  return (
    <div className={styles.agencyContainer}>
      <PermitsHeader
        title="Credits Wallet"
        onTabClick={onTabClick}
        tabs={tabs}
        availableCredits={availableCredits}
        activeTab={selectedStatus === 'CREDIT_HISTORY' ? 2 : 1}
      />
      <div className={styles.bodyContainer}>
        <div className={styles.header}>
          <div className={styles.left}>
            <div className={styles.dateWrapper}>
              <div onClick={toggleDate} className={styles.inputDate}>
                {' '}
                <div>
                  {' '}
                  {dates
                    ? formatDate(dates.start) + '-' + formatDate(dates.end)
                    : strings.ADMIN.dateRange}
                </div>{' '}
                <img src={calendarBlue} className={styles.calendar} alt="C" />
              </div>
              {showDate ? (
                <div className={styles.datePicker}>
                  <DateRangePicker
                    numberOfCalendars={1}
                    selectionType="range"
                    onSelect={onSelect}
                    className={styles.dateRange}
                    value={dates}
                    singleDateRange={true}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.inputSearch}>
              <TextInput
                onChange={text => {
                  onChange('search', text);
                }}
                isEdit={true}
                type={'text'}
                value={searchText}
                inputStyle={styles.inputText}
                showSeacrh={true}
              />
            </div>
            <div onClick={handleClear} className={styles.clearLabel}>
              {' '}
              {strings.ADMIN.clear}
            </div>
            <Button
              onClick={() => onClick('apply')}
              buttonStyle="primary"
              label={strings.ADMIN.apply}
              className={styles.applyBtn}></Button>
          </div>
        </div>
        <div className={['ag-theme-balham', styles.tableWrapper].join(' ')}>
          <AgGridReact
            rowStyle={rowStyle}
            getRowStyle={getRowStyle}
            rowHoverHighlight={true}
            columnDefs={columnDefs}
            rowHeight={50 * heightConstant}
            headerHeight={50 * heightConstant}
            defaultColDef={{sortable: true}}
            rowClass={[styles.gridRowClass]}
            //            onCellClicked={(event, index) => onCellClick(event, index)}
            rowData={filterAgencies}
            domLayout="autoHeight"></AgGridReact>
        </div>
        <div className={styles.bottomLabel}>
          {' '}
          Showing {filterAgencies.length}
        </div>
      </div>
    </div>
  );
};
export default withRouter(Desktop);
