import {ActionTypes} from '../constants';

export function setCartItem(payload) {
  return {
    type: ActionTypes.SET_CART_ITEM,
    payload,
  };
}

export function deleteCartItem(payload) {
  return {
    type: ActionTypes.DELETE_CART_ITEM,
    payload,
  };
}
export function clearCart(payload) {
  return {
    type: ActionTypes.CLEAR_CART,
    payload,
  };
}
export function updateQuantity(payload) {
  return {
    type: ActionTypes.UPDATE_QUANTITY,
    payload,
  };
}
