import React, {useEffect, useState} from 'react';
import Flex from '../../../../../../components/Flex';
import styles from './styles.module.scss';
import './styles.css';
import truckIcon from '../../../../../../assets/images/truck-icon-blue/image@3x.png';

import {operatorImages, adminImages} from '../../../../../../assets/images';

import FormElement from '../../../../../../components/FormElement';
import util from '../../../../../../util';
import {AgGridReact} from 'ag-grid-react';
import dimensions from '../../../../../../util/dimensions-util';
import moment from 'moment';

const {vw: widthConstant, vh: heightConstant} = dimensions;
function sleep(seconds) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, seconds * 1000);
  });
}

export default function Desktop({
  vehicle,
  stateData,
  formData,
  options,
  onBlur,
  goBack,
  onChange,
  searchText,
  onClick,
  handleUpload,
  checkDisabled,
  vehiclesTableData,
  materialType,
  materialTypeOptions,
  selectFromOrTo,
  selectFromOrToOptions,
  from,
  fromOptions,
  to,
  toOptions,
  lineInfo,
  isImport,
  isExport,
  importMaterialData,
  exportMaterialData,
  vesselTypeOptions,
  vesselType,
}) {
  console.log('materialTypeOptions', materialTypeOptions);
  const [state, setState] = useState({});
  const [imoNumber, setImoNumber] = useState({value: null, error: null});

  // const OnValueChange = (field, text) => {
  //   setImoNumber({value: text});
  // };
  return (
    <Flex className={styles.mainContainer}>
      <Flex className={styles.container}>
        <Header
          onClose={() => {
            onClick('cancel');
          }}
        />

        <Flex className={styles.personCardsContainer}>
          {vehicle ? <VehiclesDisplayCard vehicle={vehicle} /> : null}
        </Flex>
        <p className={styles.addMaterialHeader}>
          Add Material for the above vehicle{' '}
        </p>
        <Flex direction="row" className={styles.mainDropdownContainer}>
          <FormElement
            type={'dropdown'}
            label={'Material Type'}
            required={true}
            placeholderText={'Select Material Type'}
            onChange={text => {
              onChange('materialType', text);
            }}
            options={materialTypeOptions}
            value={materialType.value}
            error={materialType.error}
            dropdownStyle={{height: '45px'}}
            containerClassNames={styles.containerClassNames}
          />
          {/* <FormElement
            type={'dropdown'}
            label={'Select From'}
            required={true}
            placeholderText={'Select'}
            onChange={text => {
              onChange('from', text);
            }}
            options={fromOptions}
            value={from?.value}
            error={from?.error}
            dropdownStyle={{height: '45px'}}
            containerClassNames={styles.containerClassNames}
          />
          <FormElement
            type={'dropdown'}
            label={'Select To'}
            required={true}
            placeholderText={'Select'}
            onChange={text => {
              onChange('to', text);
            }}
            options={toOptions}
            value={to?.value}
            error={to?.error}
            dropdownStyle={{height: '45px'}}
            containerClassNames={styles.containerClassNames}
          /> */}
        </Flex>
        <Flex direction="column">
          {formData.length > 0 ? (
            <FormDataDisplay
              handleUpload={handleUpload}
              formData={formData}
              options={options}
              onClick={onClick}
              isImport={isImport}
              isExport={isExport}
              onChange={onChange}
              value={vesselType?.value}
              error={to?.error}
              importMaterialData={importMaterialData}
              exportMaterialData={exportMaterialData}
              vesselTypeOptions={vesselTypeOptions}
              vesselType={vesselType}
            />
          ) : null}
        </Flex>
      </Flex>
    </Flex>
  );
}

const FormDataDisplay = ({
  formData,
  options,
  onClick,
  handleUpload,
  importMaterialData,
  exportMaterialData,
  isImport,
  isExport,
  onChange,
  vesselTypeOptions,
  vesselType,
}) => {
  const [stateData, setStateData] = useState({});
  const [uploadFile, setUploadFile] = useState(null);
  const [apiState, setAPIState] = useState(null);
  const [data, setData] = useState(null);
  const [query, setQuery] = useState(null);
  const [gridAPI, setGridAPI] = useState(null);

  const getSelectedRowData = () => {
    let selectedNodes = gridAPI.getSelectedNodes();
    let selectedData = selectedNodes.map(node => node.data);
    console.log(`${JSON.stringify(selectedData)}`);
    setStateData(prevState => ({
      ...prevState,
      lineInfo: selectedData,
    }));
    return selectedData;
  };

  const onGridReady = params => {
    console.log(params.api);
    setGridAPI(params.api);
  };

  const isEmptyObject = obj => {
    return Object.keys(obj).length === 0;
  };

  //ag-Grid add new row functions
  const onAddRow = () => {
    gridAPI.applyTransaction({
      add: [{}],
    });
  };
  const onRemoveRow = () => {
    const selectedNodes = gridAPI.getSelectedRows();
    console.log(selectedNodes);
    gridAPI.applyTransaction({
      remove: selectedNodes,
    });
  };
  const editableColumnDefs = [
    {
      headerName: '',
      field: 'checkBox',
      headerClass: styles.rowHeader,
      width: 65 * widthConstant,
      resizable: true,
      checkboxSelection: function (params) {
        const displayedColumns = params.columnApi.getAllDisplayedColumns();
        return displayedColumns[0] === params.column;
      },
      headerCheckboxSelection: false,
    },
    {
      headerName: 'L No',
      field: 'LineNumber',
      headerClass: styles.rowHeader,
      width: 75 * widthConstant,
      resizable: true,
      editable: true,
      cellRendererFramework: ({data: {LineNumber}}) => {
        return <div className={styles.sessionTime}>{LineNumber}</div>;
      },
    },
    {
      headerName: 'BL No',
      field: 'BLNo',
      headerClass: styles.rowHeader,
      width: 90 * widthConstant,
      resizable: true,
      editable: true,
      cellRendererFramework: ({data: {BLNo}}) => {
        return <div className={styles.sessionTime}>{BLNo}</div>;
      },
    },
    {
      headerName: 'Gross Qty',
      field: 'GrossQuantity',
      headerClass: styles.rowHeader,
      width: 150 * widthConstant,
      resizable: true,
      editable: true,
      cellRendererFramework: ({data: {GrossQuantity}}) => {
        return <div className={styles.sessionTime}>{GrossQuantity}</div>;
      },
    },
    {
      headerName: 'Units',
      field: 'Units',
      headerClass: styles.rowHeader,
      width: 80 * widthConstant,
      resizable: true,
      editable: true,
      cellRendererFramework: ({data: {Units}}) => {
        return <div className={styles.sessionTime}>{Units}</div>;
      },
    },

    {
      headerName: 'Applied Qty',
      field: 'appliedGrossQuantity',
      headerClass: styles.rowHeader,
      width: 150 * widthConstant,
      editable: true,
      resizable: true,
      cellRendererFramework: ({data: {appliedGrossQuantity}}) => {
        return <div>{appliedGrossQuantity}</div>;
      },
    },
    {
      headerName: 'Cargo Description',
      field: 'CargoDescription',
      headerClass: styles.rowHeader,
      width: 400 * widthConstant,
      editable: true,
      resizable: true,
      cellRendererFramework: ({data: {CargoDescription}}) => {
        return <div className={styles.sessionTime}>{CargoDescription}</div>;
      },
    },
    {
      headerName: 'Nature Of Cargo',
      field: 'NatureOfCargo',
      headerClass: styles.rowHeader,
      width: 190 * widthConstant,
      editable: true,
      resizable: true,
      cellRendererFramework: ({data: {NatureOfCargo}}) => {
        return <div className={styles.sessionTime}>{NatureOfCargo}</div>;
      },
    },
  ];

  const columnDefs = [
    {
      headerName: '',
      field: 'checkBox',
      headerClass: styles.rowHeader,
      width: 65 * widthConstant,
      resizable: true,
      checkboxSelection: function (params) {
        const displayedColumns = params.columnApi.getAllDisplayedColumns();
        return (
          params?.data?.availableGrossQuantity > 0 &&
          displayedColumns[0] === params.column
        );
      },
      headerCheckboxSelection: false,
    },
    {
      headerName: 'L No',
      field: 'LineNumber',
      headerClass: styles.rowHeader,
      width: 70 * widthConstant,
      resizable: true,
      cellRendererFramework: ({data: {LineNumber}}) => {
        return <div className={styles.sessionTime}>{LineNumber}</div>;
      },
    },
    {
      headerName: 'BL No',
      field: 'BLNo',
      headerClass: styles.rowHeader,
      width: 100 * widthConstant,
      resizable: true,
      cellRendererFramework: ({data: {BLNo}}) => {
        return <div className={styles.sessionTime}>{BLNo}</div>;
      },
    },
    {
      headerName: 'Gross Qty',
      field: 'GrossQuantity',
      headerClass: styles.rowHeader,
      width: 110 * widthConstant,
      resizable: true,
      cellRendererFramework: ({data: {GrossQuantity}}) => {
        return <div className={styles.sessionTime}>{GrossQuantity}</div>;
      },
    },
    {
      headerName: 'Units',
      field: 'Units',
      headerClass: styles.rowHeader,
      width: 80 * widthConstant,
      resizable: true,
      cellRendererFramework: ({data: {Units}}) => {
        return <div className={styles.sessionTime}>{Units}</div>;
      },
    },
    {
      headerName: 'Available Qty',
      field: 'availableGrossQuantity',
      headerClass: styles.rowHeader,
      width: 120 * widthConstant,
      resizable: true,
      cellRendererFramework: ({data: {availableGrossQuantity}}) => {
        return (
          <div className={styles.sessionTime}>{availableGrossQuantity}</div>
        );
      },
    },
    {
      headerName: 'Applied Qty',
      field: 'appliedGrossQuantity',
      headerClass: styles.rowHeader,
      width: 120 * widthConstant,
      editable: true,
      resizable: true,
      valueParser: params => {
        console.log(params);
        return Number(params.newValue) >
          Number(params.data?.availableGrossQuantity) || isNaN(params.newValue)
          ? params.data?.availableGrossQuantity
          : params.newValue;
      },
      cellRendererFramework: ({
        data: {availableGrossQuantity, appliedGrossQuantity},
      }) => {
        let temp = availableGrossQuantity;
        return <div>{appliedGrossQuantity ? appliedGrossQuantity : temp}</div>;
      },
    },
    {
      headerName: 'Cargo Description',
      field: 'cargoDescription',
      headerClass: styles.rowHeader,
      width: 320 * widthConstant,
      resizable: true,
      cellRendererFramework: ({data: {CargoDescription}}) => {
        return <div className={styles.sessionTime}>{CargoDescription}</div>;
      },
    },
    {
      headerName: 'Nature Of Cargo',
      field: 'NatureOfCargo',
      headerClass: styles.rowHeader,
      width: 160 * widthConstant,
      resizable: true,
      cellRendererFramework: ({data: {NatureOfCargo}}) => {
        return <div className={styles.sessionTime}>{NatureOfCargo}</div>;
      },
    },
  ];

  const rowStyle = {border: 'none', cursor: 'pointer'};
  const getRowStyle = params => {
    // if (params.node.rowIndex % 2 === 0) {
    //   return {background: '#f6f6f6', border: 'none'};
    // }
  };
  const splitAt = index => x => [x.slice(0, index), x.slice(index)];

  useEffect(() => {
    let preparedObject = {};
    if (isImport) {
      preparedObject['vesselType'] = {
        value: stateData['vesselType'] ? stateData['vesselType'].value : null,
        error: null,
      };
      preparedObject['BillOfEntryNo'] = {
        value: stateData['BillOfEntryNo']
          ? stateData['BillOfEntryNo'].value
          : null,
        error: null,
      };
    }
    if (isExport) {
      preparedObject['vesselType'] = {
        value: stateData['vesselType'] ? stateData['vesselType'].value : null,
        error: null,
      };
      preparedObject['ShippingBillNumber'] = {
        value: stateData['ShippingBillNumber']
          ? stateData['ShippingBillNumber'].value
          : null,
        error: null,
      };
    }

    formData.map(obj => {
      preparedObject[obj.stateField] = {value: obj.stateDefault, error: null};
      if (importMaterialData && isImport) {
        if (obj.stateField === 'IMONumber') {
          preparedObject[obj.stateField] = {
            value: importMaterialData.IMONumber,
            error: null,
          };
        }
        if (obj.stateField === 'VCNNumber') {
          preparedObject[obj.stateField] = {
            value: importMaterialData.VCNNumber,
            error: null,
          };
        }
        if (obj.stateField === 'CHA') {
          preparedObject[obj.stateField] = {
            value: importMaterialData.CHA,
            error: null,
          };
        }
        if (obj.stateField === 'CHACode') {
          preparedObject[obj.stateField] = {
            value: importMaterialData.CHACode,
            error: null,
          };
        }
        if (importMaterialData.IGMDate && obj.stateField === 'IGMDate') {
          //FIXME -- fix the date format at the backend...
          let tempDate =
            importMaterialData.IGMDate.length > 0 &&
            splitAt(2)(importMaterialData.IGMDate).join('/');
          const myDate = moment(splitAt(5)(tempDate).join('/'), 'DD/MM/YYYY');
          preparedObject[obj.stateField] = {
            value: new Date(moment(myDate).format('MM/DD/YYYY')),
            error: null,
          };
        }
        if (obj.stateField === 'deliveryOrder') {
          preparedObject[obj.stateField] = {
            value: importMaterialData.deliveryOrder,
            error: null,
          };
        }
        if (
          importMaterialData.deliveryOrderDate &&
          obj.stateField === 'deliveryOrderDate'
        ) {
          const tempDate =
            importMaterialData.deliveryOrderDate.length > 0 &&
            splitAt(2)(importMaterialData.deliveryOrderDate).join('/');
          const myDate = moment(splitAt(5)(tempDate).join('/'), 'DD/MM/YYYY');

          preparedObject[obj.stateField] = {
            value: new Date(moment(myDate).format('MM/DD/YYYY')),
            error: null,
          };
        }
        // if (obj.stateField === 'IADate') {
        //   preparedObject[obj.stateField] = {
        //     value: new Date(),
        //     error: null,
        //   };
        // }
        // if (importMaterialData && obj.stateField === 'IANumber') {
        //   preparedObject[obj.stateField] = {
        //     value: importMaterialData.IANumber,
        //     error: null,
        //   };
        // }
        if (obj.stateField === 'vesselName') {
          preparedObject[obj.stateField] = {
            value: importMaterialData.vesselName,
            error: null,
          };
        }
        if (obj.stateField === 'IGMNumber') {
          preparedObject[obj.stateField] = {
            value: importMaterialData.IGMNumber,
            error: null,
          };
        }
        if (obj.stateField === 'outOfChargeNo') {
          preparedObject[obj.stateField] = {
            value: importMaterialData.outOfChargeNo,
            error: null,
          };
        }
      } else if (isExport && exportMaterialData) {
        if (obj.stateField === 'CHACode') {
          preparedObject[obj.stateField] = {
            value: exportMaterialData.CHACode,
            error: null,
          };
        }

        if (obj.stateField === 'ShippingBillNumber') {
          preparedObject[obj.stateField] = {
            value: exportMaterialData.ShippingBillNumber,
            error: null,
          };
        }
        if (
          exportMaterialData.ShippingBillDate &&
          obj.stateField === 'ShippingBillDate'
        ) {
          const tempDate =
            exportMaterialData.ShippingBillDate.length > 0 &&
            splitAt(2)(exportMaterialData.ShippingBillDate).join('/');
          const myDate = moment(splitAt(5)(tempDate).join('/'), 'DD/MM/YYYY');

          preparedObject[obj.stateField] = {
            // value: exportMaterialData.ShippingBillDate,
            value: new Date(moment(myDate).format('MM/DD/YYYY')),
            error: null,
          };
        }
        if (obj.stateField === 'VCNNumber') {
          preparedObject[obj.stateField] = {
            value: exportMaterialData.VCNNumber,
            error: null,
          };
        }
        if (obj.stateField === 'vesselName') {
          preparedObject[obj.stateField] = {
            value: exportMaterialData.vesselName,
            error: null,
          };
        }
        if (obj.stateField === 'HSCode') {
          preparedObject[obj.stateField] = {
            value: exportMaterialData.HSCode,
            error: null,
          };
        }
        if (obj.stateField === 'FOBValue') {
          preparedObject[obj.stateField] = {
            value: exportMaterialData.FOBValue,
            error: null,
          };
        }
        if (obj.stateField === 'totalWeightNumberOfUnits') {
          preparedObject[obj.stateField] = {
            value: exportMaterialData.TotalWeight,
            error: null,
          };
        }
        if (obj.stateField === 'cargoDescription') {
          preparedObject[obj.stateField] = {
            value: exportMaterialData.CargoDescription,
            error: null,
          };
        }
        if (obj.stateField === 'unitsOfWeight') {
          preparedObject[obj.stateField] = {
            value:
              exportMaterialData.unitsOfWeight === 'MTS'
                ? 2
                : exportMaterialData.unitsOfWeight === 'KGS'
                ? 1
                : null,
            error: null,
          };
        }
        if (obj.stateField === 'natureOfCargo') {
          preparedObject[obj.stateField] = {
            value:
              exportMaterialData.NatureOfCargo === 'BB' ||
              exportMaterialData.NatureOfCargo?.toLowerCase() === 'bread bulk'
                ? 2
                : exportMaterialData.NatureOfCargo === 'DB' ||
                  exportMaterialData.NatureOfCargo?.toLowerCase() === 'dry bulk'
                ? 1
                : exportMaterialData.NatureOfCargo === 'LB' ||
                  exportMaterialData.NatureOfCargo?.toLowerCase() ===
                    'liquid bulk'
                ? 3
                : null,
            error: null,
          };
        }
      }

      if (obj.optionsField) {
        preparedObject[obj.optionsField] = options[obj.optionsField];
      }
    });
    setStateData({
      ...preparedObject,
    });
  }, [formData, options]);

  const onValueChange = (field, value) => {
    console.log('onValueChange', 'field', field, 'value', value);
    setStateData(prevState => ({
      ...prevState,
      [field]: {
        value,
      },
    }));
  };

  const onBlur = (field, element) => {
    const currentObj = stateData[field];

    console.log('formField', element);
    setStateData(prevState => ({
      ...prevState,
      [field]: {
        ...currentObj,
        error: element ? util.validate(currentObj.value, element) : null,
      },
    }));
  };

  const handleErrors = () => {
    console.log('Handling errors');
    let err = false;
    const update = {};

    for (let i = 0; i < formData.length; i++) {
      let element = formData[i];

      let error = util.validate(stateData[element.stateField].value, element);
      if (error) {
        err = true;
        update[element.stateField] = {
          ...stateData[element.stateField],
          error: error,
        };
      } else {
        update[element.stateField] = {
          ...stateData[element.stateField],
          error: null,
        };
      }
    }
    if (isImport) {
      let temp = ['BillOfEntryNo', 'vesselType'];
      const config = {required: true, maxLength: 20, label: 'field'};
      for (let i = 0; i < temp.length; i++) {
        let error = util.validate(stateData[temp[i]].value, config);
        if (error) {
          err = true;
          update[temp[i]] = {
            ...stateData[temp[i]],
            error: error,
          };
        } else {
          update[temp[i]] = {
            ...stateData[temp[i]],
            error: null,
          };
        }
      }
    }

    if (isExport) {
      let temp = ['ShippingBillNumber', 'vesselType'];
      const config = {required: true, maxLength: 20, label: 'field'};
      for (let i = 0; i < temp.length; i++) {
        let error = util.validate(stateData[temp[i]].value, config);
        if (error) {
          err = true;
          update[temp[i]] = {
            ...stateData[temp[i]],
            error: error,
          };
        } else {
          update[temp[i]] = {
            ...stateData[temp[i]],
            error: null,
          };
        }
      }
    }

    console.log(stateData);
    setStateData(prevState => ({
      ...prevState,
      ...update,
    }));
    return err;
  };
  const validateLineInfo = data => {
    console.log('validateLineInfo', data);
    let isValid = true;
    data.forEach(ele => {
      if (isEmptyObject(ele)) {
        console.log('line info should not be empty');
        onClick('showLineInfoError', 'line info should not be empty');
        isValid = false;
      } else if (!ele?.LineNumber) {
        console.log('Line number should not be empty');
        onClick('showLineInfoError', 'Line number should not be empty');
        isValid = false;
      } else if (!ele?.BLNo) {
        console.log('BLNo should not be empty');
        onClick('showLineInfoError', 'BLNo should not be empty');
        isValid = false;
      } else if (!ele?.GrossQuantity) {
        console.log('GrossQuantity should not be empty');
        onClick('showLineInfoError', 'Gross quantity should not be empty');
        isValid = false;
      } else if (!ele?.Units) {
        console.log('Units should not be empty');
        onClick('showLineInfoError', 'Units should not be empty');
        isValid = false;
      } else if (!ele?.appliedGrossQuantity) {
        console.log('appliedQuantity should not be empty');
        onClick('showLineInfoError', 'Applied quantity should not be empty');
        isValid = false;
      } else if (!ele?.CargoDescription) {
        console.log('cargoDescription should not be empty');
        onClick('showLineInfoError', 'Cargo description should not be empty');
        isValid = false;
      } else if (!ele?.NatureOfCargo) {
        console.log('NatureOfCargo should not be empty');
        onClick('showLineInfoError', 'Nature of cargo should not be empty');
        isValid = false;
      }
    });
    return isValid;
  };

  const handleAdd = () => {
    let selectedData = [];
    if (vesselType?.value === 1 && isImport) {
      let selectedNodes = gridAPI?.getSelectedNodes();
      selectedData = selectedNodes?.map(node => node.data);
      if (selectedData.length > 0 && !validateLineInfo(selectedData)) {
        return;
      } else if (selectedData.length === 0 || !selectedData) {
        console.log('1');
        alert('please select at least one line number');
        return;
      }
    } else if (vesselType?.value === 2 && isImport) {
      let selectedNodes = gridAPI?.getSelectedNodes();
      selectedData = selectedNodes?.map(node => node.data);
      if (
        selectedData?.length > 0 &&
        importMaterialData?.lineInfo?.length === 0 &&
        !validateLineInfo(selectedData)
      ) {
        return;
      } else if (selectedData?.length > 0 && !validateLineInfo(selectedData)) {
        return;
      } else if (selectedData?.length === 0 || !selectedData) {
        console.log('2');
        alert('please select at least one line number');
        return;
      }
    }
    console.log(selectedData, importMaterialData?.lineInfo?.length === 0);
    // setTimeout(() => {
    //   onClick('addMaterial', {stateData: stateData, lineInfo: selectedData});
    //   console.log(stateData, selectedData);
    // }, 1000);
    let errors = handleErrors();

    console.log('errors', errors, stateData);
    if (!errors) {
      let tempVar = {};
      if (isImport) {
        const {CHACode, CHA} = importMaterialData || {};
        tempVar = {
          CHACode: CHACode || '',
          CHA: CHA || '',
          ...stateData,
        };
      } else {
        tempVar = stateData;
      }
      console.log(tempVar);

      onClick('addMaterial', {
        stateData: tempVar,
        lineInfo: selectedData,
      });
      console.log(stateData);
    }
  };

  const handleFileUpload = async (stateField, file) => {
    console.log('stateField...handleFileUpload', stateField);

    return await handleUpload(stateField, file);
  };

  useEffect(() => {
    if (!uploadFile) {
      return;
    }
    const someFunc = async () => {
      const {field, file} = uploadFile;
      const response = await handleUpload(field, file);
      const preparedValue = [...stateData[field].value, ...response];
      setUploadFile(null);
      setStateData({
        ...stateData,
        [field]: {
          value: preparedValue,
          error: null,
        },
      });
      setData({
        ...stateData,
        [field]: {
          value: preparedValue,
          error: null,
        },
      });
    };
    someFunc();
  }, [handleUpload, stateData, uploadFile]);

  console.info('find me', stateData);

  return (
    <>
      {isImport ? (
        <div>
          <Flex direction="row" className={styles.mainDropdownContainer}>
            <FormElement
              type={'dropdown'}
              label={'Type of vessel'}
              required={true}
              placeholderText={'Select'}
              onChange={text => {
                onValueChange('vesselType', text);
                onChange('vesselType', text);
              }}
              options={vesselTypeOptions}
              value={stateData['vesselType']?.value}
              error={stateData['vesselType']?.error}
              dropdownStyle={{height: '45px'}}
              containerClassNames={styles.containerClassNames}
            />

            <FormElement
              type={'text'}
              label={'Bill Of Entry Number'}
              required={true}
              placeholderText={'Enter Bill Of Entry Number'}
              onChange={text => {
                onValueChange('BillOfEntryNo', text);
              }}
              maxLength={20}
              value={stateData['BillOfEntryNo']?.value}
              error={stateData['BillOfEntryNo']?.error}
              containerClassNames={styles.containerClassNames}
            />
          </Flex>
          {vesselType?.value === 2 ? (
            <Flex direction="row" className={styles.buttonContainer}>
              <button
                // disabled={checkDisabled()}
                className={styles.finish}
                // style={
                //   checkDisabled()
                //     ? {backgroundColor: '#0045e650', cursor: 'not-allowed'}
                //     : {backgroundColor: '#0045e6'}
                // }
                onClick={() =>
                  onClick('fetchMaterialData', {
                    BillOfEntryNo: stateData['BillOfEntryNo'].value,
                  })
                }>
                Fetch data
              </button>
            </Flex>
          ) : null}
        </div>
      ) : null}
      {isExport ? (
        <div>
          <Flex direction="row" className={styles.mainDropdownContainer}>
            <FormElement
              type={'dropdown'}
              label={'Type of vessel'}
              required={true}
              placeholderText={'Select'}
              onChange={text => {
                onValueChange('vesselType', text);

                onChange('vesselType', text);
              }}
              options={vesselTypeOptions}
              value={stateData['vesselType']?.value}
              error={stateData['vesselType']?.error}
              dropdownStyle={{height: '45px'}}
              containerClassNames={styles.containerClassNames}
            />
            <FormElement
              type={'text'}
              label={'Shipping Bill Number'}
              required={true}
              placeholderText={'Enter Shipping Bill Number'}
              onChange={text => {
                onValueChange('ShippingBillNumber', text);
              }}
              maxLength={20}
              value={stateData['ShippingBillNumber']?.value}
              error={stateData['ShippingBillNumber']?.error}
              containerClassNames={styles.containerClassNames}
            />
          </Flex>
          {vesselType?.value === 2 ? (
            <Flex direction="row" className={styles.buttonContainer}>
              <button
                // disabled={checkDisabled()}
                className={styles.finish}
                // style={
                //   checkDisabled()
                //     ? {backgroundColor: '#0045e650', cursor: 'not-allowed'}
                //     : {backgroundColor: '#0045e6'}
                // }
                onClick={() =>
                  onClick('fetchExportMaterialData', {
                    ShippingBillNumber: stateData['ShippingBillNumber']?.value,
                  })
                }>
                Fetch data
              </button>
            </Flex>
          ) : null}
        </div>
      ) : null}{' '}
      <Flex direction="row" className={styles.formFieldsContainer}>
        {formData.map((elem, idx) => {
          return (
            <FormElement
              type={elem.elementType}
              label={elem.label}
              required={elem.required}
              placeholderText={elem.placeholder}
              onChange={text => {
                onValueChange(elem.stateField, text);
              }}
              options={stateData[elem.optionsField]}
              value={stateData[elem.stateField]?.value}
              key={idx}
              maxLength={elem.maxLength}
              maxFiles={elem.maxFiles}
              // disabled={checkDisabledForm(elem)}
              onBlur={() => onBlur(elem.stateField, elem)}
              error={stateData[elem.stateField]?.error}
              allowedFormats={elem.allowedFormats}
              handleUpload={file => {
                setUploadFile({
                  field: elem.stateField,
                  file,
                });
              }}
              dropdownStyle={{height: '45px'}}
              containerClassNames={styles.containerClassNames}
            />
          );
        })}{' '}
        {(isImport &&
          importMaterialData &&
          isEmptyObject(importMaterialData)) ||
        importMaterialData?.lineInfo?.length === 0 ||
        (isImport && vesselType?.value === 1) ? (
          <div className={styles.addRemoveButtonsContainer}>
            <button className={styles.finish} onClick={() => onAddRow()}>
              Add line info
            </button>
            <button className={styles.cancel} onClick={() => onRemoveRow()}>
              Remove selected line{' '}
            </button>
          </div>
        ) : null}
        {(isImport &&
          importMaterialData &&
          isEmptyObject(importMaterialData)) ||
        (isImport &&
          importMaterialData &&
          importMaterialData?.lineInfo?.length === 0) ||
        (isImport && vesselType?.value === 1) ? (
          <div className={['ag-theme-balham', styles.tableWrapper].join(' ')}>
            <AgGridReact
              checkboxSelection={true}
              rowStyle={rowStyle}
              getRowStyle={getRowStyle}
              rowHoverHighlight={true}
              columnDefs={editableColumnDefs}
              rowSelection={'multiple'}
              singleClickEdit={true}
              stopEditingWhenGridLosesFocus={true}
              rowHeight={50 * heightConstant}
              headerHeight={50 * heightConstant}
              defaultColDef={{sortable: true}}
              rowClass={[styles.gridRowClass, styles.importDataTable]}
              onGridReady={onGridReady}
              //            onCellClicked={(event, index) => onCellClick(event, index)}
              rowData={[]}></AgGridReact>
          </div>
        ) : null}
        {isImport &&
        importMaterialData &&
        !isEmptyObject(importMaterialData) &&
        importMaterialData?.lineInfo?.length > 0 ? (
          <div className={['ag-theme-balham', styles.tableWrapper].join(' ')}>
            <AgGridReact
              checkboxSelection={true}
              rowStyle={rowStyle}
              getRowStyle={getRowStyle}
              rowHoverHighlight={true}
              columnDefs={columnDefs}
              rowSelection={'multiple'}
              rowHeight={50 * heightConstant}
              headerHeight={50 * heightConstant}
              defaultColDef={{sortable: true}}
              rowClass={[styles.gridRowClass, styles.importDataTable]}
              onGridReady={onGridReady}
              //            onCellClicked={(event, index) => onCellClick(event, index)}
              rowData={
                Object.keys(importMaterialData).length === 0
                  ? []
                  : importMaterialData.lineInfo
              }></AgGridReact>
          </div>
        ) : null}
        <Flex direction="row" className={styles.buttonContainer}>
          <button className={styles.cancel} onClick={() => onClick('cancel')}>
            Cancel
          </button>
          <button
            // disabled={checkDisabled()}
            className={styles.finish}
            // style={
            //   checkDisabled()
            //     ? {backgroundColor: '#0045e650', cursor: 'not-allowed'}
            //     : {backgroundColor: '#0045e6'}
            // }
            onClick={handleAdd}>
            Add
          </button>
        </Flex>
      </Flex>
    </>
  );
};

const Header = ({onClose}) => {
  return (
    <Flex className={styles.header}>
      <p>{'Add Material'}</p>
      <img
        alt="close"
        src={adminImages.closeIcon}
        className={styles.closeIcon}
        onClick={onClose}
      />
    </Flex>
  );
};
const VehiclesDisplayCard = ({vehicle}) => {
  console.log('vehicle', vehicle);
  return (
    <Flex direction="column" className={styles.personDetails}>
      {/* //image text container */}
      <Flex direction="row" className={styles.imageTextContainer}>
        <img src={truckIcon} alt="u" className={styles.personImage} />
        <Flex direction="column" className={styles.textArea}>
          <p>
            Vehicle Number: <span>{vehicle?.vehicleRegistrationNumber}</span>
          </p>

          <p>
            Vehicle Type: <span>{vehicle?.vehicleType.name}</span>
          </p>
          {/* <p>
            RFID Card Number: <span>{vehicle?.rfidnumber}</span>
          </p> */}
        </Flex>
      </Flex>
    </Flex>
  );
};
