import React from 'react';
import styles from './Button.module.css';

export interface ButtonProps {}

const Button = (props: ButtonProps) => {
  const {label, buttonStyle, className, ...remainingProps} = props;

  const getButtonStyles = () => {
    switch (buttonStyle) {
      case 'primary':
        return styles.PrimaryButton;
      case 'secondary':
        return styles.secondaryButton;
      case 'danger':
        return styles.dangerButton;
      case 'tertiary':
        return styles.tertiaryButton;
      case 'orangeBtn':
        return styles.orangeBtn;
      default:
        return styles.PrimaryButton;
    }
  };
  return (
    <button
      type="button"
      {...props}
      className={[getButtonStyles(), styles.button, className].join(' ')}
      {...remainingProps}>
      {label}
    </button>
  );
};

export default Button;
