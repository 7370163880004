/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  formLeftFields,
  formRightFields,
  getSignatoryConfig,
  termsData,
} from './formFeilds';
import util from '../../../util';
import {API} from '../../../requests/index';
import ViewRenderer from './views';

import PopupContainer from '../../../components/PopupContainer';
import Popup from '../../../components/SimplePopup';
import Spinner from '../../../components/Spinner';
import strings from '../../../globalization';
import {commonImages, operatorImages} from '../../../assets/images';
import OtpPopup from '../../../components/OtpPopup';
import POPUP_CONSTANTS from '../../../constants/popup-constants';
import {showOtpPopup} from '../../../util/popup-util';
import {Metadata} from '../../../components/Metadata';
import PAGE_TITLES from '../../../constants/page-titles';
import Privacy from '../../../components/Privacy';
import {withRouter} from 'react-router-dom';

const registrationSteps = {
  STEP_1: '1',
  STEP_2: '2',
  TOTAL: '2',
};
const DROPDOWN_KEY_VALUE = {
  1: 'Person',
  2: 'Vehicle',
  3: 'Agency',
};
class NewAgencyRegistration extends React.Component<> {
  constructor(props) {
    super(props);
    const {status} = props;
    console.log(status, props);
    let preparedObject = {};
    this.page2Config = getSignatoryConfig();
    [...formLeftFields, ...formRightFields].forEach(obj => {
      preparedObject[obj.stateField] = {value: obj.stateDefault, error: null};
      if (obj.optionsField) {
        preparedObject[obj.optionsField] = [];
      }
    });
    // console.log("page2config - index", this.page2Config);
    this.page2Config.map(singleSignatoryObject => {
      singleSignatoryObject.formDetails.map(obj => {
        preparedObject[obj.stateField] = {
          value: obj.stateDefault,
          error: null,
        };
      });
    });
    this.state = {
      expiryMinutes: 1,
      currentStep: registrationSteps.STEP_1,
      otpVerified: false,
      status,
      terms: false,

      currentOtpVerifiactionObject: {
        verifiedNumberFields: {},
      },
      ...preparedObject,
    };
    // this.showOtpPopup = showOtpPopup.bind(this);
  }

  onChange = (action, data) => {
    console.log('onChange called...');
    console.log('action..', action, 'data...', data);
    if (action === 'terms') {
      this.toggleChecked(data);
    } else {
      this.setState(prevState => {
        return {
          ...prevState,
          [action]: data,
        };
      });
    }
  };
  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };
  onChangeHandler = (field, value) => {
    if (field === 'phoneNumber') {
      this.onOtpVerifySuccess(false);
    }
    this.setState(prevState => {
      const currentObj = prevState[field];
      return {
        ...prevState,
        [field]: {
          ...currentObj,
          value,
          error: null,
        },
      };
    });
  };
  onBlur = (field, element) => {
    this.setState(prevState => {
      const currentObj = prevState[field];
      return {
        ...prevState,
        [field]: {
          ...currentObj,
          error: element ? util.validate(currentObj.value, element) : null,
        },
      };
    });
  };

  validateForm = form => {
    return true;
  };

  handleUpload = (field, files, error, inputRef) => {
    if (error) {
      this.showErrorPopup(error);
      return;
    }
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });

    if (files === null) {
      throw new Error('File does not exist');
    }

    let filesArray = [];
    for (let i = 0, numFiles = files.length; i < numFiles; i++) {
      filesArray.push(files[i]);
    }
    let promises = filesArray.map(file => {
      const data = new FormData();
      data.append('s3FolderName', 'company');
      data.append('file', file);
      data.append('allowedExtensions', ['Pk8b8jAuPC1DF']);
      return API.uploadFilesToDpt(data).then(response => {
        console.log('Response Here', response.data);
        const url = response.data?.fileUrl;
        return {fileUrl: url, fileName: file.name};
      });

    });

    return Promise.all(promises)
      .then(response => {
        this.setState(prevState => {
          const currentObj = prevState[field];
          return {
            ...prevState,
            [field]: {
              ...currentObj,
              value: [...response],
              error: null,
            },
            popup: null,
          };
        });
      })
      .catch(error => {
        console.log('error-->', error);
        const errorMsg =
          util.getErrorMessage(error) || 'Upload Failed! Please try again.';
        console.log('errorMsg-->', errorMsg);
      
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.SIMPLE_POPUP,
            message: errorMsg,
            messageStyle: {color: '#E67717'},
            buttons: [
              {
                text: 'Okay!',
                onClick: this.closePopup,
              },
            ],
          },
        });
        if (inputRef) {
          inputRef.current.value = '';
        }
      });
  };

  // FIXME: Improve the logic...
  prepareSignatories = () => {
    const {
      name0,
      phoneNumber0,
      emailAddress0,
      designation0,
      authorizedDocument0,
      documentValidity0,
      identityProofDocument0,
      name1,
      phoneNumber1,
      emailAddress1,
      designation1,
      authorizedDocument1,
      documentValidity1,
      identityProofDocument1,
      name2,
      phoneNumber2,
      emailAddress2,
      designation2,
      authorizedDocument2,
      documentValidity2,
      identityProofDocument2,
      id0,
      id1,
      id2,
    } = this.state;
    const {status} = this.props;
    const resp = [
      {
        id: status === 'EDIT' ? id0 : undefined,
        name: name0.value,
        phoneNumber: `+91${phoneNumber0?.value}`,
        emailAddress: emailAddress0?.value,
        designation: designation0?.value,
        authorizedDocument: authorizedDocument0?.value[0]?.fileUrl,
        documentValidity:
          documentValidity0.value !== null &&
          documentValidity0.value !== undefined
            ? util.getDateTimeString(documentValidity0.value, 'mmddyyyy')
            : '',
        idProofDocument: identityProofDocument0?.value[0]?.fileUrl,
      },
      {
        id: status === 'EDIT' ? id1 : undefined,
        name: name1.value,
        phoneNumber: `+91${phoneNumber1?.value}`,
        emailAddress: emailAddress1?.value,
        designation: designation1?.value,
        authorizedDocument: authorizedDocument1?.value[0]?.fileUrl,
        documentValidity:
          documentValidity1.value !== null &&
          documentValidity1.value !== undefined
            ? util.getDateTimeString(documentValidity1.value, 'mmddyyyy')
            : '',
        idProofDocument: identityProofDocument1?.value[0]?.fileUrl,
      },
    ];
    if (name2.value) {
      console.log('Entered here!');
      let obj = {
        id: status === 'EDIT' ? id2 : undefined,
        name: name2.value,
        phoneNumber: `+91${phoneNumber2?.value}`,
        emailAddress: emailAddress2?.value,
        designation: designation2?.value,

        authorizedDocument: authorizedDocument2?.value[0]?.fileUrl,
        documentValidity:
          documentValidity2.value !== null &&
          documentValidity2.value !== undefined
            ? util.getDateTimeString(documentValidity2.value, 'mmddyyyy')
            : '',
        idProofDocument: identityProofDocument2?.value[0]?.fileUrl,
      };
      resp.push(obj);
    }
    return resp;
  };
  submitFormData = async () => {
    const {status} = this.props;
    const {companyDetails} = this.state;

    let preparedData = {
      name: this.state.name?.value,
      departmentId: this.state.departmentId.value,
      phoneNumber: `+91${this.state.phoneNumber?.value}`,
      landlineNumber: `+91${this.state.agencyLandlineNumber?.value}`,
      emailAddress: this.state.emailAddress?.value,
      // licenseNumber: this.state.licenseNumber?.value,
      // licenseValidity: validity || '',
      businessId: this.state.businessId.value,
      address1: this.state.address1.value,
      address2: this.state.address2.value
        ? this.state.address2.value
        : undefined,
      GSTNumber: this.state.GSTNumber.value,
      registrationProof: this.state.registrationProof.value,
      PANDocument: this.state.PANDocument.value,
      PANNumber: this.state.PANNumber.value,
      gstDocument: this.state.gstDocument.value,
      signatory: this.prepareSignatories(),
    };

    this.showSpinner();

    try {
      let res;
      if (status === 'EDIT') {
        res = await API.updateAgencyRegistration({
          ...preparedData,
          id: companyDetails?.id,
        });
      } else {
        res = await API.registerCompany(preparedData);
      }
      if (res?.status === 200 && res?.data?.referenceId) {
        this.props?.history?.push({
          pathname: 'registration-success',
          state: {
            id: res?.data?.referenceId,
            title: strings.newAgencyRegistration,
          },
        });
      }
      return res.data;
    } catch (errors) {
      const errorMsg = util.getErrorMessage(errors);
      this.showErrorPopup(errorMsg);
    }
  };
  getData = async () => {
    this.showSpinner();

    const promises = [];

    promises.push(
      API.getAllDepartments().then(departmentsRes => {
        const {departmentId: departmentElement} = this.state;
        this.setState({
          departmentId: {
            ...departmentElement,
            optionsField: util.addDataToStrings(departmentsRes.data),
          },
        });
        console.log('state object:', this.state);
      }),
    );

    promises.push(
      API.getAllBusinesses().then(businessesRes => {
        const {businessId: businessElement} = this.state;
        this.setState({
          businessId: {
            ...businessElement,
            optionsField: util.addDataToStrings(businessesRes.data),
          },
        });
      }),
    );

    Promise.all(promises)
      .then(() => {
        this.setState({
          popup: null,
        });
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        this.showErrorRetryPopup(errorMsg, this.getData);
      });
  };

  handleErrors1 = () => {
    let err = false;
    const update = {};
    const formData = [...formLeftFields, ...formRightFields];
    for (let i = 0; i < formData.length; i++) {
      let element = formData[i];
      const error = util.validate(this.state[element.field], element);
      if (!error) {
        update[element.field + '-error'] = {
          error: '',
        };
      }
      if (error) {
        err = true;
        update[element.field + '-error'] = {
          error: error,
        };
      }
    }
    this.setState(prevState => ({
      ...prevState,
      ...update,
    }));
    return err;
  };

  handleErrors = formData => {
    let err = false;
    const update = {};
    console.log('formData', formData, this.state);

    for (let i = 0; i < formData.length; i++) {
      let element = formData[i];
      const error = util.validate(
        this.state[element.stateField].value,
        element,
      );
      if (error) {
        err = true;
        update[element.stateField] = {
          ...this.state[element.stateField],
          error: error,
        };
      }
    }
    this.setState({
      ...update,
    });
    return err;
  };
  handlePage2Errors = () => {
    let err = false;
    const update = {};
    for (let i = 0; i < this.page2Config.length; i++) {
      // console.log('page2Config[i]', page2Config[i]);
      let requiredBool = false;
      if (!this.page2Config[i].required) {
        requiredBool = this.page2Config[i].formDetails.reduce((req, item) => {
          console.info('item.stateField', this.state[item.stateField]);
          if (item.elementType === 'file') {
            return req || !!this.state[item.stateField].value.length > 0;
          } else {
            return req || !!this.state[item.stateField].value;
          }
        }, false);
      }
      console.info('requiredBool', requiredBool);
      for (let j = 0; j < this.page2Config[i].formDetails.length; j++) {
        let element = this.page2Config[i].formDetails[j];
        const error = util.validate(this.state[element.stateField].value, {
          ...element,
          required: requiredBool || element.required,
        });
        if (error) {
          err = true;
          update[element.stateField] = {
            ...this.state[element.stateField],
            error: error,
          };
        }
      }
    }
    this.setState({
      ...update,
    });
    return err;
  };

  onClick = (action, event) => {
    const err = this.handleErrors();
    if (!err) {
      this.submitFormData();
    }
  };

  componentDidMount() {
    // this.showSpinner();
    this.getPreLoginToken();
    const {status} = this.props;
   // this.getData();
    if (status === 'EDIT') {
      this.getExistingCompanyInfo();
    }
  }

  getPreLoginToken = () => {
    API.getPreLoginToken()
      .then(response => {
        console.log(response);
        API.setPreLoginToken(response);
        this.getData();
      })
      .catch(error => {
        //this.showErrorPopup('');
        console.log(error);
      });
  };

  getExistingCompanyInfo = () => {
    console.log(this.props);
    const {status, location} = this.props;
    const {referenceId} = location;
    API.getAgencyDetailsByReferenceId(referenceId)
      .then(response => {
        console.log('agency details', response.data);
        this.setState({
          companyDetails: response.data,
          popup: null,
        });
        const {departmentId: departmentElement} = this.state;
        const {businessId: businessElement} = this.state;
        let temp = {};
        response.data?.companyDocuments.map(document => {
          if (document?.fileType === 'REGISTRATION_PROOF') {
            temp.registrationProof = document?.fileUrl
              ? [
                  {
                    fileUrl: document?.fileUrl,
                    fileName: document?.fileName.substr(
                      document?.fileName.length - 20,
                    ),
                  },
                ]
              : [];
          } else if (document?.fileType === 'GST_DOCUMENT') {
            temp.gstDocument = document?.fileUrl
              ? [
                  {
                    fileUrl: document?.fileUrl,
                    fileName: document?.fileName.substr(
                      document?.fileName.length - 20,
                    ),
                  },
                ]
              : [];
          } else if (document?.fileType === 'PAN_DOCUMENT') {
            temp.PANDocument = document?.fileUrl
              ? [
                  {
                    fileUrl: document?.fileUrl,
                    fileName: document?.fileName.substr(
                      document?.fileName.length - 20,
                    ),
                  },
                ]
              : [];
          } else if (document?.fileType === 'PANDocument') {
            temp.PANDocument = document?.fileUrl
              ? [
                  {
                    fileUrl: document?.fileUrl,
                    fileName: document?.fileName.substr(
                      document?.fileName.length - 20,
                    ),
                  },
                ]
              : [];
          }
        });
        const companyDetails = {
          ...response.data,
          ...temp,
          phoneNumber: response.data?.phoneNumber.slice(3),
          agencyLandlineNumber: response.data?.landlineNumber.slice(3),
          GSTNumber: response.data?.gstnumber,
          PANNumber: response.data?.pannumber,
        };
        console.log('company details:', temp, companyDetails);

        [...formLeftFields, ...formRightFields].forEach(obj => {
          console.log(obj?.stateField, companyDetails['' + obj.stateField]);
          if (obj.field === 'departmentId') {
            this.setState({
              [obj.stateField]: {
                ...departmentElement,
                value: response.data?.departmentId,
                error: null,
              },
            });
          } else if (obj.field === 'businessId') {
            this.setState({
              [obj.stateField]: {
                ...businessElement,
                value: response.data?.businessId,
                error: null,
              },
            });
          } else if (obj.inputType === 'upload') {
            this.setState({
              [obj.stateField]: {
                value: companyDetails['' + obj.stateField]
                  ? companyDetails['' + obj.stateField]
                  : [],
                error: null,
              },
            });
          } else {
            this.setState({
              [obj.stateField]: {
                value: companyDetails['' + obj.stateField],
                error: null,
              },
            });
          }
        });
        console.log('this.state:', this.state);
        this.editSignatory(companyDetails?.signatory);
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        console.log(errorMsg);
        this.showErrorPopup(errorMsg);
      });
  };

  editSignatory = signatoryList => {
    console.log(signatoryList);
    let preparedObject = {};
    const temp = signatoryList.map((element, index) => {
      console.log(element?.firstName + index);
      this.setState({['id' + index]: element?.id});
      return {
        ['authorizedDocument' + index]: element?.authorizedDocument,
        ['designation' + index]: element?.designation,
        ['documentValidity' + index]: element?.documentValidity,
        ['emailAddress' + index]: element?.emailAddress,
        ['identityProofDocument' + index]: element?.idProofDocument,
        ['name' + index]: element?.firstName,
        ['phoneNumber' + index]: element?.phoneNumber.slice(3),
        ['id' + index]: element?.id,
      };
    });
    const signatoriesObject = Object.assign({}, ...temp);
    const signatoryDetails = {
      ...signatoriesObject,
      authorizedDocument0: signatoriesObject?.authorizedDocument0
        ? [
            {
              fileUrl: signatoriesObject?.authorizedDocument0,
              fileName: signatoriesObject?.authorizedDocument0.substr(
                signatoriesObject?.authorizedDocument0.length - 20,
              ),
            },
          ]
        : [],
      authorizedDocument1: signatoriesObject?.authorizedDocument1
        ? [
            {
              fileUrl: signatoriesObject?.authorizedDocument1,
              fileName: signatoriesObject?.authorizedDocument1.substr(
                signatoriesObject?.authorizedDocument1.length - 20,
              ),
            },
          ]
        : [],
      authorizedDocument2: signatoriesObject?.authorizedDocument2
        ? [
            {
              fileUrl: signatoriesObject?.authorizedDocument2,
              fileName: signatoriesObject?.authorizedDocument2.substr(
                signatoriesObject?.authorizedDocument2.length - 20,
              ),
            },
          ]
        : [],
      identityProofDocument0: signatoriesObject?.identityProofDocument0
        ? [
            {
              fileUrl: signatoriesObject?.identityProofDocument0,
              fileName: signatoriesObject?.identityProofDocument0.substr(
                signatoriesObject?.identityProofDocument0.length - 20,
              ),
            },
          ]
        : [],
      identityProofDocument1: signatoriesObject?.identityProofDocument1
        ? [
            {
              fileUrl: signatoriesObject?.identityProofDocument1,
              fileName: signatoriesObject?.identityProofDocument1.substr(
                signatoriesObject?.identityProofDocument1.length - 20,
              ),
            },
          ]
        : [],
      identityProofDocument2: signatoriesObject?.identityProofDocument2
        ? [
            {
              fileUrl: signatoriesObject?.identityProofDocument2,
              fileName: signatoriesObject?.identityProofDocument2.substr(
                signatoriesObject?.identityProofDocument2.length - 20,
              ),
            },
          ]
        : [],
    };

    console.log('temp:', temp, signatoryDetails);

    this.page2Config.map(singleSignatoryObject => {
      singleSignatoryObject.formDetails.map(obj => {
        this.setState({
          [obj.stateField]: {
            value: signatoryDetails['' + obj.stateField],
            error: null,
          },
        });
      });
    });
    console.log(preparedObject);
  };

  handelNextButton = () => {
    let formData = [...formLeftFields, ...formRightFields];
    const err = this.handleErrors(formData);
    if (!err) {
      this.setState({currentStep: registrationSteps.STEP_2});
    }
  };
  handlePrevButton = () => {
    this.setState({currentStep: registrationSteps.STEP_1, terms: false});
  };

  openOTPPopUp = () => {
    this.setState(
      {
        currentOtpVerifiactionObject: {
          field: 'phoneNumber',
          phoneNumber: this.state['phoneNumber'].value,
          verifiedNumberFields: {
            ...this.state?.currentOtpVerifiactionObject?.verifiedNumberFields,
          },
        },
      },
      () => {
        this.generateOtp(this.state.currentOtpVerifiactionObject.phoneNumber);
      },
    );
  };

  handleSubmit = () => {
    const {otpVerified} = this.state;
    let error = this.handlePage2Errors(this.page2Config);

    if (error) {
      this.closePopup();
    } else if (!otpVerified) {
      this.openOTPPopUp();
    } else {
      this.submitFormData()
        .then(res => {
          console.log('Res', res);
          if (res && res.referenceId) {
            let referenceId = res.referenceId;
            console.log('REFERENCE ID: ', referenceId);
            this.props.history.push({
              pathname: 'registration-success',
              state: {
                id: referenceId,
                title: strings.newAgencyRegistration,
              },
            });
          }
        })
        .catch(errors => {
          const errorMsg = util.getErrorMessage(errors);
          this.showErrorPopup(errorMsg);
        });
    }
  };

  onClickHandler = (action, data) => {
    switch (action) {
      case 'otp-verify': {
        this.setState(
          {
            currentOtpVerifiactionObject: {
              field: data.stateField,
              phoneNumber: this.state[data.stateField].value,
              verifiedNumberFields: {
                ...this.state?.currentOtpVerifiactionObject
                  ?.verifiedNumberFields,
              },
            },
          },
          () => {
            this.generateOtp(
              this.state.currentOtpVerifiactionObject.phoneNumber,
            );
          },
        );

        break;
      }
      default: {
        console.info('case not handled', action);
      }
    }
  };

  generateOtp = async phoneNumber => {
    this.showSpinner();
    if (!phoneNumber || phoneNumber.toString().length !== 10) {
      this.showErrorPopup('Please enter a valid phone number');
    } else {
      const result = util.validatePhoneNumber(phoneNumber);
      if (!result) {
        this.showErrorPopup('Please enter a valid phone number');
        return;
      }
      await API.generateOtp({phoneNumber: `+91${phoneNumber}`})
        .then(res => {
          this.setState({
            expiryMinutes: res?.data?.expiryMinutes,
          });
          this.showOtpPopup();
        })
        .catch(error => {
          console.log(error);
          // const errorMsg = error.response
          const errorMsg = util.getErrorMessage(error);
          // const errorMsg =
          //   'Invalid Phone Number. Please enter a valid phone number.';
          this.showErrorPopup(errorMsg);
        });
    }
  };

  onOtpVerifySuccess = isSucess => {
    const {currentOtpVerifiactionObject} = this.state;
    if (true) {
      this.setState({
        otpVerified: isSucess,
        currentOtpVerifiactionObject: {
          ...this.state.currentOtpVerifiactionObject,
          otpVerified: isSucess,
          verifiedNumberFields: {
            ...this.state.currentOtpVerifiactionObject.verifiedNumberFields,
            [currentOtpVerifiactionObject.field]: isSucess,
          },
        },
      });
    }
  };

  getProps = () => {
    const {history} = this.props;
    const {currentStep, errorMessage, currentOtpVerifiactionObject, terms} =
      this.state;
    return {
      onClick: this.onClickHandler,
      onChange: this.onChange,
      handleSubmit: this.handleSubmit,
      onBlur: this.onBlur,
      onChangeHandler: this.onChangeHandler,
      handleUpload: this.handleUpload,
      getInputType: this.getInputType,
      handelNextButton: this.handelNextButton,
      handlePrevButton: this.handlePrevButton,
      termsClick: this.termsClick,
      terms,
      history,
      currentStep,
      errorMessage,
      stateData: this.state,
      otpVerifiedField: currentOtpVerifiactionObject,
      ...this.state,
    };
  };

  closePopup = () => {
    console.log('close popup called...');
    this.setState({
      popup: undefined,
    });
  };

  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  showTermsPopup = () => {
    console.log('showTermsPopup');
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.TERMS_POPUP,
        onClose: this.closePopup,
        onButtonClick: this.acceptTerms,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  acceptTerms = () => {
    console.log('accept terms...', this.state.terms);
    this.setState({
      terms: true,
      popup: null,
    });
  };

  toggleChecked = () => {
    // Open terms popup
    const {terms} = this.state;
    if (!terms) {
      this.showTermsPopup();
    } else {
      this.setState({
        terms: false,
      });
    }
  };
  showSuccessPopup = successMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: successMessage,
        onClose: this.closePopup,
        headingImage: operatorImages.successIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  showErrorRetryPopup = (errorMsg, retryCallback) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMsg,
        messageStyle: {color: '#E67717'},

        buttons: [
          {
            text: strings.cancelPopup,
            onClick: this.closePopup,
            outline: true,
          },
          {
            text: strings.retryApiPopup,
            onClick: () => {
              this.closePopup();
              retryCallback();
            },
          },
        ],
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
      },
    });
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  getPopupContent = () => {
    const {popup, phoneNumber} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="blue" />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }

      case POPUP_CONSTANTS.OTP_POPUP: {
        return (
          <OtpPopup
            onClose={this.closePopup}
            phoneNumber={this.state?.currentOtpVerifiactionObject.phoneNumber}
            onOtpVerifySuccess={this.onOtpVerifySuccess}
            message={
              strings.message +
              phoneNumber.value.substr(phoneNumber.value.length - 4)
            }
            resendOTP={this.generateOtp}
            showSuccessPopup={() =>
              this.showSuccessPopup('Phone number successfully verified!')
            }
            showErrorPopup={errorMessage => {
              this.showErrorPopup(errorMessage);
            }}
            expiryMinutes={this.state?.expiryMinutes}
          />
        );
      }
      case POPUP_CONSTANTS.TERMS_POPUP: {
        return (
          <Privacy
            onClose={this.closePopup}
            messageObject={termsData}
            heading="Terms and Conditions"
            {...popup}
          />
        );
      }
      default: {
        console.warn('case not handled', popup.type);
        return null;
      }
    }
  };
  showOtpPopup() {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.OTP_POPUP,
      },
    });
  }
  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <Metadata title={PAGE_TITLES.newAgencyRegistration} />
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

// export default NewAgencyRegistration;
export default withRouter(NewAgencyRegistration);
