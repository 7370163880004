import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {commonImages} from '../../../../assets/images';
import PopupContainer from '../../../../components/PopupContainer';
import POPUP_CONSTANTS from '../../../../constants/popup-constants';
import strings from '../../../../globalization';
import {API} from '../../../../requests';
import styles from './styles.module.scss';

import Spinner from 'react-spinkit';
import Popup from '../../../../components/SimplePopup';

import ViewRenderer from './views';
import util from '../../../../util';
import {
  cargoHandlingFormData,
  engineeringMaterialFormData,
  exportCargoFormData,
  importCargoFormData,
} from './data';
import {dispatchUpdateMaterial} from '../../../../store';
import moment from 'moment';

const MATERIAL_TYPE_OPTIONS = {
  IMPORT_CARGO: 1,
  EXPORT_CARGO: 2,
  CARGO_HANDLING_EQUIPMENT: 3,
  ENGINEERING_HANDLING: 4,
};
class AddMaterial extends Component {
  constructor(props) {
    super(props);

    this.formData = [];
    this.state = {
      IMONumber: '',
      IGMNumber: '',
      VCNNumber: '',
      materialTypesOptions: [],
      materialType: '',
      vesselType: null,
    };
  }

  componentDidMount = async () => {
    this.fetchAllMaterialTypes();
    await this.fetchOptions();
  };

  fetchAllMaterialTypes = () => {
    API.fetchAllMaterialTypes()
      .then(res => {
        console.log('material Options...', res.data);
        this.setState({
          materialTypeOptions: res.data,
        });
      })
      .catch(err => {
        const errorMsg = util.getErrorMessage(err);

        this.showErrorPopup(errorMsg);
      });
  };

  isEmptyObject = obj => {
    return Object.keys(obj).length === 0;
  };
  fetchImportMaterialData = BillOfEntryNo => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });

    API.fetchImportMaterialData({
      BillOfEntryNo: BillOfEntryNo,
    })
      .then(res => {
        console.log('material Options...', res.data);
        this.setState({
          importMaterialData: res.data,
          exportMaterialData: null,
          popup: undefined,
        });
        if (this.isEmptyObject(res.data)) {
          this.showErrorPopup(
            'Import data not available for the given BOE number, Please enter manually',
          );
        }
      })
      .catch(err => {
        const errorMsg = util.getErrorMessage(err);
        this.showErrorPopup(errorMsg);
      });
  };

  fetchExportMaterialData = ShippingBillNumber => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
    API.fetchExportMaterialData({
      SBNUMBER: ShippingBillNumber,
    })
      .then(res => {
        console.log('material Options...', res.data);
        this.setState({
          exportMaterialData: res.data,
          importMaterialData: null,
          popup: undefined,
        });
        if (this.isEmptyObject(res.data)) {
          this.showErrorPopup(
            'Export data not available for the given SB number, Please enter manually',
          );
        }
      })
      .catch(err => {
        const errorMsg = util.getErrorMessage(err);
        this.showErrorPopup(errorMsg);
      });
  };

  goBack = () => {
    console.log('goBack Called!!-->');
    const {history} = this.props;
    history.goBack();
  };
  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  onClick = (action, data) => {
    const {dispatch, reducerState} = this.props;
    const {history, location} = this.props;
    console.log('location', location);
    switch (action) {
      case 'cancel': {
        history.goBack();
        break;
      }

      case 'addMaterial': {
        const {materialType, materialTypeOptions} = this.state;
        const {reducerState, location} = this.props;
        const materialTypeObject = materialTypeOptions.find(
          elem => elem.id === materialType.value,
        );
        const id = util.generateUniqueId();
        console.log('Hit material type', materialTypeObject, data);
        // const selectFromOrTo = this.state.selectFromOrTo || {};
        const vesselType = this.state.vesselType.value || {};
        let materialDetails = {};
        const {stateData, lineInfo} = data;
        // Forming the material details object
        Object.keys(stateData).forEach((key, idx) => {
          let value;
          if (key === 'proofDocument' || key === 'cartingPermissionDocument') {
            value = stateData[key].value[0].fileUrl;
          } else if (
            key === 'validDate' ||
            key === 'cartingPermissionDate' ||
            key === 'requestValidUpto' ||
            key === 'deliveryDate' ||
            key === 'IGMDate' ||
            key === 'IADate' ||
            key === 'ShippingBillDate' ||
            key === 'deliveryOrderDate'
          ) {
            value = util.getDateTimeString(stateData[key].value, 'mmddyyyy');
            console.log('date value...', stateData[key].value);
            console.log('date value after...', value);
          } else if (stateData[key].length > 0) {
            value = stateData[key];
          } else {
            value = stateData[key].value;
          }
          materialDetails[key] = value;
        });

        console.log('MATERIAL DETAILS... Add Material', data);
        const {comingFrom} = location.state;
        if (comingFrom === 'addVehiclesPage') {
          // For global table.
          dispatch({
            type: 'update-material',
            payload: {
              id,
              vehicleId: reducerState.currentVehicle.vehicle.id,
              materialType: materialTypeObject,
              materialTypeId: materialTypeObject?.id,
              vesselType,
              lineInfo,
              ...materialDetails,
            },
          });
          history.push('/u/permits/add-new-vehicle', {
            ...location.state,
          });
        } else if (comingFrom === 'requestNewPermit') {
          // redux dispatch
          dispatchUpdateMaterial({
            id,
            vehicleId: reducerState.currentVehicle.vehicle.id,
            materialType: materialTypeObject,
            vesselType,
            materialTypeId: materialTypeObject?.id,
            lineInfo,
            ...materialDetails,
          });

          history.push('/u/permits/request-permits');
        } else {
          // Coming from add drivers page
          dispatch({
            type: 'add-material',
            payload: {
              id,
              vehicleId: reducerState.currentVehicle.vehicle.id,
              materialType: materialTypeObject,

              vesselType,
              materialTypeId: materialTypeObject?.id,
              lineInfo,
              ...materialDetails,
            },
          });
          history.goBack();
        }

        break;
      }
      case 'fetchMaterialData': {
        //  console.log(action, data, this.state);
        const {BillOfEntryNo} = data;
        this.fetchImportMaterialData(BillOfEntryNo);
        break;
      }
      case 'fetchExportMaterialData': {
        //  console.log(action, data, this.state);
        const {ShippingBillNumber} = data;
        this.fetchExportMaterialData(ShippingBillNumber);
        break;
      }
      case 'checkBox': {
        console.log(action, data);
      }
      case 'showLineInfoError': {
        this.showErrorPopup(data);
        break;
      }
      default: {
        console.warn('No action found...', action);
        break;
      }
    }
  };

  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }

      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };
  closePopup = () => {
    this.setState({
      popup: null,
    });
  };

  prepareFormData = formData => {
    console.log('formData in preparedFormData-->', formData);
    let preparedObject = {};
    formData.map(obj => {
      preparedObject[obj.stateField] = {value: obj.stateDefault, error: null};
      if (obj.optionsField) {
        preparedObject[obj.optionsField] = [];
      }
    });
    console.log('preparedObject', preparedObject);
    this.setState(prevState => ({
      ...prevState,
      ...preparedObject,
    }));
  };
  onBlur = (field, element) => {
    const currentObj = this.state[field];

    console.log('formField', element);
    this.setState({
      [field]: {
        ...currentObj,
        error: element ? util.validate(currentObj.value, element) : null,
      },
    });
  };

  fetchOptionsBoilerplate = (stateField, callingFunction) => {
    callingFunction()
      .then(optionsRes => {
        this.setState({
          [stateField]: optionsRes.data,
        });
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        console.log(errorMsg);
        this.showErrorPopup(errorMsg);
      });
  };

  fetchOptions = async () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
    let promises = [];
    promises.push(
      this.fetchOptionsBoilerplate(
        'natureOfCargoOptions',
        API.fetchAllNatureOfCargo,
      ),
    );
    promises.push(
      this.fetchOptionsBoilerplate(
        'unitsOfWeightOptions',
        API.fetchAllUnitOfWeight,
      ),
    );
    promises.push(
      this.fetchOptionsBoilerplate(
        'permitTypeOptions',
        API.fetchAllPermitTypes,
      ),
    );
    promises.push(
      this.fetchOptionsBoilerplate(
        'vesselTypeOptions',
        API.getActiveVesselTypes,
      ),
    );

    Promise.all(promises)
      .then(res => {
        this.setState({
          popup: null,
        });
      })
      .catch(error => console.log(error));
  };

  setFormFields = value => {
    switch (value) {
      case MATERIAL_TYPE_OPTIONS.EXPORT_CARGO: {
        this.formData = exportCargoFormData;
        break;
      }
      case MATERIAL_TYPE_OPTIONS.IMPORT_CARGO: {
        this.formData = importCargoFormData;
        break;
      }
      case MATERIAL_TYPE_OPTIONS.CARGO_HANDLING_EQUIPMENT: {
        this.formData = cargoHandlingFormData;
        break;
      }
      case MATERIAL_TYPE_OPTIONS.ENGINEERING_HANDLING: {
        this.formData = engineeringMaterialFormData;
        break;
      }
      default: {
        this.formData = [];
        console.warn('case not handled...', value);
      }
    }
  };
  onChangeHandler = (field, value) => {
    console.log('field', field, 'value', value);

    if (field === 'materialType' && value === 1)
      this.setState({isImport: true, isExport: false});
    else if (field === 'materialType' && value === 2) {
      this.setState({isExport: true, isImport: false});
    } else if (field === 'materialType') {
      this.setState({isImport: false, isExport: false});
    }
    this.setState(prevState => ({
      ...prevState,
      [field]: {
        value,
      },
    }));
    //  const {from, to, materialType} = this.state;
    if (field === 'materialType') {
      this.setFormFields(value);
    }
    if (field === 'vesselType') {
      this.setState({
        importMaterialData: null,
        exportMaterialData: null,
        popup: undefined,
      });
    }

    console.log('this.state', this.state);
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };
  handleUpload = (field, files) => {
    // this.showSpinner();
    if (files === null) {
      throw new Error('File does not exist');
    }

    let filesArray = [];
    for (let i = 0, numFiles = files.length; i < numFiles; i++) {
      filesArray.push(files[i]);
    }

    console.log('filesArray', filesArray);

    // FIXME: Fix real uploading thing.

    let promises = filesArray.map(file => {
      const data = new FormData();
      data.append('s3FolderName', 'company');
      data.append('file', file);
      return API.uploadToS3(data).then(response => {
        console.log('Response Here', response.data);
        const url = response.data;
        return {fileUrl: url, fileName: file.name};
      });
    });

    return Promise.all(promises)
      .then(response => {
        this.setState(prevState => ({
          popup: null,
        }));
        return response;
      })
      .catch(error => {
        console.log('error', error);
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;

        this.setState({
          popup: null,
        });

        console.log(errorMsg);
      });
  };
  getProps = () => {
    const {reducerState} = this.props;
    const vehicle = reducerState.currentVehicle.vehicle;

    const {
      natureOfCargoOptions,
      unitsOfWeightOptions,
      permitTypeOptions,
      isImport,
      isExport,
      importMaterialData,
      exportMaterialData,
      vesselTypeOptions,
      vesselType,
    } = this.state;
    const options = {
      natureOfCargoOptions,
      unitsOfWeightOptions,
      permitTypeOptions,
    };
    return {
      goBack: this.goBack,
      onChange: this.onChangeHandler,
      // stateData: this.state,
      vehicle,
      formData: this.formData,
      onBlur: this.onBlur,
      onClick: this.onClick,
      handleUpload: this.handleUpload,
      options,
      materialType: this.state.materialType,
      materialTypeOptions: this.state.materialTypeOptions,
      IMONumber: this.state.IMONumber,
      IGMNumber: this.state.IGMNumber,
      VCNNumber: this.state.VCNNumber,
      isImport: isImport,
      isExport,
      importMaterialData: importMaterialData,
      exportMaterialData,
      vesselTypeOptions,
      vesselType,
    };
  };

  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer containerStyle={styles.popupContainerStyle}>
            {' '}
            {this.getPopupContent()}{' '}
          </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

// export default withRouter(PersonCards);
export default withRouter(AddMaterial);
