import React, {useState, useEffect} from 'react';
import styles from './styles.module.scss';

import Flex from '../../../../../../components/Flex';
import PermitsHeader from '../../../../../../components/PermitsHeader';

import FormElement from '../../../../../../components/FormElement';
import Button from '../../../../../../components/Button';
import VehicleDetails from '../../../../../../components/VehicleDetails';

import strings from '../../../../../../globalization';

import dimensions from '../../../../../../util/dimensions-util';
import './styles.css';
import SannaLogo from '../../../../../../components/SannaLogo';
import {customStyles} from './customStyles';

import dropDown from '../../../../../../assets/images/drop-down/dropdown@3x.png';
import calendarBlue from '../../../../../../assets/images/calendar-blue-icon/image.png';
import moment from 'moment';
import DateRangePicker from 'react-daterange-picker';
import TimePicker from 'rc-time-picker';
import {AgGridReact} from 'ag-grid-react';
import PersonVehicleDetails from '../../../../../../components/PersonVehicleLatestActivityDetails';
import {adminImages} from '../../../../../../assets/images';

const {vw: widthConstant, vh: heightConstant} = dimensions;

const tabs = [
  {
    label: 'Person Details',
    id: 1,
    route: 'person-details',
  },
  {
    label: 'Vehicle Details',
    id: 2,
    route: 'vehicle-details',
  },
  {
    label: 'Agency Details',
    id: 3,
    route: 'agency-details',
  },
];

const columnDefs = [
  {
    headerName: 'Date',
    field: 'createDate',
    headerClass: styles.rowHeader,
    minWidth: 150 * widthConstant,
    resizable: true,
    wrapText: true,
    flex: 1,
    autoHeight: true,
    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: params => {
      const {data} = params;
      if (params?.data !== undefined) {
        console.log('params:', params);
        const {createdDate} = params?.data;
        return (
          <div className={styles.sessionTime}>
            {moment(createdDate).format('DD/MM/YYYY')}
          </div>
        );
      } else {
        return null;
      }
    },
  },
  {
    headerName: 'Gate',
    field: 'gate',
    headerClass: styles.rowHeader,
    minWidth: 150 * widthConstant,
    resizable: true,
    flex: 1,
    wrapText: true,
    autoHeight: true,
    cellStyle: {display: 'flex', alignItems: 'center', lineHeight: 1.5},
    cellRendererFramework: params => {
      if (params?.data !== undefined) {
        const {readerName} = params?.data;
        return <div className={styles.sessionTime}>{readerName}</div>;
      } else {
        return null;
      }
    },
  },
  {
    headerName: 'Gate Movement',
    field: 'gateMovement',
    headerClass: styles.rowHeader,
    minWidth: 150 * widthConstant,
    resizable: true,
    flex: 1,
    wrapText: true,
    autoHeight: true,
    cellStyle: {display: 'flex', alignItems: 'center', lineHeight: 1.5},
    cellRendererFramework: params => {
      if (params?.data !== undefined) {
        const {vehicleMovement} = params?.data;
        return <div className={styles.sessionTime}>{vehicleMovement}</div>;
      } else {
        return null;
      }
    },
  },
  {
    headerName: 'Status at the gate',
    field: 'statusAtGate',
    headerClass: styles.rowHeader,
    minWidth: 250 * widthConstant,
    resizable: true,
    flex: 1,
    wrapText: true,
    autoHeight: true,
    cellStyle: {display: 'flex', alignItems: 'center', lineHeight: 1.5},
    cellRendererFramework: params => {
      if (params?.data !== undefined) {
        const {status} = params?.data;
        return <div className={styles.sessionTime}>{status}</div>;
      } else {
        return null;
      }
    },
  },
  {
    headerName: 'Driver RFID',
    field: 'driverRFID',
    headerClass: styles.rowHeader,
    minWidth: 350 * widthConstant,
    resizable: true,
    flex: 1,
    wrapText: true,
    autoHeight: true,
    cellStyle: {display: 'flex', alignItems: 'center', lineHeight: 1.5},
    cellRendererFramework: params => {
      if (params?.data !== undefined) {
        const {driverRFID} = params?.data;
        return <div className={styles.sessionTime}>{driverRFID}</div>;
      } else {
        return null;
      }
    },
  },
  {
    headerName: 'CHA Name(If approved)',
    field: 'CHAName',
    headerClass: styles.rowHeader,
    minWidth: 350 * widthConstant,
    resizable: true,
    flex: 1,
    wrapText: true,
    autoHeight: true,
    cellStyle: {display: 'flex', alignItems: 'center', lineHeight: 1.5},
    cellRendererFramework: params => {
      if (params?.data !== undefined) {
        const {companyName} = params?.data;
        return <div className={styles.sessionTime}>{companyName}</div>;
      } else {
        return null;
      }
    },
  },
  {
    headerName: 'Reason for rejection',
    field: 'rejectionReason',
    headerClass: styles.rowHeader,
    minWidth: 350 * widthConstant,
    resizable: true,
    flex: 1,
    wrapText: true,
    autoHeight: true,
    cellStyle: {display: 'flex', alignItems: 'center', lineHeight: 1.5},
    cellRendererFramework: params => {
      if (params?.data !== undefined) {
        const {error} = params?.data;
        return <div className={styles.sessionTime}>{error}</div>;
      } else {
        return null;
      }
    },
  },
  {
    headerName: 'Timestamp',
    field: 'timestamp',
    headerClass: styles.rowHeader,
    width: 350 * widthConstant,
    resizable: true,
    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: params => {
      if (params?.data !== undefined) {
        const {createdDate} = params?.data;
        return <div>{moment(createdDate).format('DD/MM/YYYY HH:mm')}</div>;
      } else {
        return null;
      }
    },
  },
];

export default function Desktop({
  containerClassNames,
  onChangeHandler,
  stateData,
  formData,
  onClick,
  serverTime,
  onSubmit,
}) {
  const {vehicleData} = stateData;
  console.log(stateData);
  const [requestType, setRequestType] = useState(null);

  const placeholderTextConstant = {
    RFID: {
      label: 'RFID Number',
      placeholder: 'Enter RFID Number',
    },
    vehicleNumber: {
      label: 'Vehicle Number',
      placeholder: 'Enter Vehicle Number',
    },
  };

  const {
    vehicleLatestActivity,
    dates,
    vehicleActivityList,
    startTime,
    endTime,
    totalCount,
  } = stateData;

  const [showDate, setShowDate] = useState(false);
  const [showVehicleActivityTable, setShowVehicleActivityTable] =
    useState(false);
  const [showLatestActivity, setShowLatestActivity] = useState(false);

  const [startTimePanel, setStartTimePanel] = useState(null);
  const [endTimePanel, setEndTimePanel] = useState(null);

  const [closePopup, setClosePopup] = useState(false);
  const [remarks, setRemarks] = useState(null);

  console.log(totalCount, stateData);
  const [gridApi, setGridApi] = useState(null);
  const onGridReady = params => {
    setGridApi(params.api);
    onChangeHandler('gridAPI', params.api);
  };
  const updateTable = () => {
    onChangeHandler('updateTable', null);
  };

  useEffect(() => {
    console.log(gridApi);
    if (gridApi && totalCount > 10) {
      console.log('changing to normal');
      gridApi.setDomLayout('normal');
    } else if (gridApi) {
      console.log('changing to autoHeight');
      gridApi.setDomLayout('autoHeight');
    }
  }, [totalCount, gridApi]);

  useEffect(() => {
    console.log(gridApi);
    updateTable();
  }, [gridApi]);

  const onCellClick = (event, index) => {
    onClick('rowClick', event.data);
  };
  const onSelect = dates => {
    onChangeHandler('date', {
      startDate: moment(dates.start).format('MM-DD-YYYY'),
      endDate: moment(dates.end).format('MM-DD-YYYY'),
      dates,
    });
    toggleDate();
  };
  const toggleDate = () => {
    setShowDate(!showDate);
  };

  const formatDate = date => moment(date).format('MMM Do YY');
  const rowStyle = {border: 'none', cursor: 'pointer'};
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return {background: '#f6f6f6', border: 'none'};
    }
  };
  const showVehicleTable = () => {
    setShowVehicleActivityTable(!showVehicleActivityTable);
  };
  const latestActivity = () => {
    setShowLatestActivity(!showLatestActivity);
  };
  const handleClear = () => {
    setShowDate(false);
    onClick('clearFilters');
  };

  const onTabClick = id => {
    switch (id) {
      case 1:
        onClick('statusRoute', {route: 'person-details', id: id});
        break;
      case 2:
        onClick('statusRoute', {route: 'vehicle-details', id: id});
        break;
      case 3:
        onClick('statusRoute', {route: 'agency-details', id: id});
        break;
      default:
        break;
    }
  };
  const blackListVehicle = data => {
    // onClick(data);
    setClosePopup(true);
  };

  return (
    <div className={[styles.container, containerClassNames].join(' ')}>
      <PermitsHeader
        onTabClick={onTabClick}
        title="View Vehicle Details"
        tabs={tabs}
        activeTab={2}
      />
      <Flex className={styles.formContainer}>
        <FormElement
          type="dropdown"
          label={strings.requestTypeLabel}
          required={true}
          placeholderText={strings.requestTypePlaceholder}
          onChange={text => {
            setRequestType(text);
            onChangeHandler('requestType', text);
          }}
          value={stateData.requestType}
          maxLength={30}
          options={stateData.requestTypeOptions}
          dropdownContainerStyle={styles.dropdownStyle}
          styles={customStyles}
        />
        <FormElement
          uppercase={true}
          type="text"
          label={
            requestType === 1
              ? placeholderTextConstant.RFID.label
              : placeholderTextConstant.vehicleNumber.label
          }
          required={true}
          placeholderText={
            requestType === 1
              ? placeholderTextConstant.RFID.placeholder
              : placeholderTextConstant.vehicleNumber.placeholder
          }
          onChange={text => {
            onChangeHandler(
              requestType === 1 ? 'RFIDNumber' : 'vehicleNumber',
              text,
            );
          }}
          value={
            requestType === 1 ? stateData.RFIDNumber : stateData.vehicleNumber
          }
          maxLength={30}
          containerClassNames={styles.inputBox}
        />
        {/* <FormElement
          type="text"
          label={'RFID Number'}
          required={true}
          placeholderText={'Enter RFID Number'}
          onChange={text => {
            onChangeHandler('RFIDNumber', text);
          }}
          value={stateData.RFIDNumber}
          maxLength={30}
          containerClassNames={styles.inputBox}
        /> */}
        <Button
          buttonStyle="primary"
          label={`Submit`}
          className={styles.submitButton}
          onClick={onSubmit}
        />
      </Flex>
      <div>
        {' '}
        {vehicleData ? (
          <VehicleDetails
            onBlackList={blackListVehicle}
            onUnBlackList={id => {
              onClick('unBlackList', id);
            }}
            onClick={onClick}
            vehicleDetails={vehicleData}
          />
        ) : null}
      </div>

      {vehicleData ? (
        <Slider
          title={'Latest Activity'}
          max={showLatestActivity}
          onClick={latestActivity}
        />
      ) : null}
      {vehicleData && showLatestActivity ? (
        <div className={styles.latestActivity}>
          <PersonVehicleDetails
            onClick={onClick}
            details={vehicleLatestActivity}
          />
        </div>
      ) : null}
      {vehicleData ? (
        <Slider
          title={'History'}
          max={showVehicleActivityTable}
          onClick={showVehicleTable}
        />
      ) : null}
      {vehicleData && showVehicleActivityTable ? (
        <div className={styles.tableContainer}>
          <div className={styles.header}>
            <div className={styles.left}>
              <Flex direction="column" className={styles.leftTop}>
                <Flex direction="row">
                  <div className={styles.dateWrapper}>
                    <div onClick={toggleDate} className={styles.inputDate}>
                      {' '}
                      <div>
                        {' '}
                        {dates
                          ? formatDate(dates.start) +
                            '-' +
                            formatDate(dates.end)
                          : strings.ADMIN.dateRange}
                      </div>{' '}
                      <img
                        src={calendarBlue}
                        className={styles.calendar}
                        alt="C"
                      />
                    </div>
                    {showDate ? (
                      <div className={styles.datePicker}>
                        <DateRangePicker
                          numberOfCalendars={1}
                          selectionType="range"
                          onSelect={onSelect}
                          className={styles.dateRange}
                          value={dates}
                          singleDateRange={true}
                        />
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <TimePicker
                      // use12Hours
                      showSecond={false}
                      addon={panel => setStartTimePanel(panel)}
                      // FIXME: Change these
                      value={startTime}
                      onChange={time => {
                        onChangeHandler('startTime', time);
                        if (
                          startTimePanel?.state?.currentSelectPanel === 'minute'
                        ) {
                          startTimePanel.close();
                        }
                      }}
                      className={styles.timepicker}
                      placeholder={`Start Time`}
                      format={'HH:mm'}
                    />
                    <TimePicker
                      // use12Hours
                      addon={panel => setEndTimePanel(panel)}
                      showSecond={false}
                      // FIXME: Change these
                      value={endTime}
                      onChange={time => {
                        onChangeHandler('endTime', time);
                        if (
                          endTimePanel?.state?.currentSelectPanel === 'minute'
                        ) {
                          endTimePanel.close();
                        }
                      }}
                      className={styles.timepicker}
                      placeholder={`End Time`}
                      format={'HH:mm'}
                    />
                  </div>
                </Flex>
              </Flex>
            </div>

            <div className={styles.right}>
              <div onClick={handleClear} className={styles.clearLabel}>
                {' '}
                {strings.ADMIN.clear}
              </div>
              <Button
                onClick={() => {
                  setShowDate(false);
                  onClick('apply');
                }}
                buttonStyle="primary"
                label={strings.ADMIN.apply}
                className={styles.applyBtn}></Button>
            </div>
          </div>
          <div
            className={[
              'ag-theme-balham',
              totalCount > 10
                ? styles.tableWrapperNormal
                : styles.tableWrapperAutoHeight,
            ].join(' ')}>
            <AgGridReact
              onGridReady={onGridReady}
              rowStyle={rowStyle}
              getRowStyle={getRowStyle}
              rowHoverHighlight={true}
              columnDefs={columnDefs}
              rowHeight={55 * heightConstant}
              headerHeight={60 * heightConstant}
              defaultColDef={{sortable: true}}
              rowClass={[styles.gridRowClass]}
              pagination={true}
              paginationPageSize={100}
              rowModelType={'infinite'}
              animateRows={true}
              onCellClicked={(event, index) => onCellClick(event, index)}
              domLayout="autoHeight"></AgGridReact>
          </div>
          <div className={styles.bottomLabel}> Total Count: {totalCount}</div>
        </div>
      ) : null}
      {closePopup ? (
        <BlackListPopUp
          onClose={() => {
            setClosePopup(false);
          }}
          setRemarks={text => {
            setRemarks(text);
          }}
          vehicle={vehicleData}
          remarks={remarks}
          onSubmit={() => {
            onClick('blackList', {id: vehicleData?.id, remarks: remarks});
            setRemarks(null);
            setClosePopup(false);
          }}
        />
      ) : null}
      <div className={styles.SannaLogo}>
        <SannaLogo />
      </div>
    </div>
  );
}

const Slider = ({onClick, max, title}) => {
  return (
    <Flex
      className={[styles.slider, max ? styles.sliderOpen : ''].join(' ')}
      onClick={onClick}>
      <div>{title}</div>
      <img
        src={dropDown}
        alt={'u'}
        className={max ? styles.dropDownRotate : styles.dropDownIcon}
      />
    </Flex>
  );
};

const BlackListPopUp = ({onSubmit, remarks, vehicle, onClose, setRemarks}) => {
  return (
    <div className={styles.popUpContainer}>
      <div className={[styles.container].join(' ')}>
        <div className={styles.secondContainer}>
          {onClose ? (
            //  <span className={styles.closeIcon}>X </span>
            <img
              alt="close"
              src={adminImages.closeIcon}
              className={styles.closeIcon}
              onClick={onClose}
            />
          ) : null}

          <div className={styles.heading}>
            {' '}
            {'Blacklist vehicle -' + vehicle?.vehicleRegistrationNumber}
          </div>
          <div className={styles.bodyContainer}></div>

          <Flex direction="column" className={styles.textareaContainer}>
            <div style={{textAlign: 'center', marginBottom: 20}}>
              Are you sure want to blacklist the vehicle tag? <br />
              If yes please add the reason in the below box and submit.
            </div>
            <textarea
              rows="5"
              maxlength="500"
              className={styles.remark}
              value={remarks}
              onChange={e => setRemarks(e.target.value)}
              placeholder={strings.typeYourRemarks}></textarea>

            <Button
              buttonStyle="primary"
              label={`Submit`}
              className={styles.finalButton}
              onClick={onSubmit}
            />
          </Flex>
        </div>
      </div>
    </div>
  );
};
