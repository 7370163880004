/**
 *
 */
import moment from 'moment';
import LANGUAGE_PREFERENCES from '../constants/language-preferences';
import strings from '../globalization';
class UTIL {
  validatePhoneNumber = phonenumber => {
    if (typeof phonenumber === 'object') {
      return false;
    }
    var phoneno = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (phonenumber && phonenumber.match(phoneno)) {
      return true;
    } else {
      return false;
    }
  };

  classnames = (...classes) => classes.join(' ');

  getTimeOrDate = d => {
    let date = moment(d);
    let today = moment();

    let difference = today.diff(date, 'days');
    console.log('difference', difference);
    if (difference < 1) {
      return date.format('h:mm a');
    } else {
      return date.format('MMM Do YY');
    }
  };
  /**
   *
   * @param {*} email
   * @return {Boolean}
   */
  validateEmail = email => {
    // eslint-disable-next-line max-len
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  /**
   *
   * @param {*} password
   * @return {Boolean}
   */
  validatePassword = password => {
    const re =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
    return re.test(String(password));
  };

  throwError = message => {
    alert.show(message);
  };

  getDateTimeString(date, format = 'ddmmyyyy') {
    console.log(date, format);
    const updatedDate = new Date(date);
    const year = updatedDate.getFullYear();
    const month = `${updatedDate.getMonth() + 1}`.padStart(2, '0');
    const day = `${updatedDate.getDate()}`.padStart(2, '0');
    // const hours = updatedDate.getHours();
    // const minutes = updatedDate.getMinutes();
    // const seconds = date.getSeconds();
    if (format === 'mmddyyyy') {
      return `${month}/${day}/${year}`;
    } else {
      return `${day}/${month}/${year}`;
    }
  }

  roundNumber = (number, precision = 2) => {
    if (typeof number === 'number') {
      return number.toFixed(2);
    }
    return Math.round(number * 10 ** precision) / 10 ** precision;
  };

  generateUniqueId = () => {
    return `${new Date().getTime()}`;
  };

  validateAlphabets = text => {
    const re = /^[a-zA-Z][\sa-zA-Z]*$/;
    // console.log("test result - ", re.test(String(text)));
    return re.test(String(text));
  };
  specialName = text => {
    const re = /^[a-zA-Z-+./_() ]*$/;
    return re.test(String(text));
  };
  hindiName = text => {
    const re = /^[^a-zA-Z+<>|=$^*!#@%`~;:'"]*$/;
    return re.test(String(text));
  };
  validateHindiAlphabets = text => {
    const re =
      /^[\u0900-\u097F]*[-/._\s()\d]*?([\u0900-\u097F]*[-/._\s()\d]*?)*?$/;
    // const re = /\p{Devanagari}/;
    return re.test(String(text));
  };

  validateRegex = (text, regex) => {
    // const re = /^[a-zA-Z ]*$/;
    // console.log("test result - ", regex.test(String(text)));
    return regex.test(String(text));
  };

  checkMinLength = (length, minLength) => {
    if (length < minLength) {
      return true;
    } else {
      return false;
    }
  };

  validateAge = (date, requiredAge = 18) => {
    let currentDate = moment();
    let DOB = moment(date);

    let age = currentDate.diff(DOB, 'years');

    if (age < requiredAge) {
      return 'Driver cannot be less than ' + requiredAge + ' years of age';
    }
  };

  validateYear = year => {
    const yearMomentObject = moment(year, 'YYYY');
    const currentMomentYear = moment();

    let isBefore = moment(yearMomentObject).isBefore(currentMomentYear);

    const re = /^[0-9]*$/;
    console.log('test result - ', re.test(String(year)));

    return re.test(String(year)) && isBefore;
  };
  calculateDateStrings(visitingDate, count, id) {
    let daySuffix = +count === 1 ? 'day' : 'days';
    let str = `${moment(visitingDate).format('DD/MM/YYYY')} 08:00 AM - ${moment(
      visitingDate,
    )
      .add(count, 'days')
      .format('DD/MM/YYYY')} 07:59AM (${count} ${daySuffix})`;
    if (id === 2) {
      daySuffix = '8 HRS from Time of Entry';
      str = `${moment(visitingDate).format('DD/MM/YYYY')} - ${moment(
        visitingDate,
      )
        .add(count, 'days')
        .format('DD/MM/YYYY')} (${daySuffix})`;
    }

    return str;
  }

  // addDataToStrings = (array: Array) => {
  //   const preparedData = strings.getContent();
  //   const duplicate = array.map(item => {
  //     const key = this.makeId(20);
  //     preparedData[LANGUAGE_PREFERENCES.ENGLISH][key] = item.name;
  //     preparedData[LANGUAGE_PREFERENCES.HINDI][key] =
  //       item.name_hindi || item.name;
  //     return {
  //       ...item,
  //       key,
  //     };
  //   });
  //   const language = strings.getLanguage();
  //   strings.setContent(preparedData);
  //   strings.setLanguage(language);
  //   return duplicate.map(item => {
  //     return {
  //       ...item,
  //       name: strings[item.key],
  //     };
  //   });
  // };

  // eslint-disable-next-line max-lines-per-function
  validate = (value, config) => {
    console.log('validate.....', config.inputType, value);
    let err = null;
    if (config.required) {
      if (value === '' || value === null || value === undefined) {
        err = `Invalid ${config.label}`;
        return err;
      }
    }

    if (value && config.minLength) {
      let length = value && value.toString().split('').length;
      let result = this.checkMinLength(length, config.minLength);
      if (result) {
        err = `${config.label} cannot be less than ${config.minLength} characters`;
        return err;
      }
    }
    if (value && config.inputType === 'phone') {
      let phoneno = value;
      let result = this.validatePhoneNumber(phoneno);

      if (!result) {
        err = `Bad formatting - ${config.label}`;
        return err;
      }
    }
    if (config.inputType === 'date' && config.required) {
      let date = value;
      if (!date) {
        err = `Bad formatting - ${config.label}`;
        return err;
      }
    }
    if (config.inputType === 'alphabets' && config.required) {
      let text = value;
      let result = this.validateAlphabets(text);

      if (!result) {
        err = `Only Alphabets are allowed - ${config.label}`;
        return err;
      }
    }
    if (config.inputType === 'alphanumeric' && config.required) {
      let text = value;
      let regex = /^[A-Za-z0-9]*$/;

      let result = this.validateRegex(text, regex);

      if (!result) {
        err = `Only Alphanumeric are allowed - ${config.label}`;
        return err;
      }
    }
    if (config.inputType === 'number' && config.required) {
      let text = value;
      let regex = /^[0-9]*$/;

      let result = this.validateRegex(text, regex);

      if (!result) {
        err = `Only numbers are allowed - ${config.label}`;
        return err;
      }
    }
    if (config.inputType === 'text' && config.required) {
      let text = value;
      let regex = /^\s+$/;

      let result = this.validateRegex(text, regex);

      if (result) {
        err = `Empty spaces are not allowed - ${config.label}`;
        return err;
      }
    }
    if (config.inputType === 'specialName' && config.required) {
      let text = value;
      let result = this.specialName(text);

      if (!result) {
        err = `Only Alphabets and Valid Special Characters are allowed - ${config.label}`;
        return err;
      }
    }
    if (config.inputType === 'hindiName' && config.required) {
      let text = value;
      let result = this.hindiName(text);

      if (!result) {
        err = `Only Hindi Aplhabets are allowed - ${config.label}`;
        return err;
      }
    }
    if (config.inputType === 'pincode') {
      console.log('####FINDME####');
      let text = value;
      let regex = /^[0-9]*$/;

      let result = this.validateRegex(text, regex);

      let lengthResult = text.toString().length === 6 ? true : false;

      if (!result || !lengthResult) {
        err = `Only 6 digit numbers are allowed - ${config.label}`;
        return err;
      }
    }
    if (config.inputType === 'license' && config.required) {
      let text = value;
      let regex = /^[A-Z0-9-/]*$/;

      let result = this.validateRegex(text, regex);

      if (!result) {
        err = `${config.label} can only be in capital letters, numbers and contain hyphens and forward slashes.`;
        return err;
      }
    }
    if (config.inputType === 'gst') {
      let text = value;
      let regex = /^[A-Z0-9]*$/;

      let result = this.validateRegex(text, regex);

      if (!result) {
        err = `${config.label} can only contain capital letters and numbers`;
        return err;
      }
    }
    if (config.inputType === 'year') {
      let year = value;
      let result = this.validateYear(year);

      if (!result) {
        err = `Invalid year - ${config.label}`;
        return err;
      }
    }
    if (config.inputType === 'name') {
      let text = value;
      // let regex = /^[A-Za-z]*$/;

      let result = this.specialName(text);

      if (!result) {
        err = `Only Alphabets are allowed - ${config.label}`;
        return err;
      }
    }
    if (config.inputType === 'name_hindi' && config.required) {
      let text = value;
      console.log('name_hindi', text);
      let result = this.validateHindiAlphabets(text);
      console.log('result', result);
      if (!result) {
        err = `Only Hindi Aplhabets are allowed -${config.label}`;
        return err;
      }
    }
    if (config.inputType === 'companyName' && config.required) {
      let text = value;
      let regex = /^[A-Za-z0-9-., ]*$/;

      let result = this.validateRegex(text, regex);

      if (!result) {
        err = `Invalid Field - ${config.label}`;
        return err;
      }
    }
    if (config.inputType === 'agencyName') {
      let text = value;
      let regex = /^[A-Za-z0-9-.'&,/; ]*$/;

      let result = this.validateRegex(text, regex);

      if (!result) {
        err = `Invalid Field - ${config.label}`;
        return err;
      }
    }

    if (config.inputType === 'upload' && config.required) {
      if (value === null || (value && value.length === 0)) {
        err = `Invalid ${config.label}`;
        return err;
      }
    }
    if (config.inputType === 'uploadContainer') {
      if (value === null || value.length === 0) {
        err = `Invalid ${config.label}`;
        return err;
      }
    }
    if (value && config.inputType === 'email') {
      let email = value;
      let result = this.validateEmail(email);

      if (!result) {
        err = `Bad formatting - ${config.label}`;
        return err;
      }
    }
  };

  search = (list, searchText, keys) => {
    let filtered = [];
    for (let i = 0; i < keys.length; i++) {
      filtered = list.filter(elem => {
        return (
          elem[keys[i]] &&
          elem[keys[i]]
            .trim()
            .toLowerCase()
            .includes(searchText.trim().toLowerCase())
        );
      });
      if (filtered.length) {
        // Data found - first hit
        break;
      }
    }
    return filtered;
  };

  getErrorMessage = error => {
    return error.response ? error.response.data.message : error.message;
  };

  makeId(length) {
    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  addDataToStrings = (array: Array) => {
    const preparedData = strings.getContent();
    const duplicate = array.map(item => {
      const key = this.makeId(20);
      preparedData[LANGUAGE_PREFERENCES.ENGLISH][key] = item.name;
      preparedData[LANGUAGE_PREFERENCES.HINDI][key] =
        item.name_hindi || item.name;
      return {
        ...item,
        key,
      };
    });
    const language = strings.getLanguage();
    strings.setContent(preparedData);
    strings.setLanguage(language);
    return duplicate.map(item => {
      return {
        ...item,
        name: strings[item.key],
      };
    });
  };
}
export default new UTIL();
