import { loginImages, adminImages, menuIcons } from '../assets/images';

const SIDEBAR_CONSTATS = {
  permitRequests: 'Permit Requests',
  agencyRequests: 'Agency Requests',
  freePermitRequests: 'Free Permit Requests',
  visitorPermitRequests: 'Visitor Permit Requests',
  attendence: 'Attendance',
  locations: 'Locations',
  personCards: 'Person Cards',
  vehicleCards: 'Vehicle Cards',
  manageOperators: 'Manage Operators',
  dashBoard: 'Dashboard',
  permits: 'Permits',
  reports: 'Reports',
  messages: 'Messages',
  agencyRegRequests: ' Agency Reg. Requests',
  verifyRegistrations: 'Scrutiny & Verification',
  downLoadInvoices: 'Download Invoices',
  operatorModuleDashboard: 'Dashboard',
  operatorModule: 'Operator Module',
  gps: 'GPS Tracking',
};

export const adminTabs = [
  {
    keyName: SIDEBAR_CONSTATS.dashBoard,
    route: 'dashboard',
    icon: adminImages.dashboardIcon,
    whiteIcon: adminImages.dashboardIconWhite,
  },
  {
    keyName: 'Permit Requests',
    icon: adminImages.agency,
    whiteIcon: adminImages.agencyWhite,
    isOpen: false,

    children: [
      // {
      //   keyName: SIDEBAR_CONSTATS.permitRequests,
      //   // image: courses,
      //   route: "permitRequests",
      // },
      {
        keyName: SIDEBAR_CONSTATS.agencyRequests,
        // image: schedule,
        route: 'agencyRequests',
      },
      {
        keyName: SIDEBAR_CONSTATS.freePermitRequests,
        // image: settings,
        route: 'freePermitRequests',
      },
      {
        keyName: SIDEBAR_CONSTATS.visitorPermitRequests,
        // image: settings,
        route: 'visitorPermitRequests',
      },
    ],
  },
];

export const adminInvoiceTabs = [
  {
    keyName: SIDEBAR_CONSTATS.downLoadInvoices,
    route: 'invoices',
    icon: adminImages.dashboardIcon,
    whiteIcon: adminImages.dashboardIconWhite,
  },
];

export const adminPortalTabs = [
  {
    keyName: SIDEBAR_CONSTATS.dashBoard,
    route: 'dashboard',
    icon: adminImages.dashboardIcon,
    whiteIcon: adminImages.dashboardIconWhite,
  },
];

// export const deptTabs = [
//   {
//     keyName: SIDEBAR_CONSTATS.dashBoard,
//     route: 'dashboard',
//     icon: adminImages.dashboardIcon,
//     whiteIcon: adminImages.dashboardIconWhite,
//     id: 1,
//   },
//   {
//     keyName: 'Permit Requests',
//     icon: menuIcons.permitsBlue,
//     whiteIcon: menuIcons.permitsWhite,
//     isOpen: false,
//     children: [
//       {
//         keyName: 'Person/Vehicle Requests',
//         // image: schedule,
//         route: 'agency-permit-requests',
//       },
//       // {
//       //   keyName: SIDEBAR_CONSTATS.freePermitRequests,
//       //   // image: settings,
//       //   route: 'free-permit-requests',
//       // },
//       // {
//       //   keyName: SIDEBAR_CONSTATS.visitorPermitRequests,
//       //   // image: settings,
//       //   route: 'visitor-permit-requests',
//       // },
//     ],
//   },
//   {
//     keyName: SIDEBAR_CONSTATS.agencyRegRequests,
//     route: 'agency-registrations',
//     icon: menuIcons.agencyRequestsBlue,
//     whiteIcon: menuIcons.agencyRequestsWhite,
//   },
//   {
//     keyName: 'User Management',
//     route: 'user-management',
//     icon: menuIcons.usersBlue,
//     whiteIcon: menuIcons.usersWhite,
//   },
//   {
//     keyName: 'Reports',
//     icon: menuIcons.reportsBlue,
//     whiteIcon: menuIcons.reportsWhite,
//     id: 7,
//     isOpen: false,

//     children: [
//       {
//         keyName: 'Day Wise Progress Monitoring',
//         route: 'daily-progress-monitoring',
//       },
//       {
//         keyName: 'Permits Raised',
//         route: 'permits-raised',
//       },
//     ],
//   },
// ];

export const deptTabs = [
  {
    keyName: SIDEBAR_CONSTATS.dashBoard,
    route: 'dashboard',
    icon: adminImages.dashboardIcon,
    whiteIcon: adminImages.dashboardIconWhite,
    isOpen: true,
    // children: [
    //   {
    //     keyName: 'Vehicle dashboard',
    //     route: 'dashboard',
    //   },
    //   {
    //     keyName: 'Person dashboard',
    //     route: 'person-dashboard',
    //   },
    // ],
  },

  {
    keyName: 'Permit Requests',
    icon: menuIcons.permitsBlue,
    id: 2,
    whiteIcon: menuIcons.permitsWhite,
    isOpen: false,
    children: [
      {
        keyName: 'Person/Vehicle Requests',
        // image: schedule,
        route: 'agency-permit-requests',
      },
    ],
  },
  {
    keyName: SIDEBAR_CONSTATS.agencyRegRequests,
    route: 'agency-registrations',
    icon: menuIcons.agencyRequestsBlue,
    whiteIcon: menuIcons.agencyRequestsWhite,
    id: 3,
  },
  {
    keyName: 'User Management',
    route: 'user-management',
    icon: menuIcons.usersBlue,
    whiteIcon: menuIcons.usersWhite,
    id: 4,
  },
  {
    keyName: 'Registrations Requests',
    route: 'validate-registrations',
    icon: menuIcons.registrationRequestsBlue,
    whiteIcon: menuIcons.registrationRequestsWhite,
    id: 5,
  },

  {
    keyName: 'Reports',
    icon: menuIcons.reportsBlue,
    whiteIcon: menuIcons.reportsWhite,
    id: 7,
    isOpen: false,
    children: [
      {
        keyName: 'Vehicle Movement',
        route: 'vehicle-movement',
      },
      // {
      //   keyName: 'Person Movement',
      //   route: 'person-movement',
      // },
      {
        keyName: 'Day Wise Progress Monitoring',
        route: 'daily-progress-monitoring',
      },
      {
        keyName: 'Permits Raised',
        route: 'permits-raised',
      },
      {
        keyName: 'Person/Vehicle/Agency Details',
        route: 'view',
      },
      // {
      //   keyName: 'Vehicle Details',
      //   route: 'view-vehicle',
      // },
    ],
  },
];

export const cisfTabs = [
  {
    keyName: SIDEBAR_CONSTATS.dashBoard,
    route: 'dashboard',
    icon: adminImages.dashboardIcon,
    whiteIcon: adminImages.dashboardIconWhite,
    isOpen: true,
    // children: [
    //   {
    //     keyName: 'Vehicle dashboard',
    //     route: 'dashboard',
    //   },
    //   {
    //     keyName: 'Person dashboard',
    //     route: 'person-dashboard',
    //   },
    // ],
  },

  {
    keyName: 'Permit Requests',
    icon: menuIcons.permitsBlue,
    id: 2,
    whiteIcon: menuIcons.permitsWhite,
    isOpen: false,
    children: [
      {
        keyName: 'Person/Vehicle Requests',
        // image: schedule,
        route: 'agency-permit-requests',
      },
    ],
  },
  {
    keyName: SIDEBAR_CONSTATS.agencyRegRequests,
    route: 'agency-registrations',
    icon: menuIcons.agencyRequestsBlue,
    whiteIcon: menuIcons.agencyRequestsWhite,
    id: 3,
  },
  {
    keyName: 'User Management',
    route: 'user-management',
    icon: menuIcons.usersBlue,
    whiteIcon: menuIcons.usersWhite,
    id: 4,
  },
  {
    keyName: 'Registrations Requests',
    route: 'validate-registrations',
    icon: menuIcons.registrationRequestsBlue,
    whiteIcon: menuIcons.registrationRequestsWhite,
    id: 5,
  },

  {
    keyName: 'Reports',
    icon: menuIcons.reportsBlue,
    whiteIcon: menuIcons.reportsWhite,
    id: 7,
    isOpen: false,
    children: [
      {
        keyName: 'Vehicle Movement',
        route: 'vehicle-movement',
      },
      // {
      //   keyName: 'Person Movement',
      //   route: 'person-movement',
      // },
      {
        keyName: 'Day Wise Progress Monitoring',
        route: 'daily-progress-monitoring',
      },
      {
        keyName: 'Permits Raised',
        route: 'permits-raised',
      },
      {
        keyName: 'Person/Vehicle/Agency Details',
        route: 'view',
      },
      // {
      //   keyName: 'Vehicle Details',
      //   route: 'view-vehicle',
      // },
    ],
  },
  // {
  //   keyName: SIDEBAR_CONSTATS.gps,
  //   name: 'gps',
  //   route: 'gps/edrishti-login',
  //   icon: menuIcons.usersBlue,
  //   whiteIcon: menuIcons.usersWhite,
  //   id: 7,
  // },
];

export const trafficDeptTabs = [
  // {
  //   keyName: SIDEBAR_CONSTATS.dashBoard,
  //   route: 'dashboard',
  //   icon: adminImages.dashboardIcon,
  //   whiteIcon: adminImages.dashboardIconWhite,
  //   id: 1,
  // },

  {
    keyName: SIDEBAR_CONSTATS.dashBoard,
    route: 'dashboard',
    icon: adminImages.dashboardIcon,
    whiteIcon: adminImages.dashboardIconWhite,
    isOpen: true,
    // children: [
    //   {
    //     keyName: 'Vehicle dashboard',
    //     route: 'dashboard',
    //   },
    //   {
    //     keyName: 'Person dashboard',
    //     route: 'person-dashboard',
    //   },
    // ],
  },




  {
    keyName: 'Permit Requests',
    icon: menuIcons.permitsBlue,
    id: 2,
    whiteIcon: menuIcons.permitsWhite,
    isOpen: false,
    children: [
      {
        keyName: 'Person/Vehicle Requests',
        // image: schedule,
        route: 'agency-permit-requests',
      },
      {
        keyName: 'Cargo Requests',
        // image: schedule,
        route: 'agency-permit-requests-traffic',
      },
    ],
  },
  {
    keyName: SIDEBAR_CONSTATS.agencyRegRequests,
    route: 'agency-registrations',
    icon: menuIcons.agencyRequestsBlue,
    whiteIcon: menuIcons.agencyRequestsWhite,
    id: 3,
  },
  {
    keyName: 'User Management',
    route: 'user-management',
    icon: menuIcons.usersBlue,
    whiteIcon: menuIcons.usersWhite,
    id: 4,
  },
  {
    keyName: 'Registrations Requests',
    route: 'validate-registrations',
    icon: menuIcons.registrationRequestsBlue,
    whiteIcon: menuIcons.registrationRequestsWhite,
    id: 5,
  },
  {
    keyName: 'Groundstock Datasheet',
    route: 'datasheet',
    icon: adminImages.agency,
    whiteIcon: adminImages.agencyWhite,
    id: 6,
  },
  {
    keyName: 'Reports',
    icon: menuIcons.reportsBlue,
    whiteIcon: menuIcons.reportsWhite,
    id: 7,
    isOpen: false,
    children: [
      {
        keyName: 'Vehicle Movement',
        route: 'vehicle-movement',
      },
      // {
      //   keyName: 'Person Movement',
      //   route: 'person-movement',
      // },
      {
        keyName: 'Day Wise Progress Monitoring',
        route: 'daily-progress-monitoring',
      },
      {
        keyName: 'Permits Raised',
        route: 'permits-raised',
      },
      {
        keyName: 'Person/Vehicle/Agency Details',
        route: 'view',
      },
    ],
  },
  // {
  //   keyName: SIDEBAR_CONSTATS.gps,
  //   name: 'gps',
  //   route: 'gps/edrishti-login',
  //   icon: menuIcons.locationIcon,
  //   whiteIcon: menuIcons.locationIcon,
  //   id: 7,
  // },
];

// FIXME: Flat hierarchy
export const operatorTabs = [
  {
    keyName: SIDEBAR_CONSTATS.operatorModuleDashboard,
    // image: settings,
    route: 'dashboard',
    roleIds: [5, 6, 7],
    icon: adminImages.dashboardIcon,
    whiteIcon: adminImages.dashboardIconWhite,
  },
  {
    keyName: SIDEBAR_CONSTATS.verifyRegistrations,
    // image: settings,
    route: 'verify-registrations',
    roleIds: [5, 6, 7],
  },
  {
    keyName: SIDEBAR_CONSTATS.personCards,
    // image: settings,
    route: 'personCards',
    roleIds: [5, 6, 7],
  },
  {
    keyName: SIDEBAR_CONSTATS.vehicleCards,
    // image: settings,
    route: 'vehicleCards',
    roleIds: [5, 6, 7],
  },
  {
    keyName: SIDEBAR_CONSTATS.locations,
    // image: schedule,
    route: 'locations',
    roleIds: [5],
  },
  {
    keyName: SIDEBAR_CONSTATS.manageOperators,
    // image: settings,
    route: 'manageOperators',
    roleIds: [5, 6],
  },
  {
    keyName: SIDEBAR_CONSTATS.attendence,
    // image: courses,
    route: 'attendance',
    roleIds: [5, 6],
  },
  {
    keyName: SIDEBAR_CONSTATS.messages,
    // image: courses,
    route: 'messages',
    roleIds: [5, 6, 7],
  },
];

// export const operatorTabs = [
//   {
//     keyName: SIDEBAR_CONSTATS.operatorModule,
//     route: 'dashboard',
//     icon: adminImages.dashboardIcon,
//     whiteIcon: adminImages.dashboardIconWhite,
//     isOpen: true,
//     children: [
//       {
//         keyName: SIDEBAR_CONSTATS.operatorModuleDashboard,
//         // image: settings,
//         route: 'dashboard',
//         roleIds: [5, 6, 7],
//       },
//       {
//         keyName: SIDEBAR_CONSTATS.verifyRegistrations,
//         // image: settings,
//         route: 'verify-registrations',
//         roleIds: [5, 6, 7],
//       },
//       {
//         keyName: SIDEBAR_CONSTATS.personCards,
//         // image: settings,
//         route: 'personCards',
//         roleIds: [5, 6, 7],
//       },
//       {
//         keyName: SIDEBAR_CONSTATS.vehicleCards,
//         // image: settings,
//         route: 'vehicleCards',
//         roleIds: [5, 6, 7],
//       },
//       {
//         keyName: SIDEBAR_CONSTATS.locations,
//         // image: schedule,
//         route: 'locations',
//         roleIds: [5],
//       },
//       {
//         keyName: SIDEBAR_CONSTATS.manageOperators,
//         // image: settings,
//         route: 'manageOperators',
//         roleIds: [5, 6],
//       },
//       {
//         keyName: SIDEBAR_CONSTATS.attendence,
//         // image: courses,
//         route: 'attendance',
//         roleIds: [5, 6],
//       },
//       {
//         keyName: SIDEBAR_CONSTATS.messages,
//         // image: courses,
//         route: 'messages',
//         roleIds: [5, 6, 7],
//       },
//     ],
//   },
// ];

// export const agencyTabs = [
//   {
//     keyName: SIDEBAR_CONSTATS.dashBoard,
//     route: 'dashboard',
//   },
//   {
//     keyName: SIDEBAR_CONSTATS.permits,
//     route: 'permits',
//   },
//   {
//     keyName: SIDEBAR_CONSTATS.reports,
//     route: 'reports',
//   },
// ];

export const agencyTabs = [
  {
    keyName: SIDEBAR_CONSTATS.dashBoard,
    route: 'dashboard',
    icon: adminImages.dashboardIcon,
    whiteIcon: adminImages.dashboardIconWhite,
    // children: [
    //   {
    //     keyName: SIDEBAR_CONSTATS.permits,
    //     route: 'dashboard',
    //   },
    //   {
    //     keyName: SIDEBAR_CONSTATS.agencyRequests,
    //     // image: schedule,
    //     route: 'agencyRequests',
    //   },
    // ],
  },
  {
    keyName: SIDEBAR_CONSTATS.permits,
    route: 'permits',
    icon: menuIcons.permitsBlue,
    whiteIcon: menuIcons.permitsWhite,
  },
  {
    keyName: 'Wallet',
    route: 'wallet',
    icon: menuIcons.walletBlue,
    whiteIcon: menuIcons.permitsWhite,
  },
  // {
  //   keyName: 'Credits',
  //   route: 'credits',
  //   icon: adminImages.creditsIcon,
  //   whiteIcon: adminImages.agencyWhite,
  // },
  {
    keyName: 'User Management',
    route: 'user-management',
    icon: menuIcons.usersBlue,
    whiteIcon: menuIcons.usersWhite,
  },

  {
    keyName: SIDEBAR_CONSTATS.reports,
    route: 'reports',
    icon: menuIcons.reportsBlue,
    whiteIcon: menuIcons.reportsWhite,
  },
];

export const masterAdminTabs = [
  {
    keyName: 'Person',
    route: 'person',
    icon: adminImages.dashboardIcon,
    whiteIcon: adminImages.dashboardIconWhite,
    id: 1,
    isOpen: true,
    children: [
      {
        keyName: 'Person Type',
        // image: schedule,
        route: 'person-type',
      },
      {
        keyName: 'Gender',
        // image: schedule,
        route: 'gender',
      },
      {
        keyName: 'Blood Group',
        // image: settings,
        route: 'blood-group',
      },
    ],
  },
  {
    keyName: 'Vehicle',
    route: 'vehicle',
    icon: adminImages.dashboardIcon,
    whiteIcon: adminImages.dashboardIconWhite,
    id: 2,
    isOpen: false,
    children: [
      {
        keyName: 'Vehicle Category',
        // image: schedule,
        route: 'vehicle-category',
      },
      {
        keyName: 'Vehicle Type',
        // image: schedule,
        route: 'vehicle-type',
      },
    ],
  },
  {
    keyName: 'General',
    route: 'general',
    icon: adminImages.dashboardIcon,
    whiteIcon: adminImages.dashboardIconWhite,
    id: 3,
    isOpen: false,

    children: [
      {
        keyName: 'Roles',
        // image: schedule,
        route: 'roles',
      },
      {
        keyName: 'Department',
        // image: schedule,
        route: 'department',
      },
      {
        keyName: 'Work Purpose',
        // image: schedule,
        route: 'work-purpose',
      },
      {
        keyName: 'Work Area',
        // image: schedule,
        route: 'work-area',
      },
      {
        keyName: 'Business Details',
        // image: schedule,
        route: 'business-details',
      },
      {
        keyName: 'Material Types',
        // image: schedule,
        route: 'material-types',
      },
      {
        keyName: 'Nature of Cargo',
        // image: schedule,
        route: 'natureOf-cargo',
      },
      {
        keyName: 'Unit Of Weight',
        // image: schedule,
        route: 'unit-of-weight',
      },

      {
        keyName: 'Gate Level Reject Reason',
        // image: schedule,
        route: 'gate-level-reject-reason',
      },
      {
        keyName: 'Pass Category',
        route: 'Pass-Category',
      },
      {
        keyName: 'Packs By Pass',
        route: 'Packs-By-Pass-Category',
      },
    ],
  },
  {
    keyName: 'Reports',
    route: 'reports',
    icon: adminImages.dashboardIcon,
    whiteIcon: adminImages.dashboardIconWhite,
    id: 4,
    isOpen: false,

    children: [
      {
        keyName: 'Wallet Balances Report',
        // image: schedule,
        route: 'wallet-balances-report',
      },
      {
        keyName: 'Vehicle Movement Report',
        // image: schedule,
        route: 'vehicle-movement-report',
      },
      {
        keyName: 'Pass Audit Report',
        // image: schedule,
        route: 'pass-audit-report',
      },
      {
        keyName: 'Permits Raised Report',
        // image: schedule,
        route: 'permits-raised-report',
      },
      {
        keyName: 'Daily Progress Monitoring',
        route: 'daily-progress-monitoring',
      },
    ],
  },
  {
    keyName: 'User Management',
    route: 'user-management',
    icon: adminImages.dashboardIcon,
    whiteIcon: adminImages.dashboardIconWhite,
    id: 5,
    isOpen: false,

    children: [
      {
        keyName: 'Users',
        // image: schedule,
        route: 'users',
      },
    ],
  },
  {
    keyName: 'Agency Manag.',
    route: 'agency-management',
    icon: adminImages.dashboardIcon,
    whiteIcon: adminImages.dashboardIconWhite,
    id: 6,
    isOpen: false,

    children: [
      {
        keyName: 'Agency',
        // image: schedule,
        route: 'agency-management',
      },
    ],
  },
];
