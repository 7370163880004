export const entrySummary = [
  {
    valid: 0,
    expired: 6,
    notApproved: 0,
    companyName: 'Traffic pvt ltd',
    agencyId: 69,
    driverMismatch: 1,
    status: 'REJECTED',
    gateID: 2,
    vehicleRegistrationNumber: 'RJ14cx8936',
    rejectionReason: 'Vehicle Registration Number is not valid',
    timestamp: new Date(),
  },
  {
    valid: 0,
    expired: 6,
    notApproved: 0,
    companyName: 'Traffic pvt ltd',
    agencyId: 69,
    driverMismatch: 1,
    status: 'REJECTED',
    gateID: 2,
    vehicleRegistrationNumber: 'RJ14cx8936',
    rejectionReason: 'Vehicle Registration Number is not valid',
    timestamp: new Date(),
  },
  {
    valid: 0,
    expired: 6,
    notApproved: 0,
    companyName: 'Traffic pvt ltd',
    agencyId: 69,
    driverMismatch: 1,
    status: 'REJECTED',
    gateID: 2,
    vehicleRegistrationNumber: 'RJ14cx8936',
    rejectionReason: 'Vehicle Registration Number is not valid',
    timestamp: new Date(),
  },
  {
    valid: 0,
    expired: 6,
    notApproved: 0,
    companyName: 'Traffic pvt ltd',
    agencyId: 69,
    driverMismatch: 1,
    status: 'REJECTED',
    gateID: 2,
    vehicleRegistrationNumber: 'RJ14cx8936',
    rejectionReason: 'Vehicle Registration Number is not valid',
    timestamp: new Date(),
  },
  {
    valid: 0,
    expired: 6,
    notApproved: 0,
    companyName: 'Traffic pvt ltd',
    agencyId: 69,
    driverMismatch: 1,
    status: 'REJECTED',
    gateID: 2,
    vehicleRegistrationNumber: 'RJ14cx8936',
    rejectionReason: 'Vehicle Registration Number is not valid',
    timestamp: new Date(),
  },
  {
    valid: 0,
    expired: 6,
    notApproved: 0,
    companyName: 'Traffic pvt ltd',
    agencyId: 69,
    driverMismatch: 1,
    status: 'REJECTED',
    gateID: 2,
    vehicleRegistrationNumber: 'RJ14cx8936',
    rejectionReason: 'Vehicle Registration Number is not valid',
    timestamp: new Date(),
  },
  {
    valid: 0,
    expired: 6,
    notApproved: 0,
    companyName: 'Traffic pvt ltd',
    agencyId: 69,
    driverMismatch: 1,
    status: 'REJECTED',
    gateID: 2,
    vehicleRegistrationNumber: 'RJ14cx8936',
    rejectionReason: 'Vehicle Registration Number is not valid',
    timestamp: new Date(),
  },
  {
    valid: 0,
    expired: 6,
    notApproved: 0,
    companyName: 'Traffic pvt ltd',
    agencyId: 69,
    driverMismatch: 1,
    status: 'REJECTED',
    gateID: 2,
    vehicleRegistrationNumber: 'RJ14cx8936',
    rejectionReason: 'Vehicle Registration Number is not valid',
    timestamp: new Date(),
  },
  {
    valid: 0,
    expired: 6,
    notApproved: 0,
    companyName: 'Traffic pvt ltd',
    agencyId: 69,
    driverMismatch: 1,
    status: 'REJECTED',
    gateID: 2,
    vehicleRegistrationNumber: 'RJ14cx8936',
    rejectionReason: 'Vehicle Registration Number is not valid',
    timestamp: new Date(),
  },
  {
    valid: 0,
    expired: 6,
    notApproved: 0,
    companyName: 'Traffic pvt ltd',
    agencyId: 69,
    driverMismatch: 1,
    status: 'REJECTED',
    gateID: 2,
    vehicleRegistrationNumber: 'RJ14cx8936',
    rejectionReason: 'Vehicle Registration Number is not valid',
    timestamp: new Date(),
  },
];

export const rejectedReports = [
  {
    vehicleRegistrationNumber: 'TS09CF1102',
    createdDate: 1649879615000,
    permitId: 1225,
    companyName: 'Traffic pvt ltd',
    error: 'Permit validity expired',
  },
  {
    vehicleRegistrationNumber: 'GJ12BV5826',
    createdDate: 1649901608000,
    permitId: 995,
    companyName: 'SHRI BALAJI INFRAPORT PRIVATE LIMITED',
    error: 'Permit validity expired',
  },
  {
    vehicleRegistrationNumber: 'GJ12BV4140',
    createdDate: 1649917733000,
    permitId: 340,
    companyName: 'SHRI BALAJI INFRAPORT PRIVATE LIMITED',
    error: 'Permit validity expired',
  },
];
