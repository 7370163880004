import React, { Component, useEffect } from 'react';
import styles from './styles.module.scss';
import PopupContainer from '../../../components/PopupContainer';
import Popup from '../../../components/Popup';
import SimplePopup from '../../../components/SimplePopup';
import { adminImages, loginImages, commonImages } from '../../../assets/images';
import moment from 'moment';
import ViewRenderer from './views';
import AgencyRquestsPopup from '../../../components/AgencyRquestsPopup';
import AgencyPersonDetails from '../../../components/AgencyPersonDetails';
import AgencyMaterialDetails from '../../../components/AgencyMaterialDetails';

import { API } from '../../../requests';
import strings from '../../../globalization';
import Spinner from '../../../components/Spinner';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ViewDocument from '../../../components/ViewDocument';

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  ERROR: 'error',
  SPINNER_POPUP: 'spinner',
};
class AgencyPermitRequests extends Component {
  constructor(props) {
    super(props);
    const { reduxProps } = this.props;
    const {
      login: { loginInfo },
    } = reduxProps;
    const { department, user } = loginInfo;
    console.log(user, department);

    this.state = {
      totalCount: 0,
      agencies: [],
      selectedStatus: 'PENDING',
      selectedAgency: '',
      // startDate: new moment().startOf('year').format('MM-DD-YYYY'),
      // endDate: new moment().add(1, 'years').format('MM-DD-YYYY'),
      // dates: null,
      startDate: moment().subtract(1, 'week').format('MM-DD-YYYY'),
      startTime: moment().startOf('day'),
      endDate: moment().add(1, 'day').format('MM-DD-YYYY'),
      endTime: moment().endOf('day'),
      dates: {
        start: moment().subtract(1, 'week').format('MM-DD-YYYY'),
        end: moment().add(1, 'day').format('MM-DD-YYYY'),
      },

      filterAgencies: [],
      searchText: '',
      departmentName: department?.name,
      statuses: [
        {
          id: '1',
          name: 'PENDING',
        },
        {
          id: '2',
          name: 'APPROVED',
        },
        {
          id: '3',
          name: 'REJECTED',
        },
      ],
    };
  }

  applyFilters = () => {
    const { searchText, selectedStatus, selectedAgency, agencies } = this.state;
    const filterByStatusData =
      (selectedStatus &&
        agencies.filter(
          agency =>
            selectedStatus &&
            agency.status.toLowerCase() === selectedStatus.toLowerCase(),
        )) ||
      agencies;
    const filterBySearch =
      agencies.filter(element =>
        Object.values(element)
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()),
      ) || agencies;
    const filterByAgencyName =
      (selectedAgency &&
        agencies.filter(
          row =>
            selectedAgency &&
            row.company &&
            row.company.name.toLowerCase() === selectedAgency.toLowerCase(),
        )) ||
      agencies;
    const temp1 = filterByStatusData.filter(({ id: id1 }) =>
      filterBySearch.some(({ id: id2 }) => id2 === id1),
    );
    const finalFilterData = filterByAgencyName.filter(({ id: id1 }) =>
      temp1.some(({ id: id2 }) => id2 === id1),
    );
    this.setState({
      filterAgencies: finalFilterData,
    });
  };

  // getData = async (startDate, endDate, isFilter) => {
  //   this.setState({
  //     popup: {
  //       type: POPUP_CONSTANTS.SPINNER_POPUP,
  //     },
  //   });

  //   await API.getAllAgencyDetails(startDate, endDate, undefined, 0, 100)
  //     .then(response => {
  //       this.setState({
  //         agencies: response.data?.permitDetails,
  //         filterAgencies: response.data?.permitDetails,
  //         popup: undefined,
  //       });
  //       if (isFilter) {
  //         this.applyFilters();
  //       }
  //     })
  //     .catch(error => {
  //       const errorMsg = error.response
  //         ? error.response.data.message
  //         : error.message;
  //       console.info('error....', errorMsg);
  //       this.setState({
  //         popup: undefined,
  //       });
  //       // this.setState({
  //       //   popup: {
  //       //     type: POPUP_CONSTANTS.SIMPLE_POPUP,
  //       //     message: errorMsg,
  //       //     messageStyle: { color: "#E67717" },
  //       //     buttons: [
  //       //       {
  //       //         title: "Okay!",
  //       //         onPress: this.closePopup,
  //       //       },
  //       //     ],
  //       //   },
  //       // });
  //     });
  // };

  approveAgency = async data => {
    const { id, referenceId } = data;
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
    await API.approveAgencyRequest(id)
      .then(response => {
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.STANDARD,
            heading: `${strings.ADMIN.POP_UP.agencyRequest} - ${referenceId}`,
            message: this.getSuccessMessage(referenceId),
            data: data,
            onClose: this.closePopup,
            buttonContainer: styles.buttonContainer,
            buttons: [
              {
                text: 'Okay',
                buttonStyles: styles.backButton,
                onClick: () => {
                  const { startDate, endDate } = this.state;
                  let startDateStr = moment(startDate).format('MM-DD-YYYY');
                  let endDateStr = moment(endDate).format('MM-DD-YYYY');
                  this.onChangeHandler('updateTable');
                  // console.log('calling reset data');
                  //this.resetData();
                  this.closePopup();
                },
              },
            ],
          },
        });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        console.info('error....', errorMsg);

        this.setState({
          popup: {
            type: POPUP_CONSTANTS.STANDARD,
            heading: `${strings.ADMIN.POP_UP.agencyRequest} - ${referenceId}`,
            message: this.getErrorMessage(referenceId),
            data: data,
            onClose: this.closePopup,
            buttonContainer: styles.buttonContainer,
            buttons: [
              {
                text: 'Okay',
                buttonStyles: styles.backButton,
                onClick: this.closePopup,
              },
            ],
          },
        });
      });
  };

  rejectReason = (referenceId, data) => {
    console.log('reject reason:', data);
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.STANDARD,
        heading: `${strings.ADMIN.POP_UP.agencyRequest} - ${referenceId}`,
        message: this.getRejectMessage(),
        data: data,
        onClose: this.closePopup,
        buttonContainer: styles.buttonContainer,
        buttons: [
          {
            text: 'Back',
            buttonStyles: styles.backButton,
            onClick: () => this.closePopup(),
          },
          {
            text: 'Reject',
            buttonStyles: styles.buttonPrimary,
            onClick: () => {
              const { referenceId } = data;
              const rejectMessage = this.state.rejectMessage;
              console.log('reject message...', rejectMessage);
              if (!rejectMessage) {
                this.showErrorPopup(
                  `Reject reason is mandatory.`,
                  'rejectPopup',
                  data,
                );
              } else {
                this.rejectAgency(referenceId, data);
              }
            },
          },
        ],
      },
    });
  };
  showErrorPopup = (errorMsg, type, data) => {
    // crashlytics().recordError(new Error(errorMsg));
    console.log(data);

    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMsg,
        messageStyle: { color: '#E67717' },

        buttons: [
          {
            text: strings.okayPopup,
            onClick:
              type === 'rejectPopup'
                ? () => this.onClickHandler('rowClick', data)
                : this.closePopup,
          },
        ],
        onClose: this.closePopup,
      },
    });
  };

  rejectAgency = async (referenceId, data) => {
    const { id } = data;
    const { rejectMessage } = this.state;
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });

    await API.rejectAgencyRequest(id, rejectMessage)
      .then(response => {
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.STANDARD,
            heading: `${strings.ADMIN.POP_UP.agencyRequest} - ${referenceId}`,
            message: this.getRejectConformation(referenceId),
            data: data,
            onClose: this.closePopup,
            buttonContainer: styles.buttonContainer,
            buttons: [
              {
                text: 'Okay',
                buttonStyles: styles.backButton,
                onClick: () => {
                  const { startDate, endDate } = this.state;
                  let startDateStr = moment(startDate).format('MM-DD-YYYY');
                  let endDateStr = moment(endDate).format('MM-DD-YYYY');
                  //   this.getData(startDateStr, endDateStr, true);
                  this.onChangeHandler('updateTable');
                  this.closePopup();
                },
              },
            ],
          },
        });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        console.info('error....', errorMsg);
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.STANDARD,
            heading: `${strings.ADMIN.POP_UP.agencyRequest} - ${referenceId}`,
            message: this.getErrorMessage(referenceId),
            data: data,
            onClose: this.closePopup,
            buttonContainer: styles.buttonContainer,
            buttons: [
              {
                text: 'Okay',
                buttonStyles: styles.backButton,
                onClick: this.closePopup,
              },
            ],
          },
        });
      });
  };
  componentDidMount() {
    // this.onChangeHandler('updateTable');
  }

  getVehicleDetails = props => {
    const { vehicle } = props;
    const {
      name,
      emailAddress,
      phoneNumber,
      vehicleType,
      vehicleNumber,
      vehicleInsuranceNumber,
      vehicleEngineNumber,
      vehiclePollutionNumber,
      chassisNumber,
      vehicleInsuranceCertificate,
      fitnessCertificate,
      vehicleRegistrationNumber,
      puc,
      rcDocument,
      rfidnumber,
    } = props || {};
    console.log('props:', props, vehicle);
    return (
      <div className={styles.cardWrapper}>
        <div>
          <img
            src={adminImages.truckIconBlue}
            className={styles.truckIcon}
            alt="logo"
          />
        </div>
        <div className={styles.info}>
          <div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehicleOwnerName} :
              <span className={styles.requestLabel}> {name} </span>
            </div>

            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.ownerEmail} :
              <span className={styles.requestLabel}>{emailAddress}</span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.ownerPhoneNumber} :
              <span className={styles.requestLabel}> {phoneNumber} </span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehicleType} :
              <span className={styles.requestLabel}>{vehicleType.name}</span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.document} :
              <span className={styles.requestLabel}>
                {' '}
                {rcDocument ? (
                  <a
                    onClick={() =>
                      this.onClickHandler('document-view', rcDocument)
                    }>
                    {' '}
                    {strings.ADMIN.POP_UP.vehicleRC}{' '}
                    <img
                      className={styles.documentIcon}
                      src={adminImages.documentIcon}
                    />
                  </a>
                ) : null}
              </span>
            </div>
          </div>
          <div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehicleNumber} :
              <span className={styles.requestLabel}>
                {' '}
                {vehicleRegistrationNumber}{' '}
              </span>
            </div>
            {/* <div className={styles.requestKey}>
            {strings.ADMIN.POP_UP.vehicleRCNumber} :
              <span className={styles.requestLabel}>{"-"} </span>
            </div> */}
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.RFIDTagNumber} :
              <span className={styles.requestLabel}> {rfidnumber} </span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehicleInsuranceNumber} :
              <span className={styles.requestLabel}>
                {' '}
                {vehicleInsuranceNumber}{' '}
              </span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.document} :
              <span className={styles.requestLabel}>
                {vehicleInsuranceCertificate ? (
                  <a
                    onClick={() =>
                      this.onClickHandler(
                        'document-view',
                        vehicleInsuranceCertificate,
                      )
                    }>
                    {strings.ADMIN.POP_UP.vehicleInsuranceCertificate}{' '}
                    <img
                      className={styles.documentIcon}
                      src={adminImages.documentIcon}
                    />
                  </a>
                ) : null}
              </span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.document} :
              <span className={styles.requestLabel}>
                {' '}
                {puc ? (
                  <a onClick={() => this.onClickHandler('document-view', puc)}>
                    {' '}
                    {strings.ADMIN.POP_UP.pUCCertificate}{' '}
                    <img
                      className={styles.documentIcon}
                      src={adminImages.documentIcon}
                    />
                  </a>
                ) : null}
              </span>
            </div>
          </div>
          <div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehiclePollutionNumber} :
              <span className={styles.requestLabel}>
                {' '}
                {vehiclePollutionNumber}{' '}
              </span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehicleEngineNumber} :
              <span className={styles.requestLabel}>{vehicleEngineNumber}</span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehicleChassisNumber} :
              <span className={styles.requestLabel}> {chassisNumber} </span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.document} :
              <span className={styles.requestLabel}>
                {' '}
                {fitnessCertificate ? (
                  <a
                    onClick={() =>
                      this.onClickHandler('document-view', fitnessCertificate)
                    }>
                    Fitness Certificate{' '}
                    <img
                      className={styles.documentIcon}
                      src={adminImages.documentIcon}
                    />
                  </a>
                ) : null}{' '}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  getRequestInfo = data => {
    const { persons } = data;
    const { driver } = data;
    const { vehicles, material } = data;
    console.log('vehicle:', data);

    return (
      <div className={styles.requestInfo}>
        {material.map((mat, index) => {
          return (
            <>
              {' '}
              {mat?.materialTypeId === 1 || mat?.materialTypeId === 2 ? (
                <RequestCard
                  key={'person' + index}
                  header={'Material Details' + '-' + (index + 1)}>
                  <AgencyMaterialDetails
                    onClick={this.onClickHandler}
                    {...mat}
                  />
                </RequestCard>
              ) : null}
            </>
          );
        })}
        {persons.map((person, index) => {
          return (
            <RequestCard
              key={'person' + index}
              header={strings.ADMIN.POP_UP.personDetails + '-' + (index + 1)}>
              <AgencyPersonDetails onClick={this.onClickHandler} {...person} />
            </RequestCard>
          );
        })}

        {vehicles.map((vehicle, index) => {
          return (
            <>
              <RequestCard
                key={'vehicle' + index}
                header={
                  strings.ADMIN.POP_UP.vehicleDetails + '-' + (index + 1)
                }>
                {this.getVehicleDetails(vehicle?.vehicle)}
              </RequestCard>
              {vehicle?.driver ? (
                <RequestCard
                  header={
                    strings.ADMIN.POP_UP.driverDetails + '-' + (index + 1)
                  }>
                  <AgencyPersonDetails
                    onClick={this.onClickHandler}
                    {...vehicle?.driver}
                  />
                </RequestCard>
              ) : null}
            </>
          );
        })}

        {data.status && data?.status === 'REJECTED' && data?.reason ? (
          <div className={styles.rejectContainer}>
            <p className={styles.rejectReasonStyles}>
              Reason for rejection remarks: <span>{data?.reason}</span>
            </p>
          </div>
        ) : null}
      </div>
    );
  };

  getErrorMessage = referenceId => {
    return (
      <div className={styles.requestSuccess}>
        <div>
          <img
            src={commonImages.errorIcon}
            className={styles.successIcon}
            alt="logo"
          />
        </div>
        <div className={styles.errorMsg}>
          {strings.ADMIN.POP_UP.errorMessage}
        </div>
      </div>
    );
  };

  getSuccessMessage = referenceId => {
    return (
      <div className={styles.requestSuccess}>
        <div>
          <img
            src={loginImages.successIcon}
            className={styles.successIcon}
            alt="logo"
          />
        </div>
        <div className={styles.successMessage}>
          {strings.ADMIN.POP_UP.approveCnf1} {referenceId}{' '}
          {strings.ADMIN.POP_UP.approveCnf2}
        </div>
      </div>
    );
  };
  handleChange = (event, field) => {
    console.log(event.target.value, field);
    this.setState({ [field]: event.target.value });
    console.log(this.state);
  };

  getRejectMessage = () => {
    return (
      <div className={styles.requestReject}>
        <div className={styles.rejectMessage}>
          {strings.ADMIN.POP_UP.toReject}
        </div>
        <textarea
          placeholder={'Type here...'}
          className={styles.popupTextarea}
          required
          onChange={event => this.handleChange(event, 'rejectMessage')}
        />
      </div>
    );
  };
  getRejectConformation = referenceId => {
    return (
      <div className={styles.requestSuccess}>
        <div>
          <img
            src={loginImages.successIcon}
            className={styles.successIcon}
            alt="logo"
          />
        </div>
        <div className={styles.successMessage}>
          {strings.ADMIN.POP_UP.rejectCnf1} {referenceId}{' '}
          {strings.ADMIN.POP_UP.rejectCnf2}
        </div>
      </div>

      // <div className={styles.requestReject}>
      //   <div className={styles.rejectMessage}>
      //     {strings.ADMIN.POP_UP.rejectCnf1} {referenceId}{' '}
      //     {strings.ADMIN.POP_UP.rejectCnf2}
      //   </div>
      //   {/* <textarea
      //     placeholder={"Type here"}
      //     className={styles.popupTextarea}
      //     required
      //   /> */}
      // </div>
    );
  };

  onChangeHandler = (action, data) => {
    switch (action) {
      case 'status': {
        this.setState({
          selectedStatus: data,
        });

        // const { agencies } = this.state;
        // const filterAgencies = agencies.filter(
        //   (row) => row.status.toLowerCase() === data.toLowerCase()
        // );
        // this.setState({
        //   filterAgencies,
        //   selectedStatus: data,
        // });

        break;
      }
      case 'agencyName': {
        // const { agencies } = this.state;
        // const filterAgencies = agencies.filter(
        //   (row) => row.company && row.company.name.toLowerCase() === data.toLowerCase()
        // );
        this.setState({
          selectedAgency: data,
        });
        break;
      }

      case 'date': {
        const { startDate, endDate, dates } = data;
        this.setState({
          startDate,
          endDate,
          dates,
        });
        break;
      }

      case 'search': {
        console.info('data..', data);
        this.setState({
          searchText: data,
        });
        // const { agencies } = this.state;
        // const filterAgencies = agencies.filter((element) =>
        //     Object.values(element).toString().toLowerCase().includes(data.toLowerCase()));
        // this.setState({
        //   filterAgencies,
        // });
        break;
      }
      case 'gridAPI': {
        console.log('grid API', data);
        this.setState({ gridApi: data });
        this.onChangeHandler('updateTable');
        break;
      }
      case 'updateTable': {
        const { gridApi, selectedStatus, startDate, endDate, searchText } =
          this.state;
        const perPage = 50;
        if (gridApi) {
          const dataSource = {
            getRows: params => {
              const page = params.endRow / perPage;
              console.log(page, params, selectedStatus);
              this.getData(
                params,
                startDate,
                endDate,
                selectedStatus,
                page - 1,
                perPage,
                searchText,
              );
            },
          };
          gridApi.setDatasource(dataSource);
        }
        break;
      }

      default: {
        console.info('case not handled', action);
      }
    }
  };

  /**
   *
   * @param {*} action
   * @param {*} data
   */

  onClickHandler = (action, data) => {
    const { history, location } = this.props;

    switch (action) {
      case 'signin': {
        history.push('/sign-in');
        break;
      }
      case 'vehicle-registration': {
        history.push('/vehicle-registration');
        break;
      }
      case 'apply': {
        this.onChangeHandler('updateTable');
        break;
      }
      case 'clearFilters': {
        this.resetData();
        break;
      }

      case 'rowClick': {
        console.info('data', data);
        const { referenceId, status } = data;

        this.setState({
          rejectMessage: null,
          popup: {
            type: POPUP_CONSTANTS.STANDARD,
            heading: `Agency Permit Requests - ${referenceId}`,
            message: this.getRequestInfo(data),
            onClose: this.closePopup,
            data: data,
            buttonContainer: styles.buttonContainer,
            buttons:
              status === 'PENDING'
                ? [
                  {
                    text: strings.ADMIN.POP_UP.reject,
                    buttonStyles: styles.buttonPrimary,
                    onClick: () => {
                      const { referenceId } = data;

                      this.rejectReason(referenceId, data);
                    },
                  },
                  {
                    text: strings.ADMIN.POP_UP.approve,
                    buttonStyles: styles.buttonSecondary,
                    onClick: () => {
                      const { referenceId } = data;
                      this.approveAgency(data);
                    },
                  },
                ]
                : [],
          },
        });
        break;
      }
      case 'document-view': {
        console.log('in document view redirection case');
        history.push({
          pathname: location.pathname + '/document-view',
          //   pathname: location.pathname + '/add-new-person',
          state: { background: location, url: data },
        });

        break;
      }
      case 'refresh': {
        this.onChangeHandler('updateTable');
        break;
      }
      default: {
        console.info('case not handled', action);
      }
    }
  };

  getData = async (
    params,
    startDate,
    endDate,
    status,
    page,
    perPage,
    searchString,
  ) => {
    console.log('status:', status, searchString);
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
    await API.getAllAgencyDetails(
      startDate,
      endDate,
      status || undefined,
      page,
      perPage,
      searchString || undefined,
    )
      .then(resp => resp)
      .then(res => {
        console.log('response:', res, perPage);
        params.successCallback(res.data?.permitDetails, res.data?.totalCount);
        this.setState({
          totalCount: res.data?.totalCount,
        });
        this.closePopup();
      })
      .catch(err => {
        console.log(err);
        this.setState({
          totalCount: 0,
        });
        params.successCallback([], 0);
        this.showErrorPopup(
          `There is an error while fetching the permits, Please try again later.`,
        );
      });
  };

  resetData = () => {
    this.setState({
      searchText: '',
      selectedStatus: 'PENDING',
      //   startDate: new moment().startOf('year').format('MM-DD-YYYY'),
      //   endDate: new moment().add(1, 'years').format('MM-DD-YYYY'),
      //  dates: null,
      startDate: moment().subtract(1, 'week').format('MM-DD-YYYY'),
      startTime: moment().startOf('day'),
      endDate: moment().add(1, 'day').format('MM-DD-YYYY'),
      endTime: moment().endOf('day'),
      dates: {
        start: moment().subtract(1, 'week').format('MM-DD-YYYY'),
        end: moment().add(1, 'day').format('MM-DD-YYYY'),
      },
    });
    setTimeout(() => {
      this.onChangeHandler('updateTable');
    }, 0);
  };

  getProps = () => {
    const {
      statuses,
      agencies,
      filterAgencies,
      searchText,
      selectedAgency,
      selectedStatus,
      startDate,
      endDate,
      dates,
      departmentName,
      totalCount,
    } = this.state;
    return {
      onClick: this.onClickHandler,
      onChange: this.onChangeHandler,
      statuses,
      agencies,
      filterAgencies,
      searchText,
      selectedAgency,
      selectedStatus,
      startDate,
      endDate,
      dates,
      departmentName,
      totalCount,
    };
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };

  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const { popup } = this.state;
    if (!popup) return;
    switch (popup.type) {
      // case "spinner": {
      //   console.log('showing spinner');
      //   return <Spinner />;
      // }
      case POPUP_CONSTANTS.STANDARD: {
        return <AgencyRquestsPopup {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <SimplePopup {...popup} />;
      }
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const { popup } = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

const RequestCard = props => {
  const { header } = props;

  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardheader}>{header}</div>
      {props.children}
    </div>
  );
};

const mapStateToProps = reduxProps => ({
  reduxProps,
});

const mapDispatchToProps = dispatch => ({
  // dispatchSetPersonsData: payload => {
  //   dispatch(setPersonsData(payload));
  // },
});

// export default withRouter(PersonCards);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AgencyPermitRequests),
);
