import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import POPUP_CONSTANTS from '../../../../constants/popup-constants';
import {API} from '../../../../requests';
import util from '../../../../util';

import Spinner from 'react-spinkit';
import Popup from '../../../../components/SimplePopup';

import styles from './styles.module.scss';
import ViewRenderer from './views';
import PopupContainer from '../../../../components/PopupContainer';
import {commonImages} from '../../../../assets/images';
import strings from '../../../../globalization';
import ViewPerson from '../../../../components/Permits/ViewPerson';
import ViewDocument from '../../../../components/ViewDocument';
import {getDriverFormData} from './driverData';
import {setVehicleData} from '../../../../store/actions/permits';
import {connect} from 'react-redux';

const FILTER_OPTIONS = {
  REFERENCE_NUMBER: 'REFERENCE_NUMBER',
  AADHAAR_NUMBER: 'AADHAAR_NUMBER',
  RFID_CARD: 'RFID_CARD',
};

class AddDriver extends Component {
  constructor(props) {
    super(props);

    this.driverFormData = getDriverFormData();

    this.state = {
      searchText: '',
      driver: null,

      // FIXME: Add vehicle from location object
      vehicle: {
        vehicleRegistrationNumber: 'HARDCODEDREGG',
        rfidnumber: 'HARDCODEDRFID',
        vehicleType: {name: 'HARDCODEDVEHICLETYPE'},
      },
      favorite: {value: null, error: null},
      favoriteOptions: null,
      filter: null,
      filterOptions: [
        {
          id: FILTER_OPTIONS.REFERENCE_NUMBER,
          name: 'Reference Number',
        },
        {
          id: FILTER_OPTIONS.AADHAAR_NUMBER,
          name: 'Aadhaar Number',
        },
        {
          id: FILTER_OPTIONS.RFID_CARD,
          name: 'RFID Card Number',
        },
      ],
    };
  }

  componentDidMount = () => {
    this.fetchFavoriteDetails();
  };

  fetchFavoriteDetails = () => {
    API.fetchDriversForCompany()
      .then(favoriteRes => {
        console.info('favorite.data', favoriteRes.data);
        this.setState({
          favoriteOptions: favoriteRes.data,
        });
      })
      .catch(err => {
        const errorMsg = util.getErrorMessage(err);
        this.showErrorPopup(errorMsg);
      });
  };

  goBack = () => {
    console.log('goBack Called!!-->');
    const {history} = this.props;
    history.goBack();
  };

  getDocumentProofNumber = documentId => {
    switch (documentId) {
      case 1: {
        return 'aadhaarNumber';
      }
      case 2: {
        return 'drivingLicenceNumber';
      }
      case 3: {
        return 'panNumber';
      }
      case 4: {
        return 'passportNumber';
      }
      case 5: {
        return 'voterIdNumber';
      }
      default: {
        return '';
      }
    }
  };
  getDocumentProofUrl = documentId => {
    switch (documentId) {
      case 1: {
        return 'aadhaarUrl';
      }
      case 2: {
        return 'drivingLicenceUrl';
      }
      case 3: {
        return 'panUrl';
      }
      case 4: {
        return 'passportUrl';
      }
      case 5: {
        return 'voterIdUrl';
      }
      default: {
        return '';
      }
    }
  };
  handleSearch = () => {
    // API to fetch details and populate state.
    const {filter} = this.state;
    if (!filter) {
      this.showErrorPopup(`Please select a filter`);
      return;
    }

    const {searchText} = this.state;
    this.showSpinner();

    if (filter.value === FILTER_OPTIONS.REFERENCE_NUMBER) {
      API.getPersonByReferenceId(searchText.value)
        .then(res => {
          console.log('res.data....', res.data);

          let driver = res.data;

          let documentTypeName = this.getDocumentTypeName(
            driver.documentTypeId,
          );
          let documentId = driver.documentTypeId;
          let documentProofField = this.getDocumentProofNumber(documentId);
          let documentProofNumber = driver[documentProofField];

          let documentUrlStateField = this.getDocumentProofUrl(documentId);
          let documentUrl = driver[documentUrlStateField];

          this.setState({
            driver: {
              ...res.data,
              documentProofNumber: documentProofNumber,
              documentUrl: documentUrl,
              documentTypeName: documentTypeName,
            },
            popup: null,
          });
        })
        .catch(error => {
          const errorMsg = util.getErrorMessage(error);
          this.setState({
            driver: null,
          });
          this.showErrorPopup(errorMsg);
        });
    } else if (filter.value === FILTER_OPTIONS.RFID_CARD) {
      API.fetchPersonByRFID(searchText.value)
        .then(res => {
          console.log('res.data....', res.data);

          let driver = res.data;

          let documentTypeName = this.getDocumentTypeName(
            driver.documentTypeId,
          );
          let documentId = driver.documentTypeId;
          let documentProofField = this.getDocumentProofNumber(documentId);
          let documentProofNumber = driver[documentProofField];

          let documentUrlStateField = this.getDocumentProofUrl(documentId);
          let documentUrl = driver[documentUrlStateField];

          this.setState({
            driver: {
              ...res.data,
              documentProofNumber: documentProofNumber,
              documentUrl: documentUrl,
              documentTypeName: documentTypeName,
            },
            popup: null,
          });
        })
        .catch(error => {
          const errorMsg = util.getErrorMessage(error);
          this.setState({
            driver: null,
          });
          this.showErrorPopup(errorMsg);
        });
    } else {
      API.fetchPersonByAadhaarNumber(searchText.value)
        .then(res => {
          console.log('res.data....', res.data);

          let driver = res.data;

          let documentTypeName = this.getDocumentTypeName(
            driver.documentTypeId,
          );
          let documentId = driver.documentTypeId;
          let documentProofField = this.getDocumentProofNumber(documentId);
          let documentProofNumber = driver[documentProofField];

          let documentUrlStateField = this.getDocumentProofUrl(documentId);
          let documentUrl = driver[documentUrlStateField];

          this.setState({
            driver: {
              ...res.data,
              documentProofNumber: documentProofNumber,
              documentUrl: documentUrl,
              documentTypeName: documentTypeName,
            },
            popup: null,
          });
        })
        .catch(error => {
          const errorMsg = util.getErrorMessage(error);
          this.setState({
            driver: null,
          });
          this.showErrorPopup(errorMsg);
        });
    }
  };
  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.VIEW_PERSON_POPUP: {
        return <ViewPerson person={this.state.driver} {...popup} />;
      }
      case POPUP_CONSTANTS.VIEW_DOCUMENT: {
        return <ViewDocument {...popup} />;
      }

      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };
  getDocumentTypeName = documentId => {
    switch (documentId) {
      case 1: {
        return 'Aadhaar';
      }
      case 2: {
        return 'Driving License';
      }
      case 3: {
        return 'PAN';
      }
      case 4: {
        return 'Passport';
      }
      case 5: {
        return 'Voter ID';
      }
      default: {
        return '';
      }
    }
  };

  handleFavoriteClick = value => {
    let driver = this.state.favoriteOptions.find(elem => elem.id === value);

    let documentTypeName = this.getDocumentTypeName(driver.documentTypeId);
    let documentId = driver.documentTypeId;
    let documentProofField = this.getDocumentProofNumber(documentId);
    let documentProofNumber = driver[documentProofField];

    let documentUrlStateField = this.getDocumentProofUrl(documentId);
    let documentUrl = driver[documentUrlStateField];

    this.setState({
      driver: {
        ...driver,
        documentProofNumber: documentProofNumber,
        documentUrl: documentUrl,
        documentTypeName: documentTypeName,
      },
    });
  };

  onChangeHandler = (field, value) => {
    console.log('field', field, 'value', value);

    if (field === 'favorite') {
      this.handleFavoriteClick(value);
    }

    this.setState(prevState => ({
      ...prevState,
      [field]: {
        value,
      },
    }));
    console.log('this.state', this.state);
  };
  onView = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.VIEW_PERSON_POPUP,
        onClose: this.closePopup,
        viewDocument: this.viewDocument,
      },
    });
  };
  viewDocument = url => {
    // this.setState({
    //   popup: {
    //     type: POPUP_CONSTANTS.VIEW_DOCUMENT,
    //     url: url,
    //     onClick: this.closePopup,
    //   },
    // });
    const {history, location} = this.props;
    history.push({
      pathname: location.pathname + '/document-view',
      state: {background: location, url: url},
    });
    // this.getDocumentTempURL(url);
  };

  getDocumentTempURL = url => {
    const {history, location} = this.props;
    const data = {
      url: url,
      expiryTime: 30,
    };
    API.preSignedURL(data)
      .then(res => {
        console.log('res.data', res.data?.preSignedUrl);
        history.push({
          pathname: location.pathname + '/document-view',
          state: {background: location, url: res.data?.preSignedUrl},
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  onClick = (action, data) => {
    const {dispatch, reducerState, dispatchSetVehiclesData} = this.props;
    const {history, location} = this.props;
    console.log('location', location);
    switch (action) {
      case 'search': {
        this.handleSearch();
        break;
      }
      case 'cancel': {
        this.goBack();
        break;
      }
      case 'view': {
        this.onView();
        break;
      }

      case 'addMaterial': {
        history.push({
          pathname: '/u/permits/add-new-material',
          state: {
            ...location.state,
            comingFrom: 'addDriversPage',
            // vehicle: vehicle,
          },
        });
        break;
      }
      case 'addDriverToTable': {
        if (!data.RFIDNumber) {
          this.showErrorPopup(
            'RFID Number of the person is missing. Please add another person.',
          );
        } else {
          dispatch({
            type: 'add-driver',
            payload: data,
          });
        }

        break;
      }
      case 'addDriverToTableAndUpdate': {
        if (!data.RFIDNumber) {
          this.showErrorPopup(
            'RFID Number of the person is missing. Please add another person.',
          );
        } else {
          this.updatePersonAndAddToTable(data);
        }

        break;
      }
      case 'deleteDriver': {
        dispatch({
          type: 'add-driver',
          payload: null,
        });
        this.setState({
          driver: null,
          favorite: {value: null, error: null},
        });
        break;
      }
      case 'submit': {
        console.log('reducerState in driver', reducerState);
        dispatch({
          type: 'submit-vehicle',
          // payload: null,
          payload: {
            vehicle: reducerState.currentVehicle.vehicle,
            driver: reducerState.currentVehicle.driver,
            material: reducerState.currentVehicle.material,
          },
        });
        setTimeout(() => {
          const {reducerState} = this.props;
          console.log('reducerState in driver', reducerState);

          dispatchSetVehiclesData(reducerState.vehicles);
          history.push('/u/permits/request-permits');
        }, 0);
        // history.goBack();
        break;
      }
      default: {
        console.warn('No action found...', action);
        break;
      }
    }
  };

  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  closePopup = () => {
    this.setState({
      popup: null,
    });
  };
  updatePersonAndAddToTable = data => {
    const {driverDetails, ...personDetails} = data;
    const {dispatch} = this.props;
    API.updatePersonDrivingLicence(driverDetails)
      .then(updatedRes => {
        let data = {...personDetails, ...driverDetails};
        console.log('updated successfuly...', data);
        dispatch({
          type: 'add-driver',
          payload: data,
        });
      })
      .catch(err => {
        const errorMsg = util.getErrorMessage(err);
        this.showErrorPopup(errorMsg);
      });
  };

  checkDisabled = () => {
    const {reducerState} = this.props;
    const currentDriver = reducerState.currentVehicle.driver;

    if (currentDriver) {
      return false;
    } else {
      return true;
    }
  };

  getProps = () => {
    const {location} = this.props;
    const {reducerState} = this.props;
    console.log('reducerState', reducerState);
    console.log('LOCATION', location);
    // const {vehicle, driver} = this.state;
    const {driver, favoriteOptions, favorite, filter, filterOptions} =
      this.state;
    const persons = location.state.persons;
    return {
      goBack: this.goBack,
      onClick: this.onClick,
      onChange: this.onChangeHandler,
      checkDisabled: this.checkDisabled,
      driver,
      persons,
      vehicle: reducerState.currentVehicle.vehicle,
      currentDriver: reducerState.currentVehicle.driver,
      material: reducerState.currentVehicle.material,
      favoriteOptions,
      favorite,

      driverFormData: this.driverFormData,
      filter,
      filterOptions,
    };
  };

  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer containerStyle={styles.popupContainerStyle}>
            {' '}
            {this.getPopupContent()}{' '}
          </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

// export default withRouter(PersonCards);
// export default withRouter(AddDriver);
const mapStateToProps = reduxProps => ({
  reduxProps,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetVehiclesData: payload => {
    dispatch(setVehicleData(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddDriver),
);
