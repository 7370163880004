import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import util from '../../../../util';

import Spinner from 'react-spinkit';
import Popup from '../../../../components/SimplePopup';
import PermitSummaryPopup from '../../../../components/PermitSummaryPopup';

import styles from './styles.module.scss';

import ViewRenderer from './views';
import { commonImages, operatorImages } from '../../../../assets/images';
import strings from '../../../../globalization';
import PopupContainer from '../../../../components/PopupContainer';
import { getFormData } from './data';
import { API } from '../../../../requests';
import AddValidityPeriod from '../../../../components/AddValidityPeriod';
import PermitSummary from '../../AgencyPermits/PermitSummary/views';

import { connect } from 'react-redux';
import moment from 'moment';
import {
  clearPersonsVehiclesData,
  deleteMaterial,
  deletePerson,
  deleteVehicle,
} from '../../../../store/actions/permits';

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  SPINNER: 'spinner',
  ERROR: 'error',
  SIMPLE_POPUP: 'simple-popup',
  ADD_DAYS_POPUP: 'add-days-popup',
  PERMITS_POPUP: 'permits-popup',
};

class RequestNewPermit extends Component {
  constructor(props) {
    super(props);

    this.formData = getFormData();
    let preparedObject = {};
    this.formData.map(obj => {
      preparedObject[obj.stateField] = { value: obj.stateDefault, error: null };
      if (obj.optionsField) {
        preparedObject[obj.optionsField] = [];
      }
    });
    const reduxProps = this.props.reduxProps;
    console.log('reduxProps--> Request New Permit', reduxProps);
    const {
      login: { loginInfo },
    } = reduxProps;
    const { company, user } = loginInfo;

    this.state = {
      popup: null,
      passDate: '',
      success: false,
      serverTime: null,
      companyId: company?.id,
      ...preparedObject,
    };
  }

  componentDidMount = () => {
    this.reset();
    this.getData();
    //    this.getCreditsData();
    this.getServerTime();
  };

  getServerTime = () => {
    API.getServerTime()
      .then(res => {
        console.log('getServerTime res.data', res.data);
        const date = new Date(res.data.timestamp);
        const showPerviousDate = date.getHours() < 8;
        this.setState({
          serverTime: showPerviousDate
            ? moment(date).subtract(1, 'day').toDate()
            : date,
        });
      })
      .catch(error => console.log(error));
  };

  getCreditsData = () => {
    API.getCreditWalletDetails()
      .then(res => {
        console.log('res.data', res.data);
        this.setState({
          availableCredits: res.data.availableCredits,
          popup: null,
        });
      })
      .catch(error => console.log(error));
  };

  // fetchLocationById = id => {
  //   API.fetchLocationById(id)
  //     .then(res => {
  //       console.log('res---location by id', res.data);
  //       this.populateData(res.data);
  //     })
  //     .catch(error => {
  //       console.log('error', error);
  //       const errorMsg = util.getErrorMessage(error);
  //       this.showErrorPopup(errorMsg);
  //     });
  // };

  handleUpload = (field, files, error) => {
    this.showSpinner();
    if (error) {
      this.showErrorPopup(error);
      return;
    }
    if (files === null) {
      throw new Error('File does not exist');
    }

    let filesArray = [];
    for (let i = 0, numFiles = files.length; i < numFiles; i++) {
      filesArray.push(files[i]);
    }

    console.log('filesArray', filesArray);

    // FIXME: Fix real uploading thing.

    let promises = filesArray.map(file => {
      const data = new FormData();
      data.append('s3FolderName', 'company');
      data.append('file', file);
      return API.uploadToS3(data).then(response => {
        console.log('Response Here', response.data);
        const url = response.data;
        return { fileUrl: url, fileName: file.name };
      });
    });

    return Promise.all(promises)
      .then(response => {
        console.log('[field] - this.state[field]', this.state[field]);
        console.log('response', response);
        this.setState(prevState => ({
          popup: null,
          [field]: {
            ...prevState[field],
            value: [...prevState[field].value, ...response],
            error: null,
          },
        }));
      })
      .catch(error => {
        console.log('error', error);
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;

        this.setState({
          popup: null,
        });

        //throw new Error(errorMsg);
        console.log(errorMsg);
      });
  };

  getPopupContent = () => {
    const { popup } = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.ADD_DAYS_POPUP: {
        return <AddValidityPeriod onClick={popup.onClick} />;
      }
      case POPUP_CONSTANTS.PERMITS_POPUP: {
        return <PermitSummaryPopup {...popup} />;
      }
      default: {
        console.warn('case not handled', popup.type);
        return null;
      }
    }
  };

  handlePassValidity = value => {
    console.log('value', value);
    const { passValidityPeriodOptions, visitingDate } = this.state;
    if (!visitingDate.value) {
      this.showErrorPopup('Please select visiting date');
      return;
    }
    let requiredObj = passValidityPeriodOptions.find(elem => value === elem.id);
    console.log(requiredObj);
    if (!requiredObj) {
      this.showErrorPopup(strings.somethingWentWrong);
      return;
    }
    if (requiredObj.noOfDays === 0) {
      this.setState({
        popup: {
          type: POPUP_CONSTANTS.ADD_DAYS_POPUP,
          onClick: days => {
            // let passDate = util.calculateDateStrings(visitingDate.value, days);
            let passDate = util.calculateDateStrings(
              visitingDate.value,
              days,
              requiredObj?.id,
            );
            const preparedObject = {
              passDate: { value: passDate },
              numberOfDays: days,
              popup: null,
            };
            if (days <= 15) {
              preparedObject.policeVerificationDocument = { value: [] };
              this.formData = this.formData.filter(
                item => item.stateField !== 'policeVerificationDocument',
              );
            } else {
              this.formData = getFormData();
            }
            this.setState(preparedObject, function () {
              console.log('this.state', this.state);
            });
          },
        },
      });
    } else {
      let passDate = util.calculateDateStrings(
        visitingDate.value,
        requiredObj.noOfDays,
        requiredObj?.id,
      );
      const preparedObject = {
        passDate: {
          value: passDate,
        },
        numberOfDays: requiredObj.noOfDays,
      };
      console.log('handle pass validity -->', requiredObj.noOfDays);
      if (requiredObj.noOfDays <= 15) {
        preparedObject.policeVerificationDocument = { value: [] };
        this.formData = this.formData.filter(
          item => item.stateField !== 'policeVerificationDocument',
        );
      } else {
        this.formData = getFormData();
      }
      this.setState(preparedObject);
    }
  };
  getVehicleValidity = () => {
    return API.getVehicleValidity().then(validityRes => {
      console.info('validityres.data', validityRes.data);
      this.setState({
        // passValidityPeriodOptions: util.addDataToStrings(validityRes.data),
        passValidityPeriodOptions: validityRes.data,
      });
    });
  };
  getAllDepartments = () => {
    return API.getAllDepartments().then(departmentsRes => {
      console.info('departments response', departmentsRes);
      this.setState({
        // departmentOptions: util.addDataToStrings(departmentsRes.data),
        departmentOptions: departmentsRes.data,
      });
    });
  };

  getAllWorkArea = () => {
    return API.getAllWorkArea().then(areasRes => {
      this.setState({
        // areasOfWorkOptions: util.addDataToStrings(areasRes.data),
        areasOfWorkOptions: areasRes.data,
      });
    });
  };

  getAllWorkPurpose = () => {
    return API.getAllWorkPurpose().then(workPurposeRes => {
      this.setState({
        purposeOfWorkOptions: workPurposeRes.data,
        // purposeOfWorkOptions: util.addDataToStrings(workPurposeRes.data),
      });
    });
  };
  showConfirmationPopup = (message, onConfirm, data) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: message,
        messageStyle: { color: '#E67717' },
        // headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.cancelPopup,
            onClick: this.closePopup,
            outline: true,
          },
          {
            text: strings.confirm,
            onClick: onConfirm
              ? () => {
                onConfirm(data);
                this.setState({
                  popup: null,
                });
              }
              : this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  getData = async () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
    let promises = [];
    promises.push(this.getVehicleValidity());
    promises.push(this.getAllDepartments());
    promises.push(this.getAllWorkArea());
    promises.push(this.getAllWorkPurpose());

    Promise.all(promises)
      .then(() => {
        this.closePopup();
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);

        // crashlytics().recordError(new Error(errorMsg));
        console.error('getData error', errorMsg, error);
        this.showErrorPopup(errorMsg);
      });
  };

  updateValidityText = visitingDate => {
    const { numberOfDays, passValidityPeriod } = this.state;
    if (passValidityPeriod.value) {
      let passDate = util.calculateDateStrings(
        visitingDate,
        numberOfDays,
        passValidityPeriod?.value,
      );
      this.setState({
        passDate: {
          value: passDate,
        },
      });
    }
  };

  onChangeHandler = (field, value) => {
    const {
      permits: { persons },
    } = this.props.reduxProps;
    switch (field) {
      case 'passValidityPeriod': {
        this.handlePassValidity(value);

        return this.setState({
          [field]: {
            value,
          },
        });
      }
      case 'visitingDate': {
        //  alert(persons);
        if (persons && persons.length > 0) {
          this.showConfirmationPopup(
            'Your current changes in the page will be lost if you change the permit start date.',
            () => this.reset(),
          );
          console.log('clearing data on date change');
        }

        this.updateValidityText(value);

        return this.setState({
          [field]: {
            value,
          },
        });
      }
      case 'purposeOfWork': {
        const { purposeOfWorkOptions } = this.state;

        return this.setState({
          [field]: {
            value,
          },
          purposeOfWorkString: purposeOfWorkOptions.filter(
            elem => value === elem.id,
          )[0].name,
        });
      }
      case 'department': {
        const { departmentOptions } = this.state;

        return this.setState({
          [field]: {
            value,
          },
          departmentString: departmentOptions.filter(
            elem => value === elem.id,
          )[0].name,
        });
      }
      case 'areasOfWork': {
        const { areasOfWorkOptions } = this.state;

        return this.setState({
          [field]: {
            value,
          },
          areasOfWorkString: areasOfWorkOptions.filter(
            elem => value === elem.id,
          )[0].name,
        });
      }
      default:
        return this.setState({
          [field]: {
            value,
            error: null,
          },
        });
    }
  };

  checkDisabledForm = elem => {
    const { visitingDate } = this.state;
    if (elem.stateField === 'passValidityPeriod') {
      if (visitingDate.value) {
        return false;
      } else {
        return true;
      }
    }
  };
  showTwoButtonsPopup = (errorMessage, onFirstClick, onSecondClick) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: { color: '#E67717' },
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: `Cancel`,
            onClick: onFirstClick ? onFirstClick : this.closePopup,
            outline: true,
            style: { fontSize: '0.8rem' },
          },
          {
            text: `Recharge`,
            onClick: onSecondClick ? onSecondClick : this.closePopup,
            outline: true,
            style: {
              backgroundColor: '#0045e6',
              color: '#ffffff',
              fontSize: '0.8rem',
            },
          },
        ],
      },
    });
  };

  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: { color: '#E67717' },
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  showSuccessPopup = (message, onClick) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: message,
        headingImage: operatorImages.successIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: onClick ? onClick : this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  permitSummary = data => {
    console.log(data);
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.PERMITS_POPUP,
        message: <PermitSummary {...data} />,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.cancelPopup,
            onClick: this.closePopup,
            outline: true,
          },
          {
            text: strings.confirm,
            buttonStyles: styles.buttonPrimary,
            onClick: () => {
              this.showSpinner();

              this.submitRequest()
                .then(res => {
                  // Fixme: uncomment

                  let referenceIds = res.referenceIds;

                  this.closePopup();
                  this.setState({
                    // referenceId: referenceId,
                    referenceIds: referenceIds,
                    success: true,
                  });
                })
                .catch(errors => {
                  if (
                    errors.response?.data?.code &&
                    (errors?.response?.data?.code === 101 ||
                      errors?.response?.data?.code === 102)
                  ) {
                    // FIXME: Add error message from backend - remove insufficient balance.
                    this.showTwoButtonsPopup(
                      errors.response?.data?.message ||
                      `Available Credits balance is below 100, please recharge the wallet with sufficient credits`,
                      this.closePopup,
                      this.openWalletWindow,
                    );
                  } else {
                    const errorMsg = errors.response
                      ? errors.response.data.message
                      : errors.message;
                    this.closePopup();
                    this.showErrorPopup(errorMsg);
                    console.log(errors);
                  }
                });
            },
            outline: true,
          },
        ],
      },
    });
    return;
  };

  handleSubmit1 = () => {
    let error = this.handleErrors();
    const { reduxProps } = this.props;
    const { permits } = reduxProps;
    const vehicles = permits.vehicles;
    const persons = permits.persons;

    if (error) {
      this.closePopup();
    } else if (persons.length === 0 && vehicles.length === 0) {
      console.info('Add person or vehicle', persons, vehicles);
      this.showErrorPopup('Add vehicle');
    } else {
      console.log('FIND ME!');
      this.showSpinner();
      this.submitRequest()
        .then(res => {
          // Fixme: uncomment

          let referenceIds = res.referenceIds;
          // let referenceIds = [
          //   {
          //     type: 'person',
          //     referenceId: 'PW202ABDKKDD',
          //   },
          //   {
          //     type: 'person',
          //     referenceId: 'PW202XYZKLL',
          //   },
          //   {
          //     type: 'vehicle',
          //     referenceId: 'PW202ABDKKCK',
          //   },
          // ];

          this.closePopup();
          this.setState({
            // referenceId: referenceId,
            referenceIds: referenceIds,
            success: true,
          });
        })
        .catch(errors => {
          if (
            errors.response?.data?.code &&
            (errors?.response?.data?.code === 101 ||
              errors?.response?.data?.code === 102)
          ) {
            // FIXME: Add error message from backend - remove insufficient balance.
            this.showTwoButtonsPopup(
              errors.response?.data?.message ||
              `Available Credits balance is below 100, please recharge the wallet with sufficient credits`,
              this.closePopup,
              this.openWalletWindow,
            );
          } else {
            const errorMsg = errors.response
              ? errors.response.data.message
              : errors.message;
            this.closePopup();
            this.showErrorPopup(errorMsg);
            console.log(errors);
          }
        });
    }
  };

  openWalletWindow = () => {
    window.open('/u/wallet', '_blank');

    this.setState({
      popup: null,
    });
  };

  handleSubmit = () => {
    let error = this.handleErrors();
    const { reduxProps } = this.props;
    const { permits } = reduxProps;
    const vehicles = permits.vehicles;
    const persons = permits.persons;

    if (error) {
      this.closePopup();
    } else if (persons.length === 0 && vehicles.length === 0) {
      console.info('Add person or vehicle', persons, vehicles);
      this.showErrorPopup('Add vehicle or person');
    } else {
      console.log('FIND ME!', { ...this.props.reduxProps, ...this.state });
      this.permitSummary({ ...this.props.reduxProps, ...this.state });
    }
  };
  checkDisabledForm = elem => {
    const { visitingDate } = this.state;
    if (elem.stateField === 'passValidityPeriod') {
      if (visitingDate.value) {
        return false;
      } else {
        return true;
      }
    }
  };

  submitRequest = async () => {
    console.log('in submitRequest');
    const { reduxProps } = this.props;
    const { permits } = reduxProps;
    const vehicles = permits.vehicles;
    const persons = permits.persons;
    console.log(persons);
    console.log(vehicles);
    const {
      visitingDate,
      areasOfWork,
      department,
      numberOfDays,
      purposeOfWork,
      passValidityPeriod,
      policeVerificationDocument,
    } = this.state;
    console.log(this.state);
    let visitingDateValidity = util.getDateTimeString(
      visitingDate.value,
      'mmddyyyy',
    );
    console.log('submit form data... materials', vehicles[0]);
    let getMaterialDetails = () => {
      return vehicles.length > 0
        ? vehicles
          .map(el =>
            el.material
              ? {
                vehicleId: el.vehicle.id,
                id: parseInt(el.material.id),
                materialDetails: {
                  ...el.material,
                },
              }
              : undefined,
          )
          .filter(item => item !== undefined)
        : undefined;
    };

    let getVehicleIDs = () => {
      return vehicles.length > 0
        ? vehicles.map(elem => ({
          vehicleId: elem.vehicle.id,
          driverId: elem?.driver?.id,
          tripType: elem?.vehicle.tripType,
        }))
        : undefined;
    };

    let getNewPersonIDs = () => {
      return persons.length > 0
        ? persons.map(elem => ({
          vehicleId: elem?.vehicle?.vehicleId,
          personId: elem?.id,
          linkedPermitId: elem?.vehicle?.permitId,
          twoWheelerVehicleRegistrationNumber: elem?.twoWheelerVehicleRegistrationNumber
        }))
        : undefined;
    };

    let getPersonIDs = () => {
      return persons.length > 0 ? persons.map(el => el.id) : undefined;
    };

    let preparedData = {
      visitingDate: visitingDateValidity,
      // departmentId: department.value,
      noOfDays: Number(numberOfDays),
      persons: getNewPersonIDs(),
      material:
        getMaterialDetails() && getMaterialDetails().length > 0
          ? getMaterialDetails()
          : undefined,
      validityPeriodId: passValidityPeriod.value,
      vehicles: getVehicleIDs(),
      // workAreaId: areasOfWork.value,
      workPurposeId: purposeOfWork.value,
    };

    if (policeVerificationDocument.value.length > 0) {
      preparedData.policeVerificationCertificateUrl =
        policeVerificationDocument.value[0].fileUrl;
    }
    console.log('preparedData', preparedData);
    let res = await API.registerPermit(preparedData);
    // FIXME: Fix this with arrays of ref IDS when ready
    // if (res?.status === 200 && res?.data?.referenceId) {
    //   console.log('res', res.data);
    // }
    if (res?.status === 200) {
      console.log('res', res.data);
    }
    // API.registerPermit(preparedData) {

    // }
    return res.data;
  };

  handleErrors = () => {
    let err = false;
    const update = {};
    let formData = [...this.formData];
    console.log('formData', formData);
    for (let i = 0; i < formData.length; i++) {
      let element = formData[i];
      console.log('element', i, element);
      const error = util.validate(
        this.state[element.stateField].value,
        element,
      );
      if (error) {
        err = true;
        console.log('error', error);
        console.log('element.stateField', element.stateField);
        update[element.stateField] = {
          ...this.state[element.stateField],
          error: error,
        };
      }
    }
    this.setState({
      ...update,
    });
    return err;
  };

  resetState = () => {
    let preparedObject = {};
    this.formData.map(obj => {
      preparedObject[obj.stateField] = { value: obj.stateDefault, error: null };
    });

    this.setState({
      popup: null,
      passDate: '',
      success: false,
      ...preparedObject,
    });
  };
  onClickHandler = (action, data) => {
    const { history, location } = this.props;
    const { visitingDate, passValidityPeriod, numberOfDays, companyId } =
      this.state;
    console.log('passValidityPeriod:', passValidityPeriod, numberOfDays);
    const {
      permits: { persons },
    } = this.props.reduxProps;
    const {
      permits: { vehicles },
    } = this.props.reduxProps;
    switch (action) {
      case 'addPerson': {
        //FIXME path
        // const drivers = vehicles.map(vObj => vObj.driver);
        if (!visitingDate?.value || !numberOfDays) {
          this.showErrorPopup(
            'Please select permit start date and pass validity to add person permit',
          );
          return;
        }
        history.push({
          pathname: '/u/permits/add-new-person',
          //   pathname: location.pathname + '/add-new-person',
          state: {
            background: location,
            persons: [...persons],
            visitingDate: moment(visitingDate?.value).format('MM-DD-YYYY'),
            noOfDays: numberOfDays,
            companyId: companyId,
          },
        });
        break;
      }
      case 'viewPerson': {
        //FIXME path
        history.push({
          pathname: '/u/permits/add-new-person',
          state: {
            background: location,
            persons: [...persons],
            status: 'view',
            id: data,
          },
        });
        break;
      }
      case 'addVehicle': {
        //FIXME path
        const drivers = vehicles.map(vObj => vObj?.driver);
        history.push({
          pathname: '/u/permits/add-new-vehicle',
          //          pathname: location.pathname + '/add-new-vehicle',
          state: {
            background: location,
            persons: [...persons, ...drivers],
            vehicles: vehicles,
          },
        });
        break;
      }
      case 'addMaterial': {
        history.push({
          pathname: '/u/permits/select-vehicle',
          state: {
            background: location,
            comingFrom: 'requestNewPermit',
            vehicles: vehicles,
          },
        });
        console.log('Add Material clicked...');
        break;
      }
      case 'addDriver': {
        //FIXME path
        history.push({
          pathname: '/u/permits/add-new-driver',
          state: { background: location },
        });
        break;
      }
      case 'statusRoute': {
        //FIXME path
        history.push({
          pathname: '/u/permits/' + data.route,
        });
        break;
      }
      case 'submitRequest': {
        this.handleSubmit();
        break;
      }
      case 'successOkay': {
        console.log('clicked on successOkay');
        // Clear context and state Data.
        this.reset();
        break;
      }
      case 'delete-person': {
        const { dispatchDeletePerson } = this.props;
        this.showConfirmationPopup(
          'Are you sure you want to delete person?',
          dispatchDeletePerson,
          data,
        );

        // dispatchDeletePerson(data);
        break;
      }
      case 'delete-vehicle': {
        const { dispatchDeleteVehicle } = this.props;
        this.showConfirmationPopup(
          'Are you sure you want to delete the vehicle from the permit?',
          dispatchDeleteVehicle,
          data,
        );

        // dispatchDeleteVehicle(data);
        break;
      }
      case 'delete-material': {
        const { dispatchDeleteMaterial } = this.props;
        this.showConfirmationPopup(
          'Are you sure you want to delete material?',
          dispatchDeleteMaterial,
          data,
        );
        // dispatchDeleteMaterial(data);
        break;
      }
      case 'clear': {
        console.log('clicked on clear');
        this.reset();
        break;
      }

      default: {
        console.warn('no action taken');
      }
    }
  };

  reset = () => {
    // console.log(this.state);
    const { dispatchClearPersonsVehicles } = this.props;
    dispatchClearPersonsVehicles();
    this.resetState();
  };
  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  closePopup = () => {
    // const {history} = this.props;
    // history.goBack();
    this.setState({
      popup: null,
    });
  };
  computeVehiclesTableData = () => {
    const { reduxProps } = this.props;
    const { permits } = reduxProps;
    const vehicles = permits.vehicles;
    let finalArray = [];
    console.log(this.props);
    vehicles.map(vObj => {
      let vehicleObj = {
        vehicleId: vObj.vehicle.id,
        //        driverId: vObj.driver.id,
        entityName: vObj?.vehicle.vehicleRegistrationNumber,
        entityRFIDNumber: vObj?.vehicle.rfidnumber,
        entityRequestType: 'Vehicle',
        tripType:
          vObj?.vehicle.tripType === 1 ? 'Single Trip' : 'Multiple Trips',
        entityDocumentProofNumber: '-',
      };

      // let driverObj = {
      //   vehicleId: vObj.vehicle.id,
      //   driverId: vObj.driver.id,
      //   entityName: vObj.driver.name,
      //   entityRFIDNumber: vObj.driver.rfidnumber,
      //   entityRequestType: `Vehicle - Driver (${vObj.vehicle.vehicleRegistrationNumber})`,
      //   entityDocumentProofNumber: vObj.driver.aadhaarNumber,
      // };
      finalArray.push(vehicleObj);
      // finalArray.push([]);
    });

    return finalArray;
  };
  computeMaterialsTableData = () => {
    const { reduxProps } = this.props;
    const { permits } = reduxProps;
    const vehicles = permits.vehicles;
    let finalArray = [];

    vehicles.map(vObj => {
      if (vObj.material) {
        console.log('vObj.material', vObj.material);
        let materialObj = {
          id: vObj.material.id,
          vehicleId: vObj.vehicle.id,
          driverId: vObj?.driver?.id,
          entityMaterialType: vObj.material.materialType.name,
          entityNumber:
            vObj.material.IGNNumber ||
            vObj.material.CHACode ||
            vObj.material.workOrderPermitNumber,
          // entityValidityDate: moment(vObj.material.validDate.value).format(
          //   'DD/MM/YYYY',
          // ),
          entityValidityDate: vObj.material.validDate
            ? moment(vObj.material.validDate).format('DD/MM/YYYY')
            : moment(vObj.material.requestValidUpto).format('DD/MM/YYYY'),
          entityLinkedVehicle: vObj.vehicle.vehicleRegistrationNumber,
        };
        // finalArray.push(vehicleObj);
        console.log('materialObj---compute function', materialObj);
        finalArray.push(materialObj);
      }
    });

    return finalArray;
  };
  checkMaterialDisabledButton = () => {
    const { permits } = this.props.reduxProps;

    // check if any vehicle has material = undefined. If Yes, allow add material button
    let requiredData = permits.vehicles.filter(elem =>
      elem.material ? false : true,
    );
    // const vehicles = reducerState.vehicles;
    if (requiredData.length > 0) {
      return false;
    } else {
      return true;
    }
  };
  getProps = () => {
    // console.log("filterLocations", filterLocations);
    const { reduxProps } = this.props;
    const { permits } = reduxProps;
    const { passDate, availableCredits, serverTime } = this.state;
    return {
      stateData: this.state,
      // onBlur: this.onBlur,
      formData: this.formData,
      onChangeHandler: this.onChangeHandler,
      checkDisabledForm: this.checkDisabledForm,
      onClick: this.onClickHandler,
      handleUpload: this.handleUpload,
      persons: permits.persons,
      passDate,
      vehicles: this.computeVehiclesTableData(),
      materials: this.computeMaterialsTableData(),
      checkMaterialDisabledButton: this.checkMaterialDisabledButton,
      availableCredits: availableCredits,
      serverTime,
    };
  };

  render() {
    const viewProps = this.getProps();
    const { popup } = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer containerStyle={styles.popupContainerStyle}>
            {' '}
            {this.getPopupContent()}{' '}
          </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

const mapStateToProps = reduxProps => ({
  reduxProps,
});

const mapDispatchToProps = dispatch => ({
  dispatchClearPersonsVehicles: () => {
    dispatch(clearPersonsVehiclesData());
  },
  dispatchDeletePerson: payload => {
    dispatch(deletePerson(payload));
  },
  dispatchDeleteVehicle: payload => {
    dispatch(deleteVehicle(payload));
  },
  dispatchDeleteMaterial: payload => {
    dispatch(deleteMaterial(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RequestNewPermit),
);
