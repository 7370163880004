import {REHYDRATE} from 'redux-persist';
import {ActionTypes} from '../constants';

const defaults = {
  cart: [],
};

export interface CacheState {}

export interface ActionType {
  type: string;
  payload: any;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaults, action: ActionType) => {
  console.log('action...', action);
  switch (action.type) {
    case ActionTypes.SET_CART_ITEM: {
      console.log('concat operation...', state.cart.concat(action.payload));
      return {
        ...state,
        cart: state.cart.concat(action.payload),
      };
    }

    case ActionTypes.DELETE_CART_ITEM: {
      console.log('delete vehicle', action.payload);
      const updatedCart = state.cart.filter(elem => elem.id !== action.payload);
      return {
        ...state,
        cart: updatedCart,
      };
    }
    case ActionTypes.UPDATE_QUANTITY: {
      const {quantity, id} = action.payload;
      const updatedCart = state.cart.map(elem => {
        if (elem.id === id) {
          elem.quantity = quantity;
          elem.total = quantity * elem.cost;
        }
        return elem;
      });
      return {
        ...state,
        cart: updatedCart,
      };
    }
    case ActionTypes.CLEAR_CART: {
      console.log('clear cart called... redux reducer');
      return {
        ...state,
        cart: [],
      };
    }

    default:
      return {...state};
  }
};
