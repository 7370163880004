import React from 'react';
import styles from './styles.module.scss';
import util from '../../util';
import strings from '../../globalization';
import Flex from '../Flex';

import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

// eslint-disable-next-line max-lines-per-function
const CustomTimePicker = ({
  inputType,
  inputLable,
  onChange,
  placeholder,
  onLabelPress,
  style,
  value,
  keyboardType = 'default',
  textStyle,
  leftIcon,
  rightIcon,
  showBottomRightLabel,
  rightLabel,
  onIconClick,
  toggleEyeIcon,
  showIcon = false,
  iconName = '',
  inputLabelStyle,
  isNumber = false,
  containerStyle,
  required = false,
  placeholderColor = '#D3D3D4',
  error,
  onBlur,
  maxLength,
  tabIndex,
  labelStyles,
  containerStyles,
  iconStyles,
  inputBoxClassName,
  labelClassNames,
  containerClassNames,
  ...inputProps
}: Props) => {
  // console.log("value - here", value);

  return (
    <div style={containerStyles} className={styles.container}>
      <Flex direction="row" className={styles.labelContainer}>
        <p className={[styles.label, labelStyles, labelClassNames].join(' ')}>
          {inputLable}
        </p>
        {required ? (
          <p style={{color: 'red', marginBottom: '0.3rem'}}>*</p>
        ) : null}
      </Flex>

      <Flex
        style={style}
        className={[
          styles.inputBox,
          inputBoxClassName,
          containerClassNames,
        ].join(' ')}>
        {leftIcon && (
          <Flex direction="column" className={styles.imageContainer}>
            <img
              alt="U"
              src={leftIcon}
              className={styles.leftIconStyles}
              style={iconStyles}
            />
          </Flex>
        )}

        <TimePicker
          use12Hours
          showSecond={false}
          // FIXME: Change these
          value={value}
          onChange={onChange}
          defaultValue={value}
          className={styles.timepicker}
          placeholder={placeholder}
        />
      </Flex>

      <Flex style={{width: '100%', height: '1rem'}}>
        <p className={styles.error}>{error}</p>
      </Flex>
    </div>
  );
};

export default CustomTimePicker;
