import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
const InactivityDetector = ({ onInactive }) => {
    let inactivityTimer;
    const history = useHistory();
    const resetTimer = () => {
        clearTimeout(inactivityTimer);
        inactivityTimer = setTimeout(() => {
            clearTimeout(inactivityTimer);
            console.log('logging out due to inactivity')
            onInactive(); // Call the callback function when user is inactive
        }, 30 * 60 * 1000); // 5 minutes in milliseconds
    };

    const handleUserActivity = () => {
        resetTimer();
    };

    useEffect(() => {
        // Set up event listeners for user activity
        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);
        window.addEventListener('click', handleUserActivity);

        resetTimer(); // Start the initial timer

        return () => {
            // Clean up event listeners when component unmounts
            console.log('removing listeners');
            window.removeEventListener('mousemove', handleUserActivity);
            window.removeEventListener('keydown', handleUserActivity);
            window.removeEventListener('click', handleUserActivity);
            clearTimeout(inactivityTimer); // Clear timeout
            console.log('removed listeners');

        };
    }, []); // Empty dependency array ensures effect runs only once on component mount

    return null; // This component doesn't render anything in the DOM
};

export default InactivityDetector;
