import React, { useState } from 'react';
import { useRouteMatch, Switch, Route, useHistory } from 'react-router-dom';

import Flex from '../Flex';
import styles from './styles.module.scss';
import bellIcon from '../../assets/images/bell-icon/image.png';
import creditCoins from '../../assets/images/credits/image.png';
import errorIcon from '../../assets/images/credits/error.png';
import bgIcon from '../../assets/images/background/image.png';
import { style } from 'react-dom-factories';

interface Props {
  title?: string;
  tabs?: string;
  onTabClick?: () => {};
}

const PermitsHeader = (props: Props) => {
  const {
    title,
    tabs,
    onTabClick,
    onProfileClick,
    activeTab,
    showTabs = true,
    availableCredits,
  } = props;
  const [active, setActive] = useState(activeTab);
  const changeTab = id => {
    setActive(id);
    onTabClick(id);
  };

  const history = useHistory();

  const handleProfileClick = () => {
    history.push('/u/profile');
  };
  return (
    <Flex direction="column" className={styles.headerContainer}>
      <Flex className={styles.heading}>
        <h1 className={styles.headerText}>{title}</h1>
        <Flex>
          {/* <div className={styles.creditsContainer}>
            <div className={styles.creditsLabel}>
              <img
                src={creditCoins}
                className={[styles.image, styles.bellIcon].join(' ')}
              />
              <span className={styles.label}>Available Credits:</span>{' '}
            </div>

            <span className={styles.credits}>{availableCredits}</span>
          </div> */}
          {/* <div className={styles.imageContainer}>
            <img
              src={bellIcon}
              alt="U"
              className={[styles.image, styles.bellIcon].join(' ')}
            />
          </div> */}
          <div className={styles.imageContainer} onClick={handleProfileClick}>
            <img
              onClick={onProfileClick}
              src={bgIcon}
              alt="U"
              className={[styles.image, styles.profilePic].join(' ')}
            />
          </div>
        </Flex>
      </Flex>
      {showTabs && (
        <div className={styles.tabsContainer}>
          {tabs
            ? tabs.map((el, index) => {
              return (
                <div
                  key={`permits-${index}`}
                  className={[
                    styles.tab,
                    el.id == active ? styles.active : '',
                  ].join(' ')}
                  onClick={e => {
                    e.preventDefault();
                    changeTab(el.id);
                  }}>
                  {el.label}
                </div>
              );
            })
            : null}
        </div>
      )}
    </Flex>
  );
};

export default PermitsHeader;
