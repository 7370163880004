import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {API} from '../../../requests';
import util from '../../../util';
import {getOperators} from './data';

import Spinner from '../../../components/Spinner';
import Popup from '../../../components/SimplePopup';

import styles from './styles.module.scss';

import ViewRenderer from './views';
import {commonImages, operatorImages} from '../../../assets/images';
import strings from '../../../globalization';
import PopupContainer from '../../../components/PopupContainer';
import moment from 'moment';
import {connect} from 'react-redux';
import {formLeftFields, formRightFields} from '../AddNewOperator/formFields';

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  SPINNER: 'spinner',
  ERROR: 'error',
  SIMPLE_POPUP: 'simple-popup',
};

class AddNewOperator extends Component {
  constructor(props) {
    super(props);

    this.operators = getOperators();
    const id = this.props.match.params.id;

    let preparedObject = {};
    [...formLeftFields, ...formRightFields].forEach(obj => {
      preparedObject[obj.stateField] = {value: obj.stateDefault, error: null};
      if (obj.optionsField) {
        preparedObject[obj.optionsField] = [];
      }
    });
    console.log('preparedObject', preparedObject);

    const {
      login: {loginInfo},
    } = props.reduxProps;

    const {user} = loginInfo;
    const {location} = loginInfo;
    console.log('loginInfo - Manage Operators', loginInfo);
    this.state = {
      operators: [],
      filterOperators: [],
      status: undefined,
      startDate: moment(),
      endDate: moment(),
      selectedStatus: '',
      selectedLocation: '',

      user: user,
      locations: [],
      activeLocation: location,
      ...preparedObject,
      // photo: {value: operatorImages.userIcon},
      photo: null,
      roleOptions: [],
      mode: id ? 'edit' : 'add',
      id,
    };
    console.log('this.state', this.state);
  }

  componentDidMount = () => {
    const {mode, id} = this.state;
    this.getData();
    if (mode === 'edit') {
      this.fetchOperatorById(id);
    }
  };
  fetchOperatorById = id => {
    API.fetchOperatorById(id)
      .then(res => {
        console.log('res---operator by id', res.data);
        this.populateData(res.data);
      })
      .catch(error => {
        console.log('error', error);
        const errorMsg = util.getErrorMessage(error);
        this.showErrorPopup(errorMsg);
      });
  };
  populateData = operator => {
    const {id} = this.state;
    const formData = {};

    const {genderOptions} = this.state;
    let roleOptions = this.getRoleOptions();
    let locationsOptions = this.getLocationsOptions();

    console.log('currentOperator', operator);

    Object.keys(operator).forEach((key, index) => {
      const value = operator[key];
      formData[key] = {value};
    });
    // FIXME: What to do?
    formData.operatorName = {
      value: operator.user?.firstName,
    };
    formData.emailAddress = {
      value: operator.user?.emailAddress,
    };
    formData.phoneNumber = {
      value: operator.user?.phoneNumber.slice(-10),
    };
    formData.address = {
      value: operator.user?.address,
    };
    formData.photo = {
      value: operator.user?.photo,
    };
    formData.operatorId = {
      value: operator.idCardNumber,
    };
    formData.gender = {
      value: operator.genderId,
    };
    formData.location = {
      value: operator.locationId,
    };
    formData.role = {
      value: operator.user?.role?.roleId,
    };
    formData.shift = {
      value: operator.shiftId,
    };
    this.fetchShift(operator.locationId);

    console.log('formData', formData);
    this.setState(prevState => {
      return {
        ...prevState,
        ...formData,
        genderOptions,
        roleOptions,
        locationsOptions,
        id,
      };
    }, this.preSignedURL(formData.photo?.value));
  };

  getData = async () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });

    // Also doing the shift setting here
    API.fetchActiveLocation()
      .then(response => {
        this.setState({
          locations: response.data,
          locationsOptions: response.data,
          popup: undefined,
        });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        console.info('error....', errorMsg);
        this.setState({
          popup: undefined,
        });
      });
    API.getAllGender()
      .then(genderRes => {
        this.setState({
          genderOptions: genderRes.data,
        });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        this.showErrorPopup(errorMsg);
      });
    API.fetchAllRoles()
      .then(rolesRes => {
        let requiredRoles = rolesRes.data.filter(
          elem => elem.roleId === 6 || elem.roleId === 7,
        );
        // FIXME: Added ID field manually to roles array
        requiredRoles = requiredRoles.map(elem => {
          return {...elem, id: elem.roleId};
        });
        this.setState({
          roleOptions: requiredRoles,
        });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        this.showErrorPopup(errorMsg);
      });
  };

  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      default: {
        console.warn('case not handled', popup.type);
        return null;
      }
    }
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  getButtons = () => {
    const {mode, id, userId, user} = this.state;
    let loggedInUser = this.props.reduxProps.login?.loginInfo?.user;
    console.log('loggedInUser', loggedInUser);

    switch (mode) {
      case 'add': {
        return [
          {
            text: 'Cancel',
            buttonStyles: styles.buttonPrimary,
            onClick: () => {
              this.closePopup();
            },
          },
          {
            text: 'Add',
            buttonStyles: styles.buttonSecondary,
            onClick: () => {
              this.handleAdd();
            },
          },
        ];
      }
      case 'edit': {
        if (loggedInUser?.userId === userId?.value) {
          return [
            {
              text: 'Cancel',
              buttonStyles: styles.buttonPrimary,
              onClick: () => {
                this.closePopup();
              },
            },
            {
              text: 'Update',
              buttonStyles: styles.buttonSecondary,
              onClick: () => {
                this.handleEdit(id);
              },
            },
          ];
        } else {
          return [
            {
              text: 'Delete',
              buttonStyles: styles.buttonDelete,
              onClick: () => {
                this.handleDelete(id);
              },
            },
            {
              text: 'Cancel',
              buttonStyles: styles.buttonPrimary,
              onClick: () => {
                this.closePopup();
              },
            },
            {
              text: 'Update',
              buttonStyles: styles.buttonSecondary,
              onClick: () => {
                this.handleEdit(id);
              },
            },
          ];
        }
        // return [
        //   {
        //     text: 'Delete',
        //     buttonStyles: styles.buttonDelete,
        //     onClick: () => {
        //       this.handleDelete(id);
        //     },
        //   },
        //   {
        //     text: 'Cancel',
        //     buttonStyles: styles.buttonPrimary,
        //     onClick: () => {
        //       this.closePopup();
        //     },
        //   },
        //   {
        //     text: 'Update',
        //     buttonStyles: styles.buttonSecondary,
        //     onClick: () => {
        //       this.handleEdit(id);
        //     },
        //   },
        // ];
      }
      default: {
        return;
      }
    }
  };
  handleDelete = id => {
    API.deleteOperator(id)
      .then(res => {
        this.showSuccessPopup('Operator deleted Successfully');
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        this.showErrorPopup(errorMsg);
      });
  };
  handleEdit = id => {
    let formData = [...formLeftFields, ...formRightFields];
    const err = this.handleErrors(formData);
    if (!err) {
      this.updateFormData(id)
        .then(res => {
          console.log(res);
          this.showSuccessPopup('Operator edited successfully!');
        })
        .catch(error => {
          const errorMsg = util.getErrorMessage(error);
          this.showErrorPopup(errorMsg);
          console.log(error);
        });
    }
  };
  updateFormData = async id => {
    const {
      emailAddress,
      phoneNumber,
      aadharNumber,
      address,
      pincode,
      role,
      location,
      gender,
      operatorId,
      operatorName,
      loginId,
      shift,
      photo,
    } = this.state;
    let preparedData = {
      id,
      idCardNumber: operatorId.value,
      firstName: operatorName.value,
      lastName: operatorName.value,
      genderId: gender.value,
      emailAddress: emailAddress.value,
      phoneNumber: '+91' + phoneNumber.value,
      aadharNumber: aadharNumber.value,
      address: address.value,
      pincode: pincode.value,
      photo: photo.value,
      roleId: role.value,
      locationId: location.value,
      loginId: loginId.value,
      // FIXME: Add shiftId
      shiftId: shift.value,
    };

    this.showSpinner();
    let res = await API.updateOperator(preparedData);

    return res.data;
  };

  closePopup = () => {
    const {history} = this.props;
    history.goBack();
  };
  getHeader = () => {
    const {mode} = this.state;
    if (mode === 'add') {
      return 'Add New Location';
    } else {
      return 'Edit Location';
    }
  };
  fetchShift = locationId => {
    API.fetchShift(locationId)
      .then(response => {
        console.info('response...', response);
        this.setState({
          shiftOptions: response.data,
        });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        console.info('error....', errorMsg);
        this.showErrorPopup(errorMsg);
      });
  };
  onChangeHandler = (field, value) => {
    console.log('field, value', field, value);
    if (field === 'location') {
      // First reset shift, then fetch shifts
      this.setState({
        shift: {
          value: null,
          error: null,
        },
      });
      this.fetchShift(value);
    }

    this.setState(prevState => {
      const currentObj = prevState[field];
      return {
        ...prevState,
        [field]: {
          ...currentObj,
          value,
          error: null,
        },
      };
    });
  };
  onBlur = (field, element) => {
    this.setState(prevState => {
      const currentObj = prevState[field];
      return {
        ...prevState,
        [field]: {
          ...currentObj,
          error: element ? util.validate(currentObj.value, element) : null,
        },
      };
    });
  };
  preSignedURL = url => {
    const data = {
      url: url,
      expiryTime: 30,
    };
    API.preSignedURL(data)
      .then(res => {
        this.setState({
          preSignedPhoto: {value: res.data?.preSignedUrl},
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
  fileURLCallback = (url, error) => {
    // this.operatorPhotoURL = url;
    if(url) {
      this.setState(
        {
          photo: {value: url, error: error},
        },
        this.preSignedURL(url),
      );
  
    } else {
      this.setState(
        {
          photo: {value: url, error: error},
          preSignedPhoto: null,
        },
      );  
    }

  };
  handleErrors = formData => {
    const {photo} = this.state;

    let err = false;
    const update = {};
    console.log('formData', formData);

    // For photo

    for (let i = 0; i < formData.length; i++) {
      let element = formData[i];
      const error = util.validate(
        this.state[element.stateField].value,
        element,
      );
      if (error) {
        err = true;
        update[element.stateField] = {
          ...this.state[element.stateField],
          error: error,
        };
      }
    }
    if (!photo) {
      err = true;
      update.photo = {
        value: null,
        error: 'Photo field is mandatory',
      };
    }
    this.setState({
      ...update,
    });
    return err;
  };
  handleAdd = () => {
    let formData = [...formLeftFields, ...formRightFields];
    const err = this.handleErrors(formData);
    if (!err) {
      this.submitFormData()
        .then(res => {
          console.log(res);
          this.showSuccessPopup('Operator added successfully!');
        })
        .catch(error => {
          const errorMsg = util.getErrorMessage(error);
          this.showErrorPopup(errorMsg);
          console.log(error);
        });
    }
  };
  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  showSuccessPopup = (message, onClick) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: message,
        headingImage: operatorImages.successIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: onClick ? onClick : this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  submitFormData = async () => {
    const {
      emailAddress,
      phoneNumber,
      aadharNumber,
      address,
      pincode,
      role,
      location,
      gender,
      operatorId,
      operatorName,
      loginId,
      shift,
      photo,
    } = this.state;
    let preparedData = {
      idCardNumber: operatorId.value,
      firstName: operatorName.value,
      lastName: operatorName.value,
      genderId: gender.value,
      emailAddress: emailAddress.value,
      phoneNumber: `+91${phoneNumber.value}`,
      aadharNumber: aadharNumber.value,
      address: address.value,
      pincode: pincode.value,
      photo: photo.value,
      roleId: role.value,
      locationId: location.value,
      loginId: loginId.value,
      // FIXME: Add shiftId
      shiftId: shift.value,
    };

    this.showSpinner();
    let res = await API.saveOperator(preparedData);

    return res.data;
  };

  getRoleOptions = () => {
    const {
      roleOptions,
      user: {roleId},
    } = this.state;
    if (roleId === 6) {
      let roles = roleOptions.filter(elem => elem.roleId === 7);
      return roles;
    } else {
      return roleOptions;
    }
  };
  getLocationsOptions = () => {
    const {
      locationsOptions,
      activeLocation,
      user: {roleId},
    } = this.state;
    if (roleId === 6) {
      let locationOption = locationsOptions.filter(
        elem => elem.id === activeLocation.id,
      );
      return locationOption;
    } else {
      return locationsOptions;
    }
  };

  getStateData = () => {
    // NOTE: Only get employees options for Admin, get all options for super admin
    const {genderOptions, shiftOptions} = this.state;

    const roleOptions = this.getRoleOptions();
    const locationsOptions = this.getLocationsOptions();

    console.log('role options, locations', roleOptions, locationsOptions);
    // this.setState({
    //   roleOptions,
    //   locationsOptions,
    // });

    return {
      ...this.state,
      genderOptions,
      shiftOptions,
      roleOptions,
      locationsOptions,
    };
  };
  getHeader = () => {
    const {mode} = this.state;
    if (mode === 'add') {
      return 'Add New Operator';
    } else {
      return 'Edit Operator';
    }
  };
  getReadOnlyFields = () => {
    return [
      'operatorId',
      'emailAddress',
      'phoneNumber',
      'aadharNumber',
      'gender',
      'loginId',
      'location',
    ];
  };
  getProps = () => {
    // console.log("filterLocations", filterLocations);
    return {
      stateData: this.getStateData(),
      onChangeHandler: this.onChangeHandler,
      onBlur: this.onBlur,
      fileURLCallback: this.fileURLCallback,
      buttons: this.getButtons(),
      header: this.getHeader(),
      readOnlyFields: this.getReadOnlyFields(),
      user: this.state.user,
    };
  };

  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer containerStyle={styles.popupContainerStyle}>
            {' '}
            {this.getPopupContent()}{' '}
          </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

const mapStateToProps = reduxProps => ({
  reduxProps,
});
export default withRouter(connect(mapStateToProps, null)(AddNewOperator));
