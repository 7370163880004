import React from 'react';
import {Bar} from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);
const graphData = [
  {
    date: '06 May 2022',
    permitRaised: 120,
    validEntry: 100,
    invalidEntry: 60,
    percentageOfEntry: 40,
  },
  {
    date: '07 May 2022',
    permitRaised: 100,
    validEntry: 80,
    invalidEntry: 60,
    percentageOfEntry: 30,
  },
  {
    date: '08 May 2022',
    permitRaised: 100,
    validEntry: 80,
    invalidEntry: 50,
    percentageOfEntry: 30,
  },
  {
    date: '09 May 2022',
    permitRaised: 120,
    validEntry: 83,
    invalidEntry: 60,
    percentageOfEntry: 40,
  },
  {
    date: '10 May 2022',
    permitRaised: 120,
    validEntry: 70,
    invalidEntry: 50,
    percentageOfEntry: 40,
  },
];
export default function BarChart() {
  const labels = [];
  const permitsRaised = [];
  const validEntry = [];
  const invalidEntry = [];
  const percentageOfEntry = [];

  graphData.forEach(data => {
    labels.push(data.date);
    permitsRaised.push(data.permitRaised);
    validEntry.push(data.validEntry);
    invalidEntry.push(data.invalidEntry);
    percentageOfEntry.push(data.percentageOfEntry);
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: {
        ticks: {
          color: '#d9d9d9',
        },
        suggestedMin: 0,
        suggestedMax: 250,
        grid: {
          drawBorder: false,
          color: '#5c5c5c',
        },
      },

      xAxes: {
        ticks: {
          color: '#d9d9d9',
        },
        grid: {
          borderColor: '#838383',
          lineWidth: 2,
          color: '#838383',
          drawOnChartArea: false,
          //   display: false,
        },
      },
    },

    plugins: {
      legend: {
        display: true,

        position: 'bottom',
        // fontColor: 'red',
        labels: {
          color: '#d9d9d9',
          usePointStyle: true,
          pointStyle: 'rectRounded',
        },
      },

      title: {
        display: true,
        text: 'Trends',
        color: '#d9d9d9',
        font: {
          size: 22,
          family: 'tahoma',
          weight: 'bold',
        },
      },
    },
  };

  const data = {
    labels: labels,

    datasets: [
      {
        label: 'permit raised for the day',
        backgroundColor: '#517bcb',
        borderColor: '#517bcb',
        data: permitsRaised,
      },
      {
        label: 'valid permit entry',
        backgroundColor: '#f4823b',
        borderColor: '#f4823b',
        data: validEntry,
      },
      {
        label: 'invalid permit entry',
        backgroundColor: '#a9a9a9',
        borderColor: '#a9a9a9',
        data: invalidEntry,
      },
      {
        label: 'percentage of valid permit entry',

        backgroundColor: '#ffc618',
        borderColor: '#ffc618',
        data: percentageOfEntry,
      },
    ],
  };

  return (
    <div
      style={{
        width: '70vw',
        height: '70vh',
        padding: '40px',
        backgroundColor: '#525252',
        margin: '20px',
      }}>
      <Bar data={data} height={400} width={600} options={options} />
    </div>
  );
}
