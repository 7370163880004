import React, {useState, useEffect} from 'react';
import styles from './styles.module.scss';
import './styles.css';
import strings from '../../../../../../globalization';
import {withRouter} from 'react-router-dom';
import {AgGridReact} from 'ag-grid-react';
import Select from 'react-select';
import moment from 'moment';
import PermitsHeader from '../../../../../../components/PermitsHeader';

import 'react-daterange-picker/dist/css/react-calendar.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import dimensions from '../../../../../../util/dimensions-util';
// import {customStyles} from './customStyles';
import {adminImages} from '../../../../../../assets/images';
import DateRangePicker from 'react-daterange-picker';
// import { rowData } from './data';
import TextInput from '../../../../../../components/TextInput';
import Button from '../../../../../../components/Button';

import calendarBlue from '../../../../../../assets/images/calendar-blue-icon/image.png';
import {dummyData} from './dummyData';
import TableTimestamp from '../../../../../../components/TableTimestamp';
import SannaLogo from '../../../../../../components/SannaLogo';

const {vw: widthConstant, vh: heightConstant} = dimensions;

const STATUS_MAPPER = {
  PAYMENT_FAILED: 'Payment Failed',
  PAYMENT_COMPLETED: 'Payment Completed',
  PAYMENT_PENDING: 'Payment Pending',
};

const Desktop = props => {
  const {
    onClick,
    onChange,

    selectedStatus,

    agencies,

    searchText,
    dates,
    transactions,
    timestamp,
  } = props;

  const tabs = [
    {
      label: 'Available Credits',
      id: 1,
      route: 'available-credits',
    },
    {
      label: 'Transaction History',
      id: 2,
      route: 'transaction-history',
    },
    {
      label: 'Usage History',
      id: 3,
      route: 'usage-history',
    },
  ];
  const columnDefs = [
    {
      headerName: 'Date',
      field: 'date',
      headerClass: styles.rowHeader,
      width: 170 * widthConstant,
      resizable: true,
      cellRendererFramework: ({data: {date}}) => {
        return (
          <div className={styles.sessionTime}>
            {moment(date).format('DD/MM/YYYY HH:mm')}
          </div>
        );
      },
    },
    {
      headerName: 'Reference Number',
      field: 'referenceNumber',
      headerClass: styles.rowHeader,
      width: 210 * widthConstant,
      resizable: true,
      wrapText: true,
      autoHeight: true,
      cellRendererFramework: ({data: {referenceId}}) => {
        return <div className={styles.sessionTime}>{referenceId}</div>;
      },
    },
    {
      headerName: 'Entry Category',
      field: 'entryCategory',
      headerClass: styles.rowHeader,
      resizable: true,
      minWidth: 210 * widthConstant,
      flex: 1,
      wrapText: true,
      autoHeight: true,
      cellStyle: {lineHeight: 1.5},
      cellRendererFramework: ({data: {entryCategory}}) => {
        return <div className={styles.sessionTime}>{entryCategory}</div>;
      },
    },
    // {
    //   headerName: 'Pack',
    //   field: 'pack',
    //   width: 220 * widthConstant,
    //   headerClass: styles.rowHeader,
    //   resizable: true,
    //   wrapText: true,
    //   autoHeight: true,
    //   cellStyle: {lineHeight: 1.5},
    //   cellRendererFramework: ({data: {pack}}) => {
    //     return <div className={styles.sessionTime}>{pack}</div>;
    //   },
    // },
    {
      headerName: 'Credits',
      field: 'quantity',
      headerClass: styles.rowHeader,
      width: 120 * widthConstant,
      resizable: true,
      cellStyle: {display: 'flex', alignItems: 'center'},
      cellRendererFramework: ({data: {quantity}}) => {
        return <div className={styles.sessionTime}>{quantity}</div>;
      },
    },
    {
      headerName: 'Total Amount incl. GST',
      field: 'totalAmount',
      headerClass: styles.rowHeader,
      resizable: true,
      width: 190 * widthConstant,
      cellStyle: {display: 'flex', alignItems: 'center'},
      cellRendererFramework: ({data: {amount}}) => {
        return <div className={styles.sessionTime}>₹ {amount}</div>;
      },
    },

    {
      headerName: 'Status',
      field: 'statusField',
      headerClass: styles.rowHeader,
      width: 190 * widthConstant,
      resizable: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: {lineHeight: 1.5},
      cellRendererFramework: ({data: {status}}) => {
        return (
          <div className={styles.sessionTime}>
            <p
              style={
                status === 'PAYMENT_COMPLETED'
                  ? {color: '#0AC157'}
                  : {color: '#E67717'}
              }>
              {STATUS_MAPPER[status]}
            </p>
          </div>
        );
      },
    },
    {
      headerName: 'Action',
      field: 'action',
      headerClass: styles.rowHeader,
      width: 150 * widthConstant,
      resizable: true,
      cellStyle: {display: 'flex', alignItems: 'center'},
      cellRendererFramework: ({data}) => {
        return (
          <div>
            {data?.status === 'PAYMENT_COMPLETED' ? (
              <button
                onClick={() => onClick('invoice', data)}
                className={styles.invoiceButton}>
                Invoice
              </button>
            ) : null}
          </div>
        );
      },
    },
  ];
  const [showDate, setShowDate] = useState(false);
  const [gridApi, setGridApi] = useState(null);

  useEffect(() => {
    console.log(gridApi);
    if (gridApi) {
      // gridApi.setRowHeight(50);
    }
    if (gridApi && transactions.length > 10) {
      console.log('changing to normal');
      gridApi.setDomLayout('normal');
    } else if (gridApi) {
      console.log('changing to autoHeight');
      gridApi.setDomLayout('autoHeight');
    }
  }, [gridApi, transactions]);

  const onGridReady = params => {
    setGridApi(params.api);
  };
  const agencyNames =
    agencies &&
    agencies
      .map(agency => {
        return (
          agency.requestType && {
            id: agency.referenceId,
            name: agency.company.name,
          }
        );
      })
      .filter(name => name !== undefined);
  const onCellClick = (event, index) => {
    onClick('rowClick', event.data);
  };
  const onSelect = dates => {
    onChange('date', {
      startDate: moment(dates.start).format('MM-DD-YYYY'),
      endDate: moment(dates.end).format('MM-DD-YYYY'),
      dates,
    });
    toggleDate();
  };
  const toggleDate = () => {
    setShowDate(!showDate);
  };

  const onTabClick = id => {
    switch (id) {
      case 1:
        onClick('statusRoute', {route: 'available-credits', id: id});
        break;
      case 2:
        onClick('statusRoute', {route: 'transaction-history', id: id});
        break;
      case 3:
        onClick('statusRoute', {route: 'usage-history', id: id});
        break;
      default:
        break;
    }
  };

  const formatDate = date => moment(date).format('MMM Do YY');
  const rowStyle = {border: 'none', cursor: 'pointer'};
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return {background: '#f6f6f6', border: 'none'};
    }
  };

  const handleClear = () => {
    setShowDate(false);
    onClick('clearFilters');
  };

  const getRowData = () => {
    let sanitizedData = transactions.map(el => {
      return {
        date: el?.createdDate,
        referenceId: el?.referenceId,
        entryCategory:
          el?.items?.length > 0 ? el.items[0].pack?.passCategory?.name : '-',
        pack: el?.items?.length > 0 ? el.items[0].pack?.name : '-',
        quantity: el?.items?.length > 0 ? el.items[0].quantity : '-',
        amount: el?.amount ? el.amount : '-',
        status: el?.status,
        completeData: el,
      };
    });
    return sanitizedData;
  };
  return (
    <div>
      <PermitsHeader
        title="Wallet"
        onTabClick={onTabClick}
        tabs={tabs}
        // availableCredits={availableCredits}
        activeTab={selectedStatus === 'TRANSACTION_HISTORY' ? 2 : null}
      />
      <div className={styles.bodyContainer}>
        <div className={styles.header}>
          <div className={styles.left}>
            <div className={styles.dateWrapper}>
              <div onClick={toggleDate} className={styles.inputDate}>
                {' '}
                <div>
                  {' '}
                  {dates
                    ? formatDate(dates.start) + '-' + formatDate(dates.end)
                    : strings.ADMIN.dateRange}
                </div>{' '}
                <img src={calendarBlue} className={styles.calendar} alt="C" />
              </div>
              {showDate ? (
                <div className={styles.datePicker}>
                  <DateRangePicker
                    numberOfCalendars={1}
                    selectionType="range"
                    onSelect={onSelect}
                    className={styles.dateRange}
                    value={dates}
                    singleDateRange={true}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.inputSearch}>
              <TextInput
                onChange={text => {
                  onChange('search', text);
                }}
                isEdit={true}
                type={'text'}
                value={searchText}
                inputStyle={styles.inputText}
                showSeacrh={true}
                placeholder={`Search by Reference Number`}
              />
            </div>
            <div onClick={handleClear} className={styles.clearLabel}>
              {' '}
              {strings.ADMIN.clear}
            </div>
            <Button
              onClick={() => onClick('apply')}
              buttonStyle="primary"
              label={strings.ADMIN.apply}
              className={styles.applyBtn}></Button>
          </div>
        </div>
        <TableTimestamp timestamp={timestamp}>
          <div
            className={[
              'ag-theme-balham',
              styles.tableWrapper,
              transactions?.length > 10
                ? styles.tableWrapperNormal
                : styles.tableWrapperAutoHeight,
            ].join(' ')}>
            <AgGridReact
              onGridReady={onGridReady}
              rowStyle={rowStyle}
              getRowStyle={getRowStyle}
              rowHoverHighlight={true}
              columnDefs={columnDefs}
              rowHeight={70 * heightConstant}
              headerHeight={80 * heightConstant}
              defaultColDef={{sortable: true}}
              rowClass={[styles.gridRowClass]}
              onCellClicked={(event, index) => onCellClick(event, index)}
              rowData={getRowData()}
              pagination={true}
              paginationPageSize={100}
              pivotPanelShow={'always'}
              domLayout="autoHeight"></AgGridReact>
          </div>
        </TableTimestamp>
        {/* <div className={styles.bottomLabel}>
          {' '}
          Showing {filterAgencies.length}
        </div> */}
      </div>
      <div className={styles.SannaLogo}>
        <SannaLogo />
      </div>
    </div>
  );
};

export default Desktop;
