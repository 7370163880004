import React from 'react';
import {Redirect, Route, useRouteMatch} from 'react-router-dom';
import {store} from '../../store';
import CheckLogin from '../CheckLogin';
// import API from '../../network';

function PrivateRoute({component: Component, ...rest}: any) {
  const {url} = useRouteMatch();
  return (
    <Route
      {...rest}
      render={props => {
        const {login} = store.getState();
        console.info('loginInfo', login);
        if (login.isLoggedIn) {
          return <Component {...props} />;
        }
        return <CheckLogin Component={Component} {...rest} />;
      }}
    />
  );
}

export default PrivateRoute;
