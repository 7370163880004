import React, { useEffect, useRef, useState } from 'react';
import styles from './Desktop.module.scss';
import AuthLayout from '../../../../../components/AuthLayout';
import { keys, formFields, buttonGroup } from '../../data';
import SeparatorComponent from '../../../../../components/Sparator';
import { withRouter } from 'react-router-dom';
import strings from '../../../../../globalization';
import CustomInput from '../../../../../components/CustomInput';
import Button from '../../../../../components/Button';

import eyeIcon from '../../../../../assets/images/eye/image@3x.png';
import eyeIconClosed from '../../../../../assets/images/eye-hide/image@3x.png';
import closeIcon from '../../../../../assets/images/close-icon/image.png';

import Flex from '../../../../../components/Flex';
import { loginImages, commonImages } from '../../../../../assets/images';
import ReactTooltip from 'react-tooltip';
import packageJson from '../../../../../../package.json';

let interval;

const Desktop = props => {
  const { onClick, onChangeState, onKeyDown } = props;
  const [visible, setVisible] = useState(false);
  const [completed, setCompleted] = useState(1);
  const [modalVisible, setModalVisible] = useState(true);
  const widthRef = useRef(1);

  console.log('version:', packageJson.version);

  const handleClick = action => {
    console.info('handleClick...', action);
    onClick(action);
  };
  const onChange = (action, data) => {
    // console.info('onChange...', action);
    onChangeState(action, data.target.value);
    // this.setState((prevState) => {
    //   return {
    //     ...prevState,
    //     [action]: data,
    //   };
    // });
  };
  const onRightIconClick = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    interval = setInterval(() => {
      // console.log('completed value...', completed);
      // console.log('widthRef.current...', widthRef.current);
      if (widthRef.current === 100) {
        setModalVisible(false);
        clearInterval(interval);
      }
      setCompleted(completed => completed + 1);
      widthRef.current = widthRef.current + 1;
    }, 100);

    return () => clearInterval(interval);
  }, []);

  const getInput = element => {
    return (
      <CustomInput
        labelStyles={styles.customLabel}
        inputLable={element.label()}
        onChange={update => onChange(element.field, update)}
        placeholder={element.placeholder()}
        // required={element.required}
        type={element.type}
        name={element.field}
        leftIcon={element.leftIcon}
        style={{ width: '100%', height: '48px' }}
        containerStyles={{ marginBottom: '10px' }}
      />
    );
  };
  const getPasswordInput = (element, onRightIconClick, visible) => {
    return (
      <CustomInput
        labelStyles={styles.customLabel}
        inputLable={element.label()}
        onChange={update => onChange(element.field, update)}
        placeholder={element.placeholder()}
        // required={element.required}
        name={element.field}
        leftIcon={element.leftIcon}
        style={{ width: '100%', height: '48px' }}
        containerStyles={{ marginBottom: '10px' }}
        type={visible ? 'text' : 'password'}
        rightIcon={visible ? eyeIcon : eyeIconClosed}
        onRightIconClick={onRightIconClick}
      />
    );
  };

  const [open, setOpen] = useState(true);

  return (
    // <AuthLayout>
    <div className={styles.loginContainer}>
      <ReactTooltip
        html={true}
        className={styles.toolTip}
        place="top"
        type="info"
        effect="solid"
        multiline={true}
      />
      <div
        style={{ display: modalVisible ? 'visible' : 'none' }}
        className={styles.notification}>
        <img src={commonImages.errorIcon} /> Please upload your Police NOC.
        Click on Manage Registration Information to update.
        <div
          style={{
            width: completed + '%',
          }}
          className={styles.notificationBar}></div>
      </div>

      <div className={styles.block1}>
        <div className={styles.formContainer}>
          <form
            onSubmit={event => {
              handleClick('login');
              event.preventDefault();
            }}>
            <p className={styles.loginText}>{strings.login}</p>
            {formFields.map(element =>
              element.type === 'password'
                ? getPasswordInput(element, onRightIconClick, visible)
                : getInput(element),
            )}
            <div className={styles.divider} />
            <div className={styles.forgotPasswordRow}>
              <a
                data-tip={'forgot password'}
                href=""
                onClick={e => {
                  e.preventDefault();
                  handleClick('forgot-password');
                }}>
                {strings.forgotPassword}
              </a>
            </div>

            <Button
              buttonStyle="primary"
              label={strings.login}
              className={styles.submitButton}
              type="submit"
            />
          </form>
        </div>

        <div className={styles.create_user}>
          <span>{strings.dontHaveAgentAccount} </span>
          <button
            data-tip={
              'If your company is not registered with us, register and create your company’s new account.'
            }
            href=""
            onClick={() => handleClick('newAgencyRegistration')}>
            {strings.createNew}
          </button>
        </div>
        <SeparatorComponent />

        <div className={styles.button_group_section}>
          {buttonGroup.map(buttonObject => {
            return (
              // <button
              //   onClick={() => handleClick(buttonObject.navigate)}
              //   className={styles.buttonPrimaryOutlined}
              // >
              //   {buttonObject.label}
              // </button>
              <Button
                data-place="top"
                data-tip={buttonObject.toolTip}
                label={buttonObject.label()}
                className={styles.buttonPrimaryOutlined}
                onClick={() => handleClick(buttonObject.navigate)}
                disabled={buttonObject.disabled}
              />
            );
          })}
        </div>
        {/* <div className={styles.languageContainer}>
          <div
            className={[
              styles.lang,
              strings.getLanguage() === 'english'
                ? styles.currentLanguage
                : null,
            ].join(' ')}
            onClick={e => {
              e.preventDefault();
              handleClick('english');
            }}>
            {strings.english}
          </div>
          <div
            className={[
              styles.lang,
              strings.getLanguage() === 'hindi' ? styles.currentLanguage : null,
            ].join(' ')}
            onClick={e => {
              e.preventDefault();
              handleClick('hindi');
            }}>
            {strings.hindi}
          </div>
        </div> */}
      </div>

      <div className={styles.footerContainer}>
        {/* <div className={styles.mobilePlayStoreIcons}>
          Download the app from:{' '}
          <img
            className={styles.playStoreIcon}
            src={loginImages.playStoreIcon}></img>
          {' or '}
          <img
            className={styles.appStoreIcon}
            src={loginImages.appStoreIcon}></img>
        </div> */}
        <div>
          <span
            className={styles.privacy}
            onClick={() => handleClick('privacy')}>
            {strings.privacyPolicy}
          </span>
          <span className={styles.terms} onClick={() => handleClick('terms')}>
            {strings.termsOfUse}
          </span>
          <span
            className={styles.help}
            onClick={() => handleClick('help-center')}>
            {' '}
            {strings.helpCenter}
          </span>
          <span
            className={styles.help}
            onClick={() => handleClick('useful-resources')}>
            {' '}
            {strings.useFulResource}
          </span>
        </div>
        <div className={styles.copyright}>{strings.copyRight}</div>
      </div>
      <div className={styles.updatedDate}>
        Last updated: {' ' + packageJson.updatedDate}
      </div>
    </div>
    // </AuthLayout>
  );
};
export default withRouter(Desktop);
