import React, {Component} from 'react';
import {Auth} from 'aws-amplify';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import ROLES_CONSTANTS from '../../../constants/roles-constants';
import styles from './styles.module.scss';
// import styles from "./styles.module.scss";
// import AuthLayout from "../../../components/AuthLayout";
import {
  keys,
  INPUT_CONSTANTS,
  formFields,
  buttonGroup,
  privacyData,
  termsData,
} from './data';
import TextInput from '../../../components/TextInput';
import PopupContainer from '../../../components/PopupContainer';
import Popup from '../../../components/SimplePopup';
import {configureAuth} from '../../../services/aws_service';
import {signIn} from '../../../services/aws_service';
import {API} from '../../../requests/index';
import Spinner from '../../../components/Spinner';
import {commonImages} from '../../../assets/images';

import strings from '../../../globalization';

//import { setLoginData, setAuthenticatedUser } from "../../../redux-state/modules/login/actions";

import {setLoginData, setAuthenticatedUser} from '../../../store/actions/login';

import ViewRenderer from './views';
import {Metadata} from '../../../components/Metadata';
import PAGE_TITLES from '../../../constants/page-titles';
import Privacy from '../../../components/Privacy';
import GenericPopup from '../../../components/GenericPopup';
import Flex from '../../../components/Flex';
import HelpCenterPopupContent from '../../../components/HelpCenterPopupContent';

//Configure Amplify

const challenges = {
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
  SMS_MFA: 'SMS_MFA',
};

const POPUP_CONSTANTS = {
  STANDARD_POPUP: 'standard-popup',
  ERROR_POPUP: 'error-popup',
  SPINNER_POPUP: 'spinner-popup',
  USER_NOT_CONFIRM: 'user-not-confirm',
  SIMPLE_POPUP: 'simple-popup',
  PRIVACY_POPUP: 'privacy-popup',
  TERMS_POPUP: 'terms-popup',
  GENERIC_POPUP: 'generic-popup',
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      userId: '',
      isRsetPassword: false,
      newPassword: '',
      confirmPassword: '',
    };
    configureAuth();
  }

  componentDidMount() {
   // this.getPreLoginToken();
  }

  getPreLoginToken = () => {
    API.getPreLoginToken()
      .then(response => {
        console.log(response);
        API.setPreLoginToken(response);
      })
      .catch(error => {
        //this.showErrorPopup('');
        console.log(error);
      });
  };

  onChange = (action, data) => {
    console.log('action', action, 'data', data);
    this.setState(prevState => {
      return {
        ...prevState,
        [action]: data?.trim(),
      };
    });
  };

  getInputType = element => {
    switch (element.type) {
      case INPUT_CONSTANTS.PASSWORD:
      case INPUT_CONSTANTS.NUMBER:
      case INPUT_CONSTANTS.TEXT: {
        return (
          <TextInput
            label={element.label}
            lblDirection="row"
            onChange={update => {
              this.onChange(element.field, update);
            }}
            isEdit={true}
            type={element.type}
            placeholder={element.placeholder}
            //inputStyle={styles.inputTextAddress}
            required={element.required}
            name={element.field}
            error={this.state[element.field].error}
            value={this.state[element.field]}
          />
        );
      }
      default: {
        console.warn('case not handled', element.type);
        return null;
      }
    }
  };

  /**
   *
   * @param {*} action
   * @param {*} data
   */

  onClickHandler = (action, data) => {
    const {history} = this.props;
    switch (action) {
      case 'signin': {
        history.push('/sign-in');
        break;
      }
      case 'vehicle-registration': {
        history.push('/vehicle-registration');
        break;
      }
      case 'person-registration': {
        history.push('/person-registration');
        break;
      }
      case 'login': {
        this.signInWithCognito();
        break;
      }

      case 'forgot-password': {
        history.push('/change-password');
        break;
      }
      case 'newAgencyRegistration': {
        history.push('/newAgencyRegistration');
        break;
      }
      case 'check-application-status': {
        history.push('/check-application-status');
        break;
      }
      case 'manage-registration-information': {
        history.push('/manage-registration-information');
        break;
      }
      case 'check-permit-status': {
        history.push('/check-permit-status');
        break;
      }

      case 'privacy': {
        this.showPrivacyPopup(POPUP_CONSTANTS.PRIVACY_POPUP);
        break;
      }
      case 'terms': {
        this.showPrivacyPopup(POPUP_CONSTANTS.TERMS_POPUP);
        break;
      }
      case 'help-center': {
        this.showHelpCenterPopup(POPUP_CONSTANTS.GENERIC_POPUP);
        break;
      }
      case 'useful-resources': {
        history.push('/resource');
        break;
      }

      case 'english': {
        this.changeLanguage('english');
        break;
      }
      case 'hindi': {
        this.changeLanguage('hindi');
        break;
      }

      default: {
        console.info('case not handled', action);
      }
    }
  };
  showHelpCenterPopup = popupType => {
    this.setState({
      popup: {
        type: popupType,
        title: 'Help Center',
        // description: 'Contact edrishti wow bro!',
        onClick: this.closePopup,
        children: <HelpCenterPopupContent onClose={this.closePopup} />,
        showButton: false,
        containerClassnames: styles.genericPopupStyles,
      },
    });
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };
  signInWithCognito = async () => {
    const {userId: uid, password: pwd} = this.state;
    if (!uid.trim() || !pwd.trim()) {
      this.showErrorPopup(`Enter a valid username and password`);
      return;
    }

    const userId = `+91${this.state.userId}`;
    const {password} = this.state;
    this.showSpinner();
    try {
      if (!userId || !password) {
        throw new Error(strings.invalidUsernameOrPassword);
      }
      const user = await Auth.signIn(userId, this.state.password);
      const {challengeName} = user;
      if (challengeName === challenges.NEW_PASSWORD_REQUIRED) {
        const payload = {user};
        this.props.dispatchSetAuthenticatedUser(payload);
        this.props.history.push('reset-password');
      } else if (challengeName === challenges.SMS_MFA) {
        // navigation.navigate(routeConfig.Otp, {
        //   user: user,
        //   phone: userNameWithPrefix,
        // });
      } else {
        this.login();
      }
    } catch (error) {
      console.log('Error:', error);
      const errorMsg = error.response
        ? error.response.data.message
        : error.message;
      this.showErrorPopup(errorMsg);
    }
  };

  login = async userInfo => {
    this.showSpinner();
    const session = await Auth.currentSession();
    const {idToken = {}} = session;
    const {jwtToken = ''} = idToken;
    // console.info('jwtToken', jwtToken);
    API.login(jwtToken)
      .then(response => {
        console.info('login success', response);
        const {token, user} = response.data;
        const {firstName, phoneNumber, companyId, roleId, emailAddress} = user;

        API.setBearerToken(token);
        this.props.dispatchSetLoginData(response.data);
        this.closePopup();
        if (ROLES_CONSTANTS.agencyUser.includes(user.roleId)) {
          this.props.history.push('./u/dashboard');
        } else if (ROLES_CONSTANTS.deptUser.includes(user.roleId)) {
          if (user?.departmentId === 3) {
            this.props.history.push('./u/dashboard');
          } else {
            this.props.history.push('./u/dashboard');
          }
        } else if (ROLES_CONSTANTS.operatorModuleUser.includes(user.roleId)) {
          this.props.history.push('./u/dashboard');
        } else if (ROLES_CONSTANTS.invoiceAdmin.includes(user.roleId)) {
          this.props.history.push('./u/invoices');
        } else {
          this.props.history.push('./u');
        }
      })
      .catch(error => {
        console.error('error', error, error.request);
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        this.showErrorPopup(errorMsg);
      });
  };
  getProps = () => {
    return {
      onClick: this.onClickHandler,
      onChangeState: this.onChange,
      onKeyDown: this.handleKeyDown,
    };
  };

  handleKeyDown = event => {
    if (event.key === 'Enter') {
      this.signInWithCognito();
    }
  };

  changeLanguage = lang => {
    strings.setLanguage(lang);
    this.setState({});
    console.log(strings.getLanguage(), strings.getAvailableLanguages());
  };

  showPrivacyPopup = popUpType => {
    this.setState({
      popup: {
        type: popUpType,
        onClose: this.closePopup,
        buttonText: 'Okay',
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  showSuccessPopup = successMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: successMessage,
        onClose: this.closePopup,
        headingImage: commonImages.successIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  showErrorRetryPopup = (errorMsg, retryCallback) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMsg,
        messageStyle: {color: '#E67717'},

        buttons: [
          {
            text: strings.cancelPopup,
            onClick: this.closePopup,
            outline: true,
          },
          {
            text: strings.retryApiPopup,
            onClick: () => {
              this.closePopup();
              retryCallback();
            },
          },
        ],
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
      },
    });
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };

  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="blue" />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.PRIVACY_POPUP: {
        return (
          <Privacy
            onClose={this.closePopup}
            messageObject={privacyData}
            heading="Privacy Policy"
            {...popup}
          />
        );
      }
      case POPUP_CONSTANTS.TERMS_POPUP: {
        return (
          <Privacy
            onClose={this.closePopup}
            messageObject={termsData}
            heading="Terms of Use"
            {...popup}
          />
        );
      }
      case POPUP_CONSTANTS.GENERIC_POPUP: {
        return <GenericPopup {...popup} />;
      }
      default: {
        console.warn('case not handled', popup.type);
        return null;
      }
    }
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <Metadata title={PAGE_TITLES.landing} />
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

const mapStateToProps = reduxProps => ({
  reduxProps,
});

const mapDispatchToProps = dispatch => ({
  dispatchSetLoginData: payload => {
    dispatch(setLoginData(payload));
  },
  dispatchSetAuthenticatedUser: payload => {
    dispatch(setAuthenticatedUser(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
