import React from 'react';
import Flex from '../../../../../components/Flex';
import PermitsHeader from '../../../../../components/PermitsHeader';
import styles from './styles.module.scss';

import userIcon from '../../../../../assets/images/user-icon/image@3x.png';
import home from '../../../../../assets/images/agency-blue/image@3x.png';
import bgIcon from '../../../../../assets/images/background/image.png';
import FormElement from '../../../../../components/FormElement';

const TAB_CONSTANTS = {
  PROFILE: 'profile',
  AGENCY: 'agency',
};

const ROLE_MAPPER = {
  COMPANY_ADMIN: 'Company Admin',
  SIGNATORY: 'Signatory',
  COMPANY_SUPER_ADMIN: 'Company Super Admin',
};

export default function Desktop({
  activePage,
  onClick,
  user,
  company,
  role,
  onChange,
  profileFormData,
  agencyFormData,
  profileState,
  agencyState,
}) {
  console.log('company...', company);
  const handleClick = data => {
    onClick('button-click', data);
  };
  const getPageContent = () => {
    switch (activePage) {
      case TAB_CONSTANTS.PROFILE: {
        return (
          <div>
            <ProfileCard
              user={user}
              role={role}
              profileFormData={profileFormData}
              stateData={profileState}
              onChange={(action, data) => onChange('profile', action, data)}
              onClick={onClick}
            />
          </div>
        );
      }
      case TAB_CONSTANTS.AGENCY: {
        return (
          <div>
            <AgencyCard
              company={company}
              role={role}
              agencyFormData={agencyFormData}
              stateData={agencyState}
              onChange={(action, data) => onChange('agency', action, data)}
              onClick={onClick}
            />
          </div>
        );
      }
      default: {
        console.warn('case not handled...', activePage);
      }
    }
  };
  return (
    <div>
      <div className={styles.headerContainer}>
        <PermitsHeader title="Profile" activeTab={1} showTabs={false} />
      </div>
      <Flex direction="row" className={styles.mainContainer}>
        <Flex direction="column" className={styles.buttonsContainer}>
          <button
            onClick={() => handleClick(TAB_CONSTANTS.PROFILE)}
            className={styles.button}
            style={
              activePage === TAB_CONSTANTS.PROFILE
                ? {backgroundColor: '#E6EDFF'}
                : null
            }>
            <img src={userIcon} alt="U" />
            Profile Details
          </button>
          {Object.keys(company).length > 0 && (
            <button
              onClick={() => handleClick(TAB_CONSTANTS.AGENCY)}
              className={styles.button}
              style={
                activePage === TAB_CONSTANTS.AGENCY
                  ? {backgroundColor: '#E6EDFF'}
                  : null
              }>
              <img src={home} alt="U" />
              Agency Details
            </button>
          )}
        </Flex>
        <Flex direction="column" className={styles.dataContainer}>
          {getPageContent()}
        </Flex>
      </Flex>
    </div>
  );
}

const ProfileCard = ({
  user,
  onChange,
  role,
  profileFormData,
  onClick,
  stateData,
}) => {
  return (
    <Flex direction="column">
      <Flex direction="row" className={styles.topContainer}>
        <img src={bgIcon} className={styles.profileImage} alt="U" />
        <Flex direction="column" className={styles.rightForm}>
          <FormElement
            label={`Login User Name`}
            value={user.phoneNumber.slice(3)}
            type={'text'}
            disabled
            labelClassNames={styles.disabledLabel}
            containerClassNames={styles.disabledContainer}
          />
          <FormElement
            label={`Role`}
            value={ROLE_MAPPER[role?.name]}
            type={'text'}
            disabled
            labelClassNames={styles.disabledLabel}
            containerClassNames={styles.disabledContainer}
          />
        </Flex>
      </Flex>
      <Flex direction="row" className={styles.formContainer}>
        {profileFormData.map((elem, idx) => {
          return (
            <div key={`formFields-${idx}`} className={styles.formFields}>
              <FormElement
                label={elem.label}
                value={
                  elem.stateField === 'phoneNumber'
                    ? stateData[elem.stateField]?.value.slice(3)
                    : stateData[elem.stateField].value
                }
                type={elem.elementType}
                // disabled={elem.disabled}
                minLength={elem.minLength}
                maxLength={elem.maxLength}
                required={elem.required}
                disabled={elem.disabled}
                error={stateData[elem.stateField].error}
                // labelClassNames={
                //   elem.disabled ? styles.disableLabel : styles.labelClass
                // }
                // containerClassNames={
                //   elem.disabled ? styles.disabledContainer : styles.containerClass
                // }
                onChange={text => onChange(elem.stateField, text)}
              />
            </div>
          );
        })}
      </Flex>
      <Flex direction="row" style={{justifyContent: 'center'}}>
        <button
          className={styles.btnPrimary}
          onClick={() => onClick('update-profile')}>
          Save Changes
        </button>
      </Flex>
    </Flex>
  );
};

const AgencyCard = ({
  company,
  onChange,
  role,
  agencyFormData,
  onClick,
  stateData,
}) => {
  console.log('stateData company...', stateData);
  return (
    <Flex direction="column">
      <Flex direction="row" className={styles.formContainer}>
        {agencyFormData.map((elem, idx) => {
          return (
            <div key={`formFields-${idx}`} className={styles.formFields}>
              <FormElement
                label={elem.label}
                value={
                  elem.stateField === 'phoneNumber'
                    ? stateData[elem.stateField]?.value.slice(3)
                    : stateData[elem.stateField].value
                }
                type={elem.elementType}
                // disabled={elem.disabled}
                minLength={elem.minLength}
                maxLength={elem.maxLength}
                required={elem.required}
                disabled={elem.disabled}
                // labelClassNames={
                //   elem.disabled ? styles.disabledLabel : styles.labelClass
                // }
                // containerClassNames={
                //   elem.disabled
                //     ? styles.disabledContainer
                //     : styles.containerClass
                // }
                onChange={text => onChange(elem.stateField, text)}
                options={stateData[elem.optionsField]}
                dropdownContainerStyle={[
                  styles.dropdownContainerStyle,
                  elem.disabled ? styles.disableDropDown : ' ',
                ].join(' ')}
                error={stateData[elem.stateField].error}
              />
            </div>
          );
        })}
      </Flex>
      <Flex direction="row" style={{justifyContent: 'center'}}>
        {role.roleId === 8 ? (
          <button
            className={styles.btnPrimary}
            onClick={() => onClick('update-agency')}>
            Save Changes
          </button>
        ) : null}
      </Flex>
    </Flex>
  );
};
