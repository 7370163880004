import React, {Component} from 'react';
import styles from './styles.module.scss';
import PopupContainer from '../../../../components/PopupContainer';
import Popup from '../../../../components/Popup';
import {withRouter} from 'react-router-dom';

import moment from 'moment';
import ViewRenderer from './views';
import {API} from '../../../../requests';
import strings from '../../../../globalization';
import Spinner from 'react-spinkit';
import {tempData} from './data';

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  ERROR: 'error',
  SPINNER_POPUP: 'spinner',
};
class CreditHistory extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      agencies: [],
      selectedStatus: this.props.status,
      selectedRequestType: '',
      //      selectedAgency: '',
      startDate: new moment().add(-1, 'years').format('MM-DD-YYYY'),
      endDate: new moment().startOf('year').format('MM-DD-YYYY'),
      dates: null,
      filterAgencies: tempData,
      searchText: '',
      statuses: [
        {
          id: '1',
          name: 'PENDING',
        },
        {
          id: '2',
          name: 'APPROVED',
        },
        {
          id: '3',
          name: 'REJECTED',
        },
      ],
    };
  }

  applyFilters = () => {
    const {searchText, agencies} = this.state;
    const filterBySearch =
      agencies.filter(element =>
        Object.values(element)
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()),
      ) || agencies;

    console.log(filterBySearch, agencies);

    this.setState({
      filterAgencies: filterBySearch,
    });
  };

  getData = async (startDate, endDate, isFilter) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
    await API.getCreditsUsageHistory(startDate, endDate)
      .then(response => {
        this.setState({
          agencies: response.data,
          filterAgencies: response.data,
          popup: undefined,
        });
        if (isFilter) {
          this.applyFilters();
        }
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        console.info('error....', errorMsg);
        this.setState({
          popup: undefined,
        });
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.SIMPLE_POPUP,
            message: errorMsg,
            messageStyle: {color: '#E67717'},
            buttons: [
              {
                title: 'Okay!',
                onPress: this.closePopup,
              },
            ],
          },
        });
      });
  };

  componentDidMount() {
    this.getData(null, null, false);
    this.getCreditsData();
  }

  getCreditsData = () => {
    API.getCreditWalletDetails()
      .then(res => {
        console.log('res.data', res.data);
        this.setState({
          availableCredits: res.data.availableCredits,
          popup: null,
        });
      })
      .catch(error => console.log(error));
  };

  onChangeHandler = (action, data) => {
    switch (action) {
      case 'date': {
        const {startDate, endDate, dates} = data;
        this.setState({
          startDate,
          endDate,
          dates,
        });
        break;
      }

      case 'search': {
        console.info('data..', data);
        this.setState({
          searchText: data,
        });
        // const { agencies } = this.state;
        // const filterAgencies = agencies.filter((element) =>
        //     Object.values(element).toString().toLowerCase().includes(data.toLowerCase()));
        // this.setState({
        //   filterAgencies,
        // });
        break;
      }

      default: {
        console.info('case not handled', action);
      }
    }
  };

  /**
   *
   * @param {*} action
   * @param {*} data
   */

  onClickHandler = (action, data) => {
    const {history, location, status} = this.props;
    switch (action) {
      case 'signin': {
        history.push('/sign-in');
        break;
      }
      case 'apply': {
        const {startDate, endDate} = this.state;
        let startDateStr = moment(startDate).format('MM-DD-YYYY');
        let endDateStr = moment(endDate).format('MM-DD-YYYY');
        this.getData(startDateStr, endDateStr, true);
        break;
      }
      case 'clearFilters': {
        this.resetData();
        break;
      }
      case 'rowClick': {
        console.info('data', data);
        const {referenceId} = data;
        break;
      }
      case 'statusRoute': {
        //FIXME
        history.push({
          pathname: '/u/credits/' + data.route,
        });

        break;
      }

      default: {
        console.info('case not handled', action);
      }
    }
  };

  resetData = () => {
    const {status} = this.props;
    this.setState({
      searchText: '',
      //  selectedAgency: '',
      selectedRequestType: null,
      startDate: new moment().add(-1, 'years').format('MM-DD-YYYY'),
      endDate: new moment().startOf('year').format('MM-DD-YYYY'),
      dates: null,
    });
    let yearStartDate = new moment().add(-1, 'years').format('MM-DD-YYYY');
    let yearEndDate = new moment().startOf('year').format('MM-DD-YYYY');
    this.getData(yearStartDate, yearEndDate, true);
  };

  getProps = () => {
    const {
      statuses,
      agencies,
      filterAgencies,
      searchText,
      selectedStatus,
      startDate,
      endDate,
      availableCredits,
      dates,
    } = this.state;
    return {
      onClick: this.onClickHandler,
      onChange: this.onChangeHandler,
      statuses,
      agencies,
      filterAgencies,
      searchText,
      availableCredits,
      selectedStatus,
      startDate,
      endDate,
      dates,
    };
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };

  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      // case "spinner": {
      //   console.log('showing spinner');
      //   return <Spinner />;
      // }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.SPINNER: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}
export default withRouter(CreditHistory);
