import React, {useRef, useState} from 'react';
import styles from './styles.module.scss';
import useOnClickOutside from '../../../hooks/useOnClickOutside';

import companySurrender from '../../../assets/images/surrender-images/company-surrender/image.png';
import personSurrender from '../../../assets/images/surrender-images/person-surrender/image.png';
import exclamation from '../../../assets/images/surrender-images/exclamation/image.png';
import alert from '../../../assets/images/surrender-images/alert/image@3x.png';
import Flex from '../../Flex';
import OtpInput from 'react-otp-input';
import {useMediaQuery} from 'react-responsive';
import {API} from '../../../requests';
import util from '../../../util';

const STEPS = {
  STEP_1: 1,
  STEP_2: 2,
  STEP_3: 3,
};

const SurrenderComponentWithSteps = ({
  onSuccess,
  onClose,
  entityType,
  entity,
  onOutsideClick,
  phoneNumber,
}) => {
  const popupRef = useRef();
  useOnClickOutside(popupRef, () => onOutsideClick(false));

  const [step, setStep] = useState(STEPS.STEP_1);
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');

  const isMobile = useMediaQuery({maxWidth: 767});
  const otpStyle = !isMobile
    ? {
        width: '2.917vw',
        height: '2.917vw',
      }
    : {
        width: '2.0rem',
        height: '2.0rem',
      };

  const onClickHandler = (action, data) => {
    switch (action) {
      case 'close-popup': {
        onClose && onClose();
        return;
      }
      case 'proceed-to-step-2': {
        setStep(STEPS.STEP_2);
        break;
      }
      case 'proceed-to-step-3': {
        setStep(STEPS.STEP_3);
        // FIXME: uncomment
        generateOTP(phoneNumber.slice(3));
        break;
      }
      case 'verify-otp': {
        verifyOtp(phoneNumber.slice(3));
        break;
      }
      default: {
        return;
      }
    }
  };
  const generateOTP = async phoneNumber => {
    console.log(phoneNumber);
    if (!phoneNumber || phoneNumber.toString().length !== 10) {
      setError('Please enter a valid phone number', phoneNumber);
    } else {
      const result = util.validatePhoneNumber(phoneNumber);
      if (!result) {
        setError('Please enter a valid phone number', phoneNumber);
        return;
      }

      await API.generateOtp({phoneNumber: `+91${phoneNumber}`})
        .then(res => {
          console.log('OTP Sent!');
        })
        .catch(error => {
          console.log(error);

          const errorMsg = util.getErrorMessage(error);
          this.setError(`Something went wrong, please try again.`);
        });
    }
  };

  const OTPResend = () => {
    generateOTP(phoneNumber.slice(3));
  };
  const verifyOtp = async phoneNumber => {
    console.log('verify...slice', phoneNumber);
    const result = util.validatePhoneNumber(phoneNumber);
    if (!result) {
      setError('Please enter a valid phone number', phoneNumber);
      return;
    }
    try {
      const res = await API.verifyOtp({
        phoneNumber: `+91${phoneNumber}`,
        token: otp,
      })
        .then(res => {
          onSuccess && onSuccess();
        })
        .catch(error => {
          console.log(error);

          const errorMsg = error.response
            ? error.response.data?.message || error.response.data
            : error.message;
          setError(errorMsg);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getContent = () => {
    switch (step) {
      case STEPS.STEP_1: {
        return (
          <>
            <div className={styles.body}>
              <p className={styles.heading}>Why Surrender {entity}?</p>
              <Flex direction="row" className={styles.boxContainer}>
                <Box
                  image={companySurrender}
                  text={`The company related to the ${
                    entityType === 'person' ? 'person' : 'vehicle owner'
                  } has chosen to voluntarily
          cancel or surrender the ${entityType}'s RFID ${
                    entityType === 'person' ? 'card' : 'tag'
                  }`}
                />
                <p style={{fontFamily: 'Poppins-Bold'}}>OR</p>
                <Box
                  image={personSurrender}
                  text={`When the ${
                    entityType === 'person' ? 'person' : 'vehicle owner'
                  } realizes that the RFID ${entity} is no longer useful and hence decides to surrender.`}
                />
              </Flex>
              <Flex className={styles.cta}>
                <img
                  src={exclamation}
                  alt="exclamation"
                  className={styles.exclamation}
                />
                <p>
                  By clicking on “Proceed”, you are surrendering your RFID{' '}
                  {entity} and the {entity} cannot be revoked post this action.
                </p>
              </Flex>
            </div>
            <Flex className={styles.buttonsContainer}>
              <button
                onClick={() => onClickHandler('close-popup')}
                className={styles.neutral}>
                Back
              </button>
              <button
                onClick={() => onClickHandler('proceed-to-step-2')}
                className={styles.negative}>
                Proceed
              </button>
            </Flex>
          </>
        );
      }

      case STEPS.STEP_2: {
        return (
          <>
            <Flex className={styles.stepTwoImage}>
              <img
                src={exclamation}
                alt="exclamation"
                className={styles.exclamation}
              />
            </Flex>
            <div className={styles.stepTwoMiddleContainer}>
              <p>Please note</p>
              <div className={styles.stepTwoSteps}>
                <p>1. Your existing registration details will become void.</p>
                <p>2. Your access to the port will be restricted. </p>
                <p>3. You will no longer be able to raise a permit. </p>
              </div>
              <p>
                After clicking on “Next”, you will get an OTP over your
                registered phone number.
              </p>
            </div>
            <Flex className={styles.buttonsContainer}>
              <button
                onClick={() => onClickHandler('close-popup')}
                className={styles.neutral}>
                Back
              </button>
              <button
                onClick={() => onClickHandler('proceed-to-step-3')}
                className={styles.negative}>
                Next
              </button>
            </Flex>
          </>
        );
      }
      case STEPS.STEP_3: {
        return (
          <>
            <div style={{maxWidth: '40rem', margin: '0 auto'}}>
              <p className={styles.stepThreeHeader}>
                By typing and confirming the OTP, your RFID {entity} will be
                surrendered.
              </p>

              <div className={styles.textContainer}>
                <div className={styles.heading}> {`Mobile Verification`}</div>
                <p className={styles.infotext}>
                  Enter 6-digit code sent to you mobile number ending with ***
                  {phoneNumber.slice(9)}
                </p>
                <span className={styles.message}>
                  <OtpInput
                    value={otp}
                    numInputs={6}
                    inputStyle={otpStyle}
                    onChange={update => setOtp(update)}
                    separator={<span>&nbsp; &nbsp;&nbsp;</span>}
                  />
                </span>
                <a className={styles.resendOTPText} onClick={OTPResend}>
                  Resend Code
                </a>
                {error && <p className={styles.infotext}>{error}</p>}
              </div>
              <Flex className={styles.ctaSecond}>
                <img src={alert} alt="alert" className={styles.alert} />
                <p>This action cannot be undone.</p>
              </Flex>
            </div>
            <Flex className={styles.buttonsContainer}>
              <button
                onClick={() => onClickHandler('close-popup')}
                className={styles.neutral}>
                Cancel
              </button>
              <button
                onClick={() => onClickHandler('verify-otp')}
                className={styles.negative}>
                Submit
              </button>
            </Flex>
          </>
        );
      }
      default: {
        return;
      }
    }
  };
  return (
    <div className={styles.container} ref={popupRef}>
      <div className={styles.header}>Surrender RFID {entity}</div>
      {getContent()}
    </div>
  );
};

const Box = ({text, image, entity}) => {
  return (
    <Flex className={styles.box} direction="column">
      <div className={styles.boxImageContainer}>
        <img src={image} className={styles.boxImage} alt="illustration" />
      </div>
      <div className={styles.contentContainer}>
        <p>{text}</p>
      </div>
    </Flex>
  );
};
export default SurrenderComponentWithSteps;
