const materialKeyValueMapper = {
  1: [
    {title: 'Bill of Entry', valueKey: 'BillOfEntryNo'},
    {title: 'Delivery Order Date', valueKey: 'deliveryOrderDate', type: 'date'},
    {title: 'Vessel VCN Number', valueKey: 'VCNNumber'},
  ],
  2: [
    {title: 'Shipping Bill No.', valueKey: 'ShippingBillNumber'},
    {title: 'Vessel Name', valueKey: 'vesselName'},
  ],
  3: [
    {title: 'Shipping Bill No.', valueKey: 'billNumber'},
    {title: 'Vessel Name', valueKey: 'vesselName'},
    {title: 'Cargo Type', valueKey: 'type'},
    {title: 'Cargo Name', valueKey: 'vesselName'},
    {title: 'Cagro Quantity', valueKey: 'quantity'},
  ],
  4: [
    {title: 'Work Order No.', valueKey: 'orderNumber'},
    {title: 'Validity Date', valueKey: 'validityDate'},
    {title: 'Quantity', valueKey: 'quantity'},
    {title: 'Material Desc.', valueKey: 'materialDesc'},
    {title: 'Permit-In/Out', valueKey: 'permitInOut'},
  ],
  5: [
    {title: 'Work Order No.', valueKey: 'orderNumber'},
    {title: 'Validity Date', valueKey: 'validityDate'},
    {title: 'Vessel Name', valueKey: 'vesselName'},
    {title: 'Quantity', valueKey: 'quantity'},
    {title: 'Material Desc.', valueKey: 'materialDesc'},
    {title: 'Permit-In/Out', valueKey: 'permitInOUt'},
  ],
};

export {materialKeyValueMapper};
