import clsx from 'clsx';
import React, {useRef} from 'react';
import Button from '../../EDrishtiDesignSystem/Button';
import Flex from '../../EDrishtiDesignSystem/Flex';
import IconButton from '../../EDrishtiDesignSystem/IconButton';
import styles from './styles.module.scss';

import BackgroundImage from '../../../assets/images/resource-center/video-guide/background/image.png';
import Play from '../../../assets/images/resource-center/video-guide/play/image@2x.png';
import moment from 'moment';

import useOnClickOutside from '../../../hooks/useOnClickOutside';

const VideoPlayer = ({video, onClose}) => {
  const videoRef = useRef();
  useOnClickOutside(videoRef, () => onClose());
  return (
    <div className={styles.container} ref={videoRef}>
      <IconButton
        onClick={onClose}
        variant="cross"
        className={styles.crossIcon}
      />

      <div className={clsx(styles.content)}>
        <Flex
          align="center"
          justify="center"
          style={{width: '100%', height: '100%'}}>
          <video
            src={video.source}
            controls
            className={styles.video}
            autoPlay
            playsInline
          />
        </Flex>
      </div>
    </div>
  );
};

export default VideoPlayer;
