import React, {useEffect, useState} from 'react';
import Flex from '../../../../../../components/Flex';
import PermitsHeader from '../../../../../../components/PermitsHeader';
import TextInput from '../../../../../../components/TextInput';
import styles from './styles.module.scss';
import exclamation from '../../../../../../assets/images/exclamation-icon/image.png';
import one from '../../../../../../assets/images/wallet-one/image.png';
import two from '../../../../../../assets/images/wallet-two/image.png';
import three from '../../../../../../assets/images/wallet-three/image.png';
import four from '../../../../../../assets/images/wallet-four/image.png';
import util from '../../../../../../util';
const Desktop = ({
  onClick,
  selectedStatus,
  onChange,
  searchText,
  name,
  packs,
  showAllPacksText,
}) => {
  const options = [
    {title: 'Individual Pack', price: 13, validity: 'Daily', id: 'individual'},
    {title: '30 Days', price: 382, validity: 'Monthly', id: 'monthly'},
    {title: '90 Days', price: 1060, validity: 'Quarterly', id: 'quarterly'},
    {title: '360 Days', price: 4134, validity: 'Yearly', id: 'yearly'},
  ];
  const images = {
    1: one,
    2: two,
    3: three,
    4: four,
  };

  const defaultPriceList = [100, 250, 500, 1000];

  const [selectedOption, setSelectedOption] = useState({});

  useEffect(() => {
    if (packs.length > 0) {
      setSelectedOption(packs[0]);
    }
  }, [packs]);

  const [quantity, setQuantity] = useState(1);

  const checkDisabled = () => {
    if (quantity > 1) {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    console.log('selectedOption...', selectedOption);
    const data = {
      packId: selectedOption.packId,
      quantity: quantity,
      total: selectedOption.price * quantity,
      type: selectedOption.title,
      name: name,
      cost: selectedOption.price,
    };

    onClick('proceed', data);
  };

  const inputQuantity = qty => {
    if (qty < 0 || qty > 100000) {
      return;
    }
    setQuantity(qty);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1>{name}</h1>
        </div>

        <FormContainer
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          setQuantity={setQuantity}
          options={packs}
          checkDisabled={checkDisabled}
          inputQuantity={inputQuantity}
          quantity={quantity}
          images={images}
          defaultPriceList={defaultPriceList}
        />
        {/* <Flex className={styles.viewMoreContainer}>
          <button
            className={styles.viewMore}
            onClick={() => onClick('show-all-packs')}>
            {showAllPacksText}
          </button>
        </Flex> */}

        <Flex direction="row" className={styles.buttonsContainer}>
          <button
            className={styles.cancel}
            onClick={() => {
              onClick('cancel');
            }}>
            Cancel
          </button>
          <button className={styles.positive} onClick={handleSubmit}>
            Continue
          </button>
        </Flex>
      </div>
    </div>
  );
};

const FormContainer = ({
  options,
  selectedOption,
  setSelectedOption,
  setQuantity,
  checkDisabled,
  inputQuantity,
  quantity,
  images,
  defaultPriceList,
}) => {
  console.log('options...', options);
  const onChange = value => {
    setSelectedOption(options.find(option => option.packId === +value));
    setQuantity(1);
  };

  return (
    <Flex direction="row" className={styles.radiosContainer}>
      {options.map((elem, idx) => (
        <label
          key={`${elem.id}-${idx}`}
          htmlFor={elem.id}
          className={styles.radioButtonContainer}
          style={elem.status === 'INACTIVE' ? {opacity: 0.6} : {}}>
          <div
            className={util.classnames(
              styles.form,
              selectedOption.packId === elem.packId ? styles.selectedForm : '',
            )}>
            {selectedOption.packId === elem.packId ? (
              <Flex direction="column" className={styles.inputButtonContainer}>
                <Flex direction="column" style={{margin: '0 auto'}}>
                  <span className={styles.labelAmt}>Enter amount</span>
                  <div style={{position: 'relative'}}>
                    <span className={styles.rupeeSign}>₹</span>
                    <input
                      type="number"
                      value={quantity}
                      // className={styles.quantity}
                      className={styles.labelInputQuantity}
                      onChange={e => inputQuantity(e.target.value)}
                    />
                  </div>
                </Flex>
                <Flex className={styles.defaultPriceList}>
                  {defaultPriceList.map((elem, idx) => (
                    <div
                      className={styles.defaultPrice}
                      onClick={() => inputQuantity(elem)}>
                      {'₹ ' + elem}
                    </div>
                  ))}
                </Flex>
              </Flex>
            ) : null}
          </div>
          <input
            hidden
            type="radio"
            disabled={elem.status === 'INACTIVE'}
            value={elem.packId}
            id={elem.packId}
            className={styles.radioInput}
            checked={selectedOption.packId === elem.packId}
            onChange={e => onChange(e.target.value)}
            style={{display: 'hidden'}}
          />
          <div className={styles.totalContainer}>
            {selectedOption.packId === elem.packId ? (
              <p className={styles.totalBottom}>
                Total: ₹ {parseInt(selectedOption.price, 10) * quantity}
              </p>
            ) : null}
          </div>
        </label>
      ))}
    </Flex>
  );
};
export default Desktop;
