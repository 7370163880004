import React, {Component} from 'react';

import {API} from '../../../requests';

import strings from '../../../globalization';
import PopupContainer from '../../../components/PopupContainer';
import ViewRenderer from './views';

import Popup from '../../../components/SimplePopup';
import Approved from '../../../components/Approved';

import styles from './styles.module.scss';
import Rejected from '../../../components/Rejected';
import Pending from '../../../components/Pending';
import STATUSES from '../../../constants/statuses-constants';
import util from '../../../util';
import REQUEST_TYPE_OPTIONS from '../../../constants/request-type-options';

import Spinner from '../../../components/Spinner';
import {Metadata} from '../../../components/Metadata';
import PAGE_TITLES from '../../../constants/page-titles';
import PaymentPending from '../../../components/PaymentPending';
import PaymentComplete from '../../../components/PaymentComplete';
import PaymentFailed from '../../../components/PaymentFailed';
import Flex from '../../../components/Flex';

import pendingIcon from '../../../assets/images/pending-icon/image.png';
import successIcon from '../../../assets/images/success/image.png';
import rejectIcon from '../../../assets/images/reject-icon-cross/image.png';

// import crashlytics from '@react-native-firebase/crashlytics';

const POPUP_CONSTANTS = {
  STANDARD_POPUP: 'standard-popup',
  ERROR_POPUP: 'error-popup',
  SPINNER_POPUP: 'spinner-popup',
  USER_NOT_CONFIRM: 'user-not-confirm',
  SIMPLE_POPUP: 'simple-popup',
};

class CheckApplicationStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popup: null,
      referenceNumber: '',
      status: null,
      returnedObject: null,
      requestType: null,

      requestTypeOptions: [
        {
          name: REQUEST_TYPE_OPTIONS.PERSON,
          id: 1,
        },
        {
          name: REQUEST_TYPE_OPTIONS.VEHICLE,
          id: 2,
        },
        {
          name: REQUEST_TYPE_OPTIONS.COMPANY,
          id: 3,
        },
      ],
    };
  }

  onChangeHandler = (field, value) => {
    if (field === 'requestType') {
      this.setState({
        returnedObject: null,
      });
    }
    let preparedStateObject = {};
    preparedStateObject[field] = value;
    this.setState(preparedStateObject);
  };

  closePopup = () => {
    this.setState({
      popup: null,
    });
  };

  getTextContent = (entity, returnedObject) => {
    console.log(entity);
    switch (entity) {
      case REQUEST_TYPE_OPTIONS.PERSON:
        return returnedObject.name;
      case REQUEST_TYPE_OPTIONS.VEHICLE:
        return returnedObject.name;
      case REQUEST_TYPE_OPTIONS.COMPANY:
        return returnedObject.name;
      default:
        return null;
    }
  };

  getStatus = (status, statusString, entity, returnedObject, icon) => {
    return (
      <>
        <Flex direction="column" className={[styles.container].join(' ')}>
          <img src={icon} alt="X" className={styles.image} />
          <p className={styles.result}>
            {entity} {'name'} - {this.getTextContent(entity, returnedObject)}{' '}
          </p>

          <p>
            {' '}
            {entity} {'status '} -{' '}
            <span className={styles.bold}>{statusString}</span>
          </p>
        </Flex>
      </>
    );
  };

  getStatusContent = () => {
    if (!this.state.returnedObject) return;
    const {returnedObject} = this.state;
    let status = returnedObject.status;
    console.log('STATUS', status);
    let entity;
    if (this.state.requestType === 1) {
      entity = REQUEST_TYPE_OPTIONS.PERSON;
    } else if (this.state.requestType === 2) {
      entity = REQUEST_TYPE_OPTIONS.VEHICLE;
    } else {
      entity = REQUEST_TYPE_OPTIONS.COMPANY;
    }

    switch (status) {
      case STATUSES.APPROVED:
        return (
          <Approved
            returnedObject={returnedObject}
            entity={entity}
            onClick={this.handleClickHerePress}
            handleLoginClick={this.handleLoginClick}
            getTextContent={this.getTextContent}
            containerClassNames={styles.containerClassNames}
          />
        );

      case STATUSES.PENDING:
        return (
          <Pending
            returnedObject={returnedObject}
            entity={entity}
            onPress={this.handleClickHerePress}
            getTextContent={this.getTextContent}
          />
        );

      case STATUSES.REJECTED:
        return (
          <Rejected
            returnedObject={returnedObject}
            entity={entity}
            onClick={this.handleClickHerePress}
            getTextContent={this.getTextContent}
            containerClassNames={styles.containerClassNames}
          />
        );
      case STATUSES.PAYMENT_FAILED:
        return (
          <PaymentFailed
            returnedObject={returnedObject}
            entity={entity}
            onClick={this.handleClickHerePress}
            getTextContent={this.getTextContent}
            containerClassNames={styles.containerClassNames}
          />
        );
      case STATUSES.PAYMENT_PENDING:
        return (
          <PaymentPending
            returnedObject={returnedObject}
            entity={entity}
            onPress={this.handleClickHerePress}
            getTextContent={this.getTextContent}
          />
        );
      case STATUSES.PAYMENT_COMPLETED:
        return (
          <PaymentComplete
            returnedObject={returnedObject}
            entity={entity}
            onClick={this.handleClickHerePress}
            handleLoginClick={this.handleLoginClick}
            getTextContent={this.getTextContent}
            containerClassNames={styles.containerClassNames}
          />
        );
      case STATUSES.DEPARTMENT_ADMIN_APPROVED:
        return this.getStatus(
          status,
          'DEPARTMENT ADMIN APPROVED',
          entity,
          returnedObject,
          successIcon,
        );
      case STATUSES.DEPARTMENT_ADMIN_REJECTED:
        return this.getStatus(
          status,
          'DEPARTMENT ADMIN REJECTED',
          entity,
          returnedObject,
          rejectIcon,
        );
      case STATUSES.OPERATOR_ADMIN_APPROVED:
        return this.getStatus(
          status,
          'OPERATOR ADMIN APPROVED',
          entity,
          returnedObject,
          pendingIcon,
        );
      case STATUSES.OPERATOR_ADMIN_REJECTED:
        return this.getStatus(
          status,
          'OPERATOR ADMIN REJECTED',
          entity,
          returnedObject,
          rejectIcon,
        );
      default:
        return;
    }
  };

  // eslint-disable-next-line max-lines-per-function
  onSubmit = () => {
    this.showSpinner();

    if (!this.state.requestType) {
      this.showErrorPopup(strings.pleaseSelectRequestType);
    } else if (!this.state.referenceNumber) {
      this.showErrorPopup(strings.pleaseEnterReferenceNumber);
    } else {
      console.log('this.state.referenceNumber', this.state.referenceNumber);
      if (this.state.requestType === 1) {
        this.getPerson();
      } else if (this.state.requestType === 2) {
        this.getVehicle();
      } else {
        this.getCompany();
      }
    }
  };

  getPerson = () => {
    const {referenceNumber} = this.state;
    API.getPersonByReferenceId(referenceNumber)
      .then(personRes => {
        console.log('personRes.data', personRes.data);
        this.setState({
          returnedObject: personRes.data,
          popup: null,
        });
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        console.log(errorMsg);
        this.showErrorPopup(errorMsg);
      });
  };
  getVehicle = () => {
    const {referenceNumber} = this.state;
    API.getVehicleByReferenceId(referenceNumber)
      .then(vehicleRes => {
        console.log('vehicleRes.data', vehicleRes.data);
        this.setState({
          returnedObject: vehicleRes.data,
          popup: null,
        });
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        console.log(errorMsg);
        this.showErrorPopup(errorMsg);
      });
  };

  getCompany = () => {
    const {referenceNumber} = this.state;
    API.getCompanyByReferenceId(referenceNumber)
      .then(companyRes => {
        console.log('companyRes.data', companyRes.data);
        this.setState({
          returnedObject: companyRes.data,
          popup: null,
        });
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        console.log(errorMsg);
        this.showErrorPopup(errorMsg);
      });
  };
  handleLoginClick = () => {
    const {history} = this.props;
    history.push('/signin');
  };
  handleClickHerePress = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: strings.loginDetailsSent,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
          },
        ],
        onClose: this.closePopup,
        messageStyle: {
          color: '#202428',
          fontWeight: 'normal',
        },
      },
    });
  };

  showErrorPopup = errorMsg => {
    // crashlytics().recordError(new Error(errorMsg));

    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMsg,
        messageStyle: {color: '#E67717'},

        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
          },
        ],
        onClose: this.closePopup,
      },
    });
  };

  handleBackClick = () => {
    const {history} = this.props;
    history.push('/signin');
  };

  onForgotClick = () => {
    const {history} = this.props;
    history.push('/get-reference-number');
  };

  getProps = () => {
    return {
      onSubmit: this.onSubmit,
      backClick: this.handleBackClick,
      onChangeHandler: this.onChangeHandler,
      getStatusContent: this.getStatusContent,
      stateData: this.state,
      onForgotClick: this.onForgotClick,
    };
  };
  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      default: {
        console.warn('case not handled', popup.type);
        return null;
      }
    }
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  // eslint-disable-next-line max-lines-per-function
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <Metadata title={PAGE_TITLES.checkApplicationStatus} />
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

export default CheckApplicationStatus;
