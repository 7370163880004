const height = window.innerHeight;
const width = window.innerWidth;
// eslint-disable-next-line require-jsdoc

const round = (number, precision = 2) => {
  return Math.round(number * 10 ** precision) / 10 ** precision;
};
const createViewPortConfig = (
  baseDeviceWidth = 1920,
  baseDeviceHeight = 1080,
) => {
  let screenWidth = width;
  let screenHeight = height;
  if (height > width) {
    screenWidth = height;
    screenHeight = width;
  }
  return {
    vw: round(screenWidth / baseDeviceWidth),
    vh: round(screenHeight / baseDeviceHeight),
  };
};
export default createViewPortConfig(1920, 1080);
