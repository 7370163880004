import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import PopupContainer from '../../../../components/PopupContainer';
import styles from './styles.module.scss';
import ViewRenderer from './Views';
import ReactToPrint, {PrintContextConsumer} from 'react-to-print';
import Spinner from '../../../../components/Spinner';
import Popup from '../../../../components/SimplePopup';
import {API} from '../../../../requests';
import util from '../../../../util';
import strings from '../../../../globalization';

const POPUP_CONSTANTS = {
  STANDARD_POPUP: 'standard-popup',
  ERROR_POPUP: 'error-popup',
  SPINNER_POPUP: 'spinner-popup',
  USER_NOT_CONFIRM: 'user-not-confirm',
  SIMPLE_POPUP: 'simple-popup',
};

// import useLocation from ''
class PermitPass extends React.Component {
  constructor(props) {
    super(props);
    console.info('state', props.location, props.history, props.match);
    this.state = {
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
      render: false,
    };
    // const {location} = props;
    // this.driver = location.state.driver;
    // this.vehicle = location.state.vehicle;
    // this.permit = location.state.permit;
    // this.material = location.state.material;
  }

  componentDidMount() {
    const {
      match: {params},
    } = this.props;

    if (params.referenceId) {
      this.showSpinner();
      this.getPermitDetails(params.referenceId);
    }
  }
  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  showErrorPopup = errorMsg => {
    // crashlytics().recordError(new Error(errorMsg));

    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMsg,
        messageStyle: {color: '#E67717'},

        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
          },
        ],
        onClose: this.closePopup,
      },
    });
  };

  getPermitDetails = referenceId => {
    API.fetchPermitByReferenceId(referenceId)
      .then(response => {
        // throw new Error('abcd');
        this.setState({
          popup: null,
          permit: response.data,
          driver:
            response.data.vehicles.length > 0
              ? response.data.vehicles[0].driver
              : response.data.persons[0].person,
          material:
            response.data.material.length > 0
              ? response.data.material[0]
              : null,
          vehicle:
            response.data.vehicles.length > 0
              ? response.data.vehicles[0].vehicle
              : null,
          personDetails:
            response.data.persons.length > 0 ? response.data?.persons[0] : null,
          render: true,
        });
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        console.log('errorMsg', errorMsg, error.response);
        this.showErrorPopup(errorMsg || 'Permit not found');
      });
  };

  goBack = () => {
    const {history} = this.props;
    history.goBack();
  };

  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      default: {
        console.warn('case not handled', popup.type);
        return null;
      }
    }
  };
  onClickHandler = (action, data) => {
    console.log('onClickHandler...', action);
    const {history} = this.props;
    switch (action) {
      case 'closePopup': {
        history.goBack();
        break;
      }
      default: {
        console.warn('case not handled', action);
      }
    }
  };
  closePopup = () => {
    const {history} = this.props;
    history.goBack();
    // this.setState({
    //   popup: null,
    // });
  };

  getProps = () => {
    // const {location} = this.props;
    const {driver, vehicle, permit, material, render, personDetails} =
      this.state;

    return {
      driver: driver,
      vehicle: vehicle,
      material: material,
      visitingDate: permit?.visitingDate,
      noOfDays: permit?.noOfDays,
      workPurpose: permit?.workPurpose?.name,
      referenceId: permit?.referenceId,
      tripType: permit?.tripType,
      permit: permit,
      onClick: this.onClickHandler,
      render: render,
      personDetails: personDetails,
    };
  };
  render() {
    // fetch print key from router
    const printProp = this.props?.print;

    // checking if we want to print or not
    console.log('printProp', printProp);
    let print;
    if (printProp === false) {
      print = false;
    } else {
      print = true;
    }
    const {popup, render} = this.state;
    const viewProps = this.getProps();
    const pageStyle = `
  @page {
    size: 290mm 290mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;
    return (
      <>
        {popup ? (
          <PopupContainer containerStyle={styles.popupContainerStyle}>
            {this.getPopupContent()}
          </PopupContainer>
        ) : render ? (
          <ViewRenderer
            ref={el => {
              this.componentRef = el;
              console.info('reference', el);
            }}
            id="pdf"
            {...viewProps}
          />
        ) : null}

        {render && print ? (
          <ReactToPrint
            content={() => {
              console.info('this.componentRef:', this.componentRef);
              return this.componentRef;
            }}
            pageStyle={pageStyle}>
            <PrintContextConsumer>
              {({handlePrint}) => (
                <AutomaticPrint
                  goBack={this.goBack}
                  handlePrint={handlePrint}
                />
              )}
            </PrintContextConsumer>
          </ReactToPrint>
        ) : null}
      </>
    );
  }
}

export default withRouter(PermitPass);

function AutomaticPrint({handlePrint, goBack}) {
  useEffect(() => {
    setTimeout(() => {
      // FIXME: Uncomment before committing.
      handlePrint();
      goBack();
    }, 1000);
  }, []);
  return null;
}
