import React from 'react';
import styles from './Popup.module.scss';
import {adminImages} from '../../assets/images';
import Flex from '../Flex';
import {head} from 'react-dom-factories';

type Button = {
  text: String,
  onClick: () => void,
  className?: String,
};

type Props = {
  message: String,
  onClose?: () => void,
  error?: Boolean,
  success?: Boolean,
  buttons?: Button[],
  heading?: String,
};

/**
 *
 * @param {Boolean} error
 * @param {Boolean} success
 * @return {JSX.Element}
 */
// function getIcon(error, success) {
//   let src;
//   if (error) {
//     src = errorImg;
//   } else if (success) {
//     src = successImg;
//   } else {
//     return null;
//   }
//   return <img alt="icon" className={styles.icon} src={src} />;
// }

/**
 *
 * @param {Props} props
 * @return {JSX.Element}
 */
function StandardPopup(props) {
  const {
    error,
    success,
    onClose,
    message,
    showInput,
    buttons,
    heading,
    onChangeHandler,
    placeholder,
    headingImage,
    containerClassName = '',
  } = props;
  let containerClass = '';
  if (error) {
    containerClass = styles.error;
  } else if (success) {
    containerClass = styles.success;
  }

  return (
    <div
      className={[styles.container, containerClass, containerClassName].join(
        ' ',
      )}>
      {/* {onClose ? (
        <img alt="close" src={adminImages.closeIcon} className={styles.close} onClick={onClose} />
      ) : null} */}
      <div className={styles.bodyContainer}>
        <div className={styles.heading}>
          {' '}
          {headingImage && <img alt="heading" src={headingImage} />}
        </div>
        <div className={styles.textContainer}>
          {/* {getIcon(error, success)} */}
          <span className={styles.message}>{message}</span>
        </div>
      </div>

      {buttons && buttons.length > 0 ? (
        <Flex className={styles.buttonContainer}>
          {buttons.map(button => {
            const {text, ...remainingProps} = button;
            return (
              <button
                className={button?.outline ? styles.okButton : styles.button}
                onClick={button.onClose}
                key={'popup-button-' + text}
                {...remainingProps}>
                {text}
              </button>
            );
          })}
        </Flex>
      ) : null}
    </div>
  );
}

export default StandardPopup;
