import React, { Component } from 'react';
import PopupContainer from '../../../components/PopupContainer';
import styles from './styles.module.scss';

import ViewRenderer from './views';
import { API } from '../../../requests';
import { getFormData, getEditFormData } from './data';

import { commonImages, operatorImages } from '../../../assets/images';
import strings from '../../../globalization';

import Popup from '../../../components/SimplePopup';

import POPUP_CONSTANTS from '../../../constants/popup-constants';
import util from '../../../util';
import Spinner from '../../../components/Spinner';
import Success from '../../../components/operator/Success';
import Reject from '../../../components/operator/Reject';
import { vehicles } from './tableData';

// import DocViewer from 'react-doc-viewer';

import { getPopupFormData } from './popupData';
import CardsActionsPopup from '../../../components/operator/CardsActionsPopup';
import History from '../../../components/operator/History';
import { getHistoryData } from './history';
import ViewDocument from '../../../components/ViewDocument';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ChangeRFID from '../../../components/operator/ChangeRFID';

const STATUS = {
  ISSUED: 'ISSUED',
  HOLD: 'HOLD',
  PENDING: 'PENDING',
  SURRENDERED: 'SURRENDER',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAYMENT_COMPLETED: 'PAYMENT_COMPLETED',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  OPERATOR_ADMIN_APPROVED: 'OPERATOR_ADMIN_APPROVED',
  OPERATOR_ADMIN_REJECTED: 'OPERATOR_ADMIN_REJECTED',
  DEPARTMENT_ADMIN_APPROVED: 'DEPARTMENT_ADMIN_APPROVED',
  DEPARTMENT_ADMIN_REJECTED: 'DEPARTMENT_ADMIN_REJECTED',
  REISSUE_PAYMENT_COMPLETED: 'REISSUE_PAYMENT_COMPLETED',
  REISSUE_PAYMENT_PENDING: 'REISSUE_PAYMENT_PENDING',
  REISSUE_PAYMENT_FAILED: 'REISSUE_PAYMENT_FAILED',
  UPDATED: 'UPDATED',
  UPDATE_REJECTED: 'UPDATE_REJECTED',
};

const ROLES = {
  OPERATOR_SUPER_ADMIN: 5,
  OPERATOR_ADMIN: 6,
};
const FILTER_OPTIONS = {
  REFERENCE_NUMBER: 'REFERENCE_NUMBER',
  VEHICLE_REGISTRATION_NUMBER: 'VEHICLE_REGISTRATION_NUMBER',
  RFID_NUMBER: 'RFID_NUMBER',
};

class VehicleCards1 extends Component {
  constructor(props) {
    super(props);

    let preparedObject = {};
    let editPrearedObject = {};
    this.vehicles = vehicles;
    this.popupFormData = getPopupFormData();
    this.history = getHistoryData();
    this.filteredVehicles = vehicles;
    this.formData = getFormData();
    this.editFormData = getEditFormData();
    [...this.formData.firstColumn].forEach(obj => {
      preparedObject[obj.stateField] = {
        value: obj.stateDefault,
        // error: null,
      };
      if (obj.optionsField) {
        preparedObject[obj.optionsField] = [];
      }
    });
    [...this.editFormData].forEach(obj => {
      editPrearedObject[obj.stateField] = {
        value: obj.stateDefault,
        // error: null,
      };
      if (obj.optionsField) {
        editPrearedObject[obj.optionsField] = [];
      }
    });
    const reduxProps = this.props.reduxProps;
    console.log('reduxProps-->Person Cards', reduxProps);
    const roleId = reduxProps?.login?.loginInfo?.user?.roleId;
    console.log('ROLE ID---', roleId);
    this.state = {
      pageStatus: 'landing',
      vehicleInfo: {},
      searchText: '',
      selectedType: undefined,
      filterOptions: [
        {
          name: 'Reference Number',
          id: FILTER_OPTIONS.REFERENCE_NUMBER,
        },
        {
          name: 'Vehicle Registration Number',
          id: FILTER_OPTIONS.VEHICLE_REGISTRATION_NUMBER,
        },
        {
          name: 'RFID Number',
          id: FILTER_OPTIONS.RFID_NUMBER,
        },
      ],
      remarks: '',
      rfidCardNumber: '',
      //FIXME: Remove
      verifiedPersonalDetails: false,
      //FIXME: Remove
      verifiedProofDocuments: false,

      successDetails: null,
      roleId: reduxProps?.login?.loginInfo?.user?.roleId,
      ...preparedObject,
      ...editPrearedObject,
      edit: false,
    };
  }

  getVehicleInfo = async () => {
    const { searchText } = this.state;
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
    const searchTextTrim = searchText.trim();
    await API.getVehicleByReferenceId(searchTextTrim)
      .then(response => {
        console.log('res...', response.data);
        const vehicleInfo = response.data;
        const { status } = vehicleInfo;
        const buttons = [];

        switch (status) {
          case STATUS.HOLD:
            buttons.push({
              text: strings.approve,
              action: 'approve',
              style: styles.approve,
            });
            break;
          case STATUS.UPDATED:
          case STATUS.UPDATE_REJECTED:
          case STATUS.ISSUED: {
            if (
              this.state?.roleId === ROLES.OPERATOR_ADMIN ||
              this.state?.roleId === ROLES.OPERATOR_SUPER_ADMIN
            ) {
              buttons.push(
                {
                  text: strings.edit,
                  action: 'edit',
                  style: styles.hold,
                },
                // {
                //   text: strings.edit,
                //   action: 'keepOnHold',
                //   style: styles.hold,
                // },
                {
                  text: strings.surrenderCard,
                  action: 'surrenderCard',
                  style: styles.reject,
                },
                {
                  text: 'Vehicle Tag Lost/Damaged',
                  action: 'lost',
                  style: styles.approve,
                },
                {
                  text: 'Change RFID Number',
                  action: 'changeRFID',
                  style: styles.approve,
                },
              );
            }
            break;
          }

          case STATUS.DEPARTMENT_ADMIN_APPROVED: {
            buttons.push(
              // {
              //   text: strings.keepOnHold,
              //   action: 'keepOnHold',
              //   style: styles.hold,
              // },
              {
                text: strings.approve,
                action: 'approve',
                style: styles.approve,
              },
            );
            break;
          }
          case STATUS.PAYMENT_COMPLETED: {
            buttons.push(
              // {
              //   text: strings.keepOnHold,
              //   action: 'keepOnHold',
              //   style: styles.hold,
              // },
              {
                text: strings.approve,
                action: 'approve',
                style: styles.approve,
              },
            );
            break;
          }
          case STATUS.PENDING: {
            buttons.push(
              // {
              //   text: strings.keepOnHold,
              //   action: 'keepOnHold',
              //   style: styles.hold,
              // },
              {
                text: strings.approve,
                action: 'approve',
                style: styles.approve,
              },
            );
            break;
          }
          case STATUS.REISSUE_PAYMENT_COMPLETED: {
            buttons.push(
              // {
              //   text: strings.keepOnHold,
              //   action: 'keepOnHold',
              //   style: styles.hold,
              // },
              {
                text: strings.approve,
                action: 'approve',
                style: styles.approve,
              },
            );
            break;
          }
          default:
            break;
        }

        // Check for fitness certificate.
        if (!vehicleInfo.fitnessCertificate) {
          this.formData.firstColumn = this.formData.firstColumn.filter(
            obj => obj.stateField !== 'fitnessCertificate',
          );
        }
        this.setState({
          vehicleInfo: {
            ...vehicleInfo,
            vehicleType: vehicleInfo.vehicleType.name,
            phoneNumber: vehicleInfo.phoneNumber.split('+91')[1],
          },
          buttons,
          pageStatus: 'showVehicleInfo',
          popup: undefined,
          vehicleTypeId: vehicleInfo.vehicleTypeId,
          vehicleTypeOptions: vehicleInfo.vehicleCategory.vehicleTypes,

          name: vehicleInfo.name,
          vehicleRegistrationNumber: vehicleInfo.vehicleRegistrationNumber,
          phoneNumber: vehicleInfo.phoneNumber.split('+91')[1],
          edit: false,
        });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        this.setState({
          popup: undefined,
          pageStatus: '',
        });
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.SIMPLE_POPUP,
            message: errorMsg,
            messageStyle: { color: '#E67717' },
            buttons: [
              {
                text: 'Okay!',
                onClose: this.closePopup,
              },
            ],
          },
        });
      });
  };
  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: { color: '#E67717' },
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  handleSurrender = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.CARDS_ACTIONS_POPUP,
        title: strings.vehiclesCard,
        subTitle: strings.areYouSureSurrender,

        onBack: () => {
          this.setState({
            popup: null,
          });
        },
        onConfirm: remark => this.surrender(remark),
        handlePictureUpload: this.handlePictureUpload,
      },
    });
  };
  surrender = remark => {
    // console.log("RFIDNumber, remark", RFIDNumber, remark, verifiedList);
    const { vehicleInfo, photoUrl } = this.state;
    this.showSpinner();

    let formData = {
      id: vehicleInfo.id,
      remarks: remark,
      // photo: photoUrl ? photoUrl : 'dummy.jpg',
    };

    API.surrenderVehicle(formData)
      .then(res => {
        console.log('res', res);

        this.setState({
          popup: null,
          pageStatus: 'success',

          successDetails: {
            remark,
            vehicleInfo,
            type: 'surrendered',
            onOkay: () => {
              this.setState({ pageStatus: 'landing' });
            },
          },
        });
      })
      .catch(error => {
        console.log(error);
        const errorMsg = util.getErrorMessage(error);
        this.showErrorPopup(errorMsg);
      });
  };

  onClickHandler = (action, data) => {
    const { history } = this.props;
    switch (action) {
      case 'vehicle-registration': {
        history.push('/vehicle-registration');
        break;
      }
      // OMB1338808543
      // TUS9417179325
      case 'apply': {
        // this.getVehicleInfo();
        this.handleFilterBarClick();
        break;
      }
      case 'approve': {
        this.onApprovePopup(data);
        // this.handleApprove(data);

        break;
      }
      case 'keepOnHold': {
        this.onRejectPopup(data);
        // this.handleHold(data);
        console.log('keeponholddata', data);
        console.info('keepOnHold....');
        break;
      }
      case 'surrenderCard': {
        this.handleSurrender();
        break;
      }
      case 'lost': {
        this.handleLost();
        break;
      }
      case 'changeRFID': {
        this.handleChangeRID();
        break;
      }
      case 'landing': {
        this.setState({
          pageStatus: 'landing',
        });
        break;
      }
      case 'edit': {
        this.setState({
          edit: true,
          pageStatus: 'showEditVehicleInfo',
        });
        break;
      }
      case 'editCancel': {
        this.setState({
          edit: false,
          pageStatus: 'showVehicleInfo',
        });
        break;
      }
      case 'editSubmit': {
        this.handleUpdate();
        break;
      }
      default: {
        console.info('case not handled', action);
      }
    }
  };

  handleChangeRID = () => {
    console.log('handle change RFID...');
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.CHANGE_RFID_POPUP,
        header: `Change RFID Tag Number`,
        onSubmit: RFID => this.changeRFID(RFID),
        onCancel: this.closePopup,
      },
    });
  };

  changeRFID = RFID => {
    this.showSpinner();

    if (RFID.length !== 7) {
      this.showErrorPopup(`RFID number should be 7 digits`);
      return;
    }
    const { vehicleInfo } = this.state;
    let formData = {
      id: vehicleInfo?.id,
      RFIDNumber: RFID,
    };

    API.changeRFIDVehicle(formData)
      .then(res => {
        this.showSuccessPopup(
          `RFID number successfully changed!`,
          this.handleFilterBarClick,
        );
      })
      .catch(err => {
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };
  handleLost = () => {
    this.showConfirmationPopup(
      `Are you sure you want to proceed with this operation?`,
      this.lost,
    );
  };
  showConfirmationPopup = (message, onOkay, onCancel) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: message || `Are you sure you want to perform this operation?`,
        messageStyle: { color: '#E67717' },
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: onOkay ? onOkay : this.closePopup,
            outline: true,
            style: { backgroundColor: '#0045e6', color: '#fff' },
          },
          {
            text: 'Cancel',
            onClick: onCancel ? onCancel : this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  lost = () => {
    this.showSpinner();
    API.vehicleCardLost({ id: this.state.vehicleInfo.id })
      .then(res => {
        this.showSuccessPopup(
          `Tag successfully marked as lost / damaged!`,
          this.handleFilterBarClick,
        );
      })
      .catch(err => {
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };
  handleFilterBarClick = () => {
    const { selectedType } = this.state;

    if (selectedType === FILTER_OPTIONS.VEHICLE_REGISTRATION_NUMBER) {
      // do something
      this.getVehicleByVehicleRegistrationNumber();
    }
    if (selectedType === FILTER_OPTIONS.RFID_NUMBER) {
      // do something
      this.getVehicleByRFIDNumber();
    } else {
      this.getVehicleInfo();
    }
  };
  showSuccessPopup = (message, onClick) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: message || 'Action handled successfully!',
        messageStyle: {},
        headingImage: operatorImages.successIcon,
        onClose: onClick ? onClick : this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: onClick ? onClick : this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  getVehicleByRFIDNumber = async () => {
    const { searchText } = this.state;
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
    const searchTextTrim = searchText.trim();
    await API.fetchVehicleByRFID(searchTextTrim)
      .then(response => {
        console.log('res...', response.data);
        const vehicleInfo = response.data;
        const { status } = vehicleInfo;
        const buttons = [];

        switch (status) {
          case STATUS.HOLD:
            buttons.push({
              text: strings.approve,
              action: 'approve',
              style: styles.approve,
            });
            break;
          case STATUS.UPDATED:
          case STATUS.UPDATE_REJECTED:
          case STATUS.ISSUED: {
            buttons.push(
              {
                text: strings.edit,
                action: 'edit',
                style: styles.hold,
              },
              // {
              //   text: strings.keepOnHold,
              //   action: 'keepOnHold',
              //   style: styles.hold,
              // },
              {
                text: strings.surrenderCard,
                action: 'surrenderCard',
                style: styles.reject,
              },
            );
            if (
              this.state?.roleId === ROLES.OPERATOR_ADMIN ||
              this.state?.roleId === ROLES.OPERATOR_SUPER_ADMIN
            ) {
              buttons.push(
                {
                  text: 'Vehicle Tag Lost/Damaged',
                  action: 'lost',
                  style: styles.approve,
                },
                {
                  text: 'Change RFID Number',
                  action: 'changeRFID',
                  style: styles.approve,
                },
              );
            }
            break;
          }
          case STATUS.DEPARTMENT_ADMIN_APPROVED: {
            buttons.push(
              // {
              //   text: strings.keepOnHold,
              //   action: 'keepOnHold',
              //   style: styles.hold,
              // },
              {
                text: strings.approve,
                action: 'approve',
                style: styles.approve,
              },
            );
            break;
          }
          case STATUS.PAYMENT_COMPLETED: {
            buttons.push(
              // {
              //   text: strings.keepOnHold,
              //   action: 'keepOnHold',
              //   style: styles.hold,
              // },
              {
                text: strings.approve,
                action: 'approve',
                style: styles.approve,
              },
            );
            break;
          }
          case STATUS.PENDING: {
            buttons.push(
              // {
              //   text: strings.keepOnHold,
              //   action: 'keepOnHold',
              //   style: styles.hold,
              // },
              {
                text: strings.approve,
                action: 'approve',
                style: styles.approve,
              },
            );
            break;
          }
          default:
            break;
        }

        // Check for fitness certificate.
        if (!vehicleInfo.fitnessCertificate) {
          this.formData.firstColumn = this.formData.firstColumn.filter(
            obj => obj.stateField !== 'fitnessCertificate',
          );
        }
        this.setState({
          vehicleInfo: {
            ...vehicleInfo,
            vehicleType: vehicleInfo.vehicleType.name,
            phoneNumber: vehicleInfo.phoneNumber.split('+91')[1],
          },
          buttons,
          pageStatus: 'showVehicleInfo',
          popup: undefined,
          vehicleTypeId: vehicleInfo.vehicleTypeId,
          vehicleTypeOptions: vehicleInfo.vehicleCategory.vehicleTypes,

          name: vehicleInfo.name,
          vehicleRegistrationNumber: vehicleInfo.vehicleRegistrationNumber,
          phoneNumber: vehicleInfo.phoneNumber.split('+91')[1],
          edit: false,
        });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        this.setState({
          popup: undefined,
          pageStatus: '',
        });
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.SIMPLE_POPUP,
            message: errorMsg,
            messageStyle: { color: '#E67717' },
            buttons: [
              {
                text: 'Okay!',
                onClose: this.closePopup,
              },
            ],
          },
        });
      });
  };

  getVehicleByVehicleRegistrationNumber = async () => {
    const { searchText } = this.state;
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
    const searchTextTrim = searchText.trim();
    await API.fetchVehicleByVehicleRegistrationNumber(searchTextTrim)
      .then(response => {
        console.log('res...', response.data);
        const vehicleInfo = response.data;
        const { status } = vehicleInfo;
        const buttons = [];

        switch (status) {
          case STATUS.HOLD:
            buttons.push({
              text: strings.approve,
              action: 'approve',
              style: styles.approve,
            });
            break;
          case STATUS.UPDATED:
          case STATUS.UPDATE_REJECTED:
          case STATUS.ISSUED: {
            buttons.push(
              {
                text: strings.edit,
                action: 'edit',
                style: styles.hold,
              },
              // {
              //   text: strings.keepOnHold,
              //   action: 'keepOnHold',
              //   style: styles.hold,
              // },
              {
                text: strings.surrenderCard,
                action: 'surrenderCard',
                style: styles.reject,
              },
            );
            if (
              this.state?.roleId === ROLES.OPERATOR_ADMIN ||
              this.state?.roleId === ROLES.OPERATOR_SUPER_ADMIN
            ) {
              buttons.push(
                {
                  text: 'Vehicle Tag Lost/Damaged',
                  action: 'lost',
                  style: styles.approve,
                },
                {
                  text: 'Change RFID Number',
                  action: 'changeRFID',
                  style: styles.approve,
                },
              );
            }
            break;
          }
          case STATUS.DEPARTMENT_ADMIN_APPROVED: {
            buttons.push(
              // {
              //   text: strings.keepOnHold,
              //   action: 'keepOnHold',
              //   style: styles.hold,
              // },
              {
                text: strings.approve,
                action: 'approve',
                style: styles.approve,
              },
            );
            break;
          }
          case STATUS.PAYMENT_COMPLETED: {
            buttons.push(
              // {
              //   text: strings.keepOnHold,
              //   action: 'keepOnHold',
              //   style: styles.hold,
              // },
              {
                text: strings.approve,
                action: 'approve',
                style: styles.approve,
              },
            );
            break;
          }
          case STATUS.PENDING: {
            buttons.push(
              // {
              //   text: strings.keepOnHold,
              //   action: 'keepOnHold',
              //   style: styles.hold,
              // },
              {
                text: strings.approve,
                action: 'approve',
                style: styles.approve,
              },
            );
            break;
          }
          default:
            break;
        }

        // Check for fitness certificate.
        if (!vehicleInfo.fitnessCertificate) {
          this.formData.firstColumn = this.formData.firstColumn.filter(
            obj => obj.stateField !== 'fitnessCertificate',
          );
        }
        this.setState({
          vehicleInfo: {
            ...vehicleInfo,
            vehicleType: vehicleInfo.vehicleType.name,
            phoneNumber: vehicleInfo.phoneNumber.split('+91')[1],
          },
          buttons,
          pageStatus: 'showVehicleInfo',
          popup: undefined,
          vehicleTypeId: vehicleInfo.vehicleTypeId,
          vehicleTypeOptions: vehicleInfo.vehicleCategory.vehicleTypes,

          name: vehicleInfo.name,
          vehicleRegistrationNumber: vehicleInfo.vehicleRegistrationNumber,
          phoneNumber: vehicleInfo.phoneNumber.split('+91')[1],

          edit: false,
        });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        this.setState({
          popup: undefined,
          pageStatus: '',
        });
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.SIMPLE_POPUP,
            message: errorMsg,
            messageStyle: { color: '#E67717' },
            buttons: [
              {
                text: 'Okay!',
                onClose: this.closePopup,
              },
            ],
          },
        });
      });
  };

  handleUpdate = () => {
    const { name, vehicleRegistrationNumber, vehicleTypeId, vehicleInfo, phoneNumber } =
      this.state;

    if (!name) {
      this.showErrorPopup(`Please enter name`);
      return;
    }

    if (!vehicleRegistrationNumber) {
      this.showErrorPopup(`Please enter vehicle number`);
      return;
    }
    if (!vehicleTypeId) {
      this.showErrorPopup(`Please enter vehicle type`);
      return;
    }
    if (!phoneNumber) {
      this.showErrorPopup(`Please enter phone number`);
      return;
    }

    this.showSpinner();
    console.log(vehicleInfo);
    // API CALL HERE
    let formData = {
      id: vehicleInfo.id,
      name: name?.trim() === vehicleInfo?.name ? '' : name,
      vehicleRegistrationNumber:
        vehicleRegistrationNumber?.trim() ===
          vehicleInfo?.vehicleRegistrationNumber.trim()
          ? ''
          : vehicleRegistrationNumber,
      vehicleTypeId:
        vehicleTypeId === vehicleInfo?.vehicleTypeId ? 0 : vehicleTypeId,
      phoneNumber: phoneNumber?.trim() === vehicleInfo?.phoneNumber ? '' : `+91${phoneNumber}`,
    };

    API.updateVehicleByOperator(formData)
      .then(res => {
        console.log('res', res);
        this.setState({
          popup: null,
          successDetails: {
            vehicleInfo,
            type: 'updated',
          },
          pageStatus: 'updated',
        });
      })
      .catch(error => {
        console.log(error);
        const errorMsg = util.getErrorMessage(error);
        this.showErrorPopup(errorMsg);
      });
  };

  handleApprove = (remark, data) => {
    const { RFID, verifiedPersonalDetails, verifiedProofDocuments, vehicleInfo } =
      data;
    const { photoUrl } = this.state;

    if (RFID.length !== 7) {
      this.showErrorPopup(`RFID number should be 7 digits`);
      return;
    }

    console.log('handle approve data', data);
    this.showSpinner();
    // API CALL HERE
    let formData = {
      id: vehicleInfo.id,
      checkRC: true,
      checkPUC: true,
      checkInsurance: true,
      checkFitness: true,
      RFIDNumber: RFID,
      // photo: photoUrl ? photoUrl : 'dummy.jpg',
      remarks: remark,
    };

    API.approveVehicle(formData)
      .then(res => {
        console.log('res', res);
        this.setState({
          popup: null,
          successDetails: {
            RFIDNumber: RFID,
            remark,
            vehicleInfo,
            type: 'issued',
          },
          pageStatus: 'success',
        });
      })
      .catch(error => {
        console.log(error);
        const errorMsg = util.getErrorMessage(error);
        this.showErrorPopup(errorMsg);
      });
    console.log('DATA', data);
  };
  handleHold = (remark, data) => {
    const { vehicleInfo } = data;
    const { photoUrl } = this.state;
    this.showSpinner();
    // API CALL HERE
    let formData = {
      id: vehicleInfo.id,
      remarks: remark,
      // photo: vehicleInfo.photo ? vehicleInfo.photo : 'dummy.jpg',
      // photo: photoUrl ? photoUrl : 'dummy.jpg',
    };

    API.rejectVehicle(formData)
      .then(res => {
        console.log(res);
        this.setState({
          popup: null,
          pageStatus: 'success',
          successDetails: {
            remark,
            vehicleInfo,
            type: 'hold',
          },
        });
      })
      .catch(error => {
        console.log(error);
        const errorMsg = util.getErrorMessage(error);
        this.showErrorPopup(errorMsg);
      });
  };
  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  viewDocument = url => {
    const { history, location } = this.props;
    // this.setState({
    //   popup: {
    //     type: POPUP_CONSTANTS.VIEW_DOCUMENT,
    //     url: url,
    //     onClick: this.onClose,
    //   },
    // });
    history.push({
      pathname: location.pathname + '/document-view',
      state: { background: location, url: url },
    });
    // this.getDocumentTempURL(url);
  };

  getDocumentTempURL = url => {
    const { history, location } = this.props;
    const data = {
      url: url,
      expiryTime: 30,
    };
    API.preSignedURL(data)
      .then(res => {
        console.log('res.data', res.data?.preSignedUrl);
        history.push({
          pathname: location.pathname + '/document-view',
          state: { background: location, url: res.data?.preSignedUrl },
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  onClose = () => {
    this.setState({
      popup: null,
    });
  };
  handlePictureUpload = blob => {
    const formData = new FormData();
    formData.append('s3FolderName', 'company');
    formData.append('file', blob);

    API.uploadToS3(formData)
      .then(response => {
        console.log('File uploaded to S3...', response.data);
        this.setState({
          photoUrl: response.data,
        });
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        this.showErrorPopup(errorMsg);
      });
  };

  onChangeHandler = (action, data) => {
    //  console.log(action, data);
    switch (action) {
      case 'filter': {
        this.setState({
          selectedType: data,
        });
        break;
      }
      case 'searchText': {
        this.setState({
          searchText: data,
        });
        break;
      }
      case 'verifiedPersonalDetails': {
        this.setState({
          verifiedPersonalDetails: data,
        });
        break;
      }
      case 'verifiedProofDocuments': {
        this.setState({
          verifiedProofDocuments: data,
        });
        break;
      }
      case 'remark': {
        this.setState({
          remark: data,
        });
        break;
      }
      case 'RFIDNumber': {
        this.setState({
          RFIDNumber: data,
        });
        break;
      }

      case 'name': {
        this.setState({
          name: data,
        });
        break;
      }
      case 'vehicleRegistrationNumber': {
        this.setState({
          vehicleRegistrationNumber: data,
        });
        break;
      }

      case 'vehicleTypeId': {
        this.setState({
          vehicleTypeId: data,
        });
        break;
      }
      case 'phoneNumber': {
        this.setState({
          phoneNumber: data,
        });
        break;
      }

      default: {
        console.info('case not handled', action);
      }
    }
  };
  viewHistory = () => {
    // this.setState({
    //   popup: {
    //     type: POPUP_CONSTANTS.HISTORY_POPUP,
    //     title: 'History',
    //     history: this.history,
    //     onBack: () => {
    //       this.setState({
    //         popup: null,
    //       });
    //     },
    //   },
    // });
    this.getVehicleHistory();
  };
  getVehicleHistory = () => {
    const { history, location } = this.props;
    const { vehicleInfo } = this.state;

    API.viewVehicleHistory(vehicleInfo.referenceId)
      .then(res => {
        history.push({
          pathname: location.pathname + '/view-history',
          state: {
            background: location,
            history: res.data,
            rejectReasons: this.state.vehicleRejectReasons,
          },
        });
        this.setState({
          popup: null,
        });
      })
      .catch(err => {
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };

  getProps = () => {
    const {
      searchText,
      filterOptions,
      vehicleInfo,
      buttons,
      pageStatus,
      selectedType,
      verifiedPersonalDetails,
      verifiedProofDocuments,
      successDetails,
      edit,
    } = this.state;
    const { firstColumn } = this.formData;
    return {
      onClick: this.onClickHandler,
      formElements: edit ? this.editFormData : firstColumn,
      filterOptions: filterOptions,
      vehicles: this.filteredVehicles,
      searchText: searchText,
      onChange: this.onChangeHandler,
      vehicleInfo,
      buttons,
      pageStatus,
      selectedType,
      verifiedPersonalDetails,
      verifiedProofDocuments,
      successDetails,
      edit,
      stateData: this.state,
      viewDocument: this.viewDocument,
      viewHistory: this.viewHistory,
    };
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };

  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const { popup } = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SUCCESS_POPUP: {
        return <Success vehicle {...popup} />;
      }
      case POPUP_CONSTANTS.REJECT_POPUP: {
        return <Reject vehicle {...popup} />;
      }
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.VIEW_DOCUMENT: {
        return <ViewDocument {...popup} />;
      }
      case POPUP_CONSTANTS.CHANGE_RFID_POPUP: {
        return <ChangeRFID {...popup} />;
      }
      case POPUP_CONSTANTS.CARDS_ACTIONS_POPUP: {
        return (
          <CardsActionsPopup
            formData={this.popupFormData}
            stateData={this.state.vehicleInfo}
            {...popup}
          />
        );
      }
      case POPUP_CONSTANTS.HISTORY_POPUP: {
        return (
          <History
            formData={this.popupFormData}
            stateData={this.state.vehicleInfo}
            {...popup}
          />
        );
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };
  onApprovePopup = data => {
    console.log('data:', data);
    const { vehicleInfo } = this.state;
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.CARDS_ACTIONS_POPUP,
        title: strings.vehiclesCard,
        subTitle: strings.areYouSureIssue,
        onBack: () => {
          this.setState({
            popup: null,
          });
        },
        onConfirm: remark => this.handleApprove(remark, data),
        handlePictureUpload: this.handlePictureUpload,
      },
    });
    this.setState({
      vehicleInfo: {
        ...vehicleInfo,
        RFIDNumber: data?.RFID,
      },
    });
  };
  onRejectPopup = data => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.CARDS_ACTIONS_POPUP,
        title: strings.vehiclesCard,
        subTitle: strings.areYouSureReject,
        onBack: () => {
          this.setState({
            popup: null,
          });
        },
        onConfirm: remark => this.handleHold(remark, data),
        handlePictureUpload: this.handlePictureUpload,
      },
    });
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const { popup } = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

const mapStateToProps = reduxProps => ({
  reduxProps,
});

const mapDispatchToProps = dispatch => ({
  // dispatchSetPersonsData: payload => {
  //   dispatch(setPersonsData(payload));
  // },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VehicleCards1),
);
