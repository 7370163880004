import React, {useState} from 'react';
import Flex from '../../Flex';
import FormElement from '../../FormElement';
import styles from './styles.module.scss';

import bgIcon from '../../../assets/images/background/image.png';
import closeIcon from '../../../assets/images/close-icon/image.png';
import moment from 'moment';
import strings from '../../../globalization';

const STATUSES = {
  ISSUED: 'ISSUED',
  HOLD: 'HOLD',
  PENDING: 'PENDING',
  SURRENDERED: 'SURRENDER',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAYMENT_COMPLETED: 'PAYMENT_COMPLETED',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  OPERATOR_ADMIN_APPROVED: 'OPERATOR_ADMIN_APPROVED',
  OPERATOR_ADMIN_REJECTED: 'OPERATOR_ADMIN_REJECTED',
  DEPARTMENT_ADMIN_APPROVED: 'DEPARTMENT_ADMIN_APPROVED',
  DEPARTMENT_ADMIN_REJECTED: 'DEPARTMENT_ADMIN_REJECTED',
  REISSUE_PAYMENT_COMPLETED: 'REISSUE_PAYMENT_COMPLETED',
  REISSUE_PAYMENT_PENDING: 'REISSUE_PAYMENT_PENDING',
  REISSUE_PAYMENT_FAILED: 'REISSUE_PAYMENT_FAILED',
};

const RFID_ASSIGN_ALLOW_LIST = {
  REISSUE_PAYMENT_COMPLETED: 'REISSUE_PAYMENT_COMPLETED',
  DEPARTMENT_ADMIN_APPROVED: 'DEPARTMENT_ADMIN_APPROVED',
};

const ROLES = {
  OPERATOR_SUPER_ADMIN: 5,
  OPERATOR_ADMIN: 6,
};

function PersonCardsEditPopup({
  containerClass,
  formData,
  stateData,
  onChangeHandler,
  viewDocument,
  viewHistory,
  person,
  onEdit,
  onEditCancel,
  onEditSubmit,
  roleId = 7,
}) {
  console.log('role id person cards 123', roleId);
  const [RFID, setRFID] = useState(person.RFIDNumber ? person.RFIDNumber : '');
  const {edit} = stateData;
  const getStatus = () => {
    let status = person.status;

    switch (status) {
      case STATUSES.ISSUED: {
        return 'Issued';
      }
      case STATUSES.HOLD: {
        return 'On Hold';
      }
      case STATUSES.SURRENDERED: {
        return 'Surrendered';
      }
      case STATUSES.PAYMENT_COMPLETED: {
        return 'Payment Completed';
      }
      case STATUSES.PAYMENT_PENDING: {
        return 'Payment Pending';
      }
      case STATUSES.PAYMENT_FAILED: {
        return 'Payment Failed';
      }
      case STATUSES.OPERATOR_ADMIN_APPROVED: {
        return 'Operator Admin Approved';
      }
      case STATUSES.OPERATOR_ADMIN_REJECTED: {
        return 'Operator Admin Rejected';
      }
      case STATUSES.DEPARTMENT_ADMIN_APPROVED: {
        return 'Department Admin Approved';
      }
      case STATUSES.DEPARTMENT_ADMIN_REJECTED: {
        return 'Department Admin Rejected';
      }
      case STATUSES.REISSUE_PAYMENT_PENDING: {
        return 'Reissue Payment Pending';
      }
      case STATUSES.REISSUE_PAYMENT_COMPLETED: {
        return 'Reissue Payment Completed';
      }
      case STATUSES.REISSUE_PAYMENT_FAILED: {
        return 'Reissue Payment Failed';
      }
      default: {
        return status;
      }
    }
  };

  const getStyles = () => {
    let status = person.status;

    switch (status) {
      case STATUSES.ISSUED: {
        return {
          padding: '2px 10px',
          borderRadius: '5px',
          backgroundColor: '#0AC15770',
        };
      }
      case STATUSES.HOLD: {
        return {
          padding: '2px 10px',
          borderRadius: '5px',
          backgroundColor: '#E6771770',
        };
      }
      case STATUSES.SURRENDERED: {
        return {
          padding: '2px 10px',
          borderRadius: '5px',
          backgroundColor: '#E6771770',
        };
      }
      default: {
        return {
          padding: '2px 10px',
          borderRadius: '5px',
          backgroundColor: '#0045E670',
        };
      }
    }
  };

  const getFormData = () => {
    return (
      <Flex className={[styles.columnLayout, styles.editable].join(' ')}>
        {formData.map((elem, idx) => {
          return (
            <FormElement
              type={elem.elementType}
              label={elem.label}
              required={elem.required}
              placeholderText={elem.placeholder}
              tabIndex={idx}
              options={stateData[elem.optionsField]}
              // value={stateData[elem.stateField].value}
              fileIcon={person[elem.fileIcon]}
              value={edit ? stateData[elem.stateField] : person[elem.id]}
              key={`firstColumn-${idx}`}
              maxLength={elem.maxLength}
              disabled={edit ? false : true}
              onChange={text => onChangeHandler(elem.stateField, text)}
              defaultValue={person[elem.id]}
              containerClassNames={styles.containerClassNames}
              labelClassNames={styles.labelClassNames}
              uppercase={elem.uppercase}
              onRightIconClick={() => viewDocument(person[elem.fileIcon])}
            />
          );
        })}
      </Flex>
    );
  };

  const getImageColumn = () => {
    return (
      <Flex direction="column" className={styles.imageTextContainer}>
        <img
          src={person.photo ? person.photo : bgIcon}
          className={styles.image}
          alt="AVATAR"
        />
        {/* <img src={bgIcon} className={styles.image} alt="AVATAR" /> */}
        <p className={styles.text}>
          {strings.referenceNumberLabel}: {person.referenceId}
        </p>
        <p className={styles.text}>
          {strings.requestDate}:{' '}
          {moment(person.createdDate).format('DD/MM/YYYY')}
        </p>
        <p className={styles.text}>
          {strings.status}: <span style={getStyles()}>{getStatus()}</span>
        </p>
        {person.status === STATUSES.ISSUED ? (
          <p className={styles.text}>
            {/* FIXME: Remove modified date and add approved Date  */}
            Approved date: {moment(person.modifiedDate).format('DD/MM/YYYY')}
          </p>
        ) : null}
        <button className={styles.history} onClick={viewHistory}>
          {strings.viewHistory}
        </button>
      </Flex>
    );
  };

  const handleEditSubmit = () => {
    onEditSubmit(RFID);
  };

  const handleCancel = () => {
    onEditCancel(RFID);
  };

  return (
    <div className={[styles.container, containerClass].join(' ')}>
      <div>
        <Flex className={styles.mainContainer}>
          <Flex
            direction="column"
            className={[styles.flex1, styles.editable].join(' ')}>
            {getFormData()}
            <Flex className={styles.buttonsContainer}>
              <>
                <button className={styles.reject} onClick={handleCancel}>
                  Cancel
                </button>
                <button className={styles.approve} onClick={handleEditSubmit}>
                  Submit
                </button>
              </>
            </Flex>
          </Flex>

          {/* {getSecondColumn()} */}
          {getImageColumn()}
        </Flex>
      </div>
    </div>
  );
}

export default PersonCardsEditPopup;
