import React, {Component} from 'react';

import bgImage from '../../assets/images/stealth/image.png';
import strings from '../../globalization';
import Spinner from '../../components/Spinner';
import PopupContainer from '../../components/PopupContainer';

import {withRouter} from 'react-router-dom';
import ViewRenderer from './views';
import GenericPopup from '../../components/GenericPopup';

import styles from './styles.module.scss';
import VideoGuide from '../../components/ResourceCenter/VideoGuide';
import QuickGuide from '../../components/ResourceCenter/QuickGuides';
import VideoPlayer from '../../components/ResourceCenter/VideoPlayer';
import {videos, mobileVideos} from './data';
const POPUP_CONSTANTS = {
  STANDARD_POPUP: 'standard-popup',
  ERROR_POPUP: 'error-popup',
  SPINNER_POPUP: 'spinner-popup',
  SIMPLE_POPUP: 'simple-popup',
  GENERIC_POPUP: 'generic-popup',
};

class ResourceCenter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showWebVideoList: true,
    };
  }

  backClick = () => {
    const {history} = this.props;
    history.goBack();
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  closePopup = () => {
    this.setState({
      popup: null,
    });
  };
  closeSingleVideoPopup = () => {
    const {showWebVideoList} = this.state;
    console.log(
      'on closing single video popup showWebVideoList: ',
      showWebVideoList,
    );

    this.setState({
      popup: {
        type: POPUP_CONSTANTS.GENERIC_POPUP,
        onClose: this.closePopup,
        containerClassnames: styles.videoPopup,
        showButton: false,
        title: 'Video Tutorials',
        children: (
          <VideoGuide
            onClick={this.handleClick}
            webVideos={videos}
            mobileVideos={mobileVideos}
            onClose={this.closePopup}
            showWebVideoList={showWebVideoList}
          />
        ),
      },
    });
  };

  handleClick = (action, data) => {
    const {history} = this.props;
    const {showWebVideoList} = this.state;
    switch (action) {
      case 'goToEDrishti': {
        history.push('/edrishti');
        break;
      }
      case 'video-popup': {
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.GENERIC_POPUP,
            onClose: this.closePopup,
            containerClassnames: styles.videoPopup,
            showButton: false,
            title: 'Video Tutorials',
            children: (
              <VideoGuide
                onClick={this.handleClick}
                webVideos={videos}
                mobileVideos={mobileVideos}
                onClose={this.closePopup}
                showWebVideoList={showWebVideoList}
              />
            ),
          },
        });
        break;
      }
      case 'guide-popup': {
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.GENERIC_POPUP,
            containerClassnames: styles.videoPopup,
            onClose: this.closePopup,
            showButton: false,
            title: 'Quick Guides',
            children: <QuickGuide onClose={this.closePopup} />,
          },
        });
        break;
      }
      case 'single-video': {
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.GENERIC_POPUP,
            containerClassnames: styles.singleVideoPopup,
            onClose: this.closePopup,
            showButton: false,
            children: (
              <VideoPlayer video={data} onClose={this.closeSingleVideoPopup} />
            ),
          },
        });
        break;
      }
      case 'video-type': {
        console.log(action, data);
        this.setState({
          showWebVideoList: data,
        });
        break;
      }

      default: {
        break;
      }
    }
  };

  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="blue" />;
      }
      case POPUP_CONSTANTS.GENERIC_POPUP: {
        return <GenericPopup {...popup} />;
      }
      default: {
        console.warn('case not handled', popup.type);
        return null;
      }
    }
  };

  getProps = () => {
    return {
      handleClick: this.handleClick,
    };
  };

  render() {
    const {popup} = this.state;
    const props = this.getProps();

    console.log('this.props', this.props);
    // console.log('this.props.location', this.props.location);
    return (
      <>
        {popup ? (
          <PopupContainer containerStyle={styles.popup}>
            {' '}
            {this.getPopupContent()}{' '}
          </PopupContainer>
        ) : null}
        <ViewRenderer {...props} />
      </>
    );
  }
}

export default withRouter(ResourceCenter);
