import React from 'react';
import {useRouteMatch, Switch, Route} from 'react-router-dom';
import SideBar from '../components/SideBar';
import {useMediaQuery} from 'react-responsive';
import styles from './styles.module.css';
import {adminInvoiceTabs} from './tabs';
import AdminInvoice from '../pages/AdminInvoice';

/**
 * @return {JSX.Element}
 */
function InvoicesRouter(props) {
  const {url} = useRouteMatch();
  console.info('url', url);
  const isMobile = useMediaQuery({maxWidth: 767});

  const onProfileClick = () => {
    const {history} = props;
    history.push(`${url}/profile`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        {!isMobile ? (
          <div className={styles.sideBar}>
            <SideBar
              module={'admin'}
              header={'Permit Requests'}
              tabs={adminInvoiceTabs}
            />
          </div>
        ) : null}
        <div className={styles.relative}>
          <div className={styles.bodyWrapper}>
            <Switch>
              <Route path={`${url}/`}>
                <AdminInvoice />
              </Route>
              <Route path={`${url}/invoices`}>
                <AdminInvoice />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoicesRouter;
