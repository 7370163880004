import {REHYDRATE} from 'redux-persist';
import LANGUAGE_PREFERENCES from '../../constants/language-preferences';
import {ActionTypes} from '../constants';

const defaults = {
  language: LANGUAGE_PREFERENCES.ENGLISH,
};

export interface ActionType {
  type: string;
  payload: any;
}

export default (state = defaults, action: ActionType) => {
  switch (action.type) {
    case ActionTypes.UPDATE_LANGUAGE: {
      return {
        ...state,
        language: action.payload,
      };
    }
    case REHYDRATE: {
      if (action.payload && action.payload.userPreferences) {
        return {
          ...action.payload.userPreferences,
        };
      }
    }
    default:
      return {...state};
  }
};
