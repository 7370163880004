import React from 'react';
import styles from './styles.module.scss';
import {loginImages} from '../../assets/images';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import {Carousel} from 'react-responsive-carousel';

function RegistrationLeftContainer() {
  return (
    <div className={styles.leftContainer}>
      <Carousel
        showThumbs={false}
        autoPlay={true}
        showStatus={false}
        infiniteLoop={true}
        className={styles.carouselRoot}>
        <div className={styles.image1}></div>
        <div className={styles.image2}></div>
        <div className={styles.image3}></div>
      </Carousel>

      <img
        className={styles.sannaLogo}
        src={loginImages.sannaLogo}
        alt="eyeIcon"
      />
      <div className={styles.headerWrapper}>
        <div className={styles.eyeContainer}>
          <img
            className={styles.eyeIcon}
            src={loginImages.eyeIcon}
            alt="eyeIcon"
          />
          <div className={styles.heading1}>e-Drishti</div>
        </div>
        <div className={styles.heading1}>Access Control System</div>
      </div>
      <div className={styles.footerContainer}>
        <div className={styles.emblemContainer}>
          <img
            className={styles.logo}
            src={loginImages.newDptLogo}
            alt="Logo"
          />
          <img
            className={styles.emblemIcon}
            src={loginImages.emblem}
            alt="emblemIcon"
          />
        </div>
        <div className={styles.mobilePlayStoreIcons}>
          <p>Available: </p>
          <img
            className={styles.playStoreIcon}
            src={loginImages.playStoreIcon}
            alt="Play Store"
          />
          <p>and</p>
          <img
            className={styles.appStoreIcon}
            src={loginImages.appStoreIcon}
            alt="App Store"
          />
        </div>
      </div>
    </div>
  );
}

export default RegistrationLeftContainer;
