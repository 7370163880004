import React from 'react';
import Mobile from './Mobile';
import Desktop from './Desktop';
import {useMediaQuery} from 'react-responsive';
import {Metadata} from '../../../components/Metadata';
import ogImage from '../../../assets/images/og/image.png';

/**
 * This component is to render precall screen in different platforms
 * @param {viewRenderProps} props
 * @return {JSX.Element}
 */
function ViewRenderer(props) {
  // const isMobile = useMediaQuery({maxWidth: 767});
  const isMobile = () => {
    if (typeof window !== 'undefined') {
      return window.innerWidth < 767;
    } else {
      return false;
    }
  };
  if (isMobile()) {
    return (
      <Container>
        <Mobile {...props} />
      </Container>
    );
  } else {
    return (
      <Container>
        <Desktop {...props} />
      </Container>
    );
  }
}

const Container = ({children}) => {
  return (
    <>
      <Metadata
        title="One-stop smart port solution to revolutionize seaport operations and security | Saññã"
        description="Transform traditional seaports into cutting-edge smart ports with Saññã. Streamline operations, enhance security, and optimise efficiency. Explore the possibilities and potential of smart ports with Saññã. Join us in redefining the future of seaport management and logistics."
        image={ogImage}
      />
      {children}
    </>
  );
};

export default ViewRenderer;
