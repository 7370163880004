import React from 'react';
import styles from './Desktop.module.scss';
import AuthLayout from '../../../../../components/AuthLayout';
import {keys, formFields} from '../../data';
import {withRouter} from 'react-router-dom';
import strings from '../../../../../globalization';
import CustomInput from '../../../../../components/CustomInput';
import Button from '../../../../../components/Button';
import OtpInput from 'react-otp-input';
import Flex from '../../../../../components/Flex';
import {loginImages} from '../../../../../assets/images';

const Desktop = props => {
  const {onClick, onChangeState, otp, isShowOtp, backClick} = props;

  const formFieldsData = isShowOtp ? formFields : [formFields[0]];

  const handleClick = action => {
    console.info('handleClick...', action);
    onClick(action);
  };
  const onChange = (action, data) => {
    // console.info('onChange...', action);
    onChangeState(action, data.target.value);
  };

  const getInputType = element => {
    if (element.field === 'otp' && isShowOtp) {
      return (
        <div className={styles.otpContainer}>
          <label>{strings.enterOTP}</label>
          <OtpInput
            value={otp}
            numInputs={6}
            inputStyle={{
              width: '3rem',
              height: '3rem',
            }}
            onChange={update => onChangeState(element.field, update)}
            separator={<span>&nbsp; &nbsp;&nbsp;</span>}
          />
        </div>
      );
    }

    if (element.field === 'userId') {
      return (
        <>
          <CustomInput
            labelStyles={styles.customLabel}
            inputLable={element.label()}
            onChange={update => onChange(element.field, update)}
            type={element.type}
            placeholder={element.placeholder()}
            // required={element.required}
            name={element.field}
            leftIcon={element.leftIcon}
            style={{width: '100%', height: '50px'}}
            containerStyles={{marginBottom: '20px'}}
          />
          <div className={styles.forgotPasswordRow}>
            <a
              href=""
              onClick={e => {
                e.preventDefault();
                handleClick('send-otp');
              }}>
              {isShowOtp ? strings.resendOtp : strings.sendOtp}
            </a>
          </div>
        </>
      );
    }

    if (element.field !== 'otp') {
      return (
        <CustomInput
          labelStyles={styles.customLabel}
          inputLable={element.label()}
          onChange={update => onChange(element.field, update)}
          type={element.type}
          placeholder={element.placeholder()}
          // required={element.required}
          name={element.field}
          leftIcon={element.leftIcon}
          style={{width: '100%', height: '50px'}}
          containerStyles={{marginBottom: '20px'}}
        />
      );
    }
  };

  return (
    // <AuthLayout>
    <div className={styles.loginContainer}>
      <Flex onClick={backClick} className={styles.backlabel}>
        <img
          className={styles.backButtonIcon}
          src={loginImages.backButtonIcon}
          alt="back"
        />
        <span>{strings.back}</span>
      </Flex>
      {/* <div className={styles.logoContainer}>
        <img src="./static/images/Logo/logo.png" alt="Logo" />
        <h2>{keys.title}</h2>
        <h4>{keys.subTitle}</h4>
      </div> */}
      <div className={styles.formContainer}>
        <p className={styles.loginText}>{strings.forgotPassword}</p>
        {formFieldsData.map(field => getInputType(field))}
        <div className={styles.divider} />

        {isShowOtp && (
          <Button
            buttonStyle="primary"
            label={strings.confirm}
            className={styles.submitButton}
            onClick={() => handleClick('change-password')}
          />
        )}
      </div>
    </div>
    // </AuthLayout>
  );
};
export default withRouter(Desktop);
