import React from 'react';
import { loginImages } from '../../../../assets/images';
import Flex from '../../../../components/Flex';
import strings from '../../../../globalization';
import styles from './styles.module.scss';

import successIcon from '../../../../assets/images/success/image@3x.png';
// import errorIcon from '../../assets/images/error-icon/image@3x.png';
import errorIcon from '../../../../assets/images/reject-icon-cross/image@3x.png';
import PAYMENT_CONSTANTS from '../../../../constants/payment-constants';

const Desktop = ({
  paymentDetails,
  id,
  title,
  handleClick,
  backClick,
  pageStatus,
  type,
  ...remainingProps
}) => {
  const getMainContent = (paymentDetails, id, title) => {
    // const {paymentDetails, id, title} = this.props.route.params;
    // console.info('GET MAIN CONTENT ##-->', pageStatus);
    // const type = 'Vehicle Registration';
    const referenceNumber = 'REF100215084';

    switch (pageStatus) {
      case 'success': {
        return (
          <Flex direction="column" className={styles.successContainer}>
            <img src={successIcon} alt="U" className={styles.successIcon} />
            <h1 className={styles.successText}>
              Payment Successful for ₹{' '}
              {paymentDetails?.subTotal + paymentDetails?.tax}{' '}
            </h1>
            <p className={styles.applicationType}>
              Your application for {title} has been successfully submitted.
            </p>

            <p className={styles.refIdText}>
              Your Reference Number is{' '}
              <span className={styles.refId}>{id ? id : null}</span>
              <br />
              You will be informed on the next steps of collecting your RFID{' '}
              {type === PAYMENT_CONSTANTS.PERSON ? 'card' : 'tag'} to your
              email.
            </p>

            <button
              className={styles.payButton}
              onClick={() => handleClick('goBackToLogin')}>
              Go Back To Login
            </button>
          </Flex>
        );
      }
      case 'failure': {
        return (
          <>
            <Flex direction="column" className={styles.failureContainer}>
              <Flex
                direction="column"
                className={styles.paymentContainer}
                style={{ backgroundColor: '#E6771770' }}>
                <Flex direction="row" className={styles.paymentRow}>
                  <p className={styles.paymentRowLabel}>
                    {strings.registrationAmount}
                  </p>
                  <p className={styles.paymentRowLabel}>
                    ₹ {paymentDetails?.subTotal}
                  </p>
                </Flex>
                <Flex direction="row" className={styles.paymentRow}>
                  <p className={styles.paymentRowLabel}>{strings.gst}(18%)</p>
                  <p className={styles.paymentRowLabel}>
                    ₹ {paymentDetails?.tax}
                  </p>
                </Flex>
                <div className={styles.divider} />
                <Flex direction="row" className={styles.paymentRow}>
                  <p className={styles.paymentRowLabel}>{strings.total}</p>
                  <p className={styles.paymentRowLabel}>
                    ₹ {paymentDetails?.subTotal + paymentDetails?.tax}
                  </p>
                </Flex>
              </Flex>
              <img src={errorIcon} alt="U" className={styles.successIcon} />
              <h1 className={styles.successText}>Payment Failed</h1>
              {/* <p className={styles.applicationType}>
                Your Reference Number for your application is{' '}
                <span className={styles.refId}>{id ? id : null}</span>, you can
                pay later with this Reference Number from{' '}
                <span
                  className={styles.here}
                  onClick={() => handleClick('checkApplicationStatus')}>
                  check application status
                </span>
                .
              </p> */}
              <p className={styles.failureBottomText}>
                Payment for your {title} with eDrishti is unsuccessful. <br />
                Your reference No. is{' '}
                <span className={styles.refId}>{id ? id : null}</span>
              </p>
              <p className={styles.failureBottomText}>
                There must be an issue with payment processing. Please do not
                retry the payment for the next 4 hours. <br />
                For additional support, please call us at-{' '}
                <span className={styles.refId}>
                  +91 9316546987 (Timings- 10 AM to 5:30 PM){' '}
                </span>
                Or Email us at-{' '}
                <span className={styles.refId}>edrishti@sanya.ai</span>
              </p>

              <button
                className={styles.tryAgain}
                onClick={() => handleClick('payment')}>
                {strings.tryAgain}
              </button>
              <button
                className={styles.payLater}
                onClick={() => handleClick('goBackToHome')}>
                {strings.payLater}
              </button>
            </Flex>
          </>
        );
      }
      // eslint-disable-next-line no-duplicate-case
      case 'pending': {
        return (
          <>
            <Flex direction="column" className={styles.failureContainer}>
              <Flex
                direction="column"
                className={styles.paymentContainer}
                style={{ backgroundColor: '#E6771770' }}>
                <Flex direction="row" className={styles.paymentRow}>
                  <p className={styles.paymentRowLabel}>
                    {strings.registrationAmount}
                  </p>
                  <p className={styles.paymentRowLabel}>
                    ₹ {paymentDetails?.subTotal}
                  </p>
                </Flex>
                <Flex direction="row" className={styles.paymentRow}>
                  <p className={styles.paymentRowLabel}>{strings.gst}(18%)</p>
                  <p className={styles.paymentRowLabel}>
                    ₹ {paymentDetails?.tax}
                  </p>
                </Flex>
                <div className={styles.divider} />
                <Flex direction="row" className={styles.paymentRow}>
                  <p className={styles.paymentRowLabel}>{strings.total}</p>
                  <p className={styles.paymentRowLabel}>
                    ₹ {paymentDetails?.subTotal + paymentDetails?.tax}
                  </p>
                </Flex>
              </Flex>
              <img src={errorIcon} alt="U" className={styles.successIcon} />
              <h1 className={styles.successText}>Payment Pending</h1>

              <p className={styles.failureBottomText}>
                Payment for your {title} with eDrishti is pending.
                <br />
                Your reference No. is{' '}
                <span className={styles.refId}>{id ? id : null}</span>
              </p>

              <button
                className={styles.payLater}
                onClick={() => handleClick('goBackToHome')}>
                {strings.payLater}
              </button>
            </Flex>
          </>
        );
      }
      default: {
        return (
          <>
            <Flex direction="column" className={styles.paymentContainer}>
              <Flex direction="row" className={styles.paymentRow}>
                <p className={styles.paymentRowLabel}>
                  {strings.registrationAmount}
                </p>
                <p className={styles.paymentRowLabel}>
                  ₹ {paymentDetails?.subTotal}
                </p>
              </Flex>
              <Flex direction="row" className={styles.paymentRow}>
                <p className={styles.paymentRowLabel}>{strings.gst}(18%)</p>
                <p className={styles.paymentRowLabel}>
                  ₹ {paymentDetails?.tax}
                </p>
              </Flex>
              <div className={styles.divider} />
              <Flex direction="row" className={styles.paymentRow}>
                <p className={styles.paymentRowLabel}>{strings.total}</p>
                <p className={styles.paymentRowLabel}>
                  ₹ {paymentDetails?.subTotal + paymentDetails?.tax}
                </p>
              </Flex>
            </Flex>

            <button
              className={styles.payButton}
              onClick={() => handleClick('payment')}>
              {strings.pay}
            </button>
          </>
        );
      }
    }
  };

  return (
    <Flex direction="column" className={styles.container}>
      <Flex direction="column" className={styles.mainContainer}>
        <Flex direction="row" className={styles.topContainer}>
          <Flex onClick={backClick} className={styles.backlabel}>
            <img
              className={styles.backButtonIcon}
              src={loginImages.backButtonIcon}
              alt="back"
            />
            <span>{strings.back}</span>
          </Flex>
          <div className={styles.languageContainer}>
            <div className={styles.currentLanguage}>{strings.english}</div>
            {/* <div className={''}>{'Hindi'}</div> */}
          </div>
        </Flex>
        {/* <h1>Accept Payments</h1> */}
        <h1 className={styles.header}>{strings.paymentDetails}</h1>

        {getMainContent(paymentDetails, id, title)}
      </Flex>
      <Flex>
        <div className={styles.footerContainer}>
          <div>
            <span
              className={styles.privacy}
              onClick={() => handleClick('privacy')}>
              Privacy Policy
            </span>
            <span className={styles.terms} onClick={() => handleClick('terms')}>
              Terms of Use
            </span>
            <span className={styles.help}> Help Center</span>
          </div>
          <div className={styles.copyright}>
            © Copyright 2024. sanna. All rights reserved.
          </div>
        </div>
      </Flex>
    </Flex>
  );
};

export default Desktop;
