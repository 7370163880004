/*
 * @format
 * @flow
 */

import {combineReducers} from 'redux';
import postLoginReducer from './login';
import permitsReducer from './permits';
import userPreferencesReducer from './userPreferences';
import cartReducer from './cart';

const rootReducer = combineReducers({
  login: postLoginReducer,
  userPreferences: userPreferencesReducer,
  permits: permitsReducer,
  cart: cartReducer,
});

export default rootReducer;
