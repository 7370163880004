import React, {Component} from 'react';
import PopupContainer from '../../components/PopupContainer';
import Popup from '../../components/SimplePopup';

import {withRouter} from 'react-router-dom';

import ViewRenderer from './views';
import Spinner from '../../components/Spinner';
import moment from 'moment';
import {commonImages, operatorImages} from '../../assets/images';
import strings from '../../globalization';
import {API} from '../../requests';
import util from '../../util';
import * as XLSX from 'xlsx';
import Invoice from './Invoice';

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  ERROR: 'error',
  SPINNER_POPUP: 'spinner',
  PERSON_INVOICE_POPUP: 'person-invoice-popup',
  VEHICLE_INVOICE_POPUP: 'vehicle-invoice-popup',
};

class AdminInvoice extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      selectedStatus: this.props.status,
      searchText: '',
      startDate: moment().subtract(1, 'week').format('MM-DD-YYYY'),

      //      startDate: new moment().startOf('year').format('MM-DD-YYYY'),
      //      endDate: new moment().add(1, 'years').format('MM-DD-YYYY'),
      endDate: moment().add(1, 'day').format('MM-DD-YYYY'),

      dates: {
        start: moment().subtract(1, 'week').format('MM-DD-YYYY'),
        end: moment().add(1, 'day').format('MM-DD-YYYY'),
      },
      invoices: [],
      timestamp: new Date().getTime(),
      selectedRequestType: {
        id: '1',
        name: 'vehicle',
        displayName: 'Vehicle Registration',
      },
      totalCount: 0,
      statuses: [
        {
          id: '1',
          name: 'vehicle',
          displayName: 'Vehicle Registration',
        },
        {
          id: '2',
          name: 'person',
          displayName: 'Person Registration',
        },
        {
          id: '3',
          name: 'wallet',
          displayName: 'Wallet Recharge',
        },
      ],
      currentInvoiceData: null,
    };
  }

  componentDidMount() {
    // this.getData();
  }

  downloadExcel = data => {
    const updatedData = data.map((item, index) => {
      console.log(item);
      return {
        Date: item?.date,
        'Reference ID': item?.referenceId,
        'Vehicle Registration Number': item?.vehicleRegistrationNumber,
        'Entry Category': item?.entryCategory,
        'Number of Passes': item?.numberOfPasses,
        Status: item?.status,
        'Reconciliation Status': item?.reconciliationStatus,
      };
    });
    console.log(updatedData);
    const worksheet = XLSX.utils.json_to_sheet(updatedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'usage-history1');
    XLSX.writeFile(
      workbook,
      `usage-history-edrishti-${moment().format(`DD-MM-YYYY`)}.xlsx`,
    );
  };

  getData = (
    params,
    startDate,
    endDate,
    page,
    perPage,
    searchString,
    selectedRequestType,
  ) => {
    console.log('searchString:', searchString);
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });

    const data = {
      startDate: startDate,
      endDate: endDate,
      pageNumber: page,
      pageSize: perPage,
      searchString: searchString || undefined,
      type: selectedRequestType?.name || undefined,
    };

    this.showSpinner();
    API.getAllInvoices(data)
      .then(resp => resp)
      .then(res => {
        console.log('response:', typeof res.data, perPage);
        params.successCallback(
          res.data?.invoicesArray,
          res.data?.totalCount ? res.data?.totalCount : 0,
        );
        this.setState({
          invoices: res.data?.invoicesArray,
          popup: null,
          totalCount: res.data?.totalCount,
          timestamp: new Date().getTime(),
        });
      })
      .catch(err => {
        console.log(err);
        params.successCallback([], 0);
        this.setState({
          popup: undefined,
        });
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };

  showInvoicePopup = (type, data) => {
    console.log(type, data);
    this.showErrorPopup('santhsoh');
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.PERSON_INVOICE_POPUP,
      },
      currentInvoiceData: data?.invoiceData,
      details: data,
    });
  };

  onChangeHandler = (action, data) => {
    switch (action) {
      case 'search': {
        console.info('data..', data);
        this.setState({
          searchText: data,
        });

        break;
      }
      case 'date': {
        const {startDate, endDate, dates} = data;
        this.setState({
          startDate,
          endDate,
          dates,
        });
        break;
      }
      case 'gridAPI': {
        console.log('grid API', data);
        this.setState({gridApi: data});
        this.onChangeHandler('updateTable');
        break;
      }
      case 'updateTable': {
        const {gridApi, startDate, endDate, searchText, selectedRequestType} =
          this.state;
        const perPage = 100;
        console.log('updating table data', gridApi);
        if (gridApi) {
          console.log('updating table data1');
          const dataSource = {
            getRows: params => {
              const page = params.endRow / perPage;
              console.log(page, params);
              this.getData(
                params,
                startDate,
                endDate,
                page - 1,
                perPage,
                searchText,
                selectedRequestType,
              );
            },
          };
          gridApi.setDatasource(dataSource);
        }
        break;
      }
      case 'statusChange': {
        console.log(data);
        if (data) {
          this.setState({
            selectedStatus: data?.name,
            selectedRequestType: data,
          });
        }
        break;
      }

      default: {
        console.info('case not handled', action);
      }
    }
  };

  /**
   *
   * @param {*} action
   * @param {*} data
   */

  onClickHandler = (action, data) => {
    console.log(action, data);
    switch (action) {
      case 'apply': {
        console.log('clicked on apply button');
        this.onChangeHandler('updateTable');
        break;
      }
      case 'clearFilters': {
        this.resetData();
        break;
      }
      case 'downLoadInvoice': {
        console.log(data);

        const type = this.getType(data?.registrationType);
        this.showInvoicePopup(type, data);
        break;
      }
      default: {
        console.info('case not handled', action);
      }
    }
  };

  getType = typeString => {
    let type = 'vehicle';
    switch (typeString) {
      case 'Vehicle registration': {
        type = 'vehicle';
        break;
      }
      case 'Person registration': {
        type = 'person';
        break;
      }
      case 'Wallet registration': {
        type = 'wallet';
        break;
      }
      default: {
        console.info('case not handled for typeString:', typeString);
      }
    }
    return type;
  };

  resetData = () => {
    this.setState(
      {
        searchText: '',
        startDate: moment().subtract(1, 'week').format('MM-DD-YYYY'),
        endDate: moment().add(1, 'day').format('MM-DD-YYYY'),
        //        startDate: new moment().startOf('year').format('MM-DD-YYYY'),
        //       endDate: new moment().add(1, 'years').format('MM-DD-YYYY'),
        selectedRequestType: {
          id: '1',
          name: 'vehicle',
          displayName: 'Vehicle Registration',
        },
        dates: {
          start: moment().subtract(1, 'week').format('MM-DD-YYYY'),
          end: moment().add(1, 'day').format('MM-DD-YYYY'),
        },
      },
      () => {
        this.onChangeHandler('updateTable');
      },
    );
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };

  getProps = () => {
    const {
      startDate,
      endDate,
      dates,
      searchText,
      timestamp,
      statuses,
      invoices,
      selectedRequestType,
      totalCount,
    } = this.state;
    return {
      onClick: this.onClickHandler,
      selectedStatus: this.state.selectedStatus,
      onChange: this.onChangeHandler,
      searchText,
      startDate,
      endDate,
      dates,
      timestamp,
      statuses,
      invoices,
      selectedRequestType,
      totalCount,
    };
  };

  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const {popup, currentInvoiceData, details} = this.state;
    if (!popup) return;
    switch (popup.type) {
      // case "spinner": {
      //   console.log('showing spinner');
      //   return <Spinner />;
      // }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.PERSON_INVOICE_POPUP: {
        return (
          <Invoice
            type="person"
            invoiceData={currentInvoiceData}
            data={details}
            onClose={this.closePopup}
          />
        );
      }

      default: {
        console.warn('getPopupContent', popup.type);
      }
    }
  };
  showSuccessPopup = (message, onClick) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: message,
        headingImage: operatorImages.successIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: onClick ? onClick : this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  closePopup = () => {
    this.setState({
      popup: null,
    });
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}
export default withRouter(AdminInvoice);
