import styles from './styles.module.scss';
import {AgGridReact} from 'ag-grid-react';
import calendarBlue from '../../../../../assets/images/calendar-blue-icon/image.png';
import dimensions from '../../../../../util/dimensions-util';
import moment from 'moment';
import 'react-datasheet/lib/react-datasheet.css';
import PermitsHeader from '../../../../../components/PermitsHeader';
import 'react-daterange-picker/dist/css/react-calendar.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import './styles.css';
import React, {useState} from 'react';
import TextInput from '../../../../../components/TextInput';
import Button from '../../../../../components/Button';
import {withRouter} from 'react-router-dom';
import strings from '../../../../../globalization';
import DateRangePicker from 'react-daterange-picker';
import Flex from '../../../../../components/Flex';
import SannaLogo from '../../../../../components/SannaLogo';

const {vw: widthConstant, vh: heightConstant} = dimensions;

function Desktop({onClick, onChange, agencyList, dates, searchText}) {
  console.log('agencyList', agencyList);
  const [showDate, setShowDate] = useState(false);
  const rowStyle = {border: 'none', cursor: 'pointer'};
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return {background: '#f6f6f6', border: 'none'};
    }
  };
  // const formatDate = date => moment(date).format('MMM Do YY');
  const onSelect = dates => {
    console.log('dates', dates);
    onChange('date', {
      startDate: moment(dates.start).format('MM-DD-YYYY'),
      endDate: moment(dates.end).format('MM-DD-YYYY'),
      dates,
    });
    toggleDate();
  };
  const toggleDate = () => {
    setShowDate(!showDate);
  };
  const formatDate = date => moment(date).format('MMM Do YY');

  const handleClear = () => {
    setShowDate(false);
    onClick('clearFilters');
  };
  // const handleCellClick = (event, index) => {
  //   console.log('event', event);
  // };
  const columnDefs = [
    {
      headerName: 'Agency Id',
      field: 'agencyId',
      headerClass: styles.rowHeader,
      width: 150 * widthConstant,
      resizable: true,
    },
    {
      headerName: 'Reference Number',
      field: 'referenceId',
      headerClass: styles.rowHeader,
      width: 220 * widthConstant,
      resizable: true,
    },
    {
      headerName: 'Agency Name',
      field: 'agencyName',
      headerClass: styles.rowHeader,
      width: 300 * widthConstant,
      resizable: true,
    },

    {
      headerName: 'Agency Email Address',
      field: 'emailAddress',
      headerClass: styles.rowHeader,
      width: 300 * widthConstant,
      resizable: true,
      wrapText: true,
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        lineHeight: 1.2,
      },
    },
    {
      headerName: 'Mobile Number',
      field: 'phoneNumber',
      headerClass: styles.rowHeader,
      width: 200 * widthConstant,
      resizable: true,
    },
    {
      headerName: 'Date Applied',
      field: 'date',
      headerClass: styles.rowHeader,
      width: 250 * widthConstant,
      resizable: true,
    },
    {
      headerName: 'Reconciliation time',
      field: 'reconciliationtime',
      headerClass: styles.rowHeader,
      width: 250 * widthConstant,
      resizable: true,
    },
    {
      headerName: 'Status',
      field: 'status',
      headerClass: styles.rowHeader,
      width: 200 * widthConstant,
      resizable: true,
    },

    {
      headerName: 'Actions',
      field: 'actions',
      headerClass: styles.rowHeader,
      width: 160 * widthConstant,
      resizable: true,

      cellRendererFramework: ({data: {status, agencyId}}) => {
        console.log('agencyId', agencyId);
        return (
          <Flex className={styles.actionImg}>
            <button
              onClick={() => onClick('Reconciliation', agencyId)}
              className={styles.button}
              disabled={status !== 'APPROVED'}>
              Reconciliation
            </button>
          </Flex>
        );
      },
    },
  ];

  return (
    <div>
      <PermitsHeader title="Agency" showTabs={false} />
      <div className={styles.agencyContainer}>
        <div className={styles.header}>
          {/* <div className={styles.left}>
            <Flex direction="row">
              <div className={styles.dateWrapper}>
                <div onClick={toggleDate} className={styles.inputDate}>
                  {' '}
                  <div>
                    {' '}
                    {dates
                      ? formatDate(dates.start) + '-' + formatDate(dates.end)
                      : strings.ADMIN.dateRange}
                  </div>{' '}
                  <img src={calendarBlue} className={styles.calendar} alt="C" />
                </div>
                {showDate ? (
                  <div className={styles.datePicker}>
                    <DateRangePicker
                      numberOfCalendars={1}
                      selectionType="range"
                      onSelect={onSelect}
                      className={styles.dateRange}
                      value={dates}
                      singleDateRange={true}
                    />
                  </div>
                ) : null}
              </div>
            </Flex>
          </div> */}
          <div className={styles.right}>
            <div className={styles.inputSearch}>
              <TextInput
                onChange={text => {
                  onChange('search', text);
                }}
                isEdit={true}
                type={'text'}
                value={searchText}
                inputStyle={styles.inputText}
                showSeacrh={true}
                placeholder="Search by Agency Name or Reference Id"
              />
            </div>

            <div onClick={handleClear} className={styles.clearLabel}>
              Clear
            </div>
            <Button
              onClick={() => onClick('apply')}
              buttonStyle="primary"
              label={strings.ADMIN.apply}
              className={styles.applyBtn}></Button>
          </div>
        </div>

        <div className={['ag-theme-balham', styles.tableWrapper].join(' ')}>
          <AgGridReact
            columnDefs={columnDefs}
            defaultColDef={{sortable: true}}
            rowClass={[styles.gridRowClass]}
            rowData={agencyList}
            rowStyle={rowStyle}
            getRowStyle={getRowStyle}
            rowHoverHighlight={true}
            pagination={true}
            // onCellClicked={handleCellClick}
            paginationPageSize={10}
            rowHeight={50 * heightConstant}
            headerHeight={50 * heightConstant}
            domLayout="autoHeight"></AgGridReact>
        </div>
        <div className={styles.bottomLabel}> Showing {agencyList?.length}</div>
        <Button
          onClick={() => onClick('reconcile-all')}
          buttonStyle="primary"
          label={strings.ADMIN.reconcileAll}
          className={styles.reconcileAllBtn}></Button>
        <div className={styles.SannaLogo}>
          <SannaLogo />
        </div>
      </div>
    </div>
  );
}
export default withRouter(Desktop);
