import clsx from 'clsx';
import React, {useEffect, useState} from 'react';
import Button from '../../EDrishtiDesignSystem/Button';
import Flex from '../../EDrishtiDesignSystem/Flex';
import IconButton from '../../EDrishtiDesignSystem/IconButton';
import styles from './styles.module.scss';

import BackgroundImage from '../../../assets/images/resource-center/video-guide/background/image.png';
import Play from '../../../assets/images/resource-center/video-guide/play/image@2x.png';
import moment from 'moment';

const VideoGuide = ({
  onClose,
  webVideos,
  mobileVideos,
  onClick,
  showWebVideoList,
}) => {
  const [showWebVideos, setShowWebVideos] = useState(showWebVideoList);
  console.log(showWebVideos, showWebVideoList);
  const [videos, setVideos] = useState(
    showWebVideos ? webVideos : mobileVideos || [],
  );

  useEffect(() => {}, [showWebVideos, videos, showWebVideoList]);

  const showVideoTutorials = type => {
    setVideos([]);
    if (type === 'web') {
      setVideos(webVideos || []);
      setShowWebVideos(true);
      onClick('video-type', true);
    } else {
      setVideos(mobileVideos || []);
      setShowWebVideos(false);
      onClick('video-type', false);
    }

    console.log(showWebVideos, showWebVideoList);
  };

  return (
    <div className={styles.container}>
      <IconButton
        onClick={onClose}
        variant="cross"
        className={styles.crossIcon}
      />

      <div className={clsx(styles.content)}>
        <div className={styles.videoTutorialsLinks}>
          {!showWebVideos ? (
            <div
              className={styles.webLink}
              onClick={() => showVideoTutorials('web')}>
              Switch to web video tutorials
            </div>
          ) : (
            <div
              className={styles.webLink}
              onClick={() => showVideoTutorials('mobile')}>
              Switch to mobile video tutorials
            </div>
          )}
        </div>
        <h2 className={styles.heading}>
          {showWebVideos
            ? 'Please find the web video tutorials below'
            : 'Please find the mobile app video tutorials below'}
        </h2>

        <Flex align="center" style={{flexWrap: 'wrap', gap: '1rem'}}>
          {videos.map(el => (
            <VideoBox
              key={el.id}
              title={el.title}
              videoLength={el.videoLength}
              date={el.date}
              onClick={() => onClick('single-video', el)}
            />
          ))}
          {/* <VideoBox title={`Vehicle Registration`} />
          <VideoBox title={`Wallet Recharge`} />
          <VideoBox title={`Raising Permits`} /> */}
        </Flex>
      </div>
    </div>
  );
};

const VideoBox = ({title, videoLength, date, onClick}) => {
  return (
    <div className={styles.video} onClick={onClick}>
      <div className={styles.videoContainer}>
        <img
          src={BackgroundImage}
          className={styles.background}
          alt="background"
        />
        <div className={styles.bottomBadge}>{videoLength}</div>
        <div style={{position: 'relative'}}>
          <img src={Play} className={styles.play} alt="play" />
        </div>
      </div>
      <div className={styles.videoContent}>
        <p>{title}</p>
        <span>{date}</span>
      </div>
    </div>
  );
};

export default VideoGuide;
