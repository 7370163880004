import React, {useRef, useState} from 'react';

import Calendar from '../../assets/images/calendar-icon/image.png';
import util from '../../util';
import strings from '../../globalization';
import Flex from '../Flex';

import './styles.css';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import styles from './styles.module.scss';
import moment from 'moment';

import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

export const CustomDatePackage = ({
  datepickerLabel,
  datepickerPlaceholder,
  onChange,
  value,
  style,
  required = false,
  minimumDate,
  maximumDate,
  onBlur,
  tabIndex,
  dateClassNames,
  error,
  disabled,
  containerClassNames,
  labelClassNames,
  ...remainingProps
}) => {
  const handleChange = changedDate => {
    // let date = new Date(changedDate);
    onChange(changedDate);
  };
  const startRef = useRef();

  const onKeyDown = e => {
    if (e.keyCode === 9 || e.which === 9) {
      startRef.current.setOpen(false);
    }
  };

  const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/mm/yyyy');

  return (
    <div>
      <Flex>
        <p className={[styles.label, labelClassNames].join(' ')}>
          {datepickerLabel}
        </p>
        {required ? <p style={{color: 'red'}}>*</p> : null}
      </Flex>

      <DatePicker
        ref={startRef}
        tabIndex={tabIndex}
        selected={value ? new Date(value) : value}
        placeholderText={`dd/mm/yyyy`}
        onChange={date => handleChange(date)}
        minDate={minimumDate}
        maxDate={maximumDate}
        className={[styles.date, dateClassNames, containerClassNames].join(' ')}
        onBlur={onBlur}
        required={required}
        disabled={disabled}
        dateFormat={`dd/MM/yyyy`}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        onKeyDown={onKeyDown}
        customInput={
          <MaskedInput
            pipe={autoCorrectedDatePipe}
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            keepCharPositions={true}
            guide={true}
          />
        }
        // showFullMonthYearPicker
        // showMonthYearDropdown
        // renderCustomHeader={() => <div>Hi</div>}
        {...remainingProps}
      />

      <Flex style={{width: '100%', height: '1rem'}}>
        <p className={styles.error}>{error}</p>
      </Flex>
    </div>
  );
};

export default CustomDatePackage;
