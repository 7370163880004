import React from 'react';
import styles from './Person.module.scss';
import moment from 'moment';
import {adminImages, loginImages} from '../../assets/images';
import documentIcon from '../../../src/assets/images/document-icon/image.png';
import strings from '../../globalization';
/**
 *
 * @param {Boolean} error
 * @param {Boolean} success
 * @return {JSX.Element}
 */

/**
 *
 * @param {Props} props
 * @return {JSX.Element}
 */
function AgencyDetails(props) {
  console.log('agency details', props);
  const {
    rfidnumber,
    GSTNumber,
    businessName,
    address1,
    address2,
    name,
    companyDocuments,
    departmentName,
    emailAddress,
    phoneNumber,
    PANNumber,
    onClick,
  } = props || {};
  console.log('companyDocuments:', companyDocuments);
  const formatDate = date => moment(date).format('MMM Do YYYY');
  return (
    <div className={styles.cardWrapper}>
      <div className={styles.info}>
        <div>
          <div className={styles.requestKey}>
            Department :
            <span className={styles.requestLabel}> {departmentName} </span>
          </div>
          <div className={styles.requestKey}>
            {'Agency Email Address'} :{emailAddress}
            <span className={styles.requestLabel}></span>
          </div>
          <div className={styles.requestKey}>
            {strings.ADMIN.POP_UP.phoneNo} :
            <span className={styles.requestLabel}> {phoneNumber} </span>
          </div>
          <div className={styles.requestKey}>
            {'PAN Document'} :
            <span className={styles.requestLabel}>
              {'PAN DOCUMENT '}
              {companyDocuments?.map((document, index) => {
                return (
                  <>
                    {document?.fileType === 'PANDocument' ||
                    document?.fileType === 'PAN_DOCUMENT' ? (
                      <a
                        onClick={() =>
                          onClick('document-view', document.fileUrl)
                        }>
                        <img
                          className={styles.documentIcon}
                          alt="."
                          src={documentIcon}
                        />
                      </a>
                    ) : null}
                  </>
                );
              })}{' '}
            </span>
          </div>
        </div>
        <div>
          <div className={styles.requestKey}>
            {'Agency Name'} :{' '}
            <span className={styles.requestLabel}>{name ? name : null}</span>
          </div>
          <div className={styles.requestKey}>
            {'Address1'}:<span className={styles.requestLabel}>{address1}</span>
          </div>
          <div className={styles.requestKey}>
            {'GST Number'}:
            <span className={styles.requestLabel}> {GSTNumber}</span>
          </div>
          <div className={styles.requestKey}>
            {'Registration Proof Document'}:
            <span className={styles.requestLabel}>
              {'REGISTRATION PROOF'}
              {companyDocuments?.map((document, index) => {
                return (
                  <>
                    {document?.fileType === 'REGISTRATION_PROOF' ? (
                      <a
                        onClick={() =>
                          onClick('document-view', document.fileUrl)
                        }>
                        <img
                          className={styles.documentIcon}
                          alt="."
                          src={documentIcon}
                        />
                      </a>
                    ) : null}
                  </>
                );
              })}
            </span>
          </div>
        </div>
        <div>
          <div className={styles.requestKey}>
            {'Nature of Business'} :
            <span className={styles.requestLabel}> {businessName} </span>
          </div>
          <div className={styles.requestKey}>
            {'Address2'} :
            <span className={styles.requestLabel}>{address2}</span>
          </div>
          <div className={styles.requestKey}>
            {'PAN Number'} :
            <span className={styles.requestLabel}> {PANNumber} </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgencyDetails;
