import React, {useState, useEffect} from 'react';
import styles from './styles.module.scss';
import './styles.css';
import strings from '../../../../../../globalization';
import {withRouter} from 'react-router-dom';
import {AgGridReact} from 'ag-grid-react';
import Select from 'react-select';
import moment from 'moment';
import PermitsHeader from '../../../../../../components/PermitsHeader';

import 'react-daterange-picker/dist/css/react-calendar.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import dimensions from '../../../../../../util/dimensions-util';
// import {customStyles} from './customStyles';
import {adminImages} from '../../../../../../assets/images';
import DateRangePicker from 'react-daterange-picker';
// import { rowData } from './data';
import TextInput from '../../../../../../components/TextInput';
import Button from '../../../../../../components/Button';

import calendarBlue from '../../../../../../assets/images/calendar-blue-icon/image.png';
import {dummyData} from './dummyData';
import TableTimestamp from '../../../../../../components/TableTimestamp';
import {CSVLink} from 'react-csv';
import SannaLogo from '../../../../../../components/SannaLogo';
const {vw: widthConstant, vh: heightConstant} = dimensions;

const STATUS_MAPPER = {
  ADD_TO_WALLET: 'Wallet Recharged',
  HOLD_PASSES: 'Trips ON-HOLD',
  DEDUCT_ON_HOLD_PASSES: 'ON-HOLD trips deducted',
  REFUND_ON_HOLD_PASSES: 'ON-HOLD trips refunded',
  DEDUCT_VESTED_PASSES: 'Vested trips deducted',
  REFUND_VESTED_PASSES: 'Vested trips refunded',
  DEDUCT_AVAILABLE_PASSES: 'Entry Trip Deduction',
};
const RECONCILIATION_MAPPER = {
  NOT_VERIFIED: 'Not Verified',
  VERIFIED: 'Verified',
  REFUNDED: 'Refunded',
  NOT_REQUIRED: 'Not Required',
};

const Desktop = props => {
  const {
    onClick,
    onChange,

    selectedStatus,

    searchText,
    dates,
    usage,
    timestamp,
  } = props;

  const tabs = [
    {
      label: 'Available Credits',
      id: 1,
      route: 'available-credits',
    },
    {
      label: 'Transaction History',
      id: 2,
      route: 'transaction-history',
    },
    {
      label: 'Usage History',
      id: 3,
      route: 'usage-history',
    },
  ];

  const columnDefs = [
    {
      headerName: 'Date',
      field: 'date',
      headerClass: styles.rowHeader,
      minWidth: 150,
      flex: 1,
      resizable: true,
      cellStyle: {display: 'flex', alignItems: 'center'},
      cellRendererFramework: params => {
        console.log('params:', params);
        if (params?.data !== undefined) {
          const {createdDate} = params?.data;
          return (
            <div className={styles.sessionTime}>
              {moment(createdDate).format('DD/MM/YYYY HH:mm')}
            </div>
          );
        } else {
          return null;
        }
      },
    },
    {
      headerName: 'Reference ID',
      field: 'referenceId',
      headerClass: styles.rowHeader,
      // width: 200 * widthConstant,
      flex: 1,
      resizable: true,
      wrapText: true,
      autoHeight: true,
      minWidth: 150,
      cellStyle: {display: 'flex', alignItems: 'center'},
      cellRendererFramework: params => {
        if (params?.data !== undefined) {
          const referenceId = params?.data?.referenceId
            ? params?.data?.referenceId
            : params?.data?.permitDetails?.referenceId;
          return (
            <div className={styles.sessionTime}>
              {referenceId ? referenceId : '-'}
            </div>
          );
        } else {
          return null;
        }
      },
    },
    {
      headerName: 'Vehicle Registration Number/Owner Name',
      field: 'vehicleRegistrationNumber',
      headerClass: styles.rowHeader,

      resizable: true,
      width: 350,
      wrapText: true,
      autoHeight: true,
      cellStyle: {display: 'flex', alignItems: 'center'},
      cellRendererFramework: params => {
        if (params?.data !== undefined) {
          // const vehicleRegistrationNumber =
          //   params?.data?.permitDetails?.vehicles[0]?.vehicle
          //     ?.vehicleRegistrationNumber;
          // const vehicleOwnerName =
          //   params?.data?.permitDetails?.vehicles[0]?.vehicle?.name;
          const vehicleRegistrationNumber = params?.data?.vehicleNumber;
          const vehicleOwnerName = params?.data?.vehicleOwnerName;

          return (
            <div className={styles.sessionTime}>
              {vehicleRegistrationNumber ? vehicleRegistrationNumber : '-'}
              {vehicleOwnerName ? '/' + vehicleOwnerName : '-'}
            </div>
          );
        } else {
          return null;
        }
      },
    },
    {
      headerName: 'Entry Category',
      field: 'entryCategory',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      wrapText: true,
      autoHeight: true,
      minWidth: 200,
      cellStyle: {lineHeight: 1.5},
      cellRendererFramework: params => {
        if (params?.data !== undefined) {
          return (
            <div className={styles.sessionTime}>
              {params?.data?.passCategory}
            </div>
          );
        } else {
          return null;
        }
      },
    },

    {
      headerName: 'Credits',
      field: 'numberOfPasses',
      headerClass: styles.rowHeader,

      resizable: true,
      width: 100,
      wrapText: true,
      autoHeight: true,
      cellStyle: {display: 'flex', alignItems: 'center'},
      cellRendererFramework: params => {
        if (params?.data !== undefined) {
          const {numberOfPasses} = params?.data;
          return <div className={styles.sessionTime}>{numberOfPasses}</div>;
        } else {
          return null;
        }
      },
    },

    {
      headerName: 'Status',
      field: 'status',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      wrapText: true,
      autoHeight: true,
      minWidth: 200,
      cellStyle: {lineHeight: 1.5},
      cellRendererFramework: params => {
        if (params?.data !== undefined) {
          const status = params?.data?.action;
          return (
            <div className={styles.sessionTime}>
              {STATUS_MAPPER[status] ? STATUS_MAPPER[status] : status}
            </div>
          );
        } else {
          return null;
        }
      },
    },
    {
      headerName: 'Reconciliation Status',
      field: 'reconciliationStatus',
      headerClass: styles.rowHeader,
      flex: 1,
      resizable: true,
      wrapText: true,
      autoHeight: true,
      minWidth: 200,
      cellStyle: {lineHeight: 1.5},
      cellRendererFramework: params => {
        if (params?.data !== undefined) {
          const {reconciliationStatus} = params?.data;
          return (
            <div className={styles.sessionTime}>
              {RECONCILIATION_MAPPER[reconciliationStatus]
                ? RECONCILIATION_MAPPER[reconciliationStatus]
                : reconciliationStatus}
            </div>
          );
        } else {
          return null;
        }
      },
    },
  ];
  const [showDate, setShowDate] = useState(false);
  const [gridApi, setGridApi] = useState(null);

  useEffect(() => {
    console.log(gridApi);
    if (gridApi) {
      // gridApi.setRowHeight(50);
    }
    if (gridApi && usage.length > 10) {
      console.log('changing to normal');
      gridApi.setDomLayout('normal');
    } else if (gridApi) {
      console.log('changing to autoHeight');
      gridApi.setDomLayout('autoHeight');
    }
  }, [gridApi, usage, searchText]);

  const updateTable = () => {
    onChange('updateTable', null);
  };
  useEffect(() => {
    console.log(gridApi);
    updateTable();
  }, [gridApi]);

  const onGridReady = params => {
    setGridApi(params.api);
    onChange('gridAPI', params.api);
  };
  const onCellClick = (event, index) => {
    onClick('rowClick', event.data);
  };
  const onSelect = dates => {
    onChange('date', {
      startDate: moment(dates.start).format('MM-DD-YYYY'),
      endDate: moment(dates.end).format('MM-DD-YYYY'),
      dates,
    });
    toggleDate();
  };
  const toggleDate = () => {
    setShowDate(!showDate);
  };

  const onTabClick = id => {
    switch (id) {
      case 1:
        onClick('statusRoute', {route: 'available-credits', id: id});
        break;
      case 2:
        onClick('statusRoute', {route: 'transaction-history', id: id});
        break;
      case 3:
        onClick('statusRoute', {route: 'usage-history', id: id});
        break;
      default:
        break;
    }
  };

  const formatDate = date => moment(date).format('MMM Do YY');
  const rowStyle = {border: 'none', cursor: 'pointer'};
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return {background: '#f6f6f6', border: 'none'};
    }
  };

  const handleClear = () => {
    setShowDate(false);
    onClick('clearFilters');
  };

  const getRowData = () => {
    console.log('usage...', usage);
    let sanitizedData = usage.map(el => {
      return {
        date: el?.createdDate,
        entryCategory: el?.passCategory?.name,
        numberOfPasses: el?.numberOfPasses,
        status: el?.action,
        reconciliationStatus: el?.reconciliationStatus,
        referenceId: el?.agencyPassWalletOrder?.referenceId
          ? el?.agencyPassWalletOrder?.referenceId
          : el?.permitDetails?.referenceId,

        vehicleRegistrationNumber:
          el?.permitDetails?.vehicles[0]?.vehicle?.vehicleRegistrationNumber,
      };
    });
    return sanitizedData;
  };
  return (
    <div>
      <PermitsHeader
        title="Wallet"
        onTabClick={onTabClick}
        tabs={tabs}
        // availableCredits={availableCredits}
        activeTab={selectedStatus === 'USAGE_HISTORY' ? 3 : null}
      />
      <div className={styles.bodyContainer}>
        <div className={styles.header}>
          <div className={styles.left}>
            <div className={styles.dateWrapper}>
              <div onClick={toggleDate} className={styles.inputDate}>
                {' '}
                <div>
                  {' '}
                  {dates
                    ? formatDate(dates.start) + '-' + formatDate(dates.end)
                    : strings.ADMIN.dateRange}
                </div>{' '}
                <img src={calendarBlue} className={styles.calendar} alt="C" />
              </div>
              {showDate ? (
                <div className={styles.datePicker}>
                  <DateRangePicker
                    numberOfCalendars={1}
                    selectionType="range"
                    onSelect={onSelect}
                    className={styles.dateRange}
                    value={dates}
                    singleDateRange={true}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.inputSearch}>
              <TextInput
                onChange={text => {
                  onChange('search', text);
                }}
                isEdit={true}
                type={'text'}
                value={searchText}
                inputStyle={styles.inputText}
                showSeacrh={true}
                placeholder={`Search by Reg. Number or Ref. Number`}
              />
            </div>
            <div onClick={handleClear} className={styles.clearLabel}>
              {' '}
              {strings.ADMIN.clear}
            </div>
            {usage && usage.length > 0 ? (
              <Button
                onClick={() => onClick('loadCSV')}
                buttonStyle="primary"
                label={'Download'}
                className={styles.downloadBtn}></Button>
            ) : null}
            <Button
              onClick={() => onClick('apply')}
              buttonStyle="primary"
              label={strings.ADMIN.apply}
              className={styles.applyBtn}></Button>
          </div>
        </div>
        <TableTimestamp timestamp={timestamp}>
          <div
            className={[
              'ag-theme-balham usageHistoryTable',
              styles.tableWrapper,
              usage?.length > 10
                ? styles.tableWrapperNormal
                : styles.tableWrapperAutoHeight,
            ].join(' ')}>
            <AgGridReact
              onGridReady={onGridReady}
              rowStyle={rowStyle}
              getRowStyle={getRowStyle}
              rowHoverHighlight={true}
              columnDefs={columnDefs}
              rowHeight={55 * heightConstant}
              headerHeight={60}
              defaultColDef={{sortable: true}}
              rowClass={[styles.gridRowClass]}
              onCellClicked={(event, index) => onCellClick(event, index)}
              pagination={true}
              paginationPageSize={100}
              rowModelType={'infinite'}
              domLayout="autoHeight"></AgGridReact>
          </div>
        </TableTimestamp>
        {/* <div className={styles.bottomLabel}>
          {' '}
          Showing {filterAgencies.length}
        </div> */}
      </div>
      <div className={styles.SannaLogo}>
        <SannaLogo />
      </div>
    </div>
  );
};

export default Desktop;
