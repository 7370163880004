import React, {useState} from 'react';

import File from '../../assets/images/file-icon/image.png';
import styles from './styles.module.scss';

import util from '../../util';
import Flex from '../Flex';
import ALLOWED_FORMATS from '../../constants/allowed-formats';

export const UploadPhotoInput = ({
  labelText,
  placeholderText,
  required = false,
  handleUpload,
  value,
  maxFiles = 1,
  onChange,
  allowedFormats,
  onBlur,
  tabIndex,
  multiple = false,
  uploadBoxClassNames,
  error: err,
  triggerPicturePopup,
}) => {
  const [id, setId] = useState(util.generateUniqueId());
  const [error, setError] = useState(null);
  const inputRef = React.useRef();
  const handleCancel = (e, index) => {
    e.preventDefault();
    let localState = value.filter((elem, idx) => idx !== index);
    if (inputRef) {
      inputRef.current.value = '';
    }

    onChange(localState);
  };
  const getSubstring = text => {
    return text.substr(0, 6);
  };
  const upload = event => {
    setError('');
    if (value.length >= maxFiles) {
      setError(`Cannot upload more than ${maxFiles} files`);
    } else {
      handleUpload(event.target.files);
    }
  };
  const getAllowedFormats = () => {
    let formats = [];
    allowedFormats?.map(type => {
      if (type === ALLOWED_FORMATS.PDF) {
        formats.push('.pdf');
      }
      if (type === ALLOWED_FORMATS.IMAGES) {
        formats.push('.png');
        formats.push('.jpg');
        formats.push('.jpeg');
      }
    });
    return formats.join(',');
  };

  return (
    <div style={styles.containerStyles}>
      <Flex style={styles.textboxContainer}>
        <p className={styles.label}>{labelText}</p>
        {required ? <p style={{color: 'red'}}>*</p> : null}
      </Flex>

      <div style={{position: 'relative'}} tabIndex={tabIndex}>
        <label
          bordered
          htmlFor={`uploadHTMLElement-${id}`}
          className={[styles.uploadBox, uploadBoxClassNames].join(' ')}
          onClick={e => {
            e.preventDefault();
            if (triggerPicturePopup) {
              triggerPicturePopup();
            }
          }}>
          {value && value.length ? (
            <Flex direction="row" className={styles.container}>
              {value.map((elem, index) => (
                <Flex
                  direction="row"
                  key={index}
                  className={styles.fileContainer}>
                  <p className={styles.fileName}>
                    {elem.fileName ? getSubstring(elem.fileName) : ''}
                  </p>

                  <button
                    className={styles.button}
                    onClick={e => {
                      e.preventDefault();
                      handleCancel(e, index);
                    }}>
                    <p>x</p>
                  </button>
                </Flex>
              ))}
            </Flex>
          ) : (
            <p className={styles.placeholderStyles}>{placeholderText}</p>
          )}
          <div>
            <img src={File} className={styles.icon} />
          </div>
        </label>
        <input
          tabIndex={tabIndex}
          data-date-inline-picker="true"
          type="text"
          style={{visibility: 'hidden', position: 'absolute'}}
          // defaultValue={value}
          id={`uploadHTMLElement-${id}`}
          required={required}
          multiple={multiple}
          onBlur={onBlur}
          accept={getAllowedFormats()}
          ref={inputRef}
        />
      </div>
      {/* <p className={styles.error}>{error}</p> */}
      <Flex style={{width: '100%', height: '1rem'}}>
        <p className={styles.error}>{err}</p>
      </Flex>
    </div>
  );
};

export default UploadPhotoInput;
