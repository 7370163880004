import React, { Component } from 'react';
import styles from './styles.module.scss';
import PopupContainer from '../../../components/PopupContainer';
import Popup from '../../../components/SimplePopup';
import ViewRenderer from './views';
import { API } from '../../../requests';
import strings from '../../../globalization';
import Spinner from '../../../components/Spinner';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { commonImages } from '../../../assets/images';
import util from '../../../util/';
import { dispatchSetLogoutData } from '../../../store';
import { Auth } from 'aws-amplify';
const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  ERROR: 'error',
  SPINNER_POPUP: 'spinner',
};
class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timer: null,
      dashboardDetails: null,
      statusDetails: {
        personRegistrations: {
          submitted: 200,
          approved: 100,
          rejected: 50,
        },
        vehicleRegistrations: {
          submitted: 200,
          approved: 100,
          rejected: 50,
        },
        companyRegistrations: {
          submitted: 200,
          approved: 100,
          rejected: 50,
        },
      },
    };
  }

  componentDidMount() {
    this.fetchDashboard();
    this.timer = setInterval(() => {
      console.log('fetcheddata');
      this.refreshPage();
    }, 300000);
  }

  refreshPage() {
    window.location.reload(false);
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }
  handleLogout = async () => {
    const { history } = this.props;
    await Auth.signOut()
      .then(() => {
        dispatchSetLogoutData();
      })
      .catch(error => {
        console.log('error Auth.signout()', error);
      });
    API.logout()
      .then(response => {
        console.info('logout success', response);
        history.push('/u');
      })
      .catch(error => {
        console.error('logout error', error, error.request);
      });
    // history.push('/u');
  };

  fetchDashboard = () => {
    this.showSpinner();
    API.fetchDeputyDepartmentDashboard()
      .then(res => {
        this.setState({ dashboardDetails: res?.data, popup: null });
      })
      .catch(err => {
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };

  /**
   *
   * @param {*} action
   * @param {*} data
   */

  getProps = () => {
    const { statusDetails, dashboardDetails } = this.state;

    const {
      login: { loginInfo },
    } = this.props.reduxProps;
    const { company, user } = loginInfo;

    return {
      statusDetails,
      dashboardDetails,
      onClick: this.onClickHandler,
      roleId: user?.roleId,
    };
  };

  getAllAdminPortalMetadata = () => {
    return API.getAllAdminPortalMetadata()
      .then(res => {
        console.info('statusDetails:', res);
        this.setState({
          popup: undefined,
          statusDetails: res.data,
        });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        console.info('error....', errorMsg);
        this.setState({
          popup: undefined,
        });
        this.showErrorPopup(errorMsg);
      });
  };

  closePopup = () => {
    this.setState({
      popup: null,
    });
  };

  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: { color: '#E67717' },
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  onClickHandler = (action, data) => {
    const { history } = this.props;
    switch (action) {
      case 'permits': {
        //FIXME
        console.log('from add permit');
        history.push('/u/permits');
        break;
      }
      case 'profile': {
        //FIXME
        console.log('from dashboard');
        this.handleLogout();
        break;
      }
      default: {
        break;
      }
    }
  };

  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const { popup } = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.SPINNER: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const { popup } = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

const mapStateToProps = reduxProps => ({
  reduxProps,
});

const mapDispatchToProps = dispatch => ({
  // dispatchSetPersonsData: payload => {
  //   dispatch(setPersonsData(payload));
  // },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard),
);

//export default withRouter(AdminPortalDashboard);
