import React, { Component } from 'react';
import styles from './styles.module.scss';
import PopupContainer from '../../../../components/PopupContainer';
import Popup from '../../../../components/SimplePopup';
import { withRouter } from 'react-router-dom';

import {
  adminImages,
  loginImages,
  commonImages,
  operatorImages,
} from '../../../../assets/images';
import moment from 'moment';
import ViewRenderer from './views';
import AgencyRquestsPopup from '../../../../components/AgencyRquestsPopup';
import AgencyPersonDetails from '../../../../components/AgencyPersonDetails';
import { API } from '../../../../requests';
import strings from '../../../../globalization';
import Spinner from '../../../../components/Spinner';
import { tempData } from './data';
import AgencyMaterialDetails from '../../../../components/AgencyMaterialDetails';
import util from '../../../../util';
import CSVPopup from '../../../../components/CSVPopup';
import * as XLSX from 'xlsx';

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  ERROR: 'error',
  SPINNER_POPUP: 'spinner-popup',
  CSV_POPUP: 'csv-popup',
};
class PendingPermits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permits: [],
      downloadedList: [],
      CSVLoading: false,
      totalCount: 0,
      selectedStatus:
        this.props.status === 'PENDING'
          ? this.props.status
          : this.props.location?.query?.status
            ? this.props.location?.query?.status?.toUpperCase()
            : null,
      selectedRequestType: '',
      selectedDropDownStatus: null,
      startDate: moment().subtract(1, 'week').format('MM-DD-YYYY'),
      startTime: moment().startOf('day'),
      endDate: moment().add(1, 'day').format('MM-DD-YYYY'),
      endTime: moment().endOf('day'),
      // startDate: new moment().startOf('year').format('MM-DD-YYYY'),
      // endDate: new moment().add(1, 'years').format('MM-DD-YYYY'),
      dates: {
        start: moment().subtract(1, 'week').format('MM-DD-YYYY'),
        end: moment().add(1, 'day').format('MM-DD-YYYY'),
      },

      filterAgencies: [],
      searchText: '',
      perPage: 100,
      hideStatusFilter: this.props.status === 'PENDING',
      statuses: [
        {
          id: '2',
          name: 'APPROVED',
          displayName: 'APPROVED',
        },
        {
          id: '3',
          name: 'REJECTED',
          displayName: 'REJECTED',
        },
        // {
        //   id: '4',
        //   name: 'TRIP_COMPLETED',
        //   displayName: 'TRIP COMPLETED',
        // },
        {
          id: '5',
          name: 'VEHICLE_DELINKED',
          displayName: 'VEHICLE DELINKED',
        },
        {
          id: '6',
          name: 'PERSON_DELINKED',
          displayName: 'PERSON DELINKED',
        },
      ],
    };

    setTimeout(() => {
      const { statuses } = this.state;
      this.onChangeHandler(
        'statusChange',
        statuses.filter(
          option => option?.name === this.props.location?.query?.status,
        )[0],
      );
    }, 0);
  }

  getData = async (
    params,
    startDate,
    endDate,
    status,
    page,
    perPage,
    searchString,
    startTime,
    endTime,
  ) => {
    console.log('status:', status, searchString);
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
    console.log(startDate, endDate);
    let paramsList = {
      startDate: `${moment(startDate, 'MM-DD-YYYY').format(
        'DD-MM-YYYY',
      )} ${moment(startTime).format('HH:mm')}`,
      endDate: `${moment(endDate, 'MM-DD-YYYY').format('DD-MM-YYYY')} ${moment(
        endTime,
      ).format('HH:mm')}`,
      status: status,
      searchString,
      pageNumber: page,
      pageSize: perPage,
    };

    await API.getAllCompanyPermitDetailsFromView(paramsList)
      .then(resp => resp)
      .then(res => {
        console.log('response:', typeof res.data, perPage);
        params.successCallback(
          res?.data[0].vehicle,
          res?.data[0]?.totalPermitsCount ? res?.data[0]?.totalPermitsCount : 0,
        );
        this.setState({
          popup: undefined,
          permitList: res?.data[0]?.vehicle,
          totalCount: res?.data[0]?.totalPermitsCount,
        });
      })
      .catch(err => {
        console.log(err);
        params.successCallback([], 0);
        this.setState({
          popup: undefined,
        });
      });
  };

  approveAgency = async data => {
    const { id, referenceId } = data;
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
    await API.approveAgencyRequest(id)
      .then(response => {
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.STANDARD,
            heading: `${strings.ADMIN.POP_UP.agencyRequest} - ${referenceId}`,
            message: this.getSuccessMessage(referenceId),
            data: data,
            onClose: this.closePopup,
            buttonContainer: styles.buttonContainer,
            buttons: [
              {
                text: 'Okay',
                buttonStyles: styles.backButton,
                onClick: this.closePopup,
              },
            ],
          },
        });
        // const {startDate, endDate} = this.state;
        // let startDateStr = moment(startDate, 'MM-DD-YYYY').format('MM-DD-YYYY');
        // let endDateStr = moment(endDate, 'MM-DD-YYYY').format('MM-DD-YYYY');
        this.onChangeHandler('updateTable');
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        console.info('error....', errorMsg);
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.STANDARD,
            heading: `${strings.ADMIN.POP_UP.agencyRequest} - ${referenceId}`,
            message: this.getErrorMessage(referenceId),
            data: data,
            onClose: this.closePopup,
            buttonContainer: styles.buttonContainer,
            buttons: [
              {
                text: 'Okay',
                buttonStyles: styles.backButton,
                onClick: this.closePopup,
              },
            ],
          },
        });
      });
  };

  rejectReason = (referenceId, data) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.STANDARD,
        heading: `${strings.ADMIN.POP_UP.agencyRequest} - ${referenceId}`,
        message: this.getRejectMessage(),
        data: data,
        onClose: this.closePopup,
        buttonContainer: styles.buttonContainer,
        buttons: [
          {
            text: 'Back',
            buttonStyles: styles.backButton,
            onClick: this.closePopup,
          },
          {
            text: 'Reject',
            buttonStyles: styles.buttonPrimary,
            onClick: () => {
              const { referenceId } = data;
              this.rejectAgency(referenceId, data);
            },
          },
        ],
      },
    });
  };

  rejectAgency = async (referenceId, data) => {
    const { id } = data;
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });

    await API.rejectAgencyRequest(id)
      .then(response => {
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.STANDARD,
            heading: `${strings.ADMIN.POP_UP.agencyRequest} - ${referenceId}`,
            message: this.getRejectConformation(referenceId),
            data: data,
            onClose: this.closePopup,
            buttonContainer: styles.buttonContainer,
            buttons: [
              {
                text: 'Okay',
                buttonStyles: styles.backButton,
                onClick: this.closePopup,
              },
            ],
          },
        });
        // const {startDate, endDate} = this.state;
        // let startDateStr = moment(startDate, 'MM-DD-YYYY').format('MM-DD-YYYY');
        // let endDateStr = moment(endDate, 'MM-DD-YYYY').format('MM-DD-YYYY');
        this.onChangeHandler('updateTable');
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        console.info('error....', errorMsg);
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.STANDARD,
            heading: `${strings.ADMIN.POP_UP.agencyRequest} - ${referenceId}`,
            message: this.getErrorMessage(referenceId),
            data: data,
            onClose: this.closePopup,
            buttonContainer: styles.buttonContainer,
            buttons: [
              {
                text: 'Okay',
                buttonStyles: styles.backButton,
                onClick: this.closePopup,
              },
            ],
          },
        });
      });
  };
  componentDidMount() {
    this.onChangeHandler('updateTable');
  }

  getVehicleDetails = props => {
    const { vehicle } = props;
    const {
      name,
      emailAddress,
      phoneNumber,
      vehicleType,
      vehicleNumber,
      vehicleInsuranceNumber,
      vehicleEngineNumber,
      vehiclePollutionNumber,
      chassisNumber,
      vehicleInsuranceCertificate,
      fitnessCertificate,
      vehicleRegistrationNumber,
      puc,
      rfidnumber,
      rcDocument,
    } = props || {};
    return (
      <div className={styles.cardWrapper}>
        <div>
          <img
            src={adminImages.truckIconBlue}
            className={styles.truckIcon}
            alt="logo"
          />
        </div>
        <div className={styles.info}>
          <div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehicleOwnerName} :
              <span className={styles.requestLabel}> {name} </span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.ownerEmail} :
              <span className={styles.requestLabel}>{emailAddress}</span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.ownerPhoneNumber} :
              <span className={styles.requestLabel}> {phoneNumber} </span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehicleType} :
              <span className={styles.requestLabel}>{vehicleType.name}</span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.document} :
              <span className={styles.requestLabel}>
                {rcDocument ? (
                  <a
                    onClick={() =>
                      this.onClickHandler('document-view', rcDocument)
                    }>
                    {strings.ADMIN.POP_UP.vehicleRC}
                    <img
                      className={styles.documentIcon}
                      src={adminImages.documentIcon}
                    />
                  </a>
                ) : null}
              </span>
            </div>
          </div>
          <div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.RFIDTagNumber} :
              <span className={styles.requestLabel}>{rfidnumber}</span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehicleNumber} :
              <span className={styles.requestLabel}>
                {vehicleRegistrationNumber}
              </span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehicleInsuranceNumber} :
              <span className={styles.requestLabel}>
                {vehicleInsuranceNumber}
              </span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.document} :
              <span className={styles.requestLabel}>
                {vehicleInsuranceCertificate ? (
                  <a
                    onClick={() =>
                      this.onClickHandler(
                        'document-view',
                        vehicleInsuranceCertificate,
                      )
                    }>
                    {strings.ADMIN.POP_UP.vehicleInsuranceCertificate}
                    <img
                      className={styles.documentIcon}
                      src={adminImages.documentIcon}
                    />
                  </a>
                ) : null}
              </span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.document} :
              <span className={styles.requestLabel}>
                {puc ? (
                  <a onClick={() => this.onClickHandler('document-view', puc)}>
                    {strings.ADMIN.POP_UP.pUCCertificate}
                    <img
                      className={styles.documentIcon}
                      src={adminImages.documentIcon}
                    />
                  </a>
                ) : null}
              </span>
            </div>
          </div>
          <div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehiclePollutionNumber} :
              <span className={styles.requestLabel}>
                {vehiclePollutionNumber}
              </span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehicleEngineNumber} :
              <span className={styles.requestLabel}>{vehicleEngineNumber}</span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehicleChassisNumber} :
              <span className={styles.requestLabel}> {chassisNumber} </span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.document} :
              <span className={styles.requestLabel}>
                {fitnessCertificate ? (
                  <a
                    onClick={() =>
                      this.onClickHandler('document-view', fitnessCertificate)
                    }>
                    Fitness Certificate
                    <img
                      className={styles.documentIcon}
                      src={adminImages.documentIcon}
                    />
                  </a>
                ) : null}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  getRequestInfo = data => {
    const { persons } = data;
    //    const {driver} = data;
    const { vehicles } = data;
    const { material } = data;
    console.log('data... getRequestInfo', data);

    return (
      <div className={styles.requestInfo}>
        <>
          {material.map((mat, index) => {
            return (
              <>
                {' '}
                {mat?.materialTypeId === 1 || mat?.materialTypeId === 2 ? (
                  <RequestCard
                    key={'person' + index}
                    header={'Material Details' + '-' + (index + 1)}>
                    <AgencyMaterialDetails
                      onClick={this.onClickHandler}
                      {...mat}
                    />
                  </RequestCard>
                ) : null}
              </>
            );
          })}
          {persons.map((person, index) => {
            return (
              <RequestCard
                header={strings.ADMIN.POP_UP.personDetails + '-' + (index + 1)}>
                <AgencyPersonDetails
                  onClick={this.onClickHandler}
                  {...person}
                />
              </RequestCard>
            );
          })}

          {vehicles.map((vehicle, index) => {
            return (
              <>
                <RequestCard
                  key={'vehicle' + index}
                  header={
                    strings.ADMIN.POP_UP.vehicleDetails + '-' + (index + 1)
                  }>
                  {this.getVehicleDetails(vehicle?.vehicle)}
                </RequestCard>
                {vehicle?.driver ? (
                  <RequestCard
                    header={
                      strings.ADMIN.POP_UP.driverDetails + '-' + (index + 1)
                    }>
                    <AgencyPersonDetails
                      onClick={this.onClickHandler}
                      {...vehicle?.driver}
                    />
                  </RequestCard>
                ) : null}
              </>
            );
          })}
          {data.status && data?.status === 'REJECTED' && data?.reason ? (
            <div className={styles.rejectContainer}>
              <p className={styles.rejectReasonStyles}>
                Reason for rejection remarks: <span>{data?.reason}</span>
              </p>
            </div>
          ) : null}
        </>
      </div>
    );
  };

  getErrorMessage = referenceId => {
    return (
      <div className={styles.requestSuccess}>
        <div>
          <img
            src={commonImages.errorIcon}
            className={styles.successIcon}
            alt="logo"
          />
        </div>
        <div className={styles.errorMsg}>
          {strings.ADMIN.POP_UP.errorMessage}
        </div>
      </div>
    );
  };

  getSuccessMessage = referenceId => {
    return (
      <div className={styles.requestSuccess}>
        <div>
          <img
            src={loginImages.successIcon}
            className={styles.successIcon}
            alt="logo"
          />
        </div>
        <div className={styles.successMessage}>
          {strings.ADMIN.POP_UP.approveCnf1} {referenceId}
          {strings.ADMIN.POP_UP.approveCnf2}
        </div>
      </div>
    );
  };

  getRejectMessage = () => {
    return (
      <div className={styles.requestReject}>
        <div className={styles.rejectMessage}>
          {strings.ADMIN.POP_UP.toReject}
        </div>
        <textarea
          placeholder={'Type here...'}
          className={styles.popupTextarea}
          required
        />
      </div>
    );
  };
  getRejectConformation = referenceId => {
    return (
      <div className={styles.requestReject}>
        <div className={styles.rejectMessage}>
          {strings.ADMIN.POP_UP.rejectCnf1} {referenceId}
          {strings.ADMIN.POP_UP.rejectCnf2}
        </div>
        {/* <textarea
          placeholder={"Type here"}
          className={styles.popupTextarea}
          required
        /> */}
      </div>
    );
  };

  onChangeHandler = (action, data) => {
    console.log(data, action);
    switch (action) {
      case 'statusChange': {
        console.log(data);
        if (data) {
          this.setState({
            selectedStatus: data?.name,
            selectedDropDownStatus: data,
          });
        }
        break;
      }

      case 'date': {
        const { startDate, endDate, dates } = data;
        this.setState({
          startDate,
          endDate,
          dates,
        });
        break;
      }

      case 'search': {
        console.info('data..', data);
        this.setState({
          searchText: data,
        });
        break;
      }
      case 'gridAPI': {
        console.log('grid API', data);
        this.setState({ gridApi: data });
        this.onChangeHandler('updateTable');
        break;
      }
      case 'updateTable': {
        const {
          gridApi,
          selectedStatus,
          startDate,
          endDate,
          searchText,
          perPage,
          startTime,
          endTime,
        } = this.state;
        this.setState({ downloadedList: [] });
        if (gridApi) {
          const dataSource = {
            getRows: params => {
              const page = params.endRow / perPage;
              console.log(page, params, selectedStatus);
              this.getData(
                params,
                startDate,
                endDate,
                selectedStatus,
                page - 1,
                perPage,
                searchText,
                startTime,
                endTime,
              );
            },
          };
          gridApi.setDatasource(dataSource);
        }
        break;
      }
      case 'startTime': {
        this.setState({
          startTime: data,
        });
        break;
      }
      case 'endTime': {
        this.setState({
          endTime: data,
        });
        break;
      }
      default: {
        console.info('case not handled', action);
      }
    }
  };

  /**
   *
   * @param {*} action
   * @param {*} data
   */

  onClickHandler = (action, data) => {
    const { history, location, status } = this.props;
    console.log('action...', action, 'data...', data);
    switch (action) {
      case 'signin': {
        history.push('/sign-in');
        break;
      }
      case 'vehicle-registration': {
        history.push('/vehicle-registration');
        break;
      }
      case 'apply': {
        const { startDate, endDate } = this.state;
        let startDateStr = moment(startDate, 'MM-DD-YYYY').format('MM-DD-YYYY');
        let endDateStr = moment(endDate, 'MM-DD-YYYY').format('MM-DD-YYYY');
        // this.getData(
        //   startDateStr,
        //   endDateStr,
        //   true,
        //   status === 'PENDING' ? status : null,
        // );
        this.onChangeHandler('updateTable');
        break;
      }
      case 'clearFilters': {
        this.resetData();
        break;
      }
      case 'rowClick': {
        console.info('data', data);
        const { permitId } = data;
        this.rowClickData(permitId);
        break;
      }
      case 'statusRoute': {
        //FIXME
        history.push({
          pathname: '/u/permits/' + data.route,
        });

        break;
      }
      case 'document-view': {
        history.push({
          pathname: location.pathname + '/document-view',
          state: { background: location, url: data },
        });
        break;
      }
      case 'delink': {
        this.handleDelink(data);
        break;
      }
      case 'change-driver': {
        history.push({
          pathname: '/u/permits/approved-rejected-permits/change-driver',
          state: {
            driver: data.vehicles[0].driver,
            material: data.material[0],
            vehicle: data.vehicles[0].vehicle,
            permit: data,
            background: location,
          },
        });
        break;
      }
      case 'print-permit': {
        console.log('printPermit', 'data:', data);
        history.push({
          pathname:
            '/u/permits/approved-rejected-permits/print-permit/' +
            data.referenceId,
          state: {
            // driver: data.vehicles[0].driver,
            // driver:
            //   data.vehicles.length === 0 && data.vehicles[0] === null
            //     ? ''
            //     : data.vehicles[0].driver === null
            //     ? ''
            //     : data.vehicles[0].driver,
            // material: data.material[0] === null ? '' : data.material[0],
            // vehicle:
            //   data.vehicles[0] === null
            //     ? ''
            //     : data.vehicles[0].vehicle === null
            //     ? ''
            //     : data.vehicles[0].vehicle,
            // driver:
            //   data.vehicles.length > 0
            //     ? data.vehicles[0].driver
            //     : data.persons[0].person,
            // material: data.material.length > 0 ? data.material[0] : null,
            // vehicle: data.vehicles.length > 0 ? data.vehicles[0].vehicle : null,
            // permit: data,
            background: location,
          },
        });

        break;
      }
      case 'loadCSV': {
        this.getCSVData();
        break;
      }

      default: {
        console.info('case not handled', action);
      }
    }
  };

  rowClickData = id => {
    this.showSpinner();
    API.fetchPermitDetailsForViews(id)
      .then(res => {
        console.log('response:', res);
        const { referenceId } = res?.data?.permitInfo;
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.STANDARD,
            heading: `${strings.ADMIN.POP_UP.agencyRequest} - ${referenceId}`,
            message: this.getRequestInfo(res?.data?.permitInfo),
            onClose: this.closePopup,
            data: res?.data?.permitInfo,
            buttonContainer: styles.buttonContainer,
          },
        });
      })
      .catch(err => {
        console.log('err', err);
        console.log(err);
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  delink = data => {
    this.showSpinner();
    const { permitId, id, persons, requestType, referenceId } = data;
    console.log('data.....', data);
    let msg = 'Vehicle Delinked Successfully!';
    let type = 'vehicle';
    if (requestType === 'person') {
      msg = 'Person Delinked Successfully!';
      type = 'person';
    }

    API.delink({ referenceId: referenceId, type: type })
      .then(res => {
        this.showSuccessPopup(msg, () => this.onChangeHandler('updateTable'));
      })
      .catch(err => {
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };

  handleDelink = data => {
    const { persons, requestType } = data;
    let msg =
      'The vehicle gets unblocked with the permit. Are you sure you want to Delink?';
    if (requestType === 'person') {
      msg =
        'The person gets unblocked with the permit. Are you sure you want to Delink?';
    }
    this.showConfirmationPopup(msg, () => this.delink(data));
  };
  showConfirmationPopup = (message, onConfirm, data) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: message,
        messageStyle: { color: '#E67717' },
        // headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.cancelPopup,
            onClick: this.closePopup,
            outline: true,
          },
          {
            text: strings.confirm,
            onClick: onConfirm ? onConfirm : this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  showCSVPopup = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.CSV_POPUP,
      },
    });
  };

  showSuccessPopup = successMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: successMessage,
        onClose: this.closePopup,
        headingImage: operatorImages.successIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: { color: '#E67717' },
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  resetData = () => {
    const { status } = this.props;
    this.setState({
      searchText: '',
      startDate: moment().subtract(1, 'week').format('MM-DD-YYYY'),
      startTime: moment().startOf('day'),
      endDate: moment().add(1, 'day').format('MM-DD-YYYY'),
      endTime: moment().endOf('day'),
      dates: {
        start: moment().subtract(1, 'week').format('MM-DD-YYYY'),
        end: moment().add(1, 'day').format('MM-DD-YYYY'),
      },
      selectedDropDownStatus: null,
      selectedStatus:
        this.props.status === 'PENDING' ? this.props.status : null,
    });
    // let yearEndDate = new moment(new Date(), 'MM-DD-YYYY')
    //   .add(1, 'years')
    //   .format('MM-DD-YYYY');
    // let yearStartDate = new moment(new Date(), 'MM-DD-YYYY')
    //   .startOf('year')
    //   .format('MM-DD-YYYY');
    // this.getData(
    //   yearStartDate,
    //   yearEndDate,
    //   true,
    //   status === 'PENDING' ? status : null,
    // );
    this.onChangeHandler('updateTable');
  };

  getCSVDownloadHeaders = () => {
    return [
      { label: 'Reference Number', key: 'referenceId' },
      { label: 'Request Type ', key: 'requestType' },
      { label: 'Work Purpose Name', key: 'workPurposeName' },
      { label: 'Vehicle Registration Number', key: 'vehicleRegistrationNumber' },
      { label: 'Requested Date  ', key: 'createdDate' },
      { label: 'Status ', key: 'status' },
    ];
  };

  downloadExcel = data => {
    const updatedData = data.map(item => {
      console.log(item);
      // referenceId: el.referenceId,
      // requestType: requestType,
      // workPurposeName: el.workPurposeName,
      // vehicleRegistrationNumber: vehicleRegistrationNumber,
      // status: el.status,
      // visitingDate: moment(el.visitingDate).format('MMMM Do YY'),
      // error: el.error,
      // createdDate: moment(el.createdDate).format('MMMM Do YY HH:mm'),

      return {
        'Reference Number': item?.referenceId,
        'Request Type': item?.requestType,
        'Vehicle Registration Number': item?.vehicleRegistrationNumber,
        'Vehicle Owner Name': item?.vehicleOwnerName,
        'Request Date': item?.createdDate,
        'Visit Date': item?.visitingDate,
        'Purpose of Work': item?.workPurposeName,
      };
    });
    console.log(updatedData);
    const worksheet = XLSX.utils.json_to_sheet(updatedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `permits-${moment().format(`DD-MM-YYYY`)}.xlsx`);
  };

  getCSVData = async () => {
    this.showSpinner();
    const {
      startDate,
      endDate,
      startTime,
      endTime,
      selectedStatus,
      searchText,
      totalCount,
    } = this.state;
    this.setState({ CSVLoading: false, downloadedList: [] });
    if (!startTime || !endTime || !startDate || !endDate) {
      this.showErrorPopup(`Date and Time parameters cannot be empty!`);
      return;
    }
    console.log(startDate, endDate);
    let params = {
      startDate: `${moment(startDate, 'MM-DD-YYYY').format(
        'DD-MM-YYYY',
      )} ${moment(startTime).format('HH:mm')}`,
      endDate: `${moment(endDate, 'MM-DD-YYYY').format('DD-MM-YYYY')} ${moment(
        endTime,
      ).format('HH:mm')}`,
      status: selectedStatus,
      searchString: searchText,
      pageNumber: 0,
      pageSize: totalCount + 100,
    };
    console.log('params...', params);
    API.getAllCompanyPermitDetailsForCSV(params)
      .then(res => {
        console.log('response:', res);

        this.setState({
          downloadedList: res?.data[0]?.vehicle,
          popup: undefined,
          totalCount: res?.data[0]?.totalPermitsCount,
          CSVLoading: true,
        });
        const data = this.getCSVDownloadData();
        console.log('about to download:', data);
        this.downloadExcel(data);
        // this.showCSVPopup();
        // this.getCSVDownloadData();
      })
      .catch(err => {
        console.log('err', err);
        console.log(err);
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
        this.setState({ CSVLoading: false });
      });
  };

  getCSVDownloadData = () => {
    const { downloadedList } = this.state;

    console.log('downloadedList:', downloadedList);
    return downloadedList.map(el => {
      const { persons, vehicles, requestType } = el;
      // const requestType =
      //   persons.length > 0
      //     ? 'person'
      //     : '' + persons.length > 0 && vehicles.length > 0
      //     ? ','
      //     : '' + vehicles.length > 0
      //     ? 'vehicle'
      //     : '';
      const vehicleRegistrationNumber = el?.vehicleRegistrationNumber
        ? el?.vehicleRegistrationNumber
        : '-';
      const vehicleOwnerName = el?.vehicleOwnerName
        ? el?.vehicleOwnerName
        : '-';
      return {
        referenceId: el.referenceId,
        requestType: requestType,
        workPurposeName: el.workPurpose,
        vehicleRegistrationNumber: vehicleRegistrationNumber,
        vehicleOwnerName: vehicleOwnerName,
        status: el.status,
        visitingDate: moment(el.visitingDate).format('MMMM Do YY'),
        error: el.error,
        createdDate: moment(el.createdDate).format('MMMM Do YY HH:mm'),
      };
    });
  };

  getProps = () => {
    const {
      statuses,
      permitList,
      searchText,
      selectedRequestType,
      selectedStatus,
      startDate,
      endDate,
      dates,
      perPage,
      selectedDropDownStatus,
      hideStatusFilter,
      CSVLoading,
      downloadedList,
      startTime,
      endTime,
      totalCount,
    } = this.state;
    return {
      onClick: this.onClickHandler,
      onChange: this.onChangeHandler,
      statuses,
      searchText,
      selectedRequestType,
      selectedDropDownStatus,
      selectedStatus,
      startDate,
      endDate,
      dates,
      perPage,
      hideStatusFilter,
      permitList: permitList,
      downloadedList,
      downloadData: this.getCSVDownloadData(),
      downloadHeaders: this.getCSVDownloadHeaders(),
      CSVLoading,
      startTime,
      endTime,
      totalCount,
    };
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };
  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const { popup } = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.STANDARD: {
        return <AgencyRquestsPopup {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.CSV_POPUP: {
        return (
          <CSVPopup
            data={this.getCSVDownloadData()}
            headers={this.getCSVDownloadHeaders()}
            onClose={this.closePopup}
          />
        );
      }
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const { popup } = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

const RequestCard = props => {
  const { header } = props;

  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardheader}>{header}</div>
      {props.children}
    </div>
  );
};

export default withRouter(PendingPermits);
