import moment from 'moment';
import ALLOWED_FORMATS from '../../../constants/allowed-formats';
import strings from '../../../globalization';

const formLeftFields = [
  {
    field: 'departmentId',
    elementType: 'dropdown',
    inputType: 'dropdown',
    optionsField: 'optionsField',
    labelFn: () => `${strings.department}`,
    label: strings.department,
    placeholder: () => `${strings.selectDepartment}`,
    required: true,
    stateField: 'departmentId',
    stateDefault: null,
    maxLength: 20,
  },
  {
    elementType: 'phone',
    inputType: 'phone',
    labelFn: () => `${strings.agencyMobileNumber}`,
    label: strings.agencyMobileNumber,
    placeholder: () => `${strings.agencyMobileNumberPlaceholder}`,
    required: true,
    stateField: 'phoneNumber',
    stateDefault: '',
    maxLength: 10,
    verifyOtp: true,
  },

  // {
  //   field: 'licenseNumber',
  //   elementType: 'text',
  //   type: 'text',
  //   labelFn: () => `${strings.licenseNo}`,
  //   label: strings.licenseNo,
  //   placeholder: () => `${strings.selectLicenseNumber}`,
  //   required: true,
  //   inputType: 'license',
  //   stateField: 'licenseNumber',
  //   stateDefault: null,
  //   minLength: 15,
  //   maxLength: 128,
  //   uppercase: true,
  // },
  {
    field: 'businessId',
    elementType: 'dropdown',
    inputType: 'text',
    labelFn: () => `${strings.natureOfBusiness}`,
    label: strings.natureOfBusiness,
    placeholder: () => `${strings.selectNatureOfBussiness}`,
    required: true,
    optionsField: 'optionsField',
    stateField: 'businessId',
    stateDefault: null,
    maxLength: 20,
  },
  {
    field: 'address1',
    labelFn: () => `${strings.address1}`,
    label: strings.address1,
    placeholder: () => `${strings.enterAddress}`,
    required: true,
    inputType: 'text',
    elementType: 'text',
    stateField: 'address1',
    stateDefault: null,
    minLength: 5,
    maxLength: 200,
  },

  {
    field: 'registrationProof',
    type: 'file',
    labelFn: () => `${strings.companyRegistrationProof}`,
    label: strings.companyRegistrationProof,
    placeholder: () => `${strings.chooseFile}`,
    required: true,
    inputType: 'upload',
    elementType: 'file',
    stateField: 'registrationProof',
    // FIXME
    stateDefault: [],
    maxFiles: 1,
    allowedFormats: [ALLOWED_FORMATS.PDF],
  },
  {
    field: 'gstDocument',
    type: 'file',
    labelFn: () => `${strings.gstDocument}`,
    label: strings.gstDocument,
    placeholder: () => `${strings.chooseFile}`,
    required: true,
    inputType: 'upload',
    elementType: 'file',
    stateField: 'gstDocument',
    // FIXME
    stateDefault: [],
    maxFiles: 1,
    allowedFormats: [ALLOWED_FORMATS.PDF],
  },
  {
    field: 'PANDocument',
    type: 'file',
    labelFn: () => `${strings.PANDocument}`,
    label: strings.PANDocument,
    placeholder: () => `${strings.enterPANDocument}`,
    required: true,
    inputType: 'upload',
    elementType: 'file',
    stateField: 'PANDocument',
    // FIXME
    stateDefault: [],
    maxFiles: 1,
    allowedFormats: [ALLOWED_FORMATS.PDF],
  },
];
const formRightFields = [
  {
    elementType: 'text',
    inputType: 'agencyName',
    labelFn: () => `${strings.agencyName}`,
    label: strings.agencyName,
    placeholder: () => `${strings.enterAgencyName}`,
    required: true,
    stateField: 'name',
    stateDefault: null,
    minLength: 6,
    maxLength: 128,
  },
  {
    elementType: 'phone',
    inputType: 'phone',
    labelFn: () => `${strings.agencyLandlineNumber}`,
    label: strings.agencyLandlineNumber,
    placeholder: () => `${strings.agencyLandlineNumberPlaceholder}`,
    required: false,
    stateField: 'agencyLandlineNumber',
    stateDefault: '',
    verifyOtp: false,
    maxLength: 10,
  },
  {
    labelFn: () => `${strings.agencyEmailAddress}`,
    label: strings.agencyEmailAddress,
    placeholder: () => `${strings.enterEmailAddress}`,
    inputType: 'email',
    required: true,
    elementType: 'text',
    stateField: 'emailAddress',
    stateDefault: null,
    maxLength: 50,
  },
  // {
  //   elementType: 'date',
  //   labelFn: () => `${strings.licenseValidUpto}`,
  //   label: strings.licenseValidUpto,
  //   placeholder: () => `${strings.selectDate}`,
  //   required: true,
  //   inputType: 'date',
  //   stateField: 'licenseValidity',
  //   stateDefault: null,
  //   maxLength: 20,
  //   minimumDate: new Date(),
  // },

  {
    inputType: 'text',
    elementType: 'text',
    labelFn: () => `${strings.address2}`,
    label: strings.address2,
    placeholder: () => `${strings.enterAddress}`,
    required: false,
    stateField: 'address2',
    stateDefault: null,
    minLength: 5,
    maxLength: 200,
  },

  {
    labelFn: () => `${strings.gstNumber}`,
    label: strings.gstNumber,
    placeholder: () => `${strings.enterGSTNumber}`,
    required: true,
    inputType: 'gst',
    elementType: 'text',
    stateField: 'GSTNumber',
    stateDefault: null,
    minLength: 15,
    maxLength: 16,
    uppercase: true,
  },
  {
    field: 'PANNumber',
    elementType: 'text',
    type: 'text',
    labelFn: () => `${strings.PANNumber}`,
    label: strings.PANNumber,
    placeholder: () => `${strings.enterPANNumber}`,
    required: true,
    inputType: 'alphanumeric',
    stateField: 'PANNumber',
    stateDefault: null,
    minLength: 10,
    maxLength: 10,
    uppercase: true,
  },
];

const getSignatoryConfig = () => {
  return [
    {
      id: 1,
      title: strings.signatory1,
      required: true,
      formDetails: [
        {
          elementType: 'text',
          inputType: 'name',
          label: strings.name,
          placeholder: strings.namePlaceholder,
          required: true,
          stateField: 'name0',
          stateDefault: '',
          minLength: 5,
          maxLength: 128,
        },
        {
          elementType: 'text',
          inputType: 'name',
          label: `${strings.designation}`,
          placeholder: `${strings.designationPlaceholder}`,
          required: true,
          stateField: 'designation0',
          stateDefault: null,
          minLength: 5,
          maxLength: 20,
        },
        {
          elementType: 'phone',
          inputType: 'phone',
          label: strings.mobileNumber,
          placeholder: strings.mobileNumberPlaceholder,
          required: true,
          stateField: 'phoneNumber0',
          verifyOtp: false,
          stateDefault: '',
          maxLength: 10,
        },
        {
          elementType: 'text',
          inputType: 'email',
          label: strings.emailAddress,
          placeholder: strings.emailAddressPlaceholder,
          required: true,
          stateField: 'emailAddress0',
          stateDefault: '',
          maxLength: 128,
        },
        {
          field: 'authorizedDocument0',
          type: 'file',
          labelFn: () => `${strings.authorizedDocument}`,
          label: strings.authorizedDocument,
          placeholder: strings.chooseFile,
          required: true,
          inputType: 'upload',
          elementType: 'file',
          stateField: 'authorizedDocument0',
          // FIXME
          stateDefault: [],
          maxFiles: 1,
          allowedFormats: [ALLOWED_FORMATS.PDF],
        },
        {
          elementType: 'date',
          labelFn: () => `${strings.documentValiditySignatory}`,
          label: strings.documentValiditySignatory,
          placeholder: strings.selectDate,
          required: false,
          inputType: 'date',
          stateField: 'documentValidity0',
          stateDefault: null,
          maxLength: 20,
          minimumDate: new Date(),
        },
        {
          field: 'identityProofDocument0',
          type: 'file',
          labelFn: () => `${strings.identityProofDocument}`,
          label: strings.identityProofDocument,
          placeholder: strings.chooseFile,
          required: true,
          inputType: 'upload',
          elementType: 'file',
          stateField: 'identityProofDocument0',
          // FIXME
          stateDefault: [],
          maxFiles: 1,
          allowedFormats: [ALLOWED_FORMATS.PDF],
        },
      ],
    },
    {
      id: 2,
      title: strings.signatory2,
      required: true,
      formDetails: [
        {
          elementType: 'text',
          inputType: 'name',
          label: strings.name,
          placeholder: strings.namePlaceholder,
          required: true,
          stateField: 'name1',
          stateDefault: '',
          minLength: 5,
          maxLength: 128,
        },
        {
          elementType: 'text',
          inputType: 'name',
          label: `${strings.designation}`,
          placeholder: `${strings.designationPlaceholder}`,
          required: true,
          stateField: 'designation1',
          stateDefault: null,
          minLength: 5,
          maxLength: 20,
        },
        {
          elementType: 'phone',
          inputType: 'phone',
          label: strings.mobileNumber,
          placeholder: strings.mobileNumberPlaceholder,
          required: true,
          stateField: 'phoneNumber1',
          stateDefault: '',
          maxLength: 10,
          verifyOtp: false,
        },
        {
          elementType: 'text',
          inputType: 'email',
          label: strings.emailAddress,
          placeholder: strings.emailAddressPlaceholder,
          required: true,
          stateField: 'emailAddress1',
          stateDefault: '',
          maxLength: 128,
        },
        {
          field: 'authorizedDocument1',
          type: 'file',
          labelFn: () => `${strings.authorizedDocument}`,
          label: strings.authorizedDocument,
          placeholder: strings.chooseFile,
          required: true,
          inputType: 'upload',
          elementType: 'file',
          stateField: 'authorizedDocument1',
          // FIXME
          stateDefault: [],
          maxFiles: 1,
          allowedFormats: [ALLOWED_FORMATS.PDF],
        },
        {
          elementType: 'date',
          labelFn: () => `${strings.documentValiditySignatory}`,
          label: strings.documentValiditySignatory,
          placeholder: strings.selectDate,
          required: false,
          inputType: 'date',
          stateField: 'documentValidity1',
          stateDefault: null,
          maxLength: 20,
          minimumDate: new Date(),
        },
        {
          field: 'identityProofDocument1',
          type: 'file',
          labelFn: () => `${strings.identityProofDocument}`,
          label: strings.identityProofDocument,
          placeholder: strings.chooseFile,
          required: true,
          inputType: 'upload',
          elementType: 'file',
          stateField: 'identityProofDocument1',
          // FIXME
          stateDefault: [],
          maxFiles: 1,
          allowedFormats: [ALLOWED_FORMATS.PDF],
        },
      ],
    },
    {
      id: 3,
      title: strings.signatory3,
      required: false,
      formDetails: [
        {
          elementType: 'text',
          inputType: 'name',
          label: strings.name,
          placeholder: strings.namePlaceholder,
          required: false,
          stateField: 'name2',
          stateDefault: '',
          minLength: 5,
          maxLength: 128,
        },
        {
          inputType: 'name',
          elementType: 'text',
          label: `${strings.designation}`,
          placeholder: `${strings.designationPlaceholder}`,
          required: false,
          stateField: 'designation2',
          stateDefault: null,
          minLength: 5,
          maxLength: 20,
        },
        {
          elementType: 'phone',
          inputType: 'phone',
          label: strings.mobileNumber,
          placeholder: strings.mobileNumberPlaceholder,
          required: false,
          stateField: 'phoneNumber2',
          stateDefault: '',
          maxLength: 10,
          verifyOtp: false,
        },
        {
          elementType: 'text',
          inputType: 'email',
          label: strings.emailAddress,
          placeholder: strings.emailAddressPlaceholder,
          required: false,
          stateField: 'emailAddress2',
          stateDefault: '',
          maxLength: 128,
        },

        {
          field: 'authorizedDocument2',
          type: 'file',
          labelFn: () => `${strings.authorizedDocument}`,
          label: strings.authorizedDocument,
          placeholder: strings.chooseFile,
          required: false,
          inputType: 'upload',
          elementType: 'file',
          stateField: 'authorizedDocument2',
          // FIXME
          stateDefault: [],
          maxFiles: 1,
          allowedFormats: [ALLOWED_FORMATS.PDF],
        },
        {
          elementType: 'date',
          labelFn: () => `${strings.documentValiditySignatory}`,
          label: strings.documentValiditySignatory,
          placeholder: strings.selectDate,
          required: false,
          inputType: 'date',
          stateField: 'documentValidity2',
          stateDefault: null,
          maxLength: 20,
          minimumDate: new Date(),
        },
        {
          field: 'identityProofDocument2',
          type: 'file',
          labelFn: () => `${strings.identityProofDocument}`,
          label: strings.identityProofDocument,
          placeholder: strings.chooseFile,
          required: false,
          inputType: 'upload',
          elementType: 'file',
          stateField: 'identityProofDocument2',
          // FIXME
          stateDefault: [],
          maxFiles: 1,
          allowedFormats: [ALLOWED_FORMATS.PDF],
        },
      ],
    },
  ];
};

export const termsData = [
  {
    title: 'DEFINITIONS',
    content: '"Agreement" shall mean the terms and conditions of use.',
  },
  {
    title: '',
    content:
      '"Application" shall mean the application [e-Drishti] supplied by the Company and downloaded and installed by you on your mobile device, tablet, laptop, computer, etc.',
  },
  {
    title: '',
    content:
      '"Company", "we", "our" and "us" shall mean-- KR mroads India Pvt. Ltd.',
  },
  {
    title: '',
    content:
      '"You" shall mean You, the User of the Website/ Application and "Yourself" interpreted accordingly. ',
  },
  {
    title: '',
    content:
      '"Users" means the Users of the Website/ application collectively and/or individually as the context allows.',
  },
  {
    title: 'Acknowledgement',
    content:
      'Visitors to this website- www.sanya.ai/edrishti are bound by the following terms and conditions ("terms").',
  },
  {
    title: '',
    content:
      'If you do not agree with any of these terms, please do not proceed further with using the site.',
  },
  {
    title: '',
    content:
      'Your access to and use of the services in this website is also conditioned on your acceptance of and compliance with the privacy policy of the website.',
  },
  {
    title: '',
    content:
      'As a precondition to your use of the Services, you represent and warrant that:',
  },
  {
    title: '',
    content: 'The information you provide is accurate and complete.',
  },
  {
    title: '',
    content:
      'The Company (KR mroads India Pvt. Ltd.) is entitled, at all times, to verify the information that you have provided and to refuse the Services or use of the Application without providing any reasons whatsoever.',
  },
  {
    title: '',
    content:
      'You will only use our Services using authorized means. You are responsible to check and ensure you download the correct Application for your device. We will not be liable if you do not have a compatible device or if you download the wrong version of the Application for your device. The Company reserves the right to terminate.',
  },
  {
    title: '',
    content:
      'You specifically authorize us to use, store or otherwise process your data or information (in order to provide the Services to you.)',
  },
  {
    title: '',
    content:
      'You will obey all applicable laws related to the matters set forth herein, and will be solely responsible for any violations of the same.',
  },
  {
    title: '',
    content:
      'You agree that the information or data you provide in the form will or may be subjected to additional scrutiny or validation to prove authenticity. ',
  },
  {
    title: '',
    content:
      'You agree that upon agreement of the form, you will be redirected to the payment gateway and No Refunds will be given except in the event of non-performance or inexplicable errors by/from/of any service by www.sanya.ai/edrishti ',
  },
  {
    title: '',
    content:
      'You agree to receive recurring SMS/Email messages with service-related messages, including updates, alerts, and information.',
  },
];

export {formLeftFields, formRightFields, getSignatoryConfig};
