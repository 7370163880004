import {REHYDRATE} from 'redux-persist';
import {ActionTypes} from '../constants';

const defaults = {
  persons: [],
  vehicles: [],
};

export interface CacheState {}

export interface ActionType {
  type: string;
  payload: any;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaults, action: ActionType) => {
  switch (action.type) {
    case ActionTypes.SET_VEHICLE_DATA: {
      return {
        ...state,
        vehicles: state.vehicles.concat(action.payload),
      };
    }
    case ActionTypes.SET_PERSONS_DATA: {
      console.log('Set persons data', action.payload);
      return {
        ...state,
        persons: state.persons.concat(action.payload),
      };
    }

    case ActionTypes.CLEAR_PERSONS_VEHICLES_DATA: {
      console.log('clear persons vehicles data', action.payload);
      return {
        ...state,
        persons: [],
        vehicles: [],
      };
    }
    case ActionTypes.DELETE_VEHICLE: {
      console.log('delete vehicle', action.payload);
      const updatedVehicles = state.vehicles.filter(
        elem => elem.vehicle.id !== action.payload,
      );
      return {
        ...state,
        vehicles: updatedVehicles,
      };
    }

    case ActionTypes.DELETE_MATERIAL: {
      console.log('delete material', action.payload);
      let updatedVehicles = state.vehicles.map(elem => {
        if (elem.material && elem.material.id === action.payload) {
          //material found, make it undefined
          elem.material = undefined;
        }
        return elem;
      });
      return {
        ...state,
        vehicles: updatedVehicles,
      };
    }
    case ActionTypes.DELETE_PERSON: {
      console.log('person deleting...', action.payload);
      const updatedPersons = state.persons.filter(
        elem => elem.id !== action.payload,
      );
      return {
        ...state,
        persons: updatedPersons,
      };
    }

    case ActionTypes.UPDATE_MATERIAL: {
      console.log('Updating material redux...', action.payload);
      let requiredVehicleObject = state.vehicles.find(
        vArr => action.payload.vehicleId === vArr.vehicle.id,
      );

      let filteredVehicles = state.vehicles.filter(
        vArr => action.payload.vehicleId !== vArr.vehicle.id,
      );

      const rvObj = {
        ...requiredVehicleObject,
        material: action.payload,
      };

      filteredVehicles.push(rvObj);
      return {
        ...state,
        vehicles: [...filteredVehicles],
      };
    }

    default:
      return {...state};
  }
};
