import React, { useEffect, useState } from 'react';
import styles from './Popup.module.scss';
import { adminImages, loginImages } from '../../assets/images';
import Flex from '../Flex';
import OtpInput from 'react-otp-input';
import { useMediaQuery } from 'react-responsive';
import { API } from '../../requests';
import Webcam from 'react-webcam';
import CustomUpload from '../../components/CustomUpload';
import ALLOWED_FORMATS from '../../constants/allowed-formats';
import ReactTooltip from 'react-tooltip';

type Button = {
  text: String,
  onClick: () => void,
  className?: String,
};

type Props = {
  message: String,
  onClose?: () => void,
  error?: Boolean,
  success?: Boolean,
  buttons?: Button[],
  heading?: String,
};

function TakePicture(props) {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { onSaveCameraPicture, onClose, handleUpload, showError } = props;

  const [image, setImage] = useState('');
  const [isReady, setIsReady] = useState(false);
  const [fileData, setFileData] = useState('');
  const [showCamera, setShowCamera] = useState(false);

  console.log('isReady...', isReady);

  const CustomUploadButton = () => {
    return (
      <CustomUpload
        labelText={''}
        placeholderText={'Upload your picture'}
        placeholderStyle={{ color: 'white' }}
        required={false}
        handleUpload={handleUpload}
        value={''}
        maxFiles={1}
        allowedFormats={[ALLOWED_FORMATS.IMAGES]}
        uploadBoxClassNames={[styles.uploadBoxClassNames].join(' ')}
        multiple={false}
        isPhoto={true}
      />
    );
  };

  const WebcamCapture = () => {
    const webcamRef = React.useRef(null);

    const capture = React.useCallback(() => {
      const imageSrc = webcamRef.current.getScreenshot();
      console.log(imageSrc);
      if (imageSrc) {
        setImage(imageSrc);
      }
    }, [webcamRef]);

    const convertToFile = () => {
      const url = image;
      fetch(url)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], 'image-' + new Date().getTime() + '.png', {
            type: 'image/png',
          });
          setFileData(file);
          onSaveCameraPicture(file);
        });
    };

    return (
      <Flex>
        <div className={styles.webcamContainer}>
          <div>
            {image == '' ? (
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={320}
                onUserMedia={() => setIsReady(true)}
                onUserMediaError={() => showError && showError()}
              />
            ) : (
              <img src={image} />
            )}
          </div>

          <Flex className={styles.iconsContainer}>
            <ReactTooltip
              html={true}
              className={styles.toolTip}
              place="top"
              type="info"
              effect="solid"
              multiline={true}
            />
            {image != '' ? (
              <img
                onClick={e => {
                  e.preventDefault();
                  setImage('');
                }}
                alt="U"
                data-tip={'Retake picture'}
                className={styles.tickIcon}
                src={adminImages.retakeIcon}
              />
            ) : null}
            {image != '' ? (
              <img
                onClick={convertToFile}
                data-tip={'Save picture'}
                className={styles.retakeIcon}
                src={adminImages.tickIcon}
                alt={'U'}
              />
            ) : (
              <div style={{ margin: '0 auto' }}>
                {isReady && (
                  <img
                    data-tip={'Take picture'}
                    onClick={e => {
                      e.preventDefault();
                      capture();
                    }}
                    alt="U"
                    className={styles.captureIcon}
                    src={adminImages.captureIcon}
                  />
                )}
              </div>
            )}
          </Flex>
        </div>
      </Flex>
    );
  };
  return (
    <div className={styles.container}>
      {onClose ? (
        <img
          alt="close"
          src={adminImages.closeIcon}
          className={styles.close}
          onClick={onClose}
        />
      ) : null}
      {showCamera ? (
        <div
          className={styles.back}
          onClick={e => {
            e.preventDefault();
            setShowCamera(!showCamera);
          }}>
          <img src={loginImages.backButtonIcon} />
          back
        </div>
      ) : null}
      <div className={styles.heading}>Individual Photo</div>

      <div className={styles.bodyContainer}>
        {showCamera ? (
          <WebcamCapture />
        ) : (
          <Flex className={styles.buttonContainer}>
            <CustomUploadButton />
            <button
              className={styles.button}
              onClick={e => {
                e.preventDefault();
                setShowCamera(!showCamera);
              }}>
              Take a picture
              <img className={styles.attachIcon} src={adminImages.cameraIcon} />
            </button>
          </Flex>
        )}
      </div>
    </div>
  );
}

export default TakePicture;
