import React, {useCallback, useState, useEffect} from 'react';
import styles from './styles.module.scss';
import Flex from '../../../../../../components/Flex';
import edrishtiLogo from '../../../../../../assets/images/edrishti-group/Group.png';
import sannaLogo from '../../../../../../assets/images/sanna-logo/image.png';
import celLogo from '../../../../../../assets/images/cel-logo-red/image.png';
import moment from 'moment';
import {GSTStateCodes} from '../../data';

// import {title} from 'react-dom-factories';
const Desktop = (props, ref) => {
  // console.log('data1:', data);
  const {onClick, company, details} = props;

  console.log('details', details);
  console.log('company', company);

  const getSubtotal = () => {
    let price = details?.completeData?.items[0]?.pack?.price;
    let qty = details?.completeData?.items[0]?.quantity;
    let subtotal = price * qty;
    console.log('subtota...', subtotal);
    return subtotal;
  };

  const getGST = () => {
    let gst = 0.18;
    let subtotal = getSubtotal();
    let gstAmount = subtotal * gst;
    return gstAmount;
  };

  const getTotal = () => {
    let subtotal = getSubtotal();
    let gst = getGST();
    let total = subtotal + gst;
    return total;
  };

  const getGSTStateCode = code => {
    const GSTStateCode = GSTStateCodes.filter(el => el.code === code)[0];
    return GSTStateCode;
  };

  return (
    <>
      <Flex className={styles.mainContainer}>
        <Flex className={styles.container} direction="column">
          <Flex direction="column" ref={ref}>
            <Flex className={styles.header}>
              <Flex className={styles.left} direction="column">
                <img src={edrishtiLogo} alt="edrishtilogo" />
                <Flex className={styles.sanna}>
                  <p>Powered by</p>
                  <img src={sannaLogo} alt="sannaLogo" />
                </Flex>
              </Flex>
              <div className={styles.center}>Transaction Receipt</div>

              <Flex className={styles.right} direction="column">
                <p className={styles.name}>Invoice #:</p>
                <p className={styles.value}>{details?.referenceId}</p>
                <p className={styles.date}>
                  Invoice Date: {moment(details?.date).format('DD/MM/YYYY')}
                </p>
              </Flex>
            </Flex>
            <Flex className={styles.body}>
              <div className={styles.left}>
                <div>
                  <p className={styles.bold}>Bill To</p>
                  <Flex>
                    <p>{company?.company?.name}</p>
                    {/* <p>{firstName}</p>
              <p>{lastName}</p> */}
                  </Flex>
                  <p>{company?.company?.address1}</p>
                  <p>{company?.company?.address2}</p>
                  <p>Email: {company?.company?.emailAddress}</p>
                  <p>Phone: {company?.company?.phoneNumber}</p>
                </div>
                {company?.company?.gstnumber ? (
                  <div className={styles.playOfSupply}>
                    Place of Supply:{' '}
                    {company?.company?.gstnumber
                      ? company?.company?.gstnumber?.substring(0, 2)
                      : ''}{' '}
                    -{' '}
                    {
                      getGSTStateCode(
                        company?.company?.gstnumber?.substring(0, 2),
                      )?.name
                    }
                    .
                  </div>
                ) : null}
              </div>
              <div className={styles.right}>
                <Flex className={styles.rightPlace}>
                  <p className={styles.key}>GSTIN: </p>
                  <p className={styles.value}>
                    {' '}
                    {company?.company?.gstnumber
                      ? company?.company?.gstnumber
                      : 'NOT AVAILABLE'}{' '}
                  </p>
                </Flex>
                <Flex className={styles.rightPlace}>
                  <p className={styles.key}>PAN: </p>
                  <p className={styles.value}>
                    {' '}
                    {company?.company?.pannumber
                      ? company?.company?.pannumber
                      : '-'}{' '}
                  </p>
                </Flex>
              </div>
            </Flex>
            <div className={styles.table}>
              <table>
                <thead>
                  <tr className={styles.tableHeader}>
                    <th>S.No.</th>
                    <th>Item</th>
                    <th>HSN/SAC Code</th>

                    <th>Quantity</th>
                    <th></th>

                    {/* <th>Price Per Unit(#)</th> */}
                    <th>Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className={styles.rowBgcolor}>
                    <td>1</td>
                    <td>{details?.entryCategory}</td>
                    <td>996759</td>

                    <td>{details?.completeData?.items[0]?.quantity}</td>
                    <td></td>
                    {/* <td>₹ {details?.completeData?.items[0]?.pack?.price}</td> */}
                    <td>₹ {getSubtotal()}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>

                    <td className={styles.key}>Sub Total</td>
                    <td className={styles.value}>₹ {getSubtotal()}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>

                    <td className={styles.key}>GST(18%)</td>
                    <td className={styles.value}>₹ {getGST()}</td>
                  </tr>

                  <tr style={{borderTop: '1px solid #002a94'}}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>

                    <td className={styles.blueKey}>Total</td>
                    <td className={styles.blueValue}>₹ {getTotal()}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <Flex className={styles.footer} direction="column">
              <div className={styles.content}>
                Whether GST is payable on reverse charge basis - No
              </div>
              <Flex className={styles.content}>
                <Flex className={styles.left} direction="column">
                  <img src={celLogo} alt="celLogo" />
                  <p className={styles.bold}>Central Electronics Limited</p>
                  <p>(Public Sector Enterprises)</p>
                  <p>T-420, F-Block, SOBO Center South Bhopal, - AHMEDABAD</p>
                  <p className={styles.key}>GSTIN: 24AAACC1261G1Z9</p>
                </Flex>
                <Flex className={styles.right} direction="column">
                  {/* <Flex>
                    <p className={styles.key}>GSTIN:</p>
                    <p className={styles.value}>24AAACC1261G1Z9</p>
                  </Flex> */}
                  {/* <Flex>
                    <p className={styles.key}>PAN:</p>
                    <p className={styles.value}>6567GGHBYUY</p>
                  </Flex>
                  <Flex className={styles.key}>
                    <p>CIN:</p>
                    <p className={styles.value}> U-JI-GFR-6567GGHBYUY</p>
                  </Flex>
                  <Flex>
                    <p className={styles.key}>HSN/SAC:</p>
                    <p className={styles.value}>5455</p>
                  </Flex> */}
                  <Flex>
                    <p>
                      {' '}
                      *Tax invoice will be summary of whole months transactions
                      to be shared only after the end of the month
                    </p>
                  </Flex>
                </Flex>
              </Flex>
              <div className={styles.voidContent}></div>
              <div className={styles.bottomText}>
                This is a system generated invoice and hence no signature
                required
              </div>
            </Flex>
          </Flex>
          <div className={styles.buttonsContainer}>
            <button
              onClick={() => onClick('print')}
              className={styles.invoiceButton}>
              Print
            </button>
            <button
              onClick={() => onClick('close')}
              className={styles.closeButton}>
              Close
            </button>
          </div>
        </Flex>
      </Flex>
    </>
  );
};

export default React.forwardRef(Desktop);

const invoiceItems = {
  sNo: 1,
  item: 'cargo',
  quantity: 100,
  pricePerUnit: 20,
  totalPrice: 2000,
};
