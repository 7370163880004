import moment from 'moment';
import util from '../../../util';

export const persons = [
  {
    id: 104,
    emailAddress: 'manuarorawork@yopmail.com',
    dateOfBirth: moment().format('YYYY-MM-DD'),
    nationality: 'INDIAN',
    documentType: 1,
    gender: 1,
    country: 1,
    personType: 1,
    documentProofNumber: 'RKADKMAKWMD',
    referenceNumber: 'MTW3895936514',
    name: 'Manu Arora',
    phoneNumber: '9587738861',
    idProofNumber: 'AADHAR21',
    payment: 'Pending',
    requestDate: '9/10/2021',
    status: 'Pending',
  },
];
