import moment from 'moment';
import React, {PureComponent} from 'react';
import LineGraph from '../../../components/LineGraph';
import PopupContainer from '../../../components/PopupContainer';
import Spinner from '../../../components/Spinner';
import Popup from '../../../components/SimplePopup';

import './styles.css';
import styles from './styles.module.scss';
import ViewRenderer from './views';
import {API} from '../../../requests';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {commonImages} from '../../../assets/images';
import strings from '../../../globalization';
import util from '../../../util';

import closeIcon from '../../../assets/images/close-icon/image.png';

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  SPINNER_POPUP: 'spinner',
  ERROR: 'error',
  SIMPLE_POPUP: 'simple-popup',
  GRAPH_POPUP: 'graph-popup',
};

class OperatorDashboard extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      dashboardDetails: [],
    };
  }

  componentDidMount() {
    this.getData();
    // this.fetchDashboard();
  }

  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  getData = () => {
    // this.showSpinner();
    // FIXME: Correct API should be here.
    API.fetchOperatorDashboard()
      .then(res => {
        console.log('res.data', res.data);
        this.setState({
          popup: null,
          dashboardDetails: res.data,
        });
      })
      .catch(err => {
        console.log(err);
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  onClickHandler = (action, data) => {
    const {history, location, status} = this.props;
    switch (action) {
      case 'profile': {
        //FIXME
        // history.push('/u/profile');
        break;
      }

      default: {
        break;
      }
    }
  };

  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }

      default: {
        console.warn('case not handled', popup.type);
        return null;
      }
    }
  };

  closePopup = () => {
    this.setState({
      popup: null,
    });
  };

  getProps = () => {
    const {dashboardDetails} = this.state;
    const {reduxProps} = this.props;

    const {
      login: {loginInfo},
    } = reduxProps;
    const {user, department} = loginInfo;
    return {
      onClick: this.onClickHandler,
      user,
      departmentName: department?.name,
      dashboardDetails,
    };
  };

  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer containerStyle={styles.popupContainerStyle}>
            {this.getPopupContent()}
          </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}
const mapStateToProps = reduxProps => ({
  reduxProps,
});

const mapDispatchToProps = dispatch => ({
  // dispatchSetPersonsData: payload => {
  //   dispatch(setPersonsData(payload));
  // },
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OperatorDashboard),
);
