import React, {useEffect} from 'react';
import styles from './Popup.module.scss';
import {adminImages} from '../../assets/images';
import Flex from '../Flex';
import OtpInput from 'react-otp-input';
import {useMediaQuery} from 'react-responsive';
import {API} from '../../requests';
import strings from '../../globalization';
import util from '../../util';
import CountDownTimer from '../CountDownTimer';
type Button = {
  text: String,
  onClick: () => void,
  className?: String,
};

type Props = {
  message: String,
  onClose?: () => void,
  error?: Boolean,
  success?: Boolean,
  buttons?: Button[],
  heading?: String,
  phoneNumber?: String | Number,
};

function OtpPopup(props) {
  const {
    phoneNumber,
    onOtpVerifySuccess,
    message,
    resendOTP,
    showSuccessPopup,
    showErrorPopup,
    expiryMinutes,
  } = props;
  const [otp, setOtp] = React.useState('');
  const [showTimer, setShowTimer] = React.useState(true);
  const [OTPExpired, setOTPExpired] = React.useState(false);
  const [showResend, setShowResend] = React.useState(false);
  const [popupState, setVerifyButtonState] = React.useState({
    text: 'Verify',
    disable: false,
    error: '',
  });
  const isMobile = useMediaQuery({maxWidth: 767});

  const otpStyle = !isMobile
    ? {
        width: '2.917vw',
        height: '2.917vw',
      }
    : {
        width: '2.0rem',
        height: '2.0rem',
      };

  const {buttons, onClose} = props;
  let containerClass = '';

  const timerComplete = () => {
    console.log('timer completed');
    setShowTimer(false);
    setOTPExpired(true);
  };

  const showResendButton = () => {
    console.log('timer 20sec completed');
    setShowResend(true);
  };

  const OTPResend = () => {
    setOTPExpired(false);
    resendOTP(phoneNumber);
  };

  const verifyOtp = async phoneNumber => {
    const result = util.validatePhoneNumber(phoneNumber);
    console.log('is phone number valid: ', result);

    if (!result) {
      setVerifyButtonState(prevState => ({
        ...prevState,
        text: 'Verify',
        disable: false,
        error: 'Please enter valid phone number',
      }));
      return;
    }

    try {
      setVerifyButtonState(prevState => ({
        ...prevState,
        text: 'Verifying',
        disable: true,
        error: '',
      }));
      const res = await API.verifyOtp({
        phoneNumber: `+91${phoneNumber}`,
        token: otp,
      })
        .then(res => {
          if (res?.data?.phoneNumber === `+91${phoneNumber}`) {
            onOtpVerifySuccess(true);
            // onClose();
            showSuccessPopup();
          } else {
            setVerifyButtonState(prevState => ({
              ...prevState,
              text: 'Verify',
              disable: false,
              error: 'Invalid Mobile Number',
            }));
          }
        })
        .catch(error => {
          console.log(error);
          setOTPExpired(false);
          const errorMsg = error.response
            ? error.response.data?.message || error.response.data
            : error.message;
          setVerifyButtonState(prevState => ({
            ...prevState,
            text: 'Verify',
            disable: false,
            error: errorMsg,
          }));

          if (error?.response.data?.isSessionInvalid) {
            showErrorPopup(error?.response?.data?.message);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={[styles.container, containerClass].join(' ')}>
      {/* {onClose ? (
        <img alt="close" src={adminImages.closeIcon} className={styles.close} onClick={onClose} />
      ) : null} */}
      <div className={styles.bodyContainer}>
        <div className={styles.heading}> {strings.verifyMobNUmber}</div>
        <span className={styles.content}>{message}</span>
        <div className={styles.textContainer}>
          <span className={styles.message}>
            <OtpInput
              value={otp}
              numInputs={6}
              inputStyle={otpStyle}
              onChange={update => setOtp(update)}
              separator={<span>&nbsp; &nbsp;&nbsp;</span>}
            />
          </span>
        </div>
        {showTimer ? (
          <div className={styles.timer}>
            Your OTP will expire in{' '}
            <CountDownTimer
              onTimerComplete={timerComplete}
              seconds={expiryMinutes * 60 || 120}
              onShowResendButton={showResendButton}
            />{' '}
            minutes.
          </div>
        ) : null}

        {showResend ? (
          <a className={styles.resendOTPText} onClick={OTPResend}>
            Resend Code
          </a>
        ) : null}

        {OTPExpired && !popupState?.error && !showTimer ? (
          <div className={styles.timer}>Your OTP has expired.</div>
        ) : null}
      </div>

      <div className={styles.otpError}>{popupState.error}</div>
      <Flex className={styles.buttonContainer}>
        <button
          onClick={() => {
            onClose();
          }}
          className={styles.okButton}
          key={'popup-button-back'}>
          Back
        </button>

        <button
          onClick={() => {
            verifyOtp(phoneNumber);
          }}
          className={styles.button}
          key={'popup-button-verify'}
          disabled={popupState.disable}>
          {popupState.text}
        </button>
      </Flex>
    </div>
  );
}

export default OtpPopup;
