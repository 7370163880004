import React, {Component} from 'react';
import styles from './styles.module.scss';
import PopupContainer from '../../../components/PopupContainer';
import Popup from '../../../components/Popup';
import ViewRenderer from './views';
import {API} from '../../../requests';
import strings from '../../../globalization';
import Spinner from '../../../components/Spinner';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {commonImages, operatorImages} from '../../../assets/images';
import util from '../../../util';
import moment from 'moment';

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  ERROR: 'error',
  SPINNER_POPUP: 'spinner',
};
class TrafficDepartmentDashboard extends Component {
  constructor(props) {
    super(props);

    const {
      login: {
        loginInfo: {department},
      },
    } = this.props.reduxProps;
    console.log('reduxProps...', this.props.reduxProps);

    this.state = {
      departmentName: department?.name,
      metrics: null,
      startDate: moment().subtract(1, 'week').format('MM-DD-YYYY'),
      endDate: moment().format('MM-DD-YYYY'),
      dates: {
        start: moment().subtract(1, 'week').format('MM-DD-YYYY'),
        end: moment().format('MM-DD-YYYY'),
      },
      statuses: [
        {
          id: '1',
          name: 'today',
          displayName: 'Today',
        },
        {
          id: '2',
          name: 'week',
          displayName: 'Last 7 days',
        },
        {
          id: '3',
          name: 'month',
          displayName: 'Month to Date',
        },
        {
          id: '4',
          name: 'custom',
          displayName: 'Custom...',
        },
      ],
      selectedDropDownStatus: {
        id: '2',
        name: 'week',
        displayName: 'Last 7 days',
      },
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  /**
   *
   * @param {*} action
   * @param {*} data
   */

  fetchData = () => {
    this.showSpinner();
    const {startDate, endDate} = this.state;
    const data = {
      startDate: moment(startDate).format('MM-DD-YYYY'),
      endDate: moment(endDate).format('MM-DD-YYYY'),
    };
    API.fetchDepartmentDashBoardWithTrends(data)
      .then(response => {
        this.setState({
          metrics: response.data,
          popup: null,
        });
        console.log('response...', response);
      })
      .catch(error => {
        const err = util.getErrorMessage(error);
        this.showErrorPopup(err);
        console.log('error...', error);
      });
  };
  showSuccessPopup = successMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: successMessage,
        onClose: this.closePopup,
        headingImage: operatorImages.successIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  // give me a tweet that can engage my audience

  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  getProps = () => {
    const {
      departmentName,
      metrics,
      startDate,
      endDate,
      dates,
      statuses,
      selectedDropDownStatus,
    } = this.state;
    const {reduxProps} = this.props;
    const {
      login: {loginInfo},
    } = reduxProps;
    const {user} = loginInfo;
    return {
      user,
      departmentName,
      metrics,
      onClick: this.onClickHandler,
      startDate,
      endDate,
      dates,
      statuses,
      selectedDropDownStatus,
    };
  };

  onClickHandler = (action, data) => {
    const {history} = this.props;
    switch (action) {
      case 'permits': {
        //FIXME
        history.push('/u/permits');
        break;
      }
      case 'profile': {
        //FIXME
        history.push('/u/profile');
        break;
      }
      case 'date': {
        const {startDate, endDate, dates} = data;
        console.log(data);
        this.setState({
          startDate,
          endDate,
          dates,
        });
        setTimeout(() => {
          this.fetchData();
        }, 0);
        break;
      }
      case 'dateChange': {
        if (data) {
          this.setState({
            selectedDropDownStatus: data,
          });
        }
        this.setDate(data?.name);
        setTimeout(() => {
          this.fetchData();
        }, 0);
        break;
      }

      default: {
        break;
      }
    }
  };

  setDate = timePeriod => {
    switch (timePeriod) {
      case 'today': {
        this.setState({
          startDate: moment().format('MM-DD-YYYY'),
          endDate: moment().format('MM-DD-YYYY'),
          dates: {
            start: moment().format('MM-DD-YYYY'),
            end: moment().format('MM-DD-YYYY'),
          },
        });
        break;
      }
      case 'week': {
        this.setState({
          startDate: moment().subtract(7, 'days').format('MM-DD-YYYY'),
          endDate: moment().format('MM-DD-YYYY'),
          dates: {
            start: moment().subtract(7, 'days').format('MM-DD-YYYY'),
            end: moment().format('MM-DD-YYYY'),
          },
        });
        break;
      }
      case 'month': {
        this.setState({
          startDate: moment().startOf('month').format('MM-DD-YYYY'),
          endDate: moment().format('MM-DD-YYYY'),
          dates: {
            start: moment().startOf('month').format('MM-DD-YYYY'),
            end: moment().format('MM-DD-YYYY'),
          },
        });
        break;
      }
      default: {
        console.log('case not handled in setDate method');
      }
    }
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.SPINNER: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

const mapStateToProps = reduxProps => ({
  reduxProps,
});

const mapDispatchToProps = dispatch => ({
  // dispatchSetPersonsData: payload => {
  //   dispatch(setPersonsData(payload));
  // },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TrafficDepartmentDashboard),
);

//export default withRouter(AgencyDashBoard);
