import React, { Component } from 'react';

import strings from '../../globalization';
import PopupContainer from '../../components/PopupContainer';
import Spinner from '../../components/Spinner';
import styles from './styles.module.scss';
import Popup from '../../components/SimplePopup';
import SimplePopupWithComponent from '../../components/SimplePopupWithComponent';

import { withRouter } from 'react-router-dom';
import ViewRenderer from './views';
import REQUEST_TYPE_OPTIONS from '../../constants/request-type-options';
import { commonImages, operatorImages, adminImages } from '../../assets/images';

import { API } from '../../requests/index';
import util from '../../util';
import PAYMENT_CONSTANTS from '../../constants/payment-constants';
import moment from 'moment';
const POPUP_CONSTANTS = {
  STANDARD_POPUP: 'standard-popup',
  ERROR_POPUP: 'error-popup',
  SPINNER_POPUP: 'spinner-popup',
  USER_NOT_CONFIRM: 'user-not-confirm',
  SIMPLE_POPUP: 'simple-popup',
  PRIVACY_POPUP: 'privacy-popup',
  OTP_POPUP: 'otp-popup',
  STATUS_POPUP: 'status-popup',
  PERSON_INVOICE_POPUP: 'person-invoice-popup',
  VEHICLE_INVOICE_POPUP: 'vehicle-invoice-popup',
  SURRENDER_POPUP: 'surrender-popup',
  LOST_POPUP: 'lost-popup',
};

const STATUS_CONSTANTS = {
  PAYMENT_PENDING: 'Payment Pending',
  PAYMENT_COMPLETED: 'Registration Submitted',
  PAYMENT_FAILED: 'Payment Failed ',
  OPERATOR_ADMIN_APPROVED: 'Registration Pending',
  OPERATOR_ADMIN_REJECTED: 'Registration Unsuccessful',
  DEPARTMENT_ADMIN_APPROVED: 'Registration Approved',
  DEPARTMENT_ADMIN_REJECTED: 'Registration Unsuccessful',
  ISSUED: 'RFID Issued',
  HOLD: 'RFID Hold',
  SURRENDER: 'RFID Surrender',
  UPDATED: 'Details Updated',
  UPDATE_REJECTED: 'Update Rejected',
  REISSUE_PAYMENT_PENDING: 'Reissue Payment Pending',
  REISSUE_PAYMENT_COMPLETED: 'Reissue Payment Completed',
  REISSUE_PAYMENT_FAILED: 'Reissue Payment Failed',
};

const DISABLE_EDIT_STATUSES = [
  'OPERATOR_ADMIN_APPROVED',
  'DEPARTMENT_ADMIN_APPROVED',
  'HOLD',
  'SURRENDER',
  'REISSUE_PAYMENT_PENDING',
  'REISSUE_PAYMENT_COMPLETED',
  'REISSUE_PAYMENT_FAILED',
];

class CheckPermitStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popup: null,
      referenceId: '',
      status: null,
      returnedObject: null,
      requestType: 2,
      otpVerified: true,
      requestTypeOptions: [
        {
          name: REQUEST_TYPE_OPTIONS.PERSON,
          id: 1,
        },
        {
          name: REQUEST_TYPE_OPTIONS.VEHICLE,
          id: 2,
        },
        // {
        //   name: REQUEST_TYPE_OPTIONS.COMPANY,
        //   id: 3,
        // },
      ],
    };
  }

  componentDidMount() {
    this.getPreLoginToken();
  }
  getPreLoginToken = () => {
    API.getPreLoginToken()
      .then(response => {
        console.log(response);
        API.setPreLoginToken(response);
      })
      .catch(error => {
        //this.showErrorPopup('');
        console.log(error);
      });
  };
  getPopupContent = () => {
    const { popup, phoneNumber, personDetails, vehicleDetails, requestType } =
      this.state;

    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP_WITH_COMPONENT: {
        return <SimplePopupWithComponent {...popup} />;
      }

      case POPUP_CONSTANTS.STATUS_POPUP: {
        return <PermitStatus {...popup} />;
      }

      default: {
        console.warn('case not handled', popup.type);
        return null;
      }
    }
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };
  showSuccessPopup = successMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: successMessage,
        onClose: this.closePopup,
        headingImage: operatorImages.successIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  showSuccessPopupComponent = messageComponent => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP_WITH_COMPONENT,
        children: messageComponent,
        onClose: this.closePopup,
        headingImage: operatorImages.successIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: { color: '#E67717' },
        onClose: this.closePopup,
        buttons: [
          {
            text: 'Close',
            onClick: this.closePopup,
            outline: true,
            style: {
              fontFamily: 'Poppins-Light',
              width: 150,
              fontSize: 14,
              marginBottom: 30,
            },
          },
        ],
      },
    });
  };

  closePopup = () => {
    this.setState({
      popup: null,
    });
  };
  onClickHandler = action => {
    const { history } = this.props;
    switch (action) {
      case 'privacy': {
        this.showPrivacyPopup(POPUP_CONSTANTS.PRIVACY_POPUP);
        break;
      }
      case 'terms': {
        this.showPrivacyPopup(POPUP_CONSTANTS.TERMS_POPUP);
        break;
      }
      case 'checkApplicationStatus': {
        history.push('/check-application-status');
        break;
      }
      case 'goBackToLogin': {
        history.push('/signin');
        break;
      }
      case 'payment': {
        this.displayRazorpay();
        break;
      }
      default: {
        break;
      }
    }
  };
  getTextContent = (entity, returnedObject) => {
    console.log(entity);
    switch (entity) {
      case REQUEST_TYPE_OPTIONS.PERSON:
        return returnedObject.name;
      case REQUEST_TYPE_OPTIONS.VEHICLE:
        return returnedObject.name;
      case REQUEST_TYPE_OPTIONS.COMPANY:
        return returnedObject.name;
      default:
        return null;
    }
  };

  handleBackClick = () => {
    const { history } = this.props;
    history.push('/signin');
  };

  handleLoginClick = () => {
    const { history } = this.props;
    history.push('/signin');
  };

  handleClickHerePress = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: strings.loginDetailsSent,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
          },
        ],
        onClose: this.closePopup,
        messageStyle: {
          color: '#202428',
          fontWeight: 'normal',
        },
      },
    });
  };

  onChangeHandler = (field, value) => {
    console.log('field', field, 'value', value);
    this.reset();
    if (field === 'requestType') {
      this.setState({
        returnedObject: null,
        otpVerified: true,
      });
    }
    let preparedStateObject = {};
    preparedStateObject[field] = value;
    this.setState(preparedStateObject);
  };

  reset = () => {
    this.setState({
      otpVerified: true,
    });
  };

  onSubmit = () => {
    const { requestType, referenceId } = this.state;
    console.log(this.state);
    this.showSpinner();

    if (!requestType) {
      this.showErrorPopup(strings.pleaseSelectRequestType);
    } else if (!referenceId) {
      this.showErrorPopup(strings.pleaseEnterReferenceNumber);
    } else {
      if (requestType === 1) {
        this.verifyPerson();
      } else if (requestType === 2) {
        this.getVehicle();
      } else {
        this.verifyCompany();
      }
    }
  };

  statusCard = data => {
    const { icon, buttons, content, type } = data;
    console.log(data, this.state);
    const { vehicleDetails, personDetails } = this.state;
    const { agencyName, permitEndTIme, permitStartTime, createdDate, tripType } =
      vehicleDetails || personDetails;

    return (
      <>
        <div className={styles.blueTickWrapper}>
          <div className={styles.blueTickContainer}>
            <img src={operatorImages.successIcon} alt="u" />
            <div className={styles.blueText}>Permit Active</div>
          </div>
          <div className={styles.permitTimeBlock}>
            <div className={styles.timeActivePeriod}>
              <div className={styles.boldLabel}>Permit active period:</div>
              <div className={styles.col2}>
                Port permit timeline
                <div className={styles.colorBoxRow}>
                  {this.getTimeIcon(
                    'Starts',
                    '#6dd40099',
                    permitStartTime,
                    permitStartTime,
                  )}
                  <img src={commonImages.checkPermitStatusArrowIcon} alt="u" />
                  {this.getTimeIcon(
                    'Ends',
                    '#BC6500',
                    permitEndTIme,
                    permitEndTIme,
                  )}
                </div>
              </div>
            </div>
            <div className={styles.timeStampContainer}>
              <div className={styles.boldLabel}>Permit raised at:</div>
              <div className={styles.col2}>
                {' '}
                {moment(createdDate).format('DD/MM/YY HH:mm A')}
              </div>
            </div>
            <div className={styles.agencyNameContainer}>
              <div className={styles.boldLabel}>Trip Type:</div>
              <div className={styles.col2}>
                {' '}
                {tripType === 1 ? 'Single' : 'Multiple'}{' '}
              </div>
            </div>
            <div className={styles.agencyNameContainer}>
              <div className={styles.boldLabel}>Permit Agency Name:</div>
              <div className={styles.col2}> {agencyName} </div>
            </div>
          </div>
        </div>
        <div className={styles.statusCardContainer}>
          <div className={styles.heading}>{type === 'vehicle' ? 'Vehicle Details' : 'Person Details'}</div>
          <div className={styles.cardDetails}>
            <img className={styles.icon} alt="U" src={icon} />
            <div>{content}</div>
          </div>

          {buttons && buttons.length > 0 ? (
            <div className={[styles.buttonContainer].join(' ')}>
              {buttons.map(button => {
                const { text, buttonStyles, ...remainingProps } = button;
                return (
                  <button
                    className={[styles.button, buttonStyles].join(' ')}
                    key={'popup-button-' + text}
                    {...remainingProps}>
                    {text}
                  </button>
                );
              })}
            </div>
          ) : null}
        </div>
      </>
    );
  };

  getTimeIcon = (label, color, time, date) => {
    return (
      <div className={styles.timeBlock}>
        <div className={styles.label}>{label}</div>
        <div
          className={styles.timeIconContainer}
          style={{ backgroundColor: color }}>
          <div className={styles.time}>{moment(time).format('HH:mm A')}</div>
        </div>
        <div className={styles.date}>{moment(date).format('DD/MM/YY')}</div>
      </div>
    );
  };

  getVehicleContent = () => {
    const { vehicleDetails } = this.state;
    const { vehicleRegistrationNumber, status, rfidnumber, name } =
      vehicleDetails?.vehicle;

    return (
      <>
        <div className={styles.contentContainer}>
          <div>
            <b>Vehicle Number:</b> <span>{vehicleRegistrationNumber}</span>
          </div>
          <div>
            <b>Vehicle Current Status:</b>{' '}
            <span className={styles.bolder}>{STATUS_CONSTANTS[status]}</span>
          </div>
          <div>
            <b>Vehicle Owner Name: </b>
            <span> {name}</span>
          </div>
          <div>
            <b>RFID Tag Number: </b>
            <span> {rfidnumber}</span>
          </div>
        </div>
      </>
    );
  };

  getPersonContent = () => {
    const { personDetails } = this.state;
    const { person } = personDetails || {}
    console.log(person);
    return (
      <>
        <div className={styles.contentContainer}>
          <div>
            <b>Name:</b> {person?.name}
          </div>
          <div>
            {' '}
            <b>Mobile Number:</b> {person?.phoneNumber.slice(3)}
          </div>
          <div>
            <b>Aadhaar Number:</b> {person?.aadhaarNumber}
          </div>
          <div>
            <b>Status:</b>{' '}
            <span className={styles.bolder}>
              {STATUS_CONSTANTS[person?.status]}{' '}
            </span>
          </div>
        </div>
      </>
    );
  };

  getAgencyContent = () => {
    const { agencyDetails } = this.state;
    const { name, departmentName, status, emailAddress } = agencyDetails;
    return (
      <>
        <div className={styles.contentContainer}>
          <div>
            <b>Agency Name:</b> {name}
          </div>
          <div>
            <b>Email Address:</b> {emailAddress}
          </div>

          <div>
            <b>Department:</b> {departmentName}
          </div>
          <div>
            <b>Status:</b> <span className={styles.bolder}>{status}</span>
          </div>
        </div>
      </>
    );
  };

  showStatusPopup = data => {
    const { heading } = data;
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.STATUS_POPUP,
        heading: heading,
        message: this.statusCard(data),
        data: {},
        onClose: this.closePopup,
        buttonContainer: styles.buttonContainer,
        buttons: [
          {
            text: 'Close',
            outline: true,
            style: {
              backgroundColor: '#e6edff',
              color: '#0045e6',
              cursor: 'pointer',
              fontSize: 30,
            },
            onClick: () => {
              this.closePopup();
            },
          },
        ],
      },
    });
  };

  verifyPerson = () => {
    const { referenceId, otpVerified } = this.state;

    if (otpVerified) {
      this.getPerson();
    } else {
      API.getPermitStatusForPedestrian(referenceId)
        .then(personRes => {
          console.log('personRes.data', personRes.data);
          this.setState({
            phoneNumber: personRes?.data?.person?.phoneNumber.slice(3),
            popup: null,
          });
          this.openOTPPopUp();
        })
        .catch(error => {
          const errorMsg = util.getErrorMessage(error);
          console.log(errorMsg);
          this.showErrorPopup(errorMsg);
        });
    }
  };

  preSignUrl = async url => {
    const { personDetails } = this.state;
    const { history } = this.props;
    const data = {
      url: url,
      expiryTime: 30,
    };
    await API.preSignedURL(data)
      .then(res => {
        console.log('res.data', res.data?.preSignedUrl);
        // setUrl(res.data?.preSignedUrl);
        const {
          status,
          referenceId,
          paymentDetails,
          remarks,
          reason,
          rfidnumber,
        } = personDetails;
        const data = {
          heading: 'Person Details',
          icon: res.data?.preSignedUrl,
          referenceId: referenceId,
          content: this.getPersonContent(),
          status: status,
          remarks: remarks,
          reason: reason,
          invoiceData: personDetails?.invoiceData,
          type: 'person',
          buttons: [],
        }
        this.showStatusPopup(data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  getPerson = () => {
    const { referenceId } = this.state;
    console.log('person reference id:', referenceId);
    API.getPermitStatusForPedestrian(referenceId)
      .then(personRes => {
        console.log('personRes.data', personRes?.data?.person.photo);
        this.setState(
          {
            personDetails: personRes.data,
            popup: null,
          },
          () => this.preSignUrl(personRes?.data?.person?.photo),
        );
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        console.log(errorMsg);
        this.showErrorPopup(this.noPermitCard(errorMsg));
        // this.showErrorPopup(errorMsg);
      });
  };

  getVehicle = () => {
    const { referenceId } = this.state;

    console.log('vehicle reference id:', referenceId);
    API.getVehicleByRegRefRFID(referenceId)
      .then(vehicleRes => {
        console.log('vehicleRes.data', vehicleRes.data);
        this.setState({
          vehicleDetails: vehicleRes.data,
          popup: null,
        });
        const { referenceId } = vehicleRes?.data;
        const data = {
          heading: 'Permit Status',
          icon: adminImages.truckIconBlue,
          referenceId: referenceId,
          type: 'vehicle',
          content: this.getVehicleContent(),
          buttons: [],
        };

        this.showStatusPopup(data);
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        console.log(errorMsg);
        this.showErrorPopup(this.noPermitCard(errorMsg));
      });
  };

  noPermitCard = errorMsg => {
    return (
      <div className={styles.errorPopup}>
        <div className={styles.blueStrip}>Permit Status</div>
        <div className={styles.noPermitContainer}>
          <div style={{ backgroundColor: '#f6f6f6' }}>
            <img src={commonImages.errorIcon} alt="s" />
            <div className={styles.bold}>{errorMsg}</div>
          </div>
        </div>
      </div>
    );
  };

  verifyCompany = () => {
    const { referenceId, otpVerified } = this.state;
    const { history } = this.props;
    if (otpVerified) {
      this.getCompany();
      // history.push({
      //   pathname: '/edit-agency-registration',
      //   referenceId: referenceId,
      // });
    } else {
      API.verifyCompanyByReferenceId(referenceId)
        .then(companyDetails => {
          console.log('companyDetails.data:', companyDetails.data);
          this.setState({
            phoneNumber: companyDetails.data?.phoneNumber.slice(3),
            popup: null,
          });
          this.openOTPPopUp();
        })
        .catch(error => {
          const errorMsg = util.getErrorMessage(error);
          console.log(errorMsg);
          this.showErrorPopup(errorMsg);
        });
    }
  };

  getCompany = () => {
    const { referenceId } = this.state;
    const { history } = this.props;

    console.log('agency reference id:', referenceId);
    API.getAgencyDetailsByReferenceId(referenceId)
      .then(agencyRes => {
        console.log('agencyRes.data', agencyRes.data);
        this.setState({
          agencyDetails: agencyRes.data,
          popup: null,
        });
        const { status, referenceId } = agencyRes?.data;
        const data = {
          heading: 'Agency Details',
          icon: adminImages.agencyIcon,
          referenceId: referenceId,
          content: this.getAgencyContent(),
          buttons:
            status === 'PENDING' || status === 'REJECTED' || status === 'HOLD'
              ? [
                {
                  text: 'Edit',
                  buttonStyles: styles.backButton,
                  onClick: () => {
                    history.push({
                      pathname: '/edit-agency-registration',
                      referenceId: referenceId,
                    });
                  },
                },
              ]
              : [],
        };
        this.showStatusPopup(data);
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        console.log(errorMsg);
        this.showErrorPopup(errorMsg);
      });
  };

  getProps = () => {
    return {
      onSubmit: this.onSubmit,
      backClick: this.handleBackClick,
      onChangeHandler: this.onChangeHandler,
      stateData: this.state,
      onForgotClick: this.onForgotClick,
      onUploadNOC: this.onUploadNOC,
    };
  };

  render() {
    const { popup } = this.state;
    const props = this.getProps();

    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...props} />
      </>
    );
  }
}

// export default withRouter(PersonCards);
export default withRouter(CheckPermitStatus);

type Button = {
  text: String,
  onClick: () => void,
  className?: String,
};

type Props = {
  message: Object,
  onClose?: () => void,
  error?: Boolean,
  success?: Boolean,
  buttons?: Button[],
  heading?: String,
};

/**
 *
 * @param {Boolean} error
 * @param {Boolean} success
 * @return {JSX.Element}
 */

/**
 *
 * @param {Props} props
 * @return {JSX.Element}
 */
const PermitStatus = (props: Props) => {
  const { error, success, onClose, buttons, heading, buttonContainer, message } =
    props;
  let containerClass = '';
  if (error) {
    containerClass = styles.error;
  } else if (success) {
    containerClass = styles.success;
  }
  return (
    <div className={[styles.container, containerClass].join(' ')}>
      <div className={styles.secondContainer}>
        {onClose ? (
          <img
            alt="close"
            src={adminImages.closeIcon}
            className={styles.closeIcon}
            onClick={onClose}
          />
        ) : null}

        <div className={styles.heading}> {heading}</div>
        <div className={styles.bodyContainer}>
          <div className={styles.infoContainer}>{message}</div>
        </div>
        {buttons && buttons.length > 0 ? (
          <div className={[styles.buttonContainer, buttonContainer].join(' ')}>
            {buttons.map(button => {
              const { text, buttonStyles, ...remainingProps } = button;
              return (
                <button
                  className={[styles.button, buttonStyles].join(' ')}
                  key={'popup-button-' + text}
                  {...remainingProps}>
                  {text}
                </button>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};
