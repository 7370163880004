import React, { Component } from 'react';
import PopupContainer from '../../../components/PopupContainer';
import moment from 'moment';

import ViewRenderer from './views';
import AgencyRquestsPopup from '../../../components/AgencyRquestsPopup';
import PersonCardsPopup from '../../../components/operator/PersonCardsPopup';
import { commonImages, operatorImages } from '../../../assets/images';
import strings from '../../../globalization';

import Popup from '../../../components/SimplePopup';

import POPUP_CONSTANTS from '../../../constants/popup-constants';
import Spinner from '../../../components/Spinner';
import Success from '../../../components/operator/Success';
import Reject from '../../../components/operator/Reject';

import CardsActionsPopup from '../../../components/operator/CardsActionsPopup';
import History from '../../../components/operator/History';
import ViewDocument from '../../../components/ViewDocumentView';
import VerifyPersonPopup from '../../../components/VerifyRegistrations/Person';
import { getPersonFormData, getVehiclesFormData } from './data';

import styles from './styles.module.scss';
import { API } from '../../../requests';
import util from '../../../util';
import { withRouter } from 'react-router-dom';
import VerifyVehiclePopup from '../../../components/VerifyRegistrations/Vehicle';

const TYPE_CONSTANTS = {
  PERSON: 'person',
  VEHICLE: 'vehicle',
};

const STATUS_CONSTANTS = {
  PAYMENT_COMPLETED: 'PAYMENT_COMPLETED',
  OPERATOR_ADMIN_APPROVED: 'OPERATOR_ADMIN_APPROVED',
  OPERATOR_ADMIN_REJECTED: 'OPERATOR_ADMIN_REJECTED',
  UPDATED: 'UPDATED',
  REISSUE_PAYMENT_COMPLETED: 'REISSUE_PAYMENT_COMPLETED',
};

class VerifyRegistrations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: null,
      endDate: null,
      dates: null,
      searchText: '',
      pageStatus: '',
      formDataState: null,
      personsAndVehicles: [],
      filteredPersonsAndVehicles: [],
      personRejectReasons: [],
      vehicleRejectReasons: [],
      globalError: '',
      timestamp: new Date().getTime(),
      personViewedDocuments: {
        drivingLicenceDocument: false,
        aadhaarDocument: false,
      },
      vehicleViewedDocuments: {
        vehicleRegistrationNumber: false,
        vehicleInsuranceNumber: false,
        vehiclePollutionNumber: false,
        fitnessCertificate: false,
      },

      selectedStatus: null,
      selectedEntity: null,
      statuses: [
        {
          name: 'Pending',
          id: STATUS_CONSTANTS.PAYMENT_COMPLETED,
        },
        {
          name: 'Submitted',
          id: STATUS_CONSTANTS.OPERATOR_ADMIN_APPROVED,
        },
        {
          name: 'Resubmitted',
          id: STATUS_CONSTANTS.OPERATOR_ADMIN_REJECTED,
        },
        {
          name: 'Updated',
          id: STATUS_CONSTANTS.UPDATED,
        },
        {
          name: 'Reissued',
          id: STATUS_CONSTANTS.REISSUE_PAYMENT_COMPLETED,
        },
      ],
      entityTypeOptions: [
        {
          name: 'Person',
          id: TYPE_CONSTANTS.PERSON,
        },
        {
          name: 'Vehicle',
          id: TYPE_CONSTANTS.VEHICLE,
        },
      ],
    };
  }

  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: { color: '#E67717' },
        headingImage: commonImages.errorIcon,
        onClose: this.closeErrorPopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closeErrorPopup,
            outline: true,
          },
        ],
      },
      timestamp: new Date().getTime(),
    });
  };

  closeErrorPopup = () => {
    this.setState({
      popup: null,
    });
  };

  componentDidMount() {
    this.getData();
    this.fetchOptions();
  }
  getAllGenders = () => {
    API.getAllGender()
      .then(genderRes => {
        this.setState({
          genderOptions: genderRes.data,
        });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        this.showErrorPopup(errorMsg);
      });
  };

  getAllPersonOptions = () => {
    API.getAllPersonOptions()
      .then(personOptionsRes => {
        this.setState({
          personTypeOptions: personOptionsRes.data,
        });
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        this.showErrorPopup(errorMsg);
      });
  };

  fetchPersonDocumentTypes = () => {
    API.fetchPersonDocumentTypes()
      .then(optionsRes => {
        console.log('res...documentTypes', optionsRes.data);
        this.setState({
          documentTypeOptions: optionsRes.data,
        });
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        console.log(errorMsg);
        this.showErrorPopup(errorMsg);
      });
  };

  fetchPersonRejectReasons = () => {
    API.fetchPersonRejectReasons()
      .then(personRejectReasonsRes => {
        console.log('res...rejectReasons', personRejectReasonsRes.data);
        this.setState({
          personRejectReasons: personRejectReasonsRes.data,
        });
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        console.log(errorMsg);
        this.showErrorPopup(errorMsg);
      });
  };
  fetchVehicleRejectReasons = () => {
    API.fetchVehicleRejectReasons()
      .then(vehicleRejectReasons => {
        console.log('res...rejectReasons', vehicleRejectReasons.data);
        this.setState({
          vehicleRejectReasons: vehicleRejectReasons.data,
        });
      })
      .catch(error => {
        const errorMsg = util.getErrorMessage(error);
        console.log(errorMsg);
        this.showErrorPopup(errorMsg);
      });
  };

  fetchOptions = async () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
    let promises = [];
    promises.push(this.getAllGenders());
    promises.push(this.getAllPersonOptions());

    promises.push(this.fetchPersonDocumentTypes());
    promises.push(this.fetchPersonRejectReasons());
    promises.push(this.fetchVehicleRejectReasons());

    Promise.all(promises)
      .then(res => {
        this.setState({
          popup: null,
          timestamp: new Date().getTime(),
        });
      })
      .catch(error => console.log(error));
  };

  getData = async () => {
    this.showSpinner();
    API.verifyRegistrationsOperators()
      .then(res => {
        console.log('res...verifyRegOperators', res.data);

        // filter out only payment complete
        let filtered = res.data.filter(
          elem => elem.status === STATUS_CONSTANTS.PAYMENT_COMPLETED,
        );
        this.setState({
          personsAndVehicles: filtered,
          filteredPersonsAndVehicles: filtered,
          selectedStatus: this.state.statuses.find(
            o => o.id === STATUS_CONSTANTS.PAYMENT_COMPLETED,
          ),
          popup: null,
          timestamp: new Date().getTime(),
        });
      })
      .catch(err => {
        this.setState({
          filteredPersonsAndVehicles: [],
          personRejectReasons: [],
          personsAndVehicles: [],
          timestamp: new Date().getTime(),
        });
        let error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };

  onClickHandler = (action, data) => {
    console.log('action', action);
    const { history, location } = this.props;
    switch (action) {
      case 'apply': {
        this.handleFilterBarClick();

        break;
      }
      case 'back': {
        this.setState({
          popup: null,
          pageStatus: '',
        });
        this.resetPersonAndVehicleViewedDocuments();
        break;
      }
      case 'clearFilter': {
        this.clearData();
        break;
      }
      case 'rowClick': {
        this.handleRowClick(data);
        break;
      }
      case 'reset-page-status': {
        this.setState({
          pageStatus: '',
        });
        break;
      }
      case 'handle-reject-page-status': {
        this.setState({
          pageStatus: 'reject',
        });
        break;
      }
      case 'handle-reject-person': {
        console.log('data...reject person 123', data);
        this.rejectPerson(data);
        break;
      }

      case 'handle-approve-person': {
        console.log('data...approve person', data);
        // Handle viewed here
        let error = this.handleAllPersonViewed(data);
        console.log('error...', error);
        // console.log('ERROR>...', error);
        if (error) {
          this.setState({
            globalError: error,
          });
          return;
        } else {
          console.log('Good to go!', data);
          this.approvePerson(data);
        }
        break;
      }
      case 'handle-reject-vehicle': {
        this.rejectVehicle(data);
        console.log('data...reject vehicle', data);
        break;
      }

      case 'handle-approve-vehicle': {
        let error = this.handleAllVehicleViewed(data);
        console.log('error...vehicle approve', error);

        if (error) {
          this.setState({
            globalError: error,
          });
          return;
        } else {
          console.log('Good to go!', data);
          this.approveVehicle(data);
        }
        break;
      }
      case 'document-view': {
        // set state to viewed true from formStateData
        const { type, stateField, url } = data;

        if (type === 'person') {
          this.setState(
            prevState => ({
              personViewedDocuments: {
                ...prevState.personViewedDocuments,
                [stateField]: true,
              },
            }),
            function () {
              console.log('updated person state on view...', this.state);
            },
          );
        } else {
          this.setState(
            prevState => ({
              vehicleViewedDocuments: {
                ...prevState.vehicleViewedDocuments,
                [stateField]: true,
              },
            }),
            function () {
              console.log('updated vehicle state on view...', this.state);
            },
          );
        }

        history.push({
          pathname: location.pathname + '/document-view',
          state: { background: location, url: url },
        });
        // this.getDocumentTempURL(url);
        break;
      }
      default: {
        console.info('case not handled', action);
      }
    }
  };

  getDocumentTempURL = url => {
    const { history, location } = this.props;
    const data = {
      url: url,
      expiryTime: 30,
    };
    API.preSignedURL(data)
      .then(res => {
        console.log('res.data', res.data?.preSignedUrl);
        history.push({
          pathname: location.pathname + '/document-view',
          state: { background: location, url: res.data?.preSignedUrl },
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleAllPersonViewed = person => {
    console.log('PERSON...', person);
    let error = '';
    const { personViewedDocuments } = this.state;
    let personFormData = getPersonFormData();

    let requiredFormData = personFormData.filter(
      e => e.elementType === 'checkText',
    );

    requiredFormData = requiredFormData.filter((e) => {
      if (person.typeId !== 1) {
        return e.stateField !== 'drivingLicenceDocument';
      } else {
        return e;
      }
    }
    );

    for (let i = 0; i < requiredFormData.length; i++) {
      let current = requiredFormData[i];

      if (
        person[current.stateField] &&
        !personViewedDocuments[current.stateField]
      ) {
        error = 'View all the documents at least once.';
      }
    }
    return error;
  };

  handleAllVehicleViewed = vehicle => {
    console.log('VEHICLE...', vehicle);
    let error = '';
    const { vehicleViewedDocuments } = this.state;
    let vehicleFormData = getVehiclesFormData();

    let requiredFormData = vehicleFormData.filter(
      e => e.elementType === 'checkText',
    );
    for (let i = 0; i < requiredFormData.length; i++) {
      let current = requiredFormData[i];

      if (
        vehicle[current.stateField] &&
        !vehicleViewedDocuments[current.stateField]
      ) {
        error = 'View all the documents at least once.';
      }
    }
    console.log('handleAllVehicleViewed error...', error);
    return error;
  };
  clearData = () => {
    this.setState(
      {
        startDate: null,
        endDate: null,
        dates: null,
        searchText: '',
        selectedEntity: null,
        selectedStatus: this.state.statuses.find(
          o => o.id === STATUS_CONSTANTS.PAYMENT_COMPLETED,
        ),
      },
      this.handleFilterBarClick,
    );
  };
  resetPersonAndVehicleViewedDocuments = () => {
    this.setState({
      personViewedDocuments: {
        drivingLicenceDocument: false,
        aadhaarDocument: false,
      },
      vehicleViewedDocuments: {
        vehicleRegistrationNumber: false,
        vehicleInsuranceNumber: false,
        vehiclePollutionNumber: false,
        fitnessCertificate: false,
      },
      globalError: '',
    });
  };
  rejectPerson = data => {
    let formData = {
      id: data.id,
      rejectReasonId: data.rejectReasonId,
      remarks: data.remark,
    };
    console.log('formData...', formData);

    this.showSpinner();
    if (data.status === 'UPDATED') {
      API.operatorReVerifyRejectPersonRegistration(formData)
        .then(res => {
          console.log('res.data...', res.data);
          this.resetPersonAndVehicleViewedDocuments();
          this.showSuccessPopup(`Person Reverification Rejected`);
        })
        .catch(err => {
          let error = util.getErrorMessage(err);
          this.resetPersonAndVehicleViewedDocuments();
          this.showErrorPopup(error);
        });
    } else {
      API.operatorRejectPersonRegistration(formData)
        .then(res => {
          console.log('res.data...', res.data);
          this.resetPersonAndVehicleViewedDocuments();
          this.showSuccessPopup(`Person Verification Rejected`);
        })
        .catch(err => {
          let error = util.getErrorMessage(err);
          this.resetPersonAndVehicleViewedDocuments();
          this.showErrorPopup(error);
        });
    }
  };

  approvePerson = data => {
    let formData = {
      id: [data.id],
    };

    this.showSpinner();
    if (data.status === 'UPDATED') {
      API.operatorReVerifyApprovePersonRegistration(formData)
        .then(res => {
          console.log('res.data...', res.data);
          this.resetPersonAndVehicleViewedDocuments();
          this.showSuccessPopup(`Person Reverified Successfully`);
        })
        .catch(err => {
          this.resetPersonAndVehicleViewedDocuments();
          let error = util.getErrorMessage(err);
          this.showErrorPopup(error);
        });
    } else {
      API.operatorApprovePersonRegistration(formData)
        .then(res => {
          console.log('res.data...', res.data);
          this.resetPersonAndVehicleViewedDocuments();
          this.showSuccessPopup(`Person Verified Successfully`);
        })
        .catch(err => {
          this.resetPersonAndVehicleViewedDocuments();
          let error = util.getErrorMessage(err);
          this.showErrorPopup(error);
        });
    }
  };

  approveVehicle = data => {
    let formData = {
      id: [data.id],
    };

    this.showSpinner();
    if (data.status === 'UPDATED') {
      API.operatorReVerifyApproveVehicleRegistration(formData)
        .then(res => {
          console.log('res.data...', res.data);
          this.resetPersonAndVehicleViewedDocuments();
          this.showSuccessPopup(`Vehicle Reverified Successfully`);
        })
        .catch(err => {
          let error = util.getErrorMessage(err);
          this.resetPersonAndVehicleViewedDocuments();
          this.showErrorPopup(error);
        });
    } else {
      API.operatorApproveVehicleRegistration(formData)
        .then(res => {
          console.log('res.data...', res.data);
          this.resetPersonAndVehicleViewedDocuments();
          this.showSuccessPopup(`Vehicle Verified Successfully`);
        })
        .catch(err => {
          let error = util.getErrorMessage(err);
          this.resetPersonAndVehicleViewedDocuments();
          this.showErrorPopup(error);
        });
    }
  };
  rejectVehicle = data => {
    let formData = {
      id: data.id,
      rejectReasonId: data.rejectReasonId,
      remarks: data.remark,
    };
    console.log('formData...', formData);

    this.showSpinner();
    if (data.status === 'UPDATED') {
      API.operatorReVerifyRejectVehicleRegistration(formData)
        .then(res => {
          console.log('res.data...', res.data);
          this.resetPersonAndVehicleViewedDocuments();
          this.showSuccessPopup(`Vehicle Reverification Rejected`);
        })
        .catch(err => {
          let error = util.getErrorMessage(err);
          this.resetPersonAndVehicleViewedDocuments();
          this.showErrorPopup(error);
        });
    } else {
      API.operatorRejectVehicleRegistration(formData)
        .then(res => {
          console.log('res.data...', res.data);
          this.resetPersonAndVehicleViewedDocuments();
          this.showSuccessPopup(`Vehicle Verification Rejected`);
        })
        .catch(err => {
          let error = util.getErrorMessage(err);
          this.resetPersonAndVehicleViewedDocuments();
          this.showErrorPopup(error);
        });
    }
  };
  showSuccessPopup = successMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: successMessage,
        onClose: this.closePopup,
        headingImage: operatorImages.successIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
      pageStatus: '',
    });
  };
  handleFilterBarClick = () => {
    this.showSpinner();
    const { searchText, startDate, endDate } = this.state;
    API.verifyRegistrationsOperators(startDate, endDate)
      .then(res => {
        // Search by referenceId - first
        let filtered = res.data.filter(elem =>
          elem.referenceId.toLowerCase().includes(searchText.toLowerCase()),
        );
        // search by phone number - second
        if (filtered.length === 0) {
          console.log('Searching phone number');
          filtered = res.data.filter(elem =>
            elem.phoneNumber.toLowerCase().includes(searchText.toLowerCase()),
          );
        }

        // search by company name
        if (filtered.length === 0) {
          console.log('searching by company name');
          filtered = res.data.filter(elem =>
            elem.companyName?.toLowerCase()?.includes(searchText.toLowerCase()),
          );
        }

        // Select Tab Filtering
        if (this.state.selectedStatus) {
          filtered = filtered.filter(
            elem => elem.status === this.state.selectedStatus.id,
          );
        }

        // Entity Tab Filtering
        if (this.state.selectedEntity) {
          filtered = filtered.filter(
            elem => elem.type === this.state.selectedEntity.id,
          );
        }

        this.setState({
          filteredPersonsAndVehicles: filtered,
          popup: null,
          timestamp: new Date().getTime(),
        });
      })
      .catch(err => {
        this.setState({
          filteredPersonsAndVehicles: [],
          personRejectReasons: [],
          personsAndVehicles: [],
          timestamp: new Date().getTime(),
        });
        let error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };
  handleRowClick = tableRowData => {
    console.log('row clicked...', tableRowData);
    let type = tableRowData.type;

    if (type === TYPE_CONSTANTS.PERSON) {
      API.getPersonByReferenceId(tableRowData.referenceId)
        .then(res => {
          const personFormData = this.preparePersonFormData();
          let formData = getPersonFormData();
          if (res.data?.typeId !== 1) {
            formData = [...getPersonFormData()]
              .filter(obj => obj.id !== 'drivingLicenceNumber')
              .filter(el => el.id !== 'drivingLicenceDocument');
          }
          this.setState(
            {
              popup: {
                type: POPUP_CONSTANTS.VERIFY_PERSON_POPUP,
                person: {
                  ...res.data,
                  phoneNumber: res.data.phoneNumber.split('+91')[1],
                },
                formData: formData,
                stateData: this.state,
                containerClass: styles.verifyPersonPopup,
                onClick: this.onClickHandler,
                formDataState: { ...personFormData },
                error: this.state.globalError,
              },
              formDataState: { ...personFormData },
            },
            () => this.preSignUrl(this.state?.popup?.person?.photo),
          );
        })
        .catch(err => {
          let error = util.getErrorMessage(err);
          this.showErrorPopup(error);
        });
    } else {
      API.getVehicleByReferenceId(tableRowData.referenceId)
        .then(res => {
          const vehicleFormData = this.prepareVehicleFormData();
          this.setState({
            popup: {
              type: POPUP_CONSTANTS.VERIFY_VEHICLE_POPUP,
              vehicle: {
                ...res.data,
                vehicleType: res.data.vehicleType.name,
                phoneNumber: res.data.phoneNumber.split('+91')[1],
              },
              formData: getVehiclesFormData(),
              stateData: this.state,
              containerClass: styles.verifyPersonPopup,
              onClick: this.onClickHandler,
            },
            formDataState: { ...vehicleFormData },
          });
        })
        .catch(err => {
          let error = util.getErrorMessage(err);
          this.showErrorPopup(error);
        });
    }
  };

  preSignUrl = async url => {
    const data = {
      url: url,
      expiryTime: 30,
    };
    await API.preSignedURL(data)
      .then(res => {
        console.log('res.data', res.data?.preSignedUrl);

        this.setState({
          popup: {
            ...this.state?.popup,
            person: {
              ...this.state?.popup?.person,
              photo: res.data?.preSignedUrl,
            },
          },
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  prepareVehicleFormData = () => {
    let preparedObject = {};
    let formData = getPersonFormData();
    formData.forEach(obj => {
      if (obj.elementType === 'checkText') {
        preparedObject[obj.stateField] = {
          value: obj.stateDefault,
          error: null,
          viewed: false,
        };
      } else {
        preparedObject[obj.stateField] = {
          value: obj.stateDefault,
          error: null,
        };
      }
      if (obj.optionsField) {
        preparedObject[obj.optionsField] = [];
      }
    });
    return preparedObject;
  };
  preparePersonFormData = () => {
    let preparedObject = {};
    let formData = getPersonFormData();
    formData.forEach(obj => {
      if (obj.elementType === 'checkText') {
        preparedObject[obj.stateField] = {
          value: obj.stateDefault,
          error: null,
          viewed: false,
        };
      } else {
        preparedObject[obj.stateField] = {
          value: obj.stateDefault,
          error: null,
        };
      }

      if (obj.optionsField) {
        preparedObject[obj.optionsField] = [];
      }
    });
    return preparedObject;
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  onChangeHandler = (action, data) => {
    switch (action) {
      case 'date': {
        const { startDate, endDate, dates } = data;
        this.setState({
          startDate,
          endDate,
          dates,
        });
        console.log('data...', data);
        break;
      }

      case 'search': {
        console.info('data..', data);
        this.setState({
          searchText: data,
        });
        break;
      }

      case 'status-dropdown': {
        console.log('data.status dropdown', data);
        this.setState({
          selectedStatus: this.state.statuses.find(o => o.id === data),
        });
        break;
      }
      case 'entity-dropdown': {
        console.log('entityDropdown dropdown', data);
        this.setState({
          selectedEntity: this.state.entityTypeOptions.find(o => o.id === data),
        });
        break;
      }

      default: {
        console.info('case not handled', action);
      }
    }
  };
  applyFilters = () => { };

  closePopup = () => {
    this.setState(
      {
        popup: undefined,
      },
      this.getData,
    );
  };

  getPopupContent = () => {
    const { popup } = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.STANDARD: {
        return <AgencyRquestsPopup {...popup} />;
      }
      case POPUP_CONSTANTS.SUCCESS_POPUP: {
        return <Success person {...popup} />;
      }
      case POPUP_CONSTANTS.REJECT_POPUP: {
        return <Reject person {...popup} />;
      }
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.PERSONS_CARD_POPUP: {
        return <PersonCardsPopup {...popup} />;
      }
      case POPUP_CONSTANTS.VERIFY_PERSON_POPUP: {
        return (
          <VerifyPersonPopup
            personRejectReasons={this.state.personRejectReasons}
            pageStatus={this.state.pageStatus}
            errorScreen={this.state.globalError}
            viewHistory={id => this.viewHistory('person', id)}
            {...popup}
          />
        );
      }

      case POPUP_CONSTANTS.VERIFY_VEHICLE_POPUP: {
        return (
          <VerifyVehiclePopup
            vehicleRejectReasons={this.state.vehicleRejectReasons}
            pageStatus={this.state.pageStatus}
            errorScreen={this.state.globalError}
            viewHistory={id => this.viewHistory('vehicle', id)}
            {...popup}
          />
        );
      }
      case POPUP_CONSTANTS.VIEW_DOCUMENT: {
        return <ViewDocument {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };

  viewHistory = (type, id) => {
    const { history, location } = this.props;
    console.log('view history called');
    if (type === 'person') {
      // call the personAPI to get history and push to history
      this.getPersonHistory(id);
    } else {
      // call the vehicleAPI to get the history and push to history
      this.getVehicleHistory(id);
    }
  };

  getPersonHistory = id => {
    const { history, location } = this.props;
    this.showSpinner();

    API.viewPersonHistory(id)
      .then(res => {
        history.push({
          pathname: location.pathname + '/view-history',
          state: {
            background: location,
            history: res.data,
            rejectReasons: this.state.personRejectReasons,
          },
        });
        this.setState({
          popup: null,
        });
      })
      .catch(err => {
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };
  getVehicleHistory = id => {
    const { history, location } = this.props;

    API.viewVehicleHistory(id)
      .then(res => {
        history.push({
          pathname: location.pathname + '/view-history',
          state: {
            background: location,
            history: res.data,
            rejectReasons: this.state.vehicleRejectReasons,
          },
        });
        this.setState({
          popup: null,
        });
      })
      .catch(err => {
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };

  getProps = () => {
    const {
      dates,
      formData,
      personsAndVehicles,
      filteredPersonsAndVehicles,
      searchText,
      pageStatus,
      statuses,
      selectedStatus,
      entityTypeOptions,
      selectedEntity,
      timestamp,
    } = this.state;
    return {
      onClick: this.onClickHandler,
      onChange: this.onChangeHandler,
      dates,
      formData,
      personsAndVehicles,
      filteredPersonsAndVehicles,
      searchText,
      statuses,
      selectedStatus,
      entityTypeOptions,
      selectedEntity,
      timestamp,
    };
  };

  render() {
    const viewProps = this.getProps();
    const { popup } = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

// export default withRouter(PersonCards);
export default withRouter(VerifyRegistrations);
