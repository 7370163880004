export const gridConfig = {
  importColumns: [
    {
      label: 'S. No.',
      bodyKey: 'id',
      width: '50px',
      type: 'inputNumber',
      hidden: true,
    },
    {
      label: 'Vessel Type',
      width: '100px',
      bodyKey: 'vesselType',
      type: 'select',
      options: 'typeOptions',
    },
    {
      label: 'Vessel Name',
      width: '100px',
      bodyKey: 'vesselName',
      type: 'input',
    },

    {label: 'VCN', width: '100px', bodyKey: 'vcn', type: 'input'},
    {label: 'Bill of Entry', width: '100px', bodyKey: 'boe', type: 'input'},
    {label: 'IGM', width: '100px', bodyKey: 'igmnumber', type: 'input'},

    {
      label: 'Cargo Type',
      type: 'select',
      options: 'cargoTypeOptions',
      width: '100px',
      bodyKey: 'cargoType',
    },
    {
      label: 'Cargo Name',
      type: 'input',
      width: '100px',
      bodyKey: 'cargoName',
    },
    {label: 'Line No.', width: '100px', bodyKey: 'lineNo', type: 'inputNumber'},

    {label: 'CHA', width: '100px', bodyKey: 'cha', type: 'input'},
    {label: 'Importer', width: '100px', bodyKey: 'importer', type: 'input'},

    {
      label: 'Manifest Qty (MTS)',
      bodyKey: 'manifestQuantity',
      width: '50px',
      type: 'inputNumber',
    },
    {
      label: 'Balance Qty (MTS)',
      bodyKey: 'balanceQuantity',
      width: '50px',
      type: 'inputNumber',
    },

    {
      label: 'No. of Pieces',
      width: '150px',
      bodyKey: 'noOfPieces',
      type: 'inputNumber',
    },
    {
      label: 'UOM',
      type: 'select',
      options: 'UOMOptions',
      width: '100px',
      bodyKey: 'uom',
    },
    {
      label: 'Delivery Upto',
      type: 'date',
      width: '100px',
      bodyKey: 'deliveryUpto',
    },
  ],

  exportColumns: [
    {
      label: 'Sl. No.',
      bodyKey: 'id',
      width: '50px',
      type: 'inputNumber',
      hidden: true,
    },
    {
      label: 'Vessel Type',
      width: '100px',
      bodyKey: 'vesselType',

      type: 'select',
      options: 'typeOptions',
    },
    {
      label: 'Vessel Name',
      width: '100px',
      bodyKey: 'vesselName',
      type: 'input',
    },
    {label: 'VCN', width: '100px', bodyKey: 'vcn', type: 'input'},
    {
      label: 'Cargo Type',
      type: 'select',
      options: 'cargoTypeOptions',
      width: '100px',
      bodyKey: 'cargoType',
    },
    {
      label: 'Cargo Name',
      type: 'input',
      width: '100px',
      bodyKey: 'cargoName',
    },
    {
      label: 'S/B No.',
      width: '100px',
      bodyKey: 'shippingBillNumber',
      type: 'inputNumber',
    },

    {label: 'CHA', width: '100px', bodyKey: 'cha', type: 'input'},
    {label: 'Exporter', width: '100px', bodyKey: 'exporter', type: 'input'},
    {
      label: 'Manifest Qty (MTS)',
      bodyKey: 'manifestQuantity',
      width: '50px',
      type: 'inputNumber',
    },
    {
      label: 'Balance Qty (MTS)',
      bodyKey: 'balanceQuantity',
      width: '50px',
      type: 'inputNumber',
    },

    {
      label: 'No. of Pieces',
      width: '150px',
      bodyKey: 'noOfPieces',
      type: 'inputNumber',
    },
    {
      label: 'UOM',
      type: 'select',
      options: 'noOfBagsOptions',
      width: '100px',
      bodyKey: 'noOfBags',
    },
    {
      label: 'Delivery Upto',
      type: 'date',
      width: '100px',
      bodyKey: 'deliveryUpto',
    },
  ],
};

export const importDropdownOptions = {
  cargoTypeOptions: [
    {label: 'Dry Bulk', value: 'Dry Bulk'},
    {label: 'Break Bulk', value: 'Break Bulk'},
    {label: 'Both', value: 'Both'},
  ],
  cargoNameOptions: [
    {label: 'Bauxite', value: 'Bauxite'},
    {label: 'Coking Coal', value: 'Coking Coal'},
    {label: 'DAP', value: 'DAP'},
    {label: 'Gypsum', value: 'Gypsum'},
    {label: 'Iron Ore', value: 'Iron Ore'},
    {label: 'MOP', value: 'MOP'},
    {label: 'OPC Clinker', value: 'OPC Clinker'},
    {label: 'Project Cargo', value: 'Project Cargo'},
    {label: 'Rock Phosphate', value: 'Rock Phosphate'},
    {label: 'Steam Coal', value: 'Steam Coal'},
    {label: 'Sulphur', value: 'Sulphur'},
    {label: 'Timber', value: 'Timber'},
    {label: 'Urea', value: 'Urea'},
  ],
  UOMOptions: [
    {label: 'Pieces', value: 'Pieces'},
    {label: 'Bags', value: 'Bags'},
  ],
  typeOptions: [
    {label: 'Foreign Vessel', value: 'Foreign Vessel'},
    {label: 'Coastal Vessel', value: 'Coastal Vessel'},
  ],
};
export const exportDropdownOptions = {
  cargoTypeOptions: [
    {label: 'Dry Bulk', value: 'Dry Bulk'},
    {label: 'Break Bulk', value: 'Break Bulk'},
    {label: 'Both', value: 'Both'},
  ],
  cargoNameOptions: [
    {label: 'Bauxite', value: 'Bauxite'},
    {label: 'Coking Coal', value: 'Coking Coal'},
    {label: 'DAP', value: 'DAP'},
    {label: 'Gypsum', value: 'Gypsum'},
    {label: 'Iron Ore', value: 'Iron Ore'},
    {label: 'MOP', value: 'MOP'},
    {label: 'OPC Clinker', value: 'OPC Clinker'},
    {label: 'Project Cargo', value: 'Project Cargo'},
    {label: 'Rock Phosphate', value: 'Rock Phosphate'},
    {label: 'Steam Coal', value: 'Steam Coal'},
    {label: 'Sulphur', value: 'Sulphur'},
    {label: 'Timber', value: 'Timber'},
    {label: 'Urea', value: 'Urea'},
  ],
  noOfBagsOptions: [
    {label: 'Pieces', value: 'Pieces'},
    {label: 'Bags', value: 'Bags'},
  ],
  typeOptions: [
    {label: 'Foreign Vessel', value: 'Foreign Vessel'},
    {label: 'Coastal Vessel', value: 'Coastal Vessel'},
  ],
};

export const mockData = [
  {
    cargoType: 'Dry Bulk',
    noOfPieces: 0,
    vcn: 'IXY12022031068',
    importer: 'AGARWAL COAL CORP ',
    uom: '',
    lineNo: 1,
    quantityInMT: 10000,
    id: 1,
    deliveryUpto: '10-Mar-22',
    cha: 'RIL',
    vesselName: 'AGRI BRIGHT',
  },
  {
    cargoType: 'Dry Bulk',
    noOfPieces: 0,
    vcn: 'IXY12022031068',
    importer: 'AGARWAL COAL CORP ',
    uom: '',
    lineNo: 2,
    quantityInMT: 10000,
    id: 2,
    deliveryUpto: '10-Mar-22',
    cha: 'RIL',
    vesselName: 'AGRI BRIGHT',
  },
  {
    cargoType: 'Dry Bulk',
    noOfPieces: 0,
    vcn: 'IXY12022031068',
    importer: 'AGARWAL COAL CORP ',
    uom: '',
    lineNo: 3,
    quantityInMT: 5000,
    id: 3,
    deliveryUpto: '10-Mar-22',
    cha: 'RIL',
    vesselName: 'AGRI BRIGHT',
  },
  {
    cargoType: 'Dry Bulk',
    noOfPieces: 0,
    vcn: 'IXY12022031068',
    importer: 'AGARWAL COAL CORP ',
    uom: '',
    lineNo: 4,
    quantityInMT: 5000,
    id: 4,
    deliveryUpto: '10-Mar-22',
    cha: 'RIL',
    vesselName: 'AGRI BRIGHT',
  },
  {
    cargoType: 'Dry Bulk',
    noOfPieces: 0,
    vcn: 'IXY12022031068',
    importer: 'AGARWAL COAL CORP ',
    uom: '',
    lineNo: 5,
    quantityInMT: 3000,
    id: 5,
    deliveryUpto: '10-Mar-22',
    cha: 'RIL',
    vesselName: 'AGRI BRIGHT',
  },
  {
    cargoType: 'Dry Bulk',
    noOfPieces: 0,
    vcn: 'IXY12022031068',
    importer: 'AGARWAL COAL CORP ',
    uom: '',
    lineNo: 6,
    quantityInMT: 2000,
    id: 6,
    deliveryUpto: '10-Mar-22',
    cha: 'RIL',
    vesselName: 'AGRI BRIGHT',
  },
  {
    cargoType: 'Dry Bulk',
    noOfPieces: 0,
    vcn: 'IXY12022031068',
    importer: 'AGARWAL COAL CORP ',
    uom: '',
    lineNo: 7,
    quantityInMT: 3250,
    id: 7,
    deliveryUpto: '10-Mar-22',
    cha: 'RIL',
    vesselName: 'AGRI BRIGHT',
  },
  {
    cargoType: 'Dry Bulk',
    noOfPieces: 0,
    vcn: '2022031082',
    importer: 'BALAJI MALTS PVT LTD.',
    uom: '',
    lineNo: 1,
    quantityInMT: 50000,
    id: 8,
    deliveryUpto: '10-Mar-22',
    cha: 'K S CHHAYA',
    vesselName: 'M V ALEXANDRA',
  },
];
