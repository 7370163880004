import React, { Component } from 'react';
import Flex from '../../components/Flex';
import FormElement from '../../components/FormElement';
import styles from './styles.module.scss';
import util from '../../util/index';
import axios from 'axios';
import Razorpay from 'razorpay';
import * as CryptoJS from 'crypto-js';

import logo from '../../assets/images/Logo/logo.png';
import { loginImages } from '../../assets/images';
import strings from '../../globalization';
import PopupContainer from '../../components/PopupContainer';
import Spinner from '../../components/Spinner';
import Privacy from '../../components/Privacy';
import { privacyData, termsData } from './data';

import { withRouter } from 'react-router-dom';
import ViewRenderer from './views';
import { API } from '../../requests';
import PAYMENT_CONSTANTS from '../../constants/payment-constants';

const POPUP_CONSTANTS = {
  STANDARD_POPUP: 'standard-popup',
  ERROR_POPUP: 'error-popup',
  SPINNER_POPUP: 'spinner-popup',
  USER_NOT_CONFIRM: 'user-not-confirm',
  SIMPLE_POPUP: 'simple-popup',
  PRIVACY_POPUP: 'privacy-popup',
};

class Payments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: 1,
      pageStatus: null,
      orderId: this.props.location.state.paymentDetails.orderId,
      RAZORPAY_API_KEY_ID: null,
    };
  }

  onChangeHandler = value => {
    this.setState({
      amount: value,
    });
  };
  componentDidMount() {
    this.getPaymentDetails();
  }

  getPaymentDetails = () => {
    API.getPaymentProperties()
      .then(res => {
        const { key, secret } = res.data || {};
        // console.log(key, secret);
        const RAZORPAY_API_KEY_ID = this.decryptData(key);
        // const RAZORPAY_API_SECRET_ID = this.decryptData(secret);

        this.setState({
          RAZORPAY_API_KEY_ID: RAZORPAY_API_KEY_ID
        });
      })
      .catch(err => console.log('failed...', err));
  }

  secretKey = 'DPT@mRoads@12345';

  decryptData = (encryptedData) => {
    const key = CryptoJS.enc.Utf8.parse(this.secretKey);
    const decrypted = CryptoJS.AES.decrypt(encryptedData, key, {
      keySize: 128 / 8,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }



  displayRazorpay = async () => {
    console.info('display razorpay -->');
    const { paymentDetails } = this.props.location.state;
    console.log('ORDER ID####', paymentDetails);
    const { RAZORPAY_API_KEY_ID } = this.state;
    const res = await this.loadRazorpay();
    this.showSpinner();

    if (!res) {
      alert('Razorpay SDK Failed to load');
      return;
    }

    this.setState({
      popup: null,
    });

    // console.log(data);

    var options = {
      key: RAZORPAY_API_KEY_ID, // Enter the Key ID generated from the Dashboard
      name: 'e-Drishti Access Control System',
      currency: 'INR',
      amount:
        (parseInt(paymentDetails.tax) + parseInt(paymentDetails.subTotal)) *
        100,
      order_id: this.state.orderId,
      description: 'Thankyou!',
      image: { logo },
      handler: response => {
        console.log('RESPONSE...', response);
        let successDetails = {
          orderId: response.razorpay_order_id,
          paymentId: response.razorpay_payment_id,
          signature: response.razorpay_signature,
        };
        this.callSuccessAPI(successDetails);
      },
      modal: {
        ondismiss: data => {
          const errorDetails = {
            orderId: this.state.orderId,
            paymentId: 'HARDCODED',
            code: 'FAILED-HARDCODED',
          };
          this.callFailureAPI(errorDetails);
          // this.setPaymentFailure
        },
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };
  callSuccessAPI = data => {
    const { type } = this.props.location.state;
    this.showSpinner();
    switch (type) {
      case PAYMENT_CONSTANTS.PERSON: {
        API.personPaymentSuccess(data)
          .then(res => {
            console.log('person success successful...', res.data);
            if (res?.data?.paymentStatus === 'success') {
              this.setState({
                pageStatus: 'success',
                popup: null,
              });
            } else {
              this.setState({
                pageStatus: 'pending',
                popup: null,
              });
            }
            this.closePopup();
          })
          .catch(err => console.log('success failed...', err));
        break;
      }
      case PAYMENT_CONSTANTS.VEHICLE: {
        API.vehiclePaymentSuccess(data)
          .then(res => {
            console.log('vehicle success successful...', res.data);
            if (res?.data?.paymentStatus === 'success') {
              this.setState({
                pageStatus: 'success',
                popup: null,
              });
            } else {
              this.setState({
                pageStatus: 'pending',
                popup: null,
              });
            }
            this.closePopup();
          })
          .catch(err => console.log('success failed...', err));
        break;
      }
      default: {
        return;
      }
    }
  };
  callFailureAPI = data => {
    const { type } = this.props.location.state;
    this.showSpinner();

    switch (type) {
      case PAYMENT_CONSTANTS.PERSON: {
        //call person API
        API.personPaymentFailure(data)
          .then(res => {
            console.log('failure successful...', res.data);
            if (res?.data?.paymentStatus === 'fail') {
              this.setState({
                pageStatus: 'failure',
                popup: null,
              });
            } else {
              this.setState({
                pageStatus: 'pending',
                popup: null,
              });
            }

            this.closePopup();
          })
          .catch(err => console.log('failure failed...', err));
        break;
      }
      case PAYMENT_CONSTANTS.VEHICLE: {
        //call vehicle API
        API.vehiclePaymentFailure(data)
          .then(res => {
            console.log('failure successful...', res.data);

            if (res?.data?.paymentStatus === 'fail') {
              this.setState({
                pageStatus: 'failure',
                popup: null,
              });
            } else {
              this.setState({
                pageStatus: 'pending',
                popup: null,
              });
            }
            this.closePopup();
          })
          .catch(err => console.log('failure failed...', err));
        break;
      }
      default: {
        return;
      }
    }
  };

  setPaymentSuccess = response => {
    this.setState({
      pageStatus: 'success',
      paymentDetails: {
        paymentId: response.razorpay_payment_id,
        orderId: response.razorpay_order_id,
        signature: response.razorpay_signature,
      },
    });
  };
  setPaymentFailure = () => {
    console.info('FAILED##-->');
    this.setState({
      pageStatus: 'failure',
    });
  };
  loadRazorpay = () => {
    return new Promise(resolve => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      // document.body.appendChild(script);

      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };
  backClick = () => {
    const { history } = this.props;
    history.goBack();
  };
  getPopupContent = () => {
    const { popup } = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="blue" />;
      }
      case POPUP_CONSTANTS.PRIVACY_POPUP: {
        return (
          <Privacy
            onClose={this.closePopup}
            messageObject={privacyData}
            heading="Privacy Policy"
          />
        );
      }
      case POPUP_CONSTANTS.TERMS_POPUP: {
        return (
          <Privacy
            onClose={this.closePopup}
            messageObject={termsData}
            heading="Terms and Conditions"
          />
        );
      }
      default: {
        console.warn('case not handled', popup.type);
        return null;
      }
    }
  };
  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };
  showPrivacyPopup = popUpType => {
    this.setState({
      popup: {
        type: popUpType,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  closePopup = () => {
    this.setState({
      popup: null,
    });
  };
  handleClick = action => {
    const { history } = this.props;
    switch (action) {
      case 'privacy': {
        this.showPrivacyPopup(POPUP_CONSTANTS.PRIVACY_POPUP);
        break;
      }
      case 'terms': {
        this.showPrivacyPopup(POPUP_CONSTANTS.TERMS_POPUP);
        break;
      }
      case 'goBackToHome': {
        history.push('/eDrishti');
        break;
      }
      case 'goBackToLogin': {
        history.push('/signin');
        break;
      }
      case 'payment': {
        this.displayRazorpay();
        break;
      }
      default: {
        break;
      }
    }
  };

  getProps = () => {
    const { paymentDetails, id, title, type } = this.props.location.state;
    const { pageStatus } = this.state;
    return {
      paymentDetails,
      id,
      title,
      pageStatus,
      handleClick: this.handleClick,
      backClick: this.backClick,
      type,
    };
  };

  render() {
    const { popup } = this.state;
    const props = this.getProps();

    console.log('this.props', this.props);
    // console.log('this.props.location', this.props.location);
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...props} />
      </>
    );
  }
}

// export default withRouter(PersonCards);
export default withRouter(Payments);
