import React, {Component} from 'react';
import styles from './styles.module.scss';
import AuthLayout from '../../../components/AuthLayout';
import {keys, INPUT_CONSTANTS, formFields, buttonGroup} from './data';
import TextInput from '../../../components/TextInput';
import SeparatorComponent from '../../../components/Sparator';
import PopupContainer from '../../../components/PopupContainer';
import Popup from '../../../components/Popup';

import ViewRenderer from './views';
export default class AgencyRquests extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getInputType = element => {
    switch (element.type) {
      case INPUT_CONSTANTS.PASSWORD:
      case INPUT_CONSTANTS.NUMBER:
      case INPUT_CONSTANTS.TEXT: {
        return (
          <TextInput
            label={element.label}
            lblDirection="row"
            onChange={update => {
              this.onChange(element.field, update);
            }}
            isEdit={true}
            type={element.type}
            placeholder={element.placeholder}
            //inputStyle={styles.inputTextAddress}
            required={element.required}
            name={element.field}
            error={this.state[element.field].error}
            value={this.state[element.field]}
          />
        );
      }
      default: {
        console.warn('case not handled', element.type);
        return null;
      }
    }
  };

  /**
   *
   * @param {*} action
   * @param {*} data
   */

  onClickHandler = (action, data) => {
    const {history} = this.props;
    switch (action) {
      case 'signin': {
        history.push('/sign-in');
        break;
      }
      case 'vehicle-registration': {
        history.push('/vehicle-registration');
        break;
      }
      default: {
        console.info('case not handled', action);
      }
    }
  };

  getProps = () => {
    return {
      onClick: this.onClickHandler,
    };
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };

  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      // case "spinner": {
      //   return <Spinner />;
      // }
      case 'standard': {
        return <Popup {...popup} />;
      }
      default: {
        console.warn('case not handled', popup.type);
        return null;
      }
    }
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}
