import {createStore, applyMiddleware, compose} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from './reducers';
import storage from 'redux-persist/lib/storage';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import {logoutAction, setLoginData} from './actions/login';
import {updateMaterial} from './actions/permits';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['userPreferences'],
  stateReconciler: autoMergeLevel2,
};

const initialState = {};

const pReducer = persistReducer(persistConfig, rootReducer);
const middlewares = [thunkMiddleware];
const middlewareEnhancer = applyMiddleware(...middlewares);

// #region createStore : enhancer
const enhancers = [middlewareEnhancer];

const composedEnhancers =
  process.env.NODE_ENV === 'production'
    ? compose(...enhancers)
    : composeWithDevTools(...enhancers);

export const store = createStore(pReducer, initialState, composedEnhancers);

export const persistor = persistStore(store);

export function dispatchSetLoginData(data) {
  store.dispatch(setLoginData(data));
}
export function dispatchSetLogoutData() {
  store.dispatch(logoutAction());
}

export function dispatchUpdateMaterial(data) {
  store.dispatch(updateMaterial(data));
}
