import Spinner from '../components/Spinner';
import SimplePopup from '../components/SimplePopup';
import OtpPopup from '../components/OtpPopup';
import POPUP_CONSTANTS from '../constants/popup-constants';
import {commonImages} from '../assets/images';
import strings from '../globalization';
export function getPopupContent() {
  const {popup} = this.state;
  if (!popup) return;
  switch (popup.type) {
    case POPUP_CONSTANTS.SPINNER_POPUP: {
      return <Spinner name="cube-grid" color="blue" />;
    }
    case POPUP_CONSTANTS.SIMPLE_POPUP: {
      return <SimplePopup {...popup} />;
    }
    case POPUP_CONSTANTS.OTP_POPUP: {
      return (
        <OtpPopup
          onClose={this.closePopup}
          phoneNumber={this.state?.phoneNumber?.value}
          onOtpVerifySuccess={this.onOtpVerifySuccess}
          message="An OTP has been sent to your mobile number"
          heading="Verify Your Mobile Number"
          showErrorPopup={errorMessage => {
            showErrorPopup(errorMessage);
          }}
        />
      );
    }
    default: {
      console.warn('case not handled', popup.type);
      return null;
    }
  }
}

export function showOtpPopup() {
  this.setState({
    popup: {
      type: POPUP_CONSTANTS.OTP_POPUP,
    },
  });
}

export function closePopup() {
  this.setState({
    popup: undefined,
  });
}

export function showSpinner() {
  this.setState({
    popup: {
      type: POPUP_CONSTANTS.SPINNER_POPUP,
    },
  });
}

export function showErrorPopup(errorMessage) {
  this.setState({
    popup: {
      type: POPUP_CONSTANTS.SIMPLE_POPUP,
      message: errorMessage,
      messageStyle: {color: '#E67717'},
      headingImage: commonImages.errorIcon,
      onClose: this.closePopup,
      buttons: [
        {
          text: strings.okayPopup,
          onClick: this.closePopup,
          outline: true,
        },
      ],
    },
  });
}

// export function showSuccessPopup(successMessage) {
//   this.setState({
//     popup: {
//       type: POPUP_CONSTANTS.SIMPLE_POPUP,
//       message: successMessage,
//       onClose: this.closePopup,
//       headingImage: commonImages.successIcon,
//       buttons: [
//         {
//           text: strings.okayPopup,
//           onClick: this.closePopup,
//           outline: true,
//         },
//       ],
//     },
//   });
// }
