import React, {Component} from 'react';
import {getOperators} from './data';

import ViewRenderer from './views';

class TableTesting extends Component {
  constructor(props) {
    super(props);
    this.operators = getOperators();

    this.state = {};
  }

  getProps = () => {
    return {
      operators: this.operators,
    };
  };

  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return <ViewRenderer {...viewProps} />;
  }
}

// export default withRouter(PersonCards);
export default TableTesting;
