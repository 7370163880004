import React from 'react';
import AuthLayout from '../../../../../components/AuthLayout';
import {keys, INPUT_CONSTANTS, formFields, buttonGroup} from '../../data';
import TextInput from '../../../../../components/TextInput';
import SeparatorComponent from '../../../../../components/Sparator';
import {withRouter} from 'react-router-dom';
import strings from '../../../../../globalization';
import CustomInput from '../../../../../components/CustomInput';
import Button from '../../../../../components/Button';
import Flex from '../../../../../components/Flex';
import UserIcon from '../../../../../assets/images/user-icon/image.png';
import styles from './Mobile.module.scss';
import packageJson from '../../../../../../package.json';

const Mobile = props => {
  const {onClick, onChangeState} = props;
  const handleClick = action => {
    console.info('handleClick...', action);
    onClick(action);
    // const { history } = this.props;
    // history.push(action ? action : "signin");
  };
  const onChange = (action, data) => {
    // console.info('onChange...', action);
    onChangeState(action, data);
    // this.setState((prevState) => {
    //   return {
    //     ...prevState,
    //     [action]: data,
    //   };
    // });
  };

  const getInputType = element => {
    return (
      <CustomInput
        labelStyles={styles.customLabel}
        inputLable={element.label()}
        onChange={update => onChange(element.field, update.target.value)}
        type={element.type}
        placeholder={element.placeholder()}
        // required={element.required}
        iconStyles={{height: '3vh', aspectRatio: 1}}
        name={element.field}
        leftIcon={element.leftIcon}
        style={{width: '100%', height: '40px'}}
        containerStyles={{marginBottom: '20px'}}
      />
    );
  };
  return (
    <div className={styles.loginContainer}>
      <div className={styles.logoContainer}>
        <img src="./static/images/Logo/logo.png" alt="Logo" />
        <h2>{keys.title}</h2>
        <h4>{keys.subTitle}</h4>
      </div>
      <div className={styles.formContainer}>
        <p className={styles.loginText}>{strings.login}</p>
        {formFields.map(field => getInputType(field))}
        <div className={styles.divider} />
        <div className={styles.forgotPasswordRow}>
          <a
            href=""
            onClick={e => {
              e.preventDefault();
              handleClick('forgot-password');
            }}>
            {strings.forgotPassword}
          </a>
        </div>

        <Button
          buttonStyle="primary"
          label={strings.login}
          className={styles.submitButton}
          onClick={() => handleClick('login')}
        />
      </div>

      <div className={styles.create_user}>
        <span>{strings.dontHaveAgentAccount} </span>
        <a href="" onClick={() => handleClick('newAgencyRegistration')}>
          {strings.createNew}
        </a>
      </div>
      <SeparatorComponent />
      <div className={styles.button_group_section}>
        {buttonGroup.map(buttonObject => {
          return (
            // <button
            //   onClick={() => handleClick(buttonObject.navigate)}
            //   className={styles.buttonPrimaryOutlined}
            // >
            //   {buttonObject.label}
            // </button>
            <Button
              label={buttonObject.label()}
              className={styles.buttonPrimaryOutlined}
              onClick={() => handleClick(buttonObject.navigate)}
              disabled={buttonObject.disabled}
            />
          );
        })}
      </div>
      <div className={styles.footerContainer}>
        {/* <div className={styles.mobilePlayStoreIcons}>
          Download the app from:{' '}
          <img
            className={styles.playStoreIcon}
            src={loginImages.playStoreIcon}></img>
          {' or '}
          <img
            className={styles.appStoreIcon}
            src={loginImages.appStoreIcon}></img>
        </div> */}
        <div>
          <span
            className={styles.privacy}
            onClick={() => handleClick('privacy')}>
            {strings.privacyPolicy}
          </span>
          <span className={styles.terms} onClick={() => handleClick('terms')}>
            {strings.termsOfUse}
          </span>
          <span className={styles.help}> {strings.helpCenter}</span>
          <span className={styles.help}> {strings.useFulResource}</span>
        </div>
        <div className={styles.copyright}>{strings.copyRight}</div>
      </div>
      <div className={styles.updatedDate}>
        Last updated: {' ' + packageJson.updatedDate}
      </div>
    </div>
  );
};
export default withRouter(Mobile);
