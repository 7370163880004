import React, {useState} from 'react';
import styles from './Desktop.module.scss';
import './styles.css';
import strings from '../../../../../globalization';
import {withRouter} from 'react-router-dom';
import {AgGridReact} from 'ag-grid-react';
import Select from 'react-select';
import moment from 'moment';
import 'react-daterange-picker/dist/css/react-calendar.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import dimensions from '../../../../../util/dimensions-util';
import {customStyles} from './customStyles';
import {adminImages} from '../../../../../assets/images';
import DateRangePicker from 'react-daterange-picker';
import TextInput from '../../../../../components/TextInput';
import Button from '../../../../../components/Button';

import calendarBlue from '../../../../../assets/images/calendar-blue-icon/image.png';
import CustomTimePicker from '../../../../../components/CustomTimePicker';
import TimePicker from 'rc-time-picker';
import Flex from '../../../../../components/Flex';
import {CSVLink} from 'react-csv';

const {vw: widthConstant, vh: heightConstant} = dimensions;

const entrySummaryColumnDef = [
  {
    headerName: 'Sl. No.',
    field: 'slNo',
    headerClass: styles.rowHeader,
    width: 100 * widthConstant,
    resizable: true,
    valueGetter: 'node.rowIndex + 1',
    cellStyle: {display: 'flex', alignItems: 'center'},
  },
  {
    headerName: 'Gate ID',
    field: 'gateID',
    headerClass: styles.rowHeader,
    width: 100 * widthConstant,
    resizable: true,

    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {readerName}}) => {
      return <div className={styles.sessionTime}>{readerName}</div>;
    },
  },
  {
    headerName: 'Vehicle Number',
    field: 'vehicleNumber',
    headerClass: styles.rowHeader,
    width: 170 * widthConstant,
    resizable: true,

    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {vehicleRegistrationNumber}}) => {
      return (
        <div className={styles.sessionTime}>{vehicleRegistrationNumber}</div>
      );
    },
  },
  {
    headerName: 'Status at the Gate',
    field: 'statusAtTheGate',
    headerClass: styles.rowHeader,
    width: 150 * widthConstant,

    resizable: true,

    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {status}}) => {
      return <div className={styles.sessionTime}>{status}</div>;
    },
  },

  {
    headerName: 'CHA Name',
    field: 'CHAName',
    headerClass: styles.rowHeader,
    width: 200 * widthConstant,
    resizable: true,

    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {companyName}}) => {
      return <div className={styles.sessionTime}>{companyName}</div>;
    },
  },
  {
    headerName: 'Driver Name (if approved)',
    field: 'driverName',
    headerClass: styles.rowHeader,
    width: 170 * widthConstant,
    resizable: true,

    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {driverName}}) => {
      return (
        <div className={styles.sessionTime}>
          {driverName ? driverName : '-'}
        </div>
      );
    },
  },
  {
    headerName: 'Driver Company Name',
    field: 'driverCompanyName',
    headerClass: styles.rowHeader,
    width: 210 * widthConstant,

    resizable: true,
    wrapText: true,
    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {driverCompanyName}}) => {
      return (
        <div className={styles.sessionTime}>
          {driverCompanyName ? driverCompanyName : '-'}
        </div>
      );
    },
  },

  {
    headerName: 'Reason (if-rejected)',
    field: 'rejectionReason',
    headerClass: styles.rowHeader,
    width: 250 * widthConstant,
    wrapText: true,
    resizable: true,
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 1.2,
      wordBreak: 'initial',
    },
    cellRendererFramework: ({data: {error}}) => {
      return <div>{error ? error : '-'}</div>;
    },
  },

  {
    headerName: 'Timestamp at Gate',
    field: 'timestamp',
    headerClass: styles.rowHeader,
    width: 200 * widthConstant,
    resizable: true,
    wrapText: true,
    cellStyle: {display: 'flex', alignItems: 'center', lineHeight: 1.2},
    cellRendererFramework: ({data: {createdDate}}) => {
      return <div>{moment(createdDate).format('MMMM Do YY h: mm a')}</div>;
    },
  },
];

const Desktop = props => {
  const {
    onClick,
    onChange,
    searchText,
    dates,
    rejectionList,
    startTime,
    endTime,
    selectedStatus,
    statuses,
    CHAList,
    selectedCHA,
    downloadData,
    downloadHeaders,
  } = props;

  const [showDate, setShowDate] = useState(false);

  const onCellClick = (event, index) => {
    onClick('rowClick', event.data);
  };
  const onSelect = dates => {
    onChange('date', {
      startDate: moment(dates.start).format('MM-DD-YYYY'),
      endDate: moment(dates.end).format('MM-DD-YYYY'),
      dates,
    });
    toggleDate();
  };
  const toggleDate = () => {
    setShowDate(!showDate);
  };

  const formatDate = date => moment(date).format('MMM Do YY');
  const rowStyle = {border: 'none', cursor: 'pointer'};
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return {background: '#f6f6f6', border: 'none'};
    }
  };
  const handleClear = () => {
    setShowDate(false);
    onClick('clearFilters');
  };
  const handleClick = () => {
    setShowDate(false);
    onClick('apply');
  };
  const handleSelect = (action, data) => {
    setShowDate(false);
    onChange(action, data);
  };

  return (
    <div className={styles.agencyContainer}>
      <div className={styles.headerText}>{`Vehicle Movement Reports`}</div>
      <div className={styles.header}>
        <div className={styles.left}>
          <Flex direction="column" className={styles.leftTop}>
            <div className={styles.status}>
              <Select
                options={statuses}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                placeholder={strings.ADMIN.Status}
                styles={customStyles}
                menuPosition={'fixed'}
                value={statuses.filter(
                  option => option?.id === selectedStatus?.id,
                )}
                // onChange={data => {
                //   onChange('status', data);
                // }}
                onChange={data => {
                  handleSelect('status', data);
                }}
              />

              <Select
                options={CHAList}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                placeholder={`Select Agency`}
                styles={customStyles}
                menuPosition={'fixed'}
                value={CHAList.filter(option => option?.id === selectedCHA?.id)}
                // onChange={data => {
                //   onChange('CHA', data);
                // }}
                onChange={data => {
                  handleSelect('CHA', data);
                }}
              />
            </div>

            <Flex direction="row">
              <div className={styles.dateWrapper}>
                <div onClick={toggleDate} className={styles.inputDate}>
                  {' '}
                  <div>
                    {' '}
                    {dates
                      ? formatDate(dates.start) + '-' + formatDate(dates.end)
                      : strings.ADMIN.dateRange}
                  </div>{' '}
                  <img src={calendarBlue} className={styles.calendar} alt="C" />
                </div>
                {showDate ? (
                  <div className={styles.datePicker}>
                    <DateRangePicker
                      numberOfCalendars={1}
                      selectionType="range"
                      onSelect={onSelect}
                      className={styles.dateRange}
                      value={dates}
                      singleDateRange={true}
                    />
                  </div>
                ) : null}
              </div>
              <div>
                <TimePicker
                  // use12Hours
                  showSecond={false}
                  // FIXME: Change these
                  value={startTime}
                  onChange={time => onChange('startTime', time)}
                  className={styles.timepicker}
                  placeholder={`Start Time`}
                  format={'HH:mm'}
                />
                <TimePicker
                  // use12Hours
                  showSecond={false}
                  // FIXME: Change these
                  value={endTime}
                  onChange={time => onChange('endTime', time)}
                  className={styles.timepicker}
                  placeholder={`End Time`}
                  format={'HH:mm'}
                />
              </div>
            </Flex>
          </Flex>
        </div>

        <div className={styles.right}>
          <div className={styles.inputSearch}>
            <TextInput
              // onChange={text => {
              //   onChange('search', text);
              // }}
              onChange={text => {
                handleSelect('search', text);
              }}
              isEdit={true}
              type={'text'}
              value={searchText}
              inputStyle={styles.inputText}
              showSeacrh={true}
              placeholder="Search"
            />
          </div>
          <div onClick={handleClear} className={styles.clearLabel}>
            {' '}
            {strings.ADMIN.clear}
          </div>
          {rejectionList && rejectionList.length > 0 ? (
            <CSVLink
              className={styles.downloadBtn}
              data={downloadData}
              filename={`vehicle-movement-edrishti-${moment().format(
                `DD-MM-YYYY`,
              )}.csv`}
              headers={downloadHeaders}>
              Download
            </CSVLink>
          ) : null}
          <Button
            // onClick={() => onClick('apply')}
            onClick={handleClick}
            buttonStyle="primary"
            label={strings.ADMIN.apply}
            className={styles.applyBtn}></Button>
        </div>
      </div>
      <div className={['ag-theme-balham', styles.tableWrapper].join(' ')}>
        <AgGridReact
          rowStyle={rowStyle}
          getRowStyle={getRowStyle}
          rowHoverHighlight={true}
          columnDefs={entrySummaryColumnDef}
          rowHeight={50 * heightConstant}
          headerHeight={70 * heightConstant}
          defaultColDef={{sortable: true}}
          rowClass={[styles.gridRowClass]}
          pagination={true}
          paginationPageSize={10}
          animateRows={true}
          onCellClicked={(event, index) => onCellClick(event, index)}
          rowData={rejectionList ? rejectionList : []}
          domLayout="autoHeight"></AgGridReact>
      </div>
      <div className={styles.bottomLabel}>
        {' '}
        Total Count: {rejectionList ? rejectionList.length : null}
      </div>
    </div>
  );
};
export default withRouter(Desktop);
