import React from 'react';
import styles from './styles.module.scss';

import {Hero} from '../../../../components/LandingComponents/mobile/Hero/Hero';
import {Features} from '../../../../components/LandingComponents/mobile/Features/Features';
import {Solutions} from '../../../../components/LandingComponents/mobile/Solutions';
import {WhyUs} from '../../../../components/LandingComponents/mobile/WhyUs';
import {TechnologyOverview} from '../../../../components/LandingComponents/mobile/TechnologyOverview';
import {Footer} from '../../../../components/LandingComponents/mobile/Footer/Footer';
import {Contact} from '../../../../components/LandingComponents/mobile/Contact/Contact';

const Mobile = ({handleClick, formData, onChange, onSubmit, pageStatus}) => {
  return (
    <>
      <div className={styles.container}>
        <Hero />
        <Features />
        <Solutions />
        <TechnologyOverview />
        <WhyUs />
        <Contact
          formData={formData}
          onChange={onChange}
          onSubmit={onSubmit}
          pageStatus={pageStatus}
        />
        <Footer />
      </div>
    </>
  );
};

export default Mobile;
