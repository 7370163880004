import React, {useEffect, useRef, useState} from 'react';

import './styles.css';

function Loading() {
  const svgRef = useRef(null);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    const int = setInterval(() => {
      setToggle(!toggle);
      console.log('toggle...', toggle);
    }, 2000);
    return () => {
      clearInterval(int);
    };
  }, [toggle]);

  return (
    <div
      // style={{backgroundColor: 'red'}}
      // className={toggle ? 'active' : ''}
      className="container">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 280 83"
        version="1.1"
        className={toggle ? 'active' : ''}
        xmlns="http://www.w3.org/2000/svg">
        <title>saññã Logo-01</title>
        <g
          id="Web"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd">
          <g
            id="Landing-Page"
            transform="translate(-820.000000, -558.000000)"
            fill-rule="nonzero">
            <g id="saññã-Logo-01" transform="translate(821.000000, 560.000000)">
              <path
                d="M11.2982639,77.7744505 C8.15794047,76.4649502 5.40905354,74.3682476 3.31744325,71.6871113 C1.38023198,69.1879683 0.226041165,66.17254 0,63.0199899 L15.0374963,63.0199899 C15.1883684,64.6970069 16.0662705,66.2241503 17.4402731,67.1997103 C18.9638719,68.2976349 20.8088102,68.8602768 22.6865726,68.7996486 C24.5822545,68.7996486 26.0514079,68.4304321 27.0940329,67.6872655 C28.0720731,67.0532578 28.658289,65.9643412 28.6484921,64.7998029 C28.6484921,63.3797393 27.9091761,62.3241587 26.4258051,61.6425282 C24.0901607,60.6895373 21.6790328,59.9327044 19.2174748,59.3798936 C16.1954608,58.7111345 13.2232414,57.8349259 10.3219877,56.7575095 C7.93812934,55.8394175 5.82074578,54.342751 4.16102167,52.4026478 C2.44227012,50.42087 1.58289435,47.738528 1.58289435,44.3556209 C1.56580068,41.5833246 2.39213791,38.871223 3.95249667,36.578406 C5.5290721,34.2431903 7.8449634,32.3986855 10.9001707,31.0448916 C13.9553779,29.6910976 17.5887681,29.0094671 21.8003413,29 C28.0276562,29 32.9358925,30.53998 36.5250502,33.6199402 C40.1142078,36.6999003 42.1678632,40.7881056 42.6860162,45.884556 L28.6484921,45.884556 C28.471061,44.2738554 27.6530972,42.8019143 26.378413,41.7995064 C25.1146251,40.7928391 23.4401062,40.2879276 21.3548561,40.2847719 C19.5729152,40.2847719 18.2080242,40.6303207 17.2601833,41.3072177 C16.338088,41.9373714 15.8019276,42.9941311 15.8384219,44.1094765 C15.8384219,45.5295401 16.5935352,46.5945878 18.1037617,47.3046196 C19.6139883,48.0146514 21.9725324,48.7262608 25.1793943,49.4394485 C28.234596,50.1898456 31.2415785,51.1240581 34.1838831,52.2369737 C36.5504071,53.1911883 38.6452155,54.712866 40.2832394,56.6675721 C42.0304262,58.7124637 42.9356143,61.4531864 42.9988037,64.8897403 C43.0366037,67.6758042 42.1777225,70.400367 40.5486349,72.6622216 C38.9183485,74.9437904 36.5756017,76.7362264 33.5203944,78.039529 C30.1460477,79.4030816 26.5296574,80.0681665 22.8903584,79.9944832 C18.9136599,80.0693584 14.9651977,79.3131779 11.2982639,77.7744505 Z"
                id="Path"
                stroke="#000000"
                fill="#000000"
                class="svg-elem-1"></path>
              <path
                d="M88.4471897,36.5479388 C88.4471897,35.0038443 88.4800908,33.6281964 88.4471897,32.2572276 C88.4471897,31.6957386 88.6116951,31.6068362 89.1240119,31.6115153 C93.864901,31.6302316 98.6057895,31.6302316 103.346679,31.6115153 C103.905997,31.6115153 104,31.7706038 104,32.2806229 C103.981199,42.462289 103.981199,52.6423955 104,62.820942 C104,67.8041563 104,72.7873706 104,77.7705849 C104,78.3133575 103.863696,78.4303344 103.337278,78.4256553 C98.5932561,78.4007004 93.8508005,78.4007004 89.1099115,78.4256553 C88.5693937,78.4256553 88.4330893,78.2946412 88.4518899,77.7659058 C88.4941913,76.3621835 88.4518899,75.0052519 88.4518899,73.5079481 C87.9818744,74.0647579 87.6058621,74.5607398 87.1781481,75.009931 C84.9507257,77.2722494 82.0649833,78.7798556 78.9293774,79.3193585 C70.5443023,80.9897881 62.732646,79.6843264 55.8986218,74.3033907 C50.996361,70.4337961 48.2796718,65.2213072 47.3443411,59.1057569 C46.5359146,53.7996864 47.1046333,48.6293091 49.5440133,43.8005043 C53.1067302,36.7538181 58.9772228,32.5707256 66.5914726,30.7926773 C71.2372376,29.611693 76.1227255,29.7527368 80.6919353,31.1997567 C83.7587553,32.1649083 86.4616519,34.0288812 88.4471897,36.5479388 Z M75.4512633,66.9151321 C76.6494624,66.9414837 77.8462814,66.8188803 79.0139802,66.5501643 C83.8034374,65.399112 86.8726381,62.3951462 88.0711774,57.6973554 C89.2697167,52.9995647 87.784468,48.9708816 84.0431452,45.9622367 C80.1185164,42.8038614 75.5358661,42.4810053 70.8545125,43.8426159 C64.5375052,45.6814922 61.0170897,52.8545134 63.2966645,58.9373102 C65.1955268,63.9345617 69.6982746,66.9151321 75.4512633,66.9151321 Z"
                id="Shape"
                stroke="#000000"
                fill="#000000"
                class="svg-elem-2"></path>
              <path
                d="M262.451734,36.5567706 C262.451734,34.9713484 262.451734,33.5683198 262.451734,32.1652913 C262.451734,31.6976152 262.573977,31.6181102 263.006526,31.622787 C267.820987,31.622787 272.637016,31.622787 277.454612,31.622787 C277.924774,31.622787 278,31.7443828 278,32.1793216 C278,47.4068577 278,62.6328351 278,77.8572533 C278,78.2968689 277.901266,78.4137879 277.44991,78.4091111 C272.635449,78.3904041 267.820987,78.3904041 263.006526,78.4091111 C262.569275,78.4091111 262.43763,78.3155759 262.451734,77.8666068 C262.484646,76.4635783 262.451734,75.0231357 262.451734,73.4096529 C260.991817,75.5158844 258.948536,77.1551871 256.570005,78.1285054 C253.070027,79.5883454 249.273786,80.208361 245.48828,79.9384122 C240.528069,79.6952206 235.868761,78.432495 231.717228,75.6404682 C226.028265,71.8148771 222.68071,66.4506314 221.472393,59.767539 C220.475649,54.2302531 221.002231,48.8332701 223.569316,43.7730139 C227.119041,36.7578713 232.972561,32.6002302 240.565682,30.8043537 C245.210695,29.6115575 250.099913,29.7477317 254.670549,31.1972017 C257.753982,32.150708 260.46962,34.0212007 262.451734,36.5567706 L262.451734,36.5567706 Z M249.470555,66.9042773 C250.669128,66.9306159 251.866321,66.8080731 253.034385,66.5394899 C257.825338,65.3890065 260.895497,62.3865255 262.089709,57.68638 C263.283922,52.9862345 261.802911,48.9688962 258.060419,45.9617384 C254.134564,42.809601 249.550482,42.4822277 244.867666,43.8478421 C238.548686,45.6858094 235.02717,52.8599619 237.312159,58.9350754 C239.19751,63.9298569 243.701664,66.9042773 249.470555,66.9042773 Z"
                id="Shape"
                stroke="#000000"
                fill="#000000"
                class="svg-elem-3"></path>
              <path
                d="M126.589004,37.477258 C127.414001,36.3784523 128.358099,35.372132 129.404462,34.4762459 C131.724947,32.6314585 134.543242,31.5022925 137.508287,31.2294013 C141.071015,30.761607 144.692655,31.0108906 148.155409,31.9622605 C154.424494,33.7619401 158.037665,37.9921148 159.417239,44.193588 C159.819876,46.040608 160.015012,47.9259193 159.9991,49.8152675 C159.9991,59.2898694 159.9991,68.7675632 159.9991,78.2483494 C159.9991,78.8791649 159.834865,79.0044004 159.220157,79 C154.527728,78.9719319 149.835299,78.9719319 145.142869,79 C144.575085,79 144.453082,78.8466965 144.453082,78.308648 C144.471852,70.1791674 144.471852,62.0481408 144.453082,53.9155683 C144.481669,52.4273038 144.335221,50.9407954 144.016686,49.4859446 C143.275283,46.3875272 141.257538,44.6064011 138.071379,44.2121414 C136.648776,44.0254441 135.204894,44.069311 133.796576,44.3420151 C130.375795,45.0192142 128.414359,47.0229811 127.809036,50.4321679 C127.608312,51.6199319 127.512538,52.8227097 127.522798,54.0268887 C127.522798,62.1115318 127.522798,70.1915365 127.522798,78.2761795 C127.522798,78.8652499 127.386717,79.0044004 126.790779,79 C122.07958,78.9719319 117.368381,78.9719319 112.657182,79 C112.122245,79 111.99555,78.8791649 112,78.3457547 C112,63.3175026 112,48.2861581 112,33.2517217 C112,32.7368649 112.098783,32.5884377 112.65249,32.5930761 C117.05086,32.6209062 121.449231,32.6209062 125.847601,32.5930761 C126.36846,32.5930761 126.537388,32.6904814 126.518618,33.2378066 C126.556157,34.5922047 126.589004,35.951241 126.589004,37.477258 Z"
                id="Path"
                stroke="#000000"
                fill="#000000"
                class="svg-elem-4"></path>
              <path
                d="M181.545789,37.5147822 C183.567556,34.6104865 186.590042,32.5326121 190.04883,31.6691801 C192.541258,31.0846885 195.109651,30.8812856 197.664476,31.0660624 C200.814968,31.2330796 203.86686,31.8501154 206.665211,33.3625489 C211.012984,35.7147079 213.355898,39.4818737 214.407627,44.139798 C214.815647,45.9865613 215.014071,47.8725091 214.999225,49.7627105 C214.999225,59.2703194 214.999225,68.7732893 214.999225,78.2716194 C214.999225,78.8654584 214.858368,79 214.262075,79 C209.566856,78.9721638 204.854421,78.9721638 200.124771,79 C199.589516,79 199.462745,78.8793765 199.462745,78.3504887 C199.481526,70.219225 199.481526,62.086415 199.462745,53.9520587 C199.492048,52.4639164 199.348691,50.9773566 199.03548,49.5214634 C198.28894,46.3759728 196.293472,44.6176528 193.049075,44.2047491 C191.48376,44.0008664 189.894312,44.0715408 188.353856,44.4135206 C185.287878,45.1047863 183.381619,47.1971407 182.813498,50.4446974 C182.616535,51.6331334 182.522291,52.8359769 182.531785,54.0402067 C182.531785,62.1281693 182.531785,70.2130393 182.531785,78.2948162 C182.531785,78.8376222 182.409709,78.9907213 181.841588,78.9860819 C177.105676,78.9613388 172.3682,78.9613388 167.629159,78.9860819 C167.136161,78.9860819 167,78.8840158 167,78.3736855 C167,63.3204866 167,48.2626487 167,33.2001711 C167,32.7362344 167.089209,32.5692172 167.610378,32.5738566 C172.039535,32.5985999 176.465562,32.5985999 180.888458,32.5738566 C181.35798,32.5738566 181.541094,32.6573652 181.527008,33.1769743 C181.517617,34.5502268 181.545789,35.91884 181.545789,37.5147822 Z"
                id="Path"
                stroke="#000000"
                fill="#000000"
                class="svg-elem-5"></path>
              <path
                d="M209.732608,0 C210.294776,2.25033769 210.852298,4.47230977 211.405173,6.70373706 C211.916235,8.77915353 212.418005,10.85457 212.938358,12.9299865 C213.044184,13.1714421 213.012118,13.4524124 212.85473,13.6627645 C211.349421,15.4923458 209.867342,17.3408375 207.929954,18.7449347 C204.72885,21.0756416 201.211817,21.3640252 197.508943,20.3995948 C195.48317,19.8313481 193.524775,19.0386731 191.668903,18.0357947 C188.986448,16.6015112 186.079904,15.6513735 183.078421,15.2276002 C179.626432,14.792661 176.894576,15.903647 174.836391,18.7307519 C174.316038,19.4446195 173.837498,20.1821252 173.27533,21 C172.857189,19.3122467 172.462278,17.6906799 172.06272,16.116389 C171.402986,13.4878433 170.743252,10.8561459 170.083518,8.22129671 C169.972161,7.98188901 169.972161,7.70428839 170.083518,7.46488068 C171.677101,4.84106258 173.586613,2.5623593 176.416036,1.24335885 C179.612494,-0.25529041 182.883289,-0.108734804 186.172667,0.79423683 C189.462046,1.69720846 192.621336,3.15803692 195.803856,4.41557857 C197.134422,4.97885473 198.510045,5.42488594 199.915579,5.74876182 C202.647436,6.32552904 204.812478,5.2239982 206.712698,3.38496173 C207.547728,2.5314961 208.334185,1.63020931 209.068228,0.685502026 C209.227857,0.402501658 209.456735,0.166347128 209.732608,0 Z"
                id="Path"
                stroke="#01F9F4"
                fill="#01F9F4"
                class="svg-elem-6"></path>
              <path
                d="M268.873551,0 C269.862948,3.96457399 270.740338,7.8161435 271.809074,11.6112108 C272.201099,13.0237668 272.014421,13.9654709 271.01569,14.9730942 C269.914285,16.093722 268.924888,17.3273543 267.692808,18.3255605 C264.36526,21.0141256 260.617685,21.4755605 256.590091,20.4161435 C254.424498,19.7796852 252.330249,18.9181162 250.341021,17.8452915 C247.780325,16.5225637 245.015461,15.6484627 242.164493,15.2603139 C238.673601,14.7894619 235.910756,15.9242152 233.829288,18.77287 C233.325255,19.4650224 232.853891,20.185426 232.289188,21 C232.10251,20.2607623 231.939166,19.5874439 231.771155,18.9659193 C230.871985,15.390583 229.969705,11.8168162 229.064313,8.24461883 C228.965686,8.00144745 228.981119,7.72635348 229.106316,7.49596413 C230.9171,4.52959641 233.157244,2.04820628 236.531462,0.904035874 C239.424983,-0.0988789238 242.332504,0.108295964 245.230691,0.904035874 C248.567573,1.80807175 251.708443,3.25829596 254.909983,4.50605381 C256.141701,5.00739736 257.40445,5.42734293 258.690227,5.7632287 C261.131051,6.38946188 263.235854,5.7396861 265.130643,4.16704036 C266.255128,3.17015253 267.281926,2.06641148 268.196841,0.871076233 L268.873551,0 Z"
                id="Path"
                stroke="#F58E1F"
                fill="#F58E1F"
                class="svg-elem-7"></path>
              <path
                d="M154.709084,0 C155.815302,4.43986562 156.888985,8.75274356 157.962667,13.0609183 C158.037364,13.2641284 157.998305,13.4922851 157.860412,13.6582307 C156.261508,15.6147816 154.6719,17.5854423 152.547775,19.0058231 C149.294192,21.1834267 145.766379,21.3104143 142.127013,20.2851064 C140.093907,19.6535303 138.126303,18.8234212 136.251972,17.806495 C132.998389,16.1744681 129.651846,15.0645017 125.984593,15.1538634 C123.660605,15.2103024 121.847895,16.3390817 120.346598,18.0604703 C119.583163,18.9847937 118.891644,19.9675775 118.278249,21 C117.632181,18.4508399 117.014,16.0239642 116.419059,13.5970885 C115.954261,11.7910414 115.517351,9.98029115 115.057202,8.17424412 C114.968269,7.95225583 114.983655,7.70142929 115.099034,7.49227324 C117.102311,4.18589026 119.65405,1.52385218 123.55835,0.564389698 C126.505166,-0.159910414 129.377616,0.296304591 132.222177,1.17110862 C135.526888,2.19171333 138.659623,3.66853303 141.889967,4.88197088 C142.969933,5.29840032 144.085512,5.61328043 145.222565,5.82262038 C147.895152,6.29294513 149.954205,5.15005599 151.790155,3.3675252 C152.831302,2.37984323 153.695825,1.21343785 154.709084,0 Z"
                id="Path"
                stroke="#EE5123"
                fill="#EE5123"
                class="svg-elem-8"></path>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default Loading;
