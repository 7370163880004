import React, {Component} from 'react';
import ViewRenderer from './views';
import {API} from '../../../requests';
import 'react-datasheet/lib/react-datasheet.css';
import './styles.css';
import moment from 'moment';
import PopupContainer from '../../../components/PopupContainer';
import POPUP_CONSTANTS from '../../../constants/popup-constants';
import Spinner from '../../../components/Spinner';
import Popup from '../../../components/SimplePopup';

import strings from '../../../globalization';
import util from '../../../util';

import {commonImages, operatorImages} from '../../../assets/images';
class AgencyManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      startDate: moment().subtract(1, 'month'),
      endDate: moment().add(1, 'day'),
      dates: {
        start: moment().subtract(1, 'month').format('MM-DD-YYYY'),
        end: moment().add(1, 'day').format('MM-DD-YYYY'),
      },
      agencyList: [],
      filterAgencyList: [],
    };
  }
  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  getData = async () => {
    this.showSpinner();
    const {startDate, endDate} = this.state;

    if (!startDate || !endDate) {
      this.showErrorPopup(`Date and Time parameters cannot be empty!`);
      return;
    }

    let params = {
      startDate: `${moment(startDate).format('MM-DD-YYYY')}`,
      endDate: `${moment(endDate).format('MM-DD-YYYY')}`,
    };

    console.log('params...', params);
    API.fetchAgencyManagementDetailsForAdmin()
      .then(res => {
        console.log('agencyDetails', res.data);
        const data = res.data.map(dateFormate => {
          return {
            ...dateFormate,
            date: moment(dateFormate['date']).format('MMMM Do YY h: mm a'),
            reconciliationtime: moment(
              dateFormate['reconciliationtime'],
            ).format('MMMM Do YY h: mm a'),
          };
        });
        console.info('data', data);
        this.setState(
          {
            agencyList: data,
            filterAgencyList: data,
            popup: null,
          },
          this.applyFilters,
        );
      })
      .catch(err => {
        console.log('err', err);
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };

  reconciliationById = id => {
    this.showSpinner();
    API.reconciliationById(id)
      .then(res => {
        console.log('res');
        this.showSuccessPopup('Reconciliation Successful', this.getData);
      })
      .catch(error => {
        const err = util.getErrorMessage(error);
        this.showErrorPopup(err);
      });
  };

  reconciliationAll = () => {
    this.showSpinner();
    API.reconciliationAll()
      .then(res => {
        this.showSuccessPopup(
          'Reconciliation Successful for All Agencies',
          this.getData,
        );
      })
      .catch(error => {
        const err = util.getErrorMessage(error);
        this.showErrorPopup(err);
      });
  };

  resetData = () => {
    this.setState(
      {
        searchText: '',
        startDate: moment().subtract(1, 'month'),
        endDate: moment().add(1, 'day'),
        dates: {
          start: moment().subtract(1, 'month').format('MM-DD-YYYY'),
          end: moment().add(1, 'day').format('MM-DD-YYYY'),
        },
      },
      this.getData,
    );
  };
  showSuccessPopup = (successMessage, onClick) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: successMessage,
        onClose: this.onClose,
        headingImage: operatorImages.successIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: onClick ? onClick : this.onClose,
            outline: true,
          },
        ],
      },
    });
  };
  showErrorPopup = errorMsg => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMsg,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.onClose,
          },
        ],
      },
    });
  };

  onClose = () => {
    this.setState({
      popup: null,
    });
  };
  onClickHandler = (action, data) => {
    console.log('data', data);
    switch (action) {
      case 'apply': {
        this.getData();
        break;
      }
      case 'clearFilters': {
        this.resetData();
        break;
      }
      case 'Reconciliation': {
        const agencyId = {agencyId: data};
        console.log('Reconciliation');
        this.reconciliationById(agencyId);
        break;
      }
      case 'reconcile-all': {
        console.log('Reconciliation All Agencies');
        this.reconciliationAll();
        break;
      }

      default: {
        console.info('tableActionHandler case not handled', action);
      }
    }
  };

  onChangeHandler = (action, data) => {
    switch (action) {
      case 'date': {
        const {startDate, endDate, dates} = data;
        this.setState({
          startDate,
          endDate,
          dates,
        });
        break;
      }

      case 'search': {
        this.setState(
          {
            searchText: data,
          },
          this.applyFilters,
        );

        break;
      }

      default: {
        console.info('case not handled', action);
      }
    }
  };

  getProps = () => {
    const {searchText, filterAgencyList, startDate, endDate, dates} =
      this.state;
    return {
      onChange: this.onChangeHandler,
      onClick: this.onClickHandler,
      agencyList: filterAgencyList,
      startDate,
      endDate,
      dates,
      searchText,
    };
  };
  applyFilters = () => {
    const {searchText, agencyList} = this.state;
    console.log('mySearchText', searchText);
    const finalFilterData =
      agencyList.filter(elem =>
        Object.values({
          agencyName: elem.agencyName,
          referenceId: elem.referenceId,
        })
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()),
      ) || agencyList;
    this.setState({
      filterAgencyList: finalFilterData,
    });
  };

  getPopupContent = () => {
    const {popup} = this.state;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}
export default AgencyManagement;
