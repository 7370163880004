import React from 'react';
import styles from './Popup.module.scss';
// import {
//   close,
//   success as successImg,
//   error as errorImg,
// } from "../../assets/images";

import {adminImages} from '../../assets/images';
import strings from '../../globalization';
import moment from 'moment';

type Button = {
  text: String,
  onClick: () => void,
  className?: String,
};

type Props = {
  message: Object,
  onClose?: () => void,
  error?: Boolean,
  success?: Boolean,
  buttons?: Button[],
  heading?: String,
};

/**
 *
 * @param {Boolean} error
 * @param {Boolean} success
 * @return {JSX.Element}
 */

/**
 *
 * @param {Props} props
 * @return {JSX.Element}
 */
function RegistrationUpdateStatusPopup(props: Props) {
  const {
    error,
    success,
    onClose,
    buttons,
    heading,
    onChangeHandler,
    buttonContainer,
    message,
    data,
  } = props;
  const {referenceId} = data;
  let containerClass = '';
  if (error) {
    containerClass = styles.error;
  } else if (success) {
    containerClass = styles.success;
  }
  return (
    <div className={[styles.container, containerClass].join(' ')}>
      <div className={styles.secondContainer}>
        {onClose ? (
          <img
            alt="close"
            src={adminImages.closeIcon}
            className={styles.closeIcon}
            onClick={onClose}
          />
        ) : null}

        <div className={styles.heading}> {heading}</div>
        <div className={styles.bodyContainer}>
          <div className={styles.infoContainer}>{message}</div>
        </div>
        {buttons && buttons.length > 0 ? (
          <div className={[styles.buttonContainer, buttonContainer].join(' ')}>
            {buttons.map(button => {
              const {text, buttonStyles, ...remainingProps} = button;
              return (
                <button
                  className={[styles.button, buttonStyles].join(' ')}
                  key={'popup-button-' + text}
                  {...remainingProps}>
                  {text}
                </button>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default RegistrationUpdateStatusPopup;
