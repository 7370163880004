import React from 'react';
import Flex from '../Flex';
import styles from './styles.module.scss';
const GenericPopup = ({
  title,
  description,
  onClick,
  children,
  containerClassnames,
  headerStyles,
  descriptionStyles,
  buttonStyles,
  showButton = true,
  buttonText = 'Okay',
}) => {
  const onButtonClick = () => {
    onClick && onClick();
  };

  const classnames = (...classes) => classes.join(' ');
  return (
    <div className={classnames(styles.popupContainer, containerClassnames)}>
      <Flex direction="column">
        {title && (
          <p className={classnames(styles.header, headerStyles)}>{title}</p>
        )}
        {
          <p className={classnames(styles.description, descriptionStyles)}>
            {description}
          </p>
        }
        <div>{children}</div>
      </Flex>

      {showButton && (
        <Flex direction="row" style={{justifyContent: 'center'}}>
          <button
            className={classnames(styles.button, buttonStyles)}
            onClick={onButtonClick}>
            {buttonText}
          </button>
        </Flex>
      )}
    </div>
  );
};

export default GenericPopup;
