import styles from './styles.module.scss';
import dptlogo from '../../../../../../assets/images/Logo/logo.png';
import drishtilog from '../../../../../../assets/images/new-edrishti-logo/image.png';
import Flex from '../../../../../../components/Flex';
import sannaLogo from '../../../../../../assets/images/sanna-logo/image.png';
import celLogo from '../../../../../../assets/images/cel-logo-red/image.png';
import mroadsLogo from '../../../../../..//assets/images/mroads-logo/image.png';
import scannerLogo from '../../../../../../assets/images/scanner-icon/image.png';
import newDptLogo from '../../../../../../assets/images/new-dpt-logo/image.png';

import tripTypeIcon from '../../../../../../assets/images/permit-pass-icons/multiple/image.png';
import dateIcon from '../../../../../../assets/images/permit-pass-icons/date/image.png';

import Card from '../../../../../../components/PermitPassDetails';
import React from 'react';
import QRCode from 'react-qr-code';
import moment from 'moment';
import util from '../../../../../../util';

const Desktop = (
  {
    driver,
    vehicle,
    material,
    workPurpose,
    visitingDate,
    noOfDays,
    referenceId,
    onClick,
    tripType,
    permit,
    personDetails,
  },
  ref,
) => {
  console.log('data aa rha', vehicle, personDetails, workPurpose);
  // get details of vehicle/driver/material
  return (
    <Flex className={styles.mainContainer}>
      <Flex className={styles.container} ref={ref}>
        <div className={styles.pageBreak}>
          <div className={styles.subcontainer}>
            <div className={styles.header}>
              <div className={styles.left}>
                <img src={drishtilog} alt="drishtilog" />
                <div className={styles.sanna}>
                  <p>powered by</p> <img src={sannaLogo} alt="sanna" />
                </div>
              </div>
              <h1>e-Drishti Permit Pass</h1>
              <img src={newDptLogo} alt="dptlogo" />
            </div>
            {/* Permit */}
            <div className={styles.splitCardContainer}>
              <div className={styles.splitCardHeader}>
                <h2>Permit Details</h2>
                <h2>
                  Visiting Date: {moment(visitingDate).format('DD/MM/YYYY')}
                </h2>
              </div>
              <div className={styles.splitCardBody} style={{height: '11rem'}}>
                <div className={styles.splitCardLeftContainer}>
                  <div className={styles.labelValueContainer}>
                    <span className={styles.label}>Purpose Of Work:</span>
                    <span
                      className={styles.value}
                      style={{fontFamily: 'Poppins-Bold'}}>
                      {workPurpose}{' '}
                    </span>
                  </div>
                  <div className={styles.labelValueContainer}>
                    <span className={styles.label}>Validity Period:</span>
                    <div className={styles.value}>
                      {/* {util.calculateDateStrings(visitingDate, noOfDays)} */}
                      <img
                        src={dateIcon}
                        className={styles.dateIcon}
                        alt="date"
                        height="10rem"
                        style={{marginRight: '0.4rem'}}
                      />
                      <span
                        className={styles.dateContainerText}
                        style={{
                          fontSize: '0.8rem ',
                          fontFamily: 'Poppins-Bold',
                          marginRight: '0.4rem',
                        }}>
                        {moment(visitingDate).format('DD/MM/YYYY')}
                      </span>
                      <span
                        className={styles.dateContainerText}
                        style={{
                          fontSize: '0.8rem ',
                          fontFamily: 'Poppins-Bold',
                          color: '#F7B500',
                        }}>
                        8:00 AM
                      </span>
                      <span
                        style={{marginLeft: '0.5rem', marginRight: '0.5rem'}}>
                        {' '}
                        -{' '}
                      </span>
                      <img
                        src={dateIcon}
                        className={styles.dateIcon}
                        alt="date"
                        height="10rem"
                        style={{marginRight: '0.4rem'}}
                      />
                      <span
                        className={styles.dateContainerText}
                        style={{
                          fontSize: '0.8rem ',
                          fontFamily: 'Poppins-Bold',
                          marginRight: '0.4rem',
                        }}>
                        {moment(visitingDate)
                          .add(noOfDays, 'days')
                          .format('DD/MM/YYYY')}
                      </span>
                      <span
                        className={styles.dateContainerText}
                        style={{
                          fontSize: '0.8rem ',
                          fontFamily: 'Poppins-Bold',
                          color: '#F7B500',
                        }}>
                        7:59 AM
                      </span>
                    </div>
                  </div>
                  <div className={styles.labelValueContainer}>
                    <span className={styles.label}>Permit Agency:</span>
                    <span
                      className={styles.value}
                      style={{fontFamily: 'Poppins-Bold'}}>
                      {permit?.company?.name}
                    </span>
                  </div>
                  <div
                    className={[
                      styles.labelValueContainer,
                      styles.mobilePermitType,
                    ]
                      .filter(Boolean)
                      .join(' ')}>
                    <span className={styles.label}>Permit Type:</span>
                    <span
                      className={styles.value}
                      style={{fontFamily: 'Poppins-Bold'}}>
                      {tripType === 1 ? 'Single Trip' : 'Multiple Trips'}
                    </span>
                  </div>
                </div>
                <div className={styles.splitCardRightContainer}>
                  <span
                    style={{
                      fontSize: '6rem',
                      fontFamily: 'Poppins-Bold',
                      color: '#FFFFFF50',
                      dislpay: 'inline-block',
                    }}>
                    1
                  </span>
                  <span
                    style={{
                      fontSize: '2rem',
                      fontFamily: 'Poppins-Light',
                      color: '#FFFFFF50',
                      lineHeight: '1.8rem',
                    }}>
                    Day Pass
                  </span>
                </div>
              </div>
            </div>

            {/* Person details */}
            {personDetails ? (
              <div className={styles.splitCardContainer}>
                <div className={styles.splitCardHeader}>
                  <h2>Person Details</h2>
                </div>
                <div
                  className={styles.splitCardBody}
                  style={{border: '1px solid #979797', height: '11rem'}}>
                  <div
                    className={styles.splitCardLeftContainer}
                    style={{backgroundColor: '#fff'}}>
                    <div
                      className={styles.labelValueContainer}
                      style={{color: '#002892'}}>
                      <span className={styles.label}>Person Name:</span>
                      <span className={styles.value}>
                        {personDetails?.person?.name}
                      </span>
                    </div>

                    <div
                      className={styles.labelValueContainer}
                      style={{color: '#002892'}}>
                      <span className={styles.label}>
                        Associated Vehicle Reg. Number:
                      </span>
                      <span className={styles.value}>
                        {
                          personDetails?.associatedVehicle
                            ?.vehicleRegistrationNumber
                        }
                      </span>
                    </div>
                    <div
                      className={styles.labelValueContainer}
                      style={{color: '#002892'}}>
                      <span className={styles.label}>RFID Tag Number:</span>
                      <span className={styles.value}>
                        {personDetails?.person?.rfidnumber}
                      </span>
                    </div>
                  </div>
                  <div className={styles.splitCardRightContainer}>
                    <img
                      src={tripTypeIcon}
                      alt="trip type icon"
                      style={{height: '3rem', marginRight: '0.4rem'}}
                    />
                    <span
                      style={{
                        fontSize: '1.5rem',
                        fontFamily: 'Poppins-Light',
                        color: '#FFFFFF50',
                        lineHeight: '1.8rem',
                        marginRight: '0.4rem',
                      }}>
                      {tripType === 1 ? 'Single Trip' : 'Multiple Trips'}
                    </span>
                  </div>
                </div>
              </div>
            ) : null}

            {/* Vehicle */}
            {vehicle ? (
              <div className={styles.splitCardContainer}>
                <div className={styles.splitCardHeader}>
                  <h2>Vehicle Details</h2>
                </div>
                <div
                  className={styles.splitCardBody}
                  style={{border: '1px solid #979797', height: '11rem'}}>
                  <div
                    className={styles.splitCardLeftContainer}
                    style={{backgroundColor: '#fff'}}>
                    <div
                      className={styles.labelValueContainer}
                      style={{color: '#002892'}}>
                      <span className={styles.label}>Vehicle Owner:</span>
                      <span className={styles.value}>{vehicle?.name}</span>
                    </div>
                    <div
                      className={styles.labelValueContainer}
                      style={{color: '#002892'}}>
                      <span className={styles.label}>Vehicle Number:</span>
                      <span className={styles.value}>
                        {vehicle?.vehicleRegistrationNumber}
                      </span>
                    </div>
                    <div
                      className={styles.labelValueContainer}
                      style={{color: '#002892'}}>
                      <span className={styles.label}>Vehicle Type:</span>
                      <span className={styles.value}>
                        {vehicle?.vehicleType?.name}
                      </span>
                    </div>
                    <div
                      className={styles.labelValueContainer}
                      style={{color: '#002892'}}>
                      <span className={styles.label}>RFID Tag Number:</span>
                      <span className={styles.value}>
                        {vehicle?.rfidnumber}
                      </span>
                    </div>
                  </div>
                  <div className={styles.splitCardRightContainer}>
                    <img
                      src={tripTypeIcon}
                      alt="trip type icon"
                      style={{height: '3rem', marginRight: '0.4rem'}}
                    />
                    <span
                      style={{
                        fontSize: '1.5rem',
                        fontFamily: 'Poppins-Light',
                        color: '#FFFFFF50',
                        lineHeight: '1.8rem',
                        marginRight: '0.4rem',
                      }}>
                      {tripType === 1 ? 'Single Trip' : 'Multiple Trips'}
                    </span>
                  </div>
                </div>
              </div>
            ) : null}

            {/* Material */}
            <div className={styles.splitCardContainer}>
              <div className={styles.splitCardHeader}>
                <h2>Material Details</h2>
              </div>
              <div
                className={styles.splitCardBody}
                style={{border: '1px solid #979797'}}>
                {material ? (
                  <>
                    {' '}
                    <div
                      className={styles.splitCardLeftContainer}
                      style={{backgroundColor: '#fff'}}>
                      <div
                        className={styles.labelValueContainer}
                        style={{color: '#002892'}}>
                        <span className={styles.label}>Cargo Name:</span>
                        <span className={styles.value}>
                          {material?.natureOfCargoDetails
                            ? material?.natureOfCargoDetails?.name
                            : material?.lineInfo[0]?.natureOfCargo}
                        </span>
                      </div>
                      <div
                        className={styles.labelValueContainer}
                        style={{color: '#002892'}}>
                        <span className={styles.label}>IGM No.:</span>
                        <span className={styles.value}>
                          {material?.igmnumber}
                        </span>
                      </div>
                      <div
                        className={styles.labelValueContainer}
                        style={{color: '#002892'}}>
                        <span className={styles.label}>BOE No.:</span>
                        <span className={styles.value}>
                          {material?.billOfEntryNo}
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      width: '100%',
                      color: '#ADADAD',
                      display: 'flex',
                      padding: '2rem',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: '#fff',
                    }}>
                    {' '}
                    -NIL-{' '}
                  </div>
                )}
              </div>
            </div>
            {/* Department */}
            <div className={styles.splitCardContainer}>
              <div className={styles.splitCardHeader}>
                <h2>Approval Department</h2>
              </div>
              <div
                className={styles.splitCardBody}
                style={{border: '1px solid #979797', backgroundColor: '#fff'}}>
                <div
                  className={styles.splitCardLeftContainer}
                  style={{backgroundColor: '#fff', padding: '2rem'}}>
                  <div
                    className={styles.labelValueContainer}
                    style={{color: '#002892'}}>
                    <span className={styles.label}>Traffic Department</span>
                  </div>
                  <div
                    className={styles.labelValueContainer}
                    style={{color: '#002892'}}>
                    <span
                      className={styles.label}
                      style={{
                        fontSize: '0.8rem',
                        fontFamily: 'Poppins-Light',
                        textDecoration: 'underline',
                        fontStyle: 'italic',
                        color: '#000',
                      }}>
                      {' '}
                      The permit pass act as a proof of entry/exit at the port
                    </span>
                  </div>
                </div>
                <div className={styles.QRContainer}>
                  <div className={styles.scanner}>
                    <QRCode
                      value={
                        window.location.protocol +
                        '//' +
                        window.location.host +
                        '/verify-permit/' +
                        referenceId
                      }
                      size={60}
                    />
                  </div>
                  <div
                    style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                    <span
                      style={{
                        fontFamily: 'Poppins-Bold',
                        color: '#ADADAD',
                        fontSize: '0.6rem',
                      }}>
                      Scan QR Code
                    </span>
                    <span style={{fontSize: '0.6rem', color: '#000'}}>
                      To get permit details
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.footer}>
              <img src={celLogo} alt="celLogo" />
              <img
                src={mroadsLogo}
                alt="mroadsLogo"
                style={{height: '3.5vh', aspectRatio: 1}}
              />
            </div>
          </div>
        </div>
      </Flex>
    </Flex>
  );
};
export default React.forwardRef(Desktop);
