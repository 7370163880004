import React, { Component } from 'react';
import PopupContainer from '../../../../components/PopupContainer';
import Popup from '../../../../components/Popup';
import { withRouter } from 'react-router-dom';

import ViewRenderer from './views';
import Spinner from '../../../../components/Spinner';
import { API } from '../../../../requests';

import logo from '../../../../assets/images/Logo/logo.png';

import styles from './styles.module.scss';
import TermsCredits from '../../../../components/Credits/Terms';
import {
  clearCart,
  deleteCartItem,
  updateQuantity,
} from '../../../../store/actions/cart';
import { connect } from 'react-redux';
import strings from '../../../../globalization';
import { commonImages } from '../../../../assets/images';
import util from '../../../../util';
import { termsData } from './data';
import * as CryptoJS from 'crypto-js';

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  ERROR: 'error',
  SPINNER_POPUP: 'spinner',
  ADD_CREDITS: 'addCredits',
  TERMS_CREDITS_POPUP: 'terms-credits-popup',
  PAYMENT_SUCCESS_CREDITS: 'payment-success-credits',
  PAYMENT_FAILURE_CREDITS: 'payment-failure-credits',
};
class Cart extends Component {
  constructor(props) {
    super(props);
    console.log(props);

    this.state = {
      selectedStatus: this.props.status,
      searchText: '',
      cartItems: [],
      pageStatus: '',
      calculatedGST: 0,
      calculatedTotal: 0,
      calculatedPaymentObject: this.props.location.state.data,
      referenceId: '',
      RAZORPAY_API_KEY_ID: ''
    };
  }

  componentDidMount() {
    // calculate GST and Total through back-end
    this.getPaymentDetails();
    this.calculatePayment();
  }

  calculatePayment = () => {
    this.showSpinner();
    const { calculatedPaymentObject } = this.state;
    console.log('this.props.location', this.props.location);

    const requiredArray = [
      {
        id: calculatedPaymentObject.packId,
        quantity: calculatedPaymentObject.quantity,
      },
    ];
    API.calculatePayment(requiredArray)
      .then(res => {
        console.log('res...calculatePayment', res.data);
        this.setState({
          calculatedTotal: res.data.subTotal,
          calculatedGST: res.data.GST,
          popup: null,
        });
      })
      .catch(err => {
        const errorMsg = util.getErrorMessage(err);
        this.showErrorPopup(errorMsg);
        console.log(err);
      });
  };

  onChangeHandler = (action, data) => {
    switch (action) {
      case 'search': {
        console.info('data..', data);
        this.setState({
          searchText: data,
        });

        break;
      }

      default: {
        console.info('case not handled', action);
      }
    }
  };

  /**
   *
   * @param {*} action
   * @param {*} data
   */

  onClickHandler = (action, data) => {
    const { history, location, status } = this.props;
    const { dispatchDeleteCartItem, dispatchUpdateQuantity } = this.props;
    switch (action) {
      case 'statusRoute': {
        history.push({
          pathname: '/u/wallet/' + data.route,
        });

        break;
      }
      case 'proceed': {
        this.showTermsPopup(data);
        break;
      }
      case 'cancel': {
        history.goBack();
        break;
      }
      case 'success-cta': {
        history.push('/u/wallet');
        break;
      }
      case 'pay-later': {
        history.push({
          pathname: '/u/wallet/',
        });
        break;
      }
      case 'try-again': {
        this.pay();
        break;
      }
      case 'delete-cart-item': {
        // dispatchDeleteCartItem(data.id);
        history.goBack();
        console.log('delete-cart-item', data);
        break;
      }
      case 'update-quantity': {
        dispatchUpdateQuantity(data);
        this.setState(
          prevState => ({
            calculatedPaymentObject: {
              ...prevState.calculatedPaymentObject,
              ...data,
            },
          }),
          function () {
            this.calculatePayment();
          },
        );
        console.log('update-cart-item', data);
        break;
      }
      default: {
        console.info('case not handled', action);
      }
    }
  };
  pay = () => {
    this.displayRazorpay();
  };

  getPaymentDetails = () => {
    API.getPaymentProperties()
      .then(res => {
        const { key, secret } = res.data || {};
        // console.log(key, secret);
        const RAZORPAY_API_KEY_ID = this.decryptData(key);
        // console.log('RAZORPAY_API_KEY_ID::::', RAZORPAY_API_KEY_ID);
        //  const RAZORPAY_API_SECRET_ID = this.decryptData(secret);

        this.setState({
          RAZORPAY_API_KEY_ID: RAZORPAY_API_KEY_ID
        });
      })
      .catch(err => console.log('failed...', err));
  }

  secretKey = 'DPT@mRoads@12345';

  decryptData = (encryptedData) => {
    const key = CryptoJS.enc.Utf8.parse(this.secretKey);
    const decrypted = CryptoJS.AES.decrypt(encryptedData, key, {
      keySize: 128 / 8,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }


  displayRazorpay = async () => {
    const { calculatedPaymentObject } = this.state;
    // const total = this.getTotal();

    const createOrderObject = [
      {
        id: calculatedPaymentObject.packId,
        quantity: calculatedPaymentObject.quantity,
      },
    ];
    const { calculatedTotal, calculatedGST } = this.state;
    const { dispatchClearCart } = this.props;
    console.info('display razorpay -->');

    const res = await this.loadRazorpay();
    // this.showSpinner();
    const orderRes = await API.createOrderWallet(createOrderObject)
      .then(res => {
        console.log('createOrderIdRes...', res);
        this.setState({
          orderId: res.data.orderId,
        });
        return res.data;
      })
      .catch(err => console.log('err...', err));

    if (!res) {
      alert('Razorpay SDK Failed to load');
      return;
    }

    this.setState({
      popup: null,
    });

    // console.log(data);
    const { RAZORPAY_API_KEY_ID } = this.state;
    // console.log('RAZORPAY_API_KEY_ID ', RAZORPAY_API_KEY_ID);
    let error = {};
    var options = {
      key: RAZORPAY_API_KEY_ID, // Enter the Key ID generated from the Dashboard
      name: 'e-Drishti Access Control System',
      currency: 'INR',
      amount: +calculatedTotal + +calculatedGST,
      order_id: orderRes.orderId,
      description: 'Thankyou!',
      image: { logo },
      handler: response => {
        console.log('RESPONSE...', response);
        let successDetails = {
          orderId: response.razorpay_order_id,
          paymentId: response.razorpay_payment_id,
          signature: response.razorpay_signature,
          amount: +calculatedTotal + +calculatedGST,
        };
        // Call Add credits API here.

        console.log('successDetails...', { successDetails });
        this.success(successDetails);

        // Clear cart
        dispatchClearCart();
      },
      modal: {
        ondismiss: data => {
          console.log('this.state..error', this.state);
          const errorDetails = {
            orderId: this.state.orderId,
            paymentId: 'HARDCODED',
            code: 'FAILED',
            ...error,
          };
          console.log('errorDetails...', errorDetails);
          this.failure(errorDetails);
        },
      },
    };
    // console.log('RAZORPAY_API_KEY_ID:', RAZORPAY_API_KEY_ID);
    // console.info(
    //   'process.env.REACT_APP_RAZORPAY_API_KEY_ID',
    //   process.env.REACT_APP_RAZORPAY_API_KEY_ID,
    // );

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    paymentObject.on('payment.failed', function (response) {
      error = {
        code: response.error.code,
        description: response.error.description,
        source: response.error.source,
        orderId: response.error.metadata.order_id,
        paymentId: response.error.metadata.payment_id,
      };
      console.log('error...', error);
    });
  };
  showSuccessPopup = successMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: successMessage,
        onClose: this.closePopup,
        headingImage: commonImages.successIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };

  showErrorPopup = errorMsg => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMsg,
        messageStyle: { color: '#E67717' },

        buttons: [
          {
            text: strings.cancelPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
      },
    });
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  success = successDetails => {
    this.showSpinner();
    API.paymentSuccess(successDetails)
      .then(res => {
        console.log('paymentSuccessRes...', res);
        this.setState({
          pageStatus: 'success',
          referenceId: res.data?.referenceId,
          popup: null,
        });
      })
      .catch(err => {
        const errorMsg = util.getErrorMessage(err);
        this.showErrorPopup(errorMsg);
        this.setState({
          pageStatus: 'failure',
          popup: null,
        });
      });
  };
  failure = failureDetails => {
    this.showSpinner();
    API.paymentFailure(failureDetails)
      .then(res => {
        console.log('paymentSuccessRes...', res);
        this.setState({
          pageStatus: 'failure',
          popup: null,
        });
      })
      .catch(err => {
        const errorMsg = util.getErrorMessage(err);
        this.showErrorPopup(errorMsg);
        this.setState({
          pageStatus: 'failure',
          popup: null,
        });
      });
  };
  loadRazorpay = () => {
    return new Promise(resolve => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      // document.body.appendChild(script);

      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };

  showTermsPopup = amount => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.TERMS_CREDITS_POPUP,
        onClose: this.closePopup,
        buttons: [
          {
            text: 'Cancel',
            buttonStyles: styles.buttonPrimary,
            onClick: () => {
              this.closePopup();
            },
          },
          {
            text: 'Agree and Proceed',
            buttonStyles: styles.buttonSecondary,
            onClick: () => {
              this.pay();
            },
          },
        ],
        // data: data,
        buttonContainer: styles.buttonContainer,
      },
    });
  };

  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const { popup } = this.state;
    if (!popup) return;
    switch (popup.type) {
      // case "spinner": {
      //   console.log('showing spinner');
      //   return <Spinner />;
      // }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.SPINNER: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.TERMS_CREDITS_POPUP: {
        return <TermsCredits data={termsData} {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };

  getAmount = () => {
    const {
      cart: { cart },
    } = this.props.reduxProps;

    let total = 0;
    cart.forEach(item => {
      total += item.total;
    });

    return total;
  };

  getGst = () => {
    return this.getAmount() * 0.18;
  };

  getTotal = () => {
    return this.getAmount() + this.getGst();
  };

  getProps = () => {
    const { calculatedPaymentObject } = this.state;
    return {
      onClick: this.onClickHandler,
      selectedStatus: this.state.selectedStatus,
      onChange: this.onChangeHandler,
      searchText: this.state.searchText,
      cartItems: [{ ...calculatedPaymentObject }],
      pageStatus: this.state.pageStatus,
      amount: this.getAmount(),
      gst: this.getGst(),
      total: this.getTotal(),
      calculatedGST: this.state.calculatedGST,
      calculatedTotal: this.state.calculatedTotal,
      referenceId: this.state.referenceId,
    };
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const { popup } = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer containerStyle={styles.popupContainerStyle}>
            {' '}
            {this.getPopupContent()}{' '}
          </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

const mapStateToProps = reduxProps => ({
  reduxProps,
});

const mapDispatchToProps = dispatch => ({
  dispatchDeleteCartItem: payload => {
    dispatch(deleteCartItem(payload));
  },
  dispatchClearCart: () => {
    dispatch(clearCart());
  },
  dispatchUpdateQuantity: payload => {
    dispatch(updateQuantity(payload));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cart));
