import React, {useEffect, useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import Flex from '../Flex';
import styles from './styles.module.scss';

import closeIcon from '../../assets/images/close-icon/image.png';

const ViewDocument = ({url, onClick}) => {
  const [numPages, setNumPages] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  const TYPES = {
    JPEG: 'jpeg',
    JPG: 'jpg',
    PNG: 'png',
    PDF: 'pdf',
  };

  const onDocumentLoadSuccess = ({numPages}) => {
    setNumPages(numPages);
    setLoaded(true);
  };
  function get_url_extension(url) {
    return url.split(/[#?]/)[0].split('.').pop().trim();
  }

  const renderDocument = () => {
    let extension = get_url_extension(url);
    console.log('URL ----', extension);

    extension = extension.toLowerCase();
    console.log('EXTENSION lowercase ----', extension);

    switch (extension) {
      case TYPES.PDF: {
        return (
          <div style={{position: 'relative'}}>
            {loaded && (
              <button onClick={onClick} className={styles.button}>
                <img src={closeIcon} className={styles.crossIcon} alt="U" />
              </button>
            )}
            <Document
              // options={{workerSrc: 'pdf.worker.js'}}
              className={styles.pdfClassName}
              file={url}
              onLoadError={console.error}
              onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} />
            </Document>
            <p>
              Page {pageNumber} of {numPages}
            </p>
          </div>
        );
      }

      case TYPES.JPEG: {
        return <img className={styles.imageViewer} src={url} alt="U" />;
      }
      case TYPES.JPG: {
        return <img className={styles.imageViewer} src={url} alt="U" />;
      }
      case TYPES.PNG: {
        return <img className={styles.imageViewer} src={url} alt="U" />;
      }
      default: {
        return <img className={styles.imageViewer} src={url} alt="U" />;
      }
    }
  };

  console.log('url', url);
  return (
    <Flex direction="column" onClick={onClick} className={styles.container}>
      {renderDocument()}
    </Flex>
  );
};

export default ViewDocument;
