import React, {Component} from 'react';
import styles from './styles.module.scss';
import PopupContainer from '../../../components/PopupContainer';
import Popup from '../../../components/Popup';
import {adminImages, loginImages, commonImages} from '../../../assets/images';
import moment from 'moment';
import ViewRenderer from './views';
import AgencyRquestsPopup from '../../../components/AgencyRquestsPopup';
import AgencyPersonDetails from '../../../components/AgencyPersonDetails';
import {API} from '../../../requests';
import strings from '../../../globalization';
import Spinner from '../../../components/Spinner';

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  ERROR: 'error',
  SPINNER_POPUP: 'spinner',
};
export default class AgencyRquests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agencies: [],
      selectedStatus: '',
      selectedAgency: '',
      startDate: new moment().startOf('year').format('MM-DD-YYYY'),
      endDate: new moment().add(1, 'years').format('MM-DD-YYYY'),
      dates: null,
      filterAgencies: [],
      searchText: '',
      statuses: [
        {
          id: '1',
          name: 'PENDING',
        },
        {
          id: '2',
          name: 'APPROVED',
        },
        {
          id: '3',
          name: 'REJECTED',
        },
      ],
    };
  }

  applyFilters = () => {
    const {searchText, selectedStatus, selectedAgency, agencies} = this.state;
    const filterByStatusData =
      (selectedStatus &&
        agencies.filter(
          agency =>
            selectedStatus &&
            agency.status.toLowerCase() === selectedStatus.toLowerCase(),
        )) ||
      agencies;
    const filterBySearch =
      agencies.filter(element =>
        Object.values(element)
          .toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()),
      ) || agencies;
    const filterByAgencyName =
      (selectedAgency &&
        agencies.filter(
          row =>
            selectedAgency &&
            row.company &&
            row.company.name.toLowerCase() === selectedAgency.toLowerCase(),
        )) ||
      agencies;
    const temp1 = filterByStatusData.filter(({id: id1}) =>
      filterBySearch.some(({id: id2}) => id2 === id1),
    );
    const finalFilterData = filterByAgencyName.filter(({id: id1}) =>
      temp1.some(({id: id2}) => id2 === id1),
    );
    this.setState({
      filterAgencies: finalFilterData,
    });
  };

  getData = async (startDate, endDate, isFilter) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
    await API.getAllAgencyDetails(startDate, endDate)
      .then(response => {
        this.setState({
          agencies: response.data,
          filterAgencies: response.data,
          popup: undefined,
        });
        if (isFilter) {
          this.applyFilters();
        }
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        console.info('error....', errorMsg);
        this.setState({
          popup: undefined,
        });
        // this.setState({
        //   popup: {
        //     type: POPUP_CONSTANTS.SIMPLE_POPUP,
        //     message: errorMsg,
        //     messageStyle: { color: "#E67717" },
        //     buttons: [
        //       {
        //         title: "Okay!",
        //         onPress: this.closePopup,
        //       },
        //     ],
        //   },
        // });
      });
  };

  approveAgency = async data => {
    const {id, referenceId} = data;
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
    await API.approveAgencyRequest(id)
      .then(response => {
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.STANDARD,
            heading: `${strings.ADMIN.POP_UP.agencyRequest} - ${referenceId}`,
            message: this.getSuccessMessage(referenceId),
            data: data,
            onClose: this.closePopup,
            buttonContainer: styles.buttonContainer,
            buttons: [
              {
                text: 'Okay',
                buttonStyles: styles.backButton,
                onClick: this.closePopup,
              },
            ],
          },
        });
        const {startDate, endDate} = this.state;
        let startDateStr = moment(startDate).format('MM-DD-YYYY');
        let endDateStr = moment(endDate).format('MM-DD-YYYY');
        this.getData(startDateStr, endDateStr, true);
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        console.info('error....', errorMsg);

        this.setState({
          popup: {
            type: POPUP_CONSTANTS.STANDARD,
            heading: `${strings.ADMIN.POP_UP.agencyRequest} - ${referenceId}`,
            message: this.getErrorMessage(referenceId),
            data: data,
            onClose: this.closePopup,
            buttonContainer: styles.buttonContainer,
            buttons: [
              {
                text: 'Okay',
                buttonStyles: styles.backButton,
                onClick: this.closePopup,
              },
            ],
          },
        });
      });
  };

  rejectReason = (referenceId, data) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.STANDARD,
        heading: `${strings.ADMIN.POP_UP.agencyRequest} - ${referenceId}`,
        message: this.getRejectMessage(),
        data: data,
        onClose: this.closePopup,
        buttonContainer: styles.buttonContainer,
        buttons: [
          {
            text: 'Back',
            buttonStyles: styles.backButton,
            onClick: this.closePopup,
          },
          {
            text: 'Reject',
            buttonStyles: styles.buttonPrimary,
            onClick: () => {
              const {referenceId} = data;
              this.rejectAgency(referenceId, data);
            },
          },
        ],
      },
    });
  };

  rejectAgency = async (referenceId, data) => {
    const {id} = data;
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });

    await API.rejectAgencyRequest(id)
      .then(response => {
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.STANDARD,
            heading: `${strings.ADMIN.POP_UP.agencyRequest} - ${referenceId}`,
            message: this.getRejectConformation(referenceId),
            data: data,
            onClose: this.closePopup,
            buttonContainer: styles.buttonContainer,
            buttons: [
              {
                text: 'Okay',
                buttonStyles: styles.backButton,
                onClick: this.closePopup,
              },
            ],
          },
        });
        const {startDate, endDate} = this.state;
        let startDateStr = moment(startDate).format('MM-DD-YYYY');
        let endDateStr = moment(endDate).format('MM-DD-YYYY');
        this.getData(startDateStr, endDateStr, true);
      })
      .catch(error => {
        const errorMsg = error.response
          ? error.response.data.message
          : error.message;
        console.info('error....', errorMsg);
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.STANDARD,
            heading: `${strings.ADMIN.POP_UP.agencyRequest} - ${referenceId}`,
            message: this.getErrorMessage(referenceId),
            data: data,
            onClose: this.closePopup,
            buttonContainer: styles.buttonContainer,
            buttons: [
              {
                text: 'Okay',
                buttonStyles: styles.backButton,
                onClick: this.closePopup,
              },
            ],
          },
        });
      });
  };
  componentDidMount() {
    this.getData(null, null);
  }

  getVehicleDetails = props => {
    const {
      name,
      emailAddress,
      phoneNumber,
      vehicleType,
      vehicleNumber,
      vehicleInsuranceNumber,
      vehicleEngineNumber,
      vehiclePollutionNumber,
      chassisNumber,
      vehicleInsuranceCertificate,
      fitnessCertificate,
      vehicleRegistrationNumber,
      puc,
      rcDocument,
    } = props || {};
    return (
      <div className={styles.cardWrapper}>
        <div>
          <img
            src={adminImages.truckIconBlue}
            className={styles.truckIcon}
            alt="logo"
          />
        </div>
        <div className={styles.info}>
          <div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehicleOwnerName} :
              <span className={styles.requestLabel}> {name} </span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.ownerEmail} :
              <span className={styles.requestLabel}>{emailAddress}</span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.ownerPhoneNumber} :
              <span className={styles.requestLabel}> {phoneNumber} </span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehicleType} :
              <span className={styles.requestLabel}>{vehicleType}</span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.document} :
              <span className={styles.requestLabel}>
                {' '}
                {rcDocument ? (
                  <a href={rcDocument}>
                    {' '}
                    {strings.ADMIN.POP_UP.vehicleRC}{' '}
                    <img
                      className={styles.documentIcon}
                      src={adminImages.documentIcon}
                    />
                  </a>
                ) : null}
              </span>
            </div>
          </div>
          <div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehicleNumber} :
              <span className={styles.requestLabel}>
                {' '}
                {vehicleRegistrationNumber}{' '}
              </span>
            </div>
            {/* <div className={styles.requestKey}>
            {strings.ADMIN.POP_UP.vehicleRCNumber} :
              <span className={styles.requestLabel}>{"-"} </span>
            </div> */}
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehicleInsuranceNumber} :
              <span className={styles.requestLabel}>
                {' '}
                {vehicleInsuranceNumber}{' '}
              </span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.document} :
              <span className={styles.requestLabel}>
                {vehicleInsuranceCertificate ? (
                  <a href={vehicleInsuranceCertificate}>
                    {strings.ADMIN.POP_UP.vehicleInsuranceCertificate}{' '}
                    <img
                      className={styles.documentIcon}
                      src={adminImages.documentIcon}
                    />
                  </a>
                ) : null}
              </span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.document} :
              <span className={styles.requestLabel}>
                {' '}
                {puc ? (
                  <a href={puc}>
                    {' '}
                    {strings.ADMIN.POP_UP.pUCCertificate}{' '}
                    <img
                      className={styles.documentIcon}
                      src={adminImages.documentIcon}
                    />
                  </a>
                ) : null}
              </span>
            </div>
          </div>
          <div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehiclePollutionNumber} :
              <span className={styles.requestLabel}>
                {' '}
                {vehiclePollutionNumber}{' '}
              </span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehicleEngineNumber} :
              <span className={styles.requestLabel}>{vehicleEngineNumber}</span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.vehicleChassisNumber} :
              <span className={styles.requestLabel}> {chassisNumber} </span>
            </div>
            <div className={styles.requestKey}>
              {strings.ADMIN.POP_UP.document} :
              <span className={styles.requestLabel}>
                {' '}
                {fitnessCertificate ? (
                  <a href={fitnessCertificate}>
                    Fitness Certificate{' '}
                    <img
                      className={styles.documentIcon}
                      src={adminImages.documentIcon}
                    />
                  </a>
                ) : null}{' '}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  getRequestInfo = data => {
    const {persons} = data;
    const {driver} = data;
    const {vehicle} = data;
    return (
      <div className={styles.requestInfo}>
        {persons.map((person, index) => {
          return (
            <RequestCard
              header={strings.ADMIN.POP_UP.personDetails + '-' + (index + 1)}>
              <AgencyPersonDetails {...person} />
            </RequestCard>
          );
        })}
        {vehicle ? (
          <RequestCard header={strings.ADMIN.POP_UP.vehicleDetails}>
            {this.getVehicleDetails(vehicle)}
          </RequestCard>
        ) : null}
        {driver ? (
          <RequestCard header={strings.ADMIN.POP_UP.driverDetails}>
            <AgencyPersonDetails {...driver} />
          </RequestCard>
        ) : null}
      </div>
    );
  };

  getErrorMessage = referenceId => {
    return (
      <div className={styles.requestSuccess}>
        <div>
          <img
            src={commonImages.errorIcon}
            className={styles.successIcon}
            alt="logo"
          />
        </div>
        <div className={styles.errorMsg}>
          {strings.ADMIN.POP_UP.errorMessage}
        </div>
      </div>
    );
  };

  getSuccessMessage = referenceId => {
    return (
      <div className={styles.requestSuccess}>
        <div>
          <img
            src={loginImages.successIcon}
            className={styles.successIcon}
            alt="logo"
          />
        </div>
        <div className={styles.successMessage}>
          {strings.ADMIN.POP_UP.approveCnf1} {referenceId}{' '}
          {strings.ADMIN.POP_UP.approveCnf2}
        </div>
      </div>
    );
  };

  getRejectMessage = () => {
    return (
      <div className={styles.requestReject}>
        <div className={styles.rejectMessage}>
          {strings.ADMIN.POP_UP.toReject}
        </div>
        <textarea
          placeholder={'Type here...'}
          className={styles.popupTextarea}
          required
        />
      </div>
    );
  };
  getRejectConformation = referenceId => {
    return (
      <div className={styles.requestReject}>
        <div className={styles.rejectMessage}>
          {strings.ADMIN.POP_UP.rejectCnf1} {referenceId}{' '}
          {strings.ADMIN.POP_UP.rejectCnf2}
        </div>
        {/* <textarea
          placeholder={"Type here"}
          className={styles.popupTextarea}
          required
        /> */}
      </div>
    );
  };

  onChangeHandler = (action, data) => {
    switch (action) {
      case 'status': {
        this.setState({
          selectedStatus: data,
        });

        // const { agencies } = this.state;
        // const filterAgencies = agencies.filter(
        //   (row) => row.status.toLowerCase() === data.toLowerCase()
        // );
        // this.setState({
        //   filterAgencies,
        //   selectedStatus: data,
        // });

        break;
      }
      case 'agencyName': {
        // const { agencies } = this.state;
        // const filterAgencies = agencies.filter(
        //   (row) => row.company && row.company.name.toLowerCase() === data.toLowerCase()
        // );
        this.setState({
          selectedAgency: data,
        });
        break;
      }

      case 'date': {
        const {startDate, endDate, dates} = data;
        this.setState({
          startDate,
          endDate,
          dates,
        });
        break;
      }

      case 'search': {
        console.info('data..', data);
        this.setState({
          searchText: data,
        });
        // const { agencies } = this.state;
        // const filterAgencies = agencies.filter((element) =>
        //     Object.values(element).toString().toLowerCase().includes(data.toLowerCase()));
        // this.setState({
        //   filterAgencies,
        // });
        break;
      }

      default: {
        console.info('case not handled', action);
      }
    }
  };

  /**
   *
   * @param {*} action
   * @param {*} data
   */

  onClickHandler = (action, data) => {
    const {history} = this.props;
    switch (action) {
      case 'signin': {
        history.push('/sign-in');
        break;
      }
      case 'vehicle-registration': {
        history.push('/vehicle-registration');
        break;
      }
      case 'apply': {
        const {startDate, endDate} = this.state;
        let startDateStr = moment(startDate).format('MM-DD-YYYY');
        let endDateStr = moment(endDate).format('MM-DD-YYYY');
        this.getData(startDateStr, endDateStr, true);
        break;
      }
      case 'clearFilters': {
        this.resetData();
        break;
      }
      case 'rowClick': {
        console.info('data', data);
        const {referenceId} = data;
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.STANDARD,
            heading: `${strings.ADMIN.POP_UP.agencyRequest} - ${referenceId}`,
            message: this.getRequestInfo(data),
            onClose: this.closePopup,
            data: data,
            buttonContainer: styles.buttonContainer,
            buttons: [
              {
                text: strings.ADMIN.POP_UP.reject,
                buttonStyles: styles.buttonPrimary,
                onClick: () => {
                  const {referenceId} = data;
                  this.rejectReason(referenceId, data);
                },
              },
              {
                text: strings.ADMIN.POP_UP.approve,
                buttonStyles: styles.buttonSecondary,
                onClick: () => {
                  const {referenceId} = data;
                  this.approveAgency(data);
                },
              },
            ],
          },
        });
        break;
      }
      default: {
        console.info('case not handled', action);
      }
    }
  };

  resetData = () => {
    this.setState({
      searchText: '',
      selectedStatus: '',
      selectedAgency: '',
      startDate: new moment().startOf('year').format('MM-DD-YYYY'),
      endDate: new moment().add(1, 'years').format('MM-DD-YYYY'),
      dates: null,
    });
    let yearEndDate = new moment().add(1, 'years').format('MM-DD-YYYY');
    let yearStartDate = new moment().startOf('year').format('MM-DD-YYYY');
    this.getData(yearStartDate, yearEndDate, false);
  };

  getProps = () => {
    const {
      statuses,
      agencies,
      filterAgencies,
      searchText,
      selectedAgency,
      selectedStatus,
      startDate,
      endDate,
      dates,
    } = this.state;
    return {
      onClick: this.onClickHandler,
      onChange: this.onChangeHandler,
      statuses,
      agencies,
      filterAgencies,
      searchText,
      selectedAgency,
      selectedStatus,
      startDate,
      endDate,
      dates,
    };
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };

  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      // case "spinner": {
      //   console.log('showing spinner');
      //   return <Spinner />;
      // }
      case POPUP_CONSTANTS.STANDARD: {
        return <AgencyRquestsPopup {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.SPINNER: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

const RequestCard = props => {
  const {header} = props;

  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardheader}>{header}</div>
      {props.children}
    </div>
  );
};
