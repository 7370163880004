import React from 'react';
import styles from './Mobile.module.scss';
import {withRouter} from 'react-router-dom';
import RegistrationFormCard from '../../../../../components/RegistrationFormCard';
import {loginImages} from '../../../../../assets/images';
import strings from '../../../../../globalization/index';

const Mobile = props => {
  const {onClick, location} = props;
  return (
    <div className={styles.container}>
      <RegistrationFormCard
        onClick={onClick}
        terms={true}
        buttonLabel={strings.goBackToLogin}
        header={location.state?.message}>
        <div className={styles.body}>
          <div className={styles.title}>{location?.state?.title || ''}</div>
          <img
            className={styles.successIcon}
            src={loginImages.successIcon}
            alt="Logo"
          />
          <div className={styles.message1}>{strings.successfullySubmitted}</div>

          <div className={styles.message2}>
            {' '}
            {strings.yourReferenceNumberIs} <br />
            <span className={styles.id}>{location.state?.id} </span>,
            {strings.pleaseKeepNoteOfIt}.
          </div>
          <div className={styles.message3}>
            {strings.youWillReceiveMessage} <br />
            {strings.youCanCheckTheStatus}{' '}
            <span className={styles.here}>{strings.here}</span>
          </div>
        </div>
      </RegistrationFormCard>
    </div>
  );
};
export default withRouter(Mobile);
