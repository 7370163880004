export const getSuperAdminProfile = () => {
  return [
    {
      field: 'text',
      elementType: 'text',
      type: 'text',
      label: 'Name',
      placeholder: 'Enter Name',
      required: true,
      inputType: 'alphabets',
      stateField: 'firstName',
      stateDefault: null,
      minLength: 6,
      maxLength: 128,
    },
    {
      field: 'text',
      elementType: 'text',
      type: 'text',
      label: 'Email',
      placeholder: 'Enter Email',
      required: true,
      inputType: 'email',
      stateField: 'emailAddress',
      stateDefault: null,
      minLength: 15,
      maxLength: 128,
      disabled: true,
    },
    {
      inputType: 'phone',
      elementType: 'phone',
      keyboardType: 'number-pad',
      label: 'Phone Number',
      placeholder: 'Enter Phone Number',
      required: true,
      stateField: 'phoneNumber',
      stateDefault: '',
      maxLength: 10,
      verifyOtp: true,
      disabled: true,
    },
  ];
};

export const getSuperAdminAgency = () => {
  return [
    {
      elementType: 'dropdown',
      inputType: 'text',
      label: 'Department',
      placeholder: 'Select Department',
      optionsField: 'departmentOptions',
      required: true,
      stateField: 'departmentId',
      stateDefault: null,
      disabled: true,
      maxLength: 20,
    },
    {
      field: 'name',
      elementType: 'text',
      type: 'text',
      label: 'Agency Name',
      placeholder: 'Enter Agency Name',
      required: true,
      inputType: 'alphabets',
      stateField: 'name',
      stateDefault: null,
      minLength: 6,
      maxLength: 128,
    },
    {
      inputType: 'phone',
      elementType: 'phone',
      keyboardType: 'number-pad',
      label: 'Phone Number',
      placeholder: 'Enter Phone Number',
      required: true,
      stateField: 'phoneNumber',
      stateDefault: '',
      maxLength: 10,
      verifyOtp: true,
      disabled: true,
    },
    {
      field: 'text',
      elementType: 'text',
      type: 'text',
      label: 'Email',
      placeholder: 'Enter Email',
      required: true,
      inputType: 'email',
      stateField: 'emailAddress',
      stateDefault: null,
      minLength: 15,
      maxLength: 128,
      disabled: true,
    },
    {
      elementType: 'dropdown',
      inputType: 'text',
      label: 'Nature of Business',
      placeholder: 'Select Nature of Business',
      optionsField: 'businessOptions',
      required: true,
      stateField: 'businessId',
      stateDefault: null,
      maxLength: 20,
    },
    {
      field: 'text',
      elementType: 'text',
      type: 'text',
      label: 'Address 1',
      placeholder: 'Enter Address',
      required: true,
      inputType: 'text',
      stateField: 'address1',
      stateDefault: null,
      minLength: 6,
      maxLength: 128,
    },
    {
      field: 'text',
      elementType: 'text',
      type: 'text',
      label: 'Address 2',
      placeholder: 'Enter Address',
      required: true,
      inputType: 'text',
      stateField: 'address2',
      stateDefault: null,
      minLength: 6,
      maxLength: 128,
    },
    {
      field: 'text',
      elementType: 'text',
      type: 'text',
      label: 'GST Number',
      placeholder: 'Enter GST Number',
      required: true,
      inputType: 'text',
      stateField: 'gstnumber',
      stateDefault: null,
      minLength: 15,
      maxLength: 128,
      disabled: true,
    },
    {
      elementType: 'file',
      inputType: 'upload',
      label: 'Agency Registration Proof',
      placeholder: 'Upload Proof',
      required: true,
      stateField: 'REGISTRATION_PROOF',
      // FIXME
      stateDefault: [],
      allowedFormats: ['pdf', 'images'],
      maxFiles: 1,
      disabled: true,
    },
    {
      field: 'text',
      elementType: 'text',
      type: 'text',
      label: 'PAN Number',
      placeholder: 'Enter PAN Number',
      required: true,
      inputType: 'text',
      stateField: 'pannumber',
      stateDefault: null,
      minLength: 10,
      maxLength: 128,
      disabled: true,
    },
    {
      elementType: 'file',
      inputType: 'upload',
      label: 'PAN Document',
      placeholder: 'Upload Proof',
      required: true,
      stateField: 'PAN_DOCUMENT',
      // FIXME
      stateDefault: [],
      allowedFormats: ['pdf', 'images'],
      maxFiles: 1,
      dependentOnOtherFields: true,
      disabled: true,
    },
  ];
};

export const getSignatoryProfile = () => {
  return [
    {
      field: 'text',
      elementType: 'text',
      type: 'text',
      label: 'Name',
      placeholder: 'Enter Name',
      required: true,
      inputType: 'alphabets',
      stateField: 'firstName',
      stateDefault: null,
      minLength: 6,
      maxLength: 128,
    },
    {
      field: 'text',
      elementType: 'text',
      type: 'text',
      label: 'Email',
      placeholder: 'Enter Email',
      required: true,
      inputType: 'email',
      stateField: 'emailAddress',
      stateDefault: null,
      minLength: 15,
      maxLength: 128,
      disabled: true,
    },
    {
      inputType: 'phone',
      elementType: 'phone',
      keyboardType: 'number-pad',
      label: 'Phone Number',
      placeholder: 'Enter Phone Number',
      required: true,
      stateField: 'phoneNumber',
      stateDefault: '',
      maxLength: 10,
      verifyOtp: true,
      disabled: true,
    },
    {
      field: 'text',
      elementType: 'text',
      type: 'text',
      label: 'Designation',
      placeholder: 'Enter Designation',
      required: true,
      inputType: 'text',
      stateField: 'designation',
      stateDefault: null,
      minLength: 5,
      maxLength: 128,
    },
    {
      elementType: 'date',
      keyboardType: 'default',
      inputType: 'date',
      label: 'Document Validity (if any)',
      placeholder: 'Choose Date',
      required: false,
      stateField: 'documentValidity',
      stateDefault: null,
      maxLength: 20,
      disabled: true,
      minimumDate: new Date(),
      dependentOnOtherFields: false,
    },
    {
      elementType: 'file',
      inputType: 'upload',
      label: 'Authorized Document',
      placeholder: 'Upload Proof',
      required: true,
      stateField: 'authorizedDocument',
      // FIXME
      stateDefault: [],
      allowedFormats: ['pdf', 'images'],
      maxFiles: 1,
      disabled: true,
    },
    {
      elementType: 'file',
      inputType: 'upload',
      label: 'Identity Proof',
      placeholder: 'Upload Identity Proof',
      required: true,
      stateField: 'idProofDocument',
      // FIXME
      stateDefault: [],
      allowedFormats: ['pdf', 'images'],
      maxFiles: 1,
      disabled: true,
    },
  ];
};

export const getSignatoryAgency = () => {
  return [
    {
      elementType: 'dropdown',
      inputType: 'text',
      label: 'Department',
      placeholder: 'Select Department',
      optionsField: 'departmentOptions',
      required: true,
      stateField: 'departmentId',
      stateDefault: null,
      maxLength: 20,
      disabled: true,
    },
    {
      field: 'name',
      elementType: 'text',
      type: 'text',
      label: 'Agency Name',
      placeholder: 'Enter Agency Name',
      required: true,
      inputType: 'alphabets',
      stateField: 'name',
      stateDefault: null,
      minLength: 6,
      maxLength: 128,
      disabled: true,
    },
    {
      inputType: 'phone',
      elementType: 'phone',
      keyboardType: 'number-pad',
      label: 'Phone Number',
      placeholder: 'Enter Phone Number',
      required: true,
      stateField: 'phoneNumber',
      stateDefault: '',
      maxLength: 10,
      verifyOtp: true,
      dependentOnOtherFields: false,
      disabled: true,
    },
    {
      field: 'text',
      elementType: 'text',
      type: 'text',
      label: 'Email',
      placeholder: 'Enter Email',
      required: true,
      inputType: 'email',
      stateField: 'emailAddress',
      stateDefault: null,
      minLength: 15,
      maxLength: 128,
      disabled: true,
    },
    {
      elementType: 'dropdown',
      inputType: 'text',
      label: 'Nature of Business',
      placeholder: 'Select Nature of Business',
      optionsField: 'businessOptions',
      required: true,
      stateField: 'businessId',
      stateDefault: null,
      maxLength: 20,
      disabled: true,
    },
    {
      field: 'text',
      elementType: 'text',
      type: 'text',
      label: 'Address 1',
      placeholder: 'Enter Address',
      required: true,
      inputType: 'text',
      stateField: 'address1',
      stateDefault: null,
      minLength: 6,
      maxLength: 128,
      disabled: true,
    },
    {
      field: 'text',
      elementType: 'text',
      type: 'text',
      label: 'Address 2',
      placeholder: 'Enter Address',
      required: false,
      inputType: 'text',
      stateField: 'address2',
      stateDefault: null,
      minLength: 6,
      maxLength: 128,
      disabled: true,
    },
  ];
};
