const POPUP_CONSTANTS = {
  STANDARD_POPUP: 'standard-popup',
  ERROR_POPUP: 'error-popup',
  SPINNER_POPUP: 'spinner-popup',
  USER_NOT_CONFIRM: 'user-not-confirm',
  SIMPLE_POPUP: 'simple-popup',
  OTP_POPUP: 'otp-popup',
  PERSONS_CARD_POPUP: 'persons-card-popup',
  SUCCESS_POPUP: 'success-popup',
  REJECT_POPUP: 'reject-popup',
  VEHICLES_CARD_POPUP: 'vehicles-card-popup',
  VIEW_DOCUMENT: 'view-document',
  CARDS_ACTIONS_POPUP: 'cards-actions-popup',
  PHOTO_CAPTURE_POPUP: 'photo-capture-popup',
  HISTORY_POPUP: 'history-popup',
  TERMS_POPUP: 'terms',
  VIEW_PERSON_POPUP: 'view-person-popup',
  VIEW_VEHICLE_POPUP: 'view-vehicle-popup',
  VERIFY_PERSON_POPUP: 'verify-person-popup',
  VERIFY_VEHICLE_POPUP: 'verify-vehicle-popup',
  VALIDATE_VEHICLE_POPUP: 'validate-vehicle-popup',
  VALIDATE_PERSON_POPUP: 'validate-person-popup',
  DELETE_POPUP: 'delete-popup',
  EDIT_POPUP: 'edit-popup',
  ADD_DATA_POPUP: 'add-popup',
  CHANGE_RFID_POPUP: 'change-rfid-popup',
};

export default POPUP_CONSTANTS;
