import React, {Component} from 'react';
import ViewRenderer from './views';
import {API} from '../../../requests';
import 'react-datasheet/lib/react-datasheet.css';
import './styles.css';
import PopupContainer from '../../../components/PopupContainer';
import POPUP_CONSTANTS from '../../../constants/popup-constants';
import Spinner from '../../../components/Spinner';
import Popup from '../../../components/SimplePopup';

import strings from '../../../globalization';
import util from '../../../util';

import {commonImages} from '../../../assets/images';
class WalletBalancesReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      walletBalancesReport: [],
      filterWalletBalancesReport: [],
    };
  }

  showErrorPopup = errorMsg => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMsg,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.onClose,
          },
        ],
        onClose: this.closePopup,
      },
    });
  };

  onClose = () => {
    this.setState({
      popup: null,
    });
  };
  tableActionHandler = (action, data) => {
    switch (action) {
      case 'search': {
        this.setState(
          {
            searchText: data,
          },
          this.applyFilters,
        );
        break;
      }
      case 'clearFilter': {
        this.setState({
          searchText: '',
          selectedStatus: '',
        });
        this.fetchWalletBalancesReport();

        break;
      }
      default: {
        console.info('tableActionHandler case not handled', action);
      }
    }
  };

  fetchWalletBalancesReport = () => {
    this.setState({popup: {type: POPUP_CONSTANTS.SPINNER_POPUP}});
    API.fetchWalletBalancesReportData()
      .then(res => {
        this.setState(
          {
            walletBalancesReport: res.data,
            filterWalletBalancesReport: res.data,
            popup: null,
          },
          this.applyFilters,
        );
      })
      .catch(err => {
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };

  getProps = () => {
    const {searchText, filterWalletBalancesReport} = this.state;
    return {
      tableActionHandler: this.tableActionHandler,
      walletBalancesReport: filterWalletBalancesReport,

      searchText,
    };
  };

  applyFilters = () => {
    const {searchText, walletBalancesReport} = this.state;
    const filteredData =
      walletBalancesReport.length > 0 &&
      walletBalancesReport.filter(row => {
        if (
          searchText &&
          !Object.values(row)
            .toString()
            .toLowerCase()
            .includes(searchText.toLowerCase())
        ) {
          return false;
        }

        return true;
      });

    console.info('filteredData', filteredData);

    this.setState({
      filterWalletBalancesReport: filteredData,
    });
  };
  getPopupContent = () => {
    const {popup} = this.state;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };

  componentDidMount() {
    this.fetchWalletBalancesReport();
  }

  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}
export default WalletBalancesReport;
