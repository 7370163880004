import React, {Component} from 'react';

import bgImage from '../../assets/images/stealth/image.png';
import strings from '../../globalization';
import Spinner from '../../components/Spinner';
import PopupContainer from '../../components/PopupContainer';

import {withRouter} from 'react-router-dom';
import ViewRenderer from './views';
import {API} from '../../requests';
import util from '../../util';

const POPUP_CONSTANTS = {
  STANDARD_POPUP: 'standard-popup',
  ERROR_POPUP: 'error-popup',
  SPINNER_POPUP: 'spinner-popup',
  SIMPLE_POPUP: 'simple-popup',
};

const PAGE_STATUS = {
  FORM: 'form',
  SUCCESS: 'success',
  ERROR: 'error',
};

class Sanna extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageStatus: PAGE_STATUS.FORM,
      formData: {
        fullname: {
          value: '',
          error: '',
        },
        email: {
          value: '',
          error: '',
        },
        company: {
          value: '',
          error: '',
        },
        phone: {
          value: '',
          error: '',
        },
        comments: {
          value: '',
          error: '',
        },
      },
    };
  }

  backClick = () => {
    const {history} = this.props;
    history.goBack();
  };
  componentDidMount() {
    this.getPreLoginToken();
  }
  getPreLoginToken = () => {
    API.getPreLoginToken()
      .then(response => {
        console.log(response);
        API.setPreLoginToken(response);
      })
      .catch(error => {
        console.log(error);
      });
  };

  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  closePopup = () => {
    this.setState({
      popup: null,
    });
  };
  handleClick = action => {
    const {history} = this.props;
    switch (action) {
      case 'goToEDrishti': {
        history.push('/edrishti');
        break;
      }
      default: {
        break;
      }
    }
  };

  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="blue" />;
      }
      default: {
        console.warn('case not handled', popup.type);
        return null;
      }
    }
  };

  onChange = (field, value) => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [field]: {
          value,
          error: '',
        },
      },
    }));
  };
  getProps = () => {
    return {
      handleClick: this.handleClick,
      formData: this.state.formData,
      onChange: this.onChange,
      onSubmit: this.onSubmit,
      pageStatus: this.state.pageStatus,
    };
  };
  onSubmit = () => {
    const error = this.handleErrors();

    if (!error) {
      const params = {
        name: this.state.formData.fullname.value,
        email: this.state.formData.email.value,
        company: this.state.formData.company.value,
        contact: this.state.formData.phone.value,
        comments: this.state.formData.comments.value,
      };
      // FIXME: uncomment
      API.contact(params)
        .then(res => {
          console.log('response', res.data);
          // this.setState({
          //   phoneNumber: personRes.data?.phoneNumber.slice(3),
          //   popup: null,
          // });
          this.setState({
            pageStatus: PAGE_STATUS.SUCCESS,
          });
        })
        .catch(error => {
          const errorMsg = util.getErrorMessage(error);
          console.log(errorMsg);
        });
    }
  };

  handleErrors = () => {
    const {formData} = this.state;
    const {fullname, email, company, phone, comments} = formData;

    let error = false;
    const update = {};

    if (!fullname.value) {
      update.fullname = {
        ...fullname,
        error: `Full name is required`,
      };
      error = true;
    }

    // alphabets in name
    if (!/^[A-Za-z\s]+$/.test(fullname.value)) {
      update.fullname = {
        ...fullname,
        error: `Full name should contain only alphabets`,
      };
      error = true;
    }

    if (!email.value) {
      update.email = {
        ...email,
        error: `Email is required`,
      };
      error = true;
    }
    if (
      !email.value ||
      !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email.value)
    ) {
      update.email = {
        ...email,
        error: `Please enter a valid email`,
      };
      error = true;
    }

    if (!company.value) {
      update.company = {
        ...company,
        error: `Company Name is required`,
      };
      error = true;
    }
    // Check if company name is alphanumeric
    if (!/^[a-z0-9]+$/i.test(company.value)) {
      update.company = {
        ...company,
        error: `Company Name should be alphanumeric`,
      };
      error = true;
    }

    if (!comments.value) {
      update.comments = {
        ...comments,
        error: `Comments are required`,
      };
      error = true;
    }
    this.setState({
      formData: {
        ...formData,
        ...update,
      },
    });
    return error;
  };

  render() {
    const {popup} = this.state;
    const props = this.getProps();

    // console.log('this.props.location', this.props.location);
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...props} />
      </>
    );
  }
}

export default withRouter(Sanna);
