import strings from '../../../../globalization';
export const tempData = [
  {
    date: '10/11/2021',
    referenceId: 'CM21ABCDEF',
    transactionId: '12345678901234567890',
    modeOfPayment: 'UPI',
    amount: '1000',
    credits: '1000',
    time: '17:36:05',
    status: 'Success',
  },
  {
    date: '10/11/2021',
    referenceId: 'CM21ABCDEF',
    transactionId: '12345678901234567890',
    modeOfPayment: 'UPI',
    amount: '1000',
    credits: '1000',
    time: '17:36:05',
    status: 'Failed',
  },
  {
    date: '10/11/2021',
    referenceId: 'CM21ABCDEF',
    transactionId: '12345678901234567890',
    modeOfPayment: 'UPI',
    amount: '1000',
    creditsUsed: '1000',
    time: '17:36:05',
    status: 'Failed',
  },
  {
    date: '10/11/2021',
    referenceId: 'CM21ABCDEF',
    transactionId: '12345678901234567890',
    modeOfPayment: 'UPI',
    amount: '1000',
    creditsUsed: '1000',
    time: '17:36:05',
    status: 'Success',
  },
];
