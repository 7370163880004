import React, {useEffect, useState} from 'react';
import Flex from '../../../../../../components/Flex';
import styles from './styles.module.scss';

import moment from 'moment';
import FormElement from '../../../../../../components/FormElement';

export default function Desktop({
  goBack,
  onChange,
  onClick,
  checkDisabled,
  selectedStatus,
  onChangeHandler,
  stateData,
  formData,
  handleUpload,
  checkDisabledForm,
}) {
  return (
    <Flex className={styles.mainContainer}>
      <Flex className={styles.container}>
        <Header title={selectedStatus} />
        <div className={styles.h2}>Authorized User Details</div>
        <Flex className={styles.formContainer}>
          {formData.map((elem, idx) => {
            return (
              <Flex direction="column">
                <FormElement
                  type={elem.elementType}
                  label={elem.label}
                  required={elem.required}
                  placeholderText={elem.placeholder}
                  onChange={text => {
                    onChangeHandler(elem.stateField, text);
                  }}
                  options={stateData[elem.optionsField]}
                  value={stateData[elem.stateField].value}
                  key={idx}
                  maxLength={elem.maxLength}
                  maxFiles={elem.maxFiles}
                  error={stateData[elem.stateField].error}
                  allowedFormats={elem.allowedFormats}
                  handleUpload={(file, error) => {
                    handleUpload(elem.stateField, file, error);
                  }}
                  disabled={checkDisabledForm(elem)}
                  dropdownStyle={{height: '45px'}}
                  containerClassNames={styles.containerClassNames}
                  minimumDate={elem.minimumDate}
                />
              </Flex>
            );
          })}
        </Flex>
        <Flex direction="row" className={styles.buttonContainer}>
          {selectedStatus === 'EDIT' || selectedStatus === 'DELETE' ? (
            <button className={styles.delete} onClick={() => onClick('delete')}>
              Delete
            </button>
          ) : null}
          <button className={styles.cancel} onClick={() => onClick('cancel')}>
            Back
          </button>
          <button
            className={styles.finish}
            style={
              checkDisabled()
                ? {backgroundColor: '#0045e670'}
                : {backgroundColor: '#0045e6'}
            }
            disabled={checkDisabled()}
            onClick={() => onClick('save')}>
            {selectedStatus === 'ADD' ? 'Add' : 'Save'}
          </button>
        </Flex>
      </Flex>
    </Flex>
  );
}

const Header = props => {
  const {title} = props;
  console.log(title);
  return (
    <Flex className={styles.header}>
      <p>
        {title === 'ADD'
          ? 'Add User'
          : title === 'EDIT'
          ? 'Edit User'
          : 'Delete User'}
      </p>
    </Flex>
  );
};
