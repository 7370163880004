import React, {useEffect, useState} from 'react';
import Flex from '../../../Flex';
import SannaLogo from '../../../SannaLogo';
import styles from './styles.module.scss';

import pidsIcon from '../../../../assets/images/Landing/hero/1.png';
import cctvIcon from '../../../../assets/images/Landing/hero/2.png';
import smartAnalyticsIcon from '../../../../assets/images/Landing/hero/3.png';
import IOTIcon from '../../../../assets/images/Landing/hero/4.png';
import geolocationIcon from '../../../../assets/images/Landing/hero/5.png';
import accessControlIcon from '../../../../assets/images/Landing/hero/6.png';
import outerCircle from '../../../../assets/images/Landing/hero/outer-circle.png';
import outerCircleComplete from '../../../../assets/images/Landing/hero/outer-circle-complete.png';
import line from '../../../../assets/images/Landing/hero/line.png';

import {
  motion,
  useElementScroll,
  useMotionValue,
  useTransform,
  useViewportScroll,
} from 'framer-motion';

import {Link} from 'react-router-dom';
let interval;
export const Hero = () => {
  // get router

  const icons = [
    {id: 0, src: accessControlIcon, text: 'Access Control'},
    // {id: 1, src: accessControlIcon, type: 'dummy'},
    {id: 1, src: geolocationIcon, text: 'Geo Tracking'},
    // {id: 3, src: geolocationIcon, type: 'dummy'},
    {id: 2, src: IOTIcon, text: 'IOT'},
    // {id: 5, src: IOTIcon, type: 'dummy'},
    {id: 3, src: smartAnalyticsIcon, text: 'Smart Analytics'},
    // {id: 7, src: smartAnalyticsIcon, type: 'dummy'},
    {id: 4, src: cctvIcon, text: 'CCTV'},
    // {id: 9, src: cctvIcon, type: 'dummy'},
    {id: 5, src: pidsIcon, text: 'PIDS'},
    // {id: 11, src: pidsIcon, type: 'dummy'},
  ];
  const [currentIcon, setCurrentIcon] = useState(icons[0]);

  useEffect(() => {
    if (currentIcon) {
      // FIXME: Add back this thing
      startFlipping();
    }

    return () => clearInterval(interval);
  }, []);

  const ref = React.useRef(null);
  const {scrollYProgress} = useViewportScroll();
  let top = 0;

  const y = useTransform(
    scrollYProgress,
    [0, 1],
    [top, window.innerHeight - 200],
  );

  React.useEffect(() => {
    const rect = ref.current?.getBoundingClientRect();

    top = rect.top;
  }, [ref]);

  const startFlipping = () => {
    interval = setInterval(() => {
      setCurrentIcon(currentIcon => icons[(currentIcon.id + 1) % icons.length]);
    }, 3000);
  };
  const dropIn = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        ease: 'easeInOut',
      },
    },
    exit: {
      opacity: 0,
    },
  };
  const dropInText = {
    hidden: {
      opacity: 0,
      // y: '-2vh',
    },
    visible: {
      opacity: 1,
      transition: {
        // delay: 0.5,

        ease: [0.43, 0.13, 0.23, 0.96],
      },
    },
    exit: {
      opacity: 0,
      // y: '2vh',
    },
  };

  return (
    <div className={styles.heroContainer}>
      <div className={styles.gradientMask} />
      <div className={styles.overlay} />

      <div className={styles.content}>
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            color: '#fff',
            marginTop: '4rem',
          }}>
          <div
            style={{
              display: 'inline-flex',
              alignItems: 'flex-end',
              flexDirection: 'column',
              color: '#fff',
              marginBottom: '4rem',
            }}>
            <motion.div ref={ref}>
              <PoweredBySanna />
            </motion.div>
            <p className={styles.smartPorts}>Smart Ports</p>
          </div>
          <p className={styles.subText}>
            Digitally enhancing the port operations and building future-ready
            ports using innovative & emerging technologies
          </p>
          <a href="#contact" className={styles.heroCTA}>
            Let's Discuss
          </a>
        </div>
      </div>
    </div>
  );
};

export const PoweredBySanna = props => {
  return (
    <motion.svg
      width="305"
      height="85"
      viewBox="0 0 305 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M13.1427 81.3018C9.66922 79.889 6.60922 77.6452 4.29355 74.7365C2.14328 72.0356 0.861389 68.7945 0.613281 65.9689H17.3192C17.4846 67.2155 18.4771 68.836 20.0071 69.9164C21.7025 71.0799 23.7287 71.7031 25.8376 71.6616C27.9465 71.6616 29.5592 71.2461 30.7171 70.4566C31.7922 69.7917 32.4538 68.5867 32.4538 67.3401C32.4538 65.8027 31.6268 64.6808 29.9727 63.9328C27.3676 62.894 24.7211 62.1045 21.9506 61.4812C18.6011 60.7748 15.293 59.8191 12.0676 58.6557C9.42111 57.6584 7.0641 56.0378 5.24464 53.9602C3.34248 51.841 2.39139 48.9324 2.39139 45.2757C2.39139 42.284 3.30112 39.3753 5.03787 36.8821C6.77463 34.3474 9.37978 32.3945 12.7706 30.8986C16.1614 29.4442 20.2138 28.6963 24.8865 28.6963C31.7922 28.6963 37.2506 30.3584 41.2203 33.6826C45.19 37.0068 47.5057 41.4114 48.0433 49.3479H32.4538C32.2471 45.1926 31.3373 43.5721 29.9314 42.4917C28.5254 41.4114 26.6646 40.8712 24.3489 40.8712C22.3641 40.8712 20.8754 41.2451 19.8003 41.9931C18.7665 42.6579 18.1876 43.8214 18.229 45.0264C18.229 46.5639 19.056 47.6858 20.7514 48.4753C22.4468 49.2232 25.0519 50.0127 28.6081 50.7607C31.9989 51.5502 35.3484 52.589 38.6152 53.794C41.2617 54.8328 43.5773 56.4534 45.3968 58.5725C47.3403 60.7748 48.3327 63.7251 48.4154 67.4232C48.4568 70.415 47.5057 73.3652 45.6863 75.8168C43.8668 78.2684 41.2617 80.2214 37.8709 81.6342C34.1079 83.0885 30.0968 83.8365 26.0857 83.7534C21.6611 83.8365 17.2779 83.0054 13.2254 81.3433L13.1427 81.3018Z"
        fill="white"
        stroke="white"
        stroke-width="0.64"
      />
      <path
        d="M98.5737 35.8849C98.5737 34.1813 98.5737 32.6854 98.5737 31.1479C98.5737 30.5246 98.7391 30.4415 99.318 30.4415C104.611 30.4415 109.904 30.4415 115.197 30.4415C115.817 30.4415 115.941 30.6077 115.941 31.1895C115.941 42.4087 115.941 53.5863 115.941 64.7639V81.2187C115.941 81.6342 115.61 81.9251 115.238 81.9251C109.945 81.9251 104.652 81.9251 99.318 81.9251C98.6978 81.9251 98.5737 81.8004 98.5737 81.2187C98.6151 79.6813 98.5737 78.1854 98.5737 76.5233C98.0361 77.1466 97.6226 77.6867 97.1677 78.1854C94.6867 80.6785 91.4613 82.3406 87.9464 82.9224C78.601 84.7507 69.8759 83.3379 62.2259 77.3959C56.7675 73.1575 53.7075 67.4233 52.6737 60.6918C51.764 54.8744 52.4256 49.1817 55.1134 43.863C59.0832 36.0927 65.658 31.5219 74.135 29.5689C79.304 28.2808 84.7623 28.4055 89.8899 30.026C93.3221 31.1064 96.3407 33.1424 98.5323 35.9265L98.5737 35.8849ZM82.7361 69.2931C84.0594 69.2931 85.424 69.1685 86.7059 68.8776C92.0402 67.631 95.4723 64.3068 96.8369 59.1543C98.1602 54.0018 96.5061 49.5557 92.3296 46.2315C87.9464 42.7411 82.8188 42.4087 77.6086 43.9046C70.5375 45.9406 66.6091 53.8356 69.1729 60.5256C71.2818 66.0105 76.3267 69.2931 82.7361 69.2931V69.2931Z"
        fill="white"
        stroke="white"
        stroke-width="0.64"
      />
      <path
        d="M289.618 35.8848V31.0647C289.618 30.5661 289.742 30.483 290.197 30.483H305.579C306.076 30.483 306.158 30.6077 306.158 31.1063V81.3433C306.158 81.8419 306.034 81.9666 305.579 81.9666C300.452 81.9666 295.324 81.9666 290.197 81.9666C289.742 81.9666 289.576 81.8835 289.618 81.3848C289.618 79.8474 289.618 78.2684 289.618 76.4816C288.046 78.8086 285.896 80.5953 283.374 81.6757C279.652 83.2962 275.6 83.9611 271.589 83.6702C266.296 83.4209 261.375 82.0081 256.95 78.9332C250.913 74.7364 247.357 68.836 246.034 61.4812C244.958 55.3729 245.537 49.4725 248.266 43.9045C252.029 36.1757 258.274 31.6049 266.337 29.6519C271.258 28.3223 276.468 28.4885 281.348 30.0675C284.614 31.1063 287.509 33.1839 289.618 35.9679V35.8848ZM276.385 71.2045C277.709 71.2045 279.073 71.0798 280.355 70.7889C285.689 69.5424 289.122 66.2182 290.486 61.0656C291.809 55.9131 290.155 51.467 285.979 48.1844C281.596 44.7355 276.468 44.3615 271.258 45.8574C264.187 47.8935 260.258 55.7885 262.822 62.4369C264.931 67.9218 269.976 71.2045 276.385 71.2045Z"
        fill="white"
        stroke="white"
        stroke-width="0.64"
      />
      <path
        d="M140.547 37.547C141.457 36.342 142.532 35.2201 143.69 34.2228C146.295 32.1867 149.438 30.9401 152.746 30.6493C156.715 30.1506 160.809 30.4 164.655 31.4803C171.685 33.4748 175.737 38.1287 177.267 45.0264C177.722 47.0625 177.929 49.1401 177.929 51.2593V82.9223C177.929 83.3794 177.556 83.7534 177.102 83.7534C171.685 83.7534 166.309 83.7534 160.892 83.7534C160.23 83.7534 160.106 83.5872 160.106 82.9639C160.106 73.7808 160.106 64.5561 160.106 55.373C160.106 53.6694 159.982 52.0073 159.61 50.3452C158.742 46.8548 156.467 44.8187 152.787 44.3616C151.174 44.1538 149.52 44.1954 147.908 44.5278C143.979 45.2758 141.746 47.5611 141.043 51.4255C140.795 52.7552 140.712 54.1264 140.712 55.4977V81.9666C140.712 82.4237 140.34 82.7561 139.927 82.7561C134.923 82.7561 129.92 82.7561 124.875 82.7561C124.461 82.7561 124.172 82.4237 124.172 82.0497V32.8931C124.172 32.3114 124.296 32.1452 124.916 32.1452C129.837 32.1452 134.758 32.1452 139.679 32.1452C140.258 32.1452 140.464 32.2698 140.423 32.8516C140.464 34.3474 140.506 35.8433 140.506 37.547H140.547Z"
        fill="white"
        stroke="white"
        stroke-width="0.64"
      />
      <path
        d="M202.533 37.5884C204.807 34.3889 208.198 32.062 212.043 31.1062C214.855 30.4414 217.709 30.2336 220.562 30.4414C224.077 30.6076 227.509 31.314 230.652 32.9761C235.531 35.5939 238.136 39.7492 239.335 44.9017C239.79 46.9378 239.997 49.0154 239.997 51.1346V82.8807C239.997 83.3378 239.625 83.6702 239.211 83.6702C233.794 83.6702 228.377 83.6702 222.919 83.6702C222.547 83.6702 222.216 83.3378 222.216 82.9638C222.216 73.7391 222.216 64.556 222.216 55.3314C222.216 53.6277 222.092 51.9656 221.72 50.3035C220.851 46.73 218.577 44.7355 214.855 44.2784C213.077 44.0291 211.258 44.1122 209.48 44.5277C205.965 45.3172 203.773 47.6857 203.112 51.3423C202.905 52.672 202.781 54.0432 202.781 55.4145V81.8834C202.781 82.2989 202.45 82.6314 202.036 82.6314C196.992 82.6314 191.947 82.6314 186.902 82.6314C186.53 82.6314 186.24 82.3405 186.24 81.9665V32.8099C186.24 32.3113 186.323 32.1035 186.943 32.1035C191.905 32.1035 196.868 32.1035 201.83 32.1035C202.367 32.1035 202.574 32.1866 202.533 32.7683C202.533 34.3058 202.533 35.8017 202.533 37.5469V37.5884Z"
        fill="white"
        stroke="white"
        stroke-width="0.64"
      />
      <path
        d="M235.24 0.689819C235.943 2.93366 236.604 5.13594 237.307 7.37977C237.928 9.4574 238.548 11.535 239.21 13.6127C239.334 13.862 239.292 14.1528 239.127 14.3606C237.266 16.1889 235.447 18.0172 233.048 19.43C229.12 21.7569 224.778 22.0478 220.188 21.0921C217.707 20.5104 215.267 19.7209 212.993 18.7236C209.685 17.3108 206.087 16.3551 202.407 15.9396C198.148 15.5241 194.798 16.6044 192.234 19.43C191.573 20.1364 190.994 20.8843 190.291 21.6738C189.795 19.9702 189.299 18.3912 188.802 16.8122C187.975 14.1944 187.19 11.5766 186.363 8.91722C186.239 8.6679 186.239 8.41859 186.363 8.16927C188.347 5.55146 190.704 3.26608 194.178 1.97795C198.106 0.482057 202.159 0.648267 206.211 1.52087C210.264 2.43502 214.151 3.88936 218.079 5.13594C219.733 5.71767 221.429 6.1332 223.165 6.46562C226.515 7.04735 229.203 5.92544 231.56 4.09713C232.593 3.26607 233.544 2.35192 234.454 1.39621C234.661 1.10534 234.95 0.897582 235.281 0.731372L235.24 0.689819Z"
        fill="#01F9F4"
        stroke="#01F9F4"
        stroke-width="0.64"
      />
      <path
        d="M296.44 0.689868C297.681 4.63736 298.756 8.50174 300.079 12.283C300.575 13.6958 300.327 14.6515 299.087 15.6488C297.722 16.7707 296.523 18.0173 294.993 18.973C290.899 21.6739 286.268 22.131 281.306 21.0506C278.618 20.4273 276.054 19.5547 273.614 18.4743C270.472 17.1447 267.039 16.2721 263.524 15.8981C259.224 15.441 255.792 16.5629 253.228 19.3885C252.608 20.0949 252.029 20.8013 251.326 21.5908C251.078 20.8428 250.912 20.178 250.706 19.5547C249.589 15.9812 248.473 12.4077 247.356 8.87571C247.232 8.6264 247.273 8.37708 247.397 8.12777C249.63 5.17754 252.401 2.68439 256.577 1.56247C260.134 0.56521 263.731 0.772973 267.329 1.56247C271.464 2.47663 275.31 3.93096 279.279 5.13599C280.809 5.63462 282.339 6.05014 283.952 6.38256C286.971 7.00585 289.576 6.38256 291.892 4.80357C293.297 3.80631 294.538 2.72594 295.655 1.52092L296.482 0.648315L296.44 0.689868Z"
        fill="#F58E1F"
        stroke="#F58E1F"
        stroke-width="0.64"
      />
      <path
        d="M171.56 0.689819C172.924 5.13594 174.248 9.41585 175.571 13.7373C175.654 13.9451 175.612 14.1528 175.447 14.319C173.462 16.272 171.518 18.225 168.913 19.6378C164.902 21.7985 160.56 21.9232 156.053 20.9259C153.531 20.3026 151.132 19.4716 148.816 18.4327C144.805 16.8122 140.67 15.6903 136.163 15.7734C133.31 15.8149 131.077 16.9369 129.216 18.6821C128.265 19.5962 127.438 20.5935 126.652 21.6323C125.866 19.0976 125.081 16.6875 124.378 14.2359C123.799 12.4492 123.261 10.6209 122.682 8.83411C122.558 8.62635 122.6 8.37703 122.724 8.16927C125.205 4.88662 128.348 2.22726 133.144 1.27155C136.783 0.565162 140.339 1.02224 143.813 1.89484C147.907 2.93365 151.752 4.38799 155.722 5.59302C157.045 6.00854 158.41 6.34096 159.816 6.54872C163.124 7.0058 165.647 5.88388 167.921 4.09712C169.203 3.09986 170.278 1.93639 171.518 0.731372L171.56 0.689819Z"
        fill="#EE5123"
        stroke="#EE5123"
        stroke-width="0.64"
      />
    </motion.svg>
  );
};
