import strings from '../../../globalization';

export const getHistoryData = () => {
  return [
    {
      date: new Date(),
      operatorId: '1234',
      actionType: 'Approved',
      remark: 'Nice name! I like it',
      photo: 'https://dpt-mroads.com/photo.jpg',
    },
    {
      date: new Date(),
      operatorId: 'Ash69',
      actionType: 'On Hold',
      remark: 'Nice Remark There.....',
      photo: 'https://dpt-mroads.com/photo2.jpg',
    },
  ];
};
