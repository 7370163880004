export const importCargoFormData = [
  {
    elementType: 'text',
    inputType: 'text',
    label: 'IMO Number',
    placeholder: 'Enter IMO Number',
    required: true,
    stateField: 'IMONumber',
    stateDefault: '',
    maxLength: 20,
  },

  {
    elementType: 'text',
    inputType: 'text',
    label: 'IGM Number',
    placeholder: 'Enter IGM Number',
    required: true,
    stateField: 'IGMNumber',
    stateDefault: '',
    maxLength: 20,
  },
  {
    elementType: 'text',
    inputType: 'text',
    label: 'Vessel VCN Number',
    placeholder: 'Enter Vessel VCN Number',
    required: true,
    stateField: 'VCNNumber',
    stateDefault: '',
    maxLength: 20,
  },

  {
    elementType: 'date',
    inputType: 'text',
    label: 'IGM Date',
    placeholder: 'Select IGM Date',
    required: true,
    stateField: 'IGMDate',
    stateDefault: null,
    maxLength: 20,
  },
  // {
  //   elementType: 'text',
  //   inputType: 'text',
  //   label: 'CHA',
  //   placeholder: 'Enter CHA',
  //   required: true,
  //   stateField: 'CHA',
  //   stateDefault: '',
  //   maxLength: 20,
  // },
  // {
  //   elementType: 'text',
  //   inputType: 'text',
  //   label: 'CHA Code',
  //   placeholder: 'Enter CHA Code',
  //   required: true,
  //   stateField: 'CHACode',
  //   stateDefault: '',
  //   maxLength: 20,
  // },
  // {
  //   elementType: 'text',
  //   inputType: 'text',
  //   label: 'BOE Number',
  //   placeholder: 'Enter BOE Number',
  //   required: true,
  //   stateField: 'BOENumber',
  //   stateDefault: '',
  //   maxLength: 20,
  // },
  {
    elementType: 'text',
    inputType: 'text',
    label: 'Delivery Order',
    placeholder: 'Enter Delivery Order',
    required: true,
    stateField: 'deliveryOrder',
    stateDefault: '',
    maxLength: 20,
  },
  {
    elementType: 'date',
    inputType: 'text',
    label: 'Delivery Order Date',
    placeholder: 'Select Delivery Order Date',
    required: true,
    stateField: 'deliveryOrderDate',
    stateDefault: null,
    maxLength: 20,
  },
  // {
  //   elementType: 'text',
  //   inputType: 'text',
  //   label: 'IA Number',
  //   placeholder: 'Enter IA Number',
  //   required: true,
  //   stateField: 'IANumber',
  //   stateDefault: '',
  //   maxLength: 20,
  // },
  // {
  //   elementType: 'date',
  //   inputType: 'text',
  //   label: 'IA Date',
  //   placeholder: 'Select IA Date',
  //   required: true,
  //   stateField: 'IADate',
  //   stateDefault: null,
  //   maxLength: 20,
  // },
  {
    elementType: 'text',
    inputType: 'text',
    label: 'Vessel Name',
    placeholder: 'Enter Vessel Name',
    required: true,
    stateField: 'vesselName',
    stateDefault: '',
    maxLength: 20,
  },
  // {
  //   elementType: 'text',
  //   inputType: 'text',
  //   label: 'Line Number',
  //   placeholder: 'Enter Line Number',
  //   required: true,
  //   stateField: 'lineNumber',
  //   stateDefault: '',
  //   maxLength: 20,
  // },
  // {
  //   elementType: 'text',
  //   inputType: 'text',
  //   label: 'Sub-Line Number',
  //   placeholder: 'Enter Sub-Line Number',
  //   required: true,
  //   stateField: 'subLineNumber',
  //   stateDefault: '',
  //   maxLength: 20,
  // },

  // {
  //   elementType: 'text',
  //   inputType: 'text',
  //   label: 'BL NO',
  //   placeholder: 'Enter BL NO',
  //   required: true,
  //   stateField: 'blNo',
  //   stateDefault: '',
  //   maxLength: 20,
  // },
  // {
  //   elementType: 'dropdown',
  //   inputType: 'text',
  //   label: 'Nature of Cargo',
  //   placeholder: 'Select Nature of Cargo',
  //   required: true,
  //   stateField: 'natureOfCargo',
  //   optionsField: 'natureOfCargoOptions',
  //   stateDefault: null,
  // },
  // {
  //   elementType: 'dropdown',
  //   inputType: 'text',
  //   label: 'Units of Weight',
  //   placeholder: 'Select Units of Weight',
  //   required: true,
  //   stateField: 'unitsOfWeight',
  //   optionsField: 'unitsOfWeightOptions',
  //   stateDefault: null,
  // },
  // {
  //   elementType: 'text',
  //   inputType: 'number',
  //   label: 'Total Weight / Number of Units',
  //   placeholder: 'Enter Total Weight / Number of Units',
  //   required: true,
  //   stateField: 'totalWeightNumberOfUnits',
  //   stateDefault: '',
  //   maxLength: 20,
  // },
  // {
  //   elementType: 'text',
  //   inputType: 'text',
  //   label: 'Cargo Description',
  //   placeholder: 'Enter Cargo Description',
  //   required: true,
  //   stateField: 'cargoDescription',
  //   stateDefault: '',
  //   maxLength: 20,
  // },
  // {
  //   elementType: 'text',
  //   inputType: 'text',
  //   label: 'Bill of Entry Number',
  //   placeholder: 'Enter Bill of Entry',
  //   required: true,
  //   stateField: 'billOfEntry',
  //   stateDefault: '',
  //   maxLength: 20,
  // },
  {
    elementType: 'text',
    inputType: 'text',
    label: 'Out Of Charge Number',
    placeholder: 'Enter Out Of Charge Number',
    required: true,
    stateField: 'outOfChargeNo',
    stateDefault: '',
    maxLength: 20,
  },

  // {
  //   elementType: 'date',
  //   inputType: 'text',
  //   label: 'Request Valid Upto',
  //   placeholder: 'Select Request Valid Upto',
  //   required: true,
  //   stateField: 'requestValidUpto',
  //   stateDefault: null,
  //   maxLength: 20,
  // },
  // {
  //   elementType: 'file',
  //   inputType: 'upload',
  //   label: 'Proof Document',
  //   placeholder: 'Enter Proof Document',
  //   required: true,
  //   stateField: 'proofDocument',
  //   stateDefault: [],
  //   maxFiles: 1,
  // },
];
export const exportCargoFormData = [
  // {
  //   elementType: 'text',
  //   inputType: 'text',
  //   label: 'CHA Code',
  //   placeholder: 'Enter CHA Code',
  //   required: true,
  //   stateField: 'CHACode',
  //   stateDefault: '',
  //   maxLength: 20,
  // },
  // {
  //   elementType: 'text',
  //   inputType: 'text',
  //   label: 'Shipping Bill Number',
  //   placeholder: 'Enter Shipping Bill Number',
  //   required: true,
  //   stateField: 'ShippingBillNumber',
  //   stateDefault: '',
  //   maxLength: 20,
  // },
  {
    elementType: 'date',
    inputType: 'text',
    label: 'Shipping Bill Date',
    placeholder: 'Select Shipping Bill Date',
    required: true,
    stateField: 'ShippingBillDate',
    stateDefault: null,
    maxLength: 20,
  },
  {
    elementType: 'text',
    inputType: 'text',
    label: 'Carting Permission Number',
    placeholder: 'Enter Carting Permission Number',
    required: true,
    stateField: 'cartingPermissionNumber',
    stateDefault: '',
    maxLength: 20,
  },
  {
    elementType: 'date',
    inputType: 'text',
    label: 'Carting Permission Date',
    placeholder: 'Select Carting Permission Date',
    required: true,
    stateField: 'cartingPermissionDate',
    stateDefault: null,
    maxLength: 20,
  },
  {
    elementType: 'file',
    inputType: 'upload',
    label: 'Upload Carting Permission',
    placeholder: 'Upload Document',
    required: true,
    stateField: 'cartingPermissionDocument',
    stateDefault: [],
    maxFiles: 1,
  },
  {
    elementType: 'text',
    inputType: 'text',
    label: 'Vessel name',
    placeholder: 'Enter Vessel name',
    required: true,
    stateField: 'vesselName',
    stateDefault: '',
    maxLength: 20,
  },
  {
    elementType: 'text',
    inputType: 'text',
    label: 'Vessel VCN Number',
    placeholder: 'Enter Vessel VCN Number',
    required: true,
    stateField: 'VCNNumber',
    stateDefault: '',
    maxLength: 20,
  },
  {
    elementType: 'text',
    inputType: 'text',
    label: 'HS Code',
    placeholder: 'Enter HS Code',
    required: true,
    stateField: 'HSCode',
    stateDefault: '',
    maxLength: 20,
  },
  {
    elementType: 'text',
    inputType: 'text',
    label: 'FOB Value',
    placeholder: 'Enter FOB Value',
    required: true,
    stateField: 'FOBValue',
    stateDefault: '',
    maxLength: 20,
  },
  {
    elementType: 'dropdown',
    inputType: 'text',
    label: 'Nature of Cargo',
    placeholder: 'Select Nature of Cargo',
    required: true,
    stateField: 'natureOfCargo',
    optionsField: 'natureOfCargoOptions',
    stateDefault: null,
  },
  {
    elementType: 'text',
    inputType: 'number',
    label: 'Total Weight / Number of Units',
    placeholder: 'Enter Total Weight / Number of Units',
    required: true,
    stateField: 'totalWeightNumberOfUnits',
    stateDefault: '',
    maxLength: 20,
  },
  {
    elementType: 'dropdown',
    inputType: 'text',
    label: 'Units of Weight',
    placeholder: 'Select Units of Weight',
    required: true,
    stateField: 'unitsOfWeight',
    optionsField: 'unitsOfWeightOptions',
    stateDefault: null,
  },
  // {
  //   elementType: 'file',
  //   inputType: 'upload',
  //   label: 'Proof Document',
  //   placeholder: 'Enter Proof Document',
  //   required: true,
  //   stateField: 'proofDocument',
  //   stateDefault: [],
  //   maxFiles: 1,
  // },
  {
    elementType: 'text',
    inputType: 'text',
    label: 'Cargo Description',
    placeholder: 'Enter Cargo Description',
    required: true,
    stateField: 'cargoDescription',
    stateDefault: '',
    maxLength: 20,
  },
  // {
  //   elementType: 'date',
  //   inputType: 'text',
  //   label: 'Request Valid Upto',
  //   placeholder: 'Select Request Valid Upto',
  //   required: true,
  //   stateField: 'requestValidUpto',
  //   stateDefault: null,
  //   maxLength: 20,
  // },
];
export const cargoHandlingFormData = [
  {
    elementType: 'text',
    inputType: 'text',
    label: 'Work Order / Permit Number',
    placeholder: 'Enter Details',
    required: true,
    stateField: 'workOrderPermitNumber',
    stateDefault: '',
    maxLength: 20,
  },
  {
    elementType: 'date',
    inputType: 'text',
    label: 'Valid Date',
    placeholder: 'Select Valid Date',
    required: true,
    stateField: 'validDate',
    stateDefault: null,
    maxLength: 20,
  },
  {
    elementType: 'text',
    inputType: 'text',
    label: 'Vessel name',
    placeholder: 'Enter Vessel name',
    required: true,
    stateField: 'vesselName',
    stateDefault: '',
    maxLength: 20,
  },
  {
    elementType: 'text',
    inputType: 'number',
    label: 'Quantity',
    placeholder: 'Enter quantity',
    required: true,
    stateField: 'quantity',
    stateDefault: '',
    maxLength: 20,
  },
  {
    elementType: 'dropdown',
    inputType: 'text',
    label: 'Units/Denominations',
    placeholder: 'Select',
    required: true,
    stateField: 'unitsOfWeight',
    optionsField: 'unitsOfWeightOptions',
    stateDefault: null,
  },
  {
    elementType: 'file',
    inputType: 'upload',
    label: 'Proof Document',
    placeholder: 'Enter Proof Document',
    required: true,
    stateField: 'proofDocument',
    stateDefault: [],
    maxFiles: 1,
  },
  {
    elementType: 'text',
    inputType: 'text',
    label: 'Material Description',
    placeholder: 'Enter Material Description',
    required: true,
    stateField: 'materialDescription',
    stateDefault: '',
    maxLength: 20,
  },
  {
    elementType: 'dropdown',
    inputType: 'text',
    label: 'Permit Type',
    placeholder: 'Select',
    required: true,
    stateField: 'permitType',
    optionsField: 'permitTypeOptions',
    stateDefault: null,
  },
];
export const engineeringMaterialFormData = [
  {
    elementType: 'text',
    inputType: 'text',
    label: 'Work Order / Permit Number',
    placeholder: 'Enter Details',
    required: true,
    stateField: 'workOrderPermitNumber',
    stateDefault: '',
    maxLength: 20,
  },
  {
    elementType: 'date',
    inputType: 'text',
    label: 'Valid Date',
    placeholder: 'Select Valid Date',
    required: true,
    stateField: 'validDate',
    stateDefault: null,
    maxLength: 20,
  },
  {
    elementType: 'text',
    inputType: 'number',
    label: 'Quantity',
    placeholder: 'Enter quantity',
    required: true,
    stateField: 'quantity',
    stateDefault: '',
    maxLength: 20,
  },
  {
    elementType: 'dropdown',
    inputType: 'text',
    label: 'Units/Denominations',
    placeholder: 'Select',
    required: true,
    stateField: 'unitsOfWeight',
    optionsField: 'unitsOfWeightOptions',
    stateDefault: null,
  },
  {
    elementType: 'file',
    inputType: 'upload',
    label: 'Proof Document',
    placeholder: 'Enter Proof Document',
    required: true,
    stateField: 'proofDocument',
    stateDefault: [],
    maxFiles: 1,
  },
  {
    elementType: 'text',
    inputType: 'text',
    label: 'Material Description',
    placeholder: 'Enter Material Description',
    required: true,
    stateField: 'materialDescription',
    stateDefault: '',
    maxLength: 20,
  },
  {
    elementType: 'dropdown',
    inputType: 'text',
    label: 'Permit Type',
    placeholder: 'Select',
    required: true,
    stateField: 'permitType',
    optionsField: 'permitTypeOptions',
    stateDefault: null,
  },
];

// export const importData = {
//   IMONumber: '',
//   VCNNumber: '',
//   IGMNumber: '',
//   IGMDate: '',
//   CHACode: '',
//   CHA: '',
//   DeliveryOrder: '',
//   DeliveryOrderDate: '',
//   VesselName: '',
//   ValidityDate: '',
//   LineNumbers: [2, 5],
//   LineInfo: [
//     {
//       lineNumber: '2',
//       blNo: '99',
//       grossQuantity: '100',
//       units: 'KGS',
//       cargoDescription: 'first cargo',
//       natureOfCargo: 'Bulk',
//     },
//     {
//       lineNumber: '51',
//       blNo: '101',
//       grossQuantity: '200',
//       units: 'Meters',
//       cargoDescription: 'second cargo',
//       natureOfCargo: 'TBulk',
//     },
//   ],
// };

// export const lineInfo = [
//   {
//     lineNumber: '2',
//     blNo: '99',
//     grossQuantity: '100',
//     units: 'KGS',
//     cargoDescription: 'first cargo',
//     natureOfCargo: 'Bulk',
//     isSelected: true,
//   },
//   {
//     lineNumber: '5',
//     blNo: '101',
//     grossQuantity: '200',
//     units: 'Meters',
//     cargoDescription: 'second cargo',
//     natureOfCargo: 'TBulk',
//     isSelected: true,
//   },
// ];
