import strings from '../../../globalization';

export const getPopupFormData = () => {
  return [
    {
      elementType: 'text',
      inputType: 'alphabets',
      label: strings.name, //'Name',
      placeholder: strings.enterName, // 'Enter name',
      stateField: 'name',
      id: 'name',
      stateDefault: '',
      minLength: 5,
      maxLength: 128,
    },
    {
      inputType: 'phone',
      elementType: 'phone',
      keyboardType: 'number-pad',
      label: strings.phoneNumber,
      placeholder: strings.enterPhoneNumber,
      stateField: 'phoneNumber',
      id: 'phoneNumber',
      stateDefault: '',
      maxLength: 10,
    },
    {
      elementType: 'text',
      keyboardType: 'default',
      inputType: 'email',
      label: strings.rfidCardNumber,
      placeholder: strings.enterRfidNumber,
      stateField: 'RFIDNumber',
      id: 'RFIDNumber',
      stateDefault: '',
      maxLength: 50,
    },
  ];
};
