import React from 'react';
import styles from './styles.module.scss';
import clsx from 'clsx';

import crossIconPrimary from '../../../assets/images/common-images/cross-icon-primary/image@3x.png';

export interface IIconButton {
  className?: string;
  variant?: 'cross' | string;
  onClick?: Function;
  iconStyle?: React.CSSProperties;
  as?: 'a' | 'button' | T | string;
}

const IconButton = (props: IIconButton) => {
  const {
    variant,
    className,
    iconStyle,
    as: Tag = 'button',
    ...remainingProps
  } = props;

  const getIcon = () => {
    switch (variant) {
      case 'cross':
        return crossIconPrimary;
      default:
        return crossIconPrimary;
    }
  };
  const getButtonStyles = () => {
    switch (variant) {
      case 'cross':
        return styles.cross;
      default:
        return styles.cross;
    }
  };

  return (
    <Tag
      {...props}
      className={clsx(getButtonStyles(), styles.button, className)}
      {...remainingProps}>
      <img
        className={clsx(styles.icon, iconStyle)}
        src={getIcon()}
        alt="icon"
      />
    </Tag>
  );
};

export default IconButton;
