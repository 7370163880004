import React from 'react';
import styles from './styles.module.scss';
// import {
//   close,
//   success as successImg,
//   error as errorImg,
// } from "../../assets/images";

import { adminImages } from '../../assets/images';
import strings from '../../globalization';
import moment from 'moment';
import util from '../../util';

type Button = {
  text: String,
  onClick: () => void,
  className?: String,
};

type Props = {
  message: Object,
  onClose?: () => void,
  error?: Boolean,
  success?: Boolean,
  buttons?: Button[],
  heading?: String,
};

/**
 *
 * @param {Boolean} error
 * @param {Boolean} success
 * @return {JSX.Element}
 */

/**
 *
 * @param {Props} props
 * @return {JSX.Element}
 */
function VehicleMovementPermitsDetailsPopup(props: Props) {
  const {
    error,
    success,
    onClose,
    buttons,
    heading,
    showPermitMetadata = true,
    buttonContainer,
    message,
    data,
  } = props;
  console.log('data inside component vehicleMovementPermitsDetailsPopup', data);

  let visitingDate = data?.visitingDate || null;
  let workPurpose = data?.workPurpose || null;
  let referenceId = data?.referenceId || null;
  let noOfDays = data?.noOfDays || null;
  let totalTripsCompletedCount = data?.totalTripsCompletedCount || null;

  let status = data?.status || null;
  const tripType = data?.tripType || null;
  const twoWheelerVehicleRegistrationNumber = data?.twoWheelerVehicleRegistrationNumber || null;
  console.log(data);
  let containerClass = '';
  if (error) {
    containerClass = styles.error;
  } else if (success) {
    containerClass = styles.success;
  }
  return (
    <div className={[styles.container, containerClass].join(' ')}>
      <div className={styles.secondContainer}>
        {onClose ? (
          //  <span className={styles.closeIcon}>X </span>
          <img
            alt="close"
            src={adminImages.closeIcon}
            className={styles.closeIcon}
            onClick={onClose}
          />
        ) : null}

        <div className={styles.heading}> {heading}</div>
        <div className={styles.bodyContainer}>
          {showPermitMetadata && (
            <div className={styles.requestInfo}>
              <div className={styles.row1}>
                <div>
                  {' '}
                  {strings.ADMIN.POP_UP.visitingDate} :{' '}
                  <span className={styles.requestLabel}>
                    {' '}
                    {visitingDate
                      ? moment(visitingDate).format('MMM Do YY')
                      : null}{' '}
                  </span>{' '}
                </div>
                <div>
                  {' '}
                  {strings.ADMIN.POP_UP.purposeofWork}:{' '}
                  <span className={styles.requestLabel}>
                    {workPurpose?.name}{' '}
                  </span>{' '}
                </div>
                {/* <div>
                {' '}
                {strings.ADMIN.POP_UP.department} :{' '}
                <span className={styles.requestLabel}> {departmentName} </span>
              </div> */}
                {/* <div>
                {' '}
                {strings.ADMIN.POP_UP.areofWork}:{' '}
                <span className={styles.requestLabel}> {workAreaName} </span>
              </div> */}
              </div>

              <div className={styles.row1}>
                <div>
                  {' '}
                  {strings.ADMIN.POP_UP.passValidityPeriod}:{' '}
                  <span className={styles.requestLabel}>
                    {util.calculateDateStrings(visitingDate, noOfDays)}
                  </span>{' '}
                </div>
                <div>
                  {' '}
                  {strings.ADMIN.referenceNumber} :{' '}
                  <span className={styles.requestLabel}>{referenceId} </span>{' '}
                </div>
              </div>

              <div className={styles.row1}>
                <div>
                  {' '}
                  {'Trip Type'} :{' '}
                  <span className={styles.requestLabel}>
                    {tripType === 1 ? 'Single' : 'Multiple'}
                  </span>{' '}
                </div>
                {data?.persons[0]?.vehicle?.rfidnumber ? <div>
                  {' '}
                  {'Associated Vehicle RFID'} :{' '}
                  <span className={styles.requestLabel}>
                    {data?.persons[0]?.vehicle?.rfidnumber ? data?.persons[0]?.vehicle?.rfidnumber : '-'}
                  </span>{' '}
                </div>
                  : null}
                {twoWheelerVehicleRegistrationNumber ? <div>
                  {' '}
                  {'Two Wheeler Registration Number'} :{' '}
                  <span className={styles.requestLabel}>
                    {twoWheelerVehicleRegistrationNumber}
                  </span>{' '}
                </div>
                  : null}
              </div>

              {status === 'VEHICLE_DELINKED' && totalTripsCompletedCount ? (
                <div className={styles.row1}>
                  <div>
                    {' '}
                    {'Vehicle Total Trips Completed Count'}:{' '}
                    <span className={styles.requestLabel}>
                      {' '}
                      {totalTripsCompletedCount}
                    </span>{' '}
                  </div>
                  {/* <div>
                  {' '}
                  {'Vehicle Total Permits Count'} :{' '}
                  <span className={styles.requestLabel}>
                    {totalPermitsCount}{' '}
                  </span>{' '}
                </div> */}
                </div>
              ) : null}
            </div>
          )}
          <div className={styles.infoContainer}>{message}</div>
        </div>

        {buttons && buttons.length > 0 ? (
          <div className={[styles.buttonContainer, buttonContainer].join(' ')}>
            {buttons.map(button => {
              const { text, buttonStyles, ...remainingProps } = button;
              return (
                <button
                  className={[styles.button, buttonStyles].join(' ')}
                  key={'popup-button-' + text}
                  {...remainingProps}>
                  {text}
                </button>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default VehicleMovementPermitsDetailsPopup;
