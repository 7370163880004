import React, {useState} from 'react';
import styles from './Desktop.module.scss';
import './styles.css';
import strings from '../../../../../../globalization';
import {withRouter} from 'react-router-dom';
import {AgGridReact} from 'ag-grid-react';
import moment from 'moment';
import PermitsHeader from '../../../../../../components/PermitsHeader';

import 'react-daterange-picker/dist/css/react-calendar.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import dimensions from '../../.../../../../../../util/dimensions-util';
// import { rowData } from './data';
import TextInput from '../../../../../../components/TextInput';

const {vw: widthConstant, vh: heightConstant} = dimensions;
const tabs = [
  {
    label: 'Available Credits',
    id: 1,
    route: 'available-credits',
  },
  {
    label: 'Usage History',
    id: 2,
    route: 'history',
  },
];

const columnDefs = [
  {
    headerName: 'Date',
    field: 'date',
    headerClass: styles.rowHeader,
    width: 150 * widthConstant,
    resizable: true,
    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {updatedDate}}) => {
      return (
        <div className={styles.sessionTime}>
          {updatedDate ? moment(updatedDate).format('DD/MM/YYYY') : null}
        </div>
      );
    },
  },
  {
    headerName: 'Reference ID',
    field: 'referenceId',
    headerClass: styles.rowHeader,
    width: 250 * widthConstant,
    resizable: true,
    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {orderId}}) => {
      return <div className={styles.sessionTime}>{orderId}</div>;
    },
  },

  {
    headerName: 'Transaction ID',
    field: 'transactionId',
    headerClass: styles.rowHeader,
    width: 280 * widthConstant,
    resizable: true,
    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {transactionId}}) => {
      return <div className={styles.sessionTime}>{transactionId}</div>;
    },
  },
  {
    headerName: 'Mode of Payment',
    field: 'modeOfPayment',
    headerClass: styles.rowHeader,
    width: 245 * widthConstant,
    resizable: true,
    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {paymentMode}}) => {
      return <div className={styles.sessionTime}>{paymentMode}</div>;
    },
  },
  {
    headerName: 'Amount (INR)',
    field: 'credits',
    headerClass: styles.rowHeader,
    width: 250 * widthConstant,
    resizable: true,
    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {credits}}) => {
      return <div className={styles.sessionTime}>{credits}</div>;
    },
  },
  {
    headerName: strings.AGENCY.PERMITS.status,
    field: 'status',
    headerClass: styles.rowHeader,
    width: 280 * widthConstant,
    resizable: true,
    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {status}}) => {
      return (
        <div
          className={[
            styles.status,
            status === 'SUCCESS' ? styles.statusApproved : '',
            status === 'FAIL' ? styles.statusRejected : '',
          ].join(' ')}>
          <div className={styles.statusLabel}>
            <div className={styles.roundIcon}></div>
            {status}
          </div>
        </div>
      );
    },
  },
];

const Desktop = props => {
  const {
    onClick,
    onChange,
    selectedStatus,
    transactions,
    filterTransactions,
    searchText,
    availableCredits,
    holdCredits,
  } = props;

  const onTabClick = id => {
    switch (id) {
      case 1:
        onClick('statusRoute', {route: 'credits-available', id: id});
        break;
      case 2:
        onClick('statusRoute', {route: 'credit-history', id: id});
        break;
      default:
        break;
    }
  };

  const formatDate = date => moment(date).format('MMM Do YY');
  const rowStyle = {border: 'none', cursor: 'pointer'};
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return {background: '#f6f6f6', border: 'none'};
    }
  };

  return (
    <div className={styles.agencyContainer}>
      <PermitsHeader
        title="Credits Wallet"
        onTabClick={onTabClick}
        tabs={tabs}
        availableCredits={availableCredits}
        activeTab={selectedStatus === 'AVAILABLE_CREDITS' ? 1 : 2}
      />
      <div className={styles.bodyContainer}>
        <div className={styles.availableCredits}>
          <div className={styles.flexBox}>
            <div
              className={[styles.creditsBox, styles.creditsBoxGreen].join(' ')}>
              <div className={styles.leftTopLabel}>Available Credits</div>
              <div className={styles.credits}>{availableCredits}</div>
            </div>
            <div
              className={[styles.creditsBox, styles.creditsBoxGrey].join(' ')}>
              <div className={styles.leftTopLabel}>Credits on Hold</div>
              <div className={styles.credits}>{holdCredits}</div>
            </div>
          </div>

          <div
            className={styles.creditsLabel}
            onClick={e => {
              e.preventDefault();
              onClick('addCredits', 'credits');
            }}>
            +Add credits
          </div>
          <div className={styles.learnMore}>Learn more about credits</div>
        </div>
        <div className={styles.header}>
          {/* <div className={styles.left}>History</div> */}
          <div className={styles.right}>
            <div className={styles.inputSearch}>
              <TextInput
                onChange={text => {
                  onChange('search', text);
                }}
                placeholder={`Search by transaction ID`}
                isEdit={true}
                type={'text'}
                value={searchText}
                inputStyle={styles.inputText}
                showSeacrh={true}
              />
            </div>
          </div>
        </div>
        <div className={['ag-theme-balham', styles.tableWrapper].join(' ')}>
          <AgGridReact
            rowStyle={rowStyle}
            getRowStyle={getRowStyle}
            rowHoverHighlight={true}
            columnDefs={columnDefs}
            rowHeight={50 * heightConstant}
            headerHeight={50 * heightConstant}
            defaultColDef={{sortable: true}}
            rowClass={[styles.gridRowClass]}
            //     onCellClicked={(event, index) => onCellClick(event, index)}
            rowData={filterTransactions}></AgGridReact>
        </div>
        <div className={styles.bottomLabel}>
          {' '}
          Showing {filterTransactions.length}
        </div>
      </div>
    </div>
  );
};
export default withRouter(Desktop);
