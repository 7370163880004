import React from 'react';
import {useRouteMatch, Switch, Route} from 'react-router-dom';
import SideBar from '../components/SideBar';
import {useMediaQuery} from 'react-responsive';
import styles from './styles.module.css';
import {adminPortalTabs} from './tabs';
import Dashboard from '../pages/AdminPortal/Dashboard';
import Profile from '../pages/Department/Profile';

/**
 * @return {JSX.Element}
 */
function AdminPortalRouter(props) {
  const {url} = useRouteMatch();
  console.info('url', url);
  const isMobile = useMediaQuery({maxWidth: 767});

  const onProfileClick = () => {
    const {history} = props;
    history.push(`${url}/profile`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        {/* {!isMobile ? (
          <div className={styles.sideBar}>
            <SideBar
              module={'admin'}
              header={'Permit Requests'}
              tabs={adminPortalTabs}
            />
          </div>
        ) : null} */}
        <div className={styles.relative}>
          <div className={styles.bodyWrapper}>
            <Switch>
              <Route path={`${url}/`}>
                <Dashboard />
              </Route>
              <Route path={`${url}/dashboard`}>
                <Dashboard />
              </Route>
              <Route exact path={`/u/profile`}>
                <Profile />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminPortalRouter;
