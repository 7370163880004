import strings from '../../../globalization/index';
import UserIcon from '../../../assets/images/user-icon/image.png';
import lockIcon from '../../../assets/images/lock-icon/image.png';

export const userImage = './static/images/Icons/user.png';
export const lockImage = './static/images/Icons/lock.png';

export const formFields = [
  {
    field: 'userId',
    type: 'text',
    label: () => `${strings.userId}`,
    placeholder: () => `${strings.enterUserId}`,
    required: true,
    inputType: 'phone',
    leftIcon: UserIcon,
  },
  {
    field: 'otp',
    type: 'text',
    label: () => `${strings.userId}`,
    placeholder: () => `${strings.enterUserId}`,
    required: true,
    inputType: 'otp',
    leftIcon: UserIcon,
  },
  {
    field: 'password',
    type: ' password',
    label: () => `${strings.newPassword}`,
    placeholder: () => `${strings.enterPassword}`,
    required: true,
    inputType: 'text',
    leftIcon: lockIcon,
  },
];

export const INPUT_CONSTANTS = {
  TEXT: 'text',
  DROPDOWN: 'dropdown',
  DATE: 'date',
  FILE: 'file',
  NUMBER: 'number',
  PASSWORD: 'password',
};

export const keys = {
  title: `${strings.title}`,
  subTitle: `${strings.subTitle}`,
};
