export const getDriverFormData = () => {
  return [
    {
      elementType: 'text',
      inputType: 'input',
      label: 'Driving Licence Number',
      placeholder: 'Enter Driving Licence',
      required: true,
      stateField: 'drivingLicenceNumber',
      stateDefault: '',
      maxLength: 20,
    },
    {
      elementType: 'date',
      inputType: 'text',
      label: 'Document Validity',
      placeholder: 'Select Document Validity',
      required: true,
      stateField: 'drivingLicenceValidity',
      stateDefault: null,
      maxLength: 20,
      minimumDate: new Date(),
    },

    {
      elementType: 'file',
      inputType: 'upload',
      label: 'Driving Licence Document',
      placeholder: 'choose file',
      required: true,
      stateField: 'drivingLicenceDocument',
      stateDefault: [],
      maxFiles: 1,
    },
  ];
};
