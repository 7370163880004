const STATUSES = {
  APPROVED: 'ISSUED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  PAYMENT_COMPLETED: 'PAYMENT_COMPLETED',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  DEPARTMENT_ADMIN_APPROVED: 'DEPARTMENT_ADMIN_APPROVED',
  DEPARTMENT_ADMIN_REJECTED: 'DEPARTMENT_ADMIN_REJECTED',
  OPERATOR_ADMIN_APPROVED: 'OPERATOR_ADMIN_APPROVED',
  OPERATOR_ADMIN_REJECTED: 'OPERATOR_ADMIN_REJECTED',
};

export default STATUSES;
