export const awsExportConfig = {
  auth: {
    // identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_COGNITO_PROJECT_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOLS_WEB_CLIENT_ID,
    authenticationFlowType:
      process.env.REACT_APP_COGNITO_AUTHENTICATION_FLOW_TYPE,
  },
};
