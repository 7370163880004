import React, {useState} from 'react';
import Flex from '../../Flex';
import FormElement from '../../FormElement';
import styles from './styles.module.scss';

import bgIcon from '../../../assets/images/background/image.png';
import closeIcon from '../../../assets/images/close-icon/image.png';
import moment from 'moment';
import strings from '../../../globalization';

const STATUSES = {
  ISSUED: 'ISSUED',
  HOLD: 'HOLD',
  PENDING: 'PENDING',
  SURRENDERED: 'SURRENDER',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAYMENT_COMPLETED: 'PAYMENT_COMPLETED',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  OPERATOR_ADMIN_APPROVED: 'OPERATOR_ADMIN_APPROVED',
  OPERATOR_ADMIN_REJECTED: 'OPERATOR_ADMIN_REJECTED',
  DEPARTMENT_ADMIN_APPROVED: 'DEPARTMENT_ADMIN_APPROVED',
  DEPARTMENT_ADMIN_REJECTED: 'DEPARTMENT_ADMIN_REJECTED',
  REISSUE_PAYMENT_COMPLETED: 'REISSUE_PAYMENT_COMPLETED',
  REISSUE_PAYMENT_PENDING: 'REISSUE_PAYMENT_PENDING',
  REISSUE_PAYMENT_FAILED: 'REISSUE_PAYMENT_FAILED',
  UPDATED: 'UPDATED',
  UPDATE_REJECTED: 'UPDATE_REJECTED',
};

const RFID_ASSIGN_ALLOW_LIST = {
  REISSUE_PAYMENT_COMPLETED: 'REISSUE_PAYMENT_COMPLETED',
  DEPARTMENT_ADMIN_APPROVED: 'DEPARTMENT_ADMIN_APPROVED',
};

const ROLES = {
  OPERATOR_SUPER_ADMIN: 5,
  OPERATOR_ADMIN: 6,
};

function PersonCardsPopup({
  containerClass,
  formData,
  stateData,
  onClose,
  onApprove,
  onReject,
  onLost,
  onChangeRFID,
  onSurrender,
  onEdit,
  viewDocument,
  viewHistory,
  person,
  roleId = 7,
}) {
  console.log('role id person cards', roleId);
  const [RFID, setRFID] = useState(person.RFIDNumber ? person.RFIDNumber : '');
  const [confirmRFID, setConfirmRFID] = useState('');
  // const [remark, setRemark] = useState(person.remarks ? person.remarks : '');
  const [verifiedPersonalDetails, setVerifiedPersonalDetails] = useState(false);
  const [verifiedProofDocument, setVerifiedProofDocument] = useState(false);
  const [error, setError] = useState('');
  const {edit} = stateData;
  const getStatus = () => {
    let status = person.status;

    switch (status) {
      case STATUSES.ISSUED: {
        return 'Issued';
      }
      case STATUSES.HOLD: {
        return 'On Hold';
      }
      case STATUSES.SURRENDERED: {
        return 'Surrendered';
      }
      case STATUSES.PAYMENT_COMPLETED: {
        return 'Payment Completed';
      }
      case STATUSES.PAYMENT_PENDING: {
        return 'Payment Pending';
      }
      case STATUSES.PAYMENT_FAILED: {
        return 'Payment Failed';
      }
      case STATUSES.OPERATOR_ADMIN_APPROVED: {
        return 'Operator Admin Approved';
      }
      case STATUSES.OPERATOR_ADMIN_REJECTED: {
        return 'Operator Admin Rejected';
      }
      case STATUSES.DEPARTMENT_ADMIN_APPROVED: {
        return 'Department Admin Approved';
      }
      case STATUSES.DEPARTMENT_ADMIN_REJECTED: {
        return 'Department Admin Rejected';
      }
      case STATUSES.REISSUE_PAYMENT_PENDING: {
        return 'Reissue Payment Pending';
      }
      case STATUSES.REISSUE_PAYMENT_COMPLETED: {
        return 'Reissue Payment Completed';
      }
      case STATUSES.REISSUE_PAYMENT_FAILED: {
        return 'Reissue Payment Failed';
      }
      case STATUSES.UPDATED: {
        return 'Updated';
      }
      case STATUSES.UPDATE_REJECTED: {
        return 'Update Rejected';
      }

      default: {
        return status;
      }
    }
  };

  const getButtons = () => {
    let status = person.status;
    console.log('STATUS...', status);

    switch (status) {
      case STATUSES.PAYMENT_COMPLETED: {
        return (
          <>
            {/* <button className={styles.reject} onClick={handleReject}>
              {strings.keepOnHold}
            </button> */}
            <button className={styles.approve} onClick={handleApprove}>
              {strings.approve}
            </button>
          </>
        );
      }
      case STATUSES.DEPARTMENT_ADMIN_APPROVED: {
        return (
          <>
            {/* <button className={styles.reject} onClick={handleReject}>
              {strings.keepOnHold}
            </button> */}
            <button className={styles.approve} onClick={handleApprove}>
              {strings.approve}
            </button>
          </>
        );
      }
      case STATUSES.ISSUED: {
        return (
          <>
            {roleId === ROLES.OPERATOR_SUPER_ADMIN ||
            roleId === ROLES.OPERATOR_ADMIN ? (
              <>
                <button className={styles.reject} onClick={handleEdit}>
                  {strings.edit}
                </button>
                {/* <button className={styles.reject} onClick={handleReject}>
                  {strings.keepOnHold}
                </button> */}
                <button className={styles.surrender} onClick={onSurrender}>
                  {strings.surrenderCard}
                </button>
                <button className={styles.approve} onClick={handleLost}>
                  {`Person Card Lost/Damaged`}
                </button>
                <button className={styles.approve} onClick={changeRFIDNumber}>
                  {`Change RFID Number`}
                </button>
              </>
            ) : null}
          </>
        );
      }

      case STATUSES.UPDATED: {
        return (
          <>
            {roleId === ROLES.OPERATOR_SUPER_ADMIN ||
            roleId === ROLES.OPERATOR_ADMIN ? (
              <>
                <button className={styles.reject} onClick={handleEdit}>
                  {strings.edit}
                </button>
                {/* <button className={styles.reject} onClick={handleReject}>
                  {strings.keepOnHold}
                </button> */}
                <button className={styles.surrender} onClick={onSurrender}>
                  {strings.surrenderCard}
                </button>
                <button className={styles.approve} onClick={handleLost}>
                  {`Person Card Lost/Damaged`}
                </button>
                <button className={styles.approve} onClick={changeRFIDNumber}>
                  {`Change RFID Number`}
                </button>
              </>
            ) : null}
          </>
        );
      }

      case STATUSES.UPDATE_REJECTED: {
        return (
          <>
            {roleId === ROLES.OPERATOR_SUPER_ADMIN ||
            roleId === ROLES.OPERATOR_ADMIN ? (
              <>
                <button className={styles.reject} onClick={handleEdit}>
                  {strings.edit}
                </button>
                {/* <button className={styles.reject} onClick={handleReject}>
                  {strings.keepOnHold}
                </button> */}
                <button className={styles.surrender} onClick={onSurrender}>
                  {strings.surrenderCard}
                </button>
                <button className={styles.approve} onClick={handleLost}>
                  {`Person Card Lost/Damaged`}
                </button>
                <button className={styles.approve} onClick={changeRFIDNumber}>
                  {`Change RFID Number`}
                </button>
              </>
            ) : null}
          </>
        );
      }

      case STATUSES.HOLD: {
        return (
          <>
            <button className={styles.approve} onClick={handleApprove}>
              {strings.approve}
            </button>
          </>
        );
      }
      case STATUSES.REISSUE_PAYMENT_COMPLETED: {
        return (
          <>
            {/* <button className={styles.reject} onClick={handleReject}>
              {strings.keepOnHold}
            </button> */}
            <button className={styles.approve} onClick={handleApprove}>
              {strings.approve}
            </button>
          </>
        );
      }
      default: {
        return null;
      }
    }
  };

  const getStyles = () => {
    let status = person.status;

    switch (status) {
      case STATUSES.ISSUED: {
        return {
          padding: '2px 10px',
          borderRadius: '5px',
          backgroundColor: '#0AC15770',
        };
      }
      case STATUSES.HOLD: {
        return {
          padding: '2px 10px',
          borderRadius: '5px',
          backgroundColor: '#E6771770',
        };
      }
      case STATUSES.SURRENDERED: {
        return {
          padding: '2px 10px',
          borderRadius: '5px',
          backgroundColor: '#E6771770',
        };
      }
      default: {
        return {
          padding: '2px 10px',
          borderRadius: '5px',
          backgroundColor: '#0045E670',
        };
      }
    }
  };

  const getFormData = () => {
    return (
      <Flex direction="row" className={styles.columnLayout}>
        {formData.map((elem, idx) => {
          return (
            <FormElement
              type={elem.elementType}
              label={elem.label}
              required={elem.required}
              placeholderText={elem.placeholder}
              tabIndex={idx}
              options={stateData[elem.optionsField]}
              // value={stateData[elem.stateField].value}
              fileIcon={person[elem.fileIcon]}
              value={person[elem.id]}
              key={`firstColumn-${idx}`}
              maxLength={elem.maxLength}
              disabled={true}
              defaultValue={person[elem.id]}
              containerClassNames={styles.containerClassNames}
              labelClassNames={styles.labelClassNames}
              onRightIconClick={() => viewDocument(person[elem.fileIcon])}
            />
          );
        })}
      </Flex>
    );
  };

  const getImageColumn = () => {
    return (
      <Flex direction="column" className={styles.imageTextContainer}>
        <img
          src={person.photo ? person.photo : bgIcon}
          className={styles.image}
          alt="AVATAR"
        />
        {/* <img src={bgIcon} className={styles.image} alt="AVATAR" /> */}
        <p className={styles.text}>
          {strings.referenceNumberLabel}: {person.referenceId}
        </p>
        <p className={styles.text}>
          {strings.requestDate}:{' '}
          {moment(person.createdDate).format('DD/MM/YYYY')}
        </p>
        <p className={styles.text}>
          {strings.status}: <span style={getStyles()}>{getStatus()}</span>
        </p>
        {person.status === STATUSES.ISSUED ? (
          <p className={styles.text}>
            {/* FIXME: Remove modified date and add approved Date  */}
            Approved date: {moment(person.modifiedDate).format('DD/MM/YYYY')}
          </p>
        ) : null}
        <button className={styles.history} onClick={viewHistory}>
          {strings.viewHistory}
        </button>
      </Flex>
    );
  };

  const validate = () => {
    setError('');
    if (!verifiedPersonalDetails || !verifiedProofDocument) {
      setError(strings.pleaseVerify);
      return false;
    }
    if (!RFID) {
      setError(strings.pleaseAssignRFID);
      return false;
    }

    if (
      (person.status === STATUSES.DEPARTMENT_ADMIN_APPROVED ||
        person.status === STATUSES.REISSUE_PAYMENT_COMPLETED) &&
      RFID !== confirmRFID
    ) {
      setError(`RFID Numbers do not match.`);
      return false;
    }
    return true;
  };

  const handleApprove = () => {
    let isValid = validate();

    let verifiedList = {
      verifiedPersonalDetails,
      verifiedProofDocument,
    };

    if (!isValid) {
      return;
    } else {
      onApprove(RFID, verifiedList);
    }
  };

  const handleReject = () => {
    let verifiedList = {
      verifiedPersonalDetails,
      verifiedProofDocument,
    };
    onReject(verifiedList);
  };

  const handleLost = () => {
    onLost();
  };
  const changeRFIDNumber = () => {
    onChangeRFID();
  };

  const handleEdit = () => {
    onEdit();
  };
  return (
    <div className={[styles.container, containerClass].join(' ')}>
      <Flex className={styles.mainContainer}>
        {getFormData()}
        {/* {getSecondColumn()} */}
        {getImageColumn()}
      </Flex>
      <Flex className={styles.checkboxes}>
        <input
          id="personCheckbox"
          type="checkbox"
          checked={verifiedPersonalDetails}
          className={styles.checkbox}
          onChange={() => setVerifiedPersonalDetails(!verifiedPersonalDetails)}
        />
        <label className={styles.label} htmlFor="personCheckbox">
          {strings.verifiedPerson}
        </label>

        <input
          id="verifiedProof"
          type="checkbox"
          className={styles.checkbox}
          checked={verifiedProofDocument}
          onChange={() => setVerifiedProofDocument(!verifiedProofDocument)}
        />
        <label className={styles.label} htmlFor="verifiedProof">
          {strings.verifiedProof}
        </label>
      </Flex>

      <Flex className={styles.bottomFields}>
        <div className={styles.formInput}>
          {RFID_ASSIGN_ALLOW_LIST[person.status] && (
            <FormElement
              disableCopyPaste
              type="text"
              label={`Enter RFID Card Number`}
              placeholderText={strings.pleaseEnterRFID}
              value={confirmRFID}
              maxLength={30}
              onChange={value => setConfirmRFID(value)}
              required
              disabled={RFID_ASSIGN_ALLOW_LIST[person.status] ? false : true}
              containerClassNames={styles.containerClassNames}
              labelClassNames={styles.labelClassNames}
              error={error}
              viewType={`password`}
            />
          )}
          <FormElement
            disableCopyPaste
            type="text"
            label={`Confirm RFID Card Number`}
            placeholderText={strings.pleaseEnterRFID}
            value={RFID}
            maxLength={30}
            onChange={value => setRFID(value)}
            required
            disabled={RFID_ASSIGN_ALLOW_LIST[person.status] ? false : true}
            containerClassNames={styles.containerClassNames}
            labelClassNames={styles.labelClassNames}
            error={error}
          />
        </div>
      </Flex>

      {/* Get Buttons Methods */}

      <Flex className={styles.buttonsContainer}>{getButtons()}</Flex>
    </div>
  );
}

export default PersonCardsPopup;
