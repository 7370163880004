import {awsExportConfig} from './config/aws-export';
import Amplify from 'aws-amplify';
import {Auth} from 'aws-amplify';
export function configureAuth() {
  const AuthConfig = awsExportConfig.auth;
  Amplify.configure({
    Auth: AuthConfig,
  });
}

/**
 * Async function to Sign a user in using AWS Amplify and Cognito.
 * @param {String} username - The username
 * @param {String} password - The password
 * @return Returns a promise
 */
export async function signIn(username, password) {
  try {
    const user = await Auth.signIn(username, password);
    return user;
  } catch (err) {
    throw err;
  }
}

/**
 * Async function to get the current logged in user
 * @return Returns a promise resolving to session isvalid, the session object, cognito user
 */
export async function getUserSession() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return {
      isAuthenticated: user.signInUserSession.isValid(),
      user,
    };
  } catch (err) {
    throw err;
  }
}

/**
 * Asyn function to log out.
 * @return Returns a promise resolving to successful logout
 */
export async function signOut() {
  try {
    await Auth.signOut();
    return true;
  } catch (err) {
    throw err;
  }
}
