import React, {useState} from 'react';
import styles from './Desktop.module.scss';
import './styles.css';
import strings from '../../../../../globalization';
import {withRouter} from 'react-router-dom';
import {AgGridReact} from 'ag-grid-react';
import Select from 'react-select';
import moment from 'moment';
import 'react-daterange-picker/dist/css/react-calendar.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import dimensions from '../../../../../util/dimensions-util';
import {customStyles} from './customStyles';
import {adminImages} from '../../../../../assets/images';
import DateRangePicker from 'react-daterange-picker';
import TextInput from '../../../../../components/TextInput';
import Button from '../../../../../components/Button';

import calendarBlue from '../../../../../assets/images/calendar-blue-icon/image.png';
import CustomTimePicker from '../../../../../components/CustomTimePicker';
import TimePicker from 'rc-time-picker';
import Flex from '../../../../../components/Flex';
import {CSVLink} from 'react-csv';

const {vw: widthConstant, vh: heightConstant} = dimensions;

const entrySummaryColumnDef = [
  {
    headerName: 'Sl. No.',
    field: 'slNo',
    headerClass: styles.rowHeader,
    width: 120 * widthConstant,
    resizable: true,
    valueGetter: 'node.rowIndex + 1',
    cellStyle: {display: 'flex', alignItems: 'center'},
  },
  {
    headerName: 'Date',
    field: 'date',
    headerClass: styles.rowHeader,
    width: 250 * widthConstant,
    resizable: true,

    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {date}}) => {
      return <div>{moment(date).format('MMMM Do YYYY')}</div>;
    },
  },
  {
    headerName: 'Permit Requests Raised',
    field: 'permitRequests',
    headerClass: styles.rowHeader,
    flex: 1,
    resizable: true,

    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {permit}}) => {
      return <div className={styles.sessionTime}>{permit}</div>;
    },
  },
  {
    headerName: 'Agency Registration Counts',
    field: 'agencyRegistrationCounts',
    headerClass: styles.rowHeader,
    flex: 1,

    resizable: true,

    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {company}}) => {
      return <div className={styles.sessionTime}>{company}</div>;
    },
  },

  {
    headerName: 'Driver Registration Counts',
    field: 'driverRegistrationCounts',
    headerClass: styles.rowHeader,
    flex: 1,
    resizable: true,

    cellStyle: {display: 'flex', alignItems: 'center'},
    cellRendererFramework: ({data: {person}}) => {
      return <div className={styles.sessionTime}>{person}</div>;
    },
  },

  {
    headerName: 'Vehicle Registration Counts',
    field: 'vehicleRegistrationCounts',
    headerClass: styles.rowHeader,
    flex: 1,

    resizable: true,
    // wrapText: true,
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
      // lineHeight: 1.4,
      // wordBreak: 'initial',
    },
    cellRendererFramework: ({data: {vehicle}}) => {
      return <div>{vehicle}</div>;
    },
  },
];

const Desktop = props => {
  const {
    onClick,
    onChange,
    searchText,
    dates,
    dailyProgressList,

    downloadData,
    downloadHeaders,
  } = props;
  const [showDate, setShowDate] = useState(false);

  const onCellClick = (event, index) => {
    onClick('rowClick', event.data);
  };
  const onSelect = dates => {
    onChange('date', {
      startDate: moment(dates.start).format('MM-DD-YYYY'),
      endDate: moment(dates.end).format('MM-DD-YYYY'),
      dates,
    });
    toggleDate();
  };
  const toggleDate = () => {
    setShowDate(!showDate);
  };

  const formatDate = date => moment(date).format('MMM Do YY');
  const rowStyle = {border: 'none', cursor: 'pointer'};
  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
      return {background: '#f6f6f6', border: 'none'};
    }
  };
  const handleClear = () => {
    setShowDate(false);
    onClick('clearFilters');
  };
  const handleClick = () => {
    // console.log('arg', `${arg}`);
    setShowDate(false);
    onClick('apply');
  };

  return (
    <div className={styles.agencyContainer}>
      <div className={styles.headerText}>{`Day Wise Progress Monitoring`}</div>
      <div className={styles.header}>
        <div className={styles.left}>
          <Flex direction="column" className={styles.leftTop}>
            <Flex direction="row">
              <div className={styles.dateWrapper}>
                <div onClick={toggleDate} className={styles.inputDate}>
                  {' '}
                  <div>
                    {' '}
                    {dates
                      ? formatDate(dates.start) + '-' + formatDate(dates.end)
                      : strings.ADMIN.dateRange}
                  </div>{' '}
                  <img src={calendarBlue} className={styles.calendar} alt="C" />
                </div>
                {showDate ? (
                  <div className={styles.datePicker}>
                    <DateRangePicker
                      numberOfCalendars={1}
                      selectionType="range"
                      onSelect={onSelect}
                      className={styles.dateRange}
                      value={dates}
                      singleDateRange={true}
                    />
                  </div>
                ) : null}
              </div>
            </Flex>
          </Flex>
        </div>

        <div className={styles.right}>
          <div onClick={handleClear} className={styles.clearLabel}>
            {' '}
            {strings.ADMIN.clear}
          </div>
          {/* <CSVLink></CSVLink> */}

          {dailyProgressList && dailyProgressList.length > 0 ? (
            <CSVLink
              data={downloadData}
              className={styles.downloadBtn}
              onClick={() => setShowDate(false)}
              filename={`vehicle-movement-edrishti-${moment().format(
                `DD-MM-YYYY`,
              )}.csv`}
              headers={downloadHeaders}>
              Download ⬇
              {/* <button className={styles.downloadBtn}>Download ⬇</button> */}
            </CSVLink>
          ) : (
            <CSVLink
              data={downloadData}
              filename={`vehicle-movement-edrishti-${moment().format(
                `DD-MM-YYYY`,
              )}.csv`}
              headers={downloadHeaders}>
              <button className={styles.downloadBtn} disabled={true}>
                Download ⬇
              </button>
            </CSVLink>
          )}

          <Button
            // onClick={() => onClick('apply')}
            onClick={() => handleClick('apply')}
            buttonStyle="primary"
            label={strings.ADMIN.apply}
            className={styles.applyBtn}></Button>
        </div>
      </div>
      <div className={['ag-theme-balham', styles.tableWrapper].join(' ')}>
        <AgGridReact
          rowStyle={rowStyle}
          getRowStyle={getRowStyle}
          rowHoverHighlight={true}
          columnDefs={entrySummaryColumnDef}
          rowHeight={45 * heightConstant}
          headerHeight={55 * heightConstant}
          defaultColDef={{sortable: true}}
          rowClass={[styles.gridRowClass]}
          pagination={true}
          paginationPageSize={10}
          animateRows={true}
          onCellClicked={(event, index) => onCellClick(event, index)}
          rowData={dailyProgressList ? dailyProgressList : []}
          domLayout="autoHeight"></AgGridReact>
      </div>
      <div className={styles.bottomLabel}>
        {' '}
        Total Count: {dailyProgressList ? dailyProgressList.length : null}
      </div>
    </div>
  );
};
export default withRouter(Desktop);
