import React, {Component} from 'react';
import PopupContainer from '../../../../components/PopupContainer';
import Popup from '../../../../components/SimplePopup';
import {withRouter} from 'react-router-dom';

import ViewRenderer from './views';
import Spinner from '../../../../components/Spinner';
import moment from 'moment';
import {commonImages, operatorImages} from '../../../../assets/images';
import strings from '../../../../globalization';
import {API} from '../../../../requests';
import util from '../../../../util';
import * as XLSX from 'xlsx';

const POPUP_CONSTANTS = {
  STANDARD: 'standard',
  ERROR: 'error',
  SPINNER_POPUP: 'spinner',
};

const STATUS_MAPPER = {
  ADD_TO_WALLET: 'Wallet Recharged',
  HOLD_PASSES: 'Trips ON-HOLD',
  DEDUCT_ON_HOLD_PASSES: 'ON-HOLD trips deducted',
  REFUND_ON_HOLD_PASSES: 'ON-HOLD trips refunded',
  DEDUCT_VESTED_PASSES: 'Vested trips deducted',
  REFUND_VESTED_PASSES: 'Vested trips refunded',
  DEDUCT_AVAILABLE_PASSES: 'Entry Trip Deduction',
};
const RECONCILIATION_MAPPER = {
  NOT_VERIFIED: 'Not Verified',
  VERIFIED: 'Verified',
  REFUNDED: 'Refunded',
  NOT_REQUIRED: 'Not Required',
};
class UsageHistory extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      selectedStatus: this.props.status,
      searchText: '',
      startDate: moment().subtract(1, 'week').format('MM-DD-YYYY'),

      //      startDate: new moment().startOf('year').format('MM-DD-YYYY'),
      //      endDate: new moment().add(1, 'years').format('MM-DD-YYYY'),
      endDate: moment().add(1, 'day').format('MM-DD-YYYY'),

      dates: {
        start: moment().subtract(1, 'week').format('MM-DD-YYYY'),
        end: moment().add(1, 'day').format('MM-DD-YYYY'),
      },
      usage: [],
      filteredUsage: [],
      timestamp: new Date().getTime(),
    };
  }

  componentDidMount() {
    // this.getData();
  }

  downloadExcel = data => {
    const updatedData = data.map((item, index) => {
      console.log(item);
      return {
        Date: item?.date,
        'Reference ID': item?.referenceId,
        'Vehicle Owner Name': item?.vehicleOwnerName,
        'Vehicle Registration Number': item?.vehicleRegistrationNumber,
        'Entry Category': item?.entryCategory,
        'Number of Passes': item?.numberOfPasses,
        Status: item?.status,
        'Reconciliation Status': item?.reconciliationStatus,
      };
    });
    console.log(updatedData);
    const worksheet = XLSX.utils.json_to_sheet(updatedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'usage-history1');
    XLSX.writeFile(
      workbook,
      `usage-history-edrishti-${moment().format(`DD-MM-YYYY`)}.xlsx`,
    );
  };

  getCSVData = async () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
    const {startDate, endDate, searchText, totalCount} = this.state;

    const data = {
      startDate: startDate,
      endDate: endDate,
      pageNumber: 0,
      pageSize: totalCount + 100,
      searchString: searchText || undefined,
    };

    this.showSpinner();

    API.fetchUsageDetails(data)
      .then(resp => resp)
      .then(res => {
        console.log('response:', typeof res.data);
        this.setState({
          popup: null,
          totalCount: res.data?.totalCount,
          timestamp: new Date().getTime(),
        });
        const data = this.getCSVDownloadData(res.data?.usageHistory);
        this.downloadExcel(data);
      })
      .catch(err => {
        console.log(err);
        this.setState({
          popup: undefined,
        });
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });

    this.showSpinner();
    // const {startDate, endDate, startTime, endTime, selectedCHA, totalCount} =
  };

  getData = (params, startDate, endDate, page, perPage, searchString) => {
    console.log('searchString:', searchString);
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });

    const data = {
      startDate: startDate,
      endDate: endDate,
      pageNumber: page,
      pageSize: perPage,
      searchString: searchString || undefined,
    };

    this.showSpinner();

    API.fetchUsageDetails(data)
      .then(resp => resp)
      .then(res => {
        console.log('response:', typeof res.data, perPage);
        params.successCallback(
          res.data?.usageHistory,
          res.data?.totalCount ? res.data?.totalCount : 0,
        );
        this.setState({
          usage: res.data?.usageHistory,
          popup: null,
          totalCount: res.data?.totalCount,
          timestamp: new Date().getTime(),
        });
      })
      .catch(err => {
        console.log(err);
        params.successCallback([], 0);
        this.setState({
          popup: undefined,
        });
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };

  onChangeHandler = (action, data) => {
    switch (action) {
      case 'search': {
        console.info('data..', data);
        this.setState({
          searchText: data,
        });

        break;
      }
      case 'date': {
        const {startDate, endDate, dates} = data;
        this.setState({
          startDate,
          endDate,
          dates,
        });
        break;
      }
      case 'gridAPI': {
        console.log('grid API', data);
        this.setState({gridApi: data});
        this.onChangeHandler('updateTable');
        break;
      }
      case 'updateTable': {
        const {gridApi, startDate, endDate, searchText} = this.state;
        const perPage = 100;
        console.log('updating table data', gridApi);
        if (gridApi) {
          console.log('updating table data1');
          const dataSource = {
            getRows: params => {
              const page = params.endRow / perPage;
              console.log(page, params);
              this.getData(
                params,
                startDate,
                endDate,
                page - 1,
                perPage,
                searchText,
              );
            },
          };
          gridApi.setDatasource(dataSource);
        }
        break;
      }

      default: {
        console.info('case not handled', action);
      }
    }
  };

  /**
   *
   * @param {*} action
   * @param {*} data
   */

  onClickHandler = (action, data) => {
    const {history, location, status} = this.props;
    switch (action) {
      case 'statusRoute': {
        //FIXME
        history.push({
          pathname: '/u/wallet/' + data.route,
        });

        break;
      }
      case 'apply': {
        console.log('clicked on apply button');
        this.onChangeHandler('updateTable');
        break;
      }
      case 'clearFilters': {
        this.resetData();
        break;
      }
      case 'loadCSV': {
        this.getCSVData();
        break;
      }

      default: {
        console.info('case not handled', action);
      }
    }
  };

  resetData = () => {
    const {status} = this.props;
    this.setState(
      {
        searchText: '',
        startDate: moment().subtract(1, 'week').format('MM-DD-YYYY'),
        endDate: moment().add(1, 'day').format('MM-DD-YYYY'),
        //        startDate: new moment().startOf('year').format('MM-DD-YYYY'),
        //       endDate: new moment().add(1, 'years').format('MM-DD-YYYY'),
        dates: {
          start: moment().subtract(1, 'week').format('MM-DD-YYYY'),
          end: moment().add(1, 'day').format('MM-DD-YYYY'),
        },
      },
      () => {
        this.onChangeHandler('updateTable');
      },
    );
  };

  closePopup = () => {
    this.setState({
      popup: undefined,
    });
  };
  getCSVDownloadHeaders = () => {
    return [
      {label: 'Date', key: 'date'},
      {label: 'Reference ID', key: 'referenceId'},
      {label: 'Vehicle Registration Number', key: 'vehicleRegistrationNumber'},
      {label: 'Entry Category', key: 'entryCategory'},
      {label: 'Number of Passes', key: 'numberOfPasses'},
      {label: 'Status', key: 'status'},
      {label: 'Reconciliation Status', key: 'reconciliationStatus'},
    ];
  };

  getCSVDownloadData = usage => {
    return usage.map(el => {
      return {
        date: moment(el?.createdDate).format('DD/MM/YYYY HH:mm'),
        entryCategory: el?.passCategory,
        numberOfPasses: el?.numberOfPasses,
        status: STATUS_MAPPER[el.action]
          ? STATUS_MAPPER[el.action]
          : el?.action,
        reconciliationStatus:
          RECONCILIATION_MAPPER[el?.reconciliationStatus] ||
          el?.reconciliationStatus,
        referenceId: el?.referenceId,
        vehicleOwnerName: el?.vehicleOwnerName,
        vehicleRegistrationNumber: el?.vehicleNumber,
      };
    });
  };

  getProps = () => {
    const {startDate, endDate, dates, searchText, usage, timestamp} =
      this.state;
    return {
      onClick: this.onClickHandler,
      selectedStatus: this.state.selectedStatus,
      onChange: this.onChangeHandler,
      searchText,
      startDate,
      endDate,
      dates,
      usage,
      timestamp,
    };
  };

  /**
   * @return {JSX.Element}
   */
  getPopupContent = () => {
    const {popup} = this.state;
    if (!popup) return;
    switch (popup.type) {
      // case "spinner": {
      //   console.log('showing spinner');
      //   return <Spinner />;
      // }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };
  showSuccessPopup = (message, onClick) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: message,
        headingImage: operatorImages.successIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: onClick ? onClick : this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  showErrorPopup = errorMessage => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMessage,
        messageStyle: {color: '#E67717'},
        headingImage: commonImages.errorIcon,
        onClose: this.closePopup,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.closePopup,
            outline: true,
          },
        ],
      },
    });
  };
  showSpinner = () => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SPINNER_POPUP,
      },
    });
  };

  closePopup = () => {
    this.setState({
      popup: null,
    });
  };

  /**
   * @return {JSX.Element}
   */
  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}
export default withRouter(UsageHistory);
