import React, {Component} from 'react';
import ViewRenderer from './views';
import {API} from '../../../requests';
import './styles.css';
import PopupContainer from '../../../components/PopupContainer';
import POPUP_CONSTANTS from '../../../constants/popup-constants';
import Spinner from '../../../components/Spinner';
import Popup from '../../../components/SimplePopup';
import strings from '../../../globalization';
import util from '../../../util';
import {getFormData} from './data';
import {editFormData} from './editData';
import DeletePopup from '../../../components/DeletePopup';
import PopupData from '../../../components/PopupPerson/popup';
import {operatorImages} from '../../../assets/images';

class CargoNature extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: undefined,

      searchText: '',
      CargoNature: [],
      filterCargoNature: [],
      selectedStatus: '',
    };
  }
  statuses = [
    {
      id: '',
      name: 'ALL',
    },
    {
      id: 'inactive',
      name: 'INACTIVE',
    },
    {
      id: 'active',
      name: 'ACTIVE',
    },
  ];
  updatedStatus = this.statuses.filter(elem => elem.id !== '');
  onSubmit = Cargo => {
    const CargoData = {
      name: Cargo.name.value,
      name_hindi: Cargo.name_hindi.value,
    };

    this.saveCargoNatureTypeData({
      ...CargoData,
    });
    this.setState({
      popup: null,
    });
  };
  showErrorPopup = errorMsg => {
    // crashlytics().recordError(new Error(errorMsg));

    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: errorMsg,
        messageStyle: {color: '#E67717'},

        buttons: [
          {
            text: strings.okayPopup,
            onClick: this.onClose,
          },
        ],
        onClose: this.closePopup,
      },
    });
  };
  showSuccessPopup = (successMessage, onClick) => {
    this.setState({
      popup: {
        type: POPUP_CONSTANTS.SIMPLE_POPUP,
        message: successMessage,
        onClose: this.onClose,
        headingImage: operatorImages.successIcon,
        buttons: [
          {
            text: strings.okayPopup,
            onClick: onClick ? onClick : this.onClose,
            outline: true,
          },
        ],
      },
    });
  };

  onClickHandler = (action, data) => {
    switch (action) {
      case 'edit': {
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.EDIT_POPUP,
            statuses: this.updatedStatus,
            categoryTypeData: data,
          },
        });

        break;
      }
      case 'delete': {
        data.status === 'ACTIVE' &&
          this.setState({
            CargoNature: data,
            popup: {type: POPUP_CONSTANTS.DELETE_POPUP},
          });

        break;
      }
      default: {
        console.log('nahi match huya');
        break;
      }
    }
  };
  onClose = () => {
    this.setState({
      popup: null,
    });
  };
  tableActionHandler = (action, data) => {
    switch (action) {
      case 'status': {
        console.info('data..', data);
        this.setState(
          {
            selectedStatus: data,
          },
          this.applyFilters,
        );
        break;
      }
      case 'shift': {
        break;
      }
      case 'add': {
        this.setState({
          popup: {
            type: POPUP_CONSTANTS.ADD_DATA_POPUP,
          },
        });
        break;
      }
      case 'search': {
        this.setState(
          {
            searchText: data,
          },
          this.applyFilters,
        );
        break;
      }
      case 'clearFilter': {
        this.setState({
          searchText: '',
          selectedStatus: '',
        });
        this.fetchAllCargo();
        break;
      }
      default: {
        console.info('filterChangeHandler case not handled', action);
      }
    }
  };
  applyFilters = () => {
    const {searchText, selectedStatus, CargoNature} = this.state;
    const filteredData =
      CargoNature.length > 0 &&
      CargoNature.filter(row => {
        if (
          searchText &&
          !Object.values({name: row.name, name_hindi: row.name_hindi})
            .toString()
            .toLowerCase()
            .includes(searchText.toLowerCase())
        ) {
          return false;
        }

        if (
          selectedStatus &&
          row.status.toLowerCase() !== selectedStatus.toLowerCase()
        ) {
          return false;
        }
        return true;
      });
    this.setState({
      filterCargoNature: filteredData,
    });
  };
  fetchAllCargo = () => {
    this.setState({popup: {type: POPUP_CONSTANTS.SPINNER_POPUP}});
    API.fetchAllCargoNatureTypeData()
      .then(res => {
        this.setState(
          {
            CargoNature: res.data,
            filterCargoNature: res.data,
            popup: null,
          },
          this.applyFilters,
        );
      })
      .catch(err => {
        const error = util.getErrorMessage(err);
        this.showErrorPopup(error);
      });
  };
  saveCargoNatureTypeData = cargoData => {
    let formData = {
      name: cargoData.name,
      name_hindi: cargoData.name_hindi,
      status: cargoData.status,
    };
    this.setState({popup: {type: POPUP_CONSTANTS.SPINNER_POPUP}});
    API.saveCargoNatureTypeData(formData)
      .then(res => {
        this.showSuccessPopup('Cargo Added Successfully', this.fetchAllCargo);
      })
      .catch(error => {
        const err = util.getErrorMessage(error);
        this.showErrorPopup(err);
      });
  };
  onDeleteHandler = () => {
    let id = this.state.CargoNature.id;
    this.deleteCargoNatureData(id);
    this.setState({popup: null});
  };

  deleteCargoNatureData = id => {
    this.setState({popup: {type: POPUP_CONSTANTS.SPINNER_POPUP}});
    API.deleteCargoNatureTypeData(id)
      .then(res => {
        this.showSuccessPopup('Cargo Deleted Successfully', this.fetchAllCargo);
      })
      .catch(error => {
        const err = util.getErrorMessage(error);
        this.showErrorPopup(err);
      });
  };
  updateCargoNatureData = data => {
    this.setState({popup: {type: POPUP_CONSTANTS.SPINNER_POPUP}});
    API.updateCargoNatureTypeData(data)
      .then(res => {
        this.showSuccessPopup('Cargo Updated Successfully', this.fetchAllCargo);
      })
      .catch(error => {
        const err = util.getErrorMessage(error);
        this.showErrorPopup(err);
      });
  };
  onEditHandler = data => {
    let myData = {
      // id: this.state.CargoNature.id,
      id: data.id.value,
      name: data.name.value,
      name_hindi: data.name_hindi.value,
      status: data.status.value.toUpperCase(),
    };

    this.updateCargoNatureData(myData);
    this.setState({popup: null});
  };
  getProps = () => {
    const {searchText, filterCargoNature, selectedStatus} = this.state;
    return {
      onClick: this.onClickHandler,
      onChange: this.onChangeHandler,
      filterChangeHandler: this.tableActionHandler,
      CargoNature: filterCargoNature,
      statuses: this.statuses,
      searchText,
      selectedStatus,
    };
  };

  getPopupContent = () => {
    const {popup} = this.state;
    switch (popup.type) {
      case POPUP_CONSTANTS.ADD_DATA_POPUP: {
        return (
          <PopupData
            header="Add"
            onSubmit={this.onSubmit}
            onClose={this.onClose}
            getFormData={getFormData}
            {...popup}
          />
        );
      }
      case POPUP_CONSTANTS.EDIT_POPUP: {
        return (
          <PopupData
            header="Edit"
            onSubmit={this.onEditHandler}
            onClose={this.onClose}
            getFormData={editFormData}
            {...popup}
          />
        );
      }
      case POPUP_CONSTANTS.DELETE_POPUP: {
        return (
          <DeletePopup onClick={this.onDeleteHandler} onClose={this.onClose} />
        );
      }
      case POPUP_CONSTANTS.SPINNER_POPUP: {
        return <Spinner name="cube-grid" color="#0045E6" {...popup} />;
      }
      case POPUP_CONSTANTS.SIMPLE_POPUP: {
        return <Popup {...popup} />;
      }
      default: {
        console.warn('getPopupContent', popup.type);
        return null;
      }
    }
  };
  componentDidMount() {
    this.fetchAllCargo();
  }

  render() {
    const viewProps = this.getProps();
    const {popup} = this.state;
    return (
      <>
        {popup ? (
          <PopupContainer> {this.getPopupContent()} </PopupContainer>
        ) : null}
        <ViewRenderer {...viewProps} />
      </>
    );
  }
}

export default CargoNature;
