import util from '../../../../../../util';
// import strings from '../../../../globalization';

export const dummyData = [
  {
    id: util.generateUniqueId(),
    name: 'Peter',
    mobileNumber: '+911234567890',
    emailAddress: 'peter@yopmail.com',
    designation: 'Manager',
    role: 'Admin',
    action: '',
  },
  {
    id: util.generateUniqueId(),
    name: 'Peter',
    mobileNumber: '+911234567890',
    emailAddress: 'peter@yopmail.com',
    designation: 'Manager',
    role: 'Admin',
    action: '',
  },
  {
    id: util.generateUniqueId(),
    name: 'Peter',
    mobileNumber: '+911234567890',
    emailAddress: 'peter@yopmail.com',
    designation: 'Manager',
    role: 'Admin',
    action: '',
  },
  {
    id: util.generateUniqueId(),
    name: 'Peter',
    mobileNumber: '+911234567890',
    emailAddress: 'peter@yopmail.com',
    designation: 'Manager',
    role: 'Admin',
    action: '',
  },
  {
    id: util.generateUniqueId(),
    name: 'Peter',
    mobileNumber: '+911234567890',
    emailAddress: 'peter@yopmail.com',
    designation: 'Manager',
    role: 'Admin',
    action: '',
  },
  {
    id: util.generateUniqueId(),
    name: 'Peter',
    mobileNumber: '+911234567890',
    emailAddress: 'peter@yopmail.com',
    designation: 'Manager',
    role: 'Admin',
    action: '',
  },
  {
    id: util.generateUniqueId(),
    name: 'Peter',
    mobileNumber: '+911234567890',
    emailAddress: 'peter@yopmail.com',
    designation: 'Manager',
    role: 'Admin',
    action: '',
  },
];
